import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface ConfirmationData {
  hashId?: string | null;
  confirmationCode?: string | null;
}

const useConfirmSignUpQuery = () => {
  const {
    mutateAsync,
    isLoading,
    isError,
  } = useMutation(({ hashId, confirmationCode }: ConfirmationData) => api.patch(PATHS.SIGN_UP_CONFIRMATION, {
    hash_id: hashId,
    confirmation_code: confirmationCode,
  }));

  return {
    confirmSignUp: mutateAsync,
    isLoading,
    isError,
  };
};

export default useConfirmSignUpQuery;
