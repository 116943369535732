import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
} from '@common-fe/common-fe';
import pathToRegexp from 'path-to-regexp';
import { createGlobalStyle } from 'styled-components';

import ROUTES from '@/common/routes';
import { DefaultContentWrapper } from '@/components/wrappers/DefaultContentWrapper/DefaultContentWrapper';
import { useHorizontalMenuMode } from '@/modules/core/hooks';
import { useShowLockBanner } from '@/modules/core/hooks';
import useAllowNotifications from '@/modules/core/hooks/useAllowNotifications';
import useEmployerTerminationBanner from '@/modules/core/hooks/useEmployerTerminationBanner';
import NotificationsCenter from '@/modules/notification/NotificationsCenter';
import { useAuthStore } from '@/modules/user/stores';

import HorizontalMenu from '../Menu/HorizontalMenu';
import Sidebar from '../Menu/Sidebar';
import Topbar from '../Topbar';

import { Content } from './DashboardWrapper.styles';
import { useApplyFixedScrollStore } from './stores';

interface BodyProps {
  isFixedScroll?: boolean;
}
const BodyContext = createGlobalStyle<BodyProps>`
  body {
    overflow-y: ${({ isFixedScroll }) => (isFixedScroll ? 'scroll !important' : '')};
  }
`;
interface Props {
  children: React.ReactNode;
}

const MINIFIED_PAGES = [
  ROUTES.REIMBURSE_ME,
  ROUTES.REQUEST_BILL_PAY,
  ROUTES.RECEIPT_VAULT,
  ROUTES.START_INVESTING,
  ROUTES.BUY_SELL,
  ROUTES.SELF_DIRECTED(' '),
  ROUTES.SELF_DIRECTED_INVEST_MORE(' '),
  ROUTES.SELF_DIRECTED_SWITCH_SELECT(' '),
  ROUTES.MANAGED_SWITCH_QUESTIONNAIRE(' '),
  ROUTES.MANAGED_SWITCH_FUNDING_AMOUNT(' '),
  ROUTES.MANAGED_SWITCH_ALLOCATION(' '), 
  ROUTES.SELF_DIRECTED_SWITCH_ALLOCATION(' '),
  ROUTES.SELF_DIRECTED_ALLOCATION(' '),
  ROUTES.MANAGED_SELL_INVESTMENTS(' '),
  ROUTES.SELF_DIRECTED_QUESTIONNAIRE(' '),
  ROUTES.INVESTMENTS_UPDATE_RISK_PORTFOLIO,
];

const WITHOUT_WRAPPER_PAGES = [
  ROUTES.SIGN_UP_ORPHAN,
  ROUTES.DOWNLOAD_FILE_BY_QR_LINK,
  ROUTES.ONE_TIME_LINKS,
];

const DashboardWrapper: React.FC<Props> = ({ children }) => {
  const [minified, setMinified] = useState(false);
  const isHorizontalMenu = useHorizontalMenuMode();
  const isLockBannerShowed = useShowLockBanner();
  const { bannerHeight } = useEmployerTerminationBanner();

  const { auth, inited, user } = useAuthStore();
  const isLoading = !inited;
  const { pathname } = useLocation();
  useEffect(() => {
    const newValue = !!MINIFIED_PAGES.find((item) => pathname.includes(item.trim()));
    if (minified !== newValue) {
      setMinified(newValue);
    }
  }, [pathname, minified]);
  const isNotificationsAllowed = useAllowNotifications();
  const isNotificationsShowed = useMemo(() => (!!user?.employee?.id
      && isNotificationsAllowed), [user, isNotificationsAllowed]);

  const isWithWrapper = useMemo(() => WITHOUT_WRAPPER_PAGES.every((template) => {
    const regexp = pathToRegexp(template);
    return !regexp.test(pathname);
  }), [pathname]);
  const isFixedScrolled = useApplyFixedScrollStore((state) => state.isApplied);
  if (isLoading) {
    return null; 
  }

  if (auth && isWithWrapper) {
    return (
      <Box 
        direction={isHorizontalMenu ? 'column' : 'row'}
        data-testid="dashboard-wrapper"
        margin={{ top: isLockBannerShowed ? '62px' : bannerHeight || '0' }}>
        <BodyContext isFixedScroll={isFixedScrolled} /> 
        {isHorizontalMenu ? <HorizontalMenu /> : <Sidebar minified={minified} />}
        <Content
          minified={minified}
          align="center"
        >
          <DefaultContentWrapper>
            {!minified && <Topbar dashboardMode />}
            {isNotificationsShowed && (
              <NotificationsCenter />
            )}
            {children}
          </DefaultContentWrapper>
        </Content>
        
      </Box>
    );
  }
  return (
    <>
      {children}
    </>
  );
};
export default DashboardWrapper;
