import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccountStatus,
  BigAddContributionIcon,
  BigAddInvestmentIcon,
  BigDisabledAddContributionIcon,
  BigDisabledAddInvestmentIcon,
  costFormater,
  Enrollment,
  Inscription,
  SmallAddContributionIcon,
  SmallAddInvestmentIcon,
  Text,
} from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useHasAccess, useHistory } from '@/modules/core/hooks';
import { useInvestor } from '@/modules/investments/hooks';
import { InvestmentResumeBlock } from '@/modules/investments/Investments';
import { InvestorStatus } from '@/modules/investments/investments.types';
import ServerErrorModal from '@/modules/investments/modal/ServerErrorModal';
import { useInvestmentsStartQuery } from '@/modules/investments/queries';
import { ErrorContributionModal } from '@/modules/transaction/components/Contributions/ContributionModals/ErrorContributionModal';
import { MakeContributionModal } from '@/modules/transaction/components/Contributions/ContributionModals/MakeContributionModal';
import { SuccessContributionModal } from '@/modules/transaction/components/Contributions/ContributionModals/SuccessContributionModal';

export interface FundManagementOption {
  title: string;
  description?: string | React.ReactNode;
  icon?: React.ReactNode;
  isWarningIcon?: boolean;
  externalButtonIcon?: React.ReactNode;
  onClick?: () => void;
  buttonText?: string;
  isButtonLoading?: boolean;
  isButtonDisabled?: boolean;
  additionalContent?: React.ReactNode;
  isWithHint?: boolean;
  hintContent?: React.ReactNode;

  children?: React.ReactNode;
}

interface Params {
  accountId?: string;
  isNotVerified?: boolean;
  isFullLiquidation?: boolean;
}
export const useFundManagementOptions = (myAccount?: Enrollment, params?: Params) => {
  const history = useHistory();
  const [showMakeContributionModal, setShowMakeContributionModal] = useState(false);
  const [errorWarningVisible, setErrorWarningVisible] = useState(false);
  const { t } = useTranslation();
  const currentAccountId = useMemo(() => (myAccount?.id ? `${myAccount?.id}` : undefined), [myAccount]);
  const investor = useInvestor(currentAccountId);

  const zeroState = useMemo(
    () => myAccount && myAccount?.available < myAccount?.investmentThreshold,
    [myAccount]
  );

  const isLimitedAccess = useHasAccess([
    {
      role: Roles.employeeLimitedAccess,
    },
  ]);
  const { handleSave: handleStartInvestments, isLoading: isStartInvestingLoading } = useInvestmentsStartQuery(
    {
      accountId: currentAccountId,
      onError: () => {
        setErrorWarningVisible(true);
      },
    }
  );

  const handleSaveInvestment = useCallback(() => {
    if (params?.isFullLiquidation && params.accountId) {
      history.push(`${ROUTES.INVESTMENTS}?id=${params.accountId}`);
      return;
    }
    handleStartInvestments();
  }, [handleStartInvestments, params, history]);
  const isInvestmentsDisabled = useMemo(() => {
    if (params?.isNotVerified) {
      return true;
    }
    const availableBalance = myAccount?.available || 0;
    const minimumInvestmentAmount = myAccount?.minimumInvestmentAmount || 0;
    const investmentThreshold = myAccount?.investmentThreshold || 0;
    const total = minimumInvestmentAmount + investmentThreshold;
    return total > availableBalance;
  }, [myAccount, params]);

  const investmentAdditionalContent = useMemo(
    () => (
      <>
        <ServerErrorModal
          visible={errorWarningVisible}
          onSetVisible={setErrorWarningVisible}
          onSendRequest={handleStartInvestments}
          isLoading={isStartInvestingLoading}
        />
      </>
    ),
    [errorWarningVisible, handleStartInvestments, isStartInvestingLoading]
  );

  const contributionAdditionalContent = useMemo(
    () => (
      <>
        <MakeContributionModal
          visible={showMakeContributionModal}
          onSetVisible={setShowMakeContributionModal}
          account={myAccount}
        />
        <SuccessContributionModal />
        <ErrorContributionModal />
      </>
    ),
    [showMakeContributionModal, myAccount]
  );

  const investmentHintContent = useMemo(() => {
    if (params?.isNotVerified) {
      return (
        <Inscription size="small" textAlign="center">
          {t('We need to verify your identity to establish your HSA.')}
        </Inscription>
      );
    }
    if (isInvestmentsDisabled) {
      return (
        <Inscription textAlign="center" size="small">
          {t(`A minimum investment of  ${costFormater(
            myAccount?.minimumInvestmentAmount || 0
          )} is required to setup an investment account.
            You may start investing once your HSA has 
            ${costFormater(
          (myAccount?.minimumInvestmentAmount || 0) + (myAccount?.investmentThreshold || 0)
        )}.
          `)}
        </Inscription>
      );
    }
    if (isLimitedAccess) {
      return (
        <Inscription size="small" textAlign="center">
          {t('Complete your setup to gain full access to your account.')}
        </Inscription>
      );
    }

    if (zeroState && myAccount?.accountStatus === AccountStatus.OPEN) {
      return (
        <Inscription size="small" textAlign="center">
          {t('Almost there! To start investing you need a minimum of ')}
          <Text style={{ lineHeight: 'inherit' }}>{costFormater(myAccount?.investmentThreshold)}</Text>
          {t(` in your ${myAccount?.accountType} account.`)}
        </Inscription>
      );
    }
    if (myAccount?.accountStatus === AccountStatus.PENDING) {
      return (
        <Inscription size="small" textAlign="center">
          {t('To start investing you need to verify your identity.')}
        </Inscription>
      );
    }
    return '';
  }, [isInvestmentsDisabled, isLimitedAccess, myAccount, params, t, zeroState]);

  const contributionHintContent = useMemo(() => {
    if (params?.isNotVerified) {
      return (
        <Inscription size="small" textAlign="center">
          {t('We need to verify your identity to establish your HSA.')}
        </Inscription>
      );
    }
    if (isLimitedAccess) {
      return (
        <Inscription size="small" textAlign="center">
          {t('Complete your setup to gain full access to your account.')}
        </Inscription>
      );
    }
    return '';
  }, [isLimitedAccess, params, t]);

  const isInvestmentWithHint = useMemo(
    () =>
      isLimitedAccess ||
      isInvestmentsDisabled ||
      (zeroState && myAccount?.accountStatus === AccountStatus.OPEN) ||
      myAccount?.accountStatus === AccountStatus.PENDING,
    [isInvestmentsDisabled, isLimitedAccess, myAccount, zeroState]
  );

  const isInvestmentButtonDisabled = useMemo(
    () =>
      !myAccount?.allowInvestments ||
      zeroState ||
      myAccount?.accountStatus === AccountStatus.PENDING ||
      isLimitedAccess ||
      isInvestmentsDisabled ||
      !!params?.isNotVerified,
    [myAccount, zeroState, isLimitedAccess, isInvestmentsDisabled, params]
  );

  const isContributionButtonDisabled = useMemo(
    () =>
      !myAccount?.allowIndividualContributions ||
      myAccount?.accountStatus === AccountStatus.PENDING ||
      isLimitedAccess ||
      !!params?.isNotVerified,
    [myAccount, isLimitedAccess, params]
  );
  
  const investmentItem = useMemo(
    () => ({
      title: 'Investments',
      description:
        isInvestmentButtonDisabled || params?.isNotVerified
          ? t('Unavailable for now')
          : t('You can now start investing!'),
      icon:
        isInvestmentButtonDisabled || params?.isNotVerified ? (
          <BigDisabledAddInvestmentIcon />
        ) : (
          <BigAddInvestmentIcon />
        ),
      externalButtonIcon: <SmallAddInvestmentIcon />,
      onClick: () => {
        if (!investor.data.type || investor?.data?.status === InvestorStatus.INVESTOR_CREATED) {
          handleSaveInvestment();
        }
      },
      buttonText: t('Start Investing'),
      isButtonLoading: isStartInvestingLoading,
      isButtonDisabled: !!isInvestmentButtonDisabled,
      additionalContent: investmentAdditionalContent,
      isWithHint: isInvestmentWithHint || !!params?.isNotVerified,
      isWarningIcon: isInvestmentsDisabled,
      hintContent: investmentHintContent,
      children: <InvestmentResumeBlock accountId={currentAccountId} />,
    }),
    [isInvestmentButtonDisabled, params?.isNotVerified, t,
      isStartInvestingLoading, investmentAdditionalContent, 
      isInvestmentWithHint, isInvestmentsDisabled, investmentHintContent,
      currentAccountId, investor.data.type, investor.data?.status,
      handleSaveInvestment]
  );

  const contributionItem = useMemo(
    () => ({
      title: 'Contributions',
      description:
        isContributionButtonDisabled || params?.isNotVerified
          ? t('Unavailable for now')
          : t('Start saving money.'),
      icon:
        isContributionButtonDisabled || params?.isNotVerified ? (
          <BigDisabledAddContributionIcon />
        ) : (
          <BigAddContributionIcon />
        ),
      externalButtonIcon: <SmallAddContributionIcon />,
      onClick: () => history.push(ROUTES.CONTRIBUTION(currentAccountId)),
      additionalContent: contributionAdditionalContent,
      buttonText: t('Make a Contribution'),
      isButtonDisabled: isContributionButtonDisabled,
      isWarningIcon: isContributionButtonDisabled && params?.isNotVerified,
      isWithHint: isLimitedAccess || !!params?.isNotVerified,
      hintContent: contributionHintContent,
    }),
    [
      contributionAdditionalContent,
      isContributionButtonDisabled,
      isLimitedAccess,
      t,
      params,
      contributionHintContent,
      history,
      currentAccountId,
    ]
  );
  const hideInvestmentItem = useMemo(
    () =>
      investor?.data?.status === InvestorStatus.ACTIVE ||
      investor?.data?.status === InvestorStatus.PENDING_EXTERNAL_CREATION ||
      !myAccount?.allowInvestments,
    [investor, myAccount]
  );

  const hideContributionItem = useMemo(() => !myAccount?.allowIndividualContributions, [myAccount]);

  const fundManagementItems = useMemo(
    () => [
      ...(!hideInvestmentItem || params?.isNotVerified ? [investmentItem] : []),
      ...(!hideContributionItem || params?.isNotVerified ? [contributionItem] : []),
    ],
    [hideInvestmentItem, params, investmentItem, hideContributionItem, contributionItem]
  );

  const fundManagementButtons = useMemo(
    () => [
      ...(!hideInvestmentItem && !isInvestmentButtonDisabled ? [investmentItem] : []),
      ...(!isContributionButtonDisabled && !hideContributionItem ? [contributionItem] : []),
    ],
    [
      hideInvestmentItem,
      isInvestmentButtonDisabled,
      investmentItem,
      hideContributionItem,
      isContributionButtonDisabled,
      contributionItem,
    ]
  );

  return {
    fundManagementItems,
    fundManagementButtons,
  };
};
export default useFundManagementOptions;
