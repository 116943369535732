import React, { useState } from 'react';

import { Steps } from '@/modules/transaction/components/Statements/components/ChangeEmailModal/ChangeEmailModal.constants';

import EditEmailModal from './components/EditEmailModal';
import SuccessfullyChangedModal from './components/SuccessfullyChangedModal';

interface Props {
  onClose: () => void;
}

const StatementsModal: React.FC<Props> = ({ onClose }) => {
  const [step, setStep] = useState(Steps.EditEmail);

  return (
    <>
      {
        step === Steps.EditEmail && (
          <EditEmailModal
            onClose={onClose}
            setStep={setStep}
          />
        )
      }
      {
        step === Steps.SuccessfullyChanged && (
          <SuccessfullyChangedModal
            onClose={onClose}
          />
        )
      }
    </>
  );
};

export default StatementsModal;
