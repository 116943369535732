import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useSearchParams = (searchParams: string) => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search).get(searchParams),
    [location.search, searchParams]);

  return {
    query,
  };
};
