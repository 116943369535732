import { useEffect } from 'react';
import { Option } from '@common-fe/common-fe';

import { EligibleExpense } from '../../FileScan/fileScan.types';
import { FormField } from '../../ReimburseMe.types';
import { useClaimInfoStore } from '../../store/useClaimInfo.store';
import { useClaimInfoFieldsFilterStore } from '../stores/useClaimInfoFieldsFilter.store';

import { findOptionKeyByValue } from './findOption';

interface Props {
  filteredExpense?: EligibleExpense;
  patientsOptions: Option[];
  servicesOptions: Option[];
}

const useSetMaxAmountExpenseOption = ({
  filteredExpense, patientsOptions, servicesOptions
}: Props) => {
  const { filterByValues, editedFields } = useClaimInfoFieldsFilterStore();
  const { handleSetValues } = useClaimInfoStore();

  useEffect(() => {
    if (!filteredExpense) {
      return;
    }

    if (!filterByValues?.patient && filteredExpense?.patient && !editedFields?.has(FormField.patient)) {
      handleSetValues({ patient: findOptionKeyByValue(patientsOptions, filteredExpense?.patient) });
    }

    if (!filterByValues?.serviceDates && filteredExpense?.serviceDates?.length && !editedFields?.has(FormField.serviceDate)) {
      handleSetValues({ serviceDate: filteredExpense?.serviceDates[0] });
    }
    
    if (!filterByValues?.service && filteredExpense?.service && !editedFields?.has(FormField.service)) {
      handleSetValues({ service: findOptionKeyByValue(servicesOptions, filteredExpense?.service) });
    }

    if (filteredExpense?.amount && !editedFields?.has(FormField.amount)) {
      handleSetValues({ amount: filteredExpense?.amount });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByValues?.patient, filterByValues?.service, filterByValues?.serviceDates]);
};

export default useSetMaxAmountExpenseOption;
