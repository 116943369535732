import apprizeTheme from './apprize';
import ripplingTheme from './rippling';
import rmrTheme from './rmr';
import { WhiteLabelTheme } from './types';

const QUERY_KEY = 'whiteLabeling';

const DEFAULT_IOS_APP_STORE_LINK = 'https://apps.apple.com/us/app/elevate-my-benefits/id1626068256';
const DEFAULT_ANDROID_APP_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.elevateaccounts.mobile';

const DEFAULT_IOS_APP_LINK = 'elevate://open-app';
const DEFAULT_ANDROID_APP_LINK = 'elevate://open-app';


const { search } = window.location;
const envVariable =  import.meta.env.REACT_APP_WHITE_LABEL;
const query = new URLSearchParams(search).get(QUERY_KEY);
export const THEMES = {
  apprize: 'apprize',
  rippling: 'rippling',
  rmr: 'rmr',
};
const getTheme = () => {
  let currentTheme: WhiteLabelTheme | null = null;
  if (query === THEMES.apprize || envVariable === THEMES.apprize) {
    currentTheme = apprizeTheme;

  }
  if (query === THEMES.rippling || envVariable === THEMES.rippling) {
    currentTheme = ripplingTheme;
  }

  
  if (query === THEMES.rmr || envVariable === THEMES.rmr || sessionStorage.getItem('rmr')) {
    currentTheme = rmrTheme;
    sessionStorage.setItem('rmr', 'true');
  }
  if (!currentTheme) {
    return {
      IOSAppStoreLink: DEFAULT_IOS_APP_STORE_LINK,
      androidAppStoreLink: DEFAULT_ANDROID_APP_STORE_LINK,
  
      IOSAppLink: DEFAULT_IOS_APP_LINK,
      androidAppLink: DEFAULT_ANDROID_APP_LINK

    } as WhiteLabelTheme;
  }

  if (currentTheme.title) {
    document.title = currentTheme.title;
  }
  return {
    key: envVariable || query,
    title: currentTheme.title?.toLowerCase(),
    termsAndConditions: currentTheme?.termsAndConditions,

    IOSAppStoreLink: currentTheme.IOSAppStoreLink || DEFAULT_IOS_APP_STORE_LINK,
    androidAppStoreLink: currentTheme.androidAppStoreLink || DEFAULT_ANDROID_APP_STORE_LINK,

    IOSAppLink: currentTheme.IOSAppLink ||  DEFAULT_IOS_APP_LINK,
    androidAppLink: currentTheme.androidAppLink || DEFAULT_ANDROID_APP_LINK,
    mobileAppIcon: currentTheme.mobileAppIcon,
    mobileAppTitle: currentTheme.mobileAppTitle,


    privacyPolicy: currentTheme?.privacyPolicy,
    loginScreenTitle: currentTheme?.loginScreenTitle,
    tetriaryAccentButton: currentTheme.tetriaryAccentButton, // custom field
    colors: currentTheme.colors || {},
    fonts: currentTheme?.fonts || {},
    logo: currentTheme.logo,
    minifyLogo: currentTheme.minifyLogo,
    images: currentTheme.images || {},
    font: currentTheme.font, // Alse you need add font import to global.scss example: @import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
    titleFont: currentTheme.titleFont,
    rounds: currentTheme.rounds || {},
    favicon: currentTheme.favicon || '', // Save this icon, for example 'fidelityFavicon.ico', in public folder, here put the path to this icon file.
    favicon16: currentTheme.favicon16 || '', // Save this icon, for example 'favicon-fidelity-16x16.png', in public folder, here put the path to this icon file.
    favicon32: currentTheme.favicon32 || '', // Save this icon, for example 'favicon-fidelity-32x32.png', in public folder, here put the path to this icon file.
    faviconAppleTouch: currentTheme.faviconAppleTouch || '', // Save this icon, for example 'apple-touch-icon-fidelity.png', in public folder, here put the path to this icon file.
    shadows: currentTheme.shadows || {},
  } as WhiteLabelTheme;
};

export default getTheme();
