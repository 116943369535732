import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt,useHistory } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FieldTypes,
  FlexForm,
  formatSnakeCaseToCamelCase,
  Inscription,
  RoundDoneIcon,
  UploadIcon,
  WarningIcon,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { toString } from 'lodash';

import ROUTES from '@/common/routes';
import { ModalWrapper,PendingModal } from '@/components';
import AppButton from '@/components/controls/AppButton';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import { useSnackbar } from '@/modules/core/hooks';
import { useGetPaymentMethods } from '@/modules/transaction/components/ReimburseMe/queries/useGetPaymentMethods.query';
import { ContentWrapper } from '@/modules/transaction/components/ReimburseMe/ReimburseMe.styles';
import { ReimbursementFormPayload, TransactMethod } from '@/modules/transaction/components/ReimburseMe/ReimburseMe.types';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { useAuthStore } from '@/modules/user/stores';
import { PaymentMethodType } from '@/modules/user/user.types';
import globalTheme from '@/styles/theme';
import { getFullAddress } from '@/utils';

import Immediately from '../ConfirmationStep/Immediately';
import { OcrWarningType, ParsedFileByOcr } from '../FileScan/fileScan.types';
import { useGetCurrentPatients } from '../hooks/useGetCurrentPatients';
import useClaimInfoQuery, { ClaimApprovalStatusCode } from '../queries/useClaimInfo.query';
import useRemoveDocumentQuery from '../queries/useRemoveDocument.query';
import { useClaimInfoStore } from '../store/useClaimInfo.store';

import usePatientStore from './AddPatienForm/usePatient.store';
import useProviderStore from './AddProviderForm/useProvider.store';
import ClaimDuplicateWarning from './ClaimWarningBanner/ClaimDuplicateWarning';
import { ClaimMissingErrorBanner } from './ClaimWarningBanner/ClaimMissingErrorBanner';
import { useOcrWarningsStore } from './ClaimWarningBanner/store';
import useAccountOrdersMatrixesServicesQuery from './queries/useAccountOrdersMatrixesServices.query';
import AcceptFooter from './AcceptFooter';
import AddPatientForm from './AddPatienForm';
import AddProviderForm from './AddProviderForm';
import {
  ClaimFormWrapper,
  FormWrapper,
  Header,
  StyledBox,
  StyledInscription,
} from './ClaimInfo.styles';
import { useDeductiblesServicesQuery } from './queries';
import useAccountOrdersQuery, { AccountOrder,ProofOfExpensesTypes } from './useAccountOrders.query';
import useClaimInfoFields from './useClaimInfoFields';
import { Patient } from './usePatients.query';
import { Provider, useCreateProviderQuery, useGetProvidersQuery } from './useProviders.query';

const EMPTY_TEXT = '-';

const TextProps = {
  size: 'large',
  color: 'textBody',
  margin: { top: '12px' },
};

const getTimestamp = (date: string) => dayjs(date).valueOf();
const currentDate = getTimestamp(dayjs().format());

interface Props {
  isBillPayMode?: boolean;
  onBack: () => void;
  children: React.ReactNode;
  scannedDocument?: ParsedFileByOcr;
  addFileButtonRef?: React.RefObject<HTMLDivElement>;
}

const ClaimInfo: React.FC<Props> = ({
  isBillPayMode,
  onBack,
  children,
  scannedDocument,
  addFileButtonRef,
}) => {
  const ref = useRef(null);
  const {
    handleSetValues: setFilesDocument,
    values: { documentId, files },
  } = useFilesInDocumentStore();
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { remove } = useRemoveDocumentQuery();
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const history = useHistory();
  const [isAddProviderModalActive, activeAddProviderModal] = useState(false);
  const [isEditProviderModalActive, activeEditProviderModal] = useState(false);
  const [isEditProviderButtonHighlighted, highlightEditProviderButton] = useState(false);
  const { checkIsAvailablePaymentMethod } = useOrganizationProperties();
  const ocrReceiptId = useMemo(() => scannedDocument?.ocrReceiptId, [scannedDocument]);
  const employeeId = useMemo(() => {
    return user?.employee?.id || ''; 
  }, [user?.employee?.id]);
  const payAttentionToEditProviderButton = () => {
    window.scroll(0, 0);
    highlightEditProviderButton(true);

    const timeoutId = setTimeout(() => highlightEditProviderButton(false), 2000);

    return () => clearTimeout(timeoutId);
  };
  const {
    values: claimInfo,
    handleSetValues,
    handleSetProviderId,
    handleReset: handleResetClaimInfo,
  } = useClaimInfoStore();
  const warnings = useOcrWarningsStore((state) => state.ocrWarnings);
  const fieldsWarnings = useOcrWarningsStore((state) => state.fieldsOcrWarnings);
  const handleResetWarnings = useOcrWarningsStore((state) => state.handleResetOcrWarnings);

  const { save: saveProvider } = useCreateProviderQuery();

  const handleCreateProvider = useCallback((value: Provider, onSuccess?: () => void) => {
    saveProvider(
      value,
      (createdProviderName?: string) => {
        if (createdProviderName) {
          handleAddPermanentSnackbar({
            text: `${createdProviderName} ${t('added')}.`,
            closeIcon: true,
          });

          if (onSuccess) onSuccess();
        }
      },
    );
  }, [saveProvider, handleAddPermanentSnackbar, t]);

  const { providers, refetch } = useGetProvidersQuery({
    ...isBillPayMode
      ? {}
      : {
        onSuccess: () => handleCreateProvider({ name: scannedDocument?.providerName }),
        scannedProvider: scannedDocument?.providerName
      }
  });

  const selectedProvider = useMemo(
    () => providers
      .find((provider: Provider) => toString(provider.id) === claimInfo.provider),
    [claimInfo.provider, providers],
  );
  const handleSetPaymentMethods = useStore((state) => state.handleSetPaymentMethods);
  const handleSetActivePaymentMethod = useStore((state) => state.handleSetActivePaymentMethod);
  const handleGettingPaymentMethods = useCallback((paymentMethods: PaymentMethod[]) => {
    handleSetPaymentMethods(
      isBillPayMode
        ? paymentMethods
        : paymentMethods
          .filter(
            (method) => method?.paymentMethodOwner?.employeeId === toString(employeeId),
          ),
    );
  }, [employeeId, handleSetPaymentMethods, isBillPayMode]);
  const {
    formattedData, isLoading: isPaymentMethodsLoading,
  } = useGetPaymentMethods(handleGettingPaymentMethods);
  const paymentMethods = useMemo(() => {
    if (isBillPayMode) {
      return formattedData
        .filter(
          (paymentMethod) => paymentMethod?.paymentMethodOwner?.providerId === selectedProvider?.id,
        );
    }
     
    return formattedData.filter((paymentMethod) => paymentMethod?.paymentMethodOwner?.employeeId === toString(employeeId));
  }, [isBillPayMode, formattedData, selectedProvider?.id, employeeId]);
  const activePaymentMethodId = useStore((state) => state.activeMethod?.id);
  const selectedPaymentMethod = useStore((state) => state.selectedMethod);
  const clearPaymentMethods = useStore((state) => state.handleClear);
  const [isRetryError, setRetryError] = useState(false);
  const {
    mutateAsync: submitMutateAsync,
  } = useClaimInfoQuery({ onRetryError: () => setRetryError(true) });
  const isPaymentMethodAvailable = checkIsAvailablePaymentMethod(selectedPaymentMethod);
  const { accountOrders, isLoading: isAccountsLoading, isSuccess } = useAccountOrdersQuery(
    claimInfo.service,
    claimInfo.serviceDate,
    !!claimInfo.amount,
  );
  const getIsReceiptRequired = useCallback(() => {
    if (!claimInfo.serviceDate) return false;

    const suitableAccountsOrders = accountOrders
      .filter(
        (order) => {
          if (!order.coverageDateStart) return false;

          const serviceDate = getTimestamp(claimInfo.serviceDate);
          const submitClaimsDateEnd = order.submitClaimsDateEnd
            ? getTimestamp(order.submitClaimsDateEnd) : Infinity;
          const coverageDateStart = getTimestamp(order.coverageDateStart);
          const coverageDateEnd = order.coverageDateEnd
            ? getTimestamp(order.coverageDateEnd)
            : Infinity;

          return serviceDate >= coverageDateStart
            && serviceDate <= coverageDateEnd
            && submitClaimsDateEnd >= currentDate;
        },
      );

    if (!claimInfo.amount
      || !suitableAccountsOrders.length
      || (documentId && files.length)) return false;

    let result = false;
    let availableBalance = 0;
    let index = 0;

    while (parseFloat(claimInfo.amount) > availableBalance
      && result === false
      && suitableAccountsOrders[index]) {
      availableBalance += suitableAccountsOrders[index]?.availableBalance || 0;

      if (suitableAccountsOrders[index]?.proofOfExpensesType
        && suitableAccountsOrders[index]?.proofOfExpensesType
        !== ProofOfExpensesTypes.NO_RECEIPT_REQUIRED) {
        result = true;
      }

      index += 1;
    }

    return result;
  }, [accountOrders, claimInfo, documentId, files.length]);
  const [isLoading, setIsLoading] = useState(false);
  const [justAddedClaimId, setJustAddedClaimId] = useState('');
  const [message, setMessage] = useState<{ title?: string, desctiption?: string }>({});

  const [isAttemptToSubmit, setIsAttemptToSubmit] = useState(false);
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);
  const [pendingVisible, setPendingVisible] = useState(false);
  const [isAutomaticallyApprovedAi, setIsAutomaticallyApprovedAi] = useState(false);
  const [isAccepted, setAccepted] = useState(false);
  const [isAddPatientModalActive, activeAddPatientModal] = useState(false);

  const handleClosePending = () => {
    setPendingVisible(false);
    setIsAutomaticallyApprovedAi(false);
    history.push({
      pathname: ROUTES.HOME,
      search: new URLSearchParams({ justAddedClaimId }).toString(),
    });
  };


  const goToExpenseDetailsPage = () => {
    setPendingVisible(false);
    setIsAutomaticallyApprovedAi(false);
    history.push({
      pathname: ROUTES.EXPENSE_DETAILS,
      search: new URLSearchParams({ justAddedClaimId }).toString(),
    });
  };
  const {
    createdProviderId, handleReset: handleResetProviderId,
  } = useProviderStore();
  const selectedPaymentMethodForBillPay = useMemo(
    () => {
      if (!isBillPayMode) {
        return undefined;
      }

      return paymentMethods
        .find(
          (paymentMethod) => paymentMethod?.paymentMethodOwner?.providerId === selectedProvider?.id
            && paymentMethod?.type === PaymentMethodType.CHECK,
        ) as PaymentMethod;
    },
    [paymentMethods, isBillPayMode, selectedProvider],
  );
  const { services, isLoading: isServicesLoading } = useAccountOrdersMatrixesServicesQuery(employeeId, claimInfo?.serviceDate);
  const { patients } = useGetCurrentPatients();
  const [scannedProviderId, setScannedProviderId] = useState('');
  const [isScannedProviderSet, setIsScannedProviderSet] = useState(false);

  useEffect(() => {
    if (scannedDocument?.providerName && !isScannedProviderSet && providers?.length) {
      const scannedProvider = providers
        .find((provider: Provider) => provider.name === scannedDocument?.providerName)?.id || '';
      setScannedProviderId(scannedProvider); 
      setIsScannedProviderSet(true);
    }
  }, [scannedDocument?.providerName, isScannedProviderSet, providers]);

  const isCoveragePeriodActive = useMemo(() => {
    if (!accountOrders.length || isAccountsLoading) return true;

    return accountOrders.some((account: AccountOrder) => {
      const dateStart = account.coverageDateStart ? getTimestamp(account.coverageDateStart) : 0;
      const dateEnd = account.coverageDateEnd ? getTimestamp(account.coverageDateEnd) : 0;
      const submitClaimsDateEnd = account.submitClaimsDateEnd
        ? getTimestamp(account.submitClaimsDateEnd)
        : 0;

      const serviceDate = getTimestamp(claimInfo.serviceDate);

      if (submitClaimsDateEnd && submitClaimsDateEnd < currentDate) return false;

      if (!dateStart && !dateEnd) return true;

      if (serviceDate >= dateStart && !account.coverageDateEnd) return true;

      return serviceDate >= dateStart && serviceDate <= dateEnd;
    });
  }, [claimInfo, accountOrders, isAccountsLoading]);
  //

  const { isOptionsExists: isDeductiblesServicesExists} = useDeductiblesServicesQuery(employeeId);
  const isDocumentRequired = useMemo(() => Boolean(isDeductiblesServicesExists
    && !accountOrders.length
    && claimInfo?.amount
    && claimInfo?.patient
    && claimInfo?.provider
    && claimInfo?.serviceDate
    && !isAccountsLoading
    && isSuccess
    && !documentId),
  [accountOrders, isDeductiblesServicesExists, documentId, claimInfo, isAccountsLoading, isSuccess]);
  const hasValidationAccountOrdersError = useMemo(() => {
    if (isAccountsLoading
      || !claimInfo.amount
      || !claimInfo.serviceDate
      || !claimInfo.service) return false;

    return !accountOrders.length && !isDeductiblesServicesExists;
  }, [isAccountsLoading, accountOrders, claimInfo, isDeductiblesServicesExists]);
  const scannedPatientId = useMemo(() => {
    if (!scannedDocument || !patients) {
      return undefined;
    }

    const scannedPatientFullName = scannedDocument.patientName?.toLowerCase() || '';

    const findPatient = (patient: Patient) => {
      const scannedPatientNames = scannedPatientFullName.split(' ');
      return [patient?.firstName, patient?.lastName].some((name) => scannedPatientNames
        .includes(name?.toLowerCase()));
    };

    const findPatientByFullName = (patient: Patient) => scannedPatientFullName
      === patient?.fullName?.toLowerCase();

    const foundPatientByFullName = patients.find(findPatientByFullName);
    if (foundPatientByFullName) return foundPatientByFullName?.id;
    const foundPatient = patients.find(findPatient);
    return foundPatient?.id;
  }, [patients, scannedDocument]);
  const { createdPatientId, handleReset: handleResetPatientId, setPatientId } = usePatientStore();
  useEffect(() => {
    if (createdPatientId) {
      handleSetValues({ patient: createdPatientId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdPatientId]);
  const handleResetPatient = useCallback(() => {
    handleResetPatientId();
    handleSetValues({ patient: '' });
  }, [handleResetPatientId, handleSetValues]);
  const presetFields = useMemo(() => ({
    name: scannedDocument?.providerName,
    adressLine1: scannedDocument?.address1,
    adressLine2: scannedDocument?.address2,
    city: scannedDocument?.city,
    state: scannedDocument?.state,
    zipCode: scannedDocument?.zip,
  }), [scannedDocument]);
  const initEditing = useMemo(() => (
    {
      onEdit: () => activeEditProviderModal(true),
      isHighlighted: isEditProviderButtonHighlighted,
      disabled: !selectedProvider,
    }
  ), [isEditProviderButtonHighlighted, selectedProvider]);

  const isOcrErrors = useMemo(() => {
    if (!scannedDocument || !scannedDocument?.warnings) return false;
    const isAnyErrors = fieldsWarnings[OcrWarningType.MISSING].length
      || fieldsWarnings[OcrWarningType.OUT_OF_COVERAGE_DATE].length
      || fieldsWarnings[OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE].length;

    return !!isAnyErrors;
  }, [scannedDocument, fieldsWarnings]);

  const missingOcrDocumentErrors = useMemo(() => {
    if (!scannedDocument || !scannedDocument?.warnings) return false;
    if (warnings[OcrWarningType.MISSING].length) {
      return warnings[OcrWarningType.MISSING]
        .map((warning) => warning?.fieldLabel);
    }
    return false;
  }, [scannedDocument, warnings]);

  const fields = useClaimInfoFields({
    providers,
    services,
    isServicesLoading,
    patients,
    addPatient: () => activeAddPatientModal(true),
    resetCreatedPatientId: handleResetPatient,
    resetProvider: (id?: string) => {
      handleResetProviderId();
      setScannedProviderId('');
      if (id) handleSetProviderId(id);
    },
    onCreateProvider: handleCreateProvider,
    scannedDocument,
    createdProviderId: scannedProviderId || createdProviderId,
    isBillPayMode,
    addProvider: () => activeAddProviderModal(true),
    initEditing,
    isCoveragePeriodActive,
    hasValidationAccountOrdersError,
  });
  useEffect(() => {
    if (scannedProviderId) {
      setTimeout(() => handleSetProviderId(scannedProviderId));
    } else if (createdProviderId) {
      handleSetProviderId(createdProviderId);
    }
  }, [
    scannedProviderId,
    createdProviderId,
    handleSetProviderId,
  ]);
  useEffect(() => {
    if (isPaymentMethodAvailable) handleSetActivePaymentMethod();
  }, [handleSetActivePaymentMethod, isPaymentMethodAvailable]);
  const handleCancel = () => {
    activeAddProviderModal(false);
    activeEditProviderModal(false);
  };
  const transactMethod = useMemo(() => {
    const keyOfTransactMethod = formatSnakeCaseToCamelCase(selectedPaymentMethod?.type || '');

    return TransactMethod[keyOfTransactMethod as keyof typeof TransactMethod];
  }, [selectedPaymentMethod]);
  const handleSubmit = useCallback(async (values: Partial<ReimbursementFormPayload>) => {
    if (!isAccepted) return;

    if (isBillPayMode ? !selectedPaymentMethodForBillPay : !activePaymentMethodId) {
      setMessage({
        title: t('Add payment method.'),
        desctiption: t('Payment method should be required for claim creation.'),
      });
      return;
    }
    try {
      setIsLoading(true);
      setRetryError(false);
      const { data } = await submitMutateAsync({
        ...values,
        patient: claimInfo.patient,
        serviceDate: claimInfo?.serviceDate,
        amount: claimInfo.amount,
        service: claimInfo.service,
        // TODO: fix type
        // @ts-ignore
        documentId,
        paymentMethodId: isBillPayMode
          ? selectedPaymentMethodForBillPay?.id : activePaymentMethodId,
        paymentType: isBillPayMode ? 'BILL_PAY' : 'REIMBURSEMENT',
        payToType: isBillPayMode ? 'PROVIDER' : 'PARTICIPANT',
        transactMethod,
        ocrReceiptId,
      });

      if (data) {
        setIsLoading(false);
        setJustAddedClaimId(toString(data.id));
        setShouldConfirmOnClose(false);
        // @ts-ignore
        const isAiApproved = data?.claim_approval_status_dtos?.find((status) => status?.claim_approval_status_code === ClaimApprovalStatusCode.AUTOMATICALLY_APPROVED_AI_RECEIPT_APPROVED);
        if (isAiApproved) {
          setIsAutomaticallyApprovedAi(true);
        } else {
          setPendingVisible(true);
        }
      }
    } catch (e) {
      setIsLoading(false);
    }
  }, [
    isAccepted,
    submitMutateAsync,
    activePaymentMethodId,
    documentId,
    setIsLoading,
    isBillPayMode,
    transactMethod,
    selectedPaymentMethodForBillPay,
    t,
    ocrReceiptId,
    claimInfo,
  ]);
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const triggerFormSubmitEvent = useCallback(() => (ref?.current as any)?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true })), []);
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const triggerAddFileButtonClickEvent = useCallback(() => (addFileButtonRef?.current as any)?.dispatchEvent(new Event('click', { bubbles: true, cancelable: true })), [addFileButtonRef]);
  const handlePatientCancel = () => activeAddPatientModal(false);
 
  
  const onSave = useCallback((isSuccessful: boolean) => {
    if (isSuccessful) {
      triggerFormSubmitEvent();
      setIsAttemptToSubmit(true);
    }

    setIsAttemptToSubmit(true);
  }, [triggerFormSubmitEvent]);

  const handleOnBack = useCallback(() => {
    onBack();
    handleResetWarnings();
    handleResetClaimInfo();
  }, [onBack, handleResetWarnings, handleResetClaimInfo]);

  useEffect(() => {
    if (scannedPatientId) {
      setPatientId(toString(scannedPatientId));
    }
  }, [scannedPatientId, setPatientId]);
  useEffect(() => () => {
    handleResetPatientId();
    handleResetProviderId();
    clearPaymentMethods();
  },
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  []);
  useEffect(() => {
    const isReceiptRequired = getIsReceiptRequired();

    if (claimInfo.isReceiptRequired !== isReceiptRequired) {
      handleSetValues({ ...claimInfo, isReceiptRequired });
    }
  }, [
    accountOrders,
    claimInfo,
    getIsReceiptRequired,
    handleSetValues,
  ]);
  useEffect(() => {
    if (scannedDocument) {
      handleSetValues({
        ...claimInfo,
        amount: scannedDocument.amount || '',
        serviceDate: scannedDocument.serviceDate || '',
      });
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  const renderNotification = useCallback(() => {
    if (isBillPayMode && selectedProvider && !getFullAddress(selectedProvider?.address)) {
      return (
        <Box align="end" width="100%">
          <Box direction="row" align="center">
            <Box
              width="32px"
              height="32px"
              round="100%"
              background="danger"
              border={{ size: '2px', color: 'canvas' }}
              justify="center"
              align="center"
              margin={{ right: 'spacing6' }}
              style={{
                boxShadow: globalTheme.shadows.default['box-shadow'],
              }}
            >
              <Inscription
                color="textOnColor"
                weight="bold"
              >
                !
              </Inscription>
            </Box>
            <Inscription color="danger">{t('Please add the address of the Provider/Merchant')}</Inscription>
          </Box>
        </Box>
      );
    }

    if (!isCoveragePeriodActive) {
      return (
        <Box align="end" width="100%">
          <Inscription color="danger">
            {t('Oops! Unfortunately, you are not  eligible for reimbursement for the date of service entered.')}
          </Inscription>
          <Inscription color="danger">
            {t('If something doesn\'t seem right, please reach out to your employer or customer service.')}
          </Inscription>
        </Box>
      );
    }

    if (hasValidationAccountOrdersError) {
      return (
        <Box align="end" width="100%">
          <Inscription color="danger">
            {t('Oops! Unfortunately, you are not currently eligible for reimbursement for this service type.')}
          </Inscription>
          <Inscription color="danger">
            {t('If something doesn\'t seem right, please reach out to your employer or customer service.')}
          </Inscription>
        </Box>
      );
    }

    return null;
  }, [
    isBillPayMode,
    selectedProvider,
    hasValidationAccountOrdersError,
    isCoveragePeriodActive,
    t,
  ]);

  return (
    <>
      <Prompt
        when={shouldConfirmOnClose}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <PendingModal
        buttonText={t('Go to Expense Details')}
        onSubmit={goToExpenseDetailsPage}
        visible={pendingVisible}
        onCancel={handleClosePending}
        cancelButtonText={t('Go to Home')}
        {...(claimInfo.isReceiptRequired || isDocumentRequired
          ? {
            header: t('The claim submitted without a receipt'),
            icon: <RoundDoneIcon size="48px" color={globalTheme.colors.success} />,
          }
          : {
            header: t('Your request has been submitted for additional review'),
            helptext: t('You can check on the status of this request under account activity.'),
          })}
      />
      <PendingModal
        onSubmit={goToExpenseDetailsPage}
        visible={isAutomaticallyApprovedAi}
        onCancel={handleClosePending}
        cancelButtonText={t('Submit Another Claim')}
        buttonText={t('Go to Expense Details')}
        header={t('Your claim has been approved!')}
        helptext={t('Your payment is on the way.')}
        icon={<RoundDoneIcon size="48px" color={globalTheme.colors.success} />}
      />
      <PendingModal
        header={message?.title || ''}
        helptext={message?.desctiption}
        buttonText={t('Close')}
        visible={Boolean(Object.keys(message).length)}
        onSetVisible={() => setMessage({})}
      />
      <Box
        direction="row"
        justify="center"
        margin={{ bottom: 'spacing24' }}
      >
        <ContentWrapper
          flex="grow"
        >
          <Box>
            <Header>
              {t(isBillPayMode
                ? 'We Need Your Bill Details'
                : 'We Need Your Reimbursement Request Details')}
            </Header>

            {!isBillPayMode && <Inscription {...TextProps}>{t('Please review and make any needed changes to the claim details.')}</Inscription>}
          </Box>
        </ContentWrapper>
      </Box>

      <Box justify="between" flex>
        <Box
          direction="row"
          justify="center"
          flex="grow"
        >
          <ContentWrapper
            flex="grow"
          >
            <Box background="module" margin={{ bottom: 'spacing24' }} pad="medium" round="moduleRound">
              <ClaimFormWrapper>
                <Box pad={{ right: 'spacing12' }}>
                  {children}
                </Box>

                <StyledBox>
                  <ClaimDuplicateWarning />
                  <FormWrapper border={{ size: 'small', color: 'border2' }} round="container1Round">
                    <FlexForm
                      ref={ref}
                      isNarrowLabelMode
                      formTitle={(
                        <Inscription
                          margin={{ bottom: 'spacing16' }}
                          color="textTitle"
                          size="20px"
                          lineHeight="28px"
                        >
                          {t('Claim Details')}
                        </Inscription>
                      )}
                      editMode
                      fields={fields}
                      onSubmit={handleSubmit}
                      contentStyles={{ margin: 0 }}
                      onDirty={setShouldConfirmOnClose}
                    />
                  </FormWrapper>

                  {!!missingOcrDocumentErrors
                  && (
                    <ClaimMissingErrorBanner missingFields={missingOcrDocumentErrors} />
                  )}

                  <Box
                    background="canvas"
                    round="container1Round"
                    margin={{ vertical: 'spacing12' }}
                    border={{ size: 'small', color: 'border2' }}
                    pad="spacing24"
                  >
                    {isBillPayMode
                      ? (
                        <>
                          <Inscription color="textBody" size="20px" lineHeight="28px">
                            {t('Where Should We Send Your Payment?')}
                          </Inscription>

                          <Box margin={{ vertical: 'spacing16' }}>
                            <FlexForm
                              paddingless
                              contentStyles={{ margin: 0 }}
                              isThinMode
                              isNarrowLabelMode
                              editMode={false}
                              fields={[
                                {
                                  name: 'providerName',
                                  type: FieldTypes.Info,
                                  label: t('Provider/Merchant'),
                                  // @ts-ignore
                                  isThinMode: true,
                                  fieldWrapperStyles: { marginBottom: globalTheme.spacings.xxxs },
                                  value: selectedProvider?.name || EMPTY_TEXT,
                                },
                                {
                                  name: 'providerAddress',
                                  type: FieldTypes.Info,
                                  label: t('Address'),
                                  // @ts-ignore
                                  isThinMode: true,
                                  value: getFullAddress(
                                    selectedPaymentMethodForBillPay?.address,
                                  )
                                    || EMPTY_TEXT,
                                },
                              ]}
                            />
                          </Box>

                          <Inscription color="textBody">
                            {selectedProvider
                              ? (
                                <>
                                  {t('If it\'s not correct, please update the ')}
                                  <StyledInscription onClick={payAttentionToEditProviderButton}>{t('Provider/Merchant')}</StyledInscription>
                                  {t(' above.')}
                                </>
                              )
                              : t('Please add the Provider/Merchant above.')}
                          </Inscription>
                        </>
                      )
                      : <Immediately isLoading={isPaymentMethodsLoading} type="full" />}
                  </Box>

                  {claimInfo.isReceiptRequired || isDocumentRequired ? (
                    <Box background="canvas" round="container1Round" pad="small" margin={{ vertical: 'spacing12' }} border={{ size: 'small', color: 'border2' }}>
                      <Box
                        border={{
                          side: 'top',
                          size: 'large',
                          color: 'warning',
                        }}
                        margin={{ top: '-12px' }}
                      >
                        <Box
                          background="warningContainer"
                          pad={{ top: '18px', bottom: '16px', horizontal: 'medium' }}
                          margin={{ top: 'small' }}
                          round="container2Round"
                          border={{ color: 'warningBorder', size: 'small' }}
                        >
                          <Box direction="row" align="center">
                            <WarningIcon />
                            <Inscription lineHeight="20px" weight="bold" margin={{ left: '10px' }}>
                              {t('Your plan requires proof of expense')}
                            </Inscription>
                          </Box>

                          <Inscription margin={{ top: '10px', bottom: 'spacing16' }}>
                            {t(`Please upload your receipt so we can process your claim.
                                If you don’t have it handy, no worries! You can submit your claim now
                                and upload your supporting documentation later.`)}
                          </Inscription>

                          <AppButton
                            testId="ClaimInfo_receipt_addNow"
                            rightIcon={<UploadIcon width={24} />}
                            width="control"
                            onClick={triggerAddFileButtonClickEvent}
                          >
                            {t('Add Now')}
                          </AppButton>
                        </Box>
                      </Box>
                    </Box>
                  ) : null}

                  <Box background="canvas" pad="medium" round="container1Round" border={{ size: 'small', color: 'border2' }}>
                    <Checkbox
                      id="submitReguest"
                      className="checkbox-item"
                      checked={isAccepted}
                      name={t('Enable notifications')}
                      onChange={setAccepted}
                      error={!isAccepted && isAttemptToSubmit}
                    >
                      <Inscription size="medium" color="textBody" lineHeight="22px">
                        {t('I certify that the information submitted is accurate and complete.')}
                      </Inscription>
                    </Checkbox>
                  </Box>
                </StyledBox>
              </ClaimFormWrapper>
            </Box>
          </ContentWrapper>
        </Box>

        <Box
          direction="row"
          justify="center"
        >
          <ContentWrapper
            flex="grow"
            pad={{ horizontal: '40px' }}
            width={{ max: globalTheme.defaultContentWidth }}
          >
            <AcceptFooter
              isBillPayMode={isBillPayMode}
              isLoading={isLoading || isPaymentMethodsLoading || isAccountsLoading}
              onBack={handleOnBack}
              onSave={onSave}
              onCancel={() => {
                if (documentId) {
                  remove(documentId);
                  setFilesDocument({ documentId: '' });
                }
                setShouldConfirmOnClose(false);
                setTimeout(() => history.push(ROUTES.EXPENSE_DETAILS));
              }}
              isAccepted={isAccepted}
              isOcrErrors={isOcrErrors}
              hasAdditionalError={!isCoveragePeriodActive || hasValidationAccountOrdersError}
              isRetryError={isRetryError}
              {...(claimInfo.isReceiptRequired || isDocumentRequired ? { submitButtonTitle: t('Submit Without Receipt') } : {})}
              notification={renderNotification()}
            />
          </ContentWrapper>
        </Box>
      </Box>

      <ModalWrapper
        visible={isAddProviderModalActive || isEditProviderModalActive}
        title={isEditProviderModalActive
          ? `${t('Edit')} ${selectedProvider?.name}`
          : t('Add New Provider/Merchant')}
        onSetVisible={handleCancel}
      >
        <AddProviderForm
          onCancel={handleCancel}
          refetch={refetch}
          {...isEditProviderModalActive ? {
            provider: selectedProvider,
            paymentMethod: selectedPaymentMethodForBillPay,
          } : {}}
          {...(!createdProviderId && !isEditProviderModalActive ? { presetFields } : { })}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={isAddPatientModalActive}
        title={t('Add New Family Member')}
        onSetVisible={handlePatientCancel}
        nonOverflow
      >
        <AddPatientForm
          patientName={scannedDocument?.patientName}
          onCancel={handlePatientCancel}
          onSuccess={handlePatientCancel}
        />
      </ModalWrapper>
    </>
  );
};

export default ClaimInfo;
