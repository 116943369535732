import React, { useCallback, useState } from 'react';

import ROUTES from '@/common/routes';
import { PreventLeaveModal } from '@/modules/core/components';
import { useHistory } from '@/modules/core/hooks';

import {  useInvestorById } from '../hooks';

import PortfolioQuestionnaire from './PortfolioQuestionnaire';
import { ManagedWrapper } from '.';
interface Props {
  accountId: string;

}
const ManagedSwitchQuestionnaire: React.FC<Props> = ({ accountId}) => {
  const [isPreventLeave, setPreventLeave] = useState(true);
  const { data: investor } = useInvestorById({ 
    isSwitchMode: true,
  });

  
  const history = useHistory();
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);
  
  const handleNext= useCallback( async () => {
    setPreventLeave(false);
    history.push(  ROUTES.MANAGED_SWITCH_FUNDING_AMOUNT(accountId), );
   
   
  }, [accountId, history]);
  if(!investor.investorId) {
    return null;
  }
  return (
    <ManagedWrapper currentStep={1}>
      <PreventLeaveModal isPrevent={isPreventLeave} />
      <PortfolioQuestionnaire
        isSwitchMode
        onBack={handleBack}
        onNext={handleNext}
        step={1}
        stepLength={2}
      />
    </ManagedWrapper>
  
  );
};
export default ManagedSwitchQuestionnaire;