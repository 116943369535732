import { useCallback,useEffect } from 'react';

import { BannerStorageKey,DISMISSED_BANNERS_STORAGE_KEY } from '../bannersList.constants';
import { useDismissedBannersStore } from '../stores';

const useDismissBanner = () => {
  const { dismissedBanners, setDismissedBanners } = useDismissedBannersStore();
  const parsedDismissedBanners = localStorage.getItem(DISMISSED_BANNERS_STORAGE_KEY);

  useEffect(() => {
    setDismissedBanners(parsedDismissedBanners ? JSON.parse(parsedDismissedBanners) : []);
  // eslint-disable-next-line
  }, []);

  const handleDismissBanner = useCallback((bannerKey: BannerStorageKey) => {
    const updatedBanners = Array.from(new Set([...dismissedBanners, bannerKey]));
    setDismissedBanners(updatedBanners);
    localStorage.setItem(DISMISSED_BANNERS_STORAGE_KEY, JSON.stringify(updatedBanners));
  }, [dismissedBanners, setDismissedBanners]);

  const checkIsBannerDismissed = useCallback(
    (bannerKey: BannerStorageKey) => dismissedBanners.includes(bannerKey)
      || parsedDismissedBanners?.includes(bannerKey),
    [dismissedBanners, parsedDismissedBanners],
  );

  return {
    handleDismissBanner,
    checkIsBannerDismissed,
    dismissedBanners,
  };
};

export default useDismissBanner;
