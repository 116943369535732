import { useMutation } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ValuesMap } from '@/common/types';

interface Params {
  onSuccess?: (value: number) => void;
  onError?: () => void;
  setInvestmentAmount?: (amount: number) => void;
}
interface FundingPayload {
  amount: string | number;
  amounts?: ValuesMap<number>;
}

export const handleClearAmounts = (currentAmounts: ValuesMap<number>) => {
  const result: ValuesMap<number> = {};
  const keys = Object.keys(currentAmounts);
  keys.forEach((key) => {
    if (currentAmounts[key] > 0) {
      result[key] = currentAmounts[key];
    }
  });
  return result;
};
const useInvestmentFundingQuery = (params?: Params) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (payload: FundingPayload) =>
      api.patch(PATHS.INVESTMENTS_FLOW_FUNDING, _.pickBy({
        amount: _.round(_.toNumber(payload.amount), 2),
        amounts_grouped_by_instrument_ids: payload.amounts ? handleClearAmounts(payload.amounts): undefined,
      })),
    {
      onSuccess: (data) => {
        if (params?.onSuccess) {
          params.onSuccess(data.data?.account?.balance);
        } 
        if (params?.setInvestmentAmount) {
          params.setInvestmentAmount(data.data?.account?.balance);
        }
      },
      onError: () => {
        if (params?.onError) {
          params?.onError();
        } 
      },
    }
  );
  return {
    handleSave: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useInvestmentFundingQuery;
