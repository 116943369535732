export enum MFAStep {
  SEND_CODE = 'SEND_CODE',
  CHANGE_VERIFICATION_METHOD = 'CHANGE_VERIFICATION_METHOD',
}

export enum MFAChannelType {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export interface MFAChannel {
  type?: MFAChannelType;
  value?: string;
  isDefault?: boolean;
}

export interface MFAChannelPayload {
  type?: MFAChannelType;
  value?: string;
  is_default?: boolean;
}

export enum MFAError {
  CODE_IS_INVALID = 'Code is invalid.',
  SESSION_HAS_EXPIRED = 'Your session has expired, please try logging in again.',
}

export const REMEMBER_ME_DEVICE_TOKEN_COOKIE_KEY = 'REMEMBER_ME_DEVICE_TOKEN';
