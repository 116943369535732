import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field,FieldTypes } from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';
import * as yup from 'yup';

import { SPECIFIC_FIELD_REQUIRED_TEXT } from '@/common/constants';

export const useMakeContributionPaymentMethodField = (
  paymentOptions?: Option[],
  onBankAccountAdd?: () => void,
  newBankAccount?: string,
  currentPaymentMethodId?: string,
) => {
  const [bankAccount, setBankAccount] = useState<string | undefined>();
  const { t } = useTranslation();
  const isPaymentMethodsAvailable = useMemo(
    () => !!paymentOptions && paymentOptions.length > 0,
    [paymentOptions],
  );

  useEffect(() => {
    if (newBankAccount) {
      setBankAccount(newBankAccount);
    }
  }, [newBankAccount]);
  const fields: Field[] = useMemo(() => [
    {
      name: 'contributionPaymentMethod',
      type: FieldTypes.AutocompleteDropdown,
      add: onBankAccountAdd,
      label: t('Payment method'),
      placeholder: isPaymentMethodsAvailable ? t('Choose payment method') : t('No available payment methods'),
      options: paymentOptions || [],
      defaultValue: currentPaymentMethodId,
      showRequireIcon: true,
      completeValue: bankAccount,
      resetCompleteValue: () => setBankAccount(undefined),
      validator: yup.string().required(SPECIFIC_FIELD_REQUIRED_TEXT(t('Payment method'))),
      disabled: !isPaymentMethodsAvailable,
    },
  ], [onBankAccountAdd, t, isPaymentMethodsAvailable, paymentOptions, bankAccount, currentPaymentMethodId]);

  return fields;
};
