import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router';
import { Box, ChevronLeftIcon, ChevronRightIcon, Text } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useHasAccess } from '@/modules/core/hooks';
import { CardActionsCarousel } from '@/modules/transaction/components/CardDetails/components/CardActionsCarousel/CardActionsCarousel';
import { CardDto, CardStatusEnum } from '@/modules/transaction/components/Cards/Cards.types';
import { CardItem } from '@/modules/transaction/components/Cards/components/CardItem';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { useGetCardsQuery } from '@/modules/transaction/hooks/useGetCards.query';
import { useSearchParams } from '@/modules/transaction/hooks/useSearchParams';
import { useAuthStore } from '@/modules/user/stores';

import { CardDetailsTable } from '../CardDetailsTable/CardDetailsTable';

import {
  StyledCardItemWrapper,
  StyledCarousel,
  StyledCarouselButton,
  StyledCarouselWrapper,
} from './CardCarousel.styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const CardCarousel: React.FC = () => {
  const { push } = useHistory();
  const { query: cardId } = useSearchParams('card_id');
  const { query: dependentId } = useSearchParams('dependent_id');
  const { user } = useAuthStore();
  const { organizationProperties } = useOrganizationProperties();
  const isEmployeeLimitedAccess = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  const {
    cardMap, isLoading, holderKey,
  } = useGetCardsQuery(user?.employee?.id, dependentId);
  const [activeCard, setActiveCard] = useState<CardDto | undefined | null>(null);

  const cards: CardDto[] = useMemo(() => Object.keys((cardMap[holderKey] || {}))
    .reduce((arr, status) => [
      ...arr,
      ...(cardMap[holderKey][status as CardStatusEnum] || [] as CardDto[]),
    ], [] as CardDto[]), [holderKey, cardMap]);
  const selectedItemIndex = useMemo(
    () => {
      const index = cards.findIndex((card) => card.id === cardId);
      return index > -1 ? index : 0;
    },
    [cardId, cards],
  );

  const goNextHandler = useCallback((type: 'prev' | 'next') => {
    if (!activeCard) return;
    if (type === 'next' && cards[selectedItemIndex + 1]) {
      push(`${ROUTES.CARD_DETAILS}?dependent_id=${dependentId}&card_id=${cards[selectedItemIndex + 1].id}`);
    }

    if (type === 'prev' && cards[selectedItemIndex - 1]) {
      push(`${ROUTES.CARD_DETAILS}?dependent_id=${dependentId}&card_id=${cards[selectedItemIndex - 1].id}`);
    }
  }, [activeCard, cards, push, selectedItemIndex, dependentId]);

  useEffect(() => {
    if (cards.length && selectedItemIndex >= 0) setActiveCard(cards[selectedItemIndex]);
  }, [cardId, cards, isLoading, selectedItemIndex]);

  return (
    <Box background="module" round="moduleRound" pad="spacing24">
      <Box direction="row" justify="between">
        <Text size="xlarge" weight="bold" margin={{ bottom: 'spacing16' }} data-testid="CardCarousel-title">
          Cards: {cards.length}
        </Text>
        <Box direction="row">
          <StyledCarouselButton
            margin={{ right: 'spacing8' }}
            onClick={() => goNextHandler('prev')}
            disabled={!cards[selectedItemIndex - 1]}
          >
            <ChevronLeftIcon size="12px" className="arrow-icon" color="iconPrimary"  />
          </StyledCarouselButton>
          <StyledCarouselButton
            onClick={() => goNextHandler('next')}
            disabled={!cards[selectedItemIndex + 1]}
          >
            <ChevronRightIcon size="12px" className="arrow-icon" color="iconPrimary"/>
          </StyledCarouselButton>
        </Box>
      </Box>
      <StyledCarouselWrapper
        data-testid="CardCarousel-carousel-wrapper"
      >
        <StyledCarousel
          centerMode
          centerSlidePercentage={49}
          renderArrowNext={() => null}
          renderArrowPrev={() => null}
          showIndicators={false}
          showStatus={false}
          selectedItem={selectedItemIndex}
          showThumbs={false}
          className={selectedItemIndex > 0 ? 'passed' : undefined}
        >
          {
            [
              ...cards.map((card, index) => (
                <StyledCardItemWrapper
                  key={card.id}
                  margin="spacing8"
                  onClick={() => push(`${ROUTES.CARD_DETAILS}?dependent_id=${card.holder.dependentId}&card_id=${card.id}`)}
                  round="container1Round"
                  width={{ max: '490px' }}
                >
                  <CardItem
                    borderColor={index === selectedItemIndex ? 'accentActive' : 'canvas'}
                    card={card}
                    logoUrl={organizationProperties?.darkLargeLogoUrl}
                    active={index === selectedItemIndex}
                    fullyMasked={isEmployeeLimitedAccess}
                  />
                </StyledCardItemWrapper>
              )),
              <Box key="empty" />,
            ]
          }
        </StyledCarousel>
        <CardActionsCarousel
          cards={cards}
          selectedItemIndex={selectedItemIndex}
        />
      </StyledCarouselWrapper>
      {
        activeCard && (
          <CardDetailsTable
            card={activeCard}
          />
        )
      }
    </Box>
  );
};
