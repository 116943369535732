import React, { useCallback, useMemo } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import { CommunicationOption } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';

const ProfileSettingOption: React.FC<CommunicationOption> = ({
  key, title, value, isActive, isSelected, onClick
}) => {
  const isClickActive = useMemo(() =>  onClick && !isActive, [onClick, isActive]);

  const handleSelect = useCallback(() => {
    if (isClickActive && onClick) {
      onClick(key);
    }
  }, [key, isClickActive, onClick]);

  return (
    <Box
      data-testid="profile-setting-option"
      pad={{ horizontal: 'spacing16', vertical: 'spacing8' }}
      round="container2Round"
      {...isClickActive ? { onClick: handleSelect } : {}}
      border={{
        color: isActive ? 'accentActive' : isSelected ? 'border' : 'border1',
        size: isActive || isSelected ? '2px' : '1px'
      }}
    >
      <Box>
        <Inscription color="textSecondary" size="small" weight={500}>
          {title}
        </Inscription>
      </Box>
      <Inscription
        margin={{ top: 'spacing4' }}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {/* @ts-ignore */}
        {value || ( <Inscription color="textDisabled">None</Inscription>)}
      </Inscription>
    </Box>
  );
};

export default ProfileSettingOption;
