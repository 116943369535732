import React, { useMemo } from 'react';
import { fromNow, Text } from '@common-fe/common-fe';

interface Props {
  time?: Date;
}
const NotificationTime: React.FC<Props> = ({ time }) => {
  const formatedTime = useMemo(() => {
    if (time) {
      return fromNow(time);
    }
    return '';
  }, [time]);
  return (

    <Text>{formatedTime}</Text>
  );
};

export default NotificationTime;
