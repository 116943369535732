import { FlatTextInput } from '@common-fe/common-fe';
import styled from 'styled-components';

import AppButton from '@/components/controls/AppButton';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacings.spacing24};
  .button {
    margin: 0 13px;
  }

  a {
    @media ${({ theme }) => theme.deviceSize.lessThen.mobileL} {
      font-size: 14px;
    }
  }
`;

export const RedirectButton = styled.button`
  background-color: transparent;
  border: none;
  ${({ theme }) => theme.fonts.text16Bold};
  color: ${({ theme }) => theme.colors.textAccent};
`;

export const FormHelpText = styled.p`
  ${({ theme }) => theme.fonts.text14Medium};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacings.spacing24};

  @media ${({ theme }) => theme.deviceSize.lessThen.mobileL} {
    ${({ theme }) => theme.fonts.text12Medium};
  }
`;

export const FormFooter = styled.p`
  ${({ theme }) => theme.fonts.text16Regular};
  margin-top: ${({ theme }) => theme.spacings.spacing12};
  text-align: center;
  height: 22px;
  a {
    font-weight: bold;
    text-decoration: none;

    @media ${({ theme }) => theme.deviceSize.lessThen.mobileL} {
      font-size: 14px;
    }
  }

  @media ${({ theme }) => theme.deviceSize.lessThen.mobileL} {
    ${({ theme }) => theme.fonts.text14Regular};
    margin-top: ${({ theme }) => theme.spacings.spacing8};
  }
`;

export const FormTitle = styled.h4`
  ${({ theme }) => theme.fonts.text32Bold};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacings.spacing24};
`;

export const SinInAppButton = styled(AppButton)`
  @media ${({ theme }) => theme.deviceSize.lessThen.mobileL} {
    height: ${({ theme }) => theme.spacings.spacing48};
    min-height: ${({ theme }) => theme.spacings.spacing48};
  }
`;

export const SignInTextInput = styled(FlatTextInput)`
  @media ${({ theme }) => theme.deviceSize.lessThen.mobileL} {
    height: ${({ theme }) => theme.spacings.spacing48};
    min-height: ${({ theme }) => theme.spacings.spacing48};
  }
`;
