import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { QUERY_KEY } from '@/modules/investments/queries/useInvestor.query';

import { useInvestor } from '../hooks';

interface Orders {
  instrumentId: string;
  amountCash?: number;
  isFullLiquidation?: boolean,
}

interface RequestPayload {
  instrument_id: number;
  amount_cash?: number;
  quantity?: number;
}

interface Payload {

  amount?: number;
  preOrders?: Orders[];
  isFullLiquidation?: boolean; 
  
}

enum TransactionOperation {
  Sell = 'SELL',
  Buy = 'BUY'
}
interface Params {
  isSellMode?: boolean
}
export default ({isSellMode}:Params)  => {
  const queryClient = useQueryClient();
  const { data: {investorAccountId } }= useInvestor();
  const {
    mutateAsync: handleSave, isLoading,
    isSuccess
  } = useMutation(
    (data: Payload) => api.post(PATHS.TRADES, _.pickBy({
      investor_account_id: _.toNumber(investorAccountId),
      full_liquidation:  data.isFullLiquidation && !data.preOrders ? true: undefined,
      amount: data.isFullLiquidation ? undefined : data.amount ?_.round(data.amount, 2): undefined,
      side: isSellMode ? TransactionOperation.Sell : TransactionOperation.Buy,
      pre_orders: data.preOrders ?  data.preOrders?.reduce(
        (result: RequestPayload[],item) => {

          const clearedItem = _.pickBy( {
            instrument_id: _.toNumber(item.instrumentId),
            amount_cash: item.isFullLiquidation || data.isFullLiquidation ? undefined : _.round(item.amountCash || 0, 2),
            side: isSellMode ? TransactionOperation.Sell : TransactionOperation.Buy,
            full_liquidation: data.isFullLiquidation ? true : !!item.isFullLiquidation,
          }, value => value !== null && value !== undefined);

          if(item.amountCash || item.isFullLiquidation) {
            return [...result, clearedItem as unknown as RequestPayload];
          }
          return result;
        }, []): undefined
    })),
    {
      onSuccess:() => {
        queryClient.invalidateQueries(QUERY_KEY);
      }
    }
  );



  return {
    handleSave,
    isLoading,
    isSuccess
  };
};