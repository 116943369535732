import React from 'react';

import { handleCheckVersion } from '@/utils/hooks/useVersionChecker';

type Props = {
  children: React.ReactNode;
};
type State = {
  hasError: boolean;
};
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    handleCheckVersion();
    return { hasError: true };
  }

  componentDidCatch() {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>Something went wrong.</p>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
