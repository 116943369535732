import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  BankIcon,
  Box,
  RoundDoneIcon,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { ModalWrapper } from '@/components/wrappers';
import BankForm from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DirectDeposit/BankForm';
import { DuplicationErrorModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DublicationErrorModal/DuplicationErrorModal';

const StyledBankIcon = styled(BankIcon)`
  width: ${({ theme }) => theme.spacings.spacing48};
  height: ${({ theme }) => theme.spacings.spacing48};
  fill: ${({ theme }) => theme.colors.button};
`;

export enum ModalSteps {
  BANK_FORM = 'BANK_FORM',
  SUCCESS = 'SUCCESS',
}

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  setCurrentBankAccount?: (value: string) => void;
  modalTitle?: string;
}

export const AddContributionBankAccountModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  setCurrentBankAccount,
}) => {
  const [step, setStep] = useState<ModalSteps>(ModalSteps.BANK_FORM);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const { t } = useTranslation();
  const handleSetVisible = useCallback(() => {
    onSetVisible(false);
    setStep(ModalSteps.BANK_FORM);
  }, [
    onSetVisible,
  ]);

  const handleSetCurrentBankAccount = useCallback((value: string) => {
    if (setCurrentBankAccount) {
      setCurrentBankAccount(value);
      setStep(ModalSteps.SUCCESS);
    }
  }, [setStep, setCurrentBankAccount]);

  return (
    <>
      {step === ModalSteps.BANK_FORM && (
        <ModalWrapper testId="AddContributionBankAccount" visible={visible} onSetVisible={handleSetVisible}>
          <Box align="center" margin={{ bottom: 'spacing24' }}>
            <Box width="xxsmall" alignSelf="center">
              <StyledBankIcon />
            </Box>

            <Text
              size="2xl"
              weight="bold"
              color="textBody"
              margin={{ top: 'small' }}
            >
              Bank Account Setup
            </Text>
          </Box>
          <BankForm
            back={handleSetVisible}
            onAddBankAccount={handleSetCurrentBankAccount}
            backLabel="Cancel"
            onError={() => setIsErrorVisible(true)}
            isContribution
          />
        </ModalWrapper>
      )}

      {step === ModalSteps.SUCCESS && (
        <ModalWrapper testId="AddContributionBankAccount" visible={visible} onSetVisible={handleSetVisible}>
          <Box align="center" pad={{ vertical: 'spacing24' }}>
            <Box width="xxsmall" alignSelf="center">
              <RoundDoneIcon />
            </Box>

            <Text
              size="2xl"
              weight="bold"
              color="textBody"
              margin={{ top: 'spacing12', bottom: 'spacing24' }}
            >
              {t('The Payment Method Was Successfully Added')}
            </Text>
            <AppButton
              type="button"
              onClick={handleSetVisible}
              width="200px"
            >
              {t('Got It!')}
            </AppButton>
          </Box>
        </ModalWrapper>
      )}
      <DuplicationErrorModal
        visible={isErrorVisible}
        close={() => setIsErrorVisible(false)}
      />
    </>
  );
};
