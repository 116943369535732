import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface Payload {
  username: string;
}

const useSendForgotPasswordCodeQuery = () => {
  const { mutateAsync, isLoading, isError } = useMutation(({ username }: Payload) =>
    api.post(PATHS.SEND_FORGOT_PASSWORD_CODE, {
      username,
    })
  );

  return {
    sendForgotPasswordCode: mutateAsync,
    isLoading,
    isError,
  };
};

export default useSendForgotPasswordCodeQuery;
