import { useEffect, useState } from 'react';

import { useInvestorById } from '@/modules/investments/hooks';

const useAllocationInvestmentAmount = () => {
  const { data } = useInvestorById();
  const [value, setValue] = useState('');
  useEffect(() => {
    if(data.initialInvestmentAmount) {
      setValue(`${data.initialInvestmentAmount}`);
    }
  }, [data?.initialInvestmentAmount]);
  return {
    value, setValue
  };
};

export default useAllocationInvestmentAmount;