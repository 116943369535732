import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { useSearchParams } from '@/modules/transaction/hooks/useSearchParams';
import { centeredFieldStyles } from '@/modules/user/components/SignUp/signUp.constants';
import { useAuthStore } from '@/modules/user/stores';
import { isDateInFuture } from '@/utils';

import EditProfileInformationButton from '../components/EditProfileInformationButton';

enum UniqueIdTypes {
  SSN = 'ssn',
  EEID = 'eeid',
  PEID = 'peid',
}

interface Props {
  isOrphanMode?: boolean;
  isDisabledMode?: boolean;
  isError?: boolean;
}

export default ({ isOrphanMode, isDisabledMode, isError }: Props) => {
  const { t } = useTranslation();
  const { query: source } = useSearchParams('source');
  const { query: partner } = useSearchParams('partner');
  const { user } = useAuthStore();
  const email = useMemo(() => user?.preferredEmail, [user]);

  const uniqueIdLabel = useMemo(() => {
    const preparedSource = source?.toLowerCase();
    if (preparedSource === UniqueIdTypes.SSN) {
      return t('SSN');
    }
    if (preparedSource === UniqueIdTypes.EEID) {
      return t('Employee ID');
    }
    if (preparedSource === UniqueIdTypes.PEID && partner) {
      return t(`${partner} ID`);
    }
    if (preparedSource === UniqueIdTypes.PEID) {
      return t('Partner ID');
    }
    return t('Unique ID (typically SSN)');
  }, [partner, source, t]);

  const uniqueIdPlaceholder = useMemo(() => {
    const preparedSource = source?.toLowerCase();
    if (preparedSource === UniqueIdTypes.SSN) {
      return t('SSN');
    }
    if (preparedSource === UniqueIdTypes.EEID) {
      return t('Employee ID');
    }
    if (preparedSource === UniqueIdTypes.PEID && partner) {
      return t(`${partner} ID`);
    }
    if (preparedSource === UniqueIdTypes.PEID) {
      return t('Partner ID');
    }
    return t('Unique ID');
  }, [partner, source, t]);

  const fields = useMemo(() => [
    ...!isOrphanMode ? [{
      name: 'birthdate',
      type: FieldTypes.DateInput,
      label: 'Date of Birth',
      isLarge: true,
      showRequireIcon: true,
      disabled: isDisabledMode,
      placeholder: DEFAULT_DATE_FORMAT,
      maxDate: dayjs(),
      isError: isError,
      validator: yup.string().test({
        test: (val) => {
          if (val) {
            return dayjs(val, DEFAULT_DATE_FORMAT).isValid()
            && !isDateInFuture(val);
          }
          return false;
        },
        message: t('The date is invalid'),
      }).required(t('Date of birth is required')),
      ...centeredFieldStyles,
    },
    {
      name: 'uniqueId',
      type: FieldTypes.Text,
      showRequireIcon: true,
      isLarge: true,
      label: uniqueIdLabel,
      placeholder: uniqueIdPlaceholder,
      isError: isError,
      validator: yup.string().required(t(`${uniqueIdLabel} is required`)),
      disabled: isDisabledMode,
      ...centeredFieldStyles,
    },
    {
      name: 'editProfileButton',
      type: FieldTypes.Node,
      label: '',
      value: (
        <EditProfileInformationButton />
      ),
      ...centeredFieldStyles,
    }
    ] : [
      {
        name: 'email',
        type: FieldTypes.Text,
        label: 'Personal email',
        isLarge: true,
        placeholder: 'Email',
        showRequireIcon: true,
        defaultValue: email,
        validator: yup.string()
          .email('Email must be valid')
          .required(t('Email is required')),
      },
    ],
  ], [isOrphanMode, t, uniqueIdLabel, uniqueIdPlaceholder, isDisabledMode, isError, email]);
  return fields as Field[];
};
