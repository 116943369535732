import { useCallback,useEffect, useState } from 'react';
import { toNumber,toString } from 'lodash';

const useTimer = (storageKey: string) => {
  const isElapsed = (toNumber(localStorage.getItem(storageKey)) || 0) - Date.now() < 0;
  const [isTimeElapsed, setIsTimeElapsed] = useState(isElapsed);
  const [deadlineFromStorage, setDeadlineFromStorage] = useState(localStorage.getItem(storageKey));

  const setTimer = useCallback((expirationTime: number) => {
    const deadline = Date.now() + expirationTime;
    localStorage.setItem(storageKey, toString(deadline));
    setIsTimeElapsed(false);
  }, [storageKey]);

   
  useEffect(() => {
    const deadline = localStorage.getItem(storageKey);

    if (!deadline) {
      setIsTimeElapsed(true);
    }

    if (deadline) {
      const timeLeft = toNumber(deadline) - Date.now();

      if (timeLeft > 0) {
        setIsTimeElapsed(false);
        const timerId = setTimeout(() => {
          setIsTimeElapsed(true);
          localStorage.removeItem(storageKey);
        }, timeLeft);

        return () => clearTimeout(timerId);
      }

      if (timeLeft < 0) {
        localStorage.removeItem(storageKey);
        setIsTimeElapsed(true);
      }
    }
     
  }, [storageKey, setIsTimeElapsed, isTimeElapsed, deadlineFromStorage]);

  // syncing state across multiple tabs
  useEffect(() => {
    const handleStorage = (event: StorageEvent) => {
      if (event.key === storageKey) {
        const newValue = localStorage.getItem(storageKey);
        if (newValue) {
          setDeadlineFromStorage(newValue);
        }
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [storageKey]);

  return {
    isTimeElapsed,
    setTimer,
  };
};

export default useTimer;
