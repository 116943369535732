import React, {
  useCallback, useEffect,
  useMemo,   useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Inscription,
  UploadIcon,
} from '@common-fe/common-fe';
import { toString } from 'lodash';

import { FileImage } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import ScanPreviewContainer from '@/components/controls/DropScan/ScanPreviewContainer';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import { useSnackbar } from '@/modules/core/hooks';
import TransactionFileOnlyUploadPopup from '@/modules/transaction/components/TransactionDetails/TransactionFileOnlyUploadPopup/TransactionFileOnlyUploadPopup';
import {
  NonClaimTransactionData,
  ProcessingStatusType, TransactionApprovalCode,
  TransactionData } from '@/modules/transaction/transaction.types';
import { useEnvSpecificAvailable } from '@/utils';
import { useReversedFeatureFlag } from '@/utils/hooks/useFeatureFlag';

import { TransactionDocumentsPreviewModal } from './TransactionDocumentsPreview/TransactionDocumentsPreviewModal';
import { MissingOcrTransactionInfoModal } from './TransactionFileUploadPopup/MissingOcrTransactionInfoModal';
import { useQrTransactionFileUploadAdditionalActionsStore, useTransactionFileUploadPopupStore } from './TransactionFileUploadPopup/store';
import { SuccessOcrTransactionInfoModal } from './TransactionFileUploadPopup/SuccessOcrTransactionInfoModal';
import { TransactionFileUploadPopup } from './TransactionFileUploadPopup/TransactionFileUploadPopup';
import TransactionActivities from './TransactionNotification/TransactionActivities';
import TransactionProcessing from './TransactionProcessing/TransactionProcessing';
import EmployeeChoice from './EmployeeChoice';
import TransactionCard from './TransactionCard';
import { Content,Wrapper } from './TransactionDetails.styles';
import TransactionPaymentDetails from './TransactionPaymentDetails';
import TransactionStatuses from './TransactionStatuses';
import useFilesByDocumentIdQuery from './useFilesByDocumentId.query';
import useUpdateClaimDocumentIdQuery from './useUpdateClaimDocumentId.query';

interface NonClaimProps {
  data: NonClaimTransactionData;
  postingId: string;
  claimId?: undefined;
  employeeId?: string;
  hasAddDoc?: boolean;
}

interface ClaimProps {
  data: TransactionData;
  claimId: string;
  postingId?: undefined;
  employeeId?: string;
  hasAddDoc?: boolean;
}

type Props = NonClaimProps | ClaimProps;

const TransactionDetails: React.FC<Props> = (props) => {
  const isAvailableOcr = useReversedFeatureFlag('TRANSACTION_OCR_MATCHING', {
    dev: true,
    uat: true,
  });

  const isOnlyForDevAndUAT = useEnvSpecificAvailable({
    dev: true,
    uat: true,
  });
  const [shouldClaimDocumentIdBeUpdated, setShouldClaimDocumentIdBeUpdated] = useState(false);
  const [isClaimDocumentIdUpdated, setIsClaimDocumentIdUpdated] = useState(false);

  const [isFileUploadPopupVisible, setIsFileUploadPopupVisible] = useState(false);
  const [isOnlyFileUploadPopupVisible, setIsOnlyFileUploadPopupVisible] = useState(false);
  const [isScannedFileApprovedModal, setIsScannedFileApprovedModal] = useState(false);
  const [isScannedFileNotApprovedModal, setIsScannedFileNotApprovedModal] = useState(false);

  const { handleSetValues, handleReset, values } = useFilesInDocumentStore();
  const documentId = useMemo(() => (props.claimId ? props.data?.documentId : ''), [props]);
  const { files: claimFiles } = useFilesByDocumentIdQuery(documentId || values.documentId);

  const isAiApproved = useTransactionFileUploadPopupStore((store) => store.aiApproved);
  const isDocumentHasBeenScanned = useTransactionFileUploadPopupStore((store) => store.isDocumentHasBeenScanned);
  const isCancelScanning = useTransactionFileUploadPopupStore((store) => store.isCancelScanning);
  const setIsScanned = useTransactionFileUploadPopupStore((store) => store.handleSetIsDocumentHasBeenScanned);
  const handleResetOcrDocument = useTransactionFileUploadPopupStore((store) => store.handleReset);
  const handleSetOcrClaimId = useQrTransactionFileUploadAdditionalActionsStore((store) => store.handleSetClaimId);

  const [activeFile, setActiveFile] = useState(0);
  const [isDocPreviewModalVisible, setIsDocPreviewModalVisible] = useState(false);
  const [allFiles, setAllFiles] = useState<FileImage[]>([]);
  const [hasFileForPreview, setHasFileForPreview] = useState(false);
  const { t } = useTranslation();
  const addDocButtonRef = useRef(null);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const showInfoMessage = useCallback(() => {
    handleAddPermanentSnackbar({
      text: t('Document added. The claim is about to be processed.'),
      closeIcon: true,
      // state: SnackbarState.positive,
    });
  }, [handleAddPermanentSnackbar, t]);
  const {
    updateClaimDocumentId,
    isSuccess,
    isLoading,
  } = useUpdateClaimDocumentIdQuery(props.claimId);

  const isClaimReadyToUpdate = useMemo(() => 
    !isClaimDocumentIdUpdated
      && values.documentId
      && (!documentId || shouldClaimDocumentIdBeUpdated)
      && !isLoading
      && (!isAvailableOcr || !isOnlyForDevAndUAT)
      && (isDocumentHasBeenScanned || !isAvailableOcr || !isOnlyForDevAndUAT),
  [
    isClaimDocumentIdUpdated,
    isDocumentHasBeenScanned,
    isLoading,
    shouldClaimDocumentIdBeUpdated,
    values.documentId,
    documentId,
    isAvailableOcr,
    isOnlyForDevAndUAT,
  ],
  );

  useEffect(() => {
    if (isClaimReadyToUpdate) {
      updateClaimDocumentId(values.documentId, showInfoMessage);
      setIsClaimDocumentIdUpdated(true);
    }
  }, [
    isClaimReadyToUpdate,
    updateClaimDocumentId,
    values.documentId,
    showInfoMessage,
  ]);

  const hasScanPreview = useMemo(
    () => (props.hasAddDoc
      || isSuccess
      || values.files?.length > 0) && allFiles?.length > 0,
    [props.hasAddDoc, isSuccess, values.files, allFiles],
  );

  useEffect(() => {
    if (isDocumentHasBeenScanned && !isCancelScanning) {
      if (isAiApproved) {
        setIsScannedFileApprovedModal(true);
      } else {
        setIsScannedFileNotApprovedModal(true);
      }
    }
  }, [isAiApproved, isCancelScanning, isDocumentHasBeenScanned, setIsScannedFileApprovedModal, setIsScannedFileNotApprovedModal]);

  useEffect(() => {
    if (values.files?.length && !allFiles?.length) {
      setAllFiles(values.files);
    }
  }, [values.files, allFiles]);
  useEffect(() => {
    if (documentId && !values.documentId) handleSetValues({ documentId: toString(documentId) });
  }, [documentId, handleSetValues, values.documentId]);
  useEffect(() => {
    if (claimFiles?.length && !values.files.length) handleSetValues({ files: claimFiles });
  }, [claimFiles, handleSetValues, values.files.length]);
  useEffect(() => () => {
    handleReset();
    handleResetOcrDocument();
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (props.claimId) {
      handleSetOcrClaimId(props.claimId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.claimId]);

  const currentFileName = useMemo(() => {
    if (allFiles[activeFile] && allFiles[activeFile].path.includes('http')) {
      return new URL(allFiles[activeFile].path).pathname.slice(1);
    }
    return allFiles[activeFile]?.path || '';
  }, [activeFile, allFiles]);
  const decodedFileName = useMemo(() => decodeURIComponent(currentFileName || ''), [currentFileName]);
  const hasTransactionProcessingSection = useMemo(
    () => props?.claimId
      && props?.data
      && props.data.processingStatus === ProcessingStatusType.ActionRequired
      && props.data?.processingSubstatusType === TransactionApprovalCode.CARRIER_CLAIM,
    [props],
  );
  const hasEmployeeChoiceSection = useMemo(
    () => props.claimId
    && props.data
    && props.employeeId
    && props.data.processingSubstatusType === TransactionApprovalCode.WAITING_FOR_FUNDS
    && props.data.processingStatus === ProcessingStatusType.ActionRequired,
    [props],
  );
  
  const renderScanPreviewContainer = useCallback(({
    isPreviewHidden, disableUpload, isRemovingDisabled,
  }: { isPreviewHidden: boolean, disableUpload?: boolean, isRemovingDisabled?: boolean }) => (
    <ScanPreviewContainer
      isPreviewHidden={isPreviewHidden}
      disableUpload={disableUpload}
      isRemovingDisabled={isRemovingDisabled}
      isSmallViewVersion
      isFlex
      defaultFiles={allFiles}
      addFileButtonRef={addDocButtonRef}
      onSetPreviewMode={() => setIsDocPreviewModalVisible(true)}
      onSetActivePage={setActiveFile}
      onSetFiles={(files) => {
        if (!shouldClaimDocumentIdBeUpdated) {
          setShouldClaimDocumentIdBeUpdated(!shouldClaimDocumentIdBeUpdated);
        }
        setAllFiles(files as FileImage[]);
        if (hasFileForPreview !== files.length > 0) {
          setHasFileForPreview(!hasFileForPreview);
        }
      }}
      uploadButton={(
        <AppButton
          testId="uploadDocument"
          rightIcon={<UploadIcon />}
        >
          {t('Upload Document')}
        </AppButton>
      )}
    />
  ), [allFiles, hasFileForPreview, shouldClaimDocumentIdBeUpdated, t]);
  return (
    <Wrapper data-testid="TransactionDetails-wrapper">
      <Box pad="spacing24" background="module" round="moduleRound">
        <Box>
          <Content>
            <Box width="100%" margin={{ right: 'spacing24' }}>
              <TransactionCard
                className="tranaction-card"
                {
                  ...props.claimId
                    ? {
                      invoiceId: props.data?.invoiceId,
                      id: props.data?.id,
                      approvedAmount: props.data?.approvedAmount,
                      paidAmount: props.data?.paidAmount,
                      userName: props.data?.serviceFor.name,
                      userRelationship: props.data?.serviceFor?.relationshipType,
                      serviceFor: props.data?.serviceFor,
                      service: props.data?.service,
                      transactionType: props.data?.paymentType,
                      transactorId: props.data?.providerId,
                      applyDeductibleAmount: props.data?.applyDeductibleAmount,
                      date: props.data?.serviceOn,
                    } : {}
                }
                {
                  ...props.postingId
                    ? {
                      userName: props.data?.planName,
                      date: props.data?.statusDate,
                      id: props.data?.postingId,
                    } : {}
                }
                category={props.data?.category}
                providerName={props.data?.memo}
                amount={props.data?.amount}
                claimId={props.claimId}
                transactionType={props.data?.transactionType || props.data?.paymentType}
                taxYear={props.data?.taxYear}
              />
              {hasTransactionProcessingSection ? (
                <TransactionProcessing
                  claimId={props?.claimId}
                  paymentType={props?.data?.paymentType}
                  processingStatus={props?.data?.processingStatus}
                  providerId={props?.data?.providerId}
                />
              ) : null}
              {hasEmployeeChoiceSection ? (
                <EmployeeChoice employeeId={props.employeeId} claimId={props.claimId} />
              ) : null}
            </Box>
            <TransactionStatuses
              statuses={props.data?.statuses || []}
            />
          </Content>
          <TransactionPaymentDetails
            claimId={props.claimId}
            postingId={props.postingId}
            paymentMethodId={props?.data?.paymentMethodId}
          />
        </Box>
      </Box>
      {props.claimId && (
        <TransactionActivities
          data={props.data}
          // uploadDocumentButton={renderScanPreviewContainer({ isPreviewHidden: true, isRemovingDisabled: true })}
          uploadDocumentButton={isAvailableOcr && isOnlyForDevAndUAT ? (
            <AppButton
              testId="uploadDocument"
              rightIcon={<UploadIcon />}
              onClick={() => setIsFileUploadPopupVisible(true)}
            >
              {t('Upload Document')}
            </AppButton>
          ) : (renderScanPreviewContainer({ isPreviewHidden: true, isRemovingDisabled: true }))}
          // onlyFileUploadButton={(
          //   <AppButton
          //     testId="onlyUploadDocument"
          //     rightIcon={<UploadIcon />}
          //     onClick={() => setIsOnlyFileUploadPopupVisible(true)}
          //   >
          //     {t('Upload Document')}
          //   </AppButton>
          // )}
        />
      )}

      {hasScanPreview && (
        <Box margin={{ top: 'spacing24' }} pad="spacing24" background="module" round="moduleRound">
          <Inscription margin={{ bottom: 'spacing24' }} size="large" lineHeight="22px" weight="bold" color="textTitle">{t('Documents')}</Inscription>
          {
            renderScanPreviewContainer({ isPreviewHidden: false, disableUpload: true, isRemovingDisabled: true })
          }
        </Box>
      )}
      <TransactionDocumentsPreviewModal
        visible={isDocPreviewModalVisible}
        onSetVisible={setIsDocPreviewModalVisible}
        modalTitle={decodedFileName}
      >
        <ScanPreviewContainer
          defaultFiles={allFiles}
          isShowTooltip={false}
          addFileButtonRef={addDocButtonRef}
          onSetActivePage={setActiveFile}
          isActiveFileShown
          disableUpload
          isRemovingDisabled
          activeFile={activeFile}
          onSetFiles={(files) => {
            setAllFiles(files as FileImage[]);
            if (hasFileForPreview !== files.length > 0) {
              setHasFileForPreview(!hasFileForPreview);
            }
          }}
        />
      </TransactionDocumentsPreviewModal>
      <TransactionFileUploadPopup
        visible={isFileUploadPopupVisible}
        handleChangeVisibility={setIsFileUploadPopupVisible}
      />
      {isOnlyFileUploadPopupVisible && (
        <TransactionFileOnlyUploadPopup
          claimId={props?.claimId}
          onSetVisible={setIsOnlyFileUploadPopupVisible}
        />
      )}
      <MissingOcrTransactionInfoModal
        visible={isScannedFileNotApprovedModal && !isFileUploadPopupVisible}
        onSetVisible={(value: boolean) => {
          setIsScannedFileNotApprovedModal(value);
          if (isClaimDocumentIdUpdated && isSuccess) {
            showInfoMessage();
          }
        }}
        onCancel={() => {
          // setIsScannedFileNotApprovedModal(false);
          setIsScanned(false);
          updateClaimDocumentId(values.documentId);
          setIsClaimDocumentIdUpdated(true);
        }}
        onSubmit={() => {
          setIsScannedFileNotApprovedModal(false);
          setIsScanned(false);
          setIsFileUploadPopupVisible(true);
        }}
      />
      <SuccessOcrTransactionInfoModal
        visible={isScannedFileApprovedModal}
        onSetVisible={() => setIsScannedFileApprovedModal(false)}
        onSubmit={() => setIsScannedFileApprovedModal(false)}
      />
    </Wrapper>
  );
};

export default TransactionDetails;
