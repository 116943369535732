// PDF loader

// @ts-ignore
import ADV_PART3 from '@/assets/docs/ADVPart3.pdf';
// @ts-ignore
import DIVIDEND_REINVESTMENT_DISCLOSURE from '@/assets/docs/DRIP Disclosure (2023.10.31).pdf';
// @ts-ignore
import HSA_TERMS_AND_CODITIONS from '@/assets/docs/Elevate HSA Investment Terms and Conditions.pdf';
export default {
  ADV_PART3,
  HSA_TERMS_AND_CODITIONS,
  DIVIDEND_REINVESTMENT_DISCLOSURE
};
