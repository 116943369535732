import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import {
  Box, CalendarIcon, CalendarPickerIcon,   DateRangeDropdown, 
  ListItemBase, 
  OptionKey, 
  Preloader, SearchInput, SelectDropdown,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import {
  ALL_OPTION,
} from '@/common/constants';
import ROUTES from '@/common/routes';
import { RecentActivity } from '@/modules/transaction';
import { useServiceCategoriesQuery } from '@/modules/transaction/components/MyAccounts/ActivitiesList/queries';
import PaginationBarWithMediaQuery from '@/modules/transaction/components/PaginationBar';
import useListPagination from '@/modules/transaction/hooks/useListPagination';
import useTransactionsList from '@/modules/transaction/hooks/useTransactionsList';
import useGetClaimStatusesQuery from '@/modules/transaction/queries/useGetClaimStatuses.query';
import {
  SearchTransactionParams,
  Transaction,
} from '@/modules/transaction/transaction.types';

import TransactionsListHeader from '../TransactionsListHeader/TransactionsListHeader';

import { EmptyTransactionsListPlaceholder } from './EmptyTransactionsListPlaceholder/EmptyTransactionsListPlaceholder';
import {
  AllButton,
  CalendarWrapper,
  Content,
  DropdownInputWrapper,
  Header,
  HeaderContainer,
  HeaderWrapper,
  SearchInputWrapper,
  Wrapper,
} from './TransactionsList.styles';

const CUSTOM_DROPDOWN_HEIGHT = 500;
const BACKLIGHT_DELAY = 2000;
const QUERY_KEY = 'justAddedClaimId';
const headers: ListItemBase[] = [
  {
    key: 'provider',
    title: '',
    flex: 1.5,
  },
  {
    key: 'requestedAmount',
    title: 'Requested Amount',
    flex: 1,
  },
  {
    key: 'paidAmount',
    title: 'Paid Amount',
    flex: 0.8,
  },
  {
    key: 'status',
    title: 'Status',
    flex: 1.2,
  },
  {
    key: 'statusDate',
    title: 'Status Date',
  },
];

const stateAllToggling = (statuses: OptionKey[]) => {
  const minStatusesLength = 2;
  const isStatusAllSelected = statuses[statuses.length - 1] === ALL_OPTION.value;

  if (statuses.length === 0 || isStatusAllSelected) {
    return [ALL_OPTION.value];
  }

  const isStatusAllChangedToAnother = statuses.length === minStatusesLength
    && statuses[0] === ALL_OPTION.value;

  if (isStatusAllChangedToAnother) {
    return statuses.filter((status) => status !== ALL_OPTION.value);
  }

  return statuses;
};

interface Props {
  viewAll?: boolean;
  title?: string;
  onViewAll?: () => void;
  data?: Transaction[];
  total?: number;
  isLoading?: boolean;
  defaultParams?: SearchTransactionParams;
  onChangeParams?: (value: SearchTransactionParams) => void;
}
const TransactionsList: React.FC<Props> = ({
  title,
  viewAll,
  onViewAll,
}) => {
  const [initialData, setInitialData] = useState(false);
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [categories, setCategories] = useState<OptionKey[]>([ALL_OPTION.value]);
  const { statusesWithoutCanceled } = useGetClaimStatusesQuery();
  const { serviceCategories } = useServiceCategoriesQuery();
  const categoriesOptions = useMemo(() => {
    const preparedCategories = serviceCategories.map((category) => ({
      key: category.id,
      value: category.name,
      title: category.description,
    }));

    return [ALL_OPTION, ...preparedCategories];
  }, [serviceCategories]);
  const statusesOptions = useMemo(() => {
    const preparedStatuses = statusesWithoutCanceled.map((status) => ({
      key: status.name,
      value: status.name,
      title: status.description,
    }));

    return [ALL_OPTION, ...preparedStatuses];
  }, [statusesWithoutCanceled]);

  const statusValues = useMemo(() => stateAllToggling(statuses), [statuses]);

  const hasStatus = useMemo(
    () => statusValues.length > 0
      && !statusValues.some((status) => status === ALL_OPTION.value),
    [statusValues],
  );

  const categoryValues = useMemo(() => stateAllToggling(categories), [categories]);

  const hasCategory = useMemo(
    () => categoryValues.length > 0
      && !categoryValues.some((status) => status === ALL_OPTION.value),
    [categoryValues],
  );

  const {
    providerSearchString,
    currentSearch,
    setSearchProvider,
    onChangeDates,
    startDate,
    endDate,
    perPage,
    setPerPage,
    page,
    setPage,
  } = useListPagination();
  const { push } = useHistory();

  const {
    data, total, isLoading, isSuccess,
  } = useTransactionsList({
    page: page - 1,
    size: perPage,
    statuses: (hasStatus ? statusValues.join(', ') : ''),
    ...currentSearch ? { provider_name: currentSearch } : {},
    category_ids: (hasCategory ? categoryValues.join(', ') : ''),
    status_date_from: startDate && dayjs(startDate).startOf('day').format(),
    status_date_to: endDate && dayjs(endDate).endOf('day').format(),
  });

  const { search, pathname } = useLocation();
  const justAddedClaimId = useMemo(() => new URLSearchParams(search).get(QUERY_KEY), [search]);

  const isAnyInputFilled = useMemo(() => providerSearchString.length
    || statuses.length
    || categories.length
    || startDate
    || endDate,
  [categories.length, endDate, providerSearchString.length, startDate, statuses.length]);

  const isInitialData = useCallback(() => (
    (!data.length && !isAnyInputFilled) ? setInitialData(false) : setInitialData(true)),
  [data.length, isAnyInputFilled]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (justAddedClaimId && isSuccess) {
      timer = setTimeout(() => push(pathname), BACKLIGHT_DELAY);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [pathname, justAddedClaimId, isSuccess, push]);

  useEffect(() => {
    if (!data.length && !isLoading) {
      setPage(1);
    }
  }, [data, isLoading, setPage]);

  useEffect(() => {
    isInitialData();
  }, [isInitialData]);
  return (
    <Wrapper data-testid="TransactionsList">
      <HeaderWrapper>
        <HeaderContainer>
          <Header>{title ? t(title) : t('Expenses')}</Header>
          {viewAll && <AllButton onClick={onViewAll}>{t('View All')}</AllButton>}
        </HeaderContainer>
        <SearchInputWrapper>
          <SearchInput
            disabled={!initialData}
            hasSearchingHistory
            value={providerSearchString}
            placeholder={t('Search')}
            onChange={setSearchProvider}
          />
        </SearchInputWrapper>
        <DropdownInputWrapper>
          <SelectDropdown
            disabled={!initialData}
            id="status"
            allMode
            name={t('Activity status')}
            prefix="Status"
            options={statusesOptions}
            values={statusValues}
            onChangeValues={setStatuses}
            customHeight={CUSTOM_DROPDOWN_HEIGHT}
            dropdownStyles={{
              minWidth: '195px',
            }}
          />
        </DropdownInputWrapper>
        <DropdownInputWrapper>
          <SelectDropdown
            disabled={!initialData}
            id="provider"
            allMode
            name={t('Activity provider')}
            prefix="Category"
            options={categoriesOptions}
            values={categoryValues}
            onChangeValues={setCategories}
            dropdownStyles={{
              minWidth: '180px',
            }}
          />
        </DropdownInputWrapper>
        <DropdownInputWrapper>
          <DateRangeDropdown
            ellipsisMode
            disabled={!initialData}
            prefix={t('Period:')}
            onChange={onChangeDates}
            startDate={startDate}
            endDate={endDate}
            icon={(
              <CalendarWrapper disabled={!initialData}>
                {!initialData ? (
                  <CalendarPickerIcon />
                ) : (
                  <CalendarIcon size="16px" color="iconPrimary" />
                )}
              </CalendarWrapper>
            )}
          />
        </DropdownInputWrapper>
      </HeaderWrapper>
      {isLoading && <Preloader />}
      {initialData && !isLoading && (
        <>
          <TransactionsListHeader items={headers} />
          <Content>
            {isLoading ? <Box align="center" justify="center"> <Preloader /></Box>
              : data?.length === 0 && (
                <EmptyTransactionsListPlaceholder title="Expenses" isDescriptionHidden />
              )}
            {data?.map((item: Transaction) => (
              <RecentActivity
                key={item.claimId}
                data={item}
                hasBacklight={item?.claimId === justAddedClaimId}
                onPick={() => push(ROUTES.EXPENSES_TRANSACTION_DETAILS_CLAIM(item?.claimId))}
              />
            ))}
          </Content>
        </>
      )}
      {!isLoading && !initialData ? (
        <EmptyTransactionsListPlaceholder title="Expenses" isDescriptionHidden />
      ) : null}

      {
        total === 0 ? null : (
          <PaginationBarWithMediaQuery
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )
      }
    </Wrapper>
  );
};

export default TransactionsList;
