import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display:flex;
  min-height: 100vh;
`;

export const ContentWrapper = styled(Box)`
  width: 100%; 
  max-width: ${({ theme }) => theme.defaultContentWidth};
  padding: 0px ${({ theme }) => theme.spacings.spacing24};
  

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    padding: 0px 40px;
  }
`;
