export enum FileType {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
  TIFF = 'image/tiff',
}
export const YES_KEY = 'yes';
export const NO_KEY = 'no';
export const FALSE_STRING = 'false';
export const TRUE_STRING = 'true';

export interface FileImage {
  id: string;
  temporalId: string;
  path: string;
  preview: string;
  name: string;
  type: string;
  blob?: Blob | MediaSource;
  url?: string;
}

export const ACCEPTED_FILE_TYPES = `${FileType.JPEG}, ${FileType.JPG}, ${FileType.PNG}, ${FileType.PDF}, ${FileType.HEIC}, ${FileType.HEIF}, ${FileType.TIFF}`;
export const MAX_SIZE_OF_FILE = 10000000;
export const REQUIRED_TEXT = 'The field is required';
export const CUSTOM_DAY_ERROR_TEXT = 'This number does not match the range of available numbers (1-31)';
export const INVALID_TEXT = 'The field is invalid';
export const PHONE_NUMBER_INVALID_TEXT = 'Not a valid 10-digit US phone number';
export const EMAIL_INVALID_TEXT = 'Email must be valid';
export const DATE_INVALID_TEXT = 'Date must be valid';
export const DATE_INVALID_ALTERNATIVE_TEXT = 'Invalid date';
export const CLAIM_AGREEMENT_ERROR_TEXT = 'Please confirm the claim information submitted is accurate and complete.';
export const CLAIM_OCR_ERROR_TEXT = 'Please correct the highlighted errors to submit the claim. ';
export const PASSWORD_ERROR_MESSAGE = 'Password does not meet our minimum security criteria';
export const SPECIFIC_FIELD_REQUIRED_TEXT = (fieldName: string) => `${fieldName} is required`;
export const MAX_LENGTH_TEXT = (count: number) => `This field must be at most ${count} characters`;
export const ACCOUNTS_TYPES = {
  HSA: 'hsa',
  FSA: 'fsa',
};

export const CURRENCY_SIGN = '$';

export const FIRST_DAY_NUMBER = '01';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const FULL_MONTH_DATE_FORMAT = 'MMMM DD, YYYY';
export const ONLY_MONTH_DATE_FORMAT = 'MMMM, YYYY';
export const DEFAULT_TIME_FORMAT = 'HH:MM a';
export const DEFAULT_CARD_EXP_DATE_FORMAT = 'MM/DD/YY';
export const RAW_DATE_FORMAT = 'YYYY-MM-DD';
export const DEBIT_EXP_DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_TIME_FORMATE = 'hh:mm A MMMM DD YYYY';
export const DATE_TOOLTIP_FORMAT = 'D MMMM YYYY';
export const DATE_MONTH_YEAR_FORMAT = 'MM/YY';
export const MINUTES_WITH_SECONDS_FORMAT = 'mm:ss';

export const MINUTE = 60000;
export const SECOND = 1000;
export const DEFAULT_EMPTY_CHARACTER = '—';
export const DAYS_OF_DISABLED_REQUESTS = 7;
export const TWENTIETH_CENTURY = 20;
export const COOKIE_NEVER_EXPIRE = 10 * 365 * 24 * 60 * 60; 

export const INVESTMENTS_ERROR_KEY = {
  EMTPY_AMOUNT_ERROR: 'Enter the funding amount',
  AMOUNT_MORE_THAN_ZERO_ERROR: 'Invested amount should be more than $0.00',
  AGREEMENT_ERROR_TEXT: 'Please confirm that you have read Terms and Conditions and Privacy Policies, ADV Part 2A, ADV Part III (form CSR), Intellicents Discretionary Advisory Agreement, DriveWealth Privacy Policy, W9 Agreement, DriveWealth Agreements and Disclosures',
  INVESTMENT_PATH_ERROR_TEXT: 'Please select your Investment path option',
  NOT_ANSWER_ALL_QUESTIONS_ERROR: 'You have to answer all questions',
};

export const AUTH_ERROR_KEY = {
  WRONG_SSN_OR_DATE: 'Server cannot process request for specified url. Details: Search criteria common cannot be processed for the passed arguments.',
  PHONE_NUMBER: 'Invalid phone number.',
  USERNAME_WORNG_FORMATE: '1000002',
  ACCOUNT_EXIST: 'Details: Application user with ',
  AUTH_ERROR: 'Authorization has failed or has not yet been provided.',
  AUTH_SECOND_ERROR: 'Authentication has failed or has not yet been provided.',
  CODE_IS_WRONG: 'The code is wrong',
  PASSWORD_MUST_MATCH: 'Passwords must match',
  PASSWORD_IS_REQUIRED: 'Password is required',
  USER_NAME_REQUIRED: 'Username is required',
  USER_FORMAT_INVALID: 'Username format is invalid',

};

export const US_STATES = [
  { key: 'Alabama', value: 'Alabama' },
  { key: 'Alaska', value: 'Alaska' },
  { key: 'Arizona', value: 'Arizona' },
  { key: 'Arkansas', value: 'Arkansas' },
  { key: 'California', value: 'California' },
  { key: 'Colorado', value: 'Colorado' },
  { key: 'Connecticut', value: 'Connecticut' },
  { key: 'Delaware', value: 'Delaware' },
  { key: 'District of Columbia', value: 'District of Columbia' },
  { key: 'Florida', value: 'Florida' },
  { key: 'Georgia', value: 'Georgia' },
  { key: 'Hawaii', value: 'Hawaii' },
  { key: 'Idaho', value: 'Idaho' },
  { key: 'Illinois', value: 'Illinois' },
  { key: 'Indiana', value: 'Indiana' },
  { key: 'Iowa', value: 'Iowa' },
  { key: 'Kansas', value: 'Kansas' },
  { key: 'Kentucky', value: 'Kentucky' },
  { key: 'Louisiana', value: 'Louisiana' },
  { key: 'Maine', value: 'Maine' },
  { key: 'Maryland', value: 'Maryland' },
  { key: 'Massachusetts', value: 'Massachusetts' },
  { key: 'Michigan', value: 'Michigan' },
  { key: 'Minnesota', value: 'Minnesota' },
  { key: 'Mississippi', value: 'Mississippi' },
  { key: 'Missouri', value: 'Missouri' },
  { key: 'Montana', value: 'Montana' },
  { key: 'Nebraska', value: 'Nebraska' },
  { key: 'Nevada', value: 'Nevada' },
  { key: 'New Hampshire', value: 'New Hampshire' },
  { key: 'New Jersey', value: 'New Jersey' },
  { key: 'New Mexico', value: 'New Mexico' },
  { key: 'New York', value: 'New York' },
  { key: 'North Carolina', value: 'North Carolina' },
  { key: 'North Dakota', value: 'North Dakota' },
  { key: 'Ohio', value: 'Ohio' },
  { key: 'Oklahoma', value: 'Oklahoma' },
  { key: 'Oregon', value: 'Oregon' },
  { key: 'Pennsylvania', value: 'Pennsylvania' },
  { key: 'Rhode Island', value: 'Rhode Island' },
  { key: 'South Carolina', value: 'South Carolina' },
  { key: 'South Dakota', value: 'South Dakota' },
  { key: 'Tennessee', value: 'Tennessee' },
  { key: 'Texas', value: 'Texas' },
  { key: 'Utah', value: 'Utah' },
  { key: 'Vermont', value: 'Vermont' },
  { key: 'Virginia', value: 'Virginia' },
  { key: 'Washington', value: 'Washington' },
  { key: 'West Virginia', value: 'West Virginia' },
];

export enum Roles {
  agent = 'AGENT',
  admin1 = 'ADMIN_1',
  admin2 = 'ADMIN_2',
  admin3 = 'ADMIN_3',
  claimsProcessor1 = 'CLAIMS_PROCESSOR_1',
  claimsProcessor2 = 'CLAIMS_PROCESSOR_2',
  finOps = 'FIN_OPS',
  employee = 'EMPLOYEE',
  superUser = 'SUPER_USER',
  elevateSuperUser = 'ELEVATE_SUPER_USER',
  employeeLimitedAccess = 'EMPLOYEE_LIMITED_ACCESS',
  noAccess = 'NO_ACCESS',
}

export const AVAILABLE_ROLES = [
  Roles.employee,
  Roles.employeeLimitedAccess,
];

export enum OrganizationTypes {
  company = 'COMPANY',
  partner = 'PARTNER',
  system = 'SYSTEM',
  employee = 'EMPLOYEE',
  employer = 'EMPLOYER',
  distributor = 'DISTRIBUTOR',
  dependent = 'DEPENDENT',
  subsidiary = 'SUBSIDIARY',
  subgroup = 'SUBGROUP',
}

export enum RiskControlOptions {
  CONSERVATIVE = 'CONSERVATIVE',
  MODERATELY_CONSERVATIVE = 'MODERATELY_CONSERVATIVE',
  MODERATE = 'MODERATE',
  MODERATELY_AGGRESSIVE = 'MODERATELY_AGGRESSIVE',
  AGGRESSIVE = 'AGGRESSIVE',
}

export enum CommunicationChannels {
  NOTIFICATION_CENTER = 'NOTIFICATION_CENTER',
  EMAIL_SMS = 'EMAIL_SMS',
}

export enum StockQuoteType {
  VTSMX = 'VTSMX',
  VRIF = 'VRIF',
  SPDR = 'SPDR',
  SCHD = 'SCHD',
  TSLA = 'TSLA',
}

export enum BuySellOptions {
  INVEST_MORE_FUNDS = 'Invest more Funds',
  INVESTMENTS_TO_CASH = 'Investments to Cash',
}

export enum Breakpoints {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const COUNTRY_MAP: { [key: number]: string } = {
  1: 'USA',
};

export const ALL_OPTION = {
  key: 'Null',
  value: 'Null',
  title: 'All',
};

export const LOGO_SIZE = '40px';
export const FAV_SIZE = '16px';

export enum LetterIconSizeEnum {
  LOGO = 'logo',
  FAV = 'fav',
}

export const LARGE_SIZE_LOGO = {
  width: 120,
  height: 40,
};

export enum RequestHeaders {
  preferredEmployeeId = 'Preferred-Employee-Id',
}

export enum UploadFileByQrLinkStorageKey {
  QR_ID_STORAGE_KEY = 'qr_id',
  EMPLOYEE_ID_STORAGE_KEY = 'employee_id',
  VALID_TO_STORAGE_KEY = 'valid_to',
  DOCUMENT_ID_STORAGE_KEY = 'document_id',
}

export enum PartnerMobileAppType {
  NONE = 'NONE',
  ELEVATE_APP = 'ELEVATE_APP',
  PARTNER_OWN_APP = 'PARTNER_OWN_APP',
}

export enum ExternalLink {
  termsAndCondition = 'https://www.elevate.inc/terms-of-use',
  privacyPolicy = 'https://www.elevate.inc/privacy-policy',
}

export const LINKED_EMPLOYEE_STORAGE_KEY = 'LinkedEmployeeStorageKey';
export const ALLOWED_COUNTRIES = {
  233: 'Aland Islands',
  5: 'Albania',
  6: 'Algeria',
  234: 'American Samoa',
  7: 'Andorra',
  8: 'Angola',
  9: 'Anguilla',
  235: 'Antarctica',
  10: 'Antigua and Barbuda',
  11: 'Argentina',
  12: 'Armenia',
  13: 'Aruba',
  14: 'Australia',
  15: 'Austria',
  16: 'Azerbaijan',
  17: 'Bahamas',
  18: 'Bahrain',
  19: 'Bangladesh',
  20: 'Barbados',
  21: 'Belarus',
  22: 'Belgium',
  23: 'Belize',
  24: 'Benin',
  25: 'Bermuda',
  26: 'Bhutan',
  27: 'Bolivia',
  28: 'Bosnia and Herzegovina',
  29: 'Botswana',
  31: 'Brazil',
  32: 'British Indian Ocean Territory',
  226: 'British Virgin Islands',
  33: 'Brunei',
  34: 'Bulgaria',
  35: 'Burkina Faso',
  36: 'Burundi',
  39: 'Cape Verde',
  38: 'Cameroon',
  2: 'Canada',
  40: 'Cayman Islands',
  41: 'Central African Republic',
  42: 'Chad',
  43: 'Chile',
  44: 'China',
  45: 'Christmas Island',
  46: 'Cocos Islands',
  47: 'Colombia',
  48: 'Comoros',
  51: 'Cook Islands',
  52: 'Costa Rica',
  53: 'Cote d\'Ivoire',
  54: 'Croatia',
  55: 'Cuba',
  236: 'Curacao',
  56: 'Cyprus',
  57: 'Czech Republic',
  237: 'Democratic Republic of the Congo',
  58: 'Denmark',
  59: 'Djibouti',
  60: 'Dominica',
  61: 'Dominican Republic',
  62: 'Ecuador',
  63: 'Egypt',
  64: 'El Salvador',
  238: 'Equatorial Guinea',
  65: 'Eritrea',
  66: 'Estonia',
  67: 'Ethiopia',
  68: 'Falkland Islands',
  69: 'Faroe Islands',
  70: 'Fiji',
  71: 'Finland',
  72: 'France',
  74: 'French Polynesia',
  76: 'Gabon',
  77: 'Gambia',
  78: 'Georgia',
  79: 'Germany',
  80: 'Ghana',
  81: 'Gibraltar',
  82: 'Greece',
  83: 'Greenland',
  84: 'Grenada',
  239: 'Guam',
  86: 'Guatemala',
  87: 'Guernsey',
  88: 'Guinea',
  90: 'Guyana',
  93: 'Holy See (Vatican City)',
  94: 'Honduras',
  95: 'Hong Kong',
  96: 'Hungary',
  97: 'Iceland',
  98: 'India',
  99: 'Indonesia',
  102: 'Ireland',
  103: 'Isle of Man',
  104: 'Israel',
  105: 'Italy',
  106: 'Jamaica',
  107: 'Japan',
  108: 'Jersey',
  109: 'Jordan',
  110: 'Kazakhstan',
  111: 'Kenya',
  112: 'Kiribati',
  240: 'Kosovo',
  115: 'Kuwait',
  116: 'Kyrgyzstan',
  118: 'Latvia',
  119: 'Lebanon',
  120: 'Lesotho',
  121: 'Liberia',
  123: 'Liechtenstein',
  124: 'Lithuania',
  125: 'Luxembourg',
  126: 'Macau',
  127: 'Macedonia',
  128: 'Madagascar',
  129: 'Malawi',
  130: 'Malaysia',
  131: 'Maldives',
  132: 'Mali',
  133: 'Malta',
  241: 'Marshall Islands',
  135: 'Mauritania',
  136: 'Mauritius',
  137: 'Mayotte',
  138: 'Mexico',
  242: 'Micronesia',
  139: 'Moldova',
  140: 'Monaco',
  141: 'Mongolia',
  142: 'Montenegro',
  143: 'Montserrat',
  144: 'Morocco',
  145: 'Mozambique',
  243: 'Myanmar',
  146: 'Namibia',
  147: 'Nauru',
  148: 'Nepal',
  149: 'Netherlands',
  150: 'New Caledonia',
  151: 'New Zealand',
  152: 'Nicaragua',
  153: 'Niger',
  154: 'Nigeria',
  155: 'Niue',
  156: 'Norfolk Island',
  244: 'Northern Mariana Islands',
  157: 'Norway',
  158: 'Oman',
  159: 'Pakistan',
  245: 'Palau',
  246: 'Palestinian Territories',
  161: 'Papua New Guinea',
  162: 'Paraguay',
  163: 'Peru',
  164: 'Philippines',
  165: 'Pitcairn Islands',
  166: 'Poland',
  167: 'Portugal',
  247: 'Puerto Rico',
  168: 'Qatar',
  248: 'Resident Alien',
  170: 'Romania',
  171: 'Russia',
  172: 'Rwanda',
  173: 'Saint Barthelemy',
  174: 'Saint Helena',
  175: 'Saint Kitts and Nevis',
  176: 'Saint Lucia',
  177: 'Saint Martin',
  178: 'Saint Pierre and Miquelon',
  179: 'Saint Vincent and the Grenadines',
  180: 'Samoa',
  181: 'San Marino',
  182: 'Sao Tome and Principe',
  183: 'Saudi Arabia',
  184: 'Senegal',
  185: 'Serbia',
  186: 'Seychelles',
  187: 'Sierra Leone',
  188: 'Singapore',
  249: 'Sint Maarten',
  189: 'Slovakia',
  190: 'Slovenia',
  191: 'Solomon Islands',
  193: 'South Africa',
  114: 'South Korea',
  195: 'Spain',
  196: 'Sri Lanka',
  198: 'Suriname',
  199: 'Svalbard',
  200: 'Swaziland',
  201: 'Sweden',
  202: 'Switzerland',
  204: 'Taiwan',
  205: 'Tajikistan',
  206: 'Tanzania',
  207: 'Thailand',
  208: 'Timor-Leste',
  209: 'Togo',
  210: 'Tokelau',
  211: 'Tonga',
  212: 'Trinidad and Tobago',
  213: 'Tunisia',
  214: 'Turkey',
  215: 'Turkmenistan',
  216: 'Turks and Caicos Islands',
  217: 'Tuvalu',
  218: 'Uganda',
  3: 'Ukraine',
  219: 'United Arab Emirates',
  220: 'United Kingdom',
  1: 'United States',
  250: 'United States Minor Outlying Islands',
  221: 'Uruguay',
  251: 'US Virgin Islands',
  222: 'Uzbekistan',
  223: 'Vanuatu',
  225: 'Vietnam',
  227: 'Wallis and Futuna',
  228: 'Western Sahara',
  230: 'Zambia',
  231: 'Zimbabwe',
};
