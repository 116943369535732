import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import { InvestmentActivityStatus } from '@/modules/investments/investments.types';
import colors from '@/styles/colors';

interface Props {
  status: InvestmentActivityStatus;
}

const ActivityStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <Box direction="row" justify="end" align="center" data-testId="Activity_status_id">
      <Text>{t(`${status === InvestmentActivityStatus.FILLED ? 'Completed' : 'Expired'}`)}</Text>
      <Box
        width="10px"
        height="10px"
        margin={{ left: 'xs' }}
        background={status === InvestmentActivityStatus.FILLED
          ? colors.statusColors.paidActiveComplete : colors.statusColors.uploading}
        style={{ borderRadius: '50%' }}
      />
    </Box>
  );
};
export default ActivityStatus;
