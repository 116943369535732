import React from 'react';
import {
  Box, Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import theme from '@/styles/theme';

import DocumentAccordion from './DocumentAccordion';
import DocumentItem, { DocumentItemType } from './DocumentItem';

export interface DocumentItemsGroup {
  title: string;
  items: DocumentItemType[];
}

export interface DocumentsBlocks {
  title: string;
  groups: DocumentItemsGroup[];
}
const Wrapper = styled(Box)`
  ${theme.shadows.default}
`;

const DocumentsBlock: React.FC<DocumentsBlocks> = ({ title, groups }) => (
  <Box>

    <Text size="xxlarge" weight={700} margin={{ bottom: 'm' }}>{title}</Text>

    <Box
      background="module"
      pad="l"
      round="moduleRound"
    >
      <Wrapper
        pad={{
          top: 'l', left: 'l', right: 'l', bottom: 'm',
        }}
        background="canvas"
        round="container1Round"
      >
        {
          groups.map((group) => (
            <DocumentAccordion key={group.title} title={group.title}>
              {
                group.items.map((item) => (
                  <DocumentItem
                    key={item.title}
                    title={item.title}
                    url={item.url}
                    date={item.date}
                  />
                ))
              }
            </DocumentAccordion>

          ))
        }
      </Wrapper>

    </Box>
    <Box margin={{ vertical: 's' }} border={{ side: 'bottom', size: '1px', color: 'border1' }} />
  </Box>
);

export default DocumentsBlock;
