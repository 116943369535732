import styled from 'styled-components';

interface Props {
  active: boolean;
  minify?: boolean;
  border?: boolean;
}
export const Wrapper = styled.button<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: none;
  background-color: ${({ active, theme }) => (active ? theme.colors.accentContainer : 'transparent')};
  color: ${({ active, theme }) => (active ? theme.colors.textAccent : theme.colors.textBody)};
  padding 14px ${({ theme }) => theme.spacings.spacing16};
  margin-right: ${({ theme }) => theme.spacings.spacing2};
  border-radius: ${({ theme }) => theme.spacings.spacing4};
  border: ${({ border, theme }) => (border ? `1px solid ${theme.colors.border2}` : 'transparent')};
`;

export const Title = styled.p<Props >`
  ${({ theme }) => theme.fonts.text14Regular};
  color:  ${({ active, theme }) => (active ? theme.colors.textAccent : theme.colors.textBody)} ;
  overflow: hidden;
  white-space: nowrap;
  transition: visibility 0.5s linear,opacity 0.5s linear;
  transition: width 1s ease-in-out;
`;

export const DropdownContent = styled.div`
  width: 320px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  padding: ${({ theme }) => theme.spacings.spacing12};
  background-color: ${({ theme }) => theme.colors.canvas};
  border-radius: ${({ theme }) => theme.rounds.container2Round} ;
  ${({ theme }) => theme.shadows.active};
`;
