import React, { useCallback,useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Preloader,Text } from '@common-fe/common-fe';

import { CLAIM_AGREEMENT_ERROR_TEXT, CLAIM_OCR_ERROR_TEXT } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';

import ConfirmCancelModal from '../ConfirmCancelModal';
import { ReimburseFooterBackground } from '../ReimburseFooterBackground';

const RETRY_ERROR = 'The server encountered an issue processing your request. Please try again in 2 minutes.';

interface Props {
  notification?: React.ReactNode;
  isLoading?: boolean;
  onBack?: () => void;
  onSave: (isSuccessful: boolean) => void;
  onCancel: () => void;
  isAccepted: boolean;
  isRetryError: boolean;
  submitButtonTitle?: string;
  isBillPayMode?: boolean;
  hasAdditionalError: boolean;
  isOcrErrors?: boolean;
}
const AcceptFooter: React.FC<Props> = ({
  notification,
  isLoading,
  onBack,
  onSave,
  onCancel,
  isAccepted,
  isOcrErrors,
  submitButtonTitle,
  isBillPayMode,
  hasAdditionalError,
  isRetryError,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);

  const handleClick = () => {
    if (onBack) {
      onBack();
    }
  };

  const onSubmit = useCallback(() => {
    setIsSubmitted(true);

    if (isOcrErrors) {
      setError(CLAIM_OCR_ERROR_TEXT);
      onSave(false);
    }

    if (isAccepted && !hasAdditionalError) {
      onSave(true);
    }
    if (!isAccepted) {
      setError(CLAIM_AGREEMENT_ERROR_TEXT);
      onSave(false);
    }
    if (hasAdditionalError) {
      onSave(false);
    }
  }, [isOcrErrors, isAccepted, hasAdditionalError, onSave]);

  useEffect(() => {
    if (isAccepted && error && !isOcrErrors) {
      setError('');
    }
  }, [isAccepted, error, isOcrErrors]);

  return (
    <>
      <Box
        wrap
        direction="column"
        justify="end"
        height={{ min: '88px' }}
        background="module"
        style={{
          zIndex: 1,
        }}
      >
        {error && (
          <Box align="end" width="100%">
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>{t(error)}</Text>
          </Box>
        )}
        {isRetryError && (
          <Box align="end" width="100%">
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>{t(RETRY_ERROR)}</Text>
          </Box>
        )}
        {(isSubmitted || hasAdditionalError) && !error && notification}
        <Box direction="row" justify="between" align="center" width="100%" height="88px">
          <AppButton
            onClick={() => setConfirmCancelVisible(true)}
            width="120px"
            buttonType="tertiary"
            color="red"
          >
            Cancel
          </AppButton>
          <ConfirmCancelModal
            visible={confirmCancelVisible}
            onSetVisible={setConfirmCancelVisible}
            onSubmit={onCancel}
            {...(isBillPayMode
              ? {
                header: t('Cancel Bill Pay?'),
                submitButtonTitle: t('Yes, Cancel Bill Pay'),
              }
              : {
                header: t('Cancel reimbursement?'),
                submitButtonTitle: t('Yes, Cancel Reimbursement'),
              })}
          />
          <Box gap="spacing12" direction="row">
            <AppButton
              onClick={handleClick}
              buttonType="secondary"
              width="control"
            >
              {t('Back')}
            </AppButton>

            <AppButton
              width={!submitButtonTitle || isLoading ? 'control' : 'max-content'}
              {...(isLoading ? {} : { onClick: onSubmit })}
            >
              {isLoading && <Preloader color="white" />}
              {!isLoading && (submitButtonTitle || t('Submit'))}
            </AppButton>
          </Box>
        </Box>
      </Box>
      <ReimburseFooterBackground height={(error || isRetryError || ((isSubmitted || hasAdditionalError) && notification && !error)) ? '157px' : '88px'} />
    </>
  );
};

export default AcceptFooter;
