import { useMemo } from 'react';

import { useMyEnrollments } from '@/modules/transaction/hooks';
import { useAuthStore } from '@/modules/user/stores';

import { useGetOrgLockStatusQuery } from '../queries';

const useShowLockBanner = (): boolean => {
  const { user } = useAuthStore();
  const { isActiveAndReplenished } = useMyEnrollments();
  const orgId = user?.organization?.path?.split('\\')?.[4];
  const { formattedData, isError } = useGetOrgLockStatusQuery(orgId);

  return useMemo(() => !isError && !!formattedData?.isLocked && isActiveAndReplenished, [formattedData, isActiveAndReplenished, isError]);
};

export default useShowLockBanner;
