import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { AllocationPercentsMap, AutoInvestmentRules } from '@/modules/investments/SelfDirected/SelfDirected.types';

import { AutoInvestmentType } from '../AutoInvestment.types';

export const IS_AUTO_QUERY_KEY = 'IS_AUTO_INVESTMENT';

const handleGetPercentMap = (data: AutoInvestmentRules[]) => {
  const newValues: AllocationPercentsMap = {};
  data.forEach((item) => {
    newValues[item.instrument_id] = item.percentage;
  });
  return newValues;
};

interface Response {
  'id': number;
  'account_id': number;
  'investor_account_id': number;
  'amount_to_keep': number;
  'status': string;
  'auto_investment_rules': AutoInvestmentRules[];
}

export default (investorId?: string) => {
  const {
    data, isLoading, isSuccess, isError,
  } = useQuery(
    [IS_AUTO_QUERY_KEY, investorId], () => api.get<Response>(PATHS.INVESTMENT_SCHEDULERS, {
      params: {
        investor_account_id: investorId,
      },
    }),
    {
      retry: 0,
      enabled: !!investorId,
    },
  );
  const formattedData = useMemo(() => ({
    amountToKeep: _.get(data, 'data.amount_to_keep', 0) as number,
    status: _.get(data, 'data.status') as AutoInvestmentType,
    id: `${_.get(data, 'data.id', '')}`,
    autoInvestmentRules: handleGetPercentMap(_.get(data, 'data.auto_investment_rules', [])),

  }), [data]);

  return {
    data: formattedData,
    isLoading,
    isSuccess,
    isError,
  };
};
