import { LinkedEmployee } from '@/modules/user/user.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  linkedEmployee?: LinkedEmployee;
  prevLinkedEmployee?: LinkedEmployee;
  setLinkedEmployee: (linkedEmployee?: LinkedEmployee) => void;
  setPrevLinkedEmployee: (prevLinkedEmployee?: LinkedEmployee) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  linkedEmployee: undefined,
  prevLinkedEmployee: undefined,

  setLinkedEmployee: (linkedEmployee?: LinkedEmployee) => set((state) => ({
    ...state,
    linkedEmployee,
  })),

  setPrevLinkedEmployee: (prevLinkedEmployee?: LinkedEmployee) => set((state) => ({
    ...state,
    prevLinkedEmployee,
  })),

  handleReset: () => set(() => ({
    linkedEmployee: undefined,
    prevLinkedEmployee: undefined,
  })),
}));

const useOrphanModeStore = () => ({ ...useStore() });

export default useOrphanModeStore;
