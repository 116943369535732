import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppButton, Box, Heading } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { useMyEnrollments } from '@/modules/transaction/hooks';
import { useAuthStore } from '@/modules/user';

import { CancelRecurringContributionModal } from './ContributionModals/CancelRecurringContributionModal';
import { ChangeRecurringContributionStatusModal } from './ContributionModals/ChangeRecurringContributionStatusModal';
import { ErrorContributionModal } from './ContributionModals/ErrorContributionModal';
import { MakeContributionModal } from './ContributionModals/MakeContributionModal';
import { SuccessContributionModal } from './ContributionModals/SuccessContributionModal';
import { SuccessRecurringContributionModal } from './ContributionModals/SuccessRecurringContributionModal';
import { WarningModifyContributionModal } from './ContributionModals/WarningModifyContributionModal';
import { useGetRecurringContributionsQuery } from './queries/useGetRecurringContributions.query';
import { RecurringContributions } from './RecurringContributions/RecurringContributions';
import { useStore as useSuccessStore } from './store/useSuccessContributionModal.store';
import { RecurringContribution } from './contribution.types';
import { ContributionAchievementsTracker } from './ContributionAchievementsTracker';
import { ContributionsList } from './ContributionsList';

const CURRENT_YEAR = dayjs().year().toString();

export const AccountContribution = () => {
  const { t } = useTranslation();
  const { accountId } = useParams<{ accountId: string }>();
  const { personId } = useAuthStore();
  const { currentMyAccount } = useMyEnrollments(accountId);
  const { recurringContribution, refetch } = useGetRecurringContributionsQuery({
    accountId,
    personId,
    taxYear: CURRENT_YEAR,
  });
  const handleSetUpdateRecurringContributionView = useSuccessStore((store) => store.handleSetUpdateRecurringContributionView);
  const [showMakeContributionModal, setShowMakeContributionModal] = useState(false);
  const [showWarningContributionModal, setShowWarningContributionModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [
    currentRecurringContribution,
    setCurrentRecurringContribution,
  ] = useState<RecurringContribution | undefined>();

  const editRecurringContribution = useCallback(
    () => {
      setIsEditMode(true);
      setShowMakeContributionModal(true);
    }, [],
  );

  useEffect(() => {
    if (!showMakeContributionModal) {
      setIsEditMode(false);
    }
  }, [showMakeContributionModal]);

  useEffect(() => {
    if (recurringContribution) {
      setCurrentRecurringContribution(recurringContribution);
    } else {
      setCurrentRecurringContribution(undefined);
    }
  }, [recurringContribution]);

  useEffect(() => {
    handleSetUpdateRecurringContributionView(refetch);
  }, [handleSetUpdateRecurringContributionView, refetch]);

  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.MY_ACCOUNTS,
    title: '...',
    value: `${ROUTES.MY_ACCOUNTS}`,
    additionalValue: t('My accounts'),
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: currentMyAccount?.name,
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  },
  {
    key: ROUTES.CONTRIBUTION(accountId),
    title: t('Contributions'),
    value: `${ROUTES.CONTRIBUTION(accountId)}`,
  },
  ], true);

  return (
    <Box data-testid="AccountContribution_container" pad={{ bottom: 'spacing72' }}>
      <Box direction="row" justify="between" margin={{ bottom: 'spacing24' }}>
        <Heading level={3} size="32px" style={{ fontWeight: 700 }}>{t('Contributions')}</Heading>
        <AppButton
          width="190px"
          onClick={() => {
            if (currentRecurringContribution) {
              setShowWarningContributionModal(true);
            } else {
              setShowMakeContributionModal(true);
            }
          }}
          testId="AccountContribution_makeContribution"
        >
          {currentRecurringContribution ? t('Modify Contribution') : 'Make Contribution'}
        </AppButton>

      </Box>
      <Box round="moduleRound" pad="spacing24" background="module" margin={{ bottom: 'spacing24' }}>
        <ContributionAchievementsTracker currentAccount={currentMyAccount} isWithInfoBanner />
      </Box>

      {!!recurringContribution && (
        <Box margin={{ bottom: 'spacing24' }}>
          <RecurringContributions
            recurringContribution={recurringContribution}
            setRecurringContribution={editRecurringContribution}
          />
        </Box>
      )}

      <ContributionsList id={accountId} />

      <MakeContributionModal
        visible={showMakeContributionModal}
        onSetVisible={setShowMakeContributionModal}
        account={currentMyAccount}
        currentRecurringContribution={currentRecurringContribution}
        editMode={isEditMode}
      />

      <WarningModifyContributionModal
        visible={showWarningContributionModal}
        onSetVisible={setShowWarningContributionModal}
        onSubmit={() => {
          setShowWarningContributionModal(false);
          setShowMakeContributionModal(true);
        }}
      />

      <SuccessContributionModal />
      <SuccessRecurringContributionModal />
      <ErrorContributionModal />
      <ChangeRecurringContributionStatusModal />
      <CancelRecurringContributionModal />
    </Box>
  );
};
