import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  RoundDoneIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  header?: string;
  buttonText?: string;
}

export const SuccessUpdateRiskPortfolioModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSubmit,
  header,
  buttonText,
}) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper testId="SuccessUpdateRiskPortfolioModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24' }}>
        <RoundDoneIcon />
        <Text
          data-testid="SuccessUpdateRiskPortfolioModal_modal_header"
          margin={{ top: 'spacing12', bottom: 'spacing24' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {header}
        </Text>

        <AppButton
          testId="SuccessUpdateRiskPortfolioModal_modal"
          onClick={onSubmit}
          width="200px"
        >
          {t(buttonText || '')}
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};
