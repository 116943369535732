import React, { useEffect } from 'react';

import AddPaymentMethodModal from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/components/PaymentMethodModal/AddPaymentMethodModal';
import { useManageProfileModalsStore } from '@/modules/user/components/ManageProfile/stores/useManageProfileModals.store';

const ManageProfileModals = () => {
  const {
    isAddPaymentMethodModalVisible,
    handleSetIsAddPaymentMethodModalVisible,
    handleReset,
  } = useManageProfileModalsStore();

  useEffect(() => {
    return () => {
      handleReset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isAddPaymentMethodModalVisible && (
        <AddPaymentMethodModal onSetVisible={handleSetIsAddPaymentMethodModalVisible} />
      )}
    </>
  );
};

export default ManageProfileModals;
