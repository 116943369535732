import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Hint, Preloader, Text } from '@common-fe/common-fe';

import { BuySellOptions } from '@/common/constants';
import ROUTES from '@/common/routes';
import { InvestmentsType } from '@/common/types';
import AppButton from '@/components/controls/AppButton';
import useBuySell from '@/modules/investments/BuySell/stores/useBuySell.store';

import Documents from '../Documents';
import { useInvestmentsHoldings, useInvestor, useIsActiveInvestorEmpty } from '../hooks';
import useIsFullLiquidation from '../hooks/useIsFullLiquidation';
import useIsModelSwitching from '../hooks/useIsModelSwitching';
import { InvestmentPaths } from '../investments.types';

import { InvestingBannerStatus } from './InvestmentStatusBanner/InvestmentStatusBanner';
import { useInvestmentsFlexList } from './hooks';
import InvestmentsDashboard from './InvestmentsDashboard';
import InvestmentStatusBanner from './InvestmentStatusBanner';
import InvestmentsToggle from './InvestmentsToggle';
import InvestmentsTransactions from './InvestmentsTransactions';

interface Props {
  accountId?: string;
}

const Investments: React.FC<Props> = ({ accountId = '' }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { setBuySellTabState } = useBuySell();
  const [currentTab, setCurrentTab] = useState(InvestmentsType.Dashboard);
  const { cashlessHoldingAmount } = useInvestmentsFlexList(accountId || '');
  const { details, isLoading: isHoldingsLoading } = useInvestmentsHoldings(accountId);

  const {
    data: { type: investorType, type: modelType },
    isLoading: isInvestorLoading,
  } = useInvestor(accountId);
  const currentTitle = useMemo(() => {
    if (currentTab === InvestmentsType.Dashboard) {
      return t('Investments');
    }
    if (currentTab === InvestmentsType.Transactions) {
      return t('Activities');
    }
    if (currentTab === InvestmentsType.Documents) {
      return t('Secured Documents');
    }
    return '';
  }, [currentTab, t]);
  
  const isDisabledSellInvestments = useMemo(() => {
    return !cashlessHoldingAmount;
  }, [cashlessHoldingAmount]);

  const handleSell = useCallback(() => {
    window.scrollTo({ top: 0 });
    if (investorType === InvestmentPaths.SelfDirected) {
      history.push(ROUTES.SELF_DIRECTED_SELL_INVESTMENTS(accountId));
    } else if (investorType === InvestmentPaths.Managed) {
      history.push(ROUTES.MANAGED_SELL_INVESTMENTS(accountId));
    }
  }, [accountId, history, investorType]);
  const handleInvestMore = useCallback(() => {
    window.scrollTo({ top: 0 });
    if (investorType === InvestmentPaths.SelfDirected) {
      history.push(ROUTES.SELF_DIRECTED_INVEST_MORE(accountId));
    } else if (investorType === InvestmentPaths.Managed) {
      setBuySellTabState(BuySellOptions.INVEST_MORE_FUNDS);
      history.push(`${ROUTES.BUY_SELL}?id=${accountId}`);
    }
  }, [accountId, history, investorType, setBuySellTabState]);
  const isModelSwitching = useIsModelSwitching(accountId);
  const isActiveInvestorEmpty = useIsActiveInvestorEmpty(accountId);

  const {
    isFullLiquidation,
    isFullLiquidated,
    isLoading: isBannersLoading,
  } = useIsFullLiquidation({ accountId });

  const isDisabledInvestMore = useMemo(() => {
    return !details?.availableToInvest;
  }, [details?.availableToInvest]);

  const isInvestMoreDisabled = useMemo(() => {
    return isModelSwitching || false;
  }, [isModelSwitching]);

  const currentBannerStatus = useMemo(() => {

    if(isFullLiquidated) {
      return InvestingBannerStatus.Reactivate;
    }
    if(isModelSwitching) {
      return InvestingBannerStatus.Switching; 
    }
  
    return InvestingBannerStatus.Pending;
  }, [isFullLiquidated, isModelSwitching]);
  if (isHoldingsLoading || isInvestorLoading || isBannersLoading) {
    return <Preloader />;
  }

  
  return (
    <Box direction="column" data-testid="Investments-wrapper">
      <Box flex="grow" pad={{ bottom: '40px' }}>
        <InvestmentsToggle
          currentTab={currentTab}
          onSetCurrentTab={setCurrentTab}
          hideHeader={false}
          title={currentTitle}
        >
          {!isFullLiquidated && (
            <Box direction="row" align="center">
              {(!isFullLiquidation || isModelSwitching) && (
                <Box>
                  {isDisabledSellInvestments || isModelSwitching ? (
                    <Hint
                      hintElement={(
                        <AppButton buttonType="secondary" width="160px" disabled>
                          {t('Sell Investments')}
                        </AppButton>
                      )}
                    >
                      <Text size="small" color="textOnColor" textAlign="center">
                        {t('Wait for completing a trade settlement to sell investments.')}
                      </Text>
                    </Hint>
                  ) : (
                    <AppButton buttonType="secondary" onClick={handleSell} width="160px">
                      {t('Sell Investments')}
                    </AppButton>
                  )}
                </Box>
              )}

              <Box margin={{ left: 's' }}>
                {isInvestMoreDisabled ? (
                  <Hint
                    hintWidth={250}
                    hintElement={(
                      <AppButton disabled width="150px">
                        {t('Invest More')}
                      </AppButton>
                    )}
                  >
                    <Text size="small" color="textOnColor" textAlign="center">
                      {t(
                        'Additional money cannot be invested until all pending activity has settled. This may take 2-3 business days.'
                      )}
                    </Text>
                  </Hint>
                ) : isDisabledInvestMore ? (
                  <Hint
                    hintElement={(
                      <AppButton width="150px" disabled>
                        {t('Invest More')}
                      </AppButton>
                    )}
                  >
                    <Text size="small" color="textOnColor" textAlign="center">
                      {t('There is not enough money on your account.')}
                    </Text>
                  </Hint>
                ) : (
                  <AppButton onClick={handleInvestMore} width="150px">
                    {t('Invest More')}
                  </AppButton>
                )}
              </Box>
            </Box>
          )}
        </InvestmentsToggle>
       
  
        {(isFullLiquidation || isModelSwitching || isFullLiquidated) && (
          <Box margin={{ bottom: 'l' }}>
            <InvestmentStatusBanner
              accountId={accountId}
              modelType={modelType}
              status={currentBannerStatus}
            />
          </Box>
        )}

        {currentTab === InvestmentsType.Dashboard && !isModelSwitching && !isActiveInvestorEmpty && !isFullLiquidated && (
          <InvestmentsDashboard />
        )}
        {currentTab === InvestmentsType.Transactions && <InvestmentsTransactions />}
        {currentTab === InvestmentsType.Documents && <Documents />}
      </Box>
    </Box>
  );
};

export default Investments;
