import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { InvestmentPaths } from '../investments.types';

interface InvestmentPathPayload {
  option: InvestmentPaths;
}
interface Params {
  onSuccess: () => void;
  onError: () => void;
}
const useInvestmentsPathQuery = (params?: Params) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    ({ option }: InvestmentPathPayload) =>
      api.patch(
        PATHS.INVESTMENTS_FLOW_PATH,
        {
          option,
        },
        {
          params: {
            terms_accepted: true,
          },
        }
      ),
    {
      onSuccess: params?.onSuccess,
      onError: params?.onError,
    }
  );
  return {
    handleSave: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useInvestmentsPathQuery;
