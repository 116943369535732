import { capitalizeFirstLetter } from '@common-fe/common-fe';

import REGEXP from '@/common/regexp';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import { getFullAddress } from '@/utils';

const getPaymentMethodDescription = (paymentMethod?: PaymentMethod) => {
  const { type } = paymentMethod || {};

  switch (type) {
  case PaymentMethodType.PAYPAL:
    return `${paymentMethod?.requisites?.email} ${paymentMethod?.requisites?.phoneNumber}`;

  case PaymentMethodType.VENMO:
    return `${paymentMethod?.requisites?.phoneNumber}`;

  case PaymentMethodType.CHECK:
    return `${getFullAddress(paymentMethod?.address)}`;

  case PaymentMethodType.DEBIT:
    return `${capitalizeFirstLetter(paymentMethod?.requisites?.cardType || '')} (•••• ${paymentMethod?.requisites?.cardLast4})`;

  case PaymentMethodType.DIRECT_DEPOSIT:
    return `${paymentMethod?.accountDescription} (Bank a/c number ends ${paymentMethod?.accountNumberLast4})`;

  default:
    return '';
  }
};

export const getPaymentMethodDetails = (paymentMethod?: PaymentMethod) => {
  const typeName = capitalizeFirstLetter(paymentMethod?.type || '').replace(REGEXP.DASH_SYMBOL, ' ');
  const paymentMethodDescription = getPaymentMethodDescription(paymentMethod);

  return {
    typeName,
    paymentMethodDescription,
  };
};
