import React, { useEffect } from 'react';
import { Box, FlexControlledForm,useDebounceState } from '@common-fe/common-fe';

import { PersonalInformationPayload,useStore } from '../stores/usePersonalInformation.store';

import { useNetworthTotalFields } from './hooks';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const NetworthTotal: React.FC<Props> = ({ onDirty }) => {
  const fields = useNetworthTotalFields();
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<PersonalInformationPayload>>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round" margin={{ bottom: 'spacing24' }} data-testid="NetworthTotal-wrapper">
      <FlexControlledForm
        shouldControlValues
        fields={fields}
        testId="networth_total_form"
        showError={showErrors}
        editMode
        onChangeValues={setValues}
        onDirty={onDirty}
      />
    </Box>
  );
};
export default NetworthTotal;
