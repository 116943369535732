import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { QUERY_KEY } from '@/modules/transaction/hooks/useMyEnrollments';

export default () => {
  const queryClient = useQueryClient();
  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries(QUERY_KEY);
  }, [queryClient]);
 
  return {

    invalidateQuery,
  };
};
