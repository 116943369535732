import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { api } from '@/api';
import { API_KEY, GATEWAY_KEY } from '@/api/api';
import PATHS from '@/common/paths';
import {
  OcrWarningFields,
  OcrWarnings,
  OcrWarningType,
  ParsedOcrWarnings,
  TransactionParsedFileByOcr,
  TransactionParsedFileByOcrPayload,
} from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';
import { GET_TRANSACTION_DETAILS_BY_CLAIM_ID } from '@/modules/transaction/hooks/useGetTransactionDetails.query';
import { CONFIG } from '@/utils';


export const formatWarnings = (parsedWarnings?: ParsedOcrWarnings[]) => {
  const ocrWarnings: OcrWarnings = {
    [OcrWarningType.MISSING]: [],
    [OcrWarningType.OUT_OF_COVERAGE_DATE]: [],
    [OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE]: [],
    [OcrWarningType.DUPLICATE_RECEIPT]: [],
  };

  if (!parsedWarnings || parsedWarnings.length === 0) {
    return ocrWarnings;
  }

  parsedWarnings.forEach((warning) => {
    const warningFields = warning?.fields?.map((fieldName) => OcrWarningFields[fieldName]);
    if (warningFields && warning?.type) {
      ocrWarnings[warning.type]?.push(...warningFields);
    }
  });

  return ocrWarnings;
};

const formatData = (data: TransactionParsedFileByOcrPayload): TransactionParsedFileByOcr => ({
  aiApproved: data?.ai_approved,
  warningsFields: formatWarnings(data?.warnings),
});

const useGetScannedClaimDocumentQuery = (onSuccess?: () => void) => {
  const controller = useMemo(() => new AbortController(), []);
  const queryClient = useQueryClient();

  const getScannedDocument = async (id: string, claimId?: string) => {
    if (!id) {
      return {};
    }

    try {
      const url = `${CONFIG.BASE_URL?.replace(GATEWAY_KEY, API_KEY)}${PATHS.CLAIM_SCANNED_FILE(id)}`;
      const { data } = await api.get(url, {
        signal: controller.signal,
        params: {
          claim_id: claimId,
        },
      });

      if (onSuccess) {
        onSuccess();
      }

      const formattedData = formatData(data);

      if (formattedData.aiApproved) {
        queryClient.invalidateQueries([GET_TRANSACTION_DETAILS_BY_CLAIM_ID, claimId]);
      }

      return formattedData;
    } catch (error) {
      return {
        aiApproved: false,
      };
    }
  };

  const cancel = useCallback(() => {
    controller.abort();
  }, [controller]);


  return { getScannedDocument, cancel };
};

export default useGetScannedClaimDocumentQuery;
