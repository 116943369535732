import React, {
  useCallback, useEffect, useMemo,
  useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  Box,
  costFormater,
  CrossIcon,
  NavigationRightIcon,
  Preloader,
  PriceInput,
  SuccessModal,
  Text,
} from '@common-fe/common-fe';
import { Enrollment } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import AppButton from '@/components/controls/AppButton';
import ServerErrorModal from '@/modules/investments/modal/ServerErrorModal';
import EnrollmentItem from '@/modules/transaction/components/EnrollmentItem';
import theme from '@/styles/theme';

import { useAutoInvestmentExist } from '../../AutoInvestment/hooks';
import { useInvestmentsHoldings, useInvestmentsValidation } from '../../hooks';
import { useTradeInvestmentsQuery } from '../../queries';
import {
  Title,
} from '../BuySell.styles';
import DisclosureFooter from '../DisclosureFooter';
import { useFundingAmountStore } from '../stores/useFundingAmount.store';

import ConfirmFullLiquidation from './ConfirmFullLiquidation';
import ConfirmSellModal from './ConfirmSellModal';
import FullLIquidationToggle from './FullLiquidationToggle';
import FundingCashCard from './FundingCashCard';

export const PriceControl = styled(PriceInput)`
  ${() => theme.controls.mediumSize};
  min-height: 40px;
`;
interface Props {
  accountId: string;
  onCancel: () => void;
  currentMyAccount: Enrollment;
  
}

const FULL_LIQUIDATION_PERCENTS = 0.95;

const FundingAmount: React.FC<Props> = ({
  onCancel,
  currentMyAccount,
  
  accountId,
}) => {
  const { t } = useTranslation();
  const [isFullLiquidation, setFullLiquidation] = useState(false);

  const { values, handleSetValues } = useFundingAmountStore();
  
  const [investingAmount, setInvestingAmount] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [showServerErrorModal, setShowServerErrorModal] = useState(false);

  const { details, holdingsData} = useInvestmentsHoldings(accountId);
  const [successVisible, setSuccessVisible] = useState(false); 
  const formatedMyAccount = useMemo(() => ({
    ...currentMyAccount,
    available: details.portfolioBalance || 0
  }), [currentMyAccount, details.portfolioBalance]);
  const availableBalance = useMemo(
    () => {
      return formatedMyAccount?.available;
    }, [formatedMyAccount?.available],
  );


  const { isLoading, handleSave } = useTradeInvestmentsQuery({ isSellMode: true});
  const isAppliedFullLiquidation = useMemo(() => {

    const numberAmount = _.toNumber(investingAmount);
    const currentValue =  numberAmount / availableBalance;
    return currentValue >= FULL_LIQUIDATION_PERCENTS;
  }, [availableBalance, investingAmount]);
 
  const handleSellInvestments = useCallback(async () => {
    try {
      await handleSave({
        isFullLiquidation: isFullLiquidation || isAppliedFullLiquidation,
        amount: _.toNumber(investingAmount),
      });

      setSuccessVisible(true);
      handleSetValues({
        fundingAmount: investingAmount,
      });
    } catch {
      setShowServerErrorModal(true);
    }
  }, [handleSave, handleSetValues, investingAmount, isAppliedFullLiquidation, isFullLiquidation]);

  const { error } = useInvestmentsValidation({
    investingAmount: isFullLiquidation ? `${availableBalance}` : investingAmount,
    availableToInvest: availableBalance,
    isSellingMode: true,
    minimumInvestmentAmount: currentMyAccount?.minimumInvestmentAmount,
  });
  const handleSubmit = useCallback(async () => {
    setShowErrors(true);
    if (error) {
      return null;
    }

    
    setSellModalVisible(true);
    return null;
    

    
  }, [error]);

  useEffect(() => {
    if (values?.fundingAmount) {
      setInvestingAmount(values?.fundingAmount);
    }
  }, [values]);




  const { isAutoInvestmentsExist } = useAutoInvestmentExist();
  return (
    <Box direction="column" data-testid="Funding-wrapper-id">
      {(isAppliedFullLiquidation || isFullLiquidation) 
        ?(
          <ConfirmFullLiquidation
            accountId={accountId}
            isLoading={isLoading}
            visible={sellModalVisible && !successVisible}
            onSetVisible={setSellModalVisible}
            onSubmit={handleSellInvestments}
          />
        )
        :(
          <ConfirmSellModal
            fundingAmount={investingAmount}
            isLoading={isLoading}
            visible={sellModalVisible && !successVisible}
            onSetVisible={setSellModalVisible}
            onSubmit={handleSellInvestments}
          />
        )}

      
      {isFullLiquidation || isAppliedFullLiquidation ?(
        <SuccessModal
          header={t('Submitted!')}
          buttonText={t('Go To Investment Dashboard')}
          visible={successVisible}
          onSetVisible={onCancel}
          onSubmit={onCancel}
        />
      ): (
        <SuccessModal
          header={`${costFormater(_.toNumber(values.fundingAmount), true)} ${t('trade settlement submitted')}`}
          helptext={(
            <Text>
              {t('Trade settlement may take up to 2 days.')}{' '}
              {isAutoInvestmentsExist && (
                <Text>
                  {t('Automatic Investments were')}
                  <Text weight="bold">{t(' paused')}.</Text>
                </Text>
              )}
            </Text>
          )}
          buttonText={t('Go to Health Savings Account')}
          visible={successVisible}
          onSetVisible={onCancel}
          onSubmit={onCancel}
        />
      )}
      

      
      <ServerErrorModal
        visible={showServerErrorModal}
        onSetVisible={setShowServerErrorModal}
        isLoading={isLoading}
        onSendRequest={handleSellInvestments}
        style={{zIndex: 10000, position: 'fixed'}}
      />
      <Prompt
        when={!!investingAmount && !successVisible}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Box flex="grow">
        <Box direction="row" align="center" justify="between">
          <Title>
            {t('Sell Investments') }
          </Title>
        
        </Box>
        <Text size="large" margin={{ bottom: 'spacing24' }}>{ t('Move money from your investment account to your cash account in a few easy steps.')}
        </Text>
        <Box
          direction="row"
          align="center"
          background="module"
          round="moduleRound"
          pad="spacing24"
          margin={{ bottom: 'spacing24' }}
        >
          <Box
            data-testid="Enrollment-card-wrapper-id"
            width={{ min: '400px' }}
          >
            {
              formatedMyAccount
            && (
              <EnrollmentItem
                enrollment={formatedMyAccount}
                investingMode
                isDateless
                isHoverCardNumber
                investingToCashMode={true}
              >
                <FundingCashCard
                  data={holdingsData}
                  investmentThreshold={formatedMyAccount.available}
                />
              </EnrollmentItem>
            )
            }
          </Box>
          <Box direction="row" align="center" justify="center" margin={{ horizontal: 'spacing12' }}>
            <NavigationRightIcon />
          </Box>
          <Box
            direction="column"
          >
            <Box
              direction="column"
              pad="l"
              height="248px"
              background="canvas"
              round="container1Round"
              data-testid="invest_more_funds-wrapper"
              border={{ size: 'small', color: 'border2' }}
              elevation="default"
            >
              <Text margin={{ bottom: 'spacing12' }} size="xxlarge">{ t('Sell Investments')}</Text>
              <Text margin={{ bottom: 'spacing24' }} size="medium">{
                t('We will transfer this amount from your investment account to your cash account as soon as the trade is executed.')
              }
              </Text>
              <Box direction="row" align="center">
                <Box width="280px" margin={{ right: 'spacing24' }} data-testid="Price-control-wrapper-id">
                  <PriceControl
                    name="Funding amount"
                    placeholder={t('Enter $ amount')}
                    onChange={setInvestingAmount}
                    onIconClick={() => setInvestingAmount('')}
                    value={isFullLiquidation 
                      ? `${availableBalance || ''}` 
                      : investingAmount}
                    disabled={isLoading ||  isFullLiquidation}
                    rightIcon={(
                      investingAmount !== '' && !isFullLiquidation && (
                        <Box pad={{ left: 'spacing8' }} height="24px" align="center" justify='center' style={{ cursor: 'pointer' }}>
                          <CrossIcon color="iconPrimary" size="12px" />
                        </Box>
                      )
                    )}
                    autoFocus
                  />
                </Box>
                <FullLIquidationToggle
                  isLiquidation={isFullLiquidation}
                  onSetLiquidation={setFullLiquidation}
                  isApplied={isAppliedFullLiquidation} 
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {error && showErrors && (
          <Box align="end" width="100%" margin={{ bottom: 'spacing24' }}>
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>{t(error)}</Text>
          </Box>
        )}
        <Box direction="row" align="center" justify="end">
          <Box direction="row" justify="end" align="center">
            <AppButton
              onClick={() => onCancel()}
              buttonType="secondary"
              width="130px"
              containerStyle={{ marginRight: theme.spacings.s }}
            >
              {t('Cancel')}
            </AppButton>
            <AppButton
              type="submit"
              onClick={() => handleSubmit()}
              disabled={isLoading}
              width="control"
            >
              {
                isLoading 
                  ? <Preloader color="white" />
                  : t('Submit')
              }
            </AppButton>
          </Box>
        </Box>
        <DisclosureFooter /> 
      </Box>
    </Box>
  );
};

export default FundingAmount;
