import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { SignUpPayload } from '../hooks/useSignUpState';

export const useSsoSignUpQuery = (employeeId: string) => {
  const { mutateAsync: singUpSso } = useMutation(
    (payload: SignUpPayload) => api.put<{ id: string }>(PATHS.EMPLOYEE_SSO_SIGNUP(employeeId), {
      new_user_name: payload.username,
      new_password: payload.password,
      confirm_password: payload.confirmPassword,
      personal_email: payload.email,
      is_electronic_statement_only: payload.isElectronicStatementOnly,
    }),
  );

  return { singUpSso };
};
