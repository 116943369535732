// import Auth from '@aws-amplify/auth';
import { getDuration } from '@common-fe/common-fe';
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { RequestHeaders } from '@/common/constants';
import paths, { REFRESH_TOKEN } from '@/common/paths';
import { useStore as useOrphanModeStore } from '@/modules/core/store/useOrphanMode.store';
import CONFIG from '@/utils/config';
import Token from '@/utils/handlers/Token';
import { VERSION_LOCAL_STORAGE_KEY } from '@/utils/hooks/useVersionChecker';

import useStore from '../modules/user/stores/useAuth.store';

export interface RefreshBody {
  accessToken: string;
  refreshToken: string;
}

interface AxiosError {
  response: {
    status: number;
    message: string;
  };
  config: {
    headers: {
      Authorization: string;
    };
  };
}

const VERSION_HEADER = 'X-B3-FE-Version';
export const BEARER = 'Bearer';
export const GATEWAY_KEY = 'gateway';
export const API_KEY = 'api';
const UNAUTHORIZED_STATUS_CODE = 401;

// const handleRefreshToken = async (
//   callback:(newToken: string) => void,
//   errorCallback: () => void,
// ) => {
//   try {
//     const currentSession = await Auth.currentSession();
//     const idToken = currentSession.getIdToken().getJwtToken();
//     await Token.storeToken(idToken);
//     if(!idToken) {
//       errorCallback();
//     }
//     callback(idToken);
//   } catch (e) {
//     errorCallback();
//   }
// };

const axiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
});

axiosInstance.interceptors.request.use(async (request) => {
  request.headers[VERSION_HEADER] = localStorage.getItem(VERSION_LOCAL_STORAGE_KEY) || '';
  request.headers.Authorization = Token.getToken()
    ? `${BEARER} ${Token.getToken() || ''}`
    : undefined;
  return request;
});

export const refreshToken = async () => {
  const refreshToken = Token.getRefreshToken();
  const userName = Token.getUserName();

  if (!refreshToken || !userName) {

    // TODO: The error broke SSO
    // throw new Error();
    return;
  }

  const response = await axiosInstance.post(REFRESH_TOKEN, {
    user_name: userName,
    refresh_token: refreshToken,
  });

  const newAccessToken = response?.data?.access_token;
  const expiresIn = response?.data?.expires_in;

  if (!newAccessToken || !expiresIn) {
    throw new Error();
  }

  Token.storeToken(newAccessToken);
  Token.storeExpiresIn(expiresIn);
};

const forceRefreshToken = async () => {
  const { setAuth } = useStore.getState();
  try {

    await refreshToken();
  } catch {
    Token.clear();
    setAuth(false);
  }
};

forceRefreshToken();
setInterval(() => {
  forceRefreshToken();
  
}, getDuration({ minutes: 4, seconds: 30 }));

export const errorHandler = async (error: AxiosError) => {
  const { setAuth } = useStore.getState();

  if (error?.response?.status === UNAUTHORIZED_STATUS_CODE) {
    // TODO: test completely logout when token expired
    
    // await handleRefreshToken((newToken: string) => {
    //   error.config.headers.Authorization = `${BEARER} ${newToken}`;
    //   axios.request(error.config);
    // }, () => {
    //   Token.clear();
    //   setAuth(false);
    // });
    Token.clear();
    setAuth(false);
  }

  return new Promise((resolve, reject) => {
    reject(error);
  });
};

const isSSOSignupUrl = (url?: string) => {
  if (!url) return false;

  const isSSOSignupUrl = new RegExp(/\/sso-signup/);

  return isSSOSignupUrl.test(url);
};
const isException = (url?: string) => {
  if (!url) return false;

  if (isSSOSignupUrl(url)) return true;

  return false;
};

// add header for orphan mode
axiosInstance.interceptors.request.use(async (request) => {
  if (isException(request.url)) return request;

  const { linkedEmployee } = useOrphanModeStore.getState();
  if (linkedEmployee && request.url !== paths.GET_LINKED_ACCOUNTS) {
    request.headers[RequestHeaders.preferredEmployeeId] = linkedEmployee.employeeId;
  }
  return request;
});

axiosInstance.interceptors.response.use((response) => response, errorHandler);

const mock = new MockAdapter(axiosInstance, { delayResponse: 250, onNoMatch: 'passthrough' });

export { axiosInstance, mock };
