import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

const Wrapper = styled(Box)`

`;

interface Props {
  children: React.ReactNode;
}

const FlexListWrapper: React.FC<Props> = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

export default FlexListWrapper;
