import React, { useMemo } from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { FundManagementOption } from './hooks/useFundManagementOptions';
import { FundsManagementItem } from './FundsManagementItem';

interface Props {
  fundsManagementItems: FundManagementOption[];
}

export const FundsManagementBanner: React.FC<Props> = ({ fundsManagementItems }) => {
  const isOnlyOneItem = useMemo(() => fundsManagementItems?.length === 1, [fundsManagementItems]);

  if (!fundsManagementItems.length) {
    return null;
  }

  return (
    <Box
      data-testid="funds-management-banner"
      margin={{ bottom: 'l' }}
      pad="spacing24"
      background={{ color: 'module' }}
      round="moduleRound"

    >
      <Box
        margin={{ bottom: 'spacing16' }}
        height={{ min: '40px' }}
        justify="center"
      >
        <Text size="16px" weight={700}>
          Funds Management
        </Text>
      </Box>
      <Box gap="spacing16" direction="row">
        {fundsManagementItems?.map((item) => (
          <FundsManagementItem key={item.title} item={item} isOnlyOneItem={isOnlyOneItem} />
        ))}
      </Box>
    </Box>
  );
};
export default FundsManagementBanner;