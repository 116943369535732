import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Field, FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { AUTH_ERROR_KEY } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useSignUpQuery } from '@/modules/user/components/SignUp/Footer/queries/useSignup.query';
import { useSsoSignUpQuery } from '@/modules/user/components/SignUp/Footer/queries/useSsoSignup.query';
import { useAuthStore } from '@/modules/user/stores';

import useSignUpFields from './useSignUpFields';
import useSignUpState from './useSignUpState';

export default (allowed: boolean, isOrphanMode?: boolean) => {
  const { employeeId } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [submitted, setSumbitted] = useState(false);
  const [notFoundUserError, setNotFoundUserError] = useState(false);
  const [userExistError, setUserExistError] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [allowLeave, setAllowLeave] = useState(false);
  const [isSwitchEmployeeModalVisible, setIsSwitchEmployeeModalVisible] = useState(false);
  const {
    state,
    accessAccount,
    profileInformation,
    setShowProfileInformationErrors,
    // setProfileInformationErrors,
    setShowAccessAccountErrors,
    setAccessAccountErrors,
  } = useSignUpState();
  const stateWithOutVerifyMethod = useMemo(() => _.omit(state, 'verifyMethod'), [state]);
  const showConfirm = useMemo(
    () => !_.isEmpty(_.pickBy(stateWithOutVerifyMethod, _.identity)) && !allowLeave,
    [allowLeave, stateWithOutVerifyMethod],
  );
  const fields = useSignUpFields(isOrphanMode);
  const { singUp } = useSignUpQuery();
  const { singUpSso } = useSsoSignUpQuery(employeeId);
  const validationSchema = useMemo(() => {
    const value: FieldValues = {};
    const getErrors = (currentFields: Field<string>[]) => {
      currentFields.forEach((field) => {
        if (field.subFields) {
          getErrors(field.subFields);
        }
        value[field.name] = field.validator;
      });
    };
    getErrors(fields);
    return value;
  }, [fields]);
  useEffect(() => {
    setNotFoundUserError(false);
  }, [profileInformation]);
  useEffect(() => {
    setUserExistError(false);
  }, [accessAccount]);
  useEffect(() => {
    try {
      const schema = yup.object()
        .shape(validationSchema);
      schema.validateSync(state, { abortEarly: true });
      if (!state.verifyMethod) {
        setFormErrors(true);
      } else {
        setFormErrors(false);
      }
    } catch {
      setFormErrors(true);
    }
  }, [state, validationSchema]);
  const handleSaveForm = useCallback(async () => {
    setSumbitted(true);
    setShowProfileInformationErrors(true);
    setShowAccessAccountErrors(true);
    if (!allowed) {
      return;
    }
    if (!state.verifyMethod) {
      return;
    }
    if (formErrors) {
      return;
    }
    try {
      setLoading(true);
      await singUp({
        ...state,
      });
      await setAllowLeave(true);

      setTimeout(() => history.push(`${ROUTES.VERIFY}?email=${state.email}&username=${state.username}`));
    } catch (e) {
      const errorMessage = _.get(e, 'response.data.elevate_error_message', '') as string;
      const errorsList = _.get(e, 'response.data.elevate_error_meta_info.invalid_fields') as unknown as object[];

      if (errorsList) {
        errorsList.forEach((item) => {
          if (_.get(item, 'elevate_error_code') === AUTH_ERROR_KEY.USERNAME_WORNG_FORMATE) {
            setAccessAccountErrors({
              username: state.username,
            });
          }
        });
      } else if (errorMessage.includes(AUTH_ERROR_KEY.ACCOUNT_EXIST)) {
        setUserExistError(true);
      } else {
        // (errorMessage === ERROR_KEY.WRONG_SSN_OR_DATE || errorMessage === ERROR_KEY.AUTH_ERROR)
        setNotFoundUserError(true);
      }
    } finally {
      setLoading(false);
    }

    // setSubmitted(true);
  }, [
    allowed,
    formErrors,
    history,
    singUp,
    setAccessAccountErrors,
    setShowAccessAccountErrors,
    setShowProfileInformationErrors,
    state,
  ]);

  const handleSaveSsoForm = useCallback(async () => {
    setSumbitted(true);
    setShowProfileInformationErrors(true);
    setShowAccessAccountErrors(true);
    if (!allowed) {
      return;
    }
    if (!state.verifyMethod) {
      return;
    }
    if (formErrors) {
      return;
    }
    try {
      setLoading(true);
      await singUpSso({
        ...state,
      });
      await setAllowLeave(true);
      setIsSwitchEmployeeModalVisible(true);
    } catch (e) {
      const errorMessage = _.get(e, 'response.data.elevate_error_message', '') as string;
      const errorsList = _.get(e, 'response.data.elevate_error_meta_info.invalid_fields') as unknown as object[];

      if (errorsList) {
        errorsList.forEach((item) => {
          if (_.get(item, 'elevate_error_code') === AUTH_ERROR_KEY.USERNAME_WORNG_FORMATE) {
            setAccessAccountErrors({
              username: state.username,
            });
          }
        });
      } else if (errorMessage.includes(AUTH_ERROR_KEY.ACCOUNT_EXIST)) {
        setUserExistError(true);
      } else {
        setNotFoundUserError(true);
      }
    } finally {
      setLoading(false);
    }
  }, [
    allowed,
    formErrors,
    setAccessAccountErrors,
    setShowAccessAccountErrors,
    setShowProfileInformationErrors,
    state,
    singUpSso,
  ]);
  return {
    loading,
    setAllowLeave,
    showConfirm,
    formErrors,
    handleSaveForm,
    submitted,
    notFoundUserError,
    userExistError,
    handleSaveSsoForm,
    isSwitchEmployeeModalVisible,
    setIsSwitchEmployeeModalVisible,
  };
};
