import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Preloader,
  RoundPendingIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';

import { RecurringContributionStatus } from '../contribution.types';
import { useUpdateRecurringContributionQuery } from '../queries/useUpdateRecurringContribution.query';
import { useRecurringContributionStore } from '../store';
import { useStore } from '../store/useChangeRecurringContributionStatusModal.store';
import { useStore as useSuccessStore } from '../store/useSuccessContributionModal.store';

export const ChangeRecurringContributionStatusModal = () => {
  const { t } = useTranslation();
  const visible = useStore((store) => store.modalVisible);
  const action = useStore((store) => store.action);
  const onSetVisible = useStore((store) => store.handleChangeVisibility);
  const onSubmit = useStore((store) => store.onSubmit);
  const handleRefetchRecurringContribution = useSuccessStore((store) => store.updateRecurringContributionView);
  const { handleAddPermanentSnackbar } = useSnackbar();

  const contribution = useRecurringContributionStore((store) => store.recurringContribution);

  const { handlePauseOrResume, isChangeStatusLoading } = useUpdateRecurringContributionQuery(contribution?.id);

  const handlePauseResume = useCallback(async () => {
    try {
      await handlePauseOrResume(contribution?.status as RecurringContributionStatus);
      if (handleRefetchRecurringContribution) handleRefetchRecurringContribution();
      onSubmit();
    } catch {
      onSetVisible(false);
      handleAddPermanentSnackbar({
        text: t('Oops! Something went wrong, please try again'),
        closeIcon: true,
        state: SnackbarState.negative,
        minWidth: '454px',
      });
    }
    
  }, [
    contribution?.status,
    handleAddPermanentSnackbar,
    handlePauseOrResume,
    handleRefetchRecurringContribution,
    onSetVisible,
    onSubmit,
    t,
  ]);

  const headerText = useMemo(() => `${action} Recurring Contribution?`, [action]);
  return (
    <ModalWrapper testId="ChangeRecurringContributionStatusModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24', horizontal: 'spacing128' }}>
        <RoundPendingIcon color="warning" />
        <Text
          data-testid="ChangeRecurringContributionStatusModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {headerText}
        </Text>

        <Box direction="row" gap="spacing12">
          <AppButton
            buttonType="secondary"
            testId="ChangeRecurringContributionStatusModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            width="140px"
          >
            Cancel
          </AppButton>
          <AppButton
            testId="ChangeRecurringContributionStatusModal_modal_submit"
            onClick={handlePauseResume}
            width="140px"
          >
            {isChangeStatusLoading
              ? <Preloader />
              : `${action}`}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
