import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getDuration } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import { DAYS_OF_DISABLED_REQUESTS } from '@/common/constants';
import PATHS from '@/common/paths';
import { CardActivationData, CardHoldersResponse, CardStatusEnum } from '@/modules/transaction/components/Cards/Cards.types';
import { useAuthStore } from '@/modules/user/stores';

const QUERY_KEY = 'CARD_HOLDERS';

const useCardHolderQuery = () => {
  const { user } = useAuthStore();
  const {
    isLoading, data, isSuccess,
  } = useQuery(
    [QUERY_KEY, user?.employee?.id],
    () => api.get<CardHoldersResponse>(
      PATHS.CARD_HOLDERS(`employee_id=${user?.employee?.id}`),
    ),
    {
      enabled: Boolean(user?.employee?.id),
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      retry: (failureCount: number, error: any) => error.response.status !== 404,
    },
  );
  const mailedDataOfLastCard = useMemo(
    () => {
      if (!data?.data?.grouped_cards) return undefined;

      return Object.keys(data?.data?.grouped_cards)
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        .map((key) => (data?.data?.grouped_cards as any)[key])
        .flat()
        .sort((a, b) => dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf())
        .pop()?.mailed_on;
    },
    [data],
  );

  const cardActivationData = useMemo(() => {
    const cards = data?.data?.grouped_cards;
    if (!cards) return undefined;

    const readyForActivation = cards[CardStatusEnum.PENDING_OPEN]?.[0];

    if (!readyForActivation || !readyForActivation.mailed_on) return undefined;

    return {
      alternateAccountId: readyForActivation.alternate_account_id,
      memberSequenceIdentifier: readyForActivation.member_sequence_identifier,
      cardId: `${readyForActivation.id}`,
    } as CardActivationData;
  }, [data]);

  const isCardMailed = useMemo(() => {
    if (!mailedDataOfLastCard) {
      return true;
    }

    return dayjs(mailedDataOfLastCard)
      .valueOf() > (dayjs().valueOf() - getDuration({ days: DAYS_OF_DISABLED_REQUESTS }));
  }, [mailedDataOfLastCard]);

  return {
    mailedDataOfLastCard,
    cardActivationData,
    isCardMailed,
    isLoading,
    isSuccess,
  };
};

export default useCardHolderQuery;
