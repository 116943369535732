import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InvestmentOrderType } from '../../investments.types';

export default () => {
  const { t } = useTranslation();
  const getOrderType = useCallback((value?: InvestmentOrderType) => {
    if(!value) {
      return '';
    }
    if(value === InvestmentOrderType.Dividends) {
      return t('Dividend');
    }
    if(value === InvestmentOrderType.MarketBuy) {
      return t('Market Buy');
    }
    if(value === InvestmentOrderType.MarketSell) {
      return t('Market Sell');
    }
    if(value === InvestmentOrderType.Fees) {
      return t('Fee');
    }
    return value;
  }, [t]);
  return getOrderType;
};