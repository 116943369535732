import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Inscription } from '@common-fe/common-fe';

import { MINUTE, SECOND } from '@/common/constants';
import { convertSecondsToMinutes } from '@/utils/modifiers/convertSecondsToMinutes';

const ONE_MINUTE_IN_SECONDS = MINUTE / SECOND;

interface Props {
  onResend: () => void;
  defaultRemainingTime?: number;
}

const ResendCodeButton: React.FC<Props> = ({ onResend, defaultRemainingTime }) => {
  const { t } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime || ONE_MINUTE_IN_SECONDS);
  const [isTimerActive, setIsTimerActive] = useState(remainingTime > 0);

  const handleResend = useCallback(() => {
    setIsTimerActive(true);
    setRemainingTime(ONE_MINUTE_IN_SECONDS);
    onResend();
  }, [onResend]);

  const updateRemainingTime = useCallback((value: number) => {
    const currentRemainingTime = value - 1;
    if (currentRemainingTime <= 0) {
      setIsTimerActive(false);
    }
    return currentRemainingTime;
  }, []);

  useEffect(() => {
    if (isTimerActive) {
      const timer = setInterval(() => {
        setRemainingTime(updateRemainingTime);
      }, SECOND);
  
      return () => {
        clearInterval(timer);
      };
    }
  }, [isTimerActive, updateRemainingTime]);

  return (
    <Inscription
      color={isTimerActive ? 'textDisabled' : 'textAccent'}
      size="small"
      weight={500}
      {...isTimerActive ? {} : { onClick: handleResend, cursor: 'pointer' }}
    >
      {t('Resend code ')} 
      {isTimerActive && `(${convertSecondsToMinutes(remainingTime)})`}
    </Inscription>
  );
};

export default ResendCodeButton;
