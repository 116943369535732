import React, { useCallback,useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  AppButton,   Box, Preloader, Text,
} from '@common-fe/common-fe';
import { toString } from 'lodash';

import { PATHS } from '@/common';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useEmployeeProcessQuery, useUpdateAutoPayPropertiesQuery } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/queries';
import { ProcessingScreenType } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.constants';
import { Point, StyledAppButton, Wrapper } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.styles';
import { useAuthStore } from '@/modules/user/stores';
import { ClaimAutoPayType,ClaimPayee } from '@/modules/user/user.types';
import colors from '@/styles/colors';

import NotificationWrapper from '../../ProcessingNotification/NotificationWrapper';
import TransactionProcessingHeader from '../TransactionProcessingHeader';
import TransactionProcessingImmediately from '../TransactionProcessingImmediately';

const PayeeTypeTitles = {
  [ClaimPayee.Participant]: {
    title: 'Automatically Reimburse Myself',
    description: 'Got it! We will automatically process any new insurance claims and send payment via your default payment method. This option can be changed at any time.',
  },
  [ClaimPayee.Provider]: {
    title: 'Automatically Pay My Provider',
    description: 'We\'ll take it from here! When we receive future insurance claims we will automatically pull the funds from your account and send payment to the provider. This option can be changed at any time.',
  },
  [ClaimPayee.EmployeeChoice]: {
    title: 'Ask me every time',
    description: 'When we receive an insurance claim we will let you know a claim is ready for processing.',
  },
};

interface Props {
  claimId: string;
  notificationMode?: boolean;
  claimAutoPayType?: ClaimAutoPayType;
  providerPaymentMethod?: PaymentMethod;
  defaultClaimPayeeType?: ClaimPayee;
}

const HowToProcessClaimScreen: React.FC<Props> = ({
  notificationMode,
  claimId,
  claimAutoPayType,
  providerPaymentMethod,
  defaultClaimPayeeType,
}) => {
  const { user } = useAuthStore();
  const queryClient = useQueryClient();
  const selectedPaymentMethod = useStore((state) => state.selectedMethod);
  const [isNotificationMode, setIsNotificationMode] = useState(notificationMode);
  const [claimPayee, setClaimPayee] = useState<ClaimPayee | undefined>(defaultClaimPayeeType);
  const [isAskEveryTimeChecked, setIsAskEveryTimeChecked] = useState(
    claimAutoPayType === ClaimAutoPayType.Click,
  );

  const employeeId = useMemo(() => toString(user?.employee?.id), [user]);
  const showImmediately = useMemo(() => claimPayee === ClaimPayee.Participant, [claimPayee]);

  const { employeeProcess, isLoading: isEmployeeProcessLoading } = useEmployeeProcessQuery(claimId);
  const { updateAutoPayProperties, isLoading } = useUpdateAutoPayPropertiesQuery(employeeId);

  const handleSetPayeeType = useCallback((option: ClaimPayee) => {
    setClaimPayee(option);
    if (notificationMode) {
      setIsAskEveryTimeChecked(false);
    }
  }, [notificationMode]);

  const handleSetAskEveryTimeChecked = useCallback((isChecked: boolean) => {
    setIsAskEveryTimeChecked(isChecked);
    if (notificationMode) {
      setClaimPayee(undefined);
    }
  }, [notificationMode]);

  const handleSubmit = useCallback(async () => {
    if (!notificationMode && claimPayee) {
      employeeProcess({
        payToType: claimPayee,
        paymentMethodId: claimPayee === ClaimPayee.Participant
          ? selectedPaymentMethod?.id : providerPaymentMethod?.id,
        transactMethod: claimPayee === ClaimPayee.Participant
          ? selectedPaymentMethod?.type : providerPaymentMethod?.type,
      });
    }
    await updateAutoPayProperties({
      claimAutoPayType: isAskEveryTimeChecked ? ClaimAutoPayType.Click : ClaimAutoPayType.Auto,
      defaultClaimPayeeType: isAskEveryTimeChecked ? ClaimPayee.EmployeeChoice : claimPayee,
    });
    queryClient.invalidateQueries(PATHS.EMPLOYEE_INFO(employeeId));
    setIsNotificationMode(true);
  }, [employeeProcess, updateAutoPayProperties, selectedPaymentMethod,
    claimPayee, isAskEveryTimeChecked, queryClient, providerPaymentMethod,
    notificationMode, employeeId]);

  const isDisableToSubmit = useMemo(() => {
    if (!notificationMode && !claimPayee) return true;
    return notificationMode && !claimPayee && !isAskEveryTimeChecked;
  }, [claimPayee, notificationMode, isAskEveryTimeChecked]);

  const submitButtonTitle = useMemo(() => {
    if (isLoading || isEmployeeProcessLoading) return <Preloader />;
    if (isDisableToSubmit) return 'Select an Option';
    return notificationMode ? 'Save' : 'Submit';
  }, [isLoading, isEmployeeProcessLoading, notificationMode, isDisableToSubmit]);

  const description = useMemo(() => {
    if (isAskEveryTimeChecked) return PayeeTypeTitles[ClaimPayee.EmployeeChoice]?.description;
    if (claimPayee) return PayeeTypeTitles[claimPayee]?.description;
    return undefined;
  }, [isAskEveryTimeChecked, claimPayee]);

  if (isNotificationMode) {
    return (
      <NotificationWrapper
        testId="how-to-process-claim-notification"
        title="Health plan claims: "
        value={defaultClaimPayeeType ? PayeeTypeTitles[defaultClaimPayeeType]?.title : ''}
      >
        <Box direction="row">
          <Point />
          <Box
            data-testId="show-screen-button"
            onClick={() => setIsNotificationMode(false)}
          >
            <Text color={colors.textAccent} size="large">
              Edit
            </Text>
          </Box>
        </Box>
      </NotificationWrapper>
    );
  }

  return (
    <Wrapper
      data-testId="how-to-process-claim-screen"
      pad="xl"
      background="canvas"
      border={{ size: 'small', color: 'border2' }}
    >
      <TransactionProcessingHeader
        processingScreenType={ProcessingScreenType.HowToProcessPlanClaims}
      />
      <Box margin={{ top: 'spacing24' }}>
        <Box>
          <Box direction="row" justify="between" gap="spacing12">
            <Box basis="1/2">
              <StyledAppButton
                testId="select-participant"
                width="100%"
                size="L"
                buttonType="secondary"
                active={claimPayee === ClaimPayee.Participant}
                onClick={() => handleSetPayeeType(ClaimPayee.Participant)}
              >
                Automatically Reimburse Me
              </StyledAppButton>
            </Box>
            <Box basis="1/2">
              <StyledAppButton
                testId="select-provider"
                width="100%"
                size="L"
                buttonType="secondary"
                active={claimPayee === ClaimPayee.Provider}
                onClick={() => handleSetPayeeType(ClaimPayee.Provider)}
              >
                Automatically Pay My Provider
              </StyledAppButton>
            </Box>
          </Box>

          <Box margin={{ top: 'spacing12' }}>
            <StyledAppButton
              testId="ask-my-every-time"
              width="100%"
              size="L"
              buttonType="secondary"
              active={isAskEveryTimeChecked}
              onClick={() => handleSetAskEveryTimeChecked(!isAskEveryTimeChecked)}
            >
              Ask Me Every Time
            </StyledAppButton>
          </Box>
        </Box>
        {
          (claimPayee || isAskEveryTimeChecked) && (
            <Box
              margin={{ top: 'spacing24' }}
              pad={{ vertical: 'spacing24' }}
              border={{ side: 'horizontal', size: '1px', color: 'border1' }}
            >
              <Text weight={700} size="16px">
                {description}
              </Text>
            </Box>
          )
        }
        {
          showImmediately && (
            <Box
              data-testId="immediately-wrapper"
              pad={{ top: 'spacing24' }}
            >
              <TransactionProcessingImmediately />
            </Box>
          )
        }
        <Box margin={{ top: 'spacing24' }}>
          <AppButton
            testId="submit"
            size="L"
            width="100%"
            disabled={isDisableToSubmit}
            onClick={handleSubmit}
          >
            {submitButtonTitle}
          </AppButton>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default HowToProcessClaimScreen;
