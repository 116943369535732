import React, { useCallback } from 'react';
import { Controller,useForm } from 'react-hook-form';
import {
  AppButton,
  Box,   FlatTextInput,
  Form,
} from '@common-fe/common-fe';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';

import { EMAIL_INVALID_TEXT } from '@/common/constants';
import regexp from '@/common/regexp';

import useForgotUsernameQuery from './useForgotUsername.query';

export const EMAIL_FIELD_KEY = 'email';

interface ForgotUsernameProps {
  onNext: (value?: object) => void;
}
export const REQUIRED_TEXT = 'This field is required';

const ForgotUsername: React.FC<ForgotUsernameProps> = ({ onNext }) => {
  const mutateAsync = useForgotUsernameQuery(onNext);
  const {
    control, formState: { errors }, handleSubmit,
  } = useForm({
    defaultValues: {
      [EMAIL_FIELD_KEY]: '',
    },
    // @ts-ignore 
    resolver: yupResolver(yup.object().shape({
      [EMAIL_FIELD_KEY]: yup.string().test({
        test: (val) => {
          if (val) return regexp.EMAIL.test(val);
          return true;
        },
        message: EMAIL_INVALID_TEXT,
      }).required(REQUIRED_TEXT),
    })),
  });

  const handleSubmitForgotUsername = useCallback(async (value: object) => {
    const email = _.get(value, EMAIL_FIELD_KEY, '') as string;
    await mutateAsync(email);
    onNext({ email });
  }, [mutateAsync, onNext]);
  return (
    <Form autoComplete="off" onSubmit={handleSubmit(handleSubmitForgotUsername)}>
      <Box dir="column">
        <Controller
          name={EMAIL_FIELD_KEY}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FlatTextInput
              value={value}
              onChange={onChange}
              className="text-input"
              testId="text-input-email"
              defaultAutoComplete="off"
              name={EMAIL_FIELD_KEY}
              placeholder="Enter your Email"
              errorText={_.get(errors, `${EMAIL_FIELD_KEY}.message`)}
              style={{
                minHeight: '54px',
              }}
              isLowerCaseMode
            />
          )}
        />
        <Box margin={{ top: 'spacing32' }}>
          <AppButton
            testId="forgot-username-continue"
            type="submit"
            width="100%"
            size="L"
          >
            Continue
          </AppButton>
        </Box>
      </Box>
    </Form>
  );
};

export default ForgotUsername;
