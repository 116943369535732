import React, { useMemo } from 'react';
import { ListItem, ListItemType, Text } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';

import { EmployerTerminationPlan, useGetEmployerTerminationPlansListQuery } from '../queries/useGetEmployerTerminationPlansList.query';

const addDaysToDate = (dateString?: string, days = 0) => {
  if (!dateString) return '';
  let date = dayjs(dateString, DEFAULT_DATE_FORMAT);
  date = date.add(days, 'day');
  const formattedDate = date.format(DEFAULT_DATE_FORMAT);

  return formattedDate;
};

export const useEmployerTerminationPlanList = (
  orgId?: string,
  terminationDate?: string,
  customEndOfCoverage?: string | null,
  customLastDayToSubmitClaim?: string | null,
) => {
  const {
    formattedData,
    isLoading,
    isHsaAccountExist,
    hsaStartDate,
  } = useGetEmployerTerminationPlansListQuery(orgId, terminationDate);

  const formattedFields = useMemo(() => {
    const formatted = formattedData.map((item: EmployerTerminationPlan) => ({
      id: `${item.id}`,
      fields: [
        {
          key: 'planName',
          type: ListItemType.Node,
          hasTip: true,
          value: item.name,
          title: item.name,
          flex: 1.8,
          node: (
            <Text
              title={item.name}
              size="medium"
              weight="bold"
              color="textBody"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item.name}
            </Text>
          ),
        },

        {
          key: 'planStartDate',
          type: ListItemType.Text,
          value: item.startDate,
          title: item.startDate,
          fieldStyle: { alignItems: 'flex-start', flex: '0.8' },
        },
        {
          key: 'planEndDate',
          type: ListItemType.Text,
          value: item.endDate,
          title: item.endDate || '-',
          fieldStyle: { alignItems: 'flex-start', flex: '1' },
        }
      ],
    }));
    return formatted;
  }, [
    formattedData,
  ]);

  const formattedCoverageFields = useMemo(() => {
    const formatted = formattedData.map((item: EmployerTerminationPlan) => ({
      id: `${item.id}`,
      fields: [
        {
          key: 'planName',
          type: ListItemType.Node,
          hasTip: true,
          value: item.name,
          title: item.name,
          flex: 1.8,
          node: (
            <Text
              title={item.name}
              size="medium"
              weight="bold"
              color="textBody"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item.name}
            </Text>
          ),
        },
        {
          key: 'endOfCoverage',
          type: ListItemType.Text,
          value: customEndOfCoverage || addDaysToDate(terminationDate, item.gracePeriodDaysAmount),
          title: customEndOfCoverage || addDaysToDate(terminationDate, item.gracePeriodDaysAmount),
          fieldStyle: { alignItems: 'flex-start', flex: '0.8' },
        },
        {
          key: 'lastDayToSubmitClaim',
          type: ListItemType.Text,
          value: customLastDayToSubmitClaim || item.claimLastDate || '-',
          title: customLastDayToSubmitClaim || item.claimLastDate || '-',
          fieldStyle: { alignItems: 'flex-start', flex: '1' },
        }
      ],
    }));
    return formatted;
  }, [
    formattedData,
    terminationDate,
    customEndOfCoverage,
    customLastDayToSubmitClaim,
  ]);

  return {
    data: formattedFields as ListItem[],
    coverageData: formattedCoverageFields as ListItem[],
    isLoading,
    isHsaAccountExist,
    hsaStartDate,
  };
};
