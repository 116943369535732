import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FlexControlledForm,
  LinkButton,
  useDebounceState,
} from '@common-fe/common-fe';

import RiskModal from '@/modules/investments/RiskModal';

import { InvestmentProfilePayload,useStore } from '../stores/useInvestmentProfile.store';

import { useToleranceModelFields } from './hooks';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const ToleranceModel: React.FC<Props> = ({ onDirty }) => {
  const { t } = useTranslation();
  const fields = useToleranceModelFields();
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const [openRiskModal, setRiskModal] = useState<boolean>(false);

  const { setValues, values } = useDebounceState<Partial<InvestmentProfilePayload>>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round" margin={{ bottom: 'l' }} style={{ position: 'relative' }} data-testid="ToleranceModel-wrapper">
      <Box style={{ position: 'absolute', right: '32px', top: '24px' }}>
        <LinkButton onClick={() => setRiskModal(true)}>
          {t('Read More About Risk Categories')}
        </LinkButton>
      </Box>
      <FlexControlledForm
        formTitle={t('Risk Categories')}
        fields={fields}
        shouldControlValues
        testId="tolerance_model_form"
        showError={showErrors}
        editMode
        onChangeValues={setValues}
        onDirty={onDirty}
      />
      <RiskModal
        visible={openRiskModal}
        onSetVisible={(value) => setRiskModal(value)}
        isAllRiskActive
      />
    </Box>
  );
};
export default ToleranceModel;
