/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box,FontIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

interface WrapperProps {
  isSmallViewVersion?: boolean;
  isFlex?: boolean;
}

export const Wrapper = styled(Box)<WrapperProps>`
  ${({ isSmallViewVersion }) => (isSmallViewVersion
    ? `display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;`
    : `>div:not(:last-child) {
      margin-bottom: 8px;
    }`)}
    ${({ isFlex }) => (isFlex ? 'display: flex' : undefined)}
`;

export const ImgSquare = styled.img`
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const hoverShadow = ({ theme }: any) => theme.shadows.hover['box-shadow'];
const hoverShadowBorder = (props: any) => `0px 0px 0px ${props.isScanUpload ? '2px' : '1px'} ${props.theme.colors.accentActive} inset`;
const activeShadowBorder = (props: any) => `0px 0px 0px ${props.active ? '2px' : '0'} ${
  props.theme.colors.accentActive
} inset`;

export const ImgThumbOuter = styled.div<{
  active: boolean;
  isScanUpload?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.canvas};
  box-sizing: border-box;
  width: ${({ theme }) => theme.spacings.spacing72};
  height: ${({ theme }) => theme.spacings.spacing72};
  padding: ${({ theme }) => theme.spacings.spacing4};
  border-radius: ${({ theme }) => theme.rounds.fieldRound} ;
  box-shadow: ${(props) => activeShadowBorder(props)};
  overflow: hidden;
  &:hover {
    box-shadow: ${(props) => activeShadowBorder(props)},
      ${(props) => hoverShadow(props)}, ${(props) => hoverShadowBorder(props)};
  }
  cursor: pointer;
`;

export const ImgThumbInner = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.canvas};
  width: 62px;
  height: 62px;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.rounds.dropdownItemRound};
  border-color: ${({ theme }) => theme.colors.border1};
  overflow: hidden;
`;

export const CloseButton = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: ${({ theme }) => theme.spacings.spacing20};
  height: ${({ theme }) => theme.spacings.spacing20};
  background-color: ${({ theme }) => theme.colors.danger};
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  color: ${({ theme }) => theme.colors.textAccent};
  display: none;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dangerHover};
  }
  ${ImgThumbOuter}:hover & {
    display: flex;
  }
`;

export const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
`;

export const UploadText = styled.h4`
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme }) => theme.colors.textDisabled};
  ${ImgThumbOuter}:hover & {
    color: ${({ theme }) => theme.colors.textAccent};
  }
  margin-top: ${({ theme }) => theme.spacings.spacing2};
  text-align: center;
  line-height: 14px;
`;
