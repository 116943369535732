import React from 'react';
import { Box, ElevateLabelIcon, Image } from '@common-fe/common-fe';

import { LARGE_SIZE_LOGO } from '@/common/constants';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const UploadFileByLinkOrganizationLogo = () => (
  <Box
    data-testid="UploadFileOrganizationLogo"
    justify="center"
    align="center"
    style={{
      position: 'absolute',
      top: '38px',
      left: 0,
      right: 0,
    }}
  >
    {whiteLabelingConfig?.images?.qrCodeLogo ? (
      <Image
        src={whiteLabelingConfig?.images?.qrCodeLogo}
        width={LARGE_SIZE_LOGO.width}
        height={LARGE_SIZE_LOGO.height}
        alt="logo"
      />
    ) : (
      <ElevateLabelIcon />
    )}
  </Box>
);

export default UploadFileByLinkOrganizationLogo;
