import { useMemo, useState } from 'react';
import _ from 'lodash';

interface Params {
  minimumValue?: number;
  availableBalance?: number;
}
export default ({ minimumValue = 0, availableBalance = 0 }: Params) => {
  const [value, setValue] = useState('');

  const currentValue = useMemo(() => {
    const valueAsNumber = _.toNumber(value) || 0;
    return valueAsNumber;
  }, [value]);
  const isMinimumError = useMemo(
    () => currentValue < minimumValue,
    [currentValue, minimumValue],
  );
  const requiredError = useMemo(() => !currentValue, [currentValue]);

  const isValidationError = useMemo(
    () => requiredError || isMinimumError, [isMinimumError, requiredError],
  );
  const currentBalance = useMemo(
    () => availableBalance - currentValue, [availableBalance, currentValue],
  );
  return {
    currentBalance,
    requiredError,
    isMinimumError,
    isValidationError,
    value,
    valueAsNumber: currentValue,
    setValue,
  };
};
