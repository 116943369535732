import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { useCurrentOrganisationStore, useSnackbarStore } from '@/modules/core/store';

export default () => {
  const {
    list,
    handleAdd,
    handleMultipleAdd,
    handleRemoveById,
    handleReset,
  } = useSnackbarStore();

  const location = useLocation();
  const [pathKey, setPathKey] = useState(location.pathname);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialPathKey = useMemo(() => pathKey, []);

  useEffect(() => {
    setPathKey(location.pathname);
  }, [location.pathname]);
  const { handleSetOrganistaionId } = useCurrentOrganisationStore();
  // TODO: fix types
  // @ts-ignore
  const handleAddPermanentSnackbar = useCallback((props) => {
    handleReset();
    handleAdd({
      id: _.uniqueId(),
      permanent: props.permanent,
      closeIcon: props.closeIcon,
      // routeToPlans: props.routeToPlans,
      text: props.text,
      state: props.state,
      isLeft: props.isLeft,
      minWidth: props.minWidth,
      autoRemove: props.autoRemove,
    });
  }, [handleAdd, handleReset]);

  // @ts-ignore
  const handleAddMultipleSnackbar = useCallback((props) => {
    handleReset();
    // @ts-ignore
    handleMultipleAdd(props.map((item) => ({
      id: _.uniqueId(),
      permanent: item.permanent,
      closeIcon: item.closeIcon,
      // routeToPlans: props.routeToPlans,
      text: item.text,
      state: item.state,
      isLeft: item.isLeft,
      queueNumber: item.queueNumber,
      minWidth: item.minWidth,
      autoRemove: item.autoRemove,
    })));
  }, [handleMultipleAdd, handleReset]);
  const handleRemoveSnackbarById = useCallback((id: string) => {
    handleRemoveById(id);
    handleSetOrganistaionId(null);
  }, [handleRemoveById, handleSetOrganistaionId]);

  const shouldSnackbarDisappear = useCallback(() => {
    if (list.length) {
      list.forEach((item) => {
        if (initialPathKey !== pathKey || item.autoRemove) {
          setTimeout(() => {
            handleRemoveById(item.id);
          }, 2000);
        }
      });
    }
  }, [handleRemoveById, initialPathKey, list, pathKey]);

  return {
    snackbars: list,
    handleRemoveSnackbarById,
    handleAddPermanentSnackbar,
    handleAddMultipleSnackbar,
    handleReset,
    shouldSnackbarDisappear,
  };
};
