import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Content = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border2};
  border-radius: ${({ theme }) => theme.rounds.snackbarRound};
`;

interface BreadcrumbProps {
  grey: boolean;
}

export const Breadcrumb = styled.button<BreadcrumbProps>`
  background-color: transparent;
  border: none;
  ${({ theme, grey }) => (grey ? theme.fonts.text14Regular : theme.fonts.text14Medium)};
  color: ${({ theme, grey }) => (grey ? theme.colors.textDisabled : theme.colors.textBody)};
  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.colors.border2};
  }
  &:not(:disabled):active {
    background-color: ${({ theme }) => theme.colors.border1};
  }
`;

export const Delimiter = styled(Text)`
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme }) => theme.colors.textDisabled};
`;

export const BackButtonWrap = styled(Box)`
  box-shadow: none;
  .icon {
    color: ${({ theme }) => theme.colors.textAccent};
  }
`;
