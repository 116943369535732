import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, costFormater,Text } from '@common-fe/common-fe';

import { useGetFundIcon } from '@/modules/investments/hooks';
import { FundsPayload } from '@/modules/investments/SelfDirected/SelfDirected.types';





interface Props extends FundsPayload {
  value: number;
  
}
const ConfirmModalItem: React.FC<Props> = ({ title, stockCode, logo, value, status}) => {
  const { t } = useTranslation();
  const handleGetFundIcon = useGetFundIcon();
  return (
    <Box 
      direction="row"
      pad={{ vertical: '12px', horizontal: 'l' }}
      background="canvas"
      align='center'
      round="container2Round"
      border={{ size: 'small', color: 'border1' }}
      margin={{ top: 'xs' }}
    >
      <Box>
        {
          handleGetFundIcon({
            image: logo,
            status
          })
        }
      </Box>
      <Box
        margin={{ left: 's' }}
        style={{
          flex: 2,
          position: 'relative',
        }}
        direction="column"
      >
        <Box width="100%">
          <Text tip={title} truncate weight={700}>
            {title}
          </Text>
          <Text>{stockCode}</Text>
        </Box>
      </Box>
      <Box direction='row' justify='between' margin={{ left: 's' }} style={{flex: 2}}>
        <Text weight={700}>{costFormater(value, {isPrecision: true})}</Text>

        <Text color="danger">{t('Full position liquidation')}</Text>

      </Box>
      
    </Box>
  );
};

export default ConfirmModalItem;