import { useEffect, useMemo } from 'react';

import { InvestmentHolding, InvestmentsType } from '@/modules/investments/investments.types';
import { useAddedFundsStore } from '@/modules/investments/SelfDirected/AddMoreFunds/stores';
import { useFundsListQuery } from '@/modules/investments/SelfDirected/queries';
import { FundsPayload } from '@/modules/investments/SelfDirected/SelfDirected.types';

const formatFunds = (funds: FundsPayload[] ): InvestmentHolding[] => funds.map((fund) => ({
  amount: 0,
  election: 0,
  name: fund.title,
  image: fund.logo, 
  increase: 0,
  id: fund.id,
  categoryType: fund.category,
  pendingAmount: 0,
  researchURL: fund.link,
  sharePrice: fund.price || 0,
  shares: 0,
  status: fund.status,
  symbol: fund.stockCode,
  type: fund.assetClass as InvestmentsType,
  isHighlighted: fund.isHighlighted,
}));

export default () => {
  const { data: selfDirectedFunds } = useFundsListQuery({ page: 0, perPage: 500, sortBy: 'name,asc' });
  const handleReset = useAddedFundsStore((state) => state.reset);
  const addedFunds = useAddedFundsStore((state) => state.funds);

  const formattedAddedFunds = useMemo(() => formatFunds(addedFunds), [addedFunds]);
  const formattedSelfDirectedFunds = useMemo(() => formatFunds(selfDirectedFunds), [selfDirectedFunds]);

  useEffect(() => {
    return () => {
      handleReset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selfDirectedFunds: formattedSelfDirectedFunds,
    addedFunds: formattedAddedFunds,
  };
};