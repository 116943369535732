import { useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { useStatesQuery } from '@/modules/core/queries';

interface DefaultAddressFields {
  STREET_ADDRESS_1: string,
  STREET_ADDRESS_2: string,
  CITY: string,
  STATE: string,
  ZIP_CODE: string,
}

const FIELDS: DefaultAddressFields = {
  STREET_ADDRESS_1: 'address_line1',
  STREET_ADDRESS_2: 'address_line2',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zip',
};

interface Params {
  defaultFields?: DefaultAddressFields;
}

export default (data: Params) => {
  const { defaultFields } = data;
  const { states } = useStatesQuery();
  const fields: Field[] = useMemo(() => {
    const source: Field[] = [
      {
        name: FIELDS.STREET_ADDRESS_1,
        type: FieldTypes.Text,
        label: 'Address Line 1',
        placeholder: 'Enter Street',
        validator: yup.string().trim().required(REQUIRED_TEXT),
        defaultValue: defaultFields?.STREET_ADDRESS_1,
        disabled: true,
      },
      {
        name: FIELDS.STREET_ADDRESS_2,
        type: FieldTypes.Text,
        label: 'Address Line 2',
        placeholder: 'Enter Street',
        validator: yup.string().trim().required(REQUIRED_TEXT),
        defaultValue: defaultFields?.STREET_ADDRESS_2,
        disabled: true,
      },
      {
        name: FIELDS.CITY,
        type: FieldTypes.Text,
        label: 'City',
        placeholder: 'Enter City',
        validator: yup.string().trim().required(REQUIRED_TEXT),
        defaultValue: defaultFields?.CITY,
        disabled: true,
      },
      {
        name: FIELDS.STATE,
        type: FieldTypes.AutocompleteDropdown,
        label: 'State',
        placeholder: 'Select state',
        validator: yup.string().required(REQUIRED_TEXT),
        options: states.map((state) => ({ key: state.code, value: state.name, title: state.name })),
        defaultValue: defaultFields?.STATE,
        disabled: true,
      },
      {
        name: FIELDS.ZIP_CODE,
        type: FieldTypes.ZipCode,
        label: 'ZIP code',
        placeholder: 'Enter ZIP code',
        validator: yup.string().trim().test('len', 'ZIP code isn\'t valid',
          (val) => (val && (val.length === 10 || val.length === 5))
            || (val === '')).required(REQUIRED_TEXT),
        defaultValue: defaultFields?.ZIP_CODE,
        disabled: true,
      },
    ];
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return source.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      }));
    }
    return source;
  }, [defaultFields, states]);

  return fields;
};
