import { Option } from '@common-fe/common-fe';

interface Group {
  title: string;
  placeholderTitle: string;
  options?: Option[];
}

interface Groups {
  mainGroup?: Group,
  suggestedGroup?: Group,
}

interface suggestedOptionsWithIndex {
  index: number,
  option: Option,
}

const cutSuggestedOptions = ({ mainGroup, suggestedGroup }: Groups) => {
  const suggestedOptions: suggestedOptionsWithIndex[] = [];
  let mainOptions: Option[] = mainGroup?.options ? mainGroup.options : [];

  if (mainGroup?.options?.length && suggestedGroup?.options?.length) {
    mainOptions = mainGroup?.options?.filter((mainOption) => {
      const suggestedOptionIndex = suggestedGroup?.options?.findIndex(
        (suggestedOption) => suggestedOption?.value?.toString()?.toLowerCase()
        === mainOption?.value?.toString()?.toLowerCase(),
      );

      if (suggestedOptionIndex !== undefined && suggestedGroup?.options?.[suggestedOptionIndex]) {
        suggestedOptions.push({
          index: suggestedOptionIndex,
          option: {
            ...suggestedGroup?.options?.[suggestedOptionIndex],
            key: mainOption?.key,
          }
        });
        return false;
      }
      return true;
    }) as Option[];
  }


  const sortedSuggestedOptions = [...suggestedOptions]
    ?.sort((prev, next) => prev.index < next.index ? -1 : 1)
    ?.map((sortedOption) => sortedOption.option);

  return {
    mainOptions,
    suggestedOptions: sortedSuggestedOptions,
  };
};

export const compactGroups = ({ mainGroup, suggestedGroup }: Groups) => {
  const { mainOptions, suggestedOptions } = cutSuggestedOptions({ mainGroup, suggestedGroup });

  const formattedSuggestedOptions = suggestedGroup ? [
    {
      key: 'suggestedGroup',
      groupKey: 'suggestedGroup',
      isGroupTitle: true,
      title: suggestedGroup.title,
      placeholder: suggestedGroup.placeholderTitle,
    },
    ...suggestedOptions.map((option) => ({
      ...option,
      groupKey: 'suggestedGroup',
    })),
  ] : [];

  const formattedMainOptions = mainGroup ? [
    {
      key: 'mainGroup',
      groupKey: 'mainGroup',
      isGroupTitle: true,
      title: mainGroup.title,
      placeholder: mainGroup.placeholderTitle,
      divide: !!suggestedGroup,
    },
    ...mainOptions.map((option) => ({
      ...option,
      groupKey: 'mainGroup',
    })),
  ] : [];

  return [...formattedSuggestedOptions, ...formattedMainOptions] as Option[];
};

export default compactGroups;
