import { useMemo } from 'react';

import { Patient, useGetPatientsQuery } from '@/modules/transaction/components/ReimburseMe/ClaimInfo/usePatients.query';
import { useAuthStore } from '@/modules/user/stores';

const HIDDEN_DEPENDENT = 'Hidden Dependent';

export const useGetCurrentPatients = () => {
  const { user } = useAuthStore();
  const { patients } = useGetPatientsQuery();

  const patientsWithMe: Patient[] = useMemo(() => {
    const meAsPatient = {
      id: user?.employee?.id,
      first_name: user?.firstName,
      last_name: user?.lastName,
      fullName: `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
      relationshipType: 'ME',
    };
    const patientsWithoutHidden = patients
      .filter((patient: Patient) => `${patient.firstName} ${patient.lastName}` !== HIDDEN_DEPENDENT
        && patient.fullName !== HIDDEN_DEPENDENT);

    return [meAsPatient, ...patientsWithoutHidden];
  }, [patients, user]);

  const getNameOfCurrentPatient = (id: number | string) => {
    const currentPatient = patientsWithMe
      .find((patient) => Number(id) === Number(patient.id));

    return currentPatient?.fullName || `${currentPatient?.firstName || ''} ${currentPatient?.lastName || ''}`.trim();
  };

  return {
    patients: patientsWithMe,
    getNameOfCurrentPatient,
  };
};
