import React, { useMemo } from 'react';
import {
  Image,
} from '@common-fe/common-fe';

import LogoIcon from '@/assets/login-logo.svg';
import SmallLogoIcon from '@/assets/small-logo.svg';
import { SwitchImage } from '@/components/elements';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';
import { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

import { Wrapper } from './Logo.styles';

const MINIFY_LOGO_SIZE = 48;
const LARGE_LOGO_HEIGHT = 40;

interface Props {
  minify: boolean;
  horizontalMode?: boolean;
  onClick?: () => void;
}
const Logo: React.FC<Props> = ({ minify, onClick, horizontalMode }) => {
  const { organizationProperties } = useOrganizationProperties();

  const minimizedLogoUrl = useMemo(() => {
    if (whiteLabelingConfig?.key === THEMES.apprize || whiteLabelingConfig?.key === THEMES.rmr) {
      return organizationProperties?.darkMinimizedLogoUrl;
    }

    return organizationProperties?.minimizedLogoUrl;
  }, [organizationProperties]);

  const largeLogoUrl = useMemo(() => {
    if (whiteLabelingConfig?.key === THEMES.apprize || whiteLabelingConfig?.key === THEMES.rmr) {
      return organizationProperties?.darkLargeLogoUrl;
    }

    return organizationProperties?.largeLogoUrl;
  }, [organizationProperties]);


  if (minify) {
    return (
      <Wrapper data-testid="Logo-wrapper-minify" onClick={onClick}>
        <SwitchImage
          src={minimizedLogoUrl}
          width={MINIFY_LOGO_SIZE}
          height={MINIFY_LOGO_SIZE}
          alt="logo"
        >
          <Image
            className="image"
            src={whiteLabelingConfig?.images?.sidebarMinifyLogo
               || whiteLabelingConfig?.minifyLogo || SmallLogoIcon}
            width={MINIFY_LOGO_SIZE}
            height={MINIFY_LOGO_SIZE}
            alt="logo"
          />
        </SwitchImage>
      </Wrapper>
    );
  }

  return (
    <Wrapper data-testid="Logo-wrapper-not-minify" notMinify horizontalMode={horizontalMode} onClick={onClick}>
      <SwitchImage
        src={largeLogoUrl}
        height={LARGE_LOGO_HEIGHT}
        alt="logo"
      >
        <Image
          className="image"
          src={whiteLabelingConfig?.images?.sidebarLogo
            || whiteLabelingConfig?.logo || LogoIcon}
          alt="logo"
          height={LARGE_LOGO_HEIGHT}
          {...whiteLabelingConfig?.logo ? { width: '100%' } : {}}
        />
      </SwitchImage>
    </Wrapper>
  );
};
export default Logo;
