import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface OneTimeLinkPayload {
  hash_id?: string;
  link?: string;
  claim_id?: number;
  document_id?: number;
  employee_id?: number;
  organization_id?: number;
  organization_path?: string;
  active?: boolean;
}

interface OneTimeLink {
  hashId?: string;
  link?: string;
  claimId?: string;
  documentId?: string;
  employeeId?: string;
  organizationId?: string;
  organizationPath?: string;
  active?: boolean;
}

const useGetOneTimeLinkByHashIdQuery = (hashId: string) => {
  const { data, isLoading } = useQuery(PATHS.GET_ONE_TIME_LINK_BY_HASH_ID(hashId), () =>
    api.get<OneTimeLinkPayload>(PATHS.GET_ONE_TIME_LINK_BY_HASH_ID(hashId))
  );

  const formattedData = useMemo<OneTimeLink>(() => ({
    claimId: toString(data?.data?.claim_id),
    documentId: toString(data?.data?.document_id),
    employeeId: toString(data?.data?.employee_id),
    hashId: data?.data?.hash_id,
    link: data?.data?.link,
    organizationId: toString(data?.data?.organization_id),
    organizationPath: data?.data?.organization_path,
    active: data?.data?.active,
  }), [data]);

  return {
    formattedData,
    isLoading,
  };
};

export default useGetOneTimeLinkByHashIdQuery;
