import React, { useState } from 'react';
import { Box, Enrollment,Grid, Preloader } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import EnrollmentItem from '@/modules/transaction/components/EnrollmentItem';
import { ENROLLMENT_ITEM_WIDTH_S } from '@/modules/transaction/components/EnrollmentItem/EnrollmentItem';

import { MyEnrollmentsNullPlaceholder } from './MyEnrollmentsNullPlaceholder';

interface Props {
  // title: string;
  enrollments: Enrollment[];
  placeholder?: string;
  isLoading?: boolean;
}

const PER_PAGE_ITEMS_COUNT = 9;

const MyEnrollmentList: React.FC<Props> = ({
  enrollments, isLoading,
}) => {
  const [shoMore, setShowMore] = useState(false);
  if(isLoading) {
    return (
      <Box
        background="module"
        round="moduleRound"
        // title={`${title} (${enrollments.length})`}
        data-testid="MyEnrollmentList-wrapper"
      >
        <Preloader />
      </Box> 
    );
  }
  return (
    <Box
      background="module"
      round="moduleRound"
      // title={`${title} (${enrollments.length})`}
      data-testid="MyEnrollmentList-wrapper"
    >
      {enrollments.length ? (
        <>
          <Grid
            data-testid="MyEnrollmentList-id"
            columns={ENROLLMENT_ITEM_WIDTH_S}
            margin={{ bottom: 'small' }}
            gap="spacing16"
          >
            {
              enrollments
                .slice(0, shoMore ? (enrollments.length || 0) : PER_PAGE_ITEMS_COUNT)
                .map((enrollment) => (
                  <EnrollmentItem
                    key={enrollment.id}
                    enrollment={enrollment}
                    width={ENROLLMENT_ITEM_WIDTH_S}
                    clickMode
                    isHoverCardNumber
                    isListMode
                  />
                ))

            }
          </Grid>
        </>
      ) : (
        <MyEnrollmentsNullPlaceholder />
      ) }
      {
        (Boolean(enrollments.length > PER_PAGE_ITEMS_COUNT) && !shoMore)
      && (
        <Box direction="row" justify="center">

          <AppButton
            testId="EnrollmentsShowMore-id"
            type="button"
            onClick={() => setShowMore(true)}
            width="150px"
          >
            {`Show more (${enrollments.length - PER_PAGE_ITEMS_COUNT})`}
          </AppButton>
        </Box>
      )
      }
    </Box>
  );
};

export default MyEnrollmentList;
