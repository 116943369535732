import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

export const useRemovePaymentMethod = () => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (paymentMethodId: number) => api.delete(PATHS.UPDATE_DEFAULT_PAYMENT_METHODS(paymentMethodId)),
  );
  return {
    remove: mutateAsync,
    isLoading,
    isSuccess,
  };
};
