import { useCallback, useMemo } from 'react';
import JSEncrypt from 'jsencrypt';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface EncryptArgs<DK = string> {
  pubkey: string
  data: {
    [key: string]: DK;
  }
}

interface GetEncryptPubKeyResponse {
  alias: string,
  key_base64: string,
  supported_encryption_algorithms: string[];
}

interface EncryptPubKey {
  alias: string;
  keyBase64: string;
  supportedEncryptionAlgorithms: string[];
}

export const useEncryptData = () => {
  const encrypt = useCallback((args: EncryptArgs) => {
    const encryptInstance = new JSEncrypt();
    encryptInstance.setPublicKey(args.pubkey);
    return Object.keys(args.data).reduce((adapter, key) => ({
      ...adapter,
      [key]: encryptInstance.encrypt(args.data[key]),
    }), {});
  }, []);
  return useMemo(() => ({
    encrypt,
  }), [
    encrypt,
  ]);
};

function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export const getEncryptionByPubKey = async (data: Partial<EncryptArgs['data']>) => {
  const {
    data: encryptionPubKeyResp,
  } = await api.get<GetEncryptPubKeyResponse>(PATHS.ENCRYPTION_PUBLIC_KEY);
  const formatData: EncryptPubKey = {
    alias: encryptionPubKeyResp.alias,
    keyBase64: encryptionPubKeyResp.key_base64,
    supportedEncryptionAlgorithms: encryptionPubKeyResp.supported_encryption_algorithms,
  };

  const encryptData = async (adapter: Partial<EncryptArgs['data']> = {}, body: Partial<EncryptArgs['data']>, keyIndex: number) => {
    const bodyKeysArr = Object.keys(body);
    let res = { ...adapter };
    const bodyKey = bodyKeysArr[keyIndex];
    const importedKey = await window.crypto.subtle.importKey('spki', base64ToArrayBuffer(formatData.keyBase64),
      { hash: 'SHA-1', name: 'RSA-OAEP' }, true,
      ['encrypt']);
    const encryptedData = await crypto.subtle.encrypt({ name: 'RSA-OAEP' }, importedKey,
      new TextEncoder().encode(body[bodyKey]));
    // @ts-ignore
    const encryptedDataBase64String = btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
    if (bodyKeysArr[keyIndex + 1]) {
      res = await encryptData({
        ...adapter,
        [bodyKey]: encryptedDataBase64String,
      }, body, keyIndex + 1);
    }

    return {
      ...res,
      [bodyKey]: encryptedDataBase64String,
    };
  };
  return encryptData({}, data, 0);
};
