import React from 'react';
import {
  ActivityType,
  Box, CategoryIcon, Text, } from '@common-fe/common-fe';

import getActivityTypeColor from '@/utils/whiteLabeling/getActivityTypeColor';

interface TransactionTitleProps {
  category: string | null;
  children: React.ReactNode;
}

const TransactionTitle: React.FC<TransactionTitleProps> = ({ category, children }) => (
  <Box direction="row" align="center">
    <CategoryIcon
      category={category as ActivityType}
      size="s"
      background={getActivityTypeColor(category as ActivityType)}
    />
    <Text size="large" margin={{ left: 'spacing12' }}>
      {children}
    </Text>
  </Box>
);

export default TransactionTitle;
