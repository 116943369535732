import React, {
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Option,OptionKey } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { useExpensesStatisticsQuery } from '@/modules/transaction/hooks/useExpensesStatistics.query';
import useMyEnrollments from '@/modules/transaction/hooks/useMyEnrollments';

import TransactionsList from '../TransactionsList';

// import { useDebounce } from '@/utils/hooks';
// import { useLocation } from 'react-router-dom';
import {
  ActionsWrapper, Dropdown,
  Header, HeaderWrapper,   Wrapper, } from './ActivityDetails.styles';
import ActivityDetailsExpenses from './ActivityDetailsExpenses';

const YEARS_BETWEEN = 2;
const ActivityDetails = () => {
  // const location = useLocation();
  // const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  // const currentAccountType = useMemo(() => _.get(query, 'accountType') as OptionKey, [query]);
  const [types, setTypes] = useState<OptionKey[]>([]);
  // useEffect(() => {
  //   if (currentAccountType) {
  //     setTypes([currentAccountType]);
  //   }
  // }, [currentAccountType]);
  const [datesRange, setDatesRange] = useState<OptionKey[]>([]);
  const { t } = useTranslation();
  const { data: accounts } = useMyEnrollments();
  const { formatedData } = useExpensesStatisticsQuery();

  const typesOptions = useMemo<Option[]>(() => {
    if (accounts.length) {
      const list = accounts.map((item) => ({
        key: item.id,
        value: item.id,
        title: item.name || '',
      }));
      if (!types.length) {
        const keys = list.map((item) => item.key);
        setTypes(keys);
      }

      return list;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const datesOptions = useMemo<Option[]>(() => {
    const toYear = +dayjs().format('YYYY');
    const fromYear = toYear - YEARS_BETWEEN;
    const years: Option[] = [];
    for (let index = toYear; index >= fromYear; index -= 1) {
      const item = `${index}`;
      years.push({
        key: item,
        value: item,
        title: item,
      });
    }
    if (!types.length) {
      const keys = years.map((item) => item.key);
      setDatesRange(keys);
    }
    return years;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper data-testid="ExpenseDetails-wrapper">
      <HeaderWrapper>
        <Header>{t('Expense Details')}</Header>
        {/* masked this functionality for later verification */}
        {false && (
          <ActionsWrapper>
            <Dropdown
              allMode={false}
              id="accountType"
              name={t('Account type')}
              onChangeValues={setTypes}
              values={types}
              options={typesOptions}
            />
            <Dropdown
              allMode={false}
              id="datesRange"
              name={t('Dates range')}
              onChangeValues={setDatesRange}
              values={datesRange}
              options={datesOptions}
            />
          </ActionsWrapper>
        )}
      </HeaderWrapper>
      <ActivityDetailsExpenses
        expensesOptions={formatedData?.expenses || []}
        totalExpense={formatedData?.totalExpenses || 0}
      />
      <TransactionsList />
    </Wrapper>
  );
};
export default ActivityDetails;
