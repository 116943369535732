import React from 'react';
import { Box, costFormater,Text } from '@common-fe/common-fe';

import { InvestmentStockItem } from '../investments.types';

interface Props {
  data?: InvestmentStockItem[];
  title: string;
  totalHoldingsAmount?: number;
  totalPendingsAmount?: number;
  activeFund?: string;
}
const InvestmentsDonutList: React.FC<Props> = ({
  data,
  title,
  totalHoldingsAmount,
  totalPendingsAmount,
  activeFund,
}) => (
  <>
    <Box justify="between" align="center" direction="row" pad={{ bottom: 'm' }} margin={{ bottom: 'xxs' }} data-testid="Investments-donut-list">
      <Text weight="bold" size="medium">{title}</Text>
      {!!totalHoldingsAmount && <Text weight="bold" size="medium">{costFormater(totalHoldingsAmount, true)}</Text>}
      {!!totalPendingsAmount && <Text weight="bold" size="medium">{costFormater(totalPendingsAmount, true)}</Text>}
    </Box>
    {!data?.length && (
      <Text color="textSecondary" size="small " weight={500}>
        None
      </Text>
    )}
    {data?.length ? data?.map((item) => (
      <Box
        key={item.stockQuote}
        direction="row"
        justify="between"
        align="center"
        margin={{ bottom: 'xxs' }}
        pad="xs"
        background={{ color: item.stockQuote === activeFund ? 'border2' : '' }}
        height={{ min: '24px' }}
        round="dropdownItemRound"
      >
        <Box direction="row" align="center">
          <Box width="12px" height="12px" round="smallRound" background={item.color} />
          <Text margin={{ left: 'spacing8' }}>{item.stockQuote}</Text>
        </Box>
        <Box direction="row" align="center">
          <Text weight="bold" textAlign="end" margin={{ right: 'spacing16' }}>{costFormater(item?.amount, true)}</Text>
          <Text weight="bold" textAlign="end">{item?.percentage}</Text>
        </Box>
      </Box>
    )) : null}
  </>
);
export default InvestmentsDonutList;
