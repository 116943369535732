import React from 'react';
import { Box, NotificationPlaceholderIcon,Text } from '@common-fe/common-fe';

interface Props {
  children: React.ReactNode;
}
const NotificationPlaceholder: React.FC<Props> = ({ children }) => (
  <Box
    round="container1Round"
    align="center"
    justify="center"
    height="190px"

  >

    <Box margin={{ bottom: 'xs' }}>
      <NotificationPlaceholderIcon color="iconSecondary" size="54px" />
    </Box>
    <Text size="medium" weight={700}>
      {children}
    </Text>
  </Box>
);

export default NotificationPlaceholder;
