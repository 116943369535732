import React from 'react';

import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';
import UploadFileByLinkInfoContainer from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkInfoContainer';

const DocumentAddedScreen = () => {
  return (
    <UploadFileByLinkContentWrapper>
      <UploadFileByLinkInfoContainer
        title="Your request has been submitted for additional review"
        description="You can check on the status of this request under account activity."
        iconType="pending"
      />
    </UploadFileByLinkContentWrapper>
  );
};

export default DocumentAddedScreen;
