import React, { useMemo } from 'react';
import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { TransactionTitle } from '@/modules/transaction';
import ActivityStatus from '@/modules/transaction/components/ActivityStatus';
import { TransactionStatus } from '@/modules/transaction/transaction.types';
import costFormated from '@/utils/modifiers/costFormater';

const StyledRow = styled(Box)`
  &:first-child {
    margin-top: ${({ theme }) => (theme.spacings.spacing8)};
  }
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => (theme.spacings.spacing8)};
  }
`;

interface Props {
  category: string;
  title: string;
  amount: number;
  status: string;
  date: string;
  onPick?: () => void;
}
export const CardTransactionItem: React.FC<Props> = ({
  category, title, amount, onPick, date, status,
}) => {
  const isDeclined = useMemo(() => status === TransactionStatus.Declined, [status]);
  return (
    <StyledRow>
      <Box
        direction="row"
        data-testid="card-transaction-list-item"
        align="center"
        height={{ min: 'smallItemHeight' }}
        background="canvas"
        elevation="default"
        round="container1Round"
        pad={{ horizontal: 'spacing16' }}
        onClick={onPick}
      >
        <Box style={{ flex: 6 }}>
          <TransactionTitle category={category}>{title}</TransactionTitle>
        </Box>

        <Box style={{ flex: 2 }}>
          <Text
            size="large"
            style={{ textDecoration: isDeclined ? 'line-through' : undefined }}
            color={isDeclined ? 'textSecondary' : undefined}
          >
            {costFormated(amount, true)}
          </Text>
        </Box>

        <Box style={{ flex: 2, minWidth: '170px' }}>
          <ActivityStatus status={status as TransactionStatus} />
        </Box>

        <Box style={{ flex: 1.5 }}>
          <Text size="large" color="textSecondary">
            {date}
          </Text>
        </Box>

      </Box>
    </StyledRow>
  );
};
