import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import { CardHolderDto } from '@/modules/transaction/components/Cards/Cards.types';
import { isDateInFuture } from '@/utils';

interface Params {
  data?: CardHolderDto | null;
  createMode: boolean;
  relationshipTypes: Option[];
}

export const useCardDependentForm = (params: Params) => {
  const { t } = useTranslation();

  return useMemo((): Field[] => [
    {
      name: 'firstName',
      type: FieldTypes.Text,
      label: 'First name',
      placeholder: 'First name',
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: params.data?.firstName,
    },
    {
      name: 'lastName',
      type: FieldTypes.Text,
      label: 'Last name',
      placeholder: 'Last name',
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: params.data?.lastName,
    },
    ...params.createMode ? [
      // {
      //   name: 'email',
      //   type: FieldTypes.Text,
      //   label: 'Email',
      //   placeholder: 'Email',
      //   validator: yup.string().required(REQUIRED_TEXT).email('Email must be valid.'),
      //   defaultValue: params.data?.email,
      // },
      {
        name: 'dateOfBirth',
        type: FieldTypes.Date,
        isManualDateInput: true,
        selectDateWithoutApply: true,
        label: 'Date of birth',
        placeholder: 'Select birth date',
        validator: yup.string().required(REQUIRED_TEXT).test(
          'date',
          t('Date cannot be in the future'), (date) => !isDateInFuture(date),
        ),
        defaultValue: params.data?.dateOfBirth,
        maxDate: dayjs(),
      },
    ] as Field[] : [
      {
        name: 'dateOfBirth',
        type: FieldTypes.Text,
        label: 'Date of birth',
        defaultValue: `
        ${dayjs(params.data?.dateOfBirth).format(DEFAULT_DATE_FORMAT)}
         (${dayjs(params.data?.dateOfBirth).toNow(true)})
         `,
      },
    ] as Field[],
    {
      name: 'relationship',
      type: FieldTypes.Dropdown,
      label: 'Relationship',
      singleMode: true,
      placeholder: 'Select relationship',
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: params.data?.relationship,
      value: params.data?.relationship,
      options: params.relationshipTypes,
    },
  ], [params, t]);
};
