import { storeCreator } from '@/utils/store/store.utils';

interface State {
  modalVisible: boolean;
  contributionAmount: number;
  holdPeriod: string;
  onSubmit: () => void;
  additionalAction?: () => void;
  updateTracker?: () => void;
  updateRecurringContributionView?: () => void;
  handleSetOnSubmit: (onSubmit: State['onSubmit']) => void;
  handleSetAdditionalAction: (additionalAction: State['additionalAction']) => void;
  handleSetUpdateTracker: (updateTracker: State['updateTracker']) => void;
  handleSetUpdateRecurringContributionView: (updateRecurringContributionView: State['updateRecurringContributionView']) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleChangeContributionAmount: (contributionAmount: number) => void;
  handleChangeHoldPeriod: (holdPeriod: string) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  contributionAmount: 0,
  holdPeriod: '',
  onSubmit: () => {},
  additionalAction: () => {},
  updateTracker: () => {},
  updateRecurringContributionView: () => {},

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleChangeContributionAmount: (contributionAmount) => set((state) => ({
    ...state,
    contributionAmount,
  })),

  handleChangeHoldPeriod: (holdPeriod) => set((state) => ({
    ...state,
    holdPeriod,
  })),

  handleSetOnSubmit: (onSubmit) => set((state) => ({
    ...state,
    onSubmit,
  })),

  handleSetAdditionalAction: (additionalAction) => set((state) => ({
    ...state,
    additionalAction,
  })),

  handleSetUpdateTracker: (updateTracker) => set((state) => ({
    ...state,
    updateTracker,
  })),

  handleSetUpdateRecurringContributionView: (updateRecurringContributionView) => set((state) => ({
    ...state,
    updateRecurringContributionView,
  })),

  handleReset: () => set((state) => ({
    ...state,
    contributionAmount: 0,
    holdPeriod: '',
    onSubmit: () => {},
    additionalAction: () => {},
    updateTracker: () => {},
    updateRecurringContributionView: () => {},
  })),
}));
