import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';

import MyEnrollment from '../MyEnrollment';

export const MyAccountsGeneral: React.FC = () => {
  const { t } = useTranslation();

  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.MY_ACCOUNTS,
    title: t('My accounts'),
    value: `${ROUTES.MY_ACCOUNTS}`,
  }], true);
  return (
    <Box data-testid="MyAccountsGeneral_container">
      <Heading level={3} size="32px" style={{ fontWeight: 700 }}>{t('My Accounts')}</Heading>
      <MyEnrollment isFiltered />
    </Box>
  );
};
