import { useMemo } from 'react';
import { toNumber } from 'lodash';

import { EligibleExpense } from '../../FileScan/fileScan.types';
import { useClaimInfoFieldsFilterStore } from '../stores/useClaimInfoFieldsFilter.store';

import { getTheNumberOfMatches } from './getTheNumberOfMatches';

const useFilteredExpensesOptions = (eligibleExpenses?: EligibleExpense[]) => {
  const { filterByValues } = useClaimInfoFieldsFilterStore();

  const sortedExpenses = useMemo(() => {
    if (!eligibleExpenses?.length) {
      return [];
    }
  
    const eligibleExpensesWithNumberOfMatches = eligibleExpenses
      .map((expense) => getTheNumberOfMatches(expense, filterByValues));

    return [...eligibleExpensesWithNumberOfMatches]
      .sort((prev, next) => {
        if (prev.numberOfMatches === next.numberOfMatches) {
          return toNumber(next.expense.amount || 0) - toNumber(prev.expense.amount || 0);
        } else {
          return prev.numberOfMatches < next.numberOfMatches ? 1 : -1;
        }
      });
  }, [filterByValues, eligibleExpenses]);

  const filteredExpense = useMemo(() => sortedExpenses
    .filter((sortedExpense) => sortedExpense.isFullMatching)
    .map((filteredExpense) => filteredExpense.expense)
    .sort((prev, next) => toNumber(prev.amount) < toNumber(next.amount) ? 1 : -1)[0], 
  [sortedExpenses]);

  const sortedExpensesOptions = useMemo(() => sortedExpenses
    ?.map((expense) => expense?.expense), 
  [sortedExpenses]);

  return {
    sortedExpensesOptions,
    filteredExpense,
  };
};

export default useFilteredExpensesOptions;
