import React, { useMemo } from 'react';
import {
  Box,
  costFormater,
  Inscription,
} from '@common-fe/common-fe';

import { ContributionAchievementsName } from './queries/useGetContributionsHistory.query';

const nameFormatter = (name: string) => {
  if (name === ContributionAchievementsName.INDIVIDUAL) {
    return 'Individual limit';
  }
  if (name === ContributionAchievementsName.FAMILY) {
    return 'Family limit';
  }

  return name;
};

interface Props {
  name: string;
  maxValue: number;
  currentValue: number;
  maxContributionValue: number;
}

export const ContributionLimitInfoBox: React.FC<Props> = ({
  name,
  maxValue,
  currentValue,
  maxContributionValue,
}) => {
  const individualLimitValue = currentValue >= maxValue ? maxValue : currentValue;
  const familyLimitValue = currentValue >= maxValue ? maxValue : currentValue;
  const catchUpLimitValue = currentValue >= maxContributionValue
    ? currentValue - maxContributionValue
    : 0;

  const currentLimitValue = useMemo(() => {
    if (name === ContributionAchievementsName.INDIVIDUAL) {
      return costFormater(individualLimitValue, true);
    }
    if (name === ContributionAchievementsName.FAMILY) {
      return costFormater(familyLimitValue, true);
    }
    if (name === ContributionAchievementsName.CATCH_UP) {
      return costFormater(catchUpLimitValue, true);
    }

    return costFormater(0, true);
  }, [catchUpLimitValue, familyLimitValue, individualLimitValue, name]);
  return (
    <Box
      data-testid="ContributionLimitInfoBox_wrapper"
      pad={{ vertical: 'spacing8', horizontal: 'spacing16' }}
      border={{ color: 'border1' }}
      round="snackbarRound"
      width="100%"
    >
      <Inscription
        weight={500}
        lineHeight="18px"
        size="12px"
        color="textDisabled"
        margin={{ bottom: 'spacing2' }}
      >
        {nameFormatter(name)}
      </Inscription>
      <Inscription lineHeight="20px" data-testid={`ContributionLimitInfoBox_${currentLimitValue}-${costFormater(maxValue, true)}`}>
        {currentLimitValue} / {costFormater(maxValue, true)}
      </Inscription>
    </Box>
  );
};
