import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, CIPStatus, Enrollment, EnrollmentStatus,
  Heading, NullEnrollmentsIcon, Option,OptionKey,
  Preloader, SelectDropdown, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import AppButton from '@/components/controls/AppButton';
import { useHistory } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user/stores';

import { useMyEnrollments } from '../../hooks';

import MyEnrollmentList from './MyEnrollmentList';

const OPTIONS: Option[] = [
  {
    key: EnrollmentStatus.Null,
    value: 'All',
    title: 'All',
  },
  {
    key: EnrollmentStatus.Active,
    value: 'Active',
    title: 'Active',
  },
  {
    key: EnrollmentStatus.Canceled,
    value: 'Canceled',
    title: 'Canceled',
  },
];

interface EnrollmentsProps {
  title?: string;
  status?: EnrollmentStatus;
  enrollments: Enrollment[];
  isLoading?: boolean;
}

const EnrollmentsWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
  &:last-child {
    border-bottom: none;
  }
`;
const EnrollmentsList: React.FC<EnrollmentsProps> = ({ enrollments, title, status, isLoading }) => (
  <EnrollmentsWrapper data-testid={`enrolment-list_${status}`} margin={{ top: 'm' }} pad={{ bottom: 'xxs' }}>
    {title ? (
      <Box margin={{ bottom: 'm' }}>
        <Text size="xxlarge" weight={400}>
          {title}
        </Text>
      </Box>
    ) : null}
    <MyEnrollmentList enrollments={enrollments} isLoading={isLoading} />
  </EnrollmentsWrapper>
);

interface Props {
  isFiltered?: boolean;
}
const MyEnrollment: React.FC<Props> = ({ isFiltered }) => {
  const history = useHistory();
  const [filterValues, setFilterValues] = useState<OptionKey[]>([EnrollmentStatus.Null]);
  const handleSetFilterValues = useCallback(
    (values: OptionKey[]) => {
      if (filterValues.includes(EnrollmentStatus.Null) && values.length > 1) {
        const filtered = values.filter((item) => item !== EnrollmentStatus.Null);
        setFilterValues(filtered);
      } else if (
        (values.includes(EnrollmentStatus.Null) && !filterValues.includes(EnrollmentStatus.Null))
        || !values.length
      ) {
        setFilterValues([EnrollmentStatus.Null]);
      } else {
        setFilterValues(values);
      }
    },
    [filterValues],
  );
  const isAllPicked = useMemo(
    () => filterValues.length === 0
      || OPTIONS.length - 1 === filterValues.length
      || filterValues.includes(EnrollmentStatus.Null),
    [filterValues],
  );

  // const currentStatus = useMemo(() => {
  //   if (filterValues.length && !isAllPicked) {
  //     return filterValues[0] as string;
  //   }
  //   return null;
  // }, [filterValues, isAllPicked]);
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const {
    data: enrollments,
    isLoading,
    isSuccess,
  } = useMyEnrollments(user?.id);

  const canceledList = useMemo(() => {
    if (!filterValues.includes(EnrollmentStatus.Canceled) && !isAllPicked) {
      return [];
    }
    const filteredList = enrollments.filter(
      (item) => item.enrollmentStatus === EnrollmentStatus.Canceled,
    );
    if (isAllPicked) {
      return filteredList;
    }

    return filteredList;
  }, [enrollments, filterValues, isAllPicked]);
  const activeList = useMemo(() => {
    if (!filterValues.includes(EnrollmentStatus.Active) && !isAllPicked) {
      return [];
    }
    const filteredList = enrollments.filter(
      (item) => item.enrollmentStatus === EnrollmentStatus.Active
      || (
        (item?.CIPStatus === CIPStatus.Question || item?.CIPStatus === CIPStatus.Fail) 
      && item.isCIPRequired
    && item.enrollmentStatus !== EnrollmentStatus.Canceled),
    );
    if (isAllPicked) {
      return filteredList;
    }

    return filteredList;
  }, [enrollments, filterValues, isAllPicked]);
  const isEmpty = useMemo(() => enrollments.length === 0, [enrollments.length]);
  const handleRedirect = useCallback(() => {
    history.push(ROUTES.MY_ACCOUNTS);
  }, [history]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box align="center" justify="center">
          <Preloader />
        </Box>
      );
    }
    if (isEmpty) {
      
      return (
        <Box align="center" justify="center" margin={{ top: 'l' }}>
          <Box width="70px" height="70px">
            <NullEnrollmentsIcon color="iconSecondary" size="72px" />
          </Box>
          <Text size="large" weight={700} margin={{ top: 'l' }}>
            {t('There are no Enrollments yet')}
          </Text>
          <Text size="medium" margin={{ top: 's' }}>
            {t('Enrollments will be added soon')}
          </Text>
        </Box>
      );
    }
    if (activeList.length === 0 && canceledList.length === 0) {
      return (
        <Box align="center" justify="center" margin={{ top: 'l' }}>
          <NullEnrollmentsIcon color="iconSecondary" size="72px" />

          <Text size="medium" weight={700} margin={{ top: 'l' }}>
            {t('No enrollments were found by selected filter')}
          </Text>
        </Box>
      );
    }


    return (
      <Box>
        {!!activeList.length && (
          <EnrollmentsList
            status={EnrollmentStatus.Active}
            title={isFiltered ? 'Active' : ''}
            enrollments={activeList}
            isLoading={isLoading || !isSuccess}
          />
        )}

        {isFiltered && !!canceledList.length && (
          <EnrollmentsList status={EnrollmentStatus.Canceled} title="Cancel" enrollments={canceledList}  isLoading={isLoading || !isSuccess} />
        )}
      </Box>
    );
  }, [activeList, canceledList, isEmpty, isFiltered, isLoading, t, isSuccess]);
  return (
    <Box
      data-testid="MyEnrollment-wrapper"
      direction="column"
      pad={{ top: 'xl', horizontal: 'l', bottom: 'l' }}
      margin={{ top: 'l' }}
      round="moduleRound"
      background={{ color: 'module' }}
    >
      <Box direction="row" align="center" justify="between">
        <Heading level={4} size="16px" style={{ fontWeight: 700 }}>
          {t('My Enrollment')}
        </Heading>
        {isFiltered ? (
          <Box width="control">
            <SelectDropdown
              disabled={isLoading}
              id="enrollment-filter"
              allMode
              testId="enrollment-filter"
              activeTitle
              ellipsisMode
              name="Status"
              prefix="Status"
              options={OPTIONS}
              values={filterValues}
              onChangeValues={handleSetFilterValues}
            />
          </Box>
        ) : (
          <Box>
            <AppButton testId="view_all_enrollments" width="auto" buttonType="secondary" onClick={handleRedirect}>
              {t('View All Enrollments')}
            </AppButton>
          </Box>
        )}
      </Box>
      <>{content}</>
    </Box>
  );
};
export default MyEnrollment;
