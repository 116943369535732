import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CrossIcon,Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

import currentTheme from '@/styles/theme';

const StyledWrapper = styled(Box)`
  position: relative;
  ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 2px;
    left: ${({ theme }) => theme.spacings.spacing24};
    right: ${({ theme }) => theme.spacings.spacing24};
    background: ${({ theme }) => theme.colors.button};
  };
`;

const ActivitiesWrapper: React.FC<{
  children: React.ReactNode,
  onClose: () => void,
}> = ({ children, onClose }) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper
      data-testId="activities-wrapper"
      background="canvas"
      border={{ size: 'small', color: 'infoBorder' }}
      width="100%"
    >
      <Box background="module" round="container1Round" pad="spacing24">
        <Box round="container1Round">
          <Box direction="row" justify="between" align="center" width="100%" margin={{ bottom: '5px' }}>
            <Inscription
              color={currentTheme.colors.textSecondary}
              size="12px"
              weight={500}
            >
              {t('We found a few more claims with "Waiting for funding" status.')}
            </Inscription>
            <Box data-testId="activities-wrapper-close" onClick={onClose}><CrossIcon /></Box>
          </Box>
          <Box direction="row" margin={{ bottom: 'spacing16' }}>
            <Inscription
              size="16px"
              weight={700}
              textAlign="center"
              lineHeight="22px"
            >
              {t('Would you like to apply the same decision to the following claims?')}
            </Inscription>
          </Box>
          {children}
        </Box>
      </Box>
    </StyledWrapper>
  );
};

export default ActivitiesWrapper;
