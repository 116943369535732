import { useCallback, useEffect } from 'react';

import { CardStatusEnum } from '@/modules/transaction/components/Cards/Cards.types';

export const REQUESTED_ACTIVATION_CARDS_KEY = 'requestedActivationCards';

interface Props {
  cardId?: string;
  cardStatus?: CardStatusEnum;
  last4?: string;
}

export const useRequestedActivationCards = (props?: Props) => {
  const requestedToActivateCards = localStorage.getItem(REQUESTED_ACTIVATION_CARDS_KEY);
  const setRequestedActivationCards = useCallback((cardID: string) => {
    if (requestedToActivateCards) {
      localStorage.setItem(
        REQUESTED_ACTIVATION_CARDS_KEY,
        `${requestedToActivateCards},${cardID}`,
      );
    } else {
      localStorage.setItem(REQUESTED_ACTIVATION_CARDS_KEY, cardID);
    }
  }, [requestedToActivateCards]);

  const isCardRequestedToActivate = useCallback((cardID: string) => {
    if (requestedToActivateCards) {
      return requestedToActivateCards.split(',').includes(cardID);
    }
    return false;
  },
  [requestedToActivateCards]);

  useEffect(() => {
    if (requestedToActivateCards
      && props?.cardId
      && requestedToActivateCards.includes(props?.cardId)) {
      const filteredCards = requestedToActivateCards?.split(',').filter((id) => props.cardId !== id).join(',');
      if (props?.cardStatus === CardStatusEnum.ACTIVE && filteredCards) {
        localStorage.setItem(REQUESTED_ACTIVATION_CARDS_KEY, filteredCards);
      }
      if (props?.cardStatus === CardStatusEnum.ACTIVE && !filteredCards) {
        localStorage.removeItem(REQUESTED_ACTIVATION_CARDS_KEY);
      }
    }
  }, [props, requestedToActivateCards]);

  return {
    setRequestedActivationCards,
    isCardRequestedToActivate,
  };
};
