import React, { useEffect } from 'react';

let TRANSLOCATION = 50;
const SELECTOR = 'div[role="presentation"] iframe, div[role="presentation"] > div:first-child';
const INIT_BOTTOM_ATTRIBUTE = 'data-init-bottom';
let waitAndRunAgain = 2;

interface Item extends Element {
  style?: React.CSSProperties,
}

const move = (up?: boolean) => {
  if (document.readyState === 'complete') {
    if (!document.querySelectorAll(SELECTOR).length && waitAndRunAgain > 0) {
      waitAndRunAgain -= 1;
      setTimeout(() => move(up), 1000);
      return;
    }

    waitAndRunAgain = 0;

    document.querySelectorAll(SELECTOR)
      .forEach((item: Item) => {
        const currentBottom = Number(`${item?.style?.bottom}`.replace('px', ''));
        if (Number.isNaN(currentBottom)) {
          return;
        }

        let initBottom = currentBottom;

        if (!item.getAttribute(INIT_BOTTOM_ATTRIBUTE)) {
          item.setAttribute(INIT_BOTTOM_ATTRIBUTE, `${currentBottom}`);
        } else {
          initBottom = Number(item.getAttribute(INIT_BOTTOM_ATTRIBUTE));
        }

        const updatedBottom = currentBottom + TRANSLOCATION * (up ? 1 : -1);
        const isUpdatedBottomValid = updatedBottom >= initBottom
          && updatedBottom <= initBottom + TRANSLOCATION;

        if (item?.style?.bottom && isUpdatedBottomValid) {
           
          item.style.bottom = `${updatedBottom}px`;
        }
      });
  } else {
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        setTimeout(() => move(up), 1000);
      }
    });
  }
};

interface Props {
  translocation?: number,
}

const ZendeskMoving: React.FC<Props> = ({ translocation }) => {
  useEffect(() => {
    if (translocation) {
      TRANSLOCATION = translocation;
    }

    move(true);

    return move;
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  return null;
};

export default ZendeskMoving;
