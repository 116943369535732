import { useCallback } from 'react';

import { Investor } from '@/modules/investments/investments.types';

import { useStore as useInvestmentProfileStore } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/stores/useInvestmentProfile.store';
import { useStore as usePersonalInformationStore } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/stores/usePersonalInformation.store';
import { useFundingAmountStore } from '../FundingAmount/stores/useFundingAmount.store';
import { useInvestmentPathStore } from '../InvestmentPath/stores/useInvestmentPath.store';
const TRUE_KEY = 'true';
const FALSE_KEY = 'false';
interface Params {
  isSwitchMode?: boolean;
}
export const useInvestmentsFieldsSetter = (params?: Params) => {
  const {
    handleSetValues: handleSetValueInvestmentPathStore,
  } = useInvestmentPathStore();
  const {
    handleSetValues: handleSetValueFundingAmountStore,
  } = useFundingAmountStore();
  const {
    setSourceState: handleSetValuePersonalInformationStore
  } = usePersonalInformationStore();
  const {
    setSourceState: handleSetValueInvestmentProfileStore,
  } = useInvestmentProfileStore();

  const handleShowErrorsPersonalInformationStore = usePersonalInformationStore(
    (state) => state.setShowErrors,
  );
  const handleShowErrorsInvestmentProfileStore = useInvestmentProfileStore(
    (state) => state.setShowErrors,
  );
  const handleShowErrorsPersonalInformation = useCallback((value: boolean) => {
    handleShowErrorsPersonalInformationStore(value);
  }, [handleShowErrorsPersonalInformationStore]);
  const handleShowErrorsInvestmentProfile = useCallback((value: boolean) => {
    handleShowErrorsInvestmentProfileStore(value);
  }, [handleShowErrorsInvestmentProfileStore]);

  const handleSetValues = useCallback((investor: Investor) => {
    handleSetValueInvestmentPathStore({
      investmentPath: investor?.type,
      termsCondition: false,
    });
    handleSetValueFundingAmountStore({ fundingAmount: `${investor?.balance === 0 ? 0 : investor?.balance || ''}` });

    handleSetValuePersonalInformationStore({
      position: investor?.position,
      employmentType: investor?.employmentType,
      broker: investor?.broker === undefined ? '' : investor?.broker ? TRUE_KEY : FALSE_KEY,
      director: investor?.director_of === undefined
        ? '' : investor?.director_of ? TRUE_KEY : FALSE_KEY,
      directorOf: investor?.director_of,
      politicallyExposed: investor?.politically_exposed_names === undefined
        ? '' : investor?.politically_exposed_names ? TRUE_KEY: FALSE_KEY,
      politicallyExposedNames: investor?.politically_exposed_names,
      networthTotal: `${investor?.networthTotal === 0 ? 0 : investor?.networthTotal || ''}`,
      annualIncome: `${investor?.annualIncome === 0 ? 0 : investor?.annualIncome || ''}`,
    });
    if(!params?.isSwitchMode) {
      handleSetValueInvestmentProfileStore({
        toleranceModel: investor?.toleranceModel,
        investmentObjectivesType: investor?.investmentObjectivesType,
        investmentExperienceType: investor?.investmentExperienceType,
        purposeType: investor?.purposeType,
        riskToleranceType: investor?.riskTolerance,
        medicalExpensesCoverType: investor?.medicalExpensesCoverType,
      });
    }
    
  }, [
    params?.isSwitchMode,
    handleSetValueInvestmentPathStore,
    handleSetValueFundingAmountStore,
    handleSetValuePersonalInformationStore,
    handleSetValueInvestmentProfileStore,
  ]);

  return {
    handleSetValues,
    handleShowErrorsPersonalInformation,
    handleShowErrorsInvestmentProfile,
  };
};
