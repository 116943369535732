import { storeCreator } from '@/utils/store/store.utils';

interface State {
  setProviderId: (id: string) => void;
  handleReset: () => void;
  id: string;
}

const useStore = storeCreator<State>((set) => ({
  id: '',
  setProviderId: (id: string) => set(() => ({ id })),
  handleReset: () => set(() => ({ id: '' })),
}));

const useProviderStore = () => {
  const { setProviderId, handleReset, id } = useStore();

  return {
    createdProviderId: id,
    setProviderId,
    handleReset,
  };
};

export default useProviderStore;
