import { useCallback, useMemo, useState } from 'react';

export default () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const formatedDates = useMemo(() => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return [endDate, startDate];
      }
      return [startDate, endDate];
    }
    return [];
  }, [endDate, startDate]);
  const onChangeDates = useCallback((dates : [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);
  return {
    formatedDates,
    onChangeDates,
  };
};
