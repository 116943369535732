import { useMemo } from 'react';

import { useGetCardsQuery } from '@/modules/transaction/hooks/useGetCards.query';
import { useAuthStore } from '@/modules/user/stores';

import { CardDto } from '../Cards.types';

export default (cardId: string | null) => {
  const { user } = useAuthStore();
  const {
    cardList,
    refetch,
    isLoading,
  } = useGetCardsQuery(user?.employee?.id);

  const card = useMemo(() => cardList
    .find((item) => `${item.id}` === cardId),
  [cardList, cardId]);

  const preparedCard = useMemo(() => ({
    status: card?.status,
    last4Number: card?.last4Number,
    holder: card?.holder || {},
    id: `${card?.id}`,
    bankType: card?.bankType,
    primary: card?.primary,
    expDate: card?.expDate,
    cardRequestDate: card?.cardRequestDate,
    mailedDate: card?.mailedDate,
    isMailedAfterCreationAndOlderThanWeek: card?.isMailedAfterCreationAndOlderThanWeek,
    activationDate: card?.activationDate,
  }), [card]);

  return {

    card: preparedCard as CardDto,
    isLoading,
    refetch,
  };
};
