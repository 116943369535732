import React, { CSSProperties } from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { Modal } from '@/components';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  isHoveringSensitive?: boolean;
  title?: string;
  children: React.ReactNode;
  nonOverflow?: boolean;
  testId?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  hideCloseButton?: boolean;
  titleBoxStyle?: CSSProperties;
  notHideClickOutside?: boolean;
}
const ModalWrapper: React.FC<Props> = ({
  title,
  children,
  onSetVisible,
  isHoveringSensitive,
  visible,
  nonOverflow,
  testId,
  style,
  contentStyle,
  hideCloseButton,
  titleBoxStyle,
  notHideClickOutside,
}) => (

  <Modal
    isHoveringSensitive={isHoveringSensitive}
    visible={visible}
    testId={testId}
    onSetVisible={onSetVisible}
    data-testid="ModalWrapper"
    style={style}
    contentStyle={contentStyle}
    hideCloseButton={hideCloseButton}
    notHideClickOutside={notHideClickOutside}
  >
    <Box direction="column">
      {
        title && (
          <Box
            direction="row"
            align="center"
            justify="center"
            pad={{ bottom: 'spacing24', horizontal: 'l' }}
            style={titleBoxStyle}
          >
            <Text
              size="2xl"
              color="textBody"
              weight="bold"
              textAlign="center"
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {title}
            </Text>
          </Box>
        )
      }
      <Box>
        <Box>
          {children}
        </Box>
      </Box>
    </Box>
  </Modal>
);

export default ModalWrapper;
