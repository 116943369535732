import { useEffect, useState } from 'react';
import { OptionKey } from '@common-fe/common-fe';

import { usePaginationParams } from './usePaginationParams';

const useListPagination = () => {
  const {
    page, setPage, setPerPage, perPage, providerSearchString, setSearchProvider, currentSearch,
  } = usePaginationParams();
  const [statuses, setStatuses] = useState<OptionKey[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChangeDates = (dates : [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  useEffect(() => {
    setPage(1);
  }, [setPage, providerSearchString, statuses,
    startDate, endDate, currentSearch]);
  return {
    page,
    setPage,
    setPerPage,
    perPage,
    providerSearchString,
    setSearchProvider,
    statuses,
    setStatuses,
    onChangeDates,
    startDate,
    endDate,
    currentSearch,
  };
};

export default useListPagination;
