import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { VerificationType } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';
import { QUERY_KEY } from '@/modules/user/queries/useEmployeeViewData.query';

interface VerificationBody {
  code?: string;
  value: string;
  type: VerificationType;
}

interface VerificationPayload {
  verified?: boolean;
}

const useValidateVerifyCodeQuery = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError } = useMutation((body: VerificationBody) =>
    api.patch(PATHS.VALIDATE_VERIFY_CODE, {
      code: body.code,
      value: body.value,
      type: body.type,
    })
  );

  const validateVerifyCode = useCallback(async (body: VerificationBody) => {
    const response = await mutateAsync(body);
    const responseData = response?.data as VerificationPayload;
    if (responseData?.verified) {
      queryClient.invalidateQueries(QUERY_KEY);
    }
    return responseData;
  }, [mutateAsync, queryClient]);

  return {
    validateVerifyCode,
    isLoading,
    isError,
  };
};

export default useValidateVerifyCodeQuery;
