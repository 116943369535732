import {
  useCallback, useEffect, useMemo,
} from 'react';
import { AccountStatus, Enrollment, EnrollmentStatus } from '@common-fe/common-fe';

import { YES_KEY } from '@/common/constants';
import { useInvestor } from '@/modules/investments/hooks';
import useIsFullLiquidation from '@/modules/investments/hooks/useIsFullLiquidation';
import { InvestorStatus } from '@/modules/investments/investments.types';
import { useMyEnrollments } from '@/modules/transaction';

import useAutoInvestmentQuery from '../../queries/useAutoInvestment.query';
import { useAutoInvestmentBannerStore } from '../stores';

interface Props {
  currentMyAccount?: Enrollment;
}

export const STORAGE_KEY = 'isAutoInvestmentDismissed';
export default (props?: Props) => {
  const { mainHSAEnrollment } = useMyEnrollments();
  const displayed = useAutoInvestmentBannerStore((state) => state.displayed);
  const setDisplayed = useAutoInvestmentBannerStore((state) => state.setDisplayed);
  const accountId = useMemo(() =>   `${mainHSAEnrollment ? mainHSAEnrollment.id : props?.currentMyAccount?.id}`,
    [mainHSAEnrollment, props?.currentMyAccount?.id]);
  const {
    data: {
      investorAccountId,
      status,
      investorAccountStatus,
    },
    isSuccess: isInvestorSuccess,
  } = useInvestor(accountId);
  const isInvestorActive = useMemo(() => status === InvestorStatus.ACTIVE, [status]);
  const isInvestorAccountOpen = useMemo(() => investorAccountStatus === AccountStatus.OPEN, [investorAccountStatus]);
  const { isSuccess, isError: isSchedulersError, data, } = useAutoInvestmentQuery(investorAccountId);
  const { isFullLiquidation} = useIsFullLiquidation({accountId});
  const isDisplayedBanner = useMemo(() => displayed && !isFullLiquidation, [displayed, isFullLiquidation]);

  useEffect(() => {
    const currentStorage = localStorage.getItem(STORAGE_KEY) === YES_KEY;

    if (!isInvestorActive && !isInvestorAccountOpen) {
      setDisplayed(false);
      return;
    }
  
    if(isInvestorSuccess && isSchedulersError && mainHSAEnrollment?.enrollmentStatus === EnrollmentStatus.Active && !currentStorage) {
      setDisplayed(true); 
    } else if (isSuccess || !investorAccountId) {
      setDisplayed(false);
    }
   
  }, [
    data.status,
    investorAccountId,
    isInvestorSuccess,
    isSchedulersError, 
    isSuccess,
    mainHSAEnrollment?.enrollmentStatus,
    setDisplayed,
    isInvestorActive,
    isInvestorAccountOpen,
  ]);
  const handleDismiss = useCallback(() => {
    localStorage.setItem(STORAGE_KEY, YES_KEY);
    setDisplayed(false);
  }, [setDisplayed]);

  return {
    handleDismiss,
    isDisplayedBanner,
  };
};
