import React from 'react';
import { Box, Inscription,RoundPendingIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import currentTheme from '@/styles/theme';

const StyledWrapper = styled(Box)`
  position: relative;
  ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 2px;
    left: ${({ theme }) => theme.spacings.spacing24};
    right: ${({ theme }) => theme.spacings.spacing24};
    background: ${({ theme }) => theme.colors.warning};
  };
`;

const Wrapper: React.FC<{ title: string, children: React.ReactNode }> = ({ title, children }) => (
  <StyledWrapper
    data-testId="employee-choice-section-for-waiting-for-funds"
    margin={{ top: 'spacing12' }}
    background="canvas"
    border={{ size: 'small', color: 'border2' }}
  >
    <Box background="module" round="container1Round">
      <Box align="center" background="canvas" pad="spacing32" round="container1Round">
        <RoundPendingIcon color={currentTheme.colors.warning} />
        <Inscription
          margin={{ top: 'spacing16' }}
          size="20px"
          weight={700}
          textAlign="center"
          lineHeight="28px"
        >
          {title}
        </Inscription>
        {children}
      </Box>
    </Box>
  </StyledWrapper>
);

export default Wrapper;
