import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, LinkButton,
  Text,   WarningCard, WarningIcon, } from '@common-fe/common-fe';

import { LINKED_EMPLOYEE_STORAGE_KEY } from '@/common/constants';
import routes from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useOrphanModeStore } from '@/modules/core/store';
import { useSwitchingModalStore } from '@/modules/user/components/UserDrodpdown/components/OrphanModeSwitcher/store';

const MoneyMovedMessage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleReset } = useOrphanModeStore();
  const { setIsModalVisible } = useSwitchingModalStore();

  const handleSwitchToOriginalUser = useCallback(() => {
    history.push(routes.HOME);
    localStorage.removeItem(LINKED_EMPLOYEE_STORAGE_KEY);
    setIsModalVisible(true);
    handleReset();
  }, [handleReset, setIsModalVisible, history]);

  return (
    <WarningCard
      icon={<WarningIcon />}
      title={<Text weight="bold">Great news! Your HSA dollars have been moved to your Individual HSA.</Text>}
      testId="money-moved-message"
    >
      <Box margin={{ top: 'spacing8' }}>
        <Text>
          {t(`Full history of the accounts transactions can continue to be accessed here.
          To take action on your account or view your current balance, switch to your`)}
          <LinkButton
            testId="switcher-to-main-user"
            onClick={handleSwitchToOriginalUser}
            linkInlineMode
          >
            {t(' new HSA.')}
          </LinkButton>
        </Text>
      </Box>
    </WarningCard>
  );
};

export default MoneyMovedMessage;
