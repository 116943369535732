import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { EmploymentType } from '@/modules/investments/investments.types';

import { useStore as usePersonalInformationStore } from '../../stores/usePersonalInformation.store';

export default () => {
  const { t } = useTranslation();
  const options = useMemo(
    () => [
      { key: EmploymentType.AGRICULTURE, value: t('Agriculture, Forestry, Fishing and Hunting') },
      { key: EmploymentType.MINING, value: t('Mining, Quarrying, and Oil and Gas Extraction') },
      { key: EmploymentType.UTILITIES, value: t('Utilities') },
      { key: EmploymentType.CONSTRUCTION, value: t('Construction') },
      { key: EmploymentType.MANUFACTURING, value: t('Manufacturing') },
      { key: EmploymentType.WHOLESALE, value: t('Wholesale Trade') },
      { key: EmploymentType.RETAIL, value: t('Retail Trade') },
      { key: EmploymentType.TRANSPORT, value: t('Transportation and Warehousing') },
      { key: EmploymentType.INFORMATION, value: t('Information') },
      { key: EmploymentType.FINANCE, value: t('Finance and Insurance') },
      { key: EmploymentType.REAL_ESTATE, value: t('Real Estate and Rental and Leasing') },
      { key: EmploymentType.PROFESSIONAL, value: t('Professional, Scientific, and Technical Services') },
      { key: EmploymentType.MANAGEMENT, value: t('Management of Companies and Enterprises') },
      { key: EmploymentType.EDUCATION, value: t('Educational Services') },
      { key: EmploymentType.HEALTH, value: t('Health Care and Social Assistance') },
      { key: EmploymentType.ART, value: t('Arts, Entertainment, and Recreation') },
      { key: EmploymentType.FOOD, value: t('Accommodation and Food Services') },
      { key: EmploymentType.PUBLIC, value: t('Public Administration') },
      { key: EmploymentType.WASTE, value: t('Administrative and Support and Waste Management and Remediation Services') },
    ], [t],
  );
  const defaultFields = usePersonalInformationStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        testId: 'employment-type-field',
        name: 'employmentType',
        type: FieldTypes.Dropdown,
        label: t('What do you call the industry of your company?'),
        options,
        singleMode: true,
        showRequireIcon: true,
        placeholder: t('Select area'),
        defaultValue: '',
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]), [options, t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
