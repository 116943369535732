import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledActionBox = styled(Box)`
  transition: 0.1s;
`;

interface StyledBoxProps {
  clickMode?: boolean;
}

export const StyledBox = styled(Box)<StyledBoxProps>`
  cursor: pointer;
  position: relative ;
  ${({ theme }) => theme.shadows.default};
  ${StyledActionBox} {
    opacity: 0;
    height: 0;
    //transform: translateY(40px);
  }
  &:hover {
    ${({ theme }) => theme.shadows.hover};
    ${StyledActionBox} {
      //transform: translateY(0px);
      height: 40px;
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.button`
  margin: 0px;
  padding: 0px;
  background: none;
  text-align: left;
  border: none;
  &:disabled {
    ${StyledBox} {
      &:hover {
        box-shadow: none;
      }
      cursor: 'pointer';
    }
  }
`;
