import React, { useEffect, useMemo } from 'react';
import {
  AlertIcon,
  Box,
  CompleteIcon,
  CrossIcon,
  Text,
} from '@common-fe/common-fe';

import { SnackbarNotification, SnackbarState } from '@/modules/core/core.types';
import { useHistory, useSnackbar } from '@/modules/core/hooks';
import theme from '@/styles/theme';

type CurrentColor = keyof typeof theme.colors;

type CurrentBorderStyle = {
  size: string;
  color: CurrentColor;
} | boolean;

interface Props {
  onClose(id?: string): void;
  options: SnackbarNotification
}

const SnackbarItem: React.FC<Props> = ({
  options, onClose,
}) => {
  const history = useHistory();
  const { shouldSnackbarDisappear } = useSnackbar();

  const currentBorder: CurrentBorderStyle = useMemo(() => {
    if (options.state === SnackbarState.negative) {
      return { size: '1px', color: 'dangerBorder' };
    }
    if (options.state === SnackbarState.positive) {
      return { size: '1px', color: 'successBorder' };
    }
    return { size: '1px', color: 'border' };
  }, [options.state]);

  const currentBackground: CurrentColor = useMemo(() => {
    if (options.state === SnackbarState.negative) {
      return 'dangerContainer';
    }
    if (options.state === SnackbarState.positive) {
      return 'success';
    }
    return 'background3';
  }, [options.state]);

  useEffect(() => {
    shouldSnackbarDisappear();
  }, [shouldSnackbarDisappear]);

  return (
    <Box
      width={{ min: options.minWidth || '360px' }}
      height={{ min: '40px', max: '40px' }}
      direction="row"
      align="center"
      justify="between"
      round="button2Round"
      background={currentBackground}
      pad={{
        left: 'spacing12',
        right: 'spacing16',
        vertical: 'spacing8',
      }}
      border={currentBorder}
    >
      <Box
        data-testid={`snackbar_item_${currentBackground}`}
        margin={{ right: 'spacing12' }}
        direction="row"
        align="center"
      >
        {options.state === SnackbarState.positive && (
          <Box pad={{ right: 'spacing8' }}>
            <CompleteIcon color="iconOnColor" />
          </Box>
        )}
        {options.state === SnackbarState.negative && (
          <Box pad={{ right: 'spacing8' }}>
            <AlertIcon />
          </Box>
        )}
        <Text color={options.state === SnackbarState.negative ? 'textBody' : 'textOnColor'} size="medium">
          {options.text}
        </Text>
      </Box>
      <Box
        direction="row"
        justify="between"
      >
        {(options.closeIcon && !!options.redirect) && (
          <Box
            margin={{ right: '21px' }}
            direction="row"
            align="center"
          >
            <Box
              data-testid="snackbar_item_redirect_button"
              onClick={() => {
                if (options.redirect) history.push(options.redirect.path);
                onClose(options.id);
              }}
            >
              <Text
                size="medium"
                weight="bold"
                color={
                  options.state !== SnackbarState.negative
                    ? theme.colors.textOnColor
                    : theme.colors.iconAccent
                }
              >
                {options.redirect.label}
              </Text>
            </Box>
          </Box>
        )}
        {options.closeIcon
        && (
          <Box
            data-testid="snackbar_item_close_button"
            style={{ boxShadow: 'none' }}
            onClick={() => onClose(options.id)}
            direction="row"
            align="center"
          >
            <CrossIcon fill={options.state !== SnackbarState.negative ? theme.colors.iconOnColor : theme.colors.iconPrimary} size="14px" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
/* Close plan config */
export default SnackbarItem;
