import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton,Box, Text } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { useInvestor } from '../hooks';

import SelfDirectedFunds from './SelfDirectedFunds';
import SelfDIrectedWrapper from './SelfDirectedWrapper';
import { useSelectedFundsStore } from './stores';

interface Props {
  accountId?: string | null;
  investorId?: string;

}
const SelfDirected:React.FC<Props> = ({ accountId, investorId = '' }) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const { data: { startAvailableToInvest } } = useInvestor(accountId || '');

  const selectedIds =  useSelectedFundsStore((state) => state.selectedFunds); 
  const { t } = useTranslation();

  const history = useHistory();
  const handleBack = useCallback(() => {
    history.push(`${ROUTES.SELF_DIRECTED_QUESTIONNAIRE(accountId)}?investorId=${investorId}`);
  }, [accountId, history, investorId]);
  const isDisabled = useMemo(() => {
    return !selectedIds.length && isSubmitted;
  }, [isSubmitted, selectedIds.length]);
  const handleNext = useCallback(() => {
    setSubmitted(true);
    if(selectedIds.length) {
      history.push(`${ROUTES.SELF_DIRECTED_ALLOCATION(accountId)}?investorId=${investorId}`);
    }
  }, [accountId, history, investorId, selectedIds.length]);
  return (
    <SelfDIrectedWrapper currentStep={3}>
      <Text size="3xl" weight={700}>{t('Select Funds')}</Text>


      <SelfDirectedFunds availableToInvest={startAvailableToInvest} />
      <Box pad={{ top: 'l', bottom: 'l' }} direction="row" justify="end" align="end">
        <Box direction='column' align='end'>
          {isDisabled && (
            <Text margin={{bottom: 'm'}} color="danger">{t('Select at least one fund')}</Text>
          )}
        
          <Box direction='row'>
            <Box>
              <AppButton
                buttonType="secondary"
                width="140px"
                onClick={handleBack}
              >
                {t('Back')}
              </AppButton>
            </Box>
            <Box margin={{ left: 's' }}>
              <AppButton disabled={isDisabled} width="140px" onClick={handleNext}>
                {t('Next (3/4)')}
              </AppButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </SelfDIrectedWrapper>
  );
};
export default SelfDirected;
