import { InvestmentHoldingStatus } from '../investments.types';

export interface FundsPayload {
  status?: InvestmentHoldingStatus;
  id: string;
  logo: string;
  title: string;
  stockCode: string;
  link: string;
  shares?: number;
  price?: number;
  yr3: number | null;
  yr5: number | null;
  category: string;
  assetClass: string;
  isHighlighted?: boolean;
  amount?: number;
  election?: number;
  '@type'?: string;
  isFullLiquidation?: boolean;
}
export const FUNDS_TABS = {
  MUTUAL_FUNDS: 'mutualFunds',
  ALL: 'all',
  SELECTED: 'selected',
};
export interface FundsSearchParams {
  page: number;
  perPage: number;
  searchString?: string;
  sortBy?: string;
  categoryIds?: string[];

}
export interface AllocationPercentsMap {
  [key: string]: number;
}

export interface AutoInvestmentRules {
  instrument_id: string;
  percentage: number;
}
