import { storeCreator } from '@/utils/store/store.utils';

interface State {
  modalVisible: boolean;
  action: string;
  onSubmit: () => void;
  additionalAction?: () => void;
  handleSetAction: (action: string) => void;
  handleSetOnSubmit: (onSubmit: State['onSubmit']) => void;
  handleSetAdditionalAction: (additionalAction: State['additionalAction']) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  action: '',
  onSubmit: () => {},
  additionalAction: () => {},

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleSetAction: (action) => set((state) => ({
    ...state,
    action,
  })),

  handleSetOnSubmit: (onSubmit) => set((state) => ({
    ...state,
    onSubmit,
  })),

  handleSetAdditionalAction: (additionalAction) => set((state) => ({
    ...state,
    additionalAction,
  })),

  handleReset: () => set((state) => ({
    ...state,
    action: '',
    onSubmit: () => {},
    additionalAction: () => {},
  })),
}));
