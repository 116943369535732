import { baseStoreCreator } from '@/utils/store';

export interface State {
  hashId: string;
  link: string;
  claimId: string;
  documentId: string;
  employeeId: string;
  organizationId: string;
  organizationPath: string;
}

export const DEFAULT_VALUES: State = {
  hashId: '',
  link: '',
  claimId: '',
  documentId: '',
  employeeId: '',
  organizationId: '',
  organizationPath: '',
};

export const useOneTimeLinkStore = baseStoreCreator<State>(
  DEFAULT_VALUES,
);

export default useOneTimeLinkStore;
