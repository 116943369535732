import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  ElevateIcon,
  getCurrentBrowser,
  getCurrentOs,
  Image,
  Inscription,
  LargeCloseIcon,
  Preloader,
} from '@common-fe/common-fe';

import { PartnerMobileAppType } from '@/common/constants';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import useOpenDeepLinkFallback from './hooks/useOpenDeepLinkFallback';
import { AnimatedPopupWrapper, ENTER_POPUP_CLASS, EXIT_POPUP_CLASS, IconContainer } from './ContinueInAppPopup.styles';
import { PartnerAppLinks } from './continueInAppPopup.types';

const uniqDarkColor = '#2C2C2C';
const TITLE = 'Speed up this process using our app';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setPopupAvailable?: (isPopupAvailable: boolean) => void;
  appLinks?: PartnerAppLinks;
}

const ContinueInAppPopup: React.FC<Props> = ({
  visible,
  setVisible,
  setPopupAvailable,
  appLinks,
}) => {
  const { t } = useTranslation();
  const { CurrentBrowserIcon } = getCurrentBrowser();
  const { isAndroid, isIOS } = getCurrentOs();
  

  const [currentClass, setCurrentClass] = useState(ENTER_POPUP_CLASS);

  const currentVisible = useMemo(
    () => visible && (isAndroid || isIOS),
    [visible, isAndroid, isIOS],
  );

  const handleClosePopupWithDelay = useCallback(() => {
    setCurrentClass(EXIT_POPUP_CLASS);
    const timeout = setTimeout(() => {
      setVisible(false);
      if (setPopupAvailable) {
        setPopupAvailable(false);
      }
    }, 300);
    return () => clearTimeout(timeout);
  }, [setPopupAvailable, setVisible]);
  
  const isAppAvailable = useMemo(
    () => (appLinks?.partnerMobileApp && appLinks.partnerMobileApp !== PartnerMobileAppType.NONE) ||  appLinks?.defaultAppLink,
    [appLinks]
  );

  const isPartnerAppAvailable = useMemo(
    () => appLinks?.partnerMobileApp && appLinks.partnerMobileApp === PartnerMobileAppType.PARTNER_OWN_APP,
    [appLinks]
  );

  const partnerAppIcon = useMemo(() => appLinks?.logoInfo?.faviconUrl, [appLinks]);
  
  const {handleOpen, isLoading} = useOpenDeepLinkFallback();

  const openAndroidApp = useCallback(() => {
    if (isPartnerAppAvailable && appLinks?.partnerAppAndroidLink) {
      handleOpen({
        deepLink: appLinks?.partnerAppAndroidLink,
        fallbackLink: whiteLabelingConfig.androidAppStoreLink as string
      });
      return;
    }

    handleOpen({
      deepLink: appLinks?.defaultAppLink as string,
      fallbackLink: whiteLabelingConfig.androidAppStoreLink as string
    });
    
  }, [isPartnerAppAvailable, appLinks?.partnerAppAndroidLink, appLinks?.defaultAppLink, handleOpen]);

  const openIosApp = useCallback(() => {
    if (isPartnerAppAvailable && appLinks?.partnerAppIosLink) {
      handleOpen({
        deepLink: appLinks?.partnerAppIosLink,
        fallbackLink: whiteLabelingConfig.IOSAppStoreLink || ''
      });
      return;
    }
  
    handleOpen({
      deepLink: appLinks?.defaultAppLink || '',
      fallbackLink: whiteLabelingConfig.IOSAppStoreLink || ''
    });

  }, [isPartnerAppAvailable, appLinks?.partnerAppIosLink, appLinks?.defaultAppLink, handleOpen]);

  const handleOpenAppStore = useCallback(() => {
    if (isAndroid) {
      openAndroidApp();
    } else if (isIOS) {
      openIosApp();
    }
  }, [isAndroid, isIOS, openAndroidApp, openIosApp]);


  if (!currentVisible || !isAppAvailable) return null;
  return (
    <AnimatedPopupWrapper
      data-testid="ContinueInAppPopup_wrapper"
      className={currentClass}
      pad="20px"
      background={{ color: uniqDarkColor }}
      round={{ corner: 'top', size: '30px' }}
      style={{
        position: 'fixed',
        zIndex: 1000000,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ bottom: '14px' }}
        margin={{ bottom: '14px' }}
        border={{ side: 'bottom', size: '1px', color: 'canvas' }}
      >
        <Inscription weight={400} lineHeight="28px" color="canvas">
          {TITLE}
        </Inscription>
        <Box onClick={handleClosePopupWithDelay}>
          <LargeCloseIcon color="canvas" size="28px" />
        </Box>
      </Box>

      <Box gap="spacing32">
        {isAppAvailable && (
          <Box direction="row" justify="between" align="center">
            <Box direction="row" align="center" gap="spacing12">
              <IconContainer>
                {partnerAppIcon && isPartnerAppAvailable ? (
                  <Image
                    src={partnerAppIcon}
                    height={35}
                    width={35}
                    fit="contain"
                  />
                ) : whiteLabelingConfig.mobileAppIcon ? (
                  <Image
                    src={whiteLabelingConfig.mobileAppIcon}
                    height={35}
                    width={35}
                    fit="contain"
                  />
                ) :  (
                  <ElevateIcon color="iconAccent" size="35px" />
                )}
              </IconContainer>
              <Inscription size="17px" weight={500} color="textOnColor" lineHeight="28px">
                {whiteLabelingConfig?.mobileAppTitle || t('Elevate app')}
              </Inscription>
            </Box>
            <AppButton
              testId="ContinueInAppPopup_app"
              width="100px"
              style={{
                height: '48px',
              }}
              onClick={handleOpenAppStore}
            >
              <Inscription size="17px" weight={400}>
                {isLoading ? <Preloader color="white" /> : t('Open')}
              </Inscription>
            </AppButton>
          </Box>
        )}
        <Box direction="row" justify="between" align="center">
          <Box direction="row" align="center" gap="spacing12">
            <IconContainer>
              <CurrentBrowserIcon />
            </IconContainer>
            <Inscription size="17px" weight={500} color="textOnColor" lineHeight="28px">
              {t('Browser')}
            </Inscription>
          </Box>
          <AppButton
            testId="ContinueInAppPopup_browser"
            width="100px"
            buttonType="secondary"
            style={{
              paddingInline: 0,
              backgroundColor: 'transparent',
              height: '48px',
            }}
            onClick={handleClosePopupWithDelay}
          >
            <Inscription size="17px" weight={400} color="canvas">
              {t('Continue')}
            </Inscription>
          </AppButton>
        </Box>
      </Box>

    </AnimatedPopupWrapper>
  );
};

export default ContinueInAppPopup;
