import { useFavicon,useImage } from '@common-fe/common-fe';

import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const useOrganizationFavicon = () => {
  const { organizationProperties } = useOrganizationProperties();

  const faviconUrl = organizationProperties?.faviconUrl;
  const minimizedLogoUrl = organizationProperties?.minimizedLogoUrl;
  const { isLoading, isError } = useImage(faviconUrl || minimizedLogoUrl);
  if(whiteLabelingConfig.favicon) {
    return;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useFavicon(faviconUrl, isLoading, isError);
};

export default useOrganizationFavicon;
