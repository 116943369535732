import React from 'react';
import { Box } from '@common-fe/common-fe';

interface Props {
  height?: string;
}

export const ReimburseFooterBackground: React.FC<Props> = ({ height = '88px' }) => (
  <Box
    background="module"
    height={height}
    width="100vw"
    style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 0,
    }}
  />
);
