import React, { useCallback, useState } from 'react';
import { Controller,useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { resendSignUpCode } from '@aws-amplify/auth';
import { Anchor, Box, Text } from '@common-fe/common-fe';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';

import { ExternalLink, REQUIRED_TEXT } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { AccountLockoutModal } from '@/modules/user/components/SignIn/MultiFactorAuthentication/components';
import { MFAChannel, MFAChannelType } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';
import useMFAStore from '@/modules/user/components/SignIn/MultiFactorAuthentication/stores/useMFA.store';
import { EMAIL_QUERY_KEY, USERNAME_QUERY_KEY } from '@/modules/user/components/SignUp/signUp.constants';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import { LoginPayload } from './hooks/useSignIn';
import MultiFactorAuthenticationModals from './MultiFactorAuthentication/MultiFactorAuthenticationModals';
import { useSignIn } from './hooks';
import {
  Form,
  FormActions,
  FormFooter,
  FormHelpText,
  SignInTextInput,
  SinInAppButton
} from './SignInForm.styles';

const ACCOUNT_LOCKED_MINIMUM_TIME = 1;
export const UNCONFIRMED_USER_ERROR_CODE = '40108';

const getDefaultChannel = (channels: MFAChannel[]) => channels?.find((channel) => channel.isDefault)
  || channels?.find((channel) => channel.type === MFAChannelType.EMAIL)
  || channels?.[0];

interface SignInProps {
  disabled?: boolean;
  onForgot: () => void;
  children?: React.ReactNode;
  isChangePasswordMode?: boolean;
  onForgotUsername?: () => void;
}

const SignIn: React.FC<SignInProps> = ({disabled, children, onForgot, onForgotUsername }) => {
  const { t } = useTranslation();
  const { setActiveChannel, setChannels, setSession, setUserName } = useMFAStore();
  const { push } = useHistory();
  const [isMFAModalVisible, setIsMFAModalVisible] = useState(false);
  const [isAccountLockedModalVisible, setIsAccountLockedModalVisible] = useState(false);
  const [lockingLeft, setLockingLeft] = useState<number>();
  const {
    control, formState: { errors }, handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object().shape({
      username: yup.string().required(REQUIRED_TEXT),
      password: yup.string().required(REQUIRED_TEXT),
    })),
  });

  const handleShowAccountLockedModal = useCallback((lockingLeft: number) => {
    setIsMFAModalVisible(false);
    setIsAccountLockedModalVisible(true);
    setLockingLeft(lockingLeft || ACCOUNT_LOCKED_MINIMUM_TIME);
  }, []);

  const { handleLogin, isError } = useSignIn();

  const handleUnconfirmedUserError = useCallback(async (data: LoginPayload) => {
    const response = await resendSignUpCode({
      username: data?.username,
    });

    const userEmail = response?.deliveryMedium === 'EMAIL' ? response?.destination : '';
    push(`${ROUTES.VERIFY}?${USERNAME_QUERY_KEY}=${data?.username}&${EMAIL_QUERY_KEY}=${userEmail}`);
  }, [push]);

  const onSubmit = async (data: LoginPayload) => {
    try {
      const authResponse = await handleLogin(data);
      const channels = authResponse?.channels;
      if (channels?.length) {
        const defaultChannel = getDefaultChannel(channels);
        setActiveChannel(defaultChannel);
        setChannels(channels);
        setSession(authResponse?.session || '');
        setUserName(data?.username);
        setIsMFAModalVisible(true);
      }
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.elevate_error_message', '') as string;
      const lockingLeft = _.get(error, 'response.data.locking_left', 10) as number;
      const authErrorCode = _.get(error, 'response.data.auth_error_code', '') as string;

      if (errorMessage.includes('user account is locked')) {
        handleShowAccountLockedModal(lockingLeft);
      }

      if (authErrorCode === UNCONFIRMED_USER_ERROR_CODE) {
        handleUnconfirmedUserError(data);
      }
    }
  };

  return (
    <>
      <Box>
        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Text
              size="large"
              weight="bold"
              margin={{ bottom: 'spacing12' }}
              style={{
                lineHeight: '22px',
              }}
            >
              Username
            </Text>
            <Controller
              // @ts-ignore
              name="username"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box margin={{ bottom: 'spacing24' }}>
                  <SignInTextInput
                    className="text-field"
                    value={value}
                    isLarge
                    onChange={onChange}
                    name="username"
                    label={t('Username')}
                    placeholder={t('Username')}
                    error={!!errors.username?.message || isError}
                    testId="username_input"
                    isLowerCaseMode
                  />
                </Box>
              )}
            />
            <Text
              size="large"
              weight="bold"
              margin={{ bottom: 'spacing12' }}
              style={{
                lineHeight: '22px',
              }}
            >
              Password
            </Text>
            <Controller
              // @ts-ignore
              name="password"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box margin={{ bottom: 'spacing24' }}>
                  <SignInTextInput
                    className="text-field"
                    value={value}
                    isLarge
                    onChange={onChange}
                    label={t('Password')}
                    placeholder={t('Password')}
                    inputType="password"
                    name="password"
                    testId="password_input"
                    errorText={errors.password?.message || (isError ? t('Username or password is wrong') : '')}
                  />
                </Box>
              )}
            />
            {children}
            <SinInAppButton disabled={disabled} width="100%" className="button" type="submit" size="L">
              {t('Sign In')}
            </SinInAppButton>

          </Form>
        </Box>
        <FormHelpText>
          {t('By clicking Sign In, you agree to')}
          {' '}
          <Anchor
            target="_blank"
            
            href={
              whiteLabelingConfig?.termsAndConditions || ExternalLink.termsAndCondition
            }
          >
            {t('Terms and Conditions')}
          </Anchor>
          {' '}
          {t('and have read and acknowledged our')}
          {' '}
          <Anchor target="_blank"
            href={
              whiteLabelingConfig?.privacyPolicy || ExternalLink.privacyPolicy
            }>
            {t('Privacy Policy.')}
          </Anchor>
        </FormHelpText>
        <FormActions>
          <Anchor
            color="brand"
            size="large"
            disabled={disabled}
            weight="bold"
            data-testid="auth_forgot-username_button"
            onClick={onForgotUsername}
          >
            {t('Forgot Username?')}
          </Anchor>
          <Box
            margin={{ horizontal: 'spacing8' }}
            background="border"
            width={{ min: '4px' }}
            height={{ min: '4px' }}
            round="100%"
          />
          <Anchor
            type="button"
            data-testid="button-forgot-password"
            // name="forgot_password"
            onClick={onForgot}
            size="large"
            weight="bold"
            disabled={disabled}
            style={{
              height: '22px',
            }}
          >
            {t('Forgot Password?')}
          </Anchor>
          {/* <RedirectButton type="button" onClick={() => {}}>
            {t('Forgot Username?')}
          </RedirectButton> */}
        </FormActions>
        <FormFooter>
          <Text style={{ fontSize: 'inherit' }}>{t('Need an account? ')}</Text>
          {disabled ? (
            <Anchor
              size="large"
              disabled
              weight="bold"
            >
              {t('Register')}
            </Anchor>
          ) : (
            <Link to={ROUTES.SIGN_UP}>
              <Anchor
                size="large"
                weight="bold"
              >
                {t('Register')}
              </Anchor>
            </Link>
          )}
          
        </FormFooter>
      </Box>
      {isMFAModalVisible && (
        <MultiFactorAuthenticationModals
          onSetVisible={setIsMFAModalVisible}
          onShowAccountLockedModal={handleShowAccountLockedModal}
        />
      )}
      {isAccountLockedModalVisible && (
        <AccountLockoutModal
          onSetVisible={setIsAccountLockedModalVisible}
          lockingLeft={lockingLeft}
        />
      )}
    </>
  );
};

export default SignIn;
