import React from 'react';
import { Box,Grid } from '@common-fe/common-fe';
import styled from 'styled-components';

const StyledDropWrapper = styled(Box)`
  @media ${({ theme }) => theme.deviceSize.lessThen.laptopL} {
    width: 100%;
  }
`;

interface Props {
  children: React.ReactNode;
  isSmallViewVersion?: boolean;
  isFlex?: boolean;
}

const DropWrapper: React.FC<Props> = ({ children, isSmallViewVersion, isFlex }) => {
  if (isSmallViewVersion) {
    return (
      <StyledDropWrapper width={isFlex ? '100%' : '324px'} flex={{ grow: 1 }} direction="column-reverse">
        {children}
      </StyledDropWrapper>
    );
  }

  return (
    <Grid
      columns={['96px', 'auto']}
      height="453px"
      style={{ position: 'relative' }}
    >
      {children}
    </Grid>
  );
};

export default DropWrapper;
