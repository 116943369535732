import React from 'react';
import { Box, Carousel } from '@common-fe/common-fe';

import { useAuthStore } from '@/modules/user/stores';

import useBannersList from './hooks/useBannersList';

const BannersList = () => {
  const bannersList = useBannersList();
  const { user } = useAuthStore();
  if (!bannersList.length) return null;

  return (
    <Box margin={{ top: 'l' }} key={user?.id} data-testId="banners-list">
      <Carousel
        width="100%"
        slides={bannersList}
      />
    </Box>
  );
};

export default BannersList;
