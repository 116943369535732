import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Enrollment } from '@common-fe/common-fe';

import { YES_KEY } from '@/common/constants';
import { useInvestor } from '@/modules/investments/hooks';
import { useMyEnrollments } from '@/modules/transaction';

import useAutoInvestmentQuery from '../../queries/useAutoInvestment.query';
import { useAutoInvestmentReminderStore } from '../stores';

interface Props {
  currentMyAccount?: Enrollment;
  accountId?: string;
}

export const STORAGE_KEY = 'isAutoInvestmentReminderDismissed';
export default (props?: Props) => {

  const { mainHSAEnrollment } = useMyEnrollments();
  const displayed = useAutoInvestmentReminderStore((state) => state.displayed);
  const setDisplayed = useAutoInvestmentReminderStore((state) => state.setDisplayed);

  const { data: { investorAccountId } } = useInvestor(`${mainHSAEnrollment ? mainHSAEnrollment.id : props?.currentMyAccount?.id || props?.accountId }`);

  const { isSuccess, isError, data } = useAutoInvestmentQuery(investorAccountId);

  const isDisplayedReminder = useMemo(() => displayed, [displayed]);

  useEffect(() => {
    const currentStorage = localStorage.getItem(STORAGE_KEY) === YES_KEY;
    if (isError && !currentStorage && investorAccountId) {
      setDisplayed(true);
    }
    if (isSuccess || !investorAccountId) {
      setDisplayed(false);
    }
  }, [data.status, investorAccountId, isError, isSuccess, setDisplayed]);

  const handleDismiss = useCallback(() => {
    localStorage.setItem(STORAGE_KEY, YES_KEY);
    setDisplayed(false);
  }, [setDisplayed]);

  return {
    handleDismiss,
    isDisplayedReminder,
  };
};
