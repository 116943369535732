import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  LinkButton,
  Text, 
  WarningCard,
  WarningIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import regexp from '@/common/regexp';
import ROUTES from '@/common/routes';
import { useOcrWarningsStore } from '@/modules/transaction/components/ReimburseMe/ClaimInfo/ClaimWarningBanner/store';
import { OcrWarningType } from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';

const ClaimDuplicateWarningWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.spacings.spacing12};

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    margin-bottom: ${({ theme }) => theme.spacings.spacing12};
    margin-top: 0px;
  }
`;

const ClaimDuplicateWarning = () => {
  const { t } = useTranslation();
  const ocrWarningsList = useOcrWarningsStore((state) => state.ocrWarningsList);

  const duplicateWarning = useMemo(
    () => ocrWarningsList.find((warning) => warning.type === OcrWarningType.DUPLICATE_RECEIPT),
    [ocrWarningsList]);

  const duplicateClaim = useMemo(() => duplicateWarning?.duplicateClaim, [duplicateWarning]);
  const duplicateClaimId = useMemo(() => duplicateClaim?.duplicateClaimId, [duplicateClaim]);
  const duplicateClaimStatus = useMemo(
    () => duplicateClaim?.claimStatusType?.toLocaleLowerCase()?.replace(regexp.DASH_SYMBOL, ' '),
    [duplicateClaim]);

  const handleOpenClaim = useCallback(() => {
    window.open(
      ROUTES.EXPENSES_TRANSACTION_DETAILS_CLAIM(duplicateClaimId),
      '_blank'
    );
  }, [duplicateClaimId]);

  if (!duplicateWarning) return null;

  return (
    <ClaimDuplicateWarningWrapper>
      <WarningCard
        icon={<WarningIcon />}
        pad="spacing12"
        background="canvas"
        topBorderMargin="spacing12"
        elevation="default"
        title={(
          <Text weight={700}>
            {t(`It looks like this expense has already been submitted, and it is in status ${duplicateClaimStatus}.`)}
          </Text>
        )}
        containerProps={{
          pad: { horizontal: 'spacing24', vertical: 'spacing16' },
          round: 'container2Round',
        }}
      >
        <Box margin={{ top: 'spacing8' }}>
          <Text>
            {t('You may ')}
            <LinkButton
              onClick={handleOpenClaim}
              linkInlineMode
            >
              <Text style={{ textDecoration: 'underline' }}>{t('view the claim details')}</Text>
            </LinkButton>
            {t(' or, if this is not the same expense, continue submitting this expense for review.')}
          </Text>
        </Box>
      </WarningCard>
    </ClaimDuplicateWarningWrapper>
  );
};

export default ClaimDuplicateWarning;
