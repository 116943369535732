import React, { useEffect } from 'react';
import { Box } from '@common-fe/common-fe';

import useHighlightedPaymentMethodStore from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/stores/useHighlightedPaymentMethod.store';

import PaymentMethodsList from './components/PaymentMethodsList/PaymentMethodsList';
import ReimbursementOptionsHeader from './components/ReimbursementOptionsHeader/ReimbursementOptionsHeader';

interface Props {
  isFetching: boolean;
  refetchPaymentMethods: () => void;
}

const ReimbursementOptions: React.FC<Props> = ({ isFetching, refetchPaymentMethods }) => {
  const { reset } = useHighlightedPaymentMethodStore();

  useEffect(() => {
    return () => {
      reset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box round="moduleRound" pad="spacing24" background="module">
      <ReimbursementOptionsHeader />
      <PaymentMethodsList
        isFetching={isFetching}
        refetchPaymentMethods={refetchPaymentMethods}
      />
    </Box>
  );
};

export default ReimbursementOptions;
