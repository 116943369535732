import { useMemo } from 'react';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { useOrganizationProperties } from '@/modules/transaction';

enum OrgTerminationStatuses {
  PENDING_TERMINATION = 'PENDING_TERMINATION',
  TERMINATION_IN_PROGRESS = 'TERMINATION_IN_PROGRESS',
}

const useEmployerTerminationBanner = () => {
  const { organizationProperties } = useOrganizationProperties();

  const terminationDate = useMemo(
    () => organizationProperties?.terminationDate
      ? dayjs(organizationProperties.terminationDate).format(DEFAULT_DATE_FORMAT)
      : undefined,
    [organizationProperties]);

  const currentOrgStatus = useMemo(
    () => organizationProperties?.currentOrgStatus,
    [organizationProperties]);

  const showBanner = useMemo(
    () => terminationDate
      && currentOrgStatus === OrgTerminationStatuses.TERMINATION_IN_PROGRESS,
    [currentOrgStatus, terminationDate]);
  
  const organizationId = useMemo(
    () => organizationProperties?.id,
    [organizationProperties]);

  const bannerHeight: string | undefined = useMemo(() => showBanner ? '56px' : undefined, [showBanner]);
    

  return {
    showBanner,
    terminationDate,
    bannerHeight,
    organizationId,
    organizationProperties,
  };
};

export default useEmployerTerminationBanner;
