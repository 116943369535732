import prepareFile from '@/components/controls/DropScan/prepareFile';
import { getFileInBlob } from '@/utils/modifiers';

const convertFileToFormData = async (file: File | undefined) => {
  if (file) {
    const preparedFile = prepareFile(file);
    const blob = await getFileInBlob(preparedFile.preview);
    const formData = new FormData();
    formData.append('file', blob as Blob, preparedFile.name || preparedFile.path);
    return formData;
  }
  throw new Error();
};

export default convertFileToFormData;
