import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  FlexControlledForm,
  Preloader,
  Text,
} from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';

import useCardReplaceModalStore from '../../store/useCardReplaceModal.store';

import useCardAddressModalFields from './hooks/useCardAddressModalFields';
import useCardReplacementQuery from './hooks/useCardReplacement.query';
import useCardReplacementReasonFields from './hooks/useCardReplacementReasonFields';
import { useGetReplaceAddressQuery } from './queries/useGetReplaceAddress.query';

const EMPTY_VALUE = '-';

interface Props {
  onCancel?: (value: boolean) => void;
  setDoneMode?: () => void;
}

const CardReplaceFormModal: React.FC<Props> = ({ onCancel, setDoneMode }) => {
  const [cardSetupReason, setCardSetupReason] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const { onSave, isLoading } = useCardReplacementQuery();
  const { address } = useGetReplaceAddressQuery();

  const currentAddressParams = useMemo(() => ({
    STREET_ADDRESS_1: address?.line1 || EMPTY_VALUE,
    STREET_ADDRESS_2: address?.line2 || EMPTY_VALUE,
    CITY: address?.city || EMPTY_VALUE,
    STATE: address?.state || EMPTY_VALUE,
    ZIP_CODE: address?.zipcode || EMPTY_VALUE,
  }), [address]);

  const addressFields = useCardAddressModalFields({
    defaultFields: currentAddressParams,
  });

  const {
    state:
      {
        cardID,
        cardStatus,
        mailedDate,
        activationDate,
      },
    handleReset,
  } = useCardReplaceModalStore();
  const replacementFields = useCardReplacementReasonFields({
    cardStatus,
    mailedDate,
    activationDate,
  });

  const showError = useMemo(
    () => isSubmit && (!cardSetupReason),
    [cardSetupReason, isSubmit],
  );

  const [formValues, setFormValues] = useState({
    reason: '',
  });

  const handleChangeReasonValues = useCallback((values: { reason: string}) => {
    const newValues = { ...values };
    const filledObj = Object.values(newValues).every(Boolean);
    setFormValues((prev) => ({ ...prev, ...values }));
    if (filledObj) {
      setCardSetupReason(true);
    } else setCardSetupReason(false);
  }, []);

  const handleSubmit = useCallback(() => {
    setSubmit(true);
    if (cardSetupReason) {
      onSave({
        cardID,
        data: formValues,
      });

      if (!isLoading && setDoneMode) {
        setDoneMode();
      }
    }
  }, [cardID, cardSetupReason, formValues, isLoading, onSave, setDoneMode]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
      handleReset();
    }
  }, [handleReset, onCancel]);

  return (
    <Box>
      <Box direction="column">
        <Box round="container1Round" pad="spacing24" background="module">
          <Box data-testid="ReplacementReasonForm" round="container1Round" background="canvas">
            <FlexControlledForm
              // formTitle="Why you need a new card?"
              fields={replacementFields}
              editMode
              onChangeValues={handleChangeReasonValues}
              showError={showError}
              isModalType
            />
          </Box>
          {cardSetupReason
            && (
              <Box
                data-testid="ReplacementAddressForm"
                round="container1Round"
                background="canvas"
                margin={{ top: 'spacing24' }}
              >
                <FlexControlledForm
                  formTitle="Your Card Will Be Sent To"
                  subTitle={(
                    <Box margin={{ top: 'spacing4', bottom: '20px' }}>
                      <Text
                        size="12px"
                        weight={500}
                        color="textSecondary"
                      >
                        If this address is incorrect, please update with your employer.
                      </Text>
                    </Box>
                  )}
                  fields={addressFields}
                  editMode
                  onChangeValues={() => null}
                  isModalType
                />
              </Box>
            )}
        </Box>
      </Box>
      <Box
        direction="row"
        fill="horizontal"
        margin={{ top: showError ? 'spacing12' : 'spacing24' }}
        align="end"
        justify="end"
        width="medium"
        gap="xs"
      >
        <AppButton
          buttonType="secondary"
          width="control"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </AppButton>
        <AppButton
          disabled={isLoading}
          type="button"
          onClick={handleSubmit}
          width="control"
        >
          {isLoading ? <Preloader color="white" /> : 'Submit'}
        </AppButton>
      </Box>
    </Box>
  );
};

export default CardReplaceFormModal;
