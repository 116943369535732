import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box } from '@common-fe/common-fe';

import Link from '@/modules/core/components/Link';

interface Props {
  path?: string;
  onDismiss?: () => void;
}
const NotifiacationListActions: React.FC<Props> = ({ path = '', onDismiss }) => {
  const { t } = useTranslation();
  const handleDismiss = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onDismiss) {
      onDismiss();
    }
  }, [onDismiss]);
  return (
    <Box direction="row" justify="between" align='center' margin={{ right: 'm' }}>
      {path ? (
        <Box margin={{bottom: '3px'}}>
          <Link to={path}>
            <Anchor size="medium" weight={400} style={{lineHeight: 1}}>
              {t('Go to page')}
            </Anchor>
          </Link>
        </Box>
      ) : <Box />}

      
      <Anchor
        size="medium"
        data-testid="notification_dismiss_button"
        onClick={handleDismiss}
        weight={400}
        style={{lineHeight: 1}}
        
        
      >
        {t('Dismiss')}

      </Anchor>
      
    </Box>
  );
};
export default NotifiacationListActions;
