import { Option, OptionKey } from '@common-fe/common-fe';

export interface CommunicationOption extends Option {
  isActive?: boolean;
  isSelected?: boolean;
  onClick?: (value: OptionKey) => void;
}

export enum VerificationType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum PhoneVerificationStep {
  ENTER_PHONE_NUMBER = 'ENTER_PHONE_NUMBER',
  PHONE_VERIFICATION_CODE = 'PHONE_VERIFICATION_CODE',
  PHONE_VERIFIED_SUCCESSFULLY = 'PHONE_VERIFIED_SUCCESSFULLY',
}

export enum EmailVerificationStep {
  ENTER_EMAIL = 'ENTER_EMAIL',
  EMAIL_VERIFICATION_CODE = 'EMAIL_VERIFICATION_CODE',
  EMAIL_VERIFIED_SUCCESSFULLY = 'EMAIL_VERIFIED_SUCCESSFULLY',
}

export enum CommunicationPreferencesError {
  CODE_IS_INVALID = 'Code is invalid',
}
