import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import { useHorizontalMenuMode } from '@/modules/core/hooks';
import { useShowLockBanner } from '@/modules/core/hooks';
import useAllowNotifications from '@/modules/core/hooks/useAllowNotifications';
import useEmployerTerminationBanner from '@/modules/core/hooks/useEmployerTerminationBanner';
import { NotificationButton } from '@/modules/notification';
import { UserDropdown } from '@/modules/user';
import { useSwitchPopupStore } from '@/modules/user/components/UserDrodpdown/stores';
import { UserSwitchPopupInfo } from '@/modules/user/components/UserDrodpdown/UserSwitchPopupInfo';

import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';

const HEADER_HEIGHT = 72;
const LOCK_BANNER_HEIGHT = 62;
const POPUP_OFFSET = '-22px';

interface Props {
  dashboardMode?: boolean;
}

const Topbar: React.FC<Props> = ({ dashboardMode }) => {
  const { isPopupVisible, setIsPopupVisible } = useSwitchPopupStore();
  const isHorizontalMenu = useHorizontalMenuMode();
  const isNotificationsAllowed = useAllowNotifications();
  const isLockBannerShowed = useShowLockBanner();
  const { bannerHeight } = useEmployerTerminationBanner();
  const calculatedHeaderLinePosition = useMemo(
    () => isLockBannerShowed || bannerHeight && dashboardMode ? LOCK_BANNER_HEIGHT + HEADER_HEIGHT : HEADER_HEIGHT,
    [isLockBannerShowed, dashboardMode, bannerHeight]
  );
  return (
    <>
      <Box
        direction="row"
        height={`${HEADER_HEIGHT}px`}
        justify="between"
        align="center"
        margin={{ bottom: 'spacing24' }}
      >
        <Breadcrumbs />
        {!isHorizontalMenu
        && (
          <Box direction="row" align="center">
            {isNotificationsAllowed && (
              <NotificationButton />
            )}
            <UserDropdown />
          </Box>
        )}
      </Box>
      <Box
        width="200%"
        height="1px"
        background={{ color: 'border1' }}
        style={{
          position: 'absolute',
          top: calculatedHeaderLinePosition,
          left: 0,
        }}
      />
      {isPopupVisible
      && (
        <>
          <Box
            width="100%"
            justify="end"
            style={{
              position: 'relative',
              top: POPUP_OFFSET,
            }}
          >
            <UserSwitchPopupInfo onSetVisible={setIsPopupVisible} />
          </Box>
          <Box
            background={{ color: 'popup2' }}
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              left: 0,
              zIndex: 100,
            }}
          />
        </>
      )}
    </>
  );
};
export default Topbar;
