import { useCallback } from 'react';

import { api } from '@/api';
import { PATHS } from '@/common';
import useFileDownloadByURL from '@/modules/core/hooks/useFileDownloadByURL';

const getFilesByDocumentId = (documentId?: string) => api.get(PATHS.GET_FILES_BY_DOCUMENT_ID(documentId));

const useDownloadFileById = () => {
  const handleDownloadFileByURL = useFileDownloadByURL();

  const handleDownload = useCallback(
    async (fileId: string) => {
      const response = await getFilesByDocumentId(fileId);
      const fileUrl = response?.data?.files?.[0]?.s3_url;

      if (fileUrl) {
        const fileResponse = await fetch(fileUrl);
        const blob = await fileResponse.blob();
        const blobUrl = URL.createObjectURL(blob);
        handleDownloadFileByURL(blobUrl);
      }
    },
    [handleDownloadFileByURL]
  );

  return { handleDownload };
};

export default useDownloadFileById;
