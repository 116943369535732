import React, { useState } from 'react';

import { FileImage } from '@/common/constants';
import {
  TransactionDocumentSuccessfullyUploadedPopup,
  TransactionDocumentUploadingPopup,
  TransactionUploadDocumentErrorPopup,
  TransactionUploadDocumentPopup,
} from '@/modules/transaction/components/TransactionDetails/TransactionFileOnlyUploadPopup/components';
import { TransactionFileUploadSteps } from '@/modules/transaction/components/TransactionDetails/TransactionFileOnlyUploadPopup/transactionFileOnlyUploadPopup.types';

interface Props {
  claimId?: string;
  onSetVisible: (value: boolean) => void;
}

const TransactionFileOnlyUploadPopup: React.FC<Props> = ({ claimId, onSetVisible }) => {
  const [step, setStep] = useState(TransactionFileUploadSteps.UPLOAD_DOCUMENT);
  const [selectedFile, setSelectedFile] = useState<FileImage | undefined>();

  return (
    <>
      {step === TransactionFileUploadSteps.UPLOAD_DOCUMENT && (
        <TransactionUploadDocumentPopup
          claimId={claimId}
          onSetVisible={onSetVisible}
          setStep={setStep}
          setSelectedFile={setSelectedFile}
        />
      )}
      {step === TransactionFileUploadSteps.UPLOADING && (
        <TransactionDocumentUploadingPopup
          onSetVisible={onSetVisible}
        />
      )}
      {step === TransactionFileUploadSteps.SUCCESSFULLY_UPLOADED && (
        <TransactionDocumentSuccessfullyUploadedPopup onSetVisible={onSetVisible} />
      )}
      {step === TransactionFileUploadSteps.ERROR && (
        <TransactionUploadDocumentErrorPopup
          claimId={claimId}
          onSetVisible={onSetVisible} 
          setStep={setStep}
          selectedFile={selectedFile}
        />
      )}
    </>
  );
};

export default TransactionFileOnlyUploadPopup;
