import { useMemo } from 'react';
import { GagueChartItem } from '@common-fe/common-fe';
import _ from 'lodash';

import theme from '@/styles/theme';

import { useDeductiblesQuery } from '../queries';
import { DeductibleType } from '../queries/useDeductibles.query';

interface Params {
  isIndividual?: boolean;
  userId?: string;
  employeeId?: string;
  focusedUserId?: string;
}

interface AccumulatedItem {
  currentIndex?: number;
  isThreshold?: boolean;
  dependentId?: string | null;
  employeeId?: string | null;
  value: number;
}
interface AccumulatedReduce {
  index: null | number;
  items: AccumulatedItem[];
}

export default ({
  userId, isIndividual, focusedUserId, employeeId,
}: Params) => {
  const {
    isLoading: isDeductiblesLoading,
    formattedDeductiblesData,
    formattedTiersData,
  } = useDeductiblesQuery(
    employeeId,
  );
  const isLoading = useMemo(
    () => isDeductiblesLoading,
    [isDeductiblesLoading],
  );

  const individualAccumulatedValues = useMemo(() => {
    const reversedTiers = [...formattedTiersData].reverse();
    const accumulated = reversedTiers.reduce(
      (result: AccumulatedReduce, item, index) => {
        const foundFamilyTier = formattedDeductiblesData.find(
          (deductible) => deductible.type === DeductibleType.Family,
        );
        const individualTier = formattedDeductiblesData.find(
          (deductible) => deductible.type === DeductibleType.Individual,
        );
        if (foundFamilyTier && individualTier) {
          const filteredItems = formattedDeductiblesData.filter(
            (currentDeductible) => currentDeductible.type === DeductibleType.Individual,
          );
          const items = filteredItems.map((filteredItem) => ({
            currentIndex: index,
            employeeId: filteredItem.employeeId || null,
            dependentId: filteredItem.dependentId || null,
            value: filteredItem.accumulated,
          }));
          return {
            index,
            items,
          };
        }

        if (_.isNumber(result.index) && result.index < index) {
          const threshold = item.triggers.reduce(
            (triggerResult, trigger) => {
              if ((trigger.type === DeductibleType.Embedded) && trigger.threshold) {
                return Math.min(triggerResult, trigger.threshold);
              }
              return triggerResult;
            }, Infinity,
          );

          return {
            ...result,
            items: [...result.items, {
              currentIndex: index,
              isThreshold: true,
              dependentId: null,
              value: threshold,
            }],
          };
        }
        return result;
      }, {
        index: null,
        items: [],
      },
    );
    return accumulated?.items;
  }, [formattedTiersData, formattedDeductiblesData]);

  const familyAccumulatedValues = useMemo(() => {
    const reversedTiers = [...formattedTiersData].reverse();

    const accumulated = reversedTiers.reduce(
      (result: AccumulatedReduce, item, index) => {
        const foundFamilyTier = formattedDeductiblesData.find(
          (deductible) => deductible.type === DeductibleType.Family
          && (deductible.accumulated || deductible.accumulated === 0),
        );
        if (foundFamilyTier) {
          return {
            index,
            items: [{
              value: foundFamilyTier.accumulated,
            }],
          };
        }

        if (_.isNumber(result.index) && result.index < index) {
          const threshold = item.triggers.reduce(
            (triggerResult, trigger) => {
              if ((trigger.type === DeductibleType.Member
                || trigger.type === DeductibleType.Plan) && trigger.threshold) {
                return Math.min(triggerResult, trigger.threshold);
              }
              return triggerResult;
            }, Infinity,
          );
          return {
            ...result,
            items: [...result.items, {
              value: threshold === Infinity ? 0 : threshold,
            }],
          };
        }
        return result;
      }, {
        index: null,
        items: [],
      },
    );

    return accumulated.items;
  }, [formattedTiersData, formattedDeductiblesData]);

  const individualTiers = useMemo(() => {
    const values = formattedTiersData.map((item) => {
      const currentSum = item.triggers.reduce((result, trigger) => {
        if ((trigger.type === DeductibleType.Embedded)
        && trigger.threshold) {
          return Math.min(result, trigger.threshold);
        }
        return result;
      }, Infinity);

      return currentSum === Infinity ? 0 : currentSum;
    });

    return values.filter((value) => !!value);
  }, [formattedTiersData]);

  const tiersValues = useMemo(() => {
    if (isIndividual) {
      return individualTiers;
    }

    const values = formattedTiersData.map((item) => {
      const currentSum = item.triggers.reduce((result, trigger) => {
        if ((trigger.type === DeductibleType.Member || trigger.type === DeductibleType.Plan)
        && trigger.threshold) {
          return Math.min(result, trigger.threshold);
        }
        return result;
      }, Infinity);
      return currentSum === Infinity ? 0 : currentSum;
    });
    return values.filter((value) => !!value);
  }, [isIndividual, formattedTiersData, individualTiers]);

  const total = useMemo(
    () => tiersValues[tiersValues?.length - 1] || 0, [tiersValues],
  );

  const accumulated = useMemo(
    () => {
      if (isIndividual) {
        if (employeeId !== userId && !individualAccumulatedValues
          .find((item) => item.dependentId === userId)) {
          return 0;
        }
        let foundUser = individualAccumulatedValues.find(
          (value) => value.dependentId === userId,
        );
        if (!foundUser) {
          foundUser = individualAccumulatedValues.find(
            (value) => !value.isThreshold && value.dependentId === null,
          );
        }
        if (foundUser) {
          const userIndex = foundUser.currentIndex;
          let totalValue = foundUser.value || 0;
          if (userIndex) {
            individualAccumulatedValues.forEach((item) => {
              if (item.currentIndex && (item.currentIndex < userIndex && item.isThreshold)) {
                totalValue += item.value;
              }
            });
          }
          return totalValue;
        }

        return 0;
      }
      const value = familyAccumulatedValues.reduce((result, item) => result + item.value, 0);
      return value;
    },
    [familyAccumulatedValues, individualAccumulatedValues, isIndividual, userId, employeeId],
  );
  const chartValues = useMemo<GagueChartItem[]>(() => {
    if (focusedUserId) {
      let foundUser = individualAccumulatedValues.find(
        (value) => value.dependentId === focusedUserId,
      );
      if (!foundUser) {
        foundUser = individualAccumulatedValues.find(
          (value) => value.employeeId === focusedUserId,
        );
      }
      if (foundUser) {
        const userIndex = foundUser.currentIndex;
        let totalValue = foundUser.value || 0;
        if (userIndex) {
          individualAccumulatedValues.forEach((item) => {
            if (item.currentIndex && (item.currentIndex < userIndex)) {
              totalValue += item.value;
            }
          });
        }

        const currentTotal = total || accumulated || 1;
        return [{
          percents: ((accumulated - totalValue) / currentTotal) * 100,
        }, {
          color: theme.colors.accentBorder,
          percents: (accumulated / currentTotal) * 100,
        }];
      }
    }
    const currentPercents = (accumulated / (total || 1)) * 100;
    return [{
      percents:currentPercents > 100 ? 100 : currentPercents,
    }];
  }, [accumulated, focusedUserId, individualAccumulatedValues, total]);
  const chartTiersValues = useMemo(() => {
    const reversedTiers = tiersValues;
    const results: GagueChartItem[] = [];
    let previousAmount: number = 0;
    reversedTiers.forEach((item) => {
      previousAmount += item;
      if (item) {
        results.push({
          percents: (previousAmount / total) * 100,
        });
      }
    });

    results.pop();
    return results;
  },

  [tiersValues, total]);
  const individualTotal = useMemo(
    () => individualTiers.reduce((result, item) => result + item, 0),
    [individualTiers],
  );
  const remaining = useMemo(() => {

    const value  =  total - accumulated;
    return value < 0 ? 0 : value;
  },[accumulated, total]);
  return {
    isDeductiblesLoading,
    isLoading,

    chartValues,
    chartTiersValues,

    remaining: remaining,
    total,
    individualTotal,
  };
};
