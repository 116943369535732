import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, RawButton, ResetIcon, Text } from '@common-fe/common-fe';
interface Props {
  onReset: () => void;
}

interface HeaderItemProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;

}
const HeaderItem: React.FC<HeaderItemProps> = ({ children,style}) => (
  <Box width="90px" margin={{ left: 'l' }} style={style}>
    <Text
      color="textBody"
      weight={700}
      size="small"
      style={{ lineHeight: '18px' }}
    >{children}
    </Text>
  </Box> 
);

const SelfDirectedAllocationHeader: React.FC<Props> = ({ onReset }) => {
  
  const { t } = useTranslation();
  return (
    <Box
      background="legend"
      align="center"
      round="container2Round"
      direction="row"
      pad={{ vertical: '7px', horizontal: '20px' }}
    >
      {/* <Box style={{ flex: 2 }} margin={{left: '125px'}}>
        <Text
          color="textBody"
          weight={700}
          size="small"
          style={{ lineHeight: '18px' }}
        >{t('Fund Name')}
        </Text>
      </Box> */}
      <HeaderItem style={{flex: 2, marginLeft: '125px'}} >
        {t('Fund Name')}
      </HeaderItem>
      <HeaderItem>
        {t('Share price')}
      </HeaderItem>
      <HeaderItem>
        {t('Shares')}
      </HeaderItem> 
      <HeaderItem>
        {t('Amount')}
      </HeaderItem>
      
      <Box direction='row' align='center' justify='center' width="176px" margin={{ left: 'xs' }}>
        <Text
          color="textBody"
          weight={700}
          size="small"
          style={{ lineHeight: '18px' }}
        >{t('Election')}
        </Text>
        <RawButton onClick={onReset} margin={{left: 'xxxs'}} style={{height: '18px'}}>
          <ResetIcon size="16px" color="textBody" />
        </RawButton>
      </Box>
    </Box>
  );
};
export default SelfDirectedAllocationHeader;