import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { MedicalExpensesCoverType } from '@/modules/investments/investments.types';

import { useStore as useInvestmentProfileStore } from '../../stores/useInvestmentProfile.store';

export default () => {
  const { t } = useTranslation();
  const MEDICAL_EXPENSES_COVER_TYPE_OPTIONS = useMemo(
    () => [
      {
        label: t('I would rely on my invested account balance to cover unexpected medical expenses'),
        value: MedicalExpensesCoverType.INVESTMENTS,
      },
      {
        label: t('I have some degree of savings that could cover smaller unexpected medical expenses'),
        value: MedicalExpensesCoverType.SAVINGS_LEVEL_1,
      },
      {
        label: t('I have the ability to cover moderate unexpected medical expenses with outside savings and/or current income'),
        value: MedicalExpensesCoverType.SAVINGS_LEVEL_2,
      },
      {
        label: t('I have the ability to cover up to my health plan’s out of pocket maximum with outside savings and/or current income'),
        value: MedicalExpensesCoverType.SAVINGS_LEVEL_3,
      },
      {
        label: t('I have the ability to cover unexpected medical expenses above and beyond my health plan’s out of pocket maximum with outside savings and/or current income'),
        value: MedicalExpensesCoverType.SAVINGS_LEVEL_4,
      },
    ], [t],
  );
  const defaultFields = useInvestmentProfileStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        name: 'medicalExpensesCoverType',
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: t('How would you best describe your ability to cover unexpected medical expenses with funds outside of your invested account balance?'),
        placeholder: '',
        options: MEDICAL_EXPENSES_COVER_TYPE_OPTIONS,
        isWrappedMode: true,
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]), [MEDICAL_EXPENSES_COVER_TYPE_OPTIONS, t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
