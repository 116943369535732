import { useMemo } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Field, FieldTypes, Text } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { DefaultValues } from '@/common/types';
import {  useInvestorById } from '@/modules/investments/hooks';
import { InvestmentPositions } from '@/modules/investments/investments.types';
import { useFieldsWithDefaultValues } from '@/utils';

interface Params {
  defaultValues: DefaultValues;
}
const useQuestionnaireFields =  ({ defaultValues }: Params) => {
  const {data } =  useInvestorById();
  const { t } = useTranslation();
  const options = useMemo(
    () => [
      { key: InvestmentPositions.ACCOUNTANT, value: t('Accountant/CPA/Bookkeeper/Controller') },
      { key: InvestmentPositions.ACTUARY, value: t('Actuary') },
      { key: InvestmentPositions.ADJUSTER, value: t('Adjuster') },
      { key: InvestmentPositions.ADMINISTRATOR, value: t('Administrator') },
      { key: InvestmentPositions.ADVERTISER, value: t('Advertiser/Marketer/PR Professional') },
      { key: InvestmentPositions.AGENT, value: t('Agent') },
      { key: InvestmentPositions.ATC, value: t('Air Traffic Controller') },
      { key: InvestmentPositions.AMBASSADOR, value: t('Ambassador/Consulate Professional') },
      { key: InvestmentPositions.ANALYST, value: t('Analyst') },
      { key: InvestmentPositions.APPRAISER, value: t('Appraiser') },
      { key: InvestmentPositions.ARCHITECT, value: t('Architect/Designer') },
      { key: InvestmentPositions.ARTIST, value: t('Artist/Performer/Actor/Dancer') },
      { key: InvestmentPositions.ASSISTANT, value: t('Assistant') },
      { key: InvestmentPositions.ATHLETE, value: t('Athlete') },
      { key: InvestmentPositions.ATTENDANT, value: t('Attendant') },
      { key: InvestmentPositions.ATTORNEY, value: t('Attorney/Judge/Legal Professional') },
      { key: InvestmentPositions.AUCTIONEER, value: t('Auctioneer') },
      { key: InvestmentPositions.AUDITOR, value: t('Auditor') },
      { key: InvestmentPositions.BARBER, value: t('Barber/Beautician/Hairstylist') },
      { key: InvestmentPositions.BROKER, value: t('Broker') },
      { key: InvestmentPositions.BUSINESS_EXEC, value: t('Business Executive (VP, Director, etc.)') },
      { key: InvestmentPositions.BUSINESS_OWNER, value: t('Business Owner') },
      { key: InvestmentPositions.CAREGIVER, value: t('Caregiver') },
      { key: InvestmentPositions.CARPENTER, value: t('Carpenter/Construction Worker') },
      { key: InvestmentPositions.CASHIER, value: t('Cashier') },
      { key: InvestmentPositions.CHEF, value: t('Chef/Cook') },
      { key: InvestmentPositions.CHIROPRACTOR, value: t('Chiropractor') },
      { key: InvestmentPositions.CIVIL, value: t('Civil Servant') },
      { key: InvestmentPositions.CLERGY, value: t('Clergy') },
      { key: InvestmentPositions.CLERK, value: t('Clerk') },
      { key: InvestmentPositions.COMPLIANCE, value: t('Compliance/Regulatory Professional') },
      { key: InvestmentPositions.CONSULTANT, value: t('Consultant') },
      { key: InvestmentPositions.CONTRACTOR, value: t('Contractor') },
      { key: InvestmentPositions.COUNSELOR, value: t('Counselor/Therapist') },
      { key: InvestmentPositions.CUSTOMER_SERVICE, value: t('Customer Service Representative') },
      { key: InvestmentPositions.DEALER, value: t('Dealer') },
      { key: InvestmentPositions.DEVELOPER, value: t('Developer') },
      { key: InvestmentPositions.DISTRIBUTOR, value: t('Distributor') },
      { key: InvestmentPositions.DOCTOR, value: t('Doctor/Dentist/Veterinarian/Surgeon') },
      { key: InvestmentPositions.DRIVER, value: t('Driver') },
      { key: InvestmentPositions.ENGINEER, value: t('Engineer') },
      { key: InvestmentPositions.EXAMINER, value: t('Examiner') },
      { key: InvestmentPositions.EXTERMINATOR, value: t('Exterminator') },
      { key: InvestmentPositions.FACTORY, value: t('Factory/Warehouse Worker') },
      { key: InvestmentPositions.FARMER, value: t('Farmer/Rancher') },
      { key: InvestmentPositions.FINANCIAL, value: t('Financial Planner') },
      { key: InvestmentPositions.FISHERMAN, value: t('Fisherman') },
      { key: InvestmentPositions.FLIGHT, value: t('Flight Attendant') },
      { key: InvestmentPositions.HR, value: t('Human Resources Professional') },
      { key: InvestmentPositions.IMPEX, value: t('Importer/Exporter') },
      { key: InvestmentPositions.INSPECTOR, value: t('Inspector/Investigator') },
      { key: InvestmentPositions.INTERN, value: t('Intern') },
      { key: InvestmentPositions.INVESTMENT, value: t('Investment Advisor/Investment Manager') },
      { key: InvestmentPositions.INVESTOR, value: t('Investor') },
      { key: InvestmentPositions.IT, value: t('IT Professional/IT Associate') },
      { key: InvestmentPositions.JANITOR, value: t('Janitor') },
      { key: InvestmentPositions.JEWELER, value: t('Jeweler') },
      { key: InvestmentPositions.LABORER, value: t('Laborer') },
      { key: InvestmentPositions.LANDSCAPER, value: t('Landscaper') },
      { key: InvestmentPositions.LENDING, value: t('Lending Professional') },
      { key: InvestmentPositions.MANAGER, value: t('Manager') },
      { key: InvestmentPositions.MECHANIC, value: t('Mechanic') },
      { key: InvestmentPositions.MILITARY, value: t('Military, Officer or Associated') },
      { key: InvestmentPositions.MORTICIAN, value: t('Mortician/Funeral Director') },
      { key: InvestmentPositions.NURSE, value: t('Nurse') },
      { key: InvestmentPositions.NUTRITIONIST, value: t('Nutritionist') },
      { key: InvestmentPositions.OFFICE, value: t('Office Associate') },
      { key: InvestmentPositions.PHARMACIST, value: t('PharmacAffiliated with a broker dealer (et al)?') },
      { key: InvestmentPositions.PHYSICAL, value: t('Physical Therapist') },
      { key: InvestmentPositions.PILOT, value: t('Pilot') },
      { key: InvestmentPositions.POLICE, value: t('Police Officer/Firefighter/Law Enforcement Professional') },
      { key: InvestmentPositions.POLITICIAN, value: t('Politician') },
      { key: InvestmentPositions.PM, value: t('Project Manager') },
      { key: InvestmentPositions.REP, value: t('Registered Rep') },
      { key: InvestmentPositions.RESEARCHER, value: t('Researcher') },
      { key: InvestmentPositions.SAILOR, value: t('Sailor/Seaman') },
      { key: InvestmentPositions.SALES, value: t('Salesperson') },
      { key: InvestmentPositions.SCIENTIST, value: t('Scientist') },
      { key: InvestmentPositions.SEAMSTRESS, value: t('Seamstress/Tailor') },
      { key: InvestmentPositions.SECURITY, value: t('Security Guard') },
      { key: InvestmentPositions.SOCIAL, value: t('Social Worker') },
      { key: InvestmentPositions.TEACHER, value: t('Teacher/Professor') },
      { key: InvestmentPositions.TECHNICIAN, value: t('Technician') },
      { key: InvestmentPositions.TELLER, value: t('Tradesperson/Craftsperson') },
      { key: InvestmentPositions.TRADESPERSON, value: t('Spouse') },
      { key: InvestmentPositions.TRAINER, value: t('Trainer/Instructor') },
      { key: InvestmentPositions.TRANSPORTER, value: t('Transporter') },
      { key: InvestmentPositions.UNDERWRITER, value: t('Underwriter') },
      { key: InvestmentPositions.WRITER, value: t('Writer/Journalist/Edito') },
    ], [t],
  );
  const fields: Field[] = useMemo(() => {
    return [
      {
        name: 'position',
        type: FieldTypes.Dropdown,
        value: '',
        showRequireIcon: true,
        options,
        placeholder: 'Select position',
        label: t('What is your position at your company?'),
        validator: VALIDATORS.REQUIRED_STRING,
        defaultValue: '',
      },
      {
        name: 'networthTotal',
        type: FieldTypes.Currency,
        value: '',
        showRequireIcon: true,
        placeholder: 'Enter $ amount',
        label: (
          <Box>
            <Text weight={700}>
              {t('What is your total net worth - your assets minus your liabilities?')}
            </Text>
            <Text color="textSecondary" size="small" weight={500}>
              {t(
                'Assets include figures from checking, savings and liquid securities, plus illiquid assets like your home and pension assets. Your liabilities include money owed like mortgage payments, car loans, student loans, taxes, etc.'
              )}
            </Text>
          </Box>
        ),
        validator: VALIDATORS.REQUIRED_STRING,
        defaultValue: '',
      },
      {
  
        name: 'annualIncome',
        type: FieldTypes.Currency,
        value: '',
        showRequireIcon: true,
        placeholder: 'Enter $ amount',
        label: t('What is your approximate annual income?'),
        validator:VALIDATORS.REQUIRED_STRING,
        defaultValue: '',
      },
    ];
  }, [options, t]);
  const formattedFields = useFieldsWithDefaultValues(fields, defaultValues);
  return formattedFields;
};
export default useQuestionnaireFields;