import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { DashboardModalsKeys } from '@/modules/core/components/Dashboard/components/DashboardModals/dashboardModals.types';
import { SuccessfulAuthenticationModal } from '@/modules/user/components/SignIn/MultiFactorAuthentication/components';
import { getHashValue } from '@/utils/modifiers/getHashValue';

const DashboardModals = () => {
  const location = useLocation();
  const modalHash = getHashValue(location.hash);
  const [activeModalHash, setActiveModalHash] = useState<DashboardModalsKeys | null>(modalHash as DashboardModalsKeys);

  useEffect(() => {
    if(window.location.hash) {
      window.history.replaceState('', document.title, window.location.pathname);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setActiveModalHash(null);
  }, []);

  const activeModal = useMemo(() => {
    switch (activeModalHash) {
    case DashboardModalsKeys.SuccessfulAuthentication: 
      return <SuccessfulAuthenticationModal onSetVisible={handleCloseModal} />;
    default:
      return null;
    }
  }, [activeModalHash, handleCloseModal]);

  return activeModal;
};

export default DashboardModals;
