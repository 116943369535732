import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Inscription } from '@common-fe/common-fe';

import { useManageProfileModalsStore } from '@/modules/user/components/ManageProfile/stores/useManageProfileModals.store';

const ReimbursementOptionsHeader = () => {
  const { t } = useTranslation();
  const { handleSetIsAddPaymentMethodModalVisible } = useManageProfileModalsStore();

  const handleOpenAddPaymentMethodModal = useCallback(() => {
    handleSetIsAddPaymentMethodModalVisible(true);
  }, [handleSetIsAddPaymentMethodModalVisible]);

  return (
    <Box direction="row" justify="between" align="center">
      <Inscription
        size="large"
        weight={700}
      >
        {t('Reimbursement options')}
      </Inscription>
      <AppButton
        buttonType="secondary"
        onClick={handleOpenAddPaymentMethodModal}
      >
        {t('Add Payment Method')}
      </AppButton>
    </Box>
  );
};

export default ReimbursementOptionsHeader;
