import React from 'react';

import { InvestmentPaths } from '../../investments.types';
import InvestmentDashboardFooter from '../InvestmentDashboardFooter';

import InvestmentLiquidationPendingBanner from './InvestmentLiquidationPendingBanner';
import InvestmentReactivate from './InvestmentReactivate';
import InvestmentSwitchingModelBanner from './InvestmentSwitchingModelBanner';

export enum InvestingBannerStatus {
  Reactivate = 'REACTIVATE',
  Pending = 'PENDING',
  Switching = 'SWITCHING',
}
interface Props {
  modelType?: InvestmentPaths;
  status?: InvestingBannerStatus;
  accountId: string;

}
const InvestmentsStatusBanner: React.FC<Props> = ({ modelType, status,accountId }) => {
  if (status === InvestingBannerStatus.Reactivate) {
    return (
      <>
        <InvestmentReactivate accountId={accountId} />
        <InvestmentDashboardFooter />
      </>
    );
  }
  if (status === InvestingBannerStatus.Switching) {
    return (
      <>
        <InvestmentSwitchingModelBanner modelType={modelType} />
        <InvestmentDashboardFooter />
      </>
    );
  }
  return <InvestmentLiquidationPendingBanner />;
};

export default InvestmentsStatusBanner;
