import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import { toString } from 'lodash';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import masks from '@/common/masks';
import { State } from '@/modules/core/queries/useStates.query';

import { Provider } from '../useProviders.query';


export interface PresetFields {
  name?: string,
  adressLine1?: string,
  adressLine2?: string,
  city?: string,
  state?: string,
  zipCode?: string,
}

enum ProviderFields {
  name = 'name',
  adressLine1 = 'adressLine1',
  adressLine2 = 'adressLine2',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
}

const useProviderFields = (states: State[], presetFields?: PresetFields, provider?: Provider) => {
  const { t } = useTranslation();

  const scannedAddressState = useMemo(() => {
    if (!presetFields?.state) return undefined;
    return states.find((state) => state.code === presetFields.state);
  }, [presetFields, states]);

  return [
    {
      name: ProviderFields.name,
      type: FieldTypes.Text,
      label: t('Name'),
      placeholder: t('Enter Provider/Merchant name'),
      value: provider?.name || presetFields?.name,
      validator: yup.string().required(REQUIRED_TEXT),
      showRequireIcon: true,
    },
    {
      name: ProviderFields.adressLine1,
      // description: t('Please ensure this is the address where the payment should be sent.'),
      type: FieldTypes.Text,
      value: provider?.address?.line1 || presetFields?.adressLine1,
      label: t('Address Line 1'),
      placeholder: t('Address Line 1'),
      showRequireIcon: true,
      validator: yup.string().required(REQUIRED_TEXT),
    },
    {
      name: ProviderFields.adressLine2,
      type: FieldTypes.Text,
      label: t('Address Line 2'),
      placeholder: t('Address Line 2'),
      value: provider?.address?.line2 || presetFields?.adressLine2,
    },
    {
      name: ProviderFields.city,
      type: FieldTypes.Text,
      label: t('City'),
      value: provider?.address?.city || presetFields?.city,
      placeholder: t('Enter city'),
      showRequireIcon: true,
      validator: yup.string().required(REQUIRED_TEXT),
    },
    {
      name: ProviderFields.state,
      type: FieldTypes.AutocompleteDropdown,
      label: t('State'),
      placeholder: t('Select state'),
      isSearchMode: true,
      options: states.map((state) => ({
        key: state.id,
        title: state.name,
      })),
      showRequireIcon: true,
      value: toString(provider?.address?.state?.id) || scannedAddressState?.id,
      completeValue: scannedAddressState?.id || '',
      defaultValue: toString(provider?.address?.state?.id),
      validator: yup.string().required(REQUIRED_TEXT),
    },
    {
      name: ProviderFields.zipCode,
      type: FieldTypes.Mask,
      mask: masks.FULL_ZIP_CODE,
      label: t('ZIP code'),
      placeholder: t('Zip'),
      validator: yup
        .string()
        .trim()
        .test(
          'len',
          t('ZIP code isn\'t valid'),
          (val) => (val
            && (val.length === masks.FULL_ZIP_CODE.length
              || val.length === masks.SHORT_ZIP_CODE.length))
            || !val,
        ).required(REQUIRED_TEXT),
      value: provider?.address?.zipcode || presetFields?.zipCode,
      showRequireIcon: true,
    },
  ] as Field[];
};

export default useProviderFields;
