import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.text32Bold};
  margin-bottom: ${({ theme }) => theme.spacings.spacing24};
  color: ${({ theme }) => theme.colors.blockTitle || ''};
`;

export const Wrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.spacings.spacing8};
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  height: 100%;
`;
