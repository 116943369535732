import React, { useState } from 'react';
import { Box, FlexList, Preloader } from '@common-fe/common-fe';

import useDependentsQuery from '@/modules/user/components/ManageProfile/components/Dependents/queries/useDependents.query';
import useHighlightedDependentStore from '@/modules/user/components/ManageProfile/components/Dependents/stores/useHighlightedDependent.store';
import { useAuthStore } from '@/modules/user/stores';

import EditDependentModal from '../DependentsModal/EditDependentModal';
import RemoveDependentModal from '../DependentsModal/RemoveDependentModal';

import useDependentsList, { HEADERS } from './hooks/useDependentsList';
import useDependentsListOptions from './hooks/useDependentsListOptions';

const DependentsList = () => {
  const [isRemoveDependentModalVisible, setIsRemoveDependentModalVisible] = useState(false);
  const [isEditDependentModalVisible, setIsEditDependentModalVisible] = useState(false);
  const { highlightedDependentId } = useHighlightedDependentStore();

  const { user } = useAuthStore();
  const { formattedData, isLoading } = useDependentsQuery(user?.id);
  const formattedList = useDependentsList(formattedData, highlightedDependentId);
  const { options } = useDependentsListOptions({
    dependents: formattedData,
    setIsRemoveDependentModalVisible,
    setIsEditDependentModalVisible,
  });

  return (
    <>
      <Box margin={{ top: 'spacing16' }}>
        {isLoading ? (
          <Box pad={{ bottom: 'spacing24' }}>
            <Preloader testId='DependentsList_preloader' />
          </Box>
        ) : (
          <FlexList
            headers={HEADERS}
            rows={formattedList}
            total={formattedList.length}
            options={options}
            pad="0px"
          />
        )}
      </Box>
      {isRemoveDependentModalVisible && (
        <RemoveDependentModal onSetVisible={setIsRemoveDependentModalVisible} />
      )}
      {isEditDependentModalVisible && (
        <EditDependentModal onSetVisible={setIsEditDependentModalVisible} />
      )}
    </>
  );
};

export default DependentsList;
