import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Option } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

interface Response {
  id: number;
  type: string;
  description: string;
}
export default () => {
  const { data, isLoading, isSuccess } = useQuery([PATHS.INSTRUMENTS_CATEGORIES],
    () => api.get<Response[]>(PATHS.INSTRUMENTS_CATEGORIES));

  const formattedOptions = useMemo(() => {
    const values = _.get(data, 'data', []);

    const list: Option[] = values.map((item) => ({
      key: item.type,
      value: item.description,
      title: item.description,
    }));

    return list;
  }, [data]);

  const dataMap = useMemo(() => formattedOptions.reduce((result, item) => ({
    ...result,
    [String(item.key)]: item.value,
  }), { }), [formattedOptions]);

  return {
    dataMap,
    formattedOptions,
    isLoading,
    isSuccess,
  };
};
