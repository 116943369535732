import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@common-fe/common-fe';

import AutoInvestmentBanner, { useAutoInvestmentBanner } from './AutoInvestmentBanner';

interface Props {
  accountId?: string;
}
const AutoInvestmentStandaloneBanner: React.FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();
  const { isDisplayedBanner } = useAutoInvestmentBanner();
  if (!isDisplayedBanner) {
    return null;
  }
  return (
    <Box direction="column" background="module" round="moduleRound" pad="l" margin={{ bottom: 'l' }}>
      <Box direction="row" justify="between" margin={{ bottom: 's' }}>
        <Heading color="blockTitle" level={4} size="16px">{t('Automatic Investments')}</Heading>

      </Box>

      <AutoInvestmentBanner accountId={accountId} isWideMode />
    </Box>
  );
};

export default AutoInvestmentStandaloneBanner;
