import React, { useEffect,useRef } from 'react';
import {
  Box, Image, Preloader,
  Text, useClickOutside, useImage, } from '@common-fe/common-fe';
import styled from 'styled-components';

import SwitchEmp from '@/assets/gif/SwitchEmp.gif';
import AppButton from '@/components/controls/AppButton';

const StyledImage = styled(Image)`
  border-top-right-radius: ${({ theme }) => theme.spacings.spacing8};
  border-top-left-radius: ${({ theme }) => theme.spacings.spacing8};;
`;

const BUTTON_WIDTH = '100%';
const POPUP_WIDTH = '280px';
const POPUP_HEIGHT = '282px';
const IMAGE_HEIGHT = '158px';
const BOX_SHADOW = '0px 8px 25px rgba(0, 38, 102, 0.2)';

interface Props {
  visible?: boolean;
  onSetVisible: (value: boolean) => void;
}

export const UserSwitchPopupInfo: React.FC<Props> = ({ onSetVisible }) => {
  const ref = useRef(null);
  const { isLoading: isImageLoading } = useImage(SwitchEmp);

  useClickOutside(ref, () => {
    onSetVisible(false);
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Box
      data-testid="UserSwitchPopupInfo_container"
      ref={ref}
      width={POPUP_WIDTH}
      height={POPUP_HEIGHT}
      background={{ color: 'canvas' }}
      border={{ color: 'border2' }}
      round="spacing8"
      style={{
        position: 'absolute',
        boxShadow: BOX_SHADOW,
        right: 0,
        top: 0,
        zIndex: 101,
        overflow: 'hidden',
      }}
    >
      <Box
        width={POPUP_WIDTH}
        height={IMAGE_HEIGHT}
        data-testid="UserSwitchPopupInfo_image"
        background="border2"
        justify="center"
      >
        {
          isImageLoading ? (<Preloader testId="switching" size={14} />) : (<StyledImage src={SwitchEmp} />)
        }
      </Box>
      <Box pad="spacing16">
        <Text
          weight={700}
          margin={{ bottom: 'spacing12' }}
          style={{ lineHeight: '20px' }}
        >
          Select your name to switch between accounts.
        </Text>
        <Box width="100%">
          <AppButton
            testId="UserSwitchPopupInfo_dismiss"
            buttonType="secondary"
            width={BUTTON_WIDTH}
            onClick={() => onSetVisible(false)}
          >
            Dismiss
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};
