import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useApplyFixedScroll } from '@/modules/core/components/DashboardWrapper/hooks';
import { useBreadcrumbs, useHasAccess, useRedirectHome } from '@/modules/core/hooks';
import Investments from '@/modules/investments/Investments';
import { useMyEnrollments } from '@/modules/transaction';
import { useQueryParams } from '@/utils';

const QUERY_KEY = 'id';

const InvestmentsPage = () => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const accountId = useMemo(() => query.get(QUERY_KEY) || '', [query]);
  const isInvestmentsDisabled = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  useRedirectHome(isInvestmentsDisabled);
  const { currentMyAccount } = useMyEnrollments(accountId);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: currentMyAccount?.name,
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Investments'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }], true);
  useApplyFixedScroll(); 
  return (
    <Investments accountId={accountId} />
  );
};

export default InvestmentsPage;
