import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';
import _ from 'lodash';

import REGEXP from '@/common/regexp';
import routes from '@/common/routes';
import { DashboardModalsKeys } from '@/modules/core/components/Dashboard/components/DashboardModals/dashboardModals.types';
import { MFAError, MFAStep } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';
import useMFAStore from '@/modules/user/components/SignIn/MultiFactorAuthentication/stores/useMFA.store';
import ResendCodeButton from '@/modules/user/components/Verification/components/ResendCodeButton';
import VerificationCodeModal from '@/modules/user/components/Verification/components/VerificationCodeModal';
import useVerificationCodeFields, { FormValues } from '@/modules/user/components/Verification/hooks/useVerificationCodeFields';
import { useValidateForm } from '@/utils/hooks';

import useMFAChannelsDescription from '../hooks/useMFAChannelsDescription';
import useVerifyMFACodeQuery from '../queries/useVerifyMFACode.query';

import OpenChangeVerificationMethodModalButton from './OpenChangeVerificationMethodModalButton';
import RememberMeCheckbox from './RememberMeCheckbox';

interface Props {
  onSetVisible: (value: boolean) => void;
  setStep: (step: MFAStep) => void;
  onResendCode: () => void;
  onShowAccountLockedModal: (value: number) => void;
  resendTimeout: number;
  sendCodeErrorText?: string;
}

const SendCodeModal: React.FC<Props> = ({
  onSetVisible,
  setStep,
  onResendCode,
  onShowAccountLockedModal,
  resendTimeout,
  sendCodeErrorText
}) => {
  const { push } = useHistory();
  const { activeChannel, session, isRememberMe, userName } = useMFAStore();
  const fields = useVerificationCodeFields();
  const [state, handleSetState] = useState<FormValues>();
  const { values, setValues } = useDebounceState<FormValues>();
  const [verificationErrorText, setVerificationErrorText] = useState('');
  const { handlePreSubmit, hasErrors } = useValidateForm(fields);
  const channelDescription = useMFAChannelsDescription(activeChannel?.type);

  const { verifyMFACodeAndSignIn, isLoading } = useVerifyMFACodeQuery();

  const verificationCode = useMemo(() => state?.verificationCode?.replace(REGEXP.SPACE_SYMBOL, '') || '', [state]);

  const handleConfirm = useCallback(async () => {
    try {
      const isSuccessValidation = handlePreSubmit(state);
      if (isSuccessValidation) {
        setVerificationErrorText('');
        await verifyMFACodeAndSignIn({
          session: session || '',
          code: verificationCode,
          isRememberMe,
        }, userName);
        push(`${routes.HOME}#${DashboardModalsKeys.SuccessfulAuthentication}`);
      }
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.elevate_error_message', '') as string;
      const lockingLeft = _.get(error, 'response.data.locking_left', 10) as number;

      if (errorMessage?.includes('user account is locked')) {
        onShowAccountLockedModal(lockingLeft);
        return;
      }

      setVerificationErrorText(MFAError.CODE_IS_INVALID);
    }
  }, [
    state,
    verificationCode,
    session,
    isRememberMe,
    userName,
    handlePreSubmit,
    verifyMFACodeAndSignIn,
    onShowAccountLockedModal,
    push
  ]);

  const handleOpenChangeVerificationMethodModal = useCallback(() => {
    setStep(MFAStep.CHANGE_VERIFICATION_METHOD);
  }, [setStep]);

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  useEffect(() => {
    if (verificationCode?.length === 6) {
      handleConfirm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  return (
    <VerificationCodeModal
      testId="VerificationCodeModal"
      icon={channelDescription.icon}
      title={channelDescription.name}
      value={activeChannel?.value || ''}
      errorText={sendCodeErrorText || verificationErrorText}
      isLoading={isLoading}
      onSetVisible={onSetVisible}
      onConfirm={handleConfirm}
      footerNode={<RememberMeCheckbox />}
      form={(
        <FlexControlledForm
          fields={fields}
          onChangeValues={setValues}
          formStyle={{ margin: '24px' }}
          showError={hasErrors}
          editMode
        >
          <Box width="320px" alignSelf="end">
            <ResendCodeButton onResend={onResendCode} defaultRemainingTime={resendTimeout} />
            <Box margin={{ top: 'spacing12' }}>
              <OpenChangeVerificationMethodModalButton
                onClick={handleOpenChangeVerificationMethodModal}
              />
            </Box>
          </Box>
        </FlexControlledForm>
      )}
    />
  );
};

export default SendCodeModal;
