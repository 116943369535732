import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useCIPInfo } from '@/modules/investments/cip/hooks';

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.textAccent};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.textAccentHover};
  }
  &:active {
    color: ${({ theme }) => theme.colors.textAccentActive};
  }
`;

const LinkToCIP = () => {
  const { t } = useTranslation();
  const { handleVerify } = useCIPInfo();
  return <StyledLink onClick={() => handleVerify()}>{t('Verify Identity')}</StyledLink>;
};

export default LinkToCIP;
