import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { RAW_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import { Pagination } from '@/common/types';

export const QUERY_KEY = 'useGetCardTransactionListQuery';

export interface CardTransactionDto {
  id: number;
  claim_status: string;
  claim_id: number;
  card_swipe_date: string;
  swiped_amount: number;
  authorization_id: number;
  memo: string;
  category_type: string;
  approved_amount: number;
  transaction_id: number;
}

interface Params {
  cardId: string | null;
  cardAccountId?: string;
  perPage: number;
  page: number;
  searchString: string;

  statuses?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
}

export const useGetCardTransactionList = ({
  searchString,
  page,
  perPage,
  cardId,
  cardAccountId,

  statuses,
  startDate,
  endDate,
}: Params) => {
  const fromDate = useMemo(() => {
    if (startDate) {
      return dayjs(startDate).format(RAW_DATE_FORMAT);
    }
    return undefined;
  }, [startDate]);
  const toDate = useMemo(() => {
    if (endDate) {
      return dayjs(endDate).format(RAW_DATE_FORMAT);
    }
    return undefined;
  }, [endDate]);
  const { isLoading, isSuccess, isError, data, refetch, isFetched, remove } = useQuery(
    [QUERY_KEY, cardId, cardAccountId, page, perPage, searchString, statuses, startDate, endDate],
    () =>
      api.get<Pagination<CardTransactionDto>>(PATHS.CARD_ACTIVITY, {
        params: {
          card_id: cardId,
          statuses: statuses,
          start_date: fromDate,
          endDate: toDate,
          card_account_id: cardAccountId ? _.toNumber(cardAccountId): undefined,
          ...(searchString.length > 2 ? { name: searchString } : {}),
          page,
          size: perPage,
        },
      }),
    {
      cacheTime: 0,
      enabled: !!cardId,
    }
  );

  useEffect(() => {
    if (cardId) refetch();
    else remove();
  }, [cardId, refetch, remove]);
  return {
    data: data?.data.content || [],
    total: data?.data?.total || data?.data?.totalElements,
    isError,
    isLoading,
    isSuccess,
    refetch,
    isFetched,
  };
};
