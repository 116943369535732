import { storeCreator } from '@/utils/store/store.utils';

interface ProfileInformationFormState {
  isFormEditMode: boolean;
  isFormError: boolean;
}

interface State {
  values: ProfileInformationFormState,
  handleSetIsFormEditMode: (value: boolean) => void;
  handleSetIsFormError: (value: boolean) => void;
  handleReset: () => void;
}

const DEFAULT_VALUES: ProfileInformationFormState = {
  isFormEditMode: true,
  isFormError: false,
};

export const useProfileInformationFormStore = storeCreator<State>((set) => ({
  values: DEFAULT_VALUES,

  handleSetIsFormEditMode: (value: boolean) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      isFormEditMode: value,
    },
  })),

  handleSetIsFormError: (value: boolean) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      isFormError: value,
    },
  })),

  handleReset: () => set((state) => ({
    ...state,
    values: DEFAULT_VALUES,
  })),
}));
