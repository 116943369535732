import { datadogRum, } from '@datadog/browser-rum';

import { storeCreator } from '@/utils/store/store.utils';

import { UserModel } from '../user.types';

interface State {
  employeeId: string;
  organizationId: string;
  personId: string;
  auth: boolean;
  inited: boolean;
  user: UserModel | null;
  setAuth: (value: boolean) => void;
  setUser: (user: UserModel | null) => void;
  setIds: (
    employeeId?: string,
    organizationId?: string,
    personId?: string,
  ) => void;
}

const useStore = storeCreator<State>((set) => ({
  employeeId: '',
  organizationId: '',
  personId: '',
  auth: false,
  inited: false,
  user: null,
  setAuth: (value: boolean) => set(() => ({
    auth: value,
    inited: true,
  })),
  setUser: (user: State['user']) => set(() => {
    if(user) {
      datadogRum.setUser({
        id: user.id
      });
    }
    return  ({
      user,
    });
  }),
  setIds: (
    employeeId: string = '',
    organizationId: string = '',
    personId: string = '',
  ) => set(() => ({
    employeeId,
    organizationId,
    personId,
  })),
}));

export default useStore;
