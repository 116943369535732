import React from 'react';
import {
  AchievementTracker,
  Box,
  Inscription,
  Preloader,
} from '@common-fe/common-fe';

import { AchievementTrackerInfoItem } from './queries/useGetContributionsHistory.query';
import { ContributionLimitInfoBox } from './ContributionLimitInfoBox';

interface Props {
  limits: AchievementTrackerInfoItem[];
  currentContributionValue: number;
  maxContributionValue: number;
  isLoading?: boolean;
}

export const ContributionsLimitInfoBanner: React.FC<Props> = ({
  limits,
  currentContributionValue,
  maxContributionValue,
  isLoading,
}) => (
  <Box
    data-testid="contribution-limit-info"
    round="container1Round"
    background="canvas"
    pad={{ horizontal: 'spacing12', bottom: 'spacing12' }}
    border={{ color: 'border2' }}
    margin={{ vertical: 'spacing12' }}
  >
    <Box
      round="small"
      height="2px"
      background="info"
      margin={{ bottom: 'spacing12' }}
    />
    <Box pad="spacing12">
      <Inscription weight={700} lineHeight="20px" margin={{ bottom: 'spacing16' }}>
        Annual contribution limits
      </Inscription>
      {isLoading
        ? (<Preloader />)
        : (
          <AchievementTracker
            items={limits}
            currentValue={currentContributionValue}
            isWithDescription={false}
            testId="ContributionModal"
          />
        )}
      <Box direction="row" gap="spacing8">
        {limits.map((limit) => (
          <ContributionLimitInfoBox
            key={limit.id}
            name={limit.name}
            maxValue={limit.maxInitialValue}
            currentValue={currentContributionValue}
            maxContributionValue={maxContributionValue}
          />
        ))}
      </Box>
    </Box>
  </Box>
);
