import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Inscription, WarnModal } from '@common-fe/common-fe';

import { FileImage } from '@/common/constants';
import useUploadFilesToDocument from '@/components/controls/DropScan/hooks/useUploadFilesToDocument';
import { TransactionFileUploadSteps } from '@/modules/transaction/components/TransactionDetails/TransactionFileOnlyUploadPopup/transactionFileOnlyUploadPopup.types';
import useUpdateClaimDocumentIdQuery from '@/modules/transaction/components/TransactionDetails/useUpdateClaimDocumentId.query';

interface Props {
  claimId?: string;
  selectedFile?: FileImage;
  onSetVisible: (value: boolean) => void;
  setStep: (step: TransactionFileUploadSteps) => void;
}

const TransactionUploadDocumentErrorPopup: React.FC<Props> = ({
  claimId, selectedFile, onSetVisible, setStep
}) => {
  const { t } = useTranslation();
  const { handleUploadFilesToDocument } = useUploadFilesToDocument();
  const { updateClaimDocumentId } = useUpdateClaimDocumentIdQuery(claimId);

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleSubmit = useCallback(async () => {
    try {
      if (!selectedFile) {
        throw new Error();
      }
      setStep(TransactionFileUploadSteps.UPLOADING);
      const { uploadedFiles, documentId } = await handleUploadFilesToDocument([selectedFile]);
      const uploadedFile = uploadedFiles?.[0] || {};
      if (!uploadedFile?.id) {
        throw new Error();
      }
      await updateClaimDocumentId(documentId);
      setStep(TransactionFileUploadSteps.SUCCESSFULLY_UPLOADED);
    } catch {
      setStep(TransactionFileUploadSteps.ERROR);
    }
  }, [selectedFile, handleUploadFilesToDocument, setStep, updateClaimDocumentId]);

  return (
    <WarnModal
      visible
      onSetVisible={onSetVisible}
      header={t('The server encountered an error processing the request')}
      cancelButtonNode={(
        <AppButton
          buttonType="secondary"
          onClick={handleClose}
          width="200px"
        >
          {t('Close')}
        </AppButton>
      )}
      submitButtonNode={(
        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            style={{ marginLeft: 'spacing12' }}
            onClick={handleSubmit}
            width="200px"
          >
            {t('Try Again')}
          </AppButton>
        </Box>
      )}
    >
      <Inscription margin={{ top: 'spacing12' }} textAlign="center">
        {t(`It does not appear to have affected your data, but we cannot process your request.
        Our technical stuff has been automatically and will be looking into this with the utmost urgency.`)}
      </Inscription>
    </WarnModal>
  );
};

export default TransactionUploadDocumentErrorPopup;
