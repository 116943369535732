import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Field,
  FieldTypes,
  Text,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';

import { useStore as usePersonalInformationStore } from '../../stores/usePersonalInformation.store';

const Label: React.VFC<{ title: string, desc: string }> = ({ title, desc }) => (
  <Box flex={{ grow: 1 }}>
    <Text margin={{ bottom: 'spacing4' }} weight="bold" color="textBody">{title}</Text>
    <Box>
      <Text weight={500} size="small" color="textSecondary">
        {desc}
      </Text>
    </Box>
  </Box>
);

export default () => {
  const { t } = useTranslation();
  const defaultFields = usePersonalInformationStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        testId: 'Networth-total-field',
        name: 'networthTotal',
        type: FieldTypes.Currency,
        value: '',
        showRequireIcon: true,
        placeholder: 'Enter $ amount',
        label: (
          <Label
            title={t('What is your total net worth - your assets minus your liabilities?')}
            desc={t('Assets include figures from checking, savings and liquid securities, plus illiquid assets like your home and pension assets. Your liabilities include money owed like mortgage payments, car loans, student loans, taxes, etc.')}
          />
        ),
        validator: yup.string().trim().required(REQUIRED_TEXT),
        defaultValue: '',
      },
    ]
  ), [t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  
  return formatedFields;
};
