import { useMutation } from 'react-query';

import { verifyUserRole } from '@/modules/user/components/SignIn/hooks/verifyUserRole';
// import { useHistory } from 'react-router-dom';
// import Auth from '@aws-amplify/auth';
// import _ from 'lodash';
// import routes from '@/common/routes';
import { useAuthStore } from '@/modules/user/stores';
import configureAmplify from '@/utils/configureAmplify';
import Token from '@/utils/handlers/Token';

import useSignInQuery from '../queries/useSignIn.query';

export interface LoginPayload {
  username: string;
  password: string;
}
// const CODE_ENTITY_NAME = 'code';
// const NON_CONFIRMED_EXCEPTION = 'UserNotConfirmedException';

configureAmplify();

const useSignIn = () => {
  const { handleSignIn } = useSignInQuery();
  // const history = useHistory();
  const { setAuth } = useAuthStore();
  const { mutateAsync, isError } = useMutation(
    async (loginPayload: LoginPayload) => {
      const authResponse = await handleSignIn(loginPayload);
      const accessToken = authResponse?.accessToken;
      if (!accessToken) {
        return authResponse;
      }
      const isLoginBlocked = verifyUserRole(accessToken);
      if (isLoginBlocked) {
        throw new Error('');
      }
      return authResponse;
    },
    {
      onSuccess: (token) => {
        const accessToken = token?.accessToken;
        const refreshToken = token?.refreshToken;
        const expiresIn = token?.expiresIn;
        const userName= token?.username;

        if (userName && accessToken && refreshToken && expiresIn) {
          Token.storeToken(accessToken);
          Token.storeRefreshToken(refreshToken);
          Token.storeExpiresIn(expiresIn);
          Token.storeUserName(userName);
          setAuth(true);
        }
      },
      // TODO: Fix it
      // onError: (error, payload) => {
      //   const code = _.get(error, CODE_ENTITY_NAME);
      //   if (code === NON_CONFIRMED_EXCEPTION) {
      //     Auth.resendSignUp(payload.username);
      //     history.push(`${routes.VERIFY}?username=${payload.username}`);
      //   }
      // },
    },
  );

  const handleSetToken = (token: string) => {
    Token.storeToken(token);
    setAuth(true);
  };

  return {
    isError: isError,
    handleLogin: mutateAsync,
    handleSetToken
  };
};

export default useSignIn;
