/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CrossIcon,
  Inscription,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { FileType } from '@/common/constants';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';

import Embed from './Embed';
import {
  CloseButton,
  ImgSquare,
  ImgThumbInner,
  ImgThumbOuter,
  Wrapper,
} from './ScanPreview.styles';

const PreviewTooltipWrapper = styled(Box)`
  position: absolute;
  right: -205px;
  top: -6px;
  z-index: 100;
  width: 210px;
  max-width: initial;
  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: calc(100% - 48px);
    left: -5px;
    background-color: ${({ theme }) => theme.colors.legend};
    transform: rotate(-45deg);
    border-radius: 3px 0 0 0;
  }
`;
export const PreviewTooltip: React.FC<{ setShowTooltip: (val: boolean) => void }> = ({
  setShowTooltip,
}) => (
  <PreviewTooltipWrapper
    round="container1Round"
    direction="row"
    background="background3"
    align="center"
    pad={{ vertical: 'spacing12', horizontal: 'spacing16' }}
  >
    <Box margin={{ right: 'spacing16' }}>
      <Text weight={500} size="small" color="textOnColor">Claim details will be scanned automatically from this file.</Text>
    </Box>
    <Box margin={{ right: 'spacing6' }} onClick={() => setShowTooltip(false)}>
      <CrossIcon size="10px" color="iconOnColor" />
    </Box>
  </PreviewTooltipWrapper>
);

export const ScanPreview = ({
  isPreviewHidden,
  hasMainLabel,
  isSmallViewVersion,
  isFlex,
  selectedIndex,
  onSelect,
  onDelete,
  isAddDocFirst,
  children,
}: any) => {
  const { t } = useTranslation();
  const { values: { files } } = useFilesInDocumentStore();
  const thumbs = files.map((file: any, index: number) => (
    <Box key={file.temporalId}>
      <ImgThumbOuter
        active={index === selectedIndex}
        key={file.preview || file.url}
        onClick={() => onSelect(index)}
      >
        <ImgThumbInner>
          {onDelete ? (
            <CloseButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete(index);
              }}
            >
              <CrossIcon color="iconOnColor" size="12px" />
            </CloseButton>
          ) : null}

          <Box
            width="20px"
            height="20px"
            background="stressContainer"
            justify="center"
            align="center"
            round="button2Round"
            style={{ position: 'absolute' }}
          >
            <Inscription size="12px" color="textOnColor">{index + 1}</Inscription>
          </Box>

          {(index === 0 && isSmallViewVersion) && (
            <Box
              background="button"
              justify="center"
              align="center"
              width="100%"
              round={{ corner: 'bottom', size: '5px' }}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            >
              {
                hasMainLabel && (
                  <Inscription
                    size="small"
                    weight={500}
                    color="textOnColor"
                  >
                    {t('Main')}
                  </Inscription>
                )
              }
            </Box>
          )}
          {file.type === FileType.PDF
            ? (
              <>
                <Box width="100%" height="100%" style={{ position: 'absolute' }} />
                <Embed src={file.preview} url={file.url} />
              </>
            )
            : <ImgSquare src={file.preview || file.url} alt={file.name} />}
        </ImgThumbInner>
      </ImgThumbOuter>
    </Box>
  ));

  return (
    <Wrapper
      isSmallViewVersion={isSmallViewVersion}
      isFlex={isFlex}
      direction={isSmallViewVersion ? 'row' : 'column'}
    >
      {isAddDocFirst && children}
      {
        files.length && !isPreviewHidden
          ? thumbs
          : null
      }
      {!isAddDocFirst && children}
    </Wrapper>
  );
};

export default ScanPreview;
