import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FlexList,
  Heading,
  Text,
  // LinkButton,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import theme from '@/styles/theme';
import { useQueryParams } from '@/utils/hooks';

import { InvestmentsActivityType } from '../investments.types';

import { useInvestmentActivityListQuery, useInvestmentsFlexList } from './hooks';
import InvestmentActivitiesList from './InvestmentActivitiesList';

export const GrayLine = styled.hr`
  background-color: ${theme.colors.border2};
  height: 1px;
  width: 100%;
  border: none;
  margin-bottom: ${theme.spacings.m};
`;

const LIST_COUNT_NUMBER = 100;
const QUERY_KEY = 'id';

const InvestmentsTransactions: React.FC = () => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const id = useMemo(() => query.get(QUERY_KEY), [query]);
  const { isLoading } = useInvestmentActivityListQuery(InvestmentsActivityType.Pending);
  const { headers, formattedList } = useInvestmentsFlexList(id || '', InvestmentsActivityType.Pending);
  return (
    <Box data-testid="Investments-transaction-wrapper">
      <Heading level={4} size="small" color="textTitle" style={{ marginBottom: theme.spacings.s }}>{t('Pending activites')}</Heading>
      <Text size="large" color="textBody">{t('Your pending trades will be completed once the market opens.  You can cancel any pending trades if you change your mind prior to the market opening. Please note that the share amounts listed are estimates and they may fluctuate based on the actual market share price when the trade is completed.')}</Text>
      <Box
        margin={{ vertical: 'm' }}
        pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
        background={{ color: 'warningContainer' }}
        border={{ size: '1px', color: 'warningBorder' }}
        round="fieldRound"
      >
        <Box justify="between" align="center" direction="row">
          <Text weight="bold" margin={{ bottom: 'spacing8' }} color="textBody">
            {t('It looks like your trades were made after the stock market closed for the day.')}
          </Text>
          {/* <LinkButton
            size="M"
            onClick={() => {}}
          >
            {t('Dismiss')}
          </LinkButton> */}
        </Box>
        <Text
          color="textBody"
          size="14px"
        >
          {t('We will complete these trades once the market opens back up and you will be all set!')}
        </Text>
      </Box>
      <Box direction="column" background="module" round="moduleRound" margin={{ bottom: 'spacing24' }} pad={{ top: 's' }}>
        <FlexList
          key={InvestmentsActivityType.Pending}
          rows={formattedList}
          headers={headers}
          loading={isLoading}
          moreCount={LIST_COUNT_NUMBER}
        />
      </Box>
      <GrayLine />
      <Heading level={4} size="small" color="textTitle" style={{ marginBottom: theme.spacings.s }}>{t('Investment activities')}</Heading>
      <Box direction="column" background="module" round="moduleRound" margin={{ bottom: 'spacing24' }} pad={{ top: 's' }}>
        <InvestmentActivitiesList key={id} id={String(id)} />
      </Box>
    </Box>
  );
};

export default InvestmentsTransactions;
