import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { CustomFieldError } from '@/common/types';

interface Params {
  customFieldErorrs?: CustomFieldError
  hideField?: {
    email?: boolean;
    phone?: boolean;
  }
}

const PHONE_TYPES = {
  HOME: 'HOME',
  WORK: 'WORK',
  CELL: 'MOBILE',
  UNKNOWN: 'UNKNOWN',
};
export default ({ hideField, customFieldErorrs }: Params) => {
  const fields = useMemo<Field[]>(() => [

    ...!hideField?.email ? [{
      name: 'email',
      type: FieldTypes.Text,
      label: 'Personal email',
      showRequireIcon: true,
      placeholder: 'Enter email',
      validator: VALIDATORS.EMAIL_REQUIRED,
    }] as Field[] : [],

    ...!hideField?.phone
      ? [{
        name: 'phoneType',
        type: FieldTypes.Dropdown,
        singleMode: true,
        showRequireIcon: true,
        label: 'Phone',
        placeholder: 'Select phone type',
        options: [{
          key: PHONE_TYPES.HOME,
          value: 'Home',
        }, {
          key: PHONE_TYPES.WORK,
          value: 'Work',
        }, {
          key: PHONE_TYPES.CELL,
          value: 'Cell',
        }, {
          key: PHONE_TYPES.UNKNOWN,
          value: 'Unknown',
        }],
        defaultValue: PHONE_TYPES.CELL,
        validator: VALIDATORS.REQUIRED_STRING,
        subFields: [{
          name: 'phone',
          type: FieldTypes.PhoneNumber,
          label: '',
          value: ' ',
          placeholder: '+1',
          validator: customFieldErorrs?.phone ? VALIDATORS.STRING.test({
            test: (val) => val !== customFieldErorrs?.phone?.value,
            message: customFieldErorrs?.phone?.message,
          }) : VALIDATORS.PHONE_NUMBER 
          ,

        }],
        // defaultValue: defaultValues?.name || '',
      }] as Field[] : [],
  ], [customFieldErorrs, hideField]);

  return fields;
};
