import React from 'react';
import {
  Box, Heading, TransactionsNotFoundIcon,
} from '@common-fe/common-fe';

import theme from '@/styles/theme';

interface Props {
  title: string;
}

export const EmptyInvestmentActivitiesListPlaceholder: React.FC<Props> = ({ title }) => (
  <Box
    data-testid="Empty_investment_activities_list_placeholder"
    pad={{ top: 'spacing32' }}
    align="center"
  >
    <Box margin={{ bottom: 'spacing32' }}>
      <TransactionsNotFoundIcon />
    </Box>
    <Heading
      data-testid="TransactionsNotFound-title"
      margin={{ bottom: 'spacing12' }}
      size="21px"
      style={{ fontWeight: 500 }}
      color={theme.colors.textTitle}
    >
      There are no {title.toLocaleLowerCase()} yet
    </Heading>
  </Box>
);
