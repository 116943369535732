import { useMemo } from 'react';

import { InvestorStatus } from '../investments.types';

import useInvestmentsHoldings from './useInvestmentsHoldings';
import useInvestmentsPendingActivities  from './useInvestmentsPendingActivities';
import useInvestor  from './useInvestor';

export default (accountId?: string) => {
  const investor = useInvestor(accountId); 
  const {
    holdingsData,
  } = useInvestmentsHoldings(accountId);

  const { data: pendingHoldingsData,  } = useInvestmentsPendingActivities(investor.data.investorId);

  const activeHoldings = useMemo(() => {  
    // EL-17263
    // return holdingsData.filter((item) => item.id);
    return holdingsData;
  }, [holdingsData]);
  
  const isActiveInvestorEmpty = useMemo(() => {
    return investor.data.status === InvestorStatus.ACTIVE && activeHoldings.length === 0 && pendingHoldingsData.length === 0; 
  }, [activeHoldings.length, investor.data.status, pendingHoldingsData.length]);
  return isActiveInvestorEmpty;
};