import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

const useDeactivateOneTimeLinkQuery = (hashId?: string) => {
  const { mutateAsync } = useMutation(() => api.patch(PATHS.DEACTIVATE_ONE_TIME_LINK(hashId)));

  return {
    deactivateOneTimeLink: mutateAsync,
  };
};

export default useDeactivateOneTimeLinkQuery;
