import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacings.spacing16};
`;
export const Message = styled.p`
  ${({ theme }) => theme.fonts.text16Regular};
`;

export const Name = styled.p`
  ${({ theme }) => theme.fonts.text56Bold};
  ${({ theme }) => theme.fonts.secondFontFamily};
  font-weight: 900;
`;
