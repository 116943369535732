import React, { useCallback } from 'react';
import { AppButton, Box, Preloader } from '@common-fe/common-fe';

import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { useEmployeeProcessQuery } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/queries/';
import { ProcessingScreenType } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.constants';
import { Wrapper } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.styles';

import TransactionProcessingHeader from '../TransactionProcessingHeader';
import TransactionProcessingImmediately from '../TransactionProcessingImmediately';

interface Props {
  claimId: string;
}

const ReimburseMyselfScreen: React.FC<Props> = ({
  claimId,
}) => {
  const { employeeProcess, isLoading } = useEmployeeProcessQuery(claimId);
  const selectedPaymentMethod = useStore((state) => state.selectedMethod);

  const handleSubmit = useCallback(() => {
    employeeProcess({
      paymentMethodId: selectedPaymentMethod?.id,
      transactMethod: selectedPaymentMethod?.type,
    });
  }, [employeeProcess, selectedPaymentMethod]);

  return (
    <Wrapper
      pad="xl"
      background="canvas"
      border={{ size: 'small', color: 'border2' }}
    >
      <TransactionProcessingHeader
        processingScreenType={ProcessingScreenType.ReimburseMyself}
      />
      <Box margin={{ top: 'spacing24' }}>
        <Box
          border={{ side: 'top', size: '1px', color: 'border1' }}
          pad={{ top: 'spacing24' }}
        >
          <TransactionProcessingImmediately />
        </Box>
        <Box margin={{ top: 'spacing24' }}>
          <AppButton
            width="100%"
            size="L"
            onClick={handleSubmit}
          >
            {
              isLoading ? (
                <Preloader />
              ) : 'Reimburse Myself'
            }
          </AppButton>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default ReimburseMyselfScreen;
