import { Box,Button } from '@common-fe/common-fe';
import styled from 'styled-components';

export const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.danger};
  transition: all .3s;
  &:hover {
    background: ${({ theme }) => theme.colors.dangerContainer};
  }
`;

export const Wrapper = styled(Box)`
  .icon {
    font-size: ${({ theme }) => theme.spacings.xxxl};
    color: ${({ theme }) => theme.colors.warning};
  }
`;
