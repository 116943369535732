import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface StatePayload {
  id: number;
  code: string;
  name: string;
}

export interface State {
  id: string;
  name: string;
  code: string;
}

const formatData = (states: StatePayload[]) =>
  states.map((state: StatePayload) => ({
    id: `${state.id}`,
    name: state.name,
    code: state.code,
  }));

const useStatesQuery = () => {
  const { isLoading, isSuccess, isError, data } = useQuery(PATHS.STATES, () => api.get(PATHS.STATES), {});
  const states = formatData(data?.data || []);
  const statesOptions = useMemo(
    () => states.map((state) => ({ key: state.code, title: state.name })),
    [states]
  );
  return {
    states,
    options: statesOptions,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useStatesQuery;
