import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

export const AutoSelfDirectedAllocationHeader = () => {
  
  const { t } = useTranslation();
  return (
    <Box
      background="legend"
      align="center"
      round="container2Round"
      direction="row"
      pad={{ vertical: '7px', horizontal: '20px' }}
    >
      <Box style={{ flex: 2 }} margin={{left: '43px'}}>
        <Text
          color="textBody"
          weight={700}
          size="small"
          style={{ lineHeight: '18px' }}
        >{t('Fund Name')}
        </Text>
      </Box>
 
      <Box direction='row' align='center' justify='center' width="190px" margin={{ left: 'xs' }}>
        <Text
          color="textBody"
          weight={700}
          size="small"
          style={{ lineHeight: '18px' }}
        >{t('Election')}
        </Text>
      </Box>
    </Box>
  );
};
