import { useMemo } from 'react';
import { uniqBy } from 'lodash';

import { useActiveFunds } from '@/modules/investments/Investments/hooks';

import { useAddedFundsStore } from '../../AddMoreFunds/stores';
import { useFundsListQuery } from '../../queries';
import { FundsSearchParams } from '../../SelfDirected.types';

interface Params {
  searchParams: FundsSearchParams;
  isInvestMoreMode?: boolean;
  accountId?: string;
  isSwitchMode?: boolean;
}
export default (params?: Params) => {
  const {
    data: dataFunds,
    // total: totalFunds,
    isLoading: isLoadingFunds,
  } = useFundsListQuery(params?.searchParams, params?.isInvestMoreMode);
  const {
    data: dataActiveFunds,
    // total: totalActiveFunds,
    isLoading: isLoadingActiveFunds,
  } = useActiveFunds(
    {
      accountId: params?.accountId,
    },
    !params?.isInvestMoreMode && !params?.isSwitchMode
  );

  const isLoading = useMemo(
    () => (params?.isInvestMoreMode ? isLoadingActiveFunds : isLoadingFunds),
    [isLoadingActiveFunds, isLoadingFunds, params?.isInvestMoreMode]
  );
  const data = useMemo(() => {
    if (params?.isInvestMoreMode) {
      return dataActiveFunds;
    }
    return dataFunds;
  }, [dataActiveFunds, dataFunds, params?.isInvestMoreMode]);
  const localFunds = useAddedFundsStore((state) => state.funds);

  const expandedData = useMemo(() => {
    return uniqBy([...data, ...localFunds].reverse(), 'id').reverse();
  }, [data, localFunds]);

  const total = useMemo(() => {
    return expandedData.length;
  }, [expandedData.length]);

  const fundsIds = useMemo(() => {
    return expandedData?.map((item) => item.id);
  }, [expandedData]);
  const mutualFunds = useMemo(() => {
    return dataActiveFunds;
  }, [dataActiveFunds]);
  const mutualFundIds = useMemo(() => {
    return dataActiveFunds?.map((item) => item.id);
  }, [dataActiveFunds]);
  
  return {
    fundsIds,
    mutualFunds,
    mutualFundIds,
    data: expandedData,
    total,
    isLoading,
  };
};
