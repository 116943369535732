/* eslint-disable @typescript-eslint/no-explicit-any */
 
import { createSelector } from 'reselect';

export interface Dimentions {
  width: number;
  height: number;
  offsetWidth?: number;
  offsetHeight?: number;
  parentNode?: { offsetWidth: any; offsetHeight: any };
}

export const snapToTarget = (
  value: number,
  target: number,
  tolerance: number,
) => {
  const withinRange = Math.abs(target - value) < tolerance;
  return withinRange ? target : value;
};

export const negate = (value: number) => value * -1;

export function constrain(
  lowerBound: number,
  upperBound: number,
  value: number,
) {
  return Math.min(upperBound, Math.max(lowerBound, value));
}

function round(number: number, precision: number) {
  if (precision && number !== null && number !== undefined) {
    // Shift with exponential notation to avoid floating-point issues.
    // See [MDN](https://mdn.io/round#Examples) for more details.
    let pair = `${String(number)}e`.split('e');
    // this requires some dirty hacks with typescript though
    const value = Math.round(
      `${pair[0]}e${+pair[1] + precision}` as unknown as number,
    );

    pair = `${String(value)}e`.split('e');
    return +`${pair[0]}e${+pair[1] - precision}`;
  }
  return Math.round(number);
}

export const getRelativePosition = (
  { clientX, clientY }: any,
  relativeToElement: { getBoundingClientRect: () => any },
) => {
  const rect = relativeToElement.getBoundingClientRect();
  return {
    x: clientX - rect.left,
    y: clientY - rect.top,
  };
};

export const getPinchMidpoint = ([touch1, touch2]: [any, any]) => ({
  x: (touch1.clientX + touch2.clientX) / 2,
  y: (touch1.clientY + touch2.clientY) / 2,
});

export const getPinchLength = ([touch1, touch2]: [any, any]) => Math.sqrt(
  (touch1.clientY - touch2.clientY) ** 2
      + (touch1.clientX - touch2.clientX) ** 2,
);

export function setRef(
  ref: { (arg0: any): void } | { current: any },
  value: any,
) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
     
    ref.current = value;
  }
}

export const isEqualDimensions = (
  dimensions1: { width: any; height: any },
  dimensions2: { width: any; height: any },
) => {
  if ((dimensions1 === dimensions2) === undefined) {
    return true;
  }
  if (dimensions1 === undefined || dimensions2 === undefined) {
    return false;
  }
  return (
    dimensions1.width === dimensions2.width
    && dimensions1.height === dimensions2.height
  );
};

export const getDimensions = (object: {
  offsetWidth?: any;
  width?: any;
  offsetHeight?: any;
  height?: any;
}) => {
  if (object === undefined) {
    return undefined;
  }
  return {
    width: object.offsetWidth || object.width,
    height: object.offsetHeight || object.height,
  };
};

export const getContainerDimensions = (image: {
  parentNode?: { offsetWidth: any; offsetHeight: any };
}) => ({
  width: image.parentNode?.offsetWidth,
  height: image.parentNode?.offsetHeight,
});

export const isEqualTransform = (
  transform1: { top?: any; left?: any; scale?: any },
  transform2: { top?: any; left?: any; scale?: any },
) => {
  if ((transform1 === transform2) === undefined) {
    return true;
  }
  if (transform1 === undefined || transform2 === undefined) {
    return false;
  }
  return (
    round(transform1.top, 5) === round(transform2.top, 5)
    && round(transform1.left, 5) === round(transform2.left, 5)
    && round(transform1.scale, 5) === round(transform2.scale, 5)
  );
};

export const getAutofitScale = (
  containerDimensions: Dimentions,
  imageDimensions: Dimentions,
) => {
  const { width: imageWidth, height: imageHeight } = imageDimensions || {};
  if (!(imageWidth > 0 && imageHeight > 0)) {
    return 1;
  }
  return Math.min(
    containerDimensions.width / imageWidth,
    containerDimensions.height / imageHeight,
    1,
  );
};

export const getMinScale = createSelector(
  (state) => state.containerDimensions,
  (state) => state.imageDimensions,
  (_state: any, props: { minScale: any }) => props.minScale,
  (containerDimensions, imageDimensions, minScaleProp) => (String(minScaleProp).toLowerCase() === 'auto'

    ? getAutofitScale(containerDimensions as Dimentions, imageDimensions as Dimentions)

    : minScaleProp || 1),
);

export const tryCancelEvent = (event: {
  cancelable: boolean;
  preventDefault: () => void;
}) => {
  if (event.cancelable === false) {
    return false;
  }

  event.preventDefault();
  return true;
};

function calculateOverflowLeft(left: any) {
  const overflow = negate(left);
  return overflow > 0 ? overflow : 0;
}

function calculateOverflowTop(top: any) {
  const overflow = negate(top);
  return overflow > 0 ? overflow : 0;
}

function calculateOverflowRight(
  left: any,
  scale: number,
  imageDimensions: { width: number },
  containerDimensions: { width: number },
) {
  const overflow = Math.max(
    0,
    scale * imageDimensions.width - containerDimensions.width,
  );
  return overflow > 0 ? overflow - negate(left) : 0;
}

function calculateOverflowBottom(
  top: any,
  scale: number,
  imageDimensions: { height: number },
  containerDimensions: { height: number },
) {
  const overflow = Math.max(
    0,
    scale * imageDimensions.height - containerDimensions.height,
  );
  return overflow > 0 ? overflow - negate(top) : 0;
}

export const getImageOverflow = (
  top: number,
  left: number,
  scale: number,
  imageDimensions: Dimentions,
  containerDimensions: Dimentions,
) => ({
  top: calculateOverflowTop(top),
  right: calculateOverflowRight(
    left,
    scale,
    imageDimensions,
    containerDimensions,
  ),
  bottom: calculateOverflowBottom(
    top,
    scale,
    imageDimensions,
    containerDimensions,
  ),
  left: calculateOverflowLeft(left),
});
