import React, { useCallback, useEffect, useState } from 'react';
import { Preloader } from '@common-fe/common-fe';
import _ from 'lodash';

import { RiskControlOptions } from '@/common/constants';
import ROUTES from '@/common/routes';
import { PreventLeaveModal } from '@/modules/core/components';
import { useHistory } from '@/modules/core/hooks';
import { useMyEnrollments } from '@/modules/transaction';

import { useFundingAmountStore } from '../BuySell/stores/useFundingAmount.store';
import {  useInvestorById } from '../hooks';
import PortfolioAllocation from '../StartInvesting/PortfolioAllocation';

import { ManagedWrapper } from '.';
interface Props {
  accountId: string;

}
const ManagedSwitchAllocation: React.FC<Props> = ({ accountId}) => {
  const [isPreventLeave, setPreventLeave] = useState(false);

  const { currentMyAccount, isLoading } = useMyEnrollments(accountId);
  const { data: investor } = useInvestorById({ 
    isSwitchMode: false,
  });
  const [proposedToleranceModel, setProposedToleranceModel] = useState<RiskControlOptions>();
  const { values } = useFundingAmountStore();
  useEffect(() => {
    const model = investor?.proposedToleranceModel as string;
    if(proposedToleranceModel !== model && model) {
      setProposedToleranceModel(model as RiskControlOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investor?.proposedToleranceModel, investor.riskTolerance]);
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const history = useHistory();
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleNext= useCallback(() => {
    setPreventLeave(false);
    history.push(`${ROUTES.INVESTMENTS}?id=${accountId}`);
  }, [accountId, history]);
  
  useEffect(() => {

    if (values.fundingAmount) {
      setInvestmentAmount(_.toNumber(values.fundingAmount || 0));
    }
    if (investor?.proposedToleranceModel) {
      setProposedToleranceModel(investor?.proposedToleranceModel);
    }
  }, [ investor,values ]);
  if(!investor?.investorId) {
    return null;
  }
  if(isLoading || !proposedToleranceModel) {
    return (
      <Preloader />
    );
  }
  return (
    <ManagedWrapper currentStep={1}>
      <PreventLeaveModal isPrevent={isPreventLeave} />
      <PortfolioAllocation
        onBack={handleBack}
        handleGoDashboard={handleNext}
        // setIsAllowRedirect={setIsAllowRedirect}
        isSwitchMode
        step={3}
        stepLength={3}
        currentMyAccount={currentMyAccount}
        recommendedRisk={proposedToleranceModel}
        investmentAmount={investmentAmount}
        setInvestmentAmount={setInvestmentAmount}
        investorId={`${investor.investorId || ''}`} 
      />
   
    </ManagedWrapper>
  );
};
export default ManagedSwitchAllocation;