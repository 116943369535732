import React, { useMemo } from 'react';

import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { ProcessingScreenType } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.constants';
import { PaymentType } from '@/modules/transaction/transaction.types';
import { ClaimAutoPayType } from '@/modules/user/user.types';

import HowToProcessClaimScreen from './HowToProcessClaimScreen/HowToProcessClaimScreen';
import PayeeIsNotDeterminedScreen from './PayeeIsNotDeterminedScreen/PayeeIsNotDeterminedScreen';
import PayMyProviderScreen from './PayMyProviderScreen/PayMyProviderScreen';
import ReimburseMyselfScreen from './ReimburseMyselfScreen/ReimburseMyselfScreen';

interface Props {
  claimId: string;
  paymentType?: PaymentType;
  claimAutoPayType?: ClaimAutoPayType;
  providerPaymentMethod?: PaymentMethod;
}

const ProcessingScreen: React.FC<Props> = ({
  claimId,
  paymentType,
  claimAutoPayType,
  providerPaymentMethod,
}) => {
  const processingScreenType = useMemo(() => {
    if (paymentType === PaymentType.REIMBURSEMENT) return ProcessingScreenType.ReimburseMyself;
    if (paymentType === PaymentType.BILL_PAY) return ProcessingScreenType.PayMyProvider;
    if (!paymentType && claimAutoPayType) return ProcessingScreenType.PayeeIsNotDetermined;
    if (!paymentType && !claimAutoPayType) return ProcessingScreenType.HowToProcessPlanClaims;
    return undefined;
  }, [paymentType, claimAutoPayType]);

  const screenComponent = useMemo(() => ({
    [ProcessingScreenType.ReimburseMyself]: <ReimburseMyselfScreen claimId={claimId} />,
    [ProcessingScreenType.PayMyProvider]: (
      <PayMyProviderScreen
        claimId={claimId}
        providerPaymentMethod={providerPaymentMethod}
      />
    ),
    [ProcessingScreenType.PayeeIsNotDetermined]: (
      <PayeeIsNotDeterminedScreen
        claimId={claimId}
        providerPaymentMethod={providerPaymentMethod}
      />
    ),
    [ProcessingScreenType.HowToProcessPlanClaims]: (
      <HowToProcessClaimScreen
        claimId={claimId}
        providerPaymentMethod={providerPaymentMethod}
      />
    ),
  }), [claimId, providerPaymentMethod]);

  if (!processingScreenType) return null;

  return screenComponent[processingScreenType];
};

export default ProcessingScreen;
