import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { useInvestor } from '@/modules/investments/hooks';
import { SellManagedInvestments } from '@/modules/investments/Managed';


const ManagedSellInvestmentsPage =() => {
  const { t } = useTranslation();
  const { accountId = '' } = useParams<{ accountId: string }>();
  const { data: {  investorId } } = useInvestor(accountId);
  
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: '...',
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Investments'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Sell Investments'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }], true);
  if (!accountId || !investorId) {
    return null;
  }
  return (
    
    <Box>

      <SellManagedInvestments accountId={accountId} />
    </Box>
    
  );
};

export default ManagedSellInvestmentsPage;