import { useCallback, useMemo } from 'react';

import { CardHolderDto } from '@/modules/transaction/components/Cards/Cards.types';
import { useGetPatientsQuery } from '@/modules/transaction/components/ReimburseMe/ClaimInfo/usePatients.query';
import { useAuthStore } from '@/modules/user/stores';

export interface DependentDto {
  id: number;
  firstName: string;
  lastName: string;
  birthOn: string;
  relationshipType: string;
}

export const useGetDependents = () => {
  const { user } = useAuthStore();
  const { patients, isLoading } = useGetPatientsQuery();

  const employeeId = useMemo(() => user?.employee?.id, [user]);

  const formatDependent = useCallback((dependent: DependentDto) => ({
    dependentId: `${dependent.id}`,
    employeeId: `${employeeId}` || '',
    name: `${dependent.firstName} ${dependent.lastName}`,
    firstName: dependent.firstName,
    lastName: dependent.lastName,
    dateOfBirth: dependent.birthOn,
    relationship: dependent.relationshipType,
  }), [employeeId]);

  const formattedDependents: CardHolderDto[] = useMemo(() => [
    ...(patients || []).map(formatDependent),
  ], [patients, formatDependent]);

  return {
    dependents: formattedDependents,
    isLoading,
  };
};
