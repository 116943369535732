import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Grommet } from 'grommet';

import { useCombinedTheme } from '@/modules/core/hooks/useCombinedTheme';

interface Props {
  id: 'modal',
  children?: React.ReactNode;
}

const Portal: React.FC<Props> = ({ id, children }) => {
  const rootElemRef = useRef(document.createElement('div'));
  const combinedTheme = useCombinedTheme();
  useEffect(() => {
    const parentElem = document.querySelector(`#${id}-root`);
    if (parentElem) parentElem.appendChild(rootElemRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (rootElemRef.current) rootElemRef.current.remove();
    };
  }, [id]);

  return ReactDOM.createPortal(
    (<Grommet theme={combinedTheme}>{children}</Grommet>), rootElemRef.current,
  );
};

export default Portal;
