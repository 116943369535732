import { useCallback, useState } from 'react';

export default () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChangeDates = useCallback((dates : [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);
  return {
    startDate,
    endDate,
    onChangeDates,
  };
};
