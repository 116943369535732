import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Hint, Preloader, Text } from '@common-fe/common-fe';
import _ from 'lodash';

import AddMoreFunds from '@/modules/investments/SelfDirected/AddMoreFunds';
import { AllocationPercentsMap } from '@/modules/investments/SelfDirected/SelfDirected.types';
import TotalPercents from '@/modules/investments/SelfDirected/SelfDirectedAllocation/TotalPercents';

import { AutoSelfDirectedAllocationHeader } from './AutoSelfDirectedAllocationHeader';
import  AutoSelfDirectedAllocationItem from './AutoSelfDirectedAllocationItem';
import { useSelfDirectedFunds } from './hooks';
import { useCurrentInvestorHoldingsStore } from './store';
import useAutoSelfDirectedAllocation from './useAutoSelfDirectedAllocation';

interface Props {
  investAmount?: number;
  onSetValues?: (values: AllocationPercentsMap) => void;
  onSetPercentError?: (value: boolean) => void;
  initialValues?: AllocationPercentsMap;
  accountId: string;
  isEditMode?: boolean;
}

const MAX_PERCENTS = 100;

export const AutoSelfDirectedAllocationFunds: React.FC<Props> = ({
  investAmount,
  onSetPercentError,
  onSetValues,
  initialValues = {},
  accountId,
  isEditMode
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const holdings = useCurrentInvestorHoldingsStore((state) => state.holdings);
  const currentHoldings = useMemo(() => isEditMode ? [] : holdings, [isEditMode, holdings]);
  const { selfDirectedFunds, addedFunds } = useSelfDirectedFunds();
  const { 
    checkedIds,
    filteredList,
    values,
    checkedValues,
    handleChange,
    totalPercents,
    handleCheckItem,
    
  }  = useAutoSelfDirectedAllocation({
    selfDirectedFunds,
    addedFunds,
    currentHoldings,
    initialValues,
  });
  const currentIds = useMemo(() => filteredList.map((item) => item.id || ''), [filteredList]);

  const isOnlyOneAvailable = useMemo(() => {
    return filteredList?.length <= 1;
  }, [filteredList?.length]);

  useEffect(() => {
    if (totalPercents !== MAX_PERCENTS) {
      onSetPercentError?.(true);
    } else {
      onSetPercentError?.(false);
    }
  }, [onSetPercentError, totalPercents]);

  useEffect(() => {
    onSetValues?.(checkedValues);
  }, [onSetValues, checkedValues]);

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Box pad="l" background="module" round="moduleRound" margin={{ top: 'l' }}>

      {loading ?
        (<Preloader />) : 
        (
          <>
            <Box direction='row' margin={{bottom: 's'}} justify='between'>
              <Text size="large" margin={{bottom: 'xs', top:'xs'}} color="textBody" weight={700}>{t('Funds:')} {checkedIds.length}</Text>
              <AddMoreFunds accountId={accountId} excludeIds={currentIds} />
            </Box>
            <AutoSelfDirectedAllocationHeader />
            
            <Box>
              {
                filteredList.map((item) => {
                  return (
                    <AutoSelfDirectedAllocationItem 
                      key={item.id}
                      defaultValue={_.get(initialValues,`[${item?.id}]`, undefined)} 
                      isOnlyOneAvailable={isOnlyOneAvailable}
                      value={values[item.id as string]}
                      onChangeValue={handleChange(item.id as string)}
                      disabled={!investAmount || !checkedIds.includes(item.id as string)}
                      checked={checkedIds.includes(item.id as string)}
                      {...item}
                      onSelect={handleCheckItem}
                    />
                  );
                } )
              }
            </Box>
            <Box 
              direction='row'
              pad={{vertical: '6px', horizontal: 'l'}}
              background="border1"
              margin={{top: 'xs'}}
              round="container2Round"
              justify="between">
              <Text size="medium" weight={700}>{t('Total')}</Text>
              <Box direction='row'>
  
                {/* <Box>
            <Text weight={700}>{
              costFormater(investAmount || 0 * (totalPercents / 100))
            }
            </Text>
          </Box> */}
                <Box direction="row" margin={{right: 'l'}} align='center' justify='center' width="140px">
                  {totalPercents > MAX_PERCENTS
                    ? (
                      <Hint
                        hintElement={<TotalPercents value={totalPercents} />}
                      >
                        <Text textAlign="center">
                          {t('The total percentage cannot exceed 100%.')}
                        </Text>
                      </Hint>
                    )
                    : (
                      <TotalPercents value={totalPercents} /> 
                    )}
                </Box>
              </Box>
            </Box>

            <Box margin={{top: 's'}}>
              <Text size="medium" color="textSecondary">
                {t('*Actual share price and number of shares will be calculated at the time the trade is executed.')}
              </Text>
            </Box>
          </>
        )}
    </Box>
  );
};
