import { useMemo } from 'react';

import { useMyEnrollments } from '@/modules/transaction';

import { InvestorStatus } from '../investments.types';
import { useInvestorQuery } from '../queries';

export default (accountId?: string) => {
  const result = useInvestorQuery();
  const account = useMyEnrollments(accountId);
  const accountBalance = useMemo(() => {
    if (account && account.currentMyAccount && account.currentMyAccount.available) {
      return account.currentMyAccount.available;
    }
    return 0;
  }, [account]);
  const availableToInvest = useMemo(() => {
    const { investorBalance = 0 } = result.data;
    return accountBalance - investorBalance;
  }, [accountBalance, result]);

  // in case when we don't have investor entity (first investments)
  const startAvailableToInvest = useMemo(() => {
    const available = account?.currentMyAccount?.available || 0;
    const investmentThreshold = account?.currentMyAccount?.investmentThreshold || 0;
    return available - investmentThreshold;
  }, [account]);
  const minimumInvestmentAmount = useMemo(() => {
    return account?.currentMyAccount?.minimumInvestmentAmount || 0;
  }, [account]);

  const isUnsettledSellingOrders = useMemo(() => {
    return result.data.pendingTradesAmount !== 0;
  }, [result.data.pendingTradesAmount]);
  const isInvestorExists = useMemo(() => {
    return !!result.data.investorId;
  }, [result.data.investorId]);
  const isInvestorAccountExists = useMemo(() => {
    const status = result?.data?.status;
    if (status === InvestorStatus.ACTIVE || status === InvestorStatus.PENDING_EXTERNAL_CREATION) {
      return true;
    }
    return false;
  }, [result?.data?.status]);
  return {
    ...result,
    isUnsettledSellingOrders,
    isInvestorExists,
    isInvestorAccountExists,
    data: {
      ...result.data,
      availableToInvest,
      accountBalance,
      startAvailableToInvest,
      minimumInvestmentAmount,
    },
  };
};
