import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { VerificationType } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';

interface Body {
  value: string;
  type: VerificationType;
}

const useSendVerificationCodeQuery = () => {
  const { mutateAsync, isLoading, isError } = useMutation((body: Body) =>
    api.post(PATHS.SEND_VERIFICATION_CODE, {
      value: body.value,
      type: body.type,
    })
  );

  return {
    sendVerificationCode: mutateAsync,
    isLoading,
    isError,
  };
};

export default useSendVerificationCodeQuery;
