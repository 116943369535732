import { useState } from 'react';

import { RecurringContributionFormValues } from '../contribution.types';

export const useRecurringContribution = (initialFormValues?: RecurringContributionFormValues) => {
  const [recurringInfoSetup, setRecurringInfoSetup] = useState(false);
  const [
    recurringFromValues,
    setRecurringFormValues,
  ] = useState<RecurringContributionFormValues>(initialFormValues || {});

  return {
    recurringInfoSetup,
    setRecurringInfoSetup,
    recurringFromValues,
    setRecurringFormValues,
  };
};
