import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CameraIcon, GalleryIcon } from '@common-fe/common-fe';

import prepareFile from '@/components/controls/DropScan/prepareFile';
import { FileGetAndUploadButton } from '@/modules/transaction/components/UploadFileByLink/FileGetAndUploadButton';
import UploadFileByLinkHeader from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkHeader';

import useFileUploadByQrQuery from './queries/useFileUploadByQr.query';
import { LoadingPopup } from './LoadingPopup';
import { UploadFileByQrLinkAppLinksPopup } from './UploadFileByQrLinkAppLinksPopup';
import { Step } from './uploadQrFile.types';

const BUTTON_WIDTH = '320px';

interface Props {
  setStep: (step: Step) => void;
  isPopupAvailable?: boolean;
  setIsPopupAvailable?: (isPopupAvailable: boolean) => void;
}

export const UploadFileByQrLinkProcess: React.FC<Props> = ({
  setStep,
  isPopupAvailable = true,
  setIsPopupAvailable,
}) => {
  const { t } = useTranslation();
  const [popupVisible, setPopupVisible] = useState(isPopupAvailable);
  const [loading, setLoading] = useState(false);
  const { uploadFile } = useFileUploadByQrQuery();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCapture = async (target: any) => {
    setLoading(true);
    if (target.files) {
      if (target.files.length !== 0) {
        const file = prepareFile(target.files[0]);
        const response = await uploadFile(file);
        if (!response) {
          setLoading(false);
          setStep(Step.ERROR);
        }

        if (response) {
          setTimeout(() => {
            setLoading(false);
            setStep(Step.SUCCESS);
          }, 2000);
        }
      }
    }
  };

  return (
    <Box height={{ min: '100svh' }}>
      <UploadFileByLinkHeader title="Reimburse Me" />
      <Box
        width="100%"
        align="center"
        margin={{ top: 'spacing32' }}
        gap="spacing12"
      >
        <Box width={BUTTON_WIDTH}>
          <FileGetAndUploadButton
            onChange={(e) => handleCapture(e.target)}
            title={t('Use Camera')}
            id="Camera"
            cameraInput
            icon={<CameraIcon color="canvas" />}
          />

        </Box>
        <Box width={BUTTON_WIDTH}>
          <FileGetAndUploadButton
            onChange={(e) => handleCapture(e.target)}
            title={t('Select From Gallery')}
            id="Gallery"
            secondary
            icon={<GalleryIcon color="iconPrimary" />}
          />

        </Box>
      </Box>

      <UploadFileByQrLinkAppLinksPopup
        visible={popupVisible}
        setVisible={setPopupVisible}
        setPopupAvailable={setIsPopupAvailable}
      />
      {
        loading && (
          <LoadingPopup visible={loading} />
        )     
      }
    </Box>
  );
};
