import React from 'react';
import { Box,PencilIcon,RawButton,Text } from '@common-fe/common-fe';
import styled from 'styled-components';

interface Props { 
  children: React.ReactNode;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
 
}

const PencilIconBox = styled(Box)`
  cursor: pointer;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.iconAccent};
      transition: color 0.3s ease-in-out;
    }
  }
`;
const EditBreakdownItem: React.FC<Props> = ({ children,label,  onClick, disabled}) => {
  
  
  return (
    <Box margin={{ bottom: 'm' }}>
      <Box direction="row" align="center">
        <Text
          weight="bold"
          size="16px"
          margin={{ right: 'spacing2' }}
          data-testid="Moderate_portfolio"
        >{children}
        </Text>
        <RawButton disabled={disabled} onClick={onClick}>
          {!disabled && (
            <PencilIconBox >
              <PencilIcon size="16px" className="icon" color="iconSecondary" />
            </PencilIconBox>
          )}
          
        </RawButton>
      </Box>
      <Text color="textSecondary" size="small" weight={500}>{label}</Text>
    </Box>
  );
};
export default EditBreakdownItem;