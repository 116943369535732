import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { EmployeePayload } from '@/modules/user/user.types';

const useEmployeeDataQuery = (employeeId?: string) => {
  const path = `${PATHS.UI_EMPLOYEE}/${employeeId}`;
  const { data, isSuccess, isError } = useQuery([path], () => api.get<EmployeePayload>(path), {
    enabled: !!employeeId,
  });
  const clearedRawData = useMemo(() => {
    const currentData = data?.data;
    delete currentData?.ssn;
    delete currentData?.partner_employee_id;
    delete currentData?.employer_employee_id;
    return currentData;
  }, [data?.data]);
  return {
    isError,
    isSuccess,
    rawData: clearedRawData,
  };
};
export default useEmployeeDataQuery;
