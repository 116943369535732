import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { OptionKey } from '@common-fe/common-fe';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Pagination } from '@/common/types';
import { Statement,StatementPayload } from '@/modules/transaction/components/Statements/statements.types';
import { getParsedNumber } from '@/utils';

interface Props {
  accountId?: string;
  page: number;
  pageSize: number;
  searchString?: string;
  years?: OptionKey[];
  sortBy: OptionKey;
}

const useStatementsQuery = ({
  accountId,
  page,
  pageSize,
  searchString,
  years,
  sortBy,
}: Props) => {
  const params = {
    ...accountId ? { account_id: accountId } : {},
    ...searchString ? { name: searchString } : {},
    sort: `generationDate,${sortBy}`,
    page: toString(page - 1),
    size: toString(pageSize),
  };
  const parsedAccountId = getParsedNumber(accountId);
  const searchParams = new URLSearchParams(params);
  const yearsParams = years?.map((year) => `&statement_years=${year}`).join('') || '';

  const {
    data, isLoading, isSuccess,
  } = useQuery([
    parsedAccountId,
    searchString,
    page,
    pageSize,
    sortBy,
    years,
  ], () => api.get<Pagination<StatementPayload>>(`${PATHS.GET_STATEMENTS}?${searchParams}${yearsParams}`), {
    enabled: !!parsedAccountId,
  });

  const formattedData = useMemo(
    () => data?.data?.content?.map((statement: StatementPayload): Statement => ({
      id: toString(statement.id),
      startDate: statement.start_date,
      name: statement.name,
      documentId: toString(statement.document_id),
    })), [data],
  );

  const total = useMemo(() => data?.data?.totalElements || 0, [data]);

  return {
    formattedData,
    total,
    isLoading,
    isSuccess,
  };
};

export default useStatementsQuery;
