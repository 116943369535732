import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import PaymentTypeIcon from '@/components/elements/PaymentTypeIcon';
import { PAYMENT_METHOD_TITLE } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/ChoosePaymentMethod/hooks';
import { PaymentMethodLabel } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/PaymentMethodLabel';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useOnlyForDev } from '@/utils';

interface Props {
  method: Partial<PaymentMethod>;
}

const Wrap = styled(Box)`
  flex: 1;
`;

const Content = styled(Box)`
  ${({ theme }) => theme.shadows.default};
`;

export const PaymentMethodDetails: React.FC<Props> = ({ method }) => {
  const { t } = useTranslation();
  const isOnlyForDev = useOnlyForDev();

  return (
    <Wrap
      width={{ min: '370px' }}
      pad="spacing12"
      background="module"
      round="container1Round"
      margin={{ right: 'spacing32' }}
    >
      <Content
        pad="spacing16"
        round="container1Round"
        background="canvas"
        border={{ color: 'border2', size: 'small' }}
      >
        <Box
          direction="row"
          align="center"
          border={{ side: 'bottom', color: 'border1', size: 'small' }}
          pad={{ bottom: 'spacing12' }}
          margin={{ bottom: 'spacing12' }}
        >
          <PaymentTypeIcon
            paymentType={method.type}
          />
          <Box
            pad={{ left: 'spacing12' }}
          >
            <Text color="textSecondary">Payment Method</Text>
            {isOnlyForDev && method.type && <Text color="textBody" size="large" weight={700}>{t(PAYMENT_METHOD_TITLE[method.type])}</Text>}
          </Box>
        </Box>
        <Box>
          <PaymentMethodLabel simplifyDesc={!method?.accountNumberLast4} paymentMethod={method} isVerifiedDateVisible={false} />
        </Box>
      </Content>
    </Wrap>
  );
};
