import React from 'react';
import { Box, Inscription, WarningLabel } from '@common-fe/common-fe';

export const EmployerTerminationPlanListPlaceholder = () => {
  return (
    <Box
      data-testid="EmployerTerminationPlanListPlaceholder_container"
      pad={{ bottom: 'spacing16', horizontal: 'spacing24' }}
      background={{ color: 'warningContainer' }}
      border={{ size: '1px', color: 'warningBorder' }}
      round="fieldRound"
      justify="center"
    >
      <Box
        height="2px"
        width="100%"
        background={{ color: 'warning' }}
        round="fieldRound"
        margin={{ bottom: 'spacing16' }}
      />
      <Inscription weight="bold" color="textBody">
        <WarningLabel>
          All your current plans will be closed on the given termination date.
        </WarningLabel>
      </Inscription>
    </Box>
  );
};
