import { useCallback, useEffect } from 'react';
import { toString } from 'lodash';

import { FileImage } from '@/common/constants';
import prepareFile from '@/components/controls/DropScan/prepareFile';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import useGetDocumentByQRCodeQuery from '@/modules/transaction/components/ReimburseMe/queries/useGetDocumentByQRCode.query';
import useFilesStore from '@/modules/transaction/components/ReimburseMe/store/useFiles.store';
import { useQRCodeStore } from '@/modules/transaction/components/ReimburseMe/store/useQRCode.store';
import { DocumentPayload } from '@/modules/transaction/components/ReimburseMe/types/document.types';
import { getBlob } from '@/modules/transaction/hooks/useFileUploadQuery';

import { useQrTransactionFileUploadAdditionalActionsStore } from '../../TransactionDetails/TransactionFileUploadPopup/store';
import { useQrFileUploadAdditionalActionsStore } from '../FileUploadPopup/store';

const useSetFileFromDocumentStream = () => {
  const { state: { qrCodeId }, handleReset } = useQRCodeStore();
  const { document } = useGetDocumentByQRCodeQuery({ qrCodeId });
  const { files, setFiles } = useFilesStore((state) => state);
  const additionalAction = useQrFileUploadAdditionalActionsStore((state) => state.additionalAction);
  const resetFileStore = useQrFileUploadAdditionalActionsStore((state) => state.resetFileStore);

  const additionalTransactionAction = useQrTransactionFileUploadAdditionalActionsStore((state) => state.additionalAction);
  const resetTransactionFileStore = useQrTransactionFileUploadAdditionalActionsStore((state) => state.resetFileStore);
  const {
    handleSetValues,
    values: { files: documentFiles, documentId },
  } = useFilesInDocumentStore();
  const handleSetQRCodeStore = useQRCodeStore((state) => state.setState);

  const handleCreateDocument = useCallback(async () => {
    const createdDocumentId = document?.document_id;
    handleSetQRCodeStore({
      documentId: createdDocumentId,
      qrCodeId,
    });
  }, [document, handleSetQRCodeStore, qrCodeId]);


  const handleSetFilesFromStream = useCallback((filesFromStream: FileImage) => {
    if (additionalAction) {
      additionalAction();
    }
    if (additionalTransactionAction) {
      additionalTransactionAction();
    }
    if (!documentId) {
      handleSetValues({ documentId: toString(document?.document_id) });
    }
    handleCreateDocument();
    setFiles([
      ...resetFileStore || resetTransactionFileStore ? [] : files,
      filesFromStream
    ]);
    handleSetValues({ files: [...documentFiles, filesFromStream] });
  }, [
    documentId,
    handleCreateDocument,
    setFiles,
    files,
    handleSetValues,
    documentFiles,
    document,
    resetFileStore,
    additionalAction,
    resetTransactionFileStore,
    additionalTransactionAction,
  ]);

  const handleSetFileFromDocumentStream = useCallback(async (value: DocumentPayload) => {
    const fileUrl = value?.files?.[0]?.s3_url;
    const fileId = toString(value?.files?.[0]?.id);

    if (!fileUrl || !fileId) return;

    const isFileAlreadyExist = files.find((file) => file.id === fileId);

    if (!isFileAlreadyExist) {
      const fileBlob = await getBlob(fileUrl);

      const fileImage = prepareFile({
        id: fileId,
        temporalId: fileId,
        url: fileUrl,
        type: fileBlob.type,
        blob: fileBlob,
      });

      handleSetFilesFromStream(fileImage);
    }
  }, [handleSetFilesFromStream, files]);

  useEffect(() => {
    if (document) {
      handleSetFileFromDocumentStream(document);
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [document]);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => () => handleReset(), []);
};

export default useSetFileFromDocumentStream;
