import React from 'react';
import { useTranslation } from 'react-i18next';
import { Preloader } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { ActivityDetails } from '@/modules/transaction';
import { useAuthStore } from '@/modules/user/stores';

const ActivityDetailsPage = () => {
  const { user } = useAuthStore();
  const { t } = useTranslation();
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.EXPENSE_DETAILS,
    title: t('Expense details'),
    value: `${ROUTES.EXPENSE_DETAILS}`,
  }]);

  if (!user) return <Preloader />;

  return (
    <ActivityDetails />
  );
};
export default ActivityDetailsPage;
