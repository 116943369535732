import jwt_decode from 'jwt-decode';

import { AVAILABLE_ROLES } from '@/common/constants';

export const verifyUserRole = (accessToken: string) => {
  const decodedToken = jwt_decode(accessToken);
  // @ts-ignore
  const userRole = decodedToken?.['custom:role'];
  const isLoginBlocked = !AVAILABLE_ROLES.find((availableRole) => availableRole === userRole);
  return isLoginBlocked;
};
