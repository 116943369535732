import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  costFormater,
  Inscription,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { DropButton } from '@/components/controls/DropButton';
import { useSnackbar } from '@/modules/core/hooks';

import { Status } from '../../ActivityStatus/ActivityStatus';
import { RecurringContribution, RecurringContributionStatus } from '../contribution.types';
import { useCancelRecurringContributionModalStore, useChangeRecurringContributionStatusModalStore, useRecurringContributionStore } from '../store';

interface StyledButtonProps {
  isWarning?: boolean;
}

const StyledButton = styled(Box)<StyledButtonProps>`
  min-height: 32px;
  justify-content: center;
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  ${({ theme }) => theme.fonts.text14Regular};
  padding-inline: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xxxs};
  color: ${({ theme, isWarning }) => (isWarning ? `${theme.colors.danger} !important` : '')};
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: ${({ theme, isWarning }) => (isWarning ? theme.colors.danger : theme.colors.textAccent)};
    background-color: ${({ theme, isWarning }) => (isWarning ? theme.colors.statusContainerColor.deniedErrorExpiredContainer : theme.colors.accentContainer)};
    transition: all 0.2s ease-in-out;
  }
`;

interface SlotProps {
  label: string;
  value: string;
  isStatus?: boolean;
  withSeparator?: boolean;
  boxStyles?: React.CSSProperties;
}

const ItemSlot: React.FC<SlotProps> = ({
  label,
  value,
  isStatus,
  withSeparator,
  boxStyles,
}) => (
  <Box direction="row">
    <Box
      width={{ min: '112px' }}
      height={{ max: '42px' }}
      style={boxStyles}
    >
      <Box>
        <Inscription
          size="12px"
          weight={500}
          lineHeight="18px"
          margin={{ bottom: 'spacing4' }}
          color="textSecondary"
          data-testid="RecurringContributionItemSlot_label"
        >
          {label}
        </Inscription>
      </Box>

      <Box>
        {isStatus ? (
          <Status
            complete={value === RecurringContributionStatus.Active}
            pending={value === RecurringContributionStatus.Paused}
            customHeight="18px"
            data-testid="RecurringContributionItemSlot_status"
          >
            <Inscription weight={400} lineHeight="18px" data-testid="RecurringContributionItemSlot_value">
              {value}
            </Inscription>
          </Status>
        ) : (
          <Inscription lineHeight="18px" data-testid="RecurringContributionItemSlot_value">
            {value}
          </Inscription>
        )}

      </Box>
    </Box>
    {withSeparator && (
      <Box
        width="1px"
        height="100%"
        background={{ color: 'border1' }}
        margin={{ horizontal: 'spacing24' }}
      />
    )}
  </Box>
);

interface Props {
  recurringContribution: RecurringContribution;
  setRecurringContribution?: () => void;
}

export const RecurringContributionItem: React.FC<Props> = ({
  recurringContribution,
  setRecurringContribution,
}) => {
  const { t } = useTranslation();
  const {
    id,
    amount,
    frequency,
    startDate,
    endDate,
    status,
    nextScheduledDate,
  } = recurringContribution;
  const { handleAddPermanentSnackbar } = useSnackbar();
  const pauseResumeButtonText = useMemo(
    () => (status === RecurringContributionStatus.Active ? 'Pause' : 'Resume'),
    [status],
  );
  const handleSetContribution = useRecurringContributionStore(
    (store) => store.handleSetContribution,
  );

  const handleOpenModal = useChangeRecurringContributionStatusModalStore(
    (store) => store.handleChangeVisibility,
  );
  const setAction = useChangeRecurringContributionStatusModalStore(
    (store) => store.handleSetAction,
  );
  const setOnSubmit = useChangeRecurringContributionStatusModalStore(
    (store) => store.handleSetOnSubmit,
  );
  const onSetCancelModalVisible = useCancelRecurringContributionModalStore((store) => store.handleChangeVisibility);
  const handleSetCancelAdditionalAction = useCancelRecurringContributionModalStore((store) => store.handleSetAdditionalAction);

  const handleChangeContributionStatus = useCallback(() => {
    if (status === RecurringContributionStatus.Active) {
      handleAddPermanentSnackbar({
        text: 'Recurring Contribution was paused',
        closeIcon: true,
      });
    }
    if (status === RecurringContributionStatus.Paused) {
      handleAddPermanentSnackbar({
        text: 'Recurring Contribution was activated',
        closeIcon: true,
      });
    }
    handleOpenModal(false);
  },
  [handleAddPermanentSnackbar, handleOpenModal, status]);

  const handleStartChangeStatus = useCallback(() => {
    if (status === RecurringContributionStatus.Active) {
      handleSetContribution({
        id,
        status: RecurringContributionStatus.Active,
      });
      setAction('Pause');
    }
    if (status === RecurringContributionStatus.Paused) {
      handleSetContribution({
        id,
        status: RecurringContributionStatus.Paused,
      });
      setAction('Resume');
    }

    setOnSubmit(handleChangeContributionStatus);
    handleOpenModal(true);
  }, [handleChangeContributionStatus, handleOpenModal, handleSetContribution, id, setAction, setOnSubmit, status]);

  const handleSetCancelContribution = useCallback(() => {
    onSetCancelModalVisible(true);
    handleSetContribution({id});
    handleSetCancelAdditionalAction(() => {
      handleAddPermanentSnackbar({
        text: 'Recurring Contribution was canceled',
        closeIcon: true,
      });
    });
  }, [
    handleAddPermanentSnackbar,
    handleSetCancelAdditionalAction,
    handleSetContribution,
    id,
    onSetCancelModalVisible,
  ]);

  return (
    <Box
      direction="row"
      pad={{ horizontal: 'spacing24', vertical: 'spacing16' }}
      border={{ color: 'border2' }}
      round="s"
      background={{ color: 'canvas' }}
      height="fit-content"
      width="100%"
      justify="between"
      data-testid="RecurringContributionItem_wrapper"
    >
      <Box direction="row">
        <ItemSlot
          label="Amount"
          value={`${costFormater(amount, true)}`}
          withSeparator
        />
        <ItemSlot
          label="Frequency"
          value={frequency}
          withSeparator
        />
        <ItemSlot
          label="Date range"
          value={`${startDate} - ${endDate || 'Forever'}`}
          withSeparator
          boxStyles={{ marginRight: '30px' }}
        />
        <ItemSlot
          label="Next scheduled contribution"
          value={nextScheduledDate}
          withSeparator
        />
        <ItemSlot label="Status" value={status} isStatus />
      </Box>

      <Box justify="center">
        <DropButton
          contentStyle={{
            top: '40px',
            left: '-130px',
            minWidth: '150px',
            padding: '12px',
            zIndex: 100,
            maxHeight: 'fit-content',
          }}
        >
          <Box>
            <StyledButton
              onClick={handleStartChangeStatus}
            >
              {t(pauseResumeButtonText)}
            </StyledButton>
            <StyledButton
              onClick={() => {
                if (setRecurringContribution) {
                  setRecurringContribution();
                }
              }}
            >
              Edit
            </StyledButton>
            <StyledButton
              onClick={handleSetCancelContribution}
              isWarning
            >
              Cancel
            </StyledButton>
          </Box>
        </DropButton>
      </Box>
    </Box>
  );
};
