import React from 'react';
import { useTranslation } from 'react-i18next';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { Notifications } from '@/modules/notification';

const NotificationsPage = () => {
  const { t } = useTranslation();
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,

  }, {
    key: ROUTES.NOTIFICATIONS,
    title: t('Notifications'),
    value: ROUTES.NOTIFICATIONS,
  }], true);
  return (
    <Notifications />
  );
};

export default NotificationsPage;
