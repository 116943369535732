import { useMemo } from 'react';
import { Option } from '@common-fe/common-fe';
import { toNumber } from 'lodash';

import theme from '@/styles/theme';
import { compactGroups } from '@/utils';

import { EligibleExpense, ParsedFileByOcr, RecommendedValue } from '../../FileScan/fileScan.types';

import useFilteredExpensesOptions from './useFilteredExpensesOptions';
import useSetMaxAmountExpenseOption from './useSetMaxAmountExpenseOption';

const getOptions = (options?: RecommendedValue[]) => (options?.map((option) => ({
  key: `${option.value}_${option.confidence}`,
  value: `${option.value}`,
  title: `${option.value}`,
  confidence: option.confidence,
})));

const covertToRecommendedValue = (key: keyof EligibleExpense, options?: EligibleExpense[]) => (
  options?.map((option) => ({
    key: `${option[key]}_${option.amount}`,
    value: `${option[key]}`,
    title: `${option[key]}`,
  }))
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortByConfidence = (options: any[]) => ([...options]
  ?.sort((prev, next) => {
    if (prev?.isGroupTitle) return 0;
    if (next?.isGroupTitle) return 0;
    return toNumber(prev?.confidence || 0) > toNumber(next?.confidence || 0) ? -1 : 1;
  })
);

interface Props {
  providerOptions: Option[];
  servicesOptions: Option[];
  patientsOptions: Option[];
  scannedDocument?: ParsedFileByOcr;
  serviceDate?: string;
}

export const useClaimInfoGroups = ({
  providerOptions,
  servicesOptions,
  patientsOptions,
  scannedDocument,
  serviceDate,
}: Props) => {
  const hasEligibleExpenses = (scannedDocument?.expenses || []).length >= 2;
  const eligibleExpenses = hasEligibleExpenses ? scannedDocument?.eligibleExpenseServices : [];
  const { sortedExpensesOptions, filteredExpense } = useFilteredExpensesOptions(eligibleExpenses);

  useSetMaxAmountExpenseOption({
    filteredExpense,
    patientsOptions,
    servicesOptions,
  });

  const suggestedProviderOptions = useMemo(
    () => getOptions(scannedDocument?.facilityNames),
    [scannedDocument],
  );

  const suggestedPatientsOptions = useMemo(
    () => hasEligibleExpenses
      ? covertToRecommendedValue('patient', sortedExpensesOptions)
      : getOptions(scannedDocument?.patients),
    [hasEligibleExpenses, sortedExpensesOptions, scannedDocument],
  );

  const suggestedAmountOptions = useMemo(
    () => hasEligibleExpenses
      ? covertToRecommendedValue('amount', sortedExpensesOptions)
      : getOptions(scannedDocument?.amounts),
    [hasEligibleExpenses, sortedExpensesOptions, scannedDocument],
  );

  const suggestedServicesOptions = useMemo(
    () => hasEligibleExpenses
      ? covertToRecommendedValue('service', sortedExpensesOptions)
      : getOptions(scannedDocument?.serviceDescriptions)?.sort((a, b) => (a?.value > b?.value ? 1 : -1)) ,
    [hasEligibleExpenses, sortedExpensesOptions, scannedDocument],
  );

  const serviceDatesGroups = useMemo(() => {
    if (hasEligibleExpenses) {
      const eligibleServiceDates = sortedExpensesOptions?.map((eligibleExpense) => eligibleExpense?.serviceDates)?.flat();

      return eligibleServiceDates?.map((eligibleServiceDate) => ({
        date: eligibleServiceDate,
        styles: { borderColor: theme.colors.success },
      }));
    }

    const serviceDatesOptions = scannedDocument?.serviceDates?.map((date) => ({
      date: date?.value,
      styles: { borderColor: theme.colors.success },
      confidence: date?.confidence,
    })) || [];

    const serviceGroupsOptions = scannedDocument?.serviceGroups?.map((date) => ({
      date: date?.serviceDate?.value,
      styles: { borderColor: theme.colors.success },
      confidence: date?.serviceDate?.confidence,
    })) || [];

    return sortByConfidence([...serviceGroupsOptions, ...serviceDatesOptions]);
  }, [hasEligibleExpenses, sortedExpensesOptions, scannedDocument]);

  const providerGroups = useMemo(() => sortByConfidence(compactGroups({
    suggestedGroup: {
      title: 'Choose one of the suggested options or enter your own',
      placeholderTitle: 'There are no suggested options. Please choose one from the list of others or enter your own',
      options: suggestedProviderOptions,
    },
    mainGroup: {
      title: 'Other',
      placeholderTitle: 'There are no other options',
      options: providerOptions,
    },
  })), [suggestedProviderOptions, providerOptions]);

  const patientGroups = useMemo(() => sortByConfidence(compactGroups({
    suggestedGroup: {
      title: 'Choose one of the suggested options or other options or add a new one using the [+] button',
      placeholderTitle: 'There are no suggested options. Please choose the most appropriate from the list of others or add a new one using the [+] button',
      options: suggestedPatientsOptions,
    },
    mainGroup: {
      title: 'Other',
      placeholderTitle: 'There are no other options',
      options: patientsOptions,
    },
  })), [suggestedPatientsOptions, patientsOptions]);

  const amountGroups = useMemo(() => sortByConfidence(compactGroups({
    mainGroup: {
      title: 'Choose one of the suggested options or enter your own',
      placeholderTitle: 'There are no suggested options. Please enter your own',
      options: suggestedAmountOptions,
    },
  })), [suggestedAmountOptions]);
  
  const serviceGroups = useMemo(() => serviceDate ? sortByConfidence(compactGroups({
    suggestedGroup: {
      title: 'Suggested options',
      placeholderTitle: 'There are no suggested options. Please select the most appropriate from the list of other services',
      options: suggestedServicesOptions,
    },
    mainGroup: {
      title: 'Other services covered by your plans',
      placeholderTitle: 'There are no other options',
      options: servicesOptions,
    },
  })) : [], [suggestedServicesOptions, servicesOptions, serviceDate]);

  return {
    providerGroups,
    patientGroups,
    serviceDatesGroups,
    amountGroups,
    serviceGroups,
  };
};
