import React, {
  useCallback,
  useEffect, useMemo,   useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Box, CalendarIcon, CalendarPickerIcon,   DateRangeDropdown,
  FlexList,
  ListItemBase,
  SearchInput,
  Text, usePaginationParams,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import ROUTES from '@/common/routes';
import { FlexListWrapper } from '@/components/wrappers';
import { useStore } from '@/modules/transaction/components/Contributions/store/useSuccessContributionModal.store';
import PaginationBarWithMediaQuery from '@/modules/transaction/components/PaginationBar';
import { EmptyTransactionsListPlaceholder } from '@/modules/transaction/components/TransactionsList/EmptyTransactionsListPlaceholder';
import {
  DropdownInputWrapper,
  SearchInputWrapper,
} from '@/modules/transaction/components/TransactionsList/TransactionsList.styles';
import { useAuthStore } from '@/modules/user/stores';

import { useActivitiesDatesRange, useActivitiesList } from '../MyAccounts/ActivitiesList/hooks';
import TransactionItem from '../TransactionItem';

const DEFAULT_PAGE_SIZE = 10;
const CONTRIBUTIONS_KEYS = [
  'Hsa incoming contribution',
  'Hsa rollover',
  'Hsa incoming contribution reverse',
  'Hsa rollover reverse',
  'Contribution',
  'Contribution reverse',
];
const today = dayjs();

const HEADERS: ListItemBase[] = [
  {
    key: 'provider',
    title: '',
    flex: 5.3,
  },
  {
    key: 'status',
    title: '',
    flex: 3,
  },
  {
    key: 'amount',
    title: 'Amount',
    flex: 2,
  },
  {
    key: 'statusDate',
    title: 'Date',
    flex: 0.7,
    endAlign: true,
  },
  {
    key: 'option',
    title: '',
    flex: 0.5,
  }];

interface Props {
  hideHeader?: boolean
  id: string;
  title?: string;
}
export const ContributionsList: React.FC<Props> = ({
  id, title, hideHeader,
}) => {
  const [subLoading, setSubLoading] = useState(false);
  const { push } = useHistory();
  const [initialData, setInitialData] = useState(false);
  const { user } = useAuthStore();
  const { onChangeDates, formatedDates } = useActivitiesDatesRange();

  const setSuccessContributionAction = useStore((store) => store.handleSetAdditionalAction);

  const { t } = useTranslation();
  const {
    page, setPage,
    setPerPage, perPage,
    searchString,
    setSearchString,
    currentSearch,
  } = usePaginationParams(DEFAULT_PAGE_SIZE);
  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch, formatedDates.length]);

  useEffect(() => {
    setSubLoading(true);
    const timeout = setTimeout(() => {
      setSubLoading(false);
    }, 200);
    return () => clearTimeout(timeout);
  }, [page]);

  const {
    isLoading,
    total,
    data,
    refetch,
  } = useActivitiesList(
    {
      id,
      employeeId: user?.employee?.id,
      page: page - 1,
      perPage,
      searchString: currentSearch || '',
      types: CONTRIBUTIONS_KEYS,
      startDate: formatedDates[0],
      endDate: formatedDates[1],
    },
    true,
  );

  const isAnyInputFilled = useMemo(() => searchString.length
    || formatedDates.length,
  [
    formatedDates.length,
    searchString.length,
  ]);
  const isDataInclude = useMemo(() => !!data.length, [data]);
  const isInitialData = useCallback(() => (
    (!data.length && !isAnyInputFilled) ? setInitialData(false) : setInitialData(true)),
  [data.length, isAnyInputFilled]);
  useEffect(() => {
    isInitialData();
  }, [isInitialData]);

  useEffect(() => {
    setSuccessContributionAction(() => refetch());
  }, [refetch, setSuccessContributionAction]);

  const currentOnPick = useCallback((claimId?: number, postingId?: number) => {
    if (claimId) {
      return push(ROUTES.TRANSACTION_DETAILS_CLAIM(id, claimId));
    }
    if (postingId) {
      return push(ROUTES.TRANSACTION_DETAILS_POSTING(id, postingId));
    }
    return null;
  }, [id, push]);

  return (
    <Box>
      <Box
        direction="column"
        data-testid="ContributionsList"
        background="module"
        round="container1Round"
        pad={{ bottom: initialData ? undefined : 'spacing24' }}
      >
        <Box
          direction="row"
          data-testid={`Contributions-list-${initialData}`}
          align="center"
          margin={{ bottom: 'spacing4' }}
          pad={{ horizontal: 'spacing24', top: 'spacing24' }}
        >
          <Box direction="row" style={{ width: '100%' }}>
            <Text size="large" color="blockTitle" weight="bold">{title || t('Contribution History')}</Text>
          </Box>
          <SearchInputWrapper>
            <SearchInput
              disabled={!initialData}
              value={searchString}
              hasSearchingHistory
              placeholder={t('Search')}
              onChange={setSearchString}
            />
          </SearchInputWrapper>

          <DropdownInputWrapper>
            <DateRangeDropdown
              ellipsisMode
              disabled={!initialData}
              prefix={t('Period:')}
              className="period-picker"
              onChange={onChangeDates}
              startDate={formatedDates[0]}
              endDate={formatedDates[1]}
              maxDate={today}
              icon={(
                <Box margin={{ bottom: !initialData ? 'spacing4' : undefined }}>
                  {!initialData ? (
                    <CalendarPickerIcon />
                  ) : (
                    <CalendarIcon size="16px" color="iconPrimary" />
                    
                  )}
                </Box>
              )}
            />
          </DropdownInputWrapper>
        </Box>
        <FlexListWrapper>
          <FlexList
            testId="activities_list"
            headers={initialData && !hideHeader ? HEADERS : []}
            rows={[]}
            total={100}
            moreCount={perPage}
            loading={isLoading || subLoading}
            footer={(
              <Box direction="column">
                {!isDataInclude && (
                  <EmptyTransactionsListPlaceholder title="Contributions" isDescriptionHidden />
                )}
                {data.map((item) => (
                  <TransactionItem
                    key={item?.postingId || item?.claimId}
                    category={item.category}
                    type={item?.transactionType}
                    title={item.title}
                    amount={item.amount}
                    status={item.status}
                    date={item.date}
                    onPick={() => currentOnPick(item?.claimId, item?.postingId)}
                  />
                ))}
                {isDataInclude && (
                  <PaginationBarWithMediaQuery
                    page={page}
                    total={total}
                    pageSize={perPage}
                    onChangePage={setPage}
                    onChangePageSize={setPerPage}
                  />
                )}
              </Box>
            )}
          />
        </FlexListWrapper>
      </Box>
    </Box>
  );
};
