import React from 'react';
import { Box } from '@common-fe/common-fe';

import { ChangeStatusCardModal } from '@/modules/transaction/components/Cards/components/ChangeStatusCardModal/ChangeStatusCardModal';
import { useGetCardsQuery } from '@/modules/transaction/hooks/useGetCards.query';
import { useSearchParams } from '@/modules/transaction/hooks/useSearchParams';
import { useAuthStore } from '@/modules/user/stores';

import { CardActivationModal } from '../Cards/components/CardActivationModals/CardActivationModal';
import { CardReplaceModal } from '../Cards/components/CardReplaceModal/CardReplaceModal';
import useCardReplaceModalStore from '../Cards/store/useCardReplaceModal.store';

import { CardCarousel } from './components/CardCarousel/CardCarousel';
import { CardDetailsHeader } from './components/CardDetailsHeader/CardDetailsHeader';
import { CardTransactions } from './components/CardTransactionsList/CardTransactions';

export const CardDetails = () => {
  const {
    state: { modalVisible, cardholderName },
    handleChangeVisibility,
  } = useCardReplaceModalStore();
  const { user } = useAuthStore();
  const { query: dependentId } = useSearchParams('dependent_id');
  const { refetch } = useGetCardsQuery(user?.employee?.id, dependentId);
  return (
    <Box data-testid="CardDetails-wrapper" width="calc(100vw - 352px)">
      <CardDetailsHeader />
      <CardCarousel />
      <CardTransactions />
      <CardReplaceModal
        visible={modalVisible}
        cardholderName={cardholderName}
        onSetVisible={handleChangeVisibility}
        refreshCardList={refetch}
      />
      <ChangeStatusCardModal />
      <CardActivationModal />
    </Box>
  );
};
