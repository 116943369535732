import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton,WarnModal } from '@common-fe/common-fe';

import { Steps } from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/VerifyMyCardModal/VerifyMyCardModal.constants';

interface Props {
  setStep: (step: Steps) => void;
  onSetVisible: (value: boolean) => void;
}

const FailedVerificationModal: React.FC<Props> = ({
  setStep,
  onSetVisible,
}) => {
  const { t } = useTranslation();

  return (
    <WarnModal
      visible
      onSetVisible={onSetVisible}
      testId="failed-verification-modal"
      header={t('Oops, we weren’t able to verify the last 4 digits of your card')}
      helptext={t('Please try again or contact your administrator.')}
      buttonText={t('Cancel')}
      paddingless
      submitButtonNode={(
        <AppButton
          testId="try-again"
          width="180px"
          onClick={() => setStep(Steps.enterCardDigits)}
        >
          {t('Try  Again')}
        </AppButton>
      )}
    />
  );
};

export default FailedVerificationModal;
