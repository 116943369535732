import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const ProcessScanWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  background-color: ${({ theme }) => theme.colors.canvas};
  transition: border 0.24s ease-in-out;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
  background-color: ${({ theme }) => theme.colors.module};
  padding: ${({ theme }) => theme.spacings.spacing24};
`;

export const Title = styled.h4`
  ${({ theme }) => theme.fonts.text24Medium};
`;

export const Subtitle = styled.h4`
  ${({ theme }) => theme.fonts.text16Medium};
  color: ${({ theme }) => theme.colors.stressContainer};
  margin-bottom: ${({ theme }) => theme.spacings.spacing24};
`;

export const ProgressBar = styled(Box)`
  margin: ${({ theme }) => theme.spacings.spacing24};
  border-radius: ${({ theme }) => theme.rounds.container2Round} ;
  background-color: ${({ theme }) => theme.colors.module};
  width: 40%;
  height: 6px;
`;

export const InnerProgressBar = styled.div<{
  progress: number;
}>`
  border-radius: ${({ theme }) => theme.rounds.container2Round} ;
  background-color: ${({ theme }) => theme.colors.accentActive};
  width: ${(props) => Math.floor(props.progress * 100)}%;
  height: 6px;
`;
