import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Inscription, onlyDateToServer, Preloader, SuccessModal } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import DependentForm from '@/modules/transaction/components/ReimburseMe/ClaimInfo/AddPatienForm/PatientForm';
import { Patient } from '@/modules/transaction/components/ReimburseMe/ClaimInfo/usePatients.query';
import useUpdateDependentQuery from '@/modules/user/components/ManageProfile/components/Dependents/queries/useUpdateDependent.query';
import { useStore } from '@/modules/user/components/ManageProfile/components/Dependents/stores/useDependents.store';
import useHighlightedDependentStore from '@/modules/user/components/ManageProfile/components/Dependents/stores/useHighlightedDependent.store';
import useHighlightById from '@/utils/hooks/useHighlightById';

enum Step {
  EDIT_DEPENDENT = 'EDIT_DEPENDENT',
  SUCCESSFULLY_EDITED = 'SUCCESSFULLY_EDITED',
}

interface Props {
  onSetVisible: (value: boolean) => void;
}

const EditDependentModal = ({ onSetVisible }: Props) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [step, setSteps] = useState(Step.EDIT_DEPENDENT);
  const { editableDependent, handleReset } = useStore();
  const editableDependentId = editableDependent?.id;
  const { updateDependent, isLoading } = useUpdateDependentQuery(editableDependentId);
  const { reset, setHighlightedDependentId } = useHighlightedDependentStore();
  const { setHighlightedById } = useHighlightById({ onSetHighlightedId: setHighlightedDependentId, reset });

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleSubmit = useCallback(async (value: Patient) => {
    try {
      setIsError(false);
      await updateDependent({
        firstName: value.firstName,
        lastName: value.lastName,
        birthOn: onlyDateToServer(value.birthOn),
        relationshipType: value.relationshipType,
      });
      setHighlightedById(editableDependentId);
      setSteps(Step.SUCCESSFULLY_EDITED);
    } catch (e) {
      setIsError(true);
    }
  }, [updateDependent, setHighlightedById, editableDependentId]);

  useEffect(() => {
    return () => {
      handleReset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        step === Step.EDIT_DEPENDENT && (
          <ModalWrapper
            visible
            title={t('Edit a Dependent')}
            onSetVisible={onSetVisible}
            nonOverflow 
          >
            <DependentForm
              onSubmit={handleSubmit}
              defaultValue={editableDependent as Partial<Patient>}
              footer={(
                <Box pad={{ top: 'spacing24' }}>
                  {isError && (
                    <Inscription alignSelf="end" color="danger" margin={{ bottom: 'spacing24' }}>
                      {t('Something goes wrong!')}
                    </Inscription>
                  )}
                  <Box direction="row" justify="end" align="center" gap="spacing12">
                    <AppButton
                      type="button"
                      onClick={handleClose}
                      buttonType="secondary"
                      width="control"
                      testId="close"
                    >
                      {t('Cancel')}
                    </AppButton>
                    <AppButton
                      type="submit"
                      width="control"
                      testId="submit"
                    >
                      {isLoading ? <Preloader color="white" /> : t('Save')}
                    </AppButton>
                  </Box>
                </Box>
              )}
            />
          </ModalWrapper>
        )
      }
      {step === Step.SUCCESSFULLY_EDITED && (
        <SuccessModal
          visible
          header={t('Dependent was updated successfully!')}
          buttonText={t('Got It!')}
          onSetVisible={handleClose}
          buttonStyle={{ width: '200px' }}
        />
      )}
    </>
  );
};

export default EditDependentModal;
