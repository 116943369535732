import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { costFormater } from '@common-fe/common-fe';

import { useInvestor } from '@/modules/investments/hooks';


interface  Params {
  accountId: string;
  investorId?: string;
 
  value: number;
}
export default ({accountId, value}: Params) => {
  const { t } = useTranslation();
  const { data: { startAvailableToInvest = 0, minimumInvestmentAmount } } = useInvestor(accountId || '');

  const errorText = useMemo(() => {
   
    if(value < minimumInvestmentAmount) {
      return t(`Please enter an amount equal to or greater than ${costFormater(minimumInvestmentAmount, { isPrecision: true})}`);
    }
    if(value > startAvailableToInvest ) {
      return t(`Please enter an amount equal to or lesser than ${costFormater(startAvailableToInvest, { isPrecision: true})}`);
    }
    return '';
  }, [minimumInvestmentAmount, startAvailableToInvest, t, value]);

  return {
    maxAmount: startAvailableToInvest,
    errorText
  };

};
