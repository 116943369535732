import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Text, TransactionsNotFoundIcon,
} from '@common-fe/common-fe';

import theme from '@/styles/theme';

interface Props {
  title: string;
}

export const EmptyFundsListPlaceholder: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <Box
      data-testid="TransactionsNotFound"
      pad={{ top: 'spacing32' }}
      align="center"
    >
      <Box margin={{ bottom: 'l' }}>
        <TransactionsNotFoundIcon />
      </Box>
      <Text
        data-testid="TransactionsNotFound-title"
        color={theme.colors.textTitle}
        weight="bold"
        size="medium"
      >
        {t(`There are no ${title.toLocaleLowerCase()} activities`)}
      </Text>
    </Box>
  );
};
