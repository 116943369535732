import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, SwitchEmployeeIcon } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { Banner } from '@/modules/core/components/Dashboard/components/BannersList/Banner';
import { useHistory } from '@/modules/core/hooks';

const SwitchEmployeeBanner = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <Banner
      title={t('Complete Your Registration')}
      description={t('Since you previously came through your employer, you will need to create a username and password to access your account.')}
      icon={<SwitchEmployeeIcon />}
      testId="switch-employee"
    >
      <Box margin={{ top: 'spacing24' }} direction="row">
        <Box>
          <AppButton
            testId="open-popup"
            onClick={() => push(ROUTES.SIGN_UP_ORPHAN)}
            width="200px"
          >
            {t('Complete Registration')}
          </AppButton>
        </Box>

      </Box>
    </Banner>
  );
};

export default SwitchEmployeeBanner;
