import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@common-fe/common-fe';

import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';
import UploadFileByLinkInfoContainer from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkInfoContainer';

const ReceiptWasUploadedScreen = () => {
  const { t } = useTranslation();

  return (
    <UploadFileByLinkContentWrapper>
      <UploadFileByLinkInfoContainer
        title={(
          <Box pad={{ horizontal: 'spacing24' }}>
            {t('Your one-time link has already been used!')}
          </Box>
        )}
        iconType="success"
      />
    </UploadFileByLinkContentWrapper>
  );
};

export default ReceiptWasUploadedScreen;
