import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Preloader } from '@common-fe/common-fe';

import UploadFilePageWrapper from '@/components/wrappers/UploadFilePageWrapper';
import useUploadFileByLink from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/hooks/useUploadFileByLink';
import { convertFileToFormData } from '@/utils/modifiers';

import useDeactivateOneTimeLinkQuery from './queries/useDeactivateOneTimeLink.query';
import useGetOneTimeLinkByHashIdQuery from './queries/useGetOneTimeLinkByHashId.query';
import useOneTimeLinkStore from './store/useOneTimeLink.store';
import { useTransactionOcrWarningsStore } from './store/useTransactionOcrWarnings.store';
import {
  AdditionalReviewScreen,
  DocumentAddedScreen,
  ErrorScreen,
  LoadingScreen,
  ReceiptWasUploadedScreen,
  SelectFileScreen,
  SuccessScreen,
} from './components';
import { Step } from './oneTimeLinkUpload.types';

const OneTimeLinkUpload = () => {
  const [step, setStep] = useState(Step.SELECT_FILE);
  const { hashId } = useParams<{ hashId: string }>();
  const { deactivateOneTimeLink } = useDeactivateOneTimeLinkQuery(hashId);
  const { formattedData, isLoading } = useGetOneTimeLinkByHashIdQuery(hashId);
  const setOneTimeLink = useOneTimeLinkStore((state) => state.setState);
  const setTransactionOcrWarnings = useTransactionOcrWarningsStore((store) => store.handleSetOcrWarnings);
  const { handleUploadFile, handleScanFile } = useUploadFileByLink();

  useEffect(() => {
    if (formattedData) {
      setOneTimeLink({
        claimId: formattedData.claimId,
        documentId: formattedData.documentId,
        employeeId: formattedData.employeeId,
        hashId: formattedData.hashId,
        link: formattedData.link,
        organizationId: formattedData.organizationId,
        organizationPath: formattedData.organizationPath,
      });
    }
  }, [formattedData, setOneTimeLink]);

  const handleUploadSelectedFile = useCallback(async (file: File | undefined) => {
    setStep(Step.LOADING);
    try {
      const formData = await convertFileToFormData(file);
      const uploadedFile = await handleUploadFile(formData);
      setOneTimeLink({ documentId: uploadedFile.documentId });
      const scannedFile = await handleScanFile(uploadedFile, hashId);
      if (!scannedFile) {
        throw new Error();
      }
      if (scannedFile?.aiApproved) {
        deactivateOneTimeLink();
        setStep(Step.SUCCESS);
      } else {
        setTransactionOcrWarnings(scannedFile?.warningsFields);
        setStep(Step.ADDITIONAL_REVIEW);
      }
    } catch (e) {
      setStep(Step.ERROR);
    }
  }, [handleUploadFile,
    setStep,
    handleScanFile,
    setTransactionOcrWarnings,
    setOneTimeLink,
    deactivateOneTimeLink,
    hashId]);
  
  if (isLoading) {
    return (
      <Box height="100svh" justify="center" align="center">
        <Preloader color="button" />
      </Box>
    );
  }

  if (!formattedData?.active) {
    return (
      <UploadFilePageWrapper>
        <ReceiptWasUploadedScreen />
      </UploadFilePageWrapper>
    );
  }

  return (
    <UploadFilePageWrapper>
      {step === Step.SELECT_FILE && <SelectFileScreen onSelectFile={handleUploadSelectedFile} />}
      {step === Step.LOADING && <LoadingScreen />}
      {step === Step.SUCCESS && <SuccessScreen />}
      {step === Step.ERROR && <ErrorScreen onSelectFile={handleUploadSelectedFile} />}
      {step === Step.ADDITIONAL_REVIEW && <AdditionalReviewScreen onSelectFile={handleUploadSelectedFile} setStep={setStep} />}
      {step === Step.DOCUMENT_ADDED && <DocumentAddedScreen />}
    </UploadFilePageWrapper>
  );
};

export default OneTimeLinkUpload;
