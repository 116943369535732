import { useCallback } from 'react';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import { toString } from 'lodash';

import { api } from '@/api';
import {
  COUNTRY_MAP,
  DEBIT_EXP_DATE_FORMAT,
  DEFAULT_CARD_EXP_DATE_FORMAT,
  FIRST_DAY_NUMBER,
  TWENTIETH_CENTURY,
} from '@/common/constants';
import paths from '@/common/paths';
import {
  DebitCardPayload,
} from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DebitCart/hooks';
import useAddPaymentMethodQuery from '@/modules/transaction/components/ReimburseMe/queries/useAddPaymentMethod.query';
import { useUpdatePaymentMethodQuery } from '@/modules/transaction/components/ReimburseMe/queries/useUpdatePaymentMethod.query';
import { PaymentMethodOwnerType } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useAuthStore } from '@/modules/user/stores';
import { EmployeePayload, PaymentMethodType } from '@/modules/user/user.types';
import { useEncryptData } from '@/utils';

interface FiservResponse {
  tokenId: string;
  status: string;
  issuedOn: number;
  expiresInSeconds: number;
  publicKey: string;
  algorithm: string;
}

interface AddDebitCardNoEncryptedPayload {
  nameOnCard: string;
  fdCustomerId: string;
  cardType: string;
  address: {
    type: string;
    streetAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
    primary: boolean;
  }
}

interface AddDebitCardEncryptedPayload {
  cardNumber: string;
  expiryDateMonth: string;
  expiryDateYear: string;
}
type AddDebitCardPayload = AddDebitCardEncryptedPayload & AddDebitCardNoEncryptedPayload;
const ENCRYPT_PREFIX = 'ENC_';

interface AddDebitCardResponse {
  type: string;
  token: {
    tokenType: string;
    tokenProvider: string;
    tokenId: string;
  }
}

const getFiservToken = (fdCustomerId: string) => api.post<FiservResponse>(paths.FISERV_TOKEN, {
  token: {
    fdCustomerId,
  },
  publicKeyRequired: true,
});

const formatAddDebitCardBody = (payload: AddDebitCardPayload) => ({
  account: {
    type: 'CREDIT',
    credit: {
      cardNumber: payload.cardNumber,
      nameOnCard: payload.nameOnCard,
      cardType: payload.cardType,
      expiryDate: {
        month: payload.expiryDateMonth,
        year: payload.expiryDateYear,
      },
      billingAddress: payload.address,
    },
  },
  token: {
    tokenType: 'CLAIM_CHECK_NONCE',
  },
  fdCustomerId: payload.fdCustomerId,
});

export const useAddPaymentDebitCardQuery = (onSuccess?: (paymentMethodId?: string) => void) => {
  const { user } = useAuthStore();
  const { save } = useAddPaymentMethodQuery();
  const { updatePaymentMethod } = useUpdatePaymentMethodQuery();
  const { encrypt } = useEncryptData();

  const { mutateAsync: saveDebitCard } = useMutation(
    (data: {
      tokenId: string, body: AddDebitCardPayload,
    }) => api.post<AddDebitCardResponse>(
      paths.ACCOUNT_TOKEN,
      formatAddDebitCardBody(data.body),
      {
        headers: { 'Token-Id': data.tokenId },
      },
    ),
  );
  const { mutateAsync: addVaultedAccountToRecipient } = useMutation(
    (data: { merchantCustomerId: string, tokenId: string, fiservTokenId: string }) => api.post(
      paths.ADD_VAULTED_ACCOUNT_TO_RECIPIENT(data.merchantCustomerId),
      {
        card_token_id: data.tokenId,
      }, {
        headers: {
          'Fiserv-Access-Token': data.fiservTokenId,
        },
      },
    ),
  );

  const addDebitCard = useCallback(async (debitCard: DebitCardPayload, isDefault?: boolean) => {
    if (!user?.employee?.id) return;
    const expMonth = parseInt((debitCard.expirationDate || '').split(' / ')[0] || '0', 10);
    const expYear = parseInt((debitCard.expirationDate || '').split(' / ')[1] || '0', 10);
    const fullExpDate = dayjs(`${expMonth}/${FIRST_DAY_NUMBER}/${TWENTIETH_CENTURY}${expYear}`, DEFAULT_CARD_EXP_DATE_FORMAT).format(DEBIT_EXP_DATE_FORMAT);
    const { data: personalInfo } = await api.get<EmployeePayload>(
      paths.EMPLOYEE_BY_ID(user?.employee?.id),
    );
    const { data: fiservResponse } = await getFiservToken(user?.employee?.id);
    const encryptedResponse = encrypt({
      pubkey: fiservResponse.publicKey,
      data: {
        cardNumber: debitCard.cardNumber || '',
        expiryDateMonth: debitCard.expirationDate?.split('/')[0] || '',
        expiryDateYear: debitCard.expirationDate?.split('/')[1] || '',
      },
    }) as AddDebitCardEncryptedPayload;
    const formatedEncryptedData: AddDebitCardEncryptedPayload = {
      cardNumber: `${ENCRYPT_PREFIX}[${encryptedResponse.cardNumber}]`,
      expiryDateMonth: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateMonth}]`,
      expiryDateYear: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateYear}]`,
    };
    const { data: addedDebitCardResponse } = await saveDebitCard({
      tokenId: fiservResponse.tokenId,
      body: {
        nameOnCard: debitCard.nameOnCard,
        fdCustomerId: user?.employee?.id,
        cardType: debitCard.cardType,
        ...formatedEncryptedData,
        address: {
          type: personalInfo.physical_address?.address_type || '',
          streetAddress: `${personalInfo.physical_address?.line1} ${personalInfo.physical_address?.line2}`,
          locality: personalInfo.physical_address?.state || '',
          region: personalInfo.physical_address?.city || '',
          postalCode: personalInfo.physical_address?.zipcode || '',
          country: personalInfo.physical_address?.country_code || COUNTRY_MAP[1],
          primary: true,
        },
      },
    });
    const { data: accounts } = await addVaultedAccountToRecipient({
      tokenId: addedDebitCardResponse.token.tokenId,
      fiservTokenId: fiservResponse.tokenId,
      merchantCustomerId: user?.employee?.id,
    });
    const response = await save({
      paymentType: PaymentMethodType.DEBIT,
      paymentOwnerType: PaymentMethodOwnerType.EMPLOYEE,
      isDefault,
      debitCard: {
        ...debitCard,
        expirationDate: fullExpDate,
      },
      cardToken: accounts?.accounts?.[0]?.token?.tokenId,
    });
    if (onSuccess) {
      const paymentMethodId = response?.id ? toString(response?.id) : undefined;
      onSuccess(paymentMethodId);
    }
  }, [encrypt, save, saveDebitCard, user, addVaultedAccountToRecipient, onSuccess]);

  const updateDebitCard = useCallback(async (debitCard: DebitCardPayload, methodId: string) => {
    if (!user?.employee?.id) return;
    const expMonth = parseInt((debitCard.expirationDate || '').split(' / ')[0] || '0', 10);
    const expYear = parseInt((debitCard.expirationDate || '').split(' / ')[1] || '0', 10);
    const fullExpDate = dayjs(`${expMonth}/${FIRST_DAY_NUMBER}/${TWENTIETH_CENTURY}${expYear}`, DEFAULT_CARD_EXP_DATE_FORMAT).format(DEBIT_EXP_DATE_FORMAT);
    const { data: personalInfo } = await api.get<EmployeePayload>(
      paths.EMPLOYEE_BY_ID(user?.employee?.id),
    );

    const { data: fiservResponse } = await getFiservToken(user?.employee?.id);
    const encryptedResponse = encrypt({
      pubkey: fiservResponse.publicKey,
      data: {
        cardNumber: debitCard.cardNumber || '',
        expiryDateMonth: debitCard.expirationDate?.split('/')[0] || '',
        expiryDateYear: debitCard.expirationDate?.split('/')[1] || '',
      },
    }) as AddDebitCardEncryptedPayload;
    const formatedEncryptedData: AddDebitCardEncryptedPayload = {
      cardNumber: `${ENCRYPT_PREFIX}[${encryptedResponse.cardNumber}]`,
      expiryDateMonth: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateMonth}]`,
      expiryDateYear: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateYear}]`,
    };
    const { data: addedDebitCardResponse } = await saveDebitCard({
      tokenId: fiservResponse.tokenId,
      body: {
        nameOnCard: debitCard.nameOnCard,
        fdCustomerId: user?.employee?.id,
        cardType: debitCard.cardType,
        ...formatedEncryptedData,
        address: {
          type: personalInfo.physical_address?.address_type || '',
          streetAddress: `${personalInfo.physical_address?.line1} ${personalInfo.physical_address?.line2}`,
          locality: personalInfo.physical_address?.state || '',
          region: personalInfo.physical_address?.city || '',
          postalCode: personalInfo.physical_address?.zipcode || '',
          country: personalInfo.physical_address?.country_code || COUNTRY_MAP[1],
          primary: true,
        },
      },
    });
    await updatePaymentMethod({
      paymentType: PaymentMethodType.DEBIT,
      paymentMethodId: methodId,
      fiservTokenId: fiservResponse.tokenId,
      debitCard: {
        ...debitCard,
        expirationDate: fullExpDate,
        cardToken: addedDebitCardResponse.token.tokenId,
      },
    });
    if (onSuccess) {
      onSuccess(methodId);
    }
  }, [user, encrypt, saveDebitCard, updatePaymentMethod, onSuccess]);

  return {
    addDebitCard,
    updateDebitCard,
  };
};
