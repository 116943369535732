import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ActivityType, onlyDateFromServer } from '@common-fe/common-fe';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { ProcessingStatusType, TransactionApprovalCode } from '../transaction.types';

interface ClaimActivityPayload {
  id: number;
  provider_name: string;
  category_type: ActivityType;
  requested_amount: number;
  local_date_service_on: string;
}
export interface ClaimActivity {
  id: string;
  providerName: string;
  categoryType: ActivityType;
  requestedAmount: number;
  serviceOn: string;
}

const useGetClaimsActivitiesQuery = (
  employeeId: string,
  claimProcessingStatus?: ProcessingStatusType[],
  claimProcessingSubstatus?: TransactionApprovalCode,
) => {
  const { data, isLoading, isSuccess } = useQuery(
    [employeeId, claimProcessingStatus, claimProcessingSubstatus],
    () => api.get(
      PATHS.CLAIMS_ACTIVITIES,
      {
        params: {
          employee_id: employeeId,
          ...claimProcessingStatus?.length ? {
            claim_processing_status: claimProcessingStatus.toString(),
          } : {},
          ...claimProcessingSubstatus ? {
            claim_processing_substatus: claimProcessingSubstatus,
          } : {},
        },
      },
    ),
  );

  const formattedData = useMemo<ClaimActivity[]>(() => {
    if (!data) return [];

    return data?.data?.content?.map((item: ClaimActivityPayload) => ({
      id: toString(item.id),
      providerName: item.provider_name,
      categoryType: item.category_type,
      requestedAmount: item.requested_amount,
      serviceOn: item.local_date_service_on,
    }));
  }, [data]);

  return { isLoading, formattedData, isSuccess };
};

export default useGetClaimsActivitiesQuery;
