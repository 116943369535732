import React from 'react';
import {
  Box, Text,
} from '@common-fe/common-fe';

import { Modal } from '@/components';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  title?: string;
  children: React.ReactNode;
}

const ModalWrapper: React.FC<Props> = ({
  title,
  children,
  onSetVisible,
  visible,
}) => (

  <Modal visible={visible} onSetVisible={onSetVisible}>
    <Box direction="column" height={{ max: 'calc(100vh - 100px)' }}>
      {
        title
        && (
          <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing16' }}>
            <Text size="2xl" weight="bold">{title}</Text>
          </Box>
        )
      }
      <Box overflow="hidden">
        <Box overflow="auto">
          {children}
        </Box>
      </Box>
    </Box>
  </Modal>

);

export default ModalWrapper;
