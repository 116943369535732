import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { AccountAccessPayload, useStore } from '@/modules/user/components/SignUp/AccountAccess/useAccountAccess.store';

import useAccountDetailsFields from './hooks/useAccountDetailsFields';

interface Props {
  isOrphanMode?: boolean;
}

const AccountDetailsForm: React.FC<Props> = ({ isOrphanMode }) => {
  const { t } = useTranslation();

  const showErrors = useStore((state) => state.showErrors);
  const errors = useStore((state) => state.errors);
  const fields = useAccountDetailsFields({ errors, isOrphanMode });

  const handleSetState = useStore((state) => state.setState);
  const { setValues, values } = useDebounceState<Partial<AccountAccessPayload>>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <FlexControlledForm
      formTitle={t('Account Details')}
      fields={fields}
      showError={showErrors}
      editMode
      onChangeValues={setValues}
    />
  );
};

export default AccountDetailsForm;
