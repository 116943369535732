import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes, validatePhoneNumber } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';

export interface FormValues {
  phoneNumber: string;
}

const usePhoneVerificationFields = (phoneNumber: string) => {
  const { t } = useTranslation();

  const fields = useMemo<Field[]>(() => [
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      placeholder: '+1',
      showRequireIcon: true,
      defaultValue: phoneNumber,
      style: { display: 'grid', gridTemplateColumns: 'minmax(200px, 1fr) 320px' },
      validator: yup.string()
        .test(validatePhoneNumber())
        .required(t(REQUIRED_TEXT)),
    },
  ], [phoneNumber, t]);

  return fields;
};

export default usePhoneVerificationFields;
