import React, { useCallback, useState } from 'react';
import {
  Anchor, Box, ChevronDownIcon, ChevronUpIcon, Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

interface Props {
  title: string;
  children: React.ReactNode;
}
const StyledAnchor = styled(Anchor)`
  color: ${({ theme }) => theme.colors.textBody};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.accentActive};
    text-decoration: none;
  }

`;

const DocumentAccordion: React.FC<Props> = ({ children, title }) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  return (
    <Box background="module" margin={{ bottom: 'xs' }} round="container1Round" pad={{ horizontal: 'l', vertical: 'm' }}>
      <Box direction="row">
        <StyledAnchor data-testid="document-accordion_title-button" onClick={handleToggle}>
          <Box direction="row" align='center'>
            <Box margin={{ right: 's' }}>
              {
                expanded ? (
                  <ChevronUpIcon color="iconPrimary" />
                ) : (
                  <ChevronDownIcon color="iconPrimary" />
                )
              }
            </Box>
     
            <Text size="large" weight={700}>{title}</Text>
          </Box>
        </StyledAnchor>
      </Box>
      {expanded && (
        <Box pad={{ top: 'm' }}>
          {children}
        </Box>
      )}

    </Box>
  );
};

export default DocumentAccordion;
