import { Box, Heading } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  overflow-x: hidden;
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  right: -5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.module};

  .image {
    display: ${({ theme }) => (theme.impairment ? 'none' : 'block')} !important;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: 100px;
  align-items: flex-start;
  padding-top: 50px;
  height: 492px;
`;

export const Text = styled.p`
  ${({ theme }) => theme.fonts.text24Medium};
  margin-top: 50px;
`;

export const Footer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.spacing32};
  margin-left: ${({ theme }) => theme.spacings.spacing32};
  flex: 1;
  display: flex;
  align-items: flex-end;
  z-index: 1;
  font-size: ${({ theme }) => theme.spacings.spacing24};

  @media ${({ theme }) => theme.deviceSize.lessThen.laptop} {
    display: none;
  }
`;

export const RightBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.deviceSize.lessThen.laptop} {
    margin: auto;
  }
`;

export const FormContent = styled.div`
  margin-top: ${({ theme }) => theme.spacings.spacing24};
  display: flex;
  flex-direction: column;
`;

interface FormWrapperProps {
  flat?: boolean;
  isBackButton?: boolean;
}
export const FormWrapper = styled.div<FormWrapperProps>`
  display: flex;
  width: auto;
  max-width: ${({ isBackButton }) => (isBackButton ? '548' : '566')}px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.canvas};
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
  ${({ theme }) => ({
    ...theme.shadows.default,
  })};
  padding: ${({ theme }) => `${theme.spacings.spacing48} ${theme.spacings.spacing72}`};
  position: relative;

  ${({ flat, theme }) => {
    if (flat) {
      return {
        padding: theme.spacings.spacing24,
      };
    }
    return {};
  }}

  ${FormContent} {
    margin-top: ${({ theme, flat }) => (flat ? 0 : theme.spacings.spacing24)};
  }

  @media ${({ theme }) => theme.deviceSize.lessThen.laptop} {
    padding: ${({ theme }) => `${theme.spacings.spacing48} ${theme.spacings.spacing24}`};
    margin: ${({ theme }) => `${theme.spacings.spacing24} 0px`};
  }

  @media ${({ theme }) => theme.deviceSize.lessThen.mobileL} {
    border-radius: ${({ theme }) => theme.rounds.container1Round};
    padding: ${({ theme }) => `${theme.spacings.spacing32} ${theme.spacings.spacing24}`};
  }
`;

export const FormTitle = styled.h4`
  ${({ theme }) => theme.fonts.text32Bold};
  text-align: center;
  line-height: 44px;
`;
export const FormSubtitle = styled.h5`
  ${({ theme }) => theme.fonts.text16Regular};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacings.spacing8};
  line-height: 22px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  width: ${({ theme }) => theme.controls.height};
  height: ${({ theme }) => theme.controls.height};
  background-color: ${({ theme }) => theme.colors.canvas};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.rounds.button1Round};
  position: absolute;
  left: -50px;
`;

export const SignInContainer = styled(Box)`
  margin-top: 100px;
  padding: 0px 100px;

  @media ${({ theme }) => theme.deviceSize.lessThen.laptop} {
    margin-top: 0px;
    padding: ${({ theme }) => theme.spacings.spacing12};
    padding-top: ${({ theme }) => theme.spacings.spacing48};
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const SignInTitleHeading = styled(Heading)`
  margin-top: ${({ theme }) => theme.spacings.spacing24};
`;

export const SignInTitleContainer = styled(Box)`
  @media ${({ theme }) => theme.deviceSize.lessThen.laptop} {
    margin: 0px;
    flex: 0;
    align-items: center;

    ${SignInTitleHeading} {
      display: none;
    }

    img {
      max-width: 150px;
      height: 30px;
    }
  }
`;
