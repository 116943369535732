import React, {
  useCallback,
  useEffect, useMemo,   useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, CalendarIcon, CalendarPickerIcon,   DateRangeDropdown,
  FlexList,
  ListItemBase,
  OptionKey,
  SearchInput,
  SelectDropdown,
  Text, usePaginationParams,
} from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import ROUTES from '@/common/routes';
import { FlexListWrapper } from '@/components/wrappers';
import { useHistory } from '@/modules/core/hooks';
import { useStore } from '@/modules/transaction/components/Contributions/store/useSuccessContributionModal.store';
import PaginationBarWithMediaQuery from '@/modules/transaction/components/PaginationBar';
import { EmptyTransactionsListPlaceholder } from '@/modules/transaction/components/TransactionsList/EmptyTransactionsListPlaceholder';
import {
  DropdownInputWrapper,
  SearchInputWrapper,
} from '@/modules/transaction/components/TransactionsList/TransactionsList.styles';
import { useAuthStore } from '@/modules/user/stores';

import TransactionItem from '../../TransactionItem';

import { useActivitiesDatesRange, useActivitiesList } from './hooks';
import { ProcessingActivitiesList } from './ProcessingActivitiesList';

const DEFAULT_PAGE_SIZE = 10;

const HEADERS: ListItemBase[] = [
  {
    key: 'provider',
    title: '',
    flex: 5.3,
  },
  {
    key: 'status',
    title: '',
    flex: 3,
  },
  {
    key: 'amount',
    title: 'Amount',
    flex: 2,
  },
  {
    key: 'statusDate',
    title: 'Date',
    flex: 0.7,
    endAlign: true,
  },
  {
    key: 'option',
    title: '',
    flex: 0.5,
  }];

const stateAllToggling = (statuses: OptionKey[]) => {
  const minStatusesLength = 2;
  const isStatusAllSelected = statuses[statuses.length - 1] === ALL_OPTION.value;

  if (statuses.length === 0 || isStatusAllSelected) {
    return [ALL_OPTION.value];
  }

  const isStatusAllChangedToAnother = statuses.length === minStatusesLength
    && statuses[0] === ALL_OPTION.value;

  if (isStatusAllChangedToAnother) {
    return statuses.filter((status) => status !== ALL_OPTION.value);
  }

  return statuses;
};

interface Props {
  hideHeader?: boolean
  id: string;
  disabled?: boolean;
  title?: string;
}
const ActivitiesList: React.FC<Props> = ({
  id, title, hideHeader, disabled,
}) => {
  const [types, setTypes] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [subLoading, setSubLoading] = useState(false);
  const history = useHistory();
  const [initialData, setInitialData] = useState(false);
  const { user } = useAuthStore();
  const { onChangeDates, formatedDates } = useActivitiesDatesRange();

  const typesValues = useMemo(() => stateAllToggling(types), [types]);

  const setSuccessContributionAction = useStore((store) => store.handleSetAdditionalAction);

  const hasTypes = useMemo(
    () => typesValues.length > 0
      && !typesValues.some((type) => type === ALL_OPTION.value),
    [typesValues],
  );

  const { t } = useTranslation();
  const {
    page, setPage,
    setPerPage, perPage,
    searchString,
    setSearchString,
    currentSearch,
  } = usePaginationParams(DEFAULT_PAGE_SIZE);
  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch, types, formatedDates.length]);

  useEffect(() => {
    setSubLoading(true);
    const timeout = setTimeout(() => {
      setSubLoading(false);
    }, 200);
    return () => clearTimeout(timeout);
  }, [page]);

  const {
    isLoading,
    total,
    data,
    availableTypes,
    dataOnlyHolds,
    refetch,
  } = useActivitiesList(
    {
      id,
      employeeId: user?.employee?.id,
      page: page - 1,
      perPage,
      searchString: currentSearch || '',
      ...(hasTypes ? { types: typesValues } : {}),
      startDate: formatedDates[0],
      endDate: formatedDates[1],
    },
  );

  const typesOptions = useMemo(() => [ALL_OPTION, ...availableTypes], [availableTypes]);

  const isAnyInputFilled = useMemo(() => searchString.length
  || types.length
  || formatedDates.length,
  [
    formatedDates.length,
    types.length,
    searchString.length,
  ]);
  const isDataInclude = useMemo(() => !!data.length, [data]);
  const isInitialData = useCallback(() => (
    (!data.length && !isAnyInputFilled) ? setInitialData(false) : setInitialData(true)),
  [data.length, isAnyInputFilled]);
  useEffect(() => {
    isInitialData();
  }, [isInitialData]);

  useEffect(() => {
    setSuccessContributionAction(() => refetch());
  }, [refetch, setSuccessContributionAction]);

  const currentOnPick = useCallback((claimId?: number, postingId?: number) => {
    if (claimId) {
      return history.push(ROUTES.TRANSACTION_DETAILS_CLAIM(id, claimId));
    }
    if (postingId) {
      return history.push(ROUTES.TRANSACTION_DETAILS_POSTING(id, postingId));
    }
    return null;
  }, [id, history]);

  return (
    <Box>
      {(!isLoading && !!dataOnlyHolds.length) && (
        <Box margin={{ bottom: 'spacing24' }}>
          <ProcessingActivitiesList id={id} data={dataOnlyHolds} />
        </Box>
      )}
      <Box
        direction="column"
        data-testid="TransactionsList"
        background="module"
        round="container1Round"
        pad={{ bottom: initialData ? 'spacing12' : 'spacing24' }}
      >
        <Box
          direction="row"
          data-testid={`activities-list-${initialData}`}
          align="center"
          margin={{ bottom: 'spacing4' }}
          pad={{ horizontal: 'spacing24', top: 'spacing24' }}
        >
          <Box direction="row" style={{ width: '100%' }}>
            <Text size="large" color="blockTitle" weight="bold">{title || t('Expenses')}</Text>
          </Box>
          <SearchInputWrapper>
            <SearchInput
              disabled={!initialData || disabled}
              value={searchString}
              hasSearchingHistory
              placeholder={t('Search')}
              onChange={setSearchString}
            />
          </SearchInputWrapper>
          <DropdownInputWrapper>
            <SelectDropdown
              disabled={!initialData || disabled}
              id="type"
              allMode
              name={t('Activity type')}
              prefix="Type"
              options={typesOptions}
              values={typesValues}
              onChangeValues={setTypes}
            />
          </DropdownInputWrapper>
          <DropdownInputWrapper>
            <DateRangeDropdown
              ellipsisMode
              disabled={!initialData || disabled}
              prefix={t('Period:')}
              className="period-picker"
              onChange={onChangeDates}
              startDate={formatedDates[0]}
              endDate={formatedDates[1]}
              icon={(
                <Box margin={{ bottom: !initialData ? 'spacing4' : undefined }}>
                  {!initialData ? (
                    <CalendarPickerIcon />
                  ) : (
                    <CalendarIcon  color="iconPrimary" size="16px"/>
                    
                  )}
                </Box>
              )}
            />
          </DropdownInputWrapper>
        </Box>
        <FlexListWrapper>
          <FlexList
            testId="activities_list"
            headers={initialData && !hideHeader ? HEADERS : []}
            rows={[]}
            total={100}
            moreCount={perPage}
            loading={isLoading || subLoading}
            footer={(
              <Box direction="column">
                {!isDataInclude && (
                  <EmptyTransactionsListPlaceholder title="Activities" />
                )}
                {data.map((item) => (
                  <TransactionItem
                    key={item?.postingId || item?.claimId}
                    category={item.category}
                    type={item?.transactionType}
                    title={item.title}
                    amount={item.amount}
                    status={item.status}
                    date={item.date}
                    onPick={() => currentOnPick(item?.claimId, item?.postingId)}
                  />
                ))}
                {isDataInclude && (
                  <PaginationBarWithMediaQuery
                    page={page}
                    total={total}
                    pageSize={perPage}
                    onChangePage={setPage}
                    onChangePageSize={setPerPage}
                  />
                )}
              </Box>
            )}
          />
        </FlexListWrapper>
      </Box>
    </Box>
  );
};

export default ActivitiesList;
