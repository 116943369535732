 
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Text } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DATE_TOOLTIP_FORMAT } from '@/common/constants';
import { TransactionData } from '@/modules/transaction/transaction.types';
import { useAuthStore } from '@/modules/user/stores';

import useClaimActivitiesMessages from './message/useClaimActivitiesMessages';

const ShowMoreBox = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const MIN_COUNT_ACTIVITIES = 3;
const SHOW_MORE_BOX_BACKGROUND = 'linear-gradient(0deg, #F7F9FA 30.54%, rgba(247, 249, 250, 0) 100%)';

interface Props {
  data: TransactionData;
  uploadDocumentButton?: React.ReactNode;
}

const TransactionActivities: React.FC<Props> = ({
  data,
  uploadDocumentButton,
}) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const { user } = useAuthStore();

  const { dates, messagesSortedByDate, allActivitiesCount } = useClaimActivitiesMessages({
    data,
    employeeFirstName: user?.firstName,
    uploadDocumentButton,
  });

  if (!dates.length || !allActivitiesCount || !user?.firstName) return null;

  return (
    <Box
      pad="spacing24"
      background="module"
      round="moduleRound"
      overflow="hidden"
      style={{ position: 'relative' }}
      margin={{ top: 'spacing24' }}
      data-testid="transaction-activities-wrapper"
    >
      <Box height={{ min: 'fit-content' }}>
        <Text size="16px" weight={700} data-testid="transaction-activities-title">{t('Activities')}</Text>
        {dates.map((date) => {
          const messages = messagesSortedByDate.find((item) => item.date === date)?.messages || [];
          return (
            <Box key={date}>
              <Box
                margin={{ top: 'spacing24', bottom: 'spacing16' }}
                direction="row"
                align="center"
              >
                <Box margin={{ right: 'spacing12' }} data-testid="transaction-activities-date">
                  <Text size="14px" style={{ whiteSpace: 'nowrap' }}>{dayjs(date).format(DATE_TOOLTIP_FORMAT)}</Text>
                </Box>
                <Box height="1px" width="90%" background={{ color: 'border1' }} />
              </Box>

              {messages.slice(0, expand ? undefined : MIN_COUNT_ACTIVITIES).map((item) => item.message)}
            </Box>
          );
        })}
      </Box>
      {allActivitiesCount > MIN_COUNT_ACTIVITIES && !expand
      && (
        <ShowMoreBox
          background={SHOW_MORE_BOX_BACKGROUND}
          height={{ min: '156px' }}
          align="center"
          justify="end"
        >
          <Box margin={{ bottom: '40px' }}>
            <AppButton
              onClick={() => setExpand(!expand)}
            >
              {`Show more (${allActivitiesCount - MIN_COUNT_ACTIVITIES})`}
            </AppButton>
          </Box>
        </ShowMoreBox>
      )}
    </Box>
  );
};

export default TransactionActivities;
