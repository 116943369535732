import React, { useMemo } from 'react';
import { ActivityType,Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import ActivityStatus from '@/modules/transaction/components/ActivityStatus';
import { TransactionStatus } from '@/modules/transaction/transaction.types';
import costFormated from '@/utils/modifiers/costFormater';

import TransactionTitle from './TransactionTitle';

const CLAIM_TRANSFER_TYPE = 'CLAIM_TRANSFER';
const CLAIM_REPAYMENT = 'CLAIM_REPAYMENT';


const StyledRow = styled(Box)`
  &:first-child {
    margin-top: 8px
  }
  &:not(:last-child) {
    margin-bottom:8px 
  }
`;

interface Props {
  category: string;
  type?: string;
  title: string;
  amount: number;
  status: string;
  date: string;
  onPick?: () => void;
}
const Transactionitem: React.FC<Props> = ({
  category, title, amount, onPick, date, status, type,
}) => {
  const isDeclined = useMemo(() => status === TransactionStatus.Declined, [status]);
  return (
    <StyledRow>
      <Box
        direction="row"
        data-testid="transaction-list-item"
        align="center"
        height={{ min: 'smallItemHeight' }}
        background="canvas"
        elevation="default"
        round="container1Round"
        pad={{ horizontal: 'spacing16' }}
        onClick={onPick}
      >
        <Box style={{ flex: 5.3 }}>
          <TransactionTitle
            category={type?.startsWith(CLAIM_TRANSFER_TYPE) || type?.startsWith(CLAIM_REPAYMENT)
              ? ActivityType.CLAIM_TRANSFER_INTERNAL
              : category}
          >
            {title}
          </TransactionTitle>
        </Box>

        <Box style={{ flex: 3, minWidth: '170px' }}>
          {
            status === TransactionStatus.Pending
            && <ActivityStatus status={status as TransactionStatus} />
          }
        </Box>

        <Box style={{ flex: 2 }}>
          <Text
            size="large"
            style={{ textDecoration: isDeclined ? 'line-through' : undefined }}
            color={isDeclined ? 'textSecondary' : undefined}
          >
            {costFormated(amount, true)}
          </Text>
        </Box>

        <Box style={{ flex: 0.7 }} align="end">
          <Text size="large" color="textSecondary">
            {date}
          </Text>
        </Box>

        <Box style={{ flex: 0.5 }} /> {/* empty box for spacing */}
      </Box>
    </StyledRow>
  );
};

export default Transactionitem;
