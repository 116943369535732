export enum Steps {
  enterCardDigits = 'enterCardDigits',
  successActivated = 'successActivated',
  verificationError = 'verificationError',
  limitReachedError = 'limitReachedError',
}

export enum CardActivationVariants {
  ACTIVATED = 'ACTIVATED',
  ERROR_ACTIVATION = 'ERROR_ACTIVATION',
  ACTIVATION_NOT_INIT = 'ACTIVATION_NOT_INIT',
}

export const VERIFICATION_LIMIT_KEY = 'verificationRequestsLimit';
export const ATTEMPTS_LIMIT_TIMER_KEY = 'attemptsLimitTimer';
export const ATTEMPTS_LIMIT_KEY = 'attemptsLimit';
export const ATTEMPTS_LIMIT = 5;
