import React, { useEffect,useRef } from 'react';
import { Box } from '@common-fe/common-fe';
import Conffeti from 'canvas-confetti';

const CONFETTI_COUNT = 200;
const SPREAD = 160;

const DELAY = 1500;
interface Props {
  times?: number;
  delay?: number;
  options?: Conffeti.Options;
}
const Confetti: React.FC<Props> = ({ options,times, delay}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvasRef.current) {
      const confetti = Conffeti.create(canvasRef.current, {
        resize: true,
        useWorker: true,
      });
      let maxTimes = times || 5;
      const intervall = setInterval(() => {

        maxTimes -= 1;
        if (maxTimes <= 0) {
          clearInterval(intervall);
        }
 
        confetti({
          particleCount: CONFETTI_COUNT,
          spread: SPREAD,
          origin: {
            y: 1,
          },
          ...options,
        });
      }, delay || DELAY);
    }
  }, [delay, options, times]);

  return (
    <Box fill>
      <canvas data-testid="confetti" ref={canvasRef} />
    </Box>
  );
};

export default React.memo(Confetti);
