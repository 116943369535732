import React, { useCallback } from 'react';
import { Box, Text, Tip } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useDetectTextIsEllipsis } from '@/utils/hooks/useDetectTextIsEllipsis';

import { FundManagementOption } from '../../../FundsManagementBanner/hooks/useFundManagementOptions';

interface StyledBoxProps {
  isDisabled?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  transition: all 0.3s ease;
  &:hover {
    cursor: ${({ isDisabled }) => isDisabled && 'not-allowed'};
    background-color: ${({ theme }) => theme.colors.accentContainer};
  }
`;

interface Props {
  fundManagementButton: Partial<FundManagementOption>;
  additionalAction?: () => void;
}

export const FundsManagementButton: React.FC<Props> = ({
  fundManagementButton,
  additionalAction,
}) => {
  const {
    title,
    description,
    onClick,
    externalButtonIcon,
    isButtonDisabled,
  } = fundManagementButton;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (additionalAction) {
      additionalAction();
    }
  }, [onClick, additionalAction]);

  const descRef = React.useRef<HTMLSpanElement>(null);

  const isDescriptionEllipse = useDetectTextIsEllipsis(descRef);
  return (
    <StyledBox
      data-testid="funds-management-button"
      height={{ min: '46px' }}
      width={{ max: '288px' }}
      round="dropdownItemRound"
      pad={{ horizontal: 'spacing8', vertical: 'spacing4' }}
      onClick={isButtonDisabled ? undefined : handleClick}
      margin={{ bottom: 'spacing4' }}
      isDisabled={isButtonDisabled}
    >
      <Box direction="row" align="center">
        <Box
          data-testid="funds-management-button_icon"
          margin={{ right: 'spacing8' }}
          width={{ min: 'min-content' }}
          style={{
            opacity: isButtonDisabled ? 0.2 : 1,
          }}
        >
          {externalButtonIcon}
        </Box>

        <Box>
          <Text
            size="12px"
            weight={700}
            style={{ lineHeight: '18px' }}
            margin={{ bottom: 'spacing2' }}
            data-testid="funds-management-button_title"
          >
            {title}
          </Text>
          <Tip
            plain
            dropProps={{ align: { bottom: 'top' } }}
            content={(isDescriptionEllipse
              ? (
                <Box
                  width="max-content"
                  height={{ max: 'l' }}
                  align="center"
                  justify="center"
                  background="background3"
                  border={{ size: 'small', color: 'popup' }}
                  pad={{ horizontal: 'spacing6' }}
                >
                  <Text size="small" color="textOnColor">{description}</Text>
                </Box>
              ) : undefined
            )}
          >
            <Text
              data-testid="funds-management-button_description"
              ref={descRef}
              color="textSecondary"
              size="12px"
              weight={500}
              style={{
                lineHeight: '18px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {description}
            </Text>
          </Tip>
        </Box>
      </Box>
    </StyledBox>
  );
};
