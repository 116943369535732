import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, costFormater,
  OptionsButton, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useInvestor } from '../hooks';

import { AutoInvestmentType } from './AutoInvestment.types';
import AutoInvestmentStandaloneBanner from './AutoInvestmentStandaloneBanner';
import { useAutoInvestmentQuery } from './queries';
import { SetupAutoInvestmentsModal } from './SetupAutoInvestments';
import { PauseAutoInvestmentsModal } from './SetupAutoInvestments';
import { DeactivateAutoInvestmentsModal } from './SetupAutoInvestments';
import { useSetupAutoInvestmentsModalStore } from './stores';

interface StyledButtonProps {
  isWarning?: boolean;
}

const StyledButton = styled(Box)<StyledButtonProps>`
  min-height: 32px;
  justify-content: center;
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  ${({ theme }) => theme.fonts.text14Regular};
  padding-inline: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xxxs};
  color: ${({ theme, isWarning }) => (isWarning ? `${theme.colors.danger} !important` : '')};
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: ${({ theme, isWarning }) => (isWarning ? theme.colors.danger : theme.colors.textAccent)};
    background-color: ${({ theme, isWarning }) => (isWarning ? theme.colors.statusContainerColor.deniedErrorExpiredContainer : theme.colors.accentContainer)};
    transition: all 0.2s ease-in-out;
  }
`;

const Wrapper = styled(Box)`
  svg {
    fill: ${({ theme }) => theme.colors.button}
  }
`;
interface Props {
  accountId?: string;
}
const AutoInvestment: React.FC<Props> = ({ accountId }) => {
  // isAutoInvestments
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showPause, setShowPause] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [isOpenOptions, setOpenOptions] = useState(false);
  const [isReactivateMode, setReactivateMode] = useState(false);
  const { t } = useTranslation();
  const setModalVisible = useSetupAutoInvestmentsModalStore((state) => state.setVisible);
  const defaultModalVisible = useSetupAutoInvestmentsModalStore((state) => state.visible);

  useEffect(() => {
    if (defaultModalVisible) {
      setShowAdd(true);
    }
  }, [defaultModalVisible]);
  useEffect(() => {
    if (!showAdd) {
      setModalVisible(false);
    }
  }, [setModalVisible, showAdd]);
  const investor = useInvestor(accountId);

  const { data, isError } = useAutoInvestmentQuery(investor.data.investorAccountId);
  const handlePauseReactivate = useCallback(() => {
    setOpenOptions(false);
    if (data.status === AutoInvestmentType.Paused) {
      setShowEdit(true);
      setReactivateMode(true);
    } else {
      setShowPause(true);
    }
  }, [data.status]);


  const handleModify = useCallback(() => {
    setShowEdit(true);
    setOpenOptions(false);
  }, []);
  const handleDelete = useCallback(() => {
    setShowDeactivate(true);
    setOpenOptions(false);
  }, []);

  const status = useMemo(() => {
    if (data.status === AutoInvestmentType.Active) {
      return (
        <>
          <Box background="success" width="10px" height="10px" round="50%" />
          <Text margin={{ left: '6px' }} weight={400}>{t('Activated')}</Text>
        </>
      );
    }
    if (data.status === AutoInvestmentType.Paused) {
      return (
        <>
          <Box background="warning" width="10px" height="10px" round="50%" />
          <Text margin={{ left: '6px' }} weight={400}>{t('Paused')}</Text>
        </>
      );
    }
    return (
      <>
        <Box background="success" width="10px" height="10px" round="50%" />
        <Text margin={{ left: '6px' }} weight={400}>{t('Activated')}</Text>
      </>
    );
  }, [data, t]);

  const content = useMemo(() => {
    if (!data.id || isError) {
     
      return (
        <AutoInvestmentStandaloneBanner accountId={accountId} />
      );
    }
    return (
      <Box
        background="module"
        pad="l"
        direction="row"
        round="moduleRound"
        margin={{ bottom: 'l' }}
      >
        <Box
          fill
          direction="row"
          align="center"
        >
          <Text size="large" style={{lineHeight: 1}} weight={700}>
            {t('Automatic Investments')}
          </Text>
        </Box>

        <Box direction="row" fill justify="end">
          <Box direction="column" justify='center' align="end">
            <Box direction="row" align="center">
              {status}
            </Box>
            <Text color="textDisabled">
              {t('Invest funds over: ')}{costFormater(data.amountToKeep || 0, true)}
            </Text>
          </Box>
          <Wrapper margin={{ left: 'm' }}>
            <OptionsButton
              isOpen={isOpenOptions}
              onOpen={() => setOpenOptions(true)}
              onClose={() => setOpenOptions(false)}
              dropAlign={{ top: 'bottom' }}
            >
              <Box direction="column" width={{ min: '180px' }}>
                <StyledButton onClick={handleModify} >
                  <Box pad={{ vertical: 'xxs', horizontal: 'xs' }}>
                    <Text weight={400}>
                      {t('Modify')}
                    </Text>
                  </Box>
                </StyledButton>
                <StyledButton
                  onClick={handlePauseReactivate}
                >
                  <Box pad={{ vertical: 'xxs', horizontal: 'xs' }}>
                    <Text weight={400}>
                      {data.status === AutoInvestmentType.Paused ? t('Reactivate') : t('Pause')}
                    </Text>
                  </Box>
                </StyledButton>
                <StyledButton onClick={handleDelete} isWarning>
                  <Box pad={{ vertical: 'xxs', horizontal: 'xs' }}>
                    <Text color="danger" weight={400}>
                      {t('Deactivate')}
                    </Text>
                  </Box>
                </StyledButton>

              </Box>
            </OptionsButton>
          </Wrapper>
        </Box>
      </Box>
    );
  }, [data.id, data.amountToKeep, data.status, isError, t, status, isOpenOptions, handleModify, handlePauseReactivate, handleDelete, accountId]);
  return (
    <>
      <SetupAutoInvestmentsModal
        visible={showAdd}
        onSetVisible={setShowAdd}
        accountId={accountId}
        defaultPercentValues={{}}
      />
      <SetupAutoInvestmentsModal
        visible={showEdit}
        isEditMode
        investmentId={data.id}
        defaultValue={`${data.amountToKeep || ''}`}
        defaultPercentValues={data?.autoInvestmentRules}
        onSetVisible={setShowEdit}
        accountId={accountId}
        isReactivateMode={isReactivateMode}
        setReactivateMode={setReactivateMode}
      />
      <PauseAutoInvestmentsModal
        visible={showPause}
        isEditMode
        investmentId={data.id}
        defaultValue={`${data.amountToKeep || ''}`}
        defaultPercentValues={data?.autoInvestmentRules}
        onSetVisible={setShowPause}
        accountId={accountId}
      />
      <DeactivateAutoInvestmentsModal
        visible={showDeactivate}
        investmentId={data.id}
        onSetVisible={setShowDeactivate}
      />
      {content}
    </>

  );
};
export default AutoInvestment;
