import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

import theme from '@/styles/theme';

interface Props {
  title: string;
  description: string;
  icon: React.ReactNode;
  testId?: string;
  children?: React.ReactNode;
  isWideMode?: boolean;
}

const Banner: React.FC<Props> = ({
  title,
  description,
  icon,
  testId,
  children,
  isWideMode,
}) => (
  <Box
    width={isWideMode ? undefined : '984px'}
    pad={{ left: 'l', right: 'xxxl', vertical: 's' }}
    direction="row"
    round="container1Round"
    background={{ color: 'canvas' }}
    border={{ color: 'border2', size: '1px' }}
    style={{ boxShadow: theme.shadows.default['box-shadow'] }}
    align="center"
    data-testId={`${testId}_banner`}
  >
    <Box width={{ max: isWideMode ? undefined : '665px', width: '100%' }}>
      <Box>
        <Text weight="bold" size="24px">
          {title}
        </Text>
      </Box>

      <Box margin={{ top: 'spacing8' }}>
        <Text size="14px">
          {description}
        </Text>
      </Box>

      {children}
    </Box>

    <Box
      margin={{ left: 'spacing24' }}
      justify="center"
      align="center"
      width={{ width: '200px', min: '200px' }}
      height={{ height: '200px', min: '200px' }}
    >
      {icon}
    </Box>
  </Box>
);

export default Banner;
