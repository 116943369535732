import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Inscription } from '@common-fe/common-fe';

import AddDependentModal from '@/modules/user/components/ManageProfile/components/Dependents/components/DependentsModal/AddDependentModal';

const DependentsHeader = () => {
  const { t } = useTranslation();
  const [isAddDependentModalVisible, setIsAddDependentModal] = useState(false);

  const handleOpenAddDependentModal = useCallback(() => {
    setIsAddDependentModal(true);
  }, []);

  return (
    <>
      <Box direction="row" justify="between" align="center">
        <Inscription
          size="large"
          weight={700}
        >
          {t('Dependents')}
        </Inscription>
        <AppButton
          buttonType="secondary"
          onClick={handleOpenAddDependentModal}
        >
          {t('Add Dependent')}
        </AppButton>
      </Box>
      {isAddDependentModalVisible && (
        <AddDependentModal onSetVisible={setIsAddDependentModal} />
      )}
    </>
  );
};

export default DependentsHeader;
