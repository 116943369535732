import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MINUTE, SECOND } from '@/common/constants';
import { ChangeVerificationMethodModal, SendCodeModal } from '@/modules/user/components/SignIn/MultiFactorAuthentication/components';
import { MFAError, MFAStep } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';
import useSendMFACodeQuery from '@/modules/user/components/SignIn/MultiFactorAuthentication/queries/useSendMFACode.query';
import useMFAStore from '@/modules/user/components/SignIn/MultiFactorAuthentication/stores/useMFA.store';

const ONE_MINUTE_IN_SECONDS = MINUTE / SECOND;

interface Props {
  onSetVisible: (value: boolean) => void;
  onShowAccountLockedModal: (value: number) => void;
}

const MultiFactorAuthenticationModals: React.FC<Props> = ({ onSetVisible, onShowAccountLockedModal }) => {
  const [step, setStep] = useState(MFAStep.SEND_CODE);
  const { activeChannel, session, resetStore } = useMFAStore();
  const { sendMFACode } = useSendMFACodeQuery(); 
  const resendTimeout = useRef(ONE_MINUTE_IN_SECONDS);
  const [sendCodeErrorText, setSendCodeErrorText] = useState('');

  const handleSendMFACode = useCallback(async () => {
    try {
      setSendCodeErrorText('');
      if (activeChannel && session) {
        await sendMFACode({
          channel: activeChannel,
          session: session,
        });
        resendTimeout.current = ONE_MINUTE_IN_SECONDS;
      }
    } catch (error) {
      // @ts-ignore
      if (error?.response?.data?.elevate_error_message?.includes('mfa session is incorrect')) {
        setSendCodeErrorText(MFAError.SESSION_HAS_EXPIRED);
      }
    }
  }, [activeChannel, session, sendMFACode]);

  useEffect(() => {
    const timer = setInterval(() => {
      resendTimeout.current = resendTimeout.current - 1;
    }, SECOND);
 
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    handleSendMFACode();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      resetStore();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {step === MFAStep.SEND_CODE && (
        <SendCodeModal
          onSetVisible={onSetVisible}
          setStep={setStep}
          onResendCode={handleSendMFACode}
          resendTimeout={resendTimeout.current}
          sendCodeErrorText={sendCodeErrorText}
          onShowAccountLockedModal={onShowAccountLockedModal}
        />
      )}
      {step === MFAStep.CHANGE_VERIFICATION_METHOD && (
        <ChangeVerificationMethodModal
          onSetVisible={onSetVisible}
          setStep={setStep}
        />
      )}
    </>
  );
};

export default MultiFactorAuthenticationModals;
