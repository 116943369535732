import { ParsedFileByOcr } from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  modalVisible: boolean;
  reUploadedOcrFile?: ParsedFileByOcr;
  isScanningCanceled: boolean;
  handleSetReUploadedOcrFile: (value: ParsedFileByOcr) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleSetIsScanningCanceled: (value: boolean) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  reUploadedOcrFile: undefined,
  isScanningCanceled: false,

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleSetReUploadedOcrFile: (value) => set((state) => ({
    ...state,
    reUploadedOcrFile: value,
  })),

  handleSetIsScanningCanceled: (value) => set((state) => ({
    ...state,
    isScanningCanceled: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    reUploadedOcrFile: undefined,
    isScanningCanceled: false,
  })),
}));
