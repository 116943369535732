import React from 'react';
import { Text } from '@common-fe/common-fe';

import StatusIcon from '@/components/elements/StatusIcon';
import { DeclineReasonType, DeclineReasonTypes,TransactionStatus } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import { IconWrapper, Title,   Wrapper } from '../../TransactionNotification.styles';

import useGetDeclineReasonMessage from './useGetDeclineReasonMessage';

const ALLOWED_TYPES = [
  DeclineReasonTypes.NOT_ACTIVATED,
  DeclineReasonTypes.CARD_LOCKED,
  DeclineReasonTypes.CARD_LOST,
  DeclineReasonTypes.PIN_REQUIRED,
  DeclineReasonTypes.PIN_INVALID,
  DeclineReasonTypes.CVS_NOT_ALLOWED,
  DeclineReasonTypes.NOT_HEALTHCARE_SERVICE,
  DeclineReasonTypes.NOT_ALLOWED_SERVICE_FOR_PLAN,
  DeclineReasonTypes.PRIOR_TO_PLAN_START_DATE,
  DeclineReasonTypes.PAST_PLAN_END_DATE,
  DeclineReasonTypes.NO_AVAILABLE_BALANCE,
  DeclineReasonTypes.NOT_ENOUGH_BALANCE,
  DeclineReasonTypes.ORG_LOCKED,
  DeclineReasonTypes.OVERLIMIT,
  DeclineReasonTypes.GENERIC,
  DeclineReasonTypes.PEND_CONTRIBUTIONS,
  DeclineReasonTypes.PEND_CONTRIBUTIONS_AND_CIP,
];

export const hasDeclineReasonMessage = (data?: DeclineReasonType) => data?.type && ALLOWED_TYPES.includes(data.type);

interface Props {
  data?: DeclineReasonType;
  serviceDate?: string;
}

const DeclineReasonMessage: React.FC<Props> = ({ data, serviceDate }) => {
  const { mainText, text, cardLink } = useGetDeclineReasonMessage({ data, serviceDate });

  if (!hasDeclineReasonMessage(data)) return null;

  return (
    <Wrapper
      error
      data-testid={`decline_reason_type_${data?.type}`}
      flex-direction="column"
      background="canvas"
      border={{
        size: 'small',
        color: 'dangerBorder',
      }}
      elevation="default"
      pad={{
        top: 'l',
        right: 'l',
        bottom: 'l',
        left: '56px',
      }}
    >
      <IconWrapper><StatusIcon status={TransactionStatus.Denied} /></IconWrapper>
      <Title data-testid="main-text">{mainText}</Title>
      {text && typeof text === 'string' && (
        <Text size={theme.spacings.m} margin={{ top: 'xs' }}>
          {text}
          {cardLink || null}
        </Text>
      )}
      {cardLink && !text && (
        <Text size={theme.spacings.m} margin={{ top: 'xs' }}>
          {cardLink || null}
        </Text>
      )}
      {typeof text === 'object' ? text : null}
    </Wrapper>
  );
};

export default DeclineReasonMessage;
