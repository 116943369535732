import React, { useEffect,useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, ListItemBase, Preloader } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import AppButton from '@/components/controls/AppButton';
import {
  Transaction,
} from '@/modules/transaction/transaction.types';

import { useTransactionsList } from '../../hooks';
import RecentActivity from '../RecentActivity';
import { EmptyTransactionsListPlaceholder } from '../TransactionsList/EmptyTransactionsListPlaceholder';
import TransactionsListHeader from '../TransactionsListHeader/TransactionsListHeader';

import {
  Header,
  HeaderWrapper,
  Wrapper,
} from './RecentActivities.styles';

const BACKLIGHT_DELAY = 2000;
const QUERY_KEY = 'justAddedClaimId';

const headers: ListItemBase[] = [
  {
    key: 'provider',
    title: 'Provider',
    flex: 1.5,
  },
  {
    key: 'requestedAmount',
    title: 'Requested Amount',
    flex: 1,
  },
  {
    key: 'paidAmount',
    title: 'Paid Amount',
    flex: 0.8,
  },
  {
    key: 'status',
    title: 'Status',
    flex: 1.2,
  },
  {
    key: 'statusDate',
    title: 'Status Date',
  },
];

const RecentActivities = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const justAddedClaimId = useMemo(() => new URLSearchParams(search).get(QUERY_KEY), [search]);
  const {
    data,
    total,
    isSuccess,
    isLoading,
  } = useTransactionsList({
    size: 5,
  });
  const handleOpenList = () => {
    history.push(ROUTES.EXPENSE_DETAILS);
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (justAddedClaimId && isSuccess) {
      timer = setTimeout(() => push(pathname), BACKLIGHT_DELAY);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [pathname, justAddedClaimId, isSuccess, push]);

  return (
    <Wrapper data-testid="RecentActivities-wrapper">
      <HeaderWrapper>
        <Header>{t('Expenses')}</Header>
      </HeaderWrapper>
      {isLoading && <Preloader />}
      {!isLoading && !data.length ? (
        <EmptyTransactionsListPlaceholder
          title="Expenses"
          boxStyle={{ paddingTop: 0 }}
          isDescriptionHidden
        />
      ) : null}
      { data?.length > 0
      && (
        <>
          <TransactionsListHeader items={headers} />
          {data?.slice(0, 5)?.map((item: Transaction) => (
            <RecentActivity
              key={item.claimId}
              data={item}
              hasBacklight={item?.claimId === justAddedClaimId}
              onPick={() => push(ROUTES.EXPENSES_TRANSACTION_DETAILS_CLAIM(item?.claimId))}
            />
          ))}
          {total > 5
          && (
            <Box width="control" justify="center" margin={{ top: 'spacing16', horizontal: 'auto' }}>
              <AppButton
                onClick={handleOpenList}
                type="button"
                width="control"
                testId="RecentActivityButtonShowMore-id"
              >
                {t('View All')}
              </AppButton>
            </Box>
          )}
        </>
      )}
    </Wrapper>
  );
};
export default RecentActivities;
