import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Anchor,
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  costFormater,
  Hint,
  Image,
  NewTabIcon,
  NullIcon,
  PriceInput,
  RawButton,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { useGetFundIcon } from '@/modules/investments/hooks';

import useInstrumentCategoriesQuery from '../../queries/useInstrumentCategories.query';
import { FundsPayload } from '../../SelfDirected.types';

import { PERCENTS_FULL_LIQUIDATION, PERCENTS_LIQUIDATION_LIMIT } from './Funds.constants';
import LiquidationCheckbox from './LiquidationCheckbox';
const Wrapper = styled(Box)`
  ${({ theme }) => theme.shadows.default};
  transition: all 0.25s;
  &:hover {
    ${({ theme }) => theme.shadows.hover};
  }
  .liquidation-input {
    font-weight: 700;
  }
`;

interface Props extends FundsPayload {
  isPendingLiquidation?: boolean;
  isLiquidationAllMode?: boolean;
  value: number;
  onChangeValue: (value: number) => void;

  isLiquidation?: boolean;
  onSetLiquidation: (value: boolean) => void;
}


const FundsItem: React.FC<Props> = ({
  logo,
  title,
  stockCode,
  status,
  link,
  election,
  amount,
  category,
  assetClass,
  isLiquidationAllMode,
  isLiquidation = false,
  onSetLiquidation,
  isPendingLiquidation,
  value,
  onChangeValue
}) => {
  const [liquidationAmount, setLiquidationAmount] = useState('');
  const { t} = useTranslation();
  const { dataMap } = useInstrumentCategoriesQuery();
  const currentCategory = useMemo(() => _.get(dataMap, `${[category]}`, '') as string, [category, dataMap]);
  
  const [expanded, setExpanded] = useState(false);
  const handleToggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const amountNumber = useMemo(() => {
    const newValueNumber  =  _.toNumber(amount);
    if(_.isNaN(newValueNumber)) {
      return 0;
    }
    return newValueNumber;
  }, [amount]);
  
  const liquidationNumber = useMemo(() => {
    const currentValue = _.toNumber(liquidationAmount); 
    if(_.isNaN(currentValue)) {
      return 0;
    }
    return currentValue;
  }, [liquidationAmount]);

  useEffect(() => {
    if(value !== liquidationNumber) {
      onChangeValue(liquidationNumber);
    }
    
  }, [liquidationNumber, onChangeValue, value]);

  useEffect(() => {
    const currentAmount = parseFloat(liquidationAmount);
    if(_.isNaN(currentAmount)) {
      return;
    }
    if (amount === currentAmount) {
      onSetLiquidation(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liquidationAmount]);

  useEffect(() => {
    if (isLiquidationAllMode) {
      setLiquidationAmount(`${amountNumber}`);
    } else {
      setLiquidationAmount('');
    }

    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiquidationAllMode]);

  const handleChangeValue = useCallback((newValue: string) => {
    const newValueNumber = _.toNumber(newValue);

    if(newValueNumber > amountNumber) {
      setLiquidationAmount(`${amountNumber}`);
      return;
    }
    setLiquidationAmount(newValue);
  },[amountNumber]);

  const isLiquidationApplied = useMemo(() => {
    
    const percents = liquidationNumber / amountNumber;
    if(amountNumber === 0) {
      return true;
    }
    if(_.isNaN(percents)) {
      return true;
    }
    return percents >= PERCENTS_LIQUIDATION_LIMIT && percents !== PERCENTS_FULL_LIQUIDATION;
    

  }, [amountNumber, liquidationNumber]);

  const handleToggleCheck = useCallback((value: boolean) => {
    onSetLiquidation(value);
    if (value) {
      setLiquidationAmount(`${amount}`);
      return;
    }
    setLiquidationAmount('');
  }, [amount, onSetLiquidation]);
  const handleGetFundIcon = useGetFundIcon();
  
  return (
    <Wrapper
      data-testid="fund-item"
      direction="column"
      pad={{ vertical: '17px', horizontal: 'l' }}
      background="canvas"
      round="container2Round"
      border={{ size: 'small', color: 'border1' }}
      margin={{ top: 'xs' }}
    >
      <Box direction="row" align="center" justify='start'>
        <RawButton onClick={handleToggleExpanded}>
          <Box width="24px" height="24px">
            {expanded ? <ArrowUpIcon color="iconPrimary" /> : <ArrowDownIcon color="iconPrimary" />}
          </Box>
        </RawButton>

        
        <Box margin={{ left: 's' }} style={{opacity: isPendingLiquidation ? 0.2: 1}}>
          { handleGetFundIcon({
            image: logo,
            status: status,
            isGreyMode: false,
            isNullMode: true
          })}
        </Box>
        <Box
          margin={{ left: 's' }}
          style={{
            flex: 2,
            position: 'relative',
          }}
          direction="column"
        >
          <Box width="100%">
            <Text tip={title} color={isPendingLiquidation ?  'textDisabled' :'textBody'}  truncate weight={700}>
              {title}
            </Text>
            <Text color={isPendingLiquidation ?  'textDisabled' :'textBody'}>{stockCode}</Text>
          </Box>
        </Box>
        <Box
          margin={{ left: 's' }}
          style={{
            width: '24px',
          }}
          direction="column"
        >
          {link ? (
            <Anchor target="_blank" href={link}>
              <Box align="center">
                <NewTabIcon color="iconSecondary" size="26px" />
              </Box>
            </Anchor>
          ) : (
            <Box width="26px" />
          )}
        </Box>
        {/* <Box width="60px" margin={{ left: 'l' }}>
          <FundPercents value={yr3} />
        </Box>
        <Box width="60px" margin={{ left: 'l' }}>
          <FundPercents value={yr5} />
        </Box> */}
        <Box width="102px" margin={{ left: 'l' }}>
          <Text color={isPendingLiquidation ?  'textDisabled' :'textBody'}>{`${election}%`}</Text>
        </Box>
        {/* <Box width={{min: '150px'}} margin={{ left: 'l' }}>
          <Text>{assetClass || '-'}</Text>
        </Box> */}
        <Box width={{ min: '110px' }} margin={{ left: 'l' }}>
          <Text weight={700} color={isPendingLiquidation ?  'textDisabled' :'textBody'} >{costFormater(amount, { isPrecision: true})}</Text>
        </Box>
        <Box width={{ min: '150px', max: '150px' }} margin={{ left: 'l' }}>
          <PriceInput
            className="liquidation-input"
            name="price"
            disabled={
              isLiquidationAllMode || isLiquidation || isPendingLiquidation
            }        

            value={
              isPendingLiquidation ? '' :
                isLiquidationAllMode || isLiquidation ?
                  `${amount}` 
                  :liquidationAmount
            }
            onChange={handleChangeValue}
            placeholder='$0.00' />
        </Box>

        <Box direction='row'
          align='center'
          justify='end' 
          margin={{ left: 'xs' }}
          style={{flex: 1.2}}>
      
          {isPendingLiquidation  ? (
            <Hint 
              hintAlign='left'
              hintElement={(
                <LiquidationCheckbox
                  value={false}
                  onChange={() => {}}
                  isLiquidationMode={isLiquidationApplied}
                  disabled
                />
              )}>
              <Box width="220px">
                <Text size="small">
                  {t('This position is being liquidated right now. You can\'t sell this item.')}
                </Text>
              </Box>
            </Hint>
          ) : (
            <LiquidationCheckbox
              value={isLiquidation}
              onChange={handleToggleCheck}
              isLiquidationMode={isLiquidationApplied}
              disabled={isLiquidationAllMode}
            />
          )}
         
        </Box>
      </Box>

      {expanded && (
        <>
          <Box height="1px" background="border1" margin={{ top: 'l', bottom: 'l' }}></Box>
          <Box direction="row" align="center">
            {/* <Box direction='column' width="60px" margin={{right: 'l'}}>
              <Text size="small"
                weight={700}
                margin={{bottom: 'xxxs'}}>3 YR
              </Text>
              <Box pad={{vertical: 'xxxs'}}>
                <FundsPercents value={99.99} />
              </Box>
            </Box>
            <Box direction='column' width="60px" margin={{right: 'l'}}>
              <Text size="small"
                weight={700}
                margin={{bottom: 'xxxs'}}>5 YR
              </Text>
              <Box pad={{vertical: 'xxxs'}}>
                <FundsPercents value={99.99} />
              </Box>
            </Box> */}
            {currentCategory && (
              <Box direction="column" width="150px" margin={{ right: 'l' }}>
                <Text size="small" weight={700} color="textBody" margin={{ bottom: 'xxxs' }}>
                  Category
                </Text>
                <Box pad={{ vertical: 'xxxs' }}>
                  <Text color="textBody" truncate size="medium">
                    {currentCategory}
                  </Text>
                </Box>
              </Box>
            )}
          
            <Box direction="column" width="150px" margin={{ right: 'l' }}>
              <Text size="small" weight={700} color="textBody" margin={{ bottom: 'xxxs' }}>
                {t('Asset class')}
              </Text>
              <Box pad={{ vertical: 'xxxs' }}>
                <Text color="textBody" size="medium">
                  {assetClass}
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(FundsItem);
