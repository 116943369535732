import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { QUERY_KEY } from '@/modules/user/components/ManageProfile/components/Dependents/queries/useDependents.query';

const useRemoveDependentQuery = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (id: string) => api.delete(PATHS.REMOVE_DEPENDENT(id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
      },
    }
  );
  return {
    removeDependent: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useRemoveDependentQuery;
