import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

interface StyledProps {
  secondary?: boolean;
}

// temporary color
const activeSecondary = '#CFD1D3';
const BUTTON_CONTENT_HEIGHT = '38px';

const StyledLabel = styled.label<StyledProps>`
  background-color: ${({ theme, secondary }) => (secondary ? theme.colors.buttonSecondaryContainer : theme.colors.button)};
  padding-block: ${({ theme }) => theme.spacings.spacing8};
  border-radius: ${({ theme }) => theme.rounds.container2Round};
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    background-color: ${({ theme, secondary }) => (secondary ? activeSecondary : theme.colors.textAccentHover)};
  }
  &:active {
    background-color: ${({ theme, secondary }) => (secondary ? activeSecondary : theme.colors.textAccentActive)};
  }
`;

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void;
  title?: string;
  id: string;
  testId?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  cameraInput?: boolean;
  secondary?: boolean;
  isRightIconPosition?: boolean;
}

export const FileGetAndUploadButton: React.FC<Props> = ({
  onChange,
  title,
  id,
  children,
  testId,
  cameraInput,
  secondary,
  icon,
  isRightIconPosition,
}) => (
  <StyledLabel
    htmlFor={`file-upload-for-${id}`}
    data-testid={`file-upload-for-${id}`}
    secondary={secondary}
  >
    <Box
      direction="row"
      justify="center"
      align="center"
      height={BUTTON_CONTENT_HEIGHT}
    >
      {!isRightIconPosition && icon}
      <Inscription
        size="14px"
        weight={700}
        color={secondary ? 'textBody' : 'canvas'}
        lineHeight="20px"
        margin={icon ? (isRightIconPosition ? { right: 'spacing8' } : {  left: 'spacing8' }) : {}}
      >
        {children}
        {title}
      </Inscription>
      {isRightIconPosition && icon}
    </Box>
    <input
      id={`file-upload-for-${id}`}
      type="file"
      accept="image/*"
      onChange={onChange}
      style={{ display: 'none' }}
      data-testid={`${testId}_upload-button`}
      {...(cameraInput && { capture: 'environment' })}
    />
  </StyledLabel>
);
