import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const CardItemWrapper = styled(Box)`
  min-width: 508px;
  min-height: 240px;
  // overflow: hidden;
  cursor: pointer;
  width: calc(33.333% - ${({ theme }) => theme.spacings.spacing16});
`;
