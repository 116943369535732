import React from 'react';
import { Box,Image } from '@common-fe/common-fe';
import styled from 'styled-components';

const AnimatedImage = styled(Image)`
  animation:spin 2s linear infinite;
  
  @keyframes spin {
    100% {
      transform:rotate(360deg);
    }
  }
`;
const AnimatedLogo = () => (
  <Box width="xxsmall" fill align="center" justify="center">
    <AnimatedImage width="small" height="small" src="/logo.svg" alt="logo" />
  </Box>
);

export default AnimatedLogo;
