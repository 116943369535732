import React, { useMemo } from 'react';
import { FlexList, ListItem, ListItemBase } from '@common-fe/common-fe';

import { FlexListWrapper } from '@/components/wrappers';

import { NotificationType } from '../notification.types';


const HEADERS: ListItemBase[] = [
  {
    key: 'message',
    title: 'Message',
    flex: 3,
  },
  {
    key: 'received',
    title: 'Received',
    flex: 1.5,
  },
  {
    key: 'actions',
    title: 'Actions',
    flex: 1,
  },
];
const DISMISSED_HEADERS: ListItemBase[] = [
  {
    key: 'message',
    title: 'Message',
    flex: 3,
  },
  {
    key: 'received',
    title: 'Received',
    flex: 1.5,
  },
];
interface Props {
  rows: ListItem[];
  children: React.ReactNode;
  currentType?: NotificationType;
  footer: React.ReactNode;
  perPage: number;
}

const NotificationsFlexList: React.FC<Props> = (
  {
    footer,
    rows,
    children,
    perPage,
    currentType,
  },
) => {
  const headers = useMemo(() => {
    if (currentType === NotificationType.Dismissed) {
      return DISMISSED_HEADERS;
    }
    return HEADERS;
  }, [currentType]);
  return (
    <FlexListWrapper>
      <FlexList
        testId="notifications_list"
        rows={rows}
        headers={headers}
        footer={footer}
        moreCount={perPage}
     
      >
        {children}
      </FlexList>
    </FlexListWrapper>
  );
};

export default NotificationsFlexList;
