import React from 'react';
import {
  AlertIcon,
  Box,
  Inscription,
} from '@common-fe/common-fe';

export const OutOfCoverageDateWarningBanner = () => (
  <Box
    data-testid="OutOfCoverageDateWarningBanner-wrapper"
    round="container1Round"
    width="100%"
  >

    <Box
      pad={{ horizontal: 'spacing12', bottom: 'spacing16' }}
      border={{ color: 'infoBorder' }}
      round="fieldRound"
      background={{ color: 'infoContainer' }}
    >
      <Box
        round="small"
        height="2px"
        background="info"
        margin={{ bottom: 'spacing16', top: '-1px' }}
      />
      <Box pad={{ horizontal: 'spacing12' }}>
        <Box
          direction="row"
          align="center"
        >
          <AlertIcon color="info" data-testid="OutOfCoverageDateWarningBanner-alert_icon" />
          <Inscription margin={{ left: 'spacing8' }} lineHeight="20px">
            This date of service is not covered.
          </Inscription>
        </Box>
      </Box>
    </Box>
  </Box>
);
