import { useEffect } from 'react';

import { useApplyFixedScrollStore } from '../stores';



export default () => {
  const handleApply = useApplyFixedScrollStore((state) => state.apply);
  const handleReset = useApplyFixedScrollStore((state) => state.reset);
  useEffect(() => {
    handleApply();
    return () => {
      handleReset();
    };
  }, [handleApply, handleReset]);
};
