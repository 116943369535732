import React from 'react';
import { Box, Image } from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';

import { Stepper } from '@/components/elements';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import {
  Content,
  Space, Title,   Wrapper, } from './StepperSidebar.styles';

export interface SidebarOption extends Option {
  active: boolean;
  focus: boolean;
}

interface Props {
  step: number;
  title?: string;
  options: Option[];
}

const StepperSidebar: React.FC<Props> = ({ step, title, options }) => (
  <Wrapper>
    <Content>
      <Box margin={{right: 'l'}}>
        <Title>{title}</Title>
      </Box>
      <Stepper options={options} activeKey={step} grey />
      <Space />
    </Content>
    {whiteLabelingConfig?.images?.reimburseMeSidebar ? (
      <Image src={whiteLabelingConfig?.images?.reimburseMeSidebar} alt="placeholder" />
    ) : null}

  </Wrapper>
);

export default StepperSidebar;
