import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { MFAChannel, MFAChannelPayload, REMEMBER_ME_DEVICE_TOKEN_COOKIE_KEY } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';
import { LoginPayload } from '@/modules/user/components/SignIn/signIn.types';
import { getCookie } from '@/utils/modifiers';

export interface AuthResponse {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  channels?: MFAChannelPayload[];
  session?: string;
}

export interface AuthData extends LoginPayload {
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: number;
  channels?: MFAChannel[];
  session?: string;
}

const useSignInQuery = () => {
  const {
    mutateAsync,
    isLoading,
  } = useMutation((loginPayload: LoginPayload) => api.post<AuthResponse>(PATHS.SIGN_IN, {
    user_name: loginPayload.username,
    password: loginPayload.password,
    ...loginPayload?.rememberMeDeviceToken ? {
      remember_me_device_token: loginPayload?.rememberMeDeviceToken,
    } : {},
  }));

  const handleSignIn = useCallback(async (loginPayload: LoginPayload) => {
    const rememberMeDeviceToken = getCookie(REMEMBER_ME_DEVICE_TOKEN_COOKIE_KEY);

    const { data } = await mutateAsync({
      ...loginPayload,
      rememberMeDeviceToken,
    });

    const formattedData: AuthData = {
      accessToken: data?.access_token,
      refreshToken: data?.refresh_token,
      expiresIn: data?.expires_in,
      channels: data?.channels?.map((channel) => ({
        isDefault: channel?.is_default,
        type: channel?.type,
        value: channel?.value,
      })),
      session: data?.session,
      ...loginPayload
    };

    return formattedData;
  }, [mutateAsync]);

  return {
    handleSignIn,
    isLoading,
  };
};

export default useSignInQuery;
