import { useCallback, useMemo } from 'react';

import { CommunicationOption } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';
import useUpdateEmployeeQuery from '@/modules/user/queries/useUpdateEmployee.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

interface Props {
  setIsEmailMessageModalVisible: (value: boolean) => void;
}

const useEmailOptions = ({ setIsEmailMessageModalVisible }: Props) => {
  const { employee } = useEmployeeStore();
  const { updateEmployee } = useUpdateEmployeeQuery();

  const handleSetEmployeeProvidedEmail = useCallback(() => {
    if (employee?.isVerifiedEmployeeEmail && employee?.employeeProvidedEmail) {
      updateEmployee({ is_employee_email_preferred: true });
      return;
    }

    setIsEmailMessageModalVisible(true);
  }, [employee, updateEmployee, setIsEmailMessageModalVisible]);

  const handleSetEmployerProvidedEmail = useCallback(() => {
    updateEmployee({ is_employee_email_preferred: false });
  }, [updateEmployee]);

  const emailOptions = useMemo((): CommunicationOption[] => [
    {
      key: 'employeeProvidedEmail',
      title: 'Employee provided email',
      value: employee?.employeeProvidedEmail,
      isActive: employee?.isEmployeeEmailPreferred
        && !!employee?.employeeProvidedEmail
        && employee?.isVerifiedEmployeeEmail,
      isSelected: employee?.isEmployeeEmailPreferred,
      onClick: handleSetEmployeeProvidedEmail,
    },
    {
      key: 'employerProvidedEmail',
      title: 'Employer provided email',
      value: employee?.employerProvidedEmail,
      isActive: !employee?.isEmployeeEmailPreferred && !!employee?.employerProvidedEmail,
      isSelected: !employee?.isEmployeeEmailPreferred,
      ...employee?.employerProvidedEmail ? { onClick: handleSetEmployerProvidedEmail } : {},
    }
  ], [employee, handleSetEmployeeProvidedEmail, handleSetEmployerProvidedEmail]);

  return { emailOptions };
};

export default useEmailOptions;
