import { useMemo } from 'react';

import { InvestorStatus } from '../investments.types';

import useInvestmentsHoldings from './useInvestmentsHoldings';
import useInvestmentsPendingActivities from './useInvestmentsPendingActivities';
import  useInvestor  from './useInvestor';

interface Params {
  accountId?: string;
}

export default ({accountId}: Params) => {
  const { data:{ investorId, status}  } = useInvestor(accountId);
  const {data: pendingHolding, isLoading: isPendingHoldingsLoading}  = useInvestmentsPendingActivities(investorId);
  const {
    data: activeHoldings,  isLoading: isActiveHoldingsLoading
  } = useInvestmentsHoldings(accountId);
 
  const isLoading= useMemo(() => isActiveHoldingsLoading || isPendingHoldingsLoading, [isActiveHoldingsLoading, isPendingHoldingsLoading]);
  
  const isFullLiquidation = useMemo(() => {
    if(isLoading) {
      return false;
    }
    if(status === InvestorStatus.ACTIVE) {
      if(pendingHolding.length === 0) {
        return false;
      }
      // EL-17263
      // const filteredActiveHoldings = activeHoldings;
      const filteredActiveHoldings = activeHoldings.filter((item) => item.amount);
      const currentSymbolsSet = new Set<string>(filteredActiveHoldings.map((Item) => Item.symbol));
      let isLiquidating = false;
      pendingHolding?.forEach((item) => {
        
        if(item.isFullLiquidation) {
          isLiquidating =  true;
          currentSymbolsSet.delete(item.symbol);
        }
       
      });
      if(currentSymbolsSet.size === 0 && pendingHolding.length !== 0 && isLiquidating) {
        return true;
      }
      return pendingHolding.length === 0 && filteredActiveHoldings.length === 0 && isLiquidating;
    }
    return false;

  }, [activeHoldings, isLoading, pendingHolding, status]);
  const isFullLiquidated= useMemo(() => {
    if(isLoading) {
      return false;
    }
    if(status === InvestorStatus.ACTIVE) {
      // EL-17263
      // const filteredActiveHoldings = activeHoldings.filter((item) => item.symbol || item.amount);
      const filteredActiveHoldings = activeHoldings.filter((item) => item.amount);
      return pendingHolding.length === 0 && filteredActiveHoldings.length === 0;
    }
    return false;

  }, [activeHoldings, isLoading, pendingHolding, status]);


  return {
    isFullLiquidation ,
    isFullLiquidated,
    isLoading:isPendingHoldingsLoading && isActiveHoldingsLoading 
  };
};

