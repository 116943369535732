import React from 'react';
import { Text,ToggleButton } from '@common-fe/common-fe';

import { InvestmentsActivityType } from '@/modules/investments/investments.types';

interface Props {
  onClick: (name: InvestmentsActivityType) => void;
  name: InvestmentsActivityType;
  currTab: InvestmentsActivityType;
  text: string;
}

const SelectButton: React.FC<Props> = ({
  name, currTab, text, onClick,
}) => (

  <ToggleButton
    active={currTab === name}
    onClick={() => onClick(name)}
  >
    <Text weight={currTab === name ? 700 : 400}>
      {text}
    </Text>
  </ToggleButton>

);

export default SelectButton;
