import React, {
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FlexList,
  Heading,
  Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { FULL_MONTH_DATE_FORMAT } from '@/common/constants';
import InvestmentsBreakdown from '@/modules/investments/InvestmentsBreakdown';
import { useQueryParams } from '@/utils/hooks';

import AutoInvestment from '../AutoInvestment';
import { useInvestor } from '../hooks';
import { InvestmentsActivityType } from '../investments.types';

import useInvestmentActivityList from './hooks/useInvestmentActivityList';
import ActivityListToggle from './ActivityListToggle';
import { EmptyFundsListPlaceholder } from './EmptyFundsListPlaceholder';
import { useInvestmentActivityListQuery, useInvestmentsFlexList } from './hooks';
import InvestmentDashboardFooter from './InvestmentDashboardFooter';

const QUERY_KEY = 'id';

interface Props {
  accountId?: string;
}
const InvestmentsDashboard: React.FC<Props> = ({ accountId}) => {
  const { t } = useTranslation();

  const query = useQueryParams();
  const id = useMemo(() => query.get(QUERY_KEY) || accountId, [accountId, query]);
  const { data: { currentToleranceModel, investorId } } = useInvestor(accountId || id);

  const [currentActivityTab, setCurrentActivityTab] = useState(InvestmentsActivityType.Pending);
  useInvestmentActivityList(currentActivityTab);
  const { isLoading } = useInvestmentActivityListQuery(currentActivityTab);
  const {
    headers, holdingsTotal, pendingsTotal,
    formattedList,
  } = useInvestmentsFlexList(id || '', currentActivityTab);

  return (
    <Box>
      <Box direction="column" background="module" round="moduleRound" pad="spacing24" margin={{ bottom: 'spacing24' }}>
        <Box direction="row" justify="between" margin={{ bottom: 'spacing8' }}>
          <Heading color="blockTitle" level={4} size="16px" style={{ lineHeight: '20px' }}>{t('Investment Breakdown')}</Heading>
          <Box width="control" margin={{ left: 'spacing8' }}>
            {/* <DateRangeDropdown
          disabled
          prefix={t('Period:')}
          className="period-picker"
          onChange={() => null}
          startDate={null}
          endDate={null}
          icon={<CalendarPickerIcon />}
        /> */}
          </Box>
        </Box>
        <InvestmentsBreakdown
          accountId={id}
          investorId={investorId}
          toleranceModel={currentToleranceModel}
          isPendingTransactionsPresent={pendingsTotal !== 0}
        />
      </Box>

        
      <AutoInvestment accountId={id} />
      <Box background="module" round="moduleRound" margin={{ bottom: 'spacing24' }}>
        <ActivityListToggle
          currentTab={currentActivityTab}
          onSetCurrentTab={setCurrentActivityTab}
          totalHoldings={holdingsTotal}
          totalPending={pendingsTotal}
          title={t('Investment portfolio: ' + (holdingsTotal || 0 + pendingsTotal || 0))}
        />
        <FlexList
          key={currentActivityTab}
          rows={formattedList}
          headers={headers}
          loading={isLoading}
          moreCount={100}
          footer={(
            <>
              {
                currentActivityTab === InvestmentsActivityType.Holding && holdingsTotal !== 0
                  ? (
                    <>
                      <Text style={{ flex: 3 }} margin={{ vertical: 'spacing12' }} color="textSecondary">{`${t('Data as of')} ${dayjs().format(FULL_MONTH_DATE_FORMAT)}`}</Text>
                    </>
                  ) : currentActivityTab === InvestmentsActivityType.Holding
                && <EmptyFundsListPlaceholder title={currentActivityTab} />
              }
              {
                currentActivityTab === InvestmentsActivityType.Pending && pendingsTotal !== 0
                  ? <Text style={{ flex: 3 }} margin={{ vertical: 'spacing12' }} color="textSecondary">{`${t('Data as of')} ${dayjs().format(FULL_MONTH_DATE_FORMAT)}`}</Text>
                  : currentActivityTab === InvestmentsActivityType.Pending
              && <EmptyFundsListPlaceholder title={currentActivityTab} />
              }
            </>
          )}
        />
      </Box>
      <InvestmentDashboardFooter /> 
    </Box>
  );
};

export default InvestmentsDashboard;
