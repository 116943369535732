import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  Text,
  WarnModal,
} from '@common-fe/common-fe';
import _ from 'lodash';

import ServerErrorModal from '../../modal/ServerErrorModal';
import { IS_AUTO_QUERY_KEY } from '../queries/useAutoInvestment.query';
import useAutoInvestmentDeactivationQuery from '../queries/useAutoInvestmentDeactivation.query';

import { SuccessAutoInvestmentModal } from './SuccessAutoInvestmentModal';

interface Props {
  investmentId?: string;
  visible: boolean;
  onSetVisible: (value: boolean) => void;
}
const DeactivateAutoInvestmentsModal: React.FC<Props> = ({
  visible, onSetVisible, investmentId,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [showServerError, setShowServerError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { mutateAsync: handleDelete, isLoading } = useAutoInvestmentDeactivationQuery(`${investmentId}`);

  const handleCloseSuccessModal = useCallback(() => {
    setShowSuccessModal(false);
    onSetVisible(false);
    queryClient.invalidateQueries(IS_AUTO_QUERY_KEY);
  }, [onSetVisible, queryClient]);

  const handleSubmit = useCallback(async () => {
    try {
      await handleDelete();
      setShowSuccessModal(true);
    } catch {
      setShowServerError(true);
    }
    finally {
      onSetVisible(false);
    }
  }, [handleDelete, onSetVisible, setShowServerError]);

  return (
    <>
      <WarnModal
        visible={visible}
        testId="deactivate-auto-investments"
        onSetVisible={onSetVisible}
        header={t('Deactivate Automatic Investments?')}
        helptext={t('Once canceled, funds will no longer be pulled from your HSA.')}
        buttonText={t('Cancel')}
        onSubmit={handleSubmit}
        submitButtonMode="alert"
        submitButtonText={t('Yes, Deactivate')}
      >
        <Text
          size="medium"
          weight="normal"
          textAlign="center"
        >
          {t('Do you want to cancel automatic investments?')}
        </Text>
      </WarnModal>
      <SuccessAutoInvestmentModal
        header={t('Automatic Investments Deactivated')}
        buttonText={t('Go to Investments Hub')}
        visible={showSuccessModal}
        onSetVisible={handleCloseSuccessModal}
        onSubmit={handleCloseSuccessModal}
      />
      <ServerErrorModal
        visible={showServerError}
        isLoading={isLoading}
        onSetVisible={setShowServerError}
        onSendRequest={handleSubmit}
      />
    </>
  );
};

export default DeactivateAutoInvestmentsModal;
