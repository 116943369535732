import { useEffect,useMemo } from 'react';
import {
  useQuery,
} from 'react-query';
import dayjs from 'dayjs';

import { api, mock } from '@/api';
import { PATHS } from '@/common';
import { InvestmentActivityDto, InvestmentsActivityDto, InvestmentsActivityType } from '@/modules/investments/investments.types';

const QUERY_KEY = 'getInvestmentsActivityMutation';
const PENDING_ACTIVITIES: InvestmentsActivityDto = {
  total: 5,
  investmentsActivity: [
    {
      id: '1',
      fund_name: 'Vanguard Target Retiredment Income Fund Investor (VTINX)',
      type: 'Market Buy',
      symbol: 'VTINX',
      date: dayjs().format('DD/MM/YY'),
      time: '01:00 AM',
      amount: 375,
      shares: '1,000',
    },
    {
      id: '2',
      fund_name: 'Vanguard Retirement Income (VRIF)',
      type: 'Market Buy',
      symbol: 'VRIF',
      date: dayjs().format('DD/MM/YY'),
      time: '01:00 AM',
      amount: 375,
      shares: '1,000',
    },
    {
      id: '3',
      fund_name: 'Schwab US Dividend Equity ETF (SPDR)',
      type: 'Market Buy',
      symbol: 'SPDR',
      date: dayjs().format('DD/MM/YY'),
      time: '01:00 AM',
      amount: 375,
      shares: '1,000',
    },
    {
      id: '4',
      fund_name: 'SPDR S&P 500 ETF Trust (SCHD)',
      type: 'Market Buy',
      symbol: 'SCHD',
      date: dayjs().format('DD/MM/YY'),
      time: '01:00 AM',
      amount: 195,
      shares: '1,000',
    },
    {
      id: '5',
      fund_name: 'Tesla Inc (TSLA)',
      type: 'Market Buy',
      symbol: 'TSLA',
      date: dayjs().format('DD/MM/YY'),
      time: '01:00 AM',
      amount: 180,
      shares: '1,000',
    },
  ],
};

export const useInvestmentActivityListQuery = (activityState: string) => {
  const response = useMemo(() => {
    switch (activityState) {
    case InvestmentsActivityType.Pending: {
      return PENDING_ACTIVITIES;
    }
    case InvestmentsActivityType.Holding: {
      return PENDING_ACTIVITIES;
    }
    default: {
      return [];
    }
    }
  }, [activityState]);

  const {
    data, isLoading, isSuccess, refetch, remove,
  } = useQuery(
    [QUERY_KEY],
    () => api.get(PATHS.INVESTMENT_ACTIVITY_LIST),
  );

  mock.onGet(PATHS.INVESTMENT_ACTIVITY_LIST).reply(200, response);

  useEffect(() => {
    if (activityState) {
      refetch();
    } else {
      remove();
    }
  }, [activityState, refetch, remove]);

  const formatedData = useMemo(() => {
    const receivedData: InvestmentsActivityDto = data?.data || [];
    return {
      total: receivedData?.total,
      investmentsActivity: receivedData?.investmentsActivity?.map(
        (listItem: InvestmentActivityDto) => ({
          id: listItem.id,
          fundName: listItem.fund_name,
          type: listItem.type,
          symbol: listItem.symbol,
          date: listItem.date,
          time: listItem.time,
          amount: listItem.amount,
          shares: listItem.shares,
        }),
      ),
    };
  }, [data]);
  return ({
    formatedData: formatedData || [],
    isLoading,
    isSuccess,
    data,
    refetch,
  });
};

export default useInvestmentActivityListQuery;
