import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import { GrayLine } from './Investments.styles';
const InvestmentDashboardFooter: React.FC = () => {
  const { t } = useTranslation();
  return (

    <Box direction='column'>
      <GrayLine />
      <Text size="large" weight="bold" margin={{ bottom: 'spacing12' }}>
        {t('Note')}
      </Text>
      <Text margin={{ bottom: 'spacing12' }} size="medium" color="textSecondary">
        {t('Past performance of investments is simply an indicator and is no guarantee of future results.')}
      </Text>
      <Text margin={{ bottom: 'spacing24' }} size="medium" color="textSecondary">
        {t('Investment advisory services offered through Intellicents investment solutions, inc., an SEC registered investment adviser.')}
      </Text>
    </Box>
  );
  
}; 

export default InvestmentDashboardFooter;


