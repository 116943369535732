import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexControlledForm, Inscription, useDebounceState } from '@common-fe/common-fe';

import { EmailVerificationStep, VerificationType } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';
import useEmailVerificationFields, { FormValues } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/hooks/useEmailVerificationFields';
import useUpdateEmployeeQuery from '@/modules/user/queries/useUpdateEmployee.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';
import { useValidateForm } from '@/utils/hooks';

import useSendVerificationCodeQuery from '../../queries/useSendVerificationCode.query';
import useValidateVerifyCodeQuery from '../../queries/useValidateVerifyCode.query';
import VerificationModal from '../VerificationModal';

interface Props {
  email: string;
  setEmail: (value: string) => void;
  onSetVisible: (value: boolean) => void;
  setStep: (step: EmailVerificationStep) => void;
}

const EnterEmailVerificationModal: React.FC<Props> = ({ email, setEmail, onSetVisible, setStep }) => {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { updateEmployee } = useUpdateEmployeeQuery();
  const [state, handleSetState] = useState<FormValues>();
  const { values, setValues } = useDebounceState<FormValues>();
  const [isValidationResponseError, setIsValidationResponseError] = useState(false);
  const fields = useEmailVerificationFields(email);
  const { handlePreSubmit, hasErrors } = useValidateForm(fields);
  const { sendVerificationCode, isLoading, isError } = useSendVerificationCodeQuery();
  const {
    validateVerifyCode,
    isLoading: isValidationLoading,
    isError: isValidationError,
  } = useValidateVerifyCodeQuery();

  const isEmployerProvidedEmailUsed = useMemo(() => {
    const employerProvidedEmail = employee?.employerProvidedEmail;
    const newEmployeeProvidedEmail = values?.employeeProvidedEmail;

    if (employerProvidedEmail) {
      return newEmployeeProvidedEmail === employerProvidedEmail;
    }

    return false;
  }, [employee, values]);

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  const handleCancel = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleNext = useCallback(async () => {
    try {
      const isSuccess = handlePreSubmit(state);
      if (!isSuccess) {
        return;
      }
      if (isEmployerProvidedEmailUsed) {
        const response = await validateVerifyCode({
          value: state?.employeeProvidedEmail || '',
          type: VerificationType.EMAIL,
        });
        if (!response?.verified) {
          throw new Error();
        }
        updateEmployee({
          employee_provided_email: state?.employeeProvidedEmail || '',
          is_employee_email_preferred: true,
        });
        setStep(EmailVerificationStep.EMAIL_VERIFIED_SUCCESSFULLY);
      } else {
        await sendVerificationCode({
          type: VerificationType.EMAIL,
          value: state?.employeeProvidedEmail || ''
        });
        setEmail(state?.employeeProvidedEmail || '');
        setStep(EmailVerificationStep.EMAIL_VERIFICATION_CODE);
      }
    } catch (e) {
      setIsValidationResponseError(true);
    }
  }, [
    sendVerificationCode,
    setEmail,
    setStep,
    handlePreSubmit,
    validateVerifyCode,
    updateEmployee,
    state,
    isEmployerProvidedEmailUsed,
  ]);

  return (
    <VerificationModal
      title={t('Stay in the Know')}
      subTitle={t('Add your personal email and never miss an update.')}
      isLoading={isLoading || isValidationLoading}
      isError={isError || isValidationError || isValidationResponseError}
      onSetVisible={onSetVisible}
      onCancel={handleCancel}
      onNext={handleNext}
      warningText={(
        <Inscription>
          {t('To verify your email address we will send a verification code to the email address provided.')}
        </Inscription>
      )}
      form={(
        <FlexControlledForm
          fields={fields}
          formStyle={{ margin: '24px' }}
          onChangeValues={setValues}
          showError={hasErrors}
          editMode
        />
      )}
    />
  );
};

export default EnterEmailVerificationModal;
