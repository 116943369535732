import { useEffect, useState } from 'react';

interface Props {
  onSetHighlightedId: (id: string) => void;
  reset: () => void;
  delay?: number
}

const useHighlightById = ({
  onSetHighlightedId,
  reset,
  delay = 2000,
}: Props) => {
  const [highlightedById, setHighlightedById] = useState<string>();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    return () => {
      if (highlightedById) {
        onSetHighlightedId(highlightedById);

        timer = setTimeout(() => {
          reset();
          clearTimeout(timer);
        }, delay);
      }
    };
  }, [onSetHighlightedId, reset, highlightedById, delay]);

  return {
    setHighlightedById,
  };
};

export default useHighlightById;
