import { useMemo } from 'react';
import { toString } from 'lodash';

import { LinkedEmployee } from '@/modules/user/user.types';

import { LinkedEmployeeAccount } from '../orphanMode.types';

interface Props {
  linkedEmployeesString?: string;
  linkedEmployees?: LinkedEmployeeAccount[];
}

const useFormatLinkedEmployees = ({
  linkedEmployeesString,
  linkedEmployees,
}: Props): LinkedEmployee[] => {
  const formattedLinkedEmployees = useMemo(() => {
    if (!linkedEmployeesString || !linkedEmployees) return [];

    const parsedLinkedEmployees = JSON.parse(linkedEmployeesString);

    return linkedEmployees.map((linkedEmployee: LinkedEmployeeAccount) => ({
      ...linkedEmployee,
      personId: toString(parsedLinkedEmployees?.[linkedEmployee.employeeId]?.person_id),
    }));
  }, [linkedEmployeesString, linkedEmployees]);

  return formattedLinkedEmployees;
};

export default useFormatLinkedEmployees;
