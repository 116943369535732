import React, {  useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, NavigationRightIcon, Text } from '@common-fe/common-fe';
import _ from 'lodash';

import { REGEXPS } from '@/common';
import { CURRENCY_SIGN } from '@/common/constants';

import SelfDIrectedWrapper from '../SelfDirectedWrapper';

import EnrollmentCardBlock from './EnrollmentCardBlock';
import { useAllocationInvestmentAmount, useSelfDirectedAllocationValidation } from './hooks';
import SelfDirectedAllocationFunds from './SelfDirectedAllocationFunds';
import SelfDirectedInvestAmount from './SelfDirectedInvestAmount';

interface Props {
  accountId: string;
  investorId?: string;
}

const SelfDirectedAllocation: React.FC<Props> = ({ accountId, investorId }) => {
  const { value, setValue } =useAllocationInvestmentAmount();

  const [isSubmitted, setSubmitted] = useState(false);
  const valueToNumber = useMemo(() => {
    const clearedValue = value.replace(REGEXPS.ALL_COMMAS_REGEXP, '').replace(CURRENCY_SIGN, '');
    return _.toNumber(clearedValue);
  }, [value]);
  const { maxAmount, errorText } = useSelfDirectedAllocationValidation({
    accountId,
    investorId,
    value: valueToNumber,
  });

  const { t } = useTranslation();
  return (
    <SelfDIrectedWrapper currentStep={4}>
      <Text size="3xl" weight={700}>
        {t('Set Fund Allocation')}
      </Text>
      <Text size="xxlarge" margin={{ top: 'l' }} weight={700}>
        {t('Funding Amount')}
      </Text>
      <Box direction="row" margin={{ top: 'l', bottom: 'l' }} background="module" round="moduleRound" pad="l">
        <EnrollmentCardBlock accountId={accountId} />
        <Box pad={{ horizontal: 's' }} align="center" justify="center">
          <NavigationRightIcon />
        </Box>
        <Box justify="center">
          <SelfDirectedInvestAmount
            value={value}
            errorText={errorText}
            maxValue={maxAmount}
            isSubmitted={isSubmitted}
            onSetValue={setValue}
          />
        </Box>
      </Box>
      <SelfDirectedAllocationFunds
        isErrors={!!errorText}
        investorId={investorId}
        accountId={accountId}
        investAmount={valueToNumber}
        isSubmitted={isSubmitted}
        onSetSubmitted={setSubmitted}
      />
    </SelfDIrectedWrapper>
  );
};

export default SelfDirectedAllocation;
