import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvestmentPathType } from '@common-fe/common-fe';

import { useMyEnrollments } from '@/modules/transaction';

import { useInvestor } from '../hooks';
import { InvestmentPaths } from '../investments.types';
import EditBreakdownItem from '../InvestmentsBreakdown/EditBreakdownItem';

import InvestmentSwitchModelModal from './InvestmentSwitchModelModal';

interface Props {
  accountId?: string;
}

const InvestmentSwitchModel: React.FC<Props> = ({ accountId }) => {
  const [visible, onSetVisible] = useState(false); 
  const { t } = useTranslation();

  const{currentMyAccount} = useMyEnrollments(accountId);
  const handleOpenSwitcher = useCallback(() => {
    onSetVisible(true);
  }, []);
  const { data} = useInvestor(accountId);
  
  const isSelfDirectedMode = useMemo(
    () => data?.type=== InvestmentPaths.SelfDirected,
    [data?.type]
  );

  const isSwitchDisabled= useMemo(() => {
    return isSelfDirectedMode 
      ? !currentMyAccount?.investmentOptionTypes?.includes(InvestmentPathType.MANAGED)
      :!currentMyAccount?.investmentOptionTypes?.includes(InvestmentPathType.SELF_DIRECTED); 
  }, [currentMyAccount?.investmentOptionTypes, isSelfDirectedMode]);
 
  return (
    <>
      <InvestmentSwitchModelModal isSelfDirectedMode={isSelfDirectedMode} 
        accountId={accountId}
        visible={visible}
        onSetVisible={onSetVisible} />

      <EditBreakdownItem onClick={handleOpenSwitcher} label={t('Investment model')} disabled={isSwitchDisabled}>
        { isSelfDirectedMode ?
          t('Self-Directed') : t('Managed') }
      </EditBreakdownItem>
    </>
  );
};
export default InvestmentSwitchModel;