import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  width: 100%;
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  background-color: ${({ theme }) => theme.colors.canvas};
  padding: ${({ theme }) => `${theme.spacings.spacing16} ${theme.spacings.spacing16} ${theme.spacings.spacing8}`};

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    padding: ${({ theme }) => `${theme.spacings.spacing24} ${theme.spacings.spacing24} ${theme.spacings.spacing16}`};
  }

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    padding: ${({ theme }) => `${theme.spacings.spacing32} ${theme.spacings.spacing32} ${theme.spacings.spacing24}`};
  }
`;

interface TitleProps {
  isHovered?: boolean;
  isLong?: boolean;
}

export const Title = styled.h2<TitleProps>`
  flex: 1;
  ${({ theme }) => theme.fonts.text24Bold};
  ${({ isHovered }) => (isHovered ? 'cursor: pointer;' : undefined)};
  margin-left: ${({ theme }) => theme.spacings.spacing16};
  margin-bottom: ${({ theme }) => theme.spacings.spacing4};
  color: ${({ theme }) => theme.colors.blockTitle || theme.colors.textBody};
  
  line-height: ${({ theme }) => theme.spacings.spacing32};
  transition: all .3s ease-in-out;
  ${({ isLong }) => (isLong ? 'word-break: break-all' : undefined)};
  &:hover {
    ${({ theme, isHovered }) => (isHovered ? `color: ${theme.colors.textAccent}` : undefined)};
  }
`;
export const Subtitle = styled(Text)`
  ${({ theme }) => theme.fonts.text16Bold};
  margin-left: ${({ theme }) => theme.spacings.spacing16};
  color: ${({ theme }) => theme.colors.textBody};
`;
export const HeaderWrapper = styled(Box)`
  flex-direction: column-reverse;
  align-items: start;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacings.spacing24};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    flex-direction: row;
    align-items: center;
  }
`;

export const TransactionTypeWrapper = styled(Box)`
  @media ${({ theme }) => theme.deviceSize.lessThen.laptopM} {
    padding-bottom: ${({ theme }) => theme.spacings.spacing12};
    flex-direction: row;
    align-items: center;
  }
`;

export const Content = styled(Box)`
  padding: ${({ theme }) => `${theme.spacings.spacing24} 0`};
  flex-direction: column-reverse;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    flex-direction: row;
    padding: ${({ theme }) => `${theme.spacings.spacing32} 0`};
  }
`;

export const AmountsWrapper = styled(Box)`
  @media ${({ theme }) => theme.deviceSize.lessThen.laptopM} {
    padding-bottom: ${({ theme }) => theme.spacings.spacing24};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
  }
`;

export const PaidAmount = styled(Box)`
  @media ${({ theme }) => theme.deviceSize.lessThen.laptopM} {
    padding-top: ${({ theme }) => theme.spacings.spacing24};
  }
`;

export const PatientWrapper = styled(Box)`
  flex: 1;
`;

export const ColumnTitle = styled(Text)`
  ${({ theme }) => theme.fonts.text16Regular};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

interface ColumnTextProps {
  isInfoColor?: boolean;
}
export const ColumnText = styled(Text)<ColumnTextProps>`
  ${({ theme }) => theme.fonts.text16Bold};
  color: ${({ theme, isInfoColor }) => isInfoColor && theme.colors.info};
`;
export const AmountText = styled(Text)`
  ${({ theme }) => theme.fonts.text32Bold};
  margin-top:${({ theme }) => theme.spacings.spacing16};
  color: ${({ theme }) => theme.colors.textAccent};
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacings.spacing24};
  flex-wrap: wrap;
`;

export const FooterRow = styled.div`
  display: flex;
`;

export const FooterItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-width: 225px;
  padding-bottom: ${({ theme }) => theme.spacings.spacing8};
`;
export const FooterLabel = styled.p`
  ${({ theme }) => theme.fonts.text16Regular};
  color: ${({ theme }) => theme.colors.textSecondary};
`;
export const FooterText = styled.p`
  ${({ theme }) => theme.fonts.text16Bold};
  margin-left: ${({ theme }) => theme.spacings.spacing12};
`;

export const IdBlock = styled(Text)`
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme }) => theme.colors.textSecondary};
  text-align: end;
  margin-top: 0px;
  margin-left: ${({ theme }) => theme.spacings.spacing16};

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    margin-top: ${({ theme }) => theme.spacings.spacing8};
  }
`;

export const TransactionTypeLabel = styled.p`
  ${({ theme }) => theme.fonts.text12Medium};
  min-width: min-content;
  color: ${({ theme }) => theme.colors.textBody};
  background-color: ${({ theme }) => theme.colors.legend};
  text-align: center;
  border-radius: ${({ theme }) => theme.spacings.spacing4};
  padding: ${({ theme }) => `${theme.spacings.spacing2} ${theme.spacings.spacing6}`};
  white-space: nowrap;
`;

export const WrapperInfoIcon = styled.div`
  padding: ${({ theme }) => `${theme.spacings.spacing6} 0 0 ${theme.spacings.spacing12}`};
  cursor: pointer;
  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.iconAccent};
    }
  }
`;
