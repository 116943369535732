import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FileImage } from '@/common/constants';
import { ProcessScan } from '@/components/controls/DropScan/ProcessScan';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import { ContentWrapper } from '@/modules/transaction/components/ReimburseMe/ReimburseMe.styles';
import useFileUploadQuery from '@/modules/transaction/hooks/useFileUploadQuery';

import { useOcrWarningsStore } from '../ClaimInfo/ClaimWarningBanner/store';
import useFilesStore from '../store/useFiles.store';

import {
  Title,
  Wrapper, } from './FileScan.styles';
import { ParsedFileByOcr } from './fileScan.types';
import useGetScannedDocumentsQuery from './useGetScannedDocumentQuery';

interface Props {
  onNext: () => void;
  onGetScannedDocument: (data: ParsedFileByOcr) => void;
}

const FileScan: React.FC<Props> = ({
  onNext, onGetScannedDocument,
}) => {
  const { t } = useTranslation();
  const { activeFile, files, setFiles } = useFilesStore((state) => state);
  const { values: { documentId, files: docFiles } } = useFilesInDocumentStore();
  const isFilesAlreadyUploaded = useMemo(
    () => files.every((file, i) => file.id === docFiles[i]?.id),
    [files, docFiles],
  );
  const [documentsIds, setDocumentsIds] = useState<string[]>([]);
  const { getScannedDocument } = useGetScannedDocumentsQuery();
  const setOcrWarnings = useOcrWarningsStore((state) => state.handleSetOcrWarnings);
  const handleSetOcrWarningsList = useOcrWarningsStore(
    (state) => state.handleSetOcrWarningsList,
  );
  const setOcrFieldsWarnings = useOcrWarningsStore(
    (state) => state.handleSetFieldsOcrWarnings,
  );
  const { uploadFiles } = useFileUploadQuery();
  const getParsedFile = useCallback(async (id: string) => {
    const parsedFile = await getScannedDocument(id);
    onGetScannedDocument(parsedFile);
    setOcrWarnings(parsedFile.warningsFields);
    setOcrFieldsWarnings(parsedFile.warningsFields);
    handleSetOcrWarningsList(parsedFile.warnings);
    onNext();
  }, [getScannedDocument, onGetScannedDocument, onNext,
    setOcrWarnings, setOcrFieldsWarnings, handleSetOcrWarningsList]);
  const runScanning = useCallback(async () => {
    const uploadedFiles = isFilesAlreadyUploaded
      ? files
      : await uploadFiles(documentId, files as FileImage[]);
    const ids = uploadedFiles.map((file) => file.id);

    if (uploadedFiles.length) {
      const filesWithId = files
        .map(
          (file) => {
            const id = uploadedFiles
              .find(
                (uploadedFile) => uploadedFile.temporalId === file.temporalId,
              )?.id;

            if (id) {
              return { ...file, id };
            }

            return file;
          },
        );

      setFiles(filesWithId);
    }

    setDocumentsIds(ids);
    getParsedFile(ids[0]);
  }, [
    uploadFiles,
    getParsedFile,
    setFiles,
    files,
    documentId,
    isFilesAlreadyUploaded,
  ]);

  useEffect(() => {
    runScanning();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Wrapper align="center">
      <ContentWrapper>
        <Title>
          {t('We’re Checking Out the Info So You Don’t Have To')}
        </Title>
        <ProcessScan areFilesUploaded={documentsIds.length === 0} onNext={onNext} />
      </ContentWrapper>
    </Wrapper>
  );
};

export default FileScan;
