import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { AppButton, Box, Modal,  Text, WarningIcon } from '@common-fe/common-fe';

import { useHistory } from '../../hooks';


interface Props {
  isPrevent: boolean;
  onConfirm?: () => void;
  title?: string;
  subText?: string;
}
const TIMEOUT_DELAY = 250;
const PreventLeaveModal: React.FC<Props> =({title, subText, isPrevent, onConfirm }) => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  useEffect(() => {
    setConfirmedNavigation(!isPrevent);
  }, [isPrevent]);
  const handleBlockedNavigation = (nextLocation: Location,) => {
    if (!confirmedNavigation) {

      setTimeout(() => {
        setModalVisible(true);
      }, TIMEOUT_DELAY);
        
      setLastLocation(nextLocation);
      return false; 
    }
    return true; 
  };

  const handleConfirm = useCallback(() => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    if(onConfirm) {
      onConfirm();
    }
  }, [onConfirm]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, []);
  const history = useHistory();

  
  useEffect(() => {
    if (confirmedNavigation && lastLocation) { 
      history.push((lastLocation.pathname || '') + (lastLocation.search || '') as string);
    }
  }, [confirmedNavigation, history, lastLocation]);


  return (
    <>
      <Prompt when={!confirmedNavigation} message={(location) => handleBlockedNavigation(location as  unknown as Location)} />

      <Modal
        visible={isModalVisible}
        onSetVisible={setModalVisible}
        

      >
        <Box direction='column' align='center'>
          <WarningIcon size="48px"/>
          <Text margin={{top: 's'}} weight={700} size="2xl">
            {title || t('Your Progress Will Be Lost')}
          </Text>
          <Text textAlign='center' margin={{top: 's'}}>
            {subText || t('By leaving now, your progress will not be saved. No changes will be made to your investment model.')} 
          </Text>
          <Box direction="row" margin={{top: 'l', bottom: 'l'}}>
            <Box margin={{ right: 'spacing12' }}>
              <AppButton
                testId="server-modal_cancel"
                width="170px"
                onClick={handleCancel}
                buttonType="secondary"
              >
                {t('Continue Working')}
              </AppButton>
            </Box>
            <AppButton
              onClick={handleConfirm}
              width="170px"
              testId="server-modal_retry"
            >
              { t('Exit Anyway')}
            </AppButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default PreventLeaveModal;