import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { GET_TRANSACTION_DETAILS_BY_CLAIM_ID } from '@/modules/transaction/hooks/useGetTransactionDetails.query';
import { ClaimPayee, PaymentMethodType } from '@/modules/user/user.types';

const employeeProcessPaymentMethod = {
  [PaymentMethodType.DIRECT_DEPOSIT]: 'ACH',
  [PaymentMethodType.CHECK]: 'CHECK',
  [PaymentMethodType.DEBIT]: 'DEBIT',
  [PaymentMethodType.PAYPAL]: 'PAYPAL',
  [PaymentMethodType.VENMO]: 'VENMO',
};
export interface PaymentMethod {
  payToType?: ClaimPayee,
  transactMethod?: PaymentMethodType,
  paymentMethodId?: string,
}

const useEmployeeProcessQuery = (claimId: string) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync, isLoading, isError, isSuccess,
  } = useMutation(
    (paymentMethod: PaymentMethod) => api.put(PATHS.EMPLOYEE_PROCESS(claimId), {
      pay_to_type: paymentMethod.payToType,
      payment_method_id: paymentMethod.paymentMethodId,
      ...paymentMethod?.transactMethod ? {
        transact_method: employeeProcessPaymentMethod[paymentMethod.transactMethod],
      } : {},
    }), {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_TRANSACTION_DETAILS_BY_CLAIM_ID, claimId]);
      },
    },
  );

  return {
    employeeProcess: mutateAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useEmployeeProcessQuery;
