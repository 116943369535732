import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import BuySell from '@/modules/investments/BuySell';
import { useQueryParams } from '@/utils';

const QUERY_KEY = 'id';

const StartInvestingPage = () => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const accountId = useMemo(() => query.get(QUERY_KEY), [query]) || '';
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: '...',
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Investments'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Invest More'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }], true);
  return (
    <BuySell />
  );
};

export default StartInvestingPage;
