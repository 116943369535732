import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

export const useUpdateRiskModelQuery = (
  id: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const { mutateAsync, isLoading, isError } = useMutation(
    (toleranceModel: string) => api.patch(PATHS.INVESTMENTS_UPDATE_RISK_MODEL(id),
      // eslint-disable-next-line
          `\"${toleranceModel}\"`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        if (onError) onError();
      },
    },
  );

  const handleUpdate = useCallback(async (value: string) => {
    await mutateAsync(value);
  }, [mutateAsync]);

  return {
    onUpdate: handleUpdate,
    isLoading,
    isError,
  };
};
