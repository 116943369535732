import { storeCreator } from '@/utils/store/store.utils';

interface State {
  organistaionId: string | null;
  handleSetOrganistaionId: (id: string | null) => void;
}

export const useStore = storeCreator<State>((set) => ({
  organistaionId: null,
  handleSetOrganistaionId: (id) => set(() => ({
    organistaionId: id,
  })),

}));
export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
