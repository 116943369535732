import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription, Text, } from '@common-fe/common-fe';
import { capitalize } from 'lodash';

import StatusIcon from '@/components/elements/StatusIcon';
import { Notification, TransactionStatus } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import AppealButton from '../../AppealButton';
import { IconWrapper, Title, Wrapper } from '../../TransactionNotification.styles';
import appealText from '../AppealText';

interface Props {
  data?: Notification;
  employeeFirstName?: string;
  serviceName?: string;
  amount: number;
  paidAmount: number;
  approvedAmount: number;
}

const isNotPaidMessage = (props: Props) => props?.data?.status === TransactionStatus.Approved && props.paidAmount === 0 && props.amount === props.approvedAmount;
const isPartiallyApprovedMessage = (props: Props) => props?.data?.status === TransactionStatus.PartiallyApproved && props.paidAmount === 0 && props.amount > props.approvedAmount;
const isPartiallyPaidMessage = (props: Props) => props?.data?.status === TransactionStatus.Approved && props?.paidAmount > 0 && props?.amount === props?.approvedAmount;
const isPartiallyApprovedAndPaidMessage = (props: Props) => props.data?.status === TransactionStatus.PartiallyApproved && props.paidAmount > 0 && props.amount > props.approvedAmount;

export const hasWaitingForFundsMessage = (props: Props) => isNotPaidMessage(props)
  || isPartiallyApprovedMessage(props)
  || isPartiallyPaidMessage(props)
  || isPartiallyApprovedAndPaidMessage(props);

const WaitingForFundsNotificationMessage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { mainText, text, hasAppealButton } = useMemo(() => {
    if (isNotPaidMessage(props)) {
      return {
        mainText: `${t('Hi')} ${props?.employeeFirstName}. ${t('The amount requested was approved')}!`,
        text: t('There are not enough funds available to cover the full approved amount right now, but we’ll send you what we can.'),
      };
    }

    if (isPartiallyApprovedMessage(props)) {
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {props?.employeeFirstName}. It looks like some of the expenses are not eligible per your plan or IRS rules so we were only able to approve part of the amount requested for {props?.serviceName}.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>There are not enough funds available to cover the full approved amount right now, but we’ll send you what we can.</Box>
          </Inscription>
        ),
        text: appealText,
        hasAppealButton: true,
      };
    }

    if (isPartiallyPaidMessage(props)) {
      return {
        mainText: `${t('Hi')} ${props?.employeeFirstName}. ${t('The amount requested was approved')}!`,
        text: t('There are not enough funds available to cover the full approved amount right now, but we’ll send you what we can.'),
      };
    }

    if (isPartiallyApprovedAndPaidMessage(props)) {
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {props?.employeeFirstName}. It looks like some of the expenses are not eligible per your plan or IRS rules so we were only able to approve part of the amount requested for {props?.serviceName}.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>There are not enough funds available to cover the full approved amount right now, but we’ll send you what we can.</Box>
          </Inscription>
        ),
        text: appealText,
        hasAppealButton: true,
      };
    }

    return {};
  }, [props, t]);

  if (!hasWaitingForFundsMessage(props)) return null;

  return (
    <Wrapper
      warning
      data-testid={`denied-approvalCode-${props?.data?.approvalCode}`}
      flex-direction="column"
      background="canvas"
      border={{
        size: 'small',
        color: 'warningBorder',
      }}
      elevation="default"
      pad={{
        top: 'l',
        right: 'l',
        bottom: 'l',
        left: '56px',
      }}
    >
      <IconWrapper><StatusIcon status={TransactionStatus.WaitingForFunds} /></IconWrapper>
      {mainText && typeof mainText === 'string' ? <Title data-testid="main-text">{mainText}</Title> : null}
      {typeof mainText === 'object' ? mainText : null}
      {text && typeof text === 'string' && (
        <Text size={theme.spacings.m} {...mainText ? { margin: { top: 'xs' }} : {}}>
          {text}
        </Text>
      )}
      {typeof text === 'object' ? text : null}
      {hasAppealButton ? <AppealButton /> : null}
    </Wrapper>
  );
};

export default WaitingForFundsNotificationMessage;
