import { useCallback } from 'react';

import { ROUTES } from '@/common';
import { useHistory } from '@/modules/core/hooks';

import { InvestorStatus } from '../investments.types';

interface Params {
  accountId?: string;
}

interface Payload {
  investorId: string;
  investorStatus: InvestorStatus;
}
const useSelfDirectedInvestingRedirect = ({ accountId }: Params) => {
  const history = useHistory();
  const handleRedirect = useCallback(
    ({ investorId, investorStatus }: Payload) => {
      if (investorStatus === InvestorStatus.INVESTMENT_PATH_SELECTED) {
        history.push(ROUTES.SELF_DIRECTED_QUESTIONNAIRE(accountId));
      } else if (investorStatus === InvestorStatus.QUESTIONNAIRE_FILLED) {
        history.push(`${ROUTES.SELF_DIRECTED(accountId)}?investorId=${investorId}`);
      } else if (investorStatus === InvestorStatus.INVESTMENT_AMOUNT_SELECTED) {
        history.push(`${ROUTES.SELF_DIRECTED_ALLOCATION(accountId)}?investorId=${investorId}`);
      } else {
        history.push(`${ROUTES.START_INVESTING}?accountId=${accountId}&investorId=${investorId}`);
      }
    },
    [accountId, history]
  );

  return handleRedirect;
};

export default useSelfDirectedInvestingRedirect;
