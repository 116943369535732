import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import { useMyEnrollments } from '@/modules/transaction/hooks';

interface Props {
  isCurrentHSA?: boolean;
}

const DisclosureBankMessage: React.FC<Props> = ({
  isCurrentHSA,
}) => {
  const { t } = useTranslation();
  const { isHSAExists } = useMyEnrollments();

  if (!isHSAExists || !isCurrentHSA) {
    return null;
  }
  return (
    <Box direction="column">
      <Box margin={{ top: 'l', bottom: 'm' }} border={{ side: 'bottom', size: '1px', color: 'border1' }} />
      <Text color="textSecondary" size="medium">
        {t('Funds in an HSA Deposit Account are held at UMB Bank, n.a., Member FDIC.')}
      </Text>
      {isCurrentHSA
        && (
          <Text color="textSecondary" size="medium" margin={{ top: 's' }}>
            {t('Investment advisory services offered through Intellicents Investment Solutions, Inc., an SEC registered investment adviser.')}
          </Text>
        )}
    </Box>
  );
};
export default DisclosureBankMessage;
