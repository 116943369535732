import styled from 'styled-components';

export const GrayLine = styled.hr`
  background-color: ${({ theme }) => theme.colors.border2};
  height: 1px;
  width: 100%;
  border: none;
  margin-top: ${({ theme }) => theme.spacings.spacing24};
  margin-bottom: ${({ theme }) => theme.spacings.spacing16};
`;
