import { useEffect } from 'react';

import { useNotificationsQuery } from '@/modules/notification/queries';

import { useNotificationCenterStore } from '../store';
// import { Notification } from '@common-fe/common-fe';
// import dayjs from 'dayjs';

interface Params {
  isQached?: boolean;
}
const useNotifications = (param?: Params) => {
  const {
    data, isLoading, handleDismissAll, handleDismissById, handleRead, isRead, isSuccess, isFetched,
  } = useNotificationsQuery(param?.isQached);
  const setNotificationCount = useNotificationCenterStore((state) => state.setNotificationsTotal);
  useEffect(() => {
    setNotificationCount(data.length);
  }, [data, setNotificationCount]);
  return {
    data,
    isRead,
    isSuccess,
    isFetched,
    isLoading,
    handleDismissAll,
    handleDismissById,
    handleRead,
  };
};

export default useNotifications;
