import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton,Box,Text } from '@common-fe/common-fe';

import routes from '@/common/routes';
import { PreventLeaveModal } from '@/modules/core/components';
import { useHistory } from '@/modules/core/hooks';

import { useInvestor } from '../../hooks';
import SelfDirectedFunds from '../SelfDirectedFunds';
import SelfDIrectedWrapper from '../SelfDirectedWrapper';
import { useSelectedFundsStore } from '../stores';
interface Props {
  accountId: string;
}

const SwitchToSelfDirectedSelect: React.FC<Props> = ({ accountId}) => {
  const { t } = useTranslation();

  const [isPreventLeave, setPreventLeave] = useState(true);

  const selectedIds =  useSelectedFundsStore((state) => state.selectedFunds); 
  const [isSubmitted, setSubmitted] = useState(false);
  const history = useHistory();
  const isDisabled = useMemo(() => {
    return !selectedIds.length && isSubmitted;
  }, [isSubmitted, selectedIds.length]);

  const handleNext = useCallback(() => {
    setPreventLeave(false);
    setSubmitted(true);
    if(selectedIds.length) {
      history.push(routes.SELF_DIRECTED_SWITCH_ALLOCATION(accountId));
    }
  }, [accountId, history, selectedIds.length]); 
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);
  const {data: investor} = useInvestor();
  const portfolioBalance = useMemo(() => {
    return investor.cashBalance + investor.equityValue;
  }, [investor.cashBalance, investor.equityValue]);
  return (
    <SelfDIrectedWrapper isSwitchMode currentStep={1}>
      <PreventLeaveModal isPrevent={isPreventLeave} />
      <Text size="3xl" weight={700}>{t('Select Funds')}</Text>


      <SelfDirectedFunds isSwitchMode accountId={accountId} portfolioBalance={portfolioBalance} />
      <Box pad={{ top: 'l', bottom: 'l' }} direction="row" justify="end" align="end">
        <Box direction='column' align='end'>
          {isDisabled && (
            <Text margin={{bottom: 'm'}} color="danger">{t('Select at least one fund')}</Text>
          )}
        
          <Box direction='row'>
            <Box>
              <AppButton
                buttonType="secondary"
                width="140px"
                onClick={handleBack}
              >
                {t('Back')}
              </AppButton>
            </Box>
            <Box margin={{ left: 's' }}>
              <AppButton disabled={isDisabled} width="140px" onClick={handleNext}>
                {t('Next (1/2)')}
              </AppButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </SelfDIrectedWrapper>
   
  );
};
export default SwitchToSelfDirectedSelect;