import React, { useMemo } from 'react';
import {
  Box,
  ClockIcon,
  Inscription,
  RoundCloseIcon,
  RoundDoneIcon,
  RoundPendingIcon,
} from '@common-fe/common-fe';

type IconType = 'success' | 'error' | 'pending' | 'expired';

interface Props {
  iconType?: IconType;
  title?: string | React.ReactNode;
  description?: string;
}

const UploadFileByLinkInfoContainer: React.FC<Props> = ({ iconType, title, description }) => {
  const icon = useMemo(() => {
    switch (iconType) {
    case 'success':
      return <RoundDoneIcon size="72px" color="success" />;
    case 'expired':
      return <ClockIcon size="72px" color="danger" />;
    case 'pending':
      return <RoundPendingIcon size="72px" color="warning" />;
    case 'error' :
      return <RoundCloseIcon size="72px" color="danger" />;
    default:
      return null;
    }
  }, [iconType]);

  return (
    <Box justify="center" align="center">
      {icon}
      <Box
        margin={{ top: 'spacing24' }}
        pad={{ horizontal: 'spacing32' }}
      >
        <Inscription
          size="26px"
          weight={700}
          textAlign="center"
          lineHeight="36px"
          margin={{ bottom: 'spacing12' }}
        >
          {title}
        </Inscription>

        <Inscription size="16px" textAlign="center" lineHeight="22px">
          {description}
        </Inscription>
      </Box>
    </Box>
  );
};

export default UploadFileByLinkInfoContainer;
