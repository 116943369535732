import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Anchor,
  Box, Checkbox, LinkIcon, Text, } from '@common-fe/common-fe';
import _ from 'lodash';

import { useGetFundIcon } from '@/modules/investments/hooks';
import { InvestmentHoldingStatus } from '@/modules/investments/investments.types';

// import FundPercents from './FundPercents';
import useInstrumentCategoriesQuery from '../../queries/useInstrumentCategories.query';
import { FundsPayload } from '../../SelfDirected.types';

interface Props extends FundsPayload {
  isDelimiter?: boolean;
  checked?: boolean;
  onSelect: (value: string) => void;
}

const FundItem: React.FC<Props> = ({
  logo, title, stockCode, link, yr3, yr5,
  category, assetClass, onSelect, id, checked,
  isDelimiter,status
}) => {
  const { dataMap } = useInstrumentCategoriesQuery();
  const { t } = useTranslation();
  const currentCategory = useMemo(
    () => _.get(dataMap, `${[category]}`, '') as string, [category, dataMap],
  );
  const handleGetFundIcon = useGetFundIcon();
  return (
    <>
      <Box
        data-testid="fund-item"
        direction="row"
        align="center"
        pad={{ vertical: '17px', horizontal: '16px' }}
        background="canvas"
        elevation="default"
        round="container2Round"
        border={{ size: 'small', color: 'border1' }}
        margin={{ top: 'xs' }}
      >
        <Checkbox
          testId="fund-item"
          name="select"
          disabled={status === InvestmentHoldingStatus.Inactive}
          checked={checked}
          onChange={() => onSelect(id)}
        />
        <Box margin={{ left: 's' }}>
          {handleGetFundIcon({
            image: logo,
            status
          })}

        </Box>
        <Box
          margin={{ left: 's' }}
          style={{
            flex: 2,
            position: 'relative',
          }}
          direction="column"

        >
          <Box width="100%">
            <Text tip={title} truncate weight={700}>
              {title}
            </Text>
            <Text>
              {stockCode}
            </Text>
          </Box>
        </Box>
       
        {/* <Box width="60px" margin={{ left: 'l' }}>
          <FundPercents value={yr3} />
        </Box>
        <Box width="60px" margin={{ left: 'l' }}>
          <FundPercents value={yr5} />
        </Box> */}
        <Box width="150px" margin={{ left: 'l' }}>

          <Text
            truncate
          >{currentCategory || '-'}
          </Text>
        </Box>
        <Box width="150px" margin={{ left: 'l' }}>
          <Text>{assetClass || '-'}</Text>
        </Box>

        <Box
          margin={{ left: 's' }}
          style={{
            flex: 2
          }}
          align="end"
          justify='end'
          direction="column"
        >
          {link ? (
            <Anchor
              target="_blank"
              href={link}
            >
              <Box align="center" direction="row" justify="end" style={{ cursor: 'pointer' }}>
                <Text color="textAccent" textAlign="end" size="small" weight={500} margin={{ right: 'xxs' }}>{t('Fund research')}</Text>
                <Box direction="row" align="center" justify="center" width="24px">
                  <LinkIcon size="16px" color="iconAccent" />
                </Box> 
              </Box>
            </Anchor>

          ) : (
            <Box width="26px" />
          )}

        </Box>
      
      </Box>
      {isDelimiter && (
        <Box border={{ size: 'small', side: 'top', color: 'border1' }} margin={{ top: 's', bottom: 'xxs' }} />
      )}
    </>
  );
};
export default FundItem;
