import React, { useMemo } from 'react';
import { Box, EmptyListIcon,Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { CardsMap } from '@/modules/transaction/hooks/useGetCards.query';
import theme from '@/styles/theme';

import { CardDto, CardStatusEnum } from '../Cards.types';

import { CardsSectionWrapper } from './CardSectionWrapper';

const EmptyListWrap = styled(Box)`
  padding: 41px 0;
`;

interface Props {
  cardsByStatus: CardsMap[string];
  holderName: string;
}

export const CardsSection: React.FC<Props> = ({
  cardsByStatus, holderName,
}) => {
  const sortedCards = useMemo(() => Object.keys(cardsByStatus)
    .sort()
    .filter((status) => status !== CardStatusEnum.CLOSED
      && status !== CardStatusEnum.PENDING_CLOSED
      && status !== CardStatusEnum.PENDING_REPLACE
      && status !== CardStatusEnum.STOLEN
      && status !== CardStatusEnum.LOST
      && status !== CardStatusEnum.NOT_SENT), [cardsByStatus]);

  const closedCardByStatus = useMemo(() => Object.keys(cardsByStatus)
    .sort()
    .filter((status) => status !== CardStatusEnum.ACTIVE
      && status !== CardStatusEnum.FROZEN
      && status !== CardStatusEnum.PENDING_FROZEN
      && status !== CardStatusEnum.PENDING_UNLOCK
      && status !== CardStatusEnum.PENDING_OPEN), [cardsByStatus]);

  const closedCards = useMemo(() => (
    closedCardByStatus.reduce((prevValue, currentStatus) => [
      ...prevValue,
      ...cardsByStatus[currentStatus as CardStatusEnum],
    ], [] as CardDto[])
  ), [cardsByStatus, closedCardByStatus]);

  const noCards = useMemo(() => !Object.keys(cardsByStatus).length, [cardsByStatus]);
  return (
    <Box
      direction="column"
      pad={{ bottom: 'spacing12' }}
      // border={{ side: 'bottom' }}
      margin={{ bottom: 'spacing16' }}
      data-testid="Card-section-wrapper"
    >
      {!noCards ? (
        <>
          <Box direction="row" justify="between" margin={{ bottom: theme.spacings.spacing24 }}>
            <Text size="xxlarge" weight="bold">
              {holderName.split('-')[0]}
            </Text>
          </Box>
          <Box background="module" round="moduleRound" pad="spacing16">
            {sortedCards
              .map((status) => (
                <CardsSectionWrapper
                  key={status as React.Key}
                  status={status as CardStatusEnum}
                  cardList={cardsByStatus[status as CardStatusEnum] as CardDto[]}
                />
              ))}

            {
              !!(closedCards || []).length
              && (
                <CardsSectionWrapper
                  expandable
                  status={CardStatusEnum.CLOSED}
                  cardList={closedCards}
                />
              )
            }
          </Box>
        </>
      ) : (
        <>
          <Box direction="row" justify="between" margin={{ bottom: theme.spacings.spacing24 }}>
            <Text size="xxlarge" weight="bold">
              {holderName}
            </Text>
          </Box>
          <Box background="module" round="moduleRound" pad="spacing16" margin={{ bottom: 'spacing24' }}>
            <EmptyListWrap
              justify="center"
              align="center"
              direction="row"
            >
              <Box align="center">
                <EmptyListIcon />

                <Text weight={500} margin={{ top: 'spacing24', bottom: 'spacing12' }} color="textBody" size="xxlarge">
                  There are no cards yet
                </Text>
                <Box direction="row" justify="center" margin={{ bottom: 'spacing24' }}>
                  <Text color="textBody" margin={{ right: 'spacing8' }}>To add a card, click the blue-button above.</Text>
                </Box>
              </Box>
            </EmptyListWrap>
          </Box>
        </>
      )}
    </Box>
  );
};
