import React, { useCallback, useEffect,useState } from 'react';
import { Preloader } from '@common-fe/common-fe';
import { Box } from 'grommet';

import { getBlob } from '@/modules/transaction/hooks/useFileUploadQuery';

interface Props {
  src?: string;
  url?: string;
}

const Embed: React.FC<Props> = ({ src, url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState('');

  const getPreview = useCallback(async () => {
    if (!isLoading && !preview && url) {
      setIsLoading(true);
      const blob = await getBlob(url);

      setPreview(URL.createObjectURL(blob));
      setIsLoading(false);
    }
  }, [isLoading, preview, url]);

  useEffect(() => {
    getPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (src) return (<embed src={src} height="100%" width="100%" />);

  if (!url) return null;

  if (isLoading) {
    return (
      <Box height="60px" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return (
    <embed src={preview} height="100%" width="100%" />
  );
};

export default Embed;
