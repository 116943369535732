import { useMemo } from 'react';
import _ from 'lodash';

export const LOCAL_STORAGE_KEY = 'BANNER_ORDER_MEMO';

export default (employeeId?: string, ids?: number[]) => {
  const parsedMap = useMemo(() => {
    try {
      const storageMap = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (storageMap) {
        const parsedStorageMap = JSON.parse(storageMap || '');
        return parsedStorageMap;
      }
    } catch {
      return {};
      /* empty */
    }
    return {};
  }, []);

  const newOrder = useMemo(() => {
    try {
      if (employeeId) {
        const currentIds = parsedMap[employeeId] as number[];
        let newIds: number[] = ids || [];
        if (currentIds) {
          newIds = currentIds;
          const differenceIds = _.difference(ids, currentIds);

          newIds = [...differenceIds, ...newIds];
        }
        parsedMap[employeeId] = newIds;
        const formattedPayload = JSON.stringify(parsedMap);
        localStorage.setItem(LOCAL_STORAGE_KEY, formattedPayload);
        return newIds;
      }
    } catch {
      return null;
    }
  }, [employeeId, ids, parsedMap]);
  return newOrder;
};
