import { useEffect } from 'react';

import { LINKED_EMPLOYEE_STORAGE_KEY } from '@/common/constants';
import { useOrphanModeStore } from '@/modules/core/store';
import Token from '@/utils/handlers/Token';

import useEmployeeViewDataQuery from '../queries/useEmployeeViewData.query';
import useOrganizationViewDataQuery from '../queries/useOrganizationViewData.query';
import { useAuthStore } from '../stores';
import { EmployeeView, OrganizationView,UserModel } from '../user.types';

export const getTokenData = () => {
  const decodedToken = Token.getDecodedToken();

  if (!decodedToken) return {};

  const permissions = JSON.parse(decodedToken['custom:permissions'] || '[]');

  const role = decodedToken['custom:role'];
  const personId = decodedToken['custom:person_id'];
  const employeeId = decodedToken['custom:employee_id'];
  const organizationId = decodedToken['custom:organization_id'];
  const linkedEmployees = decodedToken['custom:linked_employees'];

  return {
    permissions,
    role,
    personId,
    employeeId,
    organizationId,
    linkedEmployees,
  };
};

const getUserWithTokenData = (
  employee?: EmployeeView,
  organization?: OrganizationView,
  personId?: string,
): UserModel | null => {
  if (!employee
    || !employee.hireOn
    || !organization
    || !organization?.id
    || !organization?.type) return null;
  const decodedToken = Token.getDecodedToken();
  if (!decodedToken) return null;

  const {
    permissions, role, linkedEmployees,
  } = getTokenData();

  return {
    id: personId,
    preferredName: employee?.preferredName,
    firstName: employee?.firstName,
    lastName: employee?.lastName,
    email: {
      address: employee.preferredEmail,
    },
    preferredEmail: employee.preferredEmail,

    employee: {
      id: employee.id,
      personId,
      organizationPath: organization?.path,
      organizationId: organization.id,
      hireOn: employee.hireOn,
      claimAutopayType: employee.claimAutopayType,
      defaultClaimPayeeType: employee.defaultClaimPayeeType,
      employeeProvidedEmail: employee.employeeProvidedEmail,
      employerProvidedEmail: employee.employerProvidedEmail,
      isElectronicStatementOnly: employee.isElectronicStatementOnly,
      isEmployeeEmailPreferred: employee.isEmployeeEmailPreferred,
    },
    role,
    terminationDate: employee.terminationDate,
    isSsoUser: employee?.isSsoUser,
    permissions,
    organization: {
      id: organization?.id,
      type: organization?.type,
      path: organization?.path,
      communicationChannels: organization?.communicationChannels,
      sessionTimeoutLogout: organization?.sessionTimeoutLogout,
      sessionTimeoutWarn: organization?.sessionTimeoutWarn,
    },
    linkedEmployees,
  };
};

export const useAuth = () => {
  const store = useAuthStore();
  const {
    linkedEmployee, handleReset: handleResetOrphanMode, setLinkedEmployee,
  } = useOrphanModeStore();
  const { formattedData: employee } = useEmployeeViewDataQuery(store.employeeId);
  const {
    formattedData: organization,
  } = useOrganizationViewDataQuery(store.organizationId);
  const userData = getUserWithTokenData(
    employee, organization, linkedEmployee?.personId || store.personId,
  );
  useEffect(() => {
    const linkedEmployeeFromLocalStorage = localStorage.getItem(LINKED_EMPLOYEE_STORAGE_KEY);

    if (linkedEmployeeFromLocalStorage && !linkedEmployee) {
      const parsedLinkedEmployee = JSON.parse(linkedEmployeeFromLocalStorage);
      setLinkedEmployee(parsedLinkedEmployee);
    }
  }, [setLinkedEmployee, linkedEmployee]);

  useEffect(() => {
    if (!store.auth && store.user) {
      store.setUser(null);
      store.setIds();
      handleResetOrphanMode();
    }
  }, [store, handleResetOrphanMode]);

  useEffect(() => {
    if (!linkedEmployee?.employeeId && userData && store.user?.id !== userData?.id) {
      store.setUser(userData);
    }
    if (linkedEmployee?.employeeId
      && userData && store.user?.employee?.id !== linkedEmployee?.employeeId) {
      store.setUser(userData);
    }
  }, [store, userData, linkedEmployee]);

  useEffect(() => {
    if (!store.inited) {
      store.setAuth(Token.isAuthenticated());
    }
  }, [store]);

  useEffect(() => {
    if (store.auth
      && !store.employeeId
      && !store.organizationId) {
      const { employeeId, organizationId, personId } = getTokenData();

      if (employeeId
        && organizationId
        && personId) store.setIds(employeeId, organizationId, personId);
    }
  }, [store]);
};

export default useAuth;
