import React from 'react';
import { Box } from '@common-fe/common-fe';

import { AccountContribution } from '@/modules/transaction/components/Contributions/AccountContribution';
import theme from '@/styles/theme';

const AccountContributionPage = () => {
  return (
    <Box width="100%">
      <Box width={theme.defaultContentWidth} margin={{ horizontal: 'auto' }} pad={{ top: 'l' }}>
        <AccountContribution />
      </Box>
    </Box>

  );
};

export default AccountContributionPage;
