import React from 'react';

import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';
import UploadFileByLinkInfoContainer from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkInfoContainer';

const SuccessScreen = () => {
  return (
    <UploadFileByLinkContentWrapper>
      <UploadFileByLinkInfoContainer
        title="Your claim has been approved!"
        description="Your payment is on the way."
        iconType="success"
      />
    </UploadFileByLinkContentWrapper>
  );
};

export default SuccessScreen;
