import React, { useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import AppButton from '@/components/controls/AppButton';
import TransactionStatusItem from '@/modules/transaction/components/TransactionDetails/TransactionStatusItem';
import { Status, TransactionStatus } from '@/modules/transaction/transaction.types';

import {
  CoveredBlock,
  Title,   Wrapper, } from './TransactionStatuses.styles';

const prepareStatuses = (statuses: Status[]) => [
  ...statuses.filter((item) => item.status !== TransactionStatus.AppliedToDeductible),
  ...statuses.filter((item) => item.status === TransactionStatus.AppliedToDeductible)?.slice(-1) || [],
].sort((a, b) => dayjs(b.validFrom).valueOf() - dayjs(a.validFrom).valueOf());

interface Props {
  statuses: Status[];
}
const MAX_LEN = 3;

const TransactionStatuses: React.FC<Props> = (props) => {
  const statuses = useMemo(() => prepareStatuses(props.statuses), [props.statuses]);
  const { t } = useTranslation();
  const [shoMore, setShowMore] = useState(false);

  return (
    <Wrapper
      className="transaction-statuses"
      data-testid="TransactionStatuses-wrapper"
      border={{ size: 'small', color: 'border2' }}
      elevation="default"
    >
      <Box className={`${(statuses?.length > MAX_LEN) && shoMore ? 'full-height' : 'fixed-height'}`} pad={{ horizontal: 'spacing24', top: 'spacing20' }}>
        <Title>
          {t('Tracker')}
        </Title>
        {
          statuses?.slice(0, shoMore ? (statuses?.length || 0) : MAX_LEN + 1)
            .map((item: Status, index: number) => (
              <TransactionStatusItem
                key={Math.random()}
                statusDate={item.validFrom}
                status={item.status}
                statuses={statuses}
                index={index}
              />
            ))
        }
      </Box>
      {
        (Boolean(statuses?.length > MAX_LEN) && !shoMore)
        && (
          <CoveredBlock />
        )
      }
      {
        (Boolean(statuses?.length > MAX_LEN))
      && (
        <Box>
          <AppButton
            onClick={() => setShowMore(!shoMore)}
            width="100%"
            className="transaction-statuses-button"
            testId="TransactionStatusesShowMore-id"
          >
            {`${shoMore ? t('Hide') : `${t('Show more')} (${statuses?.length - MAX_LEN})`}`}
          </AppButton>
        </Box>
      )
      }
    </Wrapper>
  );
};
export default TransactionStatuses;
