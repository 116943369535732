import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { InvestmentPaths } from '../../investments.types';
import SelfDIrectedWrapper from '../SelfDirectedWrapper';

import QuestionnaireFields from './QuestionnaireFields';

interface Props {
  accountId: string;
  investorId: string;
}
const Questionnaire: React.FC<Props> = ({ accountId, investorId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleNext = useCallback(() => {
    history.push(`${ROUTES.SELF_DIRECTED(accountId)}?investorId=${investorId}`);
  }, [accountId, history, investorId]);
  const handleBack = useCallback(() => {
    history.push(`${ROUTES.START_INVESTING}?accountId=${accountId}&investorId=${investorId}`);
  }, [accountId, history, investorId]);

  return (
    <SelfDIrectedWrapper currentStep={2}>
      <Box direction="column">
        <Text size="3xl" weight={700}>
          {t('Portfolio Questionnaire')}
        </Text>
        <Box
          justify="start"
          align="start"
          direction="row"
          margin={{ top: 'l' }}
          style={{ position: 'relative' }}
        >
          <Text size="20px" weight={700}>
            {t('Personal Information')}
          </Text>
        </Box>

        <QuestionnaireFields investmentType={InvestmentPaths.SelfDirected} onSubmit={handleNext} onBack={handleBack} />
      </Box>
    </SelfDIrectedWrapper>
  );
};

export default Questionnaire;
