import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';

import {
  IrsMaximums,
  IrsMaximumsCoverageTypes,
  IrsMaximumsDto,
} from '../transaction.types';

interface IrsMaximumsParams {
  coverageLevel?: IrsMaximumsCoverageTypes;
  years?: number[];
  type?: string;
}

export const QUERY_KEY = 'useGetIrsMaximumsQueryKey';
export const useGetIrsMaximums = (
  values?: IrsMaximumsParams,
) => {
  const params = useMemo(() => {
    if (!values) return undefined;

    const queryGenerator = new URLSearchParams();

    if (values?.coverageLevel) {
      queryGenerator.set('coverage_level', values.coverageLevel);
    }

    if (values?.type) {
      queryGenerator.set('type', `${values?.type}_MAX`);
    }

    if (values?.years) {
      values.years.forEach((year) => {
        queryGenerator.append('tax_year', year.toString());
      });
    }

    return queryGenerator;
  }, [values]);

  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY, values],
    () => api.get<IrsMaximumsDto[]>(PATHS.IRS_MAXIMUMS, { params }),
  );

  const formattedData: IrsMaximums[] = useMemo(() => {
    if (!data?.data) return [];

    return data.data.map((item) => ({
      id: item?.id,
      type: item?.type,
      taxYear: item.tax_year.toString(),
      amount: item?.amount,
      frequencyType: item?.frequency_type,
      irsCoverageLevelType: item?.irs_coverage_level_type,
      taxYearEnd: item?.tax_year_end,
    }));
  }, [data]);

  const lastDayForContribution = useMemo(() => {
    if (!data?.data) return undefined;

    return data.data
      .find((item) => item?.tax_year === dayjs().year() - 1)?.tax_year_end;
  }, [data]);

  return {
    lastDayForContribution,
    formattedData,
    isLoading,
    data: data?.data,
    isSuccess,
  };
};
