import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes, formatSnakeCaseToGeneral } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { REQUIRED_TEXT } from '@/common/constants';
import { capitalizeFirstLetter, isDateInFuture } from '@/utils';

import { Patient } from '../usePatients.query';
import { RelationshipType } from '../useRelationshipsTypes.query';

const MAX_LENGTH_ERROR_TEXT = 'The size should not exceed 255 characters';

enum PatientFields {
  firstName = 'firstName',
  lastName = 'lastName',
  dateOfBirth = 'birthOn',
  relationship = 'relationshipType',
}

interface Props {
  relationshipTypes: RelationshipType[],
  patientName?: string,
  defaultValue?: Partial<Patient>,
}

const usePatientFields = ({ relationshipTypes, defaultValue, patientName }: Props) => {
  const { t } = useTranslation();
  const relationshipTypesOptions = useMemo(() => relationshipTypes.map(
    (relationshipType: RelationshipType) => ({
      key: relationshipType.name,
      value: capitalizeFirstLetter(relationshipType.name).replace('_', ' '),
    }),
  ), [relationshipTypes]);

  const patient = useMemo(() => {
    if (!patientName) return { firstName: '', lastName: '' };
    const [firstName = '', lastName = ''] = patientName.split(' ');
    return {
      firstName,
      lastName,
    };
  }, [patientName]);

  return [
    {
      name: PatientFields.firstName,
      type: FieldTypes.Text,
      label: 'First name',
      placeholder: 'Enter first name',
      validator: yup.string().required(REQUIRED_TEXT).max(255, MAX_LENGTH_ERROR_TEXT),
      value: formatSnakeCaseToGeneral(patient.firstName),
      isWide: true,
      ...defaultValue?.firstName ? { defaultValue: defaultValue?.firstName } : {},
    },
    {
      name: PatientFields.lastName,
      type: FieldTypes.Text,
      label: 'Last name',
      placeholder: 'Enter last name',
      validator: yup.string().required(REQUIRED_TEXT).max(255, MAX_LENGTH_ERROR_TEXT),
      value: formatSnakeCaseToGeneral(patient.lastName),
      isWide: true,
      ...defaultValue?.lastName ? { defaultValue: defaultValue?.lastName } : {},
    },
    {
      name: PatientFields.dateOfBirth,
      type: FieldTypes.Date,
      isManualDateInput: true,
      selectDateWithoutApply: true,
      label: 'Date of Birth',
      placeholder: 'Select birth date',
      validator: yup.string().required(REQUIRED_TEXT).test(
        'date',
        t('Date cannot be in the future'), (date) => !isDateInFuture(date),
      ),
      className: 'common',
      isCalendar: true,
      maxDate: dayjs(),
      ...defaultValue?.birthOn ? { defaultValue: dayjs(defaultValue?.birthOn).format(DEFAULT_DATE_FORMAT) } : {},
    },
    {
      name: PatientFields.relationship,
      type: FieldTypes.Dropdown,
      singleMode: true,
      label: 'Relationship',
      placeholder: 'Select relationship',
      options: relationshipTypesOptions,
      validator: yup.string().required(REQUIRED_TEXT),
      ...defaultValue?.relationshipType ? { defaultValue: defaultValue?.relationshipType } : {},
    },
  ] as Field[];
};

export default usePatientFields;
