import React, { useCallback } from 'react';
import {
  Box,
  Field,
  FlexControlledForm,
  Text,
  WarningLabel,
} from '@common-fe/common-fe';

import { OneTimeContributionFormValues } from '../contribution.types';
// import StatusWrapper from '@/modules/core/components/StatusWrapper';
import { useMakeOneTimeContributionFields } from '../hooks/useMakeOneTimeContributionFields';
import { useStore as useWarningStore } from '../store/useApplyContributionRolloverModal.store';

import { ApplyContributionRolloverModal } from './ApplyContributionRolloverModal';

interface Props {
  handleChangeValues: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,) => void;
  isSubmit: boolean;
  lastDayForContribution?: string;
  rollover: boolean;
  setRollover: (value: boolean) => void;
  setInfoSetup: (value: boolean) => void;
  formValues: OneTimeContributionFormValues;
  minimumContributionAmount?: number;
  maximumContributionAmount?: number;
}

export const MakeOneTimeContributionForm: React.FC<Props> = ({
  handleChangeValues,
  isSubmit,
  lastDayForContribution,
  rollover,
  setRollover,
  setInfoSetup,
  formValues,
  minimumContributionAmount,
  maximumContributionAmount,
}) => {
  const handleSetVisibleRolloverModal = useWarningStore((store) => store.handleChangeVisibility);
  const setApplyRollover = useWarningStore((store) => store.setAdditionalAction);
  const handleSetVisibleRollover = useCallback(() => {
    if (!rollover) {
      setApplyRollover(() => {
        setRollover(true);
        handleSetVisibleRolloverModal(false);
      });
      handleSetVisibleRolloverModal(true);
    }
    setRollover(false);
  }, [handleSetVisibleRolloverModal, rollover, setApplyRollover, setRollover]);

  const infoFields = useMakeOneTimeContributionFields({
    lastDayForContribution,
    rollover,
    setRollover: handleSetVisibleRollover,
    formValues,
    minimumContributionAmount,
    maximumContributionAmount,
  });
  
  const handleChangeOneTimeValues = useCallback((values: object) => {
    handleChangeValues(values, infoFields, setInfoSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoFields]);

  return (
    <Box data-testid="contribution-info-form" round="container1Round" background="canvas">
      <FlexControlledForm
        fields={infoFields}
        editMode
        showError={isSubmit}
        onChangeValues={handleChangeOneTimeValues}
        isModalType
        formStyle={{ marginBottom: rollover ? 0 : undefined }}
        footer={rollover && (
          <Box margin={{ top: 'spacing16' }} pad={{ horizontal: 'spacing24', bottom: 'spacing24' }}>
            <Box
              pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
              background={{ color: 'warningContainer' }}
              border={{ size: '1px', color: 'warningBorder' }}
              round="fieldRound"
            >
              <Text weight="bold" margin={{ bottom: 'spacing8' }} color="textBody">
                <WarningLabel>
                  Rollover
                </WarningLabel>
              </Text>

              <Box>
                <Text
                  data-testId="contribution-rollover-warning-description"
                  margin={{ bottom: 'spacing8' }}
                  color="textBody"
                  size="14px"
                >
                  I can rollover funds from Archer MSAs and other
                  HSAs into an HSA within 60 days of receipt.
                  One rollover contribution to an HSA/MSA is allowed per year.
                  Rollover isn't counted in my income, isn't deductible,
                  and doesn't impact my contribution limit.
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      />
      <ApplyContributionRolloverModal />
    </Box>
  );
};
