import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FlexForm,Preloader } from '@common-fe/common-fe';
import { toString } from 'lodash';

import AppButton from '@/components/controls/AppButton';
import { useSnackbar } from '@/modules/core/hooks';
import useStatesQuery, { State } from '@/modules/core/queries/useStates.query';
import useAddPaymentMethodQuery from '@/modules/transaction/components/ReimburseMe/queries/useAddPaymentMethod.query';
import { useUpdatePaymentMethodQuery } from '@/modules/transaction/components/ReimburseMe/queries/useUpdatePaymentMethod.query';
import { useClaimInfoStore } from '@/modules/transaction/components/ReimburseMe/store/useClaimInfo.store';
import { PaymentMethod,PaymentMethodOwnerType } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import theme from '@/styles/theme';

import { Provider, useCreateProviderQuery, useUpdateProviderQuery } from '../useProviders.query';

import useProviderStore from './useProvider.store';
import useProviderFields, { PresetFields } from './useProviderFields';

interface ProviderFormData {
  adressLine1?: string;
  adressLine2?: string;
  city?: string;
  name?: string;
  state?: string;
  zipCode?: string;
}

const formatFormData = (data: ProviderFormData, states: State[]): Provider => ({
  name: data?.name || '',
  address: {
    line1: data?.adressLine1,
    line2: data?.adressLine2,
    city: data?.city,
    zipcode: data?.zipCode,
    state: {
      ...states.find((state) => state.id === data?.state),
    },
  },
});

interface Props {
  onCancel: () => void,
  refetch?: () => void,
  presetFields?: PresetFields,
  provider?: Provider,
  paymentMethod?: PaymentMethod,
}

const AddProviderForm: React.FC<Props> = ({
  onCancel, presetFields, provider, paymentMethod, refetch,
}) => {
  const { t } = useTranslation();
  const { setProviderId } = useProviderStore();
  const { handleSetProviderId } = useClaimInfoStore();
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { updatePaymentMethod } = useUpdatePaymentMethodQuery();
  const { save: createPaymentMethod } = useAddPaymentMethodQuery();
  const { save, isLoading } = useCreateProviderQuery((newlyCreatedProvider?: Provider) => {
    const providerId = toString(newlyCreatedProvider?.id);
    onCancel();
    handleAddPermanentSnackbar({
      text: `${newlyCreatedProvider?.name} ${t('added successfully.')}`,
      closeIcon: true,
    });
    createPaymentMethod({
      paymentType: PaymentMethodType.CHECK,
      paymentOwnerType: PaymentMethodOwnerType.PROVIDER,
      provider: newlyCreatedProvider,
    });
    if (providerId) {
      setProviderId(providerId);
      handleSetProviderId(providerId);
    }
  });
  const onSuccessfullyUpdateProvider = useCallback((updatedProvider: Provider) => {
    if (paymentMethod?.id && paymentMethod?.type && updatedProvider?.address) {
      updatePaymentMethod({
        paymentMethodId: paymentMethod.id,
        paymentType: paymentMethod.type,
        address: updatedProvider.address,
      });
    } else if (updatedProvider?.address) {
      createPaymentMethod({
        paymentType: PaymentMethodType.CHECK,
        paymentOwnerType: PaymentMethodOwnerType.PROVIDER,
        provider: updatedProvider,
      });
    }
  }, [updatePaymentMethod, paymentMethod, createPaymentMethod]);
  const {
    updateProvider, isLoading: isProviderLoading,
  } = useUpdateProviderQuery(onSuccessfullyUpdateProvider);
  const { states } = useStatesQuery();
  const fields = useProviderFields(states, presetFields, provider);

  return (
    <FlexForm
      fields={fields}
      contentStyles={theme.formFieldsEdging}
      paddingless
      isNarrowLabelMode
      editMode
      onSubmit={async (data) => {
        const formData = formatFormData(data, states);

        if (provider?.id) {
          await updateProvider({ ...formData, id: provider?.id });
          onCancel();
          return;
        }

        await save(formData);
        if (refetch) refetch();
      }}
      footer={(
        <Box direction="row" justify="end" align="center" pad={{ top: '40px' }}>
          <AppButton
            buttonType="secondary"
            containerStyle={{ marginRight: theme.spacings.xs }}
            type="button"
            width="130px"
            onClick={onCancel}
          >
            {t('Cancel')}
          </AppButton>
          <AppButton
            type="submit"
            width="130px"
          >
            {isLoading || isProviderLoading ? <Preloader color="white" /> : t('Save')}
          </AppButton>
        </Box>
      )}
    />
  );
};

export default AddProviderForm;
