import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { Box, Preloader } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { StepperSidebar } from '@/components/elements';
import { Topbar } from '@/modules/core/components';
import { useBreadcrumbs, useHistory } from '@/modules/core/hooks';
import { useMyEnrollments } from '@/modules/transaction';
import theme from '@/styles/theme';
import { useQueryParams } from '@/utils/hooks';

import { useInvestorById } from '../hooks';
import PortfolioQuestionnaire from '../Managed/PortfolioQuestionnaire';

import PortfolioAllocation from './PortfolioAllocation';

const ACCOUNT_ID_QUERY_KEY = 'accountId';
const INVESTOR_ID_QUERY_KEY = 'investorId';

enum STEPS {
  PORTFOLIO_ALLOCATION = 1,
  RETAKE_QUIZ = 2,
}

export const UpdateRiskPortfolio = React.memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQueryParams();
  const accountId = useMemo(() => query.get(ACCOUNT_ID_QUERY_KEY), [query]);
  const investorId = useMemo(() => query.get(INVESTOR_ID_QUERY_KEY), [
    query,
  ]) as string;
  const [step, setStep] = useState(STEPS.PORTFOLIO_ALLOCATION);
  const { currentMyAccount } = useMyEnrollments(accountId);

  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId as string),
    title: '...',
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId as string)}`,
    additionalValue: currentMyAccount?.name,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Investments'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  },
  {
    key: ROUTES.INVESTMENTS_UPDATE_RISK_PORTFOLIO,
    title: step === STEPS.PORTFOLIO_ALLOCATION
      ? t('Update Your Risk Profile')
      : t('Portfolio Questionnaire'),
    value: ROUTES.INVESTMENTS_UPDATE_RISK_PORTFOLIO,
  }], true);

  const { data: investor, isLoading } = useInvestorById();
  const currentRisk = useMemo(() => investor?.toleranceModel, [investor]);

  const [proposedToleranceModel, setProposedToleranceModel] = useState('');
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [isAllowRedirect, setIsAllowRedirect] = useState(true);

  const OPTIONS = useMemo(
    () => step === STEPS.PORTFOLIO_ALLOCATION
      ? [{ key: 1, value: t('Portfolio Allocation') }]
      : [{ key: 2, value: t('Portfolio Questionnaire') }],
    [step, t],
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (investor?.balance) {
      setInvestmentAmount(investor?.balance);
    }
  }, [setStep, investor]);

  useEffect(() => {
    if (!accountId || !investorId) {
      handleBack();
    }
  }, [handleBack, accountId, investorId]);

  return (
    <Box
      direction="row"
      flex="grow"
      data-testid="UpdateRiskPortfolio-wrapper"
      style={{ overflow: 'hidden' }}
    >
      <Prompt
        when={!isAllowRedirect}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <StepperSidebar
        title={step === STEPS.PORTFOLIO_ALLOCATION
          ? t('Update Your Risk Profile')
          : t('Start Investing')}
        options={OPTIONS}
        step={step}
      />
      {isLoading ? <Preloader /> : (
        <Box
          direction="row"
          justify="center"
          flex="grow"
          overflow={{ vertical: 'auto' }}
          height={{
            min: '100vh',
          }}
          style={{
            display: 'block',
            position: 'relative',
          }}
        >
          <Box direction="row" justify="center">
            <Box
              flex="grow"
              pad={{ horizontal: '40px' }}
              margin={{ bottom: 'l' }}
            >
              <Topbar />
            </Box>
          </Box>
          <Box direction="row" justify="center">
            <Box
              width={{ max: theme.defaultContentWidth }}
              flex
            >
              {step === STEPS.PORTFOLIO_ALLOCATION && (
                <PortfolioAllocation
                  handleRetakeQuiz={() => setStep(STEPS.RETAKE_QUIZ)}
                  handleGoDashboard={() => history.push(`${ROUTES.INVESTMENTS}?id=${accountId}`)}
                  onBack={handleBack}
                  setIsAllowRedirect={setIsAllowRedirect}
                  recommendedRisk={proposedToleranceModel}
                  currentRisk={currentRisk}
                  investmentAmount={investmentAmount}
                  investorId={investorId}
                />
              )}
              {step === 2 && (
                <PortfolioQuestionnaire
                  onNext={() => setStep(STEPS.PORTFOLIO_ALLOCATION)}
                  setProposedToleranceModel={setProposedToleranceModel}
                  step={step}
                  stepLength={OPTIONS?.length}
                  submitButtonLabel={t('Submit')}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}

    </Box>
  );
});
