import React from 'react';
import {
  Box, CardNotFoundIcon,
  Heading, Text, } from '@common-fe/common-fe';

export const EmptyCardListPlaceholder: React.FC = () => (
  <Box data-testid="EmptyCardListPlaceholder" background="module" round="moduleRound" pad={{ vertical: 'spacing32' }}>
    <Box align="center">
      <Box margin={{ bottom: 'spacing32' }}>
        <CardNotFoundIcon size="xxlarge" />
      </Box>
      <Heading data-testid="EmptyCardListPlaceholder-title" margin={{ bottom: 'spacing12' }} size="21px" style={{ fontWeight: 500 }}>
        There are no cards yet
      </Heading>
      <Text>
        To add a card, click the blue-button above.
      </Text>
    </Box>
  </Box>
);
