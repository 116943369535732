import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  capitalizeFirstLetter,
  Preloader,
  RoundPendingIcon,
  Text,
} from '@common-fe/common-fe';

import regexp from '@/common/regexp';
import { ModalWrapper } from '@/components';

const DESCRIPTION_TEXT = `Updating your risk profile will take 1 to 2 business day to reflect in your portfolios fund allocations.
 You will not be able to update your risk profile again until these transactions are complete.`;

const ALTERNATIVE_DESCRIPTION_TEXT = `If you intentionally selected this model and wish to keep your portfolio assets unchanged,
 click Confirm. However, if it was unintentional, click Cancel to select another model.`;

interface Props {
  visible: boolean;
  oldRiskModel: string;
  newRiskModel: string;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  isLoading?: boolean;
}

export const ConfirmUpdateRiskPortfolioModal: React.FC<Props> = ({
  visible,
  oldRiskModel,
  newRiskModel,
  onSetVisible,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const preparedOldRiskModel = useMemo(() => oldRiskModel
  && oldRiskModel.split(regexp.DASH_SYMBOL).map((item) => capitalizeFirstLetter(item)).join(' '),
  [oldRiskModel]);
  const preparedNewRiskModel = useMemo(() => newRiskModel
  && newRiskModel.split(regexp.DASH_SYMBOL).map((item) => capitalizeFirstLetter(item)).join(' '),
  [newRiskModel]);
  const headerText = useMemo(() => `Update from ${preparedOldRiskModel} to ${preparedNewRiskModel}?`, [preparedOldRiskModel, preparedNewRiskModel]);

  const descriptionText = useMemo(() => oldRiskModel === newRiskModel
    ? ALTERNATIVE_DESCRIPTION_TEXT
    : DESCRIPTION_TEXT,
  [oldRiskModel, newRiskModel]);

  return (
    <ModalWrapper testId="ConfirmUpdateRiskPortfolioModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24' }}>
        <RoundPendingIcon color="warning" />
        <Text
          data-testid="ConfirmUpdateRiskPortfolioModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {headerText}
        </Text>
        <Text
          data-testid="ConfirmUpdateRiskPortfolioModal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {descriptionText}
        </Text>

        <Box direction="row" gap="spacing12">
          <AppButton
            buttonType="secondary"
            testId="ConfirmUpdateRiskPortfolioModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            width="140px"
          >
            {t('Cancel')}
          </AppButton>
          <AppButton
            testId="ConfirmUpdateRiskPortfolioModal_modal_submit"
            onClick={onSubmit}
            disabled={isLoading}
            width="140px"
          >
            {isLoading
              ? <Preloader />
              : t('Confirm')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
