import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { onlyDateToServer } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RAW_DATE_FORMAT } from '@/common/constants';

interface Body {
  birthOn: string;
  uniqueId: string;
}

interface RegistrationVerificationPayload {
  employee_id?: number;
}


const useRegistrationVerificationQuery = () => {
  const { mutateAsync, isLoading } = useMutation((body: Body) => 
    api.post(PATHS.REGISTRATION_VERIFICATION, {
      local_date_birth_on: dayjs(body.birthOn).format(RAW_DATE_FORMAT),
      unique_id: body.uniqueId,
    })
  );

  const registrationVerification = useCallback(async (body: Body) => {
    const { data } = await mutateAsync(body);
    return data as RegistrationVerificationPayload;
  }, [mutateAsync]);

  return {
    registrationVerification,
    isLoading,
  };
};

export default useRegistrationVerificationQuery;
