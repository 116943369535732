import React from 'react';
import { useTranslation } from 'react-i18next';
import { Inscription, WarnModal } from '@common-fe/common-fe';

interface Props {
  lockingLeft?: number;   
  onSetVisible: (value: boolean) => void;
}

const AccountLockoutModal: React.FC<Props> = ({ lockingLeft, onSetVisible }) => {
  const { t } = useTranslation();

  return (
    <WarnModal
      visible
      onSetVisible={onSetVisible}
      header={t('Account Was Locked')}
    >
      <Inscription margin={{ top: 'spacing12' }}>
        {t(`Your account was locked due to big amount of login attempts. Try again in ${lockingLeft} minutes.`)}
      </Inscription>
    </WarnModal>
  );
};

export default AccountLockoutModal;
