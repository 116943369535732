import { storeCreator } from '@/utils/store/store.utils';

interface State {
  setPatientId: (id: string) => void;
  handleReset: () => void;
  id: string;
}

const useStore = storeCreator<State>((set) => ({
  id: '',
  setPatientId: (id: string) => set(() => ({ id })),
  handleReset: () => set(() => ({ id: '' })),
}));

const usePatientStore = () => {
  const { setPatientId, handleReset, id } = useStore();

  return {
    createdPatientId: id,
    setPatientId,
    handleReset,
  };
};

export default usePatientStore;
