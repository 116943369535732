import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Preloader, WarnModal } from '@common-fe/common-fe';
import _ from 'lodash';

import { ValuesMap } from '@/common/types';
import { useHistory } from '@/modules/core/hooks';

import { AnswersStatus } from '../cip.constants';
import { useCIPInfo } from '../hooks';

import { useQuestionnaireTimer } from './QuestionnaireTimer/hooks';
import { TOTAL_TIMER_DURATION } from './QuestionnaireTimer/hooks/useQuestionnaireTimer';
import { useQuestionnaireFields } from './hooks';
import { useCIPStatusUpdateQuery, useQuestionnaireAnswersQuery } from './queries';
import QuestionnaireFooter from './QuestionnaireFooter';
import QuestionnaireTimer from './QuestionnaireTimer';
import QuestionaireWrapper from './QuestionnaireWrapper';
import QuestionsList from './QuestionsList';
import TimeoutBanner from './TimeoutBanner';

const NUMBER_OF_ATTEMPTS = 1;

const Questionaire = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isOnceTried, setOnceTried] = useState(false);
  const [
    attempts,
    // setAttempts,
  ] = useState(NUMBER_OF_ATTEMPTS);
  const [isErrorModal, setErrorModal] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isServerErrorModal, setServerErrorModal] = useState(false);
  const { handleVerify, handleFailedCip } = useCIPInfo({

  });
  const [isErrorShowed, setErrorShowed] = useState(false);
  const {
    data: questionsFields, handleGetChallenge, idNumber, handleRefetch, isLoading: isFieldsLoading,
  } = useQuestionnaireFields();
  const history = useHistory();
  const [values, setValues] = useState<ValuesMap<string>>({});

  const { save, isLoading: isAnswersLoading } = useQuestionnaireAnswersQuery();
  const {invalidateQuery } = useCIPStatusUpdateQuery();
  const handleChangeValues = useCallback((newValues: ValuesMap<string>) => {
    setValues({
      ...values,
      ...newValues,
    });
  }, [values]);
  const handleLater = useCallback(() => {
    history.goBack();
  }, [history]);
  const { formattedTime, isTimeOver, setTimerDuration } = useQuestionnaireTimer();
  const isValidationError = useMemo(
    () => Object.keys(values).length !== questionsFields?.length,
    [questionsFields, values],
  );
  const handleRetry = useCallback(async () => {
    // setAttempts(attempts - 1);
    setIsLoading(true);
    setValues({});
    setTimerDuration(TOTAL_TIMER_DURATION);
    try {
      await handleRefetch();
    } finally {
      setIsLoading(false);
    }
  }, [handleRefetch, setTimerDuration]);

  const handleNext = useCallback(async () => {
    setErrorShowed(false);

    if (isValidationError) {
      setErrorShowed(true);

      return;
    }
    try {
      const formattedFields = questionsFields.reduce((result, field, index) => {
        const currentValue = values[field.key];

        return {
          ...result,
          [`question${index + 1}_type`]: field.key,
          [`question${index + 1}_answer`]: currentValue,
        };
      }, {

        // id_number: `${idNumber}`,
      });

      const result = await save({
        values: formattedFields,
        isChallengeMode: isOnceTried,
      });
      const resultStatus = _.toLower(_.get(result, 'data.status'));

      const idScanUrl = _.get(result, 'data.id_scan_url', '') as string;
      setValues({});
      if (idScanUrl) {
        handleVerify(idScanUrl);
        return;
      }
      if (!resultStatus) {
        setErrorModal(true);
        return;
      }
  
      if (resultStatus === AnswersStatus.Challenge) {
        setErrorShowed(false);
        setOnceTried(true);
        handleGetChallenge();
      }

      if (resultStatus === AnswersStatus.Fail) {
        // setAttempts(attempts - 1);
        // if (attempts <= 0) {
        //   setErrorModal(true);
        //   return;
        // }
        if (attempts > 0) {
          handleRetry();
          return;
        }
      }
      if ((resultStatus === AnswersStatus.Pass || resultStatus === AnswersStatus.Success)) {
        // setErrorShowed(true);
        invalidateQuery();   
        setSuccessModal(true);
      }
    } catch (e) {
      setValues({});
      setServerErrorModal(true);
      // handleRetry();
    }
  }, [attempts, handleGetChallenge, handleRetry,
    handleVerify, invalidateQuery, 
    isOnceTried, isValidationError, questionsFields, save, values]);
  const handleRedirect = useCallback(() => {
    history.push('/');
    setSuccessModal(false);
    setErrorModal(false);
  }, [history]);
  useEffect(() => {
    if (isTimeOver && attempts <= 0) {
      setErrorModal(true);
    }
  }, [attempts, isTimeOver]);
  useEffect(() => {
    if (isErrorModal) {
      handleFailedCip();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorModal]);
  if (!questionsFields) {
    return null;
  }

  return (
    <QuestionaireWrapper
      isSuccessModal={isSuccessModal}
      onCloseModal={handleRedirect}
      isErrorModal={isErrorModal}
    >
      <WarnModal
        visible={isServerErrorModal}
        testId="CIP-banner_server_error"
        onSetVisible={setServerErrorModal}
        header={t('The server encountered an error processing the request')}
        helptext={t(`It doesn't appear to have affected your data, but we cannot verify your identity to establish your HSA.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`)}
        buttonText={t('Close')}
        onSubmit={handleVerify}
        submitButtonText={t('Try Again')}
      />
      <Box margin={{ vertical: 'l' }}>
        <QuestionnaireTimer isTimeOver={isTimeOver}>
          {formattedTime}
        </QuestionnaireTimer>
      </Box>
      {isTimeOver && attempts ? (
        <TimeoutBanner onCancel={handleLater} onRetry={handleRetry} />
      ) : (
        <>

          {isFieldsLoading || isAnswersLoading || isLoading ? (
            <Box>
              <Preloader />
            </Box>
          ) : (
            <>
              <QuestionsList
                fields={questionsFields}
                values={values}
                onChangeValues={handleChangeValues}
              />
              <QuestionnaireFooter
                isErrorShowed={isErrorShowed && isValidationError}
                onCancel={handleLater}
                onNext={handleNext}
                isLoading={isAnswersLoading}
              />
            </>
          )}

        </>
      )}

    </QuestionaireWrapper>
  );
};
export default Questionaire;
