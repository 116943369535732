import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { InvestmentObjectives } from '@/modules/investments/investments.types';

import { useStore as useInvestmentProfileStore } from '../../stores/useInvestmentProfile.store';

export default () => {
  const { t } = useTranslation();
  const INVESTMENT_OBJECTIVES_TYPE_OPTIONS = useMemo(
    () => [
      {
        label: t('I prefer to minimize fluctuations in my portfolio but want some limited growth potential.'),
        value: InvestmentObjectives.NEW,
      },
      {
        label: t('I am willing to accept lower fluctuations in my portfolio with the opportunity to reach a modest return.'),
        value: InvestmentObjectives.INFREQUENT,
      },
      {
        label: t('I am willing to accept moderate fluctuations in my portfolio for an opportunity for moderate long-term growth.'),
        value: InvestmentObjectives.FREQUENT,
      },
      {
        label: t('I am willing to accept larger fluctuations in my portfolio for an opportunity to earn stronger long-term returns.'),
        value: InvestmentObjectives.ACTIVE_DAILY,
      },
      {
        label: t('I am willing to accept substantial fluctuations in my portfolio for an opportunity to earn significant long-term returns.'),
        value: InvestmentObjectives.LONG_TERM,
      },
    ], [t],
  );
  const defaultFields = useInvestmentProfileStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        name: 'investmentObjectivesType',
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: t('What is your primary goal for this portfolio?'),
        placeholder: '',
        options: INVESTMENT_OBJECTIVES_TYPE_OPTIONS,
        isWrappedMode: true,
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]), [INVESTMENT_OBJECTIVES_TYPE_OPTIONS, t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
