import React from 'react';
import { Box, Heading } from '@common-fe/common-fe';

import { InvestmentsType } from '@/common/types';

import SelectButton from './SelectButton';

interface Props {
  currentTab: InvestmentsType;
  onSetCurrentTab: (tab: InvestmentsType) => void;
  hideHeader: boolean;
  plansCount?: number;
  isScrolled?: boolean;
  children?: React.ReactNode;
  title?: string;
}

const InvestmentsToggle: React.FC<Props> = ({
  hideHeader,
  children,
  onSetCurrentTab,
  currentTab,
  title,
}) => (
  <Box direction="row" justify="between" align="center" margin={{ bottom: 'medium' }} height={{ min: 'auto' }}>
    <Heading level={4} size={hideHeader ? 'medium' : 'large'}>{title}</Heading>
    <Box direction="row" align="center">
      <Box
        direction="row"
        background="module"
     
        pad="xsmall"
        round="container1Round"
        margin={{ right: 'spacing16' }}
      >
        <SelectButton
          onClick={onSetCurrentTab}
          currTab={currentTab}
          name={InvestmentsType.Dashboard}
          text="Dashboard"
        />
        <SelectButton
          onClick={onSetCurrentTab}
          currTab={currentTab}
          name={InvestmentsType.Transactions}
          text="Activities"
        />
        <SelectButton
          onClick={onSetCurrentTab}
          currTab={currentTab}
          name={InvestmentsType.Documents}
          text="Documents"
        />
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  </Box>
);

export default InvestmentsToggle;
