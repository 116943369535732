import React from 'react';
import { useTranslation } from 'react-i18next';
import { Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DAYS_OF_DISABLED_REQUESTS,DEFAULT_DATE_FORMAT } from '@/common/constants';

interface Props {
  isCardMailed: boolean;
  mailedDataOfLastCard: string;
}

const CardMailedHint: React.FC<Props> = ({ mailedDataOfLastCard, isCardMailed }) => {
  const { t } = useTranslation();
  return (
    <Inscription size="12px">
      {t('Your card is on its way and should arrive soon. ')}
      {isCardMailed && mailedDataOfLastCard
        ? `${t('If you don\'t receive it by')} ${dayjs(mailedDataOfLastCard).add(DAYS_OF_DISABLED_REQUESTS, 'day').format(DEFAULT_DATE_FORMAT)}, ${t('you can request a replacement.')}`
        : ''}
    </Inscription>
  );
};

export default CardMailedHint;
