import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

export interface ReceiptQRCodePayload {
  qr_id?: string;
  file_upload_url?: string;
  employee_id?: number;
  valid_to?: string;
}

export interface ReceiptQRCode {
  qrId?: string;
  fileUploadUrl?: string;
  employeeId?: number;
  validTo?: string;
}

// interface Props {
//   documentId?: string;
// }

// for local development
// function changeUrl(originalUrl?: string) {
//   const newBaseUrl = 'http://192.168.31.213:3000';
//   if (!originalUrl) return originalUrl;
//   const queryParams = originalUrl.split('?')[1];
//   const updatedUrl = `${newBaseUrl}/transaction/reimburse-me/qr-codes?${queryParams}`;
//   return updatedUrl;
// }

const useGetReceiptQRCodeQuery = () => {
  // const requestBody = documentId ? { document_id: documentId } : undefined;
  const {
    data, isLoading, isFetching, refetch,
  } = useQuery(
    [PATHS.GET_RECEIPT_QR_CODE],
    () => api.post<ReceiptQRCodePayload>(PATHS.GET_RECEIPT_QR_CODE),
  );

  const formattedFileUploadUrl = useMemo(() => {
    if (!whiteLabelingConfig?.title) {
      return data?.data?.file_upload_url;
    }

    return data?.data?.file_upload_url?.replace('user', whiteLabelingConfig?.title);
  }, [data]);

  const formattedData = useMemo<ReceiptQRCode>(() => ({
    qrId: data?.data?.qr_id,
    fileUploadUrl: formattedFileUploadUrl,
    employeeId: data?.data?.employee_id,
    validTo: data?.data?.valid_to,
  }), [data, formattedFileUploadUrl]);

  return {
    qrCodeData: formattedData,
    isLoading,
    isFetching,
    refetch,
  };
};

export default useGetReceiptQRCodeQuery;
