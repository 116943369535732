import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  Box,
  getCountryByCode, Image,Option } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ValuesMap } from '@/common/types';

export const QUERY_KEY = 'GET_COUNTRIES';
interface CountryPayload {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
  us_postal: string;
}

const EXLCULED_COUNTRIES = ['RAL', 'UNK'];
const SPECIFIC_ORDER = ['1', '2'];

export default () => {
  const { isLoading, data, isSuccess } = useQuery(
    [QUERY_KEY],
    () => api.get<CountryPayload[]>(PATHS.COUNTRIES),
  );
  const formattedData = useMemo(() => data?.data.reduce(
    (result: Option[], item) => {
      if (EXLCULED_COUNTRIES.includes(item.alpha3)) {
        return result;
      }
      const foundCountry = getCountryByCode(_.toLower(item.alpha2 || ''));
      const currentItem: Option = (
        {
          key: `${item.id}`,
          value: `${item.name} (${item.alpha3})`,
          title: `${item.name} (${item.alpha3})`,
          node: (
            <Box direction="row" align="center" width={{ min: '32px' }}>
              {foundCountry?.flagIcon ? (
                <Box
                  round="50%"
                  style={{ overflow: 'hidden' }}
                  width={{ min: '24px' }}
                >
                  <Image
                    width={24}
                    height={24}
                    src={foundCountry?.flagIcon}
                    alt={foundCountry.name}
                  />
                </Box>
              )
                : (
                  <Box width={{ min: '24px' }} height={{ min: '24px' }} margin={{ right: 'xs' }} />
                )}
              {/* <Text size="medium" margin={{ left: 'xs' }}>{item.name}</Text> */}
            </Box>),
        }
      );

      return [...result, currentItem];
    }, [],

  ), [data]);
  const sorted = useMemo(() => {
    const sortedAll: Option[] = _.sortBy(formattedData, (item) => item.title);
    const specificItems = SPECIFIC_ORDER.reduce((result: Option[], key) => {
      const foundItem = formattedData?.find((item) => item.key === key);
      if (foundItem) {
        // if (_.last(SPECIFIC_ORDER) === key) {
        //   return [...result, {
        //     ...foundItem,
        //     divide: true,
        //   }];
        // }
        return [...result, foundItem];
      }
      return result;
    }, []);

    const formattedList = sortedAll.reduce(
      (result, item) => {
        if (SPECIFIC_ORDER.includes(`${item.key}`)) {
          return result;
        }
        return [...result, item];
      }, [...specificItems],
    );

    return formattedList;
  }, [formattedData]);

  const countriesMap = useMemo(() => sorted.reduce(
    (result: ValuesMap<Option>, item) => ({
      ...result,
      [`${item.key}`]: item,
    }), {},
  ), [sorted]);
  return {
    data: sorted,
    countriesMap,
    isLoading,
    isSuccess,
  };
};
