import { useEffect, useState } from 'react';

const useProgress = (delay = 300) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((p) => (Math.random() * (1 - p) * (1 - p)) / 10 + p);
    }, delay);
    return () => clearInterval(interval);
  }, [delay]);

  return {
    progress,
    setProgress,
  };
};

export default useProgress;
