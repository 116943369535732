import React, { useEffect } from 'react';
import {
  Box,
  FlexControlledForm,
  useDebounceState,
} from '@common-fe/common-fe';

import { PersonalInformationPayload,useStore } from '../stores/usePersonalInformation.store';

import { useYourPositionFields } from './hooks';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const YourPosition: React.FC<Props> = ({ onDirty }) => {
  const fields = useYourPositionFields();
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const { setValues, values } = useDebounceState<Partial<PersonalInformationPayload>>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round" margin={{ bottom: 'spacing24' }} data-testid="YourPosition-wrapper">
      <FlexControlledForm

        fields={fields}
        testId="your_position_form"
        showError={showErrors}
        editMode
        shouldControlValues
        onChangeValues={setValues}
        onDirty={onDirty}
      />
    </Box>
  );
};
export default YourPosition;
