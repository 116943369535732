import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Text } from '@common-fe/common-fe';

const DisclosureFooter = () => {
  const { t } = useTranslation();
  return (

    <div style={{position: 'relative', height:'300px'}}>
      
      <Box direction='column' style={{position: 'absolute', left: 0, right: 0}} >
        <Box 
          margin={{top: 'l', bottom: 'm'}}
          border={{size: 'small', color: 'border2', side: 'bottom'}}
        >
        </Box>
      
        <Text size="large" weight="bold" margin={{ bottom: 'spacing12' }}>
          {t('Disclosure')}
        </Text>
        
        <Text size="medium" color="textSecondary">
          {t('Trade settlement may take up to 2 days. If the market is not open at the time of trade submission, it will be executed at the next market open. Going forward, all trades will be automatically submitted on future investment account contributions and rebalancing of the holdings to the recommended portfolio allocation.')}
        </Text>
    
        <Box 
          margin={{top: 'l', bottom: 'm'}}
          border={{size: 'small', color: 'border2', side: 'bottom'}}
        >
        </Box>
        <Text margin={{ bottom: 'spacing24' }} size="medium" color="textSecondary">
          {t('Investment advisory services offered through Intellicents investment solutions, inc., an SEC registered investment adviser.')}
        </Text>
      </Box>
      
    </div>
  );
};

export default DisclosureFooter;