import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { InvestmentExperience } from '@/modules/investments/investments.types';

import { useStore as useInvestmentProfileStore } from '../../stores/useInvestmentProfile.store';

export default () => {
  const { t } = useTranslation();
  const INVESTMENT_EXPERIENCE_TYPE_OPTIONS = useMemo(
    () => [
      {
        label: t('I have no experience'),
        value: InvestmentExperience.NONE,
      },
      {
        label: t('Beginner'),
        value: InvestmentExperience.YRS_1_2,
      },
      {
        label: t('Intermediate'),
        value: InvestmentExperience.YRS_3_5,
      },
      {
        label: t('Advanced'),
        value: InvestmentExperience.YRS_5_10,
      },
      {
        label: t('Expert'),
        value: InvestmentExperience.YRS_10,
      },
    ], [t],
  );

  const defaultFields = useInvestmentProfileStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        testId: 'investment_experience_type_radiobutton',
        name: 'investmentExperienceType',
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: t('How would you best describe your experience in Investments?'),
        placeholder: '',
        options: INVESTMENT_EXPERIENCE_TYPE_OPTIONS,
        isWrappedMode: true,
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]), [INVESTMENT_EXPERIENCE_TYPE_OPTIONS, t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
