import React, { useMemo, useState } from 'react';
import { getCurrentOs } from '@common-fe/common-fe';

import { useQueryParams } from '@/utils';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import { useGetAppLinksQuery } from './queries/useGetAppLinks.query';
import ContinueInAppPopup from './ContinueInAppPopup';

const HASH_ID_QUERY_KEY = 'hash_id';

const ContinueInAppPopupStandalone = () => {
  const [visible, setVisible] = useState(true);
  const { isAndroid } = getCurrentOs();
  const query = useQueryParams();
  const hashId = useMemo(() => query.get(HASH_ID_QUERY_KEY) || undefined, [query]);
  const { appLinks } = useGetAppLinksQuery({ hashId });
  const verificationAppLinks = useMemo(
    () => ({
      ...appLinks,
      defaultAppLink: isAndroid
        ? whiteLabelingConfig.androidAppLink || whiteLabelingConfig.androidAppStoreLink
        : whiteLabelingConfig.IOSAppLink || whiteLabelingConfig.IOSAppStoreLink,
    }),
    [appLinks, isAndroid]
  );
  if (hashId) {
    return null;
  }
  return <ContinueInAppPopup visible={visible} setVisible={setVisible} appLinks={verificationAppLinks} />;
};

export default ContinueInAppPopupStandalone;
