import React, { useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityType,   Box, CategoryIcon, costFormater,
  HiddenLine,  Text } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import { DEFAULT_CARD_EXP_DATE_FORMAT } from '@/common/constants';
import { PaymentType, Service,TransactionServiceForData,TransactionType } from '@/modules/transaction/transaction.types';

import { useGetTransactionTypeLabel } from './hooks/useGetTransactionTypeLabel';
import ProviderModalWrapper from './ProviderModal/ProviderModalWrapper';
import ProviderView from './ProviderModal/ProviderView';
import {
  AmountsWrapper,
  AmountText,
  ColumnText,
  ColumnTitle,
  Content,
  Footer,
  FooterItem,
  FooterLabel,
  FooterText,
  HeaderWrapper,
  IdBlock,
  PaidAmount,
  Title,
  TransactionTypeLabel,
  TransactionTypeWrapper,
  Wrapper,
} from './TransactionCard.styles';

const getPrice = (price?: number) => {
  if (price) return `$${(price)?.toFixed(2)}`;

  return '-';
};

const IconWrapper = styled(Box)`
`;

const MAX_ONE_LINE_PROVIDER_NAME_LENGTH = 30;
const MAX_WIDTH_OF_PROVIDER_NAME = '460px';

interface Props {
  className?: string;
  invoiceId?: string;
  id?: number;
  category: ActivityType;
  providerName: string;
  amount: number;
  approvedAmount?: number;
  paidAmount?: number;
  userName?: string;
  userRelationship?: string;
  service?: Service;
  transactionType: TransactionType | PaymentType;
  date?: Date | string;
  claimId?: string;
  transactorId?: string;
  applyDeductibleAmount?: number | null;
  serviceFor?: TransactionServiceForData;
  taxYear?: string;
}

const TransactionCard: React.FC<Props> = ({
  className,
  invoiceId,
  id,
  category,
  providerName,
  amount,
  approvedAmount,
  paidAmount,
  userName,
  service,
  transactionType,
  date,
  claimId,
  transactorId,
  userRelationship,
  applyDeductibleAmount,
  serviceFor,
  taxYear,
}) => {
  const { t } = useTranslation();
  const [openAddModal, setOpenModal] = useState<boolean>(false);

  const {
    transactionTypeLabel,
    transactionDateLabel,
  } = useGetTransactionTypeLabel(transactionType, !claimId);

  const isLongProviderName = useMemo(() => providerName.split(' ')[0].length > MAX_ONE_LINE_PROVIDER_NAME_LENGTH, [providerName]);

  return (
    <Wrapper
      className={className}
      data-testid="TransactionCard-wrapper"
      pad={{ horizontal: 'spacing32', top: 'spacing32', bottom: 'spacing24' }}
      border={{ size: 'small', color: 'border2' }}
      elevation="default"
    >
      <HeaderWrapper>
        <Box direction="row">
          <IconWrapper alignSelf="center" width={{ min: '48px' }}>
            <CategoryIcon category={category} size="l" />
          </IconWrapper>
          <Box direction="column" justify="center" width={{ max: MAX_WIDTH_OF_PROVIDER_NAME }}>
            <Box width="fit-content">
              <Title
                isHovered={!!transactorId}
                isLong={isLongProviderName}
                onClick={transactorId
                  ? () => setOpenModal(true)
                  : () => null}
              >
                {providerName}
              </Title>
            </Box>

            {transactionType !== PaymentType.CARD_SWIPE
            && (
              <Box direction="row" margin={{ left: 'spacing16' }}>
                <HiddenLine
                  data={userName}
                  isAvailable={serviceFor?.isViewNameAllowed}
                  isHidden={serviceFor?.isHidden}
                  customTitleColor='textBody'
                  {...userRelationship ? { description: `(${userRelationship})` } : {}}
                />
              </Box>
            )}
          </Box>
        </Box>
        <TransactionTypeWrapper>
          <TransactionTypeLabel className="card-button" onClick={() => {}}>
            {transactionTypeLabel}
          </TransactionTypeLabel>
          {id
          && (
            <IdBlock>
              {`#${id}`}
            </IdBlock>
          )}
        </TransactionTypeWrapper>
      </HeaderWrapper>
      <Content>
        <PaidAmount>
          <ColumnTitle>
            {claimId ? t('Paid Amount') : t('Amount')}
          </ColumnTitle>
          <AmountText>
            {getPrice(claimId ? paidAmount : amount)}
          </AmountText>
        </PaidAmount>
        {(amount >= 0) && claimId
        && (
          <AmountsWrapper>
            <Box direction="row" margin={{ bottom: 'spacing16' }} alignSelf="end">
              <ColumnTitle margin={{ right: 'spacing4' }}>
                { transactionType !== PaymentType.CARD_SWIPE ? t('Requested Amount: ') : t('Card Swiped Amount: ')}
              </ColumnTitle>
              <ColumnText>
                {`$${amount.toFixed(2)}`}
              </ColumnText>
            </Box>
            <Box direction="row" alignSelf="end" margin={{ bottom: applyDeductibleAmount ? 'spacing16' : undefined }}>
              <ColumnTitle margin={{ right: 'spacing4' }}>
                Approved Amount:
              </ColumnTitle>
              <ColumnText>
                {approvedAmount ? costFormater(approvedAmount, true) : '-'}
              </ColumnText>
            </Box>
            {!!applyDeductibleAmount && (
              <Box direction="row">
                <ColumnTitle margin={{ right: 'spacing4' }}>
                  Applied to Deductible:
                </ColumnTitle>
                <ColumnText isInfoColor>
                  {`$${applyDeductibleAmount?.toFixed(2)}`}
                </ColumnText>
              </Box>
            )}
          </AmountsWrapper>
        )}
      </Content>
      <Footer>
        <FooterItem>
          <FooterLabel>
            {transactionDateLabel}
          </FooterLabel>
          <FooterText>
            {date ? dayjs(date).format(DEFAULT_CARD_EXP_DATE_FORMAT) : ''}
          </FooterText>
        </FooterItem>
        {
          service
          && (
            <FooterItem>
              <FooterLabel>
                {t('Service provided:')}
              </FooterLabel>
              <FooterText>
                {capitalize(service.categoryType.replace(/_/g, ''))}
              </FooterText>
            </FooterItem>
          )
        }
        {
          invoiceId
          && (
            <FooterItem>
              <FooterLabel>
                {t('Invoice:')}
              </FooterLabel>
              <FooterText>
                {invoiceId}
              </FooterText>
            </FooterItem>
          )
        }
        {
          taxYear
          && (
            <FooterItem>
              <FooterLabel>
                {t('Tax year:')}
              </FooterLabel>
              <FooterText>
                {taxYear}
              </FooterText>
            </FooterItem>
          )
        }
      </Footer>

      <ProviderModalWrapper
        visible={openAddModal}
        onSetVisible={() => setOpenModal(false)}
        modalTitle="Provider information"
      >
        <ProviderView
          transactorId={transactorId}
          onCancel={() => {
            setOpenModal(false);
          }}
        />
      </ProviderModalWrapper>
    </Wrapper>
  );
};
export default TransactionCard;
