import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  ButtonDropdownContent,
  ChevronDownIcon,
  ChevronUpIcon,
  useClickOutside,
} from '@common-fe/common-fe';

import { ButtonDropdownOption } from '@/common/types';

import {
  Title,
  Wrapper, } from './HorizontalMenuOption.styles';

interface SubOption {
  key: string;
  title: string;
  url?: string;
}
export interface Option {
  key: string;
  title: string;
  delimiter?: boolean;
  options?: SubOption[];
  url?: string;
  link?: string;
  border?: boolean;
  contentPosition?: boolean;
}

export type Props = Option & {
  active?: boolean;
  path:string;
  currentKey: string;
  onPick: (value: string, url?: string) => void;
};

const LEFT_POSITION_VALUE = 140;

const HorizontalMenuOption: React.FC<Props> = ({
  title,
  path,
  currentKey,
  onPick,
  options,
  url,
  link,
  border,
  contentPosition,
}) => {
  const [expand, setExpanded] = useState(false);
  const ref = useRef(null);
  const dropdownOptions: ButtonDropdownOption[] = [];
  options?.forEach((item: Option) => {
    dropdownOptions.push({
      redirectFunc: () => {
        if (item.link) {
          window.open(item.link, '_blank');
          return;
        }
        onPick(item.key, item.url);
      },
      title: item.title,
    });
  });
  const isOptions = useMemo(() => options && options.length, [options]);
  const active = useMemo(() => {
    let pureUrl = url;
    if (url?.split('?')) {
      pureUrl = url?.split('?')?.[0];
    }
    const activeTab = path === pureUrl;
    if (!activeTab && options) {
      const found = !!options.find((item) => item.url === path);
      return found;
    }
    return path === pureUrl;
  }, [path, url, options]);

  const handleClick = useCallback(() => {
    if (link) {
      window.open(link, '_blank');
      return;
    }
    if (isOptions) {
      setExpanded(!expand);
      return;
    }
    onPick(currentKey, url);
  }, [link, isOptions, onPick, currentKey, url, expand]);

  useClickOutside(ref, () => {
    setExpanded(false);
  });
  return (
    <>
      <Wrapper
        active={active}
        onClick={handleClick}
        aria-label={title}
        ref={ref}
        border={border}
      >
        <Title active={active}>
          {title}
        </Title>
        {!!isOptions
          && (
            <Box>
              {expand ? (
                <ChevronUpIcon color="iconPrimary" />
              ) : (
                <ChevronDownIcon color="iconPrimary" />
              )}
            </Box>
          )}
        {!!isOptions && !!expand && options && (
          <ButtonDropdownContent
            options={dropdownOptions}
            leftPosition={contentPosition ? LEFT_POSITION_VALUE : 0}
            close={() => setExpanded(false)}
          />
        )}
      </Wrapper>
    </>
  );
};
export default HorizontalMenuOption;
