import Empty from '@/assets/placeholders/empty.png';

import { WhiteLabelTheme } from '../types';

import CardBackground from './assets/card-background.svg';
import CardLogo from './assets/card-logo.svg';
import DashboardBanner from './assets/dashboard-banner.svg';
import LoginBackground from './assets/login-background.svg';
import LogoIcon from './assets/logo.svg';
import manageProfileBanner from './assets/manageProfileBanner.svg';
import SmallLogoIcon from './assets/minify-logo.svg';
import ReimburseMeSidebarBackground from './assets/reimburseMe-sidebar-background.svg';
import SignInLogo from './assets/signIn-logo.svg';
import VerificationBanner from './assets/verificationBackground.svg';

const buttonColors = {
  button: '#2E8B57',
  buttonHover: '#1C7A4D',
  buttonActive: '#1D5435',

  buttonSecondary: '#2E8B57',
  buttonSecondaryContainer: '#CBEEDE',
  buttonSecondaryActive: '#1D5435',


};
const navigationColors = {
  navigationBackground:  '#F7F8F9',
  navigationBorder:  '#E8E8E8',
  navigationAccent:  '#CBEEDE',
  navigationAccent2:  '#2E8B57',
  navigationTextDefault:  '#000000',
  navigationTextAccent:  '#2E8B57',
  navigationIconDefault:  '#000000',
  navigationIconAccent:  '#2E8B57',
  navigationAccentHover:  '#CBEEDE',
  navigationAccentActive:  '#CBEEDE',
};
const backgroundColors = {
  background1: '#2E8B57',
  background2: '#333333',
  background3: '#333333',
  
  border: '#B8BFCC',
  border1: '#E5E9F0',
  border2: '#EEF1F4',
  module: '#F7F8F9',
  canvas: '#FFFFFF',
  stressContainer: '#333333',
  accentActive: '#2E8B57',
  accentBorder: '#87C4A2',
  accentContainer: '#CBEEDE',
  popupBg2: '#0F16210D',
  legend:'#9BA3B3',
  
};
const baseColors = {


  ...buttonColors,
  ...navigationColors, 
  ...backgroundColors,

  textTitle: '#000000',
  textBody: '#000000',

  textSecondary: '#63656A',
  textDisabled: '#9BA3B3',
  textOnColor: '#FFFFFF',
  textAccent: '#2E8B57',
  textAccentHover: '#1C7A4D',
  textAccentActive: '#1D5435',

  iconPrimary: '#000000',
  iconSecondary: '#63656A',
  iconOnColor: '#FFFFFF',
  iconAccent: '#2E8B57',
  iconAccentHover: '#1C7A4D',
  iconAccentActive: '#1D5435',

 

  success: '#4EAB50',
  successBorder: '#B8F8C8',
  successContainer: '#E9FFEF',

  warning: '#FFCC00',
  warningBorder: '#FFF0B8',
  warningContainer: '#FFFBEC',

  danger: '#E23131',
  dangerBorder: '#F6C4C4',
  dangerContainer: '#FFF3F3',
  dangerHover: '#971C1C',
  dangerActive: '#7C1414',

  info: '#19BCCE',
  infoBorder: '#B2F4FF',
  infoContainer: '#F0FDFF',

  transactionMedical: '#00C3B3',
  transactionDental: '#176AF6',
  transactionVision: '#481A54',
  transactionOther: '#FF9D0A',
  transactionParking: '#323D9B',
  transactionTransit: '#CE8258',
  transactionAdoption: '#0F7970',
  transactionDependentCare: '#CC3381',
  transactionPharmacy: '#D83B00',
  transactionWellness: '#D3375C',
  transactionLifestyle: '#D3375C',
  transactionPremium: '#3628D1',
  transactionTravel: '#F26B31',
  refund: '#588C0E',
  transactionDefault: '#5E697E',
  claimTransferInternal: '#E5E9F0',
  nonClaimIcon: '#151F6D',
  nonClaimBg: '#E5E9F0',

  planTypeHSA: '#2EA12B',
  planTypeHCFSA: '#FA802A',
  planTypeDCAP: '#CC3381',
  planTypeHRA: '#134E8F',
  planTypeCommuterTransit: '#CE8258',
  planTypeCommuterParking: '#323D9B',
  planTypeAdoption: '#0F7970',
  planTypeLifestyle: '#D3375C',
  planTypeWellness: '#D3375C',
  planTypeSpecialty: '#00B5EE',
  planTypeTravel: '#F26B31',
  planTypeRx: '#7909AD',
  planTypeDental: '#176AF6',
  planTypeVision: '#481A54',
  planTypeMedical: '#00C3B3',

  lockedNull: '#000000',
  processingPending: '#F9C134',
  needsHelp: '#FF9D0A',
  paidActiveComplete: '#19B269',
  partiallyPaid: '#B9CC29',
  waitingForFunds: '#8EB2A1',
  deniedErrorExpired: '#E72626',
  uploading: '#9BA3B3',
  uploadedApproved: '#176AF6',
  loa: '#00B5EE',
  retirement: '#CE8258',
  future: '#7909AD',

  investmentsfuture: '#7909AD',
  investmentsFund1: '#00C3B3',
  investmentsFund2: '#481A54',
  investmentsFund3: '#D83B00',
  investmentsFund4: '#176AF6',
  investmentsFund5: '#FF9D0A',
  investmentsFund6: '#078F84',
  investmentsFund7: '#323D9B',
  investmentsFund8: '#F3C626',
  investmentsFund9: '#A528C6',
  investmentsFund10: '#0C95D0',
  investmentsFund11: '#619C00',
  investmentsFund12: '#CC3381',

  partner: '#D83B00',
  distributor: '#904D27',
  employer: '#174FBC',
  subsidiary: '#0F7970',
  subgroup: '#481A54',
  claimProcessor: '#176AF6',
  employee: '#0CAD09',
  dependent: '#5E1696',
  noAssignee: '#B8BFCC',

  claimParticipant: '#CC3381',
  claimSystem: '#18A0FB',
  claimCardProcessor: '#19B269',
};
export default {
  title: 'RMR',
  loginScreenTitle: 'Start getting the benefit out of your benefits plan',
  termsAndConditions: 'https://www.rockymountainreserve.com/terms-of-use',
  privacyPolicy: 'https://www.rockymountainreserve.com/privacy',
  
  IOSAppStoreLink: 'https://apps.apple.com/us/app/rmr-benefits/id6479498415',
  androidAppStoreLink: 'https://play.google.com/store/apps/details?id=com.rockymountainreserve.rmr_app',

  IOSAppLink: 'elevate://open-app',
  androidAppLink: 'elevate://open-app',

  mobileAppIcon: SmallLogoIcon,
  mobileAppTitle: 'RMR app',
  


  colors: {
    ...baseColors,
    loginTitleColor: baseColors.textBody,

    popup: 'rgba(15, 22, 33, 0.5)',
    popup2: 'rgba(15, 22, 33, 0.05)',
    fade1: 'linear-gradient(0deg, #F7F9FA 0%, rgba(255, 255, 255, 0.00) 100%)',
    fade2: 'linear-gradient(180deg, #F7F9FA 0%, #EEF1F4 100%)',
  },
  logo: LogoIcon,
  minifyLogo: SmallLogoIcon,
  font: '\'Open Sans\'', // Also you need add font import to global.scss example: @import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
  titleFont: '\'Open Sans\'',
  tetriaryAccentButton: true,

  //wait for design
  images: {
    banner: DashboardBanner,
    cardBackground: CardBackground,
    loginBackground: LoginBackground,
    cardLogo: CardLogo,
    sidebarLogo: LogoIcon,
    sidebarMinifyLogo: SmallLogoIcon,
    qrCodeLogo: SmallLogoIcon,
    investmentsBannerRight: Empty,
    reimburseMeSidebar: ReimburseMeSidebarBackground,
    verificationBackground: VerificationBanner,
    signInLogo: SignInLogo,
    manageProfileBannerBackground: manageProfileBanner,
  },

  fonts: {
    sidebarWeight: 500,
    sidebarFontSize: '14px',
    reimbursementWeight: 500,
    sizes: {
      small: {
        size: '12px',
        height: '16px',
        maxWidth: '336px',
      },
      '3xl': {
        size: '30px',
        height: '40px',
        maxWidth: '1008px',
      },
      '2xl': {
        size: '24px',
        height: '30px',
        maxWidth: '816px',
      },
    },
  },
  rounds: {
    moduleRound: '24px',
    container1Round: '12px',
    container2Round: '8px',
    checkboxRound: '6px',
    fieldRound: '8px',
    button1Round: '8px',
    button2Round: '4px',
    dropdownItemRound: '4px',
    snackbarRound: '4px',
  },

  shadows: {
    default: '0px 1px 7px rgba(0, 0, 0, 0.10);',
    hover: '0px 3px 10px rgba(0, 0, 0, 0.10);',
    active: '0px 8px 25px rgba(0, 0, 0, 0.25);',
    highlighted: '0px 6px 16px rgba(18, 40, 75, 0.45);',
  },
  favicon: '/rmr/favicon.ico',
  favicon16: '/rmr/favicon-16x16.png',
  favicon32: '/rmr/favicon-32x32.png',
  faviconAppleTouch: '/rmr/apple-touch-icon.png',
} as WhiteLabelTheme;
