import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Text } from '@common-fe/common-fe';

import { useHistory } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user';

import useFailedCIPStorage from '../hooks/useFailedCIPStorage';
import { useCIPIdentificationQuery } from '../queries';

import { useCIPStatusUpdateQuery } from './queries';
import QuestionaireWrapper from './QuestionnaireWrapper';
import ScanFrame from './ScanFrame';

const ScanDocument = () => {
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isErrorModal, setErrorModal] = useState(false);
  const { data: { idScanUrl } } = useCIPIdentificationQuery({ enabled: true });
  const { invalidateQuery } = useCIPStatusUpdateQuery();
  const { handleFailed } = useFailedCIPStorage();
  const { url } = useParams<{ url?: string }>();
  const currentUrl = useMemo(() => idScanUrl || (url ? decodeURIComponent(url) : '') || '', [idScanUrl, url]);
  const { t } = useTranslation();
  const history = useHistory();
  const handleSuccess = useCallback(() => {
    invalidateQuery();
    setSuccessModal(true);
  }, [invalidateQuery]);

  const handleError = useCallback(() => {
    setErrorModal(true);
    handleFailed();
  }, [handleFailed]);

  const handleRedirect = useCallback(() => {
    history.push('/');
    setSuccessModal(false);
    setErrorModal(false);
  }, [history]);
  return (
    <QuestionaireWrapper
      isSuccessModal={isSuccessModal}
      onCloseModal={handleRedirect}
      isErrorModal={isErrorModal}

    >
      <Box pad={{ vertical: 'l' }}>
        <ScanFrame src={currentUrl} onSuccess={handleSuccess} onError={handleError} />

        <Text data-testId="scan-document_text" size="small" color="textSecondary">{
          t('To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will use the information provided to us from your employer such as your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver\'s license and other identifying documents.')
        }
        </Text>
      </Box>
    </QuestionaireWrapper>
  );
};
export default ScanDocument;
