import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useOrphanModeStore } from '@/modules/core/store';

import { formatOrganizationViewData } from './formatData';
import { OrganizationViewPayload } from './payloadTypes';

const useOrganizationViewDataQuery = (organizationId?: string) => {
  const { linkedEmployee } = useOrphanModeStore();
  const currentOrganizationId = linkedEmployee?.organizationId || organizationId;
  const {
    data,
    isLoading,
    refetch,
    isSuccess,
    isError,
  } = useQuery(
    [PATHS.ORGANIZATION_INFO(currentOrganizationId)],
    () => api.get<OrganizationViewPayload>(PATHS.ORGANIZATION_INFO(currentOrganizationId)),
    {
      enabled: !!currentOrganizationId,
    },
  );

  const formattedData = useMemo(() => formatOrganizationViewData(data?.data), [data]);

  return {
    formattedData,
    isLoading,
    refetch,
    isSuccess,
    isError,
  };
};

export default useOrganizationViewDataQuery;
