import React from 'react';
import { Box, Text, TimerIcon } from '@common-fe/common-fe';

interface Props {
  children: React.ReactNode;
  isTimeOver: boolean;
}
const QuestionnaireTimer: React.FC<Props> = ({ children, isTimeOver }) => (
  <Box
    background="warningContainer"
    data-testid="questionnaire-timer"
    round="container1Round"
    border={{ color: 'warningBorder', size: 'small' }}
    pad={{ vertical: 'm', horizontal: 'l' }}
  >
    <Box direction="row" align="center" height="40px">
      <TimerIcon color={isTimeOver ? 'error' : 'primary'} />
      <Box width="70px">
        <Text
          margin={{ left: 'xxs' }}
          data-testid={isTimeOver ? 'questionnaire-timer_text--error' : 'questionnaire-timer_text'}
          color={isTimeOver ? 'error' : 'textBody'}
          size="2xl"
          weight={700}
        >{children}
        </Text>
      </Box>
      <Text margin={{ left: 'm' }}>Remaining time to complete questions</Text>
    </Box>

  </Box>
);

export default QuestionnaireTimer;
