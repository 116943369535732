import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  DocIcon,
  Inscription,
  Preloader,
} from '@common-fe/common-fe';

import { FileImage } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import prepareFile from '@/components/controls/DropScan/prepareFile';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import theme from '@/styles/theme';

import { ReimburseFooterBackground } from '../ReimburseFooterBackground';
import { ContentWrapper } from '../ReimburseMe.styles';
import { UploadFileQRCodeSection } from '../UploadFileQRCode';

import Description from './Description';
import { HeaderWrapper } from './Receipts.styles';

const DropScan = lazy(() => import('@/components/controls/DropScan'));

interface Props {
  onSetFiles: (value: FileImage[]) => void;
  onNext?: () => void;
  onSkipUpload?: (value: boolean) => void;
  isBillPayMode?: boolean;
  handleDeleteFile: (id: string) => void;
  onNavigateToFileUpload?: () => void;
}

const Receipts: React.FC<Props> = ({
  onSetFiles,
  onNext,
  onSkipUpload,
  isBillPayMode,
  handleDeleteFile,
  onNavigateToFileUpload,
}) => {
  const { t } = useTranslation();
  const { handleSetValues, values: { files } } = useFilesInDocumentStore();
  const removeFiles = useCallback(() => {
    if (files.length) {
      files.forEach((file) => handleDeleteFile(file.id));
      handleSetValues({ files: [] });
    }
  }, [files, handleSetValues, handleDeleteFile]);

  const handleClick = () => {
    if (onNext) {
      onNext();
    }
    if (onSkipUpload) {
      onSkipUpload(false);
    }
  };

  useEffect(() => {
    if (files.length && onNavigateToFileUpload) {
      onNavigateToFileUpload();
    }
  }, [files, onNavigateToFileUpload]);

  useEffect(() => {
    onSetFiles([]);
    removeFiles();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box
      margin={{ top: 'spacing8' }}
      flex="grow"
      justify="between"
    >
      <Box
        direction="row"
        justify="center"
        flex="grow"
      >
        <ContentWrapper
          flex="grow"
        >
          <HeaderWrapper>
            <Description isBillPayMode={isBillPayMode} />
          </HeaderWrapper>
          <Box
            margin={{ bottom: 'spacing24' }}
            direction="row"
            background="module"
            round="moduleRound"
            style={{ height: '100%', maxHeight: '404px' }}
          >
            <Suspense fallback={<Preloader />}>
              <DropScan
                onFilesAdded={(acceptedFiles: FileImage[]) => {
                  onSetFiles(acceptedFiles.map(prepareFile));
                }}
                {...isBillPayMode
                  ? {
                    icon: (
                      <Box margin={{ bottom: '17px' }}><DocIcon size="48px" color="navigationAccent" /></Box>
                    ),
                    title: (
                      <Inscription size="xxlarge" lineHeight="28px">
                        {t('Drag or')} <Inscription size="xxlarge" weight="bold" color="navigationAccent">{t('browse')}</Inscription> {t('a picture of your docs')}
                      </Inscription>
                    ),
                  }
                  : {}}
              />
            </Suspense>
            <Box
              pad={{ vertical: 'spacing24', right: 'spacing24' }}
            >
              <Box width={{ max: '260px' }} height="100%">
                <UploadFileQRCodeSection />
              </Box>
            </Box>
          </Box>
        </ContentWrapper>
      </Box>
      <Box
        direction="row"
        justify="center"
        style={{
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Box
          flex="grow"
          pad={{ horizontal: '40px' }}
          width={{ max: theme.defaultContentWidth }}
        >
          <Box
            alignSelf="end"
            height="88px"
            align="end"
            justify="center"
            width="100%"
            background="module"
          >
            <AppButton
              className="secondary-button"
              onClick={handleClick}
              buttonType="secondary"
              width="control"
              style={{
                zIndex: 10,
              }}
            >
              {t('Skip Upload')}
            </AppButton>
          </Box>
        </Box>
        <ReimburseFooterBackground />
      </Box>
    </Box>
  );
};

export default Receipts;
