import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import AppButton from '@/components/controls/AppButton';
import {
  StyledSelectDropdown,
} from '@/modules/transaction/components/CardDetails/CardDetails.styles';
import { ROOT_DEPENDENT_ID, useGetCardsQuery } from '@/modules/transaction/hooks/useGetCards.query';
import { useAuthStore } from '@/modules/user/stores';

const QUERY_KEY = 'dependent_id';

export const CardDetailsHeader = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location?.search || ''), [location]);
  const { user } = useAuthStore();
  const { holderList } = useGetCardsQuery(user?.employee?.id);
  const dependentId = useMemo(() => query.get(QUERY_KEY) || ROOT_DEPENDENT_ID, [query]);
  const holderFirstName = useMemo(() => holderList.find((holder) => holder.dependentId === dependentId)?.firstName || '',
    [holderList, dependentId]);
  const history = useHistory();
  const holdersOptions: Option[] = useMemo(() => holderList.map((holder) => ({
    key: holder.dependentId || ROOT_DEPENDENT_ID,
    value: holder.dependentId || ROOT_DEPENDENT_ID,
    title: holder.name,
  })), [holderList]);
  const activeHolder = useMemo(() => holderList
    .find((holder) => holder.dependentId === dependentId)?.dependentId || '',
  [holderList, dependentId]);

  return (
    <Box margin={{ bottom: 'spacing32' }} direction="row" justify="between" align="center" data-testid="CardDetailsHeader-wrapper">
      <Text size="xxlarge" weight="bold" data-testid="CardDetailsHeader-holder-name">{holderFirstName}'s Cards</Text>
      <Box direction="row" justify="between" align="center">
        <Box
          margin={{ right: 'spacing24' }}
          data-testid="CardDetailsHeader-holder-select"
          width={{ min: '160px' }}
        >
          <StyledSelectDropdown
            id="cardholder-select"
            options={holdersOptions}
            singleMode
            values={activeHolder ? [activeHolder] : []}
            placeholder="Select a value"
            name="dsa"
            onChangeValues={(value) => history.push(`${ROUTES.CARD_DETAILS}?dependent_id=${value.toString().replace(`${dependentId},`, '')}`)}
          />
        </Box>
        <AppButton
          buttonType="secondary"
          onClick={() => history.push(ROUTES.CARDS)}
          testId="CardDetailsHeader-manage-cards"
        >
          Manage cards
        </AppButton>
      </Box>
    </Box>
  );
};
