 
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Box } from '@common-fe/common-fe';

import { FileImage } from '@/common/constants';
import ScanPreviewContainer from '@/components/controls/DropScan/ScanPreviewContainer';
import NoReceipts from '@/modules/transaction/components/ReimburseMe/FileUpload/NoReceipts';
import { useClaimInfoStore } from '@/modules/transaction/components/ReimburseMe/store/useClaimInfo.store';
import useFilesStore from '@/modules/transaction/components/ReimburseMe/store/useFiles.store';
import { QRCodeUploadModal } from '@/modules/transaction/components/ReimburseMe/UploadFileQRCode';
import theme from '@/styles/theme';
import useMediaQuery from '@/utils/hooks/useMediaQuery';

interface Props {
  onBack?: () => void;
  onNext?: () => void;
  isSmallViewVersion?: boolean;
  addFileButtonRef?: React.RefObject<HTMLDivElement>;
  noReceiptsTitle?: string;
  outputCurrentFiles?: (files: FileImage[]) => void;
  handleDeleteFile?: (id: string) => void;
}

export const FileUploadContent: React.FC<Props> = ({
  isSmallViewVersion,
  addFileButtonRef,
  noReceiptsTitle,
  outputCurrentFiles,
  handleDeleteFile,
}) => {
  const isLessThenLaptopL = useMediaQuery({ mediaQuery: theme.deviceSize.lessThen.laptopL });
  const { handleReset } = useClaimInfoStore();
  const { files, setFiles, setActiveFile } = useFilesStore((state) => state);
  const [currentFiles, setCurrentFiles] = useState<FileImage[]>(files as FileImage[] || []);
  const handleSetFiles = useCallback((fileObjects: FileImage[]) => {
    setFiles(fileObjects);
    if (outputCurrentFiles) outputCurrentFiles(fileObjects);
    setCurrentFiles(fileObjects as FileImage[]);
  }, [outputCurrentFiles, setFiles]);

  useEffect(() => {
    setCurrentFiles(files);
  }, [files]);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => () => handleReset(), []);
  return (
    <Box
      round="moduleRound"
      style={isSmallViewVersion ? {} : { flex: 1 }}
      {...(isSmallViewVersion ? {}
        : {
          background: 'module',
          pad: 'medium',
        })}
    >
      <ScanPreviewContainer
        hasMainLabel
        isActiveFileShown
        isSmallViewVersion={isSmallViewVersion}
        defaultFiles={currentFiles}
        onSetFiles={handleSetFiles}
        onSetActivePage={setActiveFile}
        {...(isSmallViewVersion && noReceiptsTitle
          ? { noAddedFiles: <NoReceipts title={noReceiptsTitle} /> }
          : {})}
        addFileButtonRef={addFileButtonRef}
        {...handleDeleteFile ? { handleDeleteFile } : {}}
        {...isLessThenLaptopL ? { zoomerProps: { zoomWidth: 350, zoomPosition: 'left' } } : {}}
        qrCodeButton={<QRCodeUploadModal />}
      />
    </Box>
  );
};
