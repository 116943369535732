import { useCallback, useMemo } from 'react';
import { Field } from '@common-fe/common-fe';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { PersonalInfoPayload } from '../stores/usePersonalInfo.store';

import useCitizenshipFields from './useCitizenshipFields';
import useEmployeeData from './useEmployeeData';
import usePersonalInfoFields from './usePersonalInfoFields';

export default () => {
  const { params } = useEmployeeData();

  const personalInfoFields = usePersonalInfoFields({
    hideField: {
      email: params.email,
      phone: params.phone,
    },
  });

  const citizenshipFields = useCitizenshipFields();
  const currentValidators = useMemo(() => {
    const currentValidators: {
      [key: string]: yup.AnySchema | undefined
    } = {};
    
    const getValidators = (fields: Field[]) => {
    
      fields.forEach((field) => { 
        currentValidators[field.name] = field.validator;
        if(field.subFields) {
          getValidators(field.subFields);
        }
      });
    };
    getValidators(personalInfoFields);


    if (!params.citizenship) {
      getValidators(citizenshipFields);
    }

    return currentValidators;
  }, [citizenshipFields, params, personalInfoFields]);

  const handleValidate = useCallback(async (data: Partial<PersonalInfoPayload>) => {
    const schema = yup.object()
      .shape(currentValidators as ObjectShape);
    return schema.validate(data);
  }, [currentValidators]);
  return {
    validate: handleValidate,
    validators: currentValidators,
  };
};
