import { BuySellOptions } from '@/common/constants';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  openBuySellTab: BuySellOptions;
  setBuySellTabState: (value: BuySellOptions) => void;
}

export const useStore = storeCreator<State>((set) => ({
  openBuySellTab: BuySellOptions.INVEST_MORE_FUNDS,
  setBuySellTabState: (value: BuySellOptions) => set(() => ({
    openBuySellTab: value,
  })),
}));

export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
