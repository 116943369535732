import { Text } from '@common-fe/common-fe';
import { Button } from 'grommet';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    font-size: 24px;
    margin-left: 10px;
  }
`;
export const Name = styled.p`
  padding-left: 12px;

  ${({ theme }) => theme.fonts.text14Medium};

`;

export const Item = styled.button`
  background-color: transparent;
  border: none;
  ${({ theme }) => theme.fonts.text16Regular};
`;

export const ManageButton = styled(Text)`
  cursor: pointer;
  transition: all .2s;
  &:hover {
    color: ${({ theme }) => theme.colors.textAccent};
  }
`;

export const LogOutButton = styled(Button)`
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  transition: all .2s;
  color: ${({ theme }) => theme.colors.textBody};
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
  }
  &:active {
    fill: ${({ theme }) => theme.colors.textOnColor};
    background-color: ${({ theme }) => theme.colors.buttonSecondaryActive};
    color: ${({ theme }) => theme.colors.textOnColor};
  }
 `;
