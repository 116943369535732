

import { FALSE_STRING, NO_KEY, TRUE_STRING, YES_KEY } from '@/common/constants';

const getBooleanFromYesOrNo = (value: string) => {
  let booleanValue:boolean | undefined;
  if (value === YES_KEY || value === TRUE_STRING) {
    booleanValue = true;
  } else if (value === NO_KEY || value === FALSE_STRING) {
    booleanValue = false;
  }
  return booleanValue;
};

export default getBooleanFromYesOrNo;