import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isAddPaymentMethodModalVisible: boolean;
  handleSetIsAddPaymentMethodModalVisible: (value: boolean) => void;
  handleReset: () => void;
}

export const useManageProfileModalsStore = storeCreator<State>((set) => ({
  isAddPaymentMethodModalVisible: false,

  handleSetIsAddPaymentMethodModalVisible: (value: boolean) => set((state) => ({
    ...state,
    isAddPaymentMethodModalVisible: value,
  })),

  handleReset: () => set(() => ({
    isAddPaymentMethodModalVisible: false,
  }))
}));
