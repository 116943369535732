import React  from 'react';

import { useResponsivePageMeta } from '@/utils/hooks';

import useSignInStepper, { STEPS } from './hooks/useSignInStepper';
import { ForgotPassword, NewPassword } from './ForgotPassword';
import { ForgotUsername, ForgotUsernameSuccess } from './ForgotUsername';
import SignInForm from './SignInForm';
import SignInWrapper from './SignInWrapper';

interface Props {
  disabled?: boolean;
  children?: React.ReactNode;
  header?: React.ReactNode;
}
const SignIn: React.FC<Props> = ({disabled, children,header }) => {
  useResponsivePageMeta();

  const {
    subtitle,
    step,
    title,
    username,
    handlePreviousStep,
    isBackButton,
    isChangePasswordMode,
    handleNextStep,
    handleForgotUsername,
  } = useSignInStepper();
  
  return (
    <SignInWrapper
      header={header}
      title={title}
      subtitle={subtitle}
      onBack={handlePreviousStep}
      isBackButton={isBackButton}
      flat={step === STEPS.NEW_PASSWORD}
    >
      
    
      {step === STEPS.SIGN_IN && (
        <SignInForm
          disabled={disabled}
          isChangePasswordMode={isChangePasswordMode}
          // onLogin={mutationLogin}
          onForgot={handleNextStep}
          onForgotUsername={handleForgotUsername}
        >
          {children}
        </SignInForm>
      )}
      {step === STEPS.FORGOT && (
        <ForgotPassword username={username} onNext={handleNextStep} />
      )}
      {step === STEPS.FORGOT_USERNAME && (
        <ForgotUsername onNext={handleNextStep} />
      )}
      {step === STEPS.FORGOT_USERNAME_SUCCESS && (
        <ForgotUsernameSuccess onNext={handleNextStep} />
      )}
      {step === STEPS.NEW_PASSWORD && (
        <NewPassword username={username} onNext={handleNextStep} />
      )}
    </SignInWrapper>

  );
};
export default SignIn;
