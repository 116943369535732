import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import { Deductibles } from '@/modules/deductibles';
import theme from '@/styles/theme';

const DeductiblesPage = () => {
  const { deductibleId } = useParams<{ deductibleId: string }>();
  return (
    <Box width="100%">

      <Box width={theme.defaultContentWidth} margin={{ horizontal: 'auto' }} pad={{ top: 'l' }}>
        <Deductibles deductibleId={deductibleId} />
      </Box>
    </Box>

  );
};

export default DeductiblesPage;
