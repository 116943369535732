import { Box,Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  z-index: 1;
  overflow: hidden;
  height: 258px;  
  box-sizing: border-box;
`;

export const TitleWrapper = styled(Box)`
  height: 35px;
  .icon {
    font-size: 32px;
    color: ${({ theme }) => theme.colors.iconAccent};
  }

`;

export const GradientBox = styled(Box)`
 position:  relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999 ;
    right: 0;
    background: linear-gradient(180deg, ${({ theme }) => theme.colors.module} 50%, transparent 100%);
  }
`;
export const Content = styled.div`

`;

export const NotificationsListActions = styled(Box)`
  display: none;

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    display: flex;
  }
`;

export const NotificationCardWrapper = styled(Box)`
  width: 400px;

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    width: 470px;
  }
`;

export const IconWrapper = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.canvas};
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: ${({ theme }) => theme.rounds.button1Round} ;
  font-size: 36px;
  padding: 0;
  width: 40px;
  height: 40px;
  
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: transparent;
    border: none;
  }
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;

`;
export const ItemText = styled.p`
  ${({ theme }) => theme.fonts.text16Medium};
  margin-bottom: 15px;
`;

export const CoveredBlock = styled.div`
  position: absolute;
  z-index: 1;
  width: 500px;
  height: ${({ theme }) => theme.spacings.spacing48};
  bottom: 0;
  right: 50px;
  background: ${({ theme }) => theme.colors.fade1};
`;

export const DisabledNotificationButton = styled(Text)`
  min-height: 32px;
  height: 32px; 
  padding: 0 ${({ theme }) => theme.spacings.m};
${({ theme }) => theme.fonts.text14Regular};
color: ${({ theme }) => theme.colors.textDisabled};
display: flex ;
  align-items: center ;
  justify-content: center;
`;
