import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const AllButton = styled.button`
   ${({ theme }) => theme.fonts.text16Medium};
   color: ${({ theme }) => theme.colors.textAccent};
   padding: 0;
   padding-left: ${({ theme }) => theme.spacings.spacing8};
   background-color: transparent;
   border: none;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.module};
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
  padding: ${({ theme }) => theme.spacings.spacing24};
  margin-top: ${({ theme }) => theme.spacings.spacing16};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.spacing16};
`;

export const Header = styled.h5`
  ${({ theme }) => theme.fonts.text16Bold};
  color: ${({ theme }) => theme.colors.blockTitle || ''};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
interface CalendarWrapperProps {
  disabled?: boolean;
}

export const CalendarWrapper = styled.div<CalendarWrapperProps>`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ disabled }) => (disabled ? undefined : '3px')};
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SearchInputWrapper = styled(Box)`
  max-width: 230px;
  width: 100%;
  margin-left: ${({ theme }) => theme.spacings.spacing8};
`;

export const DropdownInputWrapper = styled(Box)`
  max-width: ${({ theme }) => theme.controls.mediumSize};
  width: 100%;
  margin-left: ${({ theme }) => theme.spacings.spacing8};
`;
