import React, { lazy, Suspense } from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import Snackbar from '@/components/Snackbar';
import { OrgLockBanner } from '@/modules/core/components/OrgLockBanner';
import { useOrganizationFavicon } from '@/modules/core/hooks';
import useWhiteLabelingFavicon from '@/modules/core/hooks/useWhiteLabelingFavicon';
import { useAuthStore } from '@/modules/user';
import { useVersionChecker } from '@/utils';

import { ContinueInAppPopupStandalone } from '../ContinueInAppPopup';
import DashboardWrapper from '../DashboardWrapper';
import { EmployerTerminationBanner } from '../EmployerTermination/EmployerTerminationBanner';
import { ErrorBoundary } from '../ErrorBoundary';

import useAppRedirect from './useAppRedirect';
import Zendesk from './Zendesk';

import './App.css';

const TimeoutModal = lazy(() => import('../TimeoutModal'));

function App() {
  const {
    loading, routes,
  } = useAppRedirect();
  useVersionChecker();
  useOrganizationFavicon();
  useWhiteLabelingFavicon();
  const auth = useAuthStore((state) => state.auth);

  if (loading) {
    return null;
  }

  return (
    <>
      <Snackbar />
      <ContinueInAppPopupStandalone/>
  
      {auth && (

        <Suspense fallback={null}>
          <TimeoutModal />
        </Suspense>
      )}
      <OrgLockBanner />
      <EmployerTerminationBanner />
      <DashboardWrapper>
        <ErrorBoundary>
          <Switch>
            {routes.map((route) => (
              <Route key={`${route.path}`} {...route} />
            ))}
          </Switch>
        </ErrorBoundary>
      </DashboardWrapper>
      <Zendesk />
    </>
  );
}

export default App;
