import { Anchor, LeafIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledLeafIcon = styled(LeafIcon)`
  position: absolute;
  bottom: 0px;
  right: ${({ theme }) => theme.spacings.spacing24};
`;

export const StyledAnchor = styled(Anchor)`
  font-weight: 400;
`;
