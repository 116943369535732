import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const useRemoveDocumentQuery = () => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (id: string) => api.delete(PATHS.DELETE_DOCUMENT(id)),
  );
  return {
    remove: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useRemoveDocumentQuery;
