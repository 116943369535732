import React, { useCallback } from 'react';
import { Enrollment } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';

import { RecurringContribution } from '../contribution.types';

import MakeContributionModalForm from './MakeContributionModalForm';

const MODAL_TITLE = 'Make a Contribution';
const MODIFY_RECURRING_MODAL_TITLE = 'Modify Recurring Contribution';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  account?: Enrollment;
  currentRecurringContribution?: RecurringContribution;
  editMode?: boolean;
}

export const MakeContributionModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  modalTitle = MODAL_TITLE,
  account,
  currentRecurringContribution,
  editMode,
}) => {
  const handleSetVisible = useCallback(() => {
    onSetVisible(false);
  }, [
    onSetVisible,
  ]);

  return (
    <ModalWrapper
      testId="ModalWrapper"
      visible={visible}
      onSetVisible={handleSetVisible}
      title={currentRecurringContribution ? MODIFY_RECURRING_MODAL_TITLE : modalTitle}
      titleBoxStyle={{ paddingBottom: '8px' }}
    >

      <MakeContributionModalForm
        onCancel={handleSetVisible}
        account={account}
        currentRecurringContribution={currentRecurringContribution}
        editMode={editMode}
      />
    </ModalWrapper>
  );
};
