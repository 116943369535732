import { useMutation } from 'react-query';
import { toNumber } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

const useUpdateClaimDocumentByHashIdQuery = (
  hashId?: string,
  claimId?: string,
) => {
  const {
    mutateAsync, isLoading, isError, isSuccess,
  } = useMutation(
    (documentId?: string) => api.put(PATHS.UPDATE_CLAIM_DOCUMENT_ID_BY_HASH_ID(claimId, hashId), {
      document_id: toNumber(documentId),
    }),
  );
 
  return {
    updateClaimDocumentId: mutateAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useUpdateClaimDocumentByHashIdQuery;
