import { storeCreator } from '@/utils/store/store.utils';

interface State {
  visible: boolean;
  setVisible: (value: boolean) => void;

}

const useStore = storeCreator<State>((set) => ({
  visible: false,

  setVisible: (value) => set(() => ({
    visible: value,
  })),
}));

export default useStore;
