import { storeCreator } from '@/utils/store/store.utils';

export enum VerifyMethod {
  Phonenumber = 'phonenumber',
  Email = 'email',
}
export interface AccountAccessPayload {
  username: string;
  password: string;
  confirmPassword?: string;
  verifyMethod?: VerifyMethod;
}
interface State {
  state: AccountAccessPayload;
  setState: (values: Partial<AccountAccessPayload>) => void;
  errors: Partial<AccountAccessPayload>;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
  setErrors: (values: Partial<AccountAccessPayload>) => void
}

export const useStore = storeCreator<State>((set) => ({
  state: {
    username: '',
    password: '',
    confirmPassword: '',
    verifyMethod: VerifyMethod.Email,
  },
  errors: {},
  showErrors: false,
  setState: (values: Partial<AccountAccessPayload>) => set((currentState) => ({
    state: {
      ...currentState.state,
      ...values,
    },
  })),
  setShowErrors: (value: boolean) => set(() => ({
    showErrors: value,
  })),
  setErrors: (values: Partial<AccountAccessPayload>) => set(() => ({
    errors: values,
  })),
}));

export default useStore;
