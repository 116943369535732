import { UploadFileByQrLinkStorageKey } from '@/common/constants';

const keysToPreserve: string[] = [
  UploadFileByQrLinkStorageKey.DOCUMENT_ID_STORAGE_KEY,
  UploadFileByQrLinkStorageKey.EMPLOYEE_ID_STORAGE_KEY,
  UploadFileByQrLinkStorageKey.QR_ID_STORAGE_KEY,
  UploadFileByQrLinkStorageKey.VALID_TO_STORAGE_KEY,
];

export const clearLocalStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    if (!keysToPreserve.includes(key)) {
      localStorage.removeItem(key);
    }
  });
};
