export const DISMISSED_BANNERS_STORAGE_KEY = 'DismissedBannersStorageKey';
export const YES_KEY = 'true';

export enum BannerStorageKey {
  ImportantChangesBanner = 'ImportantChangesBanner',
  GoPaperlessBanner = 'GoPaperlessBanner',
}

export enum BannerActionCompleted {
  ImportantChangesBannerAction = 'ImportantChangesBannerAction',
}
