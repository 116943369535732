import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailVerificationOutlineIcon, PhoneVerificationIcon,  } from '@common-fe/common-fe';

import { MFAChannelType } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';

interface MFAChannelDescription {
  icon: React.ReactNode;
  name: string;
}

const useMFAChannelsDescription = (channelType?: MFAChannelType): MFAChannelDescription => {
  const { t } = useTranslation();

  switch (channelType) {
  case MFAChannelType.EMAIL:
    return {
      icon: <EmailVerificationOutlineIcon size="128px" />,
      name: t('Email Verification'),
    };
  case MFAChannelType.SMS: 
    return {
      icon: <PhoneVerificationIcon size="128px" />,
      name: t('Phone Verification'),
    };
  default:
    return {
      icon: <></>,
      name: '',
    };
  }
};

export default useMFAChannelsDescription;
