import { useMemo } from 'react';
import _ from 'lodash';

import { useInvestmentsHoldingsQuery } from '@/modules/investments/queries';

import { FundsPayload } from '../../SelfDirected/SelfDirected.types';

interface Params {
  accountId?: string;

}
export default (params?: Params, isQueryDisabled?: boolean) => {

  const {data , isLoading} = useInvestmentsHoldingsQuery(params?.accountId, isQueryDisabled);
  
  const formattedData = useMemo(() => {
    const list =  data?.map((item) => {
      const formattedItem: FundsPayload = {
        id: `${item.id || _.get(item, '@type', '')}`,
        logo: item.image,
        shares: item.shares,
        title: item.name,
        stockCode: item.symbol,
        link: item.researchURL,
        price: item.sharePrice,
        yr3: null,
        yr5: null,
        category: item.categoryType || '',
        assetClass: item.type,
        amount: item.amount,
        election: item.election,
        status: item.status
      };
      return formattedItem; 
    });
    
    return list;
  }, [data]);

  // FundsPayload
  return {
    data: formattedData,
    isLoading,
    total: formattedData.length
  };
};