import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, getFullnameAbbr,Text } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';

import { useBreadcrumbs } from '../core/hooks';

import { useDependents } from './SpendTracker/hooks';
import SpendTracker from './SpendTracker/SpendTracker';
// import { useAuthStore } from '../user';

interface Props {
  deductibleId?: string;
}
const Deductibles: React.FC<Props> = ({ deductibleId }) => {
  const { t } = useTranslation();
  // const currentUserId = useMemo(
  //   () => currentUser?.employee?.id,
  //   [currentUser],
  // );
  const users = useDependents({
    userId: deductibleId, isIndividual: true,
  });

  const user = useMemo(() => {
    if (users.length) {
      return users[0];
    }
    return null;
  }, [users]);
  const abbr = useMemo(() => getFullnameAbbr(user?.fullName || ''), [user]);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.SPENDS_BY_ID(deductibleId),
    title: t(`Deductible ${user?.fullName}`),
    value: ROUTES.SPENDS_BY_ID(deductibleId),
  }], true);

  return (
    <Box direction="column" fill>
      <Box
        margin={{ bottom: 'l' }}
        pad={{ vertical: 'xxs', horizontal: 'xs' }}
        direction="row"
        round="l"
        align="center"
      >
        <Box
          width="40px"
          height="40px"
          align="center"
          round="50%"
          justify="center"
          background="subgroup"
        >
          <Text color="iconOnColor" weight={700}>
            {abbr}
          </Text>
        </Box>
        <Text
          margin={{ left: 'xs' }}
          size="3xl"
          color="textBody"
          weight={700}
        >
          {user?.fullName || ''}

        </Text>
      </Box>

      <SpendTracker isIndividual deductibleId={deductibleId} />
    </Box>
  );
};

export default Deductibles;
