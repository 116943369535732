import { EmployeeView } from '@/modules/user/user.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  employee?: EmployeeView,
  setEmployee: (value: EmployeeView) => void,
  reset: () => void,
}

const useStore = storeCreator<State>((set) => ({
  employee: undefined,
  setEmployee: (newState: EmployeeView) => set((oldState) => ({
    employee: {
      ...oldState.employee,
      ...newState,
    },
  })),
  reset: () => set(() => ({
    employee: undefined,
  })),
}));

export default useStore;
