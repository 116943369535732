import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.canvas};
  min-width: 260px;
  max-width: 260px;
  ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  height: fit-content;
  .transaction-statuses-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 45px;
  }
  .fixed-height {
    max-height: 284px;
    overflow: hidden;
    padding-bottom: ${({ theme }) => theme.spacings.spacing32};
  }
  .full-height {
    padding-bottom: ${({ theme }) => theme.spacings.spacing32};
    box-shadow: 0px 8px 15px rgba(0, 38, 102, 0.1);
  }
  position: relative;

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    min-width: 320px;
    max-width: 320px;
  }
`;

export const Title = styled.p`
  ${({ theme }) => theme.fonts.text16Bold};
  margin-bottom: ${({ theme }) => theme.spacings.spacing16};
`;

export const CoveredBlock = styled(Box)`
  position: absolute;
  height: ${({ theme }) => theme.spacings.spacing20};
  left: 0px;
  right: 0px;
  bottom: 45px;
  background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0.95) 43.79%, rgba(255, 255, 255, 0) 100%);
`;
