/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivityType } from '@common-fe/common-fe';
export enum TransactionStatusKeys {
  Processing = 'PROCESSING',
  Reprocessing = 'REPROCESSING',
  NeedsHelp = 'NEEDS_HELP',
  Paid = 'PAID',
  Error = 'ERROR',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyApproved = 'PARTIALLY_APPROVED',
  RepaymentRequired = 'REPAYMENT_REQUIRED',
  WaitingForFunds = 'WAITING_FOR_FUNDs',
  Pending = 'PENDING',
  PendingReview = 'PENDING_REVIEW',
  Repaid = 'REPAID',
  Denied = 'DENIED',
  Approved = 'APPROVED',
  LoA = 'LOA',
  Expired = 'EXPIRED',
  Uploading = 'UPLOADING',
  Complete = 'COMPLETE',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
  DocsNeeded = 'DOCS_NEEDED',
  Retirement = 'RETIREMENT',
  Future = 'FUTURE',
  OutOfPocket = 'OUT_OF_POCKET',
  Locked = 'LOCKED',
  Terrminated = 'TERRMINATED',
  AppliedToDeductible = 'APPLIED_TO_DEDUCTIBLE',
  Voided = 'VOIDED',
  StopProcessing = 'STOP_PROCESSING',
}

export enum TransactionStatus {
  Processing = 'Processing',
  Reprocessing = 'Reprocessing',
  NeedsHelp = 'Needs help',
  Paid = 'Paid',
  Error = 'Error',
  PartiallyPaid = 'Partially paid',
  PartiallyApproved = 'Partially approved',
  RepaymentRequired = 'Repayment Required',
  WaitingForFunds = 'Waiting for funds',
  Pending = 'Pending',
  PendingReview = 'Pending review',
  Repaid = 'Repaid',
  Denied = 'Denied',
  Approved = 'Approved',
  LoA = 'LoA',
  Expired = 'Expired',
  Uploading = 'Uploading',
  Complete = 'Complete',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  DocsNeeded = 'Docs needed',
  Retirement = 'Retirement',
  Future = 'Future',
  OutOfPocket = 'Out of pocket',
  Locked = 'Locked',
  Terrminated = 'Terrminated',
  AppliedToDeductible = 'Applied to deductible',
  Voided = 'Voided',
  StopProcessing = 'Stop processing',
}

export interface OffsetRepaymentPayload {
  donor_claim_id: number;
  recipient_claim_id: number;
  amount: number;
  created_at: string;
}

export interface OffsetRepaymentDto {
  donorClaimId: string;
  recipientClaimId: string;
  amount: number;
  date: string;
}

export enum TransactionType {
  PAY_CLAIM = 'PAY_CLAIM',
  CARD_SETTLEMENT = 'CARD_SETTLEMENT',
  NOTIONAL_FUND = 'NOTIONAL_FUND',
  CHECK_CLEARED = 'CHECK_CLEARED',
  CONTRIBUTION_PAYMENT = 'CONTRIBUTION_PAYMENT',
  PAYROLL = 'PAYROLL',
  NOTIONAL_PAYROLL = 'NOTIONAL_PAYROLL',
  INTEREST = 'INTEREST',
  FEE = 'FEE',
  PAYMENT = 'PAYMENT',
  TRANSFER_ADJUSMENT = 'TRANSFER_ADJUSMENT',
  INVESTMENT = 'INVESTMENT',
  BUY_INVESTMENT = 'BUY_INVESTMENT',
  SELL_INVESTMENT = 'SELL_INVESTMENT',
  ROLLOVER = 'ROLLOVER',
  PAYMENT_CARD_RETURN = 'PAYMENT_CARD_RETURN',
  TRANSFER_OF_ASSETS = 'TRANSFER_OF_ASSETS',
  INTERNAL_TRANSFER_OF_ASSETS = 'INTERNAL_TRANSFER_OF_ASSETS',
  CLAIM_REPAYMENT = 'CLAIM_REPAYMENT',
  HSA_INCOMING_CONTRIBUTION = 'HSA_INCOMING_CONTRIBUTION',
  HSA_ROLLOVER = 'HSA_ROLLOVER',
  HSA_INCOMING_CONTRIBUTION_REVERSE = 'HSA_INCOMING_CONTRIBUTION_REVERSE',
  HSA_ROLLOVER_REVERSE = 'HSA_ROLLOVER_REVERSE',
  PAYROLL_REVERSE = 'PAYROLL_REVERSE',
}

export enum PaymentType {
  REIMBURSEMENT = 'REIMBURSEMENT',
  BILL_PAY = 'BILL_PAY',
  CARD_SWIPE = 'CARD_SWIPE',
}

export enum TransactionApprovalCode {
  EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES = 'EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES',
  EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES = 'EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES',
  SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD = 'SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD',
  WAITING_FOR_FUNDS = 'WAITING_FOR_FUNDS',
  CLAIM_RECEIVED_AFTER_RUNOUT_DATE = 'CLAIM_RECEIVED_AFTER_RUNOUT_DATE',
  CLAIM_SUBMITTED_AFTER_GRACE_PERIOD = 'CLAIM_SUBMITTED_AFTER_GRACE_PERIOD',
  DUPLICATE_CLAIM = 'DUPLICATE_CLAIM',
  LETTER_OF_MEDICAL_NECESSITY_REQUIRED = 'LETTER_OF_MEDICAL_NECESSITY_REQUIRED',
  REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT = 'REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT',
  PROOF_OF_EXPENSE_NEEDED = 'PROOF_OF_EXPENSE_NEEDED',

  DETAILED_RECEIPT_REQUIRED = 'DETAILED_RECEIPT_REQUIRED',
  ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION = 'ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION',
  CUSTOM = 'CUSTOM',

  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID_INFO = 'PAID_INFO',
  DECLINED = 'DECLINED',

  WAITING_FOR_FUNDS_NO_PAID = 'WAITING_FOR_FUNDS_NO_PAID',
  WAITING_FOR_FUNDS_PAID = 'WAITING_FOR_FUNDS_PAID',
  WAITING_FOR_FUNDS_PARTIALLY_APPROVED_PARTIALLY_PAID = 'WAITING_FOR_FUNDS_PARTIALLY_APPROVED_PARTIALLY_PAID',
  WAITING_FOR_FUNDS_APPROVED_PARTIALLY_PAID = 'WAITING_FOR_FUNDS_APPROVED_PARTIALLY_PAID',

  CARD_SWIPE_DECLINED_BY_CARD_PROCESSOR = 'CARD_SWIPE_DECLINED_BY_CARD_PROCESSOR',

  EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES_PARTIALLY = 'EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES_PARTIALLY',
  EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES_PARTIALLY = 'EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES_PARTIALLY',
  SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD_PARTIALLY = 'SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD_PARTIALLY',
  DUPLICATE_CLAIM_PARTIALLY = 'DUPLICATE_CLAIM_PARTIALLY',
  REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT_PARTIALLY = 'REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT_PARTIALLY',
  ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION_PARTIALLY = 'ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION_PARTIALLY',
  DETAILED_RECEIPT_REQUIRED_PARTIALLY = 'DETAILED_RECEIPT_REQUIRED_PARTIALLY',
  PROOF_OF_EXPENSE_NEEDED_PARTIALLY = 'PROOF_OF_EXPENSE_NEEDED_PARTIALLY',
  LETTER_OF_MEDICAL_NECESSITY_REQUIRED_PARTIALLY = 'LETTER_OF_MEDICAL_NECESSITY_REQUIRED_PARTIALLY',
  CUSTOM_PARTIALLY = 'CUSTOM_PARTIALLY',

  OUT_OF_POCKET = 'OUT_OF_POCKET',
  APPLIED_TO_DEDUCTIBLE = 'APPLIED_TO_DEDUCTIBLE',

  DECLINED_CLAIM_GENERIC_TYPE = 'DECLINED_CLAIM_GENERIC_TYPE',
  DECLINED_CLAIM_CARD_NOT_ACTIVATED = 'DECLINED_CLAIM_CARD_NOT_ACTIVATED',
  DECLINED_CLAIM_CARD_LOCKED_FROZEN = 'DECLINED_CLAIM_CARD_LOCKED_FROZEN',
  DECLINED_CLAIM_CARD_LOST_STOLEN = 'DECLINED_CLAIM_CARD_LOST_STOLEN',
  AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_IIAS = 'AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_IIAS',
  AUTOMATICALLY_APPROVED_COPAY_MATCH = 'AUTOMATICALLY_APPROVED_COPAY_MATCH',
  AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_COPAY_MATCH = 'AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_COPAY_MATCH',
  AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_TRUSTED_MERCHANT = 'AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_TRUSTED_MERCHANT',
  CLAIM_COULD_NOT_BE_AUTO_SUBSTANTIATED_BY_CARD_PROCESSOR = 'CLAIM_COULD_NOT_BE_AUTO_SUBSTANTIATED_BY_CARD_PROCESSOR',
  AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_CARRIER_CLAIM = 'AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_CARRIER_CLAIM',
  AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_RECURRING_CLAIM = 'AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_RECURRING_CLAIM',
  AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_SUBSTANTIATION_NOT_REQUIRED = 'AUTOMATICALLY_APPROVED_BY_CARD_PROCESSOR_SUBSTANTIATION_NOT_REQUIRED',
  AUTOMATICALLY_APPROVED_NO_RECEIPT_REQUIRED = 'AUTOMATICALLY_APPROVED_NO_RECEIPT_REQUIRED',
  AUTOMATICALLY_APPROVED_CARRIER_CLAIM = 'AUTOMATICALLY_APPROVED_CARRIER_CLAIM',

  CARRIER_CLAIM = 'CARRIER_CLAIM',

  PIN_REQUIRED = 'PIN_REQUIRED',
  NO_AVAILABLE_BALANCE = 'NO_AVAILABLE_BALANCE',
  NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
  NOT_HEALTHCARE_SERVICE = 'NOT_HEALTHCARE_SERVICE',
  NOT_ALLOWED_SERVICE_FOR_PLAN = 'NOT_ALLOWED_SERVICE_FOR_PLAN',
  PRIOR_TO_PLAN_START_DATE = 'PRIOR_TO_PLAN_START_DATE',
  PAST_PLAN_END_DATE = 'PAST_PLAN_END_DATE',
  CVS_NOT_ALLOWED = 'CVS_NOT_ALLOWED',
  PIN_INVALID = 'PIN_INVALID',
  ORG_LOCKED = 'ORG_LOCKED',
  OVERLIMIT = 'OVERLIMIT',
}

export enum TransactionActivityType {
  Paid = 'Paid',
  Used = 'Used',
  Success = 'Success',
  UploadReceipt = 'UploadReceipt',
  Appeal = 'Appeal',
}

export enum ProcessingStatusType {
  Submitted = 'SUBMITTED',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Processing = 'PROCESSING',
  PartiallyRepaid = 'PARTIALLY_REPAID',
  Repaid = 'REPAID',
  WaitingForFunds = 'WAITING_FOR_FUNDS',
  Authorized = 'AUTHORIZED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  DuplicateTracking = 'DEDUCTIBLE_TRACKING',
  ActionRequired = 'ACTION_REQUIRED',
  StopProcessing = 'STOP_PROCESSING',
}

export interface Activity {
  id: string;
  type: TransactionType;
  person?: string;
  status: TransactionStatus;
  text: string
  amount: number,
  date: Date,
  description?: string;
}
export interface Notification {
  approvalCode?: TransactionApprovalCode;
  amount?: number | null;
  invoicePostingId?: string;
  status?: TransactionStatus;
  validFrom: string;
  validTo?: string;
  date: string;
  customDenialReason?: string;
}

export interface ExpenseOption {
  key: string;
  title: string,
  value: number;
  amount?: number;
}

export interface Expense {
  category_type: string;
  percent: number;
  amount: number;
}

export interface ExpensesStatisticsDto {
  total_expenses: number;
  expenses?: Expense[];
}

export interface ActivityDetailsParams {
  keys?: string;
  dates?: string;
}

export interface TransactionActivity {
  id: string;
  type: TransactionActivityType;
  datetime: Date;
  text: string;
  money?: number
}

export interface ExpandedActivity extends Activity {
  statuses: Status[];
  activities: TransactionActivity[];
}

export interface MyAccount {
  key: string;
  type: string;
  navigationTitle: string;
  title: string;
  description?: string;
  balance?: number;
  election?: number
  expenses: ExpenseOption[];
}

export interface SearchTransactionParams {
  category_ids?: string;
  statuses?: string;
  provider_name?: string;
  status_date_from?: string | null;
  status_date_to?: string | null;
  accountTypes?: string;
  years?: string;
  page?: number;
   
  size?: number;
}

export interface Election {
  id: number;
  policy_id: number;
  amount: number;
  valid_from: Date;
  valid_to: Date;
}

export interface PlanYear {
  id: number;
  organization_id: number;
  name: string;
  valid_from: Date;
  valid_to: any;
}

export interface Plan {
  id: number;
  plan_year_id?: number;
  name: string;
  name_state: string;
  plan_code: string;
  plan_code_state: string;
  account_type: string;
  account_type_state: string;
  carded: boolean;
  carded_state: string;
  cip_required: boolean;
  cip_required_state: string;
  parent_id?: number;
  is_plan: boolean;
  plan_year: PlanYear;
}

export interface ExpensesDetailsParams {
  account_id?: number;
  category_type?: string;
  date_from?: Date | string;
  date_to?: Date | string;
}

export interface TransactionDto {
  category_type: string;
  paid_amount: number;
  provider_name: string;
  requested_amount: number;
  status: string;
  status_date: Date | string;
  claim_id: number;
}

export interface Transaction {
  categoryType: ActivityType;
  paidAmount: number;
  provider: string;
  requestedAmount: number;
  status: string;
  statusDate: Date | string;
  claimId: string;
}

export interface NotificationResponse {
  invoice_posting_id: number;
  approval_code: TransactionApprovalCode;
  status?: TransactionStatus;
  valid_from: Date | string;
  valid_to?: Date | string;
  custom_denial_reason?: string;
}

export interface StatusResponse {
  status: TransactionStatus;
  valid_from: Date | string;
  valid_to: Date | string;
}

export interface NonClaimTransactionResponse {
  amount: number;
  category: ActivityType;
  employee_id: number;
  memo: string;
  plan_name: string;
  posting_id: number;
  status: TransactionStatus;
  status_date: string;
  transaction_type: TransactionType;
  payment_method_id: string;
  tax_year: string;
}

export interface TransactionServiceForResponse {
  relationship_type?: string;
  name: string;
  is_hidden?: boolean;
  is_view_name_allowed?: boolean;
}

export interface TransactionPostingsResponse {
  account_id?: number;
  plan_name?: string;
  amount?: number;
  transaction_at: Date | string;
}

export interface ServicePayload {
  id: number;
  category_type: string;
  displayed: boolean;
  name: string;
}

export interface Service {
  id: string;
  categoryType: string;
  displayed: boolean;
  name: string;
}

export enum DeclineReasonTypes {
  GENERIC = 'GENERIC',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  CARD_LOCKED = 'CARD_LOCKED',
  CARD_LOST = 'CARD_LOST',
  CARD_EXPIRED = 'CARD_EXPIRED',
  PIN_REQUIRED = 'PIN_REQUIRED',
  NO_AVAILABLE_BALANCE = 'NO_AVAILABLE_BALANCE',
  NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
  NOT_HEALTHCARE_SERVICE = 'NOT_HEALTHCARE_SERVICE',
  NOT_ALLOWED_SERVICE_FOR_PLAN = 'NOT_ALLOWED_SERVICE_FOR_PLAN',
  PRIOR_TO_PLAN_START_DATE = 'PRIOR_TO_PLAN_START_DATE',
  PAST_PLAN_END_DATE = 'PAST_PLAN_END_DATE',
  CVS_NOT_ALLOWED = 'CVS_NOT_ALLOWED',
  PIN_INVALID = 'PIN_INVALID',
  ORG_LOCKED = 'ORG_LOCKED',
  OVERLIMIT = 'OVERLIMIT',
  PEND_CONTRIBUTIONS = 'PEND_CONTRIBUTIONS',
  PEND_CONTRIBUTIONS_AND_CIP = 'PEND_CONTRIBUTIONS_AND_CIP',
}

export enum ClaimNoteGroup {
  Warning = 'WARN',
  Information = 'INFO',
}
export enum ClaimNoteType {
  NO_PAY_OUT_NOTE = 'NO_PAY_OUT_NOTE',
  SPEND_LIMIT_NOTE = 'SPEND_LIMIT_NOTE',
  CHECK_EXPIRED_NOTE = 'CHECK_EXPIRED_NOTE',
  CHECK_VOIDED_NOTE = 'CHECK_VOIDED_NOTE',
  WAITING_FOR_FUNDS_EXPIRED = 'WAITING_FOR_FUNDS_EXPIRED',
}
export interface ClaimNotePayload {
  claimNoteGroup: ClaimNoteGroup;
  claimNoteType: ClaimNoteType;
  createdAt: string;
  date: string;
}
export interface ClaimNote {
  claimNoteGroup: ClaimNoteGroup;
  claimNoteType: ClaimNoteType;
  createdAt: string;
  date: string;
}

export interface DeclineReasonTypePayload {
  reason_type?: DeclineReasonTypes;
  json_data?: string;
}

export interface ClaimTransferPayload {
  id?: number;
  claim_id?: number;
  account_from_id?: number;
  account_from_name?: string;
  account_to_id?: number;
  account_to_name?: string;
  posting_id_from?: number;
  posting_id_to?: number;
  created_by_contact_id?: number;
  created_at?: string;
  amount?: number;
}

export interface TransactionResponse {
  document_id: number;
  amount: number;
  paid_amount: number;
  approved_amount: number;
  category_type: string;
  claim_id?: number | string;
  created_at: Date | string | number;
  local_date_service_on: string;
  notifications?: NotificationResponse[];
  claim_note_dto_list?: ClaimNotePayload[];
  claim_offset_dtos?: OffsetRepaymentPayload[];
  memo: string;
  provider_id?: number;
  service: ServicePayload;
  service_for: TransactionServiceForResponse;
  statuses?: StatusResponse[];
  payment_type: PaymentType;
  provider_invoice?: string;
  postings: TransactionPostingsResponse[];
  claim_transfers: ClaimTransferPayload[];
  transaction_type?: TransactionType;
  processing_status?: ProcessingStatusType;
  processing_substatus_type?: TransactionApprovalCode;
  apply_deductible_amount?: number | null;
  decline_reason_types?: DeclineReasonTypePayload[];
  payment_method_id?: string;
  tax_year?: string;
}

export interface Status {
  status: TransactionStatus;
  validFrom: string;
  date: string;
  validTo: Date | string;
}

export enum ApprovalStatus {
  DOCS_NEEDED = 'Docs needed',
}

export interface NonClaimTransactionData {
  amount: number
  category: ActivityType
  employeeId: number
  memo: string
  planName: string
  postingId: number
  statuses: Status[];
  statusDate: Date | string;
  transactionType: TransactionType;
  paymentType?: PaymentType;
  processingStatus?: ProcessingStatusType,
  providerId?: string;
  paymentMethodId?: string;
  taxYear?: string;
}

export interface TransactionServiceForData {
  relationshipType?: string;
  name: string;
  isViewNameAllowed?: boolean;
  isHidden?: boolean;
}

export interface TransactionPostingsData {
  accountId?: string;
  planName?: string;
  amount?: number;
  transactionAt: string;
  date: string;
}

export interface TransactionActivities {
  notifications?: Notification[];
  postings?: TransactionPostingsData[];
  claimNoteList?: ClaimNote[];
  claimOffsetDonors?: OffsetRepaymentDto[];
  claimOffsetRecipients?: OffsetRepaymentDto[];
  successStatuses?: Status[];
  statuses?: Status[];
  currentStatus: TransactionStatus;
}

export interface TransactionActivitiesData {
  [key: string]: TransactionActivities;
}

export interface JsonDataItemPayload {
  submit_claims_date_end?: string;
  card_id?: number;
  account_id?: number;
  card_swipe_date?: string;
  coverage_date_start?: string;
  coverage_date_end?: string;
  plan_name?: string;
  spend_limit?: number;
  amount?: number;
  provider?: number;
}

export interface JsonDataItem {
  submitClaimsDateEnd?: string;
  cardId?: string;
  accountId?: string;
  cardSwipeDate?: string;
  coverageDateStart?: string;
  coverageDateEnd?: string;
  planName?: string;
  spendLimit?: number;
  amount?: number;
  provider?: number;
}
export interface DeclineReasonType {
  type?: DeclineReasonTypes;
  jsonData?: JsonDataItem[];
  date: string;
}

export interface ClaimTransferData {
  id?: string;
  claimId?: string;
  accountFromId?: string;
  accountFromName?: string;
  accountToId?: string;
  accountToName?: string;
  postingIdFrom?: string;
  postingIdTo?: string;
  createdByContactId?: string;
  createdAt?: string;
  amount?: number;
  date: string;
}

export interface TransactionData {
  approvalStatus?: ApprovalStatus | string;
  documentId?: string;
  amount: number;
  paidAmount?: number;
  approvedAmount?: number;
  category: ActivityType;
  id?: number;
  createdAt?: string;
  serviceOn?: string;
  invoiceId?: string;
  notifications: Notification[];
  memo: string;
  providerId?: string;
  service?: Service;
  serviceFor: TransactionServiceForData;
  statuses: Status[];
  paymentType: PaymentType;
  postings: TransactionPostingsData[];
  claimTransfers?: ClaimTransferData[];
  transactionType: TransactionType;
  processingStatus?: ProcessingStatusType;
  processingSubstatusType?: TransactionApprovalCode;
  applyDeductibleAmount?: number | null;
  declineReasonTypes?: DeclineReasonType[];
  claimNoteList: ClaimNote[];
  claimOffsetDonors?: OffsetRepaymentDto[];
  paymentMethodId?: string;
  taxYear?: string;
}

export interface Transactor {
  providerName: string;
  providerAddress1: string;
  providerAddress2: string;
  providerCity: string;
  providerState: string;
  providerZipCode: string;
}

export interface TransactorResponse {
  id: number;
  card_acceptor_id: number | null;
  facility_name: string | null;
  first_name: string | null;
  last_name: string | null;
  tax_id: number | null;
  addresses?: AddressResponse[];
}

export interface AddressResponse {
  city?: string;
  line1?: string;
  line2?: string;
  state?: StateResponse;
  zipcode?: string;
}

export interface StateResponse {
  code?: string;
  id?: number;
  name?: string;
}

export enum IrsMaximumsTypes {
  HSA_MAX = 'HSA_MAX',
  HCFSA_MAX = 'HCFSA_MAX',
  DCAP_MAX = 'DCAP_MAX',
  TRANSIT_MAX = 'TRANSIT_MAX',
  PARKING_MAX = 'PARKING_MAX',
}

export enum IrsMaximumsFrequencyTypes {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
}

export enum IrsMaximumsCoverageTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  FAMILY = 'FAMILY',
  ROLLOVER = 'ROLLOVER',
}

export interface IrsMaximumsDto {
  id: number,
  type: IrsMaximumsTypes,
  tax_year: number,
  amount: number,
  frequency_type: IrsMaximumsFrequencyTypes,
  irs_coverage_level_type: IrsMaximumsCoverageTypes,
  tax_year_end: string,
}

export interface IrsMaximums {
  id: number,
  type: IrsMaximumsTypes,
  taxYear: string,
  amount: number,
  frequencyType: IrsMaximumsFrequencyTypes,
  irsCoverageLevelType: IrsMaximumsCoverageTypes,
  taxYearEnd: string,
}
