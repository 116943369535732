import React, { useCallback, useState } from 'react';
import { Preloader } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { PreventLeaveModal } from '@/modules/core/components';
import { useHistory } from '@/modules/core/hooks';
import { useMyEnrollments } from '@/modules/transaction';

import FundingAmount from '../BuySell/FundingAmount';

import { ManagedWrapper } from '.';
interface Props {
  accountId: string;
}
const ManagedSwitchQuestionnaire: React.FC<Props> = ({ accountId }) => {
  const [isPreventLeave, setPreventLeave] = useState(false);
  const { currentMyAccount, isLoading } = useMyEnrollments(accountId);

  const history = useHistory();
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleNext = useCallback(async () => {
    setPreventLeave(false);
    history.push(ROUTES.MANAGED_SWITCH_ALLOCATION(accountId));
  }, [accountId, history]);

  if (isLoading) {
    return <Preloader />;
  }
  return (
    <ManagedWrapper currentStep={2}>
      <PreventLeaveModal isPrevent={isPreventLeave} />
      <FundingAmount
        onCancel={handleBack}
        isSwitchMode
        onNext={handleNext}
        currentMyAccount={currentMyAccount}
        step={2}
        stepLength={3}
        accountId={accountId}
      />
    </ManagedWrapper>
  );
};
export default ManagedSwitchQuestionnaire;
