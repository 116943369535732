import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { OptionKey, usePaginationParams } from '@common-fe/common-fe';

import { NotificationsSearchParams } from '../../notification.types';

type DatesRange = [Date | null, Date | null];
const useListPagination = () => {
  const {
    page, setPage, setPerPage, perPage, searchString, setSearchString, currentSearch,
  } = usePaginationParams();
  const [currentDates, setCurrentDates] = useState<DatesRange>([null, null]);
  const [currentPrior, setCurrentPrior] = useState<OptionKey | undefined>('');

  const setSearchParams = useCallback((value: NotificationsSearchParams) => {
    setSearchString(value.searchString || '');
    setCurrentDates([value.fromDate || null, value.toDate || null]);
    setCurrentPrior(value.priority);
  }, [setSearchString]);
  const searchParams = useMemo(() => ({
    searchString,
    fromDate: currentDates[0],
    toDate: currentDates[1],
    priority: currentPrior,
  }), [currentDates, currentPrior, searchString]);

  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch]);
  return {
    page,
    setPage,
    setPerPage,
    perPage,
    searchParams,
    setSearchParams,
    // setFilterParam,
    // setStatuses,
    // setCategories,
  };
};

export default useListPagination;
