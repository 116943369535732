import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FlexControlledForm, PhoneVerificationIcon, useDebounceState } from '@common-fe/common-fe';

import REGEXP from '@/common/regexp';
import { CommunicationPreferencesError, PhoneVerificationStep, VerificationType } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';
import ResendCodeButton from '@/modules/user/components/Verification/components/ResendCodeButton';
import VerificationCodeModal from '@/modules/user/components/Verification/components/VerificationCodeModal';
import useVerificationCodeFields, { FormValues } from '@/modules/user/components/Verification/hooks/useVerificationCodeFields';
import useUpdateEmployeeQuery from '@/modules/user/queries/useUpdateEmployee.query';
import { useValidateForm } from '@/utils/hooks';

import useSendVerificationCodeQuery from '../../queries/useSendVerificationCode.query';
import useValidateVerifyCodeQuery from '../../queries/useValidateVerifyCode.query';

interface Props {
  phoneNumber: string;
  onSetVisible: (value: boolean) => void;
  setStep: (step: PhoneVerificationStep) => void;
}

const PhoneVerificationCodeModal: React.FC<Props> = ({ phoneNumber, onSetVisible, setStep }) => {
  const { t } = useTranslation();
  const fields = useVerificationCodeFields();
  const { updateEmployee } = useUpdateEmployeeQuery();
  const [state, handleSetState] = useState<FormValues>();
  const { values, setValues } = useDebounceState<FormValues>();
  const [verificationErrorText, setVerificationErrorText] = useState('');
  const { handlePreSubmit, hasErrors } = useValidateForm(fields);
  const { validateVerifyCode, isLoading } = useValidateVerifyCodeQuery();
  const { sendVerificationCode } = useSendVerificationCodeQuery();

  const handleGoBack = useCallback(() => {
    setStep(PhoneVerificationStep.ENTER_PHONE_NUMBER);
  }, [setStep]);

  const handleConfirm = useCallback(async () => {
    try {
      const isSuccess = handlePreSubmit(state);
      if (isSuccess) {
        setVerificationErrorText('');
        const response = await validateVerifyCode({
          code: state?.verificationCode?.replace(REGEXP.SPACE_SYMBOL, '') || '',
          value: phoneNumber,
          type: VerificationType.SMS,
        });
        if (!response?.verified) {
          throw new Error();
        }
        updateEmployee({ is_sms_notification_enabled: true });
        setStep(PhoneVerificationStep.PHONE_VERIFIED_SUCCESSFULLY);
      }
    } catch (e) {
      setVerificationErrorText(CommunicationPreferencesError.CODE_IS_INVALID);
    }
  }, [state, phoneNumber, setStep, validateVerifyCode, handlePreSubmit, updateEmployee]);

  const handleResendCode = useCallback(async () => {
    await sendVerificationCode({
      type: VerificationType.SMS,
      value: phoneNumber,
    });
  }, [sendVerificationCode, phoneNumber]);

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <VerificationCodeModal
      testId="phone-verification-code-modal"
      icon={<PhoneVerificationIcon size="128px" />}
      title={t('Phone Verification')}
      value={phoneNumber}
      isLoading={isLoading}
      errorText={verificationErrorText}
      onSetVisible={onSetVisible}
      onBack={handleGoBack}
      onConfirm={handleConfirm}
      form={(
        <FlexControlledForm
          fields={fields}
          onChangeValues={setValues}
          formStyle={{ margin: '24px' }}
          showError={hasErrors}
          editMode
        >
          <Box width="320px" alignSelf="end">
            <ResendCodeButton onResend={handleResendCode} />
          </Box>
        </FlexControlledForm>
      )}
    />
  );
};

export default PhoneVerificationCodeModal;
