import { useCallback, useEffect } from 'react';

import { UploadFileByQrLinkStorageKey } from '@/common/constants';
import { useSearchParams } from '@/modules/transaction/hooks/useSearchParams';

const useUploadFileByQrLinkParams = () => {
  const { query: qrIdFromParams } = useSearchParams('qr_id');
  const { query: employeeIdFromParams } = useSearchParams('employee_id');
  const { query: validToFromParams } = useSearchParams('valid_to');
  const { query: docIdFromParams } = useSearchParams('document_id');

  useEffect(() => {
    if (qrIdFromParams) {
      localStorage.setItem(UploadFileByQrLinkStorageKey.QR_ID_STORAGE_KEY, qrIdFromParams);
    }
    if (employeeIdFromParams) {
      localStorage.setItem(UploadFileByQrLinkStorageKey.EMPLOYEE_ID_STORAGE_KEY, employeeIdFromParams);
    }
    if (validToFromParams) {
      localStorage.setItem(UploadFileByQrLinkStorageKey.VALID_TO_STORAGE_KEY, validToFromParams);
    }
    if (docIdFromParams) {
      localStorage.setItem(UploadFileByQrLinkStorageKey.DOCUMENT_ID_STORAGE_KEY, docIdFromParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetStorage = useCallback(() => {
    localStorage.removeItem(UploadFileByQrLinkStorageKey.QR_ID_STORAGE_KEY);
    localStorage.removeItem(UploadFileByQrLinkStorageKey.EMPLOYEE_ID_STORAGE_KEY);
    localStorage.removeItem(UploadFileByQrLinkStorageKey.VALID_TO_STORAGE_KEY);
    localStorage.removeItem(UploadFileByQrLinkStorageKey.DOCUMENT_ID_STORAGE_KEY);
  }, []);

  return {
    qrId: qrIdFromParams || localStorage.getItem(UploadFileByQrLinkStorageKey.QR_ID_STORAGE_KEY),
    employeeId: employeeIdFromParams || localStorage.getItem(UploadFileByQrLinkStorageKey.EMPLOYEE_ID_STORAGE_KEY),
    validTo: validToFromParams || localStorage.getItem(UploadFileByQrLinkStorageKey.VALID_TO_STORAGE_KEY),
    docId: docIdFromParams || localStorage.getItem(UploadFileByQrLinkStorageKey.DOCUMENT_ID_STORAGE_KEY),
    handleResetStorage,
  };
};

export default useUploadFileByQrLinkParams;
