import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { AUTH_ERROR_KEY,PASSWORD_ERROR_MESSAGE } from '@/common/constants';
import REGEXP from '@/common/regexp';
import { centeredFieldStyles } from '@/modules/user/components/SignUp/signUp.constants';

export default () => {
  const { t } = useTranslation();
  const { PASSWORD_IS_REQUIRED } = AUTH_ERROR_KEY;

  const fields = useMemo<Field[]>(() => [
    {
      name: 'password',
      type: FieldTypes.Password,
      label: 'Password',
      isLarge: true,
      placeholder: 'Password',
      showRequireIcon: true,
      passwordHelper: true,
      isErrorLarge: true,
      validator: yup.string().required(t(PASSWORD_IS_REQUIRED))
        .min(8, t(PASSWORD_ERROR_MESSAGE))
        .matches(REGEXP.UPPER_AND_LOWERCASE, t(PASSWORD_ERROR_MESSAGE))
        .matches(REGEXP.ONE_NUMBER_ONLY, t(PASSWORD_ERROR_MESSAGE))
        .matches(REGEXP.ONE_SPECIAL_CHARACTER, t(PASSWORD_ERROR_MESSAGE))
        .test({
          test: (val) => val ? !REGEXP.FORBIDDEN_CHARACTERS.test(val) : true,
          message: t(PASSWORD_ERROR_MESSAGE),
        }),
      ...centeredFieldStyles,
    },
    {
      name: 'confirmPassword',
      type: FieldTypes.Password,
      label: 'Confirm password',
      isLarge: true,
      placeholder: 'Confirm password',
      showRequireIcon: true,
      validator: yup.string().required(t(PASSWORD_IS_REQUIRED))
        .oneOf([yup.ref('password')], t(PASSWORD_IS_REQUIRED)),
      ...centeredFieldStyles,
    },
  ], [
    t,
    PASSWORD_IS_REQUIRED,
  ]);
  return fields;
};
