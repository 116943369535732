import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  AppButton,   Box, Preloader,
  Text, } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';
import { useOrphanModeStore } from '@/modules/core/store';
import theme from '@/styles/theme';

import { useSwitchingModalStore } from '../store';

const SwitchingModal = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { prevLinkedEmployee, setLinkedEmployee } = useOrphanModeStore();
  const { isModalVisible, setIsModalVisible } = useSwitchingModalStore();

  const handleClose = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setLinkedEmployee(prevLinkedEmployee);
    queryClient.removeQueries();
  }, [setIsModalVisible, setLinkedEmployee, queryClient, prevLinkedEmployee]);

  return (
    <ModalWrapper
      visible={isModalVisible}
      onSetVisible={handleClose}
      testId="switching-modal"
      hideCloseButton
      contentStyle={{
        width: '276px',
        padding: `${theme.spacings.spacing48} ${theme.spacings.spacing24}`,
      }}
    >
      <Box>
        <Preloader />
        <Box margin={{ top: 'spacing24' }} align="center">
          <Text size={theme.spacings.spacing24} weight={700}>
            Switching…
          </Text>
        </Box>
        <Box margin={{ top: 'spacing24' }} align="center">
          <AppButton
            testId="modal"
            width="100%"
            onClick={handleCancel}
            buttonType="secondary"
          >
            {t('Cancel')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default SwitchingModal;
