import React, { useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Heading, InvestmentPathCard, InvestmentPathType, Modal, Text } from '@common-fe/common-fe';

import routes from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

interface Props {
  accountId?: string;
  isSelfDirectedMode?: boolean;
  visible: boolean;
  onSetVisible: (visible: boolean) => void;

}
const OPTIONS = [InvestmentPathType.MANAGED, InvestmentPathType.SELF_DIRECTED];

const InvestmentSwitchModelModal: React.FC<Props> = ({isSelfDirectedMode, accountId, visible, onSetVisible}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [currentOption, setCurrentOption] = useState<InvestmentPathType>(isSelfDirectedMode 
    ? InvestmentPathType.SELF_DIRECTED 
    : InvestmentPathType.MANAGED);
  const handleRedirect = useCallback(() => {
    onSetVisible(false);
    if(isSelfDirectedMode) {
      if(accountId && currentOption === InvestmentPathType.MANAGED) {
        history.push(routes.MANAGED_SWITCH_QUESTIONNAIRE(accountId));
      }
    } else {
      if(accountId && currentOption === InvestmentPathType.SELF_DIRECTED) {
        history.push(routes.SELF_DIRECTED_SWITCH_SELECT(accountId));
      }
    }
    
  }, [accountId, currentOption, history, isSelfDirectedMode, onSetVisible]);
  return (
    <Modal visible={visible} onSetVisible={onSetVisible}>
      
      <Box data-testid="investment-switch-model_modal" direction="column" align='center'>
        <Box margin={{bottom: 's'}}>
          <Heading level={4} size="medium">
            {t('Investment model')}
          </Heading>
        </Box>
        <Box margin={{bottom: 'l'}}>
          <Text textAlign='center'>
            {t('Updating your investment model will transfer your entire portfolio balance to the investment model selected. This will take 3 - 4 business days to complete. During that time you will not be able to make changes to your investment portfolio. This includes additional buys or sells.')}
          </Text>
        </Box>


        <Box pad="l" direction='row' background="module" round="moduleRound">
          {OPTIONS.map((option) => {
            return (
              <Box key={option} data-testId={`investment-switch-model_option_${option}`} margin={{right: 's'}}>
                <InvestmentPathCard
                  key={option}
                  type={option}
                  commingSoon={false}
                  checked={currentOption === option}
                  onClick={() => setCurrentOption(option)}
                />
              </Box>
            );
          }
          )}
        </Box>
        <Box fill direction='row' justify='end' align='end' margin={{top: 'l'}}>
          <AppButton onClick={() => onSetVisible(false)} testId="investment-switch-model_cancel" width="140px" buttonType="secondary">
            {t('Cancel')}
          </AppButton>
          <Box onClick={handleRedirect} margin={{left: 's'}}>
            <AppButton testId="investment-switch-model_switch" width="140px"> 
              {t('Switch')}
            </AppButton>
          </Box>
        </Box>

      </Box>
    </Modal>
  );
};
export default InvestmentSwitchModelModal;
