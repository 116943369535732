import React, { useMemo } from 'react';
import {
  Box, Text,
} from '@common-fe/common-fe';

import { REGEXPS } from '@/common';
import {
  FAV_SIZE,
  LetterIconSizeEnum,
  LOGO_SIZE,
  OrganizationTypes,
} from '@/common/constants';
import colors from '@/styles/colors';

const iconShadow = (color: string) => `0px 0px 0px 6px ${color}`;

export interface Props {
  type?: OrganizationTypes,
  size: LetterIconSizeEnum,
  text?: string;
  sizePx?: string;
  testId?: string;
  isWithShadow?: boolean;
  fontSize?: string;
}

export const UserLetterIcon: React.FC<Props> = ({
  type,
  size,
  text,
  sizePx,
  testId,
  isWithShadow,
  fontSize,
}) => {
  const firstLetter = useMemo(() => text?.match(REGEXPS.FIRST_LETTERS)?.join('') || '', [text]);
  const background = useMemo(() => {
    switch (type) {
    case OrganizationTypes.dependent: {
      return colors.dependent;
    }
    case OrganizationTypes.employee: {
      return colors.employee;
    }
    default: {
      return colors.noAssignee;
    }
    }
  }, [type]);
  const boxShadow = useMemo(() => {
    switch (type) {
    case OrganizationTypes.dependent: {
      return iconShadow(colors.dependentShadow);
    }
    case OrganizationTypes.employee: {
      return iconShadow(colors.employeeShadow);
    }
    default: {
      return undefined;
    }
    }
  }, [type]);

  const iconSize = useMemo(() => {
    if (sizePx) return `${sizePx}px`;
    return size === LetterIconSizeEnum.LOGO ? LOGO_SIZE : FAV_SIZE;
  }, [size, sizePx]);

  return (
    <Box
      background={background}
      justify="center"
      align="center"
      width={iconSize}
      data-testid={testId || 'UserLetterIcon-generate'}
      height={iconSize}
      style={{
        minWidth: iconSize,
        boxShadow: isWithShadow ? boxShadow : undefined,
        transition: 'box-shadow 0.2s ease-in-out',
      }}
      round="50%"
    >
      {
        size === LetterIconSizeEnum.LOGO
          ? (
            <Text
              weight="bold"
              size={fontSize || (firstLetter.length > 1 ? 'regular' : '2xl')}
              data-testid="UserLetterIcon-generate-logo-size"
              color="textOnColor"
            >
              {firstLetter}
            </Text>
          )
          : (
            <Text
              weight="bold"
              size={fontSize || 'small'}
              data-testid="UserLetterIcon-generate-fav-size"
              color="textOnColor"
            >
              {firstLetter}
            </Text>
          )
      }
    </Box>
  );
};
