 
import React, { useCallback,useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccountStatus,
  Box,
  CardTypesEnum,
  CIPStatus,
  Enrollment,
  EnrollmentCard,
  EnrollmentStatus,
  Hint,
  Text,
} from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import routes from '@/common/routes';
import { useHasAccess, useHistory, useSnackbar } from '@/modules/core/hooks';
import useFailedCIPStorage from '@/modules/investments/cip/hooks/useFailedCIPStorage';
import { useInvestorQuery } from '@/modules/investments/queries';
import { useMyEnrollments } from '@/modules/transaction/hooks';
import { useGetAccountNumberQuery } from '@/modules/transaction/queries/useGetAccountNumber.query';
import colors from '@/styles/colors';

import { EnrollmentDates } from './EnrollmentDates';
import {
  Wrapper,
} from './EnrollmentItem.styles';


interface Props {
  enrollment: Enrollment;
  
  width?: string;
  children?: React.ReactNode;
  investingMode?: boolean;
  investingToCashMode?: boolean;
  isDateless?: boolean;
  clickMode?: boolean;
  wrapperStyle?: React.CSSProperties;
  isHoverCardNumber?: boolean;
  isListMode? : boolean;
}

const MAX_LENGTH_WITHOUT_TRUNC = 37;
export const ENROLLMENT_ITEM_WIDTH_S = '336px';
export const ENROLLMENT_ITEM_WIDTH_L = '394px';
export const ENROLLMENT_CARD_HEIGHT = 248;

const EnrollmentItem: React.FC<Props> = ({
  enrollment,
  width,
  investingMode,
  children,
  investingToCashMode,
  clickMode,
  
  isDateless,
  wrapperStyle,
  isHoverCardNumber,
  isListMode,
}) => {
  const { isFailed: isVerificationFailed } = useFailedCIPStorage();
  const history = useHistory();
  const { t } = useTranslation();
  const { handleAddPermanentSnackbar, handleReset } = useSnackbar();
  const { hasEnrollmentWithDisplayName } = useMyEnrollments();
  const isAccountNumberMasked = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  const isNotVerified = useMemo(
    () => (enrollment?.CIPStatus === CIPStatus.Fail
    || enrollment?.CIPStatus === CIPStatus.Question) && enrollment.isCIPRequired,
    [enrollment],
  );
  const accountId = useMemo(() => `${enrollment.id}`, [enrollment]);
  const isAccountTypeHSA = useMemo(
    () => enrollment.accountType === CardTypesEnum.HSA, [enrollment],
  );
  const { formattedData } = useGetAccountNumberQuery(
    accountId,
    isAccountTypeHSA,
  );

  const accountNumber = useMemo(
    () => (formattedData.accountNumber ? formattedData.accountNumber : null),
    [formattedData],
  );
  const routingNumber = useMemo(
    () => (formattedData.routingNumber ? formattedData.routingNumber : null),
    [formattedData],
  );
  const {data: {pendingTradesAmount, investorEnrollmentId}} = useInvestorQuery();
  const goToPage = useCallback((path: string) => (event: React.SyntheticEvent) => {
    event.stopPropagation();

    history.push(path);
  }, [history]);

  const getHintLabel = useCallback((isError?: boolean) => {
    if(isError || isVerificationFailed) {
      return (
        <Box
          justify="center"
          align="center"
          background="danger"
          pad={{ horizontal: 'xs' }}
          height="22px"
          round="dropdownItemRound"
        >
          <Text size="small" color="textOnColor">{t('Verification failed')}</Text>
        </Box>
      );
    }
    if(isNotVerified ) {
     
      return  (
        <Box
          justify="center"
          align="center"
          background="warning"
          pad={{ horizontal: 'xs' }}
          height="22px"
          round="dropdownItemRound"
        >
          <Text size="small" color="textOnColor">{t('Not Verified')}</Text>
        </Box>
      );

    }
  
    return (
      <Box
        justify="center"
        align="center"
        background="warning"
        pad={{ horizontal: 'xs' }}
        height="22px"
        round="dropdownItemRound"
      >
        <Text size="small" color="textBody">{t('Verifying')}</Text>
      </Box>
    );

    
  }, [isNotVerified, isVerificationFailed, t]);
  const statusLabel = useMemo(() => {
    if (enrollment) {
      const {
        // available,
        accountStatus, accountType, CIPStatus: currentCIPStatus, enrollmentStatus,
      } = enrollment;
      if (enrollmentStatus === EnrollmentStatus.Canceled) {
        return (
          <Box direction="row" align="center" height={{ max: '24px' }}>
            <Text size="medium" textAlign="center" weight={400}>Canceled</Text>
            <Box background="danger" width="11px" height="11px" round="50%" margin={{ left: 'xs' }} />
          </Box>
        );
      }
    
      const getHintWrapper = (text: string, isError?: boolean) => (
        <Box direction="row" align="center" height="fit-content">
          <Hint
            hintElement={(
              <>{
                getHintLabel(isError)
              }
               
              </>
            )}
          >
            <Box pad={{ horizontal: 'm' }}>
              <Text size="small" textAlign="center" weight={500}>
                {text}
              </Text>
            </Box>
          </Hint>
        </Box>

      );
      if (accountStatus === AccountStatus.PENDING) {
        if (currentCIPStatus === CIPStatus.None || currentCIPStatus === CIPStatus.Pending) {
          return getHintWrapper(
            t(`We are verifying your identity to establish your ${accountType} account. It might take up to a few days.`),
          );
        }


        
        if (currentCIPStatus === CIPStatus.Question || currentCIPStatus === CIPStatus.Fail) {
          if(isVerificationFailed) {
            return getHintWrapper(
              t(`We're sorry but we cannot open an ${accountType} for you as we were not able to successfully verify your identity. Please contact your administrator for next steps.`),
            ); 
          }
          return getHintWrapper(
            t(`We are verifying your identity to establish your ${accountType} account. It might take up to a few days.`),
          );
   
        }
   

        return getHintWrapper(
          t(`We are verifying your identity to establish your ${accountType} account. It might take up to a few days.`),
        );
      }
      
      if (isNotVerified) {
        
        return getHintWrapper(
          t(`We are verifying your identity to establish your ${accountType} account. It might take up to a few days.`),
        );
      }
    }
    return null;
  }, [enrollment, isNotVerified, getHintLabel, t, isVerificationFailed]);

  const showNameTip = useMemo(
    () => enrollment?.name?.length > MAX_LENGTH_WITHOUT_TRUNC, [enrollment.name],
  );

  const canceled = useMemo(
    () => enrollment.enrollmentStatus === EnrollmentStatus.Canceled,
    [enrollment.enrollmentStatus],
  );
  const handleOpenEnrollmentPage = useCallback(() => {
    if (investingMode || !clickMode) {
      return () => {};
    }

    return goToPage(routes.MY_ACCOUNTS_DETAILS(enrollment.id));
  }, [clickMode, enrollment.id, goToPage, investingMode]);

  return (
    <Box
      gap="spacing12"
      style={wrapperStyle || undefined}
    >
      <Wrapper
        onClick={handleOpenEnrollmentPage()}
      >
        <EnrollmentCard
          enrollment={enrollment}
          width={width}
          investorEnrollmentId={investorEnrollmentId}
          children={children}
          investingToCashMode={investingToCashMode}
          clickMode={clickMode}
          isHoverCardNumber={isHoverCardNumber}
          backgroundColor={colors.cardTypes[enrollment.accountType]}
          aleraThemeTypeIconColor={colors.iconOnColor}
          statusLabel={statusLabel}
          accountNumber={accountNumber}
          isAccountNumberMasked={isAccountNumberMasked}
          routingNumber={routingNumber}
          showNameTip={showNameTip}
          pendingTradesAmount={pendingTradesAmount}
          canceled={canceled}
          handleAddPermanentSnackbar={handleAddPermanentSnackbar}
          handleReset={handleReset}
          hasDisplayName={isListMode ? hasEnrollmentWithDisplayName : !!enrollment.displayName}
        />
        {!isDateless && !isNotVerified && (
          <EnrollmentDates
            coverageDateStart={enrollment.coverageDateStart}
            coverageDateEnd={enrollment.coverageDateEnd}
            submitClaimsDateEnd={enrollment.submitClaimsDateEnd}
            coveragePeriods={enrollment.coveragePeriods}
            width={ENROLLMENT_ITEM_WIDTH_S}
          />
        )}
      </Wrapper>
    </Box>
  );
};

export default EnrollmentItem;
