import React, { useCallback } from 'react';
import { Box, Field, FlexControlledForm } from '@common-fe/common-fe';

import { useEmployerTerminationParamsFields } from './hooks/useEmployerTerminationParamsFields';

interface Props {
    handleChangeValues?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled?: (val: boolean) => void,) => void;
  isSubmit?: boolean;
  setSetup?: (value: boolean) => void;
  cancelationRules?: string;
  terminationDate?: string;
  defaultEndOfCoverage?: string | null;
  defaultLastDayToSubmitClaim?: string | null;
  viewMode?: boolean;
}

export const EmployerTerminationParamsForm: React.FC<Props> = ({
  handleChangeValues,
  isSubmit,
  setSetup,
  cancelationRules,
  terminationDate,
  defaultEndOfCoverage,
  defaultLastDayToSubmitClaim,
  viewMode,
}) => {
  const fields = useEmployerTerminationParamsFields(
    cancelationRules,
    terminationDate,
    defaultEndOfCoverage,
    defaultLastDayToSubmitClaim,
    viewMode,
  );

  const handleChangeDateValue = useCallback((values: object) => {
    if (handleChangeValues) handleChangeValues(values, fields, setSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="EmployerTerminationParamsForm_container" round="container1Round" background="canvas">
      <FlexControlledForm
        fields={fields}
        editMode
        showError={isSubmit}
        onChangeValues={handleChangeDateValue}
        isModalType
      />
    </Box>
  );
};
