import { useMemo } from 'react';
import _ from 'lodash';

export default (value?: string) => {
  return useMemo(() => {
    const parsedNumber = _.toNumber(value); 
    if(_.isNaN(parsedNumber)) {
      return null;
    }
    return parsedNumber;
  }, [value]);
  
};