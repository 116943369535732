import React, { useCallback } from 'react';
import { Box, Field, FlexControlledForm } from '@common-fe/common-fe';

import { useEmployerTerminationDateForm } from './hooks/useEmployerTerminationDateForm';

interface Props {
    handleChangeValues?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled?: (val: boolean) => void,) => void;
  isSubmit?: boolean;
  setSetup?: (value: boolean) => void;
  terminationDate?: string;
  minDate?: string;
  viewMode?: boolean;
}

export const EmployerTerminationDateForm: React.FC<Props> = ({
  handleChangeValues,
  isSubmit,
  setSetup,
  terminationDate,
  minDate,
  viewMode,
}) => {
  const fields = useEmployerTerminationDateForm(terminationDate, minDate, viewMode);

  const handleChangeDateValue = useCallback((values: object) => {
    if (handleChangeValues) handleChangeValues(values, fields, setSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="EmployerTerminationDateForm_container" round="container1Round" background="canvas">
      <FlexControlledForm
        fields={fields}
        editMode
        showError={isSubmit}
        onChangeValues={handleChangeDateValue}
        isModalType
      />
    </Box>
  );
};
