export const useDetectTextIsEllipsis = (ref: React.RefObject<HTMLSpanElement> | null) => {
  const isDescriptionEllipse = () => {
    if (!ref?.current) {
      return false;
    }
    if (ref.current.scrollWidth > ref.current.clientWidth) {
      return true;
    }
    return false;
  };
  return isDescriptionEllipse();
};
