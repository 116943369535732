import dayjs from 'dayjs';

const businessDaysPassed = (enough: number, from: string) => {
  if (!enough) return from;
  let businessDays = 0;
  let i = 1;
  do {
    const yesterday = dayjs(from).add(-i, 'day');
    const weekday = yesterday.format('dddd');
    const isBusinessDay = weekday !== 'Sunday' && weekday !== 'Saturday';

    if (isBusinessDay) businessDays++;
    i++;
  } while (businessDays < enough);

  return dayjs(from).add(-i, 'day').format();
};

export default businessDaysPassed;
