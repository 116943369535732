import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface LockOrgDto {
  is_locked: boolean;
  organization_id: number;
}

const QUERY_KEY = 'GET_ORGANIZATION_LOCK_STATUS';
const useGetOrgLockStatusQuery = (id?: string) => {
  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    [QUERY_KEY, id],
    () => api.get<LockOrgDto>(PATHS.GET_ORGANIZATION_LOCK_STATUS(id)),
    {
      enabled: !!id,
      retry: 0,
    },
  );

  const formattedData = useMemo(() => {
    return {
      isLocked: data?.data?.is_locked,
      organizationId: data?.data?.organization_id,
    };
  }, [data]);

  return {
    formattedData,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useGetOrgLockStatusQuery;
