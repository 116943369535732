import React, { useMemo } from 'react';
import {
  Box,
  costFormater,
  LinkIcon,
  ListItem,
  ListItemBase,
  ListItemType,
  Text,
} from '@common-fe/common-fe';

import { InvestmentActivity } from '@/modules/investments/investments.types';

import { useInvestmentActivityListQuery } from './useInvestmentActivityListQuery';

const headers: ListItemBase[] = [{
  key: 'fundName',
  title: 'Fund Name',
  flex: 3,
}, {
  key: 'research',
  title: '',
}, {
  key: 'type',
  title: 'Type',
}, {
  key: 'symbol',
  title: 'Symbol',
}, {
  key: 'date',
  title: 'Date',
}, {
  key: 'time',
  title: 'Time',
  flex: 2,
}, {
  key: 'amount',
  title: 'Amount',
  endAlign: true,
}, {
  key: 'shares',
  title: 'Shares',
  endAlign: true,
}];

export default (activityState: string) => {
  const { formatedData } = useInvestmentActivityListQuery(activityState);
  const formatedList = useMemo<ListItem[]>(
    () => formatedData?.investmentsActivity?.map((item: InvestmentActivity) => ({
      id: item.id,
      fields: [
        {
          key: 'fundName',
          type: ListItemType.Node,
          title: '',
          node: <Text weight="bold">{item.fundName}</Text>,
          flex: 3,
        },
        {
          key: 'research',
          type: ListItemType.Node,
          title: '',
          hover: true,
          node: (
            <Box direction="row" align="center" justify="center" style={{ cursor: 'pointer' }}>
              <LinkIcon />
            </Box>
          ),
        },
        {
          key: 'type',
          type: ListItemType.Text,
          title: item.type,
        },
        {
          key: 'symbol',
          type: ListItemType.Text,
          title: item.symbol,
        },
        {
          key: 'date',
          type: ListItemType.Text,
          title: item.date,
        },
        {
          key: 'time',
          type: ListItemType.Text,
          title: item.time,
          flex: 2,
        },
        {
          key: 'amount',
          type: ListItemType.Node,
          title: '',
          node: <Text textAlign="end" weight="bold">{costFormater(item.amount, true)}</Text>,
          endAlign: true,
        },
        {
          key: 'shares',
          type: ListItemType.Node,
          title: '',
          node: <Text textAlign="end" weight="bold">[{item.shares}]</Text>,
          endAlign: true,
        },
      ],
    })), [formatedData],
  );

  return {
    formatedList: formatedList || [],
    headers,
  };
};
