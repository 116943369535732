import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  costFormater,
  FlexList,
  PencilIcon,
  RawButton,
  SliderHintIcon,
  SuccessModal,
  Text,
} from '@common-fe/common-fe';
import { Enrollment } from '@common-fe/common-fe';
import styled from 'styled-components';

import { RiskControlOptions } from '@/common/constants';
import { ConfettiSuccessIcon } from '@/components';
import AppButton from '@/components/controls/AppButton';
import usePortfolioList from '@/modules/investments/hooks/usePortfolioList';
import RiskCard from '@/modules/investments/RiskCard';
import RiskModal from '@/modules/investments/RiskModal';
import theme from '@/styles/theme';

import DisclosureFooter from '../../BuySell/DisclosureFooter';
import { useSwitchToManagedQuery } from '../../Managed/queries';
import ConfirmCancelModal from '../../modal/ConfirmCancelModal';
import EditAmountModal from '../../modal/EditAmountModal';
import ServerErrorModal from '../../modal/ServerErrorModal';
import { useInvestmentApproveQuery } from '../../queries';

import { usePortfolioAllocationStore } from './stores/usePortfolioAllocation.store';

const RecommendedModalButton = styled(RawButton)`
  line-height: 22px;
  &:hover {
    color: ${theme.colors.success};
    cursor: pointer;
  }
`;

const BUTTON_WIDTH = '130px';

interface Props {
  onBack: () => void;
  handleGoDashboard: () => void;
  setIsAllowRedirect?: (value: boolean) => void;
  stepLength: number;
  step: number;
  recommendedRisk?: string;
  investmentAmount?: number;
  setInvestmentAmount?: (amount: number) => void;
  currentMyAccount: Enrollment;
  investorId?: string;
  isSwitchMode?: boolean;
}

const PortfolioAllocation: React.FC<Props> = ({
  onBack,
  stepLength,
  step,
  recommendedRisk,
  investmentAmount,
  currentMyAccount,
  handleGoDashboard,
  setIsAllowRedirect,
  setInvestmentAmount,
  investorId,
  isSwitchMode,
}) => {
  const { t } = useTranslation();
  const riskOptions = useMemo(
    () => [
      {
        label: t('Conservative Model'),
        value: RiskControlOptions.CONSERVATIVE,
      },
      {
        label: t('Moderately Conservative Model'),
        value: RiskControlOptions.MODERATELY_CONSERVATIVE,
      },
      {
        label: t('Moderate Model'),
        value: RiskControlOptions.MODERATE,
      },
      {
        label: t('Moderately Aggressive Model'),
        value: RiskControlOptions.MODERATELY_AGGRESSIVE,
      },
      {
        label: t('Aggressive Model'),
        value: RiskControlOptions.AGGRESSIVE,
      },
    ],
    [t]
  );

  const recommendedRiskObj = useMemo(
    () => riskOptions?.find((elem) => elem.value === recommendedRisk),
    [recommendedRisk, riskOptions]
  );
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [errorWarningVisible, setErrorWarningVisible] = useState(false);
  const [editAmountlVisible, setEditAmountlVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [riskValue, setRiskValue] = useState(recommendedRisk as string);

  const riskValueObj = useMemo(
    () => riskOptions?.find((elem) => elem.value === riskValue),
    [riskOptions, riskValue]
  );

  const { formatedList, data, headers, isLoading } = usePortfolioList(
    riskValue as RiskControlOptions,
    investorId,
    investmentAmount
  );
  const { handleSave: handleSwitch, isLoading: isSwitchingLoading } = useSwitchToManagedQuery(
    investorId,
    () => {
      setConfirmCancelVisible(false);
      setErrorWarningVisible(false);

      setSuccessVisible(true);
      if (setIsAllowRedirect) {
        setIsAllowRedirect(true);
      }
    },
    () => {
      setConfirmCancelVisible(false);
      setErrorWarningVisible(true);
    }
  );

  const { handleSave, isLoading: isApproveLoading } = useInvestmentApproveQuery({
    onSuccess: () => {
      setConfirmCancelVisible(false);
      setErrorWarningVisible(false);

      setSuccessVisible(true);
      if (setIsAllowRedirect) {
        setIsAllowRedirect(true);
      }
    },
    onError: () => {
      setConfirmCancelVisible(false);
      setErrorWarningVisible(true);
    },
  });

  const { handleSetValues } = usePortfolioAllocationStore();
  const [openRiskModal, setRiskModal] = useState<boolean>(false);
  const handleSubmit = useCallback(() => {
    setConfirmCancelVisible(true);

    handleSetValues({
      riskValue,
    });
  }, [handleSetValues, riskValue]);
  const handleConfirm = useCallback(() => {
    if (isLoading || isApproveLoading) {
      return;
    }

    if (isSwitchMode) {
      handleSwitch({
        amount: investmentAmount || 0,
        toleranceModel: riskValue as RiskControlOptions,
      });
    } else {
      handleSave(riskValue as RiskControlOptions);
    }
  }, [handleSave, handleSwitch, investmentAmount, isApproveLoading, isLoading, isSwitchMode, riskValue]);

  return (
    <Box direction="column" data-testid="portfolio-allocation-wrapper-id">
      <Box flex="grow" pad={{ horizontal: '40px', bottom: '40px' }}>
        <Text size="3xl" color="blockTitle" margin={{ bottom: 's' }} data-testid="portfolio-title-wrapper-id">
          {t('Portfolio Allocation')}
        </Text>
        <Box direction="row" align="center">
          <Text size="large">{t('Based on your risk assessment, we recommend')}</Text>
          <Box
            border={{
              side: 'bottom',
              color: recommendedRisk !== riskValue ? theme.colors.success : 'transparent',
              size: '2px',
            }}
          >
            <RecommendedModalButton onClick={() => recommendedRisk && setRiskValue(recommendedRisk)}>
              <Text size="large" style={{ lineHeight: '12px' }} margin={{ horizontal: '4px' }} weight="bold">
                {t('the ')}
                {recommendedRiskObj?.label}
              </Text>
            </RecommendedModalButton>
          </Box>
          <Text size="large">{t('portfolio allocation for you.')}</Text>
        </Box>
        <Box
          flex="grow"
          direction="column"
          background="module"
          round="moduleRound"
          pad="spacing24"
          margin={{ bottom: 'spacing24', top: 'spacing12' }}
        >
          <Box direction="row" justify="between" margin={{ bottom: 'spacing24' }}>
            <Text size="large" weight="bold">
              {t('Confirm your preferred Investment Risk Model')}
            </Text>
            <Box direction="row" align="center">
              <Text size="large">{t('Estimated amount to be invested:')}</Text>

              <Box direction="row" onClick={() => setEditAmountlVisible(true)}>
                <Text size="large" weight="bold" color="textAccent" margin={{ horizontal: 'spacing8' }}>
                  {costFormater(investmentAmount, true)}
                </Text>
                <PencilIcon color={theme.colors.iconAccent} />
              </Box>
            </Box>
          </Box>
          <Box direction="row">
            <Box direction="column" flex={{ grow: 1 }} width={{ max: '580px' }}>
              <Box direction="row">
                <RiskCard
                  riskValue={riskValue}
                  setRiskValue={setRiskValue}
                  setRiskModal={setRiskModal}
                  recommendedRisk={recommendedRisk}
                  labelText={t('Recommended')}
                  riskOptions={riskOptions}
                />
              </Box>
            </Box>
            <Box justify="center" align="center" flex={{ grow: 2 }} pad={{ top: 'xxxl' }}>
              <Box width={{ max: '170px' }} justify="center" align="center">
                <SliderHintIcon color={theme.colors.iconSecondary} size="72" />
                <Text size="small" textAlign="center" margin={{ top: 's' }} color="textSecondary">
                  {t('Move slider to see allocations by risk profile')}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box background="module" round="moduleRound" margin={{ bottom: 'spacing24' }}>
          <Text
            margin={{ bottom: 'spacing16', top: 'spacing32', horizontal: 'spacing24' }}
            size="large"
            weight="bold"
          >
            {riskValueObj?.label}
            {` Investment Portfolio: ${data?.length}`}
          </Text>
          <FlexList
            rows={formatedList}
            headers={headers}
            loading={isLoading}
            footer={(
              <Box
                direction="row"
                justify="stretch"
                pad={{ horizontal: 'spacing24', vertical: 'spacing6' }}
                margin={{ bottom: 'spacing16', top: 'spacing8' }}
                background="border1"
                round="container1Round"
              >
                <Text style={{ flex: 4 }} weight="bold">
                  {t('Estimated amount to be invested')}:
                </Text>
                <Text style={{ flex: 1 }} />
                <Text weight="bold" style={{ flex: 1 }}>
                  100%
                </Text>
                <Text weight="bold" style={{ flex: 1 }}>
                  {costFormater(investmentAmount, true)}
                </Text>
                <Text style={{ flex: 2 }} />
              </Box>
            )}
          />
        </Box>
        <Box direction="row" align="center" justify="end">
          <Box direction="row" justify="end" align="center">
            <AppButton
              buttonType="secondary"
              onClick={() => onBack()}
              width={BUTTON_WIDTH}
              containerStyle={{ marginRight: theme.spacings.s }}
            >
              {t('Back')}
            </AppButton>
            <AppButton type="submit" onClick={handleSubmit} width={BUTTON_WIDTH}>
              {t(`Submit (${step}/${stepLength})`)}
            </AppButton>
          </Box>
        </Box>
      </Box>
      <DisclosureFooter />
      <RiskModal
        visible={openRiskModal}
        onSetVisible={(value) => setRiskModal(value)}
        activeRisk={riskValue}
      />
      <ConfirmCancelModal
        isSwitchMode={isSwitchMode}
        visible={confirmCancelVisible}
        onSetVisible={setConfirmCancelVisible}
        onConfirm={handleConfirm}
        isLoading={isApproveLoading || isSwitchingLoading}
        riskValue={riskValueObj?.value}
        investmentAmount={investmentAmount}
      />
      <EditAmountModal
        visible={editAmountlVisible}
        onSetVisible={setEditAmountlVisible}
        currentMyAccount={currentMyAccount}
        investmentAmount={investmentAmount}
        isSwitchMode={isSwitchMode}
        setInvestmentAmount={setInvestmentAmount}
      />
      <ServerErrorModal
        visible={errorWarningVisible}
        onSetVisible={setErrorWarningVisible}
        onSendRequest={handleConfirm}
        isLoading={isApproveLoading || isSwitchingLoading}
      />
      <SuccessModal
        header={t('Submitted!')}
        icon={<ConfettiSuccessIcon />}
        buttonStyle={{
          zIndex: 100,
        }}
        helptext={
          isSwitchMode ? (
            <Box align="center" justify="center">
              <Text size="medium" textAlign="center">
                {t('Updating your investment model will  will take 3 - 4 business days to complete.')}
              </Text>
              <Text size="medium" textAlign="center">
                {t(
                  'During that time you will not be able to make changes to your investment portfolio. This includes additional buys or sells.'
                )}
              </Text>
            </Box>
          ) : undefined
        }
        buttonText={t('Go to Investment Dashboard')}
        visible={successVisible}
        onSetVisible={handleGoDashboard}
        onSubmit={handleGoDashboard}
      />
    </Box>
  );
};

export default PortfolioAllocation;
