import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

interface WrapperWithShadowProps {
  hasTopShadow?: boolean;
  hasBottomShadow?: boolean;
}

export const WrapperWithShadow = styled(Box)<WrapperWithShadowProps>`
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 35px;
    width: 100%;
    left: 0;
    position: absolute;
  }

  &::before {
    display: ${({ hasTopShadow }) => hasTopShadow ? 'block' : 'none'};
    top: 0;
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0));
  }

  &::after {
    display: ${({ hasBottomShadow }) => hasBottomShadow ? 'block' : 'none'};
    bottom: 0px;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0));
  }
`;

export const HiddenScrollWrapper = styled(Box)`
  &::-webkit-scrollbar {
    display: none;
  }
`;
