import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Box,
  CalendarIcon,
  CalendarPickerIcon,
  capitalizeFirstLetter,
  DateRangeDropdown,
  FlexList,
  ListItemBase,
  OptionKey,
  PaginationBar,
  SearchInput,
  SelectDropdown,
  Text,
  usePaginationParams,
} from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import regexp from '@/common/regexp';
import ROUTES from '@/common/routes';
import { FlexListWrapper } from '@/components/wrappers';
import { useActivitiesDatesRange } from '@/modules/transaction/components/MyAccounts/ActivitiesList/hooks';
import { EmptyTransactionsListPlaceholder } from '@/modules/transaction/components/TransactionsList/EmptyTransactionsListPlaceholder';
import useGetClaimStatusesQuery from '@/modules/transaction/queries/useGetClaimStatuses.query';

import useCardTransactionsList from './hooks/useCardTransactionsList';
import { CardTransactionItem } from './CardTransactionItem';

const stateAllToggling = (statuses: OptionKey[]) => {
  const minStatusesLength = 2;
  const isStatusAllSelected = statuses[statuses.length - 1] === ALL_OPTION.value;

  if (statuses.length === 0 || isStatusAllSelected) {
    return [ALL_OPTION.value];
  }

  const isStatusAllChangedToAnother = statuses.length === minStatusesLength
    && statuses[0] === ALL_OPTION.value;

  if (isStatusAllChangedToAnother) {
    return statuses.filter((status) => status !== ALL_OPTION.value);
  }

  return statuses;
};

const HEADERS: ListItemBase[] = [
  {
    key: 'provider',
    title: '',
    flex: 6,
  },
  {
    key: 'amount',
    title: 'Amount',
    flex: 2,
  },
  {
    key: 'status',
    title: 'Status',
    flex: 2,

  },
  {
    key: 'statusDate',
    title: 'Status Date',
    flex: 1.5,
  }];

export const CardActivitiesList = () => {
  const [statuses, setStatuses] = useState<OptionKey[]>([]);
  const [initialData, setInitialData] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const { push } = useHistory();
  const { t } = useTranslation();
  const { onChangeDates, formatedDates } = useActivitiesDatesRange();
  const { statusesWithoutCanceled } = useGetClaimStatusesQuery();

  const {
    page,
    setPage,
    setPerPage,
    perPage,
    searchString,
    setSearchString,
    currentSearch,
  } = usePaginationParams();

  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch, formatedDates.length, statuses]);

  useEffect(() => {
    setSubLoading(true);
    const timeout = setTimeout(() => {
      setSubLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [page, currentSearch, formatedDates.length, statuses]);

  const statusesOptions = useMemo(() => {
    const preparedStatuses = statusesWithoutCanceled.map((status) => {
      const preparedStatus = capitalizeFirstLetter(status.name).replace(regexp.DASH_SYMBOL, ' ');
      return {
        key: status.name,
        value: capitalizeFirstLetter(status.name),
        title: preparedStatus,
      };
    });
    return [ALL_OPTION, ...preparedStatuses];
  }, [statusesWithoutCanceled]);
  
  
  const statusValues = useMemo(() => stateAllToggling(statuses), [statuses]);

  const hasStatus = useMemo(
    () => statusValues.length > 0
      && !statusValues.some((status) => status === ALL_OPTION.value),
    [statusValues],
  );
  const {
    isLoading,
    total,
    data,
    holderName,
    activeCard,
  } = useCardTransactionsList(
    {
      page: page - 1,
      perPage,
      searchString: currentSearch || '',
      statuses: (hasStatus ? statusValues : []),
      startDate: formatedDates[0],
      endDate: formatedDates[1],
    },
  );

  const isAnyInputFilled = useMemo(() => searchString.length
  || statuses.length
  || formatedDates.length,
  [
    formatedDates.length,
    searchString.length,
    statuses.length,
  ]);

  const isDataInclude = useMemo(() => !!data.length, [data]);
  const currentAccountId = useMemo(() => activeCard?.accountIds?.[0], [activeCard]);

  const isInitialData = useCallback(() => (
    (!data.length && !isAnyInputFilled) ? setInitialData(false) : setInitialData(true)),
  [data.length, isAnyInputFilled]);
  useEffect(() => {
    isInitialData();
  }, [isInitialData]);

  return (
    <Box
      direction="column"
      data-testid="TransactionsList"
      background="module"
      round="container1Round"
      pad={{ bottom: data ? 'spacing12' : 'spacing24' }}
    >
      <Box
        direction="row"
        data-testid={`activities-list-${holderName}`}
        align="center"
        margin={{ bottom: 'spacing4' }}
        pad={{ horizontal: 'spacing24', top: 'spacing24' }}
      >
        <Box direction="row" style={{ flex: 1 }}>
          <Text size="large" weight="bold">{holderName ? `${holderName}'s Activity` : 'Activity'}</Text>
        </Box>
        <Box width="largeControl" margin={{ left: 'spacing8' }}>
          <SearchInput
            disabled={!initialData}
            value={searchString}
            hasSearchingHistory
            placeholder={t('Search')}
            onChange={setSearchString}
          />
        </Box>
        <Box width="control" margin={{ left: 'spacing8' }}>
          <SelectDropdown
            disabled={!initialData}
            id="status"
            name={t('Activity status')}
            prefix="Status"
            allMode
            options={statusesOptions}
            values={statusValues}
         
            onChangeValues={setStatuses}
          />
        </Box>

        <Box width="control" margin={{ left: 'spacing8' }}>
          <DateRangeDropdown
            ellipsisMode
            disabled={!initialData}
            prefix={t('Period:')}
            className="period-picker"
            onChange={onChangeDates}
            startDate={formatedDates[0]}
            endDate={formatedDates[1]}
            icon={(
              <Box margin={{ bottom: !initialData ? 'spacing4' : undefined }}>
                {!initialData ? (
                  <CalendarPickerIcon />
                ) : (
                  <CalendarIcon color="primaryIcon" />
                )}
              </Box>
            )}
          />
        </Box>
      </Box>
      <FlexListWrapper>
        <FlexList
          headers={data ? HEADERS : []}
          rows={[]}
          total={100}
          moreCount={perPage}
          loading={isLoading || subLoading}
          footer={(
            <Box direction="column">
              {!isDataInclude && (
                <EmptyTransactionsListPlaceholder title="Activities" />
              )}
              {data.map((item) => (
                <CardTransactionItem
                  key={item?.claimId}
                  category={item.category}
                  title={item.title}
                  amount={item.amount}
                  status={item.status}
                  date={item.date}
                  onPick={
                    () => push(ROUTES.TRANSACTION_DETAILS_CLAIM(currentAccountId, item?.claimId))
                  }
                />
              ))}
              {isDataInclude && (
                <PaginationBar
                  page={page}
                  total={total || 0}
                  pageSize={perPage}
                  onChangePage={setPage}
                  onChangePageSize={setPerPage}
                />
              )}
            </Box>
          )}
        />
      </FlexListWrapper>
    </Box>
  );
};
