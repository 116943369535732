import React from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import { useMyEnrollments } from '@/modules/transaction';
import EnrollmentItem, { ENROLLMENT_ITEM_WIDTH_L } from '@/modules/transaction/components/EnrollmentItem/EnrollmentItem';

interface Props {
  accountId: string;
}
const EnrollmentCardBlock: React.FC<Props> = ({ accountId}) => {
  const { currentMyAccount } = useMyEnrollments(accountId);
  if(!currentMyAccount) {
    return (
      <Box align='center' justify='center'>
        <Preloader />
      </Box>
    );
  }
  return (
    <EnrollmentItem 
      isDateless
      isHoverCardNumber
      enrollment={currentMyAccount}
      wrapperStyle={{ minWidth: ENROLLMENT_ITEM_WIDTH_L }}
    />
  );
};
export default EnrollmentCardBlock;