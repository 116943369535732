import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowDownIcon, ArrowUpIcon,
  Box, RawButton,   Text, } from '@common-fe/common-fe';

const SetupAutoInvestmentsInfo = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const toggleExapnd = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  return (
    <Box round="moduleRound" background="module" margin={{ top: 'l' }} pad="l">
      <Box>
        <RawButton data-testid="setup-info-banner_expand" onClick={toggleExapnd}>
          <Box pad={{ vertical: 'xs' }} align="center" direction="row">
            {
              expanded ? (
                <ArrowUpIcon color="textBody" />
              ) : (
                <ArrowDownIcon color="textBody" />
               
              )
            }
            <Box margin={{ left: 's' }}>
              <Text size="large" weight={700}>
                {t('Tell me more about Automatic Investments')}
              </Text>
            </Box>
          </Box>
        </RawButton>
      </Box>
      {
        expanded && (
          <Box data-testid="setup-info-banner_content" margin={{ top: 'xs' }} round="container2Round" background="canvas" pad="l" elevation="default">
            <Text size="large" color="textBody">
              {t('To help you maximize the growth of your HSA we bring you Automatic Investments.  Here\'s how it works:')}
              <br />
              <br />
              <Text size="large" color="textBody" weight={700}>{t('Monitor Your Balance: ')}</Text> {t('We\'ll keep an eye on your account balance.')}
              <br />
              <br />
              <Text size="large" color="textBody" weight={700}>{t('Amount to keep in your HSA:')}</Text> {t('Once your balance goes above that amount, we recognize this as surplus funds.')}
              <br />
              <br />
              <Text size="large" color="textBody" weight={700}>{t('Invest the Extra: ')}</Text> {t('Instead of letting these extra funds just sit idle, the additional amount will be automatically invested.')}
              <br />
              <br />
              <Text size="large" color="textBody" weight={700}>{t('Peace of Mind: ')}</Text> {t('This process ensures your money is always working for you. You\'ll benefit from potential growth without the need to constantly monitor or manually move funds.')}
              <br />
              <br />
              {t('Remember, you\'re always in control. You can adjust the amount to keep in your HSA or stop automatic investments at any time. We\'re here to make your financial journey smoother.')}

            </Text>
          </Box>
        )
      }

    </Box>
  );
};
export default SetupAutoInvestmentsInfo;
