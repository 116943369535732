import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CrossIcon } from '@common-fe/common-fe';

import { useHotKeyPressIndicator } from '@/utils';
import { HotKeys } from '@/utils/hooks/useHotKeyPressIndicator';

import {
  CloseIcon, Content, HeaderWrapper, Wrapper,
} from './Modal.styles';

interface Props {
  visible: boolean;
  isHoveringSensitive?: boolean;
  notHideClickOutside?: boolean;
  onSetVisible?: (value: boolean) => void;
  children: React.ReactNode;
  testId?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  hideCloseButton?: boolean;
}

const MODAL_CLASS = 'Modal';

const Modal: React.FC<Props> = ({
  visible,
  isHoveringSensitive,
  children,
  notHideClickOutside,
  testId,
  onSetVisible,
  style,
  contentStyle,
  hideCloseButton,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isHover, setIsHover] = useState(false);
  useHotKeyPressIndicator(
    modalRef,
    () => {
      if (visible && onSetVisible) onSetVisible(false);
    },
    HotKeys.Esc,
  );

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (visible) body.style.overflow = 'hidden';
    else body.style.overflow = 'initial';
    return () => {
      const isSomeModalExist = document.querySelectorAll(`.${MODAL_CLASS}`).length;
      if (!isSomeModalExist) body.style.overflow = 'initial';
    };
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
  // <Portal id="modal">
    <Wrapper
      ref={modalRef}
      style={{
        ...style,
        // ...(isHoveringSensitive && !isHover) ? {
        //   overflow: 'hidden',
        // } : {},
      }}
      className={MODAL_CLASS}
      data-testid={testId}
      onClick={() => {
        if (!notHideClickOutside && onSetVisible) onSetVisible(false);
      }}
      {...isHoveringSensitive ? {
        onMouseOver: () => {
          if (!isHover) setIsHover(true);
        },
        onMouseOut: () => {
          if (isHover) setIsHover(false);
        },
      } : {}}
    >
      <Content
        style={contentStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderWrapper>
          {
            !hideCloseButton && (
              <CloseIcon onClick={() => {
                if (onSetVisible) onSetVisible(false);
              }}
              >
                <CrossIcon size="14px" color="iconPrimary" />
              </CloseIcon>
            )
          }
        </HeaderWrapper>
        {children}
      </Content>
    </Wrapper>
  // </Portal>
  );
};
export default Modal;
