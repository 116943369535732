/* eslint-disable @typescript-eslint/no-explicit-any */
 
// @ts-nocheck 
 
import React from 'react';
import { createSelector } from 'reselect';

import {
  constrain,
  Dimentions,
  getAutofitScale,
  getContainerDimensions,
  getDimensions,
  getImageOverflow,
  getMinScale,
  getPinchLength,
  getPinchMidpoint,
  getRelativePosition,
  isEqualDimensions,
  isEqualTransform,
  negate,
  setRef,
  snapToTarget,
  tryCancelEvent,
} from './utils';
import ZoomButtons from './ZoomButtons';

 
const warning = (...args: (string | boolean)[]) => console.log(args);
const OVERZOOM_TOLERANCE = 0.05;
const DOUBLE_TAP_THRESHOLD = 250;
const ANIMATION_SPEED = 0.1;

 
const isInitialized = (top: any, left: any, scale: any) => scale !== undefined && left !== undefined && top !== undefined;

const imageStyle = createSelector<
PinchZoomPanState,
number,
number,
number,
{ cursor: string }
>(
  // @ts-ignore
  (state) => state.top,
  (state) => state.left,
  (state) => state.scale,
  (top, left, scale) => {
    const style = {
      cursor: 'pointer',
    };
    return isInitialized(top, left, scale)
      ? {
        ...style,
        transform: `translate3d(${left}px, ${top}px, 0) scale(${scale})`,
        transformOrigin: '0 0',
      }
      : style;
  },
);

const imageOverflow = createSelector<
PinchZoomPanState,
number,
number,
number,
Dimentions,
Dimentions,
{ left: number; right: number; top: number; bottom: number }
>(
  // @ts-ignore
  (state) => state.top,
  (state) => state.left,
  (state) => state.scale,
  (state) => state.imageDimensions,
  (state) => state.containerDimensions,
  (top, left, scale, imageDimensions, containerDimensions) => {
    if (!isInitialized(top, left, scale)) {
      return null;
    }
    return getImageOverflow(
      top,
      left,
      scale,
      imageDimensions,
      containerDimensions,
    );
  },
);

const browserPanActions = createSelector(
  imageOverflow,
  (imageOverflowSelector) => {
    // Determine the panning directions where there is no image overflow and let
    // the browser handle those directions (e.g., scroll viewport if possible).
    // Need to replace 'pan-left pan-right' with 'pan-x', etc. otherwise
    // it is rejected (o_O), therefore explicitly handle each combination.
    let browserPanX = '';
    if (
      !imageOverflowSelector
      || (!imageOverflowSelector.left && !imageOverflowSelector.right)
    ) {
      browserPanX = 'pan-x'; // we can't pan the image horizontally, let the browser take it
    } else if (!imageOverflowSelector.left) {
      browserPanX = 'pan-left';
    } else if (!imageOverflowSelector.right) {
      browserPanX = 'pan-right';
    }

    let browserPanY = '';
    if (
      !imageOverflowSelector
      || (!imageOverflowSelector.top && !imageOverflowSelector.bottom)
    ) {
      browserPanY = 'pan-y'; // we can't pan the image vertically, let the browser take it
    } else if (!imageOverflowSelector.top) {
      browserPanY = 'pan-up';
    } else if (!imageOverflowSelector.bottom) {
      browserPanY = 'pan-down';
    }

    return [browserPanX, browserPanY].join(' ').trim();
  },
);

interface PinchZoomPanProps {
  doubleTapBehavior: string;
  position: string;
  initialScale: number;
  minScale: number;
  children?: React.ReactNode;
  maxScale: number;
  zoomButtons: boolean;
  initialTop?: number;
  initialLeft?: number;
}

interface PinchZoomPanState {
  top?: number;
  left?: number;
  scale?: number;
  imageDimensions?: Dimentions;
  containerDimensions?: Dimentions;
}

// Ensure the image is not over-panned, and not over- or under-scaled.
// These constraints must be checked when image changes, and when container is resized.
export default class PinchZoomPan extends React.Component<
PinchZoomPanProps,
PinchZoomPanState
> {
  lastPointerUpTimeStamp: number; // enables detecting double-tap

  lastPanPointerPosition: { x: any; y: any } | null; // helps determine how far to pan the image

  lastPinchLength: number | null; // helps determine if we are pinching in or out

  animation: number; // current animation handle

  imageRef: {
    parentNode?: any;
    removeEventListener?: any;
    addEventListener?: any;
    tagName?: any;
    offsetWidth?: any;
    width?: any;
    offsetHeight?: any;
    height?: any;
  }; // image element

  isImageLoaded: boolean; // permits initial transform

  originalOverscrollBehaviorY: any; // saves the original overscroll-behavior-y value while temporarily preventing pull down refresh

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  // event handlers
  handleTouchStart = (event: {
    touches?: any;
    cancelable: boolean;
    preventDefault: () => void;
  }) => {
    this.cancelAnimation();

    const { touches } = event;
    if (touches.length === 2) {
      this.lastPinchLength = getPinchLength(touches);
      this.lastPanPointerPosition = null;
    } else if (touches.length === 1) {
      this.lastPinchLength = null;
      this.pointerDown(touches[0]);
      tryCancelEvent(event); // suppress mouse events
    }
  };

  handleTouchMove = (event: {
    touches?: any;
    cancelable: boolean;
    preventDefault: () => void;
  }) => {
    const { touches } = event;
    if (touches.length === 2) {
      this.pinchChange(touches);

      // suppress viewport scaling on iOS
      tryCancelEvent(event);
    } else if (touches.length === 1) {
      const requestedPan = this.pan(touches[0]) || {
        left: 0,
        right: 0,
        down: 0,
        up: 0,
      };

      if (!this.controlOverscrollViaCss) {
        // let the browser handling panning if we are at the edge of the image in
        // both pan directions, or if we are primarily panning in one direction
        // and are at the edge in that directino
        const overflow = imageOverflow(this.state);
        const hasOverflowX = (requestedPan.left && overflow.left > 0)
          || (requestedPan.right && overflow.right > 0);
        const hasOverflowY = (requestedPan.up && overflow.top > 0)
          || (requestedPan.down && overflow.bottom > 0);

        if (!hasOverflowX && !hasOverflowY) {
          // no overflow in both directions
          return;
        }

        const panX = requestedPan.left || requestedPan.right;
        const panY = requestedPan.up || requestedPan.down;
        if (panY > 2 * panX && !hasOverflowY) {
          // primarily panning up or down and no overflow in the Y direction
          return;
        }

        if (panX > 2 * panY && !hasOverflowX) {
          // primarily panning left or right and no overflow in the X direction
          return;
        }

        tryCancelEvent(event);
      }
    }
  };

  handleTouchEnd = (event: {
    touches?: any;
    changedTouches?: any;
    timeStamp?: any;
    cancelable: boolean;
    preventDefault: () => void;
  }) => {
    this.cancelAnimation();
    if (event.touches.length === 0 && event.changedTouches.length === 1) {
      if (
        this.lastPointerUpTimeStamp
        && this.lastPointerUpTimeStamp + DOUBLE_TAP_THRESHOLD > event.timeStamp
      ) {
        const pointerPosition = getRelativePosition(
          event.changedTouches[0],
          this.imageRef.parentNode,
        );
        this.doubleClick(pointerPosition);
      }
      this.lastPointerUpTimeStamp = event.timeStamp;
      tryCancelEvent(event); // suppress mouse events
    }

    // We allow transient +/-5% over-pinching.
    // Animate the bounce back to constraints if applicable.
    this.maybeAdjustCurrentTransform(ANIMATION_SPEED);
  };

  handleMouseDown = (event: any) => {
    this.cancelAnimation();
    this.pointerDown(event);
  };

  handleMouseMove = (event: { buttons: any }) => {
    if (!event.buttons) return;
    this.pan(event);
  };

  handleMouseDoubleClick = (event: any) => {
    this.cancelAnimation();
    const pointerPosition = getRelativePosition(
      event,
      this.imageRef.parentNode,
    );
    this.doubleClick(pointerPosition);
  };

  handleMouseWheel = (event: {
    deltaY?: any;
    cancelable: boolean;
    preventDefault: () => void;
  }) => {
    const { scale = 0 } = this.state;
    const { maxScale } = this.props;
    this.cancelAnimation();
    const point = getRelativePosition(event, this.imageRef.parentNode);
    if (event.deltaY > 0) {
      if (scale > getMinScale(this.state, this.props)) {
        this.zoomOut(point);
        tryCancelEvent(event);
      }
    } else if (event.deltaY < 0) {
      if (scale < maxScale) {
        this.zoomIn(point);
        tryCancelEvent(event);
      }
    }
  };

  handleImageLoad = (event: any) => {
    const { children } = this.props;
    this.isImageLoaded = true;
    this.maybeHandleDimensionsChanged();

    const { onLoad } = (React.Children.only(children) as React.ReactElement)
      .props;
    if (typeof onLoad === 'function') {
      onLoad(event);
    }
  };

  handleZoomInClick = () => {
    this.cancelAnimation();
    this.zoomIn();
  };

  handleZoomOutClick = () => {
    this.cancelAnimation();
    this.zoomOut();
  };

  handleWindowResize = () => this.maybeHandleDimensionsChanged();

  handleRefImage = (ref: any) => {
    const { children } = this.props;
    if (this.imageRef) {
      this.cancelAnimation();
      this.imageRef.removeEventListener('touchmove', this.handleTouchMove);
    }

    this.imageRef = ref;
    if (ref) {
      this.imageRef.addEventListener('touchmove', this.handleTouchMove, {
        passive: false,
      });
    }

    const { ref: imageRefProp } = React.Children.only(children) as { ref: any };
    setRef(imageRefProp, ref);
  };

  // actions
  pointerDown(clientPosition: any) {
    this.lastPanPointerPosition = getRelativePosition(
      clientPosition,
      this.imageRef.parentNode,
    );
  }

  pan(pointerClientPosition: any) {
    if (!this.isTransformInitialized) {
      return;
    }

    if (!this.lastPanPointerPosition) {
      // if we were pinching and lifted a finger
      this.pointerDown(pointerClientPosition);
      return;
    }

    const pointerPosition = getRelativePosition(
      pointerClientPosition,
      this.imageRef.parentNode,
    );
    const translateX = pointerPosition.x - this.lastPanPointerPosition.x;
    const translateY = pointerPosition.y - this.lastPanPointerPosition.y;
    this.lastPanPointerPosition = pointerPosition;

    const { top = 0, left = 0, scale = 0 } = this.state;
    this.constrainAndApplyTransform(
      top + translateY,
      left + translateX,
      scale,
      0,
      0,
    );

     
    return {
      up: translateY > 0 ? translateY : 0,
      down: translateY < 0 ? negate(translateY) : 0,
      right: translateX < 0 ? negate(translateX) : 0,
      left: translateX > 0 ? translateX : 0,
    };
  }

  doubleClick(pointerPosition: { x: number; y: number }) {
    const { doubleTapBehavior, maxScale } = this.props;
    const { scale = 0 } = this.state;
    if (
      String(doubleTapBehavior).toLowerCase() === 'zoom'
      && scale * (1 + OVERZOOM_TOLERANCE) < maxScale
    ) {
      this.zoomIn(pointerPosition, ANIMATION_SPEED, 0.3);
    } else {
      // reset
      this.applyInitialTransform(ANIMATION_SPEED);
    }
  }

  pinchChange(touches: [any, any]) {
    const { scale = 0 } = this.state;
    const length = getPinchLength(touches);
    const midpoint = getPinchMidpoint(touches);
    const correctScale = this.lastPinchLength
      ? (scale * length) / this.lastPinchLength // sometimes we get a touchchange before a touchstart when pinching
      : scale;

    this.zoom(correctScale, midpoint, OVERZOOM_TOLERANCE);

    this.lastPinchLength = length;
  }

  zoomIn(midpoint?: { x: number; y: number }, speed = 0, factor = 0.1) {
    const { containerDimensions = { width: 0, height: 0 }, scale = 0 } = this.state;
    const zoomMidpoint = midpoint || {
      x: containerDimensions.width / 2,
      y: containerDimensions.height / 2,
    };
    this.zoom(scale * (1 + factor), zoomMidpoint, 0, speed);
  }

  zoomOut(midpoint?: { x: number; y: number }) {
    const { containerDimensions = { width: 0, height: 0 }, scale = 0 } = this.state;
    const zoomMidpoint = midpoint || {
      x: containerDimensions.width / 2,
      y: containerDimensions.height / 2,
    };
    this.zoom(scale * 0.9, zoomMidpoint, 0);
  }

  zoom(
    requestedScale: number,
    containerRelativePoint: { x: any; y: any },
    tolerance: number,
    speed = 0,
  ) {
    if (!this.isTransformInitialized) {
      return;
    }

    const { scale = 0, top = 0, left = 0 } = this.state;
    const imageRelativePoint = {
      top: containerRelativePoint.y - top,
      left: containerRelativePoint.x - left,
    };

    const nextScale = this.getConstrainedScale(requestedScale, tolerance);
    const incrementalScalePercentage = (nextScale - scale) / scale;
    const translateY = imageRelativePoint.top * incrementalScalePercentage;
    const translateX = imageRelativePoint.left * incrementalScalePercentage;

    const nextTop = top - translateY;
    const nextLeft = left - translateX;

    this.constrainAndApplyTransform(
      nextTop,
      nextLeft,
      nextScale,
      tolerance,
      speed,
    );
  }

  // compare stored dimensions to actual dimensions; capture actual dimensions if different
  maybeHandleDimensionsChanged() {
    if (this.isImageReady) {
      const containerDimensions = getContainerDimensions(this.imageRef);
      const imageDimensions = getDimensions(this.imageRef) || {
        height: 0,
        width: 0,
      };

      if (
         
        !isEqualDimensions(
          containerDimensions,
          // @ts-ignore
          getDimensions(this.state.containerDimensions),
        )
         
        || !isEqualDimensions(
          imageDimensions,
          // @ts-ignore
          getDimensions(this.state.imageDimensions),
        )
      ) {
        this.cancelAnimation();

        // capture new dimensions
        this.setState(
          {
            containerDimensions,
            imageDimensions,
          },
          () => {
            // When image loads and image dimensions are first established, apply initial transform.
            // If dimensions change, constraints change; current transform may need to be adjusted.
            // Transforms depend on state, so wait until state is updated.
            if (!this.isTransformInitialized) {
              this.applyInitialTransform();
            } else {
              this.maybeAdjustCurrentTransform();
            }
          },
        );
      }
    }
  }

  // transformation methods

  // Zooming and panning cause transform to be requested.
  constrainAndApplyTransform(
    requestedTop: number,
    requestedLeft: number,
    requestedScale: number,
    tolerance: number,
    speed = 0,
  ) {
    const requestedTransform = {
      top: requestedTop,
      left: requestedLeft,
      scale: requestedScale,
    };

    // Correct the transform if needed to prevent overpanning and overzooming
    const transform = this.getCorrectedTransform(requestedTransform, tolerance)
      || requestedTransform;

    if (isEqualTransform(transform, this.state)) {
      return false;
    }

    this.applyTransform(transform, speed);
    return true;
  }

  resetZooming() {
    this.setState({
      top: this.props.initialTop,
      left: this.props.initialLeft,
      scale: this.props.initialScale,
    });
  }

  applyTransform(
    { top, left, scale }: { top: any; left: any; scale: any },
    speed: number,
  ) {
    if (speed > 0) {
      const frame = () => {
        // @ts-ignore
        const translateY = top - this.state.top;
        // @ts-ignore
        const translateX = left - this.state.left;
        // @ts-ignore
        const translateScale = scale - this.state.scale;

        const nextTransform = {
          // @ts-ignore
          top: snapToTarget(this.state.top + speed * translateY, top, 1),
          // @ts-ignore
          left: snapToTarget(this.state.left + speed * translateX, left, 1),

          scale: snapToTarget(
            // @ts-ignore
            this.state.scale + speed * translateScale,
            scale,
            0.001,
          ),
        };

        // animation runs until we reach the target
        if (!isEqualTransform(nextTransform, this.state)) {
          this.setState(nextTransform, () => {
            this.animation = requestAnimationFrame(frame);
          });
        }
      };
      this.animation = requestAnimationFrame(frame);
    } else {
      this.setState({
        top,
        left,
        scale,
      });
    }
  }

  // Returns constrained scale when requested scale is outside min/max with tolerance, otherwise returns requested scale
  getConstrainedScale(requestedScale: number, tolerance: number) {
    const { maxScale } = this.props;
    const lowerBoundFactor = 1.0 - tolerance;
    const upperBoundFactor = 1.0 + tolerance;

    return constrain(
      getMinScale(this.state, this.props) * lowerBoundFactor,
      maxScale * upperBoundFactor,
      requestedScale,
    );
  }

  // Returns constrained transform when requested transform is outside constraints with tolerance, otherwise returns null
  getCorrectedTransform(
    requestedTransform: { top?: any; left?: any; scale?: any },
    tolerance: number,
  ) {
    const scale = this.getConstrainedScale(requestedTransform.scale, tolerance);

    // get dimensions by which scaled image overflows container
    const negativeSpace = this.calculateNegativeSpace(scale);
    const overflow = {
      width: Math.max(0, negate(negativeSpace.width)),
      height: Math.max(0, negate(negativeSpace.height)),
    };

    // if image overflows container, prevent moving by more than the overflow
    // example: overflow.height = 100, tolerance = 0.05 => top is constrained between -105 and +5
    const { position, initialTop, initialLeft } = this.props;
    const {
      imageDimensions = { height: 0, width: 0 },
      containerDimensions = { height: 0, width: 0 },
    } = this.state;
    const upperBoundFactor = 1.0 + tolerance;
    const top = overflow.height
      ? constrain(
        negate(overflow.height) * upperBoundFactor,
        overflow.height * upperBoundFactor - overflow.height,
        requestedTransform.top,
      )
      : position === 'center'
        ? (containerDimensions.height - imageDimensions.height * scale) / 2
        : initialTop || 0;

    const left = overflow.width
      ? constrain(
        negate(overflow.width) * upperBoundFactor,
        overflow.width * upperBoundFactor - overflow.width,
        requestedTransform.left,
      )
      : position === 'center'
        ? (containerDimensions.width - imageDimensions.width * scale) / 2
        : initialLeft || 0;

    const constrainedTransform = {
      top,
      left,
      scale,
    };

    return isEqualTransform(constrainedTransform, requestedTransform)
      ? null
      : constrainedTransform;
  }

  // Ensure current transform is within constraints
  maybeAdjustCurrentTransform(speed = 0) {
    let correctedTransform: { top: number; left: number; scale: number } | null;
     
    if ((correctedTransform = this.getCorrectedTransform(this.state, 0))) {
      this.applyTransform(correctedTransform, speed);
    }
  }

  applyInitialTransform(speed = 0) {
    const {
      imageDimensions = {
        width: 0,
        height: 0,
      },
      containerDimensions = {
        width: 0,
        height: 0,
      },
    } = this.state;
    const {
      position, initialScale, maxScale, initialTop, initialLeft,
    } = this.props;

    const scale = String(initialScale).toLowerCase() === 'auto'
      && containerDimensions
      && imageDimensions
      ? getAutofitScale(containerDimensions, imageDimensions)
      : initialScale;
    const minScale = getMinScale(this.state, this.props);

    if (minScale > maxScale) {
      warning(false, 'minScale cannot exceed maxScale.');
      return;
    }
    if (scale < minScale || scale > maxScale) {
      warning(false, 'initialScale must be between minScale and maxScale.');
      return;
    }

    let initialPosition: { top: any; left: any };
    if (position === 'center') {
      warning(
        initialTop === undefined,
        'initialTop prop should not be supplied with position=center. It was ignored.',
      );
      warning(
        initialLeft === undefined,
        'initialLeft prop should not be supplied with position=center. It was ignored.',
      );
      initialPosition = {
        top: (containerDimensions.width - imageDimensions.width * scale) / 2,
        left: (containerDimensions.height - imageDimensions.height * scale) / 2,
      };
    } else {
      initialPosition = {
        top: initialTop || 0,
        left: initialLeft || 0,
      };
    }

    this.constrainAndApplyTransform(
      initialPosition.top,
      initialPosition.left,
      scale,
      0,
      speed,
    );
  }

  // lifecycle methods
  render() {
    const { children } = this.props;
    const childElement = React.Children.only(children) as React.ReactElement;
    const { zoomButtons, maxScale } = this.props;
    const { scale } = this.state;

    const touchAction = this.controlOverscrollViaCss
      ? browserPanActions(this.state) || 'none'
      : undefined;

    const containerStyle = {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      touchAction,
    };

    return (
      <div style={containerStyle}>
        {zoomButtons && this.isImageReady && this.isTransformInitialized && (
          <ZoomButtons
            scale={scale}
            minScale={getMinScale(this.state, this.props)}
            maxScale={maxScale}
            onZoomOutClick={this.handleZoomOutClick}
            onZoomInClick={this.handleZoomInClick}
          />
        )}
        {React.cloneElement(childElement, {
          onTouchStart: this.handleTouchStart,
          onTouchEnd: this.handleTouchEnd,
          onMouseDown: this.handleMouseDown,
          onMouseMove: this.handleMouseMove,
          onDoubleClick: this.handleMouseDoubleClick,
          onWheel: this.handleMouseWheel,
          onDragStart: tryCancelEvent,
          onLoad: this.handleImageLoad,
          onContextMenu: tryCancelEvent,
          ref: this.handleRefImage,
          style: imageStyle(this.state),
        })}
      </div>
    );
  }

  static getDerivedStateFromProps(
    nextProps: {
      initialTop: any;
      initialLeft: any;
      initialScale: any;
      position: any;
    },
    prevState: {
      initialTop: any;
      initialLeft: any;
      initialScale: any;
      position: any;
    },
  ) {
    if (
      nextProps.initialTop !== prevState.initialTop
      || nextProps.initialLeft !== prevState.initialLeft
      || nextProps.initialScale !== prevState.initialScale
      || nextProps.position !== prevState.position
    ) {
      return {
        position: nextProps.position,
        initialScale: nextProps.initialScale,
        initialTop: nextProps.initialTop,
        initialLeft: nextProps.initialLeft,
      };
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.maybeHandleDimensionsChanged();
  }

  componentDidUpdate({ zoomButtons }: PinchZoomPanProps) {
    this.maybeHandleDimensionsChanged();

    if (this.props.zoomButtons !== zoomButtons) {
      this.resetZooming();
    }
  }

  componentWillUnmount() {
    this.cancelAnimation();
    this.imageRef.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  get isImageReady() {
    return (
      this.isImageLoaded || (this.imageRef && this.imageRef.tagName !== 'IMG')
    );
  }

  get isTransformInitialized() {
    const { top, left, scale } = this.state;
    return isInitialized(top, left, scale);
  }

   
  get controlOverscrollViaCss() {
    return window.CSS && window.CSS.supports('touch-action', 'pan-up');
  }

  calculateNegativeSpace(scale: number) {
    // get difference in dimension between container and scaled image
    const {
      containerDimensions = {
        width: 0,
        height: 0,
      },
      imageDimensions = {
        width: 0,
        height: 0,
      },
    } = this.state;
    const width = containerDimensions.width - scale * imageDimensions.width;
    const height = containerDimensions.height - scale * imageDimensions.height;
    return {
      width,
      height,
    };
  }

  cancelAnimation() {
    if (this.animation) {
      cancelAnimationFrame(this.animation);
    }
  }
}
