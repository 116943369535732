import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, costFormater, Inscription, LinkButton } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DATE_TOOLTIP_FORMAT } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { DeclineReasonType, DeclineReasonTypes } from '@/modules/transaction/transaction.types';

import LinkToCIP from './LinkToCIP';
import PastPlanEndDateText from './PastPlanEndDateText';

const StyledList = styled(Box)`
  font-size: 16px;
  ol {
    list-style: auto;
    margin-left: 22px;
    margin-top: ${({ theme }) => theme.spacings.spacing8};
    margin-bottom: ${({ theme }) => theme.spacings.spacing8};
  }
`;

interface Props {
  data?: DeclineReasonType;
  serviceDate?: string;
}

const useGetDeclineReasonMessage = (props: Props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { data, serviceDate } = props;

  return useMemo(() => {
    const jsonData = data?.jsonData || [];
    const path = `${ROUTES.CARD_DETAILS}?${jsonData.find((item) => item.cardId)?.cardId}`;
    switch (data?.type) {
    case DeclineReasonTypes.NOT_ACTIVATED:
      return {
        mainText: t('Card Decline: Card Not Activated'),
        text: t('This has been declined because the card used was not activated at the time swiped. '),
        cardLink: (
          <LinkButton onClick={() => push(path)} linkInlineMode>
            {t('Confirm Cards are Activated')}
          </LinkButton>
        ),
      };
    case DeclineReasonTypes.CARD_LOCKED:
      return {
        mainText: t('Card Decline: Card Frozen'),
        text: `${`${t('Your card was locked on')} ${dayjs(serviceDate || '').format(DATE_TOOLTIP_FORMAT)} ${t('when used at')} ${jsonData?.find((item) => item?.provider)?.provider} ${t('for')} ${costFormater(jsonData?.find((item) => item?.amount)?.amount, true)}. `}`,
        cardLink: (
          <LinkButton onClick={() => push(path)} linkInlineMode>
            {t('Check Card Status')}
          </LinkButton>
        ),
      };
    case DeclineReasonTypes.CARD_LOST:
      return {
        mainText: t('Card Decline: Card Lost/Stolen'),
        text: t('This card was reported as lost or stolen and can\'t be used. Your new card should be in the mail if you haven’t already received it. '),
        cardLink: (
          <LinkButton onClick={() => push(path)} linkInlineMode>
            {t('Confirm Card Status')}
          </LinkButton>
        ),
      };
    case DeclineReasonTypes.PIN_REQUIRED:
      return {
        mainText: t('Card Declined: PIN Required'),
        text: t('Walmart requires a PIN for all healthcare related purchases. A PIN was not provided causing the card to be declined. For future Walmart purchases, be sure to process using Debit and include your PIN.'),
      };
    case DeclineReasonTypes.PIN_INVALID:
      return {
        mainText: t('Card Decline: Invalid PIN'),
        text: t('The PIN you entered does not match the PIN setup for your card. Please try again.'),
      };
    case DeclineReasonTypes.CVS_NOT_ALLOWED:
      return {
        mainText: t('Card Decline: Credit Required'),
        text: t('CVS does not allow Debit for healthcare cards. For future purchases, please select Credit.'),
      };
    case DeclineReasonTypes.NOT_HEALTHCARE_SERVICE:
      return {
        mainText: t('Card Decline: Ineligible Item'),
        text: t('Item(s) in this purchase are not eligible. Please use other means to purchase these products and submit for reimbursement if you believe the item is eligible.'),
      };
    case DeclineReasonTypes.NOT_ALLOWED_SERVICE_FOR_PLAN:
      return {
        mainText: t('Card Decline: Ineligible Item'),
        text: t('Item(s) in this purchase are not eligible. Please use other means to purchase these products and submit for reimbursement if you believe the item is eligible.'),
      };
    case DeclineReasonTypes.PRIOR_TO_PLAN_START_DATE:
      return {
        mainText: t('Card Decline: Prior to Plan Start Date'),
        text: (
          <Box margin={{ top: 'spacing8' }}>
            {jsonData?.find((item) => item?.coverageDateStart)?.coverageDateStart ? <Inscription size="large">{`${t('Funds in your account* are not available until')} ${dayjs(jsonData?.find((item) => item?.coverageDateStart)?.coverageDateStart).format(DATE_TOOLTIP_FORMAT)}.`}</Inscription> : <Inscription size="large">{t('Funds in your account* are not available yet.')}</Inscription>}
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{`${t('Account:')} ${jsonData?.map((item) => item.planName)?.join(', ')}`}</Inscription>
          </Box>
        ),
      };
    case DeclineReasonTypes.PAST_PLAN_END_DATE:
      return {
        mainText: t('Card Decline: Past Plan End Date'),
        text: (
          <>
            <PastPlanEndDateText
              cardSwipeDate={jsonData?.find((item) => item.cardSwipeDate)?.cardSwipeDate}
              coverageDateEnd={jsonData?.find((item) => item.coverageDateEnd)?.coverageDateEnd}
            />
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{`${t('Account:')} ${jsonData?.map((item) => item.planName)?.join(', ')}`}</Inscription>
          </>
        ),
      };
    case DeclineReasonTypes.NO_AVAILABLE_BALANCE:
      return {
        mainText: t('Card Decline: Insufficient Funds'),
        text: (
          <Box margin={{ top: 'spacing8' }}>
            <Inscription size="large">{`${t('On')} ${dayjs(serviceDate || '').format(DATE_TOOLTIP_FORMAT)} ${t('the account did not have any remaining funds*.  If funds become available at a future date, you can submit a reimbursement request for this expense.')}`}</Inscription>
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{`${t('Account:')} ${jsonData?.map((item) => item.planName)?.join(', ')}`}</Inscription>
          </Box>
        ),
      };
    case DeclineReasonTypes.NOT_ENOUGH_BALANCE:
      return {
        mainText: t('Card Decline: Insufficient Funds'),
        text: (
          <Box margin={{ top: 'spacing8' }}>
            <Inscription size="large">{`${t('You do not have enough funds* to cover the full $')}${jsonData?.find((item) => item?.amount)?.amount}. ${jsonData?.find((item) => item?.provider)?.provider} ${t('does not accept partial payments.')}`}</Inscription>
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{`${t('Account:')} ${jsonData?.map((item) => item.planName)?.join(', ')}`}</Inscription>
          </Box>
        ),
      };
    case DeclineReasonTypes.ORG_LOCKED:
      return {
        mainText: t('Card Decline: Payments Locked'),
        text: (
          <Box margin={{ top: 'spacing8' }}>
            <Inscription size="large">{t('Your organization has a temporary lock on payments. Please contact your employer.')}</Inscription>
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{`${t('Account')}: ${jsonData?.map((item) => item.planName)?.join(', ')}`}</Inscription>
          </Box>
        ),
      };
    case DeclineReasonTypes.OVERLIMIT:
      return {
        mainText: t('Card Decline: Spend Limit Reached'),
        text: (
          <Box margin={{ top: 'spacing8' }}>
            <Inscription size="large">{`${t('Your account has a monthly spend limit of')} $${jsonData?.find((item) => item.spendLimit)?.spendLimit}. ${t('This transaction will put you over the limit. You may use funds in this account again starting the first of the month.')}`}</Inscription>
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{`${t('Account')}: ${jsonData?.map((item) => item.planName)?.join(', ')}`}</Inscription>
          </Box>
        ),
      };
    case DeclineReasonTypes.GENERIC:
      return {
        mainText: t('Card Decline: Card Declined at Point of Sale'),
        text: (
          <StyledList margin={{ top: 'spacing8' }}>
            Your card was declined. There could be a few reasons causing it to decline:
            <ol>
              <li><Inscription size="16px" weight="bold">Card Type Selection</Inscription>: If you selected, DEBIT, retry the transaction by choosing CREDIT or bypassing the PIN entry.</li>
              <li><Inscription size="16px" weight="bold">Merchant Acceptance</Inscription>: Some pharmacies, grocery, or online retailers, may not accept FSA/HSA cards.</li>
              <li><Inscription size="16px" weight="bold">Fund Availability and Eligibility</Inscription>: Ensure you have sufficient funds and are making a purchase at an authorized provider for eligible expenses.</li>
            </ol>
            <Inscription size="16px">You can always pay out of pocket and submit your receipts for reimbursement.</Inscription>
          </StyledList>
        ),
      };
    case DeclineReasonTypes.PEND_CONTRIBUTIONS:
      return {
        mainText: t('Card Decline: Pending Settlement'),
        text: (
          <Box margin={{ top: 'spacing8' }}>
            <Inscription size="large">{t('Your account currently does not have enough funds to cover this expense. Additional contributions are pending on your account and will be available to spend within a few days.')}</Inscription>
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{t('Account:')} {jsonData?.map((item) => item.planName)?.join(', ')}</Inscription>
          </Box>
        ),
      };
    case DeclineReasonTypes.PEND_CONTRIBUTIONS_AND_CIP:
      return {
        mainText: t('Card Decline: Account Verification Required'),
        text: (
          <Box margin={{ top: 'spacing8' }}>
            <Inscription size="large">{t('We need additional information to verify your identify and open your account. Once verified, the contributions on your account will be available to spend. Verify your account now.')} <LinkToCIP /></Inscription>
            <Inscription margin={{ top: 'spacing8' }} color="textSecondary" size="small">{t('Account:')} {jsonData?.map((item) => item.planName)?.join(', ')}</Inscription>
          </Box>
        ),
      };
    default:
      return {};
    }
  }, [data, serviceDate, push, t]);
};

export default useGetDeclineReasonMessage;
