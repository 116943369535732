import React from 'react';
import {
  Box, Image, RadioButton,
  Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { LARGE_SIZE_LOGO } from '@/common/constants';
import { LinkedEmployee } from '@/modules/user/user.types';

interface StyledProps {
  isSelected?: boolean;
}

const EmployerCardWrapper = styled(Box)<StyledProps>`
  position: relative;
  cursor: pointer;
  ${({ theme }) => theme.shadows.default};

  &:hover {
    ${({ theme }) => theme.shadows.hover};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ theme }) => theme.spacings.spacing8};
    border: ${({ theme, isSelected }) => (isSelected
    ? `2px solid ${theme.colors.accentActive}`
    : `1px solid ${theme.colors.border2}`)};
  }

  &:hover::before {
    border: ${({ theme, isSelected }) => (isSelected ? undefined : `2px solid ${theme.colors.border}`)};
  }
`;

interface Props {
  linkedEmployee: LinkedEmployee;
  onSwitching: (employeeId: string) => void;
  isSelected?: boolean;
  testId?: string;
}

export const EmployeeSwitcherItem: React.FC<Props> = ({
  linkedEmployee,
  onSwitching,
  isSelected,
  testId,
}) => (
  <EmployerCardWrapper
    pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
    margin={{ top: 'spacing8' }}
    round="container1Round"
    background="canvas"
    onClick={() => onSwitching(linkedEmployee?.employeeId)}
    data-testid={`${testId}-employee-switcher-card-wrapper`}
    direction="row"
    justify="between"
    align="center"
    isSelected={isSelected}
  >
    <Box>
      <Box direction="row" justify="between" align="center">
        <Image
          className="image"
          fit="contain"
          alt="logo"
          src={linkedEmployee?.largeLogoUrl}
          height={LARGE_SIZE_LOGO.height}
          style={{
            maxWidth: LARGE_SIZE_LOGO.width,
          }}
        />
      </Box>

      <Box margin={{ top: 'spacing8' }}>
        <Text size="12px" weight={500}>
          {linkedEmployee?.enrollmentTitle}
        </Text>
      </Box>
    </Box>

    <RadioButton
      name={linkedEmployee?.employeeId || ''}
      data-testid={`radio-button-option-${linkedEmployee?.employeeId}-${isSelected}`}
      checked={isSelected}
    />
  </EmployerCardWrapper>
);
