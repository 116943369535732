import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Address } from '@/common/types';

import useProviderStore from './AddProviderForm/useProvider.store';

const DEFAULT_ADDRESS_TYPE = 'RESIDENTIAL';

export interface ProviderPayload {
  id: string;
  name: string;
}

export interface Provider {
  id?: string;
  name?: string;
  address?: Address;
}

interface StateDto {
  id?: string;
  name?: string;
  code?: string;
}

interface ProviderAddressDto {
  line1?: string;
  line2?: string | null;
  city?: string;
  zipcode?: string;
  state?: StateDto,
  address_type?: string;
  validated?: boolean;
}

export interface ProviderDto {
  id?: string;
  facility_name?: string;
  first_name?: string;
  last_name: string;
  card_acceptor_id?: string;
  tax_id?: string;
  addresses?: ProviderAddressDto[];
}

const formatProviderToServer = (provider: Provider): Partial<ProviderDto> => ({
  // Currently, the name of the provider must be stored in the last_name field
  facility_name: provider?.name,
  ...provider?.address ? {
    addresses: [{
      ...provider?.address,
      address_type: DEFAULT_ADDRESS_TYPE,
      validated: false,
    }],
  } : {},
});

const formatProvider = (provider: ProviderDto): Provider => {
  const defaultAddress = provider?.addresses?.find(
    (address: ProviderAddressDto) => address?.address_type === DEFAULT_ADDRESS_TYPE,
  );

  return {
    id: provider?.id,
    name: provider?.facility_name,
    address: {
      line1: defaultAddress?.line1,
      ...defaultAddress?.line2 ? {
        line2: defaultAddress?.line2,
      } : {},
      city: defaultAddress?.city,
      zipcode: defaultAddress?.zipcode,
      state: defaultAddress?.state,
    },
  };
};

export const useCreateProviderQuery = (onSuccess?: (provider?: Provider) => void) => {
  const { setProviderId } = useProviderStore();
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (value: Provider) => api.post(
      PATHS.ADD_PROVIDER,
      formatProviderToServer(value),
    ),
    {
      onSuccess: (newData) => {
        setLoading(false);
        setProviderId(`${newData?.data.id || ''}`);
        if (onSuccess) {
          onSuccess(formatProvider(newData?.data));
        }
        queryClient.invalidateQueries(PATHS.GET_PROVIDERS);
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const handleSave = async (
    value: Provider,
    onSuccessfullyCreate?: (providerName?: string) => void,
  ) => {
    setLoading(true);
    const createdProviderResponse = await mutateAsync(value);
    const createdProvider: ProviderDto = createdProviderResponse?.data;
    if (onSuccessfullyCreate) {
      onSuccessfullyCreate(createdProvider?.facility_name);
    }
  };

  return {
    isLoading,
    save: handleSave,
  };
};

export const useUpdateProviderQuery = (onSuccess?: (provider: Provider) => void) => {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (value: Provider) => api.put(
      PATHS.UPDATE_PROVIDER(value?.id),
      formatProviderToServer(value),
    ),
    {
      onSuccess: (newData) => {
        setLoading(false);
        if (onSuccess) {
          onSuccess(formatProvider(newData?.data));
        }
        queryClient.invalidateQueries({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          predicate: (query: any) => {
            if (Array.isArray([query.queryKey]) && query.queryKey.some) {
              return query.queryKey
                .some((key: string) => key === PATHS.GET_PROVIDERS);
            }

            return false;
          },
        });
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const updateProvider = async (value: Provider) => {
    setLoading(true);
    await mutateAsync(value);
  };

  return {
    isLoading,
    updateProvider,
  };
};

interface GetProvidersQueryProps {
  onSuccess?: () => void;
  scannedProvider?: string;
}

export const useGetProvidersQuery = ({ onSuccess, scannedProvider }: GetProvidersQueryProps) => {
  const {
    isLoading, isSuccess, isError, data, refetch,
  } = useQuery(
    [PATHS.GET_PROVIDERS],
    () => api.get<ProviderDto[]>(PATHS.GET_PROVIDERS),
    {
      onSuccess: (data) => {
        const providers = data?.data;
        const hasNoProvider = scannedProvider && !providers?.find((provider) => provider.facility_name === scannedProvider);

        if (hasNoProvider && onSuccess) {
          onSuccess();
        }
      }
    },
  );
  return ({
    providers: (data?.data || [])
      .map((provider: ProviderDto) => formatProvider({
        ...provider,
        id: toString(provider?.id),
      })) as Provider[],
    isError,
    isLoading,
    isSuccess,
    refetch,
  });
};

export const useGetProviderByIdQuery = (id: string) => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    [PATHS.GET_PROVIDER(id), id],
    () => api.get<ProviderDto>(PATHS.GET_PROVIDER(id)),
    { enabled: Boolean(id) },
  );

  return ({
    provider: data?.data || {},
    formattedProvider: formatProvider(data?.data as ProviderDto) || {},
    isError,
    isLoading,
    isSuccess,
  });
};
