import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { CIPStatus,Enrollment, onlyDateFromServer } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { useAuthStore } from '@/modules/user/stores';

interface CoveragePeriods {
  coverage_date_start: string | null;
  coverage_date_end: string | null;
}

interface RawEnrollment {
  account_type: string;
  account_id: number;
  enrollment_title: string;
  available_amount: number;
  election_amount: number;
  cip_status: CIPStatus;
  cip_required?: boolean;
  allow_investments: true;
  account_status: string;
  enrollment_status: string;
  investment_threshold: number;
  investment_option_types: string[];
  // pending_trade_amount: number;
  minimum_investment_amount: number;
  account_number?: string;
  coverage_periods: CoveragePeriods[];
  submit_claims_date_end: string;
  allow_individual_contributions?: boolean;
  contribution_monitoring_required?: boolean;
  generate_statements?: boolean;
  minimum_contribution_amount?: number;
  catch_up_election?: number;
  plan_code?: string;
  is_replenished?: boolean;
  display_name?: string;
}

export const QUERY_KEY = 'getAccounts';

const useMyEnrollments = (id?: string | number | null, status?: string | null) => {
  const { user, auth } = useAuthStore();
  const {
    isLoading, isSuccess, error, data, refetch,
  } = useQuery(
    [QUERY_KEY, status, auth],
    () => api.get<RawEnrollment[]>(
      PATHS.MY_ENROLLMENT, {
        params:
        _.pickBy({
          status,
        }),
      },
    ),
    {
      enabled: !!user
    }
  );

  useEffect(() => {
    if (user?.employee && auth) refetch();
  }, [user, auth, refetch]);

  const getFormatedEnrollment = useCallback((rawEnrollment: RawEnrollment) => ({
    id: `${rawEnrollment.account_id || ''}`,
    name: rawEnrollment.enrollment_title,
    available: rawEnrollment.available_amount,
    election: rawEnrollment.election_amount,
    CIPStatus: rawEnrollment.cip_status,
    isCIPRequired: rawEnrollment.cip_required,
    isReplenished: rawEnrollment.is_replenished,
    accountType: rawEnrollment.account_type,
    allowInvestments: rawEnrollment.allow_investments,
    accountStatus: rawEnrollment.account_status,
    enrollmentStatus: rawEnrollment.enrollment_status,
    investmentThreshold: rawEnrollment.investment_threshold,
    minimumInvestmentAmount: rawEnrollment.minimum_investment_amount,
    investmentOptionTypes: rawEnrollment.investment_option_types,
    // pendingTradeAmount: rawEnrollment.pending_trade_amount,
    accountNumber: rawEnrollment.account_number,
    allowIndividualContributions: rawEnrollment.allow_individual_contributions,
    contributionMonitoringRequired: rawEnrollment?.contribution_monitoring_required,
    minimumContributionAmount: rawEnrollment?.minimum_contribution_amount,
    generateStatements: rawEnrollment?.generate_statements,
    catchUpElection: rawEnrollment?.catch_up_election,
    planCode: rawEnrollment?.plan_code,
    displayName: rawEnrollment?.display_name,
    coveragePeriods: rawEnrollment?.coverage_periods?.map((item) => ({
      startDate: item?.coverage_date_start
        ? dayjs(onlyDateFromServer(item?.coverage_date_start)).format(DEFAULT_DATE_FORMAT)
        : 'No deadline',
      endDate: item?.coverage_date_end
        ? dayjs(onlyDateFromServer(item?.coverage_date_end)).format(DEFAULT_DATE_FORMAT)
        : 'No deadline',
    })),
    coverageDateStart: rawEnrollment?.coverage_periods?.[rawEnrollment?.coverage_periods?.length - 1]?.coverage_date_start
      ? dayjs(onlyDateFromServer(rawEnrollment?.coverage_periods?.[rawEnrollment?.coverage_periods?.length - 1]?.coverage_date_start || ''))
        .format(DEFAULT_DATE_FORMAT) : '',
    coverageDateEnd: rawEnrollment?.coverage_periods?.[0]?.coverage_date_end
      ? dayjs(onlyDateFromServer(rawEnrollment?.coverage_periods?.[0]?.coverage_date_end))
        .format(DEFAULT_DATE_FORMAT) : '',
    submitClaimsDateEnd: rawEnrollment?.submit_claims_date_end
      ? dayjs(onlyDateFromServer(rawEnrollment.submit_claims_date_end)).format(DEFAULT_DATE_FORMAT)
      : '',
  }), []);
  const currentMyAccount = useMemo(() => {
    const list = data?.data || [];
    const foundItem = list.find((item: RawEnrollment) => `${item.account_id}` === id);
    if (foundItem) {
      return getFormatedEnrollment(foundItem);
    }
    return null;
  }, [data, getFormatedEnrollment, id]) as Enrollment;
  const HSA_TYPE_KEY = 'HSA';
  // const currentMyAccount: RawEnrollment | null | undefined = useMemo(
  //   () => {
  //     const list = data?.data || [];
  //     const item = list.find((item) => `${item.account_id}` === id);
  //     if(item) {
  //       // return {
  //       //   id: item.account_id,
  //       //   name: item.enrollment_title,
  //       //   available: item.available_amount,
  //       //   election: item.election_amount,
  //       //   accountType: item.account_type,
  //       // }
  //     }
  //     return null;
  //   }), [data, id],
  // );
  const mainHSAEnrollment = useMemo(
    () => {
      const list = data?.data || [];
      const foundItem = list.find((item: RawEnrollment) => item.account_type === HSA_TYPE_KEY);
      if (foundItem) {
        return getFormatedEnrollment(foundItem);
      }

      return null;
    }, [data, getFormatedEnrollment],
  );
  const isHSAExists = useMemo(() => {
    const list = data?.data || [];
    return list.find((item: RawEnrollment) => item.account_type === HSA_TYPE_KEY);
  }, [data]);
  const isCurrentHSA = useMemo(() => {
    if (currentMyAccount) {
      return currentMyAccount.accountType === HSA_TYPE_KEY;
    }
    return false;
  }, [currentMyAccount]);

  const hasEnrollmentWithDisplayName = useMemo(() => {
    const list = data?.data || [];
    return list.some((item: RawEnrollment) => item.display_name);
  }, [data]);

  const isGenerateStatementsEnabled = useMemo(
    () => !!currentMyAccount?.generateStatements,
    [currentMyAccount],
  );

  const formatedData = useMemo(
    () => data?.data?.map((item: RawEnrollment) => getFormatedEnrollment(item)),
    [data, getFormatedEnrollment],
  ) as Enrollment[];

  const isActiveAndReplenished = useMemo(() => {
    return formatedData?.some((item) => !!item?.coveragePeriods?.length && item?.isReplenished);
  }, [formatedData]);
  
  return {
    data: formatedData || [],
    isHSAExists,
    mainHSAEnrollment,
    currentMyAccount,
    error,
    isCurrentHSA,
    hasEnrollmentWithDisplayName,
    isGenerateStatementsEnabled,
    isActiveAndReplenished,
    isLoading,
    isSuccess,
  };
};

export default useMyEnrollments;
