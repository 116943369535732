import React from 'react';
import { Box, Heading } from '@common-fe/common-fe';

import { InvestmentsActivityType } from '../../investments.types';

import SelectButton from './SelectButton';

enum InvestmentActivityOptions {
  INVESTMENT_ACTIVITY_PENDING = 'Pending Activities',
  INVESTMENT_ACTIVITY_HOLDING = 'Current Holdings',
}

interface Props {
  currentTab: InvestmentsActivityType;
  onSetCurrentTab: (tab: InvestmentsActivityType) => void;
  totalPending: number;
  totalHoldings: number;
  title?: string;
}

const ActivityListToggle: React.FC<Props> = ({
  onSetCurrentTab,
  currentTab,
  totalPending = 0,
  totalHoldings = 0,
  title,
}) => (
  <Box direction="row" justify="between" align="center" margin={{ horizontal: 'spacing24', top: 'spacing24' }} height={{ min: 'auto' }}>
    <Heading level={4} size="16px">{title}</Heading>
    <Box direction="row" align="center">
      <Box
        direction="row"
        background="module"
        round="button2Round"
        pad="xsmall"
      >
        <>
          <SelectButton
            onClick={() => onSetCurrentTab(InvestmentsActivityType.Holding)}
            currTab={currentTab}
            name={InvestmentsActivityType.Holding}
            text={`${InvestmentActivityOptions.INVESTMENT_ACTIVITY_HOLDING} (${totalHoldings})`}
          />
          <SelectButton
            onClick={() => onSetCurrentTab(InvestmentsActivityType.Pending)}
            currTab={currentTab}
            name={InvestmentsActivityType.Pending}
            text={`${InvestmentActivityOptions?.INVESTMENT_ACTIVITY_PENDING} (${totalPending})`}
          />
        </>
      </Box>
    </Box>
  </Box>
);

export default ActivityListToggle;
