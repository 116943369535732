import React, { useEffect } from 'react';
import { Box, ReceiptScanIcon } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import useProgress from '@/components/controls/DropScan/hooks/useProgress';

import {
  InnerProgressBar,
  ProcessScanWrapper,
  ProgressBar,
  Subtitle,
  Title,
  Wrapper,
} from './ProcessScan.styles';

interface Props {
  onNext: () => void;
  areFilesUploaded: boolean;
  wrapperStyle?: React.CSSProperties;
  processScanWrapperStyle?: React.CSSProperties;
  onClose?: () => void;
}
export const ProcessScan: React.FC<Props> = ({
  onNext,
  areFilesUploaded,
  wrapperStyle,
  processScanWrapperStyle,
  onClose,
}) => {
  const { progress, setProgress } = useProgress();
  const handleCancel = () => {
    if (onClose) onClose();
    setProgress(0);
    onNext();
  };

  return (
    <Wrapper style={wrapperStyle}>
      <ProcessScanWrapper style={processScanWrapperStyle}>
        <Box margin={{top: 'l'}}>
          <ReceiptScanIcon size="72px" color='iconAccent' />
        </Box>
      
        <Title>Hang tight while we scan your file…</Title>
        <ProgressBar>
          <InnerProgressBar progress={progress} />
        </ProgressBar>
        <Subtitle>{`${Math.floor(progress * 100)}% Scanned`}</Subtitle>
        <AppButton
          buttonType="secondary"
          disabled={areFilesUploaded}
          onClick={handleCancel}
        >
          Cancel Scanning
        </AppButton>
      </ProcessScanWrapper>
    </Wrapper>
  );
};

export default ProcessScan;
