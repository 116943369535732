import { storeCreator } from '@/utils/store/store.utils';

interface State {
  highlightedDependentId?: string;
  setHighlightedDependentId: (value: string) => void;
  reset: () => void;
}

const useHighlightedDependentStore = storeCreator<State>((set) => ({
  highlightedDependentId: undefined,

  setHighlightedDependentId: (value: string) => set((state) => ({
    ...state,
    highlightedDependentId: value,
  })),

  reset: () => set((state) => ({
    ...state,
    highlightedDependentId: undefined,
  })),
}));

export default useHighlightedDependentStore;
