import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  AppButton,
  Box,
  RoundDoneIcon,
  Text,
} from '@common-fe/common-fe';

import { LINKED_EMPLOYEE_STORAGE_KEY } from '@/common/constants';
import routes from '@/common/routes';
import { ModalWrapper } from '@/components/wrappers';
import { useFormatLinkedEmployees } from '@/modules/user/components/UserDrodpdown/components/OrphanModeSwitcher/hooks';
import { useGetLinkedAccountsQuery } from '@/modules/user/components/UserDrodpdown/components/OrphanModeSwitcher/queries';
import { useAuthStore } from '@/modules/user/stores';

import useOrphanModeStore from '../../store/useOrphanMode.store';

import { EmployeeSwitcher } from './EmployeeSwitcher';

const DESCRIPTION = 'Select the employer from the provided list and gain access to your individual HSA or stay at this Employer. You can switch between employers at any time.';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export const SwitchEmployeeModal: React.FC<Props> = ({ onClose, visible }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const history = useHistory();
  const { user, employeeId } = useAuthStore();
  const {
    linkedEmployee,
    setLinkedEmployee,
    setPrevLinkedEmployee,
  } = useOrphanModeStore();

  const linkedEmployeeId = useMemo(() => linkedEmployee?.employeeId, [linkedEmployee]);

  const { formattedData } = useGetLinkedAccountsQuery({ enabled: !!user?.linkedEmployees });
  const formattedLinkedEmployees = useFormatLinkedEmployees({
    linkedEmployeesString: user?.linkedEmployees,
    linkedEmployees: formattedData,
  });
  const initialId = useMemo(() => linkedEmployeeId || employeeId, [employeeId, linkedEmployeeId]);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(linkedEmployeeId || employeeId);

  const handleCloseModal = useCallback(() => {
    onClose();
    history.push(routes.HOME);
    window.location.reload();
  }, [history, onClose]);

  const handleSwitch = useCallback(() => {
    setPrevLinkedEmployee(linkedEmployee);
    queryClient.removeQueries();
  }, [linkedEmployee, queryClient, setPrevLinkedEmployee]);

  const handleViewAccount = useCallback(() => {
    if (currentEmployeeId === initialId) {
      handleCloseModal();
      return;
    }

    if (currentEmployeeId === employeeId) {
      if (linkedEmployee) {
        handleSwitch();
        setLinkedEmployee(undefined);
        localStorage.removeItem(LINKED_EMPLOYEE_STORAGE_KEY);
        handleCloseModal();
        return;
      }
    }

    const currentLinkedEmployee = formattedLinkedEmployees
      .find((employee) => employee.employeeId === currentEmployeeId);

    if (currentLinkedEmployee && linkedEmployee?.employeeId !== currentEmployeeId) {
      handleSwitch();
      setLinkedEmployee(linkedEmployee);
      localStorage.setItem(LINKED_EMPLOYEE_STORAGE_KEY, JSON.stringify(currentLinkedEmployee));
      handleCloseModal();
    }
  }, [
    currentEmployeeId,
    employeeId,
    formattedLinkedEmployees,
    handleSwitch,
    linkedEmployee,
    setLinkedEmployee,
    handleCloseModal,
    initialId,
  ]);

  return (
    <ModalWrapper
      visible={visible}
      onSetVisible={handleCloseModal}
      testId="SwitchEmployeeModal_testId"
    >

      <Box align="center" pad={{ vertical: 'spacing24' }}>
        <Box width="xxsmall" alignSelf="center" data-testid="complete_icon">
          <RoundDoneIcon />
        </Box>

        <Text
          size="2xl"
          weight="bold"
          color="textBody"
          margin={{ top: 'small' }}
          textAlign="center"
        >
          {t('Now, it\'s time to choose which accounts you would like to access')}
        </Text>

        <Text
          textAlign="center"
          color="textBody"
          margin={{ top: 'small' }}
        >
          {t(DESCRIPTION)}
        </Text>
      </Box>
      <Box
        data-testid="employee-switcher-wrapper"
        margin={{ bottom: 'spacing24' }}
        pad="spacing24"
        background={{ color: 'module' }}
        round="container1Round"
      >
        <EmployeeSwitcher
          currentEmployeeId={currentEmployeeId}
          linkedEmployees={formattedLinkedEmployees}
          onSwitching={setCurrentEmployeeId}
        />
      </Box>
      <Box direction="row" alignSelf="center" gap="spacing12">
        <AppButton
          buttonType="secondary"
          onClick={handleCloseModal}
          width="160px"
        >
          {t('Cancel')}
        </AppButton>

        <AppButton
          width="160px"
          onClick={handleViewAccount}
        >
          {t('View Account')}
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};
