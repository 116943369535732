import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ROUTES from '@/common/routes';

const useRedirectHome = (isNeedToRedirect: boolean) => {
  const history = useHistory();

  useEffect(() => {
    if (isNeedToRedirect) {
      history.push(ROUTES.HOME);
    }
  }, [history, isNeedToRedirect]);
};

export default useRedirectHome;
