import React from 'react';
import { Box } from '@common-fe/common-fe';

import UploadFileByLinkOrganizationLogo from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkOrganizationLogo';

interface Props {
  children: React.ReactNode;
}

const UploadFileByLinkContentWrapper: React.FC<Props> = ({ children }) => (
  <Box height={{ min: '100svh' }} justify="center" align="center">
    <UploadFileByLinkOrganizationLogo />
    <Box
      justify="center"
      align="center"
      style={{ zIndex: 1 }}
      pad={{ vertical: '108px' }}
    >
      {children}
    </Box>
  </Box>
);

export default UploadFileByLinkContentWrapper;
