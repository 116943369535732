import { useMemo } from 'react';

import { useInvestorById } from '@/modules/investments/hooks';



const useDefaultQuestionnaireValues = () => {
  const {data } =  useInvestorById();

  const defaultValues = useMemo(() => ({
    position: data.position,
    networthTotal: `${data.networthTotal || ''}`,
    annualIncome: `${data.annualIncome || ''}` 
  }), [data.annualIncome, data.networthTotal, data.position]);
  return defaultValues;
};

export default useDefaultQuestionnaireValues;