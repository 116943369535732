import React from 'react';

import { ExpenseOption } from '../../transaction.types';
import ExpensesBreakdown from '../ExpensesBreakdown';

export interface Props {
  expensesOptions: ExpenseOption[];
  totalExpense: number;
}

const ActivityDetailsExpenses: React.FC<Props> = (props) => (
  <ExpensesBreakdown
    className="expenses-breakdown"
    options={props?.expensesOptions}
    totalExpense={props?.totalExpense}
    inlineMode
    actionNode={<div />}
  />
);

export default ActivityDetailsExpenses;
