import { AppButton } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledAppButton = styled(AppButton)<{ color?: string }>`
  border-color: ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.textTitle};
  font-size: ${({ theme }) => theme.spacings.spacing16};
  font-weight: 400;
  &:focus {
    color: ${({ theme }) => theme.colors.textTitle};
  }
  ${({ theme, active, color }) => {
    const hasRefusal = Boolean(color);
    const borderColor = hasRefusal ? theme.colors.danger : theme.colors.button;

    return (`
    border-color: ${active ? borderColor : theme.colors.border};
    &:hover {
      border-color: ${color ? theme.colors.danger : theme.colors.button};
    };
  `);
  }}
`;
