import styled from 'styled-components';

interface WrapperProps {
  active: boolean;
  focus: boolean;
}

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.iconAccent};
  }
`;
export const DotIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.accentContainer};
`;

export const DotIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.iconAccent};
`;

interface TextProps {
  grey?: boolean;
}
export const Text = styled.p<TextProps>`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.textBody};
  ${({ theme }) => theme.fonts.text16Medium};
  ${({ grey, theme }) => {
    if (grey) {
      return {
        color: theme.colors.textDisabled,
      };
    }
    return {};
  }}
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.fonts.text16Regular};

  margin-bottom: 20px;
  ${Text} {
    ${({ theme, active, focus }) => {
    if (active) {
      return {
        color: theme.colors.textBody || theme.colors.textAccent,
      };
    }
    if (focus) {
      return {
        ...theme.fonts.text16Bold,
        color: theme.colors.textBody,
      };
    }
    return {};
  }}
  }
`;
