import { useCallback,useEffect, useState } from 'react';

interface Props {
  mediaQuery: string;
}

const useMediaQuery = ({ mediaQuery }: Props) => {
  const [matches, setMatches] = useState(window.matchMedia(mediaQuery).matches);

  const handleChange = useCallback((e: MediaQueryListEvent) => setMatches(e.matches), []);

  useEffect(() => {
    const media = window.matchMedia(mediaQuery);
    media.addEventListener('change', handleChange);
    return () => media.removeEventListener('change', handleChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return matches;
};

export default useMediaQuery;
