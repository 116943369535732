import React, { useCallback, useState } from 'react';
import { Box, PencilIcon, RawButton } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import useOrganizationViewDataQuery from '@/modules/user/queries/useOrganizationViewData.query';
import { useAuthStore } from '@/modules/user/stores';
import { EmployeeAddress } from '@/modules/user/user.types';

import EditEmployeeAddressForm from './EditEmployeeAddressForm';
interface Props {
  address: EmployeeAddress;
}
const EditEmployeeAddress: React.FC<Props> = ({ address }) => {
  const store = useAuthStore();
  const { formattedData: employee } = useOrganizationViewDataQuery(store.organizationId);
  const [isModalVisible, setModalVisible] = useState(false);
  const handleToggleModalVisible = useCallback(() => {
    setModalVisible(!isModalVisible);
  }, [isModalVisible]);
  if (!employee?.allowEmployeeToEditAddress) {
    return null;
  }
  return (
    <Box data-testid="edit-employee-address">
      <ModalWrapper
        visible={isModalVisible}
        onSetVisible={setModalVisible}
        title="Edit Address"
      >
        <EditEmployeeAddressForm address={address} onClose={handleToggleModalVisible} />
      </ModalWrapper>
      <Box margin={{ left: 'xxs' }}>
        <RawButton data-testid="edit-employee-address_pencil-icon" onClick={handleToggleModalVisible}>
          <PencilIcon color="iconSecondary" size="16px"></PencilIcon>
        </RawButton>
      </Box>
    </Box>
  );
};

export default EditEmployeeAddress;
