import {
  useCallback,
} from 'react';
import {
  useMutation,
  useQueryClient, } from 'react-query';

import { api, Response } from '@/api';
import { PATHS } from '@/common';

import { RawNotification } from '../notification.types';

import { QUERY_KEY as DISMISSED_QUERY_KEY } from './useDismissedNotifications.query';

export const QUERY_KEY = 'getNotifications';

export default () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (id?: string) => api.patch(id
      ? `${PATHS.NOTIFICATIONS_DISMISSED}/${id}`
      : PATHS.NOTIFICATIONS_DISMISSED, undefined),
  );
  const handleDismissAll = useCallback(async () => {
    await mutation.mutateAsync('');
    const queryData = queryClient
      .getQueryData<Response<RawNotification[]>>([QUERY_KEY]);
    if (queryData) {
      queryClient.setQueryData([QUERY_KEY], {
        ...queryData,
        data: [],
      });
      queryClient.invalidateQueries([DISMISSED_QUERY_KEY]);
    }
  }, [mutation, queryClient]);
  const handleDismissById = useCallback(async (id?: string) => {
    if (!id) {
      return;
    }

    await mutation.mutateAsync(id);

    const queryData = queryClient
      .getQueryData<Response<RawNotification[]>>([QUERY_KEY]);
    if (queryData) {
      queryClient.setQueryData([QUERY_KEY], {
        ...queryData,
        data: queryData.data.filter((item) => `${item.id}` !== id),
      });
      queryClient.invalidateQueries([DISMISSED_QUERY_KEY]);
    }
  }, [mutation, queryClient]);
  return {
    handleDismissAll,
    handleDismissById,
  };
};
