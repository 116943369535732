import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Hint,
  Text,
  Tip,
} from '@common-fe/common-fe';

import theme from '@/styles/theme';

import {
  Delimiter, StyledText, SubItem, Title, TitleWrapper,
  Wrapper, } from './SidebarOption.styles';

interface SubOption {
  key: string;
  title: string;
  url?: string;
}
export interface Option {
  key: string;

  title: string;
  delimiter?: boolean;
  icon?: React.ReactNode;
  options?: SubOption[];
  url?: string;
  link?: string;
  textStyle?: React.CSSProperties;
  activeBackgroundOnHover?: boolean;
  additionalIcon?: React.ReactNode;
  disabled?: boolean;
  hintComponent?: React.ReactNode;
}

export type Props = Option & {
  active?: boolean;
  path: string;
  currentKey: string;
  onPick: (url?: string) => void;
  minify: boolean;
};

const MAX_TITLE_LENGTH = 18;

const SidebarOption: React.FC<Props> = ({
  title,
  icon,
  path,
  delimiter,
  currentKey,
  onPick,
  options,
  minify,
  url,
  link,
  textStyle,
  activeBackgroundOnHover,
  additionalIcon,
  disabled,
  hintComponent,
}) => {
  const [expand, setExpanded] = useState(false);
  const isOptions = useMemo(() => options && options.length, [options]);
  const handleClick = useCallback(() => {
    if (link) {
      window.open(link, '_blank');
      return;
    }
    if (isOptions) {
      setExpanded(true);
      // return;
    }
    onPick(url);
  }, [link, isOptions, onPick, url]);
  const subItemsList = useMemo(() => (options ? options.map((item) => item.url) : []), [options]);
  // useEffect(() => {
  //   if (expand && onPick && options?.length && !subItemsList.includes(path)) {
  //     const first = options[0];
  //     onPick(first.key, first.url);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [expand]);
  useEffect(() => {
    if (options?.length) {
      if (expand) {
        if (!subItemsList.includes(path) && url !== path) {
          setExpanded(false);
        }
      }
      if (!expand) {
        if (subItemsList.includes(path)) {
          setExpanded(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, path]);
  const active = useMemo(() => {
    const activeTab = path === url;
    if (!url) {
      return false;
    }
    if (!activeTab && options) {
      const found = !!options.find((item) => item.url === path);
      return found;
    }
    return path === url;
  }, [path, url, options]);

  if (delimiter) {
    if (minify) {
      return null;
    }
    return (
      <Delimiter key={currentKey}>
        {title}
      </Delimiter>
    );
  }
  return (
    <Hint
      hintWidth={240}
      hideHint={!hintComponent}
      hintAlign="right"
      hintElement={(
        <Box>
          <Wrapper
            minify={minify}
            activeBackgroundOnHover={activeBackgroundOnHover}
            active={active || expand}
            {...disabled ? {
              style: {
                cursor: 'pointer',
              },
            } : {
              onClick: handleClick,
            }}
            disabled={disabled}
            aria-label={title}
            data-testid={`Sidebar-option-${title}`}
          >
            {icon}
            <TitleWrapper direction="row" align="center">
              <Title
                minify={minify}
                active={active || expand}
                style={disabled ? { color: theme.colors.textDisabled } : textStyle || undefined}
              >
                {title}
              </Title>
              {additionalIcon && !minify && additionalIcon}
            </TitleWrapper>

            {!!isOptions && !minify && (
              <>
                {
                  expand ? (
                    <ChevronUpIcon  size="24px"  color="eeeeeeee"/>
                  ) : (
                    <ChevronDownIcon size="24px" color="textBody" />
                  )
                }
              </>
            )}
          </Wrapper>
          {!!isOptions && !!expand && !minify && options
            && options.map((item) => (
              <Tip
                key={item.key}
                plain
                dropProps={{ align: { left: 'right' } }}
                content={(item?.title?.length > MAX_TITLE_LENGTH
                  ? (
                    <Box
                      width="max-content"
                      height={{ max: 'l' }}
                      align="center"
                      justify="center"
                      background="background3"
                      border={{ size: 'small', color: 'popup' }}
                      pad={{ horizontal: 'spacing6' }}
                    >
                      <Text size="small" color="textOnColor">{item.title}</Text>
                    </Box>
                  ) : undefined
                )}
              >
                <SubItem
                  pad={{ right: 'l', left: 'xs' }}
                  margin={{ vertical: 'spacing6', left: 'xxl' }}
                  key={item.key}
                  onClick={() => {
                    onPick(item.url);
                  }}
                  active={path === item.url}
                >
                  <StyledText>
                    {item.title}
                  </StyledText>
                </SubItem>
              </Tip>
            ))}
        </Box>
      )}
      disabled={disabled}
    >
      {hintComponent}
    </Hint>
  );
};

export default SidebarOption;
