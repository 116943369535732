import { IrsMaximumsCoverageTypes, IrsMaximumsFrequencyTypes } from '@/modules/transaction/transaction.types';

export interface RecurringContribution {
  id: number;
  amount: number;
  frequency: string;
  startDate: string;
  endDate: string;
  status: string;
  nextScheduledDate: string;
  monthlyDay?: string;
  weeklyDay?: string;
  paymentMethodId: number;
}

export enum RecurringContributionStatus {
  Active = 'Active',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
}

export enum RecurringContributionFrequency {
  WEEKLY = 'Weekly',
  BIWEEKLY = 'Bi-weekly',
  MONTHLY = 'Monthly',
}

export type ContributionPaymentMethod = {
  contributionPaymentMethod?: string;
};

export type OneTimeContributionFormValues = {
  contributionAmount?: string;
  rollover?: boolean;
  taxYear?: string;
};

export type RecurringContributionFormValues = {
  contributionAmount?: string;
  monthlyDay?: string;
  weeklyDay?: string;
  frequency?: RecurringContributionFrequency;
  startDate?: string;
  endDate?: string;
};

export enum ToggleOptions {
  ONE_TIME = 'One-Time',
  RECURRING = 'Recurring',
}

export interface ContributionHistoryCategoryDto {
  id: number;
  tax_year: number;
  type: string;
  tax_year_end: string;
  amount: number;
  frequency_type: IrsMaximumsFrequencyTypes,
  irs_coverage_level_type: IrsMaximumsCoverageTypes,
}

export interface ContributionHistoryTotalDto {
  id: number;
  employee_id: number;
  total_contribution_amount: number;
  tax_year: number;
}

export interface RecurringContributionDto {
  id : number;
  amount : number;
  frequency_type : string;
  recurring_day_number : number;
  start_date : string;
  end_date : string;
  status : string;
  payment_method_id : number;
  employee_id : number;
  organization_id : number;
  organization_path : string;
  next_scheduled_date : string;
}

export enum ContributionAchievementsName {
  INDIVIDUAL = 'Individual Max',
  FAMILY = 'Family Max',
  CATCH_UP = 'Catch Up',
}