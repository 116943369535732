import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { InvestmentPaths } from '../investments.types';

import useInvestorQuery from './useInvestor.query';
import useSelfDirectedInvestingRedirect from './useSelfDirectedInvestingRedirect';

interface Params {
  accountId?: string;
  onError?: () => void;
}
const useInvestmentsStartQuery = ({ accountId, onError }: Params) => {
  const history = useHistory();
  const investor = useInvestorQuery();


  const handleRedirectToStart = useCallback(
    (investorId?: string, isStartMode?: boolean) => {
      history.push(`${ROUTES.START_INVESTING}?accountId=${accountId}&investorId=${investorId}${isStartMode ? '&startMode=true' : ''}`);
    },
    [accountId, history]
  );
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    () =>
      api.post(PATHS.INVESTMENTS_FLOW_START, null, {
        params: {
          account_id: accountId,
        },
      }),
    {
      onSuccess: (data) => {
        const investorId = data?.data?.id;
        if (investorId) {
          
          handleRedirectToStart(investorId);
        }
      },
      onError: () => {
        if (onError) onError();
      },
    }
  );
  const handleSelfDirectedInvestingRedirect = useSelfDirectedInvestingRedirect({
    accountId,
  });

  const handleSave = useCallback(async () => {
    const investorId = investor?.data?.investorId;
    if (investorId) {
      const investorType = investor?.data?.type;
      const investorStatus = investor?.data?.status;
      if (investorType === InvestmentPaths.SelfDirected && investorStatus) {
        handleSelfDirectedInvestingRedirect({
          investorId,
          investorStatus,
        });
      } else if (investorType === InvestmentPaths.Managed && investorStatus) {
        handleRedirectToStart(investorId);
      } else {
        handleRedirectToStart(investorId, true);
      }
    } else {
      await mutateAsync();
    }
  }, [
    handleRedirectToStart,
    handleSelfDirectedInvestingRedirect,
    investor?.data?.investorId,
    investor?.data?.status,
    investor?.data?.type,
    mutateAsync,
  ]);
  return {
    handleRedirectToStart,
    handleSave: handleSave,
    isLoading,
    isSuccess,
  };
};

export default useInvestmentsStartQuery;
