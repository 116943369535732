import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Inscription,
} from '@common-fe/common-fe';
import Lottie from 'lottie-react';

import AllSetIcon from '@/assets/bank-icons/all-set.json';
import routes from '@/common/routes';
import { ModalWrapper } from '@/components/wrappers';
import { CardActivationVariants } from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/VerifyMyCardModal/VerifyMyCardModal.constants';
import { useHistory } from '@/modules/core/hooks';
import theme from '@/styles/theme';

const BUTTON_WIDTH = '200px';

interface Props {
  onSetVisible: (value: boolean) => void;
  isActivationSuccess: CardActivationVariants;
}

const SuccessActivatedModal: React.FC<Props> = ({
  onSetVisible,
  isActivationSuccess,
}) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const handleNavigate = useCallback((route: string) => {
    push(route);
    window.location.reload();
    onSetVisible(false);
  }, [push, onSetVisible]);
  const handleSetVisible = useCallback((value: boolean) => {
    if (!value) {
      handleNavigate(routes.HOME);
    }
    if (value) {
      onSetVisible(value);
    }
  }, [handleNavigate, onSetVisible]);

  return (
    <ModalWrapper visible onSetVisible={handleSetVisible} testId="success-activated-modal">
      <Box margin={{ top: 'spacing12' }} align="center">
        <Lottie animationData={AllSetIcon} />
        <Inscription
          margin={{ top: 'spacing8' }}
          size={theme.spacings.spacing24}
          weight={700}
        >
          {t('You’re all set!')}
        </Inscription>
        {isActivationSuccess === CardActivationVariants.ACTIVATED
          && (
            <Inscription
              margin={{ top: 'spacing12' }}
              weight={700}
            >
              {t('This card has been activated!')}
            </Inscription>
          )}
        {isActivationSuccess === CardActivationVariants.ERROR_ACTIVATION
          && (
            <>
              <Inscription
                margin={{ top: 'spacing12' }}
                weight={700}
              >
                {t('We encountered an unexpected error and couldn’t activate the card.')}
              </Inscription>
              <Inscription weight={700}>
                {t('Try again on the Cards Dashboard.')}
              </Inscription>
            </>
          )}
        {isActivationSuccess !== CardActivationVariants.ACTIVATION_NOT_INIT
          && (
            <>
              <Inscription margin={{ top: 'spacing12' }}>{t('Did you also receive a card for your dependent?')}</Inscription>
              <Inscription>{t('You can activate or request a new card in the Cards dashboard.')}</Inscription>
            </>
          )}
      </Box>

      <Box direction="row" margin={{ vertical: 'spacing24' }} justify="center">
        {isActivationSuccess !== CardActivationVariants.ACTIVATION_NOT_INIT
        && (
          <Box margin={{ right: 'spacing12' }}>
            <AppButton
              testId="go-to-cards-dashboard"
              onClick={() => handleNavigate(routes.CARDS)}
              buttonType="secondary"
              width={BUTTON_WIDTH}
            >
              {t('Go to Cards Dashboard')}
            </AppButton>
          </Box>
        )}
        <Box>
          <AppButton
            testId="close"
            onClick={() => handleNavigate(routes.HOME)}
            width={BUTTON_WIDTH}
          >
            {t('Close')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default SuccessActivatedModal;
