import React from 'react';
import {
  AmericanExpressIcon,
  BillingCheckIcon,
  MastercardIcon,
  VisaIcon,
} from '@common-fe/common-fe';

import { BankTypeEnum } from '@/modules/transaction/components/Cards/Cards.types';
import spacings from '@/styles/spacings';

interface IconProps {
  cardType?: BankTypeEnum,
}

const DebitPaymentMethodIcon: React.FC<IconProps> = ({ cardType }) => {
  switch (cardType) {
  case BankTypeEnum.VISA:
    return (
      <VisaIcon
        data-testid={`debit-payment-method-${cardType}`}
        size={spacings.l}
      />
    );
  case BankTypeEnum.MASTERCARD:
    return (
      <MastercardIcon
        data-testid={`debit-payment-method-${cardType}`}
        size={spacings.l}
      />
    );
  case BankTypeEnum.AMEX:
    return (
      <AmericanExpressIcon
        data-testid={`debit-payment-method-${cardType}`}
        size={spacings.l}
      />
    );
  default:
    return (
      <BillingCheckIcon
        data-testid="debit-payment-method-default-icon"
        size={spacings.l}
        color="iconAccent"
      />
    );
  }
};

export default DebitPaymentMethodIcon;
