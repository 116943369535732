import React, { useMemo, useState } from 'react';
import { ListItemType, Notification, NotificationStatus } from '@common-fe/common-fe';

import { NotificationsPaginationSearchParams, NotificationType } from '@/modules/notification/notification.types';

import { useDismissedNotificationsQuery, useNotificationsQuery } from '../../queries';
import NotifiacationListActions from '../NotificationActions';
import NotificationTime from '../NotificationTime';
import NotificationListTitle from '../NotificationTitle';

export default (params: NotificationsPaginationSearchParams) => {
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<Notification | undefined>();
  const { currentType } = params;

  const {
    data: activeData, handleDismissById, isLoading: isActiveLoading, handleRead,
  } = useNotificationsQuery(true);

  const {
    data: dismissedData, total: dismissedTotal,
    searchTotal: dismissedSearchTotal, isLoading: isDismissedLoading,
  } = useDismissedNotificationsQuery(params);
  const activeTotal = useMemo(() => activeData.length, [activeData]);
  const currentTotal = useMemo(() => {
    if (currentType === NotificationType.Active) {
      return activeTotal;
    }
    if (currentType === NotificationType.Dismissed) {
      return dismissedSearchTotal;
    }
    return 0;
  }, [activeTotal, currentType, dismissedSearchTotal]);
  const data = useMemo(() => {
    if (currentType === NotificationType.Active) {
      return activeData;
    }
    if (currentType === NotificationType.Dismissed) {
      return dismissedData;
    }
    return [];
  }, [activeData, currentType, dismissedData]);
  const isLoading = useMemo(() => {
    if (currentType === NotificationType.Active) {
      return isActiveLoading;
    }
    if (currentType === NotificationType.Dismissed) {
      return isDismissedLoading;
    }
    return false;
  }, [currentType, isActiveLoading, isDismissedLoading]);
  const formatedRows = useMemo(() => data.map((item) => ({
    id: item.id,
    onPick: () => {
      setCurrentNotification(item);
      setDetailsModalVisible(true);
    },
    fields: currentType === NotificationType.Active ? [
      {
        key: 'title',
        type: ListItemType.Node,
        title: '',
        flex: 3,
        node: <NotificationListTitle
          title={item.text}
          status={item.status as unknown as NotificationStatus}
          text={item.additionalText}
        />,
      },
      {
        key: 'time',
        type: ListItemType.Node,
        title: '',
        flex: 1.5,
        node: <NotificationTime time={item.createdAt} />,
      },
      {
        key: 'actions',
        type: ListItemType.Node,
        title: '',
        flex: 1,
        node: <NotifiacationListActions
          path={item.page}
          onDismiss={() => handleDismissById(item.id)}
        />,
      },

    ] : [{
      key: 'title',
      type: ListItemType.Node,
      title: '',
      flex: 3,
      node: <NotificationListTitle
        title={item.text}
        status={item.status as unknown as NotificationStatus}
        text={item.additionalText}
      />,
    },
    {
      key: 'time',
      type: ListItemType.Node,
      title: '',
      flex: 1.5,
      node: <NotificationTime time={item.createdAt} />,
    }],
  })), [currentType, data, handleDismissById]);

  return {
    data,
    handleRead,
    dismissedTotal,
    rows: formatedRows,
    activeTotal,
    dismissedSearchTotal,
    total: currentTotal,
    isLoading,
    detailsModalVisible,
    setDetailsModalVisible,
    handleDismissById,
    currentNotification,
  };
};
