import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, RoundPendingIcon,Text } from '@common-fe/common-fe';

import { ProcessingScreenType } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.constants';
import theme from '@/styles/theme';

interface Props {
  processingScreenType: ProcessingScreenType;
}

const TransactionProcessingHeader: React.FC<Props> = ({
  processingScreenType,
}) => {
  const { t } = useTranslation();

  return (
    <Box align="center">
      <RoundPendingIcon color={theme.colors.warning} />
      <Text
        margin={{ top: 'spacing16' }}
        size="20px"
        weight={700}
        textAlign="center"
      >
        {t('When we get new insurance claims.')}
      </Text>
      <Text
        size="20px"
        weight={700}
        textAlign="center"
      >
        {t('What do you want us to do?')}
      </Text>
    </Box>
  );
};

export default TransactionProcessingHeader;
