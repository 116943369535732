import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import Access from '@/modules/core/components/Access';
import { useHasAccess } from '@/modules/core/hooks';
import { ChangeStatusCardModal } from '@/modules/transaction/components/Cards/components/ChangeStatusCardModal/ChangeStatusCardModal';
import { useAuthStore } from '@/modules/user/stores';

import { useGetCardsQuery } from '../../hooks/useGetCards.query';

import { AddCardDependentModal } from './components/AddCardDependentModal/AddCardDependentModal';
import { CardActivationModal } from './components/CardActivationModals/CardActivationModal';
import { CardReplaceModal } from './components/CardReplaceModal/CardReplaceModal';
import { CardsSection } from './components/CardsSection';
import { EmptyCardListPlaceholder } from './components/EmptyCardListPlaceholder';
import useCardReplaceModalStore from './store/useCardReplaceModal.store';

export const Cards = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { cardMap, refetch } = useGetCardsQuery(user?.employee?.id);
  const {
    state: { modalVisible, cardholderName },
    handleChangeVisibility,
  } = useCardReplaceModalStore();
  const isBlockedCardAddition = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);

  return (
    <Box>
      <Box margin={{ bottom: 'spacing32' }} direction="row" justify="between" align="center">
        <Text size="3xl" weight="bold">{t('Cards')}</Text>
        <Access
          accessRequirements={[
            { role: Roles.employee },
            { role: Roles.employeeLimitedAccess },
            { role: Roles.agent },
            { role: Roles.claimsProcessor1 },
            { role: Roles.claimsProcessor2 },
            { role: Roles.finOps },
          ]}
        >
          <AddCardDependentModal isLimitedAccess={isBlockedCardAddition} />
        </Access>
      </Box>
      {Object.keys(cardMap).length
        ? (
          Object.keys(cardMap).map((holderName) => (
            <CardsSection
              key={holderName}
              cardsByStatus={cardMap[holderName]}
              holderName={holderName}
            />
          ))
        ) : (
          <EmptyCardListPlaceholder />
        )}
      <CardReplaceModal
        visible={modalVisible}
        cardholderName={cardholderName}
        onSetVisible={handleChangeVisibility}
        refreshCardList={refetch}
      />
      <ChangeStatusCardModal />
      <CardActivationModal />
    </Box>
  );
};
