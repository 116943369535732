import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  Box, Text,
} from '@common-fe/common-fe';

import { LINKED_EMPLOYEE_STORAGE_KEY } from '@/common/constants';
import routes from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useOrphanModeStore } from '@/modules/core/store';
import { useSwitchingModalStore } from '@/modules/user/components/UserDrodpdown/components/OrphanModeSwitcher/store';
import { LinkedEmployee } from '@/modules/user/user.types';

import { EmployerCard } from './components';

interface Props {
  defaultEmployeeId: string;
  linkedEmployees: LinkedEmployee[];
  onSwitching: () => void;
}

const OrphanModeSwitcher: React.FC<Props> = ({
  defaultEmployeeId,
  linkedEmployees,
  onSwitching,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { t } = useTranslation();
  const { isModalVisible, setIsModalVisible } = useSwitchingModalStore();
  const {
    linkedEmployee: linkedEmployeeFromStore,
    setLinkedEmployee,
    setPrevLinkedEmployee,
  } = useOrphanModeStore();

  const handleSwitch = useCallback(() => {
    history.push(routes.HOME);
    setIsModalVisible(true);
    onSwitching();
    setPrevLinkedEmployee(linkedEmployeeFromStore);
    queryClient.removeQueries();
  }, [history, onSwitching, setIsModalVisible,
    queryClient, linkedEmployeeFromStore, setPrevLinkedEmployee]);

  const handleExitFromOrphanMode = useCallback(() => {
    if (linkedEmployeeFromStore) {
      handleSwitch();
      setLinkedEmployee(undefined);
      localStorage.removeItem(LINKED_EMPLOYEE_STORAGE_KEY);
    }
  }, [linkedEmployeeFromStore, setLinkedEmployee, handleSwitch]);

  const handleSwitchToOrphanMode = useCallback((employeeId: string) => {
    const linkedEmployee = linkedEmployees.find((employee) => employee.employeeId === employeeId);

    if (linkedEmployee && linkedEmployeeFromStore?.employeeId !== employeeId) {
      handleSwitch();
      setLinkedEmployee(linkedEmployee);
      localStorage.setItem(LINKED_EMPLOYEE_STORAGE_KEY, JSON.stringify(linkedEmployee));
    }
  }, [setLinkedEmployee, handleSwitch, linkedEmployees, linkedEmployeeFromStore]);

   
  useEffect(() => {
    if (isModalVisible) {
      const timerId = setTimeout(() => setIsModalVisible(false), 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [isModalVisible, setIsModalVisible]);

  return (
    <Box data-testid="orphan-mode-switcher">
      <Box>
        <Text size="12px" color="textSecondary" weight={500}>
          {t('Linked employers')}
        </Text>
      </Box>

      <Box>
        {linkedEmployees.map((linkedEmployee) => (
          linkedEmployee.employeeId === defaultEmployeeId
            ? (
              <EmployerCard
                key={linkedEmployee.employeeId}
                linkedEmployee={linkedEmployee}
                onSwitchingToOrphanMode={handleExitFromOrphanMode}
                isActiveSession={!linkedEmployeeFromStore?.employeeId}
                testId={linkedEmployee.employeeId}
              />
            ) : (
              <EmployerCard
                key={linkedEmployee.employeeId}
                linkedEmployee={linkedEmployee}
                onSwitchingToOrphanMode={handleSwitchToOrphanMode}
                isActiveSession={linkedEmployeeFromStore?.employeeId === linkedEmployee.employeeId}
                testId={linkedEmployee.employeeId}
              />
            )
        ))}
      </Box>
    </Box>
  );
};

export default OrphanModeSwitcher;
