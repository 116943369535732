import React from 'react';
import { Box, Heading } from '@common-fe/common-fe';
import { Icon as GrommetIcon } from 'grommet-icons';

import theme from '@/styles/theme';

// const BIG_ICON_SIZE = '200px';

// const IconWrapper = styled(Box)`
//   position: absolute;
//   top: -120px;
//   right: 70px;
//   z-index: 1;
// `;

interface BlockWrapperProps {
  id?: string;
  Icon: GrommetIcon;
  title: string;
  children: React.ReactNode;
}

const BlockWrapper: React.FC<BlockWrapperProps> = ({
  id, Icon, title, children,
}) => (

  <Box
    id={id}
    pad={{ top: 'spacing48' }}
  >
    <Box direction="row" align="center">
      <Box
        justify="center"
        align="center"
        width="xxxsmall"
        height="xxxsmall"
        round="full"
        background="iconAccent"
        margin={{ right: 'spacing16', bottom: 'spacing4' }}
      >
        <Icon color={theme.colors.iconOnColor} />
      </Box>
      <Heading level={4} size="medium">
        {title}
      </Heading>
    </Box>
    <Box style={{ position: 'relative' }} margin={{ top: 'spacing16' }}>
      <Box background="module" round="moduleRound" pad="spacing24" style={{ zIndex: 10 }}>

        {children}

      </Box>
      {/* <IconWrapper>
        <Icon size={BIG_ICON_SIZE} color={theme.colors.module} />
      </IconWrapper> */}
    </Box>
  </Box>
);

export default BlockWrapper;
