import { useEffect, useMemo, useState } from 'react';
import { getDuration } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { MINUTES_WITH_SECONDS_FORMAT } from '@/common/constants';

const ONE_SECOND = 1000;

export const TOTAL_TIMER_DURATION = getDuration({ minutes: 3 });
export const DEFAULT_TIME = '00:00';

export default () => {
  const [timerDuration, setTimerDuration] = useState(TOTAL_TIMER_DURATION);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerDuration(timerDuration - ONE_SECOND);
    }, ONE_SECOND);
    if (timerDuration < 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerDuration]);
  const formattedTime = useMemo(() => {
    if (timerDuration <= 0) {
      return DEFAULT_TIME;
    }
    const timer = dayjs(timerDuration).format(MINUTES_WITH_SECONDS_FORMAT);
    return timer;
  }, [timerDuration]);
  const isTimeOver = useMemo(() => timerDuration <= 0, [timerDuration]);
  return {
    formattedTime,
    isTimeOver,
    setTimerDuration,
  };
};
