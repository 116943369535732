import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Preloader,
  RoundCloseIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';

import { useUpdateRecurringContributionQuery } from '../queries/useUpdateRecurringContribution.query';
import { useCancelRecurringContributionModalStore, useRecurringContributionStore } from '../store';
import { useStore as useSuccessStore } from '../store/useSuccessContributionModal.store';


const headerText = 'Cancel Recurring Contribution?';
const description = 'Canceling your recurring contributions will stop any future contributions a part of this schedule and you will no longer be able to make any changes. A new schedule can be setup at anytime.';

export const CancelRecurringContributionModal = () => {
  const { t } = useTranslation();
  const visible = useCancelRecurringContributionModalStore((store) => store.modalVisible);
  const onSetVisible = useCancelRecurringContributionModalStore((store) => store.handleChangeVisibility);
  const handleCancelContribution = useCancelRecurringContributionModalStore((store) => store.additionalAction);
  const handleRefetchRecurringContribution = useSuccessStore((store) => store.updateRecurringContributionView);
  const { handleAddPermanentSnackbar } = useSnackbar();

  const contribution = useRecurringContributionStore((store) => store.recurringContribution);

  const { handleCancel, isChangeStatusLoading } = useUpdateRecurringContributionQuery(contribution?.id);

  const handlePauseResume = useCallback(async () => {
    try {
      await handleCancel();
      if (handleRefetchRecurringContribution) handleRefetchRecurringContribution();
      handleCancelContribution();
      onSetVisible(false);
    } catch {
      onSetVisible(false);
      handleAddPermanentSnackbar({
        text: t('Oops! Something went wrong, please try again'),
        closeIcon: true,
        state: SnackbarState.negative,
        minWidth: '454px',
      });
    }
  }, [
    handleAddPermanentSnackbar,
    handleCancel,
    handleCancelContribution,
    handleRefetchRecurringContribution,
    onSetVisible,
    t,
  ]);

  return (
    <ModalWrapper testId="CancelRecurringContributionModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24' }}>
        <RoundCloseIcon size="spacing48" color="danger" />
        <Text
          data-testid="CancelRecurringContributionModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {t(headerText)}
        </Text>

        <Text
          data-testid="CancelRecurringContributionModal_modal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {t(description)}
        </Text>
        <Box direction="row" gap="spacing12">
          <AppButton
            testId="CancelRecurringContributionModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            buttonType="secondary"
            width="140px"
          >
            {t('Cancel')}
          </AppButton>
          <AppButton
            color="red"
            testId="CancelRecurringContributionModal_modal_confirm"
            onClick={handlePauseResume}
            width="140px"
          >
            {isChangeStatusLoading
              ? <Preloader />
              : t('Yes, Cancel')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
