import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  FlexControlledForm,
  Preloader,
  Text,
  useDebounceState,
  WarningCard,
  WarningIcon,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { ModalWrapper } from '@/components/wrappers';
import { Steps } from '@/modules/transaction/components/Statements/components/ChangeEmailModal/ChangeEmailModal.constants';
import useEditEmailFields, { EmailConfig } from '@/modules/transaction/components/Statements/components/ChangeEmailModal/hooks/useEditEmailFields';
import useEditEmailStore from '@/modules/transaction/components/Statements/components/ChangeEmailModal/stores/useEditEmail.store';
import useUpdateEmployeeQuery from '@/modules/user/queries/useUpdateEmployee.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

interface Props {
  onClose: () => void;
  setStep: (value: Steps) => void;
}

const ChangeEmailModal: React.FC<Props> = ({ onClose, setStep }) => {
  const { t } = useTranslation();
  const [isSubmit, setIsSubmit] = useState(false);
  const fields = useEditEmailFields();
  const { values, setValues } = useDebounceState<Partial<EmailConfig>>();
  const { updateEmployee, isLoading } = useUpdateEmployeeQuery();
  const { setEmployee } = useEmployeeStore();
  const { state, handleReset } = useEditEmailStore();
  const { getErrors, getValidateFields } = useValidationUtils();

  const handleSubmit = useCallback(async () => {
    setIsSubmit(true);
    const validateFields = getValidateFields(fields);
    const erros = getErrors(values, validateFields);

    if (_.isEmpty(erros)) {
      await updateEmployee({
        ...state.isEmployeeEmailPreferred
          ? { employee_provided_email: values?.employeeProvidedEmail }
          : {},
        is_employee_email_preferred: state.isEmployeeEmailPreferred,
      });
      setEmployee({
        ...state.isEmployeeEmailPreferred
          ? { employeeProvidedEmail: values?.employeeProvidedEmail }
          : {},
        isEmployeeEmailPreferred: state.isEmployeeEmailPreferred,
      });
      setStep(Steps.SuccessfullyChanged);
    }
  }, [updateEmployee, values, getErrors, state,
    getValidateFields, fields, setEmployee, setStep]);

  // eslint-disable-next-line
  useEffect(() => handleReset, []);

  return (
    <ModalWrapper
      visible
      onSetVisible={onClose}
      testId="edit-email-modal"
    >
      <Box align="center">
        <Text size="2xl" weight={700}>
          {t('Would like to change your Email?')}
        </Text>
      </Box>

      <Box
        margin={{ top: 'spacing24' }}
        round="moduleRound"
        background="module"
        pad="spacing24"
      >
        <Box
          background="canvas"
          round="container1Round"
        >
          <FlexControlledForm
            fields={fields}
            showError={isSubmit}
            onChangeValues={setValues}
            isModalType
            editMode
          />
        </Box>

        <Box margin={{ top: 'spacing24' }}>
          <WarningCard
            icon={<WarningIcon />}
            title={(
              <Text>
                {t('Updating your email address here will also impact all account-related communications and services.')}
              </Text>
            )}
            topBorderMargin="spacing12"
            pad="spacing12"
            background="canvas"
            containerProps={{ pad: { horizontal: 'spacing24', vertical: 'spacing16' } }}
          />
        </Box>
      </Box>

      <Box margin={{ top: 'spacing24' }} direction="row" justify="end">
        <Box>
          <AppButton
            width="160px"
            buttonType="secondary"
            testId="on-close"
            onClick={onClose}
          >
            {t('Cancel')}
          </AppButton>
        </Box>
        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            width="160px"
            testId="submit"
            onClick={handleSubmit}
          >
            {isLoading ? <Preloader color="white" /> : t('Save Changes')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default ChangeEmailModal;
