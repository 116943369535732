import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';
interface Props {

}
interface HeaderItemProps {
  children: React.ReactNode;
  testId: string;
}
const HeaderItem: React.FC<HeaderItemProps> = ({testId, children}) => (
  <Text
    color="textBody"
    weight={700}
    size="small"
    style={{ lineHeight: '18px' }}
    data-testid={`fund-list_header_${testId}`}
  >
    {children}
  </Text> 
);
const ConfirmModalListHeader: React.FC<Props> = () => {
  
  const { t } = useTranslation();
  return (
    <Box
      background="legend"
      align="center"
      round="container2Round"
      direction="row"
      pad={{ vertical: '7px', horizontal: '20px' }}
    >
      <Box style={{ flex: 2 }}>
        <HeaderItem testId="fund-name" >
          {t('Fund Name')}
        </HeaderItem>
       
      </Box>
  
      <Box width="80px" margin={{ left: 'l' }}>
        <HeaderItem testId="share-price" >
          {t('Share price')}
        </HeaderItem>
        <Text
          color="textBody"
          weight={700}
          size="small"
          style={{ lineHeight: '18px' }}
        >
        </Text>
      </Box>
      <Box width="80px" margin={{ left: 's' }}>
        <HeaderItem testId="shares" >
          {t('Shares')}
        </HeaderItem>
        
      </Box>
      <Box width="80px" margin={{ left: 's' }}>
        <HeaderItem testId="amount" >
          {t('Amount')}
        </HeaderItem>
       
      </Box>
      <Box width="80px" margin={{ left: 's', right: 'xxs'}}>
        <HeaderItem testId="election" >
          {t('Election')}
        </HeaderItem>
        
      </Box> 
    </Box>
  );
};
export default ConfirmModalListHeader;