import React from 'react';
import { Box,   Image } from '@common-fe/common-fe';

import CardPlaceholder from '@/assets/placeholders/card.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const CardPlaceholderBackgorund = () => {
  if (whiteLabelingConfig?.images?.cardBackground) {
    return (
      <Box margin={{ right: 's' }}>
        <Image src={whiteLabelingConfig?.images?.cardBackground} alt="card" />
      </Box>
    );
  }
  
  return (
    <Box >
      <Image style={{minWidth: 502}} src={CardPlaceholder as unknown as string} />
      
    </Box>
  );
};

export default CardPlaceholderBackgorund;
