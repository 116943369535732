import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Preloader,
} from '@common-fe/common-fe';
import _ from 'lodash';

import AppButton from '@/components/controls/AppButton';
import { PendingModal } from '@/components/elements';


const BUTTON_WIDTH = '130px';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSendRequest: () => void;
  isLoading?: boolean;
  style?: React.CSSProperties
}
const ConfirmCancelModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSendRequest,
  isLoading,
  style,
}) => {
  const { t } = useTranslation();
  const handleSubmit = useCallback(
    () => {
      if(isLoading) {
        return;
      }
      onSendRequest();
    }, [isLoading, onSendRequest]);
  
  return (
    <Box style={style}>
      <PendingModal
        header={t('Oops!')}
        helptext={t('We had a temporary issue on our side and could not process your request. Please try again.')}
        visible={visible}
        onSetVisible={onSetVisible}
        warningIcon
    
      >
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton
              testId="server-modal_cancel"
              onClick={() => onSetVisible(false)}
              buttonType="secondary"
              width={BUTTON_WIDTH}
            >
              {t('Cancel')}
            </AppButton>
          </Box>
          <AppButton
            onClick={handleSubmit}
            disabled={isLoading}
            testId="server-modal_retry"
            width={BUTTON_WIDTH}
          >
            {isLoading
              ? <Preloader color="white" />
              : t('Try again')}
          </AppButton>
        </Box>
      </PendingModal>
    </Box>
  );
};

export default ConfirmCancelModal;
