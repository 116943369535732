import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';

const HelpPage = () => {
  const { t } = useTranslation();
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,

  }, {
    key: ROUTES.HELP,
    title: t('Help'),
    value: `${ROUTES.HELP}`,
  }]);

  return <Box pad={{ horizontal: '40px' }}><p data-testid="Help-wrapper">Help</p></Box>;
};

export default HelpPage;
