import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailVerificationStep } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';

import SuccessVerifiedModal from '../SuccessVerifiedModal';

import EmailVerificationCodeModal from './EmailVerificationCodeModal';
import EnterEmailVerificationModal from './EnterEmailVerificationModal';

interface Props {
  onSetVisible: (value: boolean) => void;
  defaultValue?: string;
}

const EmailVerificationModal: React.FC<Props> = ({ onSetVisible, defaultValue }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(EmailVerificationStep.ENTER_EMAIL);
  const [email, setEmail] = useState(defaultValue || '');

  return (
    <>
      {step === EmailVerificationStep.ENTER_EMAIL && (
        <EnterEmailVerificationModal
          email={email}
          setEmail={setEmail}
          setStep={setStep}
          onSetVisible={onSetVisible}
        />
      )}
      {step === EmailVerificationStep.EMAIL_VERIFICATION_CODE && (
        <EmailVerificationCodeModal
          email={email}
          setStep={setStep}
          onSetVisible={onSetVisible}
        />
      )}
      {step === EmailVerificationStep.EMAIL_VERIFIED_SUCCESSFULLY && (
        <SuccessVerifiedModal
          subTitle={t('You can update your email address anytime in your Account Profile.')}
          onSetVisible={onSetVisible}
        />
      )}
    </>
  );
};

export default EmailVerificationModal;
