import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RiskControlOptions } from '@/common/constants';

interface Payload {
  amount: number;
  toleranceModel: RiskControlOptions
}
export const useSwitchToManagedQuery = (
  id?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const {
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(
    (payload: Payload) => 
 


      api.patch(
        PATHS.INVESTMENTS_CHANGE_MODEL,
        {
          new_investment_type: 'MANAGED',
          risk_tolerance_model: payload.toleranceModel,
          investment_amount: payload.amount
        },
      ),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    },
  
  );



  return {
    handleSave: mutateAsync,
    isLoading,
    isSuccess,
    isError,
  };
};
export default useSwitchToManagedQuery;