import React from 'react';
import { CheckIcon, } from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';

import {
  DotIcon,
  DotIconWrapper,
  IconWrapper,
  Text,
  Wrapper,
} from './StepperItem.styles';

export interface StepperOption extends Option {
  active: boolean;
  focus: boolean;
  grey?: boolean;
  hidden?: boolean;
}

const SidebarItem: React.FC<StepperOption> = ({
  value,
  active,
  focus,
  grey,
}) => (
  <Wrapper active={active} focus={focus}>
    <IconWrapper>
      {active && <CheckIcon size="18px" color="iconAccent" />}
      {focus && (
        <DotIconWrapper>
          <DotIcon />
        </DotIconWrapper>
      )}
    </IconWrapper>
    <Text grey={grey && !active && !focus}>{value as string}</Text>
  </Wrapper>
);
export default SidebarItem;
