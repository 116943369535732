import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarnModal } from '@common-fe/common-fe';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const LimitReachedModal: React.FC<Props> = ({
  onSetVisible,
}) => {
  const { t } = useTranslation();

  return (
    <WarnModal
      visible
      onSetVisible={onSetVisible}
      testId="attempt-limit-modal"
      header={t('Oops, there have been too many unsuccessful attempts to verify your card')}
      paddingless
      helptext={t('Please wait for 5 minutes before trying again, or contact your administrator.')}
      buttonText={t('Cancel')}
    />
  );
};

export default LimitReachedModal;
