import { useMemo } from 'react';
import {
  dateValidatorByFormat,
  Field,
  FieldTypes,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import {
  CUSTOM_DAY_ERROR_TEXT,
  DATE_INVALID_TEXT,
  DEFAULT_DATE_FORMAT,
  REQUIRED_TEXT,
} from '@/common/constants';

import { RecurringContributionFormValues, RecurringContributionFrequency } from '../contribution.types';

const calculateTotalAmount = (
  amount: number,
  frequency: RecurringContributionFrequency,
  startDate: Date,
  endDate: Date,
  day?: string,
): number => {
  let currentDate = dayjs(startDate);
  let totalAmount = 0;

  while (currentDate.isBefore(dayjs(endDate)) || currentDate.isSame(dayjs(endDate), 'day')) {
    let currentDay = day ? _.toNumber(day) : currentDate.date();

    if (currentDay > currentDate.endOf('month').date()) {
      currentDay = currentDate.endOf('month').date();
    }

    if (frequency === RecurringContributionFrequency.MONTHLY
      && day
      && currentDate.date() !== currentDay) {
      currentDate = currentDate.add(1, 'day');
       
      continue;
    }

    if ((frequency === RecurringContributionFrequency.WEEKLY
      || frequency === RecurringContributionFrequency.BIWEEKLY)
      && day
      && currentDate.day() !== currentDay) {
      currentDate = currentDate.add(1, 'day');
       
      continue;
    }

    totalAmount += amount;

    if (frequency === RecurringContributionFrequency.WEEKLY) {
      currentDate = currentDate.add(1, 'week');
    } else if (frequency === RecurringContributionFrequency.BIWEEKLY) {
      currentDate = currentDate.add(2, 'week');
    } else if (frequency === RecurringContributionFrequency.MONTHLY) {
      currentDate = currentDate.add(1, 'month');
    }
  }

  return totalAmount;
};

const today = dayjs().toDate();
const tomorrow = dayjs(today).add(1, 'day').format(DEFAULT_DATE_FORMAT);
const lastDayOfCurrentYear = dayjs().endOf('year').format(DEFAULT_DATE_FORMAT);

const dateRangeValidator = (
  endDate?: string,
  startDate?: string,
) => endDate && startDate && dayjs(endDate).isBefore(dayjs(startDate));

const frequencyOptions = [
  {
    key: RecurringContributionFrequency.WEEKLY,
    value: RecurringContributionFrequency.WEEKLY,
    title: RecurringContributionFrequency.WEEKLY,
  },
  {
    key: RecurringContributionFrequency.BIWEEKLY,
    value: RecurringContributionFrequency.BIWEEKLY,
    title: RecurringContributionFrequency.BIWEEKLY,
  },
  {
    key: RecurringContributionFrequency.MONTHLY,
    value: RecurringContributionFrequency.MONTHLY,
    title: RecurringContributionFrequency.MONTHLY,
  },
];

const weeklyOptions = [
  { key: '1', value: 'Monday' },
  { key: '2', value: 'Tuesday' },
  { key: '3', value: 'Wednesday' },
  { key: '4', value: 'Thursday' },
  { key: '5', value: 'Friday' },
];

const minAmount = 10;
const maxAmount = 8750;

export const useMakeRecurringContributionFields = (
  formValues: RecurringContributionFormValues,
  minimumContributionAmount = minAmount,
  maximumContributionAmount = maxAmount,
) => {
  const {
    contributionAmount,
    frequency,
    monthlyDay = weeklyOptions[0].key,
    weeklyDay = weeklyOptions[0].key,
    startDate,
    endDate,
  } = formValues;
  const minEndDate = useMemo(() => {
    if (!frequency) return undefined;

    const currentTomorrow = dayjs().add(1, 'day');
    const currentStartDate = startDate ? dayjs(startDate) : currentTomorrow;

    if (frequency === RecurringContributionFrequency.WEEKLY) {
      return currentStartDate.add(1, 'week').subtract(1, 'day').format(DEFAULT_DATE_FORMAT);
    }

    if (frequency === RecurringContributionFrequency.BIWEEKLY) {
      return currentStartDate.add(2, 'week').subtract(1, 'day').format(DEFAULT_DATE_FORMAT);
    }

    if (frequency === RecurringContributionFrequency.MONTHLY) {
      return currentStartDate.add(1, 'month').subtract(1, 'day').format(DEFAULT_DATE_FORMAT);
    }

    return undefined;
  }, [frequency, startDate]);

  const currentMaxAmount = useMemo(() => {
    if (!frequency || !startDate) return maximumContributionAmount;
    const endDateValue = endDate && dateRangeValidator(endDate, lastDayOfCurrentYear)
      ? dayjs(endDate).toDate()
      : dayjs(lastDayOfCurrentYear).toDate();
  
    return calculateTotalAmount(
      _.toNumber(contributionAmount),
      frequency,
      dayjs(startDate).toDate(),
      endDateValue,
      frequency === RecurringContributionFrequency.MONTHLY ? monthlyDay : weeklyDay,
    );
  }, [
    contributionAmount,
    endDate,
    frequency,
    monthlyDay,
    startDate,
    weeklyDay,
    maximumContributionAmount,
  ]);

  const isScheduledContributionExceeds = useMemo(() => 
    currentMaxAmount > maximumContributionAmount,
  [currentMaxAmount, maximumContributionAmount]);

  const fields = useMemo(() => [
    {
      name: 'contributionAmount',
      type: FieldTypes.Currency,
      showRequireIcon: true,
      label: 'Contribution amount',
      placeholder: 'Enter Contribution amount',
      defaultValue: contributionAmount,
      validator: yup.string()
        .test({
          test: (val) => {
            if (_.toNumber(val) < minimumContributionAmount) return false;
            return true;
          },
          message: `Amount must be greater than $${minimumContributionAmount}`,
        }).test({
          test: (val) => {
            if (_.toNumber(val) > maximumContributionAmount) return false;
            return true;
          },
          message: 'Amount exceeds your remaining limit',
        }).test({
          test: () => {
            if (isScheduledContributionExceeds) return false;
            return true;
          },
          message: 'Scheduled contributions exceed your remaining limit',
        })
        .required(REQUIRED_TEXT),
    },
    {
      name: 'frequency',
      type: FieldTypes.Dropdown,
      showRequireIcon: true,
      label: 'Frequency',
      placeholder: 'Enter Frequency',
      validator: yup.string().test({
        test: () => {
          if (dateRangeValidator(endDate, minEndDate)) return false;
          return true;
        },
        message: 'Selected date range is less than frequency type',
      }).required(REQUIRED_TEXT),
      singleMode: true,
      options: frequencyOptions,
      defaultValue: RecurringContributionFrequency.MONTHLY,
      value: frequency,
    },
    ...frequency === RecurringContributionFrequency.MONTHLY ? [
      {
        name: 'monthlyDay',
        type: FieldTypes.Number,
        showRequireIcon: true,
        label: 'Day',
        value: monthlyDay,
        placeholder: 'Enter Day',
        validator: VALIDATORS.REQUIRED_STRING.test({
          test: (val) => {
            const customDayNumber = _.toNumber(val);
            return customDayNumber > 0 && customDayNumber <= 31;
          },
          message: CUSTOM_DAY_ERROR_TEXT,

        }).required(REQUIRED_TEXT),
      },
    ] : [
      {
        name: 'weeklyDay',
        type: FieldTypes.Dropdown,
        showRequireIcon: true,
        label: 'Day',
        defaultValue: weeklyDay,
        placeholder: 'Enter Day',
        validator: yup.string().required(REQUIRED_TEXT),
        singleMode: true,
        options: weeklyOptions,
      },
    ],
    {
      name: 'startDate',
      type: FieldTypes.Date,
      showRequireIcon: true,
      label: 'Start date',
      isManualDateInput: true,
      placeholder: 'Enter Start date',
      validator: yup.string()
        .test({
          test: () => {
            if (dateRangeValidator(startDate, tomorrow)) return false;
            return true;
          },
          message: 'Selected date is less than need according to frequency type',
        })
        .test({
          test: (val) => dateValidatorByFormat(val, DEFAULT_DATE_FORMAT),
          message: DATE_INVALID_TEXT,
        }).required(REQUIRED_TEXT),
      defaultValue: startDate || tomorrow,
      completeValue: startDate || tomorrow,
      minDate: tomorrow,
    },
    {
      name: 'endDate',
      type: FieldTypes.Date,
      label: 'End date',
      placeholder: 'Enter End date',
      isManualDateInput: true,
      validator: yup.string().test({
        test: () => {
          if (dateRangeValidator(endDate, minEndDate)) return false;
          return true;
        },
        message: 'Selected date is less than need according to frequency type',
      })
        .test({
          test: (val) => {
            if (!val) return true;
            return dateValidatorByFormat(val, DEFAULT_DATE_FORMAT);
          },
          message: DATE_INVALID_TEXT,
        }),
      defaultValue: endDate,
      minDate: minEndDate,
    },
  ], [
    contributionAmount,
    minimumContributionAmount,
    frequency,
    monthlyDay,
    weeklyDay,
    startDate,
    endDate,
    minEndDate,
    maximumContributionAmount,
    isScheduledContributionExceeds,
  ]);

  return {
    fields: fields as Field[],
    isScheduledContributionExceeds,
  };
};
