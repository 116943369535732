import React, { useCallback,useMemo, useState } from 'react';
import { AppButton, Box, Preloader } from '@common-fe/common-fe';

import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useEmployeeProcessQuery } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/queries';
import { ProcessingScreenType } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.constants';
import { StyledAppButton, Wrapper } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.styles';
import { ClaimPayee } from '@/modules/user/user.types';

import TransactionProcessingHeader from '../TransactionProcessingHeader';
import TransactionProcessingImmediately from '../TransactionProcessingImmediately';

interface Props {
  claimId: string;
  providerPaymentMethod?: PaymentMethod;
}

const PayeeIsNotDeterminedScreen: React.FC<Props> = ({
  claimId,
  providerPaymentMethod,
}) => {
  const [option, setOption] = useState<ClaimPayee | null>(null);

  const showImmediately = useMemo(() => option === ClaimPayee.Participant, [option]);

  const { employeeProcess, isLoading } = useEmployeeProcessQuery(claimId);
  const selectedPaymentMethod = useStore((state) => state.selectedMethod);

  const handleSubmit = useCallback(() => {
    if (!option) return;
    employeeProcess({
      payToType: option,
      paymentMethodId: option === ClaimPayee.Participant
        ? selectedPaymentMethod?.id : providerPaymentMethod?.id,
      transactMethod: option === ClaimPayee.Participant
        ? selectedPaymentMethod?.type : providerPaymentMethod?.type,
    });
  }, [employeeProcess, selectedPaymentMethod, option, providerPaymentMethod]);

  return (
    <Wrapper
      pad="xl"
      background="canvas"
      border={{ size: 'small', color: 'border2' }}
    >
      <TransactionProcessingHeader
        processingScreenType={ProcessingScreenType.PayeeIsNotDetermined}
      />
      <Box margin={{ top: 'spacing24' }}>
        <Box direction="row" justify="between" gap="spacing12">
          <Box basis="1/2">
            <StyledAppButton
              width="100%"
              size="L"
              buttonType="secondary"
              active={option === ClaimPayee.Participant}
              onClick={() => setOption(ClaimPayee.Participant)}
            >
              Reimburse Myself
            </StyledAppButton>
          </Box>
          <Box basis="1/2">
            <StyledAppButton
              width="100%"
              size="L"
              buttonType="secondary"
              active={option === ClaimPayee.Provider}
              onClick={() => setOption(ClaimPayee.Provider)}
            >
              Pay My Provider
            </StyledAppButton>
          </Box>
        </Box>
        {
          showImmediately && (
            <Box
              margin={{ top: 'spacing24' }}
              pad={{ top: 'spacing24' }}
              border={{ side: 'top', size: '1px', color: 'border1' }}
            >
              <TransactionProcessingImmediately />
            </Box>
          )
        }
        <Box margin={{ top: 'spacing24' }}>
          <AppButton
            size="L"
            width="100%"
            disabled={!option}
            onClick={handleSubmit}
          >
            { }
            {isLoading ? (<Preloader />) : option ? 'Submit' : 'Select an Option'}
          </AppButton>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default PayeeIsNotDeterminedScreen;
