import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, costFormater, 
  Hint, 
  InfoOutlineIcon, 
  Text } from '@common-fe/common-fe';

interface Props {
  value: number;
}
const InvestmentPortfolioBalanceLabel: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Box direction="row" align="center">
      <Hint hintElement={<InfoOutlineIcon color='iconSecondary' size="24px" />}>
        <Text size="small">
          {t('Portfolio Balance reflects the current total in investments held. Actual share price and number of shares will be calculated at the time the trade is executed.')}
        </Text>
      </Hint>
      <Box margin={{ left: 'xs' }}>
        <Text weight={700} size="large">
          {costFormater(value, {
            isPrecision: true,
          })}
        </Text>
        <Text size="small" color="textSecondary" weight={500}>
          {t('Investment Portfolio Balance')}
        </Text>
      </Box>
    </Box>
  );
};
export default InvestmentPortfolioBalanceLabel;
