import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, WarningCard } from '@common-fe/common-fe';

import { StyledAnchor, StyledLeafIcon } from '@/modules/transaction/components/Statements/components/ElectronicAgreement/ElectronicAgreement.styles';

import ElectronicAgreementCheckbox, { ElectronicAgreementCheckboxProps } from './ElectronicAgreementCheckbox';

interface ElectronicAgreementProps {
  email?: string;
  onEdit: () => void;
  onSetNotificationMode?: () => void;
  notificationMode?: boolean;
  children?: React.ReactNode;
  wrapperMode?: boolean;
}

type Props = ElectronicAgreementProps & ElectronicAgreementCheckboxProps

const ElectronicAgreement: React.FC<Props> = ({
  isChecked,
  onChecked,
  email,
  onEdit,
  children,
  notificationMode,
  onSetNotificationMode,
  isError,
  wrapperMode,
}) => {
  const { t } = useTranslation();

  if (notificationMode) {
    return (
      <WarningCard
        testId="electronic-agreement-notification"
        title={(
          <Text weight="bold" size="16px">
            {t('Enjoy the convenience of paperless transactions and reduce your environmental impact.')}
          </Text>
        )}
        backgroundColor="successContainer"
        borderColor="successBorder"
        topBorderColor="success"
        containerProps={{ pad: 'spacing24' }}
        headerElement={(
          <Box margin={{ left: 'auto' }} data-testId="read-more-button">
            <StyledAnchor onClick={onSetNotificationMode}>
              {t('Read More')}
            </StyledAnchor>
          </Box>
        )}
      />
    );
  }

  return (
    <WarningCard
      testId="electronic-agreement"
      title={(
        <Text weight="bold" size="16px">
          {t('Enjoy the convenience of paperless transactions and reduce your environmental impact.')}
        </Text>
      )}
      background="canvas"
      backgroundColor="successContainer"
      borderColor="successBorder"
      topBorderColor="success"
      topBorderMargin={wrapperMode ? 'spacing12' : 'spacing24'}
      {...wrapperMode ? { pad: 'spacing12' } : {}}
      containerProps={{ pad: 'spacing24' }}
    >
      <ElectronicAgreementCheckbox
        isChecked={isChecked}
        onChecked={onChecked}
        isError={isError}
      />

      <Box margin={{ top: 'spacing16' }}>
        <Text color="textSecondary" size="small" weight={500}>
          {t('Email:')}
        </Text>
        <Box margin={{ top: 'spacing2' }} direction="row" align="center">
          <Text size="large">
            {email || <Text size="large" color="textDisabled">{t('You don\'t have an email address')}</Text>}
            &nbsp;
            <StyledAnchor onClick={onEdit}>{email ? t('Edit') : t('Add Email')}</StyledAnchor>
          </Text>
        </Box>
      </Box>

      <StyledLeafIcon color="successBorder" size="spacing32" />
      {children}
    </WarningCard>
  );
};

export default ElectronicAgreement;
