import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import { PaymentMethodLabel } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/PaymentMethodLabel';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import colors from '@/styles/colors';
import spacings from '@/styles/spacings';

interface Props {
  onAdd?: () => void;
}

const PaymentMethodField: React.FC<Props> = ({
  onAdd,
}) => {
  const { t } = useTranslation();
  const paymentMethod = useStore((state) => state.activeMethod);

  return (
    <Box style={{ position: 'relative' }}>
      <Box margin={{ bottom: spacings.s }}>
        <PaymentMethodLabel paymentMethod={paymentMethod} />
      </Box>

      <Box
        style={{
          position: 'absolute',
          top: '100%',
        }}
        width="max-content"
      >
        <Box
          onClick={onAdd}
          style={{ fontSize: '14px' }}
          width="fit-content"
        >
          <Text color={colors.textAccent} weight={500} style={{ whiteSpace: 'nowrap' }}>
            {t(paymentMethod ? 'Change payment method' : 'Add payment method')}
          </Text>
        </Box>
      </Box>

    </Box>
  );
};
export default PaymentMethodField;
