import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useAuthStore } from '@/modules/user';
import useEmployeeViewDataQuery, { QUERY_KEY } from '@/modules/user/queries/useEmployeeViewData.query';

import { PersonalInfoPayload } from '../PersonalInfoForm/stores/usePersonalInfo.store';

type SavePersonalPayload = Partial<PersonalInfoPayload>;
const useSavePersonalInfoQuery = () => {
  const { user } = useAuthStore();
  const query = useQueryClient();
  const employeeId = useMemo(() => `${user?.employee?.id || ''}`, [user]);
  const { rawData, isLoading: isEmployeeLoading} = useEmployeeViewDataQuery(employeeId);
  const { mutateAsync, isLoading } = useMutation(
    (data: SavePersonalPayload) => api.patch(
      PATHS.UI_EMPLOYEE, {

        ..._.pickBy({
          citizenship_country_id: _.toNumber(data.citizenship || rawData?.citizenship_country_id),
          employee_provided_email: data.email || rawData?.employee_provided_email,
          is_employee_email_preferred: true,
          preferred_phone_number: data.phone?.trim() || rawData?.preferred_phone?.number?.trim(),
          preferred_phone_type: data.phoneType || rawData?.preferred_phone?.phone_type,

        }),
      },
    ),
    {
      onSuccess: async () => {
        await query.invalidateQueries([QUERY_KEY, PATHS.EMPLOYEE_INFO(employeeId)]);
      },
    },
  );

  return {
    handleSave: mutateAsync,
    isLoading: isLoading || isEmployeeLoading,
  };
};
export default useSavePersonalInfoQuery;
