import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Preloader,
} from '@common-fe/common-fe';
import _ from 'lodash';

import AppButton from '@/components/controls/AppButton';
import { PendingModal } from '@/components/elements';
import costFormated from '@/utils/modifiers/costFormater';

const BUTTON_WIDTH = '130px';

interface Props {
  visible: boolean;
  fundingAmount: string;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  isLoading: boolean;
}
const ConfirmBuyModal: React.FC<Props> = ({
  visible,
  fundingAmount,
  onSetVisible,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();
  
  const handleSubmit = useCallback(() => {
    if(isLoading) {
      return;
    }
    onSubmit();
  }, [isLoading, onSubmit]);
  return (
    <PendingModal
      header={`${t('Move ')} ${costFormated(_.toNumber(fundingAmount), true)} ${t('into your investment account?')}`}
      visible={visible}
      onSetVisible={onSetVisible}
    >
      <Box direction="row">
        <Box margin={{ right: 'spacing12' }}>
          <AppButton
            onClick={() => onSetVisible(false)}
            width={BUTTON_WIDTH}
            buttonType="secondary"
          >
            {t('Cancel')}
          </AppButton>
        </Box>
        <AppButton onClick={handleSubmit}  width={BUTTON_WIDTH}>
          {
            isLoading
              ? <Preloader color="white" />
              : t('Confirm')
          }
        </AppButton>
      </Box>
    </PendingModal>
  );
};

export default ConfirmBuyModal;
