import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, FlatTextInput, Form, Heading, onKeyDownForCancellingAutoComplete, Text, useIsWindowsOS } from '@common-fe/common-fe';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';

import { AUTH_ERROR_KEY, PASSWORD_ERROR_MESSAGE, REQUIRED_TEXT } from '@/common/constants';
import REGEXP from '@/common/regexp';
import { DefaultValues } from '@/common/types';
import AppButton from '@/components/controls/AppButton';

import { useForgotPassword } from './hooks';

const CODE_ENTITY_NAME = 'verificationCode';
const NEW_PASSWORD_FIELD_KEY = 'newPassword';
const CONFIRM_PASSWORD_FIELD_KEY = 'confirmPassword';

interface Props {
  username: string;
  testId?: string;
  children?: React.ReactNode;
  onNext: (value: object) => void;
}

const NewPassword: React.FC<Props> = ({ username, onNext }) => {
  const { t } = useTranslation();
  const { PASSWORD_MUST_MATCH, CODE_IS_WRONG } = AUTH_ERROR_KEY;
  const {
    error,
    handleResetError,
    handleSetNewCode,
    handleRequestCodeWithTimeout,
    disabled: disabledResend,
  } = useForgotPassword();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        [CODE_ENTITY_NAME]: yup.string().required(REQUIRED_TEXT),
        [NEW_PASSWORD_FIELD_KEY]: yup
          .string()
          .required(REQUIRED_TEXT)
          .min(8, t(PASSWORD_ERROR_MESSAGE))
          .matches(REGEXP.UPPER_AND_LOWERCASE, t(PASSWORD_ERROR_MESSAGE))
          .matches(REGEXP.ONE_NUMBER_ONLY, t(PASSWORD_ERROR_MESSAGE))
          .matches(REGEXP.ONE_SPECIAL_CHARACTER, t(PASSWORD_ERROR_MESSAGE))
          .test({
            test: (val) => val ? !REGEXP.FORBIDDEN_CHARACTERS.test(val) : true,
            message: t(PASSWORD_ERROR_MESSAGE),
          }),
        [CONFIRM_PASSWORD_FIELD_KEY]: yup
          .string()
          .required(REQUIRED_TEXT)
          .oneOf([yup.ref(NEW_PASSWORD_FIELD_KEY)], PASSWORD_MUST_MATCH),
      })
    ),
  });

  const onSubmit = useCallback(
    async (data: DefaultValues) => {
      const code = _.get(data, CODE_ENTITY_NAME, '') as string;

      const password = _.get(data, NEW_PASSWORD_FIELD_KEY, '') as string;

      await handleSetNewCode(username, code, password, onNext);
    },
    [handleSetNewCode, onNext, username]
  );
  const isWindowsOS = useIsWindowsOS();
  const [isCodeAvailable, setIsCodeAvailable] = useState(!isWindowsOS);
  const [isNewPassAvailable, setIsNewPassAvailable] = useState(!isWindowsOS);
  return (
    <Box margin={{ horizontal: 'l' }}>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box direction="column">
          <Box
            border={{ color: 'border2', size: 'medium' }}
            round="container1Round"
            pad={{
              horizontal: 'spacing48',
              vertical: 'spacing24',
            }}
            margin={{
              bottom: 'spacing12',
            }}
          >
            <Heading level={4} color="textTitle" textAlign="center">
              {t('Enter verification code')}
            </Heading>
            <Text textAlign="center" size="medium" weight={500} margin={{ horizontal: 'spacing48' }}>
              {t('Please enter the code you received in email to verify your account.')}
            </Text>
            <Controller
              // @ts-ignore
              name={CODE_ENTITY_NAME}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box margin={{ top: 'spacing24' }}>
                  <FlatTextInput
                    {...(isWindowsOS && !isCodeAvailable
                      ? {
                        value: isCodeAvailable ? value : '',
                        onKeyDown: (e) =>
                          onKeyDownForCancellingAutoComplete(
                            e,
                            onChange,
                            setIsCodeAvailable,
                            isCodeAvailable
                          ),
                      }
                      : {
                        value,
                      })}
                    onChange={(newValue: string) => {
                      onChange(newValue);
                      handleResetError();
                    }}
                    testId="input-verification-code"
                    name={CODE_ENTITY_NAME}
                    defaultAutoComplete="off"
                    errorWrap
                    placeholder={t('Enter verification code')}
                    errorText={
                      error ? CODE_IS_WRONG : (_.get(errors, `${CODE_ENTITY_NAME}.message`, '') as string)
                    }
                  />
                </Box>
              )}
            />
            <Box direction="row" margin={{ top: 'spacing12' }}>
              <AppButton
                testId="resend-code"
                buttonType="quaternary"
                disabled={disabledResend}
                onClick={() => handleRequestCodeWithTimeout(username)}
              >
                Resend Code
              </AppButton>
            </Box>
          </Box>

          <Box
            border={{ color: 'border2', size: 'medium' }}
            round="container1Round"
            pad={{
              horizontal: 'spacing48',
              vertical: 'spacing24',
            }}
            margin={{
              bottom: 'spacing12',
            }}
          >
            <Heading level={4} color="textTitle" textAlign="center">
              {t('Reset Password')}
            </Heading>

            <Text textAlign="center" size="medium" weight={500} margin={{ horizontal: 'spacing48' }}>
              {t('Please choose your new password.')}
            </Text>
            <Controller
              // @ts-ignore
              name={NEW_PASSWORD_FIELD_KEY}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box
                  margin={{ top: 'l', bottom: _.get(errors, `${NEW_PASSWORD_FIELD_KEY}.message`) ? 'm' : '' }}
                  id="password-input-id"
                >
                  <FlatTextInput
                    {...(isWindowsOS && !isNewPassAvailable
                      ? {
                        value: isNewPassAvailable ? value : '',
                        onKeyDown: (e) =>
                          onKeyDownForCancellingAutoComplete(
                            e,
                            onChange,
                            setIsNewPassAvailable,
                            isNewPassAvailable
                          ),
                      }
                      : {
                        value,
                      })}
                    onChange={(newValue: string) => {
                      onChange(newValue);
                      handleResetError();
                    }}
                    testId="password-input"
                    passwordHelper
                    passwordHelperLeftPosition
                    defaultAutoComplete="off"
                    inputType="password"
                    errorWrap
                    name={NEW_PASSWORD_FIELD_KEY}
                    placeholder={t('Choose a new password')}
                    errorText={_.get(errors, `${NEW_PASSWORD_FIELD_KEY}.message`, '') as string}
                  />
                </Box>
              )}
            />

            <Controller
              // @ts-ignore
              name={CONFIRM_PASSWORD_FIELD_KEY}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box margin={{ top: 'spacing12' }}>
                  <FlatTextInput
                    inputType="password"
                    value={value}
                    errorWrap
                    onChange={(newValue: string) => {
                      onChange(newValue);
                      handleResetError();
                    }}
                    testId="confirm-password-input"
                    name={CONFIRM_PASSWORD_FIELD_KEY}
                    placeholder={t('Confirm new password')}
                    errorText={_.get(errors, `${CONFIRM_PASSWORD_FIELD_KEY}.message`, '') as string}
                  />
                </Box>
              )}
            />
          </Box>
          <Box
            margin={{
              top: 'spacing12',
              horizontal: 'spacing48',
            }}
          >
            <AppButton size="L" testId="submit" type="submit" width="100%">
              {t('Submit')}
            </AppButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default NewPassword;
