import { useMemo } from 'react';

import { CardHolderDto } from '@/modules/transaction/components/Cards/Cards.types';
import { useAuthStore } from '@/modules/user/stores';

import { DependentFormValue } from '../AddCardDependentForm';
import { DependentInfoDto } from '../queries/useAddDependentCard.query';

export const useSetDependent = (dependentInfo: DependentFormValue | CardHolderDto | null) => {
  const { user } = useAuthStore();

  const preparedDependent = useMemo(() => ({
    id: dependentInfo?.dependentId,
    firstName: dependentInfo?.firstName,
    lastName: dependentInfo?.lastName,
    birthOn: dependentInfo?.dateOfBirth,
    person: {
      id: user?.id,
      preferredName: user?.preferredName,
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
    relationshipType: dependentInfo?.relationship?.toUpperCase() || 'OTHER',
  } as DependentInfoDto), [user, dependentInfo]);

  return preparedDependent;
};
