import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountAccessIcon, Box } from '@common-fe/common-fe';

import BlockWrapper from '../BlockWrapper';

import AccountDetailsForm from './components/AccountDetailsForm/AccountDetailsForm';
import SecurityForm from './components/SecurityForm/SecurityForm';
import VerificationForm from './components/VerificationForm/VerificationForm';

interface Props {
  isOrphanMode?: boolean;
}

const AccountAccess: React.FC<Props> = ({ isOrphanMode }) => {
  const { t } = useTranslation();

  return (
    <BlockWrapper
      Icon={AccountAccessIcon}
      title={t('Account Access')}
      id="account_access_block_wrapper"
    >
      <Box
        background="canvas"
        round="container1Round"
        elevation="default"
      >
        <AccountDetailsForm isOrphanMode={isOrphanMode} />
      </Box>

      <Box
        background="canvas"
        round="container1Round"
        margin={{ top: 'spacing24' }}
        elevation="default"
      >
        <SecurityForm />
      </Box>

      <Box
        background="canvas"
        round="container1Round"
        margin={{ top: 'spacing24' }}
        elevation="default"
      >
        <VerificationForm />
      </Box>
    </BlockWrapper>
  );
};

export default AccountAccess;
