import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

const CANCELLED = 'CANCELLED';

interface ClaimStatusPayload {
  id: number;
  name: string;
  description: string;
}

interface ClaimStatus {
  id: string;
  name: string;
  description: string;
}

export const getClaimStatuses = () => api
  .get<ClaimStatusPayload[]>(PATHS.CLAIM_STATUSES);

export const QUERY_KEY = 'getClaimStatuses';
const useGetClaimStatusesQuery = () => {
  const {
    data, isLoading,
  } = useQuery(
    [QUERY_KEY, getClaimStatuses], getClaimStatuses,

  );
  
  const claimStatuses = useMemo(() => data?.data.map((item): ClaimStatus => ({
    id: toString(item.id),
    name: item.name,
    description: item.description,
  })) || [], [data]);

  const statusesWithoutCanceled = useMemo(
    () => claimStatuses.filter((item) => item.name !== CANCELLED),
    [claimStatuses],
  );

  return {
    isLoading,
    claimStatuses,
    statusesWithoutCanceled,
  };
};

export default useGetClaimStatusesQuery;
