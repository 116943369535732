import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { PurposeType } from '@/modules/investments/investments.types';

import { useStore as useInvestmentProfileStore } from '../../stores/useInvestmentProfile.store';

export default () => {
  const { t } = useTranslation();
  const PURPOSE_TYPE_OPTIONS = useMemo(
    () => [
      {
        label: t('To pay for current medical expenses during the year'),
        value: PurposeType.PAY_MEDICAL_EXPENSES,
      },
      {
        label: t('To grow investment assets for the long-term to pay for future medical expenses'),
        value: PurposeType.GROW_INVESTMENT_ASSETS,
      },
    ], [t],
  );
  const defaultFields = useInvestmentProfileStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        name: 'purposeType',
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: t('What is the purpose of your H.S.A. account investment holdings?'),
        placeholder: '',
        options: PURPOSE_TYPE_OPTIONS,
        isWrappedMode: true,
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]), [PURPOSE_TYPE_OPTIONS, t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
