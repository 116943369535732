import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  NavigationToggler,
  Option,
  OptionKey,
  PaginationBar,
  Preloader,
  RawButton,
  SearchInput,
  SelectDropdown,
  Text,
  usePaginationParams,
} from '@common-fe/common-fe';

import costFormated from '@/utils/modifiers/costFormater';

import { useDefaultAllocationFunds } from '../hooks';
import { FUNDS_TABS } from '../SelfDirected.types';
import { useSelectedFundsStore } from '../stores';
import InvestmentPortfolioBalanceLabel from '../SwitchToSelfDirected/InvestmentPortfolioBalanceLabel';

import FundItem from './FundItem/FundItem';
import FundsCategoryPicker from './FundsCategoryPicker';
import FundsListHeader from './FundsListHeader';
import { useFundsList } from './hooks';
import SelfDirectedFundsPlaceholder from './SelfDirectedFundsPlaceholder';

const DELIMITER_INDEX = 10;
const DEFAULT_PER_PAGE = 50;
const ASC_KEY = 'name,asc';
const SORT_OPTIONS = [
  {
    key: ASC_KEY,
    title: 'A-Z',
  },
  {
    key: 'name,desc',
    title: 'Z-A',
  },
];

interface Props {
  availableToInvest?: number;
  portfolioBalance?: number;
  accountId?: string;
  isSwitchMode?: boolean;
}
const SelfDirectedFunds: React.FC<Props> = ({
  availableToInvest = 0,
  isSwitchMode,
  accountId,
  portfolioBalance = 0,
}) => {
  const { t } = useTranslation();

  const { defaultFundsIds} = useDefaultAllocationFunds();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    if(defaultFundsIds){
      setSelectedIds(defaultFundsIds);
    }
  }, [defaultFundsIds]);
  const [currentTab, setCurrentTab] = useState<string>(FUNDS_TABS.ALL);
  const setStoredSelectedIds = useSelectedFundsStore((state) => state.setSelectedFunds);
  const [sortBy, setSortBy] = useState<OptionKey>(ASC_KEY);

  const [categorries, setCategories] = useState<OptionKey[]>([]);
  const { page, setPage, searchString, setSearchString, setPerPage, perPage } =
    usePaginationParams(DEFAULT_PER_PAGE);
  useEffect(() => {
    setStoredSelectedIds(selectedIds);
  }, [selectedIds, setStoredSelectedIds]);
  useEffect(() => {
    setPage(1);
  }, [sortBy, categorries, searchString, setPage]);
  const { data, fundsIds, total, isLoading } = useFundsList({
    accountId,
    isSwitchMode,
    searchParams: {
      page,
      perPage,
      sortBy: sortBy as string,
      searchString,
      categoryIds: categorries as string[],
    },
  });
  const currentIds = useMemo(() => {
    if (currentTab === FUNDS_TABS.SELECTED) {
      return selectedIds;
    }
    return fundsIds;
  }, [currentTab, fundsIds, selectedIds]);
  const isAllSelected = useMemo(() => {
    return currentIds?.length === selectedIds?.length;
  }, [currentIds, selectedIds]);
  const options = useMemo(() => {
    const items: Option[] = [
      {
        key: FUNDS_TABS.ALL,
        value: FUNDS_TABS.ALL,
        title: t(`All funds (${total || 0})`),
      },
      {
        key: FUNDS_TABS.SELECTED,
        value: FUNDS_TABS.SELECTED,
        title: t(`Selected (${selectedIds.length || 0})`),
      },
    ];
    return items;
  }, [selectedIds.length, t, total]);
  const handleSelectAll = useCallback(() => {
    if (isAllSelected) {
      setSelectedIds([]);
    } else {
      if (fundsIds) {
        setSelectedIds(fundsIds);
      }
    }
  }, [fundsIds, isAllSelected]);
  const handleDeselectAll = useCallback(() => {
    setSelectedIds([]);
  }, []);
  const handleSelect = useCallback(
    (id: string) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((item) => item !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );
  const currentList = useMemo(() => {
    if (currentTab === FUNDS_TABS.SELECTED) {
      return data?.filter((item) => selectedIds.includes(item.id));
    }
    return data;
  }, [currentTab, data, selectedIds]);

  const currentTotal = useMemo(() => {
    if (currentTab === FUNDS_TABS.SELECTED) {
      return selectedIds.length;
    }
    return total;
  }, [currentTab, selectedIds.length, total]);
  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box margin={{ vertical: 'l' }} align="center" justify="center">
          <Preloader />
        </Box>
      );
    }
    if (!currentList?.length) {
      return <SelfDirectedFundsPlaceholder currentTab={currentTab} />;
    }
    return (
      <>
        {currentList?.map((item, index) => (
          <FundItem
            key={item.id}
            isDelimiter={(index + 1) % DELIMITER_INDEX === 0}
            checked={selectedIds.includes(item.id)}
            onSelect={handleSelect}
            {...item}
          />
        ))}
      </>
    );
  }, [currentList, currentTab, handleSelect, isLoading, selectedIds]);
  return (
    <Box direction="column">
      <Box direction="row" pad={{ vertical: 'l' }}>
        <NavigationToggler
          value={currentTab}
          buttonStyle={{ width: '150px' }}
          options={options}
          onChange={setCurrentTab}
        />
        {isSwitchMode ? (
          <Box margin={{ left: 'l' }} align="center" justify="center">
            <InvestmentPortfolioBalanceLabel value={portfolioBalance} />
          </Box>
        ) : (
          <Box margin={{ left: 'l' }} direction="column" justify="center">
            <Text weight={700} style={{ lineHeight: '20px' }}>
              {costFormated(availableToInvest, true)}
            </Text>
            <Text size="small">{t('Max. Available Balance to Invest')}</Text>
          </Box>
        )}
      </Box>
      <Box direction="column" data-testid="FundsList" background="module" round="container1Round" pad="l">
        <Box direction="row" align="center" margin={{ bottom: 's' }}>
          <Box direction="row" align="center" style={{ flex: 1 }}>
            <Text size="large" color="blockTitle" weight="bold">
              {currentTab === FUNDS_TABS.SELECTED
                ? t(`Selected funds: ${currentTotal || 0}`)
                : t(`Funds: ${currentTotal || 0}`)}
            </Text>
            {currentTab === FUNDS_TABS.SELECTED && !!selectedIds.length && (
              <Box margin={{ left: 's' }} pad={{ bottom: '3px' }}>
                <RawButton onClick={handleDeselectAll}>
                  <Text weight={400} color="textAccent" size="large" style={{ lineHeight: '12px' }}>
                    {t('Deselect all')}
                  </Text>
                </RawButton>
              </Box>
            )}
          </Box>
          <Box width={{ min: '250px' }}>
            <SearchInput
              disabled={false}
              value={searchString}
              hasSearchingHistory
              placeholder={t('Search')}
              onChange={setSearchString}
            />
          </Box>
          <Box margin={{ left: 's' }}>
            <FundsCategoryPicker values={categorries} onChangeValues={setCategories} />
          </Box>
          <Box margin={{ left: 's' }}>
            <SelectDropdown
              disabled={false}
              id="type"
              singleMode
              activeTitle
              name={t('Sort')}
              prefix="Type"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={(key) => setSortBy(key || '')}
            />
          </Box>
        </Box>
        <Box>
          <FundsListHeader value={isAllSelected} onChangeValue={handleSelectAll} />
          <Box>
            {content}
            <PaginationBar
              page={page}
              total={total || 0}
              perPageOptions={['50', '100']}
              pageSize={perPage}
              onChangePage={setPage}
              onChangePageSize={setPerPage}
            />
          </Box>
        </Box>
      </Box>

      <Box />
    </Box>
  );
};
export default SelfDirectedFunds;
