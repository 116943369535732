import { InvestmentHolding } from '@/modules/investments/investments.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  holdings: InvestmentHolding[];
  handleSetHoldings: (value: InvestmentHolding[]) => void;
  handleReset: () => void;
}

const useStore = storeCreator<State>((set) => ({
  holdings: [],

  handleSetHoldings: (value) => set(() => ({
    holdings: value,
  })),
  handleReset: () => set(() => ({
    holdings: [],
  })),
}));

export default useStore;
