import React, { useMemo } from 'react';
import {
  dateValidatorByFormat,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DATE_INVALID_TEXT, DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';

const INVALID_DATE_RANGE_TEXT = 'Termination date must be within the current plan dates and no more than 6 months in advance.';

export const dateRangeValidator = (
  endDate?: string,
  startDate?: string,
  maxDate?: string,
) => {
  if (!endDate || !startDate) return false;

  if (dayjs(endDate).isBefore(dayjs(startDate))) return true;

  if (dayjs(endDate).isAfter(dayjs(maxDate))) return true;
};

export const useEmployerTerminationDateForm = (dateValue?: string, minDate?: string, viewMode?: boolean) => {
  const fields = useMemo(() => [
    {
      name: 'terminationDate',
      type: viewMode ? FieldTypes.Node : FieldTypes.Date,
      label: viewMode ?(
        <Inscription weight={400}>
          Termination date
        </Inscription>
      ) : 'Termination date',
      placeholder: 'Select date',
      isManualDateInput: true,
      showRequireIcon: !viewMode,
      controlStyles: { width: '160px' },
      inputWrapCssClass: 'small-input',
      validator: yup.string()
        .test({
          test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
          message: DATE_INVALID_TEXT, 
        })
        .test({
          test: (val) => {
            if (dateRangeValidator(val, minDate, dayjs().add(6, 'month').format(DEFAULT_DATE_FORMAT))) return false;
            return true;
          },
          message: INVALID_DATE_RANGE_TEXT,
        }).required(REQUIRED_TEXT),
      defaultValue: dateValue,
      value: viewMode ? (
        <Inscription color="textBody" weight={700}>
          {dateValue}
        </Inscription>
      ) : dateValue,
      minDate: minDate ? dayjs(minDate) : undefined,
      maxDate: dayjs().add(6, 'month'),
    },
  ], [
    dateValue,
    minDate,
    viewMode,
  ]);

  return fields as Field[];
};
