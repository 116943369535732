import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { SignIn } from './components';


const isMaintenanceApplied = false;

interface Props {
  children: React.ReactNode;
}
const MaintenanceWrapper: React.FC<Props> = ({ children}) => {

  if(isMaintenanceApplied) {
    return (
      <SignIn disabled>
        <Box margin={{bottom: 'm'}}>
          <Text color="danger">
            We are currently undergoing maintenance. We'll be back soon. Thank you for your patience!

          </Text>
        </Box>
      </SignIn>
    );
  }
  return children;
};


export default MaintenanceWrapper;