import styled from 'styled-components';

interface WrapperProps {
  minify: boolean;
  isLockBannerMode?: boolean;
  customTopValue?: string;
}
export const Wrapper = styled.div<WrapperProps>`
  width: ${({ theme, minify }) => (minify ? theme.controls.minifiedSidebarSize : theme.controls.mediumSidebarSize)};
  min-height: 100vh;

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    width: ${({ theme, minify }) => (minify ? theme.controls.minifiedSidebarSize : theme.controls.sidebarSize)};
  }
`;

export const Content = styled.div<WrapperProps>`
  position: fixed ;
  display: flex;
  height: ${({ isLockBannerMode, customTopValue }) => (isLockBannerMode ? '95vh' : `calc(100vh - ${customTopValue || '0px'})`)};
  margin-top: ${({ customTopValue }) => customTopValue};
  flex-direction: column;
  width: ${({ theme, minify }) => (minify ? theme.controls.minifiedSidebarSize : theme.controls.mediumSidebarSize)};
  background-color: ${({ theme }) => {
    return theme.colors.navigationBackground;
  }};
  padding: ${({ theme }) => `${theme.spacings.spacing24} ${theme.spacings.spacing16} 0`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.border2}`};
  ${({ minify, theme }) => {
    if (minify) {
      return {
        width: theme.controls.minifiedSidebarSize,
        alignItems: 'center',
        padding: '20px 0 0 0',

      };
    }
    return {};
  }}

  left: 0;
  top: ${({ isLockBannerMode }) => (isLockBannerMode ? '62px' : '0')};
  bottom: 0;
  z-index: 100;
 
  transition: all 0.25s;
  box-sizing: border-box;

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    width: ${({ theme, minify }) => (minify ? theme.controls.minifiedSidebarSize : theme.controls.sidebarSize)};
  }
`;
export const DelimiterText = styled.p``;
