import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ClockIcon,
  Inscription,
} from '@common-fe/common-fe';

import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';

export const UploadFileByQrLinkError = () => {
  const { t } = useTranslation();
  return (
    <UploadFileByLinkContentWrapper>
      <Box justify="center" align="center">
        <ClockIcon size="72px" color="danger" />

        <Box
          margin={{ bottom: '50px', top: 'spacing24' }}
          pad={{ horizontal: 'spacing32' }}
        >
          <Inscription
            size="26px"
            weight={700}
            textAlign="center"
            lineHeight="36px"
            margin={{ bottom: 'spacing12' }}
          >
            {t('Your session has expired!')}
          </Inscription>

          <Inscription
            size="16px"
            textAlign="center"
            lineHeight="22px"
          >
            {t('Scan updated QR code to upload another photo.')}
          </Inscription>
        </Box>
      </Box>
    </UploadFileByLinkContentWrapper>
  );
};
