import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Pagination } from '@/common/types';

import { InvestmentHoldingStatus } from '../../investments.types';
import { FundsPayload, FundsSearchParams } from '../SelfDirected.types';

interface ResponsePayload {
  id: number;
  external_id: string;
  image_url: string;
  name: string;
  symbol: string;
  research_url: string;
  category_id: string;
  type: string;
  status?: InvestmentHoldingStatus;
}
// const MOKED_DATA = [{
//   id: '1',
//   image_url: '',
//   name: 'Core MSCI EAFE iShares',
//   short_name: 'IEFA',
//   research_url: '/',
//   yr3: null,
//   yr5: null,
//   category_id: '1',
//   type: '1',
// }];
// mock.onGet(PATHS.FUNDS_LIST).reply(200, {
//   totalElements: 10,
//   content: MOKED_DATA,
// });

const QUERY_KEY = 'FUNDS_LIST_QUERY';
export default (params?: FundsSearchParams,   isQueryDisabled?: boolean) => {
  const formattedParams = _.omitBy({
    sort: `${params?.sortBy || ''}`,
    search_value: `${params?.searchString || ''}`,
    page: `${params?.page ? params.page - 1 : ''}`,
    size: `${params?.perPage || ''}`,
    category_types: `${params?.categoryIds || ''}`,
  }, _.isEmpty);
  const searchParams = new URLSearchParams(formattedParams);
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_KEY, params?.page, params?.perPage,
      params?.searchString, params?.sortBy, params?.categoryIds],
    () => api.get<Pagination< ResponsePayload[]>>(`${PATHS.FUNDS_LIST}?${searchParams}`), {
      retry: 0,
      enabled: !isQueryDisabled
    }
  );

  const formattedData = useMemo(() => {
    const list = data?.data?.content || [];
    return list.map((item) => {
      const formattedItem: FundsPayload = {
        id: `${_.get(item, 'id', '') || '' as string}`,
        logo: _.get(item, 'image_url', ''),
        title: _.get(item, 'name', ''),
        stockCode: _.get(item, 'symbol', ''),
        link: _.get(item, 'research_url', ''),
        price: _.get(item, 'close') || 0,
        yr3: null,
        yr5: null,
        category: _.get(item, 'instrument_category_type', ''),
        assetClass: _.get(item, 'type', ''),
        status: _.get(item, 'status',), 
      };
      return formattedItem;
    });
  }, [data]);

  const total = useMemo(() => data?.data?.totalElements, [data]);

  return {
    isLoading,
    total,
    isSuccess,
    data: formattedData,
  };
};
