import React, { useMemo } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';
import _ from 'lodash';

import { ALLOWED_COUNTRIES, LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import { UserLetterIcon } from '@/components/elements/UserLetterIcon';
import { useAuthStore } from '@/modules/user/stores';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

import EditEmployeeAddress from '../EditEmployeeAddress';

const EmployeeInformation = () => {
  const { user } = useAuthStore();
  const { employee } = useEmployeeStore();

  const preferredMailingAddress = useMemo(
    () => employee?.addresses?.find((address) => address?.preferredMailing),
    [employee]
  );
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
  
  const addressesArray = useMemo(() => {
    const address = preferredMailingAddress;
    if (!address) {
      return;
    }
    const { line1, line2, city, state, zipcode, countryId } = address;
    const country = countryId ? _.get(ALLOWED_COUNTRIES, countryId) : '';

    const listOfAddress: string[]= [];
    if(line1) {
      listOfAddress.push(line1);
    }  
    if(line2) {
      listOfAddress.push(line2);
    }  
    if(city) {
      listOfAddress.push(city);
    }
    if(state) {
      listOfAddress.push(state);
    }
    if(zipcode) {
      listOfAddress.push(zipcode);
    }
    if(country) {
      listOfAddress.push(country);
    }
    return listOfAddress;
   
  }, [preferredMailingAddress]);
  return (
    <Box>
      <Box direction="row" align="center">
        <UserLetterIcon
          text={fullName}
          type={user?.role as OrganizationTypes}
          sizePx="80"
          fontSize="32px"
          size={LetterIconSizeEnum.LOGO}
        />
        <Box margin={{ left: 'spacing12' }}>{/* dependents stack... */}</Box>
      </Box>

      <Box margin={{ top: 'spacing12' }}>
        
        <Inscription color="textOnColor" size="3xl" weight={700}>
          {fullName}
        </Inscription>
        <Box direction="row" align="center" width={{max: '450px'}} style={{flexWrap: 'wrap'}} margin={{ top: 'spacing8' }}>
          
          {addressesArray?.map((item, index) => (
            <Inscription key={index} color="textOnColor" size="large" margin={{right: '8px'}} >
              {_.last(addressesArray) === item ? item: `${item}, `} 
            </Inscription>
          ))}
     
        
          {preferredMailingAddress && <EditEmployeeAddress address={preferredMailingAddress} />}
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeInformation;
