import React from 'react';
import Zendesk from 'react-zendesk';

const KEY = '6dc4524f-b662-4015-be1c-5a7d75c73ce4';
const SETTINGS = {
  color: {
    theme: '#176AF6',
  },
  webWidget: {
    offset: { vertical: '50px' },
  },
};

export default () => (
  <Zendesk
    defer
    zendeskKey={KEY}
    {...SETTINGS}
  />
);
