import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardsItemIcon,
  ExpensesDetailsIcon,
  HomeOutlineIcon,
  MyAccountsIcon,
  PayMyBillIcon,
  ReimburseMeIcon,
} from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { LimitedAccessLabel } from '@/components/elements';
import { useHasAccess } from '@/modules/core/hooks';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import useMyEnrollments from '@/modules/transaction/hooks/useMyEnrollments';
import { useAuthStore } from '@/modules/user/stores';
import whiteLabelingConfig, { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

export interface SubOption {
  key: string;
  title: string;
  url?: string;
}

export interface Option {
  key: string;
  title: string;
  delimiter?: boolean;
  icon?: React.ReactNode;
  options?: SubOption[];
  url?: string;
  link?: string;
  disabled?: boolean;
  hintComponent?: React.ReactNode;
}

const useMenuItemList = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { data: accounts } = useMyEnrollments(user?.id);
  const isReimburseMeButtonsDisabled = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  const { organizationProperties } = useOrganizationProperties();
  const isCardsHidden = useMemo(() => {
    const excludePartners = [THEMES.rippling];
    return excludePartners.includes(whiteLabelingConfig?.key || '');
  }, []);
  const optionList = useMemo<Option[]>(() => {
    const quickLinksOptions: Option[] = [
      ...(organizationProperties?.isReimbursementAllowed || isReimburseMeButtonsDisabled ? [{
        key: 'reimburseMe',
        title: t('Reimburse Me'),
        icon: <ReimburseMeIcon />,
        url: ROUTES.REIMBURSE_ME,
        disabled: isReimburseMeButtonsDisabled,
        ...isReimburseMeButtonsDisabled ? { hintComponent: <LimitedAccessLabel /> } : {},
      }] : []),
      ...(organizationProperties?.isBillPayAllowed || isReimburseMeButtonsDisabled ? [{
        key: 'requestBillPay',
        title: t('Pay a Bill'),
        icon: <PayMyBillIcon />,
        url: ROUTES.REQUEST_BILL_PAY,
        disabled: isReimburseMeButtonsDisabled,
        ...isReimburseMeButtonsDisabled ? { hintComponent: <LimitedAccessLabel /> } : {},
      }] : []),
    ];

    const allOptions: Option[] = [
      {
        key: 'home',
        title: t('Home'),
        icon: <HomeOutlineIcon />,
        url: '/',
      },
      {
        key: 'expenseDetails',
        title: t('Expense Details'),
        icon: <ExpensesDetailsIcon />,
        url: ROUTES.EXPENSE_DETAILS,
      },
      {
        key: 'myAccounts',
        title: t('My Accounts'),
        icon: <MyAccountsIcon />,
        url: ROUTES.MY_ACCOUNTS,
        options: accounts?.map((item) => ({
          key: `${item.id}`,
          title: item.name,
          url: ROUTES.MY_ACCOUNTS_DETAILS(item.id),
        })),
      },
      // {
      //   key: 'investments',
      //   title: t('Investments'),
      //   icon: <FontIcon type="investments-outline" />,
      //   url: `${ROUTES.INVESTMENTS}?id=${accounts?.[0]?.id}`,
      // },
      ...isCardsHidden ? [] : [{
        key: 'cards',
        title: t('Cards'),
        icon: <CardsItemIcon />,
        url: ROUTES.CARDS,
        options: [],
      }],
      ...(quickLinksOptions.length > 0 ? [{
        key: 'quickLinks',
        title: t('Quick Links'),
        delimiter: true,

      },
      ...quickLinksOptions,
      ] : []),
      // {
      //   key: 'payMyBills',
      //   title: t('Pay My Bills'),
      //   icon: <FontIcon type="pay-my-bill" />,
      //   url: '/transaction/pay-my-bills',
      // },
      // {
      //   key: 'settings',
      //   title: t('Settings'),
      //   icon: <FontIcon type="settings-outline" />,
      //   url: '/settings',
      // },
      // {
      //   key: 'statements',
      //   title: t('Statements'),
      //   icon: <FontIcon type="statements-outline" />,
      //   url: '/statements',
      // },
      // {
      //   key: 'dev-playground',
      //   title: t('Dev playground'),
      //   icon: <FontIcon type="scan" />,
      //   url: '/dev-playground',
      // },
    ];

    return allOptions;
  }, [organizationProperties.isReimbursementAllowed,
    organizationProperties.isBillPayAllowed, isReimburseMeButtonsDisabled,
    t, accounts, isCardsHidden]);

  return {
    formattedList: optionList || [],
  };
};

export default useMenuItemList;
