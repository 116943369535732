import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, costFormater,
  InfoOutlineIcon, Text, WarningIcon, } from '@common-fe/common-fe';

interface Props {
  isErrorMode?: boolean;
  isEditMode?: boolean;
  availableBalance?: number;
  amountToSave?: number;
  minimumInvestmentAmount?: number;
  investmentThreshold?: number;

}
const SetupAutoInvestmentsInfo: React.FC<Props> = ({
  isEditMode,
  isErrorMode, availableBalance = 0, amountToSave = 0,
  minimumInvestmentAmount = 0, investmentThreshold = 0,
}) => {
  const { t } = useTranslation();

  const isExtraFunds = useMemo(
    () => availableBalance - amountToSave >= minimumInvestmentAmount,
    [amountToSave, availableBalance, minimumInvestmentAmount],
  );

  const content = useMemo(() => {
    if (isErrorMode) {
      return (
        <Text data-testid="autoinvest-validation_error" color="textBody">{t('You must keep a minimum of ')} <Text color="textBody" weight={700}>{costFormater(investmentThreshold, true)}</Text>
          {t(' in your HSA when investing.')}
        </Text>
      );
    }
    if (isEditMode) {
      return (
        <Text
          color="textBody"
          size="large"
          // margin={{ top: '10px' }}
          style={{ lineHeight: '20px' }}
        >
          {t(' When your account balance is above ')}
          <Text size="large" color="textBody" weight={700}>
            {costFormater(amountToSave)}
          </Text>
          {t(' the extra funds will automatically invest.')}
        </Text>
      );
    }
    if (isExtraFunds) {
      return (
        <Box data-testid="autoinvest-validation_extrafunds">
          <Text
            size="large"
            color="textBody"
            style={{ lineHeight: '20px' }}
          >{t('Your current balance is ')} <Text color="textBody" size="large" weight={700}>{costFormater(availableBalance, true)}</Text>
          </Text>

          <Text
            color="textBody"
            size="large"
            margin={{ top: '10px' }}
            style={{ lineHeight: '20px' }}
          >
            <Text size="large" color="textBody" weight={700}>
              {costFormater(availableBalance - amountToSave)}
            </Text>
            {t(' will be invested today. When your account balance is above ')}
            <Text size="large" color="textBody" weight={700}>
              {costFormater(amountToSave)}
            </Text>
            {t(' the extra funds will automatically invest.')}

          </Text>
        </Box>
      );
    }

    return (
      <Box data-testid="autoinvest-validation_default">
        <Text
          size="large"
          color="textBody"
          style={{ lineHeight: '20px' }}
        >{t('Your current balance is ')} <Text color="textBody" size="large" weight={700}>{costFormater(availableBalance, true)}</Text>
        </Text>

        <Text
          color="textBody"
          size="large"
          margin={{ top: '10px' }}
          style={{ lineHeight: '20px' }}
        >{t('The minimum investment purchase amount is ')}
          <Text size="large" color="textBody" weight={700}>
            {costFormater(minimumInvestmentAmount)}
          </Text>
          {t('. Automatic investments will begin to pull funds once your balance reaches or exceeds ')}
          <Text size="large" color="textBody" weight={700}>
            {costFormater(amountToSave + minimumInvestmentAmount)}
          </Text>
          .
        </Text>
      </Box>
    );
  }, [amountToSave, availableBalance, investmentThreshold, isEditMode, isErrorMode,
    isExtraFunds, minimumInvestmentAmount, t]);
  return (
    <Box
      pad="s"
      margin={{ top: 's' }}
      background="canvas"
      round="container1Round"
      border={{ size: 'small', color: 'border2' }}
      elevation="default"
      style={{ position: 'relative' }}
    >
      <Box
        height="2px"
        background={isErrorMode ? 'error' : 'info'}
        round="4px"
        style={{
          position: 'absolute', top: '-1px', left: '30px', right: '30px',
        }}
      />
      <Box
        direction="row"
        background={isErrorMode ? 'dangerContainer' : 'infoContainer'}
        round="container2Round"
        align="center"
        pad={{ vertical: 'm', horizontal: 'l' }}
        border={{ size: 'small', color: isErrorMode ? 'dangerBorder' : 'infoBorder' }}
      >
        {isErrorMode ? (
          <WarningIcon size="24" color="danger" />
        ) : (
          <InfoOutlineIcon size="24px" color="info" />
        )}

        <Box fill margin={{ left: 'xs' }}>
          {content}
        </Box>
      </Box>
    </Box>
  );
};

export default SetupAutoInvestmentsInfo;
