import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';
interface Props {

}
const ConfirmModalListHeader: React.FC<Props> = () => {
  
  const { t } = useTranslation();
  return (
    <Box
      background="legend"
      align="center"
      round="container2Round"
      direction="row"
      pad={{ vertical: '7px', horizontal: '20px' }}
    >
      <Box style={{ flex: 2 }}>
        <Text
          color="textBody"
          weight={700}
          size="small"
          style={{ lineHeight: '18px' }}
        >{t('Fund')}
        </Text>
      </Box>
      <Box width="32px"></Box>
      <Box  margin={{ left: 'l' }}  style={{flex: 2}}>
        <Text
          color="textBody"
          weight={700}
          size="small"
          style={{ lineHeight: '18px' }}
        >{t('Amount to sell')}
        </Text>
      </Box>
      
    </Box>
  );
};
export default ConfirmModalListHeader;