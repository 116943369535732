import styled from 'styled-components';

interface WrapperProps {
  notMinify?: boolean;
  horizontalMode?: boolean;
}

export const Wrapper = styled.button<WrapperProps>`
  border: none;
  background-color: transparent;
  margin-bottom: ${({ theme, horizontalMode }) => (horizontalMode ? '0px' : theme.spacings.spacing16)};
  display: flex;
  flex-direction: row;
  /* margin-left: ${({ theme, notMinify }) => (notMinify ? `${theme.spacings.spacing6}` : '0')}; */
 
`;
export default Wrapper;
