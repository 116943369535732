import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { SuccessModal } from '@common-fe/common-fe';

import PATHS from '@/common/paths';

interface Props {
  close: () => void;
  refetch?: () => void;
}

const ReissuedSuccessfullyModal: React.FC<Props> = ({ refetch, close }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return (
    <SuccessModal
      testId="success_reissued_check"
      visible
      header={t('Check Was Successfully Reissued')}
      buttonText={t('Got It!')}
      onSetVisible={() => {
        close();
        if (refetch) {
          refetch();
        } else {
          queryClient.invalidateQueries(PATHS.GET_CHECKS_LIST);
        }
      }}
    />
  );
};

export default ReissuedSuccessfullyModal;
