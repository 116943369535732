import React from 'react';

import { ContinueInAppPopup } from '@/modules/core/components/ContinueInAppPopup';
import { useGetPartnerAppLinksByQrQuery } from '@/modules/transaction/components/ReimburseMe/UploadFileQRCode/queries';
import useUploadFileByQrLinkStore from '@/modules/transaction/components/ReimburseMe/UploadFileQRCode/store/useUploadFileByQrLink.store';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setPopupAvailable?: (isPopupAvailable: boolean) => void;
}

export const UploadFileByQrLinkAppLinksPopup: React.FC<Props> = ({
  visible,
  setVisible,
  setPopupAvailable,
}) => {
  const { qrId } = useUploadFileByQrLinkStore((state) => state.state);
  const { partnerAppLinks } = useGetPartnerAppLinksByQrQuery({ qrHashId: qrId || '' });

  return (
    <ContinueInAppPopup
      visible={visible}
      setVisible={setVisible}
      setPopupAvailable={setPopupAvailable}
      appLinks={partnerAppLinks}
    />
  );
};
