import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormField } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import { useGetTransactor } from '@/modules/transaction/hooks/useGetTransactor.query';

import {
  BlackText,
  Label,
  StyledContent,
  TextInputWrap,
} from './Provider.styles';

export interface Props {
  providerName?: string,
  providerAddress1?: string,
  providerAddress2?: string,
  providerCity?: string,
  providerState?: string,
  providerZipCode?: string,
  transactorId?: string,
  onCancel(): void;
}

const EMPTY_STATE_TEXT = '-';
const ProviderView: React.FC<Props> = ({
  transactorId,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { formattedData: transactor } = useGetTransactor(transactorId);
  return (
    <>
      <Box background="module" pad="medium" overflow="hidden" round="moduleRound" data-testid="ProviderModal-view-wrapper">
        <StyledContent background="canvas" pad="medium" overflow="auto" round="container1Round">
          <TextInputWrap viewMode label={<Label viewMode>{t('Provider name')}</Label>}>
            <FormField>
              <BlackText data-testid="ProviderModal-providerName-label" weight="bold">{transactor?.providerName || EMPTY_STATE_TEXT}</BlackText>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Provider address 1')}</Label>}>
            <FormField>
              <BlackText data-testid="ProviderModal-providerAddress1-label" weight="bold">{transactor?.providerAddress1 || EMPTY_STATE_TEXT}</BlackText>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Provider address 2')}</Label>}>
            <FormField>
              <BlackText data-testid="ProviderModal-providerAddress2-label" weight="bold">{transactor?.providerAddress2 || EMPTY_STATE_TEXT}</BlackText>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Provider city')}</Label>}>
            <FormField>
              <BlackText data-testid="ProviderModal-providerCity-label" weight="bold">{transactor?.providerCity || EMPTY_STATE_TEXT}</BlackText>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Provider state')}</Label>}>
            <FormField>
              <BlackText data-testid="ProviderModal-providerState-label" weight="bold">{transactor?.providerState || EMPTY_STATE_TEXT}</BlackText>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Provider zip code')}</Label>}>
            <FormField>
              <BlackText data-testid="ProviderModal-providerZipCode-label" weight="bold">{transactor?.providerZipCode || EMPTY_STATE_TEXT}</BlackText>
            </FormField>
          </TextInputWrap>
        </StyledContent>
      </Box>
      <Box direction="row" justify="end" align="center" pad={{ top: 'medium' }}>
        <AppButton
          buttonType="secondary"
          width="140px"
          type="button"
          onClick={onCancel}
        >
          {t('Close')}
        </AppButton>
      </Box>
    </>
  );
};
export default ProviderView;
