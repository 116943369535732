import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { phoneNumberCreator } from '@/utils/modifiers/phoneNumberCreator';

import { PaymentMethod, PaymentMethodDtoPayload, PaymentMethodOwnerType } from './constants';

export const formatPaymentMethod = (paymentMethod: PaymentMethodDtoPayload): PaymentMethod => ({
  id: toString(paymentMethod?.id),
  type: paymentMethod?.payment_method_type,
  isDefault: paymentMethod?.is_default,
  ...paymentMethod?.is_default ? { isChecked: true } : {},
  accountId: toString(paymentMethod?.account_id),
  recipientId: toString(paymentMethod?.recipient_id),
  email: paymentMethod?.email,
  phoneNumber: paymentMethod?.phone_number,
  status: paymentMethod?.payment_method_status,
  accountNumber: paymentMethod?.account_number,
  accountNumberLast4: paymentMethod?.account_number_last4,
  accountDescription: paymentMethod?.account_description,
  address: {
    id: toString(paymentMethod?.payment_address?.id),
    line1: paymentMethod?.payment_address?.line1,
    line2: paymentMethod?.payment_address?.line2,
    line3: paymentMethod?.payment_address?.line3,
    line4: paymentMethod?.payment_address?.line4,
    city: paymentMethod?.payment_address?.city,
    state: {
      id: toString(paymentMethod?.payment_address?.state?.id),
      code: paymentMethod?.payment_address?.state?.code,
      name: paymentMethod?.payment_address?.state?.name,
    },
    zipcode: paymentMethod?.payment_address?.zipcode,
    validated: paymentMethod?.payment_address?.validated,
    country: {
      name: paymentMethod?.payment_address?.country?.name || '',
      alpha2: paymentMethod?.payment_address?.country?.alpha2 || '',
      alpha3: paymentMethod?.payment_address?.country?.alpha3 || '',
    },
  },
  paymentMethodOwner: {
    organizationId: toString(paymentMethod?.payment_method_owner?.organization_id),
    employeeId: toString(paymentMethod?.payment_method_owner?.employee_id),
    providerId: toString(paymentMethod?.payment_method_owner?.provider_id),
    ownerType: toString(
      paymentMethod?.payment_method_owner?.payment_method_owner_type,
    ) as PaymentMethodOwnerType,
  },
  requisites: {
    phoneNumber: paymentMethod?.requisites?.phone_number
      ? phoneNumberCreator(paymentMethod?.requisites?.phone_number)
      : '',
    email: paymentMethod?.requisites?.email,
    cardHolderName: paymentMethod?.requisites?.card_holder_name,
    cardExpirationDate: paymentMethod?.requisites?.card_expiration_date,
    cardLast4: paymentMethod?.requisites?.card_last4,
    cardType: paymentMethod?.requisites?.card_type,
  },
});

export const useGetPaymentMethods = (
  employeeId?: string,
) => {
  const {
    data, isLoading,
  } = useQuery(
    [PATHS.PAYMENT_METHODS, employeeId],
    employeeId ? () => api.get<PaymentMethodDtoPayload[]>(
      PATHS.PAYMENT_METHODS,
      {
        params: { employee_id: employeeId },
      },
    ) : () => null,
  );

  const formattedData = useMemo(
    () => (data?.data || [])
      .map((paymentMethod) => formatPaymentMethod(paymentMethod)),
    [data],
  );

  return {
    isLoading,
    formattedData,
  };
};
