import { useMemo } from 'react';

import { ValuesMap } from '@/common/types';

import { useInvestmentsPendingActivitiesQuery } from '../queries';



export default (investorId?: string) => {
  const { data, isLoading } = useInvestmentsPendingActivitiesQuery(investorId);
  const fullLiquidationMap = useMemo(() => {

    return data?.reduce((acc: ValuesMap<boolean>, curr) => {
      if(curr.isFullLiquidation) {
        return {
          ...acc,
          [curr.symbol]: true
        };
      }
      return acc;
    }, {});

  }, [data]);
  return {
    data,
    isLoading,
    fullLiquidationMap
  };
};
