import React, { useEffect, useState } from 'react';
import { Box, RoundDoneIcon } from '@common-fe/common-fe';

import Confetti from '../Confetti';
const HIDE_TIMEOUT = 5000;

const ConfettiSuccessIcon = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  useEffect(() => {
    setIsDisplayed(true);

    setTimeout(() => {
      setIsDisplayed(false);
    }, HIDE_TIMEOUT);
  }, []);
  if(!isDisplayed) {
    return  (
      <Box fill align='center' justify='center' margin={{bottom: 'm'}} style={{position: 'relative'}}>
       
        <RoundDoneIcon size="48px" color="success" />
      </Box>
    );
  }
  return (
    <Box fill align='center' justify='center' margin={{bottom: 'm'}} style={{position: 'relative'}}>
      <Box style={{
        position: 'absolute',
        height:'1000px',
        minWidth: '1200px',
        top: -350,
      }}>
        <Box>
          <Confetti
            delay={500}
            times={3}
            options={{
              zIndex: -1,
              ticks: 150,
              spread: 120,
              origin: {
                y: 0.65,
              }
            }} />
        </Box>
      </Box>
      <RoundDoneIcon size="48px" color="success" />
    </Box>
  );
};

export default React.memo(ConfettiSuccessIcon);