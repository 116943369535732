import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, CrossIcon, Preloader, RawButton, SearchInput, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useSelectedFundsStore } from '../stores';

import AddMoreItem from './AddMoreItem';
import { useAddMoreFunds } from './hooks';
import { useAddedFundsStore } from './stores';

const Wrapper = styled(Box)`
  position: absolute;
  z-index: 200;
  right: 100%;
  top: -250px;
`;

const BlurWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.popupBg2};
  z-index: 100;
`;

interface TextLabelProps {
  children: React.ReactNode;
}
const TextLabel: React.FC<TextLabelProps> = ({ children }) => (
  <div>
    <Box direction="row" margin={{ left: 'xs', bottom: 'xs' }} height="24px" align="center">
      <Text size="small" color="textSecondary">
        {children}
      </Text>
    </Box>
  </div>
);

interface Props {
  onClose: () => void;
  accountId: string;
  excludeIds: string[];
}
const AddMoreFundsContent: React.FC<Props> = ({ onClose, accountId, excludeIds = [] }) => {
  const addedFunds = useAddedFundsStore((state) => state.funds);
  const addedIds = useMemo(() => addedFunds.map((item) => item.id), [addedFunds]);
  const {
    data,
    total,
    isLoading,
    searchString,
    setSearchString,
    handleToggleItem,
    selectedData,
    isSearchMode,
    isSelectedMode,
  } = useAddMoreFunds({
    accountId: accountId,
    excludedIds: [...excludeIds, ...addedIds],
  });

  const { t } = useTranslation();

  const handlePushFund = useAddedFundsStore((state) => state.pushFunds);
  const handlePushSelectedFunds = useSelectedFundsStore((state) => state.pushSelectedFunds);

  const handleApply = useCallback(() => {
    if (isSelectedMode) {
      handlePushSelectedFunds(selectedData.map((fund) => fund.id));
    }
    handlePushFund(
      selectedData.map((item) => ({
        ...item,
        isHighlighted: true,
      }))
    );
    onClose();
  }, [handlePushFund, onClose, selectedData, handlePushSelectedFunds, isSelectedMode]);
  return (
    <>
      <Wrapper
        direction="column"
        width={{ min: 'medium' }}
        background="canvas"
        justify="between"
        elevation="active"
        round="container2Round"
      >
        <Box pad="m" direction="column">
          <Box direction="row" align="center" justify="between">
            <Text size="medium" weight={700} margin={{ right: 'xs' }}>
              {t('Funds')}: {total}
            </Text>
            <RawButton onClick={onClose}>
              <Box width="l" height="l">
                <CrossIcon color="iconPrimary" size="14px" />
              </Box>
            </RawButton>
          </Box>
          <Box margin={{ top: 'm' }}>
            <SearchInput
              autoFocus
              onChange={setSearchString}
              value={searchString}
              placeholder={t('Search')}
            />
          </Box>
        </Box>

        <Box
          direction="column"
          height="324px"
          background="module"
          pad={{ vertical: 's', horizontal: 'xs' }}
          border={{ size: 'small', side: 'horizontal', color: 'border2' }}
          style={{ overflow: 'auto' }}
        >
          {isLoading && (
            <Box fill>
              <Preloader />
            </Box>
          )}
          {isSelectedMode && (
            <TextLabel>
              {t('Selected:')} {selectedData.length}
            </TextLabel>
          )}

          {selectedData.map((item) => (
            <AddMoreItem
              key={item.id}
              link={item.link}
              logo={item.logo}
              stockCode={item.stockCode}
              value={true}
              onChangeValue={() => handleToggleItem(item.id)}
              title={item.title}
            />
          ))}
          <TextLabel>
            {isSearchMode || !isSelectedMode ? t('Results:') : t('Others:')} {data.length}
          </TextLabel>

          {data.map((item) => (
            <AddMoreItem
              key={item.id}
              link={item.link}
              logo={item.logo}
              status={item.status}
              stockCode={item.stockCode}
              value={false}
              onChangeValue={() => handleToggleItem(item.id)}
              title={item.title}
            />
          ))}
        </Box>

        <Box direction="row" align="center" justify="center" pad={{ vertical: 'm' }}>
          <Box margin={{ right: 'xs' }}>
            <AppButton onClick={onClose} buttonType="secondary" width="130px">
              {t('Cancel')}
            </AppButton>
          </Box>
          <Box margin={{ left: 'xs' }}>
            <AppButton onClick={handleApply} width="130px">
              {t('Add')}
            </AppButton>
          </Box>
        </Box>
      </Wrapper>
      <BlurWrapper></BlurWrapper>
    </>
  );
};
export default AddMoreFundsContent;
