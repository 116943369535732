import { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';

import { Roles } from '@/common/constants';
import { VERIFICATION_LIMIT_KEY } from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/VerifyMyCardModal/VerifyMyCardModal.constants';
import { CIP_STORAGE_KEY } from '@/modules/investments/cip/hooks/useFailedCIPStorage';

import { clearLocalStorage } from './clearLocalStorage';

interface RefreshToken {
  'custom:permissions': string,
  'custom:role': Roles,
  'custom:person_id': string,
  'custom:employee_id': string,
  'custom:organization_id': string,
  'custom:linked_employees': string,
}
const ACCESS_TOKEN_KEY = 'id_token';
export const SEEN_KEY = 'seen';
interface StringMap {
  [key: string]: string;
}
const EXCEPT_CLEAR_KEYS = [VERIFICATION_LIMIT_KEY, CIP_STORAGE_KEY];
export default class Token {
  private static readonly LOCAL_STORAGE_TOKEN = 'token';

  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

  private static readonly LOCAL_STORAGE_EXPIRES_IN = 'expires_in';

  private static readonly LOCAL_USER_NAME = 'user_name';

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public static getAuthentication(): AxiosRequestConfig {
    const token = this.getToken();
    if (!token) {
      return {};
    }
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  public static storeToken(token: string): void {
    localStorage.setItem(Token.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      Token.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken,
    );
  }

  public static storeExpiresIn(expiresIn: number): void {
    localStorage.setItem(Token.LOCAL_STORAGE_EXPIRES_IN, `${expiresIn}`);
  }

  public static storeUserName(userName: string): void {
    localStorage.setItem(Token.LOCAL_USER_NAME, userName);
  }

  public static clear(): void {
    const savedValues = EXCEPT_CLEAR_KEYS.reduce(
      (result: StringMap, item) => {
        const value = localStorage.getItem(item);
        return {
          ...result,
          [item]: value || '',
        };
      }, {},
    );

    clearLocalStorage();
    localStorage.removeItem(Token.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(Token.LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.setItem(SEEN_KEY, 'true');
    Object.keys(savedValues).forEach((key) => {
      if (savedValues[key]) {
        localStorage.setItem(key, savedValues[key] || '');
      }
    });
  }

  public static getRefreshToken(): string | null {
    return localStorage.getItem(Token.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getUserName(): string | null {
    return localStorage.getItem(Token.LOCAL_USER_NAME);
  }

  public static getToken() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let accessToken = urlParams.get(ACCESS_TOKEN_KEY);

    const { hash, search, pathname } = window.location;
    if (!accessToken) {
      const accessTokenPart = hash.split('&').find((part) => (part.startsWith(ACCESS_TOKEN_KEY))) || '';
       
      accessToken = accessTokenPart.split('=')[1];
    }
    if (accessToken) {
      localStorage.setItem(Token.LOCAL_STORAGE_TOKEN, accessToken);
      window.location.replace(pathname + search);
      return accessToken;
    }
    return localStorage.getItem(Token.LOCAL_STORAGE_TOKEN);
  }

  public static getDecodedToken(): RefreshToken | null {
    const token = Token.getToken();
    if (!token) {
      return null;
    }
    return jwt_decode(token);
  }
}
