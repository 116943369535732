import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  RoundDoneIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

import { useStore } from '../store/useSuccessRecurringContributionModal.store';

const headerText = 'Setup Complete';
const description = 'Funds will be pulled on the first scheduled date.';

export const SuccessRecurringContributionModal = () => {
  const { t } = useTranslation();
  const visible = useStore((store) => store.modalVisible);
  const onSetVisible = useStore((store) => store.handleChangeVisibility);
  const onSubmit = useStore((store) => store.onSubmit);
  return (
    <ModalWrapper testId="SuccessRecurringContributionModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24', horizontal: 'spacing128' }}>
        <RoundDoneIcon />
        <Text
          data-testid="SuccessRecurringContributionModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {headerText}
        </Text>

        <Text
          data-testid="SuccessRecurringContributionModal_modal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {description}
        </Text>
        <AppButton
          testId="SuccessRecurringContributionModal_modal"
          onClick={onSubmit}
          width="200px"
        >
          {t('Got It!')}
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};
