/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback, useEffect,
  useMemo,   useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CrossIcon,
  Enrollment,
  Inscription,
  Preloader,
  PriceInput,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import AppButton from '@/components/controls/AppButton';
import { ModalWrapper } from '@/components/wrappers';
import EnrollmentItem from '@/modules/transaction/components/EnrollmentItem';
import theme from '@/styles/theme';

import { useInvestmentsValidation } from '../hooks';
import { useInvestmentFundingQuery } from '../queries';
import { useFundingAmountStore } from '../StartInvesting/FundingAmount/stores/useFundingAmount.store';

import ServerErrorModal from './ServerErrorModal';

export const PriceControl = styled(PriceInput)`
  ${() => theme.controls.mediumSize};
  min-height: 40px;
`;

const BUTTON_WIDTH = '130px';

interface Props {
  visible: boolean;
  isSwitchMode?: boolean;
  onSetVisible: (value: boolean) => void;
  currentMyAccount: Enrollment;
  investmentAmount?: number | string;
  buySellMode?: boolean;
  onRefetch?: () => void;
  setInvestmentAmount?: (amount: number) => void;
  onSave?: (value: any) => void;
  
}
const EditAmountModal: React.FC<Props> = ({
  visible,
  currentMyAccount,
  investmentAmount,
  onSetVisible,
  buySellMode,
  onRefetch,
  setInvestmentAmount,
  onSave,
  isSwitchMode,
  
}) => {
  const { t } = useTranslation();

  const [showErrors, setShowErrors] = useState(false);
  const [errorWarningVisible, setErrorWarningVisible] = useState(false);
  const { handleSetValues: handleSetAmountValues } = useFundingAmountStore();
  const [investingAmount, setInvestingAmount] = useState<string>('');
  const {
    handleSave: onSaveInvestmentAmount,
    isLoading,
  } = useInvestmentFundingQuery(
    {
      onSuccess:   (value) => {
        if(setInvestmentAmount) {
          setInvestmentAmount(_.toNumber(investingAmount));
        }
        
        handleSetAmountValues({ fundingAmount: String(value) });
        onSetVisible(false);
        setErrorWarningVisible(false);
        if (onRefetch) onRefetch();
      },
      onError:     () => setErrorWarningVisible(true),
    }    
  );
  const availableToInvest = useMemo(() => {
    if ((currentMyAccount?.available - currentMyAccount?.investmentThreshold) > 0) {
      return currentMyAccount?.available - currentMyAccount?.investmentThreshold;
    }
    return 0;
  }, [currentMyAccount]);
  const { error, setError } = useInvestmentsValidation({
    investingAmount,
    availableToInvest,
    minimumInvestmentAmount: currentMyAccount?.minimumInvestmentAmount,
  });
  const handleMaximumAllowed = useCallback(() => {
    setInvestingAmount(String(availableToInvest));
  }, [availableToInvest]);
  const handleSubmit = useCallback(() => {
    setShowErrors(true);
    if (error) {
      return null;
    }
    if(isSwitchMode && setInvestmentAmount) {
      setInvestmentAmount(_.toNumber(investingAmount));
      onSetVisible(false);
      return;
    }
    if (onSave && buySellMode) {
      onSetVisible(false);
      if (onRefetch) onRefetch();
      return onSave({ fundingAmount: investingAmount });
    }
    return onSaveInvestmentAmount({
      amount: investingAmount
    });
  }, [buySellMode, error, investingAmount, isSwitchMode, onRefetch, onSave, onSaveInvestmentAmount, onSetVisible, setInvestmentAmount]);

  useEffect(() => {
    if (investmentAmount) {
      setInvestingAmount(String(investmentAmount));
    }
  }, [investmentAmount]);

  return (
    <ModalWrapper
      title={t('Editing Funding Amount')}
      visible={visible}
      onSetVisible={onSetVisible}
      testId="EditAmountModal-wrapper-id"
    >
      <Box direction="column">
        <Box direction="column" justify="center" background="module" round="moduleRound" pad="spacing24" margin={{ bottom: 'spacing24' }}>
          <Box direction="row" justify="center">
            {
              currentMyAccount
              && (
                <EnrollmentItem
                  enrollment={currentMyAccount}
                  investingMode
                  isDateless
                  isHoverCardNumber
                />
              )
            }
          </Box>
          <Box
            direction="column"
            pad={{ horizontal: 'spacing24', top: '28px', bottom: '18px' }}
            background="canvas"
            round="container1Round"
            margin={{ top: 'l' }}
            width={{ max: '100%', min: '500px' }}
            data-testid="EnrollmentItem-wrapper"
            border={{ size: 'small', color: 'border2' }}
            elevation="default"
          >
            <Text margin={{ bottom: 'spacing12' }} size="xxlarge">{ t('Funding amount')}</Text>
            <Text margin={{ bottom: 'spacing24' }} size="medium">{t('The amount will be transferred from your cash account to your investment account after you confirm the investment.')}</Text>
            <Box direction="row" align="center">
              <Box width="280px" margin={{ right: 'spacing24' }}>
                <PriceControl
                  name="Funding amount"
                  placeholder={t('Enter $ amount')}
                  onChange={setInvestingAmount}
                  onIconClick={() => setInvestingAmount('')}
                  value={_.toString(investingAmount)}
                  disabled={isLoading}
                  rightIcon={(
                    investingAmount !== '' && (
                      <Box pad={{ left: 'spacing8' }} style={{ cursor: 'pointer' }}>
                        <CrossIcon color="iconPrimary" size="14px" />
                      </Box>
                    )
                  )}
                  autoFocus
                />
              </Box>
              <Inscription onClick={handleMaximumAllowed} cursor="pointer" size="medium" color="textAccent">{t('Maximum Allowed')}</Inscription>
            </Box>
          </Box>
        </Box>
        {error && showErrors && (
          <Box align="end" width="100%" margin={{ bottom: 'spacing24' }}>
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>{t(error)}</Text>
          </Box>
        )}
        <Box direction="row" justify="end">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton
              testId="edit_amount_modal_cancel"
              buttonType="secondary"
              width={BUTTON_WIDTH}
              onClick={() => {
                onSetVisible(false);
                setShowErrors(false);
                setError('');
                setInvestingAmount(String(investmentAmount));
              }}
            >
              {t('Cancel')}
            </AppButton>
          </Box>
          <AppButton
            testId="edit_amount_modal_submit"
            width={BUTTON_WIDTH}
            onClick={handleSubmit}
          >
            {
              isLoading
                ? <Preloader color="white" />
                : t('Submit')
            }
          </AppButton>
        </Box>
      </Box>
      <ServerErrorModal
        visible={errorWarningVisible}
        onSetVisible={setErrorWarningVisible}
        onSendRequest={() => onSaveInvestmentAmount({
          amount: investingAmount
        })}
        isLoading={isLoading}
      />
    </ModalWrapper>
  );
};

export default EditAmountModal;
