import { InvestmentPathType } from '@common-fe/common-fe';
import { AccountStatus } from '@common-fe/common-fe';

import { RiskControlOptions, StockQuoteType } from '@/common/constants';
import { ValuesMap } from '@/common/types';
export enum InvestmentPaths {
  Managed = 'MANAGED',
  Brokerage = 'BROKERAGE',
  SelfDirected = 'SELF_DIRECTED',
}


export interface SelfDirectedInvestmentsQuestionnairePayload {
  position: string;
  networthTotal: string;
  annualIncome: string;
}

export interface ManagedInvestmentsQuestionnairePayload {
  position: string;
  networthTotal: string;
  annualIncome: string;
  employmentType: string;
  broker: string;
  director: string;
  directorOf: string;
  politicallyExposed: string;
  politicallyExposedNames: string;
  toleranceModel: string;
  investmentObjectivesType: InvestmentObjectives;
  investmentExperienceType: InvestmentExperience;
  purposeType: string;
  riskToleranceType: string;
  medicalExpensesCoverType: string;
}

export interface InvestmentDto {
  election: number;
  amount: number;
  fund_name: string;
  side?: string;
  research_url: string;
  image_url: string;
  type: string;
  instrument_id: number;
  status?: InvestmentHoldingStatus;
}
export enum InvestmentsType {
  Equity = 'EQUITY',
  ETF = 'ETF',
  ADR = 'ADR',
  ETN = 'ETN',
  AlternativeAssset = 'ALTERNATIVE_ASSET',
  Crypto = 'CRYPTO',
  Cash = 'CASH',
}
export const InvestmentsTypeText = {
  [InvestmentsType.Equity]:'Equity',
  [InvestmentsType.ETF]:'ETF',
  [InvestmentsType.Crypto]:'Crypto',
  [InvestmentsType.ADR]:'ADR',
  [InvestmentsType.AlternativeAssset]:'Alternative Asset',
  [InvestmentsType.ETN]:'ETN',
  [InvestmentsType.Cash]:'Cash',

};


export enum InvestmentActivityStatus {
  FILLED = 'FILLED',
  EXPIRED = 'EXPIRED',
  PAID = 'PAID',
}

export enum InvestmentActivitySide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export interface InvestmentHolding {
  amount: number;
  election: number;
  name: string;
  image: string;
  increase: number;
  id?: string;
  categoryType?: string;
  pendingAmount: number;
  researchURL: string;
  sharePrice: number;
  shares: number;
  side?: string;
  symbol: string;
  status?: InvestmentHoldingStatus;
  type: InvestmentsType;
  orderType?: InvestmentOrderType;
  isHighlighted?: boolean;
}
export interface InvestmentActivitiesParams {
  investor_id?: number;
  date_time_start?: string | null;
  date_time_end?: string | null;
  page: number;
  size: number;
  sort?: string;
  status?: string;
}
export enum InvestmentHoldingStatus {
  Active = 'ACTIVE',
  NEW = 'NEW',
  Inactive = 'INACTIVE',
}
export interface InvestmentPending {
  amount: number;
  name: string;
  image: string;
  dateTime: string;
  researchURL: string;
  shares: number;
  symbol: string;
  type: InvestmentsType;
  side?: string;
  orderType?: InvestmentOrderType;
  status?: InvestmentHoldingStatus;
  isFullLiquidation?: boolean;
}

export interface Investment {
  election: number;
  amount: number;
  fundName: string;
  researchUrl: string;
  imageUrl: string;
  type: string;
  instrumentId: number;
  status?: InvestmentHoldingStatus;
}

export interface InvestmentActivityDto {
  id: string;
  fund_name: string;
  type: string;
  symbol: string;
  date: string;
  time: string;
  amount: number;
  shares: string;
}

export interface InvestmentActivity {
  id: string;
  fundName: string;
  type: string;
  symbol: string;
  date: string;
  time: string;
  amount: number;
  shares: string;
}
export enum InvestmentOrderType {
  MarketBuy = 'MARKET_BUY',
  MarketSell = 'MARKET_SELL',
  Dividends = 'DIVIDEND',
  Fees = 'FEE'

}
export interface InvestmentActivityDetails {
  name: string;
  type: string;
  symbol: string;
  amount: number;
  shares: number;
  status: InvestmentActivityStatus;
  instrumentStatus?: InvestmentHoldingStatus;
  image?: string;
  orderType?: InvestmentOrderType;
  researchURL?: string;
  dateTime?: string;
  side?: InvestmentActivitySide;
}

export interface InvestmentsActivityDto {
  total: number;
  investmentsActivity: InvestmentActivityDto[];
}

export interface InvestmentStock {
  availableToInvest: number;
  investmentValue: number;
  accountBalance: number;
  lastQuarter: number;
  investmentsStocks: InvestmentStockItem[];
}

export interface InvestmentStockDto {
  available_to_invest: number;
  investment_value: number;
  account_balance: number;
  last_quarter: number;
  investments_stocks: InvestmentStockItemDto[];
}

export interface InvestmentStockItemDto {
  id: string;
  stock_quote: StockQuoteType;
  current_amount: number;
  received_amount: number;
  percentage: string;
}
export enum InvestmentsActivityType {
  Pending = 'Pending',
  Holding = 'Holding',
}
export interface InvestmentStockItem {
  id: string;
  color?: string;
  stockQuote: string;
  amount: number;
  percentage: string;
  isOther?: boolean;
}

export interface Donut {
  value: number;
  color: string;
  symbol: string;
}

export interface SearchInvestmentPortfoliosParams {
  investor_id?: string | null;
  risk_model: RiskControlOptions;
  amount?: number;
  page?: number;
  size?: number;
}

export enum InvestorStatus {
  INVESTOR_CREATED = 'INVESTOR_CREATED',
  INVESTMENT_PATH_SELECTED = 'INVESTMENT_PATH_SELECTED',
  QUESTIONNAIRE_FILLED = 'QUESTIONNAIRE_FILLED',
  INVESTMENT_AMOUNT_SELECTED = 'INVESTMENT_AMOUNT_SELECTED',
  PENDING_EXTERNAL_CREATION = 'PENDING_EXTERNAL_CREATION',
  ACTIVE = 'ACTIVE',
}

export enum InvestmentPositions {
  ACCOUNTANT = 'ACCOUNTANT',
  ACTUARY = 'ACTUARY',
  ADJUSTER = 'ADJUSTER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  ADVERTISER = 'ADVERTISER',
  AGENT = 'AGENT',
  ATC = 'ATC',
  AMBASSADOR = 'AMBASSADOR',
  ANALYST = 'ANALYST',
  APPRAISER = 'APPRAISER',
  ARCHITECT = 'ARCHITECT',
  ARTIST = 'ARTIST',
  ASSISTANT = 'ASSISTANT',
  ATHLETE = 'ATHLETE',
  ATTENDANT = 'ATTENDANT',
  ATTORNEY = 'ATTORNEY',
  AUCTIONEER = 'AUCTIONEER',
  AUDITOR = 'AUDITOR',
  BARBER = 'BARBER',
  BROKER = 'BROKER',
  BUSINESS_EXEC = 'BUSINESS_EXEC',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  CAREGIVER = 'CAREGIVER',
  CARPENTER = 'CARPENTER',
  CASHIER = 'CASHIER',
  CHEF = 'CHEF',
  CHIROPRACTOR = 'CHIROPRACTOR',
  CIVIL = 'CIVIL',
  CLERGY = 'CLERGY',
  CLERK = 'CLERK',
  COMPLIANCE = 'COMPLIANCE',
  CONSULTANT = 'CONSULTANT',
  CONTRACTOR = 'CONTRACTOR',
  COUNSELOR = 'COUNSELOR',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  DEALER = 'DEALER',
  DEVELOPER = 'DEVELOPER',
  DISTRIBUTOR = 'DISTRIBUTOR',
  DOCTOR = 'DOCTOR',
  DRIVER = 'DRIVER',
  ENGINEER = 'ENGINEER',
  EXAMINER = 'EXAMINER',
  EXTERMINATOR = 'EXTERMINATOR',
  FACTORY = 'FACTORY',
  FARMER = 'FARMER',
  FINANCIAL = 'FINANCIAL',
  FISHERMAN = 'FISHERMAN',
  FLIGHT = 'FLIGHT',
  HR = 'HR',
  IMPEX = 'IMPEX',
  INSPECTOR = 'INSPECTOR',
  INTERN = 'INTERN',
  INVESTMENT = 'INVESTMENT',
  INVESTOR = 'INVESTOR',
  IT = 'IT',
  JANITOR = 'JANITOR',
  JEWELER = 'JEWELER',
  LABORER = 'LABORER',
  LANDSCAPER = 'LANDSCAPER',
  LENDING = 'LENDING',
  MANAGER = 'MANAGER',
  MECHANIC = 'MECHANIC',
  MILITARY = 'MILITARY',
  MORTICIAN = 'MORTICIAN',
  NURSE = 'NURSE',
  NUTRITIONIST = 'NUTRITIONIST',
  OFFICE = 'OFFICE',
  PHARMACIST = 'PHARMACIST',
  PHYSICAL = 'PHYSICAL',
  PILOT = 'PILOT',
  POLICE = 'POLICE',
  POLITICIAN = 'POLITICIAN',
  PM = 'PM',
  REP = 'REP',
  RESEARCHER = 'RESEARCHER',
  SAILOR = 'SAILOR',
  SALES = 'SALES',
  SCIENTIST = 'SCIENTIST',
  SEAMSTRESS = 'SEAMSTRESS',
  SECURITY = 'SECURITY',
  SOCIAL = 'SOCIAL',
  TEACHER = 'TEACHER',
  TECHNICIAN = 'TECHNICIAN',
  TELLER = 'TELLER',
  TRADESPERSON = 'TRADESPERSON',
  TRAINER = 'TRAINER',
  TRANSPORTER = 'TRANSPORTER',
  UNDERWRITER = 'UNDERWRITER',
  WRITER = 'WRITER',
}

export enum EmploymentType {
  AGRICULTURE = 'AGRICULTURE',
  MINING = 'MINING',
  UTILITIES = 'UTILITIES',
  CONSTRUCTION = 'CONSTRUCTION',
  MANUFACTURING = 'MANUFACTURING',
  WHOLESALE = 'WHOLESALE',
  RETAIL = 'RETAIL',
  TRANSPORT = 'TRANSPORT',
  INFORMATION = 'INFORMATION',
  FINANCE = 'FINANCE',
  REAL_ESTATE = 'REAL_ESTATE',
  PROFESSIONAL = 'PROFESSIONAL',
  MANAGEMENT = 'MANAGEMENT',
  EDUCATION = 'EDUCATION',
  HEALTH = 'HEALTH',
  ART = 'ART',
  FOOD = 'FOOD',
  PUBLIC = 'PUBLIC',
  WASTE = 'WASTE',
}

export enum InvestmentObjectives {
  NEW = 'NEW',
  INFREQUENT = 'INFREQUENT',
  FREQUENT = 'FREQUENT',
  ACTIVE_DAILY = 'ACTIVE_DAILY',
  LONG_TERM = 'LONG_TERM',
}

export enum InvestmentExperience {
  NONE = 'NONE',
  YRS_1_2 = 'YRS_1_2',
  YRS_3_5 = 'YRS_3_5',
  YRS_5_10 = 'YRS_5_10',
  YRS_10 = 'YRS_10_',
}

export enum PurposeType {
  PAY_MEDICAL_EXPENSES = 'PAY_MEDICAL_EXPENSES',
  GROW_INVESTMENT_ASSETS = 'GROW_INVESTMENT_ASSETS',
}

export enum RiskToleranceType {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  SPECULATIONS = 'SPECULATION',
  HIGH = 'HIGH',
}

export enum MedicalExpensesCoverType {
  INVESTMENTS = 'INVESTMENTS',
  SAVINGS_LEVEL_1 = 'SAVINGS_LEVEL_1',
  SAVINGS_LEVEL_2 = 'SAVINGS_LEVEL_2',
  SAVINGS_LEVEL_3 = 'SAVINGS_LEVEL_3',
  SAVINGS_LEVEL_4 = 'SAVINGS_LEVEL_4',
}

export interface InvestorDto {
  id: number;
  investor_id?: number;
  initial_investment_amount: number;
  amounts_grouped_by_instrument_ids?: ValuesMap<number>;

  pending_sell_trades_amount?: number;
  is_investment_model_switching?: boolean;
  investor_account: {
    id: number;
    external_id: string;
    account_id: number;
    investor_id: number;
    external_number: string;
    balance: number;
    cash_balance: number;
    equity_value: number;
    ria_product_external_id: string;
    ria_user_external_id: string;
    status: AccountStatus;
  };
  account_id: number;
  age: number;
  employee_id: number;
  employment_profile: {
    position: InvestmentPositions;
    employment_type: EmploymentType;
    broker: boolean;
    director_of: string;
    politically_exposed_names: string;
  };
  external_id: number;
  investor_profile: {
    investment_objectives_type: InvestmentObjectives;
    investment_experience_type: InvestmentExperience;
    annual_income: number;
    networth_total: number;
    networth_liquid: number;
    purpose_type: PurposeType;
    medical_expenses_cover_type: MedicalExpensesCoverType;
    tolerance_model: RiskControlOptions;
    risk_tolerance: RiskToleranceType;
    proposed_tolerance_model: RiskControlOptions;
  };
  status: InvestorStatus;
  type: string;
}


// TODO: refactor all optional fields
export interface Investor {
  status?: InvestorStatus; 
  investorBalance: number;
  investorAccountId?: string;
  investorEnrollmentId?: string;
  currentToleranceModel?: RiskControlOptions | string;
  pendingTradesAmount?: number;
  investorAccountStatus?: AccountStatus;
  isInvestmentModelSwitching?: boolean;
  initialInvestmentAmount?: number;
  amountsGroupedByInstrumentIds?:  ValuesMap<number>;

  id?: number;
  investorExternalId?: string | number;
  investorStatus?: InvestorStatus;
  type: InvestmentPaths;
  accountId?: number;
  age?: number;
  investorId: string;
  externalNumber?: string;
  balance?: number;
  cashBalance: number;
  equityValue: number;
  riskProductExternalId?: string;
  riskUserExternalId?: string;
  accountStatus?: AccountStatus;
  employeeId?: number;
  position?: InvestmentPositions;
  employmentType?: EmploymentType;
  broker?: boolean;
  director_of?: string;
  politically_exposed_names?: string;
  accountExternalId?: string;
  investmentObjectivesType?: InvestmentObjectives;
  investmentExperienceType?: InvestmentExperience;
  annualIncome?: number;
  networthTotal?: number;
  purposeType?: PurposeType;
  medicalExpensesCoverType?: MedicalExpensesCoverType;
  toleranceModel?: RiskControlOptions;
  riskTolerance?: RiskToleranceType;
  proposedToleranceModel?: RiskControlOptions;
  employmentProfile?: boolean;
}
