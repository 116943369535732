import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Pagination } from '@/common/types';

import { Service } from '../useServices.query';
export const QUERY_KEY = 'GET_DEDUCTIBLES_SERVICES';

interface DeductibleService {
  id: number;
  name: string;
  category_type: string;
  primary: boolean;
  displayed: boolean;

}

export default (employeeId?: string) => {
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_KEY, employeeId],
    () => api.get<Pagination<DeductibleService>>(PATHS.DEDUCTIBLES_SERVICES(employeeId)), {
      retry: 0,
      enabled: !!employeeId
    }
  );

  const options = useMemo(() => {
    const list = data?.data?.content;
    
    const currentOptions: Service[] = list?.map((item) => ({
      id: `${item.id}`,
      key: `${item.id}`,
      name: item.name,
    })) || [];
    return currentOptions;
  }, [data?.data?.content]);


  const isOptionsExists = useMemo(() => {
    return !!data?.data?.totalElements;
  }, [data?.data?.totalElements]);
  return {
    isOptionsExists,
    options,
    isLoading,
    isSuccess,
  };
};