import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

interface Props {
  status?: 'pending';
  children: React.ReactNode;
}
const StatusWrapper: React.FC<Props> = ({ children, status }) => {
  const color = useMemo(() => {
    switch (status) {
    case 'pending':
      return 'warning';
    default:
      return 'textOnColor';
    }
  }, [status]);

  return (
    <Box
      round="container1Round"
      border={{ size: '1px', color: 'border2' }}
      background="canvas"
      elevation="default"
    >
      <Box
        round="small"
        data-testid={`wrapper-line-${status}`}
        height="2px"
        margin={{ horizontal: 'spacing12', top: '-1px' }}
        background={color}
      />
      {children}
    </Box>
  );
};

export default StatusWrapper;
