import { useCallback } from 'react';

export default () => {
  const useFileDownloadByURL = useCallback((url: string) => {
    const href = url;
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.href = href;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return useFileDownloadByURL;
};
