import React, { useMemo } from 'react';
import { Box, costFormater,Image,NullIcon,Text } from '@common-fe/common-fe';
import _ from 'lodash';

import { FundsPayload } from '../../SelfDirected.types';


interface Props extends FundsPayload {
  investedAmount: number;
  percents: number;
 
}
const ConfirmModalItem: React.FC<Props> = ({ title, stockCode, price = 0,investedAmount, logo, percents}) => {

  const currentMoneyAmount = useMemo(() => {
    return investedAmount * (percents / 100);
  }, [investedAmount, percents]);

  const currentAmount = useMemo(() => {
    return _.floor(currentMoneyAmount / price, 3);
  }, [currentMoneyAmount, price]);
  return (
    <Box 
      direction="row"
      pad={{ vertical: '12px', horizontal: 'l' }}
      background="canvas"
      align='center'
      round="container2Round"
      border={{ size: 'small', color: 'border1' }}
      margin={{ top: 'xs' }}
    >
      <Box>
        {logo ? (
          <Image width="32px" height="32px" data-testid="fund_item-logo" src={logo} />
        ) : (
          <NullIcon color="iconPrimary" size="32px" />
        )}
      </Box>
      <Box
        margin={{ left: 's' }}
        style={{
          flex: 2,
          position: 'relative',
        }}
        direction="column"
      >
        <Box width="100%">
          <Text tip={title} truncate weight={700}>
            {title}
          </Text>
          <Text>{stockCode}</Text>
        </Box>
      </Box>
      <Box width={{min: '80px'}} margin={{ left: 's' }}>
        <Text>{costFormater(price, {isPrecision: true})}</Text>
      </Box>
      <Box width={{min: '80px'}} margin={{ left: 's' }}>
        <Text>{currentAmount}</Text>
      </Box>
      <Box width={{min: '80px'}} margin={{ left: 's' }}>
        <Text color="success" weight={700}>{costFormater(currentMoneyAmount, {
          isPrecision: true
        })}
        </Text>
      </Box>
      <Box width={{min: '80px'}} margin={{ left: 's' }}>
        <Text>{percents}%</Text>
      </Box>
    </Box>
  );
};

export default ConfirmModalItem;