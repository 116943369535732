import React, { useCallback, useMemo, useState } from 'react';

import { ModalWrapper } from '@/components/wrappers';
import { useSnackbar } from '@/modules/core/hooks';

import useCardReplaceModalStore from '../../store/useCardReplaceModal.store';

import { CardReplaceAcceptMessage } from './CardReplaceAcceptMessage';
import CardReplaceFormModal from './CardReplaceFormModal';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  refreshCardList?: () => void;
  modalTitle?: string;
  cardholderName?: string;
}

export const CardReplaceModal: React.FC<Props> = ({
  visible,
  cardholderName,
  onSetVisible,
  refreshCardList,
}) => {
  const [mode, setMode] = useState(false);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { handleReset } = useCardReplaceModalStore();

  const modalTitleWithName = useMemo(() => `Request a Replacement Card for ${cardholderName}`, [cardholderName]);

  const setDoneMode = useCallback(() => {
    if (mode) setMode(false);
    else setMode(true);
  }, [mode]);

  const handleSetVisible = useCallback(() => {
    onSetVisible(false);
    handleReset();
  }, [onSetVisible, handleReset]);

  return (
    <>
      {!mode
        ? (
          <ModalWrapper testId="ModalWrapper" visible={visible} title={modalTitleWithName} onSetVisible={handleSetVisible}>
            <CardReplaceFormModal
              onCancel={onSetVisible}
              setDoneMode={setDoneMode}
            />
          </ModalWrapper>
        )
        : (
          <ModalWrapper visible={visible} onSetVisible={handleSetVisible}>
            <CardReplaceAcceptMessage
              onCancel={onSetVisible}
              setDoneMode={setDoneMode}
              onSnackbar={handleAddPermanentSnackbar}
              refreshCardList={refreshCardList}
              cardholderName={cardholderName}
            />
          </ModalWrapper>
        )}
    </>
  );
};
