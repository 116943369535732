import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { useAuthStore } from '@/modules/user/stores';

interface Address {
  id: number;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipcode: string;
}

interface AddressesResponse {
  addresses: Address[];
}

export const useGetReplaceAddressQuery = () => {
  const { user } = useAuthStore();
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    PATHS.PERSONS_BY_ID(user?.id),
    () => api.get<AddressesResponse>(PATHS.PERSONS_BY_ID(user?.id),
      {
        headers: {
          'X-DETAILS-RESOLVERS': 'ADDRESS',
        },
      }),
  );
  return {
    data,
    address: data?.data.addresses[0],
    isError,
    isLoading,
    isSuccess,
  };
};
