import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CheckFilledIcon,Text } from '@common-fe/common-fe';

interface Props {
  onClose?: () => void;
}

const VerifyBlock: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Box
      direction="row"
      pad={{ vertical: 'spacing8', horizontal: 'spacing12' }}
      round="snackbarRound"
      align="center"
      background="success"
    >
      <Box margin={{ left: 'spacing4', right: 'spacing12' }}>
        <CheckFilledIcon color="iconOnColor" />
      </Box>
      <Box fill>
        <Text color="textOnColor" style={{ lineHeight: '20px' }}>

          {t('Your account has been verified!')}
        </Text>
        <Text color="textOnColor"  style={{ lineHeight: '20px' }}>
          {t('Please login using your credentials.')}
        </Text>
      </Box>
      {/* <RawButton onClick={onClose}>
        <Box margin={{ right: 'spacing4' }}>
          <LargeCloseIcon />
        </Box>
      </RawButton> */}
    </Box>
  );
};

export default VerifyBlock;
