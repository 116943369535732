import { Dependent } from '@/modules/user/components/ManageProfile/components/Dependents/dependents.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  editableDependent?: Dependent;
  handleSetEditableDependent: (dependent: Dependent) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  dependent: undefined,

  handleSetEditableDependent: (dependent: Dependent) =>
    set((state) => ({
      ...state,
      editableDependent: dependent,
    })),

  handleReset: () =>
    set((state) => ({
      ...state,
      dependent: undefined,
    })),
}));
