import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box } from '@common-fe/common-fe';
import _ from 'lodash';

import { ProcessScan } from '@/components/controls/DropScan/ProcessScan';

import { useOcrWarningsStore } from '../ClaimInfo/ClaimWarningBanner/store';
import { ParsedFileByOcr } from '../FileScan/fileScan.types';
import useGetScannedDocumentsQuery from '../FileScan/useGetScannedDocumentQuery';
import useFilesStore from '../store/useFiles.store';

interface Props {
  onNext: () => void;
  onGetScannedDocument: (data: ParsedFileByOcr) => void;
  onCancel?: () => void;
  onStartScanning?: () => void;
}

export const ReUploadFileScan: React.FC<Props> = ({
  onNext, onGetScannedDocument, onCancel, onStartScanning,
}) => {
  const { activeFile, files, setFiles } = useFilesStore((state) => state);

  const [documentsIds, setDocumentsIds] = useState<string[]>([]);
  const { getScannedDocument, cancel } = useGetScannedDocumentsQuery();
  const setOcrWarnings = useOcrWarningsStore((state) => state.handleSetOcrWarnings);
  const setOcrFieldsWarnings = useOcrWarningsStore(
    (state) => state.handleSetFieldsOcrWarnings,
  );
  const handleSetOcrWarningsList = useOcrWarningsStore(
    (state) => state.handleSetOcrWarningsList,
  );

  const getParsedFile = useCallback(async (id: string) => {
    if (onStartScanning) {
      onStartScanning();
    }
    const parsedFile = await getScannedDocument(id);
    if (!_.isEmpty(parsedFile)) {
      onGetScannedDocument(parsedFile);
      setOcrWarnings(parsedFile.warningsFields);
      setOcrFieldsWarnings(parsedFile.warningsFields);
      handleSetOcrWarningsList(parsedFile.warnings);
    }
    onNext();
  }, [getScannedDocument, onGetScannedDocument, setOcrWarnings,
    handleSetOcrWarningsList, setOcrFieldsWarnings, onNext, onStartScanning]);

  const runScanning = useCallback(async () => {
    const ids = files.map((file) => file.id);

    if (files.length) {
      const filesWithId = files
        .map(
          (file) => {
            const id = files
              .find(
                (files) => files.temporalId === file.temporalId,
              )?.id;

            if (id) {
              return { ...file, id };
            }

            return file;
          },
        );

      setFiles(filesWithId);
    }

    setDocumentsIds(ids);
    getParsedFile(ids[activeFile]);
  }, [
    getParsedFile,
    setFiles,
    activeFile,
    files,
  ]);

  useEffect(() => {
    runScanning();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box align="center">
      <ProcessScan
        areFilesUploaded={documentsIds.length === 0}
        onNext={onNext}
        wrapperStyle={{ padding: 0 }}
        processScanWrapperStyle={{ padding: 0 }}
        onClose={() => {
          cancel();
          onCancel && onCancel();
        }}
      />
    </Box>
  );
};
