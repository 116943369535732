// import { ModalWrapper } from '@/components';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Heading,
  Preloader,
  PriceInput,
  StatusWrapper,
  Text,
  WarningLabel,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { Modal } from '@/components';

import ServerErrorModal from '../../modal/ServerErrorModal';
import { AllocationPercentsMap, AutoInvestmentRules } from '../../SelfDirected/SelfDirected.types';
import { AutoInvestmentType } from '../AutoInvestment.types';

import { AutoSelfDirectedAllocationFunds } from './AutoSelfDirectedInvestments/AutoSelfDirectedAllocationFunds';
import { useSetupAutoInvestments, useSetupAutoInvestmentsState } from './hooks';
import { useSetupAutoInvestmentsQuery } from './queries';
import SetupAutoInvestmentsInfo from './SetupAutoInvestmentsInfo';
import SetupAutoInvestmentsValidation from './SetupAutoInvestmentsValidation';
import { useDelistedFundPresentStore } from './stores';
import { SuccessAutoInvestmentModal } from './SuccessAutoInvestmentModal';

const Wrapper = styled(Box)`
  .error-input {
    border-color: ${({ theme }) => theme.colors.danger};
    border-width: 2px;
  }
`;

interface Props {
  defaultValue?: string;
  defaultPercentValues?: AllocationPercentsMap;
  investmentId?: string;
  accountId?: string;
  isEditMode?: boolean;
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  isReactivateMode?: boolean;
  setReactivateMode?: (value: boolean) => void;
}
const SetupAutoInvestmentsModal: React.FC<Props> = ({
  accountId,
  visible,
  onSetVisible,
  isEditMode,
  defaultValue,
  investmentId,
  isReactivateMode,
  setReactivateMode,
  defaultPercentValues,
}) => {
  const isDelistedFundPresent  = useDelistedFundPresentStore((state) => state.isDelistedPresent);
  const [showServerError, setShowServerError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isPercentError, setIsPercentError] = useState(false);
  const [percentValues, setPercentValues] = useState<AllocationPercentsMap>({});
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  const {
    placeholderText,
    toleranceModel,
    investmentThreshold,
    investorAccountId,
    availableBalance,
    minimumInvestmentAmount,
    isSelfDirected,
  } = useSetupAutoInvestments({
    accountId,
  });
  const {
    value, setValue, isValidationError, valueAsNumber,
  } = useSetupAutoInvestmentsState({
    minimumValue: investmentThreshold,
    availableBalance,
  });
  const { mutateAsync: handleSave, isLoading } = useSetupAutoInvestmentsQuery({
    isEditMode,
    investmentId,
  });
  useEffect(() => {
    if (!visible) {
      setValue('');
      setSubmitted(false);
    }
  }, [setValue, visible]);

  const preparedPercentValues = useMemo(() => {
    const result: AutoInvestmentRules[] = [];

    if (Object.keys(percentValues).length) {
      Object.keys(percentValues).forEach((key) => {
        result.push({
          instrument_id: key,
          percentage: percentValues[key],
        });

      });
      return result;
    }

    return undefined;
  } ,[percentValues]);

  const preparedStatus = useMemo(() => {
    if (isEditMode || isReactivateMode) {
      return AutoInvestmentType.Active;
    }

    return undefined;
  }, [isEditMode, isReactivateMode]);

  const handleCancel = useCallback(() => {
    onSetVisible(false);
    if (setReactivateMode) setReactivateMode(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSetVisible]);
  const handleSubmit = useCallback(async () => {
    if(isLoading) {
      return;
    }
    setSubmitted(true);
    if (!isValidationError && !isPercentError) {
      try {
        await handleSave({
          investorAccountId: _.toNumber(investorAccountId || ''),
          amountToKeep: _.toNumber(value),
          autoInvestmentRules: preparedPercentValues,
          ...preparedStatus && { status: preparedStatus },
          schedulerId: investmentId,
        });
        setShowSuccessModal(true);
      } catch {
        setShowServerError(true);
      }
    }
  }, [isLoading, isValidationError, isPercentError, handleSave, investorAccountId, value, preparedPercentValues, preparedStatus, investmentId]);
  const handleCloseSuccessModal = useCallback(() => {
    setShowSuccessModal(false);
    onSetVisible(false);
    if (setReactivateMode) setReactivateMode(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSetVisible]);
  const isShowedValidation = useMemo(() => submitted || !!value, [submitted, value]);
  useEffect(() => {
    if (isEditMode && visible && defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, isEditMode, setValue, visible]);

  const successTexts = useMemo(() => {
    // return ({
    //   header: t(`${costFormater(_.toNumber(value) || 0, true)} trade settlement submitted`),
    //   text: (
    //     <Text>
    //       { t('Trade settlement may take up to 2 days. Automatic Investments were ')}
    //       <Text weight={700}>{t('paused.')}</Text>
    //     </Text>
    //   ),
    // })

    if (isReactivateMode) {
      return ({
        header: t('Automatic Investments Reactivated'),
      });
    }
    if (isEditMode) {
      return ({
        header: t('Automatic Investments Updated'),
      });
    }
    return ({
      header: t('Setup Complete'),
      text: t('Automatic investments have been activated.'),
    });
  }, [isEditMode, t, isReactivateMode]);

  const actionButtonLabel = useMemo(() => {
    if (isEditMode && isReactivateMode) {
      return t('Reactivate');
    }
    if (isEditMode) {
      return t('Update');
    }
    return t('Activate');
  }, [isEditMode, isReactivateMode, t]);
  useEffect(() => {
    if(showSuccessModal || showServerError) {
      onSetVisible(false); 
    }
  }, [onSetVisible, showServerError, showSuccessModal]);
  return (
    <>
      <SuccessAutoInvestmentModal
        header={t(successTexts.header)}
        description={successTexts?.text ? t(successTexts.text) : undefined}
        buttonText={t('Go to Investments Hub')}
        visible={showSuccessModal}
        onSetVisible={handleCloseSuccessModal}
        onSubmit={handleCloseSuccessModal}
      />
      <ServerErrorModal
        visible={showServerError}
        isLoading={isLoading}
        onSetVisible={setShowServerError}
        onSendRequest={handleSubmit}
      />
      <Modal visible={visible} onSetVisible={onSetVisible}>
       
        
        <Box direction="column">
          <Box align="center" justify="center">
            <Heading level={4} textAlign="center" size="medium">
              {isEditMode
                ? t('Modify Automatic Investments')
                : t('Set Up Automatic Investments')}
            </Heading>
          </Box>
          <Box pad={{ horizontal: 'spacing2' }}>
            <Text textAlign="center" size="large" margin={{ top: 'l' }}>
              {t(`Sit back and relax. Anytime your balance exceeds the amount you choose to keep in your HSA,
            the additional funds will automatically move to your investment portfolio.`)}
            </Text>
          </Box>
          <Box round="moduleRound" background="module" margin={{ top: 'l' }} pad="l">
            <Box
              background="canvas"
              elevation="default"
              pad="l"
              round="container1Round"
              border={{ size: 'small', color: 'border2' }}
              margin={{ bottom: toleranceModel.title ? 's' : undefined }}
            >
              <Box direction="row">
                <Box fill>
                  <Box width={{ max: '242px'}}>
                    <Text size="medium" weight={700}>
                      {t('How much would you like to keep in your HSA?')}
                    </Text>
                  </Box>
                </Box>
                <Box fill>
                  <Wrapper width={{ max: 'control' }}>
                    <PriceInput
                      placeholder={placeholderText}
                      className={isShowedValidation && isValidationError ? 'error-input' : ''}
                      name="price"
                      value={value}
                      onChange={setValue}
                    />
                  </Wrapper>
                </Box>
              </Box>
            </Box>
            {toleranceModel.title && (
              <Text size="small" color="textSecondary">
                {t('Current risk model: ')}{' '}
                <Text size="small" weight={700}>
                  {toleranceModel.title}
                </Text>
              </Text>
            )}
            {isShowedValidation && (
              <SetupAutoInvestmentsValidation
                isErrorMode={isValidationError}
                isEditMode={isEditMode}
                investmentThreshold={investmentThreshold}
                availableBalance={availableBalance}
                amountToSave={valueAsNumber}
                minimumInvestmentAmount={minimumInvestmentAmount}
              />
            )}
            {isReactivateMode && isSelfDirected && (
              <Box margin={{ top: 'spacing24' }}>
                <StatusWrapper status="pending">
                  <Box
                    margin="spacing12"
                    pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
                    background={{ color: 'warningContainer' }}
                    border={{ size: '1px', color: 'warningBorder' }}
                    round="container2Round"
                  >
                    <Text
                      data-testId="SetupAutoInvestmentsModal_confirm_message"
                      color="textBody"
                      size="14px"
                    >
                      <WarningLabel
                        data-testId="SetupAutoInvestmentsModal_warning_message"
                      >
                        Reactivating paused auto-investments will resume once the sell order is settled.
                      </WarningLabel>
                    </Text>

                  </Box>
                </StatusWrapper>
              </Box>
            )}
          </Box>
          {isSelfDirected && (
            <AutoSelfDirectedAllocationFunds
              investAmount={parseFloat(value)}
              accountId={accountId || ''}
              onSetPercentError={setIsPercentError}
              onSetValues={setPercentValues}
              initialValues={defaultPercentValues}
              isEditMode={isEditMode}
            />
          )}
          {!isEditMode && (
            <SetupAutoInvestmentsInfo />
          )}
          {isPercentError && submitted && (
            <Box alignSelf="end" margin={{ top: 'l' }}>
              <Text size="medium" color="danger">
                {t('The total percentage must equal 100%')}
              </Text>
            </Box>
          )}
          <Box direction="row" justify="end" margin={{ top: 'l' }}>
            <Box margin={{ right: 's' }}>
              <AppButton buttonType="secondary" onClick={handleCancel} width="130px">
                {t('Cancel')}
              </AppButton>
            </Box>
            <AppButton 
              disabled={isDelistedFundPresent || (submitted && isValidationError)}
              onClick={handleSubmit}
              width="130px">
              {isLoading ? <Preloader /> : actionButtonLabel}
            </AppButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SetupAutoInvestmentsModal;
