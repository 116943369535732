import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  costFormater,
  Hint,
  Preloader,
  SuccessModal,
  Text,
  usePaginationParams,
} from '@common-fe/common-fe';

import { ROUTES } from '@/common';
import routes from '@/common/routes';
import { ConfettiSuccessIcon } from '@/components';
import { useHistory } from '@/modules/core/hooks';

import ServerErrorModal from '../../modal/ServerErrorModal';
import { useInvestmentApproveQuery, useInvestmentFundingQuery, useTradeInvestmentsQuery } from '../../queries';
import AddMoreFunds from '../AddMoreFunds';
import { useDefaultAllocationFunds } from '../hooks';
import { useSwitchInvestmentModelQuery } from '../queries';
import { useFundsList } from '../SelfDirectedFunds/hooks';
import { useSelectedFundsStore } from '../stores';

import ConfirmModal from './ConfirmModal';
import { useSelfDirectedAllocation } from './hooks';
import SelfDirectedAllocationHeader from './SelfDirectedAllocationHeader';
import SelfDirectedAllocationItem from './SelfDirectedAllocationItem';
import TotalPercents from './TotalPercents';

const DEFAULT_PER_PAGE = 50;

interface Props {
  investAmount: number;
  accountId: string;
  investorId?: string;
  isInvestMoreMode?: boolean;
  isSwitchMode?: boolean;
  isErrors?: boolean;
  isSubmitted: boolean;
  onSetSubmitted: (value: boolean) => void;
  onConfirm?: () => void;
}
const MAX_PERCENTAGE = 100;
const SelfDirectedAllocationFunds: React.FC<Props> = ({
  investAmount,
  investorId,
  onConfirm,
  isSwitchMode,
  isErrors,
  isInvestMoreMode,
  accountId,
  isSubmitted,
  onSetSubmitted,
}) => {
  const { t } = useTranslation();
  const [isServerErrorModal, setServerErrorModal] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const { page, perPage } = usePaginationParams(DEFAULT_PER_PAGE);
  const { data, isLoading } = useFundsList({
    accountId: accountId,
    isInvestMoreMode,
    isSwitchMode,
    searchParams: {
      page,
      perPage,
      sortBy: 'name,asc',
    },
  });

  const { defaultFundsIds }  = useDefaultAllocationFunds();
  const selectedIds = useSelectedFundsStore((state) => state.selectedFunds);
  const setSelectedFunds = useSelectedFundsStore((state) => state.setSelectedFunds); 
  
  useEffect(() => {
    if(defaultFundsIds) {
      setSelectedFunds(defaultFundsIds);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFundsIds]);


  const currentSelectedIds = useMemo(() => {
    if (isInvestMoreMode) {
      return data.map((item) => item.id);
    }
    return selectedIds;
  }, [data, isInvestMoreMode, selectedIds]);
  const {
    key,
    checkedIds,
    filteredList,
    filteredListWithValues,
    values,
    handleChange,
    handleReset,
    totalPercents,
    handleCheckItem,
    stocksQuantities,
    percentsArray,
    errorText,
    amountValues,
  } = useSelfDirectedAllocation({
    data,
    investAmount,
    isInvestMoreMode,
    selectedIds: currentSelectedIds,
  });

  const history = useHistory();
  const handleBack = useCallback(() => {
    history.push(`${ROUTES.SELF_DIRECTED(accountId)}?investorId=${investorId}`);
  }, [accountId, history, investorId]);
  const { handleSave: handleApproveFunding, isLoading: isApproveLoading,}  = useInvestmentApproveQuery();
  const { handleSave: handleSaveFunding, isLoading:  isFundingLoading }  = useInvestmentFundingQuery();
  
  const { handleSave: handleTrade, isLoading: isTradeLoading } = useTradeInvestmentsQuery({ isSellMode: false});
  const { handleSave: handleSwitchModel, isLoading: isSwitchLoading  } = useSwitchInvestmentModelQuery(investorId || '');
  const handleNext = useCallback(async () => {
    if(isFundingLoading || isTradeLoading || isApproveLoading || isSwitchLoading) {
      return;
    }
    onSetSubmitted(true);
   
    if (!errorText && !isErrors) {
      try {
        if (!isInvestMoreMode && !isSwitchMode) {
          await handleSaveFunding({
            amount: investAmount,
            amounts: amountValues,
          });
          setConfirmModal(true);
        } else {
          setConfirmModal(true);
        }
      } catch {
        setConfirmModal(false);
        setServerErrorModal(true);
      }
    }
  }, [amountValues, errorText, handleSaveFunding, investAmount,
    isApproveLoading, isErrors, isFundingLoading, isInvestMoreMode, 
    isSwitchLoading,
    isSwitchMode, isTradeLoading, onSetSubmitted]);

  const handleApprove = useCallback(async () => {
    if(isApproveLoading) {
      return;
    }
    if(isSwitchMode) {
      try {
        await handleSwitchModel({
          preOrders: percentsArray,
        });
      } catch {
        setConfirmModal(false);
        setSuccessModal(false);
        setServerErrorModal(true);
        return;
      }
    } else if (isInvestMoreMode) {
      if (investorId) {
        try {
          await handleTrade({
            amount: investAmount,
            preOrders: stocksQuantities,
          });
        } catch {
          setConfirmModal(false);
          setSuccessModal(false);
          setServerErrorModal(true);
          return;
        }
      }
    } else {
      await handleApproveFunding(undefined);
    }
    setConfirmModal(false);
    setSuccessModal(true);
  }, [handleApproveFunding, handleSwitchModel,
    handleTrade, investAmount, investorId,
    isApproveLoading, isInvestMoreMode,
    isSwitchMode, percentsArray, stocksQuantities]);

  const handleCloseSuccess = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    setSuccessModal(false);
    history.push(`${routes.INVESTMENTS}?id=${accountId}`);
  }, [accountId, history, onConfirm]);

  if (isLoading) {
    return <Preloader />;
  }
  return (
    <>
      <ServerErrorModal
        visible={isServerErrorModal}
        onSetVisible={setServerErrorModal}
        onSendRequest={handleNext}
      />
      <SuccessModal
        onSetVisible={handleCloseSuccess}
        icon={<ConfettiSuccessIcon />}
        buttonStyle={{
          zIndex: 100,
        }}
        buttonText={t('Go to Investment Dashboard')}
        helptext={
          isSwitchMode ? (
            <Box direction="column" align="center" justify="center">
              <Text textAlign="center" size="medium">
                {t('Updating your investment model will take 3 - 4 business days to complete.')}
              </Text>
              <Text textAlign="center" size="medium">
                {t(
                  'During that time you will not be able to make changes to your investment portfolio. This includes additional buys or sells.'
                )}
              </Text>
            </Box>
          ) : null
        }
        visible={isSuccessModal}
        onSubmit={handleCloseSuccess}
        header={t('Submitted!')}
      />
      <ConfirmModal
        isSwitchMode={isSwitchMode}
        visible={isConfirmModal}
        percentsMap={values}
        isInvestMoreMode={isInvestMoreMode}
        onSetVisible={setConfirmModal}
        list={filteredListWithValues}
        onSubmit={handleApprove}
        isLoading={isApproveLoading || isFundingLoading || isTradeLoading || isSwitchLoading} 
        investedAmount={investAmount}
      />

      <Box direction="column">
        {!isSwitchMode && (
          <Text size="xxlarge" weight={700} margin={{ bottom: 'm' }}>
            {t('Purchase Allocation')}
          </Text>
        )}
        <Box pad="l" background="module" round="moduleRound">
          <Box margin={{ bottom: 's' }} direction="row" justify="between">
            <Text size="large" margin={{ bottom: 'xs', top: 'xs' }} color="textBody" weight={700}>
              {isInvestMoreMode ? t('Funds') : t('Selected funds')}: {checkedIds.length}
            </Text>
            <AddMoreFunds excludeIds={currentSelectedIds} accountId={accountId} />
          </Box>
          <SelfDirectedAllocationHeader onReset={handleReset} />
          <Box key={key}>
            {filteredList.map((item) => {
              return (
                <SelfDirectedAllocationItem
                  key={item.id}
                  // isOnlyOneAvailable={isOnlyOneAvailable}
                  value={values[item.id]}
                  status={item.status}
                  isInvestMoreMode={isInvestMoreMode}
                  investedAmount={investAmount}
                  onChangeValue={handleChange(item.id)}
                  disabled={!investAmount || !checkedIds.includes(item.id)}
                  checked={checkedIds.includes(item.id)}
                  {...item}
                  onSelect={handleCheckItem}
                />
              );
            })}
          </Box>
          <Box
            direction="row"
            pad={{ vertical: '6px', horizontal: 'l' }}
            background="border1"
            margin={{ top: 'xs' }}
            round="container2Round"
            justify="between"
          >
            <Text size="medium" weight={700}>
              {t('Total')}
            </Text>
            <Box direction="row">
              <Box width="90px">
                <Text weight={700}>
                  {costFormater(investAmount * (totalPercents / 100), {
                    isPrecision: true,
                  })}
                </Text>
              </Box>
              <Box direction="row" align="center" justify="center" width="176px">
                {totalPercents !== MAX_PERCENTAGE ? (
                  <Hint hintElement={<TotalPercents value={investAmount ? totalPercents : 0} />}>
                    <Text textAlign="center" margin={{ horizontal: 'xs' }}>
                      {
                        totalPercents > MAX_PERCENTAGE
                          ? t('The total percentage cannot exceed 100%.')
                          : t('The total percentage must equal 100%.')
                      }
                    </Text>
                  </Hint>
                ) : (
                  <TotalPercents value={investAmount ? totalPercents : 0} />
                )}
              </Box>
            </Box>
          </Box>

          <Box margin={{ top: 's' }}>
            <Text size="medium" color="textSecondary">
              {t(
                '*Actual share price and number of shares will be calculated at the time the trade is executed.'
              )}
            </Text>
          </Box>
        </Box>

        <Box direction="row" justify="end" margin={{ vertical: 'l' }}>
          <Box direction="column">
            {isSubmitted && !!errorText ? (
              <Text color="danger" margin={{ bottom: 's' }}>
                {errorText}
              </Text>
            ) : null}
            <Box direction="row" justify="end">
              {isInvestMoreMode ? (
                <Box margin={{ left: 's' }}>
                  <AppButton
                    disabled={isSubmitted && (!!errorText || isErrors)}
                    width="140px"
                    onClick={handleNext}
                  >
                    {t('Invest')}
                  </AppButton>
                </Box>
              ) : (
                <>
                  <Box>
                    <AppButton buttonType="secondary" width="140px" onClick={handleBack}>
                      {t('Back')}
                    </AppButton>
                  </Box>
                  <Box margin={{ left: 's' }}>
                    <AppButton
                      disabled={isSubmitted && (!!errorText || isErrors)}
                      width="140px"
                      onClick={handleNext}
                    >
                      {isSwitchMode ? t('Submit (2/2)') : t('Next (4/4)')}
                    </AppButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default SelfDirectedAllocationFunds;
