import {
  Box,
  Inscription,
} from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledInscription = styled(Inscription)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textAccent};
  text-decoration: underline;
`;

export const StyledBox = styled(Box)`
  flex: 1;
  max-width: 100%;
  
  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    max-width: 728px;
  }
`;

export const Header = styled.h1`
  ${({ theme }) => theme.fonts.text32Bold};
  color: ${({ theme }) => theme.colors.blockTitle || theme.colors.textTitle};

  line-height: 44px;
`;
export const FormWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.canvas};
  margin-top: ${({ theme }) => theme.spacings.spacing12};
  .small-input {
    width: 180px;
    .date-control, input {
      width: 100%;
    }
  }

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    margin-top: 0px;
  }
`;

export const ClaimFormWrapper = styled(Box)`
  flex-direction: column;

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    flex-direction: row;
  }
`;
