import React, { useCallback, useEffect, useMemo, useState } from 'react';

export const useDetectVerticalScrollShadow = (content: HTMLDivElement | null) => {
  const [scrolledToTheEnd, setScrolledToTheEnd] = useState(false);
  const [startedScrolling, setStartedScrolling] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fullWrapHeight = useMemo(() => content?.offsetHeight, [content, startedScrolling]);
  const onScrollHandler = useCallback((evt: React.UIEvent<HTMLDivElement>) => {
    if (!fullWrapHeight) return;
    if (
      !scrolledToTheEnd
      && Math.round(evt.currentTarget.scrollHeight) === Math.round(fullWrapHeight + evt.currentTarget.scrollTop)
    ) setScrolledToTheEnd(true);

    if (
      scrolledToTheEnd
      && evt.currentTarget.scrollHeight !== fullWrapHeight + evt.currentTarget.scrollTop
    ) setScrolledToTheEnd(false);

    if (
      startedScrolling
      && !evt.currentTarget.scrollTop
    ) setStartedScrolling(false);

    if (
      !startedScrolling
      && evt.currentTarget.scrollTop
    ) setStartedScrolling(true);
  }, [fullWrapHeight, scrolledToTheEnd, startedScrolling]);

  const handleSetHasScroll = useCallback(() => {
    if (content) {
      setHasScroll(Math.round(content.scrollHeight) > Math.round(content.offsetHeight));
    }
  }, [content]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          handleSetHasScroll();
        }
      });
    });

    if (content) {
      handleSetHasScroll();
      observer.observe(content, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [content, handleSetHasScroll]);

  return {
    onScrollHandler,
    scrolledToTheEnd,
    startedScrolling,
    hasScroll,
  };
};
