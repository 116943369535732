import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import AppButton from '@/components/controls/AppButton';
import { useHistory } from '@/modules/core/hooks';

import { useInvestmentsHoldings, useInvestor } from '../hooks';
import { useInvestmentsFlexList } from '../Investments/hooks';

import InvestmentsBreakdown from './InvestmentsBreakdown';

interface Props {
  accountId?: string;
  defaultNode?: React.ReactNode;
}
const InvestmentsBreakdownBanner: React.FC<Props> = ({ accountId, defaultNode }) => {
  const { isLoading, isInvestorAccountExists } = useInvestmentsHoldings(accountId);
  const {
    data: { currentToleranceModel, investorId },
  } = useInvestor(accountId);
  const { pendingsTotal } = useInvestmentsFlexList(accountId || '');
  const { t } = useTranslation();
  const history = useHistory();
  const handleRedirect = useCallback(() => {
    window.scrollTo({
      top: 0,
    });
    history.push(`${ROUTES.INVESTMENTS}?id=${accountId}`);
  }, [accountId, history]);

  if (isInvestorAccountExists) {
    return (
      <Box round="container1Round" direction="column" background="module" pad="l" margin={{ bottom: 'l' }}>
        <Box direction="row" justify="between" align="center" margin={{ bottom: 'm' }}>
          <Text size="large" color="blockTitle" weight={700}>
            {t('Investment Breakdown')}
          </Text>
          <Box>
            <AppButton onClick={handleRedirect}>{t('Manage Investments')}</AppButton>
            {/* <DateRangeDropdown
              disabled
              prefix={t('Period:')}
              className="period-picker"
              onChange={() => null}
              startDate={null}
              endDate={null}
              icon={<CalendarPickerIcon />}
            /> */}
          </Box>
        </Box>
        <InvestmentsBreakdown
          accountId={accountId}
          investorId={investorId}
          toleranceModel={currentToleranceModel}
          isPendingTransactionsPresent={pendingsTotal !== 0}
        />
      </Box>
    );
  }
  if (isLoading) {
    return null;
  }
  return <Box>{defaultNode}</Box>;
};
export default InvestmentsBreakdownBanner;
