import { OptionKey } from '@common-fe/common-fe';

export interface State {
  code?: string;
  id?: string;
  name?: string
}
export interface Country {
  alpha2: string;
  alpha3: string;
  name: string;
}
export interface CustomFieldError {
  [key: string]: {
    message: string,
    value: string;
  }
}
export interface Address {
  city?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  zipcode?: string;
  state?: State;
  country?: Country;
  validated?: boolean;
  id?: string;
  countryId?: string;
}


export interface TableRowOption {
  name: string,
  onClick: (id: string) => void;
}

export interface Pagination<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  total: number;
  size: number;
  number: number;
  totalElements: number;
}
export enum DefaultSortTypesEnum {
  ASC = 'asc',
  DESC = 'desc',
}

// PaginationSortParam need to extend type of sort parameter
export type PaginationSortParam = DefaultSortTypesEnum;
export interface PaginationParams {
  perPage: number;
  page: number;
  searchString: string;
  status?: OptionKey;
  sortBy?: PaginationSortParam;
}



export enum InvestmentsType {
  Dashboard = 'Dashboard',
  Transactions = 'Transactions',
  Documents = 'Documents',
}


export interface DefaultValues {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ValuesMap<T> {
  [key: string]: T
}

export interface ButtonDropdownOption {
  redirectFunc: () => void;
  title: string;
}

export interface RiskOption {
  label: string;
  value: string;
}

export interface AccordionHeaderState {
  disabled?: boolean;
  focus: boolean;
  hover: boolean;
}
