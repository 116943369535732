import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  GreyButton,
  Inscription,
  RoundDoneIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';

import { Step } from './uploadQrFile.types';

const StyledGreyButton = styled(GreyButton)`
  height: 54px;
  background-color: ${({ theme }) => theme.colors.buttonSecondaryContainer};
  -webkit-tap-highlight-color: transparent;

  &:active {
    background-color: #CFD1D3;
  }
`;

interface Props {
  setStep: (step: Step) => void;
}

export const UploadFileByQrLinkSuccess: React.FC<Props> = ({
  setStep,
}) => {
  const { t } = useTranslation();
  return (
    <UploadFileByLinkContentWrapper>
      <Box justify="center" align="center">
        <RoundDoneIcon size="72px" />

        <Box
          margin={{ bottom: '50px', top: 'spacing24' }}
          pad={{ horizontal: 'spacing32' }}
        >
          <Inscription
            size="26px"
            weight={700}
            textAlign="center"
            lineHeight="36px"
            margin={{ bottom: 'spacing12' }}
          >
            {t('Photo uploaded successfully!')}
          </Inscription>

          <Inscription
            size="16px"
            textAlign="center"
            lineHeight="22px"
          >
            {t('Proceed to the desktop version to continue your tasks.')}
          </Inscription>
        </Box>

        <Box width="100%" pad={{ horizontal: 'spacing32' }}>
          <StyledGreyButton testId="UploadFileByQrLinkSuccess" onClick={() => setStep(Step.PROCESS)}>
            <Inscription color="textBody" size="16px" weight={700}>
              {t('Upload Another Photo')}
            </Inscription>
          </StyledGreyButton>
        </Box>
      </Box>
    </UploadFileByLinkContentWrapper>
  );
};
