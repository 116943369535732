import React, { useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  ChevronRightIcon,
  formatSnakeCaseToGeneral,
  Inscription,
  Preloader,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEFAULT_CARD_EXP_DATE_FORMAT } from '@/common/constants';
import useGetClaimsActivitiesQuery from '@/modules/transaction/queries/useGetClaimsActivities.query';
import { ProcessingStatusType, TransactionApprovalCode } from '@/modules/transaction/transaction.types';

import ActivitiesWrapper from './ActivitiesWrapper';

const MIN_LENGTH_SHOWED_ITEMS = 4;
const CLAIM_PROCESSING_STATUSES = [ProcessingStatusType.ActionRequired];
const CLAIM_PROCESSING_SUBSTATUS = TransactionApprovalCode.WAITING_FOR_FUNDS;
const SHOW_MORE_STEP = 10;

const StyledCheckbox = styled(Box)`
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
  > div:nth-child(${SHOW_MORE_STEP}n):not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

interface Props {
  employeeId: string;
  forClaimId: string;
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
}

const Activities: React.FC<Props> = ({
  employeeId,
  selectedIds,
  setSelectedIds,
  forClaimId,
}) => {
  const { t } = useTranslation();
  const [isExtended, setIsExtended] = useState(false);
  const [numberShowedActivities, setNumberShowedActivities] = useState(SHOW_MORE_STEP);
  const { formattedData, isLoading } = useGetClaimsActivitiesQuery(
    employeeId,
    CLAIM_PROCESSING_STATUSES,
    CLAIM_PROCESSING_SUBSTATUS,
  );
  const activities = useMemo(
    () => formattedData
      .filter((claim) => claim.id !== forClaimId)
      .sort((a, b) => dayjs(b.serviceOn).valueOf() - dayjs(a.serviceOn).valueOf()),
    [formattedData, forClaimId],
  );
  const showedActivities = useMemo(
    () => activities.slice(0, numberShowedActivities),
    [activities, numberShowedActivities],
  );
  const isDeselect = useMemo(() => selectedIds.length === showedActivities.length,
    [selectedIds.length, showedActivities.length]);

  if (isLoading) return <Box margin={{ top: 'spacing24' }}><Preloader /></Box>;

  if (!activities.length) return null;

  return (
    <Box
      data-testId="employee-choice-claim-activities"
      margin={{ top: 'spacing24' }}
      width="100%"
      align="center"
      justify="center"
      direction="row"
    >
      {isExtended ? (
        <ActivitiesWrapper onClose={() => {
          setIsExtended(false);
          setNumberShowedActivities(SHOW_MORE_STEP);
          setSelectedIds([]);
        }}
        >
          {showedActivities.length >= MIN_LENGTH_SHOWED_ITEMS ? (
            <Box
              onClick={
                () => setSelectedIds(
                  isDeselect
                    ? []
                    : showedActivities.map((activity) => activity.id),
                )
              }
            >
              <Inscription color="button" lineHeight="22px" size="16px">
                {t(isDeselect ? 'Deselect All' : 'Select All')}
              </Inscription>
            </Box>
          ) : null}
          <Box margin={{ top: 'spacing8' }}>
            <StyledCheckbox>
              {showedActivities.map((activity) => (
                <Box key={activity.id}>
                  <Checkbox
                    testId={activity.id}
                    name={`claim_${activity.id}`}
                    id={activity.id}
                    checked={selectedIds.some((id) => id === activity.id)}
                    onChange={(newValue) => {
                      setSelectedIds([
                        ...selectedIds.filter((id) => id !== activity.id),
                        ...newValue ? [activity.id] : [],
                      ]);
                    }}
                  >
                    <Box direction="row">
                      <Inscription size="16px" weight="bold" color="button">{activity.providerName},&nbsp;</Inscription>
                      <Inscription size="16px" lineHeight="22px" color="textTitle">
                        {formatSnakeCaseToGeneral(activity.categoryType)},&nbsp;$
                        {(activity.requestedAmount)?.toFixed(2)},&nbsp;
                        {dayjs(activity?.serviceOn).format(DEFAULT_CARD_EXP_DATE_FORMAT)}
                      </Inscription>
                    </Box>
                  </Checkbox>
                </Box>
              ))}
            </StyledCheckbox>
            {activities.length > numberShowedActivities ? (
              <Box
                margin={{ top: 'spacing8' }}
                onClick={() => setNumberShowedActivities(numberShowedActivities + SHOW_MORE_STEP)}
              >
                <Inscription color="button" lineHeight="22px">
                  {t('Show More')} ({activities.length - numberShowedActivities})
                </Inscription>
              </Box>
            ) : null}
          </Box>
        </ActivitiesWrapper>
      ) : (
        <Box
          data-testId="show-claims"
          onClick={() => setIsExtended(true)}
          direction="row"
          align="center"
        >
          <Inscription
            size="16px"
            weight="bold"
            color="button"
          >
            {t('Apply the same decision to [')}
            {activities.length}
            {t('] similar claims?')}
          </Inscription>
          <Box width="24px" height="24px" direction="row" align="center" justify="center">
            <ChevronRightIcon color="iconSecondary" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Activities;
