import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  capitalizeFirstLetter,
  costFormater,
  FlexList,
  SliderHintIcon,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { RiskControlOptions } from '@/common/constants';
import regexp from '@/common/regexp';
import AppButton from '@/components/controls/AppButton';
import usePortfolioList from '@/modules/investments/hooks/usePortfolioList';
import RiskCard from '@/modules/investments/RiskCard';
import RiskModal from '@/modules/investments/RiskModal';
import theme from '@/styles/theme';

import DisclosureFooter from '../../BuySell/DisclosureFooter';
import ServerErrorModal from '../../modal/ServerErrorModal';
import { ConfirmUpdateRiskPortfolioModal } from '../ConfirmUpdateRiskPortfolioModal';
import { useUpdateRiskModelQuery } from '../queries';
import { SuccessUpdateRiskPortfolioModal } from '../SuccessUpdateRiskPortfolioModal';

const RecommendedModalButton = styled(Box)`
  &:hover {
    color: ${theme.colors.success};
    cursor: pointer;
  }
`;


const StyledTextButton = styled(Text)`
  color: ${({ theme }) => theme.colors.textAccent};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.textAccentHover};
  }
  &:active {
    color: ${({ theme }) => theme.colors.textAccentActive};
  }
`;

const BUTTON_WIDTH = '140px';

interface Props {
  handleGoDashboard: () => void;
  setIsAllowRedirect: (value: boolean) => void;
  handleRetakeQuiz: () => void;
  recommendedRisk?: string;
  currentRisk?: string;
  investmentAmount?: number;
  investorId?: string;
  onBack?: () => void;
}

const PortfolioAllocation: React.FC<Props> = ({
  recommendedRisk,
  setIsAllowRedirect,
  currentRisk,
  investmentAmount,
  handleGoDashboard,
  investorId,
  handleRetakeQuiz,
  onBack,
}) => {
  const { t } = useTranslation();
  const riskOptions = useMemo(
    () => [
      {
        label: t('Conservative Model'),
        value: RiskControlOptions.CONSERVATIVE,
      },
      {
        label: t('Moderately Conservative Model'),
        value: RiskControlOptions.MODERATELY_CONSERVATIVE,
      },
      {
        label: t('Moderate Model'),
        value: RiskControlOptions.MODERATE,
      },
      {
        label: t('Moderately Aggressive Model'),
        value: RiskControlOptions.MODERATELY_AGGRESSIVE,
      },
      {
        label: t('Aggressive Model'),
        value: RiskControlOptions.AGGRESSIVE,
      },
    ], [t],
  );

  const [errorWarningVisible, setErrorWarningVisible] = useState(false);
  const [confirmUpdateModelVisible, setConfirmUpdateModelVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [riskValue, setRiskValue] = useState(recommendedRisk as string || currentRisk as string);
  const riskValueObj = useMemo(() => riskOptions?.find(
    (elem) => elem.value === riskValue,
  ), [riskOptions, riskValue]);
  const {
    formatedList,
    data,
    headers,
    isLoading,
  } = usePortfolioList(
    riskValue as RiskControlOptions || currentRisk,
    investorId,
    investmentAmount,
  );

  const [openRiskModal, setRiskModal] = useState<boolean>(false);
  const { onUpdate, isLoading: updateLoading } = useUpdateRiskModelQuery(
    investorId as string,
    () => setSuccessModalVisible(true),
    () => {
      setConfirmUpdateModelVisible(false);
      setErrorWarningVisible(true);
    },
  );

  const handleUpdateRiskModel = useCallback(async () => {
    if (riskValue) {
      await onUpdate(riskValue);
      setConfirmUpdateModelVisible(false);
    }

    if (errorWarningVisible) {
      setErrorWarningVisible(false);
    }
  }, [errorWarningVisible, onUpdate, riskValue]);

  const handleSubmit = useCallback(() => {
    setConfirmUpdateModelVisible(true);
  }, []);

  const isNewValueSameAsCurrent = useMemo(() => riskValue === currentRisk, [riskValue, currentRisk]);

  const handleApply = useCallback(() => {
    if (isNewValueSameAsCurrent) {
      setConfirmUpdateModelVisible(false);
      onBack ? onBack() : handleGoDashboard();
    } else {
      handleUpdateRiskModel();
    }
  }, [isNewValueSameAsCurrent, onBack, handleGoDashboard, handleUpdateRiskModel]);

  const successModalHeader = useMemo(() => {
    const preparedNewRiskModel =  riskValue
    && riskValue.split(regexp.DASH_SYMBOL).map((item) => capitalizeFirstLetter(item)).join(' ');
    return `Your risk profile has been updated to ${preparedNewRiskModel}`;
  }, [riskValue]);

  const recommendedRiskProfileHeader = useMemo(() => {
    const preparedRecommendedRiskModel = recommendedRisk
    && recommendedRisk.split(regexp.DASH_SYMBOL).map((item) => capitalizeFirstLetter(item)).join(' ');

    return recommendedRisk
      ? (
        <Box direction="row">
          <Text size="16px">
            {t('Based on your risk assessment, we recommend the ')}
          </Text>
          <RecommendedModalButton
            height={{ max: '24px' }}
            border={{ side: 'bottom', color: recommendedRisk !== riskValue ? theme.colors.success : 'transparent', size: '2px' }}
            onClick={() => recommendedRisk && setRiskValue(recommendedRisk)}
          >
            <Text size="large" style={{ lineHeight: '24px' }} margin={{ horizontal: '4px' }} weight="bold">{preparedRecommendedRiskModel}</Text>
          </RecommendedModalButton>
          <Text size="16px">
            {t('portfolio allocation for you.')}
          </Text>
        </Box>
      )
      : (
        <Text size="16px">
          {t('Please use the slider below to select a risk model that aligns with your financial preferences.')}
        </Text>
      );
  }, [recommendedRisk, riskValue, t]);

  useEffect(() => {
    if (riskValue !== currentRisk && recommendedRisk) {
      setIsAllowRedirect(false);
    } else {
      setIsAllowRedirect(true);
    }
  }, [currentRisk, recommendedRisk, riskValue, setIsAllowRedirect]);

  return (
    <Box direction="column" data-testid="portfolio-allocation-wrapper-id">
      <Box flex="grow" pad={{ horizontal: '40px', bottom: '40px' }}>
        <Text size="3xl" color="blockTitle" margin={{bottom: 's'}} data-testid="portfolio-title-wrapper-id">{t('Portfolio Allocation')}</Text>
        <Box>
          {recommendedRiskProfileHeader}
          <Box direction="row">
            <Text size="large">
              {t('If you\'d like a personalized recommendation, take our ')}
              <StyledTextButton size="large" onClick={handleRetakeQuiz}>
                {t('Risk Assessment')}
              </StyledTextButton>
              .
            </Text>
          </Box>
        </Box>
        <Box flex="grow" direction="column" background="module" round="moduleRound" pad="spacing24" margin={{ vertical: 'spacing24' }}>
          <Box direction="row" justify="between" margin={{ bottom: 'spacing24' }}>
            <Text size="large" weight="bold">{t('Confirm your preferred Investment Risk Model')}</Text>
            <Box direction="row" align="center">
              <Text size="large">{t('Invested Amount:')}</Text>
              <Box direction="row">
                <Text size="large" weight="bold" color="textBody" margin={{ horizontal: 'spacing8' }}>{costFormater(investmentAmount, true)}</Text>
              </Box>
            </Box>
          </Box>
          <Box direction="row">
            <Box direction="column" flex={{ grow: 1 }} width={{ max: '580px' }}>

              <Box direction="row">
                <RiskCard
                  riskValue={riskValue}
                  setRiskValue={setRiskValue}
                  setRiskModal={setRiskModal}
                  recommendedRisk={recommendedRisk}
                  currentRisk={currentRisk}
                  labelText={t('Recommended Model')}
                  currentLabelText={t('Current Model')}
                  riskOptions={riskOptions}
                />
              </Box>
            </Box>
            <Box justify="center" align="center" flex={{ grow: 2 }}>
              <Box width={{ max: '170px' }} justify="center" align="center">
                <SliderHintIcon color={theme.colors.iconSecondary} size="72" />
                <Text size="small" textAlign="center" margin={{ top: 's' }} color="textSecondary">{t('Move slider to see allocations by risk profile')}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box background="module" round="moduleRound" margin={{ bottom: 'spacing24' }}>
          <Text margin={{ bottom: 'spacing16', top: 'spacing32', horizontal: 'spacing24' }} size="large" weight="bold">{riskValueObj?.label}{t(` Investment Portfolio: ${data?.length}`)}</Text>
          <FlexList
            rows={formatedList}
            headers={headers}
            loading={isLoading}
            footer={(
              <Box
                direction="row"
                justify="stretch"
                pad={{ horizontal: 'spacing24', vertical: 'spacing6' }}
                margin={{ bottom: 'spacing16', top: 'spacing8' }}
                background="border1"
                round="container1Round"
              >
                <Text style={{ flex: 4 }} weight="bold">{t('Estimated amount to be invested')}:</Text>
                <Text style={{ flex: 1 }} />
                <Text weight="bold" style={{ flex: 1 }}>
                  100%
                </Text>
                <Text weight="bold" style={{ flex: 1 }}>{costFormater(investmentAmount, true)}</Text>
                <Text style={{ flex: 2 }} />
              </Box>
            )}
          />
        </Box>
        <Box direction="row" justify="between" align="center">
          <AppButton
            buttonType="tertiary"
            onClick={handleGoDashboard}
            containerStyle={{ marginRight: theme.spacings.s }}
          >
            {t('Cancel')}
          </AppButton>
          <AppButton
            type="submit"
            onClick={() => handleSubmit()}
            width={BUTTON_WIDTH}
          >
            {t('Apply')}
          </AppButton>
        </Box>
        <DisclosureFooter /> 
      </Box>
      <RiskModal
        visible={openRiskModal}
        onSetVisible={(value) => setRiskModal(value)}
        activeRisk={riskValue}
      />

      <ServerErrorModal
        visible={errorWarningVisible}
        onSetVisible={setErrorWarningVisible}
        onSendRequest={handleUpdateRiskModel}
        isLoading={updateLoading}
      />
      <ConfirmUpdateRiskPortfolioModal
        visible={confirmUpdateModelVisible}
        oldRiskModel={currentRisk || ''}
        newRiskModel={riskValue || currentRisk || ''}
        onSetVisible={setConfirmUpdateModelVisible}
        onSubmit={handleApply}
        isLoading={updateLoading}
      />
      <SuccessUpdateRiskPortfolioModal
        visible={successModalVisible}
        onSetVisible={setSuccessModalVisible}
        header={successModalHeader}
        buttonText="Go to Investment Dashboard"
        onSubmit={handleGoDashboard}
      />
    </Box>
  );
};

export default PortfolioAllocation;
