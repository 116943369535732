import { Option  } from '@common-fe/common-fe';

import { Address,} from '@/common/types';
import { PaymentMethodType } from '@/modules/user/user.types';
import theme from '@/styles/theme';

export enum CheckStatus {
  PENDING = 'PENDING',
  ISSUED = 'ISSUED',
  CLEARED = 'CLEARED',
  VOIDED = 'VOIDED',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  REISSUING = 'REISSUING',
  REISSUED = 'REISSUED',
  VOIDING = 'VOIDING',
  READY = 'READY',
  SENT = 'SENT',
}

export const checkStatusColor = {
  PENDING: theme.colors.checkStatusPending,
  ISSUED: theme.colors.checkStatusIssued,
  CLEARED: theme.colors.checkStatusCleared,
  VOIDED: theme.colors.checkStatusVoided,
  EXPIRED: theme.colors.checkStatusExpired,
  REISSUED: theme.colors.checkStatusReissued,
  VOIDING: theme.colors.checkStatusVoiding,
  EXPIRING: theme.colors.checkStatusExpiring,
  REISSUING: theme.colors.checkStatusReissuing,
  READY: theme.colors.checkStatusReady,
  SENT: theme.colors.checkStatusReady,
};

export enum VoidReason {
  NOT_RECEIVED = 'NOT_RECEIVED',
  WRONG_ADDRESS = 'WRONG_ADDRESS',
  LOST_CHECK = 'LOST_CHECK',
  NO_LONGER_NEEDED = 'NO_LONGER_NEEDED',
  RETURNED_CHECK = 'RETURNED_CHECK',
}

export interface CheckPayload {
  amount?: number;
  check_id?: number;
  claim_ids?: number[];
  current_status?: CheckStatus;
  employee_id?: number;
  employee_name?: string;
  employer_name?: string;
  issued_date?: string;
  pay_to?: string;
  status_date?: string;
  check_address?: Address;
  void_reason?: VoidReason;
  payment_method_id?: number;
  reissue_check_id?: number;
  reissue_payment_method_type?: PaymentMethodType;
}

export interface Check {
  amount?: number;
  id?: string;
  claimIds?: string[];
  status?: CheckStatus;
  employeeId?: string;
  employeeName?: string;
  employerName?: string;
  issuedDate?: string;
  payTo?: string;
  statusDate?: string;
  address?: Address;
  voidReason?: VoidReason;
  paymentMethodId?: string;
  reissueCheckId?: string;
  reissuePaymentMethodType?: PaymentMethodType;
}

export const VOID_REASON_OPTIONS: Option[] = [
  {
    key: VoidReason.NOT_RECEIVED,
    value: VoidReason.NOT_RECEIVED,
    title: 'Not received',
  },
  {
    key: VoidReason.WRONG_ADDRESS,
    value: VoidReason.WRONG_ADDRESS,
    title: 'Wrong address',
  },
  {
    key: VoidReason.LOST_CHECK,
    value: VoidReason.LOST_CHECK,
    title: 'Lost check',
  },
  {
    key: VoidReason.NO_LONGER_NEEDED,
    value: VoidReason.NO_LONGER_NEEDED,
    title: 'No longer needed',
  },
  {
    key: VoidReason.RETURNED_CHECK,
    value: VoidReason.RETURNED_CHECK,
    title: 'Returned check',
  },
];

export enum PaymentStatus {
  VERIFIED = 'VERIFIED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  NEW = 'NEW',
}

export enum BankTypeEnum {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
}

export interface PaymentMethod {
  isChecked?: boolean;
  id: string;
  type?: PaymentMethodType;
  isDefault?: boolean;
  accountId?: string;
  recipientId?: string;
  email?: string;
  phoneNumber?: string;
  status?: PaymentStatus;
  accountNumber?: string;
  accountDescription?: string;
  accountNumberLast4?: string;
  address?: Address;
  paymentMethodOwner?: {
    id?: string;
    ownerType?: PaymentMethodOwnerType;
    employeeId?: string;
    providerId?: string;
    organizationId?: string;
  };
  isVerified?: boolean;
  requisites?: {
    phoneNumber?: string;
    email?: string;
    cardHolderName?: string;
    cardExpirationDate?: string;
    cardLast4?: string;
    cardType?: BankTypeEnum;
  },
}

export enum PaymentMethodOwnerType {
  ORGANIZATION = 'ORGANIZATION',
  EMPLOYEE = 'EMPLOYEE',
  PROVIDER = 'PROVIDER',
}

export interface PaymentMethodDtoPayload {
  id: number;
  payment_method_type?: PaymentMethodType;
  is_default?: boolean;
  account_id?: number;
  recipient_id?: number;
  email?: string;
  phone_number?: string;
  payment_method_status?: PaymentStatus;
  account_number?: string;
  account_description?: string;
  account_number_last4?: string;
  payment_address?: Address;
  payment_method_owner?: {
    organization_id?: number;
    payment_method_owner_type?: string;
    employee_id?: number;
    provider_id?: number;
  };
  requisites?: {
    phone_number?: string;
    email?: string;
    card_holder_name?: string;
    card_expiration_date?: string;
    card_last4?: string;
    card_type?: BankTypeEnum;
  },
}
