import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Preloader } from '@common-fe/common-fe';

import { useSnackbar } from '@/modules/core/hooks';
import { CardActivationModalType } from '@/modules/transaction/components/Cards/Cards.types';
import useCardActivationModalStore from '@/modules/transaction/components/Cards/store/useCardActivationModal.store';
import { useGetCardsQuery } from '@/modules/transaction/hooks/useGetCards.query';
import { useAuthStore } from '@/modules/user/stores';

import { useCardActivationQuery } from '../queries/useCardActivation.query';

import { useRequestedActivationCards } from './useRequestedActivationCards';

export const useCardActivationModal = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { refetch } = useGetCardsQuery(user?.employee?.id);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const [modalType, setModalType] = useState<CardActivationModalType>(
    CardActivationModalType.Activation,
  );
  const {
    modalVisible,
    last4Numbers,
    alternateAccountId,
    memberSequenceIdentifier,
    handleChangeVisibility,
    cardID,
  } = useCardActivationModalStore();

  const { setRequestedActivationCards } = useRequestedActivationCards();

  const { handleActivate, isLoading } = useCardActivationQuery({
    memberSequenceIdentifier,
    alternateAccountId,
  });

  const handleSubmitActivation = useCallback(async () => {
    try {
      await handleActivate();
    } catch {
      setModalType(CardActivationModalType.Error);
      return;
    }

    if (!isLoading) {
      setRequestedActivationCards(cardID);
      setModalType(CardActivationModalType.Processing);
    }
  }, [
    cardID,
    isLoading,
    setRequestedActivationCards,
    handleActivate,
  ]);

  const handleSubmitClose = useCallback(() => {
    refetch();
    handleChangeVisibility(false);
    setModalType(CardActivationModalType.Activation);
    if (modalType === CardActivationModalType.Processing) {
      handleAddPermanentSnackbar({
        text: `Card ${last4Numbers} is activated.`,
        closeIcon: true,
      });
    }
  }, [refetch, handleChangeVisibility, modalType, handleAddPermanentSnackbar, last4Numbers]);

  const handleSubmitTryAgain = useCallback(() => {
    handleSubmitActivation();
  }, [handleSubmitActivation]);

  const currentModalHeader = useMemo(() => {
    if (modalType === CardActivationModalType.Activation) {
      return t(`Activate the Card Ending In ${last4Numbers}?`);
    }
    if (modalType === CardActivationModalType.Processing) {
      return t('This Card Has Been Activated!');
    }
    if (modalType === CardActivationModalType.Error) {
      return t('We Encountered an Unexpected Error and Couldn’t Activate the Card');
    }
    return '';
  }, [last4Numbers, modalType, t]);

  const currentSubmitButtonText = useMemo(() => {
    if (modalType === CardActivationModalType.Activation) {
      return isLoading ? (<Preloader />) : t('Yes, Activate');
    }
    if (modalType === CardActivationModalType.Processing) {
      return t('Close');
    }
    if (modalType === CardActivationModalType.Error) {
      return isLoading ? (<Preloader />) : t('Try Again');
    }
    return '';
  }, [modalType, t, isLoading]);

  const currentSubmitButtonHandler = useMemo(() => {
    if (modalType === CardActivationModalType.Activation) {
      return handleSubmitActivation;
    }
    if (modalType === CardActivationModalType.Processing) {
      return handleSubmitClose;
    }
    if (modalType === CardActivationModalType.Error) {
      return handleSubmitTryAgain;
    }
    return () => null;
  }, [handleSubmitActivation, handleSubmitClose, handleSubmitTryAgain, modalType]);

  return {
    modalVisible,
    modalType,
    currentModalHeader,
    currentSubmitButtonText,
    currentSubmitButtonHandler,
    handleSubmitClose,
  };
};
