import { Option } from '@common-fe/common-fe';
import { toString } from 'lodash';

export const findOptionValueByKey = (options?: Option[], key?: string): string | undefined => {
  if (!options?.length || !key) {
    return undefined;
  }

  const option = options
    .find((option) => toString(option.key).toLocaleLowerCase() === toString(key).toLocaleLowerCase());

  return `${option?.value}`;
};

export const findOptionKeyByValue = (options?: Option[], value?: string): string | undefined => {
  if (!options?.length || !value) {
    return undefined;
  }

  const option = options
    .find((option) => toString(option.value).toLocaleLowerCase() === toString(value).toLocaleLowerCase());

  return `${option?.key}`;
};
