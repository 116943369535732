import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { PHONE_NUMBER_INVALID_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethodOwnerType } from '@/modules/transaction/components/ReimburseMe/types/payment.types';

export enum VenmoFields {
  phoneNumber = 'phoneNumber',
  paymentMethodOwnerType = 'paymentMethodOwnerType',
}

export interface VenmoInfo {
  [VenmoFields.phoneNumber]?: string;
  [VenmoFields.paymentMethodOwnerType]?: PaymentMethodOwnerType;
}

export const useVenmoFields = () => {
  const { t } = useTranslation();
  const editableMethod = useStore((store) => store.editableMethod);
  const fields = useMemo<Field[]>(() => [
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      placeholder: '+1',
      labelStyle: { maxWidth: '276px' },
      testId: 'Venmo',
      defaultValue: editableMethod?.requisites?.phoneNumber || '',
      validator: yup.string()
        .min(17, t(PHONE_NUMBER_INVALID_TEXT))
        .required(t(REQUIRED_TEXT)),
    },
  ], [t, editableMethod]);

  return fields;
};
