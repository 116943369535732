import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import { RecurringContribution } from '../contribution.types';

import { RecurringContributionItem } from './RecurringContributionItem';

interface Props {
  recurringContribution: RecurringContribution;
  setRecurringContribution?: () => void;
}

export const RecurringContributions: React.FC<Props> = ({
  recurringContribution,
  setRecurringContribution,
}) => (
  <Box
    round="moduleRound"
    pad="spacing24"
    background="module"
    data-testid="RecurringContributions_wrapper"
  >
    <Box direction="row" margin={{ bottom: 'spacing12' }}>
      <Inscription size="16px" weight={700}>
        Recurring Contribution
      </Inscription>
    </Box>

    <Box>
      <RecurringContributionItem
        setRecurringContribution={setRecurringContribution}
        recurringContribution={recurringContribution}
      />
    </Box>
  </Box>
);
