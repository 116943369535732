import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Inscription } from '@common-fe/common-fe';

import useMFAStore from '@/modules/user/components/SignIn/MultiFactorAuthentication/stores/useMFA.store';

const RememberMeCheckbox = () => {
  const { t } = useTranslation();
  const { isRememberMe, setIsRememberMe } = useMFAStore();

  const handleSetIsRememberMe = useCallback(() => {
    setIsRememberMe(!isRememberMe);
  }, [setIsRememberMe, isRememberMe]);

  return (
    <Checkbox
      testId="remember_this_device"
      checked={isRememberMe}
      onChange={handleSetIsRememberMe}
    >
      <Inscription onClick={handleSetIsRememberMe} size="medium">
        {t('Remember this device')}
      </Inscription>
    </Checkbox>
  );
};

export default RememberMeCheckbox;
