import React, { useCallback, useMemo } from 'react';
import {
  Box, FlatButton, getFullnameAbbr, Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import currentTheme from '@/styles/theme';

interface Props {
  fullName: string;
  isMe?: boolean;
  userId?: string;
  id: string;
  index?: number;
  onHover?: () => void;
  onBlur?: ()=> void;
  disabled?: boolean;

}

const Wrapper = styled(Box)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentBorder} ;
  }
`;

export const DEPENDERS_COLORS = [currentTheme.colors.subgroup, currentTheme.colors.employee,
  currentTheme.colors.subsidiary, currentTheme.colors.distributor,
  currentTheme.colors.partner, currentTheme.colors.dependent, currentTheme.colors.noAssignee];
// this component is not the same as EmployerLogo from  the flex list component.
const SpendTrackerUser: React.FC<Props> = ({
  id, fullName, isMe, userId, index, onBlur, onHover, disabled,
}) => {
  const history = useHistory();
  const handleOpen = useCallback(() => {
    if (id && !disabled) {
      history.push(ROUTES.SPENDS_BY_ID(id));
    }
  }, [history, id, disabled]);

  const abbr = useMemo(() => getFullnameAbbr(fullName), [fullName]);
  return (
    <FlatButton
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      data-testid={`spend-tracker_user-${id}`}
      onClick={handleOpen}
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <Wrapper
        pad={{ vertical: 'xxs', horizontal: 'xs' }}
        direction="row"
        round="l"
        align="center"
      >
        <Box
          width="32px"
          height="32px"
          align="center"
          round="50%"
          justify="center"
          background={_.get(DEPENDERS_COLORS, `[${index}]`) || 'iconAccent'}
        >
          <Text color="iconOnColor" weight={700}>
            {abbr}
          </Text>
        </Box>
        <Text
          margin={{ left: 'xs' }}
          size="medium"
          weight={700}
        >
          {`${fullName} `}
          {isMe && (
            <Text color="textDisabled">
              (Me)
            </Text>
          )}
        </Text>
      </Wrapper>
    </FlatButton>
  );
};

export default SpendTrackerUser;
