import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, UploadIcon } from '@common-fe/common-fe';

import { OcrWarningType } from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';
import { Step } from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/oneTimeLinkUpload.types';
import useDeactivateOneTimeLinkQuery from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/queries/useDeactivateOneTimeLink.query';
import useUpdateClaimDocumentByHashIdQuery from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/queries/useUpdateClaimDocumentByHashId.query';
import useOneTimeLinkStore from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/store/useOneTimeLink.store';
import { useTransactionOcrWarningsStore } from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/store/useTransactionOcrWarnings.store';
import { FileGetAndUploadButton } from '@/modules/transaction/components/UploadFileByLink/FileGetAndUploadButton';
import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';
import UploadFileByLinkInfoContainer from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkInfoContainer';
import { joinWithCommas } from '@/utils/modifiers/joinWithCommas';

const CLAIM_MISSING_TEXT = 'Claim information';
const BUTTON_WIDTH = '320px';

interface Props {
  onSelectFile: (file: File | undefined) => void;
  setStep: (step: Step) => void;
}

const AdditionalReviewScreen: React.FC<Props> = ({ onSelectFile, setStep }) => {
  const { t } = useTranslation();
  const { documentId, claimId, hashId } = useOneTimeLinkStore((state) => state.state);
  const { deactivateOneTimeLink } = useDeactivateOneTimeLinkQuery(hashId);
  const warnings = useTransactionOcrWarningsStore((store) => store.ocrWarnings);
  const { updateClaimDocumentId } = useUpdateClaimDocumentByHashIdQuery(hashId, claimId);

  const missingFields = useMemo(() => {
    if (!warnings[OcrWarningType.MISSING].length) return undefined;

    const preparedFields = warnings[OcrWarningType.MISSING].map((warning) => warning.fieldLabel);

    return {
      length: preparedFields.length,
      text: joinWithCommas(preparedFields)
    };
  }, [warnings]);

  const handleSubmitWithoutChanges = useCallback(async () => {
    setStep(Step.LOADING);
    try {
      await updateClaimDocumentId(documentId);
      deactivateOneTimeLink();
      setStep(Step.DOCUMENT_ADDED);
    } catch (e) {
      setStep(Step.ERROR);
    }
  }, [updateClaimDocumentId, deactivateOneTimeLink, setStep, documentId]);

  return (
    <UploadFileByLinkContentWrapper>
      <UploadFileByLinkInfoContainer
        title={t(`${missingFields?.text || CLAIM_MISSING_TEXT} not found!`)}
        description={t(`Please take another picture of the receipt making sure
        the image is clear and the ${missingFields?.text || CLAIM_MISSING_TEXT} ${missingFields?.length && missingFields?.length > 1 ? 'are' : 'is'} visible.`)}
        iconType="pending"
      />
      <Box
        width={{ width: '100%', max: BUTTON_WIDTH }}
        margin={{ top: 'spacing24' }}
      >
        <Box>
          <FileGetAndUploadButton
            onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectFile(e?.target?.files?.[0])}
            title={t('Upload Another Document')}
            id="Camera"
            cameraInput
            isRightIconPosition
            icon={<UploadIcon color="canvas" />}
          />
        </Box>
        <Box margin={{ top: 'spacing12' }}>
          <AppButton
            buttonType="secondary"
            onClick={handleSubmitWithoutChanges}
            width="100%"
            size="L"
          >
            {t('Submit Without Changes')}
          </AppButton>
        </Box>
      </Box>
    </UploadFileByLinkContentWrapper>
  );
};

export default AdditionalReviewScreen;
