import { useMemo } from 'react';

import { useQueryParams } from '@/utils/hooks';

const QUERY_KEY = 'menu';
const useHorizontalMenuMode = () => {
  const query = useQueryParams();
  const menu = useMemo(() => query.get(QUERY_KEY), [query]);
  return menu;
};

export default useHorizontalMenuMode;
