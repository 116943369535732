import { BankTypeEnum } from '@/modules/transaction/components/Cards/Cards.types';

export default {
  UPPER_AND_LOWERCASE: /(?=.*[A-Z])(?=.*[a-z])/,
  ALPHANUMERIC: /^[a-z\d\s]+$/i,
  LETTER_AND_NUMBER: /(?=.*[A-Z])(?=.*[0-9])/i,
  SPECIAL_CHARACTER: /[\^$*.[\]{}()?\-"!@#%&/,><’:;|_~`+=]/,
  ONLY_LATIN_AND_NUMBER: /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s,0-9]*)$/gi,
  ALL_NON_NUMERIC: /\D/g,
  DASH_SYMBOL: /_/g,
  MINUS_SYMBOL: /-/g,
  ALL_COMMAS_REGEXP: /,/g,
  INSIDE_CURLED_DOUBLE_BRAKETS: /{{(.+?)}}/g,
  SPACE_SYMBOL: / /g,
  BANK_CARD: {
    [BankTypeEnum.MASTERCARD]: /^(5[1-5]|2(22[1-9]|2[3-9]|[3-6]|7[0-1]|720))/,
    [BankTypeEnum.VISA]: /^4/,
    [BankTypeEnum.AMEX]: /^3[47]/,
  },
  BANK_CARD_FULL: {
    AMEX: /^3[47][0-9]{13}$/,
    MASTERCARD_FULL: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    VISA_FULL: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/,
  },
  DEFAULT_CARD_EXP_DATE: /^\d{2}\s\/\s\d{2}/,
  CARD_SECRET_KEY_: /^\d{3}/,
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  ONLY_NUMBERS: /^\d*$/,
  TWO_DIGITS: /^\d{0,2}$/,
  NOT_NUMBERS_REGEXP: /[^0-9]/g,
  /* eslint-disable-next-line no-useless-escape */
  HEIF: /([a-zA-Z0-9\s_\\.\-\(\):])+(.heif|.heic)$/i,
  FIRST_LETTERS: /\b(\w)/g,
  COMMA_WITHOUT_SPACE: /,(?!\s)/g,
  ONE_NUMBER_ONLY: /(?=.*[0-9])/i,
  ONE_SPECIAL_CHARACTER: /[~`! @#$%^&*()\-={}[\]|\\;:"<>,./?]/,
  FORBIDDEN_CHARACTERS: /[+_]/,
};
