import React from 'react';
import {
  Box, NullEnrollmentsIcon,
  Text, } from '@common-fe/common-fe';

export const MyEnrollmentsNullPlaceholder: React.FC = () => (
  <Box data-testid="MyEnrollmentsNullPlaceholder-testid" background="module" round="moduleRound" pad={{ top: 's' }}>
    <Box align="center">
      <Box margin={{ bottom: 'l' }} width="70px" height="70px">
        <NullEnrollmentsIcon size="72px" color="iconSecondary" />
      </Box>
      <Text data-testid="MyEnrollmentsNullPlaceholder-title" margin={{ bottom: 'spacing12' }} size="16px" style={{ fontWeight: 700 }}>
        There are no Enrollments yet
      </Text>
      <Text size="16px">
        Enrollments will be added soon
      </Text>
    </Box>
  </Box>
);
