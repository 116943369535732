import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import { ThemeWrapper as CommonThemeWrapper } from '@common-fe/common-fe';
import { render } from '@testing-library/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Box,Grommet } from 'grommet';
import { createMemoryHistory } from 'history';

import { elevate } from '@/styles/grommet-theme';
import theme from '@/styles/theme';

dayjs.extend(relativeTime);

export const testingHistory = createMemoryHistory();

export const testingCombinedTheme = {
  ...elevate,
  ...theme,
};

export const testingQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
interface Props {
  children?: React.ReactNode;
}
export const getWrapper: () => React.FC<Props> = () => ({ children }:Props ) => (
  <Router history={testingHistory}>
    <QueryClientProvider client={testingQueryClient}>
      {children}
    </QueryClientProvider>
  </Router>
);

export const DefaultTestContainer: React.FC<Props> = ({ children }) => (
  <Router history={testingHistory}>
    <QueryClientProvider client={testingQueryClient}>
      <CommonThemeWrapper>
        <Grommet theme={testingCombinedTheme}>
          <Box data-testid="outside-test">
            {children}
          </Box>
          <div id="modal-root" />
        </Grommet>
      </CommonThemeWrapper>
    </QueryClientProvider>
  </Router>
);

const renderTheme = (element: React.ReactElement, container?: HTMLElement) => render(element,
  {
    container,
    wrapper: DefaultTestContainer,
  });

export default renderTheme;
