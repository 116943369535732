import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexControlledForm } from '@common-fe/common-fe';

import useVerificationFields from './hooks/useVerificationFields';

const VerificationForm = () => {
  const { t } = useTranslation();
  const fields = useVerificationFields();

  return (
    <FlexControlledForm
      formTitle={t('Verification')}
      fields={fields}
      isThinMode
    />
  );
};

export default VerificationForm;
