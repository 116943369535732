import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { EMAIL_INVALID_TEXT,PHONE_NUMBER_INVALID_TEXT, REQUIRED_TEXT } from '@/common/constants';
import regexp from '@/common/regexp';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethodOwnerType } from '@/modules/transaction/components/ReimburseMe/types/payment.types';

export enum PayPalFields {
  email = 'email',
  phoneNumber = 'phoneNumber',
  paymentMethodOwnerType = 'paymentMethodOwnerType',
}
export interface PayPalInfo {
  [PayPalFields.email]?: string;
  [PayPalFields.phoneNumber]?: string;
  [PayPalFields.paymentMethodOwnerType]?: PaymentMethodOwnerType;
}

export const usePayPalFields = (values: PayPalInfo) => {
  const { t } = useTranslation();
  const editableMethod = useStore((store) => store.editableMethod);

  const validatePhoneNumber = useMemo(
    () => !values.email || (values.phoneNumber && values.phoneNumber.length > 4),
    [values.email, values.phoneNumber],
  );

  const fields = useMemo<Field[]>(() => [
    {
      name: PayPalFields.email,
      type: FieldTypes.Text,
      label: 'Email',
      placeholder: 'Email',
      testId: 'PayPal_email',
      labelStyle: { maxWidth: '276px' },
      value: values.email || '',
      showRequireIcon: true,
      defaultValue: editableMethod?.requisites?.email || '',
      validator: yup.string().test({
        test: (val) => regexp.EMAIL.test(val || ''),
        message: t(EMAIL_INVALID_TEXT),
      }).required(t(REQUIRED_TEXT)),
    },
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      labelStyle: { maxWidth: '276px' },
      placeholder: '+1',
      testId: 'PayPal',
      value: values.phoneNumber || '',
      defaultValue: editableMethod?.requisites?.phoneNumber || '',
      validator: validatePhoneNumber
        ? yup.string()
          .min(17, t(PHONE_NUMBER_INVALID_TEXT))
          .required(t(REQUIRED_TEXT))
        : undefined,
    },
  ], [t, validatePhoneNumber, values, editableMethod]);

  return fields;
};
