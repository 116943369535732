import { storeCreator } from '@/utils/store/store.utils';

import { SnackbarNotification } from '../core.types';

interface State {
  list: SnackbarNotification[];
  handleReset: () => void;
  handleAdd: (item: SnackbarNotification) => void;
  handleMultipleAdd: (items: SnackbarNotification[]) => void;
  handleRemoveById: (id: string) => void;
}

export const useStore = storeCreator<State>((set) => ({
  list: [],
  handleAdd: (item) => set((state) => ({
    list: [...state.list, item],
  })),
  handleMultipleAdd: (items) => set((state) => ({
    list: [...state.list, ...items],
  })),
  handleRemoveById: (id) => set((state) => ({
    list: state.list.filter((item) => item.id !== id),
  })),
  handleReset: () => set((state) => ({
    list: [],
  })),

}));
export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
