import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useOrphanModeStore } from '@/modules/core/store';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';
import { EmployeeView } from '@/modules/user/user.types';

import { formatEmployeeViewData } from './formatData';
import { EmployeeViewPayload } from './payloadTypes';

export const QUERY_KEY = 'getEmployeeView';

const useEmployeeViewDataQuery = (employeeId?: string) => {
  const { linkedEmployee } = useOrphanModeStore();
  const { setEmployee } = useEmployeeStore();
  const currentEmployeeId = linkedEmployee?.employeeId || employeeId;
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_KEY, PATHS.EMPLOYEE_INFO(currentEmployeeId)],
    () => api.get<EmployeeViewPayload>(PATHS.EMPLOYEE_INFO(currentEmployeeId)),
    {
      enabled: !!currentEmployeeId,
    }
  );
  const formattedData = useMemo(
    () => formatEmployeeViewData(data?.data, currentEmployeeId),
    [data, currentEmployeeId]
  );

  const formattedEmployee = useMemo<EmployeeView>(
    () => ({
      id: currentEmployeeId,
      firstName: data?.data?.first_name,
      hireOn: data?.data?.hire_on,
      lastName: data?.data?.last_name,
      preferredEmail: data?.data?.preferred_email,
      preferredName: data?.data?.preferred_name,
      employeeProvidedEmail: data?.data?.employee_provided_email,
      employerProvidedEmail: data?.data?.employer_provided_email,
      claimAutopayType: data?.data?.claim_autopay_type,
      defaultClaimPayeeType: data?.data?.default_claim_payee_type,
      terminationDate: data?.data?.termination_date,
      isElectronicStatementOnly: data?.data?.is_electronic_statement_only,
      isEmployeeEmailPreferred: data?.data?.is_employee_email_preferred,
      isVerifiedEmployeeEmail: data?.data?.is_verified_employee_email,
      isVerifiedPhoneNumber: data?.data?.is_verified_phone_number,
      isSmsNotificationEnabled: data?.data?.is_sms_notification_enabled,
      preferredPhone: {
        id: data?.data?.preferred_phone?.id,
        number: data?.data?.preferred_phone?.number,
        phoneType: data?.data?.preferred_phone?.phone_type,
        preferred: data?.data?.preferred_phone?.preferred,
        secondary: data?.data?.preferred_phone?.secondary,
      },
      phone: {
        number: data?.data?.phone?.number,
        phoneType: data?.data?.phone?.phone_type,
      },
      addresses: data?.data?.addresses?.map((address) => ({
        id: address?.id,
        line1: address?.line1,
        line2: address?.line2,
        city: address?.city,
        state: address?.state,
        zipcode: address?.zipcode,
        countryId: address?.country_id,
        addressType: address?.address_type,
        preferredMailing: address?.preferred_mailing,
        preferredPhysical: address?.preferred_physical,
      })),
    }),
    [data, currentEmployeeId]
  );

  useEffect(() => {
    if (formattedData && isSuccess) {
      setEmployee(formattedEmployee);
    }
    // eslint-disable-next-line
  }, [formattedData]);
  return {
    formattedData,
    rawData: data?.data,
    isLoading,
    isSuccess,
  };
};

export default useEmployeeViewDataQuery;
