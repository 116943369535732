import { baseStoreCreator } from '@/utils/store';

export interface QRCodeInfo {
  qrCodeId?: string;
  documentId?: string;
}

export const DEFAULT_VALUES: QRCodeInfo = {
  qrCodeId: '',
  documentId: '',
};

export const useQRCodeStore = baseStoreCreator<QRCodeInfo>(
  DEFAULT_VALUES,
);

export default useQRCodeStore;
