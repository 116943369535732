import React from 'react';
import { Preloader } from '@common-fe/common-fe';

import { Dashboard } from '@/modules/core/components';
import { useAuthStore } from '@/modules/user/stores';

export const Home: React.FC = () => {
  const { user } = useAuthStore();

  if (!user) return <Preloader />;

  return (
    <Dashboard />
  );
};
