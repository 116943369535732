import { storeCreator } from '@/utils/store/store.utils';

export interface CommunicationSettingsPayload {
  isElectronicStatementOnly: boolean;
}

interface State {
  isElectronicStatementOnly: boolean;
  setIsElectronicStatementOnly: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  isElectronicStatementOnly: false,

  setIsElectronicStatementOnly: (isElectronicStatementOnly: boolean) => set(() => ({
    isElectronicStatementOnly,
  })),
}));

const useCommunicationSettingsStore = () => ({ ...useStore() });

export default useCommunicationSettingsStore;
