import React from 'react';
import { Box } from '@common-fe/common-fe';

import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentType } from '@/modules/transaction/transaction.types';
import { ClaimAutoPayType, ClaimPayee } from '@/modules/user/user.types';

import HowToProcessClaimScreen from '../ProcessingScreen/HowToProcessClaimScreen/HowToProcessClaimScreen';

import NotificationWrapper from './NotificationWrapper';

const PaymentTitle = {
  [PaymentType.REIMBURSEMENT]: 'Reimburse Myself',
  [PaymentType.BILL_PAY]: 'Pay My Provider',
};

interface Props {
  claimId: string;
  paymentType?: PaymentType;
  defaultClaimPayeeType?: ClaimPayee;
  claimAutoPayType?: ClaimAutoPayType;
  providerPaymentMethod?: PaymentMethod;
}

const ProcessingNotification: React.FC<Props> = ({
  claimId,
  paymentType,
  defaultClaimPayeeType,
  claimAutoPayType,
  providerPaymentMethod,
}) => {
  if (paymentType !== PaymentType.REIMBURSEMENT
    && paymentType !== PaymentType.BILL_PAY) {
    return null;
  }

  return (
    <Box margin={{ top: 'spacing12' }} gap="spacing8">
      <NotificationWrapper
        testId="processing-notification"
        title="Claim payee: "
        value={PaymentTitle[paymentType]}
      />
      {defaultClaimPayeeType && (
        <HowToProcessClaimScreen
          notificationMode
          claimId={claimId}
          claimAutoPayType={claimAutoPayType}
          defaultClaimPayeeType={defaultClaimPayeeType}
          providerPaymentMethod={providerPaymentMethod}
        />
      )}
    </Box>
  );
};

export default ProcessingNotification;
