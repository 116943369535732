const getFileInBlob = (url: string) =>
  new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.open('GET', url);
    request.responseType = 'blob';
    request.onload = () => {
      if (request.status === 200) {
        resolve(request.response);
      }
    };
    request.send();
  });

export default getFileInBlob;
