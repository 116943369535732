import React, { useCallback } from 'react';
import {
  Box,
  Field,
  FlexControlledForm,
  Preloader,
} from '@common-fe/common-fe';

// import { useSnackbar } from '@/modules/core/hooks';
import { StyledContent } from './AddCardDependentModal.styles';

export interface DependentFormValue {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  relationship: string;
  dependentId?: string;
}
interface Props {
  type: string | null;
  onChangeValues: (value: DependentFormValue) => void;
  fields: Field[];
  loading: boolean;
  showErrors: boolean;
}

export const AddCardDependentForm = React.forwardRef<
HTMLFormElement,
Props
>(({
  type,
  onChangeValues,
  showErrors,
  fields,
  loading,
}) => {
  // const { handleAddPermanentSnackbar } = useSnackbar();
  const renderForm = useCallback((fieldArr: Field[]) => (
    <FlexControlledForm
      fields={fieldArr}
      editMode={type === 'new'}
      isModalType
      onChangeValues={onChangeValues}
      showError={showErrors}
      isThinMode
    />
  ), [type, onChangeValues, showErrors]);

  if (loading) {
    return (
      <Box
        background="canvas"
        height="310px"
        align="center"
        justify="center"
        round="container1Round"
        margin={{ top: 'spacing24' }}
        data-testid="AddCardDependentModal-form-loader"
      >
        <Preloader />
      </Box>
    );
  }

  return type
    ? (
      <StyledContent background="canvas" round="container1Round" margin={{ top: 'spacing24' }} data-testid="AddCardDependentModal-form">
        {type ? renderForm(fields) : null}
      </StyledContent>
    ) : null;
});
