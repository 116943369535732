import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@common-fe/common-fe';

import { StepperSidebar } from '@/components';
import { Topbar } from '@/modules/core/components';

interface Props {
  children?: React.ReactNode;
  currentStep: number;
  isSwitchMode?: boolean;
}
const SelfDIrectedWrapper: React.FC<Props> = ({ children, currentStep, isSwitchMode }) => {
  const { t } = useTranslation();
  const OPTIONS = useMemo(
    () => isSwitchMode ? [
      { key: 1, value: t('Select Funds') },
      { key: 2, value: t('Portfolio Allocation') },
     
    ]: [
      { key: 1, value: t('Investment Path') },
      { key: 2, value: t('Portfolio Questionnaire') },
      { key: 3, value: t('Select Funds') },
      { key: 4, value: t('Purchase Allocation') },
    ],
    [isSwitchMode, t]
  );
  return (
    <Box direction="column">
      <Box direction="row" flex="grow" fill data-testid="StartInvesting-wrapper">
    
        <StepperSidebar title={isSwitchMode ? t('Investments Model Switch') : t('Start Investing')} options={OPTIONS} step={currentStep} />
        <Box
          direction="row"
          justify="center"
          flex="grow"
          overflow={{ vertical: 'auto' }}
          height={{
            min: '100vh',
          }}
          style={{
            display: 'block',
            position: 'relative',
          }}
        >
          <Box direction="row" justify="center">
            <Box flex="grow" pad={{ horizontal: '40px' }} margin={{ bottom: 'l' }}>
              <Topbar />
            </Box>
          </Box>
          <Box
            direction="column"
            margin={{ horizontal: 'xl' }}
            pad={{ horizontal: 'xs' }}
            style={{ position: 'relative' }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default SelfDIrectedWrapper;
