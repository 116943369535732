import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS, REGEXPS } from '@/common';

import { LinkedEmployeeAccount, LinkedEmployeeAccountPayload } from '../orphanMode.types';

const QUERY_KEY = 'getLinkedAccountsQueryKey';

interface Props {
  enabled?: boolean;
}

const useGetLinkedAccountsQuery = ({ enabled }: Props) => {
  const { data, isLoading, isSuccess } = useQuery([QUERY_KEY], () => api.get(PATHS.GET_LINKED_ACCOUNTS), {
    enabled,
  });

  const formattedData = useMemo(() => {
    if (!data?.data) return [];

    return data?.data?.map(
      (linkedEmployeeResult: LinkedEmployeeAccountPayload): LinkedEmployeeAccount => ({
        employeeId: toString(linkedEmployeeResult.employee_id),
        enrollmentTitle: linkedEmployeeResult.enrollment_title.replace(REGEXPS.COMMA_WITHOUT_SPACE, ', '),
        largeLogoUrl: linkedEmployeeResult.large_logo_url,
        organizationId: toString(linkedEmployeeResult.organization_id),
        organizationName: linkedEmployeeResult.organization_name,
        organizationPath: linkedEmployeeResult.organization_path,
      })
    );
  }, [data]);

  return {
    formattedData,
    isLoading,
    isSuccess,
  };
};

export default useGetLinkedAccountsQuery;
