import { storeCreator } from '@/utils/store/store.utils';

interface State {
  modalVisible: boolean;
  additionalAction: () => void;
  handleSetAdditionalAction: (additionalAction: State['additionalAction']) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  additionalAction: () => {},

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleSetAdditionalAction: (additionalAction) => set((state) => ({
    ...state,
    additionalAction,
  })),

  handleReset: () => set(() => ({
    modalVisible: false,
    additionalAction: () => {},
  })),
}));
