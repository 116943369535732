import { useCallback } from 'react';
import { signOut } from '@aws-amplify/auth';

import { useHistory } from '@/modules/core/hooks';
import Token from '@/utils/handlers/Token';

import { useAuthStore } from '../stores';

const useAuthorization = () => {
  const store = useAuthStore();
  const history = useHistory();

  const handleLogout = useCallback(async () => {
    await signOut();
    Token.clear();
    store.setAuth(false);
    store.setIds();
    history.push('/');
    history.clear();
    store.setUser(null);
    location.reload();
  }, [history, store]);

  return {
    handleLogout,
  };
};

export default useAuthorization;
