import React, { useEffect } from 'react';
import {
  Box,
  FlexControlledForm,
  useDebounceState,
} from '@common-fe/common-fe';

import { InvestmentProfilePayload,useStore } from '../stores/useInvestmentProfile.store';

import { useMedicalExpensesCoverTypeFields } from './hooks';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const MedicalExpensesCoverType: React.FC<Props> = ({ onDirty }) => {
  const fields = useMedicalExpensesCoverTypeFields();
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<InvestmentProfilePayload>>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round" margin={{ bottom: 'l' }} style={{ position: 'relative' }} data-testid="MedicalExpensesCoverType-wrapper">
      <FlexControlledForm
        fields={fields}
        testId="medical_expenses_cover_type_form"
        showError={showErrors}
        editMode
        shouldControlValues
        onChangeValues={setValues}
        onDirty={onDirty}
      />
    </Box>
  );
};
export default MedicalExpensesCoverType;
