import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

export const QUERY_KEY = 'GET_DEPENDENT_NAMES';

interface DependentsNamePayload {
  id: number;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
}


export default (employeeId?: string) => {
  const {
    isLoading, data,
  } = useQuery([
    QUERY_KEY,
    employeeId,
  ],
  () => api.get<DependentsNamePayload[]>(PATHS.DEPENDENTS_NAMES, {
    params: {
      employee_id: employeeId,
    },
  }), {
    cacheTime: 0,
    enabled: !!employeeId,
  });
  const formatedData = useMemo(() => {
    const list = data?.data || [];

    return list.map((item) => ({
      id: `${item.id}`,
      fullName: `${item.first_name || ''} ${item.last_name || ''}`.trim(),
    }));
  }, [data]);

  return {
    isLoading,
    data: formatedData,
  };
};
