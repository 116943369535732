import { Address } from '@/common/types';
import { BankTypeEnum } from '@/modules/transaction/components/Cards/Cards.types';
import { PaymentMethodType } from '@/modules/user/user.types';

export enum PaymentStatus {
  VERIFIED = 'VERIFIED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  NEW = 'NEW',
}

export enum PaymentMethodOwnerType {
  ORGANIZATION = 'ORGANIZATION',
  EMPLOYEE = 'EMPLOYEE',
  PROVIDER = 'PROVIDER',
}

export interface PaymentMethodOwnerDto {
  id: number;
  payment_method_owner_type: PaymentMethodOwnerType;
  employee_id: number;
  organization_id: number;
  provider_id: number;
}

export interface PaymentMethodDtoPayload {
  id: number;
  payment_method_type?: PaymentMethodType;
  is_default?: boolean;
  account_id?: number;
  recipient_id?: number;
  email?: string;
  phone_number?: string;
  payment_method_status?: PaymentStatus;
  account_number?: string;
  account_description?: string;
  account_number_last4?: string;
  payment_address?: Address;
  payment_method_owner?: {
    organization_id?: number;
    payment_method_owner_type?: string;
    employee_id?: number;
    provider_id?: number;
  };
  requisites?: {
    phone_number?: string;
    email?: string;
    card_holder_name?: string;
    card_expiration_date?: string;
    card_last4?: string;
    card_type?: BankTypeEnum;
    card_token?: string;
  },
}

export interface PaymentMethod {
  isChecked?: boolean;
  id: string;
  type?: PaymentMethodType;
  isDefault?: boolean;
  accountId?: string;
  recipientId?: string;
  email?: string;
  phoneNumber?: string;
  status?: PaymentStatus;
  accountNumber?: string;
  accountDescription?: string;
  accountNumberLast4?: string;
  address?: Address;
  paymentMethodOwner?: {
    id?: string;
    ownerType?: PaymentMethodOwnerType;
    employeeId?: string;
    providerId?: string;
    organizationId?: string;
  };
  isVerified?: boolean;
  requisites?: {
    phoneNumber?: string;
    email?: string;
    cardHolderName?: string;
    cardExpirationDate?: string;
    cardLast4?: string;
    cardType?: BankTypeEnum;
  },
}
