import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Text } from '@common-fe/common-fe';

interface Props {
  value: boolean;
  onChangeValue: (value: boolean) => void
}
interface HeaderItemProps {
  children: React.ReactNode;
  testId: string;
}
const HeaderItem: React.FC<HeaderItemProps> = ({testId, children}) => (

  <Text
    color="textBody"
    weight={700}
    size="small"
    style={{ lineHeight: '18px' }}
    data-testid={`fund-list_header_${testId}`}
  >
    {children}
  </Text> 
);
const FundsListHeader: React.FC<Props> = ({ onChangeValue, value }) => {
  const { t } = useTranslation();
  return (
    <Box
      background="legend"
      align="center"
      round="container2Round"
      direction="row"
      pad={{ vertical: '7px', horizontal: '20px' }}
    >
      <Checkbox
        checked={value}
        testId='check-all-funds'
        onChange={onChangeValue}
      />
      <Box style={{ flex: 2 }}>
        <HeaderItem testId="fundName">
          {t('Fund Name')}
        </HeaderItem>
        
      </Box>
     
      <Box width="150px" margin={{ left: '54px' }}>
        <HeaderItem testId="category">
          {t('Category')}
        </HeaderItem>
        
        
      </Box>
      <Box width="150px" margin={{ left: 'l' }}>
        <HeaderItem testId="asset-class">
          {t('Asset class')}
        </HeaderItem>
       
      </Box>
      <Box style={{flex: 2}} />
    </Box>
  );
};

export default FundsListHeader;
