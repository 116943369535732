import { Box, Button } from '@common-fe/common-fe';
import styled from 'styled-components';

export const NOTIFY_CLASS_NAME = 'notify';

export const StyledButton = styled(Button)`
  transition: .1s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.border2};
    border-radius: 3px;
  }
`;

export const StyledShowCount = styled(Box)<{ show: boolean }>`
  transition: .1s;
  transform: scale(${({ show }) => (show ? 1 : 0)});
`;

export const StyledIconWrap = styled(Box)`
  
  &.${NOTIFY_CLASS_NAME} {
    animation: ring 1.5s ease; 
  }
  @keyframes ring {
    0% {
      transform: rotate(35deg);
    }
    12.5% {
      transform: rotate(-30deg);
    }
    25% {
      transform: rotate(25deg);
    }
    37.5% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(15deg);
    }
    62.5% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
