import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { COOKIE_NEVER_EXPIRE } from '@/common/constants';
import { verifyUserRole } from '@/modules/user/components/SignIn/hooks/verifyUserRole';
import { REMEMBER_ME_DEVICE_TOKEN_COOKIE_KEY } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';
import { useAuthStore } from '@/modules/user/stores';
import Token from '@/utils/handlers/Token';

interface Payload {
  code: string;
  session: string;
  isRememberMe?: boolean;
}

interface Response {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  remember_me_device_token?: string;
}

const useVerifyMFACodeQuery = () => {
  const { setAuth } = useAuthStore();

  const { mutateAsync, isError, isLoading } = useMutation((value: Payload) => api.post<Response>(PATHS.VERIFY_MFA_CODE, {
    code: value.code,
    session: value.session,
    is_remember_me: value.isRememberMe,
  }));

  const verifyMFACodeAndSignIn = useCallback(async (value: Payload, userName?: string) => {
    const authResponse = await mutateAsync(value);

    const accessToken = authResponse?.data?.access_token;
    const refreshToken = authResponse?.data?.refresh_token;
    const expiresIn = authResponse?.data?.expires_in;
    const rememberMeDeviceToken = authResponse?.data?.remember_me_device_token;

    const isLoginBlocked = verifyUserRole(accessToken || '');
    if (isLoginBlocked) {
      throw new Error('');
    }

    if (accessToken && refreshToken && expiresIn && userName) {
      Token.storeToken(accessToken);
      Token.storeRefreshToken(refreshToken);
      Token.storeExpiresIn(expiresIn);
      Token.storeUserName(userName);
      setAuth(true);

      if (rememberMeDeviceToken) {
        document.cookie = `${REMEMBER_ME_DEVICE_TOKEN_COOKIE_KEY}=${rememberMeDeviceToken}; max-age=${COOKIE_NEVER_EXPIRE}`;
      }
    }

    return authResponse?.data as Response;
  }, [mutateAsync, setAuth]);

  return {
    verifyMFACodeAndSignIn,
    mutateAsync,
    isError,
    isLoading,
  };
};

export default useVerifyMFACodeQuery;
