import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Field,
  FieldTypes,
  Text,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';

import { useStore as usePersonalInformationStore } from '../../stores/usePersonalInformation.store';

const Label: React.VFC<{ title: string, desc: string }> = ({ title, desc }) => (
  <Box flex={{ grow: 1 }}>
    <Text margin={{ bottom: 'spacing4' }} weight="bold" color="textBody">{title}</Text>
    <Box>
      <Text weight={500} size="small" color="textSecondary">
        {desc}
      </Text>
    </Box>
  </Box>
);

const VALIDATOR = yup.string().nullable();
const TRUE_KEY = 'true';
const POLITICALLY_EXPOSED_KEY = 'politicallyExposed';

export default () => {
  const { t } = useTranslation();
  const defaultFields = usePersonalInformationStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        testId: 'PoliticallyExposedNames-field',
        name: POLITICALLY_EXPOSED_KEY,
        type: FieldTypes.Radio,
        showRequireIcon: true,
        expandItems: true,
        label: (
          <Label
            title={t('Is the account maintained for a current or former Politically Exposed Person or Public Official (includes U.S. and Foreign)?')}
            desc={t('A politically exposed person is someone who has been entrusted with a prominent public function, or who is closely related to such a person.')}
          />
        ),
        placeholder: '',
        options: [
          {
            label: t('Yes'),
            subLabel: t('Please provide the names of that official and official\'s immediate family members (including former spouses), country of political exposure, politically exposed roles and dates/years of appointment and date/year the official left the position (if applicable)'),
            value: TRUE_KEY,
            isWrappedMode: true,
          },
          {
            label: t('No'),
            value: 'false',
          },
        ],
        validator: yup.string().required(REQUIRED_TEXT),
        subFields: [
          {
            name: 'politicallyExposedNames',
            parentValue: TRUE_KEY,
            type: FieldTypes.Text,
            label: '',
            placeholder: 'Enter the names of that official and official\'s immediate family members (including former spouses)',
            validator: yup.string().trim().when(POLITICALLY_EXPOSED_KEY, {
              is: (val: string) => val === TRUE_KEY,
              then: yup.string().required(REQUIRED_TEXT),
              otherwise: VALIDATOR,
            }),
          },
        ],
      },
    ]
  ), [t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => {
        let subFields: Field[] | undefined;
        if (item.subFields) {
          subFields = item?.subFields?.map((subtitleItem) => ({
            ...subtitleItem,
            value: defaultValues[subtitleItem.name] || subtitleItem.value || '',
          })) as Field[];
        }
        return ({
          ...item,
          subFields,
          value: defaultValues[item.name] || item.value || '',
        });
      }) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
