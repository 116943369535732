import React, {
  useCallback,
  useEffect, useMemo,   useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, getDuration, Heading, PaginationBar, Preloader, useForceIntervalUpdate,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { PaginationBarWrapper } from '@/components/wrappers';
import { useHistory } from '@/modules/core/hooks';
import NotificationModal from '@/modules/notification/NotificationDetailsModal/NotificationModal';

import { NotificationType } from '../notification.types';

import useNotificationList from './hooks/useNotificationList';
import { useNotificationsPagination } from './hooks';
import NotificationPlaceholder from './NotificationPlaceholder';
import NotificationsFlexList from './NotificationsFlexList';
import NotificationsListHeader from './NotificationsListHeader';
import NotificationTypePicker from './NotificationTypePicker';


const Wrapper = styled(Box)`
 
`;

const Notifications = () => {
  const [currentType, setCurrentType] = useState<NotificationType>(NotificationType.Active);
  const history = useHistory();
  const {
    page, setPage, perPage, setPerPage, searchParams, setSearchParams,
  } = useNotificationsPagination();
  const {
    activeTotal,
    total,
    rows,
    isLoading,
    dismissedTotal,
    dismissedSearchTotal,
    detailsModalVisible,
    setDetailsModalVisible,
    currentNotification,
    handleDismissById,
    handleRead,
  } = useNotificationList({
    page,
    perPage,
    currentType,
    ...searchParams,
  });
  useEffect(() => {
    if (activeTotal) {
      handleRead();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTotal]);

  const handleGoToPage = useCallback(() => {
    if (currentNotification && currentNotification.page) {
      history.push(currentNotification.page);
    }
  }, [currentNotification, history]);

  const { t } = useTranslation();
  const footer = useMemo(() => {
    if (isLoading) {
      return (
        <Box align="center" justify="center" height={{ min: '190px' }}>
          <Preloader />
        </Box>
      );
    }
    if (currentType === NotificationType.Active) {
      if (activeTotal === 0) {
        return (
          <NotificationPlaceholder>
            {t('There are no active notifications yet')}
          </NotificationPlaceholder>
        );
      }
    }
    if (currentType === NotificationType.Dismissed) {
      if (dismissedTotal === 0) {
        return (
          <NotificationPlaceholder>
            {t('There are no dismissed notifications yet')}

          </NotificationPlaceholder>
        );
      }
      if (dismissedSearchTotal === 0) {
        return (
          <NotificationPlaceholder>
            {t('There are no relevant search results')}

          </NotificationPlaceholder>
        );
      }
      return (
        <PaginationBarWrapper>
          <PaginationBar
            testId="NotificationsList"
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        </PaginationBarWrapper>
      );
    }
    return null;
  }, [
    activeTotal, currentType,
    dismissedSearchTotal, dismissedTotal,
    isLoading, page,
    perPage, setPage,
    setPerPage, t, total]);
  const renderKey = useForceIntervalUpdate(getDuration({ minutes: 1 }));
  return (
    <Wrapper key={`${currentType === NotificationType.Active && renderKey}`} data-testid="Notification_wrapper-id">
      <Box margin={{ bottom: 'm' }} pad={{ bottom: 'xxxs' }}>
        <Heading size="regular" color="textBody" level={1}>
          {t('Notifications')}
        </Heading>
      </Box>

      <NotificationsFlexList
        rows={rows}
        footer={footer}
        currentType={currentType}
        perPage={perPage}
      >
        <NotificationTypePicker
          activeCount={activeTotal}
          dismissedCount={dismissedTotal}
          currentType={currentType}
          onSetCurrentType={setCurrentType}
        />
        <NotificationsListHeader
          total={total}
          searchParams={searchParams}
          onSetSearchParams={setSearchParams}
          currentType={currentType}
        />
        <NotificationModal
          currentType={currentType}
          notification={currentNotification}
          setOpenModal={setDetailsModalVisible}
          openModal={detailsModalVisible}
          isPageLinkHidden={!currentNotification?.page}
          onGoToPage={handleGoToPage}
          dismissNotification={handleDismissById}
        />
      </NotificationsFlexList>
    </Wrapper>

  );
};

export default Notifications;
