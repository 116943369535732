import React, { useMemo } from 'react';
import { Option } from '@common-fe/common-fe';

import { Wrapper } from './Stepper.styles';
import StepperItem, { StepperOption } from './StepperItem';

interface Props {
  activeKey: number | string;
  options: Option[];
  grey?: boolean;
}
const Stepper: React.FC<Props> = ({ activeKey, options, grey }) => {
  const formatedOptions = useMemo<StepperOption[]>(
    () => options.map((item) => ({
      ...item,
      active: false,
      focus: false,
    })),
    [options],
  );

  const currentOptions = useMemo(() => {
    const activeIndex = formatedOptions.findIndex(
      (item) => item.key === activeKey,
    );
    const formated = formatedOptions.map((item, index) => {
      if (index === activeIndex) {
        return {
          ...item,
          focus: true,
        };
      }
      if (index < activeIndex) {
        return {
          ...item,
          active: true,
        };
      }
      return item;
    });
    return formated;
  }, [activeKey, formatedOptions]);
  return (
    <Wrapper>
      {currentOptions.map(({
        key, value, active, focus, hidden,
      }) => (!hidden && (
        <StepperItem
          key={key}
          value={value}
          grey={grey}
          active={active}
          focus={focus}
        />
      )
      ))}
    </Wrapper>
  );
};

export default Stepper;
