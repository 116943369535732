import { storeCreator } from '@/utils/store/store.utils';

import { FundingAmountPayload } from '../BuySell.types';

interface State {
  values: FundingAmountPayload;
  handleSetValues: (value: Partial<FundingAmountPayload>) => void;
  handleReset: () => void;
}

const DEFAULT_VALUES: FundingAmountPayload = {
  fundingAmount: '',
};

export const useFundingAmountStore = storeCreator<State>((set) => ({
  values: DEFAULT_VALUES,
  handleSetValues: (value: Partial<FundingAmountPayload>) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      ...value,
    },
  })),
  handleReset: () => set((state) => ({
    ...state,
    values: DEFAULT_VALUES,
  })),
}));
