import React from 'react';
import { Box } from '@common-fe/common-fe';

import theme from '@/styles/theme';

import { useDefaultContentWrapper } from './hooks/useDefaultContentWrapper';
interface Props {
  children?: React.ReactNode;
}
export const DefaultContentWrapper: React.FC<Props> = ({ children }) => {
  const isVisible = useDefaultContentWrapper();

  if (isVisible) {
    return (
      <Box width={theme.defaultContentWidth} data-testid="DefaultContentWrapper-testid">
        {children}
      </Box>
    );
  }
  return (
    <Box flex width="100%" data-testid="NoDefaultContentWrapper-testid">
      {children}
    </Box>
  );
};
