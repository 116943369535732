import React from 'react';
import { useTranslation } from 'react-i18next';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { ManageProfile } from '@/modules/user/components/ManageProfile';

const ManageAccountPage = () => {
  const { t } = useTranslation();

  useBreadcrumbs([
    {
      key: ROUTES.HOME,
      title: t('Home'),
      value: ROUTES.HOME,
    },
    {
      key: ROUTES.MANAGE_PROFILE,
      title: t('Manage account'),
      value: ROUTES.MANAGE_PROFILE,
    }
  ], true);

  return <ManageProfile />;
};

export default ManageAccountPage;
