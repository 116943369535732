import React from 'react';
import { Box } from '@common-fe/common-fe';

import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';

import PaymentMethodDescription from './PaymentMethodDescription';
import PaymentMethodIcon from './PaymentMethodIcon';
interface Props {
  isVerifiedDateVisible?: boolean;
  paymentMethod?: Partial<PaymentMethod>;
  simplifyDesc?: boolean;
  hideIconBorder?: boolean;
  hasPaymentFreeTimeLabel?: boolean;
}

export const PaymentMethodLabel: React.FC<Props> = ({
  paymentMethod,
  simplifyDesc,
  hideIconBorder,
  isVerifiedDateVisible = true,
  hasPaymentFreeTimeLabel = true,
}) => {
  return (
    <Box
      data-testid="PaymentMethodLabel_container"
      direction="row"
      align="center"
    >
      <PaymentMethodIcon paymentMethod={paymentMethod} hideIconBorder={hideIconBorder} />
      <PaymentMethodDescription hasPaymentFreeTimeLabel={hasPaymentFreeTimeLabel} paymentMethod={paymentMethod} simplifyDesc={simplifyDesc} isVerifiedDateVisible={isVerifiedDateVisible} />
    </Box>
  );
};
