import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton, Box, ErrorModal,
  Preloader, Text,   WarningIcon, WarnModal, } from '@common-fe/common-fe';

import { useCIPInfo } from '../hooks';
import { useCIPStatusUpdateQuery } from '../Questionnaire/queries';
import QuestionaireWrapper from '../Questionnaire/QuestionnaireWrapper';

const Banner = () => {
  const { t } = useTranslation();
  const {
    invalidateQuery: invalidateEnrollmentsQuery,
  } = useCIPStatusUpdateQuery();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isErrorModal, setErrorModal] = useState(false);
  const {
    isVerificationRequired,
    setServerErrorShowed,
    handleFailedCip,
    invalidateCIPInfoQuery,
    handleVerify,
    isVerificationLoading,
    isFailed,
    isServerErrorShowed,
  } = useCIPInfo({
    onFail: () => {
      setErrorModal(true);
    },
    onSuccess: async () => {
      setSuccessModal(true);
     
      invalidateEnrollmentsQuery();
      invalidateCIPInfoQuery();
    },
  });
  useEffect(() => {
    if (isErrorModal) {
      handleFailedCip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorModal]);
  const handleRedirect = useCallback(() => {
    setSuccessModal(false);
    setErrorModal(false);
  }, []);

  const content = useMemo(() => {
    if (!isVerificationRequired) {
      return null;
    }
    if (isFailed) {
      return (
        <Box
          pad={{ vertical: 'l', horizontal: 'xl' }}
          data-testid="cip-banner_failed"
          margin={{ top: 'l' }}
          round="container2Round"
          direction="column"
          justify="center"
          background="warningContainer"
          border={{ color: 'warningBorder' }}
        >
          <ErrorModal
            testId="questionnaire-wrapper_error"
            visible={isErrorModal}
            onSetVisible={setErrorModal}
            header={t('We\'re Sorry, we\'re unable to open your HSA')}
            helpText={t(`We cannot verify your details with the information provided.
          Please contact your administrator for next steps.`)}
            buttonText={t('Got It!')}
            onSubmit={() => setErrorModal(false)}
          />

          <Box direction="row" align="center">
            <Box fill>
              <Box direction="row" round="container1Round" align="center">
                <WarningIcon size="24px" color="danger" />
                <Text textAlign="center" color="textBody" margin={{ left: 'xs' }} size="large" weight="bold">
                  {t(
                    'We cannot verify your details with the information provided. Please contact your administrator for next steps.',
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <Box
        pad={{ vertical: 'l', horizontal: 'xl' }}
        data-testid="cip-banner"
        margin={{ top: 'l' }}
        round="container2Round"
        direction="column"
        justify="center"
        background="warningContainer"
        border={{ color: 'warningBorder' }}
      >
        <WarnModal
          visible={isServerErrorShowed}
          testId="CIP-banner_server_error"
          onSetVisible={setServerErrorShowed}
          header={t('The server encountered an error processing the request')}
          helptext={t(`It doesn't appear to have affected your data, but we cannot verify your identity to establish your HSA.
      Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`)}
          buttonText={t('Close')}
          onSubmit={handleVerify}
          submitButtonText={t('Try Again')}
        />
        <Box direction="row" align="center">
          <Box fill>
            <Box direction="row" round="container1Round" align="center">
              <WarningIcon size="24px" />
              <Text textAlign="center" color="textBody" margin={{ left: 'xs' }} size="large" weight="bold">
                {t('We need to verify your identity to establish your HSA')}
              </Text>
            </Box>

            <Text color="textBody" size="medium" margin={{ top: 'xs' }}>
              {t('With just a few extra details we can activate your account.')}
            </Text>
          </Box>
          <AppButton
            testId="cip-banner_verify"
            onClick={() => handleVerify()}
            disabled={isVerificationLoading}
            width="xregular"
          >
            {isVerificationLoading ? <Preloader /> : t('Verify Identity')}
          </AppButton>
        </Box>
      </Box>
    );
  }, [
    handleVerify,
    isErrorModal,
    isFailed,
    isServerErrorShowed,
    isVerificationLoading,
    isVerificationRequired,
    setServerErrorShowed,
    t,
  ]);

  return (
    <QuestionaireWrapper
      isSuccessModal={isSuccessModal}
      onCloseModal={handleRedirect}
      isStandAloneMode
    >
      {content}
    </QuestionaireWrapper>
  );
};
export default Banner;
