import React from 'react';
import { Box } from '@common-fe/common-fe';

import { CardDto } from '@/modules/transaction/components/Cards/Cards.types';
import { CardItemActions } from '@/modules/transaction/components/Cards/components/CardItemActions';

import { StyledActionsCarousel } from '../CardCarousel/CardCarousel.styles';

interface Props {
  cards: CardDto[];
  selectedItemIndex: number;
}

const CardAction: React.FC<{
  card: CardDto,
  selectedItemIndex: number,
  index: number,
}> = ({
  card, index, selectedItemIndex,
}) => (
  <Box
    justify="center"
    height="60px"
  >
    <Box
      pad="spacing8"
      width={{ max: '458px' }}
    >
      {
        selectedItemIndex === index && (
          <Box data-testid={`CardActionsCarousel-item-${card.id}`}>
            <CardItemActions
              card={card}
            />
          </Box>
        )
      }
    </Box>
  </Box>
);

export const CardActionsCarousel: React.FC<Props> = ({
  cards, selectedItemIndex,
}) => (
  <StyledActionsCarousel
    centerMode
    centerSlidePercentage={45}
    renderArrowNext={() => null}
    renderArrowPrev={() => null}
    showIndicators={false}
    showStatus={false}
    selectedItem={selectedItemIndex}
    showThumbs={false}
  >
    {
      [
        ...cards.map((card, index) => (
          <CardAction
            card={card}
            key={card.id}
            index={index}
            selectedItemIndex={selectedItemIndex}
          />
        )),
        <Box key="empty" />,
      ]
    }
  </StyledActionsCarousel>
);
