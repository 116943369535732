import { useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { CardReplacementReasons, CardStatusEnum } from '@/modules/transaction/components/Cards/Cards.types';

interface DefaultReplacementFields {
  REPLACEMENT_REASON: string,
}

const REPLACEMENT_REASONS = [
  { key: 'LOST', value: CardReplacementReasons.LOST },
  { key: 'STOLEN', value: CardReplacementReasons.STOLEN },
  { key: 'NOT_SENT', value: CardReplacementReasons.NOT_RECEIVED },
  { key: 'DAMAGED', value: CardReplacementReasons.DAMAGED },
];

const FIELDS: DefaultReplacementFields = {
  REPLACEMENT_REASON: 'reason',
};

interface Params {
  defaultFields?: DefaultReplacementFields;
  cardStatus?: CardStatusEnum;
  mailedDate?: Date | null;
  activationDate?: Date | null;
}

export default (data: Params) => {
  const { defaultFields } = data;
  const fields: Field[] = useMemo(() => {
    const source: Field[] = [
      {
        name: FIELDS.REPLACEMENT_REASON,
        type: FieldTypes.Dropdown,
        singleMode: true,
        label: 'Replacement reason',
        placeholder: 'Select reason',
        validator: yup.string().required(REQUIRED_TEXT),
        options: REPLACEMENT_REASONS,
        defaultValue: '', // generalInfo?.reason,
      },
    ];
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return source.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      }));
    }
    return source;
  }, [defaultFields]);

  return fields;
};
