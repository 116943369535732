import React, { useMemo } from 'react';
import { Box,SuccessModal } from '@common-fe/common-fe';
import Lottie from 'lottie-react';

import SuccessIcon from '@/assets/icons/successIcon.json';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

interface Props {
  onClose: (value: boolean) => void;
}

const SuccessfullyChangedModal: React.FC<Props> = ({ onClose }) => {
  const { employee } = useEmployeeStore();

  const headerTitle = useMemo(() => {
    if (employee?.isEmployeeEmailPreferred) {
      return `Your new email address is ${employee?.employeeProvidedEmail}`;
    }

    return `You set the employer provided email (${employee?.employerProvidedEmail}) as your preferred email`;
  }, [employee]);

  return (
    <SuccessModal
      visible
      icon={(
        <Box margin={{ bottom: 'spacing12' }}>
          <Lottie animationData={SuccessIcon} loop={false} />
        </Box>
      )}
      header={headerTitle}
      onSetVisible={onClose}
      buttonText="Close"
      buttonStyle={{ width: '200px' }}
      testId="successfully-changed"
    />
  );
};

export default SuccessfullyChangedModal;
