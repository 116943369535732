/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {  MinusIcon, MinusThinIcon, PlusIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
display: flex;
flex-direction: row;
  right: 0;
`;
const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.canvas};
  ${({ theme }) => theme.fonts.text14Bold}
  border-radius: ${({ theme }) => theme.rounds.button1Round} ;
  ${({ theme }) => theme.shadows.default};
  padding: 0 ${({ theme }) => theme.spacings.spacing4};
  width: ${({ theme }) => theme.controls.height};
  height: ${({ theme }) => theme.controls.height};
  transition: all 0.15s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center; 
  color: ${({ theme }) => theme.colors.textAccent};
  margin-left: ${({ theme }) => theme.spacings.spacing4};
  ${({ theme }) => theme.border.grey6};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.button};
    svg {
      fill: ${({ theme }) => theme.colors.textOnColor};
      stroke:${({ theme }) => theme.colors.textOnColor}; 
    }
  }
  &:active {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.buttonHover};
    svg {
      fill: ${({ theme }) => theme.colors.textOnColor};
    }
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.border1};
    svg {
      fill: ${({ theme }) => theme.colors.textOnColor};
    }
  }
`;

const ZoomButtons = ({
  scale,
  minScale,
  maxScale,
  onZoomInClick,
  onZoomOutClick,
}: any) => (
  <Wrapper data-testid="zoom-button">
    <Button onClick={onZoomOutClick} disabled={scale <= minScale}>
      <MinusIcon size="14px" color="iconAccent"/>
    </Button>
    <Button onClick={onZoomInClick} disabled={scale >= maxScale}>
      <PlusIcon size="14px" color="iconAccent" />
    </Button>
  </Wrapper>
);

export default ZoomButtons;
