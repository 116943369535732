import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { PartnerMobileAppType } from '@/common/constants';

interface PartnerAppLinksPayload {
  partner_mobile_app?: PartnerMobileAppType;
  partner_app_ios_link?: string;
  partner_app_android_link?: string;
  logo_info?: {
    large_logo_url?: string;
    minimized_logo_url?: string;
    favicon_url?: string;
  };
}

interface PartnerAppLinks {
  partnerMobileApp?: PartnerMobileAppType;
  partnerAppIosLink?: string;
  partnerAppAndroidLink?: string;
  logoInfo?: {
    largeLogoUrl?: string;
    minimizedLogoUrl?: string;
    faviconUrl?: string;
  };
}

interface Props {
  qrHashId: string;
}

const useGetPartnerAppLinksByQrQuery = ({ qrHashId }: Props) => {
  const { data, isLoading } = useQuery(
    PATHS.GET_PARTNER_APP_LINKS_BY_QR_CODE(qrHashId),
    () => api.get<PartnerAppLinksPayload>(PATHS.GET_PARTNER_APP_LINKS_BY_QR_CODE(qrHashId)),
    {
      enabled: !!qrHashId,
    }
  );

  const formattedData = useMemo((): PartnerAppLinks => ({
    partnerAppAndroidLink: data?.data?.partner_app_android_link,
    partnerAppIosLink: data?.data?.partner_app_ios_link,
    partnerMobileApp: data?.data?.partner_mobile_app,
    logoInfo: {
      largeLogoUrl: data?.data?.logo_info?.favicon_url,
      minimizedLogoUrl: data?.data?.logo_info?.minimized_logo_url,
      faviconUrl: data?.data?.logo_info?.favicon_url,
    },
  }), [data]);

  return {
    partnerAppLinks: formattedData,
    isLoading,
  };
};

export default useGetPartnerAppLinksByQrQuery;
