import _ from 'lodash';

import { storeCreator } from '@/utils/store/store.utils';

export type ErrorsField<Type> = {
  [Key in keyof Type]: {
    value?: Type[Key];
    message: string;
  } ;
};

const baseStoreCreator = <T>(defaultValues:T) => storeCreator<{
  state: Partial<T>;
  sourceState?: Partial<T>;
  setState:(values: Partial<T>) => void;
  setSourceState: (values: Partial<T>) => void;
  errors: Partial<T>;
  errorFields: Partial<ErrorsField<T>>;

  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
  setErrors: (values: Partial<T>) => void;
  setErrorFields: (values: Partial<ErrorsField<T>>) => void;
  handleReset:() => void;
    }>((set) => ({
      state: defaultValues,
      sourceState: defaultValues,
      errors: {},
      errorFields: {},
      showErrors: false,
      handleReset: () => set(() => ({
        state: defaultValues,
        sourceState: defaultValues,
        errors: {},
        showErrors: false,
      })),
      setShowErrors: (value: boolean) => set(() => ({
        showErrors: value,
      })),
      setErrors: (values: Partial<T>) => set(() => ({
        errors: values,
      })),
      setErrorFields: (values: Partial<ErrorsField<T>>) => set(() => ({
        errorFields: values,
      })),
      setState: (values: Partial<T>) => set((currentStore) => ({
        state: {
          ...currentStore.state,
          ..._.pick(values, Object.keys(defaultValues as object)),
        },
      })),
      setSourceState: (values: Partial<T>) => set((currentStore) => ({

        sourceState: {
          ...currentStore.state,
          ..._.pick(values, Object.keys(defaultValues as object)),
        },
      })),

    }));
export default baseStoreCreator;
