import { storeCreator } from '@/utils/store/store.utils';

import { BannerStorageKey } from '../bannersList.constants';

interface State {
  dismissedBanners: BannerStorageKey[];
  setDismissedBanners: (value: BannerStorageKey[]) => void;
}

export const useStore = storeCreator<State>((set) => ({
  dismissedBanners: [],

  setDismissedBanners: (dismissedBanners: BannerStorageKey[]) => set((state) => ({
    ...state,
    dismissedBanners,
  })),
}));

const useDismissedBannersStore = () => ({ ...useStore() });

export default useDismissedBannersStore;
