import React, {
// useMemo,
// useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, costFormater,
  Text, 
  // getPercents,
  // DropContent,
  // getPercents,
} from '@common-fe/common-fe';

// import _ from 'lodash';
import { InvestmentStockItem } from '../../investments.types';

interface Props {
  investmentThreshold?: number;
  accountId?: string;
  data?: InvestmentStockItem[];
}
const FundingCashCard: React.FC<Props> = ({ investmentThreshold = 0, data }) => {
  const { t } = useTranslation();

  // const [visible, setVisible] = useState(false);

  // const tooltipList = useMemo(() => (
  //   data?.map((item) => {
  //     const percentsRaw = getPercents(
  //       item.amount + (item.pendingAmount || 0),
  //       investmentThreshold,
  //     );

  //     return (
  //       <Box
  //         key={item.stockQuote}
  //         direction="row"
  //         justify="between"
  //         align="end"
  //         margin={{ bottom: 'spacing2' }}
  //       >
  //         <Box direction="row" align="center">
  //           <Box width="12px" height="12px" round="smallRound" background={item.color} />
  //           <Text margin={{ left: 'spacing8' }}>{item.stockQuote}</Text>
  //         </Box>
  //         <Box direction="row" align="center">
  //           <Text weight="bold" textAlign="end" margin={{ right: 'spacing16' }}>{costFormater(item?.amount + (item.pendingAmount || 0), {
  //             isPrecision: true,
  //           })}
  //           </Text>
  //           <Text weight="bold" textAlign="end">{_.floor(percentsRaw, 2)}%</Text>
  //         </Box>
  //       </Box>
  //     );
  //   })
  // ), [data, investmentThreshold]);

  // const charts = useMemo(() => (
  //   data?.map((item) => {
  //     const percentsRaw = getPercents(
  //       item.amount + (item.pendingAmount || 0),
  //       investmentThreshold,
  //     );
  //     if (!item.color) {
  //       return null;
  //     }
  //     return (
  //       <Box
  //         key={item.stockQuote}
  //         background={item.color}
  //         height="8px"
  //         width={{
  //           min: '25px',
  //           width: `${percentsRaw || 5}%`,
  //         }}

  //       />
  //     );
  //   })
  // ), [data, investmentThreshold]);
  return (
    <>
      <Box direction="row" justify="between" align="center" data-testid="FundingCashCard-wrapper-id">
        <Text color="textSecondary" size="small" weight={500}>
          {t('Portfolio Assets')}
        </Text>
        <Text size="2xl" weight={700}>
          {costFormater(investmentThreshold, true)}
        </Text>
      </Box>
      {/* <Box>
        {
          charts && charts?.length > 0 && (
            <DropContent
              contentWidth="260px"
              bottomMode
              visible={visible}
              content={
                (
                  <Box
                    direction="column"
                  >
                    <Text weight="bold" size="medium">{t('Current Holdings')}</Text>
                    {tooltipList}
                  </Box>
                )
              }
            >
              <Box
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
                height="30px"
                width="100%"
                align="center"
                justify="center"
              >
                <Box
                  direction="row"
                  round="container1Round"
                  overflow="hidden"
                  width="100%"
                >
                  {charts}
                </Box>
              </Box>
            </DropContent>
          )
        }
      </Box> */}

    </>
  );
};
export default FundingCashCard;
