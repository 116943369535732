import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  RoundPendingIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

const DESCRIPTION = `You already have contributions scheduled. Any adjustments made
 here, one-time or recurring, will replace your existing schedule.`;

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
}

export const WarningModifyContributionModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper testId="WarningModifyContributionModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24', horizontal: 'spacing96' }}>
        <RoundPendingIcon color="warning" />
        <Text
          data-testid="WarningModifyContributionModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {t('Warning')}
        </Text>

        <Text
          data-testid="WarningModifyContributionModal_modal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {t(DESCRIPTION)}
        </Text>

        <Box direction="row" gap="spacing12">
          <AppButton
            buttonType="secondary"
            testId="WarningModifyContributionModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            width="140px"
          >
            {t('Cancel')}
          </AppButton>
          <AppButton
            testId="WarningModifyContributionModal_modal_submit"
            onClick={onSubmit}
            width="140px"
          >
            {t('Continue')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
