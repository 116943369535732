import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Box, RightArrowIcon,Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useHasAccess } from '@/modules/core/hooks';
import { CardItemActions } from '@/modules/transaction/components/Cards/components/CardItemActions';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import colors from '@/styles/colors';

import { CardItemWrapper } from '../Cards.syles';
import { CardDto, CardStatusEnum } from '../Cards.types';
import useCardReplaceModalStore from '../store/useCardReplaceModal.store';

import { CardItem } from './CardItem';

const StyledBox = styled(Box)<{ expand: boolean }>`
  margin-bottom: ${({ theme, expand }) => (expand ? theme.spacings.spacing24 : 0)};

  &:last-child {
    margin-bottom: 0;
  }
`;
const ArrowIconWrap = styled(Box)<{ expand: boolean }>`
  transform: rotate(${({ expand }) => (expand ? '90deg' : '0deg')});
  color: ${({ theme }) => theme.colors.iconAccent};
`;

const TitleWrap = styled(Box)<{ expand: boolean }>`
  box-shadow: none;

  &:hover {
    color: ${({ theme }) => theme.colors.textAccent}
  }
`;

const STATUS_TITLE = {
  [CardStatusEnum.ACTIVE]: 'Active Card',
  [CardStatusEnum.FROZEN]: 'Locked Cards',
  [CardStatusEnum.CLOSED]: 'Closed Cards',
  [CardStatusEnum.PENDING_CLOSED]: 'Pending Closed Cards',
  [CardStatusEnum.PENDING_FROZEN]: 'Pending Locked Cards',
  [CardStatusEnum.PENDING_OPEN]: 'Pending Open Cards',
  [CardStatusEnum.PENDING_REPLACE]: 'Pending Replace Cards',
  [CardStatusEnum.PENDING_UNLOCK]: 'Pending Unlock Cards',
  [CardStatusEnum.LOST]: 'Lost Cards',
  [CardStatusEnum.STOLEN]: 'Stolen Cards',
  [CardStatusEnum.NOT_SENT]: 'Not Sent Cards',
};

interface Props {
  status: CardStatusEnum;
  cardList: CardDto[];
  expandable?: boolean;
}

export const CardsSectionWrapper: React.FC<Props> = ({
  expandable, cardList, status
}) => {
  const { state: { cardID } } = useCardReplaceModalStore();
  const { organizationProperties } = useOrganizationProperties();
  const { push } = useHistory();
  const [expand, setExpand] = useState(!expandable);
  const isEmployeeLimitedAccess = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);

  return (
    <StyledBox expand={expand}>
      {expandable ? (
        <TitleWrap
          margin={{ horizontal: 'spacing8', bottom: 'spacing16' }}
          direction="row"
          align="center"
          onClick={() => setExpand(!expand)}
          expand={expand}
          data-testid="Card-section-wrapper"
        >
          <ArrowIconWrap expand={expand}>
            <RightArrowIcon color={expand ? colors.iconAccent : colors.iconPrimary} />
          </ArrowIconWrap>
          <Text margin={{ left: 'spacing16' }} size="xxlarge">
            {STATUS_TITLE[status]} ({cardList.length})
          </Text>
        </TitleWrap>
      ) : null}
      <Box direction="row" wrap gap="spacing8">
        {expand && cardList.map((card) => (
          <CardItemWrapper
            key={card.id}
            margin="spacing8"
            data-testId="card-section-list"
          >
            <Box
              onClick={() => push(`${ROUTES.CARD_DETAILS}?dependent_id=${card.holder.dependentId || ''}&card_id=${card.id}`)}
            >
              <CardItem
                fullyMasked={isEmployeeLimitedAccess}
                card={card}
                borderColor={card.id === cardID ? 'accentActive' : 'transparent'}
                logoUrl={organizationProperties?.darkLargeLogoUrl}
              />
            </Box>
            {card.status !== CardStatusEnum.CLOSED
            && (
              <Box margin={{ top: 'spacing12' }}>
                <CardItemActions
                  card={card}
                />
              </Box>
            )}

          </CardItemWrapper>
        ))}
      </Box>
    </StyledBox>
  );
};
