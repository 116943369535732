import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

interface WrapperProps {
  imageSrc?: string;
}

export const ManageProfileDashboardWrapper = styled(Box)<WrapperProps>`
  min-height: 340px;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacings.spacing24};
  padding-top: ${({ theme }) => theme.spacings.spacing24};
  padding-bottom: ${({ theme }) => theme.spacings.spacing24};
  padding-left: ${({ theme }) => theme.spacings.spacing72};
  padding-right: 144px;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background1};
  background-repeat: no-repeat;
  background-image: url("${({ imageSrc}) => imageSrc}");
`;

export const ManageProfileDashboardContent = styled(Box)`
  position: relative;
  z-index: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
