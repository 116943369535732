import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Inscription, ReceiptScanIcon } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import useProgress from '@/components/controls/DropScan/hooks/useProgress';
import { InnerProgressBar, ProgressBar } from '@/components/controls/DropScan/ProcessScan.styles';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const TransactionDocumentUploadingPopup: React.FC<Props> = ({ onSetVisible }) => {
  const { t } = useTranslation();
  const { progress } = useProgress(30);

  const handleCancel = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  return (
    <ModalWrapper visible onSetVisible={onSetVisible}>
      <Box align="center">
        <ReceiptScanIcon size="72px" color='iconAccent' />

        <Inscription
          margin={{ top: 'spacing24' }}
          weight={700}
          size="2xl"
        >
          {t('Hang tight while we scan your file…')}
        </Inscription>

        <ProgressBar width={{ max: '512px', width: '100% !important' }}>
          <InnerProgressBar progress={progress} />
        </ProgressBar>

        <Box margin={{ top: 'spacing8' }}>
          <AppButton
            buttonType="secondary"
            onClick={handleCancel}
          >
            {t('Cancel Scanning')}
          </AppButton>
        </Box>        
      </Box>
    </ModalWrapper>
  );
};

export default TransactionDocumentUploadingPopup;
