import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton, Box, Preloader, Text,
} from '@common-fe/common-fe';

interface Props {
  isErrorShowed?: boolean;
  isLoading?: boolean;
  onNext: () => void;
  onCancel: () => void;
}
const QuestionnaireFooter: React.FC<Props> = ({
  isErrorShowed, onNext, onCancel, isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Box direction="column" data-testid="questionnaire-footer">
      {isErrorShowed && (
        <Box direction="row" justify="end">
          <Text color="danger" data-testid="questionnaire-footer_error">
            {t('Please fill up all questions.')}
          </Text>
        </Box>
      )}

      <Box direction="row" justify="end">
        <AppButton
          width="control"
          testId="questionnaire-footer_cancel"
          buttonType="secondary"
          onClick={onCancel}
        >
          {t('Later')}
        </AppButton>

        <Box margin={{ left: 's' }}>
          <AppButton
            testId="questionnaire-footer_next"
            width="control"
            disabled={isLoading || isErrorShowed}
            onClick={onNext}
          >
            {isLoading ? <Preloader testId="preloader" /> : t('Next')}

          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(QuestionnaireFooter);
