import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccountStatus,
  AccountTypeTextes,
  Box,
  CardTypesEnum,
  CIPStatus,
  CipStatusCard,
  Enrollment,
  EnrollmentStatus,
  InfoOutlineIcon,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';

import AppButton from '@/components/controls/AppButton';
import useFailedCIPStorage from '@/modules/investments/cip/hooks/useFailedCIPStorage';
import EnrollmentItem, { ENROLLMENT_ITEM_WIDTH_L } from '@/modules/transaction/components/EnrollmentItem/EnrollmentItem';
import { useExpensesStatisticsQuery } from '@/modules/transaction/hooks/useExpensesStatistics.query';
import globalTheme from '@/styles/theme';

import { ContributionAchievementsTracker } from '../Contributions/ContributionAchievementsTracker';
import { EnrollmentDates } from '../EnrollmentItem/EnrollmentDates';
import ExpensesBreakdown from '../ExpensesBreakdown';

import { Header, HeaderWrapper, IconWrapper } from './AccountDetails.styles';

const expensesBreakdownHight = '248px';

const TYPES = [
  'medical',
  'dental',
  'pharmacy',
  'vision',
  'other'];
export const fsaExpenses = TYPES.map((item) => ({
  key: item,
  title: _.capitalize(item),
  value: Math.floor(Math.random() * 1000),
}));

export const getEnrollmentStatusColor = (status?: EnrollmentStatus) => {
  switch (status) {
  case EnrollmentStatus.Active: {
    return globalTheme.colors.statusColors.paidActiveComplete;
  }
  case EnrollmentStatus.Canceled: {
    return globalTheme.colors.statusColors.deniedErrorExpired;
  }
  default: {
    return globalTheme.colors.statusColors.lockedNull;
  }
  }
};
interface Props {
  id: string;
  currentMyAccount: Enrollment;
}
const LINK_ZENDEST_HSA = 'https://elevateaccounts.zendesk.com/hc/en-us/articles/7091893204763-Adding-a-Beneficiary-to-my-Health-Savings-Account-HSA-';

const AccountDetails: React.FC<Props> = ({ id, currentMyAccount }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (LINK_ZENDEST_HSA) {
      window.open(LINK_ZENDEST_HSA, '_blank');
    }
  }, []);
  const { formatedData } = useExpensesStatisticsQuery({
    account_id: Number(id),
  });
  const { isFailed: isVerificationFailed } = useFailedCIPStorage();
  const statusColor = useMemo(() => getEnrollmentStatusColor(currentMyAccount.enrollmentStatus), [currentMyAccount.enrollmentStatus]);
  const content = useMemo(() => {
    if (currentMyAccount) {
      const { CIPStatus: currentCIPStatus, accountStatus, accountType } = currentMyAccount;
      if (accountStatus === AccountStatus.PENDING) {
        if (currentCIPStatus === CIPStatus.Pending || currentCIPStatus === CIPStatus.None) {
          return (
            <Box flex="grow">
              <CipStatusCard
                status="NEED_VERIFY"
                height={expensesBreakdownHight}
                text={(
                  <>
                    <Text
                      color="textBody"
                      size="large"
                      textAlign="center"
                    >
                      {t('We are verifying your identity to establish your account.')}
                    </Text>
                    <Text
                      color="textBody"
                      size="large"
                      textAlign="center"
                    >
                      {t('This may take a few days')}
                    </Text>
                  </>
                )}
              />
            </Box>
          );
        }
        if ((currentCIPStatus === CIPStatus.Fail || currentCIPStatus === CIPStatus.Question) && isVerificationFailed) {
          return (
            <div>
              <Box flex="grow">
                <CipStatusCard
                  status=""
                  height={expensesBreakdownHight}
                  text={(
                    <Text
                      color="textBody"
                      size="large"
                      textAlign="center"
                    >
                      {t(`We're sorry but we cannot open 
                      an ${AccountTypeTextes[accountType as keyof typeof AccountTypeTextes]} for you as we were not able to successfully verify your identity.
                      Please contact your administrator for next steps.`)}
                    </Text>
                    
                  )}
                />
              </Box>
            </div>
          );
        }
      }
      if (currentMyAccount.contributionMonitoringRequired) {
        return (
          <Box flex="grow">
            <ContributionAchievementsTracker currentAccount={currentMyAccount} />
          </Box>
        );
      }
      return (
        <Box flex="grow" height={{ max: expensesBreakdownHight }}>
          <ExpensesBreakdown
            className="expenses-breakdown"
            options={formatedData?.expenses || []}
            totalExpense={formatedData?.totalExpenses || 0}
            inlineMode
            isWithoutHeader
          />
        </Box>
      );
    }
  }, [
    isVerificationFailed,
    currentMyAccount,
    formatedData.expenses,
    formatedData.totalExpenses,
    t,
  ]);

  return (
    <Box
      data-testid="AccountDetails-wrapper"
      round="moduleRound"
      background="module"
      pad="l"
      margin={{ vertical: 'l' }}
    >
      <HeaderWrapper>
        <Header>{t('Account Details')}</Header>
      </HeaderWrapper>
      <Box direction="row" justify="center">
        {
          currentMyAccount
          && (
            <div>
              <EnrollmentItem 
                isDateless
                isHoverCardNumber
                enrollment={currentMyAccount}
                wrapperStyle={{ marginRight: globalTheme.spacings.s, width: ENROLLMENT_ITEM_WIDTH_L }} />
            </div>
          )
        }
        {
          content
        }
      </Box>
      {
        currentMyAccount && (
          <EnrollmentDates
            isInlineMode
            coverageDateStart={currentMyAccount.coverageDateStart}
            submitClaimsDateEnd={currentMyAccount.submitClaimsDateEnd}
            coverageDateEnd={currentMyAccount.coverageDateEnd}
            enrollmentStatus={currentMyAccount.enrollmentStatus}
            coveragePeriods={currentMyAccount.coveragePeriods}
            statusColor={statusColor}
          />
        )
      }
      {
        currentMyAccount?.accountType === CardTypesEnum.HSA
        && (
          <Box
            direction="row"
            background="canvas"
            round="container1Round"
            elevation="default"
            border={{ size: 'small', color: 'border2' }}
            justify="between"
            align="center"
            pad={{ horizontal: 'm', vertical: 's' }}
            margin={{ top: 'xs' }}
          >
            <Box direction="row">
              <IconWrapper direction="row" align="center" justify="center">
                <InfoOutlineIcon color="iconPrimary" />
              </IconWrapper>
              <Text margin={{ left: 'xs' }} size="large" color="textBody">{t('Establish or Change Beneficiary Information')}</Text>
            </Box>

            <AppButton
              buttonType="quaternary"
              onClick={handleClick}
            >
              {t('Beneficiary Designation Form')}

            </AppButton>
          </Box>
        )
      }
    </Box>
  );
};

export default AccountDetails;
