import React from 'react';
import { Anchor, Box, Checkbox, Image, NewTabIcon, NullIcon, Text } from '@common-fe/common-fe';

import { useGetFundIcon } from '../../hooks';
import { InvestmentHoldingStatus } from '../../investments.types';

interface Props {
  value: boolean;
  onChangeValue: (value: boolean) => void;
  title: string;
  stockCode: string;
  link?: string;
  logo?: string;
  status?: InvestmentHoldingStatus;
}

const AddMoreItem: React.FC<Props> = ({ value, onChangeValue, link, logo, status, stockCode, title }) => {
 
  const handleGetFundIcon = useGetFundIcon();
  return (
    <div>
      <Box
        direction="row"
        align="center"
        elevation="default"
        round="container2Round"
        background="canvas"
        pad="s"
        border={{size: 'small', color: 'border2'}}
        margin={{left: 'xs', bottom: 'xs'}}
      >
        <Box>
          <Checkbox
            disabled={status === InvestmentHoldingStatus.Inactive}
            testId="fund-item"
            name="select"
            checked={value}
            onChange={onChangeValue} />
        </Box>
        <Box margin={{ right: 's' }}>
          {handleGetFundIcon({
            image: logo,
            status
          })}
          
        </Box>
        <Box
          style={{
            flex: 2,
            position: 'relative',
          }}
          direction="column"
        >
          <Box>
            <Text tip={title} color="textBody" truncate weight={700}>
              {title}
            </Text>
            <Text color="textBody">{stockCode}</Text>
          </Box>
         
        </Box>
        {link ? (
          <Anchor target="_blank" href={link}>
            <Box align="center">
              <NewTabIcon color="iconSecondary" size="26px" />
            </Box>
          </Anchor>
        ) : (
          <Box width="26px" />
        )}
      </Box>
    </div>
  
  );
};

export default AddMoreItem;
