import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { ElectronicAgreementMessage } from '@/modules/transaction/components/Statements/components/ElectronicAgreementMessage';
import { useMyEnrollments, useOrganizationProperties } from '@/modules/transaction/hooks';

import { HEADERS, StatementsList } from './components/StatementsList';

interface Props {
  accountId: string;
}

const Statements: React.FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();
  const { organizationProperties } = useOrganizationProperties();
  const { currentMyAccount } = useMyEnrollments(accountId);

  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS,
    title: t('My accounts'),
    value: `${ROUTES.MY_ACCOUNTS}`,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: currentMyAccount?.name,
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  },
  {
    key: ROUTES.STATEMENTS(accountId),
    title: t('Statements'),
    value: ROUTES.STATEMENTS(accountId),
  }], true);

  return (
    <Box data-testId="statements">
      <Box>
        <Text size="3xl" weight="bold">{t('Statements')}</Text>
      </Box>
      <ElectronicAgreementMessage />
      <StatementsList accountId={accountId} headers={HEADERS} />
      <Box margin={{ top: 'spacing24' }}>
        <Text color="textSecondary">
          {t('For any questions, please contact us at ')}{organizationProperties?.customerServiceEmail}
        </Text>
      </Box>
    </Box>
  );
};

export default Statements;
