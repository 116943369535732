import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import { HEADERS, StatementsList } from '@/modules/transaction/components/Statements/components/StatementsList';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import useMyEnrollments from '@/modules/transaction/hooks/useMyEnrollments';

const StatementsAndDocuments = () => {
  const { t } = useTranslation();
  const { organizationProperties } = useOrganizationProperties();
  const { mainHSAEnrollment } = useMyEnrollments();
  const accountId = mainHSAEnrollment?.id;

  const headers = useMemo(() => [HEADERS[0]], []);

  return (
    <Box>
      <StatementsList accountId={accountId} headers={headers} />
      <Box margin={{ top: 'spacing24' }}>
        <Text color="textSecondary">
          {t('For any questions, please contact us at ')}{organizationProperties?.customerServiceEmail}
        </Text>
      </Box>
    </Box>
  );
};

export default StatementsAndDocuments;
