import React, { useCallback, useEffect,useMemo } from 'react';
import {
  Checkbox, Field,   FieldTypes, Hint,
  Text, } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import useEditEmailStore from '@/modules/transaction/components/Statements/components/ChangeEmailModal/stores/useEditEmail.store';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

export interface EmailConfig {
  employeeProvidedEmail?: string;
  employeePreferredEmail?: string;
  employerProvidedEmail?: string;
  isEmployeeEmailPreferred?: boolean;
  isEmployerEmailPreferred?: boolean;
}

const useEditEmailFields = () => {
  const { employee } = useEmployeeStore();
  const { state, setState } = useEditEmailStore();

  useEffect(() => {
    if (employee?.employerProvidedEmail) {
      setState({ isEmployeeEmailPreferred: employee?.isEmployeeEmailPreferred });
    }
    // eslint-disable-next-line
  }, []);

  const handleSetEmployeeEmailPreferred = useCallback(() => {
    setState({
      isEmployeeEmailPreferred: true,
    });
  }, [setState]);

  const handleSetEmployerEmailPreferred = useCallback(() => {
    setState({
      isEmployeeEmailPreferred: false,
    });
  }, [setState]);

  const fields = useMemo(() => ([
    ...state.isEmployeeEmailPreferred ? [{
      name: 'employeeProvidedEmail',
      type: FieldTypes.Text,
      label: 'Employee provided email',
      placeholder: 'Email',
      validator: VALIDATORS.EMAIL_REQUIRED,
      defaultValue: employee?.employeeProvidedEmail || '',
    }] : [{
      name: 'employeePreferredEmail',
      type: FieldTypes.Text,
      label: 'Employee provided email',
      placeholder: 'Employee provided email',
      disabled: true,
      defaultValue: employee?.employerProvidedEmail,
    }],
    {
      name: 'isEmployeeEmailPreferred',
      type: FieldTypes.Node,
      label: '',
      value: (
        <>
          <Checkbox
            checked={state.isEmployeeEmailPreferred}
            onChange={handleSetEmployeeEmailPreferred}
          >
            <Text>Set as preferred email</Text>
          </Checkbox>
          <Hint>
            <Text>
              We will use this email address to send notifications regarding your claims requests,
              investments if any, and in case you lose access to your account,
              you can restore it using this email.
            </Text>
          </Hint>
        </>
      ),
    },
    {
      name: 'employerProvidedEmail',
      type: FieldTypes.Text,
      label: 'Employer provided email',
      placeholder: '-',
      defaultValue: employee?.employerProvidedEmail,
      disabled: true,
    },
    {
      name: 'isEmployerEmailPreferred',
      type: FieldTypes.Node,
      label: '',
      value: (
        <>
          <Checkbox
            checked={!state.isEmployeeEmailPreferred}
            onChange={handleSetEmployerEmailPreferred}
            disabled={!employee?.employerProvidedEmail}
          >
            <Text>Set as preferred email</Text>
          </Checkbox>
          <Hint>
            <Text>
              We will use this email address to send notifications regarding your claims requests,
              investments if any, and in case you lose access to your account,
              you can restore it using this email.
            </Text>
          </Hint>
        </>
      ),
    },
  ]), [
    employee,
    state,
    handleSetEmployeeEmailPreferred,
    handleSetEmployerEmailPreferred,
  ]);

  return fields as Field[];
};

export default useEditEmailFields;
