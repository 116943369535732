import React from 'react';
import { Box, Tip } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DATE_TOOLTIP_FORMAT } from '@/common/constants';
import StatusIcon from '@/components/elements/StatusIcon';
import { Status, TransactionStatus } from '@/modules/transaction/transaction.types';

import {
  Content,
  ContentWrapper,
  DateTooltip,
  DateWrapper,
  Day,
  Delimiter,
  IconWrapper,
  MonthName,
  Row,
  StatusText,
} from './TransactionStatusItem.styles';

interface Props {
  statusDate: Date | string;
  status: TransactionStatus;
  index?: number;
  statuses: Status[];
}
const TransactionStatusItem: React.FC<Props> = ({
  statuses,
  status,
  statusDate,
  index,
}) => {
  const len = statuses.length;
  const lastIndex = len - 1;

  return (
    <Row key={Math.random()}>
      <DateWrapper>
        <Tip
          content={
            (
              <DateTooltip>
                {statusDate ? dayjs(statusDate).format(DATE_TOOLTIP_FORMAT) : ''}
              </DateTooltip>
            )
          }
        >
          <Box>
            <MonthName>
              {dayjs(statusDate).format('MMM')}
            </MonthName>
            <Day>
              {dayjs(statusDate).format('DD')}
            </Day>
          </Box>
        </Tip>
      </DateWrapper>
      <ContentWrapper firstItem={index === 0} statusType={status}>
        <Content>
          <IconWrapper>
            <StatusIcon
              status={status}
            />
          </IconWrapper>
          <StatusText>
            {status}
          </StatusText>
        </Content>
        {lastIndex !== index && (
          <Delimiter margin={{ left: '28px' }} />
        )}
      </ContentWrapper>
    </Row>
  );
};

export default TransactionStatusItem;
