import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface Body {
  qrId?: string,
  employeeId?: number,
  organizationId?: number,
  organizationPath?: string,
}

export interface CreatedDocument {
  document_id: number;
}
const useCreateDocumentByQRCodeQuery = () => {
  const {
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(
    (body: Body) => api.post<CreatedDocument>(PATHS.CREATE_DOCUMENT_BY_QR_CODE, {
      qr_id: body.qrId,
      employee_id: body.employeeId,
      // organization_id: body.organizationId,
      // organization_path: body.organizationPath,
    }),
  );

  return {
    createDocument: mutateAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useCreateDocumentByQRCodeQuery;
