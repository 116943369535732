import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, costFormater, GagueChart, Text,
} from '@common-fe/common-fe';

import { useAuthStore } from '@/modules/user';

import { useDeductibles, useDependents } from './hooks';
import SpendTrackerUser from './SpendTrackerUser';

interface Props {
  deductibleId?: string;
  isIndividual?: boolean;
  userId?: string;
}
const SpendTracker: React.FC<Props> = ({  deductibleId, isIndividual }) => {
  const { user: currentUser } = useAuthStore();
  const currentUserId = useMemo(
    () => currentUser?.employee?.id,
    [currentUser],
  );

  const [focusedUserId,
    // setFocusedUserId
  ] = useState('');
  const { t } = useTranslation();

  const handleHover = useCallback((id: string) => () => {
    // setFocusedUserId(id);
  }, []);

  const handleBlur = useCallback(() => {
    // setFocusedUserId('');
  }, []);
  const {
    chartValues, total, chartTiersValues, remaining, isLoading, individualTotal,
  } = useDeductibles({
    focusedUserId,
    isIndividual,
    employeeId: currentUserId,
    userId: deductibleId,
  });

  const users = useDependents({
    userId: deductibleId || currentUserId,
    isIndividual,
  });

  if (isLoading || !total) {
    return null;
  }
  return (
    <Box background="module" round="moduleRound" pad="l" margin={{ top: 'l' }}>
      <Text size="large" weight={700}>
        Spend Tracker
      </Text>
      <Box margin={{ top: 'l' }} direction="row">
        <Box elevation="default" fill width={{ max: '360px' }} round="container1Round" background="canvas">
          <GagueChart items={chartValues} tiers={chartTiersValues}>
            <Box direction="column" align="center" justify="end" margin={{ top: 'auto' }}>
              <Text weight={400} color="textSecondary">{t('Remaining')}</Text>
              <Text size="2xl" weight={700}>{costFormater(remaining, { isPrecision: true })}</Text>
              <Text>{t('of')} {costFormater(total, { isPrecision: true })}</Text>
            </Box>
          </GagueChart>

        </Box>
        <Box direction="column" elevation="default" round="container1Round" fill background="canvas" margin={{ left: 'm' }} pad="l">
          <Box background="module" round="container1Round" pad="s">
            <Box direction="column" round="container1Round" background="canvas" pad="s">
              <Box direction="row">
                <Text>{
                  isIndividual ? t('Individual Deductible:')

                    : t('Family Deductible:')
                }
                </Text>
                <Text
                  margin={{ left: 'xs' }}
                  weight={700}
                >{costFormater(total, { isPrecision: true })}
                </Text>
              </Box>
              <Box direction="row">
                <Text>{t('Remaining:')}</Text>
                <Text
                  margin={{ left: 'xs' }}
                  weight={700}
                >{costFormater(remaining, { isPrecision: true })}
                </Text>
              </Box>
            </Box>
          </Box>
          {!isIndividual && (
            <Box
              direction="row"
              justify="start"
              align="start"
              margin={{ top: 'xs' }}
              pad={{ top: 'xs' }}
              border={{ side: 'top', color: 'border1', size: '1px' }}
              style={{
                flexWrap: 'wrap',
              }}
            >
              {users.map((user, index) => (
                <SpendTrackerUser
                  key={user.id}
                  index={index}
                  disabled={!individualTotal}
                  onHover={handleHover(user.id)}
                  onBlur={handleBlur}
                  userId={currentUserId}
                  fullName={user.fullName}
                  isMe={user?.isMe}
                  id={user.id}
                />
              ))}
            </Box>
          )}
        </Box>

      </Box>

    </Box>
  );
};

export default React.memo(SpendTracker);
