import React, { useMemo } from 'react';
import { Box, Text } from '@common-fe/common-fe';

import colors from '@/styles/colors';

import { CardDto, CardStatusEnum } from '../Cards.types';

interface Props {
  card: CardDto;
}

export const CardStatusLabel: React.FC<Props> = ({ card }) => {
  const config = useMemo(() => {
    switch (card.status) {
    case CardStatusEnum.ACTIVE:
    case CardStatusEnum.PENDING_UNLOCK: {
      return {
        title: 'Active',
        color: colors.statusColors.paidActiveComplete,

      };
    }
    case CardStatusEnum.FROZEN:
    case CardStatusEnum.PENDING_FROZEN: {
      return {
        title: 'Locked',
        color: colors.statusColors.lockedNull,
      };
    }
    case CardStatusEnum.PENDING_CLOSED:
    case CardStatusEnum.CLOSED: {
      return {
        title: 'Closed',
        color: colors.statusColors.deniedErrorExpired,
      };
    }
    case CardStatusEnum.LOST: {
      return {
        title: 'Replaced (Lost)',
        color: colors.statusColors.deniedErrorExpired,
      };
    }
    case CardStatusEnum.STOLEN: {
      return {
        title: 'Replaced (Stolen)',
        color: colors.statusColors.deniedErrorExpired,
      };
    }
    case CardStatusEnum.NOT_SENT: {
      return {
        title: 'Replaced (Card is not received)',
        color: colors.statusColors.deniedErrorExpired,
      };
    }
    case CardStatusEnum.PENDING_OPEN: {
      let title = 'Requested';

      if (card?.isMailedAfterCreationAndOlderThanWeek) {
        title = 'Ready for Activation';
      }

      return {
        title,
        color: colors.statusColors.paidActiveComplete,
      };
    }
    case CardStatusEnum.PENDING_REPLACE: {
      return {
        title: 'Pending Replace',
        color: colors.statusColors.processingPending,
      };
    }
    default: {
      return null;
    }
    }
  }, [card]);
  return config
    ? (
      <Box direction="row" align="center" data-testid={`Card-status_${card?.status}`}>
        <Box width="11px" height="11px" round="full" background={config.color} />
        <Text margin={{ left: 'spacing8' }}>{config.title}</Text>
      </Box>
    ) : null;
};
