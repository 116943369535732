import React, { useCallback, useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion, AccordionPanel, Box, ChevronDownIcon, ChevronUpIcon, Grid, Inscription, Preloader, Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { capitalize, toString } from 'lodash';
import styled from 'styled-components';

import { DEFAULT_CARD_EXP_DATE_FORMAT } from '@/common/constants';
import { PaymentMethodDetails } from '@/modules/transaction/components/TransactionDetails/TransactionPaymentDetails/components/PaymentMethodDetails/PaymentMethodDetails';
import { useGetPaymentDetails } from '@/modules/transaction/components/TransactionDetails/TransactionPaymentDetails/queries/useGetPaymentDetails.query';
import { PaymentMethodType } from '@/modules/user/user.types';
import { formatCurrency } from '@/utils';
import businessDaysPassed from '@/utils/modifiers/businessDaysPassed';
import { phoneNumberCreator } from '@/utils/modifiers/phoneNumberCreator';

import { CheckStatus, checkStatusColor } from './constants';
import ReissueCheckPopup from './ReissueCheckPopup';
import VoidCheckPopup from './VoidCheckPopup';

const BUSINESS_DAYS = 10;

enum PanelIndex {
  PaymentDetails,
}

const Content = styled(Box)`
  ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  position: relative;
`;
const IconWrap = styled(Box)`
  ${({ theme }) => theme.fonts.text24Regular}
`;
const DetailsWrap = styled(Box)`
  flex: 1;
`;
const FlexGrid = styled(Grid)`
  flex: 1;
`;

interface Props {
  postingId?: string;
  claimId?: string;
  paymentMethodId?: string;
}

const TransactionPaymentDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isVisibleNeedHelpPopup, setIsVisibleNeedHelpPopup] = useState(false);
  const [isVisibleReissueCheckPopup, setIsVisibleReissueCheckPopup] = useState(false);
  const { paymentDetails, isLoading, refetch } = useGetPaymentDetails(props);
  const checkId = useMemo(() => paymentDetails.find((item) => item?.transfers?.paymentNumber)?.transfers?.paymentNumber, [paymentDetails]);
  const mailingDate = useMemo(() => paymentDetails.find((item) => item?.transfers?.mailingDate)?.transfers?.mailingDate, [paymentDetails]);
  const paymentStatus = useMemo(() => paymentDetails.find((item) => item?.transfers?.paymentStatus)?.transfers?.paymentStatus, [paymentDetails]);
  const isResentCheckAvailable = useMemo(() => {
    if (mailingDate) return dayjs(mailingDate).isBefore(businessDaysPassed(BUSINESS_DAYS, dayjs().format()));
    return false;
  }, [mailingDate]);
  const isPaymentMethodTypeCheck = useMemo(() => paymentDetails.find((item) => item?.paymentMethodType)?.paymentMethodType === PaymentMethodType.CHECK, [paymentDetails]);
  const hasHelpButton = useMemo(() => isPaymentMethodTypeCheck && (paymentStatus === 'ISSUED' || paymentStatus === 'PENDING'), [paymentStatus, isPaymentMethodTypeCheck]);
  const isDisabledCheckHelpButton = useMemo(() => !isPaymentMethodTypeCheck || paymentStatus === 'PENDING',
    [paymentStatus, isPaymentMethodTypeCheck]);
  const [activePanel, setActivePanel] = useState<PanelIndex[]>([]);
  const checkActivePanel = useCallback(
    (activePanelIndex: number) => (activePanel.some((el) => el === activePanelIndex)),
    [activePanel],
  );

  if (isLoading) {
    return (
      <Box pad="spacing24">
        <Preloader />
      </Box>
    );
  }

  if (paymentDetails.length) {
    return (
      <Content
        round="regular"
        background="canvas"
        border={{ size: 'small', color: 'border2' }}
        elevation="default"
        margin={{ top: 'spacing12' }}
      >
        {hasHelpButton ? (
          <Box justify="center" margin={{ top: 'spacing24' }} style={{ position: 'absolute', zIndex: '10', right: '24px' }}>
            <Box
              {...isDisabledCheckHelpButton ? {
                style: { cursor: 'not-allowed' },
              } : {
                onClick: () => setIsVisibleNeedHelpPopup(true),
              }}
            > 
              <Inscription color={isDisabledCheckHelpButton ? 'textDisabled' : 'textAccent'}>
                {t('I need help with my check')}
              </Inscription>
            </Box>
            <VoidCheckPopup
              id={checkId}
              paymentMethodId={props?.paymentMethodId}
              isVisible={isVisibleNeedHelpPopup}
              close={() => setIsVisibleNeedHelpPopup(false)}
              showReissueCheckPopup={() => setIsVisibleReissueCheckPopup(true)}
              refetch={refetch}
              isResentCheckAvailable={isResentCheckAvailable}
              mailingDate={mailingDate}
            />
            <ReissueCheckPopup
              isVisible={isVisibleReissueCheckPopup}
              close={() => setIsVisibleReissueCheckPopup(false)}
              refetch={refetch}
              check={{
                id: toString(checkId),
                paymentMethodId: props?.paymentMethodId,
              }}
            />
          </Box>
        ) : null}
        <Accordion pad="spacing24" onActive={setActivePanel}>
          <AccordionPanel
            header={(
              <Box
                direction="row"
                align="center"
                justify="between"
                {
                  ...checkActivePanel(PanelIndex.PaymentDetails)
                    ? {
                      border: { color: 'border1', side: 'bottom', size: 'small' },
                      pad: { bottom: 'spacing24' },
                    }
                    : {}
                }
              >
                <Box direction="row" align="center">
                  <IconWrap pad={{ right: 'spacing8' }}>
                    {checkActivePanel(PanelIndex.PaymentDetails)  ? (
                      <ChevronUpIcon size="20px" color="iconPrimary" />
                    )  : (
                      <ChevronDownIcon size="20px" color="iconPrimary" />
                    ) }
                  </IconWrap>
                  <Text size="large" weight="normal">Payment Details</Text>
                </Box>
              </Box>
            )}
          >
            {
              paymentDetails.map((details) => (
                <Box key={details.id} direction="row" pad={{ top: 'spacing24' }}>
                  <PaymentMethodDetails
                    method={{
                      type: details.paymentMethodType,
                      ...details.paymentMethodType === PaymentMethodType.DIRECT_DEPOSIT
                        ? {
                          accountNumber: Array.from(new Array(details.paymentRequisites?.accountNumberLength), () => '*').join(''),
                          accountDescription: details.paymentRequisites.accountDescription,
                          accountNumberLast4: details?.transfers?.accountNumberLast4,
                        }
                        : {
                          requisites: {
                            ...details.paymentRequisites,
                            phoneNumber: details?.paymentRequisites?.phoneNumber
                              ? phoneNumberCreator(details?.paymentRequisites?.phoneNumber)
                              : '',
                          },
                          address: details.paymentAddress,
                        },
                      isVerified: true,
                    }}
                  />
                  <DetailsWrap>
                    <FlexGrid
                      rows={['auto', 'auto']}
                      columns={['auto', 'auto']}
                      gap="small"
                      areas={[
                        { name: 'grid-1', start: [0, 0], end: [0, 1] },
                        { name: 'grid-2', start: [1, 0], end: [1, 1] },
                      ]}
                    >
                      <Box gridArea="grid-1">
                        <Box margin={{ bottom: 'spacing24' }}>
                          <Text color="textSecondary" size="small" weight={500}>Total Payment  Amount</Text>
                          <Text color="textBody">{formatCurrency(details.transfers.amount)}</Text>
                        </Box>
                        {details.paymentMethodType === PaymentMethodType.DIRECT_DEPOSIT ? (
                          <>
                            <Box margin={{ bottom: 'spacing24' }}>
                              <Text color="textSecondary" size="small" weight={500}>Trace Number</Text>
                              <Text color="textBody">{details?.transfers?.traceId || '-'}</Text>
                            </Box>
                            <Box>
                              <Text color="textSecondary" size="small" weight={500}>Invoice Number</Text>
                              <Text color="textBody">{details?.transfers?.invoiceId || '-'}</Text>
                            </Box>
                          </>
                        ) : (
                          <Box margin={{ bottom: 'spacing24' }}>
                            <Text color="textSecondary" size="small" weight={500}>Payment Number</Text>
                            <Text color="textBody">{details?.transfers?.paymentNumber || '-'}</Text>
                          </Box>
                        )}
                      </Box>
                      <Box gridArea="grid-2">
                        <Box margin={{ bottom: 'spacing24' }}>
                          <Text color="textSecondary" size="small" weight={500}>Payment Status</Text>
                          <Box direction="row" align="center">
                            <Box
                              width={{ width: '10px', min: '10px' }}
                              height="10px"
                              margin={{ right: 'spacing8' }}
                              round
                              background={checkStatusColor[details.transfers.paymentStatus as CheckStatus]}
                            />
                            <Text color="textBody">{capitalize(details.transfers.paymentStatus)}</Text>
                          </Box>
                        </Box>
                        <Box margin={{ bottom: 'spacing24' }}>
                          <Text color="textSecondary" size="small" weight={500}>Payment Date</Text>
                          <Text color="textBody">{dayjs(details.transfers.transactionDate).format(DEFAULT_CARD_EXP_DATE_FORMAT)}</Text>
                        </Box>
                        <Box>
                          <Text color="textSecondary" size="small" weight={500}>Pay To</Text>
                          <Text color="textBody">{details.payTo}</Text>
                        </Box>
                      </Box>
                    </FlexGrid>
                  </DetailsWrap>
                </Box>
              ))
            }
          </AccordionPanel>
        </Accordion>
      </Content>
    );
  }

  return null;
};

export default TransactionPaymentDetails;
