import { storeCreator } from '@/utils/store/store.utils';

import { InvestmentPathPayload } from '../../StartInvesting.types';

interface State {
  values: InvestmentPathPayload;
  handleSetValues: (value: Partial<InvestmentPathPayload>) => void;
  handleReset: () => void;
}

const DEFAULT_VALUES: InvestmentPathPayload = {
  investmentPath: '',
  termsCondition: false,
};

export const useInvestmentPathStore = storeCreator<State>((set) => ({
  values: DEFAULT_VALUES,
  handleSetValues: (value: Partial<InvestmentPathPayload>) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      ...value,
    },
  })),
  handleReset: () => set((state) => ({
    ...state,
    values: DEFAULT_VALUES,
  })),
}));
