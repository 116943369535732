import React, { useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,   Box, Inscription, Preloader,
} from '@common-fe/common-fe';

import useProcessWaitingForFundsClaimQuery from '@/modules/transaction/queries/useProcessWaitingForFundsClaim.query';

import Activities from './Activities';
import { StyledAppButton } from './EmployeeChoice.styles';
import Wrapper from './Wrapper';

const REFUSAL_APP_BUTTON_COLOR = 'red';

interface Props {
  employeeId?: string;
  claimId?: string;
}

const EmployeeChoice: React.FC<Props> = ({ employeeId, claimId }) => {
  const { t } = useTranslation();
  const { processWaitingForFundsClaim, isLoading } = useProcessWaitingForFundsClaimQuery(claimId);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isAgreement, setIsAgreement] = useState<boolean | undefined>();
  const isOptionSelected = useMemo(() => typeof isAgreement === 'boolean', [isAgreement]);

  if (!employeeId || !claimId) return null;

  return (
    <Wrapper title={t('Would you like to continue paying this claim?')}>
      <Box
        direction="row"
        justify="between"
        gap="spacing12"
        width="100%"
        margin={{ top: 'spacing24' }}
      >
        <Box basis="1/2">
          <StyledAppButton
            testId="employee-choice-yes"
            width="100%"
            size="L"
            buttonType="secondary"
            active={isAgreement === true}
            onClick={() => setIsAgreement(true)}
          >
            {t('Yes')}
          </StyledAppButton>
        </Box>
        <Box basis="1/2">
          <StyledAppButton
            testId="employee-choice-no"
            width="100%"
            size="L"
            buttonType="secondary"
            active={isAgreement === false}
            onClick={() => setIsAgreement(false)}
            color={REFUSAL_APP_BUTTON_COLOR}
          >
            {t('No')}
          </StyledAppButton>
        </Box>
      </Box>

      {isOptionSelected ? (
        <>
          <Activities
            employeeId={employeeId}
            forClaimId={claimId}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />

          <Box
            margin={{ top: 'spacing24' }}
            pad={{ vertical: 'spacing24' }}
            border={{
              side: 'horizontal',
              size: 'xsmall',
              color: 'border1',
            }}
            width="100%"
          >
            <Inscription
              weight="bold"
              size="16px"
              lineHeight="22px"
            >
              {t(isAgreement
                ? 'Any future funds will automatically pay for this claim.'
                : 'The claim will not be paid and no further action can be taken.')}
            </Inscription>
          </Box>
        </>
      ) : null}

      <Box margin={{ top: 'spacing24' }} direction="row" width="100%">
        <AppButton
          testId="employee-choice-submit"
          width="100%"
          size="L"
          disabled={!isOptionSelected}
          {
            ...{
              ...isLoading || !isOptionSelected ? {} : {
                onClick: () => processWaitingForFundsClaim({
                  isAutopay: Boolean(isAgreement),
                  claimsIds: selectedIds,
                }),
              },
            }
          }
        >
          {
            isLoading ? (
              <Preloader />
            ) : t(isOptionSelected ? 'Submit' : 'Select an Option')
          }
        </AppButton>
      </Box>
    </Wrapper>
  );
};

export default EmployeeChoice;
