import React from 'react';
import { RouteProps } from 'react-router';

import ROUTES from '@/common/routes';

import AccountContributionPage from './AccountContribution.page';
import AutoInvestmentsPage from './AutoInvestments.page';
import BuySellPage from './BuySell.page';
import CardDetailsPage from './CardDetails.page';
import CardsPage from './Cards.page';
import DeductiblesPage from './Deductibles.page';
import ErrorPage from './Error.page';
import ExpensesDetailsPage from './ExpensesDetails.page';
import HelpPage from './Help.page';
import { Home } from './Home.page';
import InvestmentsPage from './Investments.page';
import LoginPage from './Login.page';
import ManageAccountPage from './ManageAccount.page';
import ManagedSellInvestmentsPage from './ManagedSellInvestments.page';
import ManagedSwitchAllocationPage from './ManagedSwitchAllocation.page';
import ManagedSwitchFundingAmountPage from './ManagedSwitchFundingAmount.page';
import ManagedSwitchQuestionnairePage from './ManagedSwitchQuestionnaire.page';
import MyAccountsPage from './MyAccounts.page';
import MyAccountsGeneralPage from './MyAccountsGeneral.page';
import NotificationsPage from './Notifications.page';
import OneTimeLinkUploadPage from './OneTimeLinkUpload.page';
import PollPage from './Poll.page';
import ReimburseMePage from './ReimburseMe.page';
import RequestBillPayPage from './RequestBillPay.page';
import ScanDocumentPage from './ScanDocument.page';
import SelfDirectedPage from './SelfDirected.page';
import SelfDirectedAllocationPage from './SelfDirectedAllocation.page';
import SelfDirectedInvestMorePage from './SelfDirectedInvestMore.page';
import SelfDirectedQuestionnairePage from './SelfDirectedQuestionnaire.page';
import SelfDirectedSellInvestmentsPage from './SelfDirectedSellInvestments.page';
import SelfDirectedSwitchAllocationPage from './SelfDirectedSwitchAllocation.page';
import SelfDirectedSwitchSelectPage from './SelfDirectedSwitchSelect.page';
import SignUpPage from './SignUp.page';
import StartInvestingPage from './StartInvesting.page';
import StatementsPage from './Statements.page';
import TransactionDetailsPage, { TransactionPageType } from './TransactionDetails.page';
import UpdateRiskPortfolioPage from './UpdateRiskPortfolio.page';
import UploadFileByQrLinkPage from './UploadFileByQrLink.page';
import UserActivationPage from './UserActivation.page';
import VerificationPage from './Verification.page';

type Route = RouteProps & {
  authed?: boolean;
};

export const GLOBAL_ROUTES: Route[] = [{
  path: ROUTES.LOGIN,
  component: LoginPage,
},
{
  path: ROUTES.TRANSACTION_DETAILS_POSTING(),
  component: (props: React.ComponentType<RouteProps>) => (
    <TransactionDetailsPage {...props} transactionPageType={TransactionPageType.ACCOUNT} />
  ),
  authed: true,
},

{
  path: ROUTES.POLL,
  component: PollPage,
  authed: true,
},

{
  path: ROUTES.ID_SCAN(),
  component: ScanDocumentPage,
  authed: true,
},

{

  path: ROUTES.TRANSACTION_DETAILS_CLAIM(),
  component: (props: React.ComponentType<RouteProps>) => (
    <TransactionDetailsPage {...props} transactionPageType={TransactionPageType.ACCOUNT} />
  ),
  authed: true,
},
{
  path: ROUTES.EXPENSES_TRANSACTION_DETAILS_CLAIM(),
  component: (props: React.ComponentType<RouteProps>) => (
    <TransactionDetailsPage {...props} transactionPageType={TransactionPageType.EXPENSE} />
  ),
  authed: true,
},
{
  path: ROUTES.EXPENSES_TRANSACTION_DETAILS_POSTING(),
  component: (props: React.ComponentType<RouteProps>) => (
    <TransactionDetailsPage {...props} transactionPageType={TransactionPageType.EXPENSE} />
  ),
  authed: true,
},
{
  path: ROUTES.USER_ACTIVATION,
  component: UserActivationPage,
}, {
  path: ROUTES.SPENDS_BY_ID(),
  component: DeductiblesPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.SIGN_UP,
  component: SignUpPage,
},
{
  path: ROUTES.SIGN_UP_ORPHAN,
  component: SignUpPage,
},
{
  path: ROUTES.DOWNLOAD_FILE_BY_QR_LINK,
  component: UploadFileByQrLinkPage,
  exact: true,
},
{
  path: ROUTES.ONE_TIME_LINKS,
  component: OneTimeLinkUploadPage,
  exact: true,
},
{
  path: ROUTES.VERIFY,
  component: VerificationPage,
},
{
  path: ROUTES.CHANGE_PASSWORD,
  component: LoginPage,
}, {
  path: ROUTES.HELP,
  component: HelpPage,
},
{
  path: ROUTES.ERROR,
  component: ErrorPage,
},

{
  path: ROUTES.NOTIFICATIONS,
  authed: true,
  component: NotificationsPage,
},
{
  path: ROUTES.EXPENSE_DETAILS,
  authed: true,
  component: ExpensesDetailsPage,
},
{
  path: ROUTES.REIMBURSE_ME,
  authed: true,
  component: ReimburseMePage,
},
{
  path: ROUTES.AUTO_INVESTMENTS,
  authed: true,
  component: AutoInvestmentsPage,
},
{
  path: ROUTES.REQUEST_BILL_PAY,
  authed: true,
  component: RequestBillPayPage,
},
{
  path: `${ROUTES.START_INVESTING}`,
  authed: true,
  component: StartInvestingPage,
},
{
  path: ROUTES.MANAGED_SWITCH_ALLOCATION(),
  authed: true,
  component: ManagedSwitchAllocationPage,
},
{
  path: ROUTES.MANAGED_SWITCH_FUNDING_AMOUNT(),
  authed: true,
  component: ManagedSwitchFundingAmountPage,
},
{
  path: ROUTES.MANAGED_SWITCH_QUESTIONNAIRE(),
  authed: true,
  component: ManagedSwitchQuestionnairePage,
},
{
  path: ROUTES.MANAGED_SELL_INVESTMENTS(),
  authed: true,
  component: ManagedSellInvestmentsPage,
},
{
  path: ROUTES.MANAGE_PROFILE,
  authed: true,
  component: ManageAccountPage,
},
{
  path: ROUTES.SELF_DIRECTED_SELL_INVESTMENTS(),
  authed: true,
  component: SelfDirectedSellInvestmentsPage,
},
{
  path: ROUTES.SELF_DIRECTED_QUESTIONNAIRE(),
  authed: true,
  component: SelfDirectedQuestionnairePage,
},
{
  path: ROUTES.SELF_DIRECTED_INVEST_MORE(),
  authed: true,
  component: SelfDirectedInvestMorePage,
},
{
  path: ROUTES.SELF_DIRECTED_SWITCH_ALLOCATION(),
  authed: true,
  component: SelfDirectedSwitchAllocationPage,
},
{
  path: ROUTES.SELF_DIRECTED_SWITCH_SELECT(),
  authed: true,
  component: SelfDirectedSwitchSelectPage,
},
{
  path: ROUTES.SELF_DIRECTED_ALLOCATION(),
  authed: true,
  component: SelfDirectedAllocationPage,
},
{
  path: ROUTES.SELF_DIRECTED(),
  authed: true,
  component: SelfDirectedPage,
},
{
  path: `${ROUTES.BUY_SELL}`,
  authed: true,
  component: BuySellPage,
},

{
  path: ROUTES.CONTRIBUTION(),
  authed: true,
  component: AccountContributionPage,
},

{
  path: ROUTES.MY_ACCOUNTS_DETAILS(),
  authed: true,
  component: MyAccountsPage,
},
{
  path: ROUTES.MY_ACCOUNTS,
  authed: true,
  component: MyAccountsGeneralPage,
},

{
  path: ROUTES.INVESTMENTS,
  authed: true,
  component: InvestmentsPage,
  exact: true,
},
{
  path: ROUTES.INVESTMENTS_UPDATE_RISK_PORTFOLIO,
  authed: true,
  component: UpdateRiskPortfolioPage,
},
{
  path: ROUTES.CARDS,
  authed: true,
  component: CardsPage,
  exact: true,
},
{
  path: `${ROUTES.CARD_DETAILS}`,
  authed: true,
  component: CardDetailsPage,
  exact: true,
},
{
  path: ROUTES.STATEMENTS(),
  authed: true,
  component: StatementsPage,
},
{
  path: ROUTES.HOME,
  component: Home,
  authed: true,
}];
