import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';

import { useStore as useInvestmentProfileStore } from '../../stores/useInvestmentProfile.store';

export default () => {
  const { t } = useTranslation();
  const TOLERANCE_MODEL_OPTIONS = useMemo(
    () => [
      {
        label: t('Conservative'),
        value: 'CONSERVATIVE',
      },
      {
        label: t('Moderately conservative'),
        value: 'MODERATELY_CONSERVATIVE',
      },
      {
        label: t('Moderate'),
        value: 'MODERATE',
      },
      {
        label: t('Moderately aggressive'),
        value: 'MODERATELY_AGGRESSIVE',
      },
      {
        label: t('Aggressive'),
        value: 'AGGRESSIVE',
      },
    ], [t],
  );
  const defaultFields = useInvestmentProfileStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        name: 'toleranceModel',
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: t('How would you best describe your risk tolerance?'),
        placeholder: '',
        options: TOLERANCE_MODEL_OPTIONS,
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]), [TOLERANCE_MODEL_OPTIONS, t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
