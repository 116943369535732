import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, GoPaperlessIcon } from '@common-fe/common-fe';

import { Banner } from '@/modules/core/components/Dashboard/components/BannersList/Banner';
import { BannerStorageKey } from '@/modules/core/components/Dashboard/components/BannersList/bannersList.constants';
import useDismissBanner from '@/modules/core/components/Dashboard/components/BannersList/hooks/useDismissBanner';

interface Props {
  handleOpenModal: () => void;
}

const GoPaperlessBanner: React.FC<Props> = ({ handleOpenModal }) => {
  const { t } = useTranslation();
  const { handleDismissBanner } = useDismissBanner();

  const handleDismiss = useCallback(() => {
    handleDismissBanner(BannerStorageKey.GoPaperlessBanner);
  }, [handleDismissBanner]);

  return (
    <Banner
      title={t('Go Paperless')}
      description={t('Enjoy the convenience of paperless transactions and reduce your environmental impact.')}
      icon={<GoPaperlessIcon />}
      testId="go-paperless"
    >
      <Box margin={{ top: 'spacing24' }} direction="row">
        <Box>
          <AppButton
            testId="open-popup"
            onClick={handleOpenModal}
            width="180px"
          >
            {t('Sign Up')}
          </AppButton>
        </Box>

        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            testId="dismiss-banner"
            buttonType="secondary"
            width="180px"
            onClick={handleDismiss}
          >
            {t('Dismiss')}
          </AppButton>
        </Box>
      </Box>
    </Banner>
  );
};

export default GoPaperlessBanner;
