import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { onlyDateFromServer } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { toString } from 'lodash';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import { useAuthStore } from '@/modules/user/stores';

export enum ProofOfExpensesTypes {
  RECEIPT_REQUIRED = 'RECEIPT_REQUIRED',
  NO_RECEIPT_REQUIRED = 'NO_RECEIPT_REQUIRED',
  CERTIFICATION_REQUIRED = 'CERTIFICATION_REQUIRED',
}

interface CoveragePeriod {
  coverage_date_end?: string;
  coverage_date_start?: string;
}

interface AccountOrderPayload {
  account_id: number;
  order: number;
  available_balance: number;
  proof_of_expenses_type: ProofOfExpensesTypes;
  coverage_periods?: CoveragePeriod[];
  submit_claims_date_end: string;
}

export interface AccountOrder {
  accountId: string;
  order: number;
  availableBalance: number;
  proofOfExpensesType: ProofOfExpensesTypes;
  coverageDateStart?: string;
  coverageDateEnd?: string;
  submitClaimsDateEnd?: string;
}

const formatData = (accountOrders: AccountOrderPayload[]): AccountOrder[] => accountOrders
  .map((accountOrder) => ({
    accountId: toString(accountOrder.account_id),
    order: accountOrder.order,
    availableBalance: accountOrder.available_balance,
    proofOfExpensesType: accountOrder.proof_of_expenses_type,
    coverageDateStart: onlyDateFromServer(accountOrder?.coverage_periods?.find((item) => item?.coverage_date_start)?.coverage_date_start),
    coverageDateEnd: onlyDateFromServer(accountOrder?.coverage_periods?.find((item) => item?.coverage_date_end)?.coverage_date_end),
    submitClaimsDateEnd: onlyDateFromServer(accountOrder.submit_claims_date_end),
  })).sort((a, b) => a.order - b.order);

const useAccountOrdersQuery = (
  serviceId: string,
  serviceOn: string,
  enabled: boolean,
) => {
  const { user } = useAuthStore();
  const employeeId = toString(user?.employee?.id);
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    [
      PATHS.ACCOUNT_ORDERS,
      serviceId,
      employeeId,
      serviceOn,
    ],
    () => api.get(PATHS.ACCOUNT_ORDERS, {
      params: {
        service_id: serviceId,
        service_on: dayjs(serviceOn).format(DEFAULT_DATE_FORMAT),
        employee_id: employeeId,
      },
    }),
    { enabled: Boolean(serviceId) && Boolean(employeeId) && enabled },
  );

  const accountOrders = useMemo(
    () => formatData(data?.data?.flat().flat() || []), [data],
  );
  return ({
    accountOrders,
    isError,
    isLoading,
    isSuccess,
  });
};

export default useAccountOrdersQuery;
