import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, UploadIcon } from '@common-fe/common-fe';

import { FileGetAndUploadButton } from '@/modules/transaction/components/UploadFileByLink/FileGetAndUploadButton';
import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';
import UploadFileByLinkInfoContainer from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkInfoContainer';

const BUTTON_WIDTH = '320px';

interface Props {
  onSelectFile: (file: File | undefined) => void;
}

const ErrorScreen: React.FC<Props> = ({ onSelectFile }) => {
  const { t } = useTranslation();

  return (
    <UploadFileByLinkContentWrapper>
      <UploadFileByLinkInfoContainer
        title="File upload failed"
        description="Something went wrong. Please upload another document or contact support."
        iconType="error"
      />
      <Box width={BUTTON_WIDTH} margin={{ top: 'spacing24' }}>
        <FileGetAndUploadButton
          onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectFile(e?.target?.files?.[0])}
          title={t('Upload Another Document')}
          id="Camera"
          cameraInput
          isRightIconPosition
          icon={<UploadIcon color="canvas" />}
        />
      </Box>
    </UploadFileByLinkContentWrapper>
  );
};

export default ErrorScreen;
