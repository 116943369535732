import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { ServiceCategoryTypes,ServiceCategoryTypesPayload } from '../types/ServiceCategories.types';

export const getCategories = () => api
  .get<ServiceCategoryTypesPayload[]>(PATHS.SERVICE_CATEGORIES);

export const QUERY_KEY = 'getServiceCategories';
const useServiceCategoriesQuery = () => {
  const {
    data, isLoading,
  } = useQuery(
    [QUERY_KEY, getCategories], getCategories,

  );

  const serviceCategories = useMemo(() => data?.data.map((item): ServiceCategoryTypes => ({
    id: toString(item.id),
    name: item.name,
    description: item.description,
  })) || [], [data]);

  return { isLoading, serviceCategories };
};

export default useServiceCategoriesQuery;
