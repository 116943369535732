import React, { useMemo } from 'react';
import { Box, Text, } from '@common-fe/common-fe';

import StatusIcon from '@/components/elements/StatusIcon';
import { Notification, PaymentType, Service,TransactionApprovalCode,TransactionStatus } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import { IconWrapper, List, Title, Wrapper } from '../../TransactionNotification.styles';

import useGetNeedHelpsNotificationMessage from './useGetNeedHelpsNotificationMessage';

const ALLOWED_APPROVAL_CODES = [
  TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES,
  TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES,
  TransactionApprovalCode.SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD,
  // TransactionApprovalCode.REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT,
  TransactionApprovalCode.CLAIM_RECEIVED_AFTER_RUNOUT_DATE,
  TransactionApprovalCode.DETAILED_RECEIPT_REQUIRED,
  TransactionApprovalCode.PROOF_OF_EXPENSE_NEEDED,
  TransactionApprovalCode.ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION,
  TransactionApprovalCode.CLAIM_COULD_NOT_BE_AUTO_SUBSTANTIATED_BY_CARD_PROCESSOR,
  TransactionApprovalCode.LETTER_OF_MEDICAL_NECESSITY_REQUIRED,
  TransactionApprovalCode.CLAIM_SUBMITTED_AFTER_GRACE_PERIOD,
  TransactionApprovalCode.DUPLICATE_CLAIM,
  TransactionApprovalCode.CUSTOM,
];

export const hasNeedHelpsNotificationMessage = (data?: Notification) => data?.approvalCode && ALLOWED_APPROVAL_CODES.includes(data.approvalCode);

interface Props {
  data?: Notification;
  employeeFirstName?: string;
  uploadDocumentButton?: React.ReactNode;
  paymentType: PaymentType;
  service?: Service;
  serviceOn?: string;
}

const NeedHelpsNotificationMessage: React.FC<Props> = ({
  data,
  employeeFirstName,
  uploadDocumentButton,
  paymentType,
  service,
  serviceOn,
}) => {
  const { mainText, text, list, isUploadDocument } = useGetNeedHelpsNotificationMessage({
    data, paymentType, employeeFirstName, service, serviceOn,
  });

  const listItems = useMemo(() => {
    if (list) {
      return list.map((item) => <li key={item}>{item}</li>);
    }
    return null;
  }, [list]);

  if (!hasNeedHelpsNotificationMessage(data)) return null;

  return (
    <Wrapper
      warning
      data-testid={`denied-approvalCode-${data?.approvalCode}`}
      flex-direction="column"
      background="canvas"
      border={{
        size: 'small',
        color: 'warningBorder',
      }}
      elevation="default"
      pad={{
        top: 'l',
        right: 'l',
        bottom: 'l',
        left: '56px',
      }}
    >
      <IconWrapper><StatusIcon status={TransactionStatus.NeedsHelp} /></IconWrapper>
      {mainText && typeof mainText === 'string' ? <Title data-testid="main-text">{mainText}</Title> : null}
      {typeof mainText === 'object' ? mainText : null}
      {listItems && <List>{listItems}</List>}
      {text && typeof text === 'string' && (
        <Text size={theme.spacings.m} {...mainText ? { margin: { top: 'xs' }} : {}}>
          {text}
        </Text>
      )}
      {typeof text === 'object' ? text : null}
      {isUploadDocument && uploadDocumentButton ? (
        <Box margin={{ top: 'l', left: '-32px' }} direction="row">
          {uploadDocumentButton}
        </Box>
      ) : null}
    </Wrapper>
  );
};

export default NeedHelpsNotificationMessage;
