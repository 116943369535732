import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, costFormater,Modal, Preloader, Text, WarningIcon } from '@common-fe/common-fe';
import _ from 'lodash';

import { ValuesMap } from '@/common/types';
import { FundsPayload } from '@/modules/investments/SelfDirected/SelfDirected.types';

import { PERCENTS_LIQUIDATION_LIMIT } from '../Funds.constants';

import ConfirmModalItem from './ConfirmModalItem';
import ConfirmModalListHeader from './ConfirmModalListHeader';

interface Props {
  list: FundsPayload[];
  isLoading?: boolean;
  values: ValuesMap<number>;
  liquidationValues: ValuesMap<boolean>;
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit:() => void;
}
const ConfirmModal: React.FC<Props> = ({isLoading, visible, onSetVisible, list, values, onSubmit, liquidationValues}) => {
  const { t } = useTranslation();
  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const getCurrentValue = useCallback(( item: FundsPayload) => {
    const currentValue = values[item.id] || 0;
    if(liquidationValues[item.id]) {
      return item.amount || currentValue || 0;
    }
    const percents =  (item.amount || 0 )/ currentValue;
    if(_.isNaN(percents)) {
      
      return currentValue || item.amount || 0 ;
    }
    if(percents >= PERCENTS_LIQUIDATION_LIMIT) {
      return currentValue ||  item.amount  || 0 ;
    }
    return currentValue;
  }, [liquidationValues, values]);

  const filteredList = useMemo(() => {
    const existingIds = Object.keys(values).map((item) => liquidationValues[item] || values[item] > 0  ? item : null);
    return list.filter((item) => existingIds.includes(item.id));
  }, [list, values, liquidationValues]);

  const totalAmount = useMemo(() => {
    return  filteredList.reduce((result, item) => {
      return result + getCurrentValue(item);
    }, 0);
  }, [getCurrentValue, filteredList]);



  return (
    <Modal
      visible={visible}
      onSetVisible={onSetVisible}
      wrapperStyle={{zIndex: 1000}}
    >
      <Box direction='column'>
        <Text size="2xl" textAlign='center' weight={700}>
          {t('Confirm')}
        </Text>
      </Box>
      <Box direction='column' background="module" pad="l" round="moduleRound">
        <ConfirmModalListHeader />
        <Box direction='column'>
          {filteredList.map((item) => (
            <ConfirmModalItem value={getCurrentValue(item)} key={item.id} {...item}/>
          ))}
        
        </Box>
        <Box 
          direction='row'
          pad={{vertical: '6px', horizontal: 'l'}}
          background="border1"
          margin={{top: 'xs', bottom: 'l'}}
          round="container2Round"
          justify="between">
          <Text size="medium" weight={700} style={{flex: 2}}>{t('Total')}</Text>
          <Box width="56px"></Box>
          <Box direction='row' style={{flex: 2}}>
      
            <Box>
              <Text weight={700}>{
                costFormater(
                  totalAmount,
                  {
                    isPrecision: true
                  })
            
              }
              </Text>
            </Box>
            
          </Box>
        </Box>

        <Box background="canvas" 
          border={{color: 'border2', size: 'small'}}
          round="container1Round"
          pad="s"
          elevation='default'
          style={{position: 'relative'}}>
        
          <Box  height="2px"
            round="small"
            background="warning" 
            style={{position: 'absolute', top: '-0.5px', left: '12px', right: '12px'}}>

          </Box>
          <Box 
            direction='row'
            align='center'
            background="warningContainer"
            border={{color: 'warning', size: 'small'}}
            round='container2Round'
            pad={{vertical: 'm', horizontal: 'l'}}>
            <div>
              <Box>
                <WarningIcon />
              </Box>
            </div>
            <Text margin={{left: 'xs'}}>
              {t('Sells can take up to 2 to 3 business days to be reflected in your account. Any automatic investments will be paused.')}
            </Text>
          </Box>
          

          <Box 
            direction='row'
            margin={{top: '10px'}}
            align='center'
            background="warningContainer"
            border={{color: 'warning', size: 'small'}}
            round='container2Round'
            pad={{vertical: 'm', horizontal: 'l'}}>
            <div>
              <Box>
                <WarningIcon />
              </Box>
            </div>
            <Text margin={{left: 'xs'}}>
              {t('New investment purchases will be temporarily restricted until the sell order is settled.')}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box direction='row' justify='end' margin={{top: 'l'}}>
        <Box>
          <AppButton
            buttonType="secondary"
            width="140px"
            onClick={handleClose}
          >
            {t('Back')}
          </AppButton>
        </Box>
        <Box margin={{ left: 's' }}>
          <AppButton disabled={isLoading}  width="140px" onClick={onSubmit}>
            {isLoading ? <Preloader color="white" /> :t('Confirm')}
          </AppButton>
        </Box>
      </Box>

    </Modal>
  );
};


export default ConfirmModal;