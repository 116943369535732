import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { formatSnakeCaseToCamelCase } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { PaymentDetails, PaymentDetailsResponse } from '../../TransactionDetails.types';

interface Args {
  claimId?: string;
  postingId?: string;
}
export const useGetPaymentDetails = (params: Args) => {
  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error,
  } = useQuery(
    PATHS.CLAIMS_PAYMENT_DETAILS,
    () => (
      params.claimId
        ? api.get<PaymentDetailsResponse>(PATHS.CLAIMS_PAYMENT_DETAILS, {
          params: {
            claim_id: params.claimId,
          },
        }) : null),
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    },
  );
  const formattedData = useMemo(() => (
    (data?.data.transfers || []).map((transfer) => ({
      id: _.uniqueId(),
      paymentType: data?.data.payment_type,
      paymentMethodType: data?.data.payment_method_type,
      payTo: data?.data.pay_to,
      paymentAddress: data?.data.payment_address,
      paymentRequisites: Object.keys(data?.data.payment_requisites || {})
        .reduce((requisite, key) => ({
          ...requisite,
          // @ts-ignore
          [formatSnakeCaseToCamelCase(key)]: data?.data.payment_requisites[key],
        }), {} as PaymentDetails['paymentRequisites']),
      transfers: {
        amount: transfer.amount,
        paymentStatus: transfer.payment_status,
        paymentNumber: transfer.payment_number,
        transactionDate: transfer.transaction_date,
        mailingDate: transfer?.mailing_date,
        invoiceId: _.toString(transfer?.invoice_id),
        traceId: _.toString(transfer?.trace_id),
        accountNumberLast4: transfer?.account_number_last4,
      },
    } as PaymentDetails))
  ), [data]);

  useEffect(() => {
    if (params.claimId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.claimId]);

  return {
    paymentDetails: params.postingId ? [] : formattedData,
    isLoading,
    isFetching,
    refetch,
    error,
  };
};
