import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, costFormater,Modal, Preloader, Text, WarningIcon } from '@common-fe/common-fe';

import { useActiveFunds } from '@/modules/investments/Investments/hooks';

import ConfirmModalItem from './ConfirmModalItem';
import ConfirmModalListHeader from './ConfirmModalListHeader';

interface Props {
accountId: string;  
  visible: boolean;
  isLoading?: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit:() => void;
}
const ConfirmFullLiquidation: React.FC<Props> = ({ 
  visible, onSetVisible, onSubmit,
  accountId,isLoading
}) => {
  const { t } = useTranslation();
  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const { data } = useActiveFunds({ accountId});
  const filtetedData = useMemo(() => {
    return data.filter((item) => item.amount);
  }, [data]);
  const totalAmount = useMemo(() => {
    return  data.reduce((result, item) => {
      return result + (item.amount || 0);
    }, 0);
  }, [data]);

  return (
    <Modal
      visible={visible}
      onSetVisible={onSetVisible}
    >
      <Box direction='column'>
        <Text size="2xl" textAlign='center' weight={700}>
          {t('Confirm')}
        </Text>
      </Box>
      <Box direction='column' background="module" pad="l" round="moduleRound">
        <ConfirmModalListHeader />
        <Box direction='column'>
          {filtetedData.map((item) => (
            <ConfirmModalItem value={item.amount || 0} key={item.id} {...item}/>
          ))}
        
        </Box>
        <Box 
          direction='row'
          pad={{vertical: '6px', horizontal: 'l'}}
          background="border1"
          margin={{top: 'xs', bottom: 'l'}}
          round="container2Round"
          justify="between">
          <Text size="medium" weight={700} style={{flex: 2}}>{t('Total')}</Text>
          <Box width="56px"></Box>
          <Box direction='row' style={{flex: 2}}>
      
            <Box>
              <Text weight={700}>{
                costFormater(
                  totalAmount,
                  {
                    isPrecision: true
                  })
            
              }
              </Text>
            </Box>
            
          </Box>
        </Box>

        <Box background="canvas" 
          border={{color: 'border2', size: 'small'}}
          round="container1Round"
          pad="s"
          elevation='default'
          style={{position: 'relative'}}>
        
          <Box  height="2px"
            round="small"
            background="warning" 
            style={{position: 'absolute', top: '-0.5px', left: '12px', right: '12px'}}>

          </Box>
          <Box 
            direction='row'
            align='center'
            background="warningContainer"
            border={{color: 'warning', size: 'small'}}
            round='container2Round'
            pad={{vertical: 'm', horizontal: 'l'}}>
            <div>
              <Box>
                <WarningIcon />
              </Box>
            </div>
            <Text margin={{left: 'xs'}}>
              {t('Sells can take up to 2 to 3 business days to be reflected in your account. Any automatic investments will be paused.')}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box direction='row' justify='end' margin={{top: 'l'}}>
        <Box>
          <AppButton
            buttonType="secondary"
            width="140px"
            onClick={handleClose}
          >
            {t('Back')}
          </AppButton>
        </Box>
        <Box margin={{ left: 's' }}>
          <AppButton disabled={isLoading}  width="140px" onClick={onSubmit}>
            {
              isLoading 
                ? <Preloader color="white" />
                : t('Confirm')
            }
          </AppButton>
        </Box>
      </Box>

    </Modal>
  );
};


export default ConfirmFullLiquidation;