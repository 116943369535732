import { Carousel } from 'react-responsive-carousel';
import { Box, RawButton } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledCarouselWrapper = styled(Box)`
  position: relative;
  margin: 0 -${({ theme }) => theme.spacings.spacing8} ${({ theme }) => theme.spacings.spacing24};
`;

export const StyledCarousel = styled(Carousel)`
  position: relative;
  z-index: 1;
  &:after,
  &:before {
    height: 100%;
    width: 50px;
    content: '';
    display: block;
    position: absolute;
    z-index: 1000;
    top: 0;
  }

  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 52%, rgba(247, 249, 250, 1) 100%);
  }
  
  &:before {
    display: none;
    left: 0;
    background: linear-gradient(90deg, rgba(247, 249, 250, 1) , rgba(255,255,255,0) 52% 100%);
  }
  &.passed {
    &:before {
      display: block;
    }
  } 
`;

export const StyledCardItemWrapper = styled(Box)`
  box-shadow: none;
  border-width: 2px;
`;

export const StyledActionsCarousel = styled(Carousel)`
  background: ${({ theme }) => theme.colors.canvas};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  ${({ theme }) => theme.shadows.default}
`;



export const StyledCarouselButton = styled(RawButton)`
  background: ${({ theme }) => theme.colors.iconOnColor};
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.rounds.button1Round} ;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.border2};;
  &:disabled {
    svg {
      fill: ${({ theme }) => theme.colors.iconSecondary}
    }
  }
`;
