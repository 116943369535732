import React, { useMemo } from 'react';
import { Box, Inscription, ListItem, ListItemType } from '@common-fe/common-fe';
import { toNumber } from 'lodash';

import PaymentFreeTimeLabel from '@/components/elements/PaymentFreeTimeLabel';
import PaymentTypeIcon from '@/components/elements/PaymentTypeIcon';
import PaymentMethodDescription from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/PaymentMethodLabel/PaymentMethodDescription';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import theme from '@/styles/theme';
import whiteLabelingConfig, { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

import { getPaymentMethodDetails } from '../getPaymentMethodName';
import PaymentTypeIconWrapper from '../PaymentTypeIconWrapper';

const isRmrTheme = THEMES.rmr === whiteLabelingConfig.title;
const hasLabel = (type?: PaymentMethodType) => isRmrTheme && (type === PaymentMethodType.VENMO
  || type === PaymentMethodType.DEBIT || type === PaymentMethodType.PAYPAL);
const highlightedRowStyle = {
  borderSize: '2px',
  borderColor: theme.colors.accentActive,
  boxShadow: theme.shadows.highlighted['box-shadow'],
};

const usePaymentMethodsList = (paymentMethods?: PaymentMethod[], highlightedPaymentMethodId?: string) => {
  const sortedPaymentMethods: PaymentMethod[] = useMemo(() => {
    if (!paymentMethods?.length) {
      return [];
    }

    return [...paymentMethods].sort((prev, next) => toNumber(prev.id) - toNumber(next.id));
  }, [paymentMethods]);

  const formattedList = useMemo<ListItem[]>(
    () =>
      sortedPaymentMethods.map((paymentMethod) => ({
        id: paymentMethod.id,
        rowStyle: (highlightedPaymentMethodId && highlightedPaymentMethodId === paymentMethod.id) ? highlightedRowStyle : undefined,
        dropAlign: { bottom: 'top', right: 'right' },
        fields: [
          {
            key: 'paymentType',
            type: ListItemType.Node,
            title: paymentMethod?.type || '',
            node: (
              <Box margin={{ right: 'spacing32' }} direction="row" align="center">
                <PaymentTypeIconWrapper>
                  <PaymentTypeIcon paymentType={paymentMethod?.type} />
                </PaymentTypeIconWrapper>
                <Inscription
                  margin={{ left: 'spacing12' }}
                  size="16px"
                >
                  {getPaymentMethodDetails(paymentMethod)?.typeName}
                </Inscription>
                {hasLabel(paymentMethod?.type) ? (
                  <Box margin={{ left: 'spacing8' }}>
                    <PaymentFreeTimeLabel />
                  </Box>
                ) : null}
              </Box>
            ),
            flex: 5,
          },
          {
            key: 'additionalInfo',
            type: ListItemType.Node,
            title: paymentMethod?.accountDescription || '',
            node: (
              <Box margin={{ right: 'spacing32' }}>
                <PaymentMethodDescription paymentMethod={paymentMethod} hasPaymentFreeTimeLabel={false} />
              </Box>
            ),
            flex: 6,
            style: { fontSize: theme.fonts.text16Medium['font-size'] },
          },
          {
            key: 'isDefault',
            type: ListItemType.Text,
            title: paymentMethod.isDefault ? 'Yes' : '',
            flex: 4,
            style: { fontSize: theme.fonts.text16Medium['font-size'] },
          },
        ],
      })),
    [sortedPaymentMethods, highlightedPaymentMethodId]
  );

  return formattedList;
};

export default usePaymentMethodsList;
