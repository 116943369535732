import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Inscription,
  Preloader,
  WarningCard,
  WarningIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';

interface Props {
  title: string;
  subTitle: string;
  warningText: React.ReactNode;
  form: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
  onSetVisible: (value: boolean) => void;
  onNext: () => void;
  onCancel: () => void;
}

const VerificationModal: React.FC<Props> = ({
  title, subTitle, warningText, form, isLoading, isError, onSetVisible, onNext, onCancel
}) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper visible onSetVisible={onSetVisible} title={title}>
      <Inscription textAlign="center">{subTitle}</Inscription>

      <Box
        margin={{ top: 'spacing16' }}
        background="module"
        pad="spacing24"
        round="moduleRound"
      >
        <Box elevation='default' background="canvas" round="container1Round">
          {form}
        </Box>

        <Box margin={{ top: 'spacing24' }}>
          <WarningCard
            icon={<WarningIcon />}
            title={warningText}
            background="canvas"
            topBorderMargin="spacing12"
            pad="spacing12"
            elevation='default'
            containerProps={{ 
              pad: { vertical: 'spacing16', horizontal: 'spacing24' },
            }}
          />
        </Box>
      </Box>

      <Box margin={{ top: 'spacing24' }}>
        {isError && (
          <Inscription margin={{ bottom: 'spacing12' }} color="danger" textAlign="end">
            {t('Please try again later.')}
          </Inscription>
        )}
        <Box
          direction="row"
          justify="end"
        >
          <Box>
            <AppButton
              testId='cancel'
              width="160px"
              buttonType="secondary"
              onClick={onCancel}
            >
              {t('Cancel')}
            </AppButton>
          </Box>
          <Box margin={{ left: 'spacing8' }}>
            <AppButton
              testId='next'
              width="160px"
              {...isLoading ? {} : { onClick: onNext }}
            >
              {isLoading ? <Preloader /> : t('Next')}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default VerificationModal;
