import { useCallback, useMemo } from 'react';

import { Dependent } from '@/modules/user/components/ManageProfile/components/Dependents/dependents.types';
import { useStore } from '@/modules/user/components/ManageProfile/components/Dependents/stores/useDependents.store';
import theme from '@/styles/theme';

interface Props {
  dependents: Dependent[];
  setIsEditDependentModalVisible: (value: boolean) => void;
  setIsRemoveDependentModalVisible: (value: boolean) => void;
}

const useDependentsListOptions = ({ dependents, setIsEditDependentModalVisible, setIsRemoveDependentModalVisible }: Props) => {
  const { handleSetEditableDependent } = useStore();

  const handleEdit = useCallback((dependentId: string) => {
    const editableDependent = dependents.find((dependent) => dependent.id === dependentId);
    if (editableDependent) {
      handleSetEditableDependent(editableDependent);
      setIsEditDependentModalVisible(true);
    }
  }, [dependents, handleSetEditableDependent, setIsEditDependentModalVisible]);

  const handleRemove = useCallback((dependentId: string) => {
    const removingDependent = dependents.find((dependent) => dependent.id === dependentId);
    if (removingDependent) {
      handleSetEditableDependent(removingDependent);
      setIsRemoveDependentModalVisible(true);
    }
  }, [dependents, handleSetEditableDependent, setIsRemoveDependentModalVisible]);

  const options = useMemo(() => [
    {
      name: 'Edit',
      onClick: handleEdit,
    },
    {
      name: 'Remove',
      onClick: handleRemove,
      isWarning: true,
      optionStyle: { color: theme.colors.danger }, 
    },
  ], [handleEdit, handleRemove]);

  return {
    options,
  };
};

export default useDependentsListOptions;
