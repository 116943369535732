import React from 'react';
import { Box, DownloadIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useDownloadFileById } from '@/modules/core/hooks';

const StyledDownloadIcon = styled(DownloadIcon)`
  fill: ${({ theme }) => theme.colors.iconPrimary};
  &:hover {
    fill: ${({ theme }) => theme.colors.button};
  }
`;

interface Props {
  documentId: string;
}

const DownloadStatementButton: React.FC<Props> = ({ documentId }) => {
  const { handleDownload } = useDownloadFileById();

  return (
    <Box align="end" margin={{ right: 'spacing12' }}>
      <StyledDownloadIcon onClick={() => handleDownload(documentId)} cursor="pointer" />
    </Box>
  );
};

export default DownloadStatementButton;
