import { FilterByValues } from '@/modules/transaction/components/ReimburseMe/ClaimInfo/stores/useClaimInfoFieldsFilter.store';
import { EligibleExpense } from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';

export const getTheNumberOfMatches = (expense: EligibleExpense, filterObject?: Partial<FilterByValues>) => {
  let numberOfMatches = 0;

  if (!filterObject || !Object.keys(filterObject).length) {
    return {
      numberOfMatches,
      expense,
    };
  }

  const filterParameters = Object.keys(filterObject) as (keyof Partial<FilterByValues>)[];

  filterParameters.forEach((filterParameter) => {
    if (Array.isArray(expense[filterParameter])) {
      if (expense[filterParameter].includes(`${filterObject[filterParameter]}`)) {
        numberOfMatches++;
      }
    }

    if (expense[filterParameter] === filterObject[filterParameter]) {
      numberOfMatches++;
    }
  });

  const numberOfFilterParameters = filterParameters.filter((filterParameter) => filterObject?.[filterParameter])?.length;
  const isFullMatching = numberOfFilterParameters && numberOfFilterParameters === numberOfMatches;

  return {
    numberOfMatches,
    isFullMatching,
    expense,
  };
};
