import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Anchor,
  Box,
  costFormater,
  Hint,
  InfoOutlineIcon,
  LinkIcon,
  ListItem,
  ListItemBase,
  ListItemType,
  Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, } from '@/common/constants';
import { IconWrapper } from '@/modules/investments/hooks/useInvestmentsHoldings';
import theme from '@/styles/theme';

import { useGetFundIcon, useInvestmentsHoldings, useInvestmentsPendingActivities } from '../../hooks';
import {
  InvestmentActivitySide,
  InvestmentHolding,
  InvestmentPending,
  InvestmentsActivityType,
  InvestmentsTypeText,
} from '../../investments.types';
import InvestmentStatus from '../InvestmentStatus';

import useGetOrderType from './useGetOrderType';

const CASH_KEY = 'CASH';
const getFormattedAmount = (amount: number, side?: string) => {
  if(amount < 0) {
    return `${costFormater(amount, true)}`;
  } else {
    if(side === InvestmentActivitySide.BUY) {
      return `+${costFormater(amount,true)}`;
    }
    return `-${costFormater(amount, true)}`;
  }
  
};
const EMPTY_SYMBOL =  '-';

export default (accountId: string, tab?: InvestmentsActivityType) => {
  const { t } = useTranslation();
  const getOrderType = useGetOrderType();
  const holdingHeaders: ListItemBase[] = useMemo(() => {
    const baseItems: ListItemBase[] = [
      {
        key: 'fundName',
        title: t('Holding name'),
        flex: 6,
      },
      {
        key: 'research',
        title: '',
        flex: 1,
      },
      {
        key: 'type',
        title: t('Type'),
        flex: 3,
      },
    ];
    if (tab === InvestmentsActivityType.Holding) {
      return [
        ...baseItems,
        {
          key: 'raio',
          title: t('Election'),
          flex: 2,
        },
        {
          key: 'shares',
          title: t('Shares'),
          flex: 3,
        },
        {
          key: 'sharePrice',
          title: t('Share Price'),
          flex: 3,
        },
        {
          key: 'balance',
          title: t('Your Balance'),
          endAlign: true,
          flex: 3,
        },
      ];
    }
    return [
      ...baseItems,
     
      {
        key: 'date',
        title: t('Date'),
        flex: 3,
      },
     
      {
        key: 'amount',
        title: t('Amount'),
        flex: 3,
        // endAlign: true,
      },
      {
        key: 'status',
        title: t('Status'),
        endAlign: true,
        flex: 3,
      },
    ];
  }, [t, tab]);
  const {
    data: holdingsData,
    details,
    totalHoldingsAmount,
    cashlessHoldingAmount,
  } = useInvestmentsHoldings(accountId);

  const { data: pendingsData } = useInvestmentsPendingActivities(details.investorId);
  const filteredHoldings = useMemo(() => holdingsData.filter((item) => item.amount), [holdingsData]);
  const iconLabel = useMemo(
    () => (
      <IconWrapper margin={{ left: 'spacing8' }}>
        <InfoOutlineIcon color="iconSecondary" size="20px" />
      </IconWrapper>
    ),
    []
  );
  const handleGetFundIcon = useGetFundIcon();
 

  const getMainFields = useCallback(
    (item: InvestmentPending | InvestmentHolding) => {
      return [
        {
          key: 'name',
          type: ListItemType.Node,
          title: '',
          node: (
            <Box direction="row" align="center">
              {handleGetFundIcon(item)}
              <Box margin={{ left: 'm' }} direction="column">
                <Text
                  weight="bold"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title={item.name}
                >
                  {item.name}
                </Text>
                {item.type === CASH_KEY && (
                  <Hint hintElement={iconLabel}>
                    <Text textAlign="center">
                      {t(
                        'DriveWealth keeps some amount of cash for market trades. The amount depends on your risk category level.'
                      )}
                    </Text>
                  </Hint>
                )}
                <Text>{item.symbol}</Text>
              </Box>
            </Box>
          ),
          flex: 6,
        },
        {
          key: 'research',
          type: ListItemType.Node,
          title: '',
          flex: 1,
          hover: true,
          node: (
            <Box justify="center" align="center">
              {item.type === CASH_KEY ? (
                <Box width={theme.spacings.xl} />
              ) : (
                <Anchor href={item.researchURL} target="_blank">
                  <LinkIcon size={theme.spacings.s} />
                </Anchor>
              )}
            </Box>
          ),
        },
       
      ];
    },
    [handleGetFundIcon, iconLabel, t]
  );
  
  
  const formattedHoldings = useMemo<ListItem[]>(
    () =>
      filteredHoldings?.map((item: InvestmentHolding, index) => ({
        id: `${item.symbol} ${index}`,
        fields: [
          ...getMainFields(item),
          //
          {
            key: 'type',
            type: ListItemType.Text,
            title: t(InvestmentsTypeText[item.type] || EMPTY_SYMBOL),
            flex: 3,
          },
          {
            key: 'ratio',
            type: ListItemType.Text,
            title: `${item.type === CASH_KEY ? EMPTY_SYMBOL: `${(item.election || 0).toFixed(2)}%`}`,
            flex: 2,
          },
          {
            key: 'shares',
            type: ListItemType.Text,
            title: `${item.type === CASH_KEY ? EMPTY_SYMBOL: item.shares || 0}`,
            flex: 3,
          },
          {
            key: 'sharePrice',
            type: ListItemType.Text,
            title: item.type === CASH_KEY ? EMPTY_SYMBOL: costFormater(item.sharePrice, true),
            flex: 3,
          },
          {
            key: 'status',
            type: ListItemType.Node,
            title: '',
            node: <Text weight="bold">{costFormater(item.amount, true)}</Text>,
            fieldStyle: {
              flex: 3,
              alignItems: 'end',
            },
          },
        ],
      })),
    [filteredHoldings, getMainFields, t]
  );
  const formattedPendings = useMemo<ListItem[]>(
    () =>
      pendingsData?.map((item: InvestmentPending, index) => {

      
        return ({
          id: `${item.symbol} ${index}`,
          fields: [
            ...getMainFields(item),
            {
              key: 'type',
              type: ListItemType.Text,
              title: getOrderType(item.orderType),
              flex: 3,
            },
            {
              key: 'date',
              type: ListItemType.Text,
              title: dayjs(item.dateTime).format(DEFAULT_DATE_FORMAT),
              flex: 3,
            },
           
            {
              key: 'amount',
              type: ListItemType.Node,
              title: '',
              node: (
                <Text weight="bold">
                  {' '}
                  {getFormattedAmount(item.amount, item.side)}
                
                </Text>
              ),
              // endAlign: true,
              flex: 3,
            },
            {
              key: 'status',
              type: ListItemType.Node,
              title: '',
              node: <InvestmentStatus />,
              endAlign: true,
              flex: 3,
            },
            //
          ],
        });
      }),
    [getMainFields, pendingsData, getOrderType]
  );
  const formattedList = useMemo(() => {
    if (tab === InvestmentsActivityType.Holding) {
      return formattedHoldings;
    }
    return formattedPendings;
  }, [formattedHoldings, formattedPendings, tab]);

  const pendingsTotal = useMemo(() => pendingsData.length, [pendingsData.length]);
  const holdingsTotal = useMemo(() => filteredHoldings.length, [filteredHoldings.length]);
  return {
    totalHoldingsAmount,
    formattedList ,
    headers: holdingHeaders,
    cashlessHoldingAmount,
    list: formattedHoldings,
    formattedHoldings,
    formattedPendings,
    pendingsTotal,
    holdingsTotal,
  };
};
