import React, { useCallback,useEffect, useMemo } from 'react';
import {
  Box, Image,Preloader,
  QRCode, useImage } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

// import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import SmallLogoIcon from '@/assets/small-logo.svg';
import useGetReceiptQRCodeQuery from '@/modules/transaction/components/ReimburseMe/queries/useGetReceiptQRCode.query';
import { useQRCodeStore } from '@/modules/transaction/components/ReimburseMe/store/useQRCode.store';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const IS_LOGO_HIDDEN = whiteLabelingConfig.title;

const QRCodeImageWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

interface Props {
  size?: number;
  logoSize?: number;
  imagePadding?: number;
  withPadding?: boolean;
}

const UploadFileQRCode: React.FC<Props> = ({ size = 150, logoSize = 40, withPadding, imagePadding = 0 }) => {
  const { organizationProperties } = useOrganizationProperties();
  // const { values: { documentId } } = useFilesInDocumentStore();
  const { isError: isLogoError } = useImage(organizationProperties?.minimizedLogoUrl);
  const { qrCodeData, isFetching, refetch } = useGetReceiptQRCodeQuery();
  const handleSetQRCodeStore = useQRCodeStore((state) => state.setState);

  const logoUrl = useMemo(() => {
    if (isLogoError || !organizationProperties?.minimizedLogoUrl) {
      return SmallLogoIcon;
    }

    return organizationProperties?.minimizedLogoUrl;
  }, [isLogoError, organizationProperties]);

  const validTo = useMemo(() => dayjs(qrCodeData?.validTo || 0).valueOf(), [qrCodeData]);
  const qrCodeId = useMemo(() => qrCodeData?.qrId, [qrCodeData]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (validTo) {
      const currentTime = dayjs().valueOf();
      const timeout = validTo - currentTime;

      if (timeout > 0) {
        timeoutId = setTimeout(() => {
          refetch();
        }, timeout);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validTo]);

  const handleSetQrCodeId = useCallback(async () => {
    handleSetQRCodeStore({
      qrCodeId,
    });
  }, [handleSetQRCodeStore, qrCodeId]);

  useEffect(() => {
    if (!isFetching && qrCodeId) {
      handleSetQrCodeId();
    }
  // eslint-disable-next-line
  }, [isFetching, qrCodeId]);
  const paddingSize = useMemo(() => (withPadding ? 16 : 0), [withPadding]);

  return (
    <Box
      width={{ min: `${size}px` }}
      height={{ min: `${size}px` }}
      align="center"
      justify="center"
      data-testId="upload-file-qr-code"
    >
      {isFetching ? (
        <Preloader testId="qr-code_preloader" />
      ) : (
        <Box 
          {...withPadding ? { margin: 'spacing8' } : {}}
          style={{ position: 'relative' }}
        >
          <QRCode
            value={qrCodeData?.fileUploadUrl || ''}
            level="M"
            size={size - paddingSize}
            {...IS_LOGO_HIDDEN ? {} : {
              imageSettings: {
                src: '',
                height: logoSize,
                width: logoSize,
                excavate: true,
              }
            }}
          />
          {
            !IS_LOGO_HIDDEN && (
              <QRCodeImageWrapper justify="center" align="center">
                <Image
                  src={whiteLabelingConfig?.minifyLogo || logoUrl}
                  height={logoSize - imagePadding}
                  width={logoSize - imagePadding}
                  fit="contain"
                />
              </QRCodeImageWrapper>
            )
          }
        </Box>
      )}
    </Box>
  );
};

export default UploadFileQRCode;
