import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Preloader,Text } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import { ModalWrapper } from '@/components/wrappers';
import { useRemovePaymentMethod } from '@/modules/transaction/components/ReimburseMe/queries/useRemovePaymentMethod.query';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';

import { PaymentMethodLabel } from '../PaymentMethodLabel';

const DESCRIPTION = `Select 'Remove' if you do not want this payment method displayed in your list of payment options.
  Removing this payment option will not cancel any open reimbursement transactions in process.`;

const BUTTON_WIDTH = '150px';

interface Props {
  onClose: () => void;
  onBack: () => void;
  onSuccess: () => void;
  methodForRemove?: PaymentMethod;
}

export const RemovePaymentMethodModal: React.FC<Props> = ({
  onClose,
  onBack,
  onSuccess,
  methodForRemove,
}) => {
  const { t } = useTranslation();
  const { isLoading, remove, isSuccess } = useRemovePaymentMethod();

  const handleClearEditableMethodPaymentMethod = useStore(
    (state) => state.handleClearEditableMethodPaymentMethod,
  );

  const handleRemove = useCallback(() => {
    if (methodForRemove?.id) {
      remove(+methodForRemove?.id);
    }
  }, [methodForRemove, remove]);

  useEffect(() => () => {
    handleClearEditableMethodPaymentMethod();
  }, [handleClearEditableMethodPaymentMethod]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <ModalWrapper
      visible
      onSetVisible={onClose}
      testId="RemovePaymentMethodModal_testId"
    >

      <Box align="center">

        <Text
          size="2xl"
          weight="bold"
          color="textBody"
          margin={{ top: 'small' }}
        >
          {t('Remove this payment method? ')}
        </Text>

        <Text
          textAlign="center"
          color="textBody"
          margin={{ top: 'small', bottom: 'medium' }}
        >
          {t(DESCRIPTION)}
        </Text>
      </Box>

      <Box
        pad="l"
        background={{ color: 'dangerContainer' }}
        round="moduleRound"
      >
        <Box
          pad="l"
          background={{ color: 'canvas' }}
          round="container1Round"
          border={{
            size: '1px',
            color: 'dangerBorder',
          }}
        >
          <PaymentMethodLabel paymentMethod={methodForRemove} />
        </Box>
      </Box>

      <Box direction="row" justify="end" align="center" pad={{ top: 'medium' }} gap="spacing12">
        <AppButton
          testId="RemovePaymentMethod_cancel"
          buttonType="secondary"
          type="button"
          onClick={onBack}
          width={BUTTON_WIDTH}
        >
          {t('Cancel')}
        </AppButton>
        <AppButton
          testId="RemovePaymentMethod_remove"
          type="button"
          width={BUTTON_WIDTH}
          onClick={handleRemove}
          color="red"
        >
          {isLoading ? <Preloader color="white" /> : t('Remove')}
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};
