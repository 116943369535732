import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChooseVerificationMethodIcon, Inscription } from '@common-fe/common-fe';
import { FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import useVerificationMethodsFields, { FormValues } from '@/modules/user/components/SignIn/MultiFactorAuthentication/hooks/useVerificationMethodsFields';
import { MFAStep } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';
import useMFAStore from '@/modules/user/components/SignIn/MultiFactorAuthentication/stores/useMFA.store';
import VerificationModal from '@/modules/user/components/Verification/components/VerificationModal';

import RememberMeCheckbox from './RememberMeCheckbox';

interface Props {
  onSetVisible: (value: boolean) => void;
  setStep: (step: MFAStep) => void;
}

const ChangeVerificationMethodModal: React.FC<Props> = ({ onSetVisible, setStep }) => {
  const { t } = useTranslation();
  const { channels, activeChannel, setActiveChannel } = useMFAStore();
  const fields = useVerificationMethodsFields({ channels, activeChannel });
  const [state, handleSetState] = useState<FormValues>();
  const { values, setValues } = useDebounceState<FormValues>();

  const handleBack = useCallback(() => {
    setStep(MFAStep.SEND_CODE);
  }, [setStep]);

  const handleConfirm = useCallback(() => {
    const newActiveChannel = channels?.find((channel) => channel?.value === state?.verificationMethod);
    if (newActiveChannel) {
      setActiveChannel(newActiveChannel);
    }
    setStep(MFAStep.SEND_CODE);
  }, [ state, channels, setStep, setActiveChannel]);

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <VerificationModal
      icon={<ChooseVerificationMethodIcon size="128px" />}
      title={(
        <Inscription margin={{ top: 'spacing12' }} size='2xl' weight={700}>
          {t('Choose Your Verification Method')}
        </Inscription>
      )}
      description={(
        <Inscription margin={{ top: 'spacing12' }}>
          {t('Please select how you would like to receive your 6-digit code')}
        </Inscription>
      )}
      onSetVisible={onSetVisible}
      onConfirm={handleConfirm}
      onBack={handleBack}
      form={(
        <FlexControlledForm
          fields={fields}
          onChangeValues={setValues}
          formStyle={{ margin: '24px' }}
          editMode
        />
      )}
      footerNode={<RememberMeCheckbox />}
    />
  );
};

export default ChangeVerificationMethodModal;
