import lodash from 'lodash';

import { FileImage } from '@/common/constants';
import { DefaultValues } from '@/common/types';

const prepareFile = (file: DefaultValues): FileImage => ({
  id: file.id || '',
  temporalId: file.temporalId || lodash.uniqueId(),
  path: file.path || '',
  url: file.url || '',
  preview: file.preview || URL.createObjectURL(file.blob as Blob || file),
  name: file.name || '',
  type: file.type || '',
  ...file.blob ? { blob: file.blob } : {},
});

export default prepareFile;
