import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.module};
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const Header = styled.h4`
  ${({ theme }) => theme.fonts.text16Bold};
  color: ${({ theme }) => theme.colors.blockTitle || ''};
  
`;

export const AllButton = styled.button`
   ${({ theme }) => theme.fonts.text16Medium};
   color: ${({ theme }) => theme.colors.textAccent};
   padding: 0;
   padding-left: ${({ theme }) => theme.spacings.spacing8};
   background-color: transparent;
   border: none;
`;
