import { useCallback, useState } from 'react';
import { Field } from '@common-fe/common-fe';
import _ from 'lodash';

import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

const useValidateForm = (fields: Field[]) => {
  const { getValidateFields, getErrors } = useValidationUtils();
  const [hasErrors, setHasErrors] = useState(false);

  // @ts-ignore
  const handlePreSubmit = useCallback((state) => {
    const hasValidationErrors = !_.isEmpty(getErrors(state, getValidateFields(fields)));
    setHasErrors(hasValidationErrors);
    return !hasValidationErrors;
  }, [fields, getValidateFields, getErrors]);

  return { hasErrors, handlePreSubmit };
};

export default useValidateForm;
