import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, costFormater,Image,NullIcon,Text } from '@common-fe/common-fe';
import _ from 'lodash';

import { useGetFundIcon } from '@/modules/investments/hooks';
import { InvestmentHoldingStatus } from '@/modules/investments/investments.types';
import { FundsPayload } from '@/modules/investments/SelfDirected/SelfDirected.types';

import { PERCENTS_LIQUIDATION_LIMIT } from '../Funds.constants';




interface Props extends FundsPayload {
  value: number;
  
}
const ConfirmModalItem: React.FC<Props> = ({ title, stockCode,status, logo, value, amount = 0}) => {
  const { t } = useTranslation();
  const isLiquidation = useMemo(() => {
    const percents = value / amount;
    if(_.isNaN(percents)) {
      return false;
    }
    return percents >= PERCENTS_LIQUIDATION_LIMIT;
  }, [amount, value]);

  const handleGetFundIcon = useGetFundIcon();
  return (
    <Box 
      direction="row"
      pad={{ vertical: '12px', horizontal: 'l' }}
      background="canvas"
      align='center'
      round="container2Round"
      border={{ size: 'small', color: 'border1' }}
      margin={{ top: 'xs' }}
    >
      <Box style={{zIndex: 10000}}>
        {
          handleGetFundIcon({
            image: logo,
            status
          })
        }
      </Box>
      <Box
        margin={{ left: 's' }}
        style={{
          flex: 2,
          position: 'relative',
        }}
        direction="column"
      >
        <Box width="100%">
          <Text tip={title} truncate weight={700}>
            {title}
          </Text>
          <Text>{stockCode}</Text>
        </Box>
      </Box>
      <Box direction='row' justify='between' margin={{ left: 's' }} style={{flex: 2}}>
        <Text weight={700}>{costFormater(value, {isPrecision: true})}</Text>
        {isLiquidation && (
          <Text color="danger">{t('Full position liquidation')}</Text>
        )}
      </Box>
      
    </Box>
  );
};

export default ConfirmModalItem;