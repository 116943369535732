import React from 'react';
import {
  Box, RoundCloseIcon,
  Text, } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';

import Modal from '../Modal';

interface Props {
  header?: string;
  helptext?: string;
  buttonText: string;
  buttonTextTryAgain?: string;
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit?: () => void;
}
const ErrorModal: React.FC<Props> = ({
  onSetVisible,
  visible,
  header,
  helptext,
  buttonText,
  buttonTextTryAgain,
  onSubmit,
}) => {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    onSetVisible(false);
  };
  return (
    <Modal visible={visible} onSetVisible={onSetVisible}>
      <Box direction="column" align="center" data-testid="error-modal">
        <RoundCloseIcon size="spacing48" color="danger" />
        {header && (
          <Text
            weight="bold"
            textAlign="center"
            size="24px"
            margin={{ top: 'spacing32', horizontal: 'spacing48' }}
          >{header}
          </Text>
        ) }
        <Text size="14px" weight="normal" textAlign="center" margin={{ top: 'spacing8', bottom: 'spacing32' }}>{helptext}</Text>
        <span>
          <AppButton
            testId="modal"
            onClick={handleSubmit}
            buttonType="secondary"
          >
            {buttonText}
          </AppButton>
          {buttonTextTryAgain && (
            <AppButton
              testId="modal"
            >
              {buttonTextTryAgain}
            </AppButton>
          )}
        </span>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
