import { Field } from '@common-fe/common-fe';

import { DefaultValues } from '@/common/types';

export const getFieldsWithDefaultValues = (
  fields: Field[], defaultValues?: DefaultValues,
): Field[] => {
  if (defaultValues) {
    return fields.map((item) => {
      let subFields: Field[] | undefined;
      if (item.subFields) {
        subFields = getFieldsWithDefaultValues(item.subFields, defaultValues);
      }

      return ({
        ...item,
        subFields,
        value: item.value || defaultValues[item.name] || '',
      });
    });
  }
  return fields;
};

export default getFieldsWithDefaultValues;
