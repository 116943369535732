import React from 'react';
import { useImage } from '@common-fe/common-fe';

import { ProportionalImage } from './SwitchImage.styles';

interface Props {
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
  children: React.ReactElement;
}

const SwitchImage: React.FC<Props> = ({
  src, width, height, alt, children,
}) => {
  const { isLoading, isError } = useImage(src);

  if (!isLoading && !isError && src) {
    return (
      <ProportionalImage
        className="image"
        src={src}
        width={width}
        height={height}
        alt={alt}
      />
    );
  }

  return children;
};

export default SwitchImage;
