

import { useTradeInvestmentsQuery } from '@/modules/investments/queries';

export default () => {
  
  const { handleSave , isLoading } = useTradeInvestmentsQuery({ isSellMode: true});

  return {
    handleSave,
    isLoading,
    handleSellInvestments: handleSave
  };
};