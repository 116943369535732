import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuccessModal } from '@common-fe/common-fe';

import { EditPaymentMethodModal as EditModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/EditPaymentMethod';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import useHighlightedPaymentMethodStore from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/stores/useHighlightedPaymentMethod.store';
import useHighlightById from '@/utils/hooks/useHighlightById';

enum Step {
  EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD',
  SUCCESSFULLY_EDITED = 'SUCCESSFULLY_EDITED',
}

interface Props {
  onSetVisible: (value: boolean) => void;
  refetchPaymentMethods: () => void;
}

const EditMethodModal: React.FC<Props> = ({ onSetVisible, refetchPaymentMethods }) => {
  const { t } = useTranslation();
  const [step, setSteps] = useState(Step.EDIT_PAYMENT_METHOD);
  const editableMethod = useStore((state) => state.editableMethod);
  const { reset, setHighlightedPaymentMethodId } = useHighlightedPaymentMethodStore();
  const { setHighlightedById } = useHighlightById({
    onSetHighlightedId: setHighlightedPaymentMethodId,
    reset,
  });

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleEditSuccess = useCallback((editedPaymentMethodId?: string) => {
    refetchPaymentMethods();
    setSteps(Step.SUCCESSFULLY_EDITED);
    if (editedPaymentMethodId) {
      setHighlightedById(editedPaymentMethodId);
    }
  }, [refetchPaymentMethods, setHighlightedById]);

  return (
    <>
      {step === Step.EDIT_PAYMENT_METHOD && (
        <EditModal
          onClose={handleClose}
          onBack={handleClose}
          onSuccess={handleEditSuccess}
          methodForEdit={editableMethod}
        />
      )}
      {step === Step.SUCCESSFULLY_EDITED && (
        <SuccessModal
          visible
          header={t('Payment method was updated successfully!')}
          buttonText={t('Got It!')}
          onSetVisible={handleClose}
          buttonStyle={{ width: '200px' }}
        />
      )}
    </>
  );
};

export default EditMethodModal;
