import React, {
  useCallback, useEffect, useMemo,
  useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,   Box, GoPaperlessIcon, Preloader,
  Text, } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';
import { ElectronicAgreement } from '@/modules/transaction/components/Statements/components/ElectronicAgreement';
import { Steps } from '@/modules/transaction/components/Statements/components/GoPaperless/GoPaperless.constants';
import useUpdateEmployeeQuery from '@/modules/user/queries/useUpdateEmployee.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';
import globalTheme from '@/styles/theme';

interface Props {
  onSetVisible: (value: boolean) => void;
  setStep: (value: Steps) => void;
}

const GoPaperlessModal: React.FC<Props> = ({ onSetVisible, setStep }) => {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const [isChecked, setIsChecked] = useState(false);
  const [isAgreementError, setIsAgreementError] = useState(false);
  const { updateEmployee, isLoading, isSuccess } = useUpdateEmployeeQuery();

  const onCancel = useCallback(() => onSetVisible(false), [onSetVisible]);

  const currentEmail = useMemo(
    () => (employee?.isEmployeeEmailPreferred && employee.employeeProvidedEmail
      ? employee.employeeProvidedEmail
      : employee?.employerProvidedEmail
    ), [employee],
  );

  const handleChangeEmail = useCallback(() => {
    setStep(Steps.ChangeEmail);
  }, [setStep]);

  const handleCheck = useCallback(() => {
    setIsChecked(!isChecked);
  }, [setIsChecked, isChecked]);

  const handleSubmit = useCallback(() => {
    if (!isChecked) {
      setIsAgreementError(true);
      return;
    }

    updateEmployee({
      is_electronic_statement_only: true,
    });
  }, [updateEmployee, isChecked]);

  useEffect(() => {
    if (isSuccess) {
      setStep(Steps.Success);
    }
  }, [isSuccess, setStep]);

  return (
    <ModalWrapper
      visible
      onSetVisible={onSetVisible}
      contentStyle={{
        padding: `${globalTheme.spacings.spacing48} ${globalTheme.spacings.spacing24}`,
      }}
      testId="go-paperless_modal_wrapper"
    >
      <Box align="center">
        <GoPaperlessIcon width="120px" height="120px" />
        <Box margin={{ top: 'spacing12' }}>
          <Text size="2xl" weight={700}>
            {t('Go Paperless')}
          </Text>
        </Box>
      </Box>

      <Box
        margin={{ top: 'spacing24' }}
        round="moduleRound"
        background="module"
        pad="spacing24"
      >
        <ElectronicAgreement
          isChecked={isChecked}
          onChecked={handleCheck}
          onEdit={handleChangeEmail}
          email={currentEmail}
          isError={isAgreementError && !isChecked}
          wrapperMode
        />
      </Box>

      {
        isAgreementError && !isChecked && (
          <Box margin={{ top: 'spacing24' }} data-testId="error_block">
            <Text color="danger" textAlign="end">
              {t('Please confirm that you’ve read and acknowledge our Electronic Disclosure Agreement.')}
            </Text>
          </Box>
        )
      }

      <Box
        margin={{ top: 'spacing24' }}
        direction="row"
        justify="end"
      >
        <Box>
          <AppButton
            buttonType="secondary"
            width="160px"
            onClick={onCancel}
            testId="cancel"
          >
            {t('Cancel')}
          </AppButton>
        </Box>
        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            width="160px"
            onClick={handleSubmit}
            testId="submit"
            disabled={!currentEmail}
          >
            {isLoading ? <Preloader /> : t('Submit')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default GoPaperlessModal;
