import { storeCreator } from '@/utils/store/store.utils';

import { BankInfo } from './useBankFields';

interface State extends BankInfo {
  setBankAccountData: (data: BankInfo) => void;
}

const useStore = storeCreator<State>((set) => ({
  setBankAccountData: (data: BankInfo) => set(() => ({ ...data })),
}));

const useBank = () => {
  const { setBankAccountData, ...store } = useStore();

  return {
    bankAccountData: store,
    setBankAccountData,
  };
};

export default useBank;
