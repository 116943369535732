import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionBlock,
  Box,
  Text,
} from '@common-fe/common-fe';

import { RiskControlOptions } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import { ModalWrapper } from '@/components/wrappers';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  activeRisk?: string;
  isAllRiskActive?: boolean;
}

interface AccordionBlockItem {
  title: string;
  information: string;
  content: string;
  value: RiskControlOptions;
}

const RiskModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  activeRisk,
  isAllRiskActive,
}) => {
  const { t } = useTranslation();
  const accordionData: AccordionBlockItem[] = useMemo(
    () => [
      {
        title: t('Conservative Model'),
        information: t('Cash & Bonds: 80%, Stocks: 20%'),
        content: t('This portfolio may be appropriate for investors who wish to minimize risk in their portfolio but want some exposure to the stock market.  Also, consider this portfolio if you are nearing retirement, anticipate needing funds for planned medical expenses in the next 1-2 years or if you are in below average or poor health.  This portfolio would also be appropriate for investors that prefer to minimize fluctuations in their portfolio but understand that they may still experience negative returns in challenging market environments.'),
        value: RiskControlOptions.CONSERVATIVE,
      },
      {
        title: t('Moderately Conservative Model'),
        information: t('Cash & Bonds: 60%, Stocks: 40%'),
        content: t('This portfolio may be appropriate for investors who want a balance of risk and return but prefer to take lower levels of risk and are comfortable accepting lower potential returns.  Consider this portfolio if you are up to 10 years from retirement, anticipate needing funds for planned medical expenses in the next 3-5 years or if you are in average or below average health.  This portfolio would also be appropriate for investors that are willing to accept some fluctuations in their portfolio for the potential to earn a modest return.'),
        value: RiskControlOptions.MODERATELY_CONSERVATIVE,
      },
      {
        title: t('Moderate Model'),
        information: t('Cash & Bonds: 40%, Stocks: 60%'),
        content: t('This portfolio may be appropriate for investors who want a balance of risk and return.  Consider this portfolio if you are up to 10+ years from retirement and looking to grow your H.S.A. balance or you are in good health and only plan to use your HSA investment balance for unexpected medical expenses.  This portfolio would also be appropriate for investors that are willing to accept moderate fluctuations in their portfolio for the potential to earn moderate returns over an extended period of time.'),
        value: RiskControlOptions.MODERATE,
      },
      {
        title: t('Moderately Aggressive Model'),
        information: t('Cash & Bonds: 25%, Stocks: 75%'),
        content: t('This portfolio may be appropriate for investors who are comfortable accepting additional risk for the potential to earn higher returns.  Consider this portfolio if you are 10+ years from retirement, or plan on growing your balance for use in retirement and are in good to excellent health.  This portfolio would also be appropriate for investors that are willing to accept increased fluctuations in their portfolio for the potential to earn stronger returns over an extended period of time.'),
        value: RiskControlOptions.MODERATELY_AGGRESSIVE,
      },
      {
        title: t('Aggressive Model'),
        information: t('Cash & Bonds: 10%, Stocks: 90%'),
        content: t('This portfolio may be appropriate for investors who prioritize portfolio growth and are comfortable accepting substantial risk.  Consider this portfolio if you are early in your career, can meet your plan deductible without accessing your HSA funds in an emergency and you are in excellent health.  This portfolio would also be appropriate for investors that are willing to accept substantial fluctuations in their portfolio for the potential to earn above average returns over an extended period of time.'),
        value: RiskControlOptions.AGGRESSIVE,
      },
    ], [t],
  );
  const activeIndex = useMemo(() => accordionData?.findIndex((item) => item?.value === activeRisk),
    [activeRisk, accordionData]);

  const allRiskIndex = useMemo(() => {
    const arrayOfIndex: number[] = [];
    accordionData?.map((_, index) => arrayOfIndex.push(index));
    return arrayOfIndex;
  }, [accordionData]);

  return (
    <ModalWrapper
      visible={visible}
      title={t('Risk Categories')}
      onSetVisible={onSetVisible}
      testId="risk-modal-wrapper-id"
    >
      <Box background="module" round="moduleRound" margin={{ bottom: 'spacing24' }}>
        <AccordionBlock
          accordionData={accordionData}
          activeRisk={isAllRiskActive ? allRiskIndex : [activeIndex]}
        />
        <Text color="textSecondary" size="medium" margin={{ horizontal: 'spacing24', bottom: 'spacing24' }}>
          {t(
            'Please note this questionnaire is not intended to offer a complete investment profile. The risk-based model portfolios in the Elevate Investment Program consist of unique asset allocation strategies comprised of the underlying investment options. These model portfolios are not registered securities. In most situations, account holders confirm the use of just one risk-based model portfolio based upon your Investor Profile to allocate among cash, bond ETFs and stock ETFs.',
          )}
        </Text>

      </Box>
      <Box direction="row" justify="end">

        <AppButton
          testId="modal"
          width="140px"
          onClick={() => onSetVisible(false)}
          buttonType="secondary"
        >
          {t('Close')}
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};

export default RiskModal;
