import React from 'react';
import { Box } from '@common-fe/common-fe';

import { useSearchParams } from '@/modules/transaction/hooks/useSearchParams';

import { CardActivitiesList } from './CardActivitiesList';

export const CardTransactions: React.FC = () => {
  const { query } = useSearchParams('card_id');
  return (
    <Box margin={{ top: 'spacing24' }}>
      {
        query
          ? (
            <CardActivitiesList />
          )
          : null
      }
    </Box>
  );
};
