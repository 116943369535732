import React from 'react';
import { useTranslation } from 'react-i18next';
import { costFormater, Text } from '@common-fe/common-fe';

import {
  TransactionPostingsData,
} from '@/modules/transaction/transaction.types';

import { Wrapper } from '../../TransactionNotification.styles';

const isPayment = (posting?: TransactionPostingsData) => typeof posting?.amount === 'number' && posting.amount >= 0;
const isRefund = (posting?: TransactionPostingsData) => typeof posting?.amount === 'number' && posting.amount < 0;

export const hasPostingMessage = (posting?: TransactionPostingsData) => isPayment(posting) || isRefund(posting);

interface Props {
  posting?: TransactionPostingsData;
}

const PostingMessage: React.FC<Props> = ({ posting }) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      data-testid={`posting-${posting?.planName}`}
      flex-direction="column"
      background="canvas"
      round="regular"
      pad="l"
    >
      {isPayment(posting) ? (
        <Text size="large">
          <Text weight={700}>{costFormater(Math.abs(posting?.amount as number), false, true)}</Text>
          &nbsp;{t('was returned to your')}&nbsp;
          <Text weight={700}>{posting?.planName}</Text>
        </Text>
      ) : null}
      {isRefund(posting) ? (
        <Text size="large">
          {t('You paid')}&nbsp;
          <Text weight={700}>{costFormater(Math.abs(posting?.amount as number), false, true)}</Text>
          &nbsp;{t('from your')}&nbsp;
          <Text weight={700}>{posting?.planName}</Text>
        </Text>
      ) : null}
    </Wrapper>
  );
};

export default PostingMessage;
