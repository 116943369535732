import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Field,
  FlexControlledForm,
} from '@common-fe/common-fe';

import { RecurringContributionFormValues } from '../contribution.types';
import { useMakeRecurringContributionFields } from '../hooks/useMakeRecurringContributionFields';

interface Props {
  handleChangeValues: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,) => void;
  isSubmit: boolean;
  formValues: RecurringContributionFormValues;
  setInfoSetup: (value: boolean) => void;
  minimumContributionAmount?: number;
  maximumContributionAmount?: number;
}

export const MakeRecurringContributionForm: React.FC<Props> = ({
  handleChangeValues,
  isSubmit,
  formValues,
  setInfoSetup,
  minimumContributionAmount,
  maximumContributionAmount,
}) => {
  const {fields: infoFields, isScheduledContributionExceeds} = useMakeRecurringContributionFields(
    formValues,
    minimumContributionAmount,
    maximumContributionAmount,
  );

  const handleChangeOneTimeValues = useCallback((values: object) => {
    handleChangeValues(values, infoFields, setInfoSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isScheduledContributionExceeds) {
      setInfoSetup(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScheduledContributionExceeds, formValues]);

  return (
    <Box data-testid="recurring-contribution-info-form" round="container1Round" background="canvas">
      <FlexControlledForm
        fields={infoFields}
        editMode
        showError={isSubmit}
        onChangeValues={handleChangeOneTimeValues}
        isModalType
      />
    </Box>
  );
};
