import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppButton, Box, Inscription, RoundDoneIcon } from '@common-fe/common-fe';

import routes from '@/common/routes';
import { ModalWrapper } from '@/components/wrappers';

interface Props {
  subTitle: string;
  onSetVisible: (value: boolean) => void;
}

const SuccessVerifiedModal: React.FC<Props> = ({ subTitle, onSetVisible }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleNavigateToHomePage = useCallback(() => {
    history.push(routes.HOME);
  }, [history]);

  return (
    <ModalWrapper visible onSetVisible={onSetVisible}>
      <Box pad={{ vertical: 'spacing24' }}>
        <Box align="center">
          <RoundDoneIcon />
          <Inscription
            margin={{ top: 'spacing12' }}
            size="2xl"
            weight={700}
          >
            {t('Success')}
          </Inscription>
          <Inscription margin={{ top: 'spacing12' }}>{subTitle}</Inscription>
        </Box>

        <Box direction="row" justify="center" margin={{ top: 'spacing24' }}>
          <Box>
            <AppButton width="210px" onClick={handleClose} buttonType="secondary">
              {t('Go To Account Profile')}
            </AppButton>
          </Box>
          <Box margin={{ left: 'spacing12' }}>
            <AppButton width="210px" onClick={handleNavigateToHomePage}>
              {t('Go To Home Dashboard')}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
export default SuccessVerifiedModal;
