import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box, Text, WarningIcon, } from '@common-fe/common-fe';

interface Props {
  onCancel: () => void;
  onRetry: () => void;
}
const TimeoutBanner: React.FC<Props> = ({ onCancel, onRetry }) => {
  const { t } = useTranslation();
  return (
    <Box
      data-testid="timeout-banner"
      align="center"
      justify="center"
      direction="column"
      background="module"
      pad="xxl"
      round="moduleRound"
    >

      <WarningIcon size="48px" />
      <Text size="large" weight={700} margin={{ top: 'l' }}>{t('Oops!')}</Text>

      <Text margin={{ top: 's' }}>{t('Time’s up! Do you want to try the verification again?')}</Text>
      <Box margin={{ top: 'l' }} direction="row">
        <Box margin={{ right: 'spacing6' }}>
          <AppButton
            width="control"
            buttonType="secondary"
            testId="timeout-banner_cancel"
            onClick={onCancel}
          >

            {t('Try Later')}
          </AppButton>
        </Box>
        <Box margin={{ left: 'spacing6' }}>
          <AppButton
            width="control"
            testId="timeout-banner_retry"
            onClick={onRetry}
          >

            {t('Retry')}
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeoutBanner;
