import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  costFormater, 
  ListItem,
  ListItemType,
  Text, } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import colors from '@/styles/colors';
import theme from '@/styles/theme';

import { useCurrentInvestorHoldingsStore } from '../AutoInvestment/SetupAutoInvestments/AutoSelfDirectedInvestments/store';
import { Donut, InvestmentStockItem } from '../investments.types';
import { useInvestmentsHoldingsQuery } from '../queries';

import { useGetFundIcon } from '.';

const MAX_FUND_COUNT = 12;
const CASH_KEY = 'CASH';

export const IconWrapper = styled(Box)`
    &:hover {
      svg {
        cursor: pointer;
        fill:${theme.colors.iconAccent}; 
      }
    }
  `;
const SELL_TRANSACTION_KEY = 'SELL';
export default (accountId?: string) => {
  const {
    data, isSuccess, details, isInvestorExists, isInvestorAccountExists
  } = useInvestmentsHoldingsQuery(accountId);
  const setCurrentHoldings = useCurrentInvestorHoldingsStore((state) => state.handleSetHoldings);

  const handleGetFundIcon = useGetFundIcon();
  const formattedList = useMemo<ListItem[]>(
    () => data.slice().map((item, index) => {

      return ({
        id: `${item.symbol} ${index}`,
        rowStyle: {
          boxShadow: 'none',
        },
        fields: [
          {
            key: 'stockQuote',
            type: ListItemType.Node,
            title: '',
            node: <Box width="12px" height="12px" round="smallRound" background={theme.colors.stocksColors[index] || theme.colors.fund12} />,
            flex: -1,
          },
          {
            key: 'icon',
            type: ListItemType.Node,
            title: '',
            node: handleGetFundIcon(item),
            fieldStyle: {
              marginLeft: theme.spacings.xs,
              marginRight: theme.spacings.xs,
              width: theme.spacings.xl,
            },
          },
          {
            key: 'name',
            type: ListItemType.Text,
            ellipsisMode: true,
            title: item.name,
            flex: 10,
          },
          {
            key: 'receivedAmount',
            type: ListItemType.Node,
            title: '',
            flex: 3,
            node: (
              <Box direction="row" align="center" />
            ),
          },
          {
            key: 'currentAmount',
            type: ListItemType.Node,
            title: '',
            flex: 3,
            fieldStyle: {
              marginLeft: theme.spacings.s,
              minWidth: theme.spacings.l,
            },
            node: <Text textAlign="end" weight="bold">{costFormater( item.side === SELL_TRANSACTION_KEY ? item.amount * -1: item.amount, true)}</Text>,
          },
      
        ],
      });
    }), [data, handleGetFundIcon],
  );

  const getGroupedData = useCallback(
    (currentStocks: InvestmentStockItem[], sum: number, zeroless?: boolean) => {
      let filtered = zeroless
        ? _.filter(currentStocks, (item) => item.amount !== 0)
        : currentStocks;

      const otherItemSum = filtered.filter(
        (item) => item.isOther,
      ).reduce((result, item) => result + item.amount, 0);
      const otherItem = {
        id: 'other',
        stockQuote: 'Other',
        amount: otherItemSum,
        color: colors.stocksColors[MAX_FUND_COUNT - 1],

        percentage: `${otherItemSum ? _.ceil((otherItemSum / sum) * 100, 2) : 0}%`,
      };
      const firstOtherIndex = filtered.findIndex((item) => item.isOther);
      if (firstOtherIndex !== -1) {
        filtered.splice(firstOtherIndex, 0, otherItem);
      }
      filtered = filtered.filter((item) => !item.isOther);

      return filtered;
    }, [],
  );
  const donutData = useMemo(() => {
    const sums = data.reduce((result, item) => ({
      holdings: result.holdings + item.amount || 0,
    }), {
      holdings: 0,
    });

    const totalSums = {
      holdings: 0,
    };

    const donutResults: Donut[] = [];

    let otherSum = 0;
    const holdingsData = data.map((item, index) => {
      const currentAmount = item.amount;
      totalSums.holdings += item.amount || 0;
      if (currentAmount) {
        if (index < MAX_FUND_COUNT - 1) {
          donutResults.push({
            value: currentAmount,
            color: colors.stocksColors[index],
            symbol: item?.type === CASH_KEY ? item?.type : item?.symbol,
          });
        } else {
          otherSum += currentAmount;
        }
      }

      return {
        id: item.symbol,
        stockQuote: item?.type === CASH_KEY ? item?.type : item?.symbol,
        amount: item.amount,
        
        isOther: index >= MAX_FUND_COUNT - 1,
        color: index < MAX_FUND_COUNT - 1 ? colors.stocksColors[index] : '',
        percentage: `${item.amount ? _.ceil((item.amount / sums.holdings) * 100, 2) : 0}%`,
      } as InvestmentStockItem;
    });
    donutResults.push({
      value: otherSum,
      color: colors.stocksColors[MAX_FUND_COUNT - 1],
      symbol: 'Other',
    });
    
    return {
      holdingsData: getGroupedData(holdingsData, totalSums.holdings, true),
      allHoldingsData: getGroupedData(holdingsData, totalSums.holdings, false),
      donutResults,
      sums,
    };
  }, [data, getGroupedData]);
  const totalInvestments = useMemo(
    () => donutData.sums.holdings, 
    [donutData.sums.holdings],
  );

  const cashlessHoldingAmount = useMemo(() => donutData.holdingsData.reduce((result, item) => {
    if (item.stockQuote !== CASH_KEY) {
      return result + item.amount;
    }
    return result;
  }, 0), [donutData.holdingsData]);

  useEffect(() => {
    setCurrentHoldings(data);
  }, [data, setCurrentHoldings]);

  return {
    isInvestorAccountExists,
    isInvestorExists,
    isLoading: false, // TODO: this field broke investment hub
    isSuccess,
    donutResults: donutData.donutResults,
    allHoldingsData: donutData.allHoldingsData,
    holdingsData: donutData.holdingsData,
    totalHoldingsAmount: donutData.sums.holdings,
    cashlessHoldingAmount,
    data,
    details,
    totalInvestments,
    formattedList ,
  };
};
