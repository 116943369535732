import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { onlyDateFromServer } from '@common-fe/common-fe';
import { toString, uniqBy } from 'lodash';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { isDateInRange } from '@/utils/modifiers/isDateInRange';

import { useDeductiblesServicesQuery } from '.';

interface ServicePayload {
  id: number;
  name: string;
}

interface Service {
  id: string;
  name: string;
}

interface CoveragePeriodPayload {
  coverage_date_end?: string;
  coverage_date_start?: string;
}

interface AccountOrderPayload {
  coverage_periods?: CoveragePeriodPayload[];
  services?: ServicePayload[];
}

interface AccountOrderMatrixPayload {
  payout_definition_id?: number;
  priority?: number;
  matrix_coverages?: CoveragePeriodPayload[];
  account_orders?: AccountOrderPayload[][];
}

const useAccountOrdersMatrixesServicesQuery = (employeeId?: string, serviceDate?: string) => {
  
  const { data, isLoading } = useQuery(
    [PATHS.ACCOUNT_ORDERS_MATRIXES, employeeId],
    () =>
      api.get<AccountOrderMatrixPayload[]>(PATHS.ACCOUNT_ORDERS_MATRIXES, {
        params: { employee_id: employeeId },
      }),
    {
      enabled: !!employeeId,
    }
  );

  const formattedServices = useMemo(() => {
    const accountOrdersMatrixes = data?.data;

    if (!accountOrdersMatrixes?.length) {
      return [];
    }

    const filteredMatrixes = accountOrdersMatrixes.filter((matrix) =>
      matrix?.matrix_coverages?.some((coveragePeriod) =>
        isDateInRange(
          serviceDate,
          onlyDateFromServer(coveragePeriod?.coverage_date_start),
          onlyDateFromServer(coveragePeriod?.coverage_date_end)
        )
      )
    );

    const priorityMatrix = filteredMatrixes?.sort((a, b) => (a?.priority || 0) > (b?.priority || 0) ? 1 : -1)[0];

    const accountOrders = priorityMatrix?.account_orders?.flat();

    if (!accountOrders?.length) {
      return [];
    }

    const filteredAccountOrders = accountOrders.filter((accountOrder) =>
      accountOrder?.coverage_periods?.some((coveragePeriod) =>
        isDateInRange(
          serviceDate,
          onlyDateFromServer(coveragePeriod?.coverage_date_start),
          onlyDateFromServer(coveragePeriod?.coverage_date_end)
        )
      )
    );

    const accountOrdersServices = filteredAccountOrders
      .map((filteredAccountOrder) => filteredAccountOrder?.services)
      ?.flat();

    const uniqAccountOrdersServices = uniqBy(accountOrdersServices, 'id');

    const formattedServices = uniqAccountOrdersServices.map((service) => ({
      id: toString(service?.id),
      name: service?.name || '',
    })).sort((a, b) => (a.name > b.name ? 1 : -1));

    return formattedServices;
  }, [data, serviceDate]);

  const { options} = useDeductiblesServicesQuery(employeeId);

  const extendedServices = useMemo(() => {
    return [...formattedServices ,...options]as Service[];
  }, [formattedServices,options]);
  
  return {
    services: extendedServices,
    isLoading,
  };
};

export default useAccountOrdersMatrixesServicesQuery;
