import {
  useCallback, useEffect, useMemo,
} from 'react';
import {
  useQuery, useQueryClient,
} from 'react-query';
import {
  NotificationStatus,
} from '@common-fe/common-fe';
import { EventSourcePolyfill } from 'event-source-polyfill';
import _ from 'lodash';

import { api, Response } from '@/api';
import { BEARER } from '@/api/api';
import { PATHS } from '@/common';
import { CONFIG } from '@/utils';
import Token from '@/utils/handlers/Token';

import { useFormatNotifications } from '../hooks';
import { RawNotification } from '../notification.types';

import useNotificationReadQuery from './useNotificationRead.query';
import useNotificationsDismissQuery from './useNotificationsDismiss.query';

export const QUERY_KEY = 'getNotifications';
const GATEWAY_KEY = 'gateway';
const API_KEY = 'api';
const NOTIFICATION_STATUS_ENTITY_PATH = '[0].notificationStatus';

export default (isFromQache?: boolean) => {
  const queryClient = useQueryClient();
  const { handleDismissAll, handleDismissById } = useNotificationsDismissQuery();
  const { handleRead, handleSent } = useNotificationReadQuery();

  const {
    data, isFetched, isSuccess, isLoading,
  } = useQuery(
    [QUERY_KEY],
    () => api.get<RawNotification[]>(PATHS.NOTIFICATIONS),
    {
      enabled: !isFromQache,
    },
  );
  const formatedData = useFormatNotifications(data?.data || []);
  // TODO the sorting will be implemented on BE
  const sortedFormatedData = useMemo(
    () => formatedData?.sort((a, b) => +b.createdAt - +a.createdAt),
    [formatedData],
  );
  const isRead = useMemo(
    () => {
      if (!sortedFormatedData) {
        return null;
      }
    
      return _.get(sortedFormatedData, NOTIFICATION_STATUS_ENTITY_PATH, '') as NotificationStatus === NotificationStatus.Read;
    }, [sortedFormatedData],
  );

  const handleReadUnread = useCallback(() => {
    if (isRead === false) {
      handleRead();
    }
     
  }, [handleRead, isRead]);

  const handleLoadMode = useCallback((notifications: RawNotification[]) => {
    const queryData = queryClient
      .getQueryData<Response<RawNotification[]>>([QUERY_KEY]);
    if (queryData && notifications) {
      handleSent();

      queryClient.setQueryData([QUERY_KEY], {
        ...queryData,
        data: [...notifications, ...queryData.data],
      });
    }
  }, [handleSent, queryClient]);

  const handleSubscribe = useCallback(() => {
    const listener = new EventSourcePolyfill(
      // `${CONFIG.BASE_URL}${PATHS.NOTIFICATIONS_STREAM}?employee_id=${employeeId}`,
      // TODO: BE should allow us to use the endpoint. At the moment GATEWAY blocks FE.
      `${CONFIG.BASE_URL?.replace(GATEWAY_KEY, API_KEY)}${PATHS.NOTIFICATIONS_STREAM}`,
      {
        headers: {
          Authorization: `${BEARER} ${Token.getToken()}`,
        },

      },
    );
    listener.onmessage = (event) => {
      if (event.data) {
        const parsed = JSON.parse(event.data) as RawNotification[];
        if (parsed.length) {
          handleLoadMode(parsed);
        }
      }
    };
    const dispose = () => {
      listener.close();
    };
    return dispose;
  }, [handleLoadMode]);
  useEffect(() => {
    if (!isFromQache) {
      const dispose = handleSubscribe();
      return () => {
        dispose();
      };
    }

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromQache]);

  return {
    data: sortedFormatedData || [],
    isRead,
    isLoading: isLoading && !isFetched,
    isSuccess,
    isFetched,
    handleDismissAll,
    handleDismissById,
    handleRead: handleReadUnread,
  };
};
