import React from 'react';
import {
  AlertIcon,
  AppButton,
  Box,
  Inscription,
} from '@common-fe/common-fe';

import { FileUploadPopup } from '@/modules/transaction/components/ReimburseMe/FileUploadPopup/FileUploadPopup';
import { useFileUploadPopupStore } from '@/modules/transaction/components/ReimburseMe/FileUploadPopup/store';
import { joinWithCommas } from '@/utils/modifiers/joinWithCommas';

const missingMockedFields = ['Service Description', 'Provider/Merchant', 'Kek'];

interface Props {
  missingFields?: string[];
}

export const ClaimMissingErrorBanner: React.FC<Props> = ({
  missingFields = missingMockedFields,
}) => {
  const onSetVisible = useFileUploadPopupStore((store) => store.handleChangeVisibility);

  return (
    <Box
      data-testid="ClaimMissingErrorBanner-wrapper"
      round="container1Round"
      background="canvas"
      pad={{ horizontal: 'spacing12', bottom: 'spacing12' }}
      border={{ color: 'border2' }}
      margin={{ top: 'spacing12' }}
    >
      <Box
        round="small"
        height="2px"
        background="info"
      />
      <Box
        margin={{ top: 'spacing12' }}
        pad={{ horizontal: 'spacing24', vertical: 'spacing16' }}
        border={{ color: 'infoBorder' }}
        round="fieldRound"
        background={{ color: 'infoContainer' }}
      >
        <Box
          direction="row"
          align="center"
          margin={{ bottom: 'spacing8' }}
        >
          <AlertIcon color="info" />
          {/* <Inscription margin={{ left: 'spacing8' }} weight={700} lineHeight="20px">
            We are unable to find the {joinWithCommas(missingFields)} on the document provided.
          </Inscription> */}
          <Inscription margin={{ left: 'spacing8' }} weight={700} lineHeight="20px">
            We cannot find the information required in the document you provided.
          </Inscription>
        </Box>

        <Box margin={{ bottom: 'spacing16' }}>
          {/* <Inscription lineHeight="20px">
            This information is required to process the claim.
            If this information is on your document, please take
            another picture of the receipt making sure the image
            is clear and {joinWithCommas(missingFields)} is visible.
          </Inscription> */}
          <Inscription lineHeight="20px">
            Please try again and make sure {joinWithCommas(missingFields)} are all visible.
          </Inscription>
        </Box>

        <AppButton
          testId="ClaimMissingErrorBanner-upload-document"
          onClick={() => onSetVisible(true)}
        >
          Upload Document
        </AppButton>
      </Box>
      <FileUploadPopup />
    </Box>
  );
};
