import { storeCreator } from '@/utils/store/store.utils';


interface State {
  isApplied: boolean;
  apply: () => void;
  reset: () => void;
  
}

const useStore = storeCreator<State>((set) => ({
  isApplied: false,
  apply: () => set(() => ({
    isApplied: true
  })),
  reset: () => set(() => ({
    isApplied: false
  })),
}));

export default useStore;
