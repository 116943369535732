import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuccessModal } from '@common-fe/common-fe';

import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import { RemovePaymentMethodModal as RemoveModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/RemovePaymentMethod';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';

enum Step {
  REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD',
  SUCCESSFULLY_REMOVED = 'SUCCESSFULLY_REMOVED',
}

interface Props {
  onSetVisible: (value: boolean) => void;
  refetchPaymentMethods: () => void;
}

const RemovePaymentMethodModal: React.FC<Props> = ({ onSetVisible, refetchPaymentMethods }) => {
  const { t } = useTranslation();
  const { handleAddPermanentSnackbar } = useSnackbar();
  const [step, setSteps] = useState(Step.REMOVE_PAYMENT_METHOD);
  const editableMethod = useStore((state) => state.editableMethod);

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleRemoveSuccess = useCallback(() => {
    refetchPaymentMethods();
    setSteps(Step.SUCCESSFULLY_REMOVED);
  }, [refetchPaymentMethods]);

  useEffect(() => {
    return () => {
      if (step === Step.SUCCESSFULLY_REMOVED) {
        handleAddPermanentSnackbar({
          text: 'Payment method was deleted successfully',
          state: SnackbarState.positive,
          closeIcon: true,
          autoRemove: true,
        });
      }
    };
  }, [step, handleAddPermanentSnackbar]);

  return (
    <>
      {step === Step.REMOVE_PAYMENT_METHOD && (
        <RemoveModal
          onClose={handleClose}
          onBack={handleClose}
          onSuccess={handleRemoveSuccess}
          methodForRemove={editableMethod}
        />
      )}
      {step === Step.SUCCESSFULLY_REMOVED && (
        <SuccessModal
          visible
          header={t('Your payment method has been removed!')}
          buttonText={t('Got It!')}
          onSetVisible={handleClose}
          buttonStyle={{ width: '200px' }}
        />
      )}
    </>
  );
};

export default RemovePaymentMethodModal;
