import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  RoundCloseIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

const HEADER_TEXT = 'Cannot update risk model';

const DESCRIPTION_TEXT = `Your portfolio allocations are in the process of being updated. This can take 1 to 2 business day to complete.
 Once updated, your risk model can be updated.`;

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
}

export const WarningUpdateRiskPortfolioModal: React.FC<Props> = ({
  visible,
  onSetVisible,
}) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper testId="WarningUpdateRiskPortfolioModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24' }}>
        <RoundCloseIcon size="spacing48" color="danger" />
        <Text
          data-testid="WarningUpdateRiskPortfolioModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {t(HEADER_TEXT)}
        </Text>
        <Text
          data-testid="WarningUpdateRiskPortfolioModal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {t(DESCRIPTION_TEXT)}
        </Text>

        <Box direction="row">
          <AppButton
            testId="WarningUpdateRiskPortfolioModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            width="200px"
          >
            {t('Got it!')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
