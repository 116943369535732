import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CrossIcon,
  LinkButton,
  MagicIcon,
  Text,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { useSetupAutoInvestmentsModalStore } from '../stores';

import { useAutoInvestmentReminder } from './hooks';

interface Props {
  accountId?: string;
}

const AutoInvestmentReminder: React.FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();
  const setModalVisible = useSetupAutoInvestmentsModalStore((state) => state.setVisible);
  const { handleDismiss } = useAutoInvestmentReminder();
  const history = useHistory();

  const handleRedirect = useCallback(() => {
    setModalVisible(true);
    history.push(`${ROUTES.INVESTMENTS}?id=${accountId}`);
  }, [accountId, history, setModalVisible]);
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: 'l', vertical: 's' }}
      margin={{ top: 'm' }}
      background="canvas"
      round="container1Round"
      data-testid="learn_more_investing_automatic_reminder-wrapper"
      border={{ size: 'small', color: 'border2' }}
      elevation="default"
    >
      <Box alignSelf="right">
        <MagicIcon color="iconAccent" />
      </Box>
      <Box direction="column" margin={{ left: 'xs' }} flex>
        <Text>{t('Boost your investments without lifting a finger.')}</Text>
        <LinkButton onClick={handleRedirect} linkInlineMode>
          {t('Learn more about Automatic Investments')}
        </LinkButton>
      </Box>
      <Box
        data-testid="dismiss-close_button"
        alignSelf="right"
        style={{ cursor: 'pointer' }}
        onClick={handleDismiss}
      >
        <CrossIcon size="16px" color="iconPrimary" />
      </Box>
    </Box>
  );
};

export default AutoInvestmentReminder;
