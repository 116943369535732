import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.spacings.spacing4};

  svg {
    width: ${({ theme }) => theme.spacings.spacing32};
    height: ${({ theme }) => theme.spacings.spacing32};
  }
`;

interface Props {
  children: React.ReactNode;
}

const PaymentTypeIconWrapper: React.FC<Props> = ({ children }) => <Wrapper>{children}</Wrapper>;

export default PaymentTypeIconWrapper;
