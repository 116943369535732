import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const EXIT_POPUP_CLASS = 'exit-popup';
export const ENTER_POPUP_CLASS = 'enter-popup';

export const AnimatedPopupWrapper = styled(Box)`
  @keyframes entrance {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes exit {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
  &.${EXIT_POPUP_CLASS} {
    animation: exit 0.3s ease-in-out;
  }
  &.${ENTER_POPUP_CLASS} {
    animation: entrance 0.3s ease-in-out;
  }
`;

export const IconContainer = ({ children }: { children: React.ReactElement }) => (
  <Box
    width="50px"
    height="50px"
    background={{ color: 'canvas' }}
    round="container1Round"
    align="center"
    justify="center"
  >
    {children}
  </Box>
);
