import React from 'react';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { CardDetails } from '@/modules/transaction/components/CardDetails/CardDetails';
import { useGetCardsQuery } from '@/modules/transaction/hooks/useGetCards.query';
import { useSearchParams } from '@/modules/transaction/hooks/useSearchParams';
import { useAuthStore } from '@/modules/user/stores';

export default () => {
  const { query: dependentId } = useSearchParams('dependent_id');
  const { query: cardId } = useSearchParams('card_id');
  const { user } = useAuthStore();
  const { holderName } = useGetCardsQuery(user?.employee?.id, dependentId);

  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: 'Home',
    value: ROUTES.HOME,

  }, {
    key: ROUTES.CARDS,
    title: 'Cards',
    value: `${ROUTES.CARDS}`,
  }, {
    key: `${ROUTES.CARD_DETAILS}?dependent_id=${dependentId}&card_id=${cardId}`,
    title: holderName,
    value: `${ROUTES.CARD_DETAILS}?dependent_id=${dependentId}&card_id=${cardId}`,
  },
  ], true);

  return <CardDetails />;
};
