import { storeCreator } from '@/utils/store/store.utils';

interface State {
  state: {
    cardId: string;
  };
  handleChangeState: (state: State['state']) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  state: {
    cardId: '',
  },

  handleChangeState: (state) => set((oldState) => ({
    ...oldState,
    state,
  })),
  handleReset: () => set((state) => ({
    ...state,
    state: {
      ...state.state,
      cardId: '',
    },
  })),
}));
export const useSetCardStatusModal = () => {
  const store = useStore();
  return {
    ...store,
    state: {
      ...store.state,
      visible: !!store.state.cardId,
    },
  };
};
