import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Anchor, Box, costFormater,
  Hint, InfoOutlineIcon, LinkIcon,
  ListItem, ListItemBase, ListItemType, Text, } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { useGetFundIcon } from '@/modules/investments/hooks';
import { IconWrapper } from '@/modules/investments/hooks/useInvestmentsHoldings';
import { InvestmentActivitiesParams, InvestmentActivityDetails, InvestmentActivitySide, InvestmentActivityStatus, InvestmentOrderType } from '@/modules/investments/investments.types';
import theme from '@/styles/theme';

import useGetOrderType from '../../hooks/useGetOrderType';
import ActivityStatus from '../ActivityStatus';
import useInvestmentActivitiesListQuery from '../queries/useInvestmentActivitiesList.query';

const CASH_KEY = 'CASH';

const useInvestmentActivitiesList = (searchParams: InvestmentActivitiesParams) => {
  const { t } = useTranslation();
  const getOrderType = useGetOrderType();

  const headers: ListItemBase[] = useMemo(() => [
    {
      key: 'fundName',
      title: t('Holding name'),
      flex: 7,
    }, {
      key: 'research',
      title: '',
      flex: 2,
    }, {
      key: 'type',
      title: t('Type'),
      flex: 3,
    }, {
      key: 'date',
      title: t('Date'),
      flex: 3,
    },
    //  {
    //   key: 'time',
    //   title: t('Time'),
    //   flex: 3,
    // },
    {
      key: 'amount',
      title: t('Amount'),
      flex: 3,
    }, {
      key: 'shares',
      title: t('Shares'),
      flex: 3,
    }, {
      key: 'status',
      title: t('Status'),
      endAlign: true,
      flex: 3,
    },
  ], [t]);
  const { isLoading, data, total } = useInvestmentActivitiesListQuery(searchParams);
  const iconLabel = useMemo(() => (
    <IconWrapper margin={{ left: 'spacing8' }}>
      <InfoOutlineIcon color="iconSecondary" size="20px" />
    </IconWrapper>
  ), []);
  const handleGetFundIcon = useGetFundIcon();
  
  const formatedList = useMemo<ListItem[]>(
    () => data?.map((item: InvestmentActivityDetails, index) => ({
      id: `${item.symbol} ${index}`,
      fields: [
        {
          key: 'name',
          type: ListItemType.Node,
          title: '',
          node: (
            <Box direction="row" align="center">
              { 
                handleGetFundIcon({
                  image: item.image,
                  status: item.instrumentStatus
                }) 
  
              }
              <Box margin={{ left: 'm' }} direction="column">
                <Text
                  weight="bold"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title={item.name}
                >{item.name}
                </Text>
                {item.type === CASH_KEY
                  && (
                    <Hint hintElement={iconLabel}>
                      <Text textAlign="center">
                        {t('DriveWealth keeps some amount of cash for market trades. The amount depends on your risk category level.')}
                      </Text>
                    </Hint>
                  )}
                <Text>{item.symbol}</Text>
              </Box>
            </Box>),
          flex: 7,
        },
        {
          key: 'research',
          type: ListItemType.Node,
          flex: 2,
          title: '',
          hover: true,
          node: (
            <Box justify="center" align="center">
              {
                item.type === CASH_KEY
                  ? (
                    <Box width={theme.spacings.xl} />
                  ) : (
                    <Anchor href={item.researchURL} target="_blank">
                      <LinkIcon size={theme.spacings.m} color="iconSecondary" />
                    </Anchor>
                  )
              }
            </Box>
          ),
        },
        {
          key: 'type',
          type: ListItemType.Text,
          title: getOrderType(item.orderType),
          flex: 3,
        },
        {
          key: 'date',
          type: ListItemType.Text,
          title: dayjs(item.dateTime).format(DEFAULT_DATE_FORMAT),
          flex: 3,
        },
        // {
        //   key: 'time',
        //   type: ListItemType.Text,
        //   title: dayjs(item.dateTime).format(DEFAULT_TIME_FORMAT),
        //   flex: 3,
        // },
        {
          key: 'amount',
          type: ListItemType.Node,
          title: '',
          node: (
            <Text
               
              color={item.status === InvestmentActivityStatus.EXPIRED
                ? 'textDisabled' : item.side === InvestmentActivitySide.BUY ? 'success' : 'error'}
              weight="bold"
            >
              {`${item.side === InvestmentActivitySide.BUY ? '+' : '-'}`}{costFormater(item.amount, true)}
            </Text>),
          flex: 3,
        },
        {
          key: 'shares',
          type: ListItemType.Node,
          title: '',
          node: <Text color={item.status === InvestmentActivityStatus.EXPIRED ? 'textDisabled' : 'textBody'} weight="bold">{item.shares}</Text>,
          flex: 3,
        },
        {
          key: 'status',
          type: ListItemType.Node,
          title: '',
          node: (
            <ActivityStatus status={item.status} />
          ),
          endAlign: true,
          flex: 3,
        },
      ],
    })), [data, getOrderType, handleGetFundIcon, iconLabel, t],
  );
  return ({
    data,
    headers,
    formatedList,
    total,
    isLoading,
  });
};

export default useInvestmentActivitiesList;
