import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Text } from '@common-fe/common-fe';

import { PreventLeaveModal } from '@/modules/core/components';

import { useInvestor } from '../../hooks';
import SelfDirectedAllocationFunds from '../SelfDirectedAllocation/SelfDirectedAllocationFunds';
import SelfDIrectedWrapper from '../SelfDirectedWrapper';

import InvestmentPortfolioBalanceLabel from './InvestmentPortfolioBalanceLabel';
interface Props {
  accountId: string;
  investorId: string;
}

const SwitchToSelfDirectedAllocation: React.FC<Props> = ({ accountId, investorId}) => {
  const { t } = useTranslation();
  const [isPreventLeave, setPreventLeave] = useState(true);
  const [isSubmitted, setSubmitted] = useState(false);

  const {data: investor} = useInvestor();
  const portfolioBalance = useMemo(() => {
    return investor.cashBalance + investor.equityValue;
  }, [investor.cashBalance, investor.equityValue]);
  

  const handleConfirm = useCallback(() => {
    setPreventLeave(false);
  }, []);

 
  return (
    <>
      <SelfDIrectedWrapper isSwitchMode currentStep={2}>
        <PreventLeaveModal isPrevent={isPreventLeave} />
        <Box direction='row' justify='between' margin={{bottom: 'l'}}>
          <Text size="3xl" weight={700}>
            {t('Set Fund Allocation')}
          </Text>
          <InvestmentPortfolioBalanceLabel value={portfolioBalance} />
        </Box>
        <SelfDirectedAllocationFunds
        // isErrors={!!errorText}
          investorId={investorId}
          isSwitchMode
          onConfirm={handleConfirm}
          accountId={accountId}
          investAmount={portfolioBalance}
          isSubmitted={isSubmitted}
          onSetSubmitted={setSubmitted}
        />
      </SelfDIrectedWrapper>
    </>
  );
};
export default SwitchToSelfDirectedAllocation;

