import { AppButton,Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  position: relative;
  ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 2px;
    left: ${({ theme }) => theme.spacings.spacing24};
    right: ${({ theme }) => theme.spacings.spacing24};
    background: ${({ theme }) => theme.colors.warning};
  };
`;

export const StyledAppButton = styled(AppButton)`
  font-size: ${({ theme }) => theme.spacings.spacing16};
  color: ${({ theme }) => theme.colors.background1};
  border-color: ${({ theme, active }) => (active ? theme.colors.button : theme.colors.border)};
  font-weight: 400;
  &:hover {
    border-color: ${({ theme }) => theme.colors.button};
    background-color: ${({ theme, active }) => (active ? theme.colors.buttonSecondaryContainer : 'transparent')};
  };
`;

export const Point = styled.div`
  border-radius: 50%;
  margin: ${({ theme }) => `${theme.spacings.spacing12}`};
  width: ${({ theme }) => theme.spacings.spacing4};
  height: ${({ theme }) => theme.spacings.spacing4};
  background-color: ${({ theme }) => theme.colors.border};
`;
