import { useMutation } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { RAW_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import {
  ReimbursementDto,
  ReimbursementFormPayload,
} from '@/modules/transaction/components/ReimburseMe/ReimburseMe.types';
import { useAuthStore } from '@/modules/user/stores';

export enum ClaimApprovalStatusCode {
  AUTOMATICALLY_APPROVED_AI_RECEIPT_APPROVED = 'AUTOMATICALLY_APPROVED_AI_RECEIPT_APPROVED'
}

interface Props {
  onRetryError?: () => void;
}

const useClaimInfoQuery = ({ onRetryError }: Props) => {
  const { user } = useAuthStore();

  const { values: { documentId, files } } = useFilesInDocumentStore();
  const { mutateAsync, isLoading } = useMutation(
    (value: ReimbursementFormPayload) => api.post(
      PATHS.CREATE_CLAIM,
      {
        pay_to_type: value.payToType,
        provider_id: Number(value.provider),
        ...(user?.organization?.id
          ? { organization_id: Number(user?.organization?.id) } : {}),
        payment_type: value.paymentType,
        service_id: Number(value.service),
        transact_method: value.transactMethod,
        requested_amount: parseFloat(value.amount),
        local_date_service_on: dayjs(value.serviceDate).format(RAW_DATE_FORMAT),
        service_for: {
          ...(`${user?.employee?.id}` === value.patient
            ? {
              employee_id: user?.employee?.id,
              claim_actor_type: user?.role,
            }
            : {
              dependent_id: Number(value.patient),
              claim_actor_type: 'DEPENDENT',
            }
          ),
        },
        ocr_receipt_id: value?.ocrReceiptId,
        source: 'WEBSITE',
        employee_provider_acc: value.account,
        provider_invoice: value.invoice,
        organization_path: user?.organization?.path,
        ...(user?.employee?.id ? { employee_id: Number(user?.employee?.id) } : {}),
        ...files.length && documentId ? {
          document_id: parseInt(documentId, 10),
        } : {},
        claim_status_type: 'PROCESSING',
        ...value?.paymentMethodId ? { payment_method_id: parseFloat(value?.paymentMethodId) } : {},
      } as ReimbursementDto,
    ),
    {
      ...onRetryError ? {
        retryDelay: 1000,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        retry: (attemptNumber: number, error: any) => {
          if (attemptNumber === 1) {
            onRetryError();
          }
          // retry at 5XX error only 1 time
          return attemptNumber === 0 && error.response.status >= 500;
        },
      } : {},
    },
  );
  return {
    isLoading,
    mutateAsync,
  };
};
export default useClaimInfoQuery;
