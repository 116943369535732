import React from 'react';
import { useTranslation } from 'react-i18next';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useHasAccess, useRedirectHome } from '@/modules/core/hooks';
import StartInvesting from '@/modules/investments/StartInvesting';
import useMyEnrollments from '@/modules/transaction/hooks/useMyEnrollments';
import { useSearchParams } from '@/modules/transaction/hooks/useSearchParams';

const StartInvestingPage = () => {
  const { t } = useTranslation();
  const { query } = useSearchParams('accountId');
  const { currentMyAccount } = useMyEnrollments(query);
  const isInvestmentsDisabled = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  useRedirectHome(isInvestmentsDisabled);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id),
    title: currentMyAccount?.accountType,
    value: ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id),
  },
  {
    key: ROUTES.START_INVESTING,
    title: t('Start Investing'),
    value: ROUTES.START_INVESTING,
  }], true);
  return (
    <StartInvesting />
  );
};

export default StartInvestingPage;
