import React, {
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Heading,
  Image,   Text, } from '@common-fe/common-fe';

import BannerImage from '@/assets/placeholders/dashboardBanner.svg';
import ROUTES from '@/common/routes';
import { Confetti } from '@/components/elements';
// import { CounterWrapper } from '@/components';
import { useBreadcrumbs } from '@/modules/core/hooks';
import useAllowNotifications from '@/modules/core/hooks/useAllowNotifications';
import NotificationList from '@/modules/notification/NotificationsList';
import { useAuthStore } from '@/modules/user/stores';
import { MODULE_NAME } from '@/modules/user/user.constants';
import { SEEN_KEY } from '@/utils/handlers/Token';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import {
  BannerWrapper,
  ConfettiWrapper,
  WelcomeMessage,
  Wrapper,
  // Primary,
} from './Banner.styles';

const Banner = () => {
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: 'Home',
    value: ROUTES.HOME,
  }]);
  const { t } = useTranslation(MODULE_NAME);
  const { user } = useAuthStore();
  const isEmployee = useMemo(() => !!user?.employee?.id, [user]);
  const isNotificationsAllowed = useAllowNotifications();
  const alreadySaw = useRef(localStorage.getItem(SEEN_KEY));
  useEffect(() => {
    if (!alreadySaw.current) {
      localStorage.setItem(SEEN_KEY, 'true');
    }
  }, []);
  return (
    <BannerWrapper>
      <Wrapper>
        <WelcomeMessage fill>
          <Image
            className="image"
            src={whiteLabelingConfig?.images?.banner || BannerImage}
            fit="contain"
          />
          <Text size="large" color="textTitle" style={{ zIndex: 1 }}>
            {t('Welcome, ')}
          </Text>
          <Heading
            weight={700}
            level={1}
            size="regular"
            color="textTitle"
            style={{ zIndex: 1 }}
            data-testid="banner-username"
          >
            {user?.firstName}
            !
          </Heading>

          {/* {notifications && notifications.length && (
          <Primary
            // flexMode
            rightIcon={<CounterWrapper whiteMode>{notifications.length}</CounterWrapper>}
          >
            {t('You have notifications')}
          </Primary>
          )} */}
        </WelcomeMessage>
        <Box fill>
          {isEmployee && isNotificationsAllowed && (
            <NotificationList />
          )}
        </Box>

      </Wrapper>
      {!alreadySaw.current && (
        <ConfettiWrapper>
          <Confetti />
        </ConfettiWrapper>
      )}
    </BannerWrapper>
  );
};
export default Banner;
