import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { onlyDateToServer } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { toString } from 'lodash';

import { api } from '@/api';
import { RAW_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import { useAuthStore } from '@/modules/user/stores';
import { UserModel } from '@/modules/user/user.types';

import usePatientStore from './AddPatienForm/usePatient.store';

export interface Patient {
  id: number;
  firstName: string;
  lastName: string;
  relationshipType: string;
  birthOn: string;
  fullName: string;
}

export interface PatientPayload {
  id: number;
  first_name: string;
  last_name: string;
  relationship_type: string;
  local_date_birth_on: string;
}

export interface Person {
  id: number;
  preferredName: string;
  firstName: string;
  lastName: string;
}

const CREATE_DEPENDENT_KEY = 'update_card_holder';

const formatPatientToServer = (patient: Patient, user?: UserModel | null) => ({
  ssn: 'none',
  first_name: patient.firstName,
  middle_name: 'none',
  last_name: patient.lastName,
  local_date_birth_on: dayjs(patient.birthOn).format(RAW_DATE_FORMAT),
  person: {
    id: user?.employee?.personId,
    preferred_name: user?.preferredName,
    first_name: user?.firstName,
    last_name: user?.lastName,
  },
  relationship_type: patient.relationshipType,
});

export const useCreatePatientQuery = (onSuccess?: (dependentId?: string) => void) => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(CREATE_DEPENDENT_KEY, `${false}`);

    return queryGenerator;
  }, []);
  const { setPatientId } = usePatientStore();
  const { user } = useAuthStore();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    (value: Patient) => api.post(
      `${PATHS.DEPENDENTS}?${query}`,
      formatPatientToServer(value, user),
    ),
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(PATHS.PERSONS_BY_ID(user?.id));
        setPatientId(`${newData?.data.id || ''}`);
        if (onSuccess) {
          onSuccess(`${newData?.data.id || ''}`);
        }
      },
    },
  );

  const handleSave = useCallback(async (value: Patient) => {
    await mutateAsync(value);
  }, [mutateAsync]);

  return {
    isLoading,
    save: handleSave,
  };
};

export const useGetPatientsQuery = () => {
  const { user } = useAuthStore();
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    PATHS.PERSONS_BY_ID(user?.id),
    () => api.get(PATHS.PERSONS_BY_ID(user?.id),
      {
        headers: {
          'X-DETAILS-RESOLVERS': 'DEPENDENT',
        },
      }),
  );
  return ({
    patients: data?.data.dependents.map((patient: PatientPayload) => ({
      id: toString(patient.id),
      firstName: patient.first_name,
      lastName: patient.last_name,
      fullName: `${patient.first_name || ''} ${patient.last_name || ''}`.trim(),
      relationshipType: patient.relationship_type,
      birthOn: patient.local_date_birth_on,
    })) || [],
    isError,
    isLoading,
    isSuccess,
  });
};
