import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexControlledForm, Inscription, LinkButton, useDebounceState } from '@common-fe/common-fe';

import { ExternalLink } from '@/common/constants';
import { PhoneVerificationStep, VerificationType } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';
import usePhoneVerificationFields, { FormValues } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/hooks/usePhoneVerificationFields';
import { useValidateForm } from '@/utils/hooks';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import useSendVerificationCodeQuery from '../../queries/useSendVerificationCode.query';
import VerificationModal from '../VerificationModal';

interface Props {
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  onSetVisible: (value: boolean) => void;
  setStep: (step: PhoneVerificationStep) => void;
}

const EnterPhoneNumberVerificationModal: React.FC<Props> = ({ phoneNumber, setPhoneNumber, onSetVisible, setStep }) => {
  const { t } = useTranslation();
  const [state, handleSetState] = useState<FormValues>();
  const { values, setValues } = useDebounceState<FormValues>();
  const fields = usePhoneVerificationFields(phoneNumber);
  const { handlePreSubmit, hasErrors } = useValidateForm(fields);
  const { sendVerificationCode, isLoading, isError } = useSendVerificationCodeQuery();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  const handleClick = useCallback((link: string) => {
    if (link) {
      window.open(link, '_blank');
    }
  }, []);

  const handleCancel = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleNext = useCallback(async () => {
    const isSuccess = handlePreSubmit(state);
    if (isSuccess) { 
      await sendVerificationCode({
        type: VerificationType.SMS,
        value: state?.phoneNumber || ''
      });
      setPhoneNumber(state?.phoneNumber || '');
      setStep(PhoneVerificationStep.PHONE_VERIFICATION_CODE);
    }
  }, [sendVerificationCode, setPhoneNumber, setStep, handlePreSubmit, state]);

  return (
    <VerificationModal
      title={t('Stay in the Know')}
      subTitle={t('Setup text alerts and never miss an update.')}
      isLoading={isLoading}
      isError={isError}
      onSetVisible={onSetVisible}
      onCancel={handleCancel}
      onNext={handleNext}
      warningText={(
        <Inscription>
          {t('By opting in to text messaging, you  agree to the ')}
          <LinkButton onClick={() => handleClick(whiteLabelingConfig?.termsAndConditions || ExternalLink.termsAndCondition)} linkInlineMode>
            {t('Terms of Service')}
          </LinkButton>
          {t(' and ')}
          <LinkButton onClick={() => handleClick( whiteLabelingConfig?.privacyPolicy || ExternalLink.privacyPolicy)} linkInlineMode>
            {t('Privacy Policy')}
          </LinkButton>
          {t('. Msg & Data rates may apply. You can turn off text messaging any time.')}
        </Inscription>
      )}
      form={(
        <FlexControlledForm
          fields={fields}
          formStyle={{ margin: '24px' }}
          onChangeValues={setValues}
          showError={hasErrors}
          editMode
        />
      )}
    />
  );
};

export default EnterPhoneNumberVerificationModal;
