import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  costFormater,
  Enrollment,
  FlexList,
  PencilIcon,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { RiskControlOptions } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import usePortfolioList from '@/modules/investments/hooks/usePortfolioList';
import RiskCard from '@/modules/investments/RiskCard';
import RiskModal from '@/modules/investments/RiskModal';
import theme from '@/styles/theme';

import EditAmountModal from '../../modal/EditAmountModal';
import {
  Title, } from '../BuySell.styles';
import DisclosureFooter from '../DisclosureFooter';
import { useFundingAmountStore } from '../stores/useFundingAmount.store';
import { usePortfolioAllocationStore } from '../stores/usePortfolioAllocation.store';

interface Props {
  onBack: () => void;
  onNext: (value: boolean) => void;
  stepLength: number;
  step: number;
  investorId: string;
  investmentAmount: string;
  currentMyAccount: Enrollment;
  toleranceModel?: string;
}

// const MOCKED_FUNDING_AMOUNT = 500;
const BUTTON_WIDTH = '130px';

const PortfolioAllocation: React.FC<Props> = ({
  onBack,
  onNext,
  stepLength,
  step,
  investorId,
  investmentAmount,
  currentMyAccount,
  toleranceModel,
}) => {
  const { t } = useTranslation();
  const riskOptions = useMemo(
    () => [
      {
        label: t('Conservative Model'),
        value: RiskControlOptions.CONSERVATIVE,
      },
      {
        label: t('Moderately Conservative Model'),
        value: RiskControlOptions.MODERATELY_CONSERVATIVE,
      },
      {
        label: t('Moderate Model'),
        value: RiskControlOptions.MODERATE,
      },
      {
        label: t('Moderately Aggressive Model'),
        value: RiskControlOptions.MODERATELY_AGGRESSIVE,
      },
      {
        label: t('Aggressive Model'),
        value: RiskControlOptions.AGGRESSIVE,
      },
    ], [t],
  );
  const [riskValue, setRiskValue] = useState(toleranceModel || RiskControlOptions.MODERATE);
  const {
    formatedList,
    data,
    headers,
    isLoading,
    refetch,
  } = usePortfolioList(riskValue as RiskControlOptions, investorId, _.toNumber(investmentAmount));
  const { handleSetValues } = usePortfolioAllocationStore();
  const { handleSetValues: handleSetAmountValues } = useFundingAmountStore();
  const [openRiskModal, setRiskModal] = useState<boolean>(false);
  const [editAmountlVisible, setEditAmountlVisible] = useState<boolean>(false);
  const recommendedRiskObj = useMemo(() => riskOptions?.find(
    (elem) => elem.value === riskValue,
  ), [riskValue, riskOptions]);
  const handleSubmit = useCallback(() => {
    onNext(true);
    handleSetValues({
      riskValue,
    });
  }, [onNext, handleSetValues, riskValue]);

  return (
    <Box direction="column" data-testid="portfolio-allocation-wrapper-id">
      <Box flex="grow" pad={{ bottom: '40px' }}>
        <Title data-testid="portfolio-title-wrapper-id">{t('Portfolio Allocation')}</Title>
        <Box direction="row">
          <Text size="large">{t('Based on your risk assessment, we recommend')}</Text>
          <Text size="large" margin={{ horizontal: '4px' }} weight="bold">{t('the ')}{recommendedRiskObj?.label}</Text>
          <Text size="large">{t('portfolio allocation for you.')}</Text>
        </Box>
        <Box flex="grow" direction="column" background="module" round="moduleRound" pad="spacing24" margin={{ bottom: 'spacing24', top: 'spacing12' }}>
          <Box direction="row" justify="between" margin={{ bottom: 'spacing24' }}>
            <Text size="large" weight="bold">{t('Confirm your preferred Investment Risk Model')}</Text>
            <Box direction="row" align="center">
              <Text size="large">{t('Estimated amount to be invested:')}</Text>
              <Box direction="row" onClick={() => setEditAmountlVisible(true)}>
                <Text size="large" weight="bold" color="textAccent" margin={{ horizontal: 'spacing8' }}>{costFormater(+investmentAmount, true)}</Text>
                <PencilIcon
                  color={theme.colors.iconAccent}
                />
              </Box>
            </Box>
          </Box>
          <Box direction="column" flex={{ grow: 1 }} width={{ max: '580px' }}>

            <Box direction="row">
              <RiskCard
                riskValue={riskValue}
                setRiskValue={setRiskValue}
                setRiskModal={setRiskModal}
                disabled
                labelColor={theme.colors.stressContainer}
                labelText={t('Your Current Portfolio')}
                isHintHidden
                riskOptions={riskOptions}
              />
            </Box>
          </Box>
        </Box>
        <Box background="module" round="moduleRound" margin={{ bottom: 'spacing24' }}>
          <Text margin={{ bottom: 'spacing16', top: 'spacing32', horizontal: 'spacing24' }} size="large" weight="bold">{_.startCase(riskValue)}{` Investment Portfolio: ${data?.length || 0}`}</Text>
          <FlexList
     
            rows={formatedList}
            headers={headers}
            loading={isLoading}
            footer={(
              <Box
                direction="row"
                justify="stretch"
                pad={{ horizontal: 'spacing24', vertical: 'spacing6' }}
                margin={{ bottom: 'spacing16', top: 'spacing8' }}
                background="border1"
                round="container1Round"
              >
                <Text style={{ flex: 4 }} weight="bold">{t('Total:')}</Text>
                <Text style={{ flex: 1 }} />
                <Text weight="bold" style={{ flex: 1 }}>
                  100%
                </Text>
                <Text weight="bold" style={{ flex: 1 }}>{costFormater(+investmentAmount, true)}</Text>
                <Text style={{ flex: 2 }} />
              </Box>
            )}
          />
        </Box>
        <Box direction="row" align="center" justify="end">
          <Box direction="row" justify="end" align="center">
            <AppButton
              buttonType="secondary"
              onClick={() => onBack()}
              containerStyle={{ marginRight: theme.spacings.s }}
              width={BUTTON_WIDTH}
            >
              {t('Back')}
            </AppButton>
            <AppButton
              type="submit"
              onClick={() => handleSubmit()}
              width={BUTTON_WIDTH}
            >
              {t(`Submit (${step}/${stepLength})`)}
            </AppButton>
          </Box>
        </Box>
        <DisclosureFooter /> 
      </Box>
      <EditAmountModal
        visible={editAmountlVisible}
        onSetVisible={setEditAmountlVisible}
        currentMyAccount={currentMyAccount}
        investmentAmount={investmentAmount}
        onSave={handleSetAmountValues}
        onRefetch={refetch}
        buySellMode
      />
      <RiskModal
        visible={openRiskModal}
        onSetVisible={(value) => setRiskModal(value)}
        activeRisk={riskValue}
      />
    </Box>
  );
};

export default PortfolioAllocation;
