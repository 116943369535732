import { useCallback, useEffect } from 'react';
import { toString } from 'lodash';

import { useGetPaymentMethods } from '@/modules/transaction/components/ReimburseMe/queries/useGetPaymentMethods.query';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useAuthStore } from '@/modules/user/stores';

const useSetPaymentMethodsInStore = () => {
  const { user } = useAuthStore();
  const handleSetPaymentMethods = useStore((state) => state.handleSetPaymentMethods);
  const handleResetPaymentMethods = useStore((state) => state.handleClear);

  const handleGettingPaymentMethods = useCallback(
    (paymentMethods: PaymentMethod[]) => {
      handleSetPaymentMethods(
        paymentMethods.filter(
          (method) => method?.paymentMethodOwner?.employeeId === toString(user?.employee?.id) && user?.employee?.id
        )
      );
    },
    [handleSetPaymentMethods, user]
  );

  const response = useGetPaymentMethods(handleGettingPaymentMethods, undefined, !!user?.employee?.id);

  useEffect(() => {
    return () => {
      handleResetPaymentMethods();
    };
  }, [handleResetPaymentMethods]);

  return response;
};

export default useSetPaymentMethodsInStore;
