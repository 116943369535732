import { useMemo } from 'react';

import { OrganizationTypes, Roles } from '@/common/constants';
import { useAuthStore } from '@/modules/user/stores';

export interface AccessRequirement {
  permission?: number;
  organizationType?: OrganizationTypes;
  isException?: boolean;
  role?: Roles;
}

const useHasAccess = (accessRequirements?: AccessRequirement[]): boolean => {
  const { user } = useAuthStore();

  return useMemo(() => {
    if (!accessRequirements?.length) {
      return true;
    }

    const hasExeption = accessRequirements
      .filter((accessRequirement) => accessRequirement.isException)
      .some((exeption) => {
        const hasExeptionByPermission = exeption?.permission
          && (user?.permissions || [])
            .some((permision) => permision === exeption?.permission);
        const hasExeptionByOrganizationType = exeption?.organizationType
          && user?.organization?.type
          && exeption?.organizationType === user?.organization?.type;
        const hasExeptionByRole = exeption?.role
          && user?.role
          && exeption?.role === user?.role;

        return hasExeptionByPermission || hasExeptionByOrganizationType || hasExeptionByRole;
      });
    if (hasExeption) {
      return false;
    }

    const accessRequirementsWithoutExceptions = accessRequirements
      .filter((accessRequirement) => !accessRequirement.isException);

    return accessRequirementsWithoutExceptions.length === 0
      || accessRequirementsWithoutExceptions
        .filter((accessRequirement) => !accessRequirement.isException)
        .some((accessRequirement) => {
          const isAccessibleByPermissions = !accessRequirement.permission
            || (user?.permissions || [])
              .some((permision) => permision === accessRequirement.permission);
          const isAccessibleByOrganizationType = !accessRequirement.organizationType
            || user?.organization?.type === accessRequirement.organizationType;
          const isAccessibleByRole = !accessRequirement.role
            || user?.role === accessRequirement.role;
          return isAccessibleByPermissions && isAccessibleByOrganizationType && isAccessibleByRole;
        });
  }, [accessRequirements, user]);
};

export default useHasAccess;
