import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Dependent } from '@/modules/user/components/ManageProfile/components/Dependents/dependents.types';
import { QUERY_KEY } from '@/modules/user/components/ManageProfile/components/Dependents/queries/useDependents.query';

const useUpdateDependentQuery = (dependentId?: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation((dependent: Dependent) => api.put(PATHS.UPDATE_DEPENDENT(dependentId), {
    first_name: dependent.firstName,
    last_name: dependent.lastName,
    local_date_birth_on: dependent.birthOn,
    relationship_type: dependent.relationshipType,
  }), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY);
    },
  });

  return {
    updateDependent: mutateAsync,
    isLoading,
  };
};

export default useUpdateDependentQuery;
