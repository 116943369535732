import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { GET_TRANSACTION_DETAILS_BY_CLAIM_ID } from '@/modules/transaction/hooks/useGetTransactionDetails.query';

const useUpdateClaimDocumentIdQuery = (
  claimId?: string,
) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync, isLoading, isError, isSuccess,
  } = useMutation(
    (documentId?: string) => api.put(PATHS.UPDATE_CLAIM_DOCUMENT_ID(claimId), {
      document_id: documentId,
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_TRANSACTION_DETAILS_BY_CLAIM_ID, claimId]);
      },
    },
  );

  const handleMutateAsync = useCallback(async (documentId?: string, onSuccess?: () => void) => {
    await mutateAsync(documentId);
    if (onSuccess) onSuccess();
  }, [mutateAsync]);

  return {
    updateClaimDocumentId: handleMutateAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useUpdateClaimDocumentIdQuery;
