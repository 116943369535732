import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { InvestmentPaths } from '../../investments.types';



interface Orders {
  instrumentId: number;
  percentage?: number;
}

interface SwitchModelPayload {
  newInvestmentType?: string;
  preOrders:Orders[]
}
const useSwitchInvestmentModelQuery = (
  id: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const {
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(
    (payload: SwitchModelPayload) => api.patch(
      PATHS.INVESTMENTS_CHANGE_MODEL,
      {
        new_investment_type: payload.newInvestmentType || InvestmentPaths.SelfDirected,
        pre_orders: payload.preOrders.map((item) => ({
          instrument_id: item.instrumentId,
          percentage: item.percentage,
        })),
      }
    ),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    },
  );



  return {
    handleSave: mutateAsync,
    isLoading,
    isSuccess,
    isError,
  };
};
export default useSwitchInvestmentModelQuery;