import React from 'react';
import {
  AppButton,
  Box,
  RoundDoneIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

interface Props {
  header: string;
  buttonText: string;
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  description?: string;
}

export const SuccessAutoInvestmentModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSubmit,
  header,
  buttonText,
  description,
}) => (
  <ModalWrapper testId="SuccessAutoInvestmentModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
    <Box align="center" pad={{ vertical: 'spacing24' }}>
      <RoundDoneIcon />
      <Text
        data-testid="SuccessAutoInvestmentModal_modal_header"
        margin={{ top: 'spacing12', bottom: description ? 'spacing12' : 'spacing24' }}
        textAlign="center"
        size="24px"
        weight={700}
      >
        {header}
      </Text>

      {description && (
        <Text
          data-testid="SuccessAutoInvestmentModal_modal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {description}
        </Text>
      )}

      <AppButton
        testId="SuccessAutoInvestmentModal_modal"
        onClick={onSubmit}
        width="200px"
      >
        {buttonText}
      </AppButton>
    </Box>
  </ModalWrapper>
);
