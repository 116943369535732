import { useEffect,useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter, onlyDateFromServer } from '@common-fe/common-fe';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import regexp from '@/common/regexp';
import {
  JsonDataItem,
  JsonDataItemPayload,
  NonClaimTransactionData,
  NonClaimTransactionResponse,
  Notification,
  NotificationResponse,
  Status,
  StatusResponse,
  TransactionData,
  TransactionResponse,
} from '@/modules/transaction/transaction.types';

const parseJsonDataReasons = (jsonData: string): JsonDataItem[] => {
  const parsedJsonData = JSON.parse(jsonData) || [];
  const data = Array.isArray(parsedJsonData) ? parsedJsonData : [parsedJsonData];

  return data?.map((item: JsonDataItemPayload) => ({
    ...item?.submit_claims_date_end ? { submitClaimsDateEnd: onlyDateFromServer(item.submit_claims_date_end) } : {},
    ...item.card_id ? { cardId: toString(item.card_id) } : {},
    ...item.account_id ? { accountId: toString(item.account_id) } : {},
    ...item.card_swipe_date ? { cardSwipeDate: item.card_swipe_date } : {},
    ...item.coverage_date_start ? { coverageDateStart: onlyDateFromServer(item.coverage_date_start) } : {},
    ...item.coverage_date_end ? { coverageDateEnd: onlyDateFromServer(item.coverage_date_end) } : {},
    ...item.plan_name ? { planName: item.plan_name } : {},
    ...item.amount ? { amount: item.amount } : {},
    ...item.provider ? { provider: item.provider } : {},
    ...typeof item?.spend_limit === 'number' ? { spendLimit: item.spend_limit } : {},
  }));
};

export const GET_TRANSACTION_DETAILS_BY_CLAIM_ID = 'getTransactionDetailsByClaimId';
export const GET_TRANSACTION_DETAILS_BY_POSTING_ID = 'getTransactionDetailsByPostingId';

const formatData = (transaction?: TransactionResponse) => ({
  documentId: toString(transaction?.document_id),
  amount: transaction?.amount,
  paidAmount: transaction?.paid_amount,
  approvedAmount: transaction?.approved_amount,
  category: transaction?.category_type,
  id: transaction?.claim_id,
  createdAt: transaction?.created_at,
  serviceOn: transaction?.local_date_service_on,
  invoiceId: transaction?.provider_invoice,
  taxYear: transaction?.tax_year,
  notifications: transaction?.notifications?.map((item: NotificationResponse) => ({
    approvalCode: item.approval_code,
    customDenialReason: item.custom_denial_reason,
    invoicePostingId: toString(item.invoice_posting_id),
    status: item.status,
    validFrom: item.valid_from,
    date: transaction?.statuses?.[0]?.valid_from || item.valid_from,
    validTo: item.valid_to,
  } as Notification)),
  claimNoteList: transaction?.claim_note_dto_list?.map(
    (item) => ({ ...item, date: item.createdAt }),
  ),
  ...transaction?.claim_offset_dtos?.length ? {
    claimOffsetDonors: transaction?.claim_offset_dtos?.map(
      (item) => ({
        amount: item.amount,
        donorClaimId: toString(item?.donor_claim_id),
        recipientClaimId: toString(item?.recipient_claim_id),
        date: item.created_at,
      }),
    ),
  } : {},
  paymentMethodId: toString(transaction?.payment_method_id),

  approvalStatus: transaction?.notifications?.[0]?.status,
  processingStatus: transaction?.processing_status,
  processingSubstatusType: transaction?.processing_substatus_type,
  memo: transaction?.memo,
  providerId: transaction?.provider_id,
  service: {
    id: toString(transaction?.service?.id),
    categoryType: transaction?.service?.category_type,
    displayed: transaction?.service?.displayed,
    name: transaction?.service?.name,
  },
  serviceFor: {
    name: transaction?.service_for.name,
    relationshipType: capitalizeFirstLetter(transaction?.service_for?.relationship_type || 'Me').replace(regexp.DASH_SYMBOL, ' '),
    isHidden: transaction?.service_for?.is_hidden,
    isViewNameAllowed: transaction?.service_for?.is_view_name_allowed,
  },
  postings: transaction?.postings.map((item) => ({
    accountId: toString(item?.account_id),
    planName: item.plan_name,
    amount: item.amount,
    transactionAt: item.transaction_at,
    date: item.transaction_at,
  })),
  ...transaction?.claim_transfers?.length ? {
    claimTransfers: transaction.claim_transfers.map((item) => ({
      id: toString(item?.id),
      claimId: toString(item?.claim_id),
      accountFromId: toString(item?.account_from_id),
      accountFromName: item?.account_from_name,
      accountToId: toString(item?.account_to_id),
      accountToName: item?.account_to_name,
      postingIdFrom: item?.posting_id_from,
      postingIdTo: toString(item?.posting_id_to),
      createdByContactId: toString(item?.created_by_contact_id),
      createdAt: item?.created_at,
      amount: item?.amount,
      date: item.created_at,
    })),
  } : {},
  paymentType: transaction?.payment_type,
  statuses: transaction?.statuses?.map((item: StatusResponse) => ({
    status: item?.status,
    validFrom: item?.valid_from,
    date: item?.valid_from,
    validTo: item?.valid_to,
  } as Status)),
  applyDeductibleAmount: transaction?.apply_deductible_amount,
  declineReasonTypes: transaction?.decline_reason_types?.map((item) => ({
    ...item?.json_data ? { jsonData: parseJsonDataReasons(item.json_data) } : {},
    ...item?.reason_type ? { type: item.reason_type } : {},
    date: transaction?.created_at,
  })),
} as TransactionData);

export const useGetTransactionDetailsByClaimId = (claimId: string) => {
  const {
    data, isLoading, isFetching, refetch, remove,
  } = useQuery(
    [GET_TRANSACTION_DETAILS_BY_CLAIM_ID, claimId],
    () => api.get<TransactionResponse>(PATHS.ACTIVITY_CLAIMS_BY_ID(claimId)),
    {
      enabled: !!claimId,
      cacheTime: 2000,
    },
  );

  useEffect(() => {
    if (claimId) refetch();
    else remove();
  }, [claimId, refetch, remove]);

  const formattedData = useMemo(() => formatData(data?.data), [data]);

  return {
    formattedData,
    isLoading,
    isFetching,
    refetch,
  };
};

export const useGetTransactionDetailsByPostingId = (postingId: string, profileId?: string) => {
  const {
    data, isLoading, isFetching, isSuccess, refetch, remove,
  } = useQuery(
    [GET_TRANSACTION_DETAILS_BY_POSTING_ID, postingId, profileId],
    () => api.get(PATHS.ACTIVITY_POSTINGS_BY_ID(postingId)),
    {
      enabled: !!postingId && !!profileId,
    },
  );

  useEffect(() => {
    if (postingId && profileId) refetch();
    else remove();
  }, [postingId, profileId, refetch, remove]);
  const formatData = (transaction: NonClaimTransactionResponse): NonClaimTransactionData => ({
    amount: transaction.amount,
    category: transaction.category,
    employeeId: transaction.employee_id,
    memo: transaction.memo,
    planName: transaction.plan_name,
    postingId: transaction.posting_id,
    taxYear: transaction?.tax_year,
    statuses: [
      {
        status: transaction?.status,
        validFrom: transaction?.status_date,
        validTo: transaction?.status_date,
      } as Status,
    ],
    statusDate: transaction.status_date,
    transactionType: transaction.transaction_type,
    paymentMethodId: toString(transaction?.payment_method_id),
  });
  const formattedData = useMemo(() => (data?.data ? formatData(data?.data) : null), [data]);

  return {
    formattedData,
    data: data?.data,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  };
};
