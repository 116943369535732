import React, {
  useCallback, useEffect,
  useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription } from '@common-fe/common-fe';

import { FileImage } from '@/common/constants';
import { ModalWrapper } from '@/components';
import DropScan from '@/components/controls/DropScan';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';

import useSetFileFromDocumentStream from '../../ReimburseMe/hooks/useSetFileFromDocumentStream';
import useFilesStore from '../../ReimburseMe/store/useFiles.store';
import { UploadFileQRCodeSection } from '../../ReimburseMe/UploadFileQRCode';

// import useQRCodeStore from '../store/useQRCode.store';
// import { UploadFileQRCodeSection } from '../UploadFileQRCode';
import { useQrTransactionFileUploadAdditionalActionsStore, useTransactionFileUploadPopupStore } from './store';
import { TransactionUploadFileScan } from './TransactionUploadFileScan';

enum Steps {
  FILE_UPLOAD = 'FILE_UPLOAD',
  FILE_SCAN = 'FILE_SCAN',
}

interface Props {
  visible: boolean;
  handleChangeVisibility: (visible: boolean) => void;
}

export const TransactionFileUploadPopup: React.FC<Props> = ({
  visible,
  handleChangeVisibility,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(Steps.FILE_UPLOAD);
  const setFiles = useFilesStore((state) => state.setFiles);
  const setAiApproved = useTransactionFileUploadPopupStore((store) => store.handleSetAiApprovedOcrFile);
  useSetFileFromDocumentStream();
  const { handleReset } = useFilesInDocumentStore();

  const setAdditionalAction = useQrTransactionFileUploadAdditionalActionsStore((state) => state.handleSetAdditionalAction);
  const onCancelScanning = useQrTransactionFileUploadAdditionalActionsStore((state) => state.onCancelScanning);
  const setResetFileStore = useQrTransactionFileUploadAdditionalActionsStore((state) => state.handleSetResetFileStore);
  const resetQrFileUploadStore = useQrTransactionFileUploadAdditionalActionsStore((state) => state.handleReset);
  const handleSetIsCancelScanning = useTransactionFileUploadPopupStore((store) => store.handleSetIsCancelScanning);

  const handleSetFiles = useCallback((fileObjects: FileImage[]) => {
    setFiles(fileObjects);
    setStep(Steps.FILE_SCAN);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setResetFileStore(true);
    setAdditionalAction(() => {
      handleChangeVisibility(true);
      setStep(Steps.FILE_SCAN);
    });
  }, [setAdditionalAction, setFiles, setResetFileStore, handleChangeVisibility]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => resetQrFileUploadStore(), []);

  const handleClosePopup = useCallback((value: boolean) => {
    if (step === Steps.FILE_SCAN && onCancelScanning) {
      onCancelScanning();
      handleSetIsCancelScanning(true);
    }
    handleChangeVisibility(value);
  }, [step, handleChangeVisibility, onCancelScanning, handleSetIsCancelScanning]);

  return (
    <ModalWrapper
      testId="FileUploadPopup_modal_wrapper"
      visible={visible}
      onSetVisible={handleClosePopup}
      notHideClickOutside={step === Steps.FILE_SCAN}
    >
      <Box align="center">
        {step === Steps.FILE_UPLOAD
        && (
          <>
            <Inscription weight={700} size="24px" lineHeight="32px">
              {(t('Upload Document'))}
            </Inscription>
            <Box
              pad="spacing24"
              margin={{ top: 'spacing24' }}
              background={{ color: 'module' }}
              round="moduleRound"
              direction="row"
              gap="spacing24"
            >
              <Box basis="1/2">
                <DropScan
                  onFilesAdded={handleSetFiles}
                  isCenterTitle
                  wrapperStyle={{ padding: 0 }}
                  additionalAction={() => handleReset()}
                />
              </Box>
              <Box basis="1/2">
                <UploadFileQRCodeSection />
              </Box>
            </Box>
          </>
        )}
        {step === Steps.FILE_SCAN
          && (
            <TransactionUploadFileScan
              onNext={() => {
                setStep(Steps.FILE_UPLOAD);
                handleChangeVisibility(false);
              }}
              onGetScannedDocument={setAiApproved}
              // onCancel={handleResetOcrWarnings}
            />
          )}
      </Box>
    </ModalWrapper>
  );
};
