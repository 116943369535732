import React from 'react';

import { useSnackbar } from '@/modules/core/hooks';

import SnackbarBlock from './SnackbarBlock';
import SnackbarItem from './SnackbarItem';

const Snackbar = () => {
  const { snackbars, handleRemoveSnackbarById } = useSnackbar();
  const rootNode = document.getElementById('root');
  if (rootNode) {
    return (
      <>
        {snackbars.map((item) => (
          <SnackbarBlock queueNumber={item.queueNumber} key={item.id} notification={item.isLeft || item.closeIcon}>
            <SnackbarItem
              options={item}
              onClose={handleRemoveSnackbarById}
            />
          </SnackbarBlock>
        ))}
      </>
    );
  }
  return null;
};
export default Snackbar;
