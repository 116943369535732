import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { CustomFieldError } from '@/common/types';

import { PersonalInfoPayload } from './stores/usePersonalInfo.store';
import { useCitizenshipFields, useEmployeeData, usePersonalInfoFields } from './hooks';
import { usePersonalInfoStore } from './stores';

interface Props {
  isErrorShowed?: boolean;
  customFieldErorrs?: CustomFieldError;
}
const PersonalInfoForm: React.FC<Props> = ({ isErrorShowed, customFieldErorrs }) => {
  const { params } = useEmployeeData();

  const personalInfoFields = usePersonalInfoFields({
    customFieldErorrs,
    hideField: {
      email: params.email,
      phone: params.phone,
    },
  });
  const citizenshipFields = useCitizenshipFields();
  const handleSetState = usePersonalInfoStore((state) => state.setState);
  const { setValues, values } = useDebounceState<Partial<PersonalInfoPayload>>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  useEffect(() => () => {
    handleSetState({
      citizenship: '',
    });
  }, [handleSetState]);
  return (
    <Box data-testid="personal-info-form">
      {(!params.email || !params.phone) && (
        <Box
          data-testid="personal-info-form_main"
          round="container1Round"
          elevation="default"
          background="canvas"
          margin={{ bottom: 'm' }}
        >

          <FlexControlledForm
            fields={personalInfoFields}
            editMode
            showError={isErrorShowed}
            isModalType
            onChangeValues={setValues}
          />

        </Box>
      )}

      {!params.citizenship && (
        <Box
          data-testid="personal-info-form_citizenship"
          round="container1Round"
          elevation="default"
          background="canvas"
        >

          <FlexControlledForm
            fields={citizenshipFields}
            editMode
            showError={isErrorShowed}
            isModalType
            onChangeValues={handleSetState}
          />

        </Box>

      )}
    </Box>

  );
};
export default PersonalInfoForm;
