import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box,costFormater, Text } from '@common-fe/common-fe';
import { toNumber } from 'lodash';

import {
  ClaimTransferData,
} from '@/modules/transaction/transaction.types';

import { Wrapper } from './TransactionNotification.styles';

interface Props {
  claimTransfer?: ClaimTransferData;
}

const ClaimTransferMessage: React.FC<Props> = ({ claimTransfer }) => {
  const { t } = useTranslation();
  const amount = useMemo(() => Math.abs(toNumber(claimTransfer?.amount)), [claimTransfer]);

  return (
    <Wrapper
      data-testid={`claim_transfer-${claimTransfer?.id}`}
      flex-direction="column"
      background="canvas"
      round="regular"
      pad="l"
    >
      <Box direction="row" align="center" justify="between">
        <Text size="large" color="textTitle">
          <Text weight={700}>{costFormater(amount, false, true)}</Text>
          &nbsp;{t('claim transferred from')}&nbsp;
          <Text weight={700}>{claimTransfer?.accountFromName}</Text>
          &nbsp;{t('to')}&nbsp;
          <Text weight={700}>{claimTransfer?.accountToName}</Text>
        </Text>
      </Box>
    </Wrapper>
  );
};

export default ClaimTransferMessage;
