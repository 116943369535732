import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription, UploadIcon } from '@common-fe/common-fe';

import useProgress from '@/components/controls/DropScan/hooks/useProgress';
import { InnerProgressBar, ProgressBar } from '@/components/controls/DropScan/ProcessScan.styles';
import UploadFileByLinkContentWrapper from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkContentWrapper';

const LoadingScreen = () => {
  const { t } = useTranslation();
  const { progress } = useProgress();

  return (
    <UploadFileByLinkContentWrapper>
      <UploadIcon size="80px" color="button" />
      <Box
        margin={{ top: 'spacing12' }}
        pad="spacing12"
        width={{ width: '100%' }}
        align="center"
      >
        <Inscription weight={700}>
          {t('Hang tight while we upload your file…')}
        </Inscription>
        <ProgressBar width="100%">
          <InnerProgressBar progress={progress} />
        </ProgressBar>
        <Inscription>{`${Math.floor(progress * 100)}% Uploading`}</Inscription>
      </Box>
    </UploadFileByLinkContentWrapper>
  );
};

export default LoadingScreen;
