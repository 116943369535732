import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Inscription, LinkButton, Toggle } from '@common-fe/common-fe';

import { CommunicationOption } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';

import ProfileSettingOption from './ProfileSettingOption';

interface Props {
  testId?: string;
  icon: React.ReactNode;
  title: string;
  description: string;
  options?: CommunicationOption[];
  isActive?: boolean;
  defaultValue?: string;
  isActivationDisabled?: boolean;
  onActivate: (value: boolean) => void;
  onActivateOption?: () => void;
}

const ProfileSettingCard: React.FC<Props> = ({
  testId,
  icon,
  title,
  description,
  options,
  isActive,
  defaultValue,
  isActivationDisabled,
  onActivate,
  onActivateOption,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      round="container1Round"
      pad="spacing24"
      background="canvas"
      border={{ color: 'border2', size: '1px' }}
      elevation="default"
    >
      <Box direction="row" align="center">
        <Box>{icon}</Box>
        <Box margin={{ left: 'spacing16' }} direction="column" flex>
          <Inscription color="text" size="large" weight={700}>
            {title}
          </Inscription>
          <Inscription color="textSecondary" size="small" weight={500}>
            {description}
          </Inscription>
        </Box>
        <Box margin={{ left: 'spacing16' }}>
          <Toggle
            testId={testId}
            value={!!isActive}
            disabled={isActivationDisabled}
            onChange={onActivate}
          />
        </Box>
      </Box>

      <Grid margin={{ top: 'spacing24' }} columns={['1fr', '1fr']} gap="spacing16">
        {options?.map((option) => (
          <ProfileSettingOption
            key={option.key}
            title={option.title}
            value={option.value}
            isSelected={option?.isSelected && !!option?.value}
            isActive={option?.isActive && !!option?.value}
            onClick={option.onClick}
          />
        ))}
      </Grid>
      <Box margin={{ top: 'spacing8' }} width="fit-content">
        <LinkButton
          onClick={onActivateOption}
          linkInlineMode
        >
          {t(defaultValue ? 'Edit' : 'Add')}
        </LinkButton>
      </Box>
    </Box>
  );
};

export default ProfileSettingCard;
