import React, {
  RefObject, useCallback, useEffect,
  useRef, } from 'react';
import { Box, FlatTextInput,Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

import regexp from '@/common/regexp';

const CardNumberInput = styled(FlatTextInput)`
  width: ${({ theme }) => theme.spacings.spacing54};
  height: ${({ theme }) => theme.spacings.spacing54}}; 
  min-height: ${({ theme }) => theme.spacings.spacing54}}; 
  margin-left: ${({ theme }) => theme.spacings.spacing12};
  font-size: ${({ theme }) => theme.spacings.spacing20};
  font-weight: 700;
  text-align: center;
`;

interface Props {
  title: string;
  length: number;
  onChangeValue?: (value: string) => void;
}

const CardDigitsField: React.FC<Props> = ({
  title,
  length,
  onChangeValue,
}) => {
  const inputRefs = useRef<Array<RefObject<HTMLInputElement>>>([]);

  const createInputRef = (index: number) => (el: HTMLInputElement | null) => {
    if (el) {
      inputRefs.current[index] = { current: el };
    }
  };

  const handleChange = useCallback((inputValue: string, index: number) => {
    const isNumber = inputValue.replace(regexp.NOT_NUMBERS_REGEXP, '');
    const currentInput = inputRefs.current?.[index];
    const nextInput = inputRefs.current?.[index + 1];
    const prevInput = inputRefs.current?.[index - 1];

    if (!isNumber && currentInput && currentInput?.current) {
      currentInput.current.value = '';
    }

    if (inputValue && nextInput && isNumber) {
      nextInput.current?.focus();
    }

    if (!inputValue && prevInput) {
      prevInput.current?.focus();
    }

    if (onChangeValue) {
      const fieldValue = inputRefs.current.map(({ current }) => current?.value).join('');
      onChangeValue(fieldValue);
    }
  }, [onChangeValue]);

  const handleDelete = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      const currentInput = inputRefs.current?.[index];
      const prevInput = inputRefs.current?.[index - 1];
      const isBackspaceKey = event.key === 'Backspace';

      if (isBackspaceKey && prevInput && !currentInput.current?.value) {
        const timeoutId = setTimeout(() => prevInput.current?.focus(), 0);
        return () => clearTimeout(timeoutId);
      }

      return () => { };
    }, [],
  );

  useEffect(() => {
    const firstInput = inputRefs?.current[0];
    if (firstInput) {
      firstInput.current?.focus();
    }
  }, []);

  const inputs = Array.from({ length }, (_, inputIndex) => (
    <CardNumberInput
      key={inputIndex}
      ref={createInputRef(inputIndex)}
      onChange={(value: string) => handleChange(value, inputIndex)}
      onKeyDown={(event) => handleDelete(event, inputIndex)}
      inputMaxLength={1}
      testId={`input_${inputIndex}`}
    />
  ));

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      background="canvas"
      round="container1Round"
      pad="spacing24"
      border={{ size: 'small', color: 'border2' }}
      data-testId="card-digits-field"
    >
      <Box>
        <Inscription weight={700}>{title}</Inscription>
      </Box>

      <Box direction="row">
        {inputs}
      </Box>
    </Box>
  );
};

export default CardDigitsField;
