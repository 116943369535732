import { storeCreator } from '@/utils/store/store.utils';

import { ReimbursementFormPayload } from '../ReimburseMe.types';

export interface StateValues extends ReimbursementFormPayload {
  isReceiptRequired: boolean;
}

interface State {
  values: StateValues;
  handleSetValues: (value: Partial<StateValues>) => void;
  handleSetProviderId: (value?: string) => void;
  handleSetService: (value?: string) => void;
  handleReset: () => void;
}

const DEFAULT_VALUES: StateValues = {
  account: '',
  amount: '',
  invoice: '',
  patient: '',
  provider: '',
  service: '',
  serviceDate: '',
  isReceiptRequired: false,
};

export const useClaimInfoStore = storeCreator<State>((set) => ({
  values: DEFAULT_VALUES,
  handleSetValues: (value: Partial<StateValues>) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      ...value,
    },
  })),
  handleSetProviderId: (value?: string) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      provider: value || '',
    },
  })),
  handleSetService: (value?: string) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      service: value || '',
    },
  })),
  handleReset: () => set((state) => ({
    ...state,
    values: DEFAULT_VALUES,
  })),
}));
