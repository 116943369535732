export const DEV_ENV_KEY = 'dev';
export const UAT_ENV_KEY = 'uat';
export const PROD_ENV_KEY = 'prod';
export const DEMO_ENV_KEY = 'demo';
interface Params {
  uat?: boolean;
  prod?: boolean;
  dev?: boolean;
  demo?: boolean;
}

const YES_KEY = 'yes';


export const useReversedFeatureFlag = (name: string, params: Params) => {
  const formattedKey = `FEATURE_FLAG: ${name}`;

  const currentEnv = import.meta.env.REACT_APP_ENV;
  const currentStorage = localStorage.getItem(formattedKey);
  const setLocalStorage = () => {
    localStorage.setItem(formattedKey, YES_KEY);
    return true;
  };

  if (currentStorage && currentStorage !== YES_KEY) {
    return false;
  }

  if (currentStorage && currentStorage === YES_KEY) {
    return true;
  }

  if (currentEnv === DEV_ENV_KEY && params?.dev === true) {
    return setLocalStorage();
  } if (currentEnv === UAT_ENV_KEY && params?.uat === true) {
    return setLocalStorage();
  } if (currentEnv === PROD_ENV_KEY && params?.prod === true) {
    return setLocalStorage();
  } if (currentEnv === DEMO_ENV_KEY && params?.demo === true) {
    return setLocalStorage();
  }
  return false;
};

export default useReversedFeatureFlag;