import { useMemo } from 'react';
import _ from 'lodash';

import { ValuesMap } from '@/common/types';
import { useInvestorById } from '@/modules/investments/hooks';


const useDefaultAllocationFunds = () => {
  const investor  = useInvestorById();
  

  const defaultFundsIds = useMemo(() => {
    if(investor.data.amountsGroupedByInstrumentIds) {
      return Object.keys(investor.data.amountsGroupedByInstrumentIds);
    }
    return null;
  }, [investor.data.amountsGroupedByInstrumentIds]);
  const defaultFundsValues = useMemo(() => {
    const initialInvestmentAmount = investor.data.initialInvestmentAmount;
    const values = investor.data.amountsGroupedByInstrumentIds;
    if(values && initialInvestmentAmount) {
      const result: ValuesMap<number> = {};
      Object.keys(values).forEach(key => {
        const currentValue = values[key];

        result[key] = _.round(currentValue / initialInvestmentAmount  * 100);
      });
      return result;
    }
    return null;
  }, [investor.data.amountsGroupedByInstrumentIds, investor.data.initialInvestmentAmount]);
  return {
    defaultFundsValues,
    defaultFundsIds
  };

};


export default useDefaultAllocationFunds;