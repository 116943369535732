 
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  Box, Inscription,
  SiblingBoxesWithSpacing, } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import { ContentWrapper } from '@/modules/transaction/components/ReimburseMe/ReimburseMe.styles';
import theme from '@/styles/theme';

import { ReimburseFooterBackground } from '../ReimburseFooterBackground';
import useFilesStore from '../store/useFiles.store';

import { Title } from './FileUpload.styles';
import { FileUploadContent } from './FileUploadContent';

const TextProps = {
  size: 'large',
  color: 'black',
  margin: { bottom: '24px' },
};

interface Props {
  handleDeleteFile?: (id: string) => void;
  onBack?: () => void;
  onNext?: () => void;
  isSmallViewVersion?: boolean;
  addFileButtonRef?: React.RefObject<HTMLDivElement>;
  defaultValues?: object;
  noReceiptsTitle?: string;
}
const FileUpload: React.FC<Props> = ({
  handleDeleteFile,
  onBack,
  onNext,
  isSmallViewVersion,
  addFileButtonRef,
  noReceiptsTitle,
}) => {
  const { files, setFiles } = useFilesStore((state) => state);
  const [currentFiles, setCurrentFiles] = useState(files || []);
  const { t } = useTranslation();
  const handleNext = useCallback(() => {
    setFiles(currentFiles);
    if (files.length !== 0 && onNext) {
      onNext();
    }
  }, [files, onNext, setFiles, currentFiles]);

  useEffect(() => {
    setCurrentFiles(files);
  }, [files]);

  return (
    <>
      <Box
        direction="column"
        flex={{ grow: 1 }}
        justify="between"
        {...(isSmallViewVersion ? { pad: { right: 'small' } } : {})}
      >
        <Prompt
          when={!!currentFiles.length}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Box
          direction="row"
          justify="center"
          style={{
            zIndex: 1,
            position: 'relative',
          }}
        >
          <ContentWrapper
            flex="grow"
          >
            <Box
              style={{ flex: 1 }}
              {...(isSmallViewVersion ? {} : { margin: { bottom: 'spacing24' } })}
            >
              {!isSmallViewVersion && (
                <Box>
                  <Title style={{ marginBottom: '12px' }}>
                    {t('Please Make Sure We Can See Everything')}
                  </Title>
                  <Inscription {...TextProps}>
                    {t('Claim details will be scanned automatically from the first file uploaded. ')}
                    <br />
                    {t('Any additional files will be used as supporting documentation to approve your claim, if needed.')}
                  </Inscription>
                </Box>
              )}
              <FileUploadContent
                handleDeleteFile={handleDeleteFile}
                onBack={onBack}
                onNext={onNext}
                outputCurrentFiles={setCurrentFiles}
                isSmallViewVersion={isSmallViewVersion}
                addFileButtonRef={addFileButtonRef}
                noReceiptsTitle={noReceiptsTitle}
              />
            </Box>
          </ContentWrapper>

        </Box>

        {!isSmallViewVersion && (
          <>
            {(!files || !files.length) && (
              <Box margin={{ top: 'auto', bottom: 'spacing16' }} align="center">
                <ContentWrapper align="end">
                  <Inscription color="danger" size="12px">
                    {t('Please upload a picture of your receipt.')}
                  </Inscription>
                </ContentWrapper>
              </Box>
            )}
            <Box
              direction="row"
              justify="center"
              style={{
                zIndex: 1,
                position: 'relative',
              }}
            >
              <Box
                flex="grow"
                pad={{ horizontal: '40px' }}
                width={{ max: theme.defaultContentWidth }}
              >
                <Box
                  direction="row"
                  align="center"
                  justify="end"
                  background="module"
                  height="88px"
                  style={{
                    zIndex: 1,
                  }}
                >
                  <SiblingBoxesWithSpacing>
                    <AppButton
                      onClick={onBack}
                      width="control"
                      buttonType="secondary"
                    >
                      {t('Back')}
                    </AppButton>
                  </SiblingBoxesWithSpacing>
                  <AppButton onClick={handleNext} width="control">
                    {t('Save')}
                  </AppButton>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <ReimburseFooterBackground />
    </>
  );
};

export default FileUpload;
