import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RiskControlOptions } from '@/common/constants';

import { InvestmentPaths, InvestorStatus } from '../investments.types';

export const QUERY_KEY = 'useInvestorInfo';

interface InvestmentDTO {
  id: number;
  external_id: string;
  account_id: number;
  employee_id: number;
  investor_id?: number;
  age: number;
  status: InvestorStatus;
  type: string;
  pending_sell_trades_amount?: number;
  is_investment_model_switching?: boolean;
  investor_account?: {
    id?: number;
    balance?: number ;
    investor_id?: number;
    account_id?: number;
    status?: string;

    cash_balance?: number;
    equity_value?: number;
  }
  investor_profile?: {
    networth_total: number;
    tolerance_model: RiskControlOptions;
    risk_tolerance: RiskControlOptions;
    proposed_tolerance_model: RiskControlOptions;
  }
}
export default () => {
  const { data, isSuccess, isError, isLoading } = useQuery(
    [QUERY_KEY],
    () => api.get<InvestmentDTO>(PATHS.INVESTORS), {
      retry: false,
    },
  );

  const formatedData = useMemo(() => {
    const payload = data?.data;
    
    return ({
      investorId: `${payload?.investor_account?.investor_id || payload?.investor_id || payload?.id || ''}`,
      status: payload?.status,
      investorBalance: payload?.investor_account?.balance,
      toleranceModel: payload?.investor_profile?.tolerance_model
      || payload?.investor_profile?.proposed_tolerance_model,
      investorAccountId: `${payload?.investor_account?.id || ''}`,
      investorEnrollmentId: `${payload?.investor_account?.account_id || ''}`
       || `${payload?.account_id || ''}`,
      currentToleranceModel: payload?.type === InvestmentPaths.Managed ? payload?.investor_profile?.tolerance_model: '',
      type: payload?.type as InvestmentPaths,
      pendingTradesAmount: payload?.pending_sell_trades_amount || 0,
      investorAccountStatus: payload?.investor_account?.status,
      isInvestmentModelSwitching: payload?.is_investment_model_switching || false,
      cashBalance: payload?.investor_account?.cash_balance || 0,
      equityValue: payload?.investor_account?.equity_value || 0,
    });
  }, [data]);
  return {
    data: formatedData,
    isSuccess,
    isError,
    isLoading
  };
};
