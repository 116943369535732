import React from 'react';

import manageProfileBannerBackground from '@/assets/placeholders/manage-profile-banner.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import EmployeeInformation from './components/EmployeeInformation/EmployeeInformation';
import EmployeePaymentMethods from './components/EmployeePaymentMethods/EmployeePaymentMethods';
import { ManageProfileDashboardContent, ManageProfileDashboardWrapper } from './ManageProfileDashboard.styles';

interface Props {
  refetchPaymentMethods: () => void;
}

const ManageProfileDashboard: React.FC<Props> = ({ refetchPaymentMethods }) => {
  return (
    <ManageProfileDashboardWrapper
      imageSrc={whiteLabelingConfig?.images?.manageProfileBannerBackground || manageProfileBannerBackground}
    >
      <ManageProfileDashboardContent>
        <EmployeeInformation />
        <EmployeePaymentMethods refetchPaymentMethods={refetchPaymentMethods} />
      </ManageProfileDashboardContent>
    </ManageProfileDashboardWrapper>
  );
};

export default ManageProfileDashboard;
