import React, {
  useCallback, useEffect, useMemo,
  useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  getDuration,
  Notification,
  NotificationBellIcon,
  NotificationCard,
  NotificationPlaceholder,
  Preloader,
  Text,
  useForceIntervalUpdate,
} from '@common-fe/common-fe';

import routes from '@/common/routes';
import AppButton from '@/components/controls/AppButton';
import { useHistory } from '@/modules/core/hooks';
import NotificationModal from '@/modules/notification/NotificationDetailsModal/NotificationModal';
import themeStyles from '@/styles/theme';

import { useNotifications } from '../hooks';
import { useNotificationCenterStore } from '../store';

import {
  CoveredBlock,
  DisabledNotificationButton,
  GradientBox,
  IconWrapper,
  NotificationCardWrapper,
  NotificationsListActions,
  TitleWrapper,
  Wrapper, } from './NotificationsList.styles';

const NotificationsList: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();

  const { t } = useTranslation();

  const [activeNotification, setActiveNotification] = useState<Notification | undefined>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const {
    data: notifications, isLoading, handleDismissById, isSuccess,
  } = useNotifications({
    isQached: true,
  });

  const dismissedNotificationsTotal = useNotificationCenterStore(
    (state) => state.dismissedNotificationsTotal,
  );
  const [
    notificationsData,
    setFiltredNotifications,
  ] = useState<Notification[]>([]);
  useEffect(() => {
    if (isSuccess) {
      setFiltredNotifications(notifications);
    }
  }, [isSuccess, notifications]);

  const handleDismissNotification = useCallback((id?: string) => {
    handleDismissById(id);
    if (currentIndex === notificationsData.length - 1) {
      setCurrentIndex(currentIndex - 1);
    }
  }, [handleDismissById, notificationsData, currentIndex]);
  const renderKey = useForceIntervalUpdate(getDuration({ minutes: 1 }));
  const handleOpenNotifications = useCallback(() => {
    history.push(routes.NOTIFICATIONS);
  }, [history]);

  const total = useMemo(
    () => (dismissedNotificationsTotal || 0) + (notificationsData.length || 0),
    [dismissedNotificationsTotal, notificationsData.length],
  );

  const handleGoToPage = useCallback((path?: string) => {
    const link = path || activeNotification?.page;

    if (link) {
      history.push(link);

      setOpenModal(false);
    }
  }, [activeNotification, history]);
  if (isLoading) {
    return (
      <Box width="100%">
        <Preloader />
      </Box>
    );
  }
  if (!notificationsData || !notificationsData.length) {
    return (
      <Wrapper
        direction="column"
        pad={{ horizontal: 'l', top: 'm' }}
        data-testid="Notification-list-wrapper"
      >
        <Box direction="row" margin={{ left: 'auto' }}>
          <Box direction="column">
            <Box direction="row" justify="between" pad={{ bottom: 's' }}>
              <TitleWrapper direction="row" align="center">
                <NotificationBellIcon size="20px" color={themeStyles.colors.iconAccent} />
                <Text margin={{ left: 'spacing8' }} size="large" weight="bold" color="textTitle">{t('Recent notifications')}</Text>
              </TitleWrapper>
              <Box>
                {/* {(!dismissedNotifications.length && !notificationsData.length) */}
                {(!total)
                  ? (
                    <DisabledNotificationButton>
                      {t('All notifications (0)')}
                    </DisabledNotificationButton>
                  ) : (
                    <AppButton
                      testId="all-notifications"
                      buttonType="quaternary"
                      onClick={handleOpenNotifications}
                    >
                      {t(`All notifications (${total})`)}
                    </AppButton>
                  )}

              </Box>
            </Box>
            <Box fill direction="row" justify="end">
              <Box
                fill
                data-testid="list-notification"
                style={{ width: '470px' }}
              >
                <NotificationPlaceholder isWhite />
              </Box>

            </Box>
          </Box>
          <NotificationsListActions
            direction="column"
            justify="center"
            width={{ min: 'xxxsmall' }}
            margin={{ left: 'l' }}
          >
            <IconWrapper
              disabled
            >
              <ChevronUpIcon size="36px" color="iconPrimary" />
              
            </IconWrapper>
            <IconWrapper
              disabled
            >
              <ChevronDownIcon size="36px" color="iconPrimary" />

            </IconWrapper>
          </NotificationsListActions>
        </Box>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      direction="column"
      pad={{ right: 'l' }}
      data-testid="Notification-list-wrapper"
    >
      {
        currentIndex !== notificationsData.length - 1
        && (
          <CoveredBlock />
        )
      }
      <Box direction="row">
        <Box direction="column" margin={{ left: 'auto' }}>
          <GradientBox pad={{ top: 'm' }}>
            <Box
              direction="row"
              justify="between"
              align="center"
              pad={{ bottom: 'm' }}
              style={{
                zIndex: 1000,
              }}
            >
              <TitleWrapper direction="row" align="center">
                <NotificationBellIcon size="18px" color={themeStyles.colors.iconAccent} />
                <Text margin={{ left: 'spacing8' }} size="large" weight="bold" color="textTitle">{t('Recent notifications')}</Text>
              </TitleWrapper>
              <AppButton
                testId="all-notifications"
                buttonType="quaternary"
                onClick={handleOpenNotifications}
              >
                {t(`All notifications (${total})`)}
              </AppButton>
            </Box>
          </GradientBox>
          <Box fill direction="row" style={{ overflow: 'hidden' }}>
            <NotificationCardWrapper
              key={renderKey}
              align="end"
              data-testid="list-notification"
              fill
            >
              {notificationsData.map((item, index) => (
                <NotificationCard
                  key={item.id}
                  notification={item}
                  index={index}
                  currentIndex={currentIndex}
                  bannerStyle
                  dismissNotification={handleDismissNotification}
                  setActiveNotification={setActiveNotification}
                  setOpenModal={setOpenModal}
                  isPageLinkHidden={!item.page}
                  style={{ width: currentIndex !== index ? 'calc(100% - 20px)' : '100%' }}
                  onGoToPage={() => handleGoToPage(item.page)}

                />
              ))}
            </NotificationCardWrapper>
          </Box>
        </Box>

        {notificationsData && (
          <NotificationsListActions
            direction="column"
            justify="center"
            width={{ min: 'xxxsmall' }}
            margin={{ left: 'l' }}
          >
            <IconWrapper
              disabled={!currentIndex}
              onClick={() => setCurrentIndex(currentIndex - 1)}
            >
              <ChevronUpIcon size="36px" color="iconPrimary" /> 
            </IconWrapper>
            <IconWrapper
              disabled={currentIndex === notificationsData.length - 1}
              onClick={() => setCurrentIndex(currentIndex + 1)}
            >
              <ChevronDownIcon size="36px" color="iconPrimary" />
            </IconWrapper>
          </NotificationsListActions>
        )}

        <NotificationModal
          notification={activeNotification}
          setOpenModal={setOpenModal}
          openModal={openModal}
          isPageLinkHidden={!activeNotification?.page}
          onGoToPage={handleGoToPage}
          dismissNotification={handleDismissNotification}
        />
      </Box>
    </Wrapper>
  );
};

export default NotificationsList;
