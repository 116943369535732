import React, {
  lazy,
  Suspense, useMemo, useRef, useState,
} from 'react';
import { Box, Preloader } from '@common-fe/common-fe';
import lodash from 'lodash';
import styled from 'styled-components';

import { FileImage,FileType } from '@/common/constants';
import { PinchZoomPan } from '@/components/elements/vendor/PinchZoomPan';
import { WrapperWithVerticalShadows } from '@/components/wrappers/WrapperWithVerticalShadows';
import { useDetectVerticalScrollShadow } from '@/utils/hooks/useDetectVerticalScrollShadow';

import DropWrapper from './DropWrapper';
import Embed from './Embed';
import ImageZoomer, { ZoomerProps } from './ImageZoomer';
import prepareFile from './prepareFile';
import { PreviewTooltip, ScanPreview } from './ScanPreview';

const ScanUpload = lazy(() => import('./ScanUpload'));

const Thumbs = styled.div<{ isSmallViewVersion?: boolean }>`
  ${({ theme }) => theme.mixins.customLightScroll};
  overflow-y: ${({ isSmallViewVersion }) => (isSmallViewVersion ? 'unset' : 'auto')};
  &::-webkit-scrollbar {
    width: 8px;
  }
`;

const RoundedImg = styled.img`
  max-height: 100%;
  max-width: 100%;
  ${({ theme }) => theme.shadows.active};
  border-radius: ${({ theme }) => theme.rounds.container2Round} ;
`;

interface Props {
  isPreviewHidden?: boolean;
  uploadButton?: React.ReactNode;
  qrCodeButton?: React.ReactNode;
  hasMainLabel?: boolean;
  isActiveFileShown?: boolean;
  isShowTooltip?: boolean;
  noAddedFiles?: React.ReactNode;
  isSmallViewVersion?: boolean;
  isFlex?: boolean;
  isAddDocFirst?: boolean;
  disableUpload?: boolean;
  isRemovingDisabled?: boolean;
  activeFile?: number;
  defaultFiles?: FileImage[];
  onSetFiles?: (values: FileImage[]) => void;
  onSetActivePage?: (index: number) => void;
  onSetPreviewMode?: () => void;
  addFileButtonRef?: React.RefObject<HTMLDivElement>;
  handleDeleteFile?: (id: string) => void;
  zoomerProps?: ZoomerProps;
}

const ScanPreviewContainer: React.FC<Props> = ({
  isPreviewHidden,
  uploadButton,
  hasMainLabel,
  isActiveFileShown,
  noAddedFiles,
  isSmallViewVersion,
  isAddDocFirst,
  defaultFiles,
  onSetFiles,
  onSetActivePage,
  onSetPreviewMode,
  addFileButtonRef,
  isShowTooltip = true,
  activeFile = 0,
  disableUpload,
  isRemovingDisabled,
  handleDeleteFile,
  isFlex,
  zoomerProps,
  qrCodeButton,
}) => {
  const [showTooltip, setShowTooltip] = useState(isShowTooltip);
  const [files, setFiles] = React.useState<FileImage[]>(defaultFiles || []);
  const ref = useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (!lodash.isEqual(files, defaultFiles)) {
      setFiles(defaultFiles || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFiles]);
  React.useEffect(() => {
    if (!lodash.isEqual(files, defaultFiles) && onSetFiles) {
      onSetFiles(files || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);
  const [activePage, setActivePage] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (activeFile !== undefined) {
      setActivePage(activeFile);
    }
  }, [activeFile]);
  // React.useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     files.forEach((file) => URL.revokeObjectURL(file.preview));
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [],
  // );
  const isPDF = React.useMemo(
    () => activePage !== null && files[activePage]?.type === FileType.PDF,
    [files, activePage],
  );
  const handleSetFiles = React.useCallback((acceptedFiles: FileImage[]) => {
    setFiles((state) => state.concat(acceptedFiles.map(prepareFile)));
  }, []);
  const handleDelete = React.useCallback(
    (index: number) => {
      if (files[index]) {
        URL.revokeObjectURL(files[index].preview);
        if (handleDeleteFile) handleDeleteFile(files[index].id);
      }
      const newlist = files.filter((_, i) => i !== index);
      setFiles(newlist);
      setActivePage(0);

      if (onSetActivePage) {
        onSetActivePage(0);
      }
    },
    [files, onSetActivePage, handleDeleteFile],
  );

  const {
    onScrollHandler,
    scrolledToTheEnd,
    startedScrolling,
  } = useDetectVerticalScrollShadow(ref.current);

  const isShadowAvailable = useMemo(
    () => !isSmallViewVersion && files.length > 4,
    [files, isSmallViewVersion],
  );
  return (
    <DropWrapper isSmallViewVersion={isSmallViewVersion} isFlex={isFlex}>
      <Box style={{ position: 'relative', width: 'fit-content' }}>
        {
          !isSmallViewVersion
          && showTooltip
          && <PreviewTooltip setShowTooltip={setShowTooltip} />
        }
        <WrapperWithVerticalShadows
          notAllowed={!isShadowAvailable}
          startedScrolling={startedScrolling}
          scrolledToTheEnd={isShadowAvailable ? scrolledToTheEnd : true}
        >
          <Thumbs
            ref={ref}
            isSmallViewVersion={isSmallViewVersion}
            onScroll={onScrollHandler}
          >
            <ScanPreview
              isPreviewHidden={isPreviewHidden}
              hasMainLabel={hasMainLabel}
              isSmallViewVersion={isSmallViewVersion}
              isFlex={isFlex}
              onSelect={(index: number) => {
                setActivePage(index);
                if (onSetPreviewMode) onSetPreviewMode();

                if (onSetActivePage) {
                  onSetActivePage(index);
                }
              }}
              {...isRemovingDisabled ? {} : {
                onDelete: handleDelete,
              }}
              selectedIndex={activePage}
              isAddDocFirst={isAddDocFirst}
            >
              {
                !disableUpload && (
                  <Suspense fallback={<Preloader />}>
                    <ScanUpload
                      onFilesAdded={handleSetFiles}
                      addFileButtonRef={addFileButtonRef}
                      uploadButton={uploadButton}
                    />
                  </Suspense>
                )
              }
              {
                !disableUpload && (
                  qrCodeButton
                )
              }
            </ScanPreview>
          </Thumbs>
        </WrapperWithVerticalShadows>
      </Box>
      {activePage !== null && files[activePage] && isActiveFileShown && (
        <>
          {isSmallViewVersion && files.map((file, index) => (
            <Box
              key={file.preview}
              {...index !== activePage ? { style: { display: 'none' } } : {}}
            >
              <ImageZoomer key={file.id} file={file} zoomerProps={zoomerProps} />
            </Box>
          ))}

          {!isSmallViewVersion && (
            <Box width="100%" margin={{ left: 'spacing24' }} pad={{ right: 'spacing24' }}>
              {isPDF
                ? (
                  <Embed
                    key={activePage}
                    src={files[activePage].preview}
                    url={files[activePage].url}
                  />
                )
                : (
                  <PinchZoomPan
                    doubleTapBehavior="zoom"
                    position="center"
                    initialScale={1}
                    minScale={0.1}
                    maxScale={4}
                    zoomButtons={!isPDF}
                  >
                    <RoundedImg
                      src={files[activePage].preview || files[activePage].url}
                      alt={files[activePage].name}
                    />
                  </PinchZoomPan>
                )}
            </Box>
          )}
        </>
      )}

      {(files.length === 0 && noAddedFiles) && noAddedFiles}
    </DropWrapper>
  );
};

export default ScanPreviewContainer;
