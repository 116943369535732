import React, { useCallback,useState } from 'react';

import { ChangeEmailModal } from '@/modules/transaction/components/Statements/components/ChangeEmailModal';
import { GoPaperlessModal } from '@/modules/transaction/components/Statements/components/GoPaperless/components/GoPaperlessModal';
import { GoPaperlessSuccessModal } from '@/modules/transaction/components/Statements/components/GoPaperless/components/GoPaperlessSuccessModal';
import { Steps } from '@/modules/transaction/components/Statements/components/GoPaperless/GoPaperless.constants';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const GoPaperless: React.FC<Props> = ({ onSetVisible }) => {
  const [step, setStep] = useState(Steps.GoPaperless);

  const handleCloseChangeEmailModal = useCallback(() => {
    setStep(Steps.GoPaperless);
  }, []);

  return (
    <>
      {
        step === Steps.GoPaperless && (
          <GoPaperlessModal
            onSetVisible={onSetVisible}
            setStep={setStep}
          />
        )
      }
      {
        step === Steps.ChangeEmail && (
          <ChangeEmailModal
            onClose={handleCloseChangeEmailModal}
          />
        )
      }
      {
        step === Steps.Success && (
          <GoPaperlessSuccessModal
            onSetVisible={onSetVisible}
          />
        )
      }
    </>
  );
};

export default GoPaperless;
