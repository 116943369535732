import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@common-fe/common-fe';

import { FileImage } from '@/common/constants';
import { ModalWrapper } from '@/components';
import DropScan from '@/components/controls/DropScan';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import { TransactionFileUploadSteps } from '@/modules/transaction/components/TransactionDetails/TransactionFileOnlyUploadPopup/transactionFileOnlyUploadPopup.types';
import useUpdateClaimDocumentIdQuery from '@/modules/transaction/components/TransactionDetails/useUpdateClaimDocumentId.query';

interface Props {
  claimId?: string;
  onSetVisible: (value: boolean) => void;
  setStep: (step: TransactionFileUploadSteps) => void;
  setSelectedFile: (file: FileImage) => void;
}

const TransactionUploadDocumentPopup: React.FC<Props> = ({ claimId, onSetVisible, setStep, setSelectedFile }) => {
  const { t } = useTranslation();
  const { handleReset } = useFilesInDocumentStore();
  const { updateClaimDocumentId } = useUpdateClaimDocumentIdQuery(claimId);

  const handleFileSelect = useCallback(() => {
    setStep(TransactionFileUploadSteps.UPLOADING);
  }, [setStep]);

  const handleDocumentUploaded = useCallback(async (files: FileImage[], currentDocumentId?: string) => {
    const addedFile = files?.[0] || {};
    try {
      if (!addedFile?.id) {
        throw new Error();
      }
      await updateClaimDocumentId(currentDocumentId);
      setStep(TransactionFileUploadSteps.SUCCESSFULLY_UPLOADED);
    } catch (e) {
      setSelectedFile(addedFile);
      setStep(TransactionFileUploadSteps.ERROR);
    }
  }, [updateClaimDocumentId, setStep, setSelectedFile]);

  return (
    <ModalWrapper
      visible
      onSetVisible={onSetVisible}
      title={t('Upload Document')}
    >
      <Box
        pad="spacing24"
        background={{ color: 'module' }}
        round="moduleRound"
        direction="row"
        gap="spacing24"
      >
        <DropScan
          onFilesAdded={handleDocumentUploaded}
          onFilesSelect={handleFileSelect}
          isCenterTitle
          wrapperStyle={{ padding: 0 }}
          additionalAction={() => handleReset()}
        />
      </Box>
    </ModalWrapper>
  );
};

export default TransactionUploadDocumentPopup;
