// export interface SnackbarNotification {
//   id: string;
//   text: string;
//   permanent?: boolean;
//   routeToPlans?: boolean;
//   closeIcon?: boolean;
// }

export enum SnackbarState {
  positive = 'Positive',
  negative = 'Negative',
  neutral = 'Neutral',
}

interface DefaultSnackbarOptions {
  id: string;
  text: string;
  state?: SnackbarState,
  permanent?: boolean;
  isLeft?: boolean;
  queueNumber?: number;
  minWidth?: string;
  autoRemove?: boolean;
}

interface SnackbarWithRedirect {
  closeIcon: true;
  redirect?: {
    path: string;
    label: string;
  }
}

interface SnackbarWithoutRedirect {
  closeIcon?: false;
}

export type SnackbarNotification =
  DefaultSnackbarOptions & (SnackbarWithoutRedirect | SnackbarWithRedirect);
