import React from 'react';
import { useParams } from 'react-router-dom';

import Statements from '@/modules/transaction/components/Statements';

const StatementsPage = () => {
  const { accountId = '' } = useParams<{ accountId: string }>();

  return (
    <Statements accountId={accountId} />
  );
};

export default StatementsPage;
