import React, { useCallback } from 'react';
import { Box } from '@common-fe/common-fe';

import { ValuesMap } from '@/common/types';

import { QuestionnaireField } from '../../cip.constants';

import QuestionListItem from './QuestionListItem';

interface Props {
  values: ValuesMap<string>;
  fields: QuestionnaireField[];
  onChangeValues: (values: ValuesMap<string>) => void;
}
const QuestionsList: React.FC<Props> = ({ values, onChangeValues, fields }) => {
  const handleChange = useCallback((key: string) => (value: string) => {
    onChangeValues({
      [key]: value,
    });
  }, [onChangeValues]);
  return (
    <Box round="moduleRound" data-testid="questions-list" background="module" pad={{ top: 'l', left: 'l', right: 'l' }} margin={{ bottom: 'l' }}>
      {fields.map((field) => (
        <QuestionListItem
          key={field.key}
          value={values[field.key]}
          label={field.label}
          onChangeValue={handleChange(field.key)}
          options={field.options}
        />

      ))}

    </Box>
  );
};

export default React.memo(QuestionsList);
