import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { ManagedSwitchQuestionnaire } from '@/modules/investments/Managed';
import { useMyEnrollments } from '@/modules/transaction';

// import {SwitchToSelfDirectedSelect} from '@/modules/investments/SelfDirected/SwitchToSelfDirected';

const ManagedSwitchQuestionnairePage = () => {
  const { t } = useTranslation();
  const { accountId = '' } = useParams<{ accountId: string }>();
  const { currentMyAccount } = useMyEnrollments(accountId);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: '...',
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: currentMyAccount?.name,
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Investments Model Switch'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }], true); 
  if (!accountId) {
    return null;
  }
  return (
    <ManagedSwitchQuestionnaire accountId={accountId} />
  );
};
export default ManagedSwitchQuestionnairePage;
