import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, NotificationBellIcon, Stack,
} from '@common-fe/common-fe';

import { useNotificationCenterStore } from '@/modules/notification/store';
import spacings from '@/styles/spacings';

import { useDismissedNotifications, useNotifications } from '../hooks';

import {
  NOTIFY_CLASS_NAME,
  StyledButton,
  StyledIconWrap,
  StyledShowCount,
} from './NotificationButton.styles';

const NotificationButton = () => {
  const {
    isRead,
  } = useNotifications({
    isQached: true,
  });
  const [newIndicator, setNewIndicator] = useState(false);
  useDismissedNotifications();
  const setNotificationCenterState = useNotificationCenterStore(
    (state) => state.setNotificationCenterState,
  );
  const totalNotifications = useNotificationCenterStore(
    (state) => state.notificationsTotal,
  );
  const isOpenNotificationCenter = useNotificationCenterStore(
    (state) => state.isOpenNotificationCenter,
  );

  const [notifyClass, setNotifyClass] = useState('');

  const ring = useCallback(async () => {
    setNotifyClass('');
    await new Promise((resolve) => {
      setTimeout(() => {
        setNotifyClass(NOTIFY_CLASS_NAME);
        resolve(true);
      }, 1);
    });
  }, []);
  useEffect(() => {
    if (!isRead) {
      ring();
      setNewIndicator(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [totalNotifications, isRead]);
  useEffect(() => {
    setNewIndicator(false);
  }, [isOpenNotificationCenter]);
  const handleOpenNotificationCenter = useCallback(() => {
    setNotificationCenterState(true);
    setNewIndicator(false);
  }, [setNotificationCenterState]);
  return (
    <Box margin={{ right: spacings.spacing20 }}>
      <StyledButton onClick={handleOpenNotificationCenter}>
        <Box width="40px" height="40px">
          <Stack anchor="top-right">
            <StyledIconWrap className={notifyClass}>
              <Box
                width="40px"
                height="40px"
                align="center"
                justify="center"
              >
                <NotificationBellIcon size="20px" color="iconPrimary" />
              </Box>
            </StyledIconWrap>
            {newIndicator && (
              <StyledShowCount
                data-testid="show-count-id"
                show={!!totalNotifications}
                width="10px"
                height="10px"
                round="full"
                background="danger"
                margin={
                  {
                    top: 'spacing8',
                    right: 'spacing8',
                  }
                }
              />
            )}

          </Stack>
        </Box>
      </StyledButton>
    </Box>
  );
};
export default NotificationButton;
