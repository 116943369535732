import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AchievementTrackerItem, costFormater } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { IrsMaximumsCoverageTypes, IrsMaximumsFrequencyTypes } from '@/modules/transaction/transaction.types';

export type AchievementTrackerInfoItem = AchievementTrackerItem & {
  maxInitialValue: number;
};

interface ContributionHistoryCategoryDto {
  id: number;
  tax_year: number;
  type: string;
  tax_year_end: string;
  amount: number;
  frequency_type: IrsMaximumsFrequencyTypes,
  irs_coverage_level_type: IrsMaximumsCoverageTypes,
}

interface ContributionHistoryTotalDto {
  id: number;
  employee_id: number;
  total_contribution_amount: number;
  tax_year: number;
}
interface ContributionHistoryResponse {
  is_with_catch_up_age?: boolean;
  total_employee_contribution: ContributionHistoryTotalDto;
  irs_limits: ContributionHistoryCategoryDto[];
}

export enum ContributionAchievementsName {
  INDIVIDUAL = 'Individual Max',
  FAMILY = 'Family Max',
  CATCH_UP = 'Catch Up',
}

const contributionAchievementsNameFormatter = (name: IrsMaximumsCoverageTypes) => {
  switch (name) {
  case IrsMaximumsCoverageTypes.INDIVIDUAL:
    return ContributionAchievementsName.INDIVIDUAL;
  case IrsMaximumsCoverageTypes.FAMILY:
    return ContributionAchievementsName.FAMILY;
  default:
    return '';
  }
};

const HSA_KEY = 'HSA_MAX';
const ROLLOVER_KEY = 'ROLLOVER';

const QUERY_KEY = 'getContributionsHistoryQueryKey';

export const useGetContributionsHistoryQuery = (
  personId: string,
  taxYear?: string,
  accountId?: string,
) => {
  const params = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set('person_id', personId);

    if (taxYear) queryGenerator.set('tax_year', taxYear);
    if (accountId) queryGenerator.set('account_id', `${accountId}`);

    return queryGenerator;
  }, [accountId, personId, taxYear]);

  const {
    isLoading, isSuccess, isError, data, refetch,
  } = useQuery(
    [QUERY_KEY, accountId, taxYear],
    accountId && taxYear
      ? () => api.get<ContributionHistoryResponse>(PATHS.CONTRIBUTION_HISTORY,
        { params })
      : () => null,
  );

  const contributionAchievements = useMemo(
    () => {
      const filteredItems = data?.data?.irs_limits
        ?.filter((item) => item.type === HSA_KEY)
        ?.filter((item) => item.irs_coverage_level_type !== ROLLOVER_KEY);

      const individualAmount = filteredItems?.find(
        (item) => item.irs_coverage_level_type === IrsMaximumsCoverageTypes.INDIVIDUAL,
      )?.amount || 0;

      return filteredItems?.map((item) => ({
        id: `${item.id}`,
        name: contributionAchievementsNameFormatter(item.irs_coverage_level_type),
        maxValue: item.irs_coverage_level_type === IrsMaximumsCoverageTypes.FAMILY
          ? item.amount - individualAmount
          : item.amount,
        maxInitialValue: item.amount,
        maxValueDescription: costFormater(item.amount, true),
      })).sort((a) => (a.name === ContributionAchievementsName.INDIVIDUAL ? -1 : 1));
    },
    [data],
  );

  const formattedData = useMemo(() => ({
    items: data?.data?.irs_limits,
    currentValue: data?.data?.total_employee_contribution?.total_contribution_amount || 0,
    isWithCatchUp: data?.data?.is_with_catch_up_age,
  }), [data]);

  const lastDayForContribution = useMemo(() => {
    if (!data?.data) return undefined;

    return data.data.irs_limits
      .find((item) => item?.tax_year === dayjs().year() - 1)?.tax_year_end;
  }, [data]);

  return {
    data: data?.data,
    refetch,
    formattedData,
    contributionAchievements: contributionAchievements as AchievementTrackerInfoItem[] || [],
    lastDayForContribution,
    isError,
    isLoading,
    isSuccess,
  };
};
