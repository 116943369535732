import React, { useEffect } from 'react';
import { Box } from '@common-fe/common-fe';

import backgroundImage from '@/assets/backgrounds/uploadFileByQrBackground.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

interface Props {
  children?: React.ReactNode;
}

const UploadFilePageWrapper: React.FC<Props> = ({ children }) => {
  const checkOrientation = async () => {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;
    const metaViewport = document.querySelector('meta[name=\'viewport\']');
    if (isLandscape) {
      if (metaViewport) {
        metaViewport.setAttribute('content', 'width=device-width, initial-scale=0.5');
      }
    } else if (metaViewport) {
      metaViewport.setAttribute('content', 'width=device-width, initial-scale=1');
    }
  };

  useEffect(() => {
    let metaViewport: HTMLMetaElement | null = document.querySelector('meta[name=\'viewport\']');
    if (!metaViewport) {
      metaViewport = document.createElement('meta');
      metaViewport.name = 'viewport';
      metaViewport.content = 'width=device-width, initial-scale=0.1';
  
      document.head.appendChild(metaViewport);
    }

    if (metaViewport) {
      metaViewport.setAttribute('content', 'width=device-width, initial-scale=1');
    }

    return () => {
      if (metaViewport) {
        metaViewport.setAttribute('content', 'width=device-width, initial-scale=0.1');
      }
    };
  }, []);

  useEffect(() => {
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  return (
    <Box
      data-testid="UploadFilePageWrapper_wrapper"
      background={{ color: 'canvas' }}
      width={{ min: '100svw' }}
      // height="100svh"
      overflow="hidden"
    >
      <Box
        width="100svw"
        height="24vh"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
        {
          ...whiteLabelingConfig?.title ? {} : {
            background: {
              image: `url(${backgroundImage})`,
              size: 'cover',
            },
          }
        }
      />
      {children}
    </Box>
  );
};

export default UploadFilePageWrapper;
