import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Enrollment,
  Field,
  Inscription,
  Preloader,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DEBIT_EXP_DATE_FORMAT, DEFAULT_DATE_FORMAT } from '@/common/constants';
import regexp from '@/common/regexp';
import AppButton from '@/components/controls/AppButton';
import { useSnackbar } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user';

import {
  ContributionPaymentMethod,
  OneTimeContributionFormValues,
  RecurringContribution,
  RecurringContributionFormValues,
  RecurringContributionFrequency,
  RecurringContributionStatus,
  ToggleOptions,
} from '../contribution.types';
import { ContributionsLimitInfoBanner } from '../ContributionsLimitInfoBanner';
import { useContributionAchievementsTracker } from '../hooks/useContributionAchievementsTracker';
import { useContributionPaymentMethod } from '../hooks/useContributionPaymentMethod';
import { useOneTimeContribution } from '../hooks/useOneTimeContribution';
import { useRecurringContribution } from '../hooks/useRecurringContribution';
import { useAddContributionQuery } from '../queries/useAddContribution.query';
import { useAddRecurringContributionQuery } from '../queries/useAddRecurringContribution.query';
import { useUpdateRecurringContributionQuery } from '../queries/useUpdateRecurringContribution.query';
import { useStore as useErrorStore } from '../store/useErrorContributionModal.store';
import { useStore as useSuccessStore } from '../store/useSuccessContributionModal.store';
import { useStore as useRecurringSuccessStore } from '../store/useSuccessRecurringContributionModal.store';

import { MakeContributionModalToggler } from './MakeContributionModalToggler';
import { MakeContributionPaymentMethodForm } from './MakeContributionPaymentMethodForm';
import { MakeOneTimeContributionForm } from './MakeOneTimeContributionForm';
import { MakeRecurringContributionForm } from './MakeRecurringContributionForm';
import { WarningOneTimeContributionModal } from './WarningOneTimeContributionModal';

const TOGGLE_OPTIONS = [
  ToggleOptions.ONE_TIME,
  ToggleOptions.RECURRING,
];

const CURRENT_YEAR = dayjs().year().toString();
const tomorrow = dayjs().add(1, 'day').format(DEFAULT_DATE_FORMAT);

const SNACKBAR_WIDTH = '454px';
const CONTRIBUTION_DESCRIPTION = `Add money to your HSA in addition to any contributions coming through payroll.
 Contributions made here will not be pulled from your paycheck, but directly from your bank account.`;

const dateFormatter = (value?: string) => {
  if (!value) return '';
  return dayjs(value).format(DEBIT_EXP_DATE_FORMAT);
};

interface Props {
  onCancel?: (value: boolean) => void;
  account?: Enrollment;
  currentRecurringContribution?: RecurringContribution;
  editMode?: boolean;
}

const MakeContributionModalForm: React.FC<Props> = ({
  onCancel,
  account,
  currentRecurringContribution,
  editMode,
}) => {
  const { t } = useTranslation();
  const { personId } = useAuthStore();
  const { handleAddPermanentSnackbar, handleAddMultipleSnackbar } = useSnackbar();
  const [selectedOption, setSelectedOption] = useState(
    currentRecurringContribution
      ? ToggleOptions.RECURRING
      : ToggleOptions.ONE_TIME,
  );
  const [isOneTimeWarningVisible, setIsOneTimeWarningVisible] = useState(false);
  const accountId = useMemo(() => account?.id, [account]);
  const accountType = useMemo(() => account?.accountType, [account]);
  const catchUpElection = useMemo(() => account?.catchUpElection, [account]);
  const minimumContributionAmount = useMemo(() => account?.minimumContributionAmount, [account]);
  const planCode = useMemo(() => account?.planCode, [account]);
  const submitButtonLabel = useMemo(() => currentRecurringContribution ? 'Save' : 'Add', [currentRecurringContribution]);
  const isOneTimeDueToRecurring = useMemo(() => currentRecurringContribution && selectedOption === ToggleOptions.ONE_TIME, [currentRecurringContribution, selectedOption]);

  const {
    formValues,
    setFormValues,
    infoSetup,
    setInfoSetup,
    rollover,
    setRollover,
    currentTaxYear,
  } = useOneTimeContribution();

  const {
    preparedContributionAchievements,
    formattedData,
    maxContributionValue,
    contributionHistoryLoading,
    lastDayForContribution,
    currentContributionValue,
    isWithCatchUp,
  } = useContributionAchievementsTracker({
    personId,
    taxYear: currentTaxYear || CURRENT_YEAR,
    accountId,
    catchUpElection,
    accountType,
  });

  const {
    paymentMethod,
    setPaymentMethod,
    paymentMethodSetup,
    setPaymentMethodSetup,
  } = useContributionPaymentMethod();

  const {
    recurringInfoSetup,
    setRecurringInfoSetup,
    recurringFromValues,
    setRecurringFormValues,
  } = useRecurringContribution(
    currentRecurringContribution ? {
      contributionAmount: `${currentRecurringContribution.amount}`,
      monthlyDay: currentRecurringContribution?.monthlyDay,
      weeklyDay: currentRecurringContribution?.weeklyDay,
      frequency: currentRecurringContribution.frequency as RecurringContributionFrequency,
      startDate: tomorrow,
      endDate: currentRecurringContribution.endDate,
    } : undefined,
  );

  const onSetVisible = useSuccessStore((store) => store.handleChangeVisibility);
  const handleSetContributionAmount = useSuccessStore(
    (store) => store.handleChangeContributionAmount,
  );
  const handleSetHoldPeriod = useSuccessStore((store) => store.handleChangeHoldPeriod);
  const handleSetGotItButton = useSuccessStore((store) => store.handleSetOnSubmit);
  const handleRefetch = useSuccessStore((store) => store.additionalAction);
  const handleRefetchTracker = useSuccessStore((store) => store.updateTracker);
  const resetSuccessContributionModal = useSuccessStore((store) => store.handleReset);
  const handleRefetchRecurringContribution = useSuccessStore((store) => store.updateRecurringContributionView);
  const handleSetVisibleErrorModal = useErrorStore((store) => store.handleChangeVisibility);
  const handleSetRecurringGotItButton = useRecurringSuccessStore(
    (store) => store.handleSetOnSubmit,
  );
  const handleSetRecurringVisible = useRecurringSuccessStore((store) => store.handleChangeVisibility);
  const resetSuccessRecurringContributionModal = useRecurringSuccessStore(
    (store) => store.handleReset,
  );

  const maximumContributionAmount = useMemo(() => (isWithCatchUp
    ? maxContributionValue + (catchUpElection || 0) - currentContributionValue
    : maxContributionValue - currentContributionValue),
  [catchUpElection, currentContributionValue, isWithCatchUp, maxContributionValue]);

  const { save, isLoading } = useAddContributionQuery();
  const {
    save: recurringSave,
    isLoading: isRecurringLoading,
  } = useAddRecurringContributionQuery({
    person_id: personId,
    account_id: accountId,
  });
  const {
    handleCancel: handleCancelRecurringContribution,
  } = useUpdateRecurringContributionQuery(currentRecurringContribution?.id);

  const [isSubmit, setSubmit] = useState(false);

  const currentInfoSetup = useMemo(
    () => (selectedOption === ToggleOptions.ONE_TIME
      ? infoSetup
      : recurringInfoSetup),
    [infoSetup, recurringInfoSetup, selectedOption],
  );

  const currentSetFormValues = useMemo(
    () => (selectedOption === ToggleOptions.ONE_TIME
      ? setFormValues
      : setRecurringFormValues),
    [setFormValues, setRecurringFormValues, selectedOption],
  );

  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,
    isPayment?: boolean,
  ) => {
    if (isPayment) {
      setPaymentMethod((prev: ContributionPaymentMethod) => ({ ...prev, ...values }));
    } else {
      currentSetFormValues((
        prev: RecurringContributionFormValues
        | OneTimeContributionFormValues,
      ) => ({ ...prev, ...values }));
    }
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setFieldsAreFilled(true);
    } catch {
      setFieldsAreFilled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSetFormValues]);

  const isAllFormsValid = useMemo(() => currentInfoSetup && paymentMethodSetup,
    [currentInfoSetup, paymentMethodSetup]);

  const handleSubmit = useCallback(async () => {
    setSubmit(true);
    try {
      if (isAllFormsValid && selectedOption === ToggleOptions.ONE_TIME) {
        if (currentRecurringContribution) {
          await handleCancelRecurringContribution();
        }
        const data = await save({
          payment_method_id: paymentMethod?.contributionPaymentMethod,
          tax_year: formValues?.taxYear,
          employee_contribution_amount: formValues?.contributionAmount,
          rollover,
          plan_code: planCode,
        });
        if (onCancel) {
          onCancel(true);
        }
        handleSetGotItButton(() => {
          if (handleRefetch) handleRefetch();
          if (handleRefetchTracker) handleRefetchTracker();
          onSetVisible(false);
          resetSuccessContributionModal();
        });
        handleSetHoldPeriod(`${data?.hold_days || 0}`);
        handleSetContributionAmount(data?.employee_contribution_amount || parseFloat(formValues?.contributionAmount || '0'));
        onSetVisible(true);
        if (currentRecurringContribution) {
          if (handleRefetchRecurringContribution) handleRefetchRecurringContribution();
          setIsOneTimeWarningVisible(false);
          handleAddMultipleSnackbar([
            {
              text: 'The recurring contribution was canceled!',
              closeIcon: true,
              queueNumber: 1,
              minWidth: SNACKBAR_WIDTH,
            },
            {
              text: 'The one-time contribution was successfully created!',
              closeIcon: true,
              queueNumber: 0,
              minWidth: SNACKBAR_WIDTH,
            }
          ]);
        } else
          handleAddPermanentSnackbar({
            text: 'The one-time contribution was successfully created!',
            closeIcon: true,
          });
      
      }
      if (isAllFormsValid && selectedOption === ToggleOptions.RECURRING) {
        if (onCancel) {
          onCancel(true);
        }
        if (currentRecurringContribution) {
          await recurringSave({
            amount: parseFloat(recurringFromValues?.contributionAmount as string),
            frequency_type: recurringFromValues?.frequency?.toLocaleUpperCase().replace(regexp.MINUS_SYMBOL, '_'),
            recurring_day_number: recurringFromValues.frequency === RecurringContributionFrequency.MONTHLY
              ? parseInt(recurringFromValues?.monthlyDay || '0', 10)
              : parseInt(recurringFromValues?.weeklyDay || '0', 10),
            start_date: dateFormatter(recurringFromValues?.startDate),
            ...recurringFromValues?.endDate ? { end_date: dateFormatter(recurringFromValues?.endDate) } : {},
            payment_method_id: parseInt(paymentMethod?.contributionPaymentMethod || '0', 10),
            status: RecurringContributionStatus.Active.toLocaleUpperCase(),
          });
        } else {
          await recurringSave({
            amount: parseFloat(recurringFromValues?.contributionAmount as string),
            frequency_type: recurringFromValues?.frequency?.toLocaleUpperCase().replace(regexp.MINUS_SYMBOL, '_'),
            recurring_day_number: recurringFromValues.frequency === RecurringContributionFrequency.MONTHLY
              ? parseInt(recurringFromValues?.monthlyDay || '0', 10)
              : parseInt(recurringFromValues?.weeklyDay || '0', 10),
            start_date: dateFormatter(recurringFromValues?.startDate),
            ...recurringFromValues?.endDate ? { end_date: dateFormatter(recurringFromValues?.endDate) } : {},
            payment_method_id: parseInt(paymentMethod?.contributionPaymentMethod || '0', 10),
            status: RecurringContributionStatus.Active.toLocaleUpperCase(),
          });
        }
        handleSetRecurringGotItButton(() => {
          handleSetRecurringVisible(false);
          if (handleRefetchRecurringContribution) handleRefetchRecurringContribution();
          resetSuccessRecurringContributionModal();
          if (currentRecurringContribution) {
            handleAddPermanentSnackbar({
              text: 'Recurring Contribution was updated',
              closeIcon: true,
            });
          } else {
            handleAddPermanentSnackbar({
              text: 'The recurring contribution was successfully set up!',
              closeIcon: true,
            });
          }
        });
        handleSetRecurringVisible(true);
      }
    } catch (e) {
      handleSetVisibleErrorModal(true);
    }
  }, [
    formValues,
    handleSetContributionAmount,
    onCancel,
    onSetVisible,
    handleSetVisibleErrorModal,
    handleSetHoldPeriod,
    handleSetGotItButton,
    resetSuccessContributionModal,
    isAllFormsValid,
    save,
    handleRefetch,
    rollover,
    paymentMethod,
    selectedOption,
    handleSetRecurringGotItButton,
    handleSetRecurringVisible,
    resetSuccessRecurringContributionModal,
    handleAddPermanentSnackbar,
    planCode,
    currentRecurringContribution,
    recurringFromValues,
    handleRefetchTracker,
    recurringSave,
    handleAddMultipleSnackbar,
    handleCancelRecurringContribution,
    handleRefetchRecurringContribution,
  ]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
    }
  }, [onCancel]);

  if (isOneTimeWarningVisible) {
    return (
      <WarningOneTimeContributionModal
        visible={isOneTimeWarningVisible}
        onSetVisible={setIsOneTimeWarningVisible}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    );
  }

  return (
    <>
      <Box margin={{ bottom: 'spacing16' }}>
        <Inscription textAlign="center" size="14px">
          {t(CONTRIBUTION_DESCRIPTION)}
        </Inscription>
      </Box>
      {!editMode && (
        <Box align="center" margin={{ bottom: 'spacing16' }}>
          <MakeContributionModalToggler
            toggleOptions={TOGGLE_OPTIONS}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Box>
      )}
      <Box data-testid="Make-contribution-modal-form">
        <Box direction="column">
          <Box round="container1Round" pad="spacing24" background="module">

            {selectedOption === ToggleOptions.ONE_TIME
              ? (
                <MakeOneTimeContributionForm
                  handleChangeValues={changeValues}
                  isSubmit={isSubmit}
                  lastDayForContribution={lastDayForContribution}
                  rollover={rollover}
                  setRollover={setRollover}
                  setInfoSetup={setInfoSetup}
                  formValues={formValues}
                  minimumContributionAmount={minimumContributionAmount}
                  maximumContributionAmount={maximumContributionAmount}
                />
              )
              : (
                <MakeRecurringContributionForm
                  handleChangeValues={changeValues}
                  isSubmit={isSubmit}
                  setInfoSetup={setRecurringInfoSetup}
                  formValues={recurringFromValues}
                  minimumContributionAmount={minimumContributionAmount}
                  maximumContributionAmount={maximumContributionAmount}
                />
              )}

            <ContributionsLimitInfoBanner
              limits={preparedContributionAchievements}
              currentContributionValue={formattedData.currentValue}
              maxContributionValue={maxContributionValue}
              isLoading={contributionHistoryLoading}
            />

            <MakeContributionPaymentMethodForm
              isSubmit={isSubmit}
              currentPaymentMethodId={currentRecurringContribution?.paymentMethodId}
              handleChangeValues={changeValues}
              setPaymentMethodSetup={setPaymentMethodSetup}
            />

          </Box>
          <Box
            direction="row"
            fill="horizontal"
            margin={{ top: 'spacing24' }}
            align="end"
            justify="end"
            width="medium"
            gap="xs"
          >
            <AppButton
              testId="make-contribution-cancel"
              buttonType="secondary"
              width="control"
              type="button"
              onClick={handleCancel}
            >
              {t('Cancel')}
            </AppButton>
            <AppButton
              testId="make-contribution-add"
              type="button"
              disabled={isLoading || isRecurringLoading}
              onClick={isOneTimeDueToRecurring
                ? () => {
                  setSubmit(true);
                  if (isAllFormsValid) {
                    setIsOneTimeWarningVisible(true);
                  }
                }
                : handleSubmit}
              width="control"
            >
              {isLoading || isRecurringLoading
                ? (<Preloader />)
                : t(submitButtonLabel)}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MakeContributionModalForm;
