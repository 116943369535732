import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';

import { useStore as usePersonalInformationStore } from '../../stores/usePersonalInformation.store';

export default () => {
  const { t } = useTranslation();
  const defaultFields = usePersonalInformationStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        testId: 'Broker-field',
        name: 'broker',
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: t('Are you affiliated with or employed by a stock exchange, member firm of an exchange or FINRA, or a municipal securities broker-dealer?'),
        placeholder: '',
        isWrappedMode: true,
        options: [
          {
            label: t('Yes, and by selecting this I confirm that I will notify my employer in writing of my intention to open an account.'),
            value: 'true',
          },
          {
            label: t('No'),
            value: 'false',
          },
        ],
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]
  ), [t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
