import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription, Preloader } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';

import { Patient, useCreatePatientQuery } from '../usePatients.query';

import PatientForm from './PatientForm';
import usePatientStore from './usePatient.store';

interface Props {
  patientName?: string,
  submitButtonText?: string;
  onCancel: () => void,
  onSuccess?: () => void,
}

const AddPatientForm: React.FC<Props> = ({ patientName, submitButtonText, onCancel, onSuccess }) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const { save, isLoading } = useCreatePatientQuery(onSuccess);
  const { handleReset } = usePatientStore();

  const handleSubmit = useCallback(async (value: Patient) => {
    try {
      setIsError(false);
      await save(value);
    } catch (e) {
      setIsError(true);
    }
  }, [save]);

  useEffect(() => {
    handleReset();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <PatientForm
        patientName={patientName}
        onSubmit={handleSubmit}
        footer={(
          <Box pad={{ top: 'spacing24' }}>
            {isError && (
              <Inscription alignSelf="end" color="danger" margin={{ bottom: 'spacing24' }}>
                {t('Something goes wrong!')}
              </Inscription>
            )}
            <Box direction="row" justify="end" align="center" gap="spacing12">
              <AppButton
                type="button"
                onClick={onCancel}
                buttonType="secondary"
                width="control"
                testId='cancel'
              >
                {t('Cancel')}
              </AppButton>
              <AppButton
                type="submit"
                width="control"
                testId="submit"
              >
                {isLoading ? <Preloader color="white" /> : t(submitButtonText || 'Save Dependent')}
              </AppButton>
            </Box>
          </Box>
        )}
      />
    </>
  );
};

export default AddPatientForm;
