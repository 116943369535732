import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, OptionKey, SelectDropdown } from '@common-fe/common-fe';

import useInstrumentCategoriesQuery from '../queries/useInstrumentCategories.query';

interface Props {
  values: OptionKey[];
  onChangeValues: (values: OptionKey[]) => void;
}
const ALL_KEY = 'ALL_KEY';
const FundsCategoryPicker: React.FC<Props> = ({ values, onChangeValues }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [currentValue, setCurrentValue] = useState<OptionKey[]>([]);
  const { formattedOptions: categoryOptions } = useInstrumentCategoriesQuery();
  const formattedOptions = useMemo(() => [
    {
      key: ALL_KEY,
      value: t('All'),
      title: t('All'),
    },
    ...categoryOptions,
  ], [categoryOptions, t]);
  const handleChangeValues = useCallback((mewValues: OptionKey[]) => {
    if (mewValues.includes(ALL_KEY) && !currentValue.includes(ALL_KEY)) {
      setCurrentValue([ALL_KEY]);
    } else {
      setCurrentValue(mewValues.filter((item) => item !== ALL_KEY));
    }
  }, [currentValue]);
  const handleCancel = useCallback(() => {
    setExpanded(false);
    setCurrentValue(values);
  }, [values]);
  const handleApply = useCallback(() => {
    setExpanded(false);
    onChangeValues(currentValue.filter((item) => item !== ALL_KEY));
  }, [currentValue, onChangeValues]);
  useEffect(() => {
    if (!expanded) {
      setCurrentValue(values);
      onChangeValues(values);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);
  return (
    <Box
      key={`${expanded}`}
      width={{ min: 'control', max: 'control' }}
    >
      <SelectDropdown
        testId="funds-category-picker"
        id="category"
        name="category"
        allMode
        prefix={t('Category')}
        options={formattedOptions}
        values={currentValue}
        placeholder="Select a value"
        dropdownStyles={{
          minWidth: '320px',
        }}
        defaultExpanded={expanded}
        onExpanded={setExpanded}
  
        onChangeValues={handleChangeValues}
        footer={(

          <Box
            direction="column"
            width="100%"
            pad={{ bottom: 'xxs' }}
            style={{ position: 'relative' }}
          >
            <Box
              border={{ color: 'border2', side: 'top', size: 'small' }}
            />
            <Box
              pad={{
                vertical: 'm',
                horizontal: 'l',
              }}
              direction="row"
            >
              <Box direction="column" fill style={{ flex: 1 }} margin={{ right: 'spacing6' }}>

                <AppButton
                  buttonType="secondary"
                  width="100%"
                  testId="funds-category-picker_cancel"
                  onClick={handleCancel}
                >
                  {t('Cancel')}
                </AppButton>
              </Box>
              <Box
                direction="column"
                fill
                style={{ flex: 1 }}
                margin={{ left: 'spacing6' }}
              >

                <AppButton
                  width="100%"
                  testId="funds-category-picker_apply"
                  onClick={handleApply}
                >
                  {t('Apply')}
                </AppButton>
              </Box>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};
export default FundsCategoryPicker;
