import { storeCreator } from '@/utils/store/store.utils';

import { RecurringContribution } from '../contribution.types';

interface State {
  recurringContribution?: Partial<RecurringContribution>;
  handleSetContribution: (value: Partial<RecurringContribution>) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  recurringContributions: undefined,

  handleSetContribution: (recurringContribution) =>
    set(() => ({ recurringContribution })),

  handleReset: () => set(() => ({ recurringContribution: undefined })),
}));
