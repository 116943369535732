import React, { useMemo } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DATE_TOOLTIP_FORMAT } from '@/common/constants';
import { Notification, PaymentType,TransactionApprovalCode } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import text from '../AppealText';

interface Props {
  data?: Notification;
  employeeFirstName?: string;
  paymentType?: PaymentType;
  serviceName?: string;
  serviceDate?: string;
}

const useGetDeniedNotificationMessage = (props: Props) => {
  const { data, employeeFirstName, paymentType, serviceDate, serviceName } = props;
  const paymentTypeLabel = useMemo(() => paymentType?.replace('_', ' ')?.toLowerCase(), [paymentType]);

  return useMemo(() => {
    switch (data?.approvalCode) {
    case TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES:
      return {
        mainText: `Hi ${employeeFirstName}. The IRS does not allow ${paymentTypeLabel} for this service.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES:
      return {
        mainText: `Hi ${employeeFirstName}. Your plan does not allow ${paymentTypeLabel} for this service.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like the date for the expense you submitted was outside the coverage period for your plan.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like the amount you requested for ${paymentTypeLabel} is more than what is allowed by your plan.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CLAIM_RECEIVED_AFTER_RUNOUT_DATE:
      return {
        mainText: `Hi ${employeeFirstName}. Unfortunately we received your request after the end of the allowable period to submit expenses.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.DETAILED_RECEIPT_REQUIRED:
      return {
        mainText: (
          <Inscription size={theme.spacings.m}>
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. It looks like we didn't receive all of the required documentation.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>We were missing a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.PROOF_OF_EXPENSE_NEEDED:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we didn't receive all of the required documentation. We were missing a detailed receipt or document with the following information:`,
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we didn't receive all of the required documentation. We were missing a detailed receipt or document with the following information:`,
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CLAIM_COULD_NOT_BE_AUTO_SUBSTANTIATED_BY_CARD_PROCESSOR:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we didn't receive all of the required documentation. We were missing a detailed receipt or document with the following information:`,
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.LETTER_OF_MEDICAL_NECESSITY_REQUIRED:
      return {
        mainText: `Hi ${employeeFirstName}. A letter of medical necessity is needed from your physician to approve this expense.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CLAIM_SUBMITTED_AFTER_GRACE_PERIOD:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like the date of service provided is outside of the plan's coverage period.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.DUPLICATE_CLAIM:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we already received and processed a ${paymentTypeLabel} for ${serviceName} provided on ${dayjs(serviceDate || '').format(DATE_TOOLTIP_FORMAT)}`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CUSTOM:
      return {
        text: data.customDenialReason,
        hasAppealButton: true,
      };
    default:
      return {};
    }
  }, [data, employeeFirstName, paymentTypeLabel, serviceDate, serviceName]);
};

export default useGetDeniedNotificationMessage;
