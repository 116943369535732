import React from 'react';
import { AppButton,Box } from '@common-fe/common-fe';

const AppealButton = () => (
  <Box margin={{ top: 'spacing12' }}>
    <AppButton testId='appeal_button' onClick={() => window.open('https://app.smartsheet.com/b/form/d0ffa00fb5414f1697c8f23b681e235b', '_blank')}>Appeal</AppButton>
  </Box>
);

export default AppealButton;
