import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { useInvestor } from '@/modules/investments/hooks';
import {SwitchToSelfDirectedAllocation}  from '@/modules/investments/SelfDirected/SwitchToSelfDirected';
import { useMyEnrollments } from '@/modules/transaction';

const SelfDirectedSwitchAllocationPage = () => {
  const { accountId = '' } = useParams<{ accountId: string }>();
  
  const { data: {  investorId } } = useInvestor(accountId);
  const { t } = useTranslation();
  const { currentMyAccount } = useMyEnrollments(accountId);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: '...',
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: currentMyAccount?.name,
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Investments Model Switch'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }], true);

  if(!accountId || !investorId){
    return null;
  }
  
  return (
 
    <SwitchToSelfDirectedAllocation accountId={accountId} investorId={_.toString(investorId)} /> 
  
  );
};
export default SelfDirectedSwitchAllocationPage;
