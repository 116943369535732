import React, { useCallback, useMemo } from 'react';
import {
  Box, Heading, RoundDoneIcon,
  Text, } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';

import useCardReplaceModalStore from '../../store/useCardReplaceModal.store';

interface Props {
  onCancel?: (value: boolean) => void;
  setDoneMode?: () => void;
  onSnackbar?: (props: unknown) => void;
  refreshCardList?: () => void;
  cardholderName?: string;
}

export const CardReplaceAcceptMessage: React.FC<Props> = ({
  onCancel, setDoneMode, onSnackbar, refreshCardList, cardholderName,
}) => {
  const { handleReset } = useCardReplaceModalStore();
  const messageText = useMemo(() => `${cardholderName}'s card has been requested and will be mailed in 3-5 days`, [cardholderName]);

  const onResetModalData = useCallback(() => {
    if (setDoneMode && onSnackbar) {
      setDoneMode();
      onSnackbar({ text: messageText, closeIcon: true });
      setTimeout(() => {
        handleReset();
      }, 5000);
    }
  }, [handleReset, messageText, onSnackbar, setDoneMode]);

  const handleCancel = useCallback(() => {
    if (onCancel && refreshCardList) {
      refreshCardList();
      onCancel(false);
      onResetModalData();
    }
  }, [onCancel, onResetModalData, refreshCardList]);

  return (
    <Box align="center" pad={{ top: 'spacing12', bottom: 'spacing32' }} data-testid="CardReplaceAcceptMessage">
      <Box margin={{ bottom: 'spacing32' }}>
        <RoundDoneIcon />
      </Box>
      <Heading margin={{ bottom: 'spacing8' }} size="24px" style={{ fontWeight: 700 }}>
        We’ve Received Your Card Replacement Request!
      </Heading>
      <Text margin={{ bottom: 'spacing32' }} size="16px">
        Card will be mailed in 3-5 days
      </Text>
      <AppButton
        type="button"
        onClick={handleCancel}
        width="control"
      >
        Got it!
      </AppButton>
    </Box>
  );
};
