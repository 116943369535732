import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, CloseCircleIcon,
  LockIcon,   Preloader,
  Text,   UnlockIcon,
} from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import {
  ErrorModal, ModalWrapper,
} from '@/components';
import AppButton from '@/components/controls/AppButton';
import { useHasAccess,useSnackbar } from '@/modules/core/hooks';
import { CardStatusEnum } from '@/modules/transaction/components/Cards/Cards.types';
import { useSetCardStatusModal } from '@/modules/transaction/components/Cards/store/useSetCardStatusModal.store';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import {
  useChangeCardStatus,
  useGetCardsQuery,
} from '@/modules/transaction/hooks/useGetCards.query';
import { useAuthStore } from '@/modules/user/stores';

import useGetCard from '../../hooks/useGetCard';
import { CardItem } from '../CardItem';
import CloseCardConfirmationModal from '../CloseCardConfirmationModal';

import {
  CardItemWrapper,
  CardLoggedTooltip,
  CardStatusActionWrap,
} from './ChangeStatusCardModal.styles';

export const ChangeStatusCardModal: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const {
    state: { cardId, visible: visibilityModal }, handleReset,
  } = useSetCardStatusModal();
  const { organizationProperties } = useOrganizationProperties();
  const [closeCardConfirmation, setCloseCardConfirmation] = useState(false);
  const [closeCardSuccess, setCloseCardSuccess] = useState(false);
  const { card, refetch, isLoading } = useGetCard(cardId);
  const { handleAddPermanentSnackbar } = useSnackbar();

  const { refetch: refetchCardList } = useGetCardsQuery(user?.employee?.id);
  const [hideVisibility, setHideVisibility] = useState(false);
  const visible = useMemo(() => (hideVisibility ? false : visibilityModal),
    [visibilityModal, hideVisibility]);
  const {
    mutateAsync: changeStatusMutation,
    isLoading: changeStatusMutationLoading,
  } = useChangeCardStatus();
  const lockBtnText = useMemo(() => (card?.status === CardStatusEnum.FROZEN
    ? 'Unlock Card'
    : 'Lock Card'), [card]);
  const loadingButton = useMemo(() => changeStatusMutationLoading || isLoading,
    [
      changeStatusMutationLoading,
      isLoading,
    ]);
  const isCardNumberFullyMasked = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);

  const handleCloseErrorModal = useCallback((value: boolean) => {
    handleAddPermanentSnackbar({
      text: `Card ends ${card?.last4Number} was closed!`,
      closeIcon: true,
    });
    setCloseCardSuccess(value);
    setHideVisibility(false);
    handleReset();
  }, [card.last4Number, handleAddPermanentSnackbar, handleReset]);

  const changeStatusMutationHandler = useCallback(async (status: CardStatusEnum) => {
    if (card) {
      await changeStatusMutation({ id: cardId, status });
      refetch();
      refetchCardList();
    }
  }, [card, cardId, changeStatusMutation, refetch, refetchCardList]);

  return (
    <>
      <ModalWrapper visible={visible} onSetVisible={handleReset} nonOverflow>
        <Box height={{ min: '42px' }} />
        <Box background="module" round="container1Round" align="center" justify="end">
          {card
            ? (
              <CardItemWrapper margin={{ top: '-135px', bottom: 'spacing24' }}>
                {
                  card.status === CardStatusEnum.FROZEN
                  || card.status === CardStatusEnum.PENDING_FROZEN
                    ? (
                      <CardLoggedTooltip direction="row" justify="center">
                        <Box
                          justify="center"
                          align="center"
                          direction="row"
                          background="danger"
                          round="dropdownItemRound"
                          pad={{ horizontal: 'spacing24', vertical: 'spacing8' }}
                        >
                          <LockIcon size="small" color="iconAccent" />
                          <Text weight="bold" margin={{ left: 'spacing8' }} color="textOnColor">Your card is locked</Text>
                        </Box>
                      </CardLoggedTooltip>
                    ) : null
                }
                <CardItem
                  borderColor="accentActive"
                  fullyMasked={isCardNumberFullyMasked}
                  logoUrl={organizationProperties?.darkLargeLogoUrl}
                  card={card}
                  active
                  isLabel
                />
              </CardItemWrapper>
            )
            : null}
          <Text weight="bold" size="xxlarge" margin={{ bottom: 'spacing24' }}>What would you like to do?</Text>
          <Box direction="row" align="start" pad={{ horizontal: 'spacing24', bottom: 'spacing24' }}>
            <CardStatusActionWrap
              background="canvas"
              round="container1Round"
              pad={{ horizontal: 'spacing24', top: 'spacing32', bottom: '28px' }}
              margin={{ right: 'spacing24' }}
              align="center"
              border={{ color: 'border2' }}
            >
              {
                card?.status === CardStatusEnum.FROZEN
                || card?.status === CardStatusEnum.PENDING_FROZEN
                  ? <UnlockIcon size="xlarge" color="iconAccent" />
                  : <LockIcon size="xlarge" color="iconAccent" />
              }
              <Text
                weight="bold"
                margin={{ top: 'spacing24', bottom: 'spacing8' }}
              >
                {card?.status === CardStatusEnum.FROZEN
                  ? 'Unlock Card'
                  : 'Lock Card'}
              </Text>
              <Text
                textAlign="center"
                margin={{ bottom: 'spacing24' }}
              >
                You can unlock this card at any time.
              </Text>
              <AppButton
                disabled={loadingButton}
                width="130px"
                onClick={() => changeStatusMutationHandler(
                  card?.status === CardStatusEnum.FROZEN
                    ? CardStatusEnum.ACTIVE
                    : CardStatusEnum.FROZEN,
                )}
              >
                {loadingButton
                  ? <Preloader color="white" />
                  : lockBtnText}
              </AppButton>
            </CardStatusActionWrap>
            <CardStatusActionWrap
              background="canvas"
              round="container1Round"
              pad={{ horizontal: 'spacing24', top: 'spacing32', bottom: '28px' }}
              align="center"
              border={{ color: 'border2' }}
            >
              <CloseCircleIcon />
              <Text
                weight="bold"
                margin={{ top: 'spacing24', bottom: 'spacing8' }}
              >
                Close Card
              </Text>
              <Text textAlign="center" margin={{ bottom: 'spacing24' }}>
                {card?.primary
                  ? 'You cannot close your primary account card, only replace it.'
                  : 'You cannot reopen this card after closing it.'}
              </Text>
              <AppButton
                disabled={loadingButton || card?.primary}
                width="130px"
                color="red"
                onClick={() => {
                  setCloseCardConfirmation(true);
                  setHideVisibility(true);
                }}
              >
                {loadingButton
                  ? <Preloader color="white" />
                  : 'Close Card'}
              </AppButton>
            </CardStatusActionWrap>
          </Box>
        </Box>
      </ModalWrapper>
      <CloseCardConfirmationModal
        header={t('Are you sure that you want to close this card?')}
        helpText={t('You cannot reopen this card after closing it.')}
        buttonText={t('Continue')}
        visible={closeCardConfirmation}
        onCancel={() => {
          setCloseCardConfirmation(false);
          setHideVisibility(false);
        }}
        submitting={changeStatusMutationLoading}
        onSubmit={async () => {
          await changeStatusMutationHandler(CardStatusEnum.CLOSED);
          setCloseCardConfirmation(false);
          setCloseCardSuccess(true);
        }}
      /><ErrorModal
        visible={closeCardSuccess}
        onSetVisible={handleCloseErrorModal}
        header={`Card ends ${card?.last4Number} was closed!`}
        buttonText="Got it!"
      />
    </>
  );
};
