import { ActivityType } from '@common-fe/common-fe';
import { capitalize } from 'lodash';

import { whiteLabelingConfig } from '.';

const getActivityTypeColor = (type?: ActivityType) => {
  if (type && whiteLabelingConfig?.colors) {
    return whiteLabelingConfig.colors[
      `transaction${capitalize(type || '')}` as keyof typeof whiteLabelingConfig.colors
    ] || '';
  }

  return '';
};

export default getActivityTypeColor;
