export interface ReimbursementDto {
  pay_to_type?: string;
  provider_id?: number;
  organization_id?: number;
  payment_type?: string;
  service_id?: number;
  transact_method?: string;
  requested_amount?: number;
  created_by?: {
    claim_actor_type?: string;
    employee_id?: number;
    dependent_id?: number;
    contact_id?: number;
    card_processor_id?: number;
  },
  service_for?: {
    claim_actor_type?: string;
    employee_id?: number;
    dependent_id?: number;
    contact_id?: number;
    card_processor_id?: number;
  },
  source?: string;
  employee_provider_acc?: string;
  provider_invoice?: string;
  organization_path?: string;
  employee_id?: number;
  document_id?: number;
  claim_status_type?: string;
  payment_method_id?: number;
}

export enum TransactMethod {
  check = 'CHECK',
  directDeposit = 'ACH',
  debit = 'DEBIT',
  venmo = 'VENMO',
  paypal = 'PAYPAL',
}

export enum FormField {
  provider = 'provider',
  patient = 'patient',
  serviceDate = 'serviceDate',
  amount = 'amount',
  service = 'service',
  account = 'account',
  invoice = 'invoice',
}

export interface ReimbursementFormPayload {
  account: string;
  amount: string;
  invoice: string;
  patient: string;
  provider: string;
  service: string;
  serviceDate: string;
  paymentMethodId?: string;
  paymentType?: 'REIMBURSEMENT' | 'BILL_PAY';
  payToType?: string;
  transactMethod?: TransactMethod;
  ocrReceiptId?: string | null;
}
