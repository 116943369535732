import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, FlexControlledForm, Preloader, useDebounceState } from '@common-fe/common-fe';
import * as yup from 'yup';

import { ValuesMap } from '@/common/types';

import { useInvestorById } from '../../hooks';
import { InvestmentPaths, SelfDirectedInvestmentsQuestionnairePayload } from '../../investments.types';
import ServerErrorModal from '../../modal/ServerErrorModal';
import { useInvestmentQuestionnaireQuery } from '../../queries';

import { useDefaultQuestionnaireValues, useQuestionnaireFields } from './hooks';
interface Props {
  onSubmit: () => void;
  onBack: () => void;
  investmentType: InvestmentPaths;
}

const QuestionnaireFields: React.FC<Props> = ({ onSubmit,investmentType, onBack }) => {
  const [isShowErrors, setShowErrors] = useState(false);
  const [isServerError, setServerError] = useState(false);
  const { t } = useTranslation();
  
  const defaultValues  =  useDefaultQuestionnaireValues();

  const [localValues, setLocalValues] = useState<Partial<SelfDirectedInvestmentsQuestionnairePayload>>(defaultValues || {});

  useEffect(() => {
    setLocalValues(defaultValues);
  }, [defaultValues]);

  const { setValues, values } = useDebounceState<Partial<SelfDirectedInvestmentsQuestionnairePayload>>();

  useEffect(() => {
    if (values) {
      setLocalValues({
        ...localValues,
        ...values,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  const fields = useQuestionnaireFields({
    defaultValues
  });
  const { handleSave, isLoading } = useInvestmentQuestionnaireQuery({investmentType: investmentType});

  const handleSubmit = useCallback(async () => {
    if(isLoading) {
      return;
    }
    setShowErrors(true);
    const validatorMap = fields.reduce((result, field) => {
      return {
        ...result,
        [field.name]: field.validator,
      } as ValuesMap<yup.AnySchema>;
    }, {});

    const schema = yup.object().shape(validatorMap);
    try {
      schema.validateSync(localValues, { abortEarly: false });
     
      try {
        await handleSave(localValues as SelfDirectedInvestmentsQuestionnairePayload);
        setShowErrors(false);
        onSubmit();
      } catch {
        setServerError(true);
      }
    } catch {
      setShowErrors(true);
    }
  }, [fields, handleSave, isLoading, localValues, onSubmit]);
  return (
    <>
      <ServerErrorModal
        visible={isServerError}
        isLoading={isLoading}
        onSetVisible={setServerError}
        onSendRequest={handleSubmit}
      />
      <Box direction="column">
        <Box margin={{ top: 's' }} background="module" pad="l" round="moduleRound">
          <Box direction="column">
            {fields.map((field) => (
              <Box
                key={field.name}
                background="canvas"
                elevation="default"
                border={{ color: 'border2' }}
                round="container1Round"
                margin={{ bottom: 'l' }}
              >
                <FlexControlledForm
                  fields={[field]}
                  showError={isShowErrors}
                  editMode
                  onChangeValues={setValues}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box direction="row" justify="end" margin={{ top: 'l' }}>
          <AppButton width="140px" buttonType="secondary" onClick={onBack}>
            {t('Back')}
          </AppButton>
          <Box margin={{ left: 's' }}>
            <AppButton width="140px" onClick={handleSubmit}>
              {isLoading ? <Preloader /> : t('Next (2/4)')}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QuestionnaireFields;
