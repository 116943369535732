import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  & > div > label {
    margin-left: 0;
  }
`;

interface Props {
  isSaveAsDefault: boolean;
  setIsSaveAsDefault: (value: boolean) => void;
  isDisabled?: boolean;
}

const SaveAsDefaultCheckbox: React.FC<Props> = ({ isSaveAsDefault, setIsSaveAsDefault, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <StyledCheckbox
        id="submitReguest"
        className="checkbox-item"
        padding
        checked={isSaveAsDefault}
        disabled={isDisabled}
        onChange={() => setIsSaveAsDefault(!isSaveAsDefault)}
      >
        <Inscription color="textBody">{t('Save as default payment method')}</Inscription>
      </StyledCheckbox>
    </Box>
  );
};

export default SaveAsDefaultCheckbox;
