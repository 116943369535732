import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { useOrphanModeStore } from '@/modules/core/store';
import { CIPBanner } from '@/modules/investments/cip';
import { useCIPInfo } from '@/modules/investments/cip/hooks';
import useIsFullLiquidation from '@/modules/investments/hooks/useIsFullLiquidation';
import InvestmentsBreakdownBanner from '@/modules/investments/InvestmentsBreakdown/InvestmentsBreakdownBanner';
import { useMyEnrollments } from '@/modules/transaction/hooks';

import  FundsManagementBanner, { useFundManagementOptions } from '../../FundsManagementBanner';

import AccountDetails from './AccountDetails';
import ActivitiesList from './ActivitiesList';
import DisclosureBankMessage from './DisclosureBankMessage';
import DisclosureButton from './DisclosureButton';
import { MoneyMovedMessage } from './MoneyMovedMessage';
import {
  Wrapper,
} from './MyAccounts.styles';

interface Props {
  accountId: string;
}
const MyAccounts: React.FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();
  const {
    currentMyAccount, isCurrentHSA, isGenerateStatementsEnabled,
  } = useMyEnrollments(accountId);

  const { linkedEmployee } = useOrphanModeStore();

  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.MY_ACCOUNTS,
    title: t('My accounts'),
    value: `${ROUTES.MY_ACCOUNTS}`,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: currentMyAccount?.name,
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  }], true);
  const { isVerificationRequired } = useCIPInfo();
  const isNotVerified = useMemo(
    () => isVerificationRequired && currentMyAccount?.isCIPRequired,
    [currentMyAccount, isVerificationRequired],
  );
  const {isFullLiquidated} = useIsFullLiquidation({accountId});
  const { fundManagementItems, fundManagementButtons } = useFundManagementOptions(
    currentMyAccount, 
    {
      accountId,
      isNotVerified,
      isFullLiquidation: isFullLiquidated
    },

  );
  
  return (
    <Wrapper data-testid="MyAccounts-wrapper">
      {currentMyAccount && (
        <Box margin={{ bottom: 'spacings24' }}>
          <Box direction="row" align="center" justify="between">
            <Heading color="textTitle" level={1} size="regular">
              {currentMyAccount.name}
            </Heading>
            {
              (isGenerateStatementsEnabled || !!fundManagementButtons.length) && (
                <DisclosureButton
                  externalButtons={fundManagementButtons}
                  accountId={accountId}
                  isCurrentHSA={isCurrentHSA}
                />
              )
            }
          </Box>
          {
            isCurrentHSA && linkedEmployee && (
              <Box margin={{ top: 'spacing24' }}>
                <MoneyMovedMessage />
              </Box>
            )
          }
          <AccountDetails
            id={accountId}
            currentMyAccount={currentMyAccount}
          />
        </Box>
      )}
      {isNotVerified && (
        <Box pad={{ bottom: 'l' }}>
          <CIPBanner />
        </Box>
      )}
      {isCurrentHSA && (
        <FundsManagementBanner
          fundsManagementItems={fundManagementItems}
        />
      )}
      {isCurrentHSA  && (
        <InvestmentsBreakdownBanner
          accountId={accountId}
        />
      )}
      
      <ActivitiesList disabled={isNotVerified} key={accountId} id={accountId} title={t('Activity')} />
      <DisclosureBankMessage isCurrentHSA={isCurrentHSA} />
      <Box height="72px" />
    </Wrapper>
  );
};
export default MyAccounts;
