const dollarUSLocale = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrency = (amount?: number) => {
  if (typeof amount !== 'number') return amount;
  return dollarUSLocale.format(amount);
};

export default formatCurrency;
