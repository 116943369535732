import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

const AccountExistError = () => {
  const { t } = useTranslation();
  return (
    <Box direction="column" align="end" margin={{ bottom: 'spacing24' }} fill>
      <Text color="danger">{t('We aren’t able to find you! No worries, just reach out to your plan administrator for help.')}</Text>
    </Box>
  );
};

export default AccountExistError;
