import { storeCreator } from '@/utils/store/store.utils';

import { PortfolioAllocationPayload } from '../../StartInvesting.types';

interface State {
  values: PortfolioAllocationPayload;
  handleSetValues: (value: Partial<PortfolioAllocationPayload>) => void;
  handleReset: () => void;
}

const DEFAULT_VALUES: PortfolioAllocationPayload = {
  riskValue: '',
};

export const usePortfolioAllocationStore = storeCreator<State>((set) => ({
  values: DEFAULT_VALUES,
  handleSetValues: (value: Partial<PortfolioAllocationPayload>) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      ...value,
    },
  })),
  handleReset: () => set((state) => ({
    ...state,
    values: DEFAULT_VALUES,
  })),
}));
