import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Inscription, Preloader, RoundPendingIcon } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import useRemoveDependentQuery from '@/modules/user/components/ManageProfile/components/Dependents/queries/useRemoveDependent.query';
import { useStore } from '@/modules/user/components/ManageProfile/components/Dependents/stores/useDependents.store';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const RemoveDependentModal: React.FC<Props> = ({ onSetVisible }) => {
  const { t } = useTranslation();
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { editableDependent, handleReset } = useStore();
  const { removeDependent, isLoading } = useRemoveDependentQuery();

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const onSuccess = useCallback(() => {
    handleClose();
    handleAddPermanentSnackbar({
      text: `${editableDependent?.firstName} ${editableDependent?.lastName} was successfully removed`,
      state: SnackbarState.positive,
      closeIcon: true,
      autoRemove: true,
    });
  }, [editableDependent, handleClose, handleAddPermanentSnackbar]);

  const handleSubmit = useCallback(async() => {
    const dependentId = editableDependent?.id;
    if (dependentId) {
      await removeDependent(dependentId);
      onSuccess();
    }
  }, [editableDependent, onSuccess, removeDependent]);

  useEffect(() => {
    return () => {
      handleReset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper visible onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24' }}>
        <RoundPendingIcon color='warning' />
        <Inscription
          margin={{ top: 'spacing12' }}
          size='2xl'
          weight={700}
        >
          {t('Remove dependent?')}
        </Inscription>
        <Box margin={{ top: 'spacing24' }} direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton
              onClick={handleClose}
              buttonType='secondary'
              width='140px'
              testId='cancel'
            >
              {t('Cancel')}
            </AppButton>
          </Box>
          <Box>
            <AppButton
              onClick={handleSubmit}
              color='red'
              width='140px'
              testId='remove'
            >
              {isLoading ? <Preloader color='error' /> : t('Remove')}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default RemoveDependentModal;
