import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { RelationshipType } from '../components/Cards/Cards.types';

const GET_RELATIONSHIP_TYPES_QUERY_KEY = 'getRelationshipTypesQueryKey';
export const useGetRelationshipTypesQuery = () => {
  const { data, isLoading } = useQuery(
    [GET_RELATIONSHIP_TYPES_QUERY_KEY],
    () => api.get<RelationshipType[]>(PATHS.GET_RELATIONSHIP_TYPES),
  );

  return {
    types: data?.data || [],
    isLoading,
  };
};
