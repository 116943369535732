import { useCallback } from 'react';

import { useFundingAmountStore } from '@/modules/investments/BuySell/stores/useFundingAmount.store';

export default () => {
  const { handleReset: handleResetFundingAmountStore } = useFundingAmountStore();

  const handleReset = useCallback(() => {
    handleResetFundingAmountStore();
  }, [handleResetFundingAmountStore]);
  return handleReset;
};
