import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const IconWrapper = styled.div`
  font-size: ${({ theme }) => theme.spacings.spacing32};
  margin-right: ${({ theme }) => theme.spacings.spacing8};
  position: absolute;
  top: ${({ theme }) => theme.spacings.spacing20};
  left: ${({ theme }) => theme.spacings.spacing18};

`;

interface TextProps {
  padding?: boolean;
}
export const Text = styled.p<TextProps>`
  ${({ theme }) => theme.fonts.text16Regular};
  margin-top: ${({ theme, padding }) => (padding ? theme.spacings.spacing8 : undefined)};
`;

export const Title = styled.p<TextProps>`
  ${({ theme }) => theme.fonts.text16Bold};
  margin-top: ${({ theme, padding }) => (padding ? theme.spacings.spacing8 : undefined)};
`;

export const Subtitle = styled.p<TextProps>`
  ${({ theme }) => theme.fonts.text16Medium};
  margin-top: ${({ theme, padding }) => (padding ? theme.spacings.spacing8 : undefined)};
`;

interface WrapperProps {
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  withoutBackground?: boolean;
}
export const Wrapper = styled(Box)<WrapperProps>`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing16};
  }
  min-height: 'fit-content';
  ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  position: relative;
  ${({
    success, warning, theme, error,
  }) => {
    if (success) {
      return {
        backgroundColor: theme.colors.successContainer,
      };
    }
    if (error) {
      return {
        backgroundColor: theme.colors.dangerContainer,
      };
    }
    if (warning) {
      return {
        backgroundColor: theme.colors.warningContainer,
      };
    }
    return {
      backgroundColor: theme.colors.canvas,
    };
  }};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 2px;
    left: ${({ theme }) => theme.spacings.spacing24};
    right: ${({ theme }) => theme.spacings.spacing24};
    background-color: ${({
    success, warning, theme, error,
  }) => {
    if (success) {
      return theme.colors.success;
    }
    if (error) {
      return theme.colors.danger;
    }
    if (warning) {
      return theme.colors.warning;
    }
    return 'transparent';
  }}
  }
  
  ${IconWrapper} {
 
    color: ${({
    theme, warning, success, error,
  }) => {
    if (success) {
      return theme.colors.success;
    }
    if (error) {
      return theme.colors.danger;
    }
    if (warning) {
      return theme.colors.warning;
    }
    return 'transparent';
  }};
}
`;

interface ContentProps {
  padding?: boolean;
}
export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme, padding }) => (padding ? theme.spacings.spacing12 : undefined)};
`;

export const List = styled.ul`
  ${({ theme }) => theme.fonts.text14Regular};
  list-style: inside;
  /* padding: ${({ theme }) => theme.spacings.spacing24}; */
  /* background-color: ${({ theme }) => theme.colors.canvas}; */
  /* ${({ theme }) => theme.border.warning}; */
  /* border-radius: ${({ theme }) => theme.spacings.spacing8}; */
  margin-top: ${({ theme }) => theme.spacings.spacing8};
`;
