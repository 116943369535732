import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  costFormater,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { INVESTMENTS_ERROR_KEY } from '@/common/constants';

interface Params {
  investingAmount?: string;
  minimumInvestmentAmount?: number;
  availableToInvest: number;
  isSellingMode?: boolean;
}
const { EMTPY_AMOUNT_ERROR, AMOUNT_MORE_THAN_ZERO_ERROR } = INVESTMENTS_ERROR_KEY;
export default ({ investingAmount, minimumInvestmentAmount, availableToInvest, isSellingMode }: Params) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  useEffect(() => {
    if (investingAmount === '') {
      setError(EMTPY_AMOUNT_ERROR);
    } else if ( !isSellingMode && !!minimumInvestmentAmount
      && _.toNumber(investingAmount) < minimumInvestmentAmount) {
      setError(t(`Minimum investment amount is ${costFormater(minimumInvestmentAmount, true)}`));
    } else if (availableToInvest < _.toNumber(investingAmount)) {
      const NOT_ALLOWED_AMOUNT_ERROR = `${t('You cannot invest more than')} ${costFormater(availableToInvest, true)}`;
      setError(NOT_ALLOWED_AMOUNT_ERROR);
    } else if ((_.toNumber(investingAmount) === 0
      && investingAmount !== '')
      || availableToInvest === 0
    ) {
      setError(AMOUNT_MORE_THAN_ZERO_ERROR);
    } else {
      setError('');
    }
  }, [availableToInvest, investingAmount, minimumInvestmentAmount,isSellingMode, t]);
 
  return {
    error,
    setError,
  };
};
