import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import colors from './colors';

const mainFontFamily = whiteLabelingConfig?.font || '\'Inter\', sans-serif';
const secondFontFamily = whiteLabelingConfig?.font || '\'Playfair Display\', serif';
// @mixin common-,text-style {
//   font-family: $font-family;
//   color: $black;
// }

const commonTextStyle = {
  'font-family': mainFontFamily,
  color: colors.textBody,
};

const boldMixin = {
  ...commonTextStyle,
  'font-weight': 'bold',
};
const mediumMixin = {
  ...commonTextStyle,
  'font-weight': '500',
};
const regularMixin = {
  ...commonTextStyle,
  'font-weight': '400',
};

export default {

  icons: {
    size: '16px',
  },
  secondFontFamily: {
    'font-family': secondFontFamily,
  },
  text80Bold: {
    'font-size': '80px',
    ...boldMixin,
    'line-height': '120px',
  },

  text80Medium: {
    'font-size': '80px',
    ...mediumMixin,
    'line-height': '120px',
  },

  text80Regular: {
    'font-size': '80px',
    ...regularMixin,
    'line-height': '96px',
  },

  // ,text 64
  text64Bold: {
    'font-size': '64px',
    ...boldMixin,
    'line-height': '96px',
  },

  text64Medium: {
    'font-size': '64px',
    ...mediumMixin,
    'line-height': '96px',
  },

  text64Regular: {
    'font-size': '64px',
    ...regularMixin,
    'line-height': '96px',
  },

  // ,text 56
  text56Bold: {
    'font-size': '56px',
    ...boldMixin,
    'line-height': '84px',
  },

  text56Medium: {
    'font-size': '56px',
    ...mediumMixin,
    'line-height': '84px',
  },

  text56Regular: {
    'font-size': '56px',
    ...regularMixin,
    'line-height': '67px',
  },

  // ,text 48
  text48Bold: {
    'font-size': '48px',
    ...boldMixin,
    'line-height': '72px',
  },

  text48Medium: {
    'font-size': '48px',
    ...mediumMixin,
    'line-height': '72px',
  },

  text48Regular: {
    'font-size': '48px',
    ...regularMixin,
    'line-height': '72px',
  },

  // ,text 40
  text40Bold: {
    'font-size': '40px',
    ...boldMixin,
    'line-height': '60px',
  },

  text40Medium: {
    'font-size': '40px',
    ...mediumMixin,
    'line-height': '60px',
  },

  text40Regular: {
    'font-size': '40px',
    ...regularMixin,
    'line-height': '60px',
  },

  // ,text 32
  text32Bold: {
    'font-size': '32px',
    ...boldMixin,
    'line-height': '48px',
  },

  text32Medium: {
    'font-size': '32px',
    ...mediumMixin,
    'line-height': '48px',
  },

  text32Regular: {
    'font-size': '32px',
    ...regularMixin,
    'line-height': '48px',
  },

  // ,text 28
  text28Bold: {
    'font-size': '28px',
    ...boldMixin,
    'line-height': '44px',
  },

  text28Medium: {
    'font-size': '28px',
    ...mediumMixin,
    'line-height': '44px',
  },

  text28Regular: {
    'font-size': '28px',
    ...regularMixin,
    'line-height': '44px',
  },

  // ,text 24
  text24Bold: {
    'font-size': '24px',
    ...boldMixin,
    'line-height': '36px',
  },

  text24Medium: {
    'font-size': '24px',
    ...mediumMixin,
    'line-height': '36px',
  },

  text24Regular: {
    'font-size': '24px',
    ...regularMixin,
    'line-height': '36px',
  },

  // ,text 21
  text21Bold: {
    'font-size': '21px',
    ...boldMixin,
    'line-height': '32px',
  },

  text21Medium: {
    'font-size': '21px',
    ...mediumMixin,
    'line-height': '32px',
  },

  text21Regular: {
    'font-size': '21px',
    ...regularMixin,
    'line-height': '32px',
  },

  // ,text 18
  text18Bold: {
    'font-size': '18px',
    ...boldMixin,
    'line-height': '27px',
  },

  text18Medium: {
    'font-size': '18px',
    ...mediumMixin,
    'line-height': '27px',
  },

  text18Regular: {
    'font-size': '18px',
    ...regularMixin,
    'line-height': '27px',
  },

  // ,text 16
  text16Bold: {
    'font-size': '16px',
    ...boldMixin,
    'line-height': '24px',
  },

  text16Medium: {
    'font-size': '16px',
    ...mediumMixin,
    'line-height': '24px',
  },

  text16Regular: {
    'font-size': '16px',
    ...regularMixin,
    'line-height': '24px',
  },

  // ,text 14
  text14Bold: {
    'font-size': '14px',
    ...boldMixin,
    'line-height': '21px',
  },

  text14Medium: {
    'font-size': '14px',
    ...mediumMixin,
    'line-height': '21px',
  },

  text14Regular: {
    'font-size': '14px',
    ...regularMixin,
    'line-height': '21px',
  },

  // ,text 12
  text12Bold: {
    'font-size': '12px',
    ...boldMixin,
    'line-height': '18px',
  },

  text12Medium: {
    'font-size': '12px',
    ...mediumMixin,
    'line-height': '18px',
  },

  text12Regular: {
    'font-size': '12px',
    ...regularMixin,
    'line-height': '18px',
  },

  // ,text 10
  text10Bold: {
    'font-size': '10px',
    ...boldMixin,
    'line-height': '15px',
  },

  text10Medium: {
    'font-size': '10px',
    ...mediumMixin,
    'line-height': '15px',
  },

  text10Regular: {
    'font-size': '10px',
    ...regularMixin,
    'line-height': '15px',
  },
};
