import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, FloatImage,
  Heading, Image, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import logoImage from '@/assets/login-logo.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const FixedWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 295px;
  height: 300px;
`;
const Wrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.navigationBackground || theme.colors.fade2};
  color: ${({ theme }) => theme.colors.navigationTextDefault};
  
`;

interface SidebarProps {
  onSignInRedirect?: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onSignInRedirect }) => {
  const { t } = useTranslation();
  return (
    <Wrapper
      direction="column"
      width={{ min: 'stepperSize' }}
      style={{
        position: 'relative',
      }}
    >
      <Box
        fill
        align="start"
        pad={{
          horizontal: 'spacing32',
          vertical: 'spacing32',
        }}
        style={{
          zIndex: 100,
        }}
      >
        <Image
          src={whiteLabelingConfig?.images?.sidebarLogo as string || whiteLabelingConfig?.logo  as string || logoImage as unknown as string}
          alt="logo"
          height={40}
          {...(whiteLabelingConfig?.logo ? { width: '100%' } : {})}
        />
        <Box direction="row" align="center" margin={{ top: 'spacing12' }}>
          <Text size="large" color="navigationTextDefault">{t('Already a member? ')}</Text>
          <Box pad={{ left: 'spacing4' }}>
            <Button size="small"  empty style={{ fontWeight: 'bold' }} onClick={onSignInRedirect}>
              <Text color="navigationAccent2">
                {t('Sign In')}
              </Text>
            </Button>
          </Box>
        </Box>
        <Box margin={{ top: 'spacing32' }}>
          <Heading
            level={4}
            size="24px"
            color="navigationTextDefault"
          >
            {t('Register to Get Going!')}
          </Heading>
        </Box>
      </Box>
    
      {whiteLabelingConfig?.images?.reimburseMeSidebar ?  (
        <FixedWrapper>
          <FloatImage
            className="placeholder"
            src={whiteLabelingConfig?.images?.reimburseMeSidebar}
            fit="contain"
            style={{
              left: 0,
              right: 0,
              objectPosition: 'center bottom',
            }}
          />
        </FixedWrapper>
      ): null}
    </Wrapper>
  );
};
export default Sidebar;
