import { baseStoreCreator } from '@/utils/store';

export interface PersonalInfoPayload {
  email?: string;
  phoneType?: string;
  phone?: string;
  citizenship?: string;
}

export const DEFAULT_VALUES: PersonalInfoPayload = {
  email: '',
  phoneType: '',
  phone: '',
  citizenship: '',
};

export const useEmailConfigurationStore = baseStoreCreator<PersonalInfoPayload>(
  DEFAULT_VALUES,
);

export default useEmailConfigurationStore;
