import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, DonutChart, Text
} from '@common-fe/common-fe';

import { Donut, InvestmentStockItem } from '../investments.types';

import InvestmentsDonutList from './InvestmentsDonutList';
import { TipContentWrapper } from './InvestmentsDunut.styles';

interface Props {
  investmentValue?: number;
  data?: Donut[];
  headerNode?: React.ReactNode;
  holdingsData?: InvestmentStockItem[];
  totalHoldingsAmount: number;
}

const InvestmentsBreakdownChart: React.FC<Props> = ({
  investmentValue, data, holdingsData,
  totalHoldingsAmount,
}) => {
  const { t } = useTranslation();
  const [activeFund, setActiveFund] = useState('');
  return (
    <Box background="canvas" round="container2Round" width={{ min: '256px' }} height={{ min: '256px' }} border={{ size: 'small', color: 'border2' }} elevation="default">
      <DonutChart
      // TODO: refactor types
        // @ts-ignore
        data={data || []}
        totalAmount={investmentValue || 0}
        setActiveFund={setActiveFund}
        tipContent={
          (
            <TipContentWrapper>
              <Box direction="column" margin={{ right: 's' }}>
                <InvestmentsDonutList totalHoldingsAmount={totalHoldingsAmount} data={holdingsData} activeFund={activeFund} title={t('Current Holdings')} />
                
                <Box
                  pad="s"
                  background={{ color: 'warningContainer' }}
                  border={{ size: '1px', color: 'warningBorder' }}
                  round="fieldRound"
                  height={{ min: '105px' }}
                >
                  <Text margin={{ bottom: 'xs' }} color="textBody" size="small">
                    {t('Your trades were made outside market hours. Your transactions will be completed once the market is opened.')}
                  </Text>
                </Box>
              </Box>
            </TipContentWrapper>
          )
        }
      />
    </Box>
  );
};

export default InvestmentsBreakdownChart;
