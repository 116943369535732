import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

interface Props {
  startedScrolling: boolean;
  scrolledToTheEnd: boolean;
  notAllowed?: boolean;
}

export const WrapperWithVerticalShadows = styled(Box)<Props>`
  position: relative;
  &:before {
    content: '';
    opacity: ${({ startedScrolling }) => (startedScrolling ? '1' : '0')};
    transition: 0.2s;
    position: absolute;
    display: block;
    height: ${({ notAllowed, startedScrolling }) => (!startedScrolling || notAllowed ? '0px' : '40px')};
    width: 100%;
    right: 0;
    top: 0;
    z-index: 10;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 27.92%, #FFFFFF 100%);
  }
  &:after {
    content: '';
    opacity: ${({ scrolledToTheEnd }) => (scrolledToTheEnd ? '0' : '1')};
    transition: 0.2s;
    position: absolute;
    display: block;
    height: ${({ notAllowed, scrolledToTheEnd }) => (scrolledToTheEnd || notAllowed ? '0px' : '40px')};
    width: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 27.92%, #FFFFFF 100%);
  }
`;
