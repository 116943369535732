import React, { useCallback, useRef } from 'react';

import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import { useSetDefaultPaymentMethodQuery } from '@/modules/transaction/components/ReimburseMe/queries/useUpdatePaymentMethod.query';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { getPaymentMethodDetails } from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/components/PaymentMethodsList/getPaymentMethodName';
import { useDetectVerticalScrollShadow } from '@/utils/hooks/useDetectVerticalScrollShadow';

import { HiddenScrollWrapper, WrapperWithShadow } from './EmployeePaymentMethods.styles';
import PaymentMethodListItem from './PaymentMethodListItem';

interface Props {
  paymentMethodsList: PaymentMethod[];
  refetchPaymentMethods: () => void;
}

const EmployeePaymentMethodsList: React.FC<Props> = ({ paymentMethodsList, refetchPaymentMethods }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { updateDefaultPaymentMethod } = useSetDefaultPaymentMethodQuery();
  
  const {
    onScrollHandler,
    scrolledToTheEnd,
    startedScrolling,
    hasScroll,
  } = useDetectVerticalScrollShadow(ref.current);

  const handleSetDefaultPaymentMethod = useCallback(async (paymentMethodId: string) => {
    const paymentMethod = paymentMethodsList?.find((item) => item?.id && item?.id === paymentMethodId);

    if (paymentMethod) {
      await updateDefaultPaymentMethod(paymentMethod?.id);
      const paymentMethodName = getPaymentMethodDetails(paymentMethod);
      handleAddPermanentSnackbar({
        text: `${paymentMethodName.typeName}: ${paymentMethodName.paymentMethodDescription} is your default payment method`,
        state: SnackbarState.positive,
        closeIcon: true,
        autoRemove: true,
      });
      refetchPaymentMethods();
    }
  }, [updateDefaultPaymentMethod, refetchPaymentMethods, handleAddPermanentSnackbar, paymentMethodsList]);

  return (
    <WrapperWithShadow hasTopShadow={startedScrolling} hasBottomShadow={hasScroll && !scrolledToTheEnd}>
      <HiddenScrollWrapper
        ref={ref}
        onScroll={onScrollHandler}
        overflow={{ vertical: 'auto' }}
      >
        {paymentMethodsList.map((paymentMethod) => (
          <PaymentMethodListItem
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            onClick={handleSetDefaultPaymentMethod}
          />
        ))}
      </HiddenScrollWrapper>
    </WrapperWithShadow>
  );
};

export default EmployeePaymentMethodsList;
