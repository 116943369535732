import React, { useEffect,useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityType,
  Box,
  getPercents,
  OptionKey,
  SelectDropdown,
  Text,
  Tip,
} from '@common-fe/common-fe';

import spacings from '@/styles/spacings';
import { costFormater } from '@/utils/modifiers';

import { ExpenseOption } from '../../transaction.types';

import {
  Bar,
  ChartWrapper,
  ContentWrapper,
  Cost,
  DropdownWrapper,
  Label,
  LabelsWrapper,
  TipWrapper,
  Title,
  TotalHeader,
  Wrapper,
} from './ExpensesBreakdown.styles';

const HEIGHT_ONE_TIP_ITEM = 24;
const MINIMUM_TIP_EMPTY_SPACE = 85;
const CHART_WRAPPER_ID = 'chartWrapper';

interface Props {
  options: ExpenseOption[];
  className?: string;
  actionNode?: React.ReactNode;
  inlineMode?: boolean;
  totalExpense: number;
  isWithoutHeader?: boolean;
  isPortfolioMode?: boolean;
  riskModerate?: string;
  timeHorizon?: string;
}
const ExpensesBreakdown: React.FC<Props> = ({
  options,
  className,
  inlineMode,
  actionNode,
  totalExpense,
  isWithoutHeader,
  isPortfolioMode,
  riskModerate,
  timeHorizon,
}) => {
  const [picked, setPicked] = useState<OptionKey[]>([]);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [bottomTipPosition, setBottomTipPosition] = useState(false);

  const { t } = useTranslation('transaction');
  const items = useMemo<ExpenseOption[]>(() => {
    let reducedItems;
    if (!picked.length) {
      reducedItems = options;
    } else {
      reducedItems = options.reduce((result: ExpenseOption[], item) => {
        const find = picked.includes(item.key);
        if (find) {
          return [...result, item];
        }
        return result;
      }, []);
    }
    reducedItems.sort((a, b) => (a.value < b.value ? 1 : -1));
    return reducedItems;
  }, [picked, options]);

  const total = useMemo(() => items.reduce((result, item) => result + item.value, 0), [items]);
  const costBlock = (
    <>
      <TotalHeader>{t('Total:')}</TotalHeader>

      <Cost isNull={!totalExpense}>{costFormater(totalExpense, true)}</Cost>
    </>
  );

  useEffect(() => {
    const calculateTipPosition = () => {
      const chartWrapper = document.getElementById(CHART_WRAPPER_ID);
      if (chartWrapper) {
        const rect = chartWrapper.getBoundingClientRect();
        const distanceFromTop = rect.top;
        if (distanceFromTop < (items.length * HEIGHT_ONE_TIP_ITEM) + MINIMUM_TIP_EMPTY_SPACE) {
          setBottomTipPosition(true);
        } else {
          setBottomTipPosition(false);
        }
      }
    };

    calculateTipPosition();
    window?.addEventListener('scroll', calculateTipPosition);
    return () => window?.removeEventListener('scroll', calculateTipPosition);
  }, [items]);

  return (
    <Wrapper
      flex
      direction="column"
      round="container1Round"
      pad={isWithoutHeader ? undefined : 'spacing24'}
    >
      {isWithoutHeader || (
        <Box
          flex
          direction="row"
          align="center"
          margin={{ bottom: 'm' }}
        >
          <Title>{isPortfolioMode ? t('Recommended Portfolio') : t('Expense Breakdown')}</Title>
          {!inlineMode && costBlock}

          {actionNode || (
            <DropdownWrapper>
              <SelectDropdown
                values={picked}
                options={options}
                onChangeValues={setPicked}
                id="expenseType"
                prefix={t('Show')}
                name={t('Expense type')}
              />
            </DropdownWrapper>
          )}
        </Box>
      )}

      <ContentWrapper
        height={isWithoutHeader ? '100%' : undefined}
        inlineMode={inlineMode}
        isPortfolioMode={isPortfolioMode}
        isWithoutHeader={isWithoutHeader}
        justify="start"
        elevation="default"
        border={{ size: 'small', color: 'border2' }}
      >
        {items.length ? (
          <>
            <Box flex direction="row" align="center" height={{ max: spacings.spacing24 }}>
              <Title>{isPortfolioMode ? t('Recommended Portfolio') : t('Expense Breakdown')}</Title>
              <Box
                direction="row"
                align="center"
                justify="end"
              >
                {inlineMode && !isPortfolioMode && costBlock}
              </Box>
            </Box>
            <Tip
              dropProps={{
                align: bottomTipPosition ? { top: 'bottom' } : { bottom: 'top' },
              }}
              plain
              content={(
                <TipWrapper
                  direction="row"
                  background="background3"
                  round="container1Round"
                  pad="spacing12"
                  width="210px"
                  color="textOnColor"
                >
                  <Box
                    direction="column"
                    width="100%"
                  >
                    <Text size="14px" weight={700}>{t('Current Holdings')}</Text>
                    {items.map((item) => (
                      <Box
                        key={item.key}
                        direction="row"
                        justify="between"
                        align="end"
                        height={{ max: '24px' }}
                        pad={{ horizontal: 'spacing8' }}
                        background={{ color: hoveredItem === item.key ? 'border2' : undefined }}
                        round="xxsmall"
                      >
                        <Label
                          inline={inlineMode}
                          key={item.key}
                          typeKey={item.key as ActivityType}
                        >
                          {item.title}
                        </Label>
                        <Text weight="bold">{costFormater(item?.amount, true)}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    background="canvas"
                    width="10px"
                    height="10px"
                    style={{
                      transform: 'rotate(-135deg)',
                      position: 'absolute',
                      bottom: !bottomTipPosition ? '-5px' : '',
                      top: bottomTipPosition ? '-5px' : '',
                      left: '48%',
                    }}
                  />
                </TipWrapper>
              )}
            >
              <ChartWrapper
                id={CHART_WRAPPER_ID}
                flex
                direction="row"
                round="container1Round"
                overflow="hidden"
                height={{ min: spacings.spacing48, max: spacings.spacing48 }}
                margin={{ top: 'spacing12' }}
              >
                {items.map((item) => {
                  const percentsRaw = getPercents(item.value, total);
                  const percents = getPercents(item.value, total).toFixed(0);
                  return (
                    <Bar
                      key={item.key}
                      typeKey={item.key as ActivityType}
                      width={percentsRaw}
                      onMouseEnter={() => setHoveredItem(item.key)}
                      onMouseLeave={() => setHoveredItem(null)}
                    >
                      {percents}
                      %
                    </Bar>
                  );
                })}
              </ChartWrapper>
            </Tip>
            <Box
              flex
              direction="row"
            >
              <LabelsWrapper
                flex
                direction="row"
              >
                {items.map((item) => (
                  <Label inline={inlineMode} key={item.key} typeKey={item.key as ActivityType}>
                    {item.title}
                  </Label>
                ))}
              </LabelsWrapper>
            </Box>
          </>
        ) : (
          <>
            <Box flex direction="row" align="center" height={{ max: spacings.spacing24 }}>
              <Title>{isPortfolioMode ? t('Recommended Portfolio') : t('Expense Breakdown')}</Title>
              <Box
                flex
                direction="row"
                align="center"
                justify="end"
              >
                {inlineMode && costBlock}
              </Box>
            </Box>
            <ChartWrapper
              flex
              direction="row"
              round="container1Round"
              overflow="hidden"
              height={{ min: spacings.spacing48, max: spacings.spacing48 }}
              margin={{ top: '21px' }}
            >
              <Bar width={100}>
                {0}
                %
              </Bar>
            </ChartWrapper>
          </>
        )}

      </ContentWrapper>
      {
        isPortfolioMode && (
          <Box
            direction="row"
            justify="between"
            border={{ side: 'top', color: 'border2', size: 'small' }}
            round={{ size: 'small', corner: 'bottom' }}
            background="canvas"
            pad="spacing16"
          >
            <Box direction="row">
              <Text size="medium" margin={{ right: 'spacing4' }}>{t('Risk Tolerance:')}</Text>
              <Text size="medium" weight="bold" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{riskModerate}</Text>
            </Box>
            <Box direction="row">
              <Text size="medium" margin={{ right: 'spacing4' }}>{t('Time Horizon:')}</Text>
              <Text size="medium" weight="bold">{timeHorizon}</Text>
            </Box>
          </Box>
        )
      }
    </Wrapper>
  );
};
export default ExpensesBreakdown;
