import { storeCreator } from '@/utils/store/store.utils';

interface State {
  modalVisible: boolean;
  handleChangeVisibility: (value: boolean) => void;
  additionalAction?: () => void;
  setAdditionalAction: (value: () => void) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  additionalAction: undefined,

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  setAdditionalAction: (value) => set((state) => ({
    ...state,
    additionalAction: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    modalVisible: false,
    additionalAction: undefined,
  })),
}));
