import React, { useEffect, useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, 
  Inscription, 
  QRCodeIcon,
  ThumbnailButton,
} from '@common-fe/common-fe';

import { Portal } from '@/components';
import { ModalWrapper } from '@/components/wrappers';
import useFilesStore from '@/modules/transaction/components/ReimburseMe/store/useFiles.store';
import UploadFileQRCode from '@/modules/transaction/components/ReimburseMe/UploadFileQRCode/UploadFileByQRCode';

const UploadFileQRCodeModal = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { files } = useFilesStore((state) => state);

  const numberOfFiles = useMemo(() => files.length, [files]);

  useEffect(() => {
    setVisible(false);
  }, [numberOfFiles]);

  return (
    <>
      <ThumbnailButton
        icon={<QRCodeIcon />}
        label={(
          <>
            {t('Add')} <br /> {t('a File')}
          </>
        )}
        onClick={() => setVisible(true)}
        testId="qr-code-modal"
      />
      {
        visible && (
  
          <Portal id="modal">
            <ModalWrapper
              visible={visible}
              onSetVisible={setVisible}
              testId="qr-code-modal"
            >
              <Box align="center">
                <Inscription size="2xl" weight={700}>
                  {t('Scan QR Code to Upload File')}
                </Inscription>
              </Box>

              <Box align="center" margin={{ top: 'spacing12' }}>
                <Inscription size="large" textAlign="center">
                  {t('Effortlessly upload files by simply scanning the QR')}
                  <br />
                  {t('code with your device\'s camera.')}
                </Inscription>
              </Box>

              <Box
                margin={{ top: 'spacing24' }}
                background="module"
                round="moduleRound"
                pad={{ vertical: 'spacing32', horizontal: 'spacing24' }}
                justify="center"
                align="center"
              >
                <Box
                  round="container1Round"
                  background="canvas"
                  border={{ size: 'small', color: 'border2' }}
                  pad="xl"
                >
                  <UploadFileQRCode size={280} logoSize={80} imagePadding={20} />
                </Box>
              </Box>
            </ModalWrapper>
          </Portal>
        )
      }
    </>
  );
};

export default UploadFileQRCodeModal;
