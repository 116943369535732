import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Anchor,
  Box,
  Checkbox,
  Image,
  NewTabIcon,
  NullIcon,
  Text,
} from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

import { useGetFundIcon } from '@/modules/investments/hooks';
import { InvestmentHolding, InvestmentHoldingStatus } from '@/modules/investments/investments.types';
import FundAllocationInput from '@/modules/investments/SelfDirected/SelfDirectedAllocation/FundAllocationInput';

interface WrapperProps {
  isHighlighted?: boolean;
}
const Wrapper = styled(Box)<WrapperProps>`
  ${({ theme }) => theme.shadows.default};
  transition: all 0.25s;
  &:hover {
    ${({ theme }) => theme.shadows.hover};
  }
  ${({ isHighlighted }) => {
    if(isHighlighted) {
      return css`
      animation-name: showHighlight;
        animation-duration: 3.5s;
        @keyframes showHighlight {
          0% {
            border: 2px solid ${ ({ theme }) => theme.colors.accentActive};
          }
          75% {
            border: 2px solid ${ ({ theme }) => theme.colors.accentActive};
          }
          100% {
            border-color: 1px solid ${({ theme }) => theme.colors.border1};
          }
        }
      `;
    }

    return css``;
  } }
`;

interface Props extends InvestmentHolding {
  checked?: boolean;
  onSelect: (value: string) => void;
  disabled?: boolean;
  isOnlyOneAvailable?: boolean;
  value: number;
  defaultValue?: number;
  onChangeValue: (value: number) => void;
}

const AutoSelfDirectedAllocationItem: React.FC<Props> = ({
  value,
  onChangeValue,
  disabled,
  image,
  name,
  researchURL,
  isOnlyOneAvailable,
  onSelect,
  symbol,
  checked,
  id,
  status,
  isHighlighted,
  defaultValue
}) => {
  const { t } = useTranslation();
  const handleGetFundIcon = useGetFundIcon(); 

  const isDelisted = useMemo(() => {
    return status === InvestmentHoldingStatus.Inactive;
  }, [status]);

  return (
    <Wrapper
      isHighlighted={isHighlighted}
      data-testid="fund-item"
      direction="column"
      pad={{ vertical: '17px', horizontal: 'l' }}
      background="canvas"
      round="container2Round"
      border={{ size: 'small', color: 'border1' }}
      margin={{ top: 'xs' }}
    >
      <Box direction="row" align="center">

        <Box width={{ max: '24px'}}>
          <Checkbox testId="fund-item"
            name="select"
            checked={checked}
            onChange={() => {
              
              onSelect(id as string);
            }} />
        </Box>
        <Box margin={{ left: 's' }}>
          
          {handleGetFundIcon({
            image: image,
            status,
            message: t('Additional purchases cannot be made for the selected fund.')
          })}
         
        </Box>
        <Box
          margin={{ left: 's' }}
          style={{
            flex: 2,
            position: 'relative',
          }}
          direction="column"
        >
          <Box width="100%">
            <Text tip={name} truncate weight={700}>
              {name}
            </Text>
            <Text>{symbol}</Text>
          </Box>
        </Box>
        <Box
          margin={{ left: 'l' }}
          style={{
            width: '24px',
          }}
          direction="column"
        >
          {researchURL ? (
            <Anchor target="_blank" href={researchURL}>
              <Box align="center">
                <NewTabIcon color="iconSecondary" size="26px" />
              </Box>
            </Anchor>
          ) : (
            <Box width="26px" />
          )}
        </Box>

        <Box margin={{ left: 'l' }}>
          <FundAllocationInput 
            isMaxLimited={isDelisted}
            maxValue={defaultValue}
            isOnlyOneAvailable={isOnlyOneAvailable}
            disabled={disabled}
            value={value}
            onChange={onChangeValue} />
        </Box>
      </Box>

    </Wrapper>
  );
};
export default React.memo(AutoSelfDirectedAllocationItem);