import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, CheckBox, Inscription, Modal, RoundDoneIcon,
  SuccessModal,   Text, } from '@common-fe/common-fe';
import _ from 'lodash';

import { ALLOWED_COUNTRIES } from '@/common/constants';
import { CustomFieldError } from '@/common/types';
import AppButton from '@/components/controls/AppButton';
import { StatusWrapper } from '@/modules/core/components';

import { useEmployeeData } from './PersonalInfoForm/hooks';
import usePersonalInfoValidators from './PersonalInfoForm/hooks/usePersonalInfoValidators';
import { usePersonalInfoStore } from './PersonalInfoForm/stores';
import ChangeCitizenshipBlock from './ChangeCitizenshipBlock';
import NotAllowedCitizenship from './NotAllowedCitizenship';
import PersonalInfoForm from './PersonalInfoForm';
import { useSavePersonalInfoQuery } from './queries';

interface Props {
  onSubmit: () => void;
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onRejectByCitizenship: () => void;
  onResetRejectByCitizenship: () => void;
}

const PHONE_NUMBER_NOT_CORRECT = 'Phone number format is incorrect';
const PersonalInfoModal: React.FC<Props> = ({onResetRejectByCitizenship,
  onSubmit, visible, onSetVisible, onRejectByCitizenship,
}) => {
  const [isShowChangeCitizenship, setIsShowChangeCitizenship] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [customFieldErorrs, setCustomFieldErrors] = useState<CustomFieldError>({});
  const [notAllowedCitizenshipMode, setNotAllowedCitizenshipMode] = useState(false);
  
  const { t } = useTranslation();
  const {  citizenShipId } = useEmployeeData();
  const { handleSave, isLoading } = useSavePersonalInfoQuery();

  const currentState = usePersonalInfoStore((state) => state.state);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isErrorShowed, setIsErrorShowed] = useState(false);
  const { validate } = usePersonalInfoValidators();
  const handleSubmit = useCallback(async () => {
    setIsShowChangeCitizenship(false); 
    onResetRejectByCitizenship();
    setIsErrorShowed(true);
    if (isAccepted) {
      try {
        await validate(currentState);
        const isAllowedCountry = _.get(ALLOWED_COUNTRIES, `[${currentState.citizenship}]`) || _.get(ALLOWED_COUNTRIES, `[${citizenShipId}]`);

        if (isAllowedCountry) {
          await handleSave(currentState);
          onSetVisible(false);
          setSuccessVisible(true);
        } else {
          onRejectByCitizenship();
          onSetVisible(false);
          setNotAllowedCitizenshipMode(true);
        }
      } catch (e) {
        const messages = _.get(e, 'response.data.elevate_error_meta_info.invalid_fields') as  unknown as  object[];
        messages.forEach((message) => {
          const currentMessage = _.get(message, 'elevate_error_message', '') as string;

          if (currentMessage.includes(PHONE_NUMBER_NOT_CORRECT)) {
            setCustomFieldErrors({
              phone: {
                value: currentState.phone || '',
                message: t('Phone number is not valid'),
              },
            });
          }
        });
      }
    }
  }, [citizenShipId, currentState, handleSave,
    isAccepted, onRejectByCitizenship,
    onResetRejectByCitizenship, onSetVisible, t, validate]);

  const handleCloseSuccess = useCallback(() => {
    setSuccessVisible(false);
    onSetVisible(false);
    // onSubmit();
  }, [onSetVisible]);
  const handeCloseNotAllowedCitizenship = useCallback(() => {
    setNotAllowedCitizenshipMode(false);
    setIsShowChangeCitizenship(true);
  },[]);
  const handleOpen = useCallback(() => {
    onSetVisible(true);
    setIsErrorShowed(false);
  }, [onSetVisible]);
  if(isLoading) {
    return null;
  }
  return (
    <>
      {isShowChangeCitizenship && (
        <ChangeCitizenshipBlock onOpen={handleOpen} />
      )}
      
      <SuccessModal
        icon={(
          <RoundDoneIcon />
        )}
        header={t('You’re all set!')}
        helptext={t('Now you\'re eligible to start investing!')}
        visible={successVisible}
        onSetVisible={handleCloseSuccess}
        buttonText={t('Got It!')}
        buttonStyle={{ width: '200px' }}
        testId="go-success_modal_wrapper"
      />
      <Box>
        <NotAllowedCitizenship
          visible={notAllowedCitizenshipMode}
          onClose={handeCloseNotAllowedCitizenship}
        />
        <Modal
          visible={successVisible ? false: visible}
          onSetVisible={onSetVisible}
        >
          <Text
            weight="bold"
            textAlign="center"
            size="24px"
          // margin={{ top: 'spacing32', horizontal: 'spacing48' }}
          >
            {t('Lets Get Started')}
          </Text>
          <Box
            direction="column"
            background="module"
            elevation="default"
            pad="l"
            round="moduleRound"
            margin={{ top: 'l' }}
          >
            <PersonalInfoForm
              customFieldErorrs={customFieldErorrs}
              isErrorShowed={isErrorShowed}
            />
            <Box margin={{ top: 's' }}>
              <StatusWrapper status="pending">
                <Box
                  background="canvas"
                  elevation="default"
                  pad="s"
                  round="moduleRound"
                >
                  <Box
                    round="container1Round"
                    direction="row"
                    background="warningContainer"
                    border={{ color: 'warningBorder' }}
                    pad={{ vertical: 'm', horizontal: 'l' }}
                  >

                    <CheckBox
                      id="submitReguest"
                      className="checkbox-item"
                      checked={isAccepted}
                      name={t('Enable notifications')}
                      onChange={() => setIsAccepted(!isAccepted)}
                      // error={!isAccepted && isErrorShowed}
                      label={(
                        <Inscription size="medium" color="textBody" lineHeight="22px">
                          {t('I certify that the information submitted is accurate and complete')}
                        </Inscription>
                      )}
                    />

                  </Box>
                </Box>
              </StatusWrapper>
            </Box>
          </Box>
          <Box direction="column">
            {isErrorShowed && !isAccepted && (
              <Box direction="column" align="end" margin={{ bottom: 'spacing24' }} fill>
                <Text color="danger">
                  {t('Confirm that your data is correct to continue')}
                </Text>
              </Box>
            )}

            <Box direction="row" justify="end">
              <Box margin={{ right: 's' }}>
                <AppButton onClick={() => onSetVisible(false)} buttonType="secondary" width="control">
                  Cancel
                </AppButton>
              </Box>
              <AppButton onClick={handleSubmit} width="control">
                Next
              </AppButton>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default PersonalInfoModal;
