import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
// import { useCurrentOrganisationStore } from '../../store';

const StylessLink = styled(RouterLink)`
  color: ${({ theme }) => theme.colors.buttonText || 'inherit'};
  text-decoration: none;
  font-size: inherit;
`;
interface Props {
  children: React.ReactNode;
  to: string;
}
export const ORGANIZATION_QUERY_KEY = 'organization_id';
export const QUERY_KEY = '?';

// const { organizationId } = useCurrentOrganisationStore();
// const formatedLink = useMemo(() => {
//   let currentPath = to;
//   if (!currentPath.includes(ORGANIZATION_QUERY_KEY)) {
//     if (organizationId) {
//       if (currentPath.includes(QUERY_KEY)) {
//         currentPath.replace(QUERY_KEY, `${QUERY_KEY}${ORGANIZATION_QUERY_KEY}=${organizationId}`);
//       } else {
//         currentPath = `${currentPath}${QUERY_KEY}${ORGANIZATION_QUERY_KEY}=${organizationId}`;
//       }
//     }
//   }
//   return currentPath;
// }, [organizationId, to]);
const Link: React.FC<Props> = ({ children, to }) => (
  <StylessLink to={to}>
    {children}
  </StylessLink>
);
export default Link;
