import { TransactionApprovalCode } from '@/modules/transaction/transaction.types';

export interface RecommendedValue {
  confidence?: string;
  value?: string;
}

export interface ServiceGroupPayload {
  service_date?: RecommendedValue,
  services?: RecommendedValue[],
}

export interface ServiceGroup {
  serviceDate?: RecommendedValue,
  services?: RecommendedValue[],
}

export interface DuplicateClaimPayload {
  duplicate_claim_id?: number;
  claim_status_type?: TransactionApprovalCode;
}

export interface DuplicateClaim {
  duplicateClaimId?: string;
  claimStatusType?: TransactionApprovalCode;
}

export enum OcrWarningType {
  MISSING = 'MISSING',
  OUT_OF_COVERAGE_DATE = 'OUT_OF_COVERAGE_DATE',
  RETAIL_OUT_OF_COVERAGE_DATE = 'RETAIL_OUT_OF_COVERAGE_DATE',
  DUPLICATE_RECEIPT = 'DUPLICATE_RECEIPT',
}

export type ClaimWarningField = {
  fieldName : string;
  fieldLabel : string;
};

export const OcrWarningFields: { [key: string]: ClaimWarningField } = {
  provider_id: {
    fieldName: 'provider',
    fieldLabel: 'Provider/Merchant',
  },
  service_for: {
    fieldName: 'patient',
    fieldLabel: 'Family member',
  },
  service_on: {
    fieldName: 'serviceDate',
    fieldLabel: 'Date of service',
  },
  requested_amount: {
    fieldName: 'amount',
    fieldLabel: 'Amount',
  },
  service_id: {
    fieldName: 'service',
    fieldLabel: 'Service description',
  },
};

export interface ParsedOcrWarnings {
  warning?: string;
  fields?: string[];
  type?: OcrWarningType;
  duplicate_document_id?: number;
  duplicate_claim?: DuplicateClaimPayload;
}

export interface OcrWarning {
  warning?: string;
  fields?: string[];
  type?: OcrWarningType;
  duplicateDocumentId?: string;
  duplicateClaim?: DuplicateClaim;
}

export interface OcrWarnings {
  [OcrWarningType.MISSING]: ClaimWarningField[];
  [OcrWarningType.OUT_OF_COVERAGE_DATE]: ClaimWarningField[];
  [OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE]: ClaimWarningField[];
  [OcrWarningType.DUPLICATE_RECEIPT]: ClaimWarningField[];
}

export interface EligibleExpensePayload {
  patient: string,
  service: string,
  amount: number,
  service_dates: string[],
}

export interface EligibleExpense {
  patient: string,
  service: string,
  amount: string,
  serviceDates: string[],
}

export interface ExpensePayload {
  patient?: string;
  service?: string;
  category?: string;
  amount?: string;
  discount?: string;
  expense?: string;
  number?: string;
}

export interface Expense {
  patient?: string;
  service?: string;
  category?: string;
  amount?: string;
  discount?: string;
  expense?: string;
  number?: string;
}

export interface ParsedFileByOcrPayload {
  ocr_receipt_id?: number;
  address: {
    tax_id?: string;
    address_dto?: {
      address_line_1?: string;
      address_line_2?: string;
      city?: string;
      state?: string;
      zip?: string;
    };
  };
  facility_names?: RecommendedValue[],
  patients?: RecommendedValue[],
  amounts?: RecommendedValue[],
  service_dates?: RecommendedValue[],
  service_groups?: ServiceGroupPayload[],
  service_descriptions?: RecommendedValue[],
  accounts?: RecommendedValue[],
  invoices?: RecommendedValue[],
  warnings?: ParsedOcrWarnings[],
  eligible_expenses?: EligibleExpensePayload[],
  expenses?: ExpensePayload[];
}

export interface TransactionParsedFileByOcrPayload {
  ai_approved?: boolean;
  warnings?: ParsedOcrWarnings[],
}

export enum NonCleanableFields {
  providerName = 'providerName',
  facilityNames = 'facilityNames',
  patientName = 'patientName',
  patients = 'patients',
  serviceDate = 'serviceDate',
  serviceDates = 'serviceDates',
  serviceGroups = 'serviceGroups',
  amount = 'amount',
  amounts = 'amounts',
  serviceDescription = 'serviceDescription',
  serviceDescriptions = 'serviceDescriptions',
  accountId = 'accountId',
  invoiceId = 'invoiceId',
}

export interface ParsedFileByOcr {
  ocrReceiptId?: string | null,
  [NonCleanableFields.providerName]?: string,
  [NonCleanableFields.patientName]?: string,
  [NonCleanableFields.serviceDate]?: string,
  [NonCleanableFields.amount]?: string,
  [NonCleanableFields.serviceDescription]?: string,
  zip?: string,
  address1?: string,
  address2?: string,
  city?: string,
  state?: string,
  [NonCleanableFields.facilityNames]?: RecommendedValue[],
  [NonCleanableFields.patients]?: RecommendedValue[],
  [NonCleanableFields.amounts]?: RecommendedValue[],
  [NonCleanableFields.serviceDates]?: RecommendedValue[],
  [NonCleanableFields.serviceDescriptions]?: RecommendedValue[],
  [NonCleanableFields.serviceGroups]?: ServiceGroup[],
  [NonCleanableFields.accountId]?: string,
  [NonCleanableFields.invoiceId]?: string,
  warnings?: OcrWarning[],
  warningsFields?: OcrWarnings,
  eligibleExpenseServices?: EligibleExpense[],
  expenses?: Expense[];
}

export interface TransactionParsedFileByOcr {
  aiApproved?: boolean,
  warningsFields?: OcrWarnings,
}
