import React from 'react';
import { Text,ToggleButton } from '@common-fe/common-fe';

import { InvestmentsType } from '@/common/types';

interface Props {
  onClick: (name: InvestmentsType) => void;
  name: InvestmentsType;
  currTab: InvestmentsType;
  text: string
}

const SelectButton: React.FC<Props> = ({
  name, currTab, text, onClick,
}) => (

  <ToggleButton
    active={currTab === name}
    onClick={() => onClick(name)}
  >
    <Text weight={currTab === name ? 700 : 400}>
      {text}
    </Text>
  </ToggleButton>

);

export default SelectButton;
