import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { WarnModal } from '@common-fe/common-fe';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const TransactionDocumentSuccessfullyUploadedPopup: React.FC<Props> = ({ onSetVisible }) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  return (
    <WarnModal
      visible
      onSetVisible={onSetVisible}
      header={t('Your document has been uploaded successfully')}
      helptext={t('You can check on the status of this request under account activity.')}
      submitButtonText={t('Go to Expense Details')}
      onSubmit={handleClose}
      isPendingIcon
      paddingless
    />
  );
};

export default TransactionDocumentSuccessfullyUploadedPopup;
