import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ROUTES } from '@/common';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { useInvestor } from '@/modules/investments/hooks';
import Questionnaire from '@/modules/investments/SelfDirected/Questionnaire';
import { useMyEnrollments } from '@/modules/transaction';


const SelfDirectedQuestionnairePage = () =>{ 
  const { t } = useTranslation();
  const { accountId = '' } = useParams<{ accountId: string }>();
  const { currentMyAccount } = useMyEnrollments(accountId);
  const { data: {  investorId } } = useInvestor(accountId);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id),
    title: currentMyAccount?.accountType,
    value: ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id),
  },
  {
    key: ROUTES.START_INVESTING,
    title: t('Start Investing'),
    value: ROUTES.START_INVESTING,
  }], true);
  if (!accountId || !investorId) {
    return null;
  }
  return (
    <Questionnaire accountId={accountId} investorId={`${investorId || ''}`} />
  );
};

export default SelfDirectedQuestionnairePage;