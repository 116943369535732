import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { RAW_DATE_FORMAT } from '@/common/constants';
import { TransactionData, TransactionStatus } from '@/modules/transaction/transaction.types';

import ClaimTransferMessage from '../ClaimTransferMessage';

import ClaimNoteMessage, { hasClaimNoteMessage } from './ClaimNoteMessage/ClaimNoteMessage';
import DeclineReasonMessage, { hasDeclineReasonMessage } from './DeclineReasonMessage/DeclineReasonMessage';
import DeniedNotificationMessage, { hasDeniedNotificationMessage } from './DeniedNotificationMessage/DeniedNotificationMessage';
import NeedHelpsNotificationMessage, { hasNeedHelpsNotificationMessage } from './NeedHelpsNotificationMessage/NeedHelpsNotificationMessage';
import OffsetRepayment from './OffsetRepayment/OffsetRepayment';
import PartiallyPaidNotificationMessage, { hasPartiallyPaidNotification } from './PartiallyPaidNotificationMessage/PartiallyPaidNotificationMessage';
import PostingMessage, { hasPostingMessage } from './PostingMessage/PostingMessage';
import SuccessStatusMessage from './SuccessStatusMessage/SuccessStatusMessage';
import WaitingForFundsNotificationMessage, { hasWaitingForFundsMessage } from './WaitingForFundsNotificationMessage/WaitingForFundsNotificationMessage';

interface Message {
  date: string;
  message: React.ReactNode;
}

const formatedDate = (date: string) => dayjs(date).format(RAW_DATE_FORMAT);

interface Props {
  data: TransactionData;
  employeeFirstName?: string;
  uploadDocumentButton?: React.ReactNode;
}

const useClaimActivitiesMessages = (props: Props) => {
  const { data, employeeFirstName, uploadDocumentButton } = props;
  const actualStatus = data?.statuses?.[0];
  const actualNotification = data?.notifications?.[0];

  const postingsMessages = useMemo(() => data?.postings?.map((item) => ({
    date: item.date,
    message: hasPostingMessage(item) ? <PostingMessage posting={item} /> : null,
  }))?.filter((item) => item?.message) || [], [data]);
  const claimTransfersMessages = useMemo(() => data?.claimTransfers?.map((item) => ({
    date: item.date,
    message: <ClaimTransferMessage claimTransfer={item} />,
  }))?.filter((item) => item?.message) || [], [data]);
  const claimNotesMessages = useMemo(() => data?.claimNoteList?.map((item) => ({
    date: item.date,
    message: hasClaimNoteMessage(item) ? <ClaimNoteMessage data={item} /> : null,
  }))?.filter((item) => item?.message) || [], [data]);
  const offsetRepayments = useMemo(() => data?.claimOffsetDonors?.map((item) => ({
    date: item.date,
    message: <OffsetRepayment data={item} />,
  })) || [], [data]);
  const declineReasonMessages = useMemo(() => data?.declineReasonTypes?.map((item) => ({
    date: item.date,
    message: hasDeclineReasonMessage(item) ? <DeclineReasonMessage data={item} serviceDate={data.serviceOn} /> : null,
  }))?.filter((item) => item?.message) || [], [data]);
  const successStautseMessages = useMemo(() => ([
    ...data?.statuses?.filter((item) => item?.status === TransactionStatus.AppliedToDeductible)?.slice(-1) || [],
    ...data?.statuses?.filter((item) => item?.status === TransactionStatus.OutOfPocket) || [],
    ...actualStatus?.status === TransactionStatus.Paid ? [actualStatus] : [],
  ].map((item) => ({
    date: item.date,
    message: <SuccessStatusMessage data={item} employeeFirstName={employeeFirstName} />,
  }))?.filter((item) => item?.message)), [data, employeeFirstName, actualStatus]);
  const partiallyPaidNotificationMessages = useMemo(() => actualStatus?.status === TransactionStatus.PartiallyPaid ? [{
    date: actualStatus.date,
    message: hasPartiallyPaidNotification(actualNotification) ? (
      <PartiallyPaidNotificationMessage
        key={employeeFirstName}
        data={actualNotification}
        employeeFirstName={employeeFirstName}
        paymentType={data?.paymentType}
        service={data?.service}
        serviceOn={data?.serviceOn}
      />
    ) : null,
  }]?.filter((item) => item?.message) : [], [data, actualStatus, actualNotification, employeeFirstName]);
  const deniedNotificationMessages = useMemo(() => actualStatus?.status === TransactionStatus.Denied ? [{
    date: actualStatus.date,
    message: hasDeniedNotificationMessage(actualNotification) ? (
      <DeniedNotificationMessage
        data={actualNotification}
        employeeFirstName={employeeFirstName}
        paymentType={data?.paymentType}
        serviceDate={data?.serviceOn}
        serviceName={data?.service?.name}
      />
    ) : null,
  }]?.filter((item) => item?.message) : [], [data, actualStatus, actualNotification, employeeFirstName]);
  const needHelpsNotificationMessages = useMemo(() => actualStatus?.status === TransactionStatus.NeedsHelp ? [{
    date: actualStatus.date,
    message: hasNeedHelpsNotificationMessage(actualNotification) ? (
      <NeedHelpsNotificationMessage
        data={actualNotification}
        employeeFirstName={employeeFirstName}
        uploadDocumentButton={uploadDocumentButton}
        paymentType={data?.paymentType}
        service={data?.service}
        serviceOn={data?.serviceOn}
      />
    ) : null,
  }]?.filter((item) => item?.message) : [], [data, actualStatus, actualNotification, employeeFirstName, uploadDocumentButton]);
  const waitingForFundsNotificationMessages = useMemo(() => actualStatus?.status === TransactionStatus.WaitingForFunds ? [{
    date: actualStatus.date,
    message: typeof data?.paidAmount === 'number'
      && typeof data?.approvedAmount === 'number'
      && hasWaitingForFundsMessage({
        data: actualNotification,
        paidAmount: data?.paidAmount,
        amount: data?.amount,
        approvedAmount: data?.approvedAmount,
      }) ? (
        <WaitingForFundsNotificationMessage
          data={actualNotification}
          employeeFirstName={employeeFirstName}
          serviceName={data?.service?.name}
          amount={data?.amount}
          paidAmount={data?.paidAmount}
          approvedAmount={data?.approvedAmount}
        />
      ) : null,
  }]?.filter((item) => item?.message) : [], [actualStatus, actualNotification, employeeFirstName, data]);

  const allActivitiesCount = useMemo(() => postingsMessages?.length
    + claimTransfersMessages?.length
    + claimNotesMessages?.length
    + offsetRepayments?.length
    + declineReasonMessages?.length
    + successStautseMessages?.length
    + partiallyPaidNotificationMessages?.length
    + deniedNotificationMessages?.length
    + needHelpsNotificationMessages?.length
    + waitingForFundsNotificationMessages?.length, [
    postingsMessages,
    claimTransfersMessages,
    claimNotesMessages,
    offsetRepayments,
    declineReasonMessages,
    successStautseMessages,
    partiallyPaidNotificationMessages,
    deniedNotificationMessages,
    needHelpsNotificationMessages,
    waitingForFundsNotificationMessages,
  ]);

  const dates = useMemo(() => [...new Set([
    ...new Set(postingsMessages.map((item) => formatedDate(item.date))),
    ...new Set(claimTransfersMessages.map((item) => formatedDate(item.date))),
    ...new Set(claimNotesMessages.map((item) => formatedDate(item.date))),
    ...new Set(offsetRepayments?.map((item) => formatedDate(item.date))),
    ...new Set(declineReasonMessages.map((item) => formatedDate(item.date))),
    ...new Set(successStautseMessages.map((item) => formatedDate(item.date))),
    ...new Set(partiallyPaidNotificationMessages.map((item) => formatedDate(item.date))),
    ...new Set(deniedNotificationMessages.map((item) => formatedDate(item.date))),
    ...new Set(needHelpsNotificationMessages.map((item) => formatedDate(item.date))),
    ...new Set(waitingForFundsNotificationMessages.map((item) => formatedDate(item.date))),
  ])].sort((a, b) => dayjs(b).valueOf() - dayjs(a).valueOf()), [
    postingsMessages,
    claimTransfersMessages,
    claimNotesMessages,
    offsetRepayments,
    declineReasonMessages,
    successStautseMessages,
    partiallyPaidNotificationMessages,
    deniedNotificationMessages,
    needHelpsNotificationMessages,
    waitingForFundsNotificationMessages,
  ]);

  const messagesSortedByDate = useMemo(() => dates.map((date) => ({
    date,
    messages: ([
      ...postingsMessages.filter((item) => formatedDate(item.date) === date),
      ...claimTransfersMessages.filter((item) => formatedDate(item.date) === date),
      ...claimNotesMessages.filter((item) => formatedDate(item.date) === date),
      ...offsetRepayments?.filter((item) => formatedDate(item.date) === date) || [],
      ...declineReasonMessages.filter((item) => formatedDate(item.date) === date),
      ...successStautseMessages.filter((item) => formatedDate(item.date) === date),
      ...partiallyPaidNotificationMessages.filter((item) => formatedDate(item.date) === date),
      ...deniedNotificationMessages.filter((item) => formatedDate(item.date) === date),
      ...needHelpsNotificationMessages.filter((item) => formatedDate(item.date) === date),
      ...waitingForFundsNotificationMessages.filter((item) => formatedDate(item.date) === date),
    ] as Message[]).sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf()),
  })), [
    dates,
    postingsMessages,
    claimTransfersMessages,
    claimNotesMessages,
    offsetRepayments,
    declineReasonMessages,
    successStautseMessages,
    partiallyPaidNotificationMessages,
    deniedNotificationMessages,
    needHelpsNotificationMessages,
    waitingForFundsNotificationMessages,
  ]);

  return { dates, messagesSortedByDate, allActivitiesCount };
};

export default useClaimActivitiesMessages;
