import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import regexp from '@/common/regexp';
import { capitalizeFirstLetter } from '@/utils';

import { ContributionHistoryCategoryDto, RecurringContribution, RecurringContributionDto, RecurringContributionFrequency,  } from '../contribution.types';

export const getNextMonthSameDay = (inputDate: string) => {
  const currentDate = dayjs().toDate();
  const inputDateParts = inputDate.split('/');
  const inputMonth = parseInt(inputDateParts[0], 10) - 1; // Months are zero-based in JavaScript
  const inputDay = parseInt(inputDateParts[1], 10);
  const inputYear = parseInt(inputDateParts[2], 10);

  const inputDateTime = new Date(inputYear, inputMonth, inputDay);

  if (currentDate < inputDateTime) {
    return inputDate;
  }

  let nextMonth = currentDate.getMonth() + 1;
  let nextYear = currentDate.getFullYear();

  if (nextMonth > 11) {
    nextMonth = 0;
    nextYear += 1;
  }

  let nextSameDay = new Date(nextYear, nextMonth, inputDay);

  if (nextSameDay <= currentDate) {
    nextMonth += 1;
    if (nextMonth > 11) {
      nextMonth = 0;
      nextYear += 1;
    }
    nextSameDay = new Date(nextYear, nextMonth, inputDay);
  }

  const formattedResult = `${(nextSameDay.getMonth() + 1).toString().padStart(2, '0')}/${inputDay.toString().padStart(2, '0')}/${nextSameDay.getFullYear()}`;
  return formattedResult;
};

export interface RecurringContributionResponse {
  is_with_catch_up_age?: boolean;
  recurring_contribution_dto: RecurringContributionDto;
  irs_limits: ContributionHistoryCategoryDto[];
}

const QUERY_KEY = 'getRecurringContributionsQueryKey';

interface Params {
  accountId: number | string;
  personId: number | string;
  taxYear: number | string;
}

export const useGetRecurringContributionsQuery = (queryParams: Params) => {
  const { accountId, personId, taxYear } = queryParams;
  const params = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    if (personId) queryGenerator.set('person_id', `${personId}`);
    if (taxYear) queryGenerator.set('tax_year', `${taxYear}`);
    if (accountId) queryGenerator.set('account_id', `${accountId}`);

    return queryGenerator;
  }, [accountId, personId, taxYear]);

  const {
    isLoading, isSuccess, isError, data, refetch,
  } = useQuery(
    [QUERY_KEY, queryParams],
    accountId && personId
      ? () => api.get<RecurringContributionResponse>(PATHS.ADD_RECURRING_CONTRIBUTION, { params })
      : () => null,
    {
      retry: false,
    }
  );

  const recurringContribution = useMemo(() => {
    const payload = data?.data?.recurring_contribution_dto;

    if (!payload) return undefined;
    if (isError) return undefined;

    const startDate = dayjs(payload?.start_date).format(DEFAULT_DATE_FORMAT);
    const endDate = payload?.end_date ? dayjs(payload?.end_date).format(DEFAULT_DATE_FORMAT) : undefined;
    const frequency = capitalizeFirstLetter(payload?.frequency_type || '').replace(regexp.DASH_SYMBOL, '-');
    const nextScheduledDate = dayjs(payload?.next_scheduled_date).format(DEFAULT_DATE_FORMAT);

    return {
      id: payload?.id,
      amount: payload?.amount,
      frequency,
      startDate,
      endDate,
      status: capitalizeFirstLetter(payload?.status || ''),
      nextScheduledDate,
      monthlyDay: frequency === RecurringContributionFrequency.MONTHLY ? payload?.recurring_day_number : undefined,
      weeklyDay: frequency !== RecurringContributionFrequency.MONTHLY ? `${payload?.recurring_day_number}` : undefined,
      paymentMethodId: payload?.payment_method_id,
    } as RecurringContribution;
  }, [data, isError]);

  return {
    data: data?.data,
    recurringContribution,
    refetch,
    isError,
    isLoading,
    isSuccess,
  };
};
