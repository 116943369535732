import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { AutoInvestmentRules } from '@/modules/investments/SelfDirected/SelfDirected.types';

import { IS_AUTO_QUERY_KEY } from '../../queries/useAutoInvestment.query';

interface Payload {
  amountToKeep: number;
  investorAccountId?: number;
  autoInvestmentRules?: AutoInvestmentRules[];
  status?: string;
  schedulerId?: string;
}
interface Params {
  isEditMode?: boolean;
  investmentId?: string;
  // status?: string;
}
export default (params?: Params) => {
  const queryClient = useQueryClient();
  const {
    isLoading, mutateAsync, isError, isSuccess,
  } = useMutation(
    (payload: Payload) => (params?.isEditMode
      ? api.put(`${PATHS.INVESTMENT_SCHEDULERS}`, {
        id: payload?.schedulerId,
        amount_to_keep: payload.amountToKeep,
        ...payload.autoInvestmentRules
          ? {
            auto_investment_rules: payload.autoInvestmentRules.filter((item) => item.percentage),
          } : {},
        status: payload.status,
      })
      : api.post(`${PATHS.INVESTMENT_SCHEDULERS}`, {
        investor_account_id: payload.investorAccountId,
        amount_to_keep: payload.amountToKeep,
        ...payload.autoInvestmentRules
          ? {
            auto_investment_rules: payload.autoInvestmentRules.filter((item) => item.percentage),
          } : {},
      })),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(IS_AUTO_QUERY_KEY);
      },
    },
  );
  return {
    isLoading,
    mutateAsync,
    isError,
    isSuccess,
  };
};
