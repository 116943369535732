import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RiskControlOptions } from '@/common/constants';
import { QUERY_KEY } from '@/modules/investments/queries/useInvestor.query';
interface Params {
  onSuccess?: () => void;
  onError?: () => void;
}
const useInvestmentApproveQuery = (params?: Params) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (toleranceModel?: RiskControlOptions) =>
      api.patch(PATHS.INVESTMENTS_FLOW_APPROVE, undefined, {
        params: _.pickBy({
          model: toleranceModel,
        }),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
        if (params?.onSuccess) {
          params.onSuccess();
        }
      },
      onError: params?.onError,
    }
  );
  return {
    handleSave: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useInvestmentApproveQuery;
