import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.popup};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: ${({ theme }) => theme.spacings.spacing48};
  padding-bottom: ${({ theme }) => theme.spacings.spacing48};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;  
`;

export const Content = styled.div`
  max-width: 672px;
  margin: auto;
  width: 100%;
  vertical-align: middle;

  background-color: ${({ theme }) => theme.colors.canvas};
  padding: ${({ theme }) => theme.spacings.spacing24};
  position: relative;
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
`;
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseIcon = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 18px;
  top: 18px;
  padding: 5px;
`;
