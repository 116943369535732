import { useCallback, useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';
import _ from 'lodash';

import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useInvestmentExperienceTypeFields } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/InvestmentExperienceType/hooks';
import { useInvestmentObjectivesTypeFields } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/InvestmentObjectivesType/hooks';
import { useMedicalExpensesCoverTypeFields } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/MedicalExpensesCoverType/hooks';
import { usePurposeTypeFields } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/PurposeType/hooks';
import { useRiskToleranceTypeFields } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/RiskToleranceType/hooks';
import { useStore as useInvestmentProfileStore } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/stores/useInvestmentProfile.store';
import { useToleranceModelFields } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/ToleranceModel/hooks';
import { useAnnualIncomeFields } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/AnnualIncome/hooks';
import { useBrokerFields } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/Broker/hooks';
import { useDirectorFields } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/Director/hooks';
import { useWorkAreaFields } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/EmploymenType/hooks';
import { useNetworthTotalFields } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/NetworthTotal/hooks';
import { usePoliticallyExposedNamesFields } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/PoliticallyExposedNames/hooks';
import { useStore as usePersonalInformationStore } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/stores/usePersonalInformation.store';
import { useYourPositionFields } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/YourPosition/hooks';

import { useInvestmentsFieldsSetter } from './useInvestmentsFieldsSetter';

const getFormatedString = (value: string) => _.capitalize(_.startCase(value));

export const useSubmitQuestionnaireForm = () => {
  const { getValidateFields, getErrors } = useValidationUtils();
  const { state: personalInformationState } = usePersonalInformationStore();
  const { state: investmentProfileState } = useInvestmentProfileStore();
  const {
    handleShowErrorsPersonalInformation,
    handleShowErrorsInvestmentProfile,
  } = useInvestmentsFieldsSetter();

  const annualIncomeFields = useAnnualIncomeFields();
  const brokerFields = useBrokerFields();
  const directorFields = useDirectorFields();
  const workAreaFields = useWorkAreaFields();
  const networthTotalFields = useNetworthTotalFields();
  const politicallyExposedFields = usePoliticallyExposedNamesFields();
  const yourPositionFields = useYourPositionFields();
  const investmentExperienceTypeFields = useInvestmentExperienceTypeFields();
  const investmentObjectivesTypeFields = useInvestmentObjectivesTypeFields();
  const medicalExpensesCoverTypeFields = useMedicalExpensesCoverTypeFields();
  const purposeTypeFields = usePurposeTypeFields();
  const riskToleranceTypeFields = useRiskToleranceTypeFields();
  const toleranceModelFields = useToleranceModelFields();

  const annualIncomeErrors: FieldError = useMemo(
    () => getErrors(personalInformationState, getValidateFields(annualIncomeFields)),
    [getErrors, getValidateFields, annualIncomeFields, personalInformationState],
  );
  const brokerErrors: FieldError = useMemo(
    () => getErrors(personalInformationState, getValidateFields(brokerFields)),
    [getErrors, getValidateFields, brokerFields, personalInformationState],
  );
  const directorErrors: FieldError = useMemo(
    () => getErrors(personalInformationState, getValidateFields(directorFields)),
    [getErrors, getValidateFields, directorFields, personalInformationState],
  );
  const workAreaErrors: FieldError = useMemo(
    () => getErrors(personalInformationState, getValidateFields(workAreaFields)),
    [getErrors, getValidateFields, workAreaFields, personalInformationState],
  );
  const networthTotalErrors: FieldError = useMemo(
    () => getErrors(personalInformationState, getValidateFields(networthTotalFields)),
    [getErrors, getValidateFields, networthTotalFields, personalInformationState],
  );
  const politicallyExposedErrors: FieldError = useMemo(
    () => getErrors(personalInformationState, getValidateFields(politicallyExposedFields)),
    [getErrors, getValidateFields, politicallyExposedFields, personalInformationState],
  );
  const yourPositionErrors: FieldError = useMemo(
    () => getErrors(personalInformationState, getValidateFields(yourPositionFields)),
    [getErrors, getValidateFields, yourPositionFields, personalInformationState],
  );

  const investmentExperienceTypeErrors: FieldError = useMemo(
    () => getErrors(investmentProfileState, getValidateFields(investmentExperienceTypeFields)),
    [getErrors, getValidateFields, investmentExperienceTypeFields, investmentProfileState],
  );
  const investmentObjectivesTypeErrors: FieldError = useMemo(
    () => getErrors(investmentProfileState, getValidateFields(investmentObjectivesTypeFields)),
    [getErrors, getValidateFields, investmentObjectivesTypeFields, investmentProfileState],
  );
  const medicalExpensesCoverTypeErrors: FieldError = useMemo(
    () => getErrors(investmentProfileState, getValidateFields(medicalExpensesCoverTypeFields)),
    [getErrors, getValidateFields, medicalExpensesCoverTypeFields, investmentProfileState],
  );
  const purposeTypeErrors: FieldError = useMemo(
    () => getErrors(investmentProfileState, getValidateFields(purposeTypeFields)),
    [getErrors, getValidateFields, purposeTypeFields, investmentProfileState],
  );
  const riskToleranceTypeErrors: FieldError = useMemo(
    () => getErrors(investmentProfileState, getValidateFields(riskToleranceTypeFields)),
    [getErrors, getValidateFields, riskToleranceTypeFields, investmentProfileState],
  );
  const toleranceModelErrors: FieldError = useMemo(
    () => getErrors(investmentProfileState, getValidateFields(toleranceModelFields)),
    [getErrors, getValidateFields, toleranceModelFields, investmentProfileState],
  );

  const filteredErrors = useMemo(() => {
    const allErrors: FieldError = {
      ...annualIncomeErrors,
      ...brokerErrors,
      ...directorErrors,
      ...workAreaErrors,
      ...networthTotalErrors,
      ...politicallyExposedErrors,
      ...yourPositionErrors,
      ...investmentExperienceTypeErrors,
      ...investmentObjectivesTypeErrors,
      ...medicalExpensesCoverTypeErrors,
      ...purposeTypeErrors,
      ...riskToleranceTypeErrors,
      ...toleranceModelErrors,
    };
    return allErrors;
  }, [
    annualIncomeErrors,
    brokerErrors,
    directorErrors,
    workAreaErrors,
    networthTotalErrors,
    politicallyExposedErrors,
    yourPositionErrors,
    investmentExperienceTypeErrors,
    investmentObjectivesTypeErrors,
    medicalExpensesCoverTypeErrors,
    purposeTypeErrors,
    riskToleranceTypeErrors,
    toleranceModelErrors,
  ]);

  const errorsKeys = useMemo(
    () => _.map(filteredErrors, (item, key) => key), [filteredErrors],
  );

  const errorMessages = useMemo(() => {
    const list = errorsKeys.map((item) => getFormatedString(item));

    return list;
  }, [errorsKeys]);

  const handleSetSaveValidate = useCallback(
    (value: boolean) => {
      handleShowErrorsPersonalInformation(value);
      handleShowErrorsInvestmentProfile(value);
    },
    [handleShowErrorsPersonalInformation, handleShowErrorsInvestmentProfile],
  );
  return {
    setSaveValidate: handleSetSaveValidate,
    errorMessages,
  };
};
