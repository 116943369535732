import React, { useState } from 'react';
import { Box, FlexList, ListItemBase, Preloader } from '@common-fe/common-fe';

import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import useHighlightedPaymentMethodStore from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/stores/useHighlightedPaymentMethod.store';

import EditPaymentMethodModal from '../PaymentMethodModal/EditPaymentMethodModal';
import RemovePaymentMethodModal from '../PaymentMethodModal/RemovePaymentMethodModal';

import usePaymentMethodOptions from './hooks/usePaymentMethodOptions';
import usePaymentMethodsList from './hooks/usePaymentMethodsList';

const headers: ListItemBase[] = [
  {
    key: 'paymentType',
    title: 'Payment type',
    flex: 5,
  },
  { 
    key: 'additionalInfo',
    title: 'Additional info',
    flex: 6,
  },
  {
    key: 'isDefault',
    title: 'Is default?',
    flex: 4,
  },
];

interface Props {
  isFetching: boolean;
  refetchPaymentMethods: () => void;
}

const PaymentMethodsList: React.FC<Props> = ({ isFetching, refetchPaymentMethods }) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const { highlightedPaymentMethodId } = useHighlightedPaymentMethodStore();

  const paymentMethods = useStore((state) => state.methods);
  const formattedList = usePaymentMethodsList(paymentMethods, highlightedPaymentMethodId);
  const { options } = usePaymentMethodOptions({
    refetchPaymentMethods,
    handleOpenEditModal: () => setIsEditModalVisible(true),
    handleOpenRemoveModal: () => setIsRemoveModalVisible(true),
  });

  return (
    <>
      <Box margin={{ top: 'spacing16' }}>
        {isFetching && !paymentMethods.length ? (
          <Box pad={{ bottom: 'spacing24' }}>
            <Preloader />
          </Box>
        ) : (
          <FlexList
            headers={headers}
            rows={formattedList}
            total={formattedList.length}
            options={options}
            pad="0px"
          />
        )}
      </Box>
      {isEditModalVisible && (
        <EditPaymentMethodModal
          refetchPaymentMethods={refetchPaymentMethods}
          onSetVisible={setIsEditModalVisible}
        />
      )}
      {isRemoveModalVisible && (
        <RemovePaymentMethodModal
          refetchPaymentMethods={refetchPaymentMethods}
          onSetVisible={setIsRemoveModalVisible}
        />
      )}
    </>
  );
};

export default PaymentMethodsList;
