import React, { useMemo } from 'react';
import { Box, CheckIcon, CloseIcon, CrossIcon, Text } from '@common-fe/common-fe';
interface Props {
  value: number;
}

const MAX_PERCENTS = 100;
const TotalPercents: React.FC<Props> = ({ value }) => {
  
  const currentColor = useMemo(() => {
    if(value === 0) {
      return 'textBody';
    }
    if(value === MAX_PERCENTS) {
      return 'success';
    }
    if(value !== MAX_PERCENTS) {
      return 'error';
    }
  }, [value]);

  return (
    <Box direction='row' align='center'>
      {value === 0 ? null : value === MAX_PERCENTS ? (
        <CheckIcon size="12px" color="success" />
      ):  (
        <CloseIcon size="12px" color="danger" />
      )}
      
      <Text
        margin={{left: 'xxxs'}} 
        size="medium"
        weight={700}
        color={currentColor}
      >

        {value}%
      </Text>
    </Box>
  ) ;
};
export default TotalPercents;