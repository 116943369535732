import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface CreateRecurringContributionRequest {
  amount?: number;
  frequency_type?: string;
  recurring_day_number?: number;
  start_date?: string;
  end_date?: string;
  status?: string;
  payment_method_id?: number;
}

export interface CreateRecurringContributionResponse {
  employee_contribution_amount: number;
  hold_days: number;
}

interface Params {
  account_id?: string;
  person_id: number | string;
}

export const useAddRecurringContributionQuery = (params: Params) => {
  const { mutateAsync, isSuccess, isLoading } = useMutation(
    (value: CreateRecurringContributionRequest) => api.put(
      PATHS.CREATE_RECURRING_CONTRIBUTION,
      value,
      { params },
    ),
  );

  const save = useCallback(async (value: CreateRecurringContributionRequest) => {
    const { data } = await mutateAsync(value);

    return data as CreateRecurringContributionResponse;
  }, [mutateAsync]);

  return { isLoading, save, isSuccess };
};
