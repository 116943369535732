import { useMemo } from 'react';

import routes from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

const RESERVED_PAGES = [
  routes.REIMBURSE_ME,
  routes.REQUEST_BILL_PAY,
  routes.RECEIPT_VAULT,
  routes.BUY_SELL,
  routes.SELF_DIRECTED(' '),
  routes.SELF_DIRECTED_INVEST_MORE(' '),
  routes.SELF_DIRECTED_SWITCH_SELECT(' '),
  routes.MANAGED_SWITCH_QUESTIONNAIRE(' '),
  routes.MANAGED_SWITCH_FUNDING_AMOUNT(' '),
  routes.MANAGED_SWITCH_ALLOCATION(' '), 

  routes.SELF_DIRECTED_SWITCH_ALLOCATION(' '),
  routes.MANAGED_SELL_INVESTMENTS(' '),
  routes.SELF_DIRECTED_ALLOCATION(' '),
  routes.SELF_DIRECTED_QUESTIONNAIRE(' '),
  routes.START_INVESTING,
  routes.INVESTMENTS_UPDATE_RISK_PORTFOLIO

];
export const useDefaultContentWrapper = () => {
  const { location } = useHistory();

  return useMemo(() => {
    if (RESERVED_PAGES.find((item) => location.pathname.includes(item.trim()))) {
      return false;
    }
    return true;
  }, [location.pathname]);
};
