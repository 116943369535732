import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box } from '@common-fe/common-fe';

import { ProcessScan } from '@/components/controls/DropScan/ProcessScan';

import useFilesStore from '../../ReimburseMe/store/useFiles.store';

import useGetScannedClaimDocumentQuery from './queries/useGetScannedClaimDocumentQuery';
import { useQrTransactionFileUploadAdditionalActionsStore, useTransactionFileUploadPopupStore, useTransactionOcrWarningsStore } from './store';

interface Props {
  onNext: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGetScannedDocument: (data: any) => void;
  onCancel?: () => void;
}

export const TransactionUploadFileScan: React.FC<Props> = ({
  onNext, onGetScannedDocument, onCancel,
}) => {
  const { files, setFiles } = useFilesStore((state) => state);
  // const { values: { documentId } } = useFilesInDocumentStore();
  const setIsScanned = useTransactionFileUploadPopupStore((store) => store.handleSetIsDocumentHasBeenScanned);
  const claimId = useQrTransactionFileUploadAdditionalActionsStore((store) => store.claimId);
  const setTransactionOcrWarnings = useTransactionOcrWarningsStore((store) => store.handleSetOcrWarnings);
  const handleSetOnCancelScanning = useQrTransactionFileUploadAdditionalActionsStore((state) => state.handleSetOnCancelScanning);
  const handleSetIsCancelScanning = useTransactionFileUploadPopupStore((store) => store.handleSetIsCancelScanning);

  const [documentsIds, setDocumentsIds] = useState<string[]>([]);
  const { getScannedDocument, cancel } = useGetScannedClaimDocumentQuery(() => handleSetIsCancelScanning(false));

  // const { uploadFiles } = useFileUploadQuery();
  const getParsedFile = useCallback(async (id: string) => {
    const parsedFile = await getScannedDocument(id, claimId);
    onGetScannedDocument(parsedFile.aiApproved);
    if (parsedFile.warningsFields) {
      setTransactionOcrWarnings(parsedFile.warningsFields);
    }
    setIsScanned(true);
    onNext();
  }, [
    getScannedDocument,
    claimId,
    onGetScannedDocument,
    setIsScanned,
    onNext,
    setTransactionOcrWarnings,
  ]);
  const runScanning = useCallback(async () => {
    // const uploadedFiles = await uploadFiles(documentId, files as FileImage[]);
    const ids = files.map((file) => file.id);

    if (files) {
      const filesWithId = files
        .map(
          (file) => {
            const id = files
              .find(
                (file) => file.temporalId === file.temporalId,
              )?.id;

            if (id) {
              return { ...file, id };
            }

            return file;
          },
        );

      if (setFiles) setFiles(filesWithId);
    }

    setDocumentsIds(ids);
    getParsedFile(ids[0]);
  }, [files, getParsedFile, setFiles]);

  useEffect(() => {
    runScanning();
    handleSetOnCancelScanning(cancel);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => () => {
    setIsScanned(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box align="center">
      <ProcessScan
        areFilesUploaded={documentsIds.length === 0}
        onNext={onNext}
        wrapperStyle={{ padding: 0 }}
        processScanWrapperStyle={{ padding: 0 }}
        onClose={() => {
          cancel();
          handleSetIsCancelScanning(true);
          onCancel && onCancel();
        }}
      />
    </Box>
  );
};
