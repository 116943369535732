import { Option } from '@common-fe/common-fe';

import { storeCreator } from '@/utils/store/store.utils';

interface State {
  breadcrumbs: Option<string>[];
  backBtn: boolean;
  setBreadcrumbs: (value: Option<string>[]) => void;
  handleSetBackBtn: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  breadcrumbs: [],
  backBtn: false,
  handleSetBackBtn: (value: boolean) => set({
    backBtn: value,
  }),
  setBreadcrumbs: (value: Option<string>[]) => set(() => ({
    breadcrumbs: value,
  })),
}));

export const useBreadcrumbsStore = () => {
  const {
    breadcrumbs,
    backBtn,
    setBreadcrumbs,
    handleSetBackBtn,
    ...store
  } = useStore();
  return {
    ...store,
    breadcrumbs,
    backBtn,
    setBreadcrumbs,
    handleSetBackBtn,
  };
};
