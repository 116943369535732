import {
  useMemo,
} from 'react';
import {
  useQuery,
  // useQueryClient,
} from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';

import { useFormatNotifications } from '../hooks';
import { NotificationsPaginationSearchParams, RawNotification } from '../notification.types';

export const QUERY_KEY = 'getDismissedNotifications';

interface DismissedNotificationsPayload {
  appNotifications: RawNotification[];
  totalDismissed: number;
  totalDismissedBySearch: number;
}

export default (params?: NotificationsPaginationSearchParams) => {
  const {
    data, isFetched, isSuccess, isLoading,
  } = useQuery(
    [
      QUERY_KEY,

      params?.page,
      params?.perPage,
      params?.searchString,
      params?.priority,
      params?.fromDate,
      params?.toDate,
    ],
    () => api.get<DismissedNotificationsPayload>(PATHS.NOTIFICATIONS_DISMISSED, {
      params: _.pickBy({
        page: (params?.page || 1) - 1, // BE counts pages from 0
        size: params?.perPage || 4,
        search: params?.searchString,
        priority: params?.priority,
        sort: 'createdAt,DESC',
        from: params?.fromDate ? dayjs(params.fromDate).format(DEFAULT_DATE_FORMAT) : undefined,
        to: params?.toDate ? dayjs(params.toDate).format(DEFAULT_DATE_FORMAT) : undefined,
      }),

    }),
    {
      enabled: !params?.isFromChache,

    },
  );
  const formatedData = useFormatNotifications(data?.data?.appNotifications || []);

  const total = useMemo(() => data?.data.totalDismissed || 0, [data]);
  const searchTotal = useMemo(() => data?.data.totalDismissedBySearch || 0, [data]);

  return {
    total,
    searchTotal,
    data: formatedData || [],
    isLoading: isLoading && !isFetched,
    isSuccess,
  };
};
