import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useHasAccess, useRedirectHome } from '@/modules/core/hooks';
import SelfDirected from '@/modules/investments/SelfDirected';
import { useMyEnrollments } from '@/modules/transaction/hooks';
import { useQueryParams } from '@/utils';
const INVESTOR_ID_QUERY = 'investorId';
const SelfDirectedPage = () => {
  const { t } = useTranslation();
  const { accountId = '' } = useParams<{ accountId: string }>();
  const query = useQueryParams();
  const investorId = useMemo(() => query.get(INVESTOR_ID_QUERY), [query]);
  const { currentMyAccount } = useMyEnrollments(accountId);
  const isInvestmentsDisabled = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  useRedirectHome(isInvestmentsDisabled);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id),
    title: currentMyAccount?.accountType,
    value: ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id),
  },
  {
    key: ROUTES.START_INVESTING,
    title: t('Start Investing'),
    value: ROUTES.START_INVESTING,
  }], true);

  return (
    <SelfDirected accountId={accountId} investorId={`${investorId || ''}`} />
  );
};

export default SelfDirectedPage;
