import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Heading,
  NavigationLeftIcon,
  NavigationRightIcon,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

export const BackButtonWrap = styled(Box)`
  box-shadow: none;
  .icon {
    color: ${({ theme }) => theme.colors.textAccent};
  }
`;

const HEADER_SIZE = '32px';

interface Props {
  onSignInRedirect?: () => void;
  isOrphanMode?: boolean;
}

const Header: React.FC<Props> = ({ onSignInRedirect, isOrphanMode }) => {
  const { t } = useTranslation();
  return (
    <Box direction="column">
      <BackButtonWrap
        data-testid="SignUp-BackButton-testid"
        onClick={onSignInRedirect}
        direction="row"
        align="center"
        margin={{ bottom: 'spacing24' }}
      >
        <NavigationLeftIcon color="textAccent"
          size="18px"
        />
       
        <Text margin={{ left: 'spacing8' }} color="textAccent" weight="bold">{t(isOrphanMode ? 'Back to Home' : 'Back to Welcome page')}</Text>
      </BackButtonWrap>
      <Heading
        level={3}
        size={HEADER_SIZE}
        color="textTitle"
        style={{
          fontWeight: 700,
        }}
      >
        {t('Let\'s Get Started!')}
      </Heading>
      <Text size="large" margin={{ top: 'spacing8' }}>
        {t('You’ve enrolled in your benefits, now let’s get you access to your account!')}
      </Text>
    </Box>
  );
};

export default Header;
