import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

export const QUERY_KEY = 'GET_DEDUCTIVLES';
export enum DeductibleType {
  External = 'EXTERNAL_DEDUCTIBLE_MET',
  Individual = 'INDIVIDUAL_DEDUCTIBLE_MET',
  Family = 'FAMILY_DEDUCTIBLE_MET',
  Member = 'MEMBER_DEDUCTIBLE_MET',
  Embedded = 'EMBEDDED_DEDUCTIBLE_MET',
  Plan = 'PLAN_DEDUCTIBLE_MET',
}

interface Deductible {
  tier_id: number;
  accumulated: number;
  deductible_target: number;
  next_tier_id: number;
  employee_id: number | null;
  dependent_id: number | null;
  deductible_type: DeductibleType;
}

interface Trigger {
  threshold_amount?: number;
  type: DeductibleType;
}

interface Tier {
  id: number;
  name: string;
  next_tier_id: number;
  triggers: Trigger[];
}

interface DeductibleListPayload {
  deductibles: Deductible[];
  tiers: Tier[];
}

export default (employeeId?: string) => {
  const {
    isLoading, data,
  } = useQuery([
    QUERY_KEY,
    employeeId,
  ],
  () => api.get<DeductibleListPayload>(PATHS.DEDUCTIBLES, {
    params: {
      employee_id: employeeId,
    },
  }), {
    cacheTime: 0,
    enabled: !!employeeId,
  });

  const formattedDeductiblesData = useMemo(() => {
    const list = data?.data?.deductibles || [];
    return list.map((deductible) => ({
      accumulated: deductible.accumulated,
      type: deductible.deductible_type,
      employeeId: deductible.employee_id ? `${deductible.employee_id}` : null,
      dependentId: deductible.dependent_id ? `${deductible.dependent_id}` : null,
    }));
  }, [data]);

  const formattedTiersData = useMemo(() => {
    const list = data?.data?.tiers || [];
    return list.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      triggers: item?.triggers?.map((trigger) => ({
        type: trigger.type,
        threshold: trigger.threshold_amount,
      })),
    }));
  }, [data]);

  return {
    isLoading,
    formattedDeductiblesData,
    formattedTiersData,
  };
};
