import React, { useEffect } from 'react';
import { Box } from '@common-fe/common-fe';

import useHighlightedDependentStore from '@/modules/user/components/ManageProfile/components/Dependents/stores/useHighlightedDependent.store';

import DependentsHeader from './components/DependentsHeader/DependentsHeader';
import DependentsList from './components/DependentsList/DependentsList';

const Dependents = () => {
  const { reset } = useHighlightedDependentStore();

  useEffect(() => {
    return () => {
      reset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testId="dependents" round="moduleRound" pad="spacing24" background="module">
      <DependentsHeader />
      <DependentsList />
    </Box>
  );
};

export default Dependents;
