import { emailValidation, validateUrl } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import { DEFAULT_DATE_FORMAT, PHONE_NUMBER_INVALID_TEXT, REQUIRED_TEXT } from './constants';
import REGEXPS from './regexp';

export const DATE_SEPARATOR = '/';
export const SEPARATOR_COUNT = 3;
export const MIN_YEAR_COUNT = 4;
export const WRONG_DATE_TEXT = 'Date format should be MM/DD/YYYY';
const FUTURE_DATE_TEXT = 'Date cannot be in the future';
export const FORMAT_VALIDATOR = (val: string) => {
  if (!val) {
    return true;
  }

  const count = val.split(DATE_SEPARATOR).length;
  const isYearExist = val.split(DATE_SEPARATOR).reverse()[0].length >= MIN_YEAR_COUNT;
  if (count === SEPARATOR_COUNT && isYearExist && dayjs(val, DEFAULT_DATE_FORMAT).isValid()) {
    return true;
  }
  return false;
};
const TODAY = dayjs().toDate();
const DATE_FORMAT = yup
  .string()
  .trim()
  .test({
    test: FORMAT_VALIDATOR as yup.TestFunction<string | undefined>,
    message: WRONG_DATE_TEXT,
  });
export default {
  PHONE_NUMBER: yup.string().min(17, PHONE_NUMBER_INVALID_TEXT).required(REQUIRED_TEXT),
  REQUIRED_STRING: yup.string().required(REQUIRED_TEXT),
  STRING: yup.string().nullable(),
  URL: yup.string().test(validateUrl()),
  URL_REQUIRED: yup.string().test(validateUrl()).required(REQUIRED_TEXT),
  EMAIL: emailValidation,
  EMAIL_REQUIRED: emailValidation.required(REQUIRED_TEXT),
  DATE_FORMAT,
  DATE_FORMAT_BEFORE_OTHER_DATE: (otherKey: string, text: string) =>
    yup
      .string()
      .trim()
      .test({
        exclusive: false,
        test(val) {
          const startDate = this.parent[otherKey];
          const endDate = val;
          if (startDate && endDate) {
            return dayjs(startDate).valueOf() > dayjs(endDate).valueOf();
          }
          return true;
        },
        message: text,
      })

      .test({
        test: FORMAT_VALIDATOR as yup.TestFunction<string | undefined>,
        message: WRONG_DATE_TEXT,
      }),
  DATE_FORMAT_AFTER_OTHER_DATE: (otherKey: string, text: string) =>
    yup
      .string()
      .trim()
      .test({
        exclusive: false,
        test(val) {
          if (!val) {
            return true;
          }
          const startDate = _.get(this, `parent[${otherKey}]`);

          const endDate = val;
          if (startDate && endDate) {
            return dayjs(startDate, DEFAULT_DATE_FORMAT) < dayjs(endDate, DEFAULT_DATE_FORMAT);
          }
          return true;
        },
        message: text,
      })

      .test({
        test: FORMAT_VALIDATOR as yup.TestFunction<string | undefined>,
        message: WRONG_DATE_TEXT,
      }),
  DATE_FORMAT_MAX_TODAY: yup
    .string()
    .trim()
    .test({
      test(val) {
        if (!val) {
          return true;
        }

        const currentDate = dayjs(val).toDate();
        if (currentDate <= TODAY) {
          return true;
        }
        return false;
      },
      message: FUTURE_DATE_TEXT,
    })
    .test({
      test(val) {
        if (!val) {
          return true;
        }

        const count = val.split(DATE_SEPARATOR).length;
        const isYearExist = val.split(DATE_SEPARATOR).reverse()[0].length >= MIN_YEAR_COUNT;
        if (count === SEPARATOR_COUNT && isYearExist && dayjs(val, DEFAULT_DATE_FORMAT).isValid()) {
          return true;
        }
        return false;
      },
      message: WRONG_DATE_TEXT,
    }),
  REQUIRED_POSITIVE_NUMBER_ONLY: yup
    .string()
    .required(REQUIRED_TEXT)
    .test({
      test(val) {
        if (!val || +val > 0) {
          return true;
        }
        return false;
      },
      message: 'Should be a positive number',
    }),
  ONLY_LATIN: yup.string().matches(REGEXPS.ONLY_LATIN_AND_NUMBER, 'Can only contain Latin letters'),
  REQUIRED_ARRAY: yup.array().min(1, REQUIRED_TEXT).required(REQUIRED_TEXT),
  ARRAY: yup.array().nullable(),

  ZIP_CODE: yup
    .string()
    .trim()
    .test(
      'len',
      'ZIP code isn\'t valid',
      (val) => (val && (val.length === 10 || val.length === 5)) || val === ''
    ),
};
