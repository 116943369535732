import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  LogOutIcon,
  PopupWrapper,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import ROUTES from '@/common/routes';
import { UserLetterIcon } from '@/components/elements/UserLetterIcon';
import { useAuthorization } from '@/modules/user/hooks';
import { useAuthStore } from '@/modules/user/stores';

import OrphanModeSwitcher from './components/OrphanModeSwitcher';
import { SwitchingModal } from './components/OrphanModeSwitcher/components';
import { useFormatLinkedEmployees } from './components/OrphanModeSwitcher/hooks';
import { useGetLinkedAccountsQuery } from './components/OrphanModeSwitcher/queries';
import { LogOutButton, ManageButton } from './UserDropdwon.styles';

const StyledPopupContent = styled(Box)`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 21px;
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.canvas};
    transform: rotate(45deg);
    border-top-left-radius: ${({ theme }) => theme.spacings.spacing4};
  }
`;

const AnimatedPopupWrapper = styled(PopupWrapper)`
    @keyframes entrance {
      0% {
        opacity: 0;
        transform: translateY(-14px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
`;

const POPUP_SHADOW = '0px 8px 25px rgba(0, 38, 102, 0.2)';

function UserDropdown(): JSX.Element {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { user, employeeId } = useAuthStore();
  const { handleLogout } = useAuthorization();
  const [isVisible, setIsVisible] = useState(false);
  const { formattedData } = useGetLinkedAccountsQuery({ enabled: !!user?.linkedEmployees });
  const formattedLinkedEmployees = useFormatLinkedEmployees({
    linkedEmployeesString: user?.linkedEmployees,
    linkedEmployees: formattedData,
  });
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
  const hasLinkedEmployees = formattedData.length > 0 && user?.linkedEmployees;

  const handleNavigateToManageProfilePage = useCallback(() => {
    push(ROUTES.MANAGE_PROFILE);
    setIsVisible(false);
  }, [push]);

  return (
    <>
      <Box
        data-testid="user-dropdown_wrapper"
        style={{
          zIndex: 101,
        }}
      >
        <AnimatedPopupWrapper
          flat
          flex
          style={{
            top: '58px',
            boxShadow: POPUP_SHADOW,
            animation: 'entrance 0.3s ease-in-out',
          }}
          controlledVisible={isVisible}
          onVisible={setIsVisible}
          content={(
            <StyledPopupContent direction="column" width={hasLinkedEmployees ? '324px' : '220px'}>
              <Box
                direction="row"
                pad="spacing16"
                align="center"
              >
                <UserLetterIcon
                  size={LetterIconSizeEnum.LOGO}
                  text={fullName}
                  type={user?.role as OrganizationTypes}
                  sizePx="40"
                />
                <Box
                  direction="column"
                  margin={{
                    left: 'spacing12',
                  }}
                >
                  <Text
                    size="medium"
                    weight={700}
                    color="textBody"
                  >
                    {fullName}
                  </Text>
                  <ManageButton
                    size="small"
                    weight={500}
                    color="textSecondary"
                    onClick={handleNavigateToManageProfilePage}
                  >
                    {t('Manage Account')}
                  </ManageButton>
                </Box>
              </Box>

              {
                hasLinkedEmployees && employeeId && (
                  <Box
                    data-testid="user-dropdown_linked-employees"
                    border={{ color: 'border2', side: 'top', size: 'small' }}
                    pad={{ vertical: 'spacing12', horizontal: 'spacing16' }}
                    background="module"
                  >
                    <OrphanModeSwitcher
                      defaultEmployeeId={employeeId}
                      linkedEmployees={formattedLinkedEmployees}
                      onSwitching={() => setIsVisible(false)}
                    />
                  </Box>
                )
              }

              <Box
                border={{ color: 'border2', side: 'top', size: 'small' }}
                pad={{ vertical: 'spacing12', horizontal: 'spacing16' }}
              >
                <LogOutButton onClick={handleLogout}>
                  <Box direction="row">
                    <LogOutIcon color="iconPrimary" />
                    <Box margin={{ left: 'spacing12' }}>
                      <Text size="medium" weight="normal">Log Out</Text>
                    </Box>
                  </Box>
                </LogOutButton>
              </Box>
            </StyledPopupContent>
          )}
        >
          <UserLetterIcon
            size={LetterIconSizeEnum.LOGO}
            text={fullName}
            type={user?.role as OrganizationTypes}
            sizePx="40"
            isWithShadow={isVisible}
          />
        </AnimatedPopupWrapper>

      </Box>
      <SwitchingModal />
    </>
  );
}

export default UserDropdown;
