import React, { useCallback } from 'react';
import {
  Box,
  FontIcon,
  RoundCloseIcon,
  RoundDoneIcon,
  RoundPendingIcon,
  WarningIcon,
} from '@common-fe/common-fe';

import { TransactionStatus } from '@/modules/transaction/transaction.types';

interface Props {
  status?: TransactionStatus;
}
const ICON_SIZE = '20px';
const StatusIcon: React.FC<Props> = ({ status }) => {
  const handleGetIconType = useCallback((type?: TransactionStatus) => {
    if (type === TransactionStatus.Paid
        ||type === TransactionStatus.PartiallyPaid
        ||type === TransactionStatus.Repaid
        ||type === TransactionStatus.OutOfPocket
        ||type === TransactionStatus.AppliedToDeductible
        ||type === TransactionStatus.Complete
    ) {
      return <RoundDoneIcon size={ICON_SIZE} />;
    }
    if (type === TransactionStatus.Processing
      || type === TransactionStatus.Pending
    ) {
      return <RoundPendingIcon size={ICON_SIZE} color="warning" />;
    }
    if (type === TransactionStatus.NeedsHelp 
      || type === TransactionStatus.RepaymentRequired
      || type === TransactionStatus.WaitingForFunds) {
      return <WarningIcon size={ICON_SIZE} />;
    }

    if (type === TransactionStatus.Denied
      || type === TransactionStatus.StopProcessing
      || type === TransactionStatus.Declined
      || type === TransactionStatus.Voided) {
      return <RoundCloseIcon size={ICON_SIZE} color="danger" />;
    }
    return <RoundPendingIcon size={ICON_SIZE} />;
  }, []);
  return (
    <Box data-testid={status}
      height="32px"
      width="32px"
      align='center'
      justify='center'
    >{handleGetIconType(status)}
    </Box>
  );
};

export default StatusIcon;
