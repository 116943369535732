import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface PayloadOption {
  key: string;
  message: string;
}
interface QuestionPayloadOption {
  prompt: string;
  type: string;
  answer: string[ ],
}
interface QuestionPayload {
  idNumber: number;
  summaryResult: PayloadOption;
  results: PayloadOption;
  qualifiers: {
    qualifier: PayloadOption[];
  }

  questions: {
    question: QuestionPayloadOption[]
  }

}

// const MOCKED_DATA = {
//   response: {
//     idNumber: 3622353522,
//     summaryResult: {
//       key: 'id.failure',
//       message: 'FAIL',
//     },
//     results: {
//       key: 'result.match',
//       message: 'ID Located',
//     },
//     qualifiers: {
//       qualifier: {
//         key: 'resultcode.ssn.within.one.digit',
//         message: 'SSN Does Not Match, Within Tolerance',
//       },
//     },
//     questions: {
//       question: [
//         {
//           prompt: 'From whom did you purchase the property at 222333 PEACHTREE PLACE?',
//           type: 'purchased.property.from',
//           answer: [
//             'STEVE. REIFEL',
//             'JOE ANDERSON',
//             'A VIRAY',
//             'None of the above',
//           ],
//         },
//         {
//           prompt: 'Between 1979 and 1980, in which State did you live?',
//           type: 'prior.residence.state.multiyear',
//           answer: [
//             'NEW YORK',
//             'OHIO',
//             'MARYLAND',
//             'None of the above',
//           ],
//         },
//         {
//           prompt: 'In which county have you lived?',
//           type: 'current.county.b',
//           answer: [
//             'TIPPAH',
//             'ARCHULETA',
//             'FULTON',
//             'None of the above',
//           ],
//         },
//       ],
//     },
//     'id-scan': 'no',
//   },
// };
// mock.onGet(PATHS.CIP_CHALLENGE).reply(200, MOCKED_DATA);

export const QUESTIONS_LIST_QUERY_KEY = 'QUESTIONS_LIST_QUERY_KEY';

interface Params {
  idNumber: number;
}
const useQuestionnaireFieldsQuery = ({ idNumber }: Params) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    () => api.post<QuestionPayload>(PATHS.CIP_CHALLENGE, {
      // id_number: idNumber,
    }),
  );

  const handleRetiveQuestions = useCallback(async () => {
    const data = await mutateAsync();
    return data;
  }, [mutateAsync]);
  return {
    isSuccess,
    isLoading,
    handleRetiveQuestions,
  };
};

export default useQuestionnaireFieldsQuery;
