import React, {
  useEffect,
  useMemo,   useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Hint,
  Inscription,
} from '@common-fe/common-fe';
import { toNumber } from 'lodash';
import Lottie from 'lottie-react';

import BankCardIcon from '@/assets/bank-icons/card.json';
import CardNumbersIcon from '@/assets/bank-icons/card-numbers.json';
import AppButton from '@/components/controls/AppButton';
import { Banner } from '@/modules/core/components/Dashboard/components/BannersList/Banner';
import CardMailedHint from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/CardMailedHint';
import {
  ATTEMPTS_LIMIT,
  ATTEMPTS_LIMIT_KEY,
  ATTEMPTS_LIMIT_TIMER_KEY,
  VERIFICATION_LIMIT_KEY,
} from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/VerifyMyCardModal/VerifyMyCardModal.constants';
import theme from '@/styles/theme';
import { useTimer } from '@/utils/hooks';

import useCardHolderQuery from './useCardHolder.query';

const BUTTON_WIDTH = '180px';

interface Props {
  handleOpenModal: () => void;
}

const CompleteBanner: React.FC<Props> = ({ handleOpenModal }) => {
  const { t } = useTranslation();
  const attemptLimitFromStorage = toNumber(localStorage.getItem(ATTEMPTS_LIMIT_KEY)) || 0;
  const [attempt, setAttempt] = useState(attemptLimitFromStorage);
  const { isTimeElapsed } = useTimer(VERIFICATION_LIMIT_KEY);
  const { isTimeElapsed: isAttemptLimitElapsed } = useTimer(ATTEMPTS_LIMIT_TIMER_KEY);
  const { mailedDataOfLastCard, isCardMailed } = useCardHolderQuery();

  const hasVerificationLimit = useMemo(() => (
    !isTimeElapsed || attempt >= ATTEMPTS_LIMIT
  ), [attempt, isTimeElapsed]);

  useEffect(() => {
    if (isAttemptLimitElapsed) {
      setAttempt(0);
      localStorage.removeItem(ATTEMPTS_LIMIT_KEY);
    }
  }, [isAttemptLimitElapsed]);

  return (
    <Banner
      title={t('Complete Your Setup!')}
      description={t('A debit card will be mailed to you shortly. Once you receive the card in the mail, please return here to verify the card and receive full access to your accounts.')}
      icon={(
        <Lottie
          animationData={isCardMailed && mailedDataOfLastCard ? CardNumbersIcon : BankCardIcon}
          loop
        />
      )}
    >
      <Box direction="row" margin={{ top: 'l' }}>
        <Hint
          hintAlign={mailedDataOfLastCard ? 'right' : 'vertical'}
          disabled={hasVerificationLimit || !mailedDataOfLastCard}
          hideHint={!hasVerificationLimit && mailedDataOfLastCard}
          hintWidth={240}
          hintElement={(
            <Box margin={{ right: 'm' }}>
              <AppButton
                testId="verify-my-card"
                width={BUTTON_WIDTH}
                onClick={handleOpenModal}
                disabled={hasVerificationLimit || !mailedDataOfLastCard}
              >
                {t('Verify My Card')}
              </AppButton>
            </Box>
          )}
        >
          {!mailedDataOfLastCard && (
            <Inscription size="12px">
              {t('The card verification button will become enabled as soon as your card is created and sent to you.')}
            </Inscription>
          )}
          {hasVerificationLimit && (
            <Inscription size="12px">
              {t('There have been too many unsuccessful attempts to verify your card. Please wait for 5 minutes before trying again.')}
            </Inscription>
          )}
        </Hint>
        {
          mailedDataOfLastCard && (
            <Hint
              hintAlign="right"
              disabled={isCardMailed}
              hideHint={!isCardMailed}
              backgroundColor={theme.colors.background3}
              hintElement={(
                <Box>
                  <AppButton
                    width={BUTTON_WIDTH}
                    buttonType="secondary"
                    disabled={isCardMailed}
                  >
                    {t('Request a New Card')}
                  </AppButton>
                </Box>
              )}
            >
              <CardMailedHint
                mailedDataOfLastCard={mailedDataOfLastCard}
                isCardMailed={isCardMailed}
              />
            </Hint>
          )
        }
      </Box>
    </Banner>
  );
};

export default CompleteBanner;
