export const REFRESH_TOKEN = '/refresh';

export const USER = '/user/';
export const USERS = '/users/';
export const EMPLOYEES = '/employees/';
export const AUTH = `${USER}auth/`;
export const TRANSACTION = '/transaction/';
export const MY_ACCOUNTS = `${TRANSACTION}my-accounts/`;
export const FORGOT_PASSWORD = `${AUTH}forgot-password/`;
const NOTIFICATIONS = '/app-notifications';
export const INVESTORS = '/investors/';
export const INVESTMENTS = '/investments';
export const INSTRUMENTS = '/instruments';
export const CLAIMS = '/claims';
const CIP_PREFIX = '/cip';
const IDOLOGY_PREFIX = '/idology';
const UI = '/ui';
const DOCUMENTS = '/documents';

export default {
  ACCOUNT_ACTIVITIES_BY_ID: (id?: string | number, employeeId?: number | string) => `/activities/accounts/${id}?employee_id=${employeeId}`,
  ACTIVITY_DETAILS_EXPENSES: '/statistics/expenses',
  ACTIVITY_DETAILS: '/activities',
  ACTIVITY_POSTINGS_BY_ID: (postingId: string) => `/activities/postings/${postingId}`,
  ACTIVITY_CLAIMS_BY_ID: (claimId: string) => `/activities/claims/${claimId}`,
  ACCOUNT_ORDERS: '/account-orders',
  ACCOUNT_NUMBER: (accountId: string) => `/accounts/hsa/${accountId}/number`,
  ADD_VAULTED_ACCOUNT_TO_RECIPIENT: (merchantCustomerId?: string) => `/recipients/${merchantCustomerId}/accounts`,
  ADD_PROVIDER: '/providers',
  UPDATE_PROVIDER: (id?: string) => `/providers/${id}`,
  BANC_ACCOUNT: '/accounts',
  BANC_ACCOUNT_BY_ID: (accountId: string | number) => `/accounts/${accountId}`,
  CARD_ADD_DEPENDENT: '/card-add-dependent/',
  CARD_HOLDERS_MOCK: '/card-holders',
  CARD_HOLDERS: (query: string) => `/card-holders?${query}`,
  CARD_TRANSACTIONS: `${TRANSACTION}card/`,
  CARD_ACTIVITY: '/activities/cards',
  CARDS: 'cards',
  COUNTRIES: '/enums/countries',
  INVESTMENT_SCHEDULERS: '/investment-schedulers',
  FUNDS_LIST: '/instruments/self-directed',
  INSTRUMENTS_CATEGORIES: `${INSTRUMENTS}/categories`,
  CIP_INFO: '/cip-infos/employee-id',
  CIP_CHALLENGE: `${IDOLOGY_PREFIX}/challenge`,
  CIP_CHALLENGE_ANSWERS: `${IDOLOGY_PREFIX}/challenge-answers`,
  CIP_ANSWERS: `${CIP_PREFIX}/challenge-answers`,
  CIP_IDENTIFICATION: `${IDOLOGY_PREFIX}/identification`,
  CIP_QUESTIONS: `${CIP_PREFIX}/questions`,
  CIP_QUESTIONS_ANSWERS: `${IDOLOGY_PREFIX}/questionnaire-answers`,
  CIP_STATUS: '/cip-statuses',
  CLAIM_STATUSES: '/enums/claim-status',
  GET_CHECKS_LIST: '/checks/search',
  REISSUE_CHECK: (id?: string) => `/checks/${id}/reissue`,
  VOID_CHECK: (id?: string) => `/checks/${id}/void`,
  CLAIMS_ACTIVITIES: `${CLAIMS}/search/activities`,
  PROCESS_WAITING_FOR_FUNDS_CLAIM: (id?: string) => `${CLAIMS}/${id}/process-waiting-for-funds`,
  CREATE_CLAIM: CLAIMS,
  EMPLOYEE_PROCESS: (claimId: string) => `${CLAIMS}/${claimId}/employee-process`,
  CREATE_DOCUMENT_ID: '/documents',
  DEDUCTIBLES_SERVICES: (employeeId?: string) => `/deductibles-services/?employee_id=${employeeId}&service_type=MANUAL_CLAIM&page=0&size=1000`,
  DEPENDENTS: '/dependents',
  DEPENDENTS_NAMES: '/dependents/names',
  REMOVE_DEPENDENT: (dependentId?: string) => `/dependents/${dependentId}`,
  UPDATE_DEPENDENT: (dependentId?: string) => `/dependents/${dependentId}`,
  DEDUCTIBLES: '/tiers/deductibles',
  DELETE_DOCUMENT: (id: string) => `/documents/${id}`,
  DELETE_FILE: (id: string) => `/documents/files/${id}`,
  EXPENSES_STATISTICS: '/expenses/statistics',
  EXPENSES: '/expenses',
  FORGOT_PASSWORD,
  FORGOT_USERNAME: 'persons/usernames/forgot',
  GET_CARD: (cardId: string) => `/cards/${cardId}`,
  VERIFY_CARD: (last4: string) => `cards/verify?last4=${last4}`,
  GET_FILES_BY_DOCUMENT_ID: (documentId?: string) => `/documents/${documentId}/files`,
  GET_PROVIDER: (id: string) => `/providers/${id}`,
  GET_PROVIDERS: '/providers',
  GET_RELATIONSHIP_TYPES: '/enums/relationship-types',
  GET_STATE: (name: string) => `/enums/states&name=${name}`,
  GET_ORGANIZATION_LOCK_STATUS: (id?: string) =>  `/organization-locks/${id}`,
  INVESTMENT_ACTIVITY_LIST: '/investments-activity',
  INVESTMENT_LIST: '/investments',
  MY_ACCOUNTS,
  MY_ENROLLMENT: '/enrollments',
  PERSONS_BY_ID: (id?: string) => `/persons/${id}`,
  REFRESH_TOKEN,
  REIMBURSE_RECEIPT_UPLOAD: (documentId: string) => `/documents/${documentId}/import`,
  REIMBURSE_RECEIPT_UPLOAD_BY_QR: (documentId: string | number) => `/documents/${documentId}/files/by-qr-codes/import`,
  REIMBURSE_SCANNED_FILE: (fileId: string) => `/documents/files/${fileId}/ocr-reader`,
  CLAIM_SCANNED_FILE: (fileId: string) => `/documents/files/${fileId}/openai/ocr/matching`,
  REPLACEMENT_CARD_REQUEST: (cardId: string) => `/cards/${cardId}/replacement`,
  RELATIONSHIPS_TYPES: '/enums/relationship-types',
  SEND_CODE: `${FORGOT_PASSWORD}code`,
  SERVICES: '/services',
  ACCOUNT_ORDERS_SERVICES: '/account-orders/services',
  ACCOUNT_ORDERS_MATRIXES: '/account-orders/matrixes',
  SERVICE_CATEGORIES: '/enums/service-categories',
  SET_CARD_STATUS: (cardId: string) => `/cards/${cardId}`,
  SIGN_UP_BY_GUI: (gui: string) => `${USERS}signup/${gui}`,
  SIGN_UP_BY_SSN: `${USERS}signup`,
  SIGN_UP: '/signup',
  SIGN_UP_COMMUNICATION: `${AUTH}step_3`,
  SIGN_UP_PERSONAL_INFO: `${AUTH}step_1`,
  SIGN_UP_SECURITY_FITST: `${AUTH}step_2_1`,
  SIGN_UP_SECURITY_SECOND: `${AUTH}step_2_2`,
  SIGN_UP_CONFIRMATION: 'sign-up/confirmation',
  SIGNIN: `${AUTH}login`,
  SIGN_IN: 'signin',
  SEND_FORGOT_PASSWORD_CODE: '/persons/forgot-password',
  REGISTRATION_VERIFICATION: 'registration/verification',
  STATES: '/enums/states',
  TRANSACTION_LIST: `${TRANSACTION}list/`,
  TRANSACTION,
  TRANSACTOR_DETAILS_BY_ID: (id?: string) => `/providers/${id}`,
  NOTIFICATIONS,
  NOTIFICATIONS_DISMISSED: `${NOTIFICATIONS}/dismissed`,
  NOTIFICATIONS_READ: `${NOTIFICATIONS}/read`,
  NOTIFICATIONS_SENT: `${NOTIFICATIONS}/sent`,
  NOTIFICATIONS_STREAM: `${NOTIFICATIONS}/stream`,
  INVESTORS,
  INVESTMENTS_CHANGE_MODEL: `${INVESTORS}investment-models/`,
  INVESTMENT_STOCKS_LIST: '/investment-stocks',
  INVESTMENTS_BUY: `${INVESTMENTS}/buy`,
  INVESTMENTS_SELL: `${INVESTMENTS}/sell`,
  INVESTMENTS_HOLDINGS: `${INVESTMENTS}/holdings`,
  INVESTMENTS_ACTIVITES_PENDING: `${INVESTMENTS}/activities/pending`,
  TRADES: '/trades',

  CIP_DOCUMENT_SCAN_CHECK: () => '/idology/document-process-status',
  INVESTMENTS_UPDATE_RISK_MODEL: (id?: string) => `${INVESTORS}${id}/risk-tolerance-model`,
  INVESTMENTS_PORTFOLIOS: '/investment-portfolios/proposed',
  INVESTMENTS_ACTIVITIES: '/investments/activities/completed',
  EMPLOYEE_INFO: (employeeId?: string) => `/employees/${employeeId}/employee-view`,
  EMPLOYEE_SSO_SIGNUP: (employeeId?: string) => `/employees/${employeeId}/sso-signup`,
  ORGANIZATION_INFO: (organizationId?: string) => `/organizations/${organizationId}/employee-view`,
  TERMINATE_ORGANIZATION: (id: string) => `/organizations/${id}/termination`,
  EMPLOYER_TERMINATION_PLANS: (orgId: string) => `/plans/organizations/${orgId}/active-grace-period`,
  UPDATE_CLAIM_DOCUMENT_ID: (id?: string) => `${CLAIMS}/${id}/document`,
  UPDATE_CLAIM_DOCUMENT_ID_BY_HASH_ID: (claimId?: string, hashId?: string) => `${CLAIMS}/${claimId}/documents/one-time-links/${hashId}`,
  CLAIMS_PAYMENT_DETAILS: `${CLAIMS}/payment-details`,
  PAYMENT_METHODS: '/payment-methods',
  UPDATE_DEFAULT_PAYMENT_METHODS: (paymentMethodId: number | string) => `/payment-methods/${paymentMethodId}`,
  FISERV_TOKEN: '/tokens',
  ACCOUNT_TOKEN: '/account-tokens',
  FISERV_CARD_ACTIVATION: '/maintenance/card',
  EMPLOYEES_PERSONAL_INFO_BY_ID: (employeeId: number) => `${EMPLOYEES}/personal-info/${employeeId}`,
  CLAIM_CONFIG: (employeeId: string) => `${EMPLOYEES}/${employeeId}/claim-config`,
  ENCRYPTION_PUBLIC_KEY: 'encryption/public-key',
  EMPLOYEE_BY_ID: (id: string | number) => `${UI}/employees/${id}`,
  UI_EMPLOYEE: `${UI}/employees`,
  GET_STATEMENTS: '/statements',
  GET_LINKED_ACCOUNTS: `${EMPLOYEES}linked-accounts`,
  IRS_MAXIMUMS: '/irs-maximums',
  CREATE_CONTRIBUTION: '/contributions/contribution-requests/employee',
  CREATE_RECURRING_CONTRIBUTION: '/recurring-contributions',
  UPDATE_RECURRING_CONTRIBUTION: (id?: number) => `/recurring-contributions/${id}`,
  ADD_RECURRING_CONTRIBUTION: '/recurring-contributions/info',
  GET_RECEIPT_QR_CODE: `${DOCUMENTS}/receipts/qr-codes`,
  CONTRIBUTION_HISTORY: '/monitor-contribution/info',
  CREATE_DOCUMENT_BY_QR_CODE: `${DOCUMENTS}/receipts/by-qr-codes`,
  GET_DOCUMENT_BY_QR_CODE: `${DOCUMENTS}/receipts/file/by-qr-codes/stream`,
  GET_ONE_TIME_LINK_BY_HASH_ID: (hashId: string) => `${DOCUMENTS}/one-time-links/${hashId}`,
  CREATE_DOCUMENT_BY_ONE_TIME_LINK: `${DOCUMENTS}/receipts/by-link`,
  IMPORT_FILE_BY_ONE_TIME_LINK: (documentId: string) => `${DOCUMENTS}/${documentId}/files/by-link/import`,
  GET_CLAIM_SCANNED_FILE_BY_HASH_ID: (fileId: string) => `${DOCUMENTS}/files/by-link/${fileId}/openai/ocr/matching`,
  DEACTIVATE_ONE_TIME_LINK: (hashId?: string) => `${DOCUMENTS}/one-time-links/${hashId}/deactivation`,
  GET_PARTNER_APP_LINKS_BY_QR_CODE: (qrHashId: string) => `/organizations/overridable-properties/by-qr-codes/${qrHashId}`,
  GET_PARTNER_APP_LINKS_BY_HASH_ID: (qrHashId?: string) => `/organization-properties/hash/${qrHashId}`,
  SEND_VERIFICATION_CODE: '/codes/verifications',
  VALIDATE_VERIFY_CODE: '/codes/verifications/validation',
  SEND_MFA_CODE: 'mfa/codes',
  VERIFY_MFA_CODE: '/mfa/codes/verifications',
  
  INVESTMENTS_FLOW_QUESTIONNAIRE: '/investments/flows/questionnaire',
  INVESTMENTS_FLOW_APPROVE: '/investments/flows/approve',
  INVESTMENTS_FLOW_FUNDING: '/investments/flows/funding',
  INVESTMENTS_FLOW_PATH: '/investments/flows/path',
  INVESTMENTS_FLOW_START: '/investments/flows/start',

};
