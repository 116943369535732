import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Preloader } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { StepperSidebar } from '@/components/elements';
import { Topbar } from '@/modules/core/components';
import { useHistory } from '@/modules/core/hooks';
import useMyEnrollments from '@/modules/transaction/hooks/useMyEnrollments';
import theme from '@/styles/theme';
import { useQueryParams } from '@/utils';

import { useInvestorById } from '../hooks';
import { InvestmentPaths, InvestorStatus } from '../investments.types';
import PortfolioQuestionnaire from '../Managed/PortfolioQuestionnaire';

import FundingAmount from './FundingAmount';
import { useStartInvestingResetData } from './hooks';
import InvestmentPath from './InvestmentPath';
import PortfolioAllocation from './PortfolioAllocation';

const ACCOUNT_ID_QUERY_KEY = 'accountId';
const INVESTOR_ID_QUERY_KEY = 'investorId';
const START_MODE_QUERY_KEY = 'startMode';

const StartInvesting = React.memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQueryParams();
  const accountId = useMemo(() => query.get(ACCOUNT_ID_QUERY_KEY), [query]);
  const isStartMode = useMemo(() => query.get(START_MODE_QUERY_KEY), [query]);
  const investorId = useMemo(() => query.get(INVESTOR_ID_QUERY_KEY), [
    query,
  ]) as string;
  const { data: investor, isLoading: isInvestorLoading } = useInvestorById();
  const activeStep = useMemo(
    () => {
      const investorStatus = investor?.investorStatus; 
      const investmentType =investor?.type;
      if(isStartMode) {
        return 1;
      }
      if(investmentType === InvestmentPaths.Managed) {
        if(investorStatus === InvestorStatus.INVESTMENT_PATH_SELECTED) {
          return 2;
        }
        if(investorStatus === InvestorStatus.QUESTIONNAIRE_FILLED) {
          return 3;
        }
        if(investorStatus === InvestorStatus.INVESTMENT_AMOUNT_SELECTED) {
          return 4;
        }
      }
      return 1;    
    },
    [investor, isStartMode],
  );
  const [step, setStep] = useState(activeStep);
  useEffect(() => {
    setStep(activeStep);
  }, [activeStep]);
  const handleReset = useStartInvestingResetData();
  const { currentMyAccount, isLoading } = useMyEnrollments(accountId);
  const [proposedToleranceModel, setProposedToleranceModel] = useState('');
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [isAllowRedirect, setIsAllowRedirect] = useState(false);
  const OPTIONS = useMemo(
    () => [
      { key: 1, value: t('Investment Path') },
      { key: 2, value: t('Portfolio Questionnaire') },
      { key: 3, value: t('Initial Funding Amount') },
      { key: 4, value: t('Portfolio Allocation') },
    ],
    [t],
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handlePreviousStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleCancel = useCallback(() => {
    handleReset();
    history.push(ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id));
  }, [history, currentMyAccount, handleReset]);

  const handleGoDashboard = useCallback(() => {
    handleReset();
    history.push(`${ROUTES.INVESTMENTS}?id=${currentMyAccount?.id}`);
  }, [history, currentMyAccount, handleReset]);

 

  const handleNextStep = useCallback(async (key?: string) => {
    if (step === 1) {
      
      if (key === InvestmentPaths.SelfDirected) {
      
        history.push(`${ROUTES.SELF_DIRECTED_QUESTIONNAIRE(`${currentMyAccount?.id || ''}`)}?investorId=${investorId}`);
        return;
      } else  {
     
        setStep(2);
      }
      return;
    }
    if (step === 2) {
      setStep(3);
      return;
    }
    if (step === 3) {
      setStep(4);
      return;
    }
    setStep(1);
  }, [currentMyAccount?.id, history, investorId, step]);
  useEffect(() => {
    if (investor?.balance) {
      setInvestmentAmount(investor?.balance);
    }
    if (investor?.proposedToleranceModel) {
      setProposedToleranceModel(investor?.proposedToleranceModel);
    }
  }, [ investor ]);
  useEffect(() => {
    if (!accountId || !investorId) {
      handleBack();
    }
  }, [handleBack, accountId, investorId]);
  useEffect(() => () => handleReset(), [handleReset]);
  useEffect(() => {
    if (step !== 1 && !isAllowRedirect) {
      window.addEventListener('beforeunload', (e) => {
        const confirmationMessage = 'You have unsaved changes, are you sure you want to leave?';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      });
    }
  }, [step, history, currentMyAccount, isAllowRedirect]);
  if(isInvestorLoading || isLoading) {
    return <Preloader />;
  } 
  return (
    <Box
      direction="row"
      flex="grow"
      data-testid="StartInvesting-wrapper"
      style={{ overflow: 'hidden' }}
    >
      <StepperSidebar
        title={t('Start Investing')}
        options={OPTIONS}
        step={step}
      />
      <Box
        direction="row"
        justify="center"
        flex="grow"
        overflow={{ vertical: 'auto' }}
        height={{
          min: '100vh',
        }}
        style={{
          display: 'block',
          position: 'relative',
        }}
      >
        <Box direction="row" justify="center">
          <Box
            flex="grow"
            pad={{ horizontal: '40px' }}
            margin={{ bottom: 'l' }}
          >
            <Topbar />
          </Box>
        </Box>
        <Box direction="row" justify="center">
          <Box
            width={{ max: theme.defaultContentWidth }}
            flex
          >
            {step === 1 && (
              <InvestmentPath
                onNext={handleNextStep}
                onCancel={handleCancel}
                step={step}
                stepLength={OPTIONS?.length}
                currentMyAccount={currentMyAccount}
                isLoading={isLoading}
              />
            )}
            {step === 2 && (
              <PortfolioQuestionnaire
                onBack={handlePreviousStep}
                onNext={handleNextStep}
                setProposedToleranceModel={setProposedToleranceModel}
                step={step}
                stepLength={OPTIONS?.length}
              />
            )}
            {step === 3 && (
              <FundingAmount
                onBack={handlePreviousStep}
                onNext={handleNextStep}
                onSetInvestmentAmount={setInvestmentAmount}
                currentMyAccount={currentMyAccount}
                step={step}
                stepLength={OPTIONS?.length}
              />
            )}
 
            {step === 4 && (
              <PortfolioAllocation
                onBack={handlePreviousStep}
                handleGoDashboard={handleGoDashboard}
                setIsAllowRedirect={setIsAllowRedirect}
                step={step}
                stepLength={OPTIONS?.length}
                currentMyAccount={currentMyAccount}
                recommendedRisk={proposedToleranceModel}
                investmentAmount={investmentAmount}
                setInvestmentAmount={setInvestmentAmount}
                investorId={investorId}
              />
            )}
          </Box>
        </Box>
      </Box>

    </Box>
  );
});

export default StartInvesting;
