import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionItem,
  Box,
  capitalizeFirstLetter,
  CoveragePeriods,
  EnrollmentStatus,
  Inscription,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import globalTheme from '@/styles/theme';

import CoveragePeriodsTable from './CoveragePeriodsTable';

interface StatusProps {
  iconColor?: string;
}

export const Status = styled(Inscription)<StatusProps>`
  position: relative;
  padding-left: 14px;
  margin-left: ${globalTheme.spacings.xs};
  display: flex;
  color: ${globalTheme.colors.textBody};
  ${globalTheme.fonts.text12Bold};
  flex-direction: row;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    width: 10px;
    height: 10px;
    background: ${({ iconColor }) => iconColor};
    border-radius: 100%;
  }
`;

const AccordionItemWrapper = styled(Box)`
  a {
    color: ${globalTheme.colors.textSecondary};
    pointer-events: none;
  }
`;

interface Props {
  coverageDateStart?: string;
  coverageDateEnd?: string;
  submitClaimsDateEnd?: string;
  isInlineMode?: boolean;
  enrollmentStatus?: EnrollmentStatus;
  coveragePeriods?: CoveragePeriods[];
  statusColor?: string;
  width?: string;
}

export const EnrollmentDates: React.FC<Props> = ({
  coverageDateStart,
  coverageDateEnd,
  submitClaimsDateEnd,
  coveragePeriods,
  enrollmentStatus,
  isInlineMode,
  statusColor,
  width,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const prefixText = useMemo(() => {
    let currentText = '';
    if (!coveragePeriods?.length) {
      currentText = t('You can\'t submit claims for this enrollment.');
    } else if (coverageDateStart && coverageDateEnd && submitClaimsDateEnd) {
      currentText = t('Submit expenses with dates of service between');
    } else if (coverageDateStart && coverageDateEnd && !submitClaimsDateEnd) {
      currentText = t('Submit expenses with dates of service between ');
    } else if (coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = t('Submit expenses with dates of service on or after');
    } else if (coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = t('Submit expenses with dates of service on or after');
    } else if (!coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = t('Submit expenses with any date of service');
    } else if (!coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = t('Submit expenses with any date of service until ');
    }
    return (
      <Text size="small" weight={500} color="textSecondary">{`${currentText} `}</Text>
    );
  }, [coverageDateEnd, coverageDateStart, submitClaimsDateEnd, coveragePeriods, t]);
  const rangeText = useMemo(() => {
    let currentText = '';
    if (!coveragePeriods?.length) {
      currentText = '';
    } else if (coverageDateStart && coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${coverageDateStart} – ${coverageDateEnd}`;
    } else if (coverageDateStart && coverageDateEnd && !submitClaimsDateEnd) {
      currentText = `${coverageDateStart} – ${coverageDateEnd}`;
    } else if (coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${coverageDateStart}`;
    } else if (coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = `${coverageDateStart}`;
    } else if (!coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = '';
    } else if (!coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${submitClaimsDateEnd}`;
    }
    return (
      <Text
        data-testid="EnrollmentDates_coverage-period"
        size="small"
        margin={{ top: isInlineMode ? '' : 'xxs' }}
        weight={700}
        style={{ display: 'inline-block' }}
      >{`${currentText}`}
      </Text>
    );
  }, [coverageDateEnd, coverageDateStart, isInlineMode, submitClaimsDateEnd, coveragePeriods]);

  const postfixText = useMemo(() => {
    let currentText = '';
    if (!coveragePeriods?.length) {
      currentText = '';
    } else if (coverageDateStart && coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${submitClaimsDateEnd}`;
    } else if (coverageDateEnd && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${submitClaimsDateEnd}`;
    }
    if (!currentText) {
      return null;
    }
    return (
      <>
        <Text data-testid="EnrollmentDates_last-date-to-submit" size="small" color="textSecondary">{t(' until ')}</Text>
        <Text size="small" weight={700}>{currentText}</Text>
      </>
    );
  }, [coverageDateEnd, coverageDateStart, submitClaimsDateEnd, coveragePeriods, t]);

  const enrollmentDates = useMemo(() => {
    return (
      <Box
        margin={{ left: 'xxs', top: coveragePeriods && coveragePeriods?.length > 1 ? '0' : 'm' }}
        direction="row"
        align="center"
      >
        <Inscription
          color="textSecondary"
          size="small"
          style={{ fontWeight: 500 }}
        >
          {t('Enrollment status:')}
        </Inscription>
        <Status lineHeight="24px" iconColor={statusColor}>
          {enrollmentStatus && capitalizeFirstLetter(enrollmentStatus)}
        </Status>
        <Box  align="center" justify="center" height="4px" width="4px" round="full" background="border" margin={{ horizontal: 'xs' }} />
        <Box direction="row">
          <Text size="medium" style={{ lineHeight: 1 }}>
            {prefixText}
            {rangeText}
            {postfixText}
          </Text>
        </Box>
      </Box>
    );
  }, [statusColor, enrollmentStatus, prefixText, rangeText, postfixText, coveragePeriods, t]);

  if (isInlineMode) {
    if (coveragePeriods && coveragePeriods?.length > 1) {
      return (
        <Box
          style={{
            position: 'relative',
            cursor: 'pointer',
          }}
          margin={{ top: isInlineMode ? 'm' : '0' }}
        >
          <AccordionItemWrapper 
            width="100%"
            onClick={() => setExpanded(!expanded)}
          >
            <AccordionItem
              isExpanded={expanded}
              text={enrollmentDates}
              isLeftArrow
              iconSize="20px"
            >
              <>
                <Box height="1px" background="border1" margin={{ vertical: 'm' }} />
                <Box
                  background="canvas"
                  pad={{ bottom: 'xs', horizontal: 'm', top: 'm' }}
                  round="small"
                  width="100%"
                  height="fit-content"
                >
                  <Text
                    size="small"
                    weight="bold"
                    color="textTitle"
                    margin={{ bottom: 'xs' }}
                  >
                    {t('All coverage periods')}
                  </Text>
                  {!!coveragePeriods?.length &&<CoveragePeriodsTable coveragePeriods={coveragePeriods} />}
                </Box>
              </>
            </AccordionItem>
          </AccordionItemWrapper>
        </Box>
      );
    }
    return enrollmentDates;
  }
  return (
    <Box
      width={width || '100%'}
      direction="column"
      background="border1"
      margin={{ top: '-12px' }}
      style={{ zIndex: -1 }}
      round={{ corner: 'bottom', size: 'container1Round' }}
      border={{ color: 'border2', size: 'small' }}
      pad={{ vertical: 'spacing8', horizontal: 'spacing24', top: 'spacing20' }}
      data-testid="EnrollmentDates_container"
    >
      <Text size="medium" style={{ lineHeight: 1 }}>
        {prefixText}
        {rangeText}
        {postfixText}
      </Text>
    </Box>
  );
};
