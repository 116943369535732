/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return props.theme.colors.accentActive;
  }
  if (props.isDragReject) {
    return props.theme.colors.dangerBorder;
  }
  if (props.isDragActive) {
    return props.theme.colors.accentActive;
  }
  return props.theme.colors.accentActive;
};

const getBorderType = (props: any) => {
  if (props.isDragAccept) {
    return 'solid';
  }
  if (props.isDragReject) {
    return 'dashed';
  }
  if (props.isDragActive) {
    return 'solid';
  }
  return 'dashed';
};
export const DropScanTitle = styled.h4`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.textTitle || ''};

  ${() => {
    if (whiteLabelingConfig?.title) {
      return {
        fontWeight: 700,
      };
    }
  }}
`;

export const DropScanTitlePrimary = styled.span`
  ${({ theme }) => theme.fonts.text24Bold};
  font-size: 20px;
  color: ${({ theme }) => theme.colors.navigationAccent};
`;

export const DropScanSubtitle = styled.h4`
  color: ${({ theme }) => theme.colors.blockTitle || ''};
  ${({ theme }) => theme.fonts.text16Medium};
  color: ${({ theme }) => theme.colors.stressContainer};
  margin: 6px;
`;

export const DropScanSubtitle2 = styled.h4`
  ${({ theme }) => theme.fonts.text14Medium};
  margin-top: ${({ theme }) => theme.spacings.spacing8};
  font-weight: ${whiteLabelingConfig.fonts?.reimbursementWeight || 'normal'};
  text-align: center;

  ${({ theme }) => {
    if (whiteLabelingConfig?.title) {
      return {
        color: theme.colors.textSecondary,
      };
    }

    return {
      color: theme.colors.textDisabled,
    };
  }}
`;

export const DropScanSubtitlePill = styled.h4`
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme }) => theme.colors.stressContainer};
  background-color: ${({ theme }) => theme.colors.accentContainer};
  border-radius: 6px;
  padding: 3px 15px;
  margin: ${({ theme }) => theme.spacings.spacing24};
`;


export const EmptyWrapper = styled.div``;

export const DropScanWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings.spacing72} ${theme.spacings.spacing16}`};
  border-color: ${(props) => getColor(props)};
  border-style: ${(props) => getBorderType(props)};
  border-width: 2px;
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  background-color: ${({ theme }) => theme.colors.canvas};
  transition: border 0.24s ease-in-out;
  &:hover {
    border-style: solid;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
  background-color: ${({ theme }) => theme.colors.module};
  padding: ${({ theme }) => theme.spacings.spacing24};
  box-sizing: border-box;
`;
