import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { RiskToleranceType } from '@/modules/investments/investments.types';

import { useStore as useInvestmentProfileStore } from '../../stores/useInvestmentProfile.store';

export default () => {
  const { t } = useTranslation();
  const RISK_TOLERANCE_TYPE_OPTIONS = useMemo(
    () => [
      {
        label: t('I am very conservative person and try to avoid the possibility of any loss'),
        value: RiskToleranceType.LOW,
      },
      {
        label: t('I am conservative, but I am willing to accept a small amount of risk in order to achieve higher incomes'),
        value: RiskToleranceType.MODERATE,
      },
      {
        label: t('I am tolerant to losses to achieve potentially higher returns'),
        value: RiskToleranceType.SPECULATIONS,
      },
      {
        label: t('I can easily take on significant risk and are willing to tolerate large losses for the potential of achieving higher returns'),
        value: RiskToleranceType.HIGH,
      },
    ], [t],
  );
  const defaultFields = useInvestmentProfileStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        name: 'riskToleranceType',
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: t('How would you describe your attitude to making financial and investment decisions?'),
        placeholder: '',
        options: RISK_TOLERANCE_TYPE_OPTIONS,
        isWrappedMode: true,
        validator: yup.string().required(REQUIRED_TEXT),
      },
    ]), [RISK_TOLERANCE_TYPE_OPTIONS, t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
