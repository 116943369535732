import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box, FlexForm, Inscription, Preloader, SuccessModal, Text,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { PendingModal } from '@/components';
import useAddPaymentMethodQuery from '@/modules/transaction/components/ReimburseMe/queries/useAddPaymentMethod.query';
import { useGetPaymentMethods } from '@/modules/transaction/components/ReimburseMe/queries/useGetPaymentMethods.query';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethodOwnerType } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import spacings from '@/styles/spacings';

import ConfirmCancelModal from '../../ConfirmCancelModal';

import useBank from './DirectDeposit/BankForm/useBank.store';
import { BankInfo } from './DirectDeposit/BankForm/useBankFields';
import { useGetExternalBankAccountsQuery } from './DirectDeposit/BankForm/useGetBankAccountsList.query';
import { RemovePaymentMethodModal } from './RemovePaymentMethod/RemovePaymentMethodModal';
import ChoosePaymentMethod from './ChoosePaymentMethod';
import { DebitCardModal } from './DebitCart';
import DirectDeposit from './DirectDeposit';
import { EditPaymentMethodModal } from './EditPaymentMethod';
import PaymentMethodField from './PaymentMethodField';
import { AddPayPalAccountModal } from './PayPal';
import useImmediatelyForm from './useImmediatelyForm';
import { AddVenmoAccountModal } from './Venmo';

enum StepsStates {
  notInitiated = '',
  depositOrDebit = 'depositOrDebit',
  depositOptionsChoosing = 'depositOptionsChoosing',
  debitOptionsChoosing = 'debitOptionsChoosing',
  payPalOptionsChoosing = 'payPalOptionsChoosing',
  venmoOptionsChoosing = 'venmoOptionsChoosing',
  paymentMethodAdded = 'paymentMethodAdded',
}

enum EditStepsStates {
  notInitiated = '',
  removePaymentMethod = 'removePaymentMethod',
  editPaymentMethod = 'editPaymentMethod',
  paymentMethodRemoved = 'paymentMethodRemoved',
}

interface Props {
  isLoading?: boolean;
  type: 'full' | 'small';
}

const Immediately: React.FC<Props> = ({ isLoading, type }) => {
  const history = useHistory();

  const [step, setSteps] = useState(StepsStates.notInitiated);
  const [editStep, setEditSteps] = useState(EditStepsStates.notInitiated);
  const [message, setMessage] = useState<{ title?: string, desctiption?: string }>({});
  const [successVisible, setSuccessVisible] = useState(false);
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const { t } = useTranslation();
  const { save, isLoading: addCheckPaymentLoading } = useAddPaymentMethodQuery();
  const {
    isLoading: isPaymentMethodsLoading,
    refetch,
  } = useGetPaymentMethods();
  const handleSetChecked = useStore((state) => state.handleSetChecked);
  const editableMethod = useStore((state) => state.editableMethod);
  const { setBankAccountData } = useBank();
  const {
    externalBankAccounts, isLoading: isBankAccountLoading,
  } = useGetExternalBankAccountsQuery();
  const {
    fields,
    isLoading: isFormDataLoading,
    // isPaymentMethodExist,
  } = useImmediatelyForm(() => setSteps(StepsStates.depositOrDebit));

  useEffect(() => {
    setBankAccountData(externalBankAccounts[0] as BankInfo);
  }, [externalBankAccounts, setBankAccountData]);

  const handleRedirect = useCallback(() => {
    history.push(ROUTES.HOME);
  }, [history]);

  const chooseCheckHandler = useCallback(async () => {
    const data = await save({
      paymentType: PaymentMethodType.CHECK,
      paymentOwnerType: PaymentMethodOwnerType.EMPLOYEE,
    });
    handleSetChecked(`${data.id}`);
  }, [save, handleSetChecked]);

  const handleRemoveSuccess = useCallback(() => {
    refetch();
    setEditSteps(EditStepsStates.paymentMethodRemoved);
  }, [refetch]);

  const handleEditSuccess = useCallback(() => {
    refetch();
    setEditSteps(EditStepsStates.notInitiated);
  }, [refetch]);

  return (
    <>
      {step === StepsStates.depositOrDebit && (
        <ChoosePaymentMethod
          onClose={() => setSteps(StepsStates.notInitiated)}
          chooseDeposit={() => setSteps(StepsStates.depositOptionsChoosing)}
          chooseDebitCard={() => setSteps(StepsStates.debitOptionsChoosing)}
          choosePayPal={() => setSteps(StepsStates.payPalOptionsChoosing)}
          chooseVenmo={() => setSteps(StepsStates.venmoOptionsChoosing)}
          chooseCheck={chooseCheckHandler}
          removePaymentMethod={() => setEditSteps(EditStepsStates.removePaymentMethod)}
          editPaymentMethod={() => setEditSteps(EditStepsStates.editPaymentMethod)}
        />
      )}

      {step === StepsStates.depositOptionsChoosing && (
        <DirectDeposit
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {step === StepsStates.payPalOptionsChoosing && (
        <AddPayPalAccountModal
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {step === StepsStates.venmoOptionsChoosing && (
        <AddVenmoAccountModal
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {step === StepsStates.debitOptionsChoosing && (
        <DebitCardModal
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {editStep === EditStepsStates.removePaymentMethod && (
        <RemovePaymentMethodModal
          onClose={() => setEditSteps(EditStepsStates.notInitiated)}
          onBack={() => setEditSteps(EditStepsStates.notInitiated)}
          onSuccess={handleRemoveSuccess}
          methodForRemove={editableMethod}
        />
      )}

      {editStep === EditStepsStates.editPaymentMethod && (
        <EditPaymentMethodModal
          onClose={() => setEditSteps(EditStepsStates.notInitiated)}
          onBack={() => setEditSteps(EditStepsStates.notInitiated)}
          onSuccess={handleEditSuccess}
          methodForEdit={editableMethod}
        />
      )}

      <SuccessModal
        header={t('Payment method added successfully!')}
        buttonText={t('Got It!')}
        visible={step === StepsStates.paymentMethodAdded}
        onSetVisible={() => setSteps(StepsStates.depositOrDebit)}
        buttonStyle={{ width: '160px' }}
      />

      <SuccessModal
        header={t('Your payment method has been removed successfully!')}
        buttonText={t('Got It!')}
        visible={editStep === EditStepsStates.paymentMethodRemoved}
        onSetVisible={() => setEditSteps(EditStepsStates.notInitiated)}
        buttonStyle={{ width: '200px' }}
      />

      <ConfirmCancelModal
        visible={confirmCancelVisible}
        onSetVisible={setConfirmCancelVisible}
        onSubmit={handleRedirect}
      />
      <SuccessModal
        header={t('Your request is approved')}
        helptext={t(`Your payment will be issued as soon as possible based
        on your preferred payment method.`)}
        buttonText={t('Back to Account Details')}
        visible={successVisible}
        onSetVisible={setSuccessVisible}
        onSubmit={handleRedirect}
      />
      <PendingModal
        header={message?.title || ''}
        helptext={message?.desctiption}
        buttonText={t('Close')}
        visible={Boolean(Object.keys(message).length)}
        onSetVisible={() => setMessage({})}
      />

      {
        (
          isLoading
          || isFormDataLoading
          || isBankAccountLoading
          || addCheckPaymentLoading
          || isPaymentMethodsLoading
        ) ? (
            // eslint-disable-next-line react/jsx-indent
            <Preloader />
          ) : (
            <>
              {
                type === 'full' && (
                  <>
                    <Inscription margin={{ bottom: 'spacing24' }} color="textBody" size="20px" lineHeight="28px">
                      {t('How Should We Reimburse You?')}
                    </Inscription>
                    <FlexForm
                      paddingless
                      fields={fields}
                      contentStyles={{ margin: 0 }}
                      isThinMode
                    />
                    {/* {isPaymentMethodExist && <Box height={spacings.l} />} */}
                    <Box height={spacings.l} />
                  </>
                )
              }
              {
                type === 'small' && (
                  <>
                    <Text weight={700} size="16px" margin={{ bottom: 'spacing12' }}>
                      {t('We’ll pay the claim to your default payment method')}
                    </Text>
                    <PaymentMethodField onAdd={() => setSteps(StepsStates.depositOrDebit)} />
                    <Box height={spacings.l} />
                  </>
                )
              }
            </>
          )
      }
    </>
  );
};
export default Immediately;
