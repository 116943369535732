import { baseStoreCreator } from '@common-fe/common-fe';

export interface EditEmail {
  isEmployeeEmailPreferred: boolean;
}

export const DEFAULT_VALUES: EditEmail = {
  isEmployeeEmailPreferred: true,
};

export const useStore = baseStoreCreator<EditEmail>(DEFAULT_VALUES);

export default useStore;
