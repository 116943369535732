import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, getStylesByBreakpoint,ResponsiveContextWrapper } from '@common-fe/common-fe';

import { Breakpoints } from '@/common/constants';
import useAllowNotifications from '@/modules/core/hooks/useAllowNotifications';
import { NotificationButton } from '@/modules/notification';
import { UserDropdown } from '@/modules/user';
import theme from '@/styles/theme';

import useMenuItemList from '../hooks/useMenuItemList';
import Logo from '../Logo';

import {
  Wrapper,
} from './HorizontalMenu.styles';
import HorizontalMenuOption from './HorizontalMenuOption';

const MAXIMUM_NUMBER_MENU_OPTIONS = 5;
const HorizontalMenu: React.FC = () => {
  const { pathname } = useLocation();
  const isNotificationsAllowed = useAllowNotifications();
  const history = useHistory();
  const { formattedList } = useMenuItemList();
  const filteredList = formattedList?.filter((el) => !el.delimiter);
  const moreOptions = filteredList.slice(MAXIMUM_NUMBER_MENU_OPTIONS, filteredList?.length);
  const handleSetTab = useCallback((value: string, url?: string) => {
    if (pathname !== url && url) history.push(`${url}?menu=true`);
  }, [history, pathname]);
  const handleClickLogo = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <>
      <Wrapper
        justify="center"
        align="center"
        pad={{ horizontal: 'spacing24', vertical: 'spacing12' }}
        data-testid="horizontal_menu_wrapper"
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          width={theme.defaultContentWidth}
          style={{ position: 'relative' }}
        >
          <Logo minify={false} horizontalMode onClick={handleClickLogo} />
          <Box direction="row" align="center">
            <ResponsiveContextWrapper breakpoints={[Breakpoints.LARGE, Breakpoints.MEDIUM]}>
              <Box
                direction="row"
                border={{ color: 'border2', side: 'right', size: 'small' }}
                pad={{ right: 'spacing16' }}
                style={getStylesByBreakpoint(Breakpoints.MEDIUM, { marginRight: 'spacing16' }) || {}}
              >
                {filteredList?.slice(0, MAXIMUM_NUMBER_MENU_OPTIONS)?.map((item) => (
                  <HorizontalMenuOption
                    path={pathname}
                    key={item.key}
                    currentKey={item.key}
                    title={item.title}
                    options={item.options}
                    url={item.url}
                    onPick={handleSetTab}
                    link={item.link}
                  />
                ))}
                {
                  moreOptions?.length > 0
                    && (
                      <HorizontalMenuOption
                        key="more"
                        path={pathname}
                        currentKey="more"
                        title="More"
                        options={moreOptions}
                        onPick={handleSetTab}
                      />
                    )
                }
              </Box>
            </ResponsiveContextWrapper>
            <Box
              direction="row"
              align="center"
              style={
                getStylesByBreakpoint(Breakpoints.LARGE,
                  {
                    position: 'absolute',
                    right: '-130px',
                  }) || {
                  position: 'relative',
                  right: '0',
                }
              }
            >
              {
                isNotificationsAllowed && (
                  <NotificationButton />
                )
              }
              <UserDropdown />
            </Box>
          </Box>
        </Box>
      </Wrapper>
      <ResponsiveContextWrapper breakpoints={[Breakpoints.SMALL]}>
        <Wrapper
          justify="center"
          align="center"
          pad={{ horizontal: 'spacing24', vertical: 'spacing12' }}
        >
          <Box
            direction="row"
            align="center"
            justify="between"
            width={theme.defaultContentWidth}
            style={{ position: 'relative' }}
          >
            <Box
              direction="row"
              pad={{ right: 'spacing16' }}
            >
              {filteredList?.slice(0, MAXIMUM_NUMBER_MENU_OPTIONS)?.map((item) => (
                <HorizontalMenuOption
                  path={pathname}
                  key={item.key}
                  currentKey={item.key}
                  title={item.title}
                  options={item.options}
                  url={item.url}
                  onPick={handleSetTab}
                  link={item.link}
                />
              ))}
            </Box>
            {
              moreOptions?.length > 0
                && (
                  <HorizontalMenuOption
                    key="more"
                    path={pathname}
                    currentKey="more"
                    title="More"
                    options={moreOptions}
                    onPick={handleSetTab}
                    border
                    contentPosition
                  />
                )
            }
          </Box>
        </Wrapper>
      </ResponsiveContextWrapper>
    </>
  );
};

export default HorizontalMenu;
