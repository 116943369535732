import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

import { FundsPayload } from '../../SelfDirected.types';
import { useFundsList } from '../../SelfDirectedFunds/hooks';

interface Params {
  accountId: string;
  excludedIds: string[];
}
export default (params: Params) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchString, setSearchString] = useState('');
  const { data = [], isLoading } = useFundsList({
    accountId: params.accountId,
    searchParams: {
      page: 0,
      perPage: 500,
      sortBy: 'name,asc',
    },
  });
  const filteredExcludedData = useMemo(() => {
    const list = data.filter((item) => !params.excludedIds.includes(item.id));

    if (!searchString) {
      return list;
    }
    return list.filter((data) => _.toLower(data.title).includes(searchString.toLowerCase()));
  }, [data, params.excludedIds, searchString]);

  const selectedData = useMemo(() => {
    const results: FundsPayload[] = [];
    selectedIds.forEach((id) => {
      const foundItem = data.find((item) => item.id === id);
      if (foundItem) {
        results.push(foundItem);
      }
    });
    return results;
  }, [data, selectedIds]);
  const filteredData = useMemo(() => {
    const dataWithoutSelected = filteredExcludedData.filter((item) => !selectedIds.includes(item.id));
    return dataWithoutSelected.filter((data) => _.toLower(data.title).includes(searchString.toLowerCase()));
  }, [filteredExcludedData, searchString, selectedIds]);

  const isSearchMode = useMemo(() => !_.isEmpty(searchString), [searchString]);
  const isSelectedMode = useMemo(() => !_.isEmpty(selectedIds), [selectedIds]);
  const handleToggleItem = useCallback(
    (id: string) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((currentId) => currentId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );
  const total = useMemo(() => {
    return filteredExcludedData.length;
  }, [filteredExcludedData.length]);
  return {
    total,
    isSelectedMode,
    handleToggleItem,
    isSearchMode,
    searchString,
    setSearchString,
    isLoading,
    data: filteredData,
    selectedData,
  };
};
