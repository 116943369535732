import { useEffect,useMemo } from 'react';

import { useInvestmentsFieldsSetter } from '@/modules/investments/StartInvesting/hooks';

import { InvestmentPaths, Investor,InvestorDto } from '../investments.types';
import { useInvestorByIdQuery } from '../queries';
interface Params {
  isSwitchMode?: boolean;
}
export default (params?: Params) => {
  const {
    handleSetValues,
  } = useInvestmentsFieldsSetter(params);
  const { data: investor, isLoading, isSuccess } = useInvestorByIdQuery();
  
  const formatData = (data?: InvestorDto): Investor => ({

    investorId: `${data?.investor_account?.investor_id || data?.investor_id || data?.id || ''}`,
    status: data?.status,
    investorBalance: data?.investor_account?.balance || 0,
    toleranceModel: data?.investor_profile?.tolerance_model
    || data?.investor_profile?.proposed_tolerance_model,
    investorAccountId: `${data?.investor_account?.id || ''}`,
    investorEnrollmentId: `${data?.investor_account?.account_id || ''}`
     || `${data?.account_id || ''}`,
    currentToleranceModel: data?.type === InvestmentPaths.Managed ? data?.investor_profile?.tolerance_model: '',
    type: data?.type as InvestmentPaths,
    pendingTradesAmount: data?.pending_sell_trades_amount || 0,
    investorAccountStatus: data?.investor_account?.status,
    isInvestmentModelSwitching: data?.is_investment_model_switching || false,
    cashBalance: data?.investor_account?.cash_balance || 0,
    equityValue: data?.investor_account?.equity_value || 0,
    initialInvestmentAmount: data?.initial_investment_amount,
    amountsGroupedByInstrumentIds: data?.amounts_grouped_by_instrument_ids,

    id: data?.id,
    investorExternalId: data?.employee_id,
    accountId: data?.account_id,
    externalNumber: data?.investor_account?.external_number,
    balance: ((data?.investor_account?.equity_value || 0) +  (data?.investor_account?.cash_balance || 0)) || data?.initial_investment_amount,
    riskProductExternalId: data?.investor_account?.ria_product_external_id,
    riskUserExternalId: data?.investor_account?.ria_user_external_id,
    accountStatus: data?.investor_account?.status,
    age: data?.age,
    employeeId: data?.employee_id,
    position: data?.employment_profile?.position,
    employmentType: data?.employment_profile?.employment_type,
    broker: data?.employment_profile?.broker,
    director_of: data?.employment_profile?.director_of,
    politically_exposed_names: data?.employment_profile?.politically_exposed_names,
    accountExternalId: `${data?.external_id || ''}`,
    investmentObjectivesType: data?.investor_profile?.investment_objectives_type,
    investmentExperienceType: data?.investor_profile?.investment_experience_type,
    annualIncome: data?.investor_profile?.annual_income,
    networthTotal: data?.investor_profile?.networth_total,
    purposeType: data?.investor_profile?.purpose_type,
    medicalExpensesCoverType: data?.investor_profile?.medical_expenses_cover_type,
    
    riskTolerance: data?.investor_profile?.risk_tolerance,
    proposedToleranceModel: data?.investor_profile?.proposed_tolerance_model,
    investorStatus: data?.status,
    
    employmentProfile: !!data?.employment_profile,
  });
  const formattedData = useMemo(() => {
    return formatData(investor?.data);
  } , [investor]);
  useEffect(() => {
    handleSetValues(formattedData);
  }, [handleSetValues, formattedData]);

  return {
    data: formattedData,
    isLoading,
    isSuccess,
  };
};
