import { storeCreator } from '@/utils/store/store.utils';

interface State {
  aiApproved: boolean;
  isDocumentHasBeenScanned: boolean;
  isCancelScanning: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSetAiApprovedOcrFile: (value: any) => void;
  handleSetIsDocumentHasBeenScanned: (value: boolean) => void;
  handleReset: () => void;
  handleSetIsCancelScanning: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  aiApproved: false,
  isDocumentHasBeenScanned: false,
  isCancelScanning: false,

  handleSetAiApprovedOcrFile: (value) => set((state) => ({
    ...state,
    aiApproved: value,
  })),

  handleSetIsDocumentHasBeenScanned: (value) => set((state) => ({
    ...state,
    isDocumentHasBeenScanned: value,
  })),
  
  handleSetIsCancelScanning: (value) => set((state) => ({
    ...state,
    isCancelScanning: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    aiApproved: false,
    isDocumentHasBeenScanned: false,
  })),
}));
