import _ from 'lodash';

const costFormated = (
  value?: number,
  precision?: boolean,
  optionalPrecision?: boolean,
) => {
  if (value === undefined) {
    return '$0';
  }
  const formatValue = (amount: number) => (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const isInteger = _.round(value) === value;
  if (precision || (optionalPrecision && !isInteger)) {
    return value >= 0 ? `$${formatValue(value)}` : `-$${formatValue(Math.abs(value))}`;
  }
  if (value < 0) {
    return `-$${formatValue(Math.abs(value)).slice(0, -3)}`;
  }
  return `$${formatValue(value).slice(0, -3)}`;
};
export default costFormated;
