import React from 'react';
import {
  Box,   NotificationStatus,   NotificationStatusIcon,
  Text,
} from '@common-fe/common-fe';

interface Props {
  title: string;
  text?: string;
  status?: NotificationStatus;
}
const NotificationListTitle: React.FC<Props> = ({
  title,
  status,
  text,
}) => (
  <Box direction="row" pad={{ vertical: 'spacing12' }} align="center">
    <Box direction="row">
      <Box margin={{ top: '13px' }} pad={{ right: 'xxxs' }}>
        <NotificationStatusIcon status={status} />
      </Box>
      <Box width={{ max: '383px' }}>
        <Text color="textTitle" size="large">
          {title}
        </Text>
        <Text color="textSecondary" size="small" weight={500}>
          {text}
        </Text>
      </Box>
    </Box>
  </Box>
);

export default NotificationListTitle;
