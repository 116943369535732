import { useCallback } from 'react';

// import Auth from '@aws-amplify/auth';
import { refreshToken } from '@/api/api';
import { useAuthStore } from '@/modules/user/stores';
// import Token from '@/utils/handlers/Token';
// import { useQueryClient } from 'react-query';

export default () => {
  // const queryClient = useQueryClient();
  const store = useAuthStore();
  const handleRefreshToken = useCallback(async () => {
    try {
      await refreshToken();
      store.setUser({
        ...store.user,
      });
    // eslint-disable-next-line no-empty
    } catch {}
  }, [store]);
  return {
    handleRefreshToken,
  };
};
