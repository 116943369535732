import { baseStoreCreator } from '@common-fe/common-fe';

export interface PersonalInformationPayload {
  position: string;
  employmentType: string;
  broker?: string;
  director: string | boolean;
  directorOf: string;
  politicallyExposed: string | boolean;
  politicallyExposedNames: string;
  networthTotal: string | number;
  annualIncome: string | number;

  isSynced?: boolean;
}

export const DEFAULT_VALUES: PersonalInformationPayload = {
  position: '',
  employmentType: '',
  broker: '',
  director: '',
  directorOf: '',
  politicallyExposed: '',
  politicallyExposedNames: '',
  networthTotal: '',
  annualIncome: '',

  isSynced: false,

};

export const useStore = baseStoreCreator<PersonalInformationPayload>(DEFAULT_VALUES);

export default useStore;
