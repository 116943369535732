import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Heading, InvestmentsLiquidationReactivate, Text } from '@common-fe/common-fe';

import { useInvestor } from '../../hooks';
import { InvestmentPaths } from '../../investments.types';
import {InvestmentReactivationModal } from '../../InvestmentSwitchModal';

interface Props {
  accountId?: string;
}
const InvestmentReactivate: React.FC<Props> = ({ accountId}) => {
  const [visible,setVisible] = useState(false);
  const {data: { type: investorType }} = useInvestor(accountId);
  const isSelfDirectedMode = useMemo(() => investorType === InvestmentPaths.SelfDirected , [investorType]);
  const handleToggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);
  const { t } = useTranslation();
  return (
    <Box direction="column" pad="l" background="module" round="moduleRound">
      <InvestmentReactivationModal
        isSelfDirectedMode={isSelfDirectedMode} 
        accountId={accountId}
        visible={visible}
        onSetVisible={setVisible} />
      <Box
        direction="row"
        background="canvas"
        elevation="default"
        round="container1Round"
        pad={{ vertical: 's', horizontal: 'l', right: 'xxxl' }}
        border={{ size: 'small', color: 'border2' }}
      >
        <Box style={{ flex: 1 }} justify="center">
          <Heading level={4} margin={{ bottom: 'xs' }}>
            {t('Ready to Invest?')}
          </Heading>
          <Text size="medium">
            {t('Pickup where you left off. Re-activate your investments.')}
          </Text>
          <Box margin={{top: 'l'}}>
            <AppButton onClick={handleToggle} width="180px"> 
              {t('Start Investing')}
            </AppButton>
          </Box>
        </Box>
        <Box margin={{ left: 'l' }}>
          <InvestmentsLiquidationReactivate />
        </Box>
      </Box>
    </Box>
  );
};
export default InvestmentReactivate;


