import { useCallback } from 'react';

export default (text: string) => {
  const copyToClipboard = useCallback(() => {
    if (navigator
       && navigator.clipboard
       && navigator.clipboard.writeText) { return navigator.clipboard.writeText(text); }
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    return '';
  }, [text]);
  return copyToClipboard;
};
