import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription, ReceiptIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import waves from '@/assets/backgrounds/wavesBackground.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const StyledWavesBox = styled(Box)`
  opacity: 0.5;
  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 30px;
    width: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to top, rgba(21, 31, 109, 0), ${({ theme }) => theme.colors.legend} 90%);
  }
`;

interface Props {
  title: string;
}

const UploadFileByLinkHeader: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Box
      data-testid="UploadFilesHeader_wrapper"
      height="40svh"
      width="100svw"
      background={{ color: 'tetriary' }}
      round={{ corner: 'bottom', size: 'container2Round' }}
    >
      <StyledWavesBox
        height="calc(40svh - 55px)"
        margin={{ top: '55px' }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
        background={{ image: `url(${waves})`, size: '200%' }}
        justify="center"
        overflow="hidden"
      />
      <Box
        align="center"
        justify="center"
        height="100%"
        style={{ zIndex: 2 }}
      >
        <Inscription
          size="16px"
          weight={700}
          lineHeight="22px"
        >
          {title}
        </Inscription>

        <Box
          data-testid="UploadFileByQrLinkProcess_receipt_icon"
          margin={{ vertical: 'spacing24' }}
        >
          <ReceiptIcon size="52px" color="canvas" />
        </Box>

        <Inscription
          size="24px"
          weight={700}
          lineHeight="32px"
        >
          {t('Let\'s Find Your Receipt')}
        </Inscription>

        <Box margin={{ top: 'spacing12' }}>
          <Inscription
            size="16px"
            weight={whiteLabelingConfig.fonts?.reimbursementWeight || 400}
            lineHeight="22px"
            textAlign="center"
          >
            {t('Add document from your phone or')}
            <br />
            {t('Use camera to scan new receipt')}
          </Inscription>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadFileByLinkHeader;
