import { Option } from '@common-fe/common-fe';

export interface StatementPayload {
  id: number;
  start_date: string;
  name: string;
  document_id: number;
}

export interface Statement {
  id: string;
  name?: string;
  startDate: string;
  documentId: string;
}

export const SORT_OPTIONS: Option[] = [
  {
    key: 'ASC',
    value: 'Ascending',
  },
  {
    key: 'DESC',
    value: 'Descending',
  },
];
