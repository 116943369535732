import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isOpenNotificationCenter: boolean;
  setNotificationCenterState: (value: boolean) => void;
  notificationsTotal: number;
  dismissedNotificationsTotal: number;
  setNotificationsTotal: (value: number) => void;
  seDismissedNotificationstTotal: (value: number) => void;
}

export const useStore = storeCreator<State>((set) => ({
  isOpenNotificationCenter: false,
  notificationsTotal: 0,
  dismissedNotificationsTotal: 0,
  setNotificationCenterState: (value: boolean) => set(() => ({
    isOpenNotificationCenter: value,
  })),
  setNotificationsTotal: (totalNotifications: number) => set(() => ({
    notificationsTotal: totalNotifications,
  })),
  seDismissedNotificationstTotal: (value: number) => set(() => ({
    dismissedNotificationsTotal: value,
  })),
}));

export default useStore;
