import { storeCreator } from '@/utils/store/store.utils';

import { FundsPayload } from '../../SelfDirected.types';

interface State {
  funds: FundsPayload[];
  setFunds: (values: FundsPayload[]) => void;
  pushFunds: (values: FundsPayload[]) => void;
  removeHighlight: () => void;
  reset: () => void;
}

const useStore = storeCreator<State>((set) => ({
  funds: [],
  setFunds: (values: FundsPayload[]) =>
    set(() => ({
      funds: values,
    })),
  pushFunds: (values: FundsPayload[]) =>
    set((state) => ({
      funds: [...state.funds, ...values],
    })),
  removeHighlight: () =>
    set((state) => ({
      funds: state.funds.map((item) => ({
        ...item,
        isHighlighted: false,
      }))
    })),
  reset: () =>
    set(() => ({
      funds: [],
    })),
}));

export default useStore;
