import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';

export const QUERY_KEY = 'useActivitiesList';
const ENTITY_PATH = 'data.totalElements';
const REFUND_TRANSACTION_TYPE = 'PAYMENT_CARD_RETURN';
const REFUND = 'REFUND';

export interface ActivityDTO {
  'posting_id': number;
  'claim_id': number;
  'authorization_id': number;
  'category_name': string;
  'status_date': string;
  'memo': string;
  'status': string;
  'amount': number;
  'transaction_type': string;
  'has_active_hold': boolean;
}
export interface ActivityRow {
  claimId: number;
  postingId: number;
  amount: number;
  category: string;
  title: string;
  date: string;
  status: string;
  transactionType?: string;
  hasActiveHold?: boolean;
}

interface Params {
  id: string;
  category?: string;
  employeeId?: string
}
const useActivitiesListQuery = ({ id, employeeId }: Params) => {
  const {
    isLoading, isSuccess, isError, data, refetch, isFetched, remove,
  } = useQuery([
    QUERY_KEY,
    id,
    employeeId,
  ],
  () => api.get<ActivityDTO[]>(PATHS.ACCOUNT_ACTIVITIES_BY_ID(id, employeeId)), {
    cacheTime: 0,
    enabled: !!employeeId,
  });

  useEffect(() => {
    if (id && employeeId) refetch();
    else remove();
  }, [id, employeeId, refetch, remove]);

  const isRefund = useCallback(
    (item: ActivityDTO) => item?.transaction_type === REFUND_TRANSACTION_TYPE,
    [],
  );

  const formattedData = useMemo<ActivityRow[]>(
    () => {
      const list = data?.data || [];
      const newList = list.map((item) => ({
        claimId: item.claim_id,
        postingId: item.posting_id,
        amount: item.amount,
        category: isRefund(item) ? REFUND : item.category_name,
        title: isRefund(item) ? `Return ${item.memo}` : item.memo,
        date: item.status_date ? dayjs(item.status_date).format(DEFAULT_DATE_FORMAT) : '',
        status: item.status,
        transactionType: item.transaction_type,
        hasActiveHold: item.has_active_hold,
      }));
      return newList;
    }, [data, isRefund],
  );

  const total = useMemo<number>(() => _.get(data, ENTITY_PATH, 0) || 0, [data]);
  return ({
    data: formattedData,

    isError,
    isLoading,
    isSuccess,
    total,
    refetch,
    isFetched,
  });
};

export default useActivitiesListQuery;
