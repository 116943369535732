import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Inscription } from '@common-fe/common-fe';

import StatusIcon from '@/components/elements/StatusIcon';
import { Status, TransactionStatus } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import { IconWrapper, Title, Wrapper } from '../../TransactionNotification.styles';

interface Props {
  data?: Status;
  employeeFirstName?: string;
}

const SuccessStatusMessage: React.FC<Props> = ({ data, employeeFirstName }) => {
  const { t } = useTranslation();
  const mainText = useMemo(
    () => {
      switch (data?.status) {
      case TransactionStatus.AppliedToDeductible:
        return (
          <Inscription size="large" data-testId="AppliedToDeductible">
            <Inscription size="large" weight="bold">{employeeFirstName}</Inscription>, {t('this claim has been applied to your deductible')}.
          </Inscription>
        );
      case TransactionStatus.OutOfPocket:
        return (
          <Inscription size="large" data-testId="OutOfPocket">
            <Inscription size="large" weight="bold">{employeeFirstName}</Inscription>, {t('this claim was applied to your deductible. Payment to the provider will need to be handled outside of the plan if it hasn\'t already')}.
          </Inscription>
        );
      case TransactionStatus.Paid:
        return <Title data-testId="Paid">{t('All set! The amount requested was approved and paid.')}</Title>;
      default:
        return '';
      }
    },
    [data, employeeFirstName, t],
  );

  if (!mainText) return null;

  return (
    <Wrapper
      success
      data-testid={`success-status-type-${data?.status}`}
      flex-direction="column"
      background="canvas"
      border={{
        size: 'small',
        color: theme.colors.successBorder,
      }}
      elevation="default"
      pad={{
        top: 'l',
        right: 'l',
        bottom: 'l',
        left: '56px',
      }}
    >
      <IconWrapper><StatusIcon status={TransactionStatus.Paid} /></IconWrapper>
      {mainText}
    </Wrapper>
  );
};

export default SuccessStatusMessage;
