import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  isModalVisible: false,

  setIsModalVisible: (value: boolean) => set(() => ({
    isModalVisible: value,
  })),
}));

const useSwitchingModalStore = () => ({ ...useStore() });

export default useSwitchingModalStore;
