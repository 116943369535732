import React from 'react';
import { Grommet as CommonGrommet } from '@common-fe/common-fe';
// import { Grommet as CommonGrommet, combinedTheme as commonTheme } from '@common-fe/common-fe';
import { Grommet } from 'grommet';

import { useCombinedTheme } from '@/modules/core/hooks/useCombinedTheme';

interface Props {
  children: React.ReactNode;
}


// const customStylisPlugin = (context, content, selectors) => {
//   if (context === 2) {
//     // Modify the selectors as needed
//     selectors[0] = selectors[0].replace(/^\.sc-/i, '.myCustomPrefix-');
//   }
// };

const ThemeWrapper: React.FC<Props> = ({ children }) => {
  const combinedTheme = useCombinedTheme();
  return (
    <CommonGrommet theme={combinedTheme}>
      <Grommet theme={combinedTheme}>{children}</Grommet>
    </CommonGrommet>
  );
};
export default ThemeWrapper;
