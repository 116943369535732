import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import { ContinueInAppPopup } from '@/modules/core/components/ContinueInAppPopup';
import { useGetAppLinksQuery } from '@/modules/core/components/ContinueInAppPopup/queries/useGetAppLinks.query';
import useConfirmSignUpQuery from '@/modules/user/components/UserActivation/queries/useConfirmSignUp.query';
import { useAuthStore } from '@/modules/user/stores';
import { useQueryParams } from '@/utils/hooks';

import SignIn from '../SignIn';

import VerifyBlock from './VerifyBlock';

const CODE_QUERY_KEY = 'confirm_code';
const USERNAME_QUERY_KEY = 'username';
const HASH_ID_QUERY_KEY = 'hash_id';

const CONTINUE_ACTIVATION_IN_ELEVATE_APP_LINK = 'elevate://open-app?method=verificationCompleted';
const UserActivation = () => {
  const { inited, auth } = useAuthStore();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const query = useQueryParams();
  const { confirmSignUp } = useConfirmSignUpQuery();
  const code = useMemo(() => query.get(CODE_QUERY_KEY), [query]);
  const username = useMemo(() => query.get(USERNAME_QUERY_KEY), [query]);
  const hashId = useMemo(() => query.get(HASH_ID_QUERY_KEY) || undefined, [query]);

  const [isAppPopupVisible, setIsAppPopupVisible] = useState(true);
  const { appLinks, isLoading, isError } = useGetAppLinksQuery({ hashId });

  const verificationAppLinks = useMemo(() => ({
    ...appLinks,
    defaultAppLink: CONTINUE_ACTIVATION_IN_ELEVATE_APP_LINK,
  }), [appLinks]);

  const activateUser = useCallback(async (currentHashId: string, currentCode: string) => {
    if (auth) {
      return;
    }
    try {
      // await Auth.confirmSignUp(currentUserName, currentCode);
      // await confirmSignUp({
      //   username: currentUserName,
      //   confirmationCode: currentCode
      // });
      await confirmSignUp({
        hashId: currentHashId,
        confirmationCode: currentCode,
      });
      setLoading(false);
      setSuccess(true);
    } catch {
       
      alert('Something went wrong.\nPossible your account already verified. Try to login or reset your password');

      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (code && hashId && username && inited && !auth) {
      activateUser(hashId, code);
    }
   
  }, [activateUser, auth, code, inited, username, hashId]);

  return (
    <SignIn
      header={(
        <>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {success ? (
                <Box margin={{ bottom: 'spacing24' }}>
                  <VerifyBlock onClose={() => setSuccess(false)} />
                </Box>
              ) : null}
            </>
          )}
          {
            !isLoading && !isError && (
              <ContinueInAppPopup
                visible={isAppPopupVisible}
                setVisible={setIsAppPopupVisible}
                appLinks={verificationAppLinks}
              />
            )
          }
        </>
      )}
    >
     
    </SignIn>
  );
};
export default UserActivation;
