import { FileImage } from '@/common/constants';
import { storeCreator } from '@/utils/store/store.utils';

interface StateValues {
  documentId: string,
  files: FileImage[],
}

interface State {
  values: StateValues;
  handleSetValues: (value: Partial<StateValues>) => void;
  handleReset: () => void;
}

const DEFAULT_VALUES: StateValues = {
  documentId: '',
  files: [],
};

const useFilesInDocumentStore = storeCreator<State>((set) => ({
  values: DEFAULT_VALUES,
  handleSetValues: (value: Partial<StateValues>) => set((state) => ({
    ...state,
    values: {
      ...state.values,
      ...value,
    },
  })),
  handleReset: () => set((state) => ({
    ...state,
    values: DEFAULT_VALUES,
  })),
}));

export default useFilesInDocumentStore;
