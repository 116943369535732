import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box,  Text } from '@common-fe/common-fe';

interface Props {
  onOpen: () => void;
}
const ChangeCitizenshipBlock: React.FC<Props> = ({ onOpen }) => {
  const { t } = useTranslation();
  return (
    <Box direction="column" pad={{bottom: 'l'}} margin={{bottom: 's'}} border={{side: 'bottom', color: 'border1'}}>
      <Box
        pad="s"
        border={{ color: 'border2' }}
        round="container1Round"
        background="canvas"
        style={{ position: 'relative' }}
        elevation="default"
      >
        <Box
          margin={{ horizontal: 's' }}
          border={{ side: 'top', size: '2px', color: 'warning' }}
          style={{ position: 'absolute', top: '-1px', left: '0', right: '0' }}
        >
        </Box>
        <Box
          round="container2Round"
          pad={{ vertical: 'm', horizontal: 'l' }}
          border={{ color: 'warningBorder' }}
          background="warningContainer"
        >
          <Box direction="row" height="32px" align="center" justify="between">
            <Box>
              <Text>
                {t('Unfortunately, as a citizen of the selected country, you cannot start investing.')}
              </Text>
            </Box>
            <AppButton onClick={onOpen} buttonType="quaternary">{t('Change Citizenship')}</AppButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ChangeCitizenshipBlock;
