import {
  useEffect,
} from 'react';
import { Enrollment } from '@common-fe/common-fe';

import { useInvestor } from '@/modules/investments/hooks';
import { useMyEnrollments } from '@/modules/transaction';

import useAutoInvestmentQuery from '../queries/useAutoInvestment.query';
import { useAutoInvestmentsStore } from '../stores';

interface Props {
  currentMyAccount?: Enrollment;
}

export default (props?: Props) => {
  const { mainHSAEnrollment } = useMyEnrollments();
  const isAutoInvestmentsExist = useAutoInvestmentsStore((state) => state.isAutoInvestmentExist);
  const setAutoInvestmentsExist = useAutoInvestmentsStore((state) => state.setAutoInvestmentExist);

  const { data: { investorAccountId } } = useInvestor(`${mainHSAEnrollment ? mainHSAEnrollment.id : props?.currentMyAccount?.id}`);

  const { isSuccess, isError, data } = useAutoInvestmentQuery(investorAccountId);

  useEffect(() => {
    if (isSuccess && investorAccountId) {
      setAutoInvestmentsExist(true);
    }
    if (isError) {
      setAutoInvestmentsExist(false);
    }
  }, [data.status, investorAccountId, isSuccess, isError, setAutoInvestmentsExist]);

  return {
    isAutoInvestmentsExist,
  };
};
