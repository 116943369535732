import React from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Text } from '@common-fe/common-fe';

interface Props {
  onSignInRedirect: () => void;
}
const UserxistError: React.FC<Props> = ({ onSignInRedirect }) => {
  const { t } = useTranslation();
  return (
    <Box direction="column" margin={{ bottom: 'spacing24' }} fill>
      <Text color="danger">{t('A corresponding user account already exists.')}</Text>
      <Text>
        {t('Please go to the ')}
        <Anchor onClick={onSignInRedirect}>
          Sign in page
        </Anchor>
        {t(', and use your credentials to Sign in.')}
      </Text>
    </Box>
  );
};

export default UserxistError;
