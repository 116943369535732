import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { QuestionnaireField,QuestionPayloadOption } from '../../cip.constants';
import { useCIPIdentificationQuery } from '../../queries';
import { getFormattedQuestion } from '../../queries/useCIPIdentification.query';
import { useQuestionnaireFieldsQuery } from '../queries';

export default () => {
  const {
    data, handleRefetch,
    isLoading: isCipLoading,
  } = useCIPIdentificationQuery({ enabled: false });
  const [currentData, setCurrentData] = useState<QuestionnaireField[]>([]);
  const { isLoading, handleRetiveQuestions } = useQuestionnaireFieldsQuery({
    idNumber: data.id,
  });
  useEffect(() => {
    if (data) {
      setCurrentData(data.questions);
    }
  }, [data]);

  const handleGetChallenge = useCallback(async () => {
    const newQuestions = await handleRetiveQuestions();
    const gettedQuestion = _.get(newQuestions, 'data.questions.question') as unknown as QuestionPayloadOption[];
    if (!gettedQuestion) {
      return;
    }
    
    const formattedData = gettedQuestion.map((item) => (
      getFormattedQuestion(
        item
      )
    )) ;
    
    setCurrentData(formattedData);
  }, [handleRetiveQuestions]);
  return {
    handleRefetch,
    data: currentData,
    idNumber: data.id,
    isLoading: isCipLoading || isLoading,
    handleGetChallenge,
  };
};
