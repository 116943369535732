import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.iconOnColor};
  border: none;
  padding: 10px 20px 10px 10px;
  align-items: center;
  max-width: 250px;
  
  border-radius: ${({ theme }) => theme.rounds.button1Round} ;

`;
export const Text = styled.p`
  ${({ theme }) => theme.fonts.text14Medium};
  text-align: left;
  margin-left: 12px;
`;

export const IconWrapper = styled.div`
  height: ${({ theme }) => theme.controls.height};
  width: ${({ theme }) => theme.controls.height};
  min-width: ${({ theme }) => theme.controls.height};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  background-color: ${({ theme }) => theme.colors.border2};

`;
