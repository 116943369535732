import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isDelistedPresent?: boolean;
  setDelistedPresent: (value: boolean) => void;
}

const useStore = storeCreator<State>((set) => ({
  isDelistedPresent: false,
  setDelistedPresent: (value: boolean) => set(() => ({
    isDelistedPresent: value
  })),
  
}));

export default useStore;
