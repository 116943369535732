import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { FileImage,FileType } from '@/common/constants';

export const QUERY_KEY = 'getFilesByDocumentId';

const getFilesByDocumentId = (documentId?: string) => api
  .get(PATHS.GET_FILES_BY_DOCUMENT_ID(documentId));

const getFileType = (url: string): FileType | undefined => {
  const formatStr = url?.split('?')?.[0]?.split('.')?.pop();
  const type = formatStr ? formatStr.trim().toUpperCase() : null;

  return FileType[type as keyof typeof FileType];
};

interface FileUrls {
  id: number;
  s3_url: string;
}

const useFilesByDocumentIdQuery = (documentId?: string) => {
  const {
    isLoading,
    error,
    data,
    isSuccess,
    refetch,
  } = useQuery(
    [QUERY_KEY, documentId],
    () => getFilesByDocumentId(documentId),
    { enabled: Boolean(documentId), cacheTime: 0 },
  );

  const formattedData = useMemo(() => data?.data?.files?.map((file: FileUrls) => ({
    id: toString(file.id),
    path: file.s3_url,
    url: file.s3_url,
    type: getFileType(file.s3_url),
    preview: '',
  })), [data]);

  return {
    files: formattedData as FileImage[],
    error,
    isLoading,
    isSuccess,
    refetch,
  };
};

export default useFilesByDocumentIdQuery;
