import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { toNumber, toString } from 'lodash';

import { api } from '@/api';
import { API_KEY, GATEWAY_KEY } from '@/api/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import { CONFIG } from '@/utils';

import {
  OcrWarningFields,
  OcrWarnings,
  OcrWarningType,
  ParsedFileByOcr,
  ParsedFileByOcrPayload,
  ParsedOcrWarnings,
  RecommendedValue,
} from './fileScan.types';

export const formatWarnings = (parsedWarnings?: ParsedOcrWarnings[]) => {
  const ocrWarnings: OcrWarnings = {
    [OcrWarningType.MISSING]: [],
    [OcrWarningType.OUT_OF_COVERAGE_DATE]: [],
    [OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE]: [],
    [OcrWarningType.DUPLICATE_RECEIPT]: [],
  };

  if (!parsedWarnings || parsedWarnings.length === 0) {
    return ocrWarnings;
  }

  parsedWarnings.forEach((warning) => {
    const warningFields = warning?.fields?.map((fieldName) => OcrWarningFields[fieldName]);
    if (warningFields && warning?.type) {
      ocrWarnings[warning.type]?.push(...warningFields);
    }
  });

  return ocrWarnings;
};

const getByMaxConfidence = (options?: RecommendedValue[]) => options
  ?.sort((prev, next) => toNumber(prev?.confidence || 0) > toNumber(next?.confidence || 0) ? -1 : 1)?.[0];

const getDefaultValue = (data: ParsedFileByOcrPayload): Partial<ParsedFileByOcr> => {
  const eligibleExpenseServicesLength = data?.eligible_expenses?.length || 0;
  const expensesLength = data?.expenses?.length || 0;

  if (eligibleExpenseServicesLength && expensesLength > 1) {
    const mostExpensiveSuggestedValue = data?.eligible_expenses?.sort((prev, next) =>
      toNumber(prev.amount) < toNumber(next.amount) ? 1 : -1
    )[0];

    return {
      patientName: mostExpensiveSuggestedValue?.patient,
      serviceDescription: mostExpensiveSuggestedValue?.service,
      amount: toString(mostExpensiveSuggestedValue?.amount),
      serviceDate: mostExpensiveSuggestedValue?.service_dates?.[0]
        ? dayjs(mostExpensiveSuggestedValue?.service_dates?.[0]).format(DEFAULT_DATE_FORMAT)
        : '',
    };
  }

  const patientByMaxConfidence = getByMaxConfidence(data?.patients)?.value;
  const serviceDescriptionMaxConfidence = getByMaxConfidence(data?.service_descriptions)?.value;
  const amountByMaxConfidence = getByMaxConfidence(data?.amounts)?.value;
  const serviceDateByMaxConfidence = getByMaxConfidence(data?.service_dates)?.value;

  return {
    patientName: patientByMaxConfidence,
    serviceDescription: serviceDescriptionMaxConfidence,
    amount: amountByMaxConfidence,
    serviceDate: serviceDateByMaxConfidence
      ? dayjs(serviceDateByMaxConfidence).format(DEFAULT_DATE_FORMAT)
      : '',
  };
};

const formatData = (data: ParsedFileByOcrPayload): ParsedFileByOcr => {
  const defaultValues = getDefaultValue(data);

  return ({
    ocrReceiptId: toString(data?.ocr_receipt_id),
    providerName: getByMaxConfidence(data?.facility_names)?.value || '',
    patientName: defaultValues?.patientName || '',
    serviceDate: defaultValues?.serviceDate || '',
    amount: defaultValues?.amount || '',
    serviceDescription: defaultValues?.serviceDescription || '',
    zip: data?.address?.address_dto?.zip || '',
    address1: data?.address?.address_dto?.address_line_1 || '',
    address2: data?.address?.address_dto?.address_line_2 || '',
    city: data?.address?.address_dto?.city || '',
    state: data?.address?.address_dto?.state || '',
    facilityNames: data?.facility_names,
    patients: data?.patients,
    amounts: data?.amounts,
    serviceDates: data?.service_dates,
    serviceDescriptions: data?.service_descriptions,
    serviceGroups: data?.service_groups?.map((serviceGroup) => ({
      serviceDate: serviceGroup?.service_date,
      services: serviceGroup?.services,
    })),
    accountId: data?.accounts?.[0]?.value || '',
    invoiceId: data?.invoices?.[0]?.value || '',
    warnings: data?.warnings?.map((warning) => ({
      fields: warning?.fields,
      type: warning?.type,
      warning: warning?.warning,
      duplicateDocumentId: toString(warning?.duplicate_document_id),
      duplicateClaim: {
        claimStatusType: warning?.duplicate_claim?.claim_status_type,
        duplicateClaimId: toString(warning?.duplicate_claim?.duplicate_claim_id),
      },
    })),
    warningsFields: formatWarnings(data?.warnings),
    eligibleExpenseServices: data?.eligible_expenses?.map((eligibleExpense) => ({
      patient: eligibleExpense?.patient,
      service: eligibleExpense?.service,
      amount: toString(eligibleExpense?.amount),
      serviceDates: eligibleExpense?.service_dates
        ?.map((serviceDate) => serviceDate ? dayjs(serviceDate).format(DEFAULT_DATE_FORMAT) : ''),
    })),
    expenses: data?.expenses?.map((expense) => ({
      amount: expense?.amount,
      category: expense?.category,
      discount: expense?.discount,
      expense: expense?.expense,
      number: expense?.number,
      patient: expense?.patient,
      service: expense?.service,
    })),
  });
};

const useGetScannedDocumentsQuery = () => {
  const controller = useMemo(() => new AbortController(), []);

  const getScannedDocument = async (id: string) => {
    if (!id) {
      return {};
    }

    try {
      const url = `${CONFIG.BASE_URL?.replace(GATEWAY_KEY, API_KEY)}${PATHS.REIMBURSE_SCANNED_FILE(id)}`;
      const { data } = await api.get(url, {
        signal: controller.signal,
      });

      return formatData(data);
    } catch (error) {
      return {} as ParsedFileByOcr;
    }
  };

  const cancel = useCallback(() => {
    controller.abort();
  }, [controller]);


  return { getScannedDocument, cancel };
};

export default useGetScannedDocumentsQuery;
