import React from 'react';
import { Box, Option,RadioButton ,Text } from '@common-fe/common-fe';

interface Props {
  value: string;
  onChangeValue:(value: string) => void;
  label: string;
  options: Option[];
}
const QuestionListItem: React.FC<Props> = ({
  value, onChangeValue, options, label,
}) => (
  <Box
    direction="row"
    data-testid="question-list-item"
    round="container1Round"
    margin={{ bottom: 'l' }}
    pad={{ vertical: 'l', horizontal: 'xl' }}
    background="canvas"
  >

    <Box style={{ flex: 1 }}>
      <Text size="medium" weight={700}>{label}</Text>
    </Box>
    <Box style={{ flex: 1 }}>
      {options.map((option) => (
        <Box
          key={option.key}
          margin={{ bottom: 'm' }}
        >
          <RadioButton
            name={`${option.key}`}
            data-testid={`question-list-item_${option.key}`}
            checked={value === option.key}
            onChange={() => onChangeValue(`${option.key}`)}
            label={(
              <Text>
                {option.value as string}
              </Text>
            )}
          />
        </Box>
      ))}

    </Box>

  </Box>
);
export default QuestionListItem;
