import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Preloader,
  RoundPendingIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

const DESCRIPTION = `Are you sure that you want to create a one-time contribution?
 It will cancel your recurring contribution`;

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  isLoading?: boolean;
}

export const WarningOneTimeContributionModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper testId="WarningOneTimeContributionModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24', horizontal: 'spacing96' }}>
        <RoundPendingIcon color="warning" />
        <Text
          data-testid="WarningOneTimeContributionModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {t('Warning')}
        </Text>

        <Text
          data-testid="WarningOneTimeContributionModal_modal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {t(DESCRIPTION)}
        </Text>

        <Box direction="row" gap="spacing12">
          <AppButton
            buttonType="secondary"
            testId="WarningOneTimeContributionModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            width="140px"
          >
            {t('Cancel')}
          </AppButton>
          <AppButton
            testId="WarningOneTimeContributionModal_modal_submit"
            onClick={onSubmit}
            width="140px"
          >
            {isLoading ? <Preloader /> : t('Continue')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
