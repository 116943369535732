import { useMemo } from 'react';
import { capitalizeFirstLetter, ListItem, ListItemBase, ListItemType } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import REGEXP from '@/common/regexp';
import { Dependent } from '@/modules/user/components/ManageProfile/components/Dependents/dependents.types';
import theme from '@/styles/theme';

const FIELD_PLACEHOLDER = '-';

export const HEADERS: ListItemBase[] = [
  { key: 'firstName', title: 'First name' },
  { key: 'secondName', title: 'Second name' },
  { key: 'dateOfBirth', title: 'Date of birth' },
  { key: 'relationship', title: 'Relationship' },
];

const highlightedRowStyle = {
  borderSize: '2px',
  borderColor: theme.colors.accentActive,
  boxShadow: theme.shadows.highlighted['box-shadow'],
};

const useDependentsList = (dependents?: Dependent[], highlightedDependentId?: string) => {
  const sortedDependents = useMemo(() => {
    if (!dependents?.length) {
      return [];
    }
  
    return [...dependents].sort((prev, next) => {
      const firstNamePrev = prev?.firstName || '';
      const firstNameNext = next?.firstName || '';
      return firstNamePrev.localeCompare(firstNameNext);
    });
  }, [dependents]);

  const formattedList = useMemo<ListItem[]>(
    () =>
      sortedDependents?.map((dependent) => ({
        id: `${dependent.id}`,
        rowStyle: (highlightedDependentId && highlightedDependentId === dependent.id) ? highlightedRowStyle : undefined,
        dropAlign: { bottom: 'top', right: 'right' },
        fields: [
          {
            key: 'firstName',
            type: ListItemType.Text,
            title: dependent.firstName || FIELD_PLACEHOLDER,
            flex: 1,
            style: { fontSize: theme.fonts.text16Medium['font-size'] },
          },
          {
            key: 'secondName',
            type: ListItemType.Text,
            title: dependent.lastName || FIELD_PLACEHOLDER,
            flex: 1,
            style: { fontSize: theme.fonts.text16Medium['font-size'] },
          },
          {
            key: 'dateOfBirth',
            type: ListItemType.Text,
            title: dependent.birthOn ? dayjs(dependent.birthOn).format(DEFAULT_DATE_FORMAT) : FIELD_PLACEHOLDER,
            flex: 1,
            style: { fontSize: theme.fonts.text16Medium['font-size'] },
          },
          {
            key: 'relationship',
            type: ListItemType.Text,
            title: capitalizeFirstLetter(dependent?.relationshipType?.replace(REGEXP.DASH_SYMBOL, ' ') || '') || FIELD_PLACEHOLDER,
            flex: 1,
            style: { fontSize: theme.fonts.text16Medium['font-size'] },
          },
        ],
      })) || [],
    [sortedDependents, highlightedDependentId]
  );

  return formattedList;
};

export default useDependentsList;
