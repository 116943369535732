 
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api, mock } from '@/api';
import { PATHS } from '@/common';
import { DAYS_OF_DISABLED_REQUESTS } from '@/common/constants';
import { capitalizeFirstLetter } from '@/utils';

import {
  BankTypeEnum,
  CardDto,
  CardHolderDto,
  CardHoldersResponse, CardResponse,
  CardStatusEnum,
  ChangeCardStatusMutationArgs,
} from '../components/Cards/Cards.types';

enum CardHolderType {
  PRIMARY = 'PRIMARY',
}

export const HOLDER_MOCK_LIST: CardHolderDto[] = [
  {
    name: 'Me (Linda Smith)',
    dependentId: '2781678312',
    employeeId: '2781678312',
    firstName: 'Me (Linda',
    lastName: 'Smith)',
    email: 'linda1smith@gmail.com',
    dateOfBirth: dayjs('10/10/1980').toISOString(),
    relationship: '1',
  },
  {
    name: 'Brian Smith',
    dependentId: '783672487123',
    employeeId: '783672487123',
    firstName: 'Brian',
    lastName: 'Smith',
    email: 'brian1smith@gmail.com',
    dateOfBirth: dayjs('01/10/1990').toISOString(),
    relationship: '2',
  },
];

export const MOCK_CARDS_LIST: CardDto[] = [
  {
    holder: HOLDER_MOCK_LIST[0],
    status: CardStatusEnum.ACTIVE,
    last4Number: '1234',
    expDate: dayjs('01/10/22').toDate(),
    id: _.uniqueId(),
    accountIds: [1],
    cardAccountId: 1,
    bankType: BankTypeEnum.VISA,
    cardRequestDate: dayjs().toDate(),
    mailedDate: dayjs().toDate(),
    activationDate: dayjs().toDate(),
    primary: true,
    memberSequenceIdentifier: 1,
    alternateAccountId: '123456789',
  },
  {
    holder: HOLDER_MOCK_LIST[0],
    status: CardStatusEnum.ACTIVE,
    last4Number: '4321',
    expDate: dayjs('02/10/22').toDate(),
    id: _.uniqueId(),
    accountIds: [1],
    cardAccountId: 1,
    bankType: BankTypeEnum.MASTERCARD,
    cardRequestDate: dayjs().toDate(),
    mailedDate: dayjs().toDate(),
    activationDate: dayjs().toDate(),
    primary: false,
    memberSequenceIdentifier: 1,
    alternateAccountId: '123456789',
  },
  {
    holder: HOLDER_MOCK_LIST[0],
    status: CardStatusEnum.FROZEN,
    last4Number: '1234',
    expDate: dayjs('03/10/22').toDate(),
    id: _.uniqueId(),
    accountIds: [1],
    cardAccountId: 1,
    bankType: BankTypeEnum.MASTERCARD,
    cardRequestDate: dayjs().toDate(),
    mailedDate: dayjs().toDate(),
    activationDate: dayjs().toDate(),
    primary: false,
    memberSequenceIdentifier: 1,
    alternateAccountId: '123456789',
  },
  {
    holder: HOLDER_MOCK_LIST[1],
    status: CardStatusEnum.ACTIVE,
    last4Number: '1234',
    expDate: dayjs('04/10/22').toDate(),
    id: _.uniqueId(),
    accountIds: [1],
    cardAccountId: 1,
    bankType: BankTypeEnum.MASTERCARD,
    cardRequestDate: dayjs().toDate(),
    mailedDate: dayjs().toDate(),
    activationDate: dayjs().toDate(),
    primary: false,
    memberSequenceIdentifier: 1,
    alternateAccountId: '123456789',
  },
  {
    holder: HOLDER_MOCK_LIST[1],
    status: CardStatusEnum.FROZEN,
    last4Number: '1234',
    expDate: dayjs('05/10/22').toDate(),
    id: _.uniqueId(),
    accountIds: [1],
    cardAccountId: 1,
    bankType: BankTypeEnum.VISA,
    cardRequestDate: dayjs().toDate(),
    mailedDate: dayjs().toDate(),
    activationDate: dayjs().toDate(),
    primary: false,
    memberSequenceIdentifier: 1,
    alternateAccountId: '123456789',
  },
  {
    holder: HOLDER_MOCK_LIST[1],
    status: CardStatusEnum.PENDING_CLOSED,
    last4Number: '1234',
    expDate: dayjs('06/10/22').toDate(),
    id: _.uniqueId(),
    accountIds: [1],
    cardAccountId: 1,
    bankType: BankTypeEnum.VISA,
    cardRequestDate: dayjs().toDate(),
    mailedDate: dayjs().toDate(),
    activationDate: dayjs().toDate(),
    primary: false,
    memberSequenceIdentifier: 1,
    alternateAccountId: '123456789',
  },
  {
    holder: HOLDER_MOCK_LIST[1],
    status: CardStatusEnum.CLOSED,
    last4Number: '1234',
    expDate: dayjs('07/10/22').toDate(),
    id: _.uniqueId(),
    accountIds: [1],
    cardAccountId: 1,
    bankType: BankTypeEnum.MASTERCARD,
    cardRequestDate: dayjs().toDate(),
    mailedDate: dayjs().toDate(),
    activationDate: dayjs().toDate(),
    primary: false,
    memberSequenceIdentifier: 1,
    alternateAccountId: '123456789',
  },
];

export interface CardsMap {
  [holderName: string]: {
    [cardStatus in CardStatusEnum]: CardDto[]
  }
}

const GET_CARDS_QUERY_KEY = 'getCardsQueryKey';
const GET_CARDS_QUERY_PARAMS_KEYS = {
  EMPLOYEE_ID: 'employee_id',
  DEPENDENT_ID: 'dependent_id',
};
export const ROOT_DEPENDENT_ID = 'none';
export const useGetCardsQuery = (
  employeeId?: number | string | null,
  dependentId?: number | string | null,
) => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    if (employeeId) {
      queryGenerator.set(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID, `${employeeId}`);
    }

    if (dependentId && dependentId !== ROOT_DEPENDENT_ID) {
      queryGenerator.set(GET_CARDS_QUERY_PARAMS_KEYS.DEPENDENT_ID, `${dependentId || ROOT_DEPENDENT_ID}`);
    }

    return queryGenerator;
  }, [
    employeeId,
    dependentId,
  ]);

  const {
    data,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    PATHS.CARD_HOLDERS(query.toString()),
    () => (query.get(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID)
      ? api.get<CardHoldersResponse>(PATHS.CARD_HOLDERS(query.toString()))
      : null),
    {
      enabled: false,
    },
  );

  const getHolderName = useCallback((firstName?: string, lastName?: string) => (firstName && lastName ? `${firstName} ${lastName}` : ''),
    []);

  useEffect(() => {
    if (query.get(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID)) refetch();
  }, [query, refetch]);

  const formattingCard = useCallback((
    response: CardHoldersResponse,
    card: CardResponse,
    status: CardStatusEnum,
  ): CardDto => {
    const isMailedAfterCreationAndOlderThanWeek = (() => {
      if (!card.created_at || !card.mailed_on) return false;
    
      const cardRequestDay = dayjs(card?.created_at).startOf('day');
      const mailedDay = dayjs(card?.mailed_on).startOf('day');
    
      const isMailedAfterCreation = mailedDay.isAfter(cardRequestDay);
      const isOlderThanWeek = dayjs().diff(mailedDay, 'day') > DAYS_OF_DISABLED_REQUESTS;
    
      return isMailedAfterCreation && isOlderThanWeek;
    })();

    return {
      status,
      last4Number: card.last4,
      holder: {
        dependentId: `${response.dependent_id || ROOT_DEPENDENT_ID}`,
        employeeId: `${response.employee_id}`,
        name: `${response.first_name} ${response.last_name}`,
        firstName: response.first_name,
        lastName: response.last_name,
      },
      id: `${card.id}`,
      bankType: card.card_type,
      primary: response.card_holder_type === CardHolderType.PRIMARY,
      accountIds: card.account_ids,
      cardAccountId: card.card_account_id,
      expDate: card.expires_on ? dayjs(card.expires_on).toDate() : null,
      mailedDate: card.mailed_on ? dayjs(card.mailed_on).toDate() : null,
      cardRequestDate: card.created_at ? dayjs(card.created_at).toDate() : null,
      isMailedAfterCreationAndOlderThanWeek: isMailedAfterCreationAndOlderThanWeek,
      activationDate: card.activated_on ? dayjs(card.activated_on).toDate() : null,
      memberSequenceIdentifier: card.member_sequence_identifier,
      alternateAccountId: card.alternate_account_id
    };
  }, []);

  const formattingCardsResponse = useCallback((response: CardHoldersResponse): CardsMap => Object
    .keys(response.grouped_cards || {}).reduce((map, status) => {
      const holderName = getHolderName(response.first_name, response.last_name);
      const cardHolderKey = `${holderName}-${response.id}`;
      const cards = response.grouped_cards
        ? response.grouped_cards[status as CardStatusEnum]
        : [];
      return {
        ...map,
        [cardHolderKey]: {
          ...map[cardHolderKey],
          [status]: cards.map((card) => formattingCard(response, card, status as CardStatusEnum)),
        },
      };
    }, {} as CardsMap),
  [formattingCard, getHolderName]);

  const formattingHolder = useCallback((response: CardHoldersResponse): CardHolderDto => ({
    dependentId: `${response.dependent_id || ROOT_DEPENDENT_ID}`,
    employeeId: `${response.employee_id}`,
    name: `${response.first_name} ${response.last_name}`,
    firstName: response.first_name,
    lastName: response.last_name,
    dateOfBirth: response.date_of_birth,
    relationship: capitalizeFirstLetter(response.relationship || ''),
  }), []);

  const cardMap: CardsMap = useMemo(() => {
    const dependents = (data?.data?.dependents || []);
    const rootCardMap: CardsMap = data?.data ? formattingCardsResponse(data?.data) : { };
    const dependentsCardMap: CardsMap = dependents.reduce((map, dependent) => (
      dependent.grouped_cards ? {
        ...map,
        ...formattingCardsResponse(dependent),
      } : {
        ...map,
      }), {});

    return {
      ...rootCardMap,
      ...dependentsCardMap,
    };
  }, [data, formattingCardsResponse]);

  const holderList: CardHolderDto[] = useMemo(() => [
    ...data?.data ? [formattingHolder(data?.data)] : [],
    ...(data?.data.dependents || []).map(formattingHolder),
  ], [data, formattingHolder]);

  const cardList = useMemo(() => Object.keys(cardMap)
    .reduce((cardDtoList, holderName) => [
      ...cardDtoList,
      ...Object.values(cardMap[holderName])
        .reduce(
          (arr, status) => [...arr, ...status],
          [] as CardDto[],
        ),
    ], [] as CardDto[]),
  [cardMap]);

  const cardAccountId = useMemo(() => cardList[0]?.cardAccountId || null, [cardList]);
  const holderName = getHolderName(data?.data.first_name, data?.data.last_name) || '';
  const holderKey = `${holderName}-${data?.data.id}`;

  return {
    cardMap,
    cardList,
    cardAccountId,
    response: data?.data,
    holderName,
    holderKey,
    holderList,
    refetch,
    isLoading,
    isFetching,
    formattingCard,
  };
};

export const useGetCardListByHolderIdQuery = (id: string) => {
  const { data, isLoading } = useQuery(
    [GET_CARDS_QUERY_KEY, id],
    () => api.get<CardDto[]>(`/${PATHS.CARDS}?holder=${id}`),
  );
  return {
    cards: data?.data || [],
    isLoading,
  };
};

const GET_CARD_BY_ID_QUERY_KEY = 'getCardByIdQueryKey';
export const useGetCardByIdQuery = (id?: string | null) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_CARD_BY_ID_QUERY_KEY, id],
    () => api.get<CardResponse>(PATHS.GET_CARD(id as string)),
    {
      enabled: false,
    },
  );
  useEffect(() => {
    if (id) refetch();
  }, [refetch, id]);

  return {
    card: data?.data,
    isLoading,
    refetch,
  };
};

mock.onGet(PATHS.CARD_HOLDERS_MOCK).reply(200, HOLDER_MOCK_LIST);
const GET_CARD_HOLDERS_QUERY_KEY = 'getCardHoldersQueryKey';
export const useGetCardHolderListQuery = () => {
  const { data, isLoading } = useQuery(
    [GET_CARD_HOLDERS_QUERY_KEY],
    () => api.get<CardHolderDto[]>(PATHS.CARD_HOLDERS_MOCK),
  );
  return {
    holders: data?.data || [],
    isLoading,
  };
};

export const useGetCardHolderByIdQuery = (id: string | number | null) => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();
    queryGenerator.set(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID, `${id}`);

    return queryGenerator;
  }, [id]);

  const {
    data, isLoading, isFetching, refetch,
  } = useQuery(
    PATHS.CARD_HOLDERS(query.toString()),
    () => (query.get(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID)
      ? api.get<CardHoldersResponse>(PATHS.CARD_HOLDERS(query.toString()))
      : null),
    {
      enabled: false,
    },
  );

  const preparedHolder = useMemo(() => ({
    dependentId: `${data?.data.dependent_id || ROOT_DEPENDENT_ID}`,
    employeeId: `${data?.data.employee_id}`,
    name: `${data?.data.first_name} ${data?.data.last_name}`,
    firstName: data?.data.first_name,
    lastName: data?.data.last_name,
  }), [data]);

  return {
    holder: preparedHolder as CardHolderDto,
    isLoading,
    isFetching,
    refetch,
  };
};

export const useChangeCardStatus = () => {
  const { mutateAsync, isLoading } = useMutation(
    (args: ChangeCardStatusMutationArgs) => api.put(PATHS.SET_CARD_STATUS(args.id), {
      status: args.status,
    }),
  );
  return {
    mutateAsync,
    isLoading,
  };
};
