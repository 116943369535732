import React from 'react';
import { Image } from '@common-fe/common-fe';

import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const BackgroundImage = () => {
  if (!whiteLabelingConfig?.images?.sidebarBottom) {
    return null;
  }
  return (
    <Image
      src={whiteLabelingConfig?.images?.sidebarBottom}
      style={{

        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        zIndex: 0,

      }}
    />
  );
};

export default BackgroundImage;
