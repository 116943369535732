import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box, ElevateLogoIcon, Image, NavigationLeftIcon, NavigationRightIcon, Text, Tip,
} from '@common-fe/common-fe';

import { SwitchImage } from '@/components/elements';
import { useBreadcrumbsStore } from '@/modules/core/store';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import {
  BackButtonWrap,
  Breadcrumb,
  Content,
  Delimiter,
} from './Breadcrumbs.styles';

const ICON_SIZE = 16;

export const Breadcrumbs: React.FC = () => {
  const { breadcrumbs, backBtn } = useBreadcrumbsStore();

  const history = useHistory();
  const { t } = useTranslation();
  const { organizationProperties } = useOrganizationProperties();
  const faviconUrl = organizationProperties?.faviconUrl;
  const minimizedLogoUrl = organizationProperties?.minimizedLogoUrl;

  const lastIndex = useMemo(() => breadcrumbs.length - 1, [breadcrumbs]);
  const handleRedirect = useCallback((url: string) => {
    history.push(url);
  }, [history]);
  const backButtonHandler = useCallback(() => {
    if (breadcrumbs[lastIndex - 1]) {
      history.push(breadcrumbs[lastIndex - 1].value);
    }
  }, [history, breadcrumbs, lastIndex]);

  return (
    <Box direction="row">
      {
        backBtn
          ? (
            <BackButtonWrap
              data-testid="Breadcrumbs-BackButton-testid"
              onClick={() => backButtonHandler()}
              direction="row"
              align="center"
              margin={{ right: 'spacing24' }}
            >
              <NavigationLeftIcon color="navigationAccent" size="18px" />
              <Text margin={{ left: 'spacing8' }} color="navigationAccent" weight="bold">Back</Text>
            </BackButtonWrap>
          )
          : null
      }
      <Content
        data-testid="Breadcrumbs-content-testid"
        direction="row"
        align="center"
        pad={{ horizontal: 'spacing4', vertical: 'spacing2' }}
      >
        <SwitchImage
          src={faviconUrl || minimizedLogoUrl}
          width={ICON_SIZE}
          height={ICON_SIZE}
          alt="logo"
        >
          {whiteLabelingConfig?.minifyLogo
            ? <Image className="image" src={whiteLabelingConfig?.minifyLogo} alt="logo" height={ICON_SIZE} width={ICON_SIZE} />
            : <ElevateLogoIcon />}
        </SwitchImage>
        {breadcrumbs.map((item, index) => (
          <Box
            direction="row"
            justify="between"
            align="center"
            key={item.key}
          >
            {index !== 0 && <Delimiter key={`delimeter-${item.key}`}>/</Delimiter>}
            <Tip
              plain
              content={item.additionalValue
                ? (
                  <Box elevation="default" background="background3" pad="small">
                    <Text color="textOnColor">{item.additionalValue as unknown as string}</Text>
                  </Box>
                ) : null}
            >
              <Breadcrumb
                data-testid={`Breadcrumb-${item.title}`}
                onClick={() => handleRedirect(`${item.value}`)}
                key={item.key}
                disabled={lastIndex === index}
                grey={lastIndex === index}
              >
                {t(item.title || '')}
              </Breadcrumb>
            </Tip>
          </Box>
        ))}
      </Content>
    </Box>
  );
};
