const TRANSACTION = '/transaction';
const DASHBOARD = '/dashboard';
const MY_ACCOUNTS = '/my-accounts';

const MANAGED = '/managed/';
export default {
  HOME: '/',
  HELP: '/help',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  SIGN_UP_ORPHAN: '/sign-up/orphan',
  CHANGE_PASSWORD: '/change-password',
  EXPENSE_DETAILS: '/expense-details/list',
  REIMBURSE_ME: `${TRANSACTION}/reimburse-me`,
  REQUEST_BILL_PAY: `${TRANSACTION}/request-bill-pay`,
  RECEIPT_VAULT: `${TRANSACTION}/receipt-vault`,
  AUTO_INVESTMENTS: '/auto-investments',
  ERROR: '/error',
  CARDS: '/cards',
  CARD_DETAILS: '/card-details',
  ONE_TIME_LINKS: '/documents/one-time-links/:hashId',
  POLL: '/poll',
  ID_SCAN: (url?: string) => `/id-scan/${url || ':url'}`,
  VERIFY: '/verify',
  USER_ACTIVATION: '/user-activation',
  START_INVESTING: '/start-investing',
  MANAGE_PROFILE: '/manage-profile',
  
  SPENDS_BY_ID: (deductibleId?: string | number) => `/spends/${deductibleId || ':deductibleId'}`,
  INVESTMENTS: '/investments',
  INVESTMENTS_UPDATE_RISK_PORTFOLIO: '/investments/update-risk-portfolio',
  BUY_SELL: '/buy-sell',
  
  MANAGED_SELL_INVESTMENTS: (accountId?: string) => `${MANAGED}sell-investments/${accountId || ':accountId'}`,
  MANAGED_SWITCH_QUESTIONNAIRE: (accountId?: string) => `${MANAGED}switch/questions/${accountId || ':accountId'}`,
  MANAGED_SWITCH_FUNDING_AMOUNT: (accountId?: string) => `${MANAGED}switch/funding/${accountId || ':accountId'}`,
  
  MANAGED_SWITCH_ALLOCATION: (accountId?: string) => `${MANAGED}switch/allocation/${accountId || ':accountId'}`,

  NOTIFICATIONS: '/notifications',
  MY_ACCOUNTS: `${DASHBOARD}${MY_ACCOUNTS}`,
  MY_ACCOUNTS_HSA: `${DASHBOARD}${MY_ACCOUNTS}/hsa`,
  DOWNLOAD_FILE_BY_QR_LINK: `${TRANSACTION}/reimburse-me/qr-codes`,

  MY_ACCOUNTS_DETAILS: (accountId?: string | number) => `${DASHBOARD}${MY_ACCOUNTS}/${accountId || ':accountId'}`,
  CONTRIBUTION: (accountId?: string | number) => `${DASHBOARD}${MY_ACCOUNTS}/${accountId || ':accountId'}/contribution`,

  SELF_DIRECTED: (accountId?: string) => `/self-directed/${accountId || ':accountId'}`,
  SELF_DIRECTED_INVEST_MORE: (accountId?: string) => `/self-directed/invest-more/${accountId || ':accountId'}`,
  SELF_DIRECTED_SWITCH_SELECT: (accountId?: string) => `/self-directed/switch/select/${accountId || ':accountId'}`,
  SELF_DIRECTED_SWITCH_ALLOCATION: (accountId?: string) => `/self-directed/switch/allocation/${accountId || ':accountId'}`,
  SELF_DIRECTED_SELL_INVESTMENTS: (accountId?: string) => `/self-directed/sell-investments/${accountId || ':accountId'}`,
  SELF_DIRECTED_ALLOCATION: (accountId?: string | null) => `/self-directed/allocation/${accountId || ':accountId'}`,
  SELF_DIRECTED_QUESTIONNAIRE: (accountId?: string | null) => `/self-directed/questions/${accountId || ':accountId'}`,
  
  TRANSACTION_DETAILS_POSTING: (accountId?: string | number, postingId?: string | number) => `${DASHBOARD}${MY_ACCOUNTS}/${accountId || ':accountId'}${TRANSACTION}/${postingId || ':postingId'}/posting`,
  TRANSACTION_DETAILS_CLAIM: (accountId?: string | number, claimId?: string | number) => `${DASHBOARD}${MY_ACCOUNTS}/${accountId || ':accountId'}${TRANSACTION}/${claimId || ':claimId'}/claim`,

  EXPENSES_TRANSACTION_DETAILS_CLAIM: (claimId?: string | number) => `${TRANSACTION}/${claimId || ':claimId'}/claim`,
  EXPENSES_TRANSACTION_DETAILS_POSTING: (postingId?: string | number) => `${TRANSACTION}/${postingId || ':postingId'}/posting`,
  STATEMENTS: (accountId?: string) => `/statements/${accountId || ':accountId'}`,
};
