import React from 'react';
import { useTranslation } from 'react-i18next';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useHasAccess,useRedirectHome } from '@/modules/core/hooks';
import { ReimburseMe } from '@/modules/transaction';
import { useOrganizationProperties } from '@/modules/transaction/hooks';

const RequestBillPayPage = () => {
  const { t } = useTranslation();
  const { organizationProperties } = useOrganizationProperties();
  const isBillPayDisabled = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  useRedirectHome(
    (organizationProperties
      && Object.keys(organizationProperties).length > 0
      && !organizationProperties.isBillPayAllowed)
    || isBillPayDisabled,
  );
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.REQUEST_BILL_PAY,
    title: t('Pay a Bill'),
    value: ROUTES.REQUEST_BILL_PAY,
  }], true);
  return (
    <ReimburseMe isBillPayMode />
  );
};

export default RequestBillPayPage;
