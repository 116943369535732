import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import Link from '@/modules/core/components/Link';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import { Title } from './Receipts.styles';

const StyledInscription = styled(Inscription)`
  font-weight: ${whiteLabelingConfig.fonts?.reimbursementWeight || 'normal'};
  color: ${({ theme }) => theme.colors.textBody};
`;

interface DescriptionProps {
  isBillPayMode?: boolean;
}

const Description: React.FC<DescriptionProps> = ({ isBillPayMode }) => {
  const { t } = useTranslation();

  return (
    <Box margin={{ bottom: 'spacing24' }}>
      <Title>{t(`Let's Find Your ${isBillPayMode ? 'Docs' : 'Receipt'}`)}</Title>
      <Box>
        {isBillPayMode ? (
          <>
            <StyledInscription size="large">
              {t('Need to pay a bill to a provider? You\'re in the right place!')}
            </StyledInscription>
            <StyledInscription size="large" margin={{ bottom: 'spacing12' }}>
              {t('You can submit a payment to the provider here or pay them directly with your benefits payment card.')}
            </StyledInscription>
            <StyledInscription size="large" margin={{ bottom: 'spacing12' }}>
              {t(`Let's start by adding documentation of your bill.
                We'll be able to pull some details from the bill automatically.`)}
            </StyledInscription>
            <Inscription weight={500} size={whiteLabelingConfig.title ? 'large' : 'small'} color="textSecondary">
              {t('If you already paid a bill and need to be reimbursed, you can get your payment here — ')}
              <Link to={ROUTES.REIMBURSE_ME}>
                <Inscription size={whiteLabelingConfig.title ? 'large' : 'small'} color="navigationAccent">
                  {t('Reimburse Me.')}
                </Inscription>
              </Link>
            </Inscription>
          </>
        ) : (
          <>
            <StyledInscription size="large" margin={{ bottom: 'spacing12' }}>
              {t('Need to be reimbursed for something you\'ve already paid for? You\'re in the right place!')}
            </StyledInscription>
            <StyledInscription size="large">
              {t('Let\'s start by adding your documentation. This could be a receipt, bill, or an EOB.')}
            </StyledInscription>
            <StyledInscription size="large" margin={{ bottom: 'spacing12' }}>
              {t('We\'ll be able to pull some details from the document automatically.')}
            </StyledInscription>
            <Inscription weight={500} size={whiteLabelingConfig.title ? 'large' : 'small'} color="textSecondary">
              {t('Have a bill you haven\'t paid yet? Send your payment directly to the provider here - ')}
              <Link to={ROUTES.REQUEST_BILL_PAY}>
                <Inscription color="navigationAccent" size={whiteLabelingConfig.title ? 'large' : 'small'}>
                  {t('Pay a Bill')}.
                </Inscription>
              </Link>
            </Inscription>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Description;
