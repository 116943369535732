import React, { useState } from 'react';

import { PhoneVerificationStep } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';

import SuccessVerifiedModal from '../SuccessVerifiedModal';

import EnterPhoneNumberVerificationModal from './EnterPhoneNumberVerificationModal';
import PhoneVerificationCodeModal from './PhoneVerificationCodeModal';

interface Props {
  onSetVisible: (value: boolean) => void;
  defaultValue?: string;
}

const PhoneVerificationModal: React.FC<Props> = ({ onSetVisible, defaultValue }) => {
  const [step, setStep] = useState(PhoneVerificationStep.ENTER_PHONE_NUMBER);
  const [phoneNumber, setPhoneNumber] = useState(defaultValue || '');
 
  return (
    <>
      {step === PhoneVerificationStep.ENTER_PHONE_NUMBER && (
        <EnterPhoneNumberVerificationModal
          phoneNumber={phoneNumber}
          onSetVisible={onSetVisible}
          setStep={setStep}
          setPhoneNumber={setPhoneNumber}
        />
      )}
      {step === PhoneVerificationStep.PHONE_VERIFICATION_CODE && (
        <PhoneVerificationCodeModal
          phoneNumber={phoneNumber}
          onSetVisible={onSetVisible}
          setStep={setStep}
        />
      )}
      {step === PhoneVerificationStep.PHONE_VERIFIED_SUCCESSFULLY && (
        <SuccessVerifiedModal
          onSetVisible={onSetVisible}
          subTitle="You can update your phone number anytime in your Account Profile."
        />
      )}
    </>
  );
};

export default PhoneVerificationModal;
