import {
  Box, CopyIcon,
  NewTabIcon, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { whiteLabelingConfig } from '@/utils/whiteLabeling';

interface Props {
  active: boolean;
  minify?: boolean;
  activeBackgroundOnHover?: boolean;
  disabled?: boolean;
}

export const LinkIcon = styled(NewTabIcon)`
  fill: #C4C4C4 !important;
  margin-bottom: ${({ theme }) => theme.spacings.spacing8};
  margin-left: ${({ theme }) => theme.spacings.spacing8};
`;

export const StyledCopyIcon = styled(CopyIcon)`
  display: none;
  margin-left: ${({ theme }) => theme.spacings.spacing12};
  &:hover {
    fill: ${({ theme }) => theme.colors.accentActive} !important;
  }
`;
export const Title = styled.p<Props >`

  ${({ theme }) => theme.fonts.text16Medium};
  color:  ${({ active, theme }) => (active
    ? theme.colors.navigationTextAccent
    : theme.colors.navigationTextDefault)} ;
  overflow: hidden;
  text-align: left;
  margin-left: 20px;
  white-space: nowrap;
  opacity: 1;
  font-weight: ${whiteLabelingConfig?.fonts?.sidebarWeight || ''} ;
  font-size: ${whiteLabelingConfig?.fonts?.sidebarFontSize || ''} ;
  ${({ minify }) => {
    if (minify) {
      return {
        display: 'none',
        width: '0%',
        opacity: 0,
        marginLeft: 0,
      };
    }
    return {};
  }}
  transition:visibility 0.5s linear,opacity 0.5s linear;
  transition: width 1s ease-in-out;
`;
export const Wrapper = styled.button<Props>`
  display: flex;
  ${({ theme }) => theme.fonts.text24Regular}
  align-items: center;
  justify-content: center;
  max-height: 40px;
  min-height: 36px;
  overflow-y: hidden;
  border: none;
  z-index: 1;
  ${({ minify, theme }) => {
    if (minify) {
      return {
        borderRadius: theme.rounds.button1Round,
      };
    }
    return {
      borderRadius: theme.rounds.button2Round,
    };
  }}
  font-size: ${whiteLabelingConfig?.fonts?.sidebarFontSize || ''} ;
  font-weight: ${whiteLabelingConfig?.fonts?.sidebarWeight || ''} ;
  background-color: ${({ active, theme }) => (active ? theme.colors.navigationAccentActive: 'transparent')} ;
  padding-right: ${({ theme, minify }) => (minify ? theme.spacings.spacing8 : theme.spacings.spacing24)};
  padding-left: ${({ theme }) => theme.spacings.spacing12};
  width: ${({ theme, minify }) => (minify ? theme.spacings.spacing54 : undefined)};
  color:  ${({ active, theme }) => (active
    ? theme.colors.navigationTextAccent
    : theme.colors.navigationTextDefault)} ;
  svg {
    ${({ theme, active, disabled }) => {
    if (active) {
      return {
        fill: theme.colors.navigationIconAccent,
      };
    }
    if (disabled) {
      return {
        fill: theme.colors.textDisabled,
      };
    }
    return {
      fill: theme.colors?.navigationIconDefault,
    };
  }}
  }
  &:hover {
    background-color: ${({ theme, active, activeBackgroundOnHover }) => (!active && !activeBackgroundOnHover ? theme.colors.navigationAccentHover : theme.colors.navigationAccentActive)};
    /* transition: .3s all; */
    color:  ${({ theme,active }) => ( active ?theme.colors.navigationTextAccent : theme.colors.navigationTextDefault)} !important;
    ${Title} {
      color:  ${({ theme,active }) => ( active ?theme.colors.navigationTextAccent : theme.colors.navigationTextDefault)} !important;
    }
    svg {
      fill:  ${({ theme,active }) => ( active ?theme.colors.navigationIconAccent : theme.colors.navigationIconDefault)} !important;
    }
    ${LinkIcon} {
      fill:  ${({ theme,active }) => ( active ?theme.colors.navigationIconAccent : theme.colors.navigationIconDefault)} !important;
    };

    ${StyledCopyIcon} {
      display: block;
    };
  }
  &:not(:last-child) {
    margin-bottom: 1px;
  }
`;

export const Delimiter = styled.p`
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme }) => theme.colors.sidebarDelimiter || theme.colors.textSecondary};
  margin-top: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xxxs};
`;

export const TitleWrapper = styled(Box)`
  flex: 1;
`;

export const SubItem = styled(Box) <Props>`
  ${({ theme }) => theme.fonts.text16Bold};
  
  border: none;
  font-size: ${whiteLabelingConfig?.fonts?.sidebarFontSize || ''} ;
  font-weight: ${whiteLabelingConfig?.fonts?.sidebarWeight || ''} ;
  color:  ${({ active, theme }) => (active
    ? theme.colors.navigationTextAccent
    : theme.colors.navigationTextDefault)} ;

background-color: ${({ theme, active }) =>active ? theme.colors.navigationAccentActive: ''};
  box-shadow: none;
  border-radius: ${({ theme }) => theme.rounds.button2Round} ;
  &:hover {
    background-color: ${({ theme }) => theme.colors.navigationAccentHover};
    color: ${({ theme, active }) => active ?theme.colors.navigationTextAccent : theme.colors.navigationTextDefault};
    /* transition: .3s all; */
  }
  
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
