import { useLayoutEffect } from 'react';

const useResponsivePageMeta = () => {
  useLayoutEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1.0';
    document.getElementsByTagName('head')[0].appendChild(meta);

    return () => {
      const existingMeta = document.querySelector('meta[name="viewport"][content="width=device-width, initial-scale=1.0"]');
      if (existingMeta) {
        existingMeta?.parentNode?.removeChild(existingMeta);
      }
    };
  }, []);
};

export default useResponsivePageMeta;
