import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import masks from '@/common/masks';
import REGEXP from '@/common/regexp';

const CODE_IS_INVALID_ERROR = 'Code is invalid.';
const CODE_LENGTH = 6;

export interface FormValues {
  verificationCode: string;
}

const useVerificationCodeFields = () => {
  const { t } = useTranslation();

  const fields = useMemo<Field[]>(() => [
    {
      name: 'verificationCode',
      type: FieldTypes.Mask,
      label: 'Verification code',
      placeholder: 'Verification code',
      showRequireIcon: true,
      mask: masks.VERIFYING_CODE,
      style: { display: 'grid', gridTemplateColumns: 'minmax(200px, 1fr) 320px' },
      validator: yup.string()
        .test({
          test: (verificationCode) => verificationCode?.replace(REGEXP.SPACE_SYMBOL, '').length === CODE_LENGTH,
          message: CODE_IS_INVALID_ERROR,
        })
        .required(t(REQUIRED_TEXT)),
    },
  ], [t]);

  return fields;
};

export default useVerificationCodeFields;
