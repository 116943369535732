import {
  useCallback,
} from 'react';
import {
  useMutation, useQueryClient,
} from 'react-query';
import { NotificationStatus } from '@common-fe/common-fe';

import { api, Response } from '@/api';
import { PATHS } from '@/common';

import { RawNotification } from '../notification.types';

export const QUERY_KEY = 'getNotifications';

export default () => {
  const queryClient = useQueryClient();
  const sentMutation = useMutation(
    () => api.patch(`${PATHS.NOTIFICATIONS_SENT}`, undefined),
  );
  const readMutation = useMutation(
    () => api.patch(`${PATHS.NOTIFICATIONS_READ}`, undefined),
  );
  const handleSent = useCallback(async () => {
    await sentMutation.mutateAsync();
  }, [sentMutation]);
  const handleRead = useCallback(async () => {
    await readMutation.mutateAsync();
    const queryData = queryClient
      .getQueryData<Response<RawNotification[]>>([QUERY_KEY]);
    if (queryData) {
      queryClient.setQueryData([QUERY_KEY], {
        ...queryData,
        data: queryData.data.map((item) => ({
          ...item,
          status: NotificationStatus.Read,
        })),
      });
    }
  }, [queryClient, readMutation]);

  return {
    handleSent,
    handleRead,
  };
};
