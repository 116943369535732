import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Field, FieldTypes, Inscription, validatePhoneNumber } from '@common-fe/common-fe';
import * as yup from 'yup';

import { AUTH_ERROR_KEY } from '@/common/constants';
import { AccountAccessPayload } from '@/modules/user/components/SignUp/AccountAccess/useAccountAccess.store';
import { centeredFieldStyles } from '@/modules/user/components/SignUp/signUp.constants';
import { useAuthStore } from '@/modules/user/stores';

interface Props {
  errors?: Partial<AccountAccessPayload>;
  isOrphanMode?: boolean;
}

export default ({ errors, isOrphanMode }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const email = useMemo(() => user?.preferredEmail, [user]);
  const { USER_NAME_REQUIRED, USER_FORMAT_INVALID } = AUTH_ERROR_KEY;
  const usernameValidation = useMemo(() => {
    const validator = yup.string().required(t(USER_NAME_REQUIRED));
    if (errors?.username) {
      return validator.test({
        test: (val) => {
          if (val !== errors.username) {
            return true;
          }
          return false;
        },
        message: t(USER_FORMAT_INVALID),
      });
    }
 
    return validator;
  }, [errors, t, USER_FORMAT_INVALID, USER_NAME_REQUIRED]);

  const fields = useMemo(() => [
    ...!isOrphanMode ? [{
      name: 'email',
      type: FieldTypes.Text,
      label: (
        <Box flex={{ grow: 1 }}>
          <Inscription margin={{ bottom: '4px' }} size="large" weight="bold" color="textBody">
            {t('Personal email')}
          </Inscription>
          <Box>
            <Inscription weight={500} color="textSecondary" size="12px" lineHeight="18px">
              {t('It will be used for account verification.')}
            </Inscription>
          </Box>
        </Box>
      ),
      isLarge: true,
      placeholder: 'Email',
      showRequireIcon: true,
      defaultValue: email,
      validator: yup.string()
        .email('Email must be valid')
        .required(t('Email is required')),
      ...centeredFieldStyles,
    }] : [],
    {
      name: 'username',
      type: FieldTypes.Text,
      label: 'Username',
      isLarge: true,
      placeholder: 'Username',
      showRequireIcon: true,
      validator: usernameValidation,
      isLowerCaseMode: true,
      ...centeredFieldStyles,
    },
    ...!isOrphanMode ? [{
      name: 'phonenumber',
      type: FieldTypes.PhoneNumber,
      showRequireIcon: true,
      label: 'Phone number',
      placeholder: '+1',
      isLarge: true,
      validator: yup.string()
        .test(validatePhoneNumber())
        .required(t('Phone number is required')),
      ...centeredFieldStyles,
    }] : [],
  ], [
    t,
    usernameValidation,
    email,
    isOrphanMode,
  ]);
  return fields as Field[];
};
