import dayjs from 'dayjs';

export const isDateInRange = (date?: string, startDate?: string, endDate?: string) => {
  if (!date) return false;

  const dateTimestamp = dayjs(date).valueOf();
  const startDateTimestamp = startDate ? dayjs(startDate).valueOf() : Number.NEGATIVE_INFINITY;
  const endDateTimestamp = endDate ? dayjs(endDate).valueOf() : Number.POSITIVE_INFINITY;

  return dateTimestamp >= startDateTimestamp && dateTimestamp <= endDateTimestamp;
};
