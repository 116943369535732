import React, { useState } from 'react';
import { toNumber } from 'lodash';

import { useTimer } from '@/utils/hooks';

import useCardHolderQuery from '../../useCardHolder.query';

import EnterCardDigitsModal from './components/EnterCardDigitsModal';
import FailedVerificationModal from './components/FailedVerificationModal';
import LimitReachedModal from './components/LimitReachedModal';
import SuccessActivatedModal from './components/SuccessActivatedModal';
import {
  ATTEMPTS_LIMIT_KEY,
  ATTEMPTS_LIMIT_TIMER_KEY,
  CardActivationVariants,
  Steps,
  VERIFICATION_LIMIT_KEY,
} from './VerifyMyCardModal.constants';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const VerifyMyCardModal: React.FC<Props> = ({ onSetVisible }) => {
  const [step, setStep] = useState(Steps.enterCardDigits);
  const attemptLimitFromStorage = toNumber(localStorage.getItem(ATTEMPTS_LIMIT_KEY)) || 0;
  const [attempt, setAttempt] = useState(attemptLimitFromStorage);
  const [isActivationSuccess, setIsActivationSuccess] = useState(
    CardActivationVariants.ACTIVATION_NOT_INIT,
  );
  const {
    setTimer: setVerificationRequestsLimit,
  } = useTimer(VERIFICATION_LIMIT_KEY);
  const {
    setTimer: setAttemptLimitTimer,
  } = useTimer(ATTEMPTS_LIMIT_TIMER_KEY);
  const { cardActivationData } = useCardHolderQuery();

  return (
    <>
      {
        step === Steps.enterCardDigits && (
          <EnterCardDigitsModal
            setStep={setStep}
            onSetVisible={onSetVisible}
            cardActivationData={cardActivationData}
            setIsActivationSuccess={setIsActivationSuccess}
            setVerificationRequestsLimit={setVerificationRequestsLimit}
            attempt={attempt}
            setAttempt={setAttempt}
            setAttemptLimitTimer={setAttemptLimitTimer}
          />
        )
      }
      {
        step === Steps.successActivated && (
          <SuccessActivatedModal
            onSetVisible={onSetVisible}
            isActivationSuccess={isActivationSuccess}
          />
        )
      }
      {
        step === Steps.verificationError && (
          <FailedVerificationModal setStep={setStep} onSetVisible={onSetVisible} />
        )
      }
      {
        step === Steps.limitReachedError && (
          <LimitReachedModal onSetVisible={onSetVisible} />
        )
      }
    </>
  );
};

export default VerifyMyCardModal;
