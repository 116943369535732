import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isFailed: boolean;
  setIsFailed: (value: boolean) => void;


}
export default storeCreator<State>((set) => ({
  isFailed: false,
  setIsFailed: (value: boolean) => set((state) => ({
    ...state,
    isFailed: value
  })),
  
}));