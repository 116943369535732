import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.module};
  max-width: 282px;
  min-width: 282px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  
  
  min-height: 100%;
  z-index: 1;
  position: relative;
  border-left: 1px solid ${({ theme }) => theme.colors.border2};
  border-right: 1px solid ${({ theme }) => theme.colors.border2};

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    max-width: 282px;
    min-width: 282px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${({ theme }) => theme.spacings.spacing24};
  padding-left: ${({ theme }) => theme.spacings.spacing16};
  /* position: relative; */
  flex: 1;
  z-index: 1;

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    padding-left: ${({ theme }) => theme.spacings.spacing24};
  }
`;
export const Title = styled.h2`
  ${({ theme }) => theme.fonts.text24Bold};
  color: ${({ theme }) => theme.colors?.textTitle};
`;

export const Space = styled.div`
  flex: 1;
`;
