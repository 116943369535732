import React, { useCallback,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  AppButton,
  Box,
  Heading,
  Inscription,
  Modal,
  Preloader,
  RadioButton,
  SuccessModal,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';

import ReissuedSuccessfullyModal from './ReissuedSuccessfullyModal';
import useReissueCheckQuery from './useReissueCheck.query';
import useVoidCheckQuery from './useVoidCheck.query';

enum Solution {
  void = 'VOID',
  changePayment = 'CHANGE_PAYMENT',
  resend = 'RESEND',
}

const Wrapper = styled(Box)`
  > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;

interface Props {
  id?: string;
  paymentMethodId?: string;
  isVisible: boolean;
  close: () => void;
  refetch?: () => void;
  showReissueCheckPopup?: () => void;
  isResentCheckAvailable?: boolean;
  mailingDate?: string;
}

const VoidCheckPopup: React.FC<Props> = ({
  id,
  isVisible,
  close,
  refetch,
  showReissueCheckPopup,
  paymentMethodId,
  isResentCheckAvailable,
  mailingDate,
}) => {
  const { t } = useTranslation();
  const [solution, setSolution] = useState<Solution | undefined>();
  const queryClient = useQueryClient();
  const [isVoidedSuccessfully, setIsVoidedSuccessfully] = useState(false);
  const [isReissuedSuccessfully, setIsReissuedSuccessfully] = useState(false);
  const onReissueSuccess = useCallback(() => {
    setIsReissuedSuccessfully(true);
    close();
  }, [close, setIsReissuedSuccessfully]);
  const onSuccess = useCallback(() => {
    setIsVoidedSuccessfully(true);
    close();
  }, [close, setIsVoidedSuccessfully]);
  const { mutateAsync: voidCheck, isLoading } = useVoidCheckQuery(onSuccess, id);
  const { mutateAsync: reissueCheck, isLoading: isReissueCheckLoading } = useReissueCheckQuery(onReissueSuccess, id);
  const onSubmit = useCallback(() => {
    if (solution === Solution.void) {
      voidCheck(null);
    }
    if (solution === Solution.changePayment) {
      close();
      if (showReissueCheckPopup) showReissueCheckPopup();
    }
    if (solution === Solution.resend) {
      reissueCheck(paymentMethodId);
    }
  }, [reissueCheck, voidCheck, solution, close, showReissueCheckPopup, paymentMethodId]);

  if (isReissuedSuccessfully) {
    return (
      <ReissuedSuccessfullyModal
        close={() => setIsReissuedSuccessfully(false)}
        refetch={refetch}
      />
    );
  }

  if (isVoidedSuccessfully) {
    return (
      <SuccessModal
        testId="success_void_check"
        visible
        header={`${t('Check #')}${id} ${t('has been voided')}`}
        buttonText={t('Got It!')}
        onSetVisible={() => {
          setIsVoidedSuccessfully(false);
          if (refetch) {
            refetch();
          } else {
            queryClient.invalidateQueries(PATHS.GET_CHECKS_LIST);
          }
        }}
      />
    );
  }

  return (
    <Modal
      testId="void_check"
      visible={isVisible}
      wrapperStyle={{ zIndex: 2002 }}
      onSetVisible={close}
    >
      <Heading level="4" fill textAlign="center" color="textTitle">{t('I need help with my check')}</Heading>
      <Inscription textAlign="center" lineHeight="22px" color="textTitle" size="large" margin={{ top: 'spacing8' }}>
        {t('Please allow 1-3 business days to resend payments or return the funds to your account.')}
      </Inscription>
      <Box
        margin={{ vertical: 'spacing24' }}
        round="medium"
        pad="spacing24"
        background="module"
      >
        <Box
          style={{ boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.03)' }}
          pad="spacing24"
          background="canvas"
          round="small"
          border={{ color: 'border2' }}
          direction="row"
          align="center"
          justify="between"
        >
          <Box direction="row" width="100%">
            <Box width="244px">
              <Inscription weight="bold" lineHeight="20px" color="textTitle">{t('Solution')}</Inscription>
            </Box>
            <Wrapper>
              <RadioButton
                name="void"
                onChange={() => setSolution(Solution.void)}
                label={<Inscription color="textBody">{t('Void and return to my account')}</Inscription>}
                checked={solution === Solution.void}
              />
              <RadioButton
                name="changePayment"
                onChange={() => setSolution(Solution.changePayment)}
                label={<Inscription color="textBody">{t('Resend payment using a different payment method')}</Inscription>}
                checked={solution === Solution.changePayment}
              />
              <RadioButton
                name="resend"
                onChange={() => setSolution(Solution.resend)}
                label={<Inscription color="textBody">{t('Resend check')}</Inscription>}
                checked={solution === Solution.resend}
                disabled={!isResentCheckAvailable}
              />
              {mailingDate ? (
                <Box>
                  <Inscription size="small" color="textSecondary">
                    {`${t('Your check was mailed on')} ${dayjs(mailingDate).format(DEFAULT_DATE_FORMAT)}. ${t('It can take up to 2 weeks to arrive.')}`}
                  </Inscription>
                </Box>
              ) : null}
            </Wrapper>
          </Box>
        </Box>
      </Box>
      <Box direction="row" justify="end">
        <AppButton
          buttonType="secondary"
          color="blue"
          onClick={close}
          width="133px"
        >
          {t('Cancel')}
        </AppButton>
        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            buttonType="primary"
            width="133px"
            disabled={isLoading || isReissueCheckLoading || !solution}
            onClick={onSubmit}
          >
            {isLoading || isReissueCheckLoading ? <Preloader /> : t('Submit')}
          </AppButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default VoidCheckPopup;
