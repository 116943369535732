import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  notification?: boolean;
  queueNumber?: number;
}

const SNACKBAR_GAP = 16;
const SNACKBAR_HEIGHT = 40;
const SNACKBAR_BOTTOM_GAP = 20;
const SNACKBAR_BOTTOM_MINIFIED_GAP = 10;

const snackbarPosition = (bottomGap: number, queueNumber?: number) => {
  return queueNumber ? bottomGap + (queueNumber * (SNACKBAR_GAP + SNACKBAR_HEIGHT)) : bottomGap;
};

const Wrapper = styled.div<Props>`
  position: fixed;
  ${({ notification, queueNumber }) => (notification
    ? `bottom: ${snackbarPosition(SNACKBAR_BOTTOM_GAP, queueNumber)}px; left: 20px; `
    : `bottom: ${snackbarPosition(SNACKBAR_BOTTOM_MINIFIED_GAP, queueNumber)}px; left: 0; right: 0;`)};
  z-index: 1000;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SnackbarBlock: React.FC<Props> = ({ children, notification, queueNumber }) => (
  <Wrapper notification={notification} queueNumber={queueNumber} data-testid="snackbar_block">
    {children}
  </Wrapper>
);

export default SnackbarBlock;
