import React from 'react';
import {
  BankCardIconLogo,
  Box,
  Inscription,
  MastercardIcon,
  PayPalIcon,
  ReceiptVaultIcon,
  VenmoIcon,
} from '@common-fe/common-fe';

import { PaymentMethodType } from '@/modules/user/user.types';
import { getFullAddress } from '@/utils';

import { EllipsisText } from './Checks.styled';
import { PaymentMethod } from './constants';

interface Props {
  method: PaymentMethod;
}

const PaymentMethodOption: React.FC<Props> = ({ method }) => (
  <Box direction="row" align="center">
    <Box margin={{ right: 'spacing4' }} width={{ min: '24px' }} height={{ min: '24px' }} border={{ color: 'border1' }} round="xsmall" justify="center" align="center">
      {method.type === PaymentMethodType.DIRECT_DEPOSIT ? <BankCardIconLogo color="info" style={{ width: '16px', height: '16px' }} /> : null}
      {method.type === PaymentMethodType.CHECK ? <ReceiptVaultIcon color="info" size="16px" /> : null}
      {method.type === PaymentMethodType.PAYPAL ? <PayPalIcon color="info" style={{ width: '16px', height: '16px' }} /> : null}
      {method.type === PaymentMethodType.VENMO ? <VenmoIcon color="info" style={{ width: '16px', height: '16px' }} /> : null}
      {method.type === PaymentMethodType.DEBIT ? <MastercardIcon color="info" style={{ width: '16px', height: '16px' }} /> : null}
    </Box>
    {method.type === PaymentMethodType.DIRECT_DEPOSIT ? <Inscription size="12px">{`Direct Deposit (•••• ${method.accountNumberLast4 ||  method?.requisites?.cardLast4})`}</Inscription> : null}
    {method.type === PaymentMethodType.CHECK ? <EllipsisText size="12px" title={getFullAddress(method?.address)}>Check ({getFullAddress(method?.address)})</EllipsisText> : null}
    {method.type === PaymentMethodType.VENMO ? <Inscription size="12px">Venmo {method?.requisites?.phoneNumber}</Inscription> : null}
    {method.type === PaymentMethodType.PAYPAL ? <Inscription size="12px">PayPal {method?.requisites?.phoneNumber}</Inscription> : null}
    {method.type === PaymentMethodType.DEBIT ? <Inscription size="12px">{`Debit Card (•••• ${method?.requisites?.cardLast4})`}</Inscription> : null}
  </Box>
);

export default PaymentMethodOption;
