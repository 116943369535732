import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { RecurringContributionStatus } from '../contribution.types';

export interface UpdateRecurringContributionRequest {
  amount?: number;
  frequency_type?: string;
  recurring_day_number?: number;
  start_date?: string;
  end_date?: string;
  status?: string;
  payment_method_id?: number;
}

export interface UpdateRecurringContributionStatusRequest {
  status: string;
}

export interface UpdateRecurringContributionResponse {
  id: number;
  amount: number;
  frequency_type: string;
  recurring_day_number: number;
  start_date: string;
  end_date: string;
  status: string;
  payment_method_id: number;
  employee_id: number;
  organization_id: number;
  organization_path: string;
}

export const useUpdateRecurringContributionQuery = (contributionId?: number) => {

  const {
    mutateAsync: handleChangeStatus, isLoading: isChangeStatusLoading,
    isSuccess: isChangeStatusSuccess
  } = useMutation(
    (value: UpdateRecurringContributionRequest) => api.patch(
      PATHS.UPDATE_RECURRING_CONTRIBUTION(contributionId),
      value,
    ),
  );

  const handlePauseOrResume = useCallback(async (value: RecurringContributionStatus) => {
    if (value === RecurringContributionStatus.Paused) {
      const { data } = await handleChangeStatus({ status: RecurringContributionStatus.Active.toLocaleUpperCase() });

      return data as UpdateRecurringContributionResponse;
    }
    
    if (value === RecurringContributionStatus.Active) {
      const { data } = await handleChangeStatus({ status: RecurringContributionStatus.Paused.toLocaleUpperCase() });

      return data as UpdateRecurringContributionResponse;
    }

    return undefined;
  }, [handleChangeStatus]);

  const handleCancel = useCallback(async () => {
    const { data } = await handleChangeStatus({ status: RecurringContributionStatus.Cancelled.toLocaleUpperCase() });

    return data as UpdateRecurringContributionResponse;
  }, [handleChangeStatus]);

  return {
    isChangeStatusLoading,
    isChangeStatusSuccess,
    handlePauseOrResume,
    handleCancel,
  };
};
