import {
  ClaimWarningField,
  OcrWarning,
  OcrWarnings,
  OcrWarningType,
} from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';
import { storeCreator } from '@/utils/store/store.utils';

const defaultOcrWarnings: OcrWarnings = {
  [OcrWarningType.MISSING]: [],
  [OcrWarningType.OUT_OF_COVERAGE_DATE]: [],
  [OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE]: [],
  [OcrWarningType.DUPLICATE_RECEIPT]: [],
};

interface State {
  ocrWarnings: OcrWarnings;
  ocrWarningsList: OcrWarning[];
  fieldsOcrWarnings: OcrWarnings;
  handleSetOcrWarnings: (warnings?: OcrWarnings) => void;
  handleSetFieldsOcrWarnings: (warnings?: OcrWarnings) => void;
  handleSetOcrWarningsList: (warningsList?: OcrWarning[]) => void;
  handleSetOcrWarningByType: (type: OcrWarningType, warning: ClaimWarningField) => void;
  handleSetFieldsOcrWarningByType: (type: OcrWarningType, warning: ClaimWarningField) => void;
  handleRemoveOcrWarningByType: (type: OcrWarningType, fieldName: string) => void;
  handleRemoveFieldsOcrWarningByType: (type: OcrWarningType, fieldName: string) => void;
  handleResetOcrWarnings: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  ocrWarnings: defaultOcrWarnings,
  fieldsOcrWarnings: defaultOcrWarnings,
  ocrWarningsList: [],

  handleSetOcrWarnings: (warnings) => {
    set({ ocrWarnings: warnings || defaultOcrWarnings });
  },

  handleSetFieldsOcrWarnings: (warnings) => {
    set({ fieldsOcrWarnings: warnings || defaultOcrWarnings });
  },

  handleSetOcrWarningsList: (warningsList) => {
    set({ ocrWarningsList: warningsList || [] });
  },

  handleSetOcrWarningByType: (type, warning) => {
    set((state) => ({
      ...state,
      ocrWarnings: {
        ...state.ocrWarnings,
        [type]: [...state.ocrWarnings[type], warning],
      },
    }));
  },

  handleSetFieldsOcrWarningByType: (type, warning) => {
    set((state) => ({
      ...state,
      fieldsOcrWarnings: {
        ...state.fieldsOcrWarnings,
        [type]: [...state.fieldsOcrWarnings[type], warning],
      },
    }));
  },

  handleRemoveOcrWarningByType: (type, fieldName) => {
    set((state) => ({
      ...state,
      ocrWarnings: {
        ...state.ocrWarnings,
        [type]: state.ocrWarnings[type].filter((warning) => warning.fieldName !== fieldName),
      },
    }));
  },

  handleRemoveFieldsOcrWarningByType: (type, fieldName) => {
    set((state) => ({
      ...state,
      fieldsOcrWarnings: {
        ...state.fieldsOcrWarnings,
        [type]: state.fieldsOcrWarnings[type].filter((warning) => warning.fieldName !== fieldName),
      },
    }));
  },

  handleResetOcrWarnings: () => {
    set({ ocrWarnings: defaultOcrWarnings, fieldsOcrWarnings: defaultOcrWarnings, ocrWarningsList: [] });
  },
}));
