import React from 'react';
import {
  Anchor,
  Box,   PDFIcon, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useOpenFile } from '@/modules/core/hooks';

const StyledAnchor = styled(Anchor)`
  color: ${({ theme }) => theme.colors.textBody};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.accentActive};
    text-decoration: none;
  }
`;

interface Props {
  startDate: string;
  documentId: string;
  name?: string;
}

const ViewStatementButton: React.FC<Props> = ({ startDate, documentId, name }) => {
  const { handleOpen } = useOpenFile();

  return (
    <Box data-testId="view-statement-button" direction="row" align="center">
      <Box
        margin={{ right: 'spacing12' }}
        pad="spacing4"
        border={{ size: 'small', color: 'border1' }}
        round="button2Round"
      >
        <PDFIcon />
      </Box>
      <StyledAnchor data-testid="view-statement-link" onClick={() => handleOpen(documentId)} size="medium" color="textBody" weight="bold">
        <Text>{name || '-'}</Text>
      </StyledAnchor>
    </Box>
  );
};

export default ViewStatementButton;
