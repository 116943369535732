import React from 'react';
import { useTranslation } from 'react-i18next';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useHasAccess,useRedirectHome } from '@/modules/core/hooks';
import { ReimburseMe } from '@/modules/transaction';
import { useOrganizationProperties } from '@/modules/transaction/hooks';

const ReimburseMePage = () => {
  const { t } = useTranslation();
  const { organizationProperties } = useOrganizationProperties();
  const isReimburseMeDisabled = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  useRedirectHome(
    (organizationProperties
      && Object.keys(organizationProperties).length > 0
      && !organizationProperties.isReimbursementAllowed)
    || isReimburseMeDisabled,
  );
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.REIMBURSE_ME,
    title: t('Reimburse Me'),
    value: ROUTES.REIMBURSE_ME,
  }], true);
  return (
    <ReimburseMe />
  );
};

export default ReimburseMePage;
