import React from 'react';
import { Box, OutlinedCompleteIcon, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { LinkedEmployee } from '@/modules/user/user.types';

const EmployerCardWrapper = styled(Box)`
  position: relative;
  cursor: pointer;
  ${({ theme }) => theme.shadows.default};

  &:hover {
    ${({ theme }) => theme.shadows.hover};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ theme }) => theme.spacings.spacing8};
    border: ${({ theme }) => `1px solid ${theme.colors.border2}`};
  }

  &:hover::before {
    border: ${({ theme }) => `2px solid ${theme.colors.border}`};
  }
`;

interface Props {
  linkedEmployee: LinkedEmployee;
  onSwitchingToOrphanMode: (employeeId: string) => void;
  isActiveSession?: boolean;
  testId?: string;
}

const EmployerCard: React.FC<Props> = ({
  linkedEmployee,
  onSwitchingToOrphanMode,
  isActiveSession,
  testId,
}) => {
  return (
    <EmployerCardWrapper
      pad={{ vertical: 'spacing12', horizontal: 'spacing16' }}
      margin={{ top: 'spacing8' }}
      round="container2Round"
      background="canvas"
      onClick={() => onSwitchingToOrphanMode(linkedEmployee?.employeeId)}
      data-testid={`${testId}-employer-card-wrapper`}
    >
      <Box direction="row" justify="between" align="top">
        <Text size="xxlarge" weight={700} style={{ lineHeight: '28px' }}>
          {linkedEmployee?.organizationName}
        </Text>

        {isActiveSession && <OutlinedCompleteIcon data-testid="outlined-complete-icon" color="button" />}
      </Box>

      <Box margin={{ top: 'spacing8' }}>
        <Text size="12px" weight={500}>
          {linkedEmployee?.enrollmentTitle}
        </Text>
      </Box>
    </EmployerCardWrapper>
  );
};

export default EmployerCard;
