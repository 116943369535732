import { Address } from '@/common/types';

const getFullAddress = (address?: Address) => [
  address?.line1?.trim(),
  address?.line2?.trim(),
  address?.line3?.trim(),
  address?.line4?.trim(),
  address?.city?.trim(),
  address?.state?.name?.trim(),
  address?.zipcode?.trim(),
  address?.country?.name?.trim(),
].filter((item) => item).join(', ');

export default getFullAddress;
