import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, FlexControlledForm } from '@common-fe/common-fe';

import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import { EmployeeAddress } from '@/modules/user/user.types';

import { useEditEmployeeAddressFields, useEmployeeAddressSave } from './hooks';

interface Props {
  address: EmployeeAddress;
  onClose: () => void;
}
const EditEmployeeAddressForm: React.FC<Props> = ({ address, onClose }) => {
  const fields = useEditEmployeeAddressFields({
    address,
  });
  const { handleAddPermanentSnackbar } = useSnackbar();

  const { setState, handleSave, isSubmitted } = useEmployeeAddressSave({
    fields,
    onSuccess: () => {
      onClose();
      handleAddPermanentSnackbar({
        text: t('Your address has been changed.'),
        state: SnackbarState.positive,
        closeIcon: true,
        autoRemove: true,
      });
    },
  });
  const { t } = useTranslation();
  return (
    <Box data-testId="edit-employee-address_form">
      <Box pad="l" round="moduleRound" background="module">
        <Box background="canvas" round="container1Round">
          <FlexControlledForm
            testId="edit-address"
            fields={fields}
            isModalType
            showError={isSubmitted}
            editMode
            onChangeValues={(values) => {
              setState(values);
            }}
          />
        </Box>
      </Box>
      <Box margin={{ top: 'l' }} direction="row" justify="end">
        <AppButton testId="cancel-edit-employee" onClick={onClose} buttonType="secondary" width="180px">
          {t('Cancel')}
        </AppButton>
        <Box margin={{ left: 's' }}>
          <AppButton testId="save-edit-employee" onClick={handleSave} width="180px">
            {t('Save')}
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default EditEmployeeAddressForm;
