import { useCallback, useEffect } from 'react';

import useAuthStore  from '@/modules/user/stores/useAuth.store';

import  useCIPFailedStore  from '../stores/useCIPFailed.store';
export const CIP_STORAGE_KEY = 'CIP_FAILED_KEY';
const EMPTY_ARRAY_STRING = '[]';
export default () => {
  const employeeId  = useAuthStore(state => state.employeeId);
  const isFailed = useCIPFailedStore((state) => state.isFailed);
  const setIsFailed = useCIPFailedStore((state) => state.setIsFailed);
  useEffect(() => {
    setIsFailed(JSON.parse(localStorage.getItem(CIP_STORAGE_KEY) || EMPTY_ARRAY_STRING).includes(employeeId)
    || false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
 

  const handleFailed = useCallback(() => {
    setIsFailed(true);
    const currentIds = JSON.parse(localStorage.getItem(CIP_STORAGE_KEY) || EMPTY_ARRAY_STRING);
    if(employeeId) {
      localStorage.setItem(CIP_STORAGE_KEY, JSON.stringify([...currentIds,employeeId]));
    }
    
  }, [setIsFailed,employeeId]);

  return {
    isFailed,
    handleFailed,
  };
};
