import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, costFormater,PriceInput,Text} from '@common-fe/common-fe';
import styled from 'styled-components';
interface Props {
  value: string;
  maxValue: number;
  errorText?: string;
  isSubmitted?: boolean;
  onSetValue: (value: string)  => void;
}
const Wrapper = styled(Box)`
  .invest-amount-input {
    font-weight: 700;
  }

`;
const SelfDirectedInvestAmount: React.FC<Props> = ({ value,  onSetValue, maxValue, errorText, isSubmitted}) => {
  const { t } = useTranslation();

  const handleSetDefaultValue = useCallback(() => {
    onSetValue(`${maxValue || ''}`);
  }, [maxValue, onSetValue]);
  return (
    <Box pad='l' background="canvas" round="container1Round" elevation="default">
      <Text size="xxlarge">
        {t('How much would you like to invest?')}
      </Text>
      <Box direction="row" margin={{top: 'l'}}>
        <Wrapper width="control">
          <PriceInput
            autoFocus
            errorStyle={{flex: 'auto'}}
            errorText={isSubmitted ? errorText: ''}
            className='invest-amount-input'
            name="amount"
            value={value}
            onChange={onSetValue} 
                  
          />
        </Wrapper>
        <Box margin={{left: 'xs', top: 'xxxs'}}>
          <AppButton buttonType="quaternary" onClick={handleSetDefaultValue}>
            Max. {
              costFormater(maxValue,{
                isPrecision: true
              })
            }
          </AppButton>
        </Box>
      </Box>
    </Box> 
  );
};

export default SelfDirectedInvestAmount;