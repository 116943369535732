import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuccessModal } from '@common-fe/common-fe';

import ChoosePaymentMethod from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/ChoosePaymentMethod';
import { DebitCardModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DebitCart';
import DirectDeposit from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DirectDeposit';
import { AddPayPalAccountModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/PayPal';
import { AddVenmoAccountModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/Venmo';
import useHighlightedPaymentMethodStore from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/stores/useHighlightedPaymentMethod.store';
import useHighlightById from '@/utils/hooks/useHighlightById';

enum StepsStates {
  choosePaymentMethod = 'choosePaymentMethod',
  depositOptionsChoosing = 'depositOptionsChoosing',
  debitOptionsChoosing = 'debitOptionsChoosing',
  payPalOptionsChoosing = 'payPalOptionsChoosing',
  venmoOptionsChoosing = 'venmoOptionsChoosing',
  paymentMethodAdded = 'paymentMethodAdded',
}

interface Props {
  onSetVisible: (value: boolean) => void;
}

const AddPaymentMethodModal: React.FC<Props> = ({ onSetVisible }) => {
  const { t } = useTranslation();
  const [step, setSteps] = useState(StepsStates.choosePaymentMethod);
  const { reset, setHighlightedPaymentMethodId } = useHighlightedPaymentMethodStore();
  const { setHighlightedById } = useHighlightById({
    onSetHighlightedId: setHighlightedPaymentMethodId,
    reset,
  });

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);
  
  const onSuccess = useCallback((paymentMethodId?: string) => {
    if (paymentMethodId) {
      setHighlightedById(paymentMethodId);
    }
    setSteps(StepsStates.paymentMethodAdded);
  }, [setHighlightedById]);

  return (
    <>
      {step === StepsStates.choosePaymentMethod && (
        <ChoosePaymentMethod
          onClose={handleClose}
          chooseDeposit={() => setSteps(StepsStates.depositOptionsChoosing)}
          chooseDebitCard={() => setSteps(StepsStates.debitOptionsChoosing)}
          choosePayPal={() => setSteps(StepsStates.payPalOptionsChoosing)}
          chooseVenmo={() => setSteps(StepsStates.venmoOptionsChoosing)}
          onlyAddingMode
        />
      )}

      {step === StepsStates.depositOptionsChoosing && (
        <DirectDeposit
          onClose={handleClose}
          onBack={() => setSteps(StepsStates.choosePaymentMethod)}
          onSuccess={onSuccess}
          hideNavigationButton
          allowSaveAsDefault
        />
      )}

      {step === StepsStates.debitOptionsChoosing && (
        <DebitCardModal
          onClose={handleClose}
          onBack={() => setSteps(StepsStates.choosePaymentMethod)}
          onSuccess={onSuccess}
          hideNavigationButton
          allowSaveAsDefault
        />
      )}

      {step === StepsStates.payPalOptionsChoosing && (
        <AddPayPalAccountModal
          onClose={handleClose}
          onBack={() => setSteps(StepsStates.choosePaymentMethod)}
          onSuccess={onSuccess}
          hideNavigationButton
          allowSaveAsDefault
        />
      )}

      {step === StepsStates.venmoOptionsChoosing && (
        <AddVenmoAccountModal
          onClose={handleClose}
          onBack={() => setSteps(StepsStates.choosePaymentMethod)}
          onSuccess={onSuccess}
          hideNavigationButton
          allowSaveAsDefault
        />
      )}

      <SuccessModal
        header={t('Payment method added successfully!')}
        buttonText={t('Got It!')}
        visible={step === StepsStates.paymentMethodAdded}
        onSetVisible={handleClose}
        buttonStyle={{ width: '160px' }}
      />
    </>
  );
};

export default AddPaymentMethodModal;
