import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import StatusIcon from '@/components/elements/StatusIcon';
import {
  ClaimNote,
  ClaimNoteGroup,
  ClaimNoteType,
  TransactionStatus,
} from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import {
  IconWrapper, Title,
  Wrapper, } from '../../TransactionNotification.styles';

const ALLOWED_TYPES = [
  ClaimNoteType.NO_PAY_OUT_NOTE,
  ClaimNoteType.SPEND_LIMIT_NOTE,
  ClaimNoteType.CHECK_EXPIRED_NOTE,
  ClaimNoteType.CHECK_VOIDED_NOTE,
  ClaimNoteType.WAITING_FOR_FUNDS_EXPIRED,
];

export const hasClaimNoteMessage = (data?: ClaimNote) => data?.claimNoteType && ALLOWED_TYPES.includes(data?.claimNoteType);

interface Props {
  data?: ClaimNote;
}

const ClaimNoteMessage: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const isDenided = useMemo(
    () => data?.claimNoteGroup === ClaimNoteGroup.Warning, [data],
  );
  const isPending = useMemo(
    () => data?.claimNoteGroup === ClaimNoteGroup.Information, [data],
  );

  const statusIcon = useMemo(() => {
    if (isDenided) {
      return <StatusIcon status={TransactionStatus.Denied} />;
    }
    if (isPending) {
      return <StatusIcon status={TransactionStatus.WaitingForFunds} />;
    }
    return null;
  }, [isDenided, isPending]);
  const mainText = useMemo(
    () => {
      if (data?.claimNoteType === ClaimNoteType.NO_PAY_OUT_NOTE) {
        return 'Additional payments for this claim have been turned off. Payment processing is complete.';
      }
      if (data?.claimNoteType === ClaimNoteType.SPEND_LIMIT_NOTE) {
        return 'The spend limit has been reached for this service and no further payment can be issued.';
      }
      if (data?.claimNoteType === ClaimNoteType.CHECK_EXPIRED_NOTE) {
        return 'Check has expired. Money has been returned to your account.';
      }
      if (data?.claimNoteType === ClaimNoteType.CHECK_VOIDED_NOTE) {
        return 'Payment was voided. Money has been returned to your account.';
      }
      if (data?.claimNoteType === ClaimNoteType.WAITING_FOR_FUNDS_EXPIRED) {
        return 'No additional payments will be made for this claim.';
      }
      return '';
    },
    [data],
  );

  const currentBorderColor = useMemo(() => {
    if (isDenided) return theme.colors.dangerBorder;
    if (isPending) return theme.colors.warningBorder;
    return 'transparent';
  }, [isDenided, isPending]);

  if (!hasClaimNoteMessage(data)) return null;

  return (
    <Wrapper
      error={isDenided}
      warning={isPending}
      data-testid={`claim-note-type-${data?.claimNoteType}`}
      flex-direction="column"
      background="canvas"
      border={{
        size: 'small',
        color: currentBorderColor,
      }}
      elevation="default"
      pad={{
        top: 'l',
        right: 'l',
        bottom: 'l',
        left: statusIcon ? '56px' : 'l',
      }}
    >
      {statusIcon ? <IconWrapper>{statusIcon}</IconWrapper> : null}
      <Title data-testid="main-text">{t(mainText)}</Title>
    </Wrapper>
  );
};

export default ClaimNoteMessage;
