import React, { useCallback, useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  CheckBox,
  Inscription,
  Preloader,
} from '@common-fe/common-fe';
import Lottie from 'lottie-react';

import GainingFullAccessIcon from '@/assets/bank-icons/gaining-full-access.json';
import { MINUTE } from '@/common/constants';
import { ModalWrapper } from '@/components/wrappers';
import { useVerifyCard } from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/VerifyMyCardModal/queries';
import {
  ATTEMPTS_LIMIT,
  CardActivationVariants,
  Steps,
} from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/VerifyMyCardModal/VerifyMyCardModal.constants';
import { CardActivationData } from '@/modules/transaction/components/Cards/Cards.types';
import { useRequestedActivationCards } from '@/modules/transaction/components/Cards/components/CardActivationModals/hooks/useRequestedActivationCards';
import { useCardActivationQuery } from '@/modules/transaction/components/Cards/components/CardActivationModals/queries/useCardActivation.query';
import { useRefreshToken } from '@/modules/user/components/SignIn/hooks';
import theme from '@/styles/theme';

import CardDigitsField from './CardDigitsField';

const BUTTON_WIDTH = '160px';
const CARD_NUMBERS_LENGTH = 4;
const LIMIT_REACHED_STATUS_CODE = 429;
const FIVE_MINUTES = 5 * MINUTE;

interface Props {
  setStep: (step: Steps) => void;
  onSetVisible: (value: boolean) => void;
  setVerificationRequestsLimit: (value: number) => void;
  cardActivationData?: CardActivationData;
  setIsActivationSuccess?: (value: CardActivationVariants) => void;
  attempt: number;
  setAttempt: (value: number) => void;
  setAttemptLimitTimer: (value: number) => void;
}

const EnterCardDigitsModal: React.FC<Props> = ({
  setStep,
  onSetVisible,
  cardActivationData,
  setIsActivationSuccess,
  setVerificationRequestsLimit,
  attempt,
  setAttempt,
  setAttemptLimitTimer,
}) => {
  const [cardNumbers, setCardNumbers] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const {
    handleActivate: handleCardActivate,
    isLoading: isCardActivationLoading,
  } = useCardActivationQuery({
    memberSequenceIdentifier: cardActivationData?.memberSequenceIdentifier,
    alternateAccountId: cardActivationData?.alternateAccountId,
  });

  const { setRequestedActivationCards } = useRequestedActivationCards();

  const isCardNumberValid = useMemo(() => cardNumbers.length === CARD_NUMBERS_LENGTH,
    [cardNumbers]);

  const handleActivate = useCallback(async () => {
    if (!isChecked) return;

    await handleCardActivate();
    setIsActivationSuccess?.(CardActivationVariants.ACTIVATED);
    setRequestedActivationCards(cardActivationData?.cardId || '');
  }, [
    cardActivationData,
    handleCardActivate,
    isChecked,
    setIsActivationSuccess,
    setRequestedActivationCards,
  ]);

  const { handleRefreshToken } = useRefreshToken();
  const {
    onVerify, isLoading,
  } = useVerifyCard({
    last4: cardNumbers,
    onSuccess: () => {
      handleRefreshToken();
    },

  });

  const handleSubmit = useCallback(async () => {
    if (isCardNumberValid && !isLoading) {
      try {
        await onVerify();
      } catch (error) {
        // @ts-ignore
        const statusCode = error?.response?.status;
        if (statusCode === LIMIT_REACHED_STATUS_CODE) {
          setVerificationRequestsLimit(FIVE_MINUTES);
          setStep(Steps.limitReachedError);
        } else {
          if (attempt === 0) {
            setAttemptLimitTimer(FIVE_MINUTES);
          }
          const currentAttempt = attempt + 1;
          setAttempt(currentAttempt);
          if (currentAttempt >= ATTEMPTS_LIMIT) {
            setStep(Steps.limitReachedError);
            setVerificationRequestsLimit(FIVE_MINUTES);
          } else {
            setStep(Steps.verificationError);
          }
        }
        return;
      }

      try {
        await handleActivate();
        setStep(Steps.successActivated);
      } catch {
        setIsActivationSuccess?.(CardActivationVariants.ERROR_ACTIVATION);
        setStep(Steps.successActivated);
      }
    }
  }, [onVerify,
    isCardNumberValid,
    isLoading,
    setStep,
    handleActivate,
    setVerificationRequestsLimit,
    setIsActivationSuccess,
    attempt,
    setAttempt,
    setAttemptLimitTimer,
  ]);

  const handleClose = useCallback(() => onSetVisible(false), [onSetVisible]);

  const isCurrentLoading = useMemo(
    () => isCardActivationLoading || isLoading,
    [isCardActivationLoading, isLoading],
  );

  return (
    <ModalWrapper
      visible
      onSetVisible={onSetVisible}
      testId="enter-card-digits"
    >
      <Box margin={{ top: 'spacing12' }} align="center">
        <Lottie animationData={GainingFullAccessIcon} />
        <Inscription margin={{ top: 'spacing8' }} size={theme.spacings.spacing24} weight={700}>
          {t('Enter the last 4 digits of your payment card')}
        </Inscription>
      </Box>

      <Box margin={{ top: 'spacing24' }} round="container1Round" pad="spacing24" background="module">
        <CardDigitsField
          title={t('The last 4 digits of your card')}
          length={CARD_NUMBERS_LENGTH}
          onChangeValue={setCardNumbers}
        />
      </Box>

      <Box
        direction="row"
        justify={cardActivationData ? 'between' : 'end'}
        align="center"
        margin={{ vertical: 'spacing24' }}
      >
        {!!cardActivationData && (
          <Box>
            <CheckBox
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              label={<Inscription style={{ fontSize: 14 }}>{t('Once verified, request activation')}</Inscription>}
            />
          </Box>
        )}
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton
              testId="close"
              width={BUTTON_WIDTH}
              buttonType="secondary"
              onClick={handleClose}
            >
              {t('Cancel')}
            </AppButton>
          </Box>
          <Box>
            <AppButton
              width={BUTTON_WIDTH}
              onClick={handleSubmit}
              disabled={!isCardNumberValid}
              testId="submit"
            >
              {isCurrentLoading ? <Preloader color="white" /> : t('Submit')}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default EnterCardDigitsModal;
