import React, { useCallback, useMemo } from 'react';
import { Inscription } from '@common-fe/common-fe';

import routes from '@/common/routes';
import { OffsetRepaymentDto } from '@/modules/transaction/transaction.types';
import { formatCurrency } from '@/utils';

import { Wrapper } from '../../TransactionNotification.styles';

const fragment = (fragment?: string, splitter: string = '-') => fragment ? `${splitter}${fragment}` : '';
const STEP = 4;
const formatClaimId = (id?: string) => {
  if (!id) return id;

  let result = '';
  for (let i = 0; i < Math.ceil(id.length / STEP); i++) {
    result = result + fragment(id.slice(i ? i * STEP : i, (i * STEP) + STEP), i === 0 ? '#' : undefined);
  }

  return result;
};

interface Props {
  data?: OffsetRepaymentDto;
}

const OffsetRepayment: React.FC<Props> = ({ data }) => {
  const [claimId, claimIdFor] = useMemo(() => [formatClaimId(data?.donorClaimId), formatClaimId(data?.recipientClaimId)], [data]);
  const goToClaimsPage = useCallback((claimId: string) => window.open(`${routes.EXPENSES_TRANSACTION_DETAILS_CLAIM(claimId)}`, '_blank'), []);

  return (
    <Wrapper
      data-testid={`claim-offset-repayment-${data?.date}`}
      flex-direction="column"
      background="canvas"
      elevation="default"
      pad={{
        top: 'l',
        right: 'l',
        bottom: 'l',
        left: 'l',
      }}
    >
      <Inscription size="14px" lineHeight="22px">
        <Inscription weight="bold">{formatCurrency(data?.amount)}</Inscription>
        &nbsp;claim <Inscription color="textAccent" style={{ cursor: 'pointer' }} onClick={() => goToClaimsPage(data?.donorClaimId || '')}>{claimId}</Inscription>
        &nbsp;payment was used to offset repayment required for claim <Inscription color="textAccent" style={{ cursor: 'pointer' }} onClick={() => goToClaimsPage(data?.recipientClaimId || '')}>{claimIdFor}</Inscription>
      </Inscription>
    </Wrapper>
  );
};

export default OffsetRepayment;
