import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

const useForgotUsernameQuery = (onSuccess?: () => void) => {
  const { mutateAsync } = useMutation(
    (email?: string) => api.post(
      PATHS.FORGOT_USERNAME,
      { email },
    ),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
    },
  );

  return mutateAsync;
};

export default useForgotUsernameQuery;
