import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';

import { useStore as usePersonalInformationStore } from '../../stores/usePersonalInformation.store';

export default () => {
  const { t } = useTranslation();
  const defaultFields = usePersonalInformationStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        testId: 'Annual-income-total-field',
        name: 'annualIncome',
        type: FieldTypes.Currency,
        value: '',
        showRequireIcon: true,
        placeholder: 'Enter $ amount',
        label: t('What is your approximate annual income?'),
        validator: yup.string().trim().required(REQUIRED_TEXT),
        defaultValue: '',
      },
    ]
  ), [t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
