import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface Props {
  last4: string,
  onSuccess?: () => void;
  onError?: () => void;
}

const useVerifyCard = ({
  last4,
  onSuccess,
  onError,
}: Props) => {
  const {
    mutateAsync, isLoading, isError, isSuccess,
  } = useMutation(
    () => api.patch(PATHS.VERIFY_CARD(last4)), {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        if (onError) {
          onError();
        }
      },
    },
  );

  return {
    onVerify: mutateAsync,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useVerifyCard;
