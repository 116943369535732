import React from 'react';

import useHasAccess, { AccessRequirement } from '@/modules/core/hooks/useHasAccess';

interface Props {
  children: React.ReactNode;
  accessRequirements?: AccessRequirement[],
  accessDeniedAction?: () => void,
}

const Access: React.FC<Props> = ({
  children,
  accessRequirements,
  accessDeniedAction,
}) => {
  const hasAccess = useHasAccess(accessRequirements);

  if (hasAccess) {
    return (<>{children}</>);
  }

  if (accessDeniedAction) {
    accessDeniedAction();
  }

  return null;
};

export default Access;
