import { Box } from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

import { TransactionStatus } from '@/modules/transaction/transaction.types';

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacings.spacing2};
  margin-right: ${({ theme }) => theme.spacings.spacing8};
  min-width: 30px;
`;
export const MonthName = styled.p`
  ${({ theme }) => theme.fonts.text12Bold};
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: pointer;
`;
export const Day = styled.p`
  ${({ theme }) => theme.fonts.text14Bold};
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  font-size:  ${({ theme }) => theme.spacings.spacing32};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StatusText = styled.p`
  ${({ theme }) => theme.fonts.text14Regular};
  flex: 1;
  margin-left: ${({ theme }) => theme.spacings.spacing12};
`;

export const Delimiter = styled(Box)`
  width:1px;
  height: ${({ theme }) => theme.spacings.spacing16}; 
  background-color: ${({ theme }) => theme.colors.border} !important;
  
  border-radius: ${({ theme }) => theme.rounds.smallRound};
  /* margin-left:  ${({ theme }) => theme.spacings.spacing24}; */
  margin-bottom: ${({ theme }) => theme.spacings.spacing2};
  margin-top: ${({ theme }) => theme.spacings.spacing2};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings.spacing4} ${theme.spacings.spacing12}`};
`;

interface ContentProps {
  statusType: TransactionStatus;
  firstItem: boolean;
}
export const ContentWrapper = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${Content} {
    border-radius: ${({ theme }) => theme.rounds.container2Round} ;
    ${({ statusType, theme, firstItem }) => {
    let color = theme.colors.border;
    if (!firstItem) {
      return {};
    }
    if (statusType === TransactionStatus.Locked
    ) {
      color = theme.colors.statusContainerColor.lockedNullContainer;
    }

    if (statusType === TransactionStatus.Processing
      || statusType === TransactionStatus.Pending
      || statusType === TransactionStatus.Reprocessing) {
      color = theme.colors.statusContainerColor.processingPendingContainer;
    }

    if (statusType === TransactionStatus.Paid
      || statusType === TransactionStatus.Repaid
      || statusType === TransactionStatus.Complete
      || statusType === TransactionStatus.OutOfPocket
      || statusType === TransactionStatus.AppliedToDeductible
    ) {
      color = theme.colors.statusContainerColor.paidActiveCompleteContainer;
    }
    if (statusType === TransactionStatus.PartiallyApproved
      || statusType === TransactionStatus.PartiallyPaid
    ) {
      color = theme.colors.statusContainerColor.partiallyPaidContainer;
    }

    if (statusType === TransactionStatus.NeedsHelp
    || statusType === TransactionStatus.RepaymentRequired
    || statusType === TransactionStatus.WaitingForFunds
    ) {
      color = theme.colors.statusContainerColor.needsHelpContainer;
    }
    if (statusType === TransactionStatus.Denied
    || statusType === TransactionStatus.Declined
    || statusType === TransactionStatus.Voided
    || statusType === TransactionStatus.Terrminated
    || statusType === TransactionStatus.Error
    || statusType === TransactionStatus.Cancelled
    || statusType === TransactionStatus.StopProcessing
    ) {
      color = theme.colors.statusContainerColor.deniedErrorExpiredContainer;
    }

    if (statusType === TransactionStatus.Uploading
      || statusType === TransactionStatus.Expired
    ) {
      color = theme.colors.statusContainerColor.uploadingContainer;
    }
    if (statusType === TransactionStatus.LoA) {
      color = theme.colors.statusContainerColor.loaContainer;
    }

    if (statusType === TransactionStatus.Retirement) {
      color = theme.colors.statusContainerColor.retirementContainer;
    }
    if (statusType === TransactionStatus.Future) {
      color = theme.colors.statusContainerColor.futureContainer;
    }
    return {
      backgroundColor: color,
    };
  }}
  }

  /* ${StatusText} {
    ${({ statusType, theme, firstItem }) => {
    if (!firstItem) {
      return {};
    }
    let color = theme.colors.textBody;
    if (statusType === TransactionStatus.Paid
      || statusType === TransactionStatus.Repaid
      || statusType === TransactionStatus.Complete
      || statusType === TransactionStatus.PartiallyPaid) {
      color = theme.colors.success;
    }
    if (statusType === TransactionStatus.Processing) {
      color = theme.colors.info;
    }
    if (statusType === TransactionStatus.NeedsHelp
    || statusType === TransactionStatus.WaitingForFunds
    || statusType === TransactionStatus.Pending) {
      color = theme.colors.warning;
    }
    if (statusType === TransactionStatus.Denied
    || statusType === TransactionStatus.Voided
    || statusType === TransactionStatus.StopProcessing
    || statusType === TransactionStatus.Declined) {
      color = theme.colors.danger;
    }
    return {
      color,
      fontWeight: 'bold',
    };
  }} 
  } */
 
  ${IconWrapper} {
    ${({ statusType, theme }) => {
    let color = theme.colors.iconPrimary;
    if (statusType === TransactionStatus.Locked
    ) {
      color = theme.colors.statusColors.lockedNull;
    }

    if (statusType === TransactionStatus.Processing
        || statusType === TransactionStatus.Pending
        || statusType === TransactionStatus.Reprocessing) {
      color = theme.colors.statusColors.processingPending;
    }

    if (statusType === TransactionStatus.Paid
        || statusType === TransactionStatus.Repaid
        || statusType === TransactionStatus.Complete
        || statusType === TransactionStatus.OutOfPocket
        || statusType === TransactionStatus.AppliedToDeductible
    ) {
      color = theme.colors.statusColors.paidActiveComplete;
    }
    if (statusType === TransactionStatus.PartiallyApproved
        || statusType === TransactionStatus.PartiallyPaid
    ) {
      color = theme.colors.statusColors.partiallyPaid;
    }

    if (statusType === TransactionStatus.NeedsHelp
      || statusType === TransactionStatus.RepaymentRequired
      || statusType === TransactionStatus.WaitingForFunds
    ) {
      color = theme.colors.statusColors.needsHelp;
    }
    if (statusType === TransactionStatus.Denied
      || statusType === TransactionStatus.Declined
      || statusType === TransactionStatus.Voided
      || statusType === TransactionStatus.Terrminated
      || statusType === TransactionStatus.Error
      || statusType === TransactionStatus.Cancelled
      || statusType === TransactionStatus.StopProcessing
    ) {
      color = theme.colors.statusColors.deniedErrorExpired;
    }

    if (statusType === TransactionStatus.Uploading
        || statusType === TransactionStatus.Expired
    ) {
      color = theme.colors.statusColors.uploading;
    }
    if (statusType === TransactionStatus.LoA) {
      color = theme.colors.statusColors.loa;
    }

    if (statusType === TransactionStatus.Retirement) {
      color = theme.colors.statusColors.retirement;
    }
    if (statusType === TransactionStatus.Future) {
      color = theme.colors.statusColors.future;
    }
    return css`
      svg {
        fill: ${color}
      }
    
    `; 
  }}
  }
  ${Delimiter} {
    /* display: ${({ firstItem }) => (firstItem ? 'none' : 'block')}; */
    ${({ theme }) => {
    const color = theme.colors.success;
    return {
      backgroundColor: color,
    };
  }}
  }
`;

interface RowProps {
  disabled?: boolean;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  ${IconWrapper} {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.stressContainer : undefined)};
  }
  ${StatusText} {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.stressContainer : undefined)};
  }
`;

export const DateTooltip = styled(Box)`
  display: flex;
  // flex-direction: row;
  // align-items: flex-start;
  padding: 3px 6px;
  background: ${({ theme }) => theme.colors.background3};
  border: 1px solid #3D3E40;
  box-sizing: border-box;
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme}) => theme.colors.textOnColor};
`;
