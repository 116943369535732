import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, FULL_MONTH_DATE_FORMAT, ONLY_MONTH_DATE_FORMAT } from '@/common/constants';

import DOCS from '../docs';
import { useInvestor } from '../hooks';
import { InvestmentPaths } from '../investments.types';

import DocumentsBlock, { DocumentItemsGroup } from './DocumentsBlock';

interface Props {
  accountId?: string;
}
const Documents: React.FC<Props> = ({ accountId}) => {
  const { t } = useTranslation();
  const { data: { type} } = useInvestor(accountId);

  const isSelfDirected = useMemo(() => {
    return type === InvestmentPaths.SelfDirected;
  }, [type]);
  
  const driveWealthGroup = useMemo(() => {
    const list: DocumentItemsGroup[] = [
      {
        title: 'DriveWealth Disclosures and Disclaimers',
        items: [{
          title: 'DW Privacy Policy',
          url: 'https://legal.drivewealth.com/privacy-policy',
          date: dayjs('02/01/2021', DEFAULT_DATE_FORMAT).format(FULL_MONTH_DATE_FORMAT),
        }, {
          title: 'W9 Agreement',
          url: 'https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true&citizenship=USA',
          date: dayjs('09/22/2022', DEFAULT_DATE_FORMAT).format(FULL_MONTH_DATE_FORMAT),
        }, {
          title: 'DriveWealth Agreements and Disclosures',
          url: 'https://apps.drivewealth.com/disclosures/?ria=Intellicents?sweep=bank',

        }],
      },

      {
        title: 'Advisory Documents and Disclosures',
        items: [{
          title: 'ADV Part 2A - Intellicents investment solutions',
          url: 'https://www.intellicents.com/adv2a',
          date: dayjs('02/09/2022', DEFAULT_DATE_FORMAT).format(ONLY_MONTH_DATE_FORMAT),
        }, {
          title: 'ADV Part 2B',
          url: 'https://www.intellicents.com/adv2b',
          date: dayjs('02/09/2022', DEFAULT_DATE_FORMAT).format(ONLY_MONTH_DATE_FORMAT),
        }, {
          title: 'ADV Part 3',
          url: DOCS.ADV_PART3,
          date: dayjs('02/09/2022', DEFAULT_DATE_FORMAT).format(ONLY_MONTH_DATE_FORMAT),

        }],
      },
      {
        title: 'Elevate Disclosures',
        items: [{
          title: 'HSA Investment Terms and Conditions',
          url: DOCS.HSA_TERMS_AND_CODITIONS,
          date: dayjs('09/01/2022', DEFAULT_DATE_FORMAT).format(ONLY_MONTH_DATE_FORMAT),
        },
        ...isSelfDirected ? [{
          title: 'Dividends Reinvestment Disclosure',
          url: DOCS.DIVIDEND_REINVESTMENT_DISCLOSURE,
          date: dayjs('10/31/2023', DEFAULT_DATE_FORMAT).format(ONLY_MONTH_DATE_FORMAT),
        }] : []],
      },

    ];
    return list;
  }, [isSelfDirected]);
  return (
    <Box data-testid="Documents-wrapper-id">
      <DocumentsBlock
        title={t('Disclosure Documents')}
        groups={driveWealthGroup}
      />

    </Box>
  );
};

export default Documents;
