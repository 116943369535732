import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Dependent, DependentPayload } from '@/modules/user/components/ManageProfile/components/Dependents/dependents.types';

interface PersonsResponse {
  dependents?: DependentPayload[];
}

export const QUERY_KEY = 'GET_DEPENDENTS';

const useDependentsQuery = (personId?: string) => {
  const { data, isLoading } = useQuery([QUERY_KEY, personId], () =>
    api.get<PersonsResponse>(PATHS.PERSONS_BY_ID(personId),
      { 
        headers: {
          'X-DETAILS-RESOLVERS': 'DEPENDENT',
        }
      }
    )
  );

  const formattedData = useMemo(() => {
    const dependents = data?.data?.dependents || [];

    return dependents.map(
      (dependent: DependentPayload): Dependent => ({
        id: toString(dependent?.id),
        firstName: dependent?.first_name,
        lastName: dependent?.last_name,
        birthOn: dependent?.local_date_birth_on,
        relationshipType: dependent?.relationship_type,
      })
    );
  }, [data]);

  return {
    formattedData,
    isLoading,
  };
};

export default useDependentsQuery;
