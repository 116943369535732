import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Hint, Text, WarningIcon } from '@common-fe/common-fe';


interface Props {
  isLiquidation: boolean;
  onSetLiquidation: (value:boolean) => void;
  isApplied?: boolean
}
const FullLIquidationToggle: React.FC<Props> = ({ isApplied, isLiquidation, onSetLiquidation}) => {
  const { t } = useTranslation();
  return (
    <Box direction="row" align="center">
      <Checkbox
        checked={isLiquidation || isApplied}
        onChange={onSetLiquidation}
        disabled={isApplied}
      />
      <Text>{t('Full portfolio liquidation')}</Text>
      {isApplied && (
        <Hint
          hintAlign="left"
          hintElement={(
            <Box margin={{ left: 'xs' }}>
              <WarningIcon color="warning" />
            </Box>
          )}
        >
          <Text>{t('Your request exceeds 95% of the portfolio. All holdings will be sold.')}</Text>
        </Hint>
      )}
      
    </Box>
  );

};

export default FullLIquidationToggle;



