import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BankDepositIcon,
  BillingCheckIcon,
  Box,
  PayPalIcon,
  QuestionIcon,
  Text,
  VenmoIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import spacings from '@/styles/spacings';

import { DebitCardForm } from '../DebitCart';
import BankForm from '../DirectDeposit/BankForm';
import { AddPayPalForm } from '../PayPal';
import { AddVenmoForm } from '../Venmo';

// const BUTTON_WIDTH = '150px';

interface Props {
  onClose: () => void;
  onBack: () => void;
  onSuccess: () => void;
  methodForEdit?: PaymentMethod;
}

export const EditPaymentMethodModal: React.FC<Props> = ({
  onClose,
  onBack,
  onSuccess,
  methodForEdit,
}) => {
  const { t } = useTranslation();
  const currentMethodType = useMemo(() => methodForEdit?.type, [methodForEdit]);
  const currentMethodId = useMemo(() => methodForEdit?.id, [methodForEdit]);
  const currentBankAccountId = useMemo(() => methodForEdit?.accountId, [methodForEdit]);

  const handleClearEditableMethodPaymentMethod = useStore(
    (state) => state.handleClearEditableMethodPaymentMethod,
  );

  useEffect(() => () => {
    handleClearEditableMethodPaymentMethod();
  }, [handleClearEditableMethodPaymentMethod]);

  const currentIcon = useMemo(() => {
    const { type } = methodForEdit || {};
    switch (type) {
    case PaymentMethodType.PAYPAL:
      return <PayPalIcon data-testid={`PaymentMethodLabel_icon_${type}`} />;
    case PaymentMethodType.VENMO:
      return <VenmoIcon data-testid={`PaymentMethodLabel_icon_${type}`} />;
    case PaymentMethodType.DEBIT:
      return (
        <BillingCheckIcon
          data-testid={`PaymentMethodLabel_icon_${type}`}
          size={spacings.l}
          color="iconAccent"
        />
      );
    case PaymentMethodType.DIRECT_DEPOSIT:
      return (
        <BankDepositIcon
          data-testid={`PaymentMethodLabel_icon_${type}`}
          size={spacings.spacing48}
          color="iconAccent"
        />
      );
    default:
      return (
        <QuestionIcon
          data-testid="PaymentMethodLabel_no_icon"
          size={spacings.l}
          color="iconAccent"
        />
      );
    }
  }, [methodForEdit]);

  return (
    <ModalWrapper
      visible
      onSetVisible={onClose}
      testId="EditPaymentMethodModal_testId"
    >

      <Box align="center">
        <Box width="xxsmall" alignSelf="center" data-testid="EditPaymentMethodModal_icon">
          {currentIcon}
        </Box>
        <Text
          size="2xl"
          weight="bold"
          color="textBody"
          margin={{ top: 'small', bottom: 'l' }}
        >
          {t('Update Payment Method')}
        </Text>
      </Box>
      {
        currentMethodType === PaymentMethodType.PAYPAL
        && (
          <AddPayPalForm
            onBack={onBack}
            onSuccess={onSuccess}
            editableMethodId={currentMethodId}
          />
        )
      }
      {
        currentMethodType === PaymentMethodType.VENMO
        && (
          <AddVenmoForm
            onBack={onBack}
            onSuccess={onSuccess}
            editableMethodId={currentMethodId}
          />
        )
      }
      {
        currentMethodType === PaymentMethodType.DEBIT
        && (
          <DebitCardForm
            onBack={onBack}
            onSuccess={onSuccess}
            editableMethodId={currentMethodId}
          />
        )
      }
      {
        currentMethodType === PaymentMethodType.DIRECT_DEPOSIT
        && (
          <BankForm
            back={onBack}
            onSuccess={onSuccess}
            bankAccountId={currentBankAccountId}
            paymentMethodId={currentMethodId}
          />
        )
      }
    </ModalWrapper>
  );
};
