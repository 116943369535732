import { storeCreator } from '@/utils/store/store.utils';

interface State {
  modalVisible: boolean;
  cardID: string;
  last4Numbers: string;
  memberSequenceIdentifier: number;
  alternateAccountId: string;
  handleChangeVisibility: (value: boolean) => void;
  handleChangeCardID: (cardID: string) => void;
  handleChangeLast4Numbers: (last4Numbers: string) => void;
  handleChangeMemberSequenceIdentifier: (memberSequenceIdentifier: number) => void;
  handleChangeAlternateAccountId: (alternateAccountId: string) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  cardID: '',
  last4Numbers: '',
  memberSequenceIdentifier: 0,
  alternateAccountId: '',

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleChangeCardID: (cardID) => set((state) => ({
    ...state,
    cardID,
  })),

  handleChangeLast4Numbers: (last4Numbers) => set((state) => ({
    ...state,
    last4Numbers,
  })),

  handleChangeMemberSequenceIdentifier: (memberSequenceIdentifier) => set((state) => ({
    ...state,
    memberSequenceIdentifier,
  })),

  handleChangeAlternateAccountId: (alternateAccountId) => set((state) => ({
    ...state,
    alternateAccountId,
  })),

  handleReset: () => set((state) => ({
    ...state,
    cardID: '',
    last4Numbers: '',
    memberSequenceIdentifier: 0,
    alternateAccountId: '',
  })),
}));
export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
