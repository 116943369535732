import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ValuesMap } from '@/common/types';
import { InvestmentHolding, InvestmentHoldingStatus } from '@/modules/investments/investments.types';
import { AllocationPercentsMap } from '@/modules/investments/SelfDirected/SelfDirected.types';

import { useDelistedFundPresentStore } from '../stores';

interface Params {
  selfDirectedFunds: InvestmentHolding[];
  addedFunds: InvestmentHolding[];
  currentHoldings: InvestmentHolding[];
  initialValues?: AllocationPercentsMap;
}
const MAX_PERCENTS = 100;
const CASH_NAME = 'Cash';

export default ({ selfDirectedFunds, addedFunds, currentHoldings, initialValues }: Params) => {
  const [values, setValues] = useState<AllocationPercentsMap>({});
  const [checkedValues, setCheckedValues] = useState<AllocationPercentsMap>({});
  const [checkedMap, setCheckedMap] = useState<ValuesMap<boolean>>({});

  const selectedIds = useMemo(() => {
    if (initialValues) {
      return Object.keys(initialValues);
    }
    const selectedIds = selfDirectedFunds.filter(
      (item) =>
        item.id &&
        //  && item.election !== 0
        item.name !== CASH_NAME
    );
    return selectedIds.map((item) => item.id);
  }, [selfDirectedFunds, initialValues]);

  const filteredList = useMemo(() => {
    const filteredSelfDirectedFunds = initialValues
      ? selfDirectedFunds.filter((item) => item.id && initialValues[item.id] && item.name !== CASH_NAME)
      : selfDirectedFunds.filter((item) => selectedIds.includes(item.id)).sort((a, b) => b.election - a.election);

    return [...currentHoldings, ...filteredSelfDirectedFunds, ...addedFunds];
  }, [selfDirectedFunds, initialValues, selectedIds, addedFunds, currentHoldings]);

  useEffect(() => {
    const formattedResult = filteredList.reduce((result, item) => {
      const id = item.id || '';
      if (checkedMap[id] === undefined) {
        return {
          ...result,
          [id]: true,
        };
      } else {
        return {
          ...result,
          [id]: checkedMap[id],
        };
      }
    }, {} as ValuesMap<boolean>);
    setCheckedMap(formattedResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ filteredList]);

  const checkedIds = useMemo(() => {
    const result: string[] = [];
    const keys = Object.keys(checkedMap);
    keys.map((key) => {
      if (checkedMap[key] !== false) {
        result.push(key);
      }
    });
    return result;
  }, [checkedMap]);

  const handleGetPercentMap = useCallback(() => {
    const newValues: AllocationPercentsMap = {};
    const isValuesEmpty = Object.keys(values).length === 0;
    filteredList.forEach((item) => {
      newValues[item.id as string] = item.election;
    });

    if (initialValues && isValuesEmpty) {
      setValues(initialValues);
      return;
    }
    setValues(newValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleGetCheckedPercentMap = useCallback(() => {
    const newValues: AllocationPercentsMap = {};
    checkedIds.forEach((item) => {
      newValues[item as string] = values[item as string] || 0;
    });
    setCheckedValues(newValues);
  }, [checkedIds, values]);

  useEffect(() => {
    handleGetPercentMap();
  }, [handleGetPercentMap]);

  useEffect(() => {
    handleGetCheckedPercentMap();
  }, [checkedIds, handleGetCheckedPercentMap]);

  const handleChange = useCallback(
    (id: string) => (newValue: number) => {
      const preparedValue = _.floor(newValue, 2);
      setValues({
        ...values,
        [id]: preparedValue,
      });
    },
    [values]
  );

  const totalPercents = useMemo(() => {
    let total = 0;
    checkedIds.forEach((id) => {
      const value = values[id as string] || 0;
      total += value;
    });
    return _.round(total, 2);
  }, [checkedIds, values]);

  const handleCheckItem = useCallback(
    (id: string) => {
      if (checkedMap[id] === true) {
        setCheckedMap({
          ...checkedMap,
          [id]: false,
        });
      } else {
        setCheckedMap({
          ...checkedMap,
          [id]: true,
        });
      }
    },
    [checkedMap]
  );

  const { t } = useTranslation();
  const errorText = useMemo(() => {
    if (totalPercents !== MAX_PERCENTS) {
      return t('Allocation to funds must be 100% to submit the investment order');
    }
  }, [t, totalPercents]);
  const delistedList = useMemo(() => {
    return filteredList.filter((item) => item.status === InvestmentHoldingStatus.Inactive);
  },[filteredList]);
  const isDelistedFundPresent = useMemo(() => {
    return delistedList.some((item) => values[item.id as string] && checkedMap[item.id || '']);
  },[checkedMap, delistedList, values]);

  const setIsDelistedFundPresent = useDelistedFundPresentStore((state) => state.setDelistedPresent);
  useEffect(() => {
    setIsDelistedFundPresent(isDelistedFundPresent);
  }, [isDelistedFundPresent, setIsDelistedFundPresent]);
  return {
    
    errorText,
    handleCheckItem,
    checkedIds,
    filteredList,
    values,
    checkedValues,
    handleChange,
    totalPercents,
  };
};
