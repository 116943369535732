import React from 'react';
import { Box } from '@common-fe/common-fe';

import { SpendTracker } from '@/modules/deductibles';
import { CIPBanner } from '@/modules/investments/cip';
import { MyEnrollment,RecentActivities } from '@/modules/transaction';
import DisclosureBankMessage from '@/modules/transaction/components/MyAccounts/DisclosureBankMessage';
import { Banner } from '@/modules/user';

import AppWrapper from '../DashboardWrapper';

import { BannersList } from './components/BannersList';
import DashboardModals from './components/DashboardModals/DashboardModals';

const Dashboard = () => {
 
  return (

    <Box data-testid="Dashboard-wrapper">
      <Banner />

      <CIPBanner />

      <BannersList />
      <MyEnrollment />

      <SpendTracker />

      <RecentActivities />
      <DisclosureBankMessage />
      <DashboardModals />
    </Box>
  );
};
Dashboard.Layout = AppWrapper;
export default Dashboard;
