import React, { useEffect } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Preloader, ReceiptIcon, Text } from '@common-fe/common-fe';

import { ACCEPTED_FILE_TYPES, MAX_SIZE_OF_FILE } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import useOnDrop from '@/components/controls/DropScan/useOnDrop';

import {
  DropScanSubtitle2,
  DropScanTitle,
  DropScanTitlePrimary,
  DropScanWrapper,
  Wrapper,
} from './DropScan.styles';

export const DropScan = ({
  onFilesAdded,
  icon,
  title,
  isCenterTitle,
  wrapperStyle,
  additionalAction,
  onFilesSelect,
}: DefaultValues) => {
  const { onDrop, isLoading, isSizeError } = useOnDrop(onFilesAdded, onFilesSelect);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ACCEPTED_FILE_TYPES as unknown as Accept,
    onDrop,
    maxSize: MAX_SIZE_OF_FILE,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (additionalAction) {
      additionalAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper style={wrapperStyle} data-testid="drop_scan_wrapper">
      <DropScanWrapper
        style={{ justifyContent: 'center' }}
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      >
        {!isLoading && <input {...getInputProps()} />}
        {isLoading ? (
          <Box height="120px" justify="center" align="center">
            <Preloader />
          </Box>
        ) : (
          icon || (
            <Box width="72px" height="72px" align="center" justify="center">
              <ReceiptIcon size="36px" color="navigationAccent" />
            </Box>
          )
        )}
        {title || (
          <DropScanTitle style={isCenterTitle && { textAlign: 'center' }}>
            <p>
              {t('Drag or ')}<DropScanTitlePrimary>{t('browse')}</DropScanTitlePrimary> {t(' a picture of your receipt')} 
            </p>
          </DropScanTitle>
        )}
        {/* <DropScanSubtitle>We’ll fill in the details.</DropScanSubtitle> */}
        <DropScanSubtitle2>PDF, JPEG, JPG, PNG, BMP, TIFF, HEIC, HEIF</DropScanSubtitle2>
        <DropScanSubtitle2>Max. file size: 10MB</DropScanSubtitle2>
        {isSizeError && (
          <>
            <Text color="danger" style={{ lineHeight: '20px' }} margin={{ top: 'spacing8' }}>
              {t('Looks like your image is too large (over 10 MB) and can\'t be loaded.')}
            </Text>
            <Text color="danger" style={{ lineHeight: '20px' }}>
              {t('Please try a smaller image size.')}
            </Text>
          </>
        )}
      </DropScanWrapper>
    </Wrapper>
  );
};

export default DropScan;
