import React, { useCallback, useEffect } from 'react';
import { toString } from 'lodash';

import Immediately from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately';
import { useGetPaymentMethods } from '@/modules/transaction/components/ReimburseMe/queries/useGetPaymentMethods.query';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { useAuthStore } from '@/modules/user/stores';

const TransactionProcessingImmediately = () => {
  const { user } = useAuthStore();
  const handleSetPaymentMethods = useStore((state) => state.handleSetPaymentMethods);
  const selectedPaymentMethod = useStore((state) => state.selectedMethod);
  const handleSetActivePaymentMethod = useStore((state) => state.handleSetActivePaymentMethod);
  const clearPaymentMethods = useStore((state) => state.handleClear);
  const { checkIsAvailablePaymentMethod } = useOrganizationProperties();
  const isPaymentMethodAvailable = checkIsAvailablePaymentMethod(selectedPaymentMethod);

  const handleGettingPaymentMethods = useCallback((paymentMethods: PaymentMethod[]) => {
    handleSetPaymentMethods(paymentMethods.filter(
      (method) => method?.paymentMethodOwner?.employeeId === toString(user?.employee?.id),
    ));
  }, [handleSetPaymentMethods, user]);

  const {
    isLoading: isPaymentMethodsLoading,
  } = useGetPaymentMethods(handleGettingPaymentMethods);

  useEffect(() => {
    if (isPaymentMethodAvailable) handleSetActivePaymentMethod();
  }, [handleSetActivePaymentMethod, isPaymentMethodAvailable]);

  useEffect(() => () => {
    clearPaymentMethods();
  },
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  []);

  return (
    <Immediately isLoading={isPaymentMethodsLoading} type="small" />
  );
};

export default TransactionProcessingImmediately;
