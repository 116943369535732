import React, { useCallback,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  Box,
  Preloader,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { INVESTMENTS_ERROR_KEY } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import theme from '@/styles/theme';

import { InvestmentPaths, ManagedInvestmentsQuestionnairePayload } from '../../investments.types';
import ServerErrorModal from '../../modal/ServerErrorModal';
import useInvestmentsQuestionnaireQuery  from '../../queries/useInvestmentQuestionnaire.query';
import { useSubmitQuestionnaireForm } from '../../StartInvesting/hooks';

import { usePortfolioQuestionnaireState } from './hooks';
import InvestmentProfile from './InvestmentProfile';
import PersonalInformation from './PersonalInformation';

interface Props {
  onNext: () => void;
  setProposedToleranceModel?: (model: string) => void;
  stepLength: number;
  step: number;
  onBack?: () => void;
  submitButtonLabel?: string;
  isSwitchMode?: boolean;
  isLoading?: boolean;
}

const BUTTON_WIDTH = '130px';

const PortfolioQuestionnaire: React.FC<Props> = ({
  onNext,
  onBack,
  setProposedToleranceModel,
  stepLength,
  step,
  submitButtonLabel,
  isSwitchMode,
  isLoading
}) => {
  const { t } = useTranslation();
  const { NOT_ANSWER_ALL_QUESTIONS_ERROR } = INVESTMENTS_ERROR_KEY;
  const portfolioQuestionnaireState = usePortfolioQuestionnaireState( ) as ManagedInvestmentsQuestionnairePayload;
  const [error, setError] = useState<string>('');
  const [errorWarningVisible, setErrorWarningVisible] = useState(false);
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);
  const { handleSave , isLoading: isSaveLoading} = useInvestmentsQuestionnaireQuery( {
    investmentType: InvestmentPaths.Managed,
    onSuccess: onNext,
    onError: () => {
      setErrorWarningVisible(true);
    },
    setProposedToleranceModel
  }
  );
  const { setSaveValidate, errorMessages } = useSubmitQuestionnaireForm();

  const handleSubmit = useCallback(() => {
    setSaveValidate(true);
    if (_.isEmpty(errorMessages)) {
      handleSave(portfolioQuestionnaireState);
    } else {
      setError(NOT_ANSWER_ALL_QUESTIONS_ERROR);
    }
  }, [
    handleSave,
    portfolioQuestionnaireState,
    setSaveValidate,
    errorMessages,
    NOT_ANSWER_ALL_QUESTIONS_ERROR,
  ]);

  return (
    <Box direction="row" data-testid="PortfolioQuestionnaire-wrapper">
      <Prompt
        when={!isSwitchMode  && shouldConfirmOnClose}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Box flex="grow" pad={{ horizontal: '40px', bottom: '40px' }}>
        <Text size="3xl" color="blockTitle" margin={{bottom: 's'}}>{t('Portfolio Questionnaire')}</Text>
        <Text size="large" margin={{ bottom: 'spacing24' }}>{t('Let’s get started! Just answer a few questions to determine your investor profile.')}</Text>
        <PersonalInformation onDirty={setShouldConfirmOnClose} />
        <InvestmentProfile onDirty={setShouldConfirmOnClose} />
        {error && !_.isEmpty(errorMessages) && (
          <Box align="end" width="100%" margin={{ bottom: 'spacing24' }}>
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>{t(error)}</Text>
          </Box>
        )}
        <Box direction="row" align="center" justify="end">
          <Box direction="row" justify="end" align="center">
            {onBack && (
              <AppButton
                buttonType="secondary"
                width={BUTTON_WIDTH}
                onClick={() => onBack()}
                containerStyle={{ marginRight: theme.spacings.s }}
              >
                {t('Back')}
              </AppButton>
            )}
            <AppButton
              type="submit"
              width={BUTTON_WIDTH}
              onClick={() => handleSubmit()}
            >
              {
                (isLoading || isSaveLoading)
                  ? <Preloader color="white" />
                  : t(submitButtonLabel || `Next (${step}/${stepLength})`)
              }
            </AppButton>
          </Box>
        </Box>
      </Box>
      <ServerErrorModal
        visible={errorWarningVisible}
        onSetVisible={setErrorWarningVisible}
        onSendRequest={() => handleSave(portfolioQuestionnaireState)}
        isLoading={isLoading || isSaveLoading}
      />
    </Box>
  );
};

export default PortfolioQuestionnaire;
