import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarnModal } from '@common-fe/common-fe';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const SuccessfulAuthenticationModal: React.FC<Props> = ({ onSetVisible }) => {
  const { t } = useTranslation();

  return (
    <WarnModal
      visible
      onSetVisible={onSetVisible}
      header={t('Success')}
      submitButtonText={t('Go To Home Dashboard')}
      isSuccessIcon
      testId='SuccessfulAuthenticationModal'
    />
  );
};

export default SuccessfulAuthenticationModal;
