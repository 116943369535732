import {
  useMemo,
} from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { InvestmentActivitiesParams,InvestmentActivityDetails, InvestmentHoldingStatus, InvestmentOrderType, InvestmentsType } from '@/modules/investments/investments.types';

const QUERY_KEY = 'investmentActivityList';
const ENTITY_PATH = 'data.totalElements';

interface InvestmentActivityDTO {
  amount: number;
  date_time: string;
  fund_name: string;
  image_url: string;
  status: string;
  shares: number;
  order_type?: InvestmentOrderType;
  symbol: string;
  type: InvestmentsType;
  instrument_status: InvestmentHoldingStatus;
  side: string;
}

export interface ActivityRow {
  claimId: number;
  postingId: number;
  amount: number;
  category: string;
  title: string;
  date: string;
  status: string;
}

const useInvestmentActivitiesListQuery = (searchParams: InvestmentActivitiesParams) => {
  const {
    isLoading, data, isSuccess, refetch,
  } = useQuery([QUERY_KEY, searchParams], () => api.get(`${PATHS.INVESTMENTS_ACTIVITIES}`, {
    params: { ...searchParams },
  }));

  const formattedData = useMemo<InvestmentActivityDetails[]>(() => {
    const investmentActivities = data?.data?.content || [];
    
    return investmentActivities?.map((item: InvestmentActivityDTO) => ({
      amount: item.amount,
      name: item.fund_name,
      image: item.image_url,
      status: item.status,
      dateTime: item.date_time,
      shares: item.shares,
      symbol: item.symbol,
      orderType: item.order_type,
      instrumentStatus: item.instrument_status,
      type: item.type,
      side: item.side,
    }));
  }, [data]);

  const total = useMemo<number>(() => _.get(data, ENTITY_PATH, 0) || 0, [data]);
  return ({
    data: formattedData,
    isLoading,
    isSuccess,
    total,
    refetch,
  });
};

export default useInvestmentActivitiesListQuery;
