import React, { useCallback } from 'react';
import { AppButton, Box, Preloader } from '@common-fe/common-fe';

import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { useEmployeeProcessQuery } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/queries/';
import { ProcessingScreenType } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.constants';
import { Wrapper } from '@/modules/transaction/components/TransactionDetails/TransactionProcessing/TransactionProcessing.styles';

import TransactionProcessingHeader from '../TransactionProcessingHeader';

interface Props {
  claimId: string;
  providerPaymentMethod?: PaymentMethod;
}

const PayMyProviderScreen: React.FC<Props> = ({
  claimId,
  providerPaymentMethod,
}) => {
  const { employeeProcess, isLoading } = useEmployeeProcessQuery(claimId);

  const handleSubmit = useCallback(() => {
    if (providerPaymentMethod) {
      employeeProcess({
        paymentMethodId: providerPaymentMethod.id,
        transactMethod: providerPaymentMethod.type,
      });
    }
  }, [employeeProcess, providerPaymentMethod]);

  return (
    <Wrapper
      pad="xl"
      background="canvas"
      border={{ size: 'small', color: 'border2' }}
    >
      <TransactionProcessingHeader
        processingScreenType={ProcessingScreenType.PayMyProvider}
      />
      <Box margin={{ top: 'spacing24' }}>
        <AppButton
          width="100%"
          size="L"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <Preloader />
          ) : 'Pay My Provider'}
        </AppButton>
      </Box>
    </Wrapper>
  );
};

export default PayMyProviderScreen;
