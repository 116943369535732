import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { CardHolderDto } from '@/modules/transaction/components/Cards/Cards.types';
import colors from '@/styles/colors';

interface Params {
  holders: CardHolderDto[],
  defaultType: string | null;
}

export const useTypeForm = (params: Params) => useMemo((): Field[] => [
  {
    name: 'type',
    type: FieldTypes.Dropdown,
    label: 'Select dependent',
    placeholder: 'None',
    singleMode: true,
    validator: yup.string().required(REQUIRED_TEXT),
    defaultValue: params.defaultType ? params.defaultType : '',
    options: [
      {
        key: 'new',
        value: 'new',
        title: 'Create New Dependent',
        divide: true,
        textStyle: {
          color: colors.textAccent,
          fontWeight: 400,
        },
      },
      ...params.holders.map((holder) => ({
        key: holder.dependentId,
        value: holder.dependentId,
        title: holder.name,
        textStyle: {
          fontWeight: 400,
        },
      })),
    ],
  },
], [params]);
