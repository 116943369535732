import React from 'react';
import { useTranslation } from 'react-i18next';
import { Inscription } from '@common-fe/common-fe';

const LimitedAccessLabel = () => {
  const { t } = useTranslation();

  return (
    <Inscription size="12px">
      {t('Complete your setup to gain full access to your account.')}
    </Inscription>
  );
};

export default LimitedAccessLabel;
