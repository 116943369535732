import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import { useShowLockBanner } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user';

const OrgLockBanner: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuthStore((state) => state.auth);
  const isLockBannerShowed = useShowLockBanner();
  if (!isLockBannerShowed || !auth) return null;
  return (
    <Box
      width="100%"
      height="62px"
      background="warningBorder"
      justify="center"
      align="center"
      pad={{ horizontal: 'm' }}
      style={{
        position: 'fixed',
        zIndex: 1003,
        top: 0,
      }}
    >
      <Text size="large">{t('Your organization has a temporary lock on non-HSA payments. Please contact your employer.')}</Text>
    </Box>
  );
};

export default OrgLockBanner;
