import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';

export interface FormValues {
  employeeProvidedEmail: string;
}

const useEmailVerificationFields = (email: string) => {

  const fields = useMemo<Field[]>(() => [
    {
      name: 'employeeProvidedEmail',
      type: FieldTypes.Text,
      label: 'Employee provided email',
      placeholder: 'Employee provided email',
      showRequireIcon: true,
      defaultValue: email,
      style: { display: 'grid', gridTemplateColumns: 'minmax(200px, 1fr) 320px' },
      validator: VALIDATORS.EMAIL_REQUIRED,
    },
  ], [email]);

  return fields;
};

export default useEmailVerificationFields;
