import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ClaimAutoPayType,ClaimPayee } from '@/modules/user/user.types';

export interface AutoPayProperties {
  claimAutoPayType?: ClaimAutoPayType,
  defaultClaimPayeeType?: ClaimPayee,
}

const useUpdateAutoPayPropertiesQuery = (employeeId: string) => {
  const {
    mutateAsync, isLoading, isError, isSuccess,
  } = useMutation(
    (properties: AutoPayProperties) => api.patch(PATHS.CLAIM_CONFIG(employeeId), {
      claim_autopay_type: properties.claimAutoPayType,
      default_claim_payee_type: properties.defaultClaimPayeeType,
    }),
  );

  return {
    updateAutoPayProperties: mutateAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useUpdateAutoPayPropertiesQuery;
