import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings.spacing8};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacings.spacing32};
`;

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.text32Bold};
  margin-bottom: ${({ theme }) => theme.spacings.spacing12};
  color: ${({ theme }) => theme.colors.textBody || ''};
`;
export const HeaderWrapper = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
