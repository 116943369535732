import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, InvestmentsLiquidationPending,Text } from '@common-fe/common-fe';

const InvestmentLiquidationPendingBanner = () => {

  const { t } = useTranslation();
  return (
    <Box direction='column'
      pad="l"
      background="module"
      round="moduleRound"
    >
      <Box direction='row'
        background="canvas"
        elevation="default"
        round="container1Round"
        pad={{vertical: 's', horizontal: 'l', right: 'xxxl'}}
        border={{size: 'small', color: 'border2'}}
      > 
        <Box style={{flex: 1}} justify='center'>
          <Heading level={4} margin={{bottom: 'xs'}}>{t('Your Full Portfolio Liquidation Is In Progress')}</Heading>
          <Text size="medium">{t('Once the sell orders are settled, you will no longer have active investments.')}
          </Text>
          <Text size="medium">{t('You can start investing again when ready.')}
          </Text>
        </Box>
        <Box margin={{left: 'l'}}>
          <InvestmentsLiquidationPending />
        </Box>
      </Box>
    </Box>
    
  );
}; 

export default InvestmentLiquidationPendingBanner;