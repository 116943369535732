import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  NavigationLeftIcon,
  Text,
  VenmoIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';
import { DuplicationErrorModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DublicationErrorModal/DuplicationErrorModal';

import { BackIcon } from '../DirectDeposit/StyledComponents.styles';

import { AddVenmoForm } from './AddVenmoForm';

const DESCRIPTION = 'Enter your personal Venmo account phone number to get started.';

interface Props {
  onClose: () => void;
  onBack: () => void;
  onSuccess: () => void;
  hideNavigationButton?: boolean;
  allowSaveAsDefault?: boolean;
}

export const AddVenmoAccountModal: React.FC<Props> = ({ onClose, onBack, onSuccess, hideNavigationButton, allowSaveAsDefault }) => {
  const { t } = useTranslation();
  const [serverErrorShow, setServerErrorShow] = useState(false);

  return (
    <ModalWrapper
      visible
      onSetVisible={onClose}
      testId="AddVenmoAccountModal_testId"
    >
      {onBack && !hideNavigationButton && (
        <BackIcon onClick={onBack} data-testid="back-icon-button">
          <NavigationLeftIcon color="iconPrimary"
            size="18px"
          />
        </BackIcon>
      )}

      <Box align="center">
        <Box width="xxsmall" alignSelf="center" data-testid="Venmo_icon">
          <VenmoIcon />
        </Box>

        <Text
          size="2xl"
          weight="bold"
          color="textBody"
          margin={{ top: 'small' }}
        >
          {t('Venmo')}
        </Text>

        <Text
          textAlign="center"
          color="textBody"
          margin={{ top: 'small', bottom: 'medium' }}
        >
          {t(DESCRIPTION)}
        </Text>
      </Box>

      <AddVenmoForm
        onBack={onBack}
        onSuccess={onSuccess}
        onError={() => setServerErrorShow(true)}
        allowSaveAsDefault={allowSaveAsDefault}
      />
      <DuplicationErrorModal
        visible={serverErrorShow}
        close={() => setServerErrorShow(false)}
      />
    </ModalWrapper>
  );
};
