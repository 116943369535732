import React, { useCallback, useMemo, useState } from 'react';
import { Box, Field, FlexControlledForm } from '@common-fe/common-fe';

import { useGetPaymentMethods } from '@/modules/transaction/components/ReimburseMe/queries/useGetPaymentMethods.query';
import { PaymentMethodType } from '@/modules/user/user.types';

import { useMakeContributionPaymentMethodField } from '../hooks/useMakeContributionPaymentMethodField';

import { AddContributionBankAccountModal } from './AddContributionBankAccountModal';

interface Props {
  isSubmit: boolean;
  handleChangeValues: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    handleChangeValues: (val: boolean) => void,
    isPayment?: boolean,) => void;
  setPaymentMethodSetup: (value: boolean) => void;
  currentPaymentMethodId?: number;
}

export const MakeContributionPaymentMethodForm: React.FC<Props> = ({
  isSubmit,
  handleChangeValues,
  setPaymentMethodSetup,
  currentPaymentMethodId,
}) => {
  const [addBankAccountModalVisible, setAddBankAccountModalVisible] = useState(false);
  const [newBankAccountId, setNewBankAccountId] = useState<string | undefined>(undefined);
  const directDepositPaymentMethods = useGetPaymentMethods(
    undefined,
    PaymentMethodType.DIRECT_DEPOSIT,
  );

  const paymentOptions = useMemo(() => directDepositPaymentMethods?.data?.map((method) => ({
    key: `${method.id}`,
    value: method.id,
    title: `${method.account_description || 'Without name'} (Bank a/c number ends ${method.account_number_last4 || '••••'})`,
  })), [directDepositPaymentMethods]);

  const paymentMethodField = useMakeContributionPaymentMethodField(
    paymentOptions,
    () => setAddBankAccountModalVisible(true),
    newBankAccountId,
    currentPaymentMethodId ? `${currentPaymentMethodId}` : undefined,
  );

  const handleChangePaymentMethodValues = useCallback((values: object) => {
    handleChangeValues(values, paymentMethodField, setPaymentMethodSetup, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box data-testid="contribution-payment-method-form" round="container1Round" background="canvas">
        <FlexControlledForm
          fields={paymentMethodField}
          editMode
          showError={isSubmit}
          onChangeValues={handleChangePaymentMethodValues}
          isModalType
        />
      </Box>
      <AddContributionBankAccountModal
        visible={addBankAccountModalVisible}
        onSetVisible={setAddBankAccountModalVisible}
        setCurrentBankAccount={setNewBankAccountId}
      />
    </>
  );
};
