import { storeCreator } from '@/utils/store/store.utils';

interface State {
  selectedFunds: string[];
  setSelectedFunds: (values: string[]) => void;
  pushSelectedFunds: (values: string[]) => void;
}

const useStore = storeCreator<State>((set) => ({
  selectedFunds: [],
  setSelectedFunds: (data: string[]) => set(() => ({ selectedFunds: data })),
  pushSelectedFunds: (data: string[]) => set((state) => ({ selectedFunds: [...state.selectedFunds, ...data] })),
}));

export default useStore;
