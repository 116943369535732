import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription,WarningIcon } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';

interface Props {
  onClick?: () => void;
}

const AddAddress: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Box
      background="warningContainer"
      pad={{ vertical: '16px', horizontal: 'medium' }}
      margin={{ top: 'small', bottom: '16px' }}
      round="container2Round"
      border={{ color: 'warningBorder', size: 'small' }}
      data-testid="Add-address-wrapper"
    >
      <Box direction="row" align="center">
        <WarningIcon />
        <Inscription color="textBody" lineHeight="20px" weight="bold" margin={{ left: '10px' }}>
          {t('The Provider/Merchant must have an address to send this bill for payment.')}
        </Inscription>
      </Box>

      <Inscription color="textBody" margin={{ top: '10px', bottom: 'spacing16' }}>
        {t('Please add the address of the Provider/Merchant.')}
      </Inscription>

      <AppButton
        testId="ClaimInfo_add_address"
        onClick={onClick}
        width="control"
      >
        {t('Add Address')}
      </AppButton>
    </Box>
  );
};

export default AddAddress;
