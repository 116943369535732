import { useCallback, useMemo } from 'react';
import { ListItemField } from '@common-fe/common-fe';

import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import { useSetDefaultPaymentMethodQuery } from '@/modules/transaction/components/ReimburseMe/queries/useUpdatePaymentMethod.query';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { getPaymentMethodDetails } from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/components/PaymentMethodsList/getPaymentMethodName';
import { PaymentMethodType } from '@/modules/user/user.types';
import theme from '@/styles/theme';

interface Props {
  refetchPaymentMethods: () => void;
  handleOpenEditModal: () => void;
  handleOpenRemoveModal: () => void;
}

const usePaymentMethodOptions = ({
  refetchPaymentMethods, handleOpenEditModal, handleOpenRemoveModal
}: Props) => {
  const { methods } = useStore();
  const handleSetEditableMethodPaymentMethod = useStore((state) => state.handleSetEditableMethodPaymentMethod);
  const { updateDefaultPaymentMethod } = useSetDefaultPaymentMethodQuery();
  const { handleAddPermanentSnackbar } = useSnackbar();

  const checkPaymentMethodsIds = useMemo(() => {
    const checkPaymentMethods = methods.filter((paymentMethod) => paymentMethod.type === PaymentMethodType.CHECK);
    return new Set(checkPaymentMethods.map((paymentMethod) => paymentMethod.id));
  }, [methods]);

  const handleSetAsDefault = useCallback(async (paymentMethodId: string) => {
    await updateDefaultPaymentMethod(paymentMethodId);
    refetchPaymentMethods();
  
    const updatedPaymentMethod = methods.find((paymentMethod) => paymentMethod.id === paymentMethodId);
    const paymentMethodName = getPaymentMethodDetails(updatedPaymentMethod);
    handleAddPermanentSnackbar({
      text: `${paymentMethodName.typeName}: ${paymentMethodName.paymentMethodDescription} is your default payment method`,
      state: SnackbarState.positive,
      closeIcon: true,
      autoRemove: true,
    });
  }, [updateDefaultPaymentMethod, refetchPaymentMethods, handleAddPermanentSnackbar, methods]);

  const handleEdit = useCallback((paymentMethodId: string) => {
    handleOpenEditModal();
    const paymentMethod = methods.find((paymentMethod) => paymentMethod.id === paymentMethodId);
    if (paymentMethod) {
      handleSetEditableMethodPaymentMethod(paymentMethod);
    }
  }, [methods, handleOpenEditModal, handleSetEditableMethodPaymentMethod]);

  const handleRemove = useCallback((paymentMethodId: string) => {
    handleOpenRemoveModal();
    const paymentMethod = methods.find((paymentMethod) => paymentMethod.id === paymentMethodId);
    if (paymentMethod) {
      handleSetEditableMethodPaymentMethod(paymentMethod);
    }
  }, [methods, handleOpenRemoveModal, handleSetEditableMethodPaymentMethod]);

  const options = useMemo(() => [
    {
      name: 'Set as Default',
      onClick: handleSetAsDefault,
    },
    {
      name: 'Edit',
      onClick: handleEdit,
      isAvailable: (fields: ListItemField[], paymentMethodId: string) => !checkPaymentMethodsIds.has(paymentMethodId),
    },
    {
      name: 'Remove',
      onClick: handleRemove,
      isWarning: true,
      optionStyle: { color: theme.colors.danger }, 
      isAvailable: (fields: ListItemField[], paymentMethodId: string) => !checkPaymentMethodsIds.has(paymentMethodId),
    },
  ], [handleSetAsDefault, handleEdit, handleRemove, checkPaymentMethodsIds]);

  return {
    options,
  };
};

export default usePaymentMethodOptions;
