import { api } from '@/api';
import { API_KEY, GATEWAY_KEY } from '@/api/api';
import PATHS from '@/common/paths';
import {
  OcrWarningFields,
  OcrWarnings,
  OcrWarningType,
  ParsedOcrWarnings,
  TransactionParsedFileByOcr,
  TransactionParsedFileByOcrPayload,
} from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';
import { CONFIG } from '@/utils';

export const formatWarnings = (parsedWarnings?: ParsedOcrWarnings[]) => {
  const ocrWarnings: OcrWarnings = {
    [OcrWarningType.MISSING]: [],
    [OcrWarningType.OUT_OF_COVERAGE_DATE]: [],
    [OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE]: [],
    [OcrWarningType.DUPLICATE_RECEIPT]: [],
  };

  if (!parsedWarnings || parsedWarnings.length === 0) {
    return ocrWarnings;
  }

  parsedWarnings.forEach((warning) => {
    const warningFields = warning?.fields?.map((fieldName) => OcrWarningFields[fieldName]);
    if (warningFields && warning?.type) {
      ocrWarnings[warning.type]?.push(...warningFields);
    }
  });

  return ocrWarnings;
};

const formatData = (data: TransactionParsedFileByOcrPayload): TransactionParsedFileByOcr => ({
  aiApproved: data?.ai_approved,
  warningsFields: formatWarnings(data?.warnings),
});

const useGetScannedDocumentByHashIdQuery = () => {
  const getScannedDocument = async (fileId: string, hashId: string) => {
    try {
      const url = `${CONFIG.BASE_URL?.replace(GATEWAY_KEY, API_KEY)}${PATHS.GET_CLAIM_SCANNED_FILE_BY_HASH_ID(fileId)}`;
      const { data } = await api.get(url, {
        params: {
          hash_id: hashId,
        },
      });

      const formattedData = formatData(data);
      return formattedData;
    } catch (error) {
      return null;
    }
  };

  return { getScannedDocument };
};

export default useGetScannedDocumentByHashIdQuery;
