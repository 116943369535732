import React from 'react';

import { ErrorModal } from '@/components';

const ErrorBlock = () => (
  <ErrorModal
    visible
    onSetVisible={() => {
      window.history.back();
      window.history.back();
    }}
    header="Something went wrong"
    helptext="Some technical issues occur during login. Check your credentials and try again."
    buttonText="Back"

  />
);

export default ErrorBlock;
