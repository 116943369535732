import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DATE_TOOLTIP_FORMAT } from '@/common/constants';
import ROUTES from '@/common/routes';

interface Props {
  cardSwipeDate?: string;
  coverageDateEnd?: string;
}

const PastPlanEndDateText: React.FC<Props> = ({
  cardSwipeDate, coverageDateEnd,
}) => {
  const { t } = useTranslation();
  return (
    <Text size="large" margin={{ top: 'xs' }}>
      {`${t('There are no funds* to pay from for a date of service of')} ${dayjs(cardSwipeDate || '').format(DATE_TOOLTIP_FORMAT)}. ${t('If this is to pay for an expense prior to')} ${dayjs(coverageDateEnd || '').format(DATE_TOOLTIP_FORMAT)} ${t('you can')} `}
      <a href={ROUTES.REIMBURSE_ME} target="_blank" rel="noreferrer">
        {t('submit a reimbursement request')}
      </a>.
    </Text>
  );
};

export default PastPlanEndDateText;
