import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';

import { useStore as usePersonalInformationStore } from '../../stores/usePersonalInformation.store';

const VALIDATOR = yup.string().nullable();
const TRUE_KEY = 'true';
const DIRECTOR_KEY = 'director';

export default () => {
  const { t } = useTranslation();
  const defaultFields = usePersonalInformationStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => (
    [
      {
        testId: 'Director-field',
        name: DIRECTOR_KEY,
        type: FieldTypes.Radio,
        showRequireIcon: true,
        expandItems: true,
        label: t('Are you a control person of a publicly traded company? A Director, Officer or 10% stock owner?'),
        placeholder: '',
        options: [
          {
            label: t('Yes'),
            subLabel: t('Enter your company name and it\'s ticker symbol.'),
            value: TRUE_KEY,
            isWrappedMode: true,
          },
          {
            label: t('No'),
            value: 'false',
          },
        ],
        validator: yup.string().required(REQUIRED_TEXT),
        subFields: [
          {
            name: 'directorOf',
            parentValue: TRUE_KEY,
            type: FieldTypes.Text,
            label: '',
            placeholder: 'Enter your Company name and it’s ticker symbol',
            validator: yup.string().trim().when(DIRECTOR_KEY, {
              is: (val: string) => val === TRUE_KEY,
              then: yup.string().required(REQUIRED_TEXT),
              otherwise: VALIDATOR,
            }),
          },
        ],
      },
    ]
  ), [t]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => {
        let subFields: Field[] | undefined;
        if (item.subFields) {
          subFields = item?.subFields?.map((subtitleItem) => ({
            ...subtitleItem,
            value: defaultValues[subtitleItem.name] || subtitleItem.value || '',
          })) as Field[];
        }
        return ({
          ...item,
          subFields,
          value: defaultValues[item.name] || item.value || '',
        });
      }) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
