import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  costFormater,
  Preloader,
  Text,
} from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import { PendingModal } from '@/components/elements';

const BUTTON_WIDTH = '130px';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onConfirm: () => void;
  riskValue?: string
  investmentAmount?: number;
  isLoading?: boolean;
  isSwitchMode?: boolean;
}
const ConfirmCancelModal: React.FC<Props> = ({
  visible,
  investmentAmount,
  isLoading,
  riskValue,
  onSetVisible,
  onConfirm,
  isSwitchMode
}) => {
  const { t } = useTranslation();
  const handleSubmit = () => {
    onConfirm();
  };
  const TOLERANCE_MODEL_OPTIONS = useMemo(
    () => [
      {
        label: t('Conservative'),
        
        value: 'CONSERVATIVE',
      },
      {
        label: t('Moderately conservative'),
        value: 'MODERATELY_CONSERVATIVE',
      },
      {
        label: t('Moderate'),
        value: 'MODERATE',
      },
      {
        label: t('Moderately aggressive'),
        value: 'MODERATELY_AGGRESSIVE',
      },
      {
        label: t('Aggressive'),
        value: 'AGGRESSIVE',
      },
    ], [t],
  );
  const riskLabel = useMemo(() => TOLERANCE_MODEL_OPTIONS.find((item) => item.value === riskValue)?.label, [TOLERANCE_MODEL_OPTIONS, riskValue]);
  const header = useMemo(() => {
    
    if(isSwitchMode) {
      return  t(`Update your portfolio to to the ${riskLabel} Managed model?`); 
    }

    return t(`Invest ${costFormater(investmentAmount, true)} in the ${riskLabel} model?`);
  }, [investmentAmount, isSwitchMode, riskLabel, t]);
  const subText = useMemo(() => {
    
    if(isSwitchMode) {
      return  t('Updating your investment model will transfer your entire portfolio balance to the investment model selected. This will take 3 - 4 business days to complete. During that time you will not be able to make changes to your investment portfolio. This includes additional buys or sells.'); 
    }

    return null;
  }, [isSwitchMode, t]);
  return (
    <PendingModal
      header={header}
      
      visible={visible}
      onSetVisible={onSetVisible}
    >
      <Box direction="column" justify='center' align='center'>
        <Box margin={{bottom: 'l'}}>
          <Text size="medium" textAlign='center'>
            {subText}
          </Text>
        </Box>
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton
              onClick={() => onSetVisible(false)}
              buttonType="secondary"
              width={BUTTON_WIDTH}
            >
              {t('Cancel')}
            </AppButton>
          </Box>
          <AppButton disabled={isLoading} onClick={handleSubmit} width={BUTTON_WIDTH}>
            {
              isLoading
                ? <Preloader color="white" />
                : t('Confirm')
            }
          </AppButton>
        </Box>
      </Box>
    </PendingModal>
  );
};

export default ConfirmCancelModal;
