import React, {
  useCallback, useEffect, useMemo,
  useState, } from 'react';
import {
  Box,
  getDuration,
  Notification,   NotificationCenter,   useForceIntervalUpdate, } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import NotificationModal from '@/modules/notification/NotificationDetailsModal/NotificationModal';
import { useNotificationCenterStore } from '@/modules/notification/store';

import { useNotifications } from '../hooks';

const NotificationsCenter = () => {
  const history = useHistory();
  const isOpenNotificationCenter = useNotificationCenterStore(
    (state) => state.isOpenNotificationCenter,
  );
  const setNotificationCenterState = useNotificationCenterStore(
    (state) => state.setNotificationCenterState,
  );

  const [activeNotification, setActiveNotification] = useState<Notification | undefined>();
  const {
    data: notifications, handleDismissAll, handleDismissById, handleRead, isSuccess,
  } = useNotifications();
  const [
    notificationsData,
    setFiltredNotifications,
  ] = useState<Notification[]>([]);
  useEffect(() => {
    if (isSuccess) {
      setFiltredNotifications([...notifications]);
    }
  }, [isSuccess, notifications]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const renderKey = useForceIntervalUpdate(getDuration({ minutes: 1 }));
  const [previousLength, setPreviousLength] = useState(0);
  useEffect(() => {
    if (isOpenNotificationCenter) {
      if (notificationsData.length > previousLength) {
        handleRead();
      }
      setPreviousLength(notificationsData.length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenNotificationCenter, notificationsData]);
  const handleAllRedirect = useCallback(() => {
    history.push(ROUTES.NOTIFICATIONS);
    setNotificationCenterState(false);
  }, [history, setNotificationCenterState]);
  const dismissedNotificationsTotal = useNotificationCenterStore(
    (state) => state.dismissedNotificationsTotal,
  );
  const total = useMemo(
    () => notifications.length + dismissedNotificationsTotal,
    [dismissedNotificationsTotal, notifications.length],
  );
  const handleGoToPage = useCallback((path?: string) => {
    const link = path || activeNotification?.page;
    if (link) {
      history.push(link);
      setNotificationCenterState(false);
      setOpenModal(false);
    }
  }, [activeNotification, history, setNotificationCenterState]);

  return (
    <Box data-testid="Notification_center_wrapper">
      <NotificationCenter
        key={renderKey}
        notificationsArray={notificationsData}
        setNotificationCenterState={setNotificationCenterState}
        setActiveNotification={setActiveNotification}
        setOpenModal={setOpenModal}
        dismissNotification={handleDismissById}
        dismissAllNotifications={handleDismissAll}
        allCount={total}
        onGoToPage={handleGoToPage}
        onAllRedirect={handleAllRedirect}
        isModalOpened={openModal}
        isOpenNotificationCenter={!!isOpenNotificationCenter}
      />
      <NotificationModal
        notification={activeNotification}
        setOpenModal={setOpenModal}
        openModal={openModal}
        isPageLinkHidden={!activeNotification?.page}
        onGoToPage={handleGoToPage}
        dismissNotification={handleDismissById}
      />
    </Box>
  );
};

export default NotificationsCenter;
