import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Preloader,
  RoundPendingIcon,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { ModalWrapper } from '@/components';
import { AllocationPercentsMap, AutoInvestmentRules } from '@/modules/investments/SelfDirected/SelfDirected.types';

import ServerErrorModal from '../../modal/ServerErrorModal';
import { AutoInvestmentType } from '../AutoInvestment.types';

import { useSetupAutoInvestments, useSetupAutoInvestmentsState } from './hooks';
import { useSetupAutoInvestmentsQuery } from './queries';
import { SuccessAutoInvestmentModal } from './SuccessAutoInvestmentModal';

const BUTTON_WIDTH = '140px';

interface Props {
  defaultValue?: string;
  defaultPercentValues?: AllocationPercentsMap;
  investmentId?: string;
  accountId?: string;
  isEditMode?: boolean;
  visible: boolean;
  onSetVisible: (value: boolean) => void;
}
const PauseAutoInvestmentsModal: React.FC<Props> = ({
  accountId, visible, onSetVisible, isEditMode, defaultValue, investmentId, defaultPercentValues,
}) => {
  const { t } = useTranslation();
  const [showServerError, setShowServerError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const {
    investmentThreshold,
    availableBalance,
  } = useSetupAutoInvestments({
    accountId,
  });
  const {
    value, setValue,
  } = useSetupAutoInvestmentsState({
    minimumValue: investmentThreshold,
    availableBalance,
  });
  const { mutateAsync: handleSave, isLoading } = useSetupAutoInvestmentsQuery({
    isEditMode,
    investmentId,
  });

  useEffect(() => {
    if (!visible) {
      setValue('');
    }
  }, [setValue, visible]);

  const preparedPercentValues = useMemo(() => {
    const result: AutoInvestmentRules[] = [];

    if (defaultPercentValues && Object.keys(defaultPercentValues).length) {
      Object.keys(defaultPercentValues).forEach((key) => {
        result.push({
          instrument_id: key,
          percentage: defaultPercentValues[key],
        });

      });
      return result;
    }

    return undefined;
  } ,[defaultPercentValues]);

  const handleCancel = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleCloseSuccessModal = useCallback(() => {
    setShowSuccessModal(false);
    onSetVisible(false);
  }, [onSetVisible]);

  const handleSubmit = useCallback(async () => {
    if(isLoading) {
      return;
    }
    try {
      await handleSave({
        schedulerId: investmentId,
        amountToKeep: _.toNumber(value),
        autoInvestmentRules: preparedPercentValues,
        status: AutoInvestmentType.Paused,
      });
      setShowSuccessModal(true);
    } catch {
      setShowServerError(true);
    }
    finally {
      onSetVisible(false);
    }
  }, [isLoading, handleSave, investmentId, value, preparedPercentValues, onSetVisible]);

  useEffect(() => {
    if (isEditMode && visible && defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, isEditMode, setValue, visible]);

  return (
    <>
      <ModalWrapper testId="PauseAutoInvestmentsModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
        <Box align="center" pad="spacing24">
          <RoundPendingIcon color="warning" />
          <Text
            data-testid="PauseAutoInvestmentsModal_modal_header"
            margin={{ vertical: 'spacing12' }}
            textAlign="center"
            size="24px"
            weight={700}
          >
            {t('Pause Automatic Investments?')}
          </Text>
          <Text
            data-testid="PauseAutoInvestmentsModal_help-text"
            margin={{ bottom: 'spacing24' }}
            textAlign="center"
          >
            {t('While paused, no funds will be automatically pulled from your HSA. You can reactivate Automatic Investments at any time.')}
          </Text>

          <Box direction="row" gap="spacing12">
            <AppButton
              testId="PauseAutoInvestmentsModal_cancel"
              buttonType="secondary"
              onClick={handleCancel}
              width={BUTTON_WIDTH}
            >
              {t('Cancel')}
            </AppButton>
            <AppButton
              testId="PauseAutoInvestmentsModal_confirm"
              onClick={handleSubmit}
              width={BUTTON_WIDTH}
            >
              {isLoading ? <Preloader color="white" /> : t('Yes, Pause')}
            </AppButton>
          </Box>
        </Box>
      </ModalWrapper>
      <SuccessAutoInvestmentModal
        header={t('Automatic Investments Paused')}
        buttonText={t('Go to Investments Hub')}
        visible={showSuccessModal}
        onSetVisible={setShowSuccessModal}
        onSubmit={handleCloseSuccessModal}
      />
      <ServerErrorModal
        visible={showServerError}
        isLoading={isLoading}
        onSetVisible={setShowServerError}
        onSendRequest={handleSubmit}
      />
    </>
  );
};

export default PauseAutoInvestmentsModal;
