import { useState } from 'react';
import { OptionKey, usePaginationParams } from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import { SORT_OPTIONS } from '@/modules/transaction/components/Statements/statements.types';

const useSearchParams = () => {
  const [sortParams, setSortParams] = useState<OptionKey>(SORT_OPTIONS[1].key);
  const [years, setYears] = useState<OptionKey[]>([ALL_OPTION.value]);
  const {
    page, setPage, setPerPage, perPage, searchString, setSearchString, currentSearch,
  } = usePaginationParams(10);

  return {
    page,
    setPage,
    setPerPage,
    perPage,
    searchString,
    setSearchString,
    currentSearch,
    sortParams,
    setSortParams,
    years,
    setYears,
  };
};

export default useSearchParams;
