import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetter, DateFieldPickerSide, Field, FieldTypes,
  Inscription, } from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DATE_INVALID_ALTERNATIVE_TEXT, DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import regexp from '@/common/regexp';
import { getFullAddress } from '@/utils';

import { FormField } from '../../ReimburseMe/ReimburseMe.types';
import { OcrWarnings, OcrWarningType, ParsedFileByOcr } from '../FileScan/fileScan.types';
import { useClaimInfoStore } from '../store/useClaimInfo.store';

import { OcrFieldsWarningErrorsTracker } from './ClaimWarningBanner/OcrFieldsWarningErrorsTracker';
// import { RetailOutOfCoverageDateErrorBanner } from './ClaimWarningBanner/RetailOutOfCoverageDateErrorBanner';
import { OutOfCoverageDateWarningBanner } from './ClaimWarningBanner/OutOfCoverageDateWarningBanner';
import { useOcrWarningsStore } from './ClaimWarningBanner/store';
import { findOptionValueByKey } from './hooks/findOption';
import { useClaimInfoGroups } from './hooks/useClaimInfoGroups';
import { useClaimInfoFieldsFilterStore } from './stores/useClaimInfoFieldsFilter.store';
import AddAddress from './AddAddress';
import { Patient } from './usePatients.query';
import { Provider } from './useProviders.query';
import { Service } from './useServices.query';

export enum ClaimInfoFields {
  account = 'account',
  amount = 'amount',
  invoice = 'invoice',
  patient = 'patient',
  provider = 'provider',
  fullAddress = 'fullAddress',
  service = 'service',
  serviceDate = 'serviceDate',
}

const DATE_IS_INCORRECT = 'Provided Date of Service is incorrect';

export const getMissingFieldsErrors = (warnings?: OcrWarnings, showErrors = true) => {
  if (!warnings || !showErrors) return {};
  const providerMissingError = warnings[OcrWarningType.MISSING]
    .find((warning) => warning.fieldName === ClaimInfoFields.provider)
    ? REQUIRED_TEXT : undefined;
  const serviceMissingError = warnings[OcrWarningType.MISSING]
    .find((warning) => warning.fieldName === ClaimInfoFields.service)
    ? REQUIRED_TEXT : undefined;
  const serviceDateMissingError = warnings[OcrWarningType.MISSING]
    .find((warning) => warning.fieldName === ClaimInfoFields.serviceDate)
    ? REQUIRED_TEXT : undefined;
  const amountMissingError = warnings[OcrWarningType.MISSING]
    .find((warning) => warning.fieldName === ClaimInfoFields.amount)
    ? REQUIRED_TEXT : undefined;
  const patientMissingError = warnings[OcrWarningType.MISSING]
    .find((warning) => warning.fieldName === ClaimInfoFields.patient)
    ? REQUIRED_TEXT : undefined;

  return {
    providerMissingError,
    serviceMissingError,
    serviceDateMissingError,
    amountMissingError,
    patientMissingError,
  };
};

const getProviderOptionValue = (patient: Patient) => {
  const relationship = capitalizeFirstLetter(patient?.relationshipType || '').replace(regexp.DASH_SYMBOL, ' ');

  return `${patient.fullName} ${relationship ? `(${relationship})` : ''}`.trim();
};

const isDateInFuture = (date?: string) => dayjs(date).valueOf() > dayjs().valueOf();

interface Props {
  providers: Provider[];
  services: Service[];
  patients: Patient[];
  addPatient: () => void;
  resetCreatedPatientId: () => void;
  resetProvider: (id?: string) => void;
  onCreateProvider: (value: Provider, onSuccess?: () => void) => void;
  scannedDocument?: ParsedFileByOcr;
  createdProviderId?: string;
  isBillPayMode?: boolean;
  addProvider?: () => void;
  initEditing?: {
    onEdit?: () => void,
    isHighlighted?: boolean,
    disabled?: boolean,
  };
  isCoveragePeriodActive: boolean,
  hasValidationAccountOrdersError: boolean,
  isServicesLoading: boolean,
}

const useClaimInfoFields = ({
  providers,
  services,
  patients,
  addPatient,
  resetCreatedPatientId,
  resetProvider,
  onCreateProvider,
  scannedDocument,
  createdProviderId,
  isBillPayMode,
  addProvider,
  initEditing,
  isCoveragePeriodActive,
  isServicesLoading,
  hasValidationAccountOrdersError,
}: Props) => {
  const {
    handleSetValues,
    handleSetProviderId,
    handleSetService,
    values: claimInfo,
  } = useClaimInfoStore();
  const { setFilterValues, setEditedFields, resetFilterValues } = useClaimInfoFieldsFilterStore();
  const fieldsWarnings = useOcrWarningsStore((state) => state.fieldsOcrWarnings);
  const warnings = useOcrWarningsStore((state) => state.ocrWarnings);
  const setOcrWarning = useOcrWarningsStore((state) => state.handleSetOcrWarningByType);
  const setFieldsOcrWarning = useOcrWarningsStore((state) => state.handleSetFieldsOcrWarningByType);
  const removeFieldsOcrWarning = useOcrWarningsStore(
    (state) => state.handleRemoveFieldsOcrWarningByType,
  );
  const [scannedServiceId, setScannedServiceId] = useState<string>();
  const { provider: providerId } = claimInfo;
  const { t } = useTranslation();
  const [cleaningInitiator, setCleaningInitiator] = useState(false);
  const selectedProvider = useMemo(
    () => providers.find((provider) => provider?.id?.toString() === providerId),
    [providerId, providers],
  );
  
  const hasServiceMissingField = useMemo(
    () => fieldsWarnings[OcrWarningType.MISSING]
      .find((field) => field.fieldName === ClaimInfoFields.service),
    [fieldsWarnings]
  );

  const isRetailOutOfCoverageError = useMemo(
    () => {
      if (!scannedDocument || !scannedDocument?.warnings) return false;
      return fieldsWarnings[OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE].length;
    }, [scannedDocument, fieldsWarnings],
  );
  const isOutOfCoverageDateError = useMemo(
    () => {
      if (!scannedDocument || !scannedDocument?.warnings) return false;
      return fieldsWarnings[OcrWarningType.OUT_OF_COVERAGE_DATE].length;
    }, [scannedDocument, fieldsWarnings],
  );

  const {
    providerMissingError,
    serviceMissingError,
    serviceDateMissingError,
    amountMissingError,
    patientMissingError,
  } = getMissingFieldsErrors(fieldsWarnings, !!scannedDocument);

  const dateFieldManualError = useMemo(() => {
    if (isRetailOutOfCoverageError || isOutOfCoverageDateError) {
      return t(DATE_INVALID_ALTERNATIVE_TEXT);
    }
    return serviceDateMissingError;
  }, [isOutOfCoverageDateError, isRetailOutOfCoverageError, serviceDateMissingError, t]);
  
  const isServicesExist = useMemo(() => services.length > 0, [services]);

  const foundScannedServiceId = useMemo(() => (services
    .find((service) => service.name === scannedDocument?.serviceDescription)?.id || '').toString(),
  [scannedDocument, services]);

  useEffect(() => {
    if (foundScannedServiceId) {
      setScannedServiceId(foundScannedServiceId);
    }
  }, [foundScannedServiceId]);

  const fullAddress = useMemo(() => getFullAddress(selectedProvider?.address), [selectedProvider]);
  const fullAddressFieldColor = useMemo(() => {
    if (!isBillPayMode) {
      return 'textTitle';
    }

    if (selectedProvider && !fullAddress) {
      return 'error';
    }

    if (fullAddress) {
      return 'textTitle';
    }

    return 'textSecondary';
  }, [fullAddress, selectedProvider, isBillPayMode]);
  const completeProviderId = useMemo(
    () => createdProviderId || selectedProvider?.id,
    [createdProviderId, selectedProvider],
  );
  const providerOptions = useMemo<Option[]>(
    () => providers
      .map((provider) => ({
        key: `${provider.id}`,
        value: provider.name || '',
        title: provider.name,
      })),
    [providers],
  );
  const patientsOptions = useMemo<Option[]>(
    () => patients.map((patient) => ({
      key: `${patient.id}`,
      value: patient.fullName,
      title: getProviderOptionValue(patient),
    })),
    [patients],
  );
  const servicesOptions = useMemo<Option[]>(
    () => services.map(
      (service) => ({
        key: service.id,
        value: service.name,
        title: service.name,
      }),
    ), [services],
  );

  const {
    providerGroups,
    patientGroups,
    amountGroups,
    serviceGroups,
    serviceDatesGroups,
  } = useClaimInfoGroups({
    providerOptions,
    servicesOptions,
    patientsOptions,
    scannedDocument,
    serviceDate: claimInfo.serviceDate,
  });
  const completeProviderName = useMemo(
    () => {
      if (!completeProviderId) return '';

      return providers
        .find((provider) => provider.id === completeProviderId)?.name || '';
    },
    [completeProviderId, providers],
  );
  const handleOnChangeProvider = useCallback((value?: string) => {
    const id = createdProviderId || value;

    if (!id) return;

    if (createdProviderId && selectedProvider?.id === createdProviderId) {
      resetProvider(id);
    }

    if (id !== providerId) {
      handleSetProviderId(id);
    }
  }, [
    createdProviderId,
    providerId,
    selectedProvider,
    handleSetProviderId,
    resetProvider,
  ]);
  const onSearch = useCallback((searchValue: string) => {
    if ((!searchValue && selectedProvider)
      || (selectedProvider && searchValue !== selectedProvider.name)) {
      handleSetProviderId();
    }
  }, [selectedProvider, handleSetProviderId]);

  const getServiceByDate = useCallback((serviceDate: string) => {
    const formattedServiceDate = dayjs(serviceDate).format(DEFAULT_DATE_FORMAT);

    const foundServiceGroup = scannedDocument?.serviceGroups
      ?.find((group) => dayjs(group?.serviceDate?.value)
        .format(DEFAULT_DATE_FORMAT) === formattedServiceDate);

    const foundService = foundServiceGroup?.services?.[0].value;

    const service = serviceGroups?.find((option) => option?.title && option.title === foundService);

    return service?.key;
  }, [scannedDocument, serviceGroups]);

  const resetScannedServiceId = useCallback(() => {
    setScannedServiceId('');
    handleSetService('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => setCleaningInitiator(!cleaningInitiator), [completeProviderName]);

  useEffect(() => {
    if (!services.length && !hasServiceMissingField && (isRetailOutOfCoverageError || isOutOfCoverageDateError)) {
      setOcrWarning(
        OcrWarningType.MISSING,
        {
          fieldName: ClaimInfoFields.service,
          fieldLabel: t('Service description'),
        },
      );
      setFieldsOcrWarning(
        OcrWarningType.MISSING,
        {
          fieldName: ClaimInfoFields.service,
          fieldLabel: t('Service description'),
        },
      );
    }

    if (services.length && (isRetailOutOfCoverageError || isOutOfCoverageDateError)) {
      removeFieldsOcrWarning(
        OcrWarningType.OUT_OF_COVERAGE_DATE,
        ClaimInfoFields.serviceDate,
      );
      removeFieldsOcrWarning(
        OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE,
        ClaimInfoFields.serviceDate,
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services.length]);

  useEffect(() => {
    OcrFieldsWarningErrorsTracker(
      claimInfo,
      warnings,
      setFieldsOcrWarning,
      removeFieldsOcrWarning,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimInfo]);

  useEffect(() => {
    return () => {
      resetFilterValues();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    ...isBillPayMode
      ? [{
        // cleaningInitiator,
        onSearch,
        name: ClaimInfoFields.provider,
        type: FieldTypes.AutocompleteDropdown,
        label: t('Provider/Merchant'),
        placeholder: t('Select Provider/Merchant'),
        notCompletelyFilledTextError: t('Please confirm creating Provider/Merchant'),
        validator: yup.string().required(REQUIRED_TEXT),
        manualError: providerMissingError,
        options: providerGroups,
        showRequireIcon: true,
        isSearchMode: true,
        completeValue: completeProviderId,
        resetCompleteValue: resetProvider,
        add: addProvider,
        initEditing,
        onChange: handleOnChangeProvider,
        ...selectedProvider && !fullAddress ? { className: 'error-border' } : {},
        optionsPlaceholder: t('There are no providers in the list. Please enter your own'),
        disableFiltering: true,
      }]
      : [{
        onSearch,
        name: ClaimInfoFields.provider,
        type: FieldTypes.AutocompleteDropdown,
        label: t('Provider/Merchant'),
        placeholder: t('Select Provider/Merchant'),
        notCompletelyFilledTextError: t('Please confirm creating Provider/Merchant'),
        validator: yup.string().required(REQUIRED_TEXT),
        manualError: providerMissingError,
        options: providerGroups,
        showRequireIcon: true,
        isSearchMode: true,
        completeValue: createdProviderId,
        defaultText: scannedDocument?.providerName,
        onClear: handleSetProviderId,
        resetCompleteValue: resetProvider,
        onCreate: (
          name: string, onSuccess?: () => void,
        ) => {
          onCreateProvider({ name } as Provider, onSuccess);
        },
        onChange: handleOnChangeProvider,
        optionsPlaceholder: t('There are no providers in the list. Please enter your own'),
        disableFiltering: true,
      }],
    ...isBillPayMode ? [
      {
        name: ClaimInfoFields.fullAddress,
        type: FieldTypes.Node,
        label: t('Address'),
        showRequireIcon: true,
        isThinMode: true,
        value: (
          <Inscription
            lineHeight="20px"
            color={fullAddressFieldColor}
          >
            {selectedProvider
              ? fullAddress || t('No address')
              : t('Select a Provider to display its address')}
          </Inscription>
        ),
      },
    ] : [],
    ...(isBillPayMode && selectedProvider && !fullAddress ? [
      {
        type: FieldTypes.Node,
        name: 'addAddress',
        value: <AddAddress onClick={initEditing?.onEdit} />,
      },
    ] : []),
    {
      name: ClaimInfoFields.patient,
      type: FieldTypes.AutocompleteDropdown,
      add: addPatient,
      label: t('Family member'),
      placeholder: t('Select family member'),
      options: patientGroups,
      validator: yup.string().required(REQUIRED_TEXT),
      manualError: patientMissingError,
      showRequireIcon: true,
      isSearchMode: true,
      completeValue: claimInfo.patient,
      resetCompleteValue: resetCreatedPatientId,
      onChange: (patient: string) => handleSetValues({ ...claimInfo, patient }),
      onSelect: (patient: string) => {
        setFilterValues({ patient: findOptionValueByKey(patientsOptions, patient)});
        if (patient || (claimInfo.patient && !patient)) {
          setEditedFields(FormField.patient);
        }
      },
      disableFiltering: true,
    },
    {
      name: ClaimInfoFields.serviceDate,
      type: FieldTypes.Date,
      label: t('Date of service'),
      isManualDateInput: true,
      inputWrapCssClass: 'small-input',
      placeholder: t('Date of service'),
      validator: yup.string().required(REQUIRED_TEXT).test('date', t(DATE_IS_INCORRECT), (date) => !isDateInFuture(date)),
      manualError: dateFieldManualError,
      showRequireIcon: true,
      isCalendar: true,
      pickerSide: DateFieldPickerSide.RIGHT,
      value: scannedDocument?.serviceDate && !isDateInFuture(scannedDocument?.serviceDate) ? dayjs(scannedDocument.serviceDate).format(DEFAULT_DATE_FORMAT) : '',
      ...!isCoveragePeriodActive ? { className: 'error-border' } : {},
      completeValue: claimInfo.serviceDate,
      onChange: (serviceDate: string) => {
        const service = getServiceByDate(serviceDate);
        handleSetValues({ ...claimInfo, serviceDate, ...service ? { service: `${service}` } : {} });
      },
      onSelect: (serviceDate: string) => {
        setFilterValues({ serviceDates: serviceDate });
        if (serviceDate || (claimInfo.serviceDate && !serviceDate)) {
          setEditedFields(FormField.serviceDate);
        }
      },
      maxDate: dayjs(),
      // TODO: change it to options
      highlightedDatesTitle: serviceDatesGroups?.length
        ? 'Choose one of the suggested options or select any from the calendar'
        : 'There are no suggested options. Please select the most appropriate from the calendar',
      highlightedDates: serviceDatesGroups,
    },
    // ...isRetailOutOfCoverageError ? [{
    //   name: 'errorRetailOutOfCoverageDate',
    //   type: FieldTypes.Node,
    //   value: <RetailOutOfCoverageDateErrorBanner
    //     serviceType={scannedDocument?.serviceDescription}
    //     lastDateToIncurClaim={scannedDocument?.serviceDate}
    //     planLastDateToIncurClaim={scannedDocument?.serviceDate}
    //   />,
    // }] : [],
    ...isOutOfCoverageDateError || isRetailOutOfCoverageError ? [{
      name: 'errorOutOfCoverageDate',
      type: FieldTypes.Node,
      value: <OutOfCoverageDateWarningBanner />,
    }] : [],
    {
      name: ClaimInfoFields.amount,
      type: FieldTypes.Currency,
      label: t('Amount'),
      placeholder: t('Amount'),
      inputWrapCssClass: 'small-input',
      validator: yup.string().required(REQUIRED_TEXT),
      manualError: amountMissingError,
      showRequireIcon: true,
      value: scannedDocument?.amount,
      completeValue: claimInfo.amount,
      onChange: (amount: string) => {
        handleSetValues({ ...claimInfo, amount });
        if (amount && claimInfo.amount && amount !== claimInfo.amount) {
          setEditedFields(FormField.amount);
        }
      },
      options: amountGroups,
    },
    {
      name: ClaimInfoFields.service,
      type: FieldTypes.AutocompleteDropdown,
      label: t('Service description'),
      placeholder: t('Select service'),
      validator: yup.string().required(REQUIRED_TEXT),
      manualError: serviceMissingError,
      maxOptionsCount: 1000,
      completeValue: isServicesExist ? claimInfo.service || scannedServiceId : undefined,
      options: serviceGroups,
      showRequireIcon: true,
      onChange: (service: string) => handleSetService(service),
      onSelect: (service: string) => {
        setFilterValues({ service: findOptionValueByKey(servicesOptions, service)});
        if (service || (claimInfo.service && !service)) {
          setEditedFields(FormField.service);
        }
      },
      isSearchMode: true,
      isLoading: isServicesLoading,
      resetCompleteValue: resetScannedServiceId,
      optionsPlaceholder: !claimInfo?.serviceDate ? t('Select the date of service first to view and choose relevant services') : undefined,
      ...hasValidationAccountOrdersError ? { className: 'error-border' } : {},
      disableFiltering: true,
    },
    {
      name: ClaimInfoFields.account,
      type: FieldTypes.Text,
      label: t('Account #'),
      placeholder: t('Enter account #'),
      validator: yup.string(),
      value: scannedDocument?.accountId,
    },
    {
      name: ClaimInfoFields.invoice,
      type: FieldTypes.Text,
      label: t('Invoice #'),
      placeholder: t('Enter invoice #'),
      validator: yup.string(),
      value: scannedDocument?.invoiceId,
    },
  ] as Field[];
};

export default useClaimInfoFields;
