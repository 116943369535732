import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import { useGetPaymentMethods } from '@/modules/transaction/components/ReimburseMe/queries/useGetPaymentMethods.query';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentType, ProcessingStatusType } from '@/modules/transaction/transaction.types';
import { useAuthStore } from '@/modules/user/stores';

import ProcessingNotification from './components/ProcessingNotification/ProcessingNotification';
import ProcessingScreen from './components/ProcessingScreen/ProcessingScreen';

interface Props {
  claimId?: string,
  paymentType?: PaymentType,
  processingStatus?: ProcessingStatusType,
  providerId?: string;
}

const TransactionProcessing: React.FC<Props> = ({
  paymentType,
  claimId,
  processingStatus,
  providerId,
}) => {
  const { user } = useAuthStore();
  const claimAutoPayType = useMemo(() => user?.employee?.claimAutopayType, [user]);
  const defaultClaimPayeeType = useMemo(() => user?.employee?.defaultClaimPayeeType, [user]);

  const displayCarrierProcessing = useMemo(() => processingStatus
    === ProcessingStatusType.ActionRequired, [processingStatus]);

  const { formattedData: allPaymentMethods } = useGetPaymentMethods();

  const providerPaymentMethod = allPaymentMethods
    .find(
      (paymentMethod: PaymentMethod) => paymentMethod.paymentMethodOwner?.providerId === providerId,
    );

  if (!claimId) return null;

  return (
    <>
      {
        displayCarrierProcessing ? (
          <Box margin={{ top: 'spacing24' }}>
            <ProcessingScreen
              claimId={claimId}
              paymentType={paymentType}
              claimAutoPayType={claimAutoPayType}
              providerPaymentMethod={providerPaymentMethod}
            />
          </Box>
        ) : (
          <ProcessingNotification
            claimId={claimId}
            paymentType={paymentType}
            defaultClaimPayeeType={defaultClaimPayeeType}
            claimAutoPayType={claimAutoPayType}
            providerPaymentMethod={providerPaymentMethod}
          />
        )
      }
    </>
  );
};

export default TransactionProcessing;
