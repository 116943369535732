import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resendSignUpCode } from '@aws-amplify/auth';
import { Anchor, Box, Heading, Image, RoundDoneIcon, Text } from '@common-fe/common-fe';

import verificationBanner from '@/assets/placeholders/verify/verificationBanner.svg';
import { EMAIL_QUERY_KEY, USERNAME_QUERY_KEY } from '@/modules/user/components/SignUp/signUp.constants';
import theme from '@/styles/theme';
import { useQueryParams } from '@/utils/hooks';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const BLOCK_TIMEOUT = 5000;
const Verification = () => {
  const [disableResend, setDisableResend] = useState(false);
  const query = useQueryParams();
  const email = useMemo(() => query.get(EMAIL_QUERY_KEY), [query]);
  const username = useMemo(() => query.get(USERNAME_QUERY_KEY), [query]);

  const { t } = useTranslation();
  const resendActivation = useCallback(() => {
    if (username && !disableResend) {
      resendSignUpCode({
        username,
      });
      setDisableResend(true);
      setTimeout(() => {
        setDisableResend(false);
      }, BLOCK_TIMEOUT);
    }
  }, [disableResend, username]);
  return (
    <Box
      direction="column"
      align="center"
      justify="end"
      background="background2"
      height={{
        min: '100vh',
      }}
    >
      <Box pad="spacing48" round="container1Round" align="center" direction="column">
        <Box direction="column" align="center" >
          <RoundDoneIcon color={theme.colors.navigationAccent2} />
          <Heading
            data-testid="verification-header"
            level={1}
            color="textOnColor"
            size="regular"
            margin={{ vertical: 'spacing12' }}
          >
            {t('Verification link sent!')}
          </Heading>
          <Box>
            <Text textAlign="center" size="large" color="textOnColor" style={{ lineHeight: 1.2 }}>
              {t(' We’ve sent a confirmation link to ')}
              <Text weight="bold">{email}.</Text>
            </Text>

            <Text textAlign="center" size="large" color="textOnColor" style={{ lineHeight: 1.2 }}>
              {t('Check your email for a link to finish registration.')}
            </Text>
          </Box>
          <Box align="center" margin={{ top: 'spacing12' }}>
            <Text size="large" color="textOnColor" style={{ lineHeight: 1.2 }}>
              {t('Didn\'t get a confirmation email? ')}
              <Anchor
                data-testid="send-again"
                color="navigationAccent2"
                disabled={disableResend}
                onClick={resendActivation}
              >
                {t('Resend')}
              </Anchor>
            </Text>
          </Box>
          <Box margin={{ top: 'spacing24' }}>
            <Text size="small" color="textOnColor" style={{ opacity: 0.4 }}>
              {t('If you received a confirmation email, you can close this page.')}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box fill direction="column" align="stretch" justify="between">
        <Box fill></Box>
        {/* <Image src={coloudsImage} /> */}
        <Box height="450px">
          <Image
            src={
              (whiteLabelingConfig?.images?.verificationBackground as string) ||
              (verificationBanner as string)
            }
            fill="vertical"
            fit="cover"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Verification;
