import {
  useMutation,
  useQueryClient,
} from 'react-query';
import { toNumber } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { GET_TRANSACTION_DETAILS_BY_CLAIM_ID } from '@/modules/transaction/hooks/useGetTransactionDetails.query';

interface Props {
  isAutopay: boolean;
  claimsIds?: string[];
}

const useProcessWaitingForFundsClaimQuery = (
  claimId?: string,
) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync, isLoading, isError, isSuccess,
  } = useMutation(
    (props: Props) => api.put(PATHS.PROCESS_WAITING_FOR_FUNDS_CLAIM(claimId), {
      is_autopay: props.isAutopay,
      ...props?.claimsIds?.length ? {
        claim_ids: props.claimsIds.map((id) => toNumber(id)),
      } : {},
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_TRANSACTION_DETAILS_BY_CLAIM_ID, claimId]);
      },
    },
  );

  return {
    processWaitingForFundsClaim: mutateAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useProcessWaitingForFundsClaimQuery;
