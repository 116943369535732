import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, SwitchInvestmentModelIcon, Text } from '@common-fe/common-fe';

import { InvestmentPaths } from '../../investments.types';


interface Props {
  modelType?: InvestmentPaths
}
const InvestmentSwitchingModelBanner: React.FC<Props> = () => {
  const { t }  = useTranslation();
  return (
    <Box direction='column'
      pad="l"
      background="module"
      round="moduleRound"
    >
      <Box direction='row'
        background="canvas"
        elevation="default"
        round="container1Round"
        pad={{vertical: 's', horizontal: 'l', right: 'xxxl'}}
        border={{size: 'small', color: 'border2'}}
      > 
        <Box direction='column' style={{flex: 1}} justify='center'>
          <Heading level={4} margin={{bottom: 'xs'}}>
            {t('Your Investment Settings are Updating')}
            
          </Heading>
          <Text size="medium">{t('Updating your investment model will take 3 - 4 business days to complete.')}
          </Text>
          <Text size="medium">{t('During that time you will not be able to make changes to your investment portfolio.')}
          </Text>
        
        </Box>
        <Box margin={{left: 'l'}}>
          <SwitchInvestmentModelIcon />
        </Box>
      </Box>
    </Box>
  );
};
export default InvestmentSwitchingModelBanner;
