import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useAuthStore } from '@/modules/user/stores';

interface AccountNumberDto {
  account_number: string;
  routing_transit_number: string;
}

export const QUERY_KEY = 'getAccountNumberQueryKey';
export const useGetAccountNumberQuery = (accountId: string, enable?: boolean) => {
  const { auth } = useAuthStore();
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY, enable, accountId, auth],
    enable && accountId && auth
      ? () => api.get<AccountNumberDto>(PATHS.ACCOUNT_NUMBER(accountId))
      : () => null,

  );
  const formattedData = useMemo(() => ({
    accountNumber: data?.data.account_number,
    routingNumber: data?.data.routing_transit_number,
  }), [data]);

  return { isLoading, formattedData, isSuccess };
};
