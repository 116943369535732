import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FlexControlledForm, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';
import * as yup from 'yup';

import AppButton from '@/components/controls/AppButton';
import { BankTypeEnum } from '@/modules/transaction/components/Cards/Cards.types';
import SaveAsDefaultCheckbox from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/SaveAsDefaultCheckbox';
import colors from '@/styles/colors';

import {
  DEBIT_CARD_TEST_ID_PREFIX,
  DebitCardFields,
  DebitCardPayload,
  useDebitCardFields,
} from './hooks/useDebitCardFields';
import { useAddPaymentDebitCardQuery } from './queries/useAddPaymentDebitCard.query';

const BUTTON_WIDTH = '150px';

const StyledBox = styled(Box)`
  ${({ theme }) => theme.border.blueGray2}
  ${({ theme }) => theme.shadows.default}
  .small-input {
    width: 100px;
    div[data-testid=_${DEBIT_CARD_TEST_ID_PREFIX}_${DebitCardFields.expirationDate}_mask-text-input_error-message] {
      white-space: nowrap;
    }
  }
`;

interface Props {
  onBack?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  editableMethodId?: string;
  allowSaveAsDefault?: boolean;
}

export const DebitCardForm: React.FC<Props> = ({
  onBack,
  onSuccess,
  editableMethodId,
  allowSaveAsDefault,
  onError,
}) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<DebitCardPayload>();
  const [isSaveAsDefault, setIsSaveAsDefault] = useState(false);
  const { fields, bankCardType } = useDebitCardFields(formValues);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { addDebitCard, updateDebitCard } = useAddPaymentDebitCardQuery(onSuccess);

  const activeValues = useMemo(() => {
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(formValues, { abortEarly: false });
      return true;
    } catch {
      return false;
    }
  }, [fields, formValues]);
  

  const handleChangeValues = useCallback((values: DebitCardPayload) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitted(true);
      const cardType = Object.keys(bankCardType).find((type) => bankCardType[type as BankTypeEnum]);
      if (activeValues && onSuccess && cardType && formValues) {
        setLoading(true);

        if (editableMethodId) {
          await updateDebitCard({ ...formValues, cardType }, editableMethodId);
        } else {
          await addDebitCard({ ...formValues, cardType }, isSaveAsDefault);
        }
        onSuccess();
      }
    } catch (e) {
      // @ts-ignore
      if (onError && e.response.status === 409) {
        onError();
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeValues, formValues, onError, onSuccess, addDebitCard, bankCardType, isSaveAsDefault]);

  return (
    <>
      <Box
        pad="spacing24"
        round="moduleRound"
        background={{ color: colors.module }}
      >
        <StyledBox
          round="container1Round"
          background={{ color: colors.canvas }}
        >
          <FlexControlledForm
            fields={fields}
            showError={submitted}
            editMode
            onChangeValues={handleChangeValues}
          />
        </StyledBox>
      </Box>
      <Box pad={{ top: 'medium' }} direction="row" align="center">
        {
          allowSaveAsDefault && (
            <SaveAsDefaultCheckbox
              isSaveAsDefault={isSaveAsDefault}
              setIsSaveAsDefault={setIsSaveAsDefault}
            />
          )
        }
        <Box direction="row" align="center" gap="spacing12" margin={{ left: 'auto' }}>
          <AppButton
            testId="DebitCard_form_back"
            type="button"
            buttonType="secondary"
            onClick={onBack}
            width={BUTTON_WIDTH}
          >
            {editableMethodId ? t('Cancel') : t('Back')}
          </AppButton>
          <AppButton
            testId="DebitCard_form_submit"
            type="button"
            width={BUTTON_WIDTH}
            onClick={handleSubmit}
          >
            {loading ? <Preloader color="white" /> : t('Submit')}
          </AppButton>
        </Box>
      </Box>
    </>
  );
};
