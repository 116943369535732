import React from 'react';
import {
  Box,
} from '@common-fe/common-fe';

import ModalWrapper from './ModalWrapper';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  children: React.ReactNode;
  id?: string;
}

const ProviderModalWrapper: React.FC<Props> = ({
  modalTitle,
  children,
  onSetVisible,
  visible,
  id,
}) => (
  <Box direction="row" align="center" id={id}>
    <ModalWrapper visible={visible} title={modalTitle} onSetVisible={onSetVisible}>
      {children}
    </ModalWrapper>
  </Box>
);

export default ProviderModalWrapper;
