import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box,  Checkbox, Hint, Text,WarningIcon } from '@common-fe/common-fe';



interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  isLiquidationMode?: boolean;
}

const LiquidationCheckbox: React.FC<Props> = ({ value, onChange, disabled, isLiquidationMode }) => {
  const { t } = useTranslation();
  return (
    <Box direction='row'>

      {isLiquidationMode && (
        <Hint
          hintAlign="left" 
          hintElement={(
            <Box margin={{right: 'xs'}}>
              <WarningIcon color='warning' />
            </Box>
          )}>
          <Text>{t('Your request exceeds 95% of the portfolio. All holdings will be sold.')}</Text>
        </Hint>
      )}
      <Checkbox checked={isLiquidationMode || value} onChange={onChange}  disabled={disabled || isLiquidationMode} />
      
    </Box>
  );
};

export default LiquidationCheckbox;