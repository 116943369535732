import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const borders = {
  largeRound: whiteLabelingConfig.rounds?.largeRound || '40px',
  moduleRound: whiteLabelingConfig.rounds?.moduleRound || '24px',
  container1Round: whiteLabelingConfig.rounds?.container1Round || '12px',
  container2Round: whiteLabelingConfig.rounds?.container2Round || '8px',
  container3Round: whiteLabelingConfig.rounds?.container3Round || '16px',
  checkboxRound: whiteLabelingConfig.rounds?.checkboxRound || '6px',
  fieldRound: whiteLabelingConfig.rounds?.fieldRound || '8px',
  button1Round: whiteLabelingConfig.rounds?.button1Round || '8px',
  button2Round: whiteLabelingConfig.rounds?.button2Round || '4px',
  dropdownItemRound: whiteLabelingConfig.rounds?.dropdownItemRound || '4px',
  snackbarRound: whiteLabelingConfig.rounds?.snackbarRound || '4px',
  smallRound: whiteLabelingConfig.rounds?.smallRound || '2px',
};

export default borders;
