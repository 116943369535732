import { useMutation } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { YES_KEY } from '@/common/constants';
import { getBooleanFromString } from '@/utils';

import {
  InvestmentPaths,
  ManagedInvestmentsQuestionnairePayload,
  SelfDirectedInvestmentsQuestionnairePayload,
} from '../investments.types';

interface Params {
  investmentType: InvestmentPaths;
  onSuccess?: () => void;
  onError?: () => void;
  setProposedToleranceModel?: (model: string) => void;
}

const useInvestmentsQuestionnaireQuery = ({
  onError,
  onSuccess,
  investmentType,
  setProposedToleranceModel,
}: Params) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (data: SelfDirectedInvestmentsQuestionnairePayload | ManagedInvestmentsQuestionnairePayload) => {
      let body: object = {};

      if (investmentType === InvestmentPaths.Managed) {
        const currentData = data as ManagedInvestmentsQuestionnairePayload;
        body = {
          investment_type: investmentType,
          employment_profile: {
            position: currentData.position,
            employment_type: currentData.employmentType,
            broker: currentData.broker ? getBooleanFromString(currentData.broker) : undefined,
            director_of: currentData.director === YES_KEY ? currentData.directorOf : undefined,
            politically_exposed_names:
              currentData.politicallyExposed === YES_KEY ? currentData.politicallyExposedNames : undefined,
          },
          investment_profile: {
            networth_total: _.toNumber(currentData.networthTotal),
            annual_income: _.toNumber(currentData.annualIncome),
            tolerance_model: currentData.toleranceModel,
            investment_objectives_type: currentData.investmentObjectivesType,
            investment_experience_type: currentData.investmentExperienceType,
            purpose_type: currentData.purposeType,
            risk_tolerance: currentData.riskToleranceType,
            medical_expenses_cover_type: currentData.medicalExpensesCoverType,
          },
        };
      } else if (investmentType === InvestmentPaths.SelfDirected) {
        const currentData = data as SelfDirectedInvestmentsQuestionnairePayload;
        body = {
          investment_type: investmentType,
          employment_profile: {
            position: currentData.position,
          },
          investment_profile: {
            networth_total: _.toNumber(currentData.networthTotal),
            annual_income: _.toNumber(currentData.annualIncome),
          },
        };
      }

      return api.patch(PATHS.INVESTMENTS_FLOW_QUESTIONNAIRE, body);
    },
    {
      onSuccess: (data) => {
        if (setProposedToleranceModel) {
          setProposedToleranceModel(data.data?.investor_profile?.proposed_tolerance_model);
        }

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        if (onError) onError();
      },
    }
  );
  return {
    handleSave: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useInvestmentsQuestionnaireQuery;
