import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { InvestmentHoldingStatus, InvestmentOrderType, InvestmentPending, InvestmentsType } from '../investments.types';

export const QUERY_KEY = 'GET_INVESTMENTS_PENDINGS';

interface PedningActivityDTO {
  side?: string;
  amount: number;
  date_time: string;
  fund_name: string;
  image_url: string;
  research_url: string;
  shares: number;
  symbol: string;
  order_type?:InvestmentOrderType;
  type: InvestmentsType;
  instrument_status?: InvestmentHoldingStatus;
  full_liquidation: boolean;
  status?: string;
}

export default (investorId?: string) => {

  const { data, isSuccess, isLoading } = useQuery(
    [QUERY_KEY, investorId],
    () =>
      api.get<PedningActivityDTO[]>(PATHS.INVESTMENTS_ACTIVITES_PENDING, {
        params: {
          investor_id: _.toNumber(investorId),
        },
      }),
    {
      retry: false,
      enabled: !!investorId,
    }
  );
  const formatedPendings = useMemo<InvestmentPending[]>(() => {
    const pendings = data?.data || [];

    return pendings?.map((item) => ({
      amount: item.amount,
      name: item.fund_name,
      image: item.image_url,
      researchURL: item.research_url,
      dateTime: item.date_time,
      shares: item.shares,
      symbol: item.symbol,
      type: item.type,
      status: item.instrument_status,
      orderType: item.order_type,
      side: item.side,
      isFullLiquidation: item.full_liquidation,
    }));
  }, [data]);
  const sotedFormatedPendings = useMemo(
    () => _.sortBy(formatedPendings, (item) => item.amount).reverse(),
    [formatedPendings]
  );
  return {
    data: sotedFormatedPendings,
    isSuccess,
    isLoading,
  };
};
