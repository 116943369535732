import { RefObject, useEffect } from 'react';

export enum HotKeys {
  Esc = 'Escape',
}

const useHotKeyPressIndicator = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void,
  key: string,
) => {
  useEffect(() => {
    function handleCallback(event: KeyboardEvent) {
      if (event.key === HotKeys.Esc) {
        callback();
      }
    }

    document.addEventListener('keydown', handleCallback);

    return () => {
      document.removeEventListener('keydown', handleCallback);
    };
  }, [ref, callback, key]);
};

export default useHotKeyPressIndicator;
