import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription, Preloader } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import useRegistrationVerificationQuery from '@/modules/user/components/SignUp/ProfileInformation/queries/useRegistrationVerification.query';
import { useProfileInformationFormStore } from '@/modules/user/components/SignUp/ProfileInformation/stores/useProfileInformationFormMode.store';
import { useStore as useProfileInformationStore } from '@/modules/user/components/SignUp/ProfileInformation/useProfileInformation.store';

const EditProfileInformationButton = () => {
  const { t } = useTranslation();
  const {
    values: { isFormEditMode, isFormError },
    handleSetIsFormEditMode,
    handleSetIsFormError,
  } = useProfileInformationFormStore();
  const profileInformation = useProfileInformationStore((state) => state.state);

  const { registrationVerification, isLoading } = useRegistrationVerificationQuery();

  const handleScroll = useCallback(() => {
    setTimeout(() => {
      const accountAccessBlock = document.getElementById('account_access_block_wrapper');
      accountAccessBlock?.scrollIntoView({ behavior: 'smooth' });
    }, 250);
  }, []);

  const handleVerification = useCallback(async () => {
    if (isFormEditMode) {
      try {
        const response = await registrationVerification({
          birthOn: profileInformation.birthdate,
          uniqueId: profileInformation.uniqueId,
        });
        if (!response.employee_id) {
          throw new Error();
        }
        handleSetIsFormError(false);
        handleSetIsFormEditMode(false);
        handleScroll();
      } catch (e) {
        handleSetIsFormError(true);
      }
    } else {
      handleSetIsFormEditMode(true);
    }
  }, [
    isFormEditMode,
    profileInformation,
    handleSetIsFormEditMode,
    handleSetIsFormError,
    registrationVerification,
    handleScroll,
  ]);

  return (
    <Box width="100%">
      {
        isFormError && (
          <Inscription color="danger" margin={{ bottom: 'spacing16' }}>
            {t('We are unable to locate your account based on the information provided. Contact your plan administrator for assistance.')}
          </Inscription>
        )
      }
      <AppButton size="L" width="100%" onClick={handleVerification}>
        {
          isLoading ? (
            <Preloader size={16} thinSize={4} color="white" />
          ) : (
            <Inscription>
              {isFormEditMode 
                ? t('Next') 
                : t('Edit Profile Information')}
            </Inscription>
          )
        }
      </AppButton>
    </Box>
  );
};

export default EditProfileInformationButton;
