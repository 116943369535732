import { useCallback, useState } from 'react';

interface Params {
  deepLink: string;
  fallbackLink: string;
}

const useOpenDeepLinkFallback = () => {
  const [isLoading, setLoading] = useState(false);
  const handleOpen = useCallback(({ deepLink, fallbackLink }: Params) => {
    const timeout = 2000; 
    setLoading(true);

    const timer = setTimeout(() => {
      if (document.visibilityState === 'visible') {
        // @ts-ignore
        window.location = fallbackLink;
      }
      setLoading(false);
    }, timeout);
    if(deepLink) {
      // @ts-ignore
      window.location = deepLink; 

      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          clearTimeout(timer); 
          setLoading(false);
        }
      });

      return;
    }
    // @ts-ignore
    window.location = fallbackLink;

   
  }, []);
  return {
    isLoading,
    handleOpen
  };
};

export default useOpenDeepLinkFallback;