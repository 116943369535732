import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isPopupVisible: boolean;
  setIsPopupVisible: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  isPopupVisible: false,

  setIsPopupVisible: (value: boolean) => set(() => ({
    isPopupVisible: value,
  })),
}));

const useSwitchPopupStore = () => ({ ...useStore() });

export default useSwitchPopupStore;
