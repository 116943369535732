import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, FlexForm,
  Inscription, Preloader, } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import SaveAsDefaultCheckbox from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/SaveAsDefaultCheckbox';
import useAddPaymentMethodQuery from '@/modules/transaction/components/ReimburseMe/queries/useAddPaymentMethod.query';
import {
  useUpdatePaymentMethodQuery,
} from '@/modules/transaction/components/ReimburseMe/queries/useUpdatePaymentMethod.query';
import { PaymentMethodOwnerType } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import theme from '@/styles/theme';

import useProviderFields, { BankInfo } from './useBankFields';
import { useGetBankAccountByIdQuery } from './useGetBankAccountsList.query';

interface Props {
  back?: () => void;
  onSuccess?: () => void;
  bankAccountId?: number | string;
  paymentMethodId?: number | string;
  onError?: () => void;
  onAddBankAccount?: (value: string) => void;
  backLabel?: string;
  isContribution?: boolean;
  allowSaveAsDefault?: boolean;
}

const BankForm: React.FC<Props> = ({
  back,
  onSuccess,
  bankAccountId,
  paymentMethodId,
  onError,
  backLabel,
  onAddBankAccount,
  isContribution,
  allowSaveAsDefault,
}) => {
  const { t } = useTranslation();
  const [isSaveAsDefault, setIsSaveAsDefault] = useState(false);
  const { save, isLoading } = useAddPaymentMethodQuery(onSuccess, onAddBankAccount, isContribution);
  const {
    updatePaymentMethod: updateDirectDeposit,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdatePaymentMethodQuery(onSuccess);

  const {
    currentBankAccount,
    isLoading: isBankAccountLoading,
  } = useGetBankAccountByIdQuery(bankAccountId);

  const formRef = useRef<HTMLFormElement>(null);
  const fields = useProviderFields(currentBankAccount);
  const submit = useCallback(async (bankData: BankInfo) => {
    try {
      await save({
        paymentType: PaymentMethodType.DIRECT_DEPOSIT,
        paymentOwnerType: PaymentMethodOwnerType.EMPLOYEE,
        bankData,
        isDefault: isSaveAsDefault,
      });
    } catch (e) {
      // @ts-ignore
      if (onError && e.response.status === 409) {
        onError();
      }
    }
  }, [save, onError, isSaveAsDefault]);

  const update = useCallback(async (bankData: BankInfo) => updateDirectDeposit({
    paymentType: PaymentMethodType.DIRECT_DEPOSIT,
    paymentMethodId: paymentMethodId || '',
    bankData,
  }), [updateDirectDeposit, paymentMethodId]);

  const submitButtonLabel = useMemo(() => (bankAccountId
    ? t('Submit')
    : t('Next')), [bankAccountId, t]);

  const handleSubmit = useCallback(() => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [formRef]);

  useEffect(() => {
    if (onSuccess && (isUpdateSuccess)) onSuccess();
  }, [isUpdateSuccess, onSuccess]);

  return (
    <Box>
      {bankAccountId && isBankAccountLoading
        ? <Preloader />
        : (
          <FlexForm
            ref={formRef}
            paddingless
            contentStyles={theme.formFieldsEdging}
            formTitle={(
              <Inscription
                margin={{ bottom: '22px' }}
                lineHeight="28px"
                color="textTitle"
                size="xxlarge"
              >
                {t('Banking information')}
              </Inscription>
            )}
            fields={fields}
            editMode
            onSubmit={bankAccountId ? update : submit}
          />
        )}
      <Box direction="row" margin={{ top: 'spacing24' }}>
        {
          allowSaveAsDefault && (
            <SaveAsDefaultCheckbox
              isSaveAsDefault={isSaveAsDefault}
              setIsSaveAsDefault={setIsSaveAsDefault}
            />
          )
        }
        <Box gap="spacing12" direction="row" justify="end" margin={{ left: 'auto'}}>
          {back && (
            <AppButton
              onClick={back}
              buttonType="secondary"
              width="130px"
            >
              {backLabel || bankAccountId ? t('Cancel') : t('Back')}
            </AppButton>
          )}
          <AppButton onClick={handleSubmit} width="130px">
            {(isLoading || isUpdateLoading)
              ? <Preloader color="white" />
              : submitButtonLabel}
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BankForm;
