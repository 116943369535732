import React, { useMemo } from 'react';
import {
  AchievementTrackerItem,
  Box,
  Inscription,
} from '@common-fe/common-fe';

const descriptionPartOne = `The money you contribute to your HSA is tax advantaged,
 so every dollar you add helps you save. It’s yours to use now or save for the future.`;

const descriptionPartTwo = `These limits include all sources of HSA contributions: employer deposits, payroll deposits, and any other contributions to your HSA.
 If filing jointly, contributions to a spouse’s HSA are also included.`;

interface Props {
  limits?: AchievementTrackerItem[];
  isWithCatchUp?: boolean;
  taxYear?: string;
}

export const ContributionInfoBanner: React.FC<Props> = ({ limits, isWithCatchUp, taxYear }) => {
  const individualLimit = useMemo(
    () => limits?.find((item) => item.name.includes('Individual'))?.maxValueDescription,
    [limits],
  );

  const familyLimit = useMemo(
    () => limits?.find((item) => item.name.includes('Family'))?.maxValueDescription,
    [limits],
  );

  const catchUpLimit = useMemo(
    () => limits?.find((item) => item.name.includes('Catch Up'))?.maxValueDescription,
    [limits],
  );

  const descriptionInscription = useMemo(
    () => `The IRS has limits on how much you can contribute. ${taxYear} limits are ${individualLimit} 
    for individual coverage and ${familyLimit} for family coverage.
     ${isWithCatchUp ? `If you’re age 55 or older, you can contribute an extra ${catchUpLimit} each year.` : ''}`,
    [catchUpLimit, familyLimit, individualLimit, isWithCatchUp, taxYear],
  );

  return (
    <Box
      pad="spacing24"
      border={{ color: 'border2' }}
      round="s"
      background={{ color: 'canvas' }}
      height="fit-content"
      width="100%"
      data-testid="ContributionInfoBanner_wrapper"
    >
      <Inscription
        size="20px"
        weight={700}
        lineHeight="28px"
        margin={{ bottom: 'spacing8' }}
      >
        Add more, save more. It’s all yours.
      </Inscription>

      <Inscription lineHeight="20px" margin={{ bottom: 'spacing18' }}>
        {descriptionPartOne}
      </Inscription>

      <Inscription lineHeight="20px" margin={{ bottom: 'spacing18' }}>
        {descriptionInscription}
      </Inscription>

      <Inscription lineHeight="20px">
        {descriptionPartTwo}
      </Inscription>
    </Box>
  );
};
