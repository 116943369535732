import REGEXP from '@/common/regexp';

export const phoneNumberCreator = (value: string) => {
  const correctNumber = value.replace(REGEXP.ALL_NON_NUMERIC, '');

  const phoneCode = correctNumber.slice(0, 3);
  const middleOfNumber = correctNumber.slice(3, 6);
  const endOfNumber = correctNumber.slice(6);
  return `+1 (${phoneCode}) ${middleOfNumber}-${endOfNumber}`;
};
