import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Box, FlexList,
  ListItemBase,
  Text, } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { FlexListWrapper } from '@/components/wrappers';

import TransactionItem from '../../TransactionItem';

import { ActivityRow } from './queries/useActivitiesList.query';

const EMPTY_STATUS = '';

const HEADERS: ListItemBase[] = [
  {
    key: 'provider',
    title: '',
    flex: 5.3,
  },
  {
    key: 'status',
    title: '',
    flex: 3,
  },
  {
    key: 'amount',
    title: 'Amount',
    flex: 2,
  },
  {
    key: 'statusDate',
    title: 'Date',
    flex: 0.7,
    endAlign: true,
  },
  {
    key: 'option',
    title: '',
    flex: 0.5,
  }];

interface Props {
  id: string;
  title?: string;
  data?: ActivityRow[];
}
export const ProcessingActivitiesList: React.FC<Props> = ({
  id, title, data,
}) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  return (
    <Box
      direction="column"
      data-testid="TransactionsList"
      background="module"
      round="container1Round"
      pad={{ bottom: 'spacing12' }}
    >
      <Box
        direction="row"
        data-testid="processing_activities_list_header"
        align="center"
        margin={{ bottom: 'spacing4' }}
        pad={{ horizontal: 'spacing24', top: 'spacing24' }}
      >
        <Box direction="row" style={{ width: '100%' }}>
          <Text size="large" color="blockTitle" weight="bold">{title || t('Processing')}</Text>
        </Box>
      </Box>
      <FlexListWrapper>
        <FlexList
          testId="processing_activities_list"
          headers={HEADERS}
          rows={[]}
          // loading={isLoading}
          footer={(
            <Box direction="column">
              {data?.map((item) => (
                <TransactionItem
                  key={item?.postingId || item?.claimId}
                  category={item.category}
                  type={item?.transactionType}
                  title={item.title}
                  amount={item.amount}
                  status={EMPTY_STATUS}
                  date={item.date}
                  onPick={() => push(
                    item?.claimId
                      ? ROUTES.TRANSACTION_DETAILS_CLAIM(id, item?.claimId)
                      : ROUTES.TRANSACTION_DETAILS_POSTING(id, item?.postingId),
                  )}
                />
              ))}
            </Box>
          )}
        />
      </FlexListWrapper>
    </Box>
  );
};
