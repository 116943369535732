import React, { useCallback, useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,   CalendarIcon,
  DateRangeDropdown,
  NotificationBellIcon,   NotificationDismissedIcon,
  Option,
  OptionKey,
  SearchInput,   SelectDropdown,
  Text,
  useDebounce,
} from '@common-fe/common-fe';

import { NotificationsSearchParams,NotificationType } from '../notification.types';

interface Props {
  searchParams: NotificationsSearchParams;
  onSetSearchParams: (value: NotificationsSearchParams) => void;
  currentType?: NotificationType;
  total?: number;
  isLoading?: boolean;
}

export const PRIOR_OPTIONS: Option[] = [{
  key: 'IMPORTANT',
  value: 'Important',
}, {
  key: 'NEED_HELP',
  value: 'Need help',
}, {
  key: 'INFO',
  value: 'Info',
}];
type DatesRange = [Date | null, Date | null];
const SEARCH_CHARS_COUNT = 3;
const NotificationsListHeader: React.FC<Props> = ({
  searchParams, onSetSearchParams, currentType, total = 0, isLoading,
}) => {
  const [currentDates, setCurrentDates] = useState<DatesRange>([null, null]);
  const [currentPrior, setCurrentPrior] = useState<OptionKey | undefined>(searchParams?.priority);
  const [currentSearchString, setCurrentSearchString] = useState(searchParams?.searchString || '');

  const debouncedSearch = useDebounce(currentSearchString.length >= SEARCH_CHARS_COUNT ? currentSearchString : '', 250);

  const handleSetCurrentDates = useCallback((values: DatesRange) => {
    if ((values[0] && values[1])
    || (values[0] === null && values[1] === null)) {
      setCurrentDates(values);
    }
  }, []);
  useEffect(() => {
    onSetSearchParams({
      fromDate: currentDates[0],
      toDate: currentDates[1],
      priority: currentPrior,
      searchString: debouncedSearch,
    });
  }, [currentDates, currentPrior, debouncedSearch, onSetSearchParams]);
  const { t } = useTranslation();
  return (
    <Box direction="row" justify="between" height={{ min: '40px' }} margin={{ bottom: 'm' }}>
      <Box direction="row" align="center">
        {currentType === NotificationType.Active && (
          <NotificationBellIcon color="iconAccent" size="20px" />
        )}
        {currentType === NotificationType.Dismissed && (
          <NotificationDismissedIcon color="iconAccent" size="20px" />
        )}

        <Box margin={{ left: 'xs' }}>
          <Text size="large" weight={700} color="textBody">
            {t('Notifications')}: {total}
          </Text>
        </Box>
      </Box>
      {currentType === NotificationType.Dismissed && (
        <Box direction="row">
          <Box margin={{ left: 'xs' }} width={{ min: '180px', max: '180px' }}>
            <SearchInput
              testId="NotificationsList"
              className="search-input"
              hasSearchingHistory
              value={currentSearchString}
              placeholder="Search"
              onChange={setCurrentSearchString}
            />
          </Box>
          <Box margin={{ left: 'xs' }} width={{ min: '180px', max: '180px' }}>
            <SelectDropdown
              disabled={isLoading}
              testId="NotificationsList_priority"
              id="category"
              allMode
              activeTitle
              singleMode
              removeMode
              prefix="Priority"
              name={t('Activity category')}
              options={PRIOR_OPTIONS}
              value={currentPrior}
              onChange={setCurrentPrior}
            />
          </Box>
          <Box margin={{ left: 'xs' }} width={{ min: '180px', max: '180px' }}>
            <DateRangeDropdown
              testId="NotificationsList"
              disabled={isLoading}
              prefix={t('Period:')}
              onChange={handleSetCurrentDates}
              startDate={currentDates[0]}
              ellipsisMode
              endDate={currentDates[1]}
              icon={(
                <CalendarIcon color="iconPrimary" />
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NotificationsListHeader;
