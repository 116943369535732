import { useMemo } from 'react';

import { useAuthStore } from '@/modules/user/stores';

import { useDependentsListQuery } from '../queries';

interface DeductibleUser {
  id: string;
  fullName: string;
  isMe?: boolean;
}

interface Params {
  userId?: string;
  isIndividual?: boolean;
}
export default ({ userId, isIndividual }: Params) => {
  const { user } = useAuthStore();
  const { data: dependents, isLoading } = useDependentsListQuery(user?.employee?.id || userId);

  const users = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const me = {
      id: `${user?.employee?.id}`,
      fullName: `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
      isMe: true,
    };
    const list = [me, ...dependents] as DeductibleUser[];
    if (!isIndividual) {
      return list;
    }
    const found = list.find((currentUser) => currentUser.id === userId);
    if (found) {
      return [found];
    }
    return [me];
  }, [user, dependents, isIndividual, userId, isLoading]);
  return users;
};
