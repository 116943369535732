import {
  useMemo,
} from 'react';
import { capitalizeFirstLetter,OptionKey } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import regexp from '@/common/regexp';
import {
  PaginationParams,
} from '@/common/types';
import { TransactionStatus, TransactionType } from '@/modules/transaction/transaction.types';

import useActivitiesListQuery from '../queries/useActivitiesList.query';

export const convertTransactionType = (type: string, isPosting?: boolean) => {
  if (type === TransactionType.PAY_CLAIM) {
    return 'Reimburse Me';
  }
  if (type === TransactionType.CARD_SETTLEMENT
    || type === TransactionType.PAYMENT_CARD_RETURN) {
    return 'Card swipe';
  }
  if (type === TransactionType.NOTIONAL_FUND) {
    return 'Account Funding';
  }
  if (type === TransactionType.CONTRIBUTION_PAYMENT) {
    return 'Contributions';
  }
  if (type === TransactionType.PAYROLL || type === TransactionType.NOTIONAL_PAYROLL) {
    return isPosting ? 'Contribution' : 'Payroll';
  }
  if (type === TransactionType.INTEREST) {
    return 'Interest';
  }
  if (type === TransactionType.FEE) {
    return 'Fee';
  }
  if (type === TransactionType.PAYMENT) {
    return 'Payment';
  }
  if (type === TransactionType.INTERNAL_TRANSFER_OF_ASSETS
    || type === TransactionType.TRANSFER_OF_ASSETS) {
    return 'Transfer of Assets';
  }
  if (type === TransactionType.TRANSFER_ADJUSMENT) {
    return 'Transfer Adjustment';
  }
  if (type === TransactionType.INVESTMENT
      || type === TransactionType.BUY_INVESTMENT
      || type === TransactionType.SELL_INVESTMENT) {
    return 'Investment';
  }
  if (type === TransactionType.ROLLOVER) {
    return 'Rollover';
  }
  if (type === TransactionType.PAYROLL_REVERSE) {
    return 'Contribution reverse';
  }
  return capitalizeFirstLetter(type).replace(regexp.DASH_SYMBOL, ' ');
};

interface CurrentPaginationParams extends PaginationParams{
  id: string;
  employeeId?: string;
  categories?: OptionKey[];
  statuses?: OptionKey[];
  types?: OptionKey[];
  startDate?: Date | null;
  endDate?: Date | null;
}

const useActivitiesList = ({
  page,
  perPage,
  searchString,
  id,
  employeeId,
  types,
  startDate,
  endDate,
}: CurrentPaginationParams, isWithHolds?: boolean) => {
  const { isLoading, data, refetch } = useActivitiesListQuery({
    id, employeeId,
  });

  const dataWithoutHolds = useMemo(
    () => (isWithHolds ? data : data?.filter((item) => !item.hasActiveHold)),
    [data, isWithHolds],
  );

  const dataWithoutDeclinedTrx = useMemo(
    () => dataWithoutHolds?.filter((item) => item.status !== TransactionStatus.Declined),
    [dataWithoutHolds],
  );

  const dataOnlyHolds = useMemo(() => data?.filter((item) => item.hasActiveHold), [data]);

  const convertData = useMemo(() => dataWithoutDeclinedTrx?.map((item) => ({
    ...item,
    type: convertTransactionType(item?.transactionType || '', !item.claimId),
  })), [dataWithoutDeclinedTrx]);

  const availableTypes = useMemo(
    () => _.uniq(convertData?.map((item) => item.type))
      .map((item) => ({
        key: item,
        value: item,
        title: item,
      })),
    [convertData],
  );

  const filteredTypes = useMemo(() => convertData.filter((item) => {
    if (!_.isEmpty(types)) {
      return types?.includes(item.type);
    }
    return item;
  }), [convertData, types]);

  const filteredDate = useMemo(() => filteredTypes.filter((item) => {
    if (startDate) {
      const currentDate = dayjs(item.date, DEFAULT_DATE_FORMAT);
      const start = dayjs(startDate, DEFAULT_DATE_FORMAT);
      if (endDate) {
        return currentDate <= dayjs(endDate, DEFAULT_DATE_FORMAT)
        && currentDate >= start;
      }
      return currentDate >= start;
    }
    return item;
  }), [endDate, filteredTypes, startDate]);

  const filteredSearch = useMemo(() => [
    ...filteredDate.filter((item) => item.status === TransactionStatus.Pending),
    ...filteredDate.filter((item) => item.status !== TransactionStatus.Pending),
  ].filter(
    (item) => item.title.toLowerCase().trim().includes(searchString.toLowerCase().trim()),
  ), [filteredDate, searchString]);

  const currentPageData = useMemo(() => {
    const offset = (page) * perPage;
    return filteredSearch.slice(offset, offset + perPage);
  }, [filteredSearch, page, perPage]);

  const total = useMemo<number>(() => _.get(filteredSearch, 'length', 0) || 0, [filteredSearch]);
  return ({
    data: currentPageData || [],
    dataOnlyHolds,
    availableTypes,
    isLoading,
    total,
    refetch,
  });
};

export default useActivitiesList;
