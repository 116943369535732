import { useCallback, useEffect, useState } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { API_KEY, BEARER,GATEWAY_KEY } from '@/api/api';
import { PATHS } from '@/common';
import { DocumentPayload } from '@/modules/transaction/components/ReimburseMe/types/document.types';
import { CONFIG } from '@/utils';
import Token from '@/utils/handlers/Token';

const getUrl = (qrId?: string) => `${CONFIG.BASE_URL?.replace(GATEWAY_KEY, API_KEY)}${PATHS.GET_DOCUMENT_BY_QR_CODE}?qr_id=${qrId}`;

interface Props {
  qrCodeId?: string;
}

const useGetDocumentByQRCodeQuery = ({ qrCodeId }: Props) => {
  const [document, setDocument] = useState<DocumentPayload>();

  const handleSubscribe = useCallback(() => {
    const listener = new EventSourcePolyfill(getUrl(qrCodeId), {
      headers: {
        Authorization: `${BEARER} ${Token.getToken()}`,
      },
    });

    listener.onmessage = (event) => {
      if (!event?.data) return;

      const parsedDocument = JSON.parse(event.data) as DocumentPayload;

      if (parsedDocument.files?.length) {
        setDocument(parsedDocument);
      }
    };

    const dispose = () => {
      listener.close();
    };

    return dispose;
  }, [qrCodeId]);

  useEffect(() => {
    if (qrCodeId) {
      // open connection
      const dispose = handleSubscribe();

      // close connection after unmount
      return () => {
        dispose();
      };
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCodeId]);

  return {
    document,
  };
};

export default useGetDocumentByQRCodeQuery;
