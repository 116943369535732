import React, { useCallback } from 'react';
import { Box } from '@common-fe/common-fe';

import PaymentTypeIcon from '@/components/elements/PaymentTypeIcon';
import PaymentMethodDescription from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/PaymentMethodLabel/PaymentMethodDescription';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import PaymentTypeIconWrapper from '@/modules/user/components/ManageProfile/components/ReimbursementOptions/components/PaymentMethodsList/PaymentTypeIconWrapper';

interface Props {
  paymentMethod: PaymentMethod;
  onClick: (paymentMethodId: string) => void;
}

const PaymentMethodListItem: React.FC<Props> = ({ paymentMethod, onClick }) => {
  const handleOnClick = useCallback(() => {
    if (paymentMethod?.id) {
      onClick(paymentMethod?.id);
    }
  }, [paymentMethod, onClick]);

  return (
    <Box
      data-testId={`${paymentMethod?.id}_paymentMethod-list-item`}
      margin={{ top: 'spacing8' }}
      round="container2Round"
      pad="spacing8"
      height={{ min: 'auto' }}
      border={paymentMethod.isDefault
        ? { size: 'large', color: 'accentActive' } 
        : { size: 'small', color: 'border1' }}
      {...paymentMethod.isDefault ? {} : {
        onClick: handleOnClick
      }}
    >
      <Box direction="row" align="center" height={{ min: '44px' }}>
        <PaymentTypeIconWrapper>
          <PaymentTypeIcon paymentType={paymentMethod?.type} />
        </PaymentTypeIconWrapper>
        <Box margin={{ left: 'spacing12' }} flex>
          <PaymentMethodDescription
            paymentMethod={paymentMethod}
            titleFontSize="medium"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethodListItem;
