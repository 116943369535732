import { useMemo } from 'react';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RAW_DATE_FORMAT } from '@/common/constants';

interface BasePersonDtoPayload {
  id: string;
  preferred_name: string;
  first_name: string;
  last_name: string;
}
export interface DependentInfoDtoPayload {
  id?: string;
  first_name: string;
  last_name: string;
  local_date_birth_on: string;
  person: BasePersonDtoPayload;
  relationship_type: string;
}

interface BasePersonDto {
  id: string;
  preferredName: string;
  firstName: string;
  lastName: string;
}
export interface DependentInfoDto {
  id?: string;
  firstName: string;
  lastName: string;
  birthOn: string;
  person: BasePersonDto;
  relationshipType: string;
}

const preparedDependentInfo = (data: DependentInfoDto): DependentInfoDtoPayload => ({
  id: data.id,
  first_name: data.firstName,
  last_name: data.lastName,
  local_date_birth_on: dayjs(data.birthOn).format(RAW_DATE_FORMAT),
  person: {
    id: data.person.id,
    preferred_name: data.person.preferredName,
    first_name: data.person.firstName,
    last_name: data.person.lastName,
  },
  relationship_type: data.relationshipType,
});

const CREATE_DEPENDENT_KEY = 'update_card_holder';

export const useAddCardDependentMutation = (onSuccess?: (dependent: DependentInfoDto) => void) => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(CREATE_DEPENDENT_KEY, 'true');

    return queryGenerator;
  }, []);

  const { mutateAsync, isLoading, isError } = useMutation(
    (data: DependentInfoDto) => api.post(`${PATHS.DEPENDENTS}?${query}`, preparedDependentInfo(data)),
    {
      onSuccess: (_, value) => {
        if (onSuccess) onSuccess(value);
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
    isError,
  };
};

export const useUpdateCardDependentMutation = () => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(CREATE_DEPENDENT_KEY, 'true');

    return queryGenerator;
  }, []);

  const { mutateAsync, isLoading, isError } = useMutation(
    (data: DependentInfoDto) => api.put(`${PATHS.DEPENDENTS}/${data.id}?${query}`, preparedDependentInfo(data)),
  );

  return {
    mutateAsync,
    isLoading,
    isError,
  };
};
