import { Button } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledButton = styled(Button)<{ isActive: boolean }>`
  height: 40px;
  color: ${({ theme }) => theme.colors.textAccent};
  font-weight: 500;
  margin-right: 0 !important;

  ${({ isActive, theme }) => (isActive
    ? `
      border: 1px solid ${theme.colors.border2};
      ${theme.shadows.default};
      background: ${theme.colors.canvas};
      font-weight: bold;
      color: ${theme.colors.iconPrimary};
    `
    : null)}
  &:disabled {
    background-color: transparent !important;
    border: none;
    font-weight: normal;
  }
`;

export const BackIcon = styled.button`
  position: absolute;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -52px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.iconOnColor};
  font-size: 19px;
  transition: .1s;
  i:before {
    transition: .1s;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.iconPrimary};
  }
  &:active,
  &:hover {
    i:before {
      color: ${({ theme }) => theme.colors.iconOnColor};
    }
  }
  &:hover {
    background: ${({ theme }) => theme.colors.iconAccentHover};
  }
  &:active {
    background: ${({ theme }) => theme.colors.textAccentActive};
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.border1};
    i:before {
      color: ${({ theme }) => theme.colors.iconSecondary};
    }
  }
`;
