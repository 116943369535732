import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, } from '@common-fe/common-fe';
import _ from 'lodash';

import ROUTES from '@/common/routes';
import { StepperSidebar } from '@/components/elements';
import { Topbar } from '@/modules/core/components';
import ServerErrorModal from '@/modules/investments/modal/ServerErrorModal';
import useMyEnrollments from '@/modules/transaction/hooks/useMyEnrollments';
import theme from '@/styles/theme';

import ConfirmBuyModal from '../BuySell/ConfirmBuyModal';
import FundingAmountSell from '../BuySell/FundingAmount/FundingAmountSell';
import { useBuySellResetData } from '../BuySell/hooks';
import { useFundingAmountStore } from '../BuySell/stores/useFundingAmount.store';
import { useTradeInvestmentsQuery } from '../queries';

interface Props {
  accountId: string;
}
const SellManagedInvestments: React.FC<Props> = ({ accountId }) => {

  const { t } = useTranslation();
  const history = useHistory();
  const handleReset = useBuySellResetData();
  const [confirmBuyVisible, setConfirmBuyVisible] = useState(false);
  const [showServerErrorModal, setShowServerErrorModal] = useState(false);


  const { currentMyAccount } = useMyEnrollments(accountId);

  const { values } = useFundingAmountStore();

  const OPTIONS = useMemo(() => [{ key: 1, value: t('Sell Investments') }], [t]);

  const { handleSave, isLoading } = useTradeInvestmentsQuery({});
  const handleBuyInvestments = useCallback(async () => {
    try {
      
      await handleSave({
        amount: _.toNumber(values?.fundingAmount) || 0,
      });
      setShowServerErrorModal(false);
      setConfirmBuyVisible(false);
    } catch {
      setConfirmBuyVisible(false);
      setShowServerErrorModal(true);
    }
  }, [handleSave, values]);

  const handleGoDashboard = useCallback(() => {
    handleReset();
    history.push(`${ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id)}`);
  }, [handleReset, history, currentMyAccount]);

  useEffect(() => () => handleReset(), [handleReset]);
  
  return (
    <Box
      direction="row"
      height={{ min: '100vh' }}
      data-testid="BuySell-wrapper"
      style={{ overflow: 'hidden' }}
    >
      <StepperSidebar title={t('Sell Investments')} options={OPTIONS} step={1} />
      <Box
        width="100%"
        pad={{ horizontal: '40px' }}
        align="center"
        style={{
          position: 'relative',
        }}
      >
        <Box width={theme.defaultContentWidth} height={{ min: '100vh' }}>
          <Topbar />
          <FundingAmountSell
            onCancel={handleGoDashboard}
            accountId={accountId}
            currentMyAccount={currentMyAccount}
          />
        </Box>
      </Box>
      <ConfirmBuyModal
        visible={confirmBuyVisible}
        fundingAmount={values.fundingAmount}
        onSetVisible={setConfirmBuyVisible}
        onSubmit={handleBuyInvestments}
        isLoading={isLoading}
      />
      
      <ServerErrorModal
        visible={showServerErrorModal}
        isLoading={isLoading}
        onSetVisible={setShowServerErrorModal}
        onSendRequest={handleBuyInvestments}
      />
    </Box>
  );
};

export default React.memo(SellManagedInvestments);
