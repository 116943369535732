import { SelectDropdown } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Dropdown = styled(SelectDropdown)`
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .expenses-breakdown {
    background-color: ${({ theme }) => theme.colors.module};
    padding: ${({ theme }) => theme.spacings.spacing24};
    border-radius: ${({ theme }) => theme.rounds.moduleRound};
  }
  // padding: 0 40px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacings.spacing24};
`;

export const Header = styled.h3`
  flex: 1;
  ${({ theme }) => theme.fonts.text32Bold};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  ${Dropdown} {
    min-width: ${({ theme }) => theme.controls.mediumSize};
    max-width: ${({ theme }) => theme.controls.mediumSize};
    margin-left: ${({ theme }) => theme.spacings.spacing8};
  
  }
`;
