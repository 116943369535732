import React from 'react';
import {
  Box, Modal,
  Text, } from '@common-fe/common-fe';

import { Portal } from '@/components';

interface Props {
  visible: boolean;
  setOpenModal: (value: boolean) => void;
  title?: string;
  children: React.ReactNode;
}

const NotificationModalWrapper: React.FC<Props> = ({
  children,
  setOpenModal,
  visible,
}) => (
  <Portal id="modal">
    <Modal visible={visible} onSetVisible={setOpenModal}>
      <Box direction="column">
        <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
          <Text size="2xl" weight="bold">Notification details</Text>
        </Box>
        <Box overflow="hidden">
          <Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Modal>
  </Portal>
);

export default NotificationModalWrapper;
