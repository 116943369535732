import React, {} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, ErrorModal,
  SuccessModal, Text, } from '@common-fe/common-fe';

interface Props {
  children: React.ReactNode;
  isSuccessModal?: boolean;
  onCloseModal: () => void;
  isStandAloneMode?: boolean;
  isErrorModal?: boolean;
}
const QuestionaireWrapper: React.FC<Props> = ({
  children, isSuccessModal, onCloseModal, isErrorModal, isStandAloneMode,
}) => {
  const { t } = useTranslation();

  return (
    <Box data-testid="questionnaire-wrapper">

      <SuccessModal
        testId="questionnaire-wrapper_success"
        visible={!!isSuccessModal}
        onSetVisible={onCloseModal}
        header={t('Your HSA is now active!')}
        buttonText={t('Got It!')}
        onSubmit={onCloseModal}
      />
      <ErrorModal
        testId="questionnaire-wrapper_error"
        visible={!!isErrorModal}
        onSetVisible={onCloseModal}
        header={t('We\'re Sorry, we\'re unable to open your HSA')}
        helpText={t(`We cannot verify your details with the information provided.
        Please contact your administrator for next steps.`)}
        buttonText={t('Got It!')}
        onSubmit={onCloseModal}
      />
      {!isStandAloneMode && (
        <>
          <Text size="3xl" weight={700} style={{ lineHeight: '44px' }}>
            {t('We need a little more information')}
          </Text>
          <Text size="large" margin={{ top: 's' }} style={{ lineHeight: '22px' }}>
            {t('In order to establish your HSA, we are required to verify your identity.')}
          </Text>
        </>
      )}

      {children}

    </Box>
  );
};
export default QuestionaireWrapper;
