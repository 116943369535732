import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

export interface RelationshipType {
  id: string,
  name: string,
}

export const useRelationshipsTypesQuery = () => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    PATHS.RELATIONSHIPS_TYPES,
    () => api.get<Array<RelationshipType>>(PATHS.RELATIONSHIPS_TYPES),
  );

  const formattedRelationshipsTypes = useMemo(() => (
    data?.data.map((relationshipsTypes: RelationshipType) => ({
      id: relationshipsTypes.id,
      name: relationshipsTypes.name,
    }))),
  [data]);

  return {
    relationshipsTypes: data?.data || [],
    formattedRelationshipsTypes: formattedRelationshipsTypes || [],
    isError,
    isLoading,
    isSuccess,
  };
};
