import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import routes from '@/common/routes';
import { useShowLockBanner } from '@/modules/core/hooks';
import useEmployerTerminationBanner from '@/modules/core/hooks/useEmployerTerminationBanner';
import { useOrganizationProperties } from '@/modules/transaction/hooks';

import { useHelpMenuItemList,useMenuItemList } from '../hooks';
import Logo from '../Logo';

import BackgroundImage from './BackgroundImage';
import { Content,Wrapper } from './Sidebar.styles';
import SidebarOption from './SidebarOption';

interface Props {
  minified?: boolean;
}
const Sidebar: React.FC<Props> = ({ minified }) => {
  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation();
  const { formattedList } = useMenuItemList();
  const isLockBannerShowed = useShowLockBanner();
  const { bannerHeight } = useEmployerTerminationBanner();
  const { organizationProperties } = useOrganizationProperties();
  const { formattedList: helpList } = useHelpMenuItemList({ organizationProperties });
  const history = useHistory();

  const handleSetTab = useCallback((url?: string) => {
    if (url && pathname !== url) history.push(url);
  }, [history, pathname]);
  const handleClickLogo = useCallback(() => {
    history.push(routes.HOME);
  }, [history]);

  return (
    <Wrapper minify={!!minified}>
      <Content
        data-testid="SideBar-wrapper"
        isLockBannerMode={isLockBannerShowed}
        customTopValue={bannerHeight}
        minify={!!minified && !expanded}
        onMouseEnter={() => minified && setExpanded(true)}
        onMouseLeave={() => minified && setExpanded(false)}
      >
        <Logo minify={!!minified && !expanded} onClick={handleClickLogo} />
        {formattedList.map((item) => (
          <SidebarOption
            path={pathname}
            minify={!!minified && !expanded}
            key={item.key}
            currentKey={item.key}
            title={item.title}
            icon={item.icon}
            options={item.options}
            delimiter={item.delimiter}
            url={item.url}
            onPick={handleSetTab}
            link={item.link}
            disabled={item.disabled}
            hintComponent={item.hintComponent}
          />
        ))}
        {
          !!helpList.length && (
            <Box margin={{ top: 'auto', bottom: 'spacing20' }}>
              {helpList.map((item) => (
                <SidebarOption
                  path={pathname}
                  minify={!!minified && !expanded}
                  key={item.key}
                  currentKey={item.key}
                  title={item.title}
                  icon={item.icon}
                  url={item.url}
                  onPick={handleSetTab}
                  link={item.link}
                  activeBackgroundOnHover={item.activeBackgroundOnHover}
                  additionalIcon={item.additionalIcon}
                  textStyle={item?.textStyle}
                />
              ))}
            </Box>
          )
        }
        {
          (!minified || expanded) && (
            <BackgroundImage />
          )
        }

      </Content>

    </Wrapper>
  );
};

export default Sidebar;
