import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, DebitCardIcon, NavigationLeftIcon, Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import { BackIcon } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DirectDeposit/StyledComponents.styles';
import { DuplicationErrorModal } from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/DublicationErrorModal/DuplicationErrorModal';
import colors from '@/styles/colors';
import spacings from '@/styles/spacings';

import { DebitCardForm } from './DebitCardForm';

const DESCRIPTION = 'For faster access to your money, we will deposit your reimbursement on to your personal debit card.';

interface Props {
  onClose: () => void;
  onBack: () => void;
  onSuccess: () => void;
  hideNavigationButton?: boolean;
  allowSaveAsDefault?: boolean;
}

export const DebitCardModal: React.FC<Props> = ({ onClose, onBack, onSuccess, hideNavigationButton, allowSaveAsDefault }) => {
  const { t } = useTranslation();
  const [serverErrorShow, setServerErrorShow] = useState(false);

  return (
    <ModalWrapper
      visible
      contentStyle={{
        maxWidth: '718px',
      }}
      onSetVisible={onClose}
      testId="AddDebitCardModal_testId"
    >
      {onBack && !hideNavigationButton && (
        <BackIcon onClick={onBack} data-testid="back-icon-button">
        
          <NavigationLeftIcon color="iconPrimary"
            size="18px"
          />
        </BackIcon>
      )}

      <Box align="center">
        <Box width="xxsmall" alignSelf="center" data-testid="DebitCard_icon">
          <DebitCardIcon size={spacings.xl} color={colors.iconAccent} />
        </Box>

        <Text
          size="2xl"
          weight="bold"
          color="textBody"
          margin={{ top: spacings.spacing20 }}
        >
          {t('My personal debit card')}
        </Text>

        <Text
          textAlign="center"
          color="textBody"
          margin={{ top: 'small', bottom: 'medium' }}
        >
          {t(DESCRIPTION)}
        </Text>
      </Box>

      <DebitCardForm
        onBack={onBack}
        onSuccess={onSuccess}
        onError={() => setServerErrorShow(true)}
        allowSaveAsDefault={allowSaveAsDefault}
      />
      <DuplicationErrorModal
        visible={serverErrorShow}
        close={() => setServerErrorShow(false)}
      />
    </ModalWrapper>
  );
};
