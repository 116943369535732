import React, { useCallback,useMemo, useState } from 'react';
import _ from 'lodash';

import { Roles } from '@/common/constants';
import VerifyMyCardModal from '@/modules/core/components/Dashboard/components/BannersList/CompleteBanner/components/VerifyMyCardModal';
import { GoPaperlessBanner } from '@/modules/core/components/Dashboard/components/BannersList/GoPaperlessBanner';
import { useHasAccess } from '@/modules/core/hooks';
import { useOrphanModeStore } from '@/modules/core/store';
import { AutoInvestmentBanner, useAutoInvestmentBanner } from '@/modules/investments/AutoInvestment';
import { GoPaperless } from '@/modules/transaction/components/Statements/components/GoPaperless';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { useAuthStore } from '@/modules/user/stores';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';
import { StatementType } from '@/modules/user/user.types';

import { BannerStorageKey } from '../bannersList.constants';
import CompleteBanner from '../CompleteBanner';
import { ImportantChangesBanner } from '../ImportantChangesBanner';
import SwitchEmployeeBanner from '../SwitchEmployeeBanner/SwitchEmployeeBanner';

import useBannersOrderMemo from './useBannersOrderMemo';
import useDismissBanner from './useDismissBanner';

export interface SlideItem {
  node: React.ReactNode;
  id: number;
}

const useBannersList = (): SlideItem[] => {
  const { user } = useAuthStore();
  const { linkedEmployee } = useOrphanModeStore();
  const { employee } = useEmployeeStore();
  const { organizationProperties } = useOrganizationProperties();
  const [isVerifyMyCardModalVisible, setIsVerifyMyCardModalVisible] = useState(false);
  const [isGoPaperlessModalVisible, setIsGoPaperlessModalVisible] = useState(false);

  const handleOpenVerifyMyCardModal = useCallback(() => setIsVerifyMyCardModalVisible(true), []);
  const handleOpenGoPaperlessModal = useCallback(() => setIsGoPaperlessModalVisible(true), []);

  const hasSwitchEmployeeBanner = !!user?.linkedEmployees;

  const showCompleteBanner = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);

  const isSsoUser = useMemo(
    () => user?.isSsoUser,
    [user],
  );

  const { checkIsBannerDismissed } = useDismissBanner();

  const isImportantChangesBannerDismissed = useMemo(
    () => checkIsBannerDismissed(BannerStorageKey.ImportantChangesBanner),
    [checkIsBannerDismissed],
  );

  const isGoPaperlessBannerDismissed = useMemo(
    () => checkIsBannerDismissed(BannerStorageKey.GoPaperlessBanner),
    [checkIsBannerDismissed],
  );

  const showImportantChangesBanner = useMemo(
    () => !linkedEmployee
      && user?.linkedEmployees
      && !isImportantChangesBannerDismissed,
    [user, linkedEmployee, isImportantChangesBannerDismissed],
  );

  const isPaperAndElectronicsStatementType = useMemo(
    () => organizationProperties.statementType === StatementType.PAPER_AND_ELECTRONIC,
    [organizationProperties],
  );

  const showGoPaperlessBanner = useMemo(
    () => !isGoPaperlessBannerDismissed
    && isPaperAndElectronicsStatementType
    && employee && !employee?.isElectronicStatementOnly,
    [
      isGoPaperlessBannerDismissed,
      isPaperAndElectronicsStatementType,
      employee,
    ],
  );

  const { isDisplayedBanner: isAutoInvestementBannerDisplayed } = useAutoInvestmentBanner();

  const bannersList = useMemo(
    () => [
      ...showImportantChangesBanner && !isSsoUser ? [{
        id: 1,
        node: <ImportantChangesBanner />,
      }] : [],
      ...showCompleteBanner && !isSsoUser ? [{
        id: 2,
        node: <CompleteBanner handleOpenModal={handleOpenVerifyMyCardModal} />,
        modalWindow: isVerifyMyCardModalVisible
          && <VerifyMyCardModal onSetVisible={setIsVerifyMyCardModalVisible} />,
      }] : [],


      ...isAutoInvestementBannerDisplayed ? [{
        id: 3,
        node: <AutoInvestmentBanner />,
        modalWindow: false,
      }] : [],

      ...showGoPaperlessBanner && !isSsoUser ? [{
        id: 4,
        node: <GoPaperlessBanner handleOpenModal={handleOpenGoPaperlessModal} />,
        modalWindow: isGoPaperlessModalVisible
        && <GoPaperless onSetVisible={setIsGoPaperlessModalVisible} />,
      }] : [],
      ...isSsoUser && hasSwitchEmployeeBanner ? [{
        id: 5,
        node: <SwitchEmployeeBanner />,
      }] : [],
    ],
    [showCompleteBanner, isSsoUser, handleOpenVerifyMyCardModal,
      isVerifyMyCardModalVisible, showImportantChangesBanner,
      isAutoInvestementBannerDisplayed, showGoPaperlessBanner,
      handleOpenGoPaperlessModal, isGoPaperlessModalVisible, hasSwitchEmployeeBanner],
  );

  const bannersOrderIds = useMemo(() => {
    return bannersList.map((item) => item.id);
  }, [bannersList]);

  const orderIds = useBannersOrderMemo(employee?.id, bannersOrderIds);
  const sortedBanners = useMemo(() => {
    if(orderIds) {
      return _.sortBy(bannersList, (item) => orderIds.indexOf(item.id));
    }
    return bannersList;
  }, [bannersList, orderIds]);
  return sortedBanners;
};

export default useBannersList;