import React, { useMemo } from 'react';
import {
  Box, Image, MastercardIcon,
  Stack, Text, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import VisaIcon from '@/assets/bank-icons/visa.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import { BankTypeEnum, CardDto, CardStatusEnum } from '../Cards.types';
import useCardReplaceModalStore from '../store/useCardReplaceModal.store';

import CardPlaceholderBackgorund from './CardPlaceholderBackground';
import { CardStatusLabel } from './CardStatusLabel';

const Wrapper = styled(Box)`
  position: relative;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.03);
`;

const Container = styled(Box)`
  text-align: left;
`;

const StyledImage = styled(Image)`
    width: auto !important; 
`;

const CARD_EXP_FORMAT = 'MM/YY';

interface Props {
  card: CardDto;
  logoUrl?: string;
  borderColor?: string;
  active?: boolean;
  isLabel?: boolean;
  fullyMasked?: boolean;
}

const SHADOW_STYLE = {
  boxShadow: '18px 8px 25px rgba(0, 38, 102, 0.2)',
};

export const CardItem: React.FC<Props> = ({
  card, active, isLabel, fullyMasked, logoUrl,
}) => {
  const { state: { cardID } } = useCardReplaceModalStore();

  const cardShadow = useMemo(() => (card.id === cardID || active ? SHADOW_STYLE : undefined),
    [card.id, cardID, active]);

  const cardLogo = useMemo(() => logoUrl || whiteLabelingConfig?.images?.cardLogo
  || whiteLabelingConfig?.logo , [logoUrl]);
  
  return (
    <Container>
      <Stack
       
        anchor="bottom-right"
        style={card.status !== CardStatusEnum.CLOSED ? { marginBottom: 'spacing4' } : undefined}
      >
        <Wrapper
          background="canvas"
          pad="spacing24"
          round="container1Round"
          border={{ size: 'small', color: 'border2' }}
          style={cardShadow}
          data-testid="Card-item-wrapper"
        >

          <Box direction="row" justify="between" align="center" margin={{ bottom: '36px' }}>
            {cardLogo ? (
              <StyledImage
                className="image"
                src={cardLogo}
                width={128}
                alt="logo"
                height={33}
              />
            ) :
              (<Box />)}
          
          
            <CardStatusLabel card={card} />
          </Box>
          <Box margin={{ bottom: 'spacing12' }}>
            <Text size="xxlarge" weight="bold">{card?.holder?.name}</Text>
          </Box>
          <Box
            direction="row"
            align="center"
            margin={{ bottom: isLabel ? 'spacing48' : 'spacing32' }}
          >
            <Text margin={{ right: 'spacing12' }} size="xlarge" weight="bold">••••</Text>
            <Text margin={{ right: 'spacing12' }} size="xlarge" weight="bold">••••</Text>
            <Text margin={{ right: 'spacing12' }} size="xlarge" weight="bold">••••</Text>
            {
              fullyMasked ? (
                <Text margin={{ right: 'spacing12' }} size="xlarge" weight="bold">••••</Text>
              ) : (
                <Text size="xlarge" weight="bold">{card.last4Number}</Text>
              )
            }
          </Box>
          <Box direction="row" justify="between" align="center" style={{ zIndex: 1 }}>
            {
              card.bankType === BankTypeEnum.VISA ? (
                <StyledImage
                  className="bank-logo"
                  src={VisaIcon}
                  width="30"
                  height="20"
                />
              ) : (
                <MastercardIcon width="30" height="20" />
              )
            }
            <Text>{card.expDate ? dayjs(card.expDate).format(CARD_EXP_FORMAT) : '-'}</Text>
          </Box>
        </Wrapper>
        <Box width="100%" style={{ zIndex: -1 }}>
          <CardPlaceholderBackgorund />
        </Box>
      </Stack>
    </Container>
  );
};
