import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { useInvestor } from '@/modules/investments/hooks';
import { SelfDirectedInvestMore } from '@/modules/investments/SelfDirected';


const SelfDirectedInvestMorePage = () =>{ 
  const { t } = useTranslation();
  const { accountId = '' } = useParams<{ accountId: string }>();
 
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  },
  {
    key: ROUTES.MY_ACCOUNTS_DETAILS(accountId),
    title: '...',
    value: `${ROUTES.MY_ACCOUNTS_DETAILS(accountId)}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Investments'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }, {
    key: `${ROUTES.INVESTMENTS}?id=${accountId}`,
    title: t('Invest More'),
    value: `${ROUTES.INVESTMENTS}?id=${accountId}`,
  }], true);
  
  const { data: {  investorId } } = useInvestor(accountId);
  if (!accountId || !investorId) {
    return null;
  }
  return (
    
    <SelfDirectedInvestMore accountId={accountId} investorId={`${investorId || ''}`} />
    
  );
};

export default SelfDirectedInvestMorePage;