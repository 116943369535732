import React, { useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';

export enum CancelationHsaRules {
  BULK_TRANSFER = 'BULK_TRANSFER',
  EMPLOYEE_OWNED = 'EMPLOYEE_OWNED',
}

const viewModeNodes = {
  [CancelationHsaRules.BULK_TRANSFER]: (
    <Box>
      <Box width={{ max: '282px' }}>
        <Inscription
          color="textBody"
          weight={700}
          style={{ display: 'block', whiteSpace: 'normal'}}
        >
          Bulk Transfer
        </Inscription>
      </Box>
      <Box>
        <Inscription color="textBody">
          Bulk transfer of HSA assets will be coordinated with the new custodian within 30 days of the termination date.
        </Inscription>
      </Box>
    </Box>
  ),
  [CancelationHsaRules.EMPLOYEE_OWNED]: (
    <Box>
      <Box width={{ max: '282px' }}>
        <Inscription
          color="textBody"
          weight={700}
          style={{ display: 'block', whiteSpace: 'normal'}}
        >
          Employee Owned
        </Inscription>
      </Box>
      <Box>
        <Inscription color="textBody">
          Employee is responsible for individual transfer of any HSA assets
        </Inscription>
      </Box>
    </Box>
  ),
};

const options = [{
  value: CancelationHsaRules.BULK_TRANSFER,
  label: (
    <Box margin={{ left: 'spacing4' }} width={{ max: '282px' }}>
      <Inscription
        color="textBody"
        weight={700}
        style={{ display: 'block', whiteSpace: 'normal'}}
      >
        Bulk Transfer
      </Inscription>
    </Box>
  
  ),
  hintText: (
    <Box margin={{ left: '36px' }}>
      <Inscription color="textBody">
        Bulk transfer of HSA assets will be coordinated with the new custodian within 30 days of the termination date.
      </Inscription>
    </Box>
  ),
}, {
  value: CancelationHsaRules.EMPLOYEE_OWNED,
  label: (
    <Box margin={{ left: 'spacing4' }} width={{ max: '282px' }}>
      <Inscription
        color="textBody"
        weight={700}
        style={{ display: 'block', whiteSpace: 'normal'}}
      >
        Employee Owned
      </Inscription>
    </Box>
  
  ),
  hintText: (
    <Box margin={{ left: '36px' }}>
      <Inscription color="textBody">
        Employee is responsible for individual transfer of any HSA assets
      </Inscription>
    </Box>
  ),
}];

export const useEmployerTerminationHsaTransferFields = (defaultHsaTransferOfAssets?: boolean, viewMode?: boolean) => {
  const defaultValue = useMemo(() => {
    if (typeof defaultHsaTransferOfAssets === 'boolean') {
      return defaultHsaTransferOfAssets ? CancelationHsaRules.BULK_TRANSFER : CancelationHsaRules.EMPLOYEE_OWNED;
    }
    return undefined;
  }, [defaultHsaTransferOfAssets]);
  const fields = useMemo(() => [
    {
      name: 'hsaTransferOfAssets',
      type: viewMode ? FieldTypes.Node : FieldTypes.Radio,
      options,
      label: viewMode ? (
        <Inscription weight={400} color="textBody">
          HSA Transfer of Assets
        </Inscription>
      ) :
        'HSA Transfer of Assets',
      expandItems: true,
      showRequireIcon: !viewMode,
      defaultValue,
      value: viewMode ? viewModeNodes[defaultValue as CancelationHsaRules] : defaultValue,
      style: { marginBottom: '-4px' },
      validator: yup.string().required(REQUIRED_TEXT),
    },  
  ], [defaultValue, viewMode]);

  return fields as Field[];
};
