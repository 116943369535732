import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription, PlusIcon } from '@common-fe/common-fe';
import { toNumber } from 'lodash';

import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { useManageProfileModalsStore } from '@/modules/user/components/ManageProfile/stores/useManageProfileModals.store';

import EmployeePaymentMethodsList from './EmployeePaymentMethodsList';

interface Props {
  refetchPaymentMethods: () => void;
}

const EmployeePaymentMethods: React.FC<Props> = ({ refetchPaymentMethods }) => {
  const { t } = useTranslation();
  const paymentMethods = useStore((state) => state.methods);
  const { handleSetIsAddPaymentMethodModalVisible } = useManageProfileModalsStore();

  const handleOpenAddPaymentMethodModal = useCallback(() => {
    handleSetIsAddPaymentMethodModalVisible(true);
  }, [handleSetIsAddPaymentMethodModalVisible]);

  const sortedPaymentMethods = useMemo(() => {
    if (!paymentMethods?.length) {
      return [];
    }

    return [...paymentMethods].sort((prev, next) => {
      if (prev.isDefault && !next.isDefault) {
        return -1;
      } else if (!prev.isDefault && next.isDefault) {
        return 1;
      } else {
        return toNumber(prev.id) - toNumber(next.id);
      }
    });
  }, [paymentMethods]);

  if (!sortedPaymentMethods?.length) {
    return null;
  }

  return (
    <Box
      pad="spacing16"
      background="canvas"
      round="container1Round"
      height={{ max: '269px' }}
      width={{ max: '275px', width: '100%' }}
    >
      <Inscription color="textSecondary" weight={500} size="small">
        {t('How Should We Reimburse You?')}
      </Inscription>

      <Box margin={{ top: 'spacing4' }}>
        <EmployeePaymentMethodsList
          paymentMethodsList={sortedPaymentMethods}
          refetchPaymentMethods={refetchPaymentMethods}
        />
      </Box>

      <Box margin={{ top: 'spacing12' }} height={{ min: 'auto' }}>
        <Box
          direction="row"
          align="center"
          onClick={handleOpenAddPaymentMethodModal}
        >
          <PlusIcon size="16px" color="iconAccent" style={{ padding: '4px' }} />
          <Inscription margin={{ left: 'spacing8' }} color="textAccent">
            {t('Add Payment Method')}
          </Inscription>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeePaymentMethods;
