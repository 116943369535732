import styled from 'styled-components';

const ContentWrapper = styled.div<{ paddingless?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.module};
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
  padding: ${({ theme, paddingless }) => (paddingless ? 0 : theme.spacings.spacing24)};
`;

export default ContentWrapper;
