import { useMemo } from 'react';

import { useStore as useInvestmentProfileStore } from '../InvestmentProfile/stores/useInvestmentProfile.store';
import { useStore as usePersonalInformationStore } from '../PersonalInformation/stores/usePersonalInformation.store';

export default () => {
  const personalInformationStore = usePersonalInformationStore((state) => state.state);
  const investmentProfileStore = useInvestmentProfileStore((state) => state.state);

  const state = useMemo(() => ({
    ...personalInformationStore,
    ...investmentProfileStore,
  }), [
    personalInformationStore,
    investmentProfileStore,
  ]);
  return state;
};
