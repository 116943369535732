import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Preloader } from '@common-fe/common-fe';
import _ from 'lodash';

import AppButton from '@/components/controls/AppButton';
import { PendingModal } from '@/components/elements';
import costFormated from '@/utils/modifiers/costFormater';

const BUTTON_WIDTH = '130px';

interface Props {
  visible: boolean;
  fundingAmount: string;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  isLoading?: boolean;
}
const ConfirmSellModal: React.FC<Props> = ({
  visible,
  fundingAmount,
  onSetVisible,
  onSubmit,
  isLoading
}) => {
  const { t } = useTranslation();
  const handleSubmit = useCallback(() => {
    if(isLoading) {
      return;
    }
    onSubmit();
    onSetVisible(false);
  }, [isLoading, onSetVisible, onSubmit]);

  return (
    <PendingModal
      header={t(`Submit a trade settlement for ${costFormated(_.toNumber(fundingAmount), true)}?`)}
      helptext={t('Trade settlement may take up to 2 days.')}
      visible={visible}
      onSetVisible={onSetVisible}
    >
      <Box direction="row">
        <Box margin={{ right: 'spacing12' }}>
          <AppButton
            testId="confirm-sell-modal_cancel"
            buttonType="secondary"
            onClick={() => onSetVisible(false)}
            width={BUTTON_WIDTH}
          >
            {t('Cancel')}
          </AppButton>
        </Box>
        <AppButton
          testId="confirm-sell-modal_confirm"
          onClick={handleSubmit}
          width={BUTTON_WIDTH}
        >
          {isLoading ? <Preloader /> :t('Confirm')}
        </AppButton>
      </Box>
    </PendingModal>
  );
};

export default ConfirmSellModal;
