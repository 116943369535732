import { useEffect } from 'react';

import { useDismissedNotificationsQuery } from '@/modules/notification/queries';

import { useStore } from '../store/useNotificationCenter.store';

const useDismissedNotifications = () => {
  const {
    data, isLoading, total,
  } = useDismissedNotificationsQuery({
    searchString: '',
    page: 1,
    perPage: 20,
  });
  const setTotalDismissedNotifications = useStore((state) => state.seDismissedNotificationstTotal);
  useEffect(() => {
    setTotalDismissedNotifications(total || 0);
  }, [setTotalDismissedNotifications, total]);
  return {
    data,
    isLoading,

  };
};

export default useDismissedNotifications;
