import { WhiteLabelTheme } from '../types';

import CardBackground from './assets/cardBackground.svg';
import BannerImage from './assets/dashboardBanner.svg';
import InvestmentsBannerRight from './assets/investmentsBannerRight.svg';
import LoginBackground from './assets/loginBackground.svg';
import LogoIcon from './assets/logo.svg';
import manageProfileBanner from './assets/manageProfileBanner.svg';
import SmallLogoIcon from './assets/minify-logo.svg';
import QrCodeLogo from './assets/qrCodeLogo.svg';
import ReimburseMeSidebar from './assets/reimburseMeSidebar.svg';
import VerificationBackground from './assets/verificationBackground.svg';

const buttonColors = {
  button: '#12284B',
  buttonHover: '#344663',
  buttonActive: '#213452',

  buttonSecondary: '#637775',
  buttonSecondaryContainer: '#D0DCDB',
  buttonSecondaryActive: '#91A4A2',

};
const navigationColors = {
  navigationBackground:  '#F7F8F9',
  navigationBorder:  '#E8E8E8',
  navigationAccent:  '#12284B',
  navigationAccent2:  '#E0CC00',
  navigationTextDefault:  '#0F1621',
  navigationTextAccent:  '#E0CC00',
  navigationIconDefault:  '#0F1621',
  navigationIconAccent:  '#E0CC00',
  navigationAccentHover:  '#D0DCDB',
  navigationAccentActive:  '#12284B',
};
const backgroundColors = {
  background1: '#12284B',
  background2: '#12284B',
  background3: '#0F1621',

  border: '#B8BFCC',
  border1: '#E5E9F0',
  border2: '#EEF1F4',
  module: '#F7F8F9',
  canvas: '#FFFFFF',
  
  stressContainer: '#12284B',
  accentActive: '#12284B',
  accentBorder: '#2C4670',
  accentContainer: '#BED3F4',


  popupBg2: '#0F16210D',
  legend:'#9BA3B3',
  
};

const baseColors = {
  ...buttonColors,
  ...navigationColors,
  ...backgroundColors,

  
  textTitle: '#12284B',
  textBody: '#12284B',
  textActive: '#E0CC00',
  textActiveHover: '#BBAC10',
  buttonText: '#E0CC00',


  textSecondary: '#63656A',
  textDisabled: '#9BA3B3',
  textOnColor: '#FFFFFF',
  textAccent: '#12284B',
  textAccentHover: '#1A72A8',
  textAccentActive: '#0B466A',

  iconPrimary: '#12284B',
  iconActive: '#E0CC00',
  iconActiveHover: '#BBAC10',

  iconSecondary: '#63656A',
  iconOnColor: '#FFFFFF',
  iconAccent: '#637775',
  iconAccentHover: '#586B69',
  iconAccentActive: '#91A4A2',

  success: '#5DAC37',
  successBorder: '#A0D288',
  successContainer: '#D3EDC6',
  warning: '#E8B842',
  warningBorder: '#ffe6a6',
  warningContainer: '#fdf4df',

  danger: '#E23131',
  dangerBorder: '#f6c4c4',
  dangerContainer: '#fff3f3',
  dangerHover: '#A61C18',
  dangerActive: '#66120A',
  info: '#425CE2',
  infoBorder: '#b7c3ff',
  infoContainer: '#f3f5fc',

  transactionDental: '#176AF6',
  transactionVision: '#481A54',
  transactionMedical: '#00C3B3',
  transactionOther: '#FF9D0A',
  transactionParking: '#323D9B',
  transactionTransit: '#CE8258',
  transactionAdoption: '#0F7970',
  transactionDependentCare: '#CC3381',
  transactionPharmacy: '#D83B00',
  transactionLifestyle: '#D3375C',
  transactionWellness: '#D3375C',
  transactionPremium: '#3628D1',
  transactionTravel: '#F26B31',
  refund: '#588C0E',
  transactionDefault: '#5E697E',
  claimTransferInternal: '#E5E9F0',
  nonClaimIcon: '#151F6D',
  nonClaimBg: '#E5E9F0',

  planTypeHSA: '#2EA12B',
  planTypeHCFSA: '#FA802A',
  planTypeDCAP: '#CC3381',
  planTypeHRA: '#134E8F',
  planTypeCommuterTransit: '#CE8258',
  planTypeCommuterParking: '#323D9B',
  planTypeAdoption: '#0F7970',
  planTypeLifestyle: '#D3375C',
  planTypeWellness: '#D3375C',
  planTypeSpecialty: '#00B5EE',
  planTypeTravel: '#F26B31',
  planTypeRx: '#7909AD',
  planTypeDental: '#176AF6',
  planTypeVision: '#481A54',
  planTypeMedical: '#00C3B3',
  
  lockedNull: '#000000',
  processingPending: '#F9C134',
  needsHelp: '#FF9D0A',
  paidActiveComplete: '#19B269',
  partiallyPaid: '#B9CC29',
  waitingForFunds: '#8EB2A1',
  deniedErrorExpired: '#E72626',
  uploading: '#9BA3B3',
  uploadedApproved: '#176AF6',
  loa: '#00B5EE',
  retirement: '#CE8258',
  future: '#7909AD',
  investmentsfuture: '#7909AD',
  investmentsFund1: '#00C3B3',
  investmentsFund2: '#481A54',
  investmentsFund3: '#D83B00',
  investmentsFund4: '#176AF6',
  investmentsFund5: '#FF9D0A',
  investmentsFund6: '#078F84',
  investmentsFund7: '#323D9B',
  investmentsFund8: '#F3C626',
  investmentsFund9: '#A528C6',
  investmentsFund10: '#0C95D0',
  investmentsFund11: '#619C00',
  investmentsFund12: '#CC3381',

  // new
  partner: '#D83B00',
  distributor: '#904D27',
  employer: '#174FBC',
  subsidiary: '#0F7970',
  subgroup: '#481A54',
  claimProcessor: '#176AF6',
  employee: '#0CAD09',
  dependent: '#5E1696',
  noAssignee: '#B8BFCC',

  claimParticipant: '#CC3381',
  claimSystem: '#18A0FB',
  claimCardProcessor: '#19B269',
 
};
export default {
  title: 'Apprize',
  colors: {
    ...baseColors,

    loginTitleColor: baseColors.textBody,
    popup: 'rgba(0, 0, 0, 0.5)',
    popup2: 'rgba(15, 22, 33, 0.05)',
    fade1: 'linear-gradient(360deg, #FAFAFA 0%, rgba(255, 255, 255, 0) 100%)',
    fade2: 'linear-gradient(180deg, #FAFAFA 0%, #F4F4F4 100%)',
  },
  logo: LogoIcon,
  minifyLogo: SmallLogoIcon,
  font: '\'GothamRounded\'', // Also you need add font import to global.scss example: @import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
  titleFont: '\'GothamRounded\'',
  images: {
    banner: BannerImage,
    investmentsBannerRight: InvestmentsBannerRight,
    cardBackground: CardBackground,
    reimburseMeSidebar: ReimburseMeSidebar,
    loginBackground: LoginBackground,
    verificationBackground: VerificationBackground,
    qrCodeLogo: QrCodeLogo,
    manageProfileBannerBackground: manageProfileBanner,
  },
  fonts: {
    sidebarWeight: 700,
    reimbursementWeight: 500,
    sizes: {
      small: {
        size: '12px',
        height: '16px',
        maxWidth: '336px',
      },
      '3xl': {
        size: '30px',
        height: '40px',
        maxWidth: '1008px',
      },
      '2xl': {
        size: '24px',
        height: '30px',
        maxWidth: '816px',
      },
    },
  },
  rounds: {
    largeRound: '0px',
    moduleRound: '0px',
    container1Round: '0px',
    container2Round: '0px',
    checkboxRound: '0px',
    fieldRound: '0px',
    button1Round: '0px',
    button2Round: '0px',
    dropdownItemRound: '0px',
    snackbarRound: '0px',
    smallRound: '0px',
  },
  shadows: {
    default: '0px 1px 7px rgba(0, 0, 0, 0.04);',
    hover: '0px 2px 13px rgba(0, 0, 0, 0.10);',
    active: '0px 8px 25px rgba(0, 0, 0, 0.25);',
    highlighted: '0px 6px 16px rgba(18, 40, 75, 0.45);',
  },
  favicon: '/apprize/favicon.ico',
  favicon16: '/apprize/favicon-16x16.png',
  favicon32: '/apprize/favicon-32x32.png',
  faviconAppleTouch: '/apprize/apple-touch-icon-fidelity.png',
} as WhiteLabelTheme;
