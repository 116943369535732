import { Box,PrimaryButton } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Primary = styled(PrimaryButton)`
  text-transform: uppercase;
`;

export const BannerWrapper = styled(Box)`
  position: relative;
  overflow: hidden ;
`;

export const Wrapper = styled.div`

  display: flex;
  justify-content: space-between;
  height: 258px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
  background-color: ${({ theme }) => theme.colors.module};
  position: relative;
  align-items: center;
  .image {
    z-index: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
  }
`;

export const WelcomeMessage = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 280px;
  padding: ${({ theme }) => `70px ${theme.spacings.spacing24}  0px`};

  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    max-width: 450px;
  }
  @media ${({ theme }) => theme.deviceSize.moreThen.laptopM} {
    padding: ${({ theme }) => `70px ${theme.spacings.spacing48} 0px`};
  }
`;

export const BackgroundWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ConfettiWrapper = styled(Box)`

  overflow: hidden ;
  position: absolute ;
  bottom: 0;
  left: 0 ;
  right: 0;
  
`;
