import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Text,
  WarningIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

import { useStore } from '../store/useApplyContributionRolloverModal.store';

const headerText = 'Warning';
const description = `I can rollover funds from Archer MSAs and other HSAs into an HSA within 60 days of receipt. One rollover contribution to an HSA/MSA is allowed per year.
  Rollover isn't counted in my income, isn't deductible, and doesn't impact my contribution limit.`;

export const ApplyContributionRolloverModal = () => {
  const { t } = useTranslation();
  const visible = useStore((store) => store.modalVisible);
  const onSetVisible = useStore((store) => store.handleChangeVisibility);
  const additionalAction = useStore((store) => store.additionalAction);
  return (
    <ModalWrapper testId="ApplyContributionRolloverModal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ vertical: 'spacing24' }}>
        <WarningIcon size="48px" />
        <Text
          data-testid="ApplyContributionRolloverModal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {headerText}
        </Text>

        <Text
          data-testid="ApplyContributionRolloverModal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {description}
        </Text>
        <Box direction="row" gap="spacing12">
          <AppButton
            testId="ApplyContributionRolloverModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            buttonType="secondary"
            width="200px"
          >
            {t('Cancel')}
          </AppButton>
          <AppButton
            testId="ApplyContributionRolloverModal_modal_confirm"
            onClick={() => additionalAction && additionalAction()}
            width="200px"
          >
            {t('Confirm')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
