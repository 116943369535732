import { storeCreator } from '@/utils/store/store.utils';

interface State {
  modalVisible: boolean;
  handleChangeVisibility: (value: boolean) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    modalVisible: false,
  })),
}));