import React from 'react';
import { useParams } from 'react-router-dom';

import { MyAccounts } from '@/modules/transaction';

const MyAccountsPage = () => {
  const { accountId = '' } = useParams<{ accountId: string }>();

  return (
    <MyAccounts accountId={accountId} />
  );
};
export default MyAccountsPage;
