import { useCallback, useMemo } from 'react';
import { toString } from 'lodash';

import { api } from '@/api';
import { FileImage,FileType } from '@/common/constants';
import PATHS from '@/common/paths';
import { useAuthStore } from '@/modules/user/stores';
import { getFileInBlob } from '@/utils/modifiers';

const getFileType = (url: string): FileType | undefined => {
  const formatStr = url?.split('?')?.[0]?.split('.')?.pop();
  const type = formatStr ? formatStr.trim().toUpperCase() : null;

  return FileType[type as keyof typeof FileType];
};
export const getBlob = async (url: string) => fetch(url)
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  .then(async (response: any) => {
    const blob = await response?.arrayBuffer();

    return new Blob(
      [blob],
      { type: getFileType(url) },
    );
  });

const useFileUploadQuery = () => {
  const { user } = useAuthStore();
  const createDocumentId = useCallback(async () => {
    try {
      const response = await api.post(PATHS.CREATE_DOCUMENT_ID, {
        employee_id: user?.employee?.id,
        document_type: 'RECEIPT',
      });
      return `${response?.data?.document_id || ''}`;
    } catch {
      return '';
    }
  }, [user]);

  const uploadFile = useCallback(async (file: FileImage, documentId: string) => {
    if (!documentId) {
      return file;
    }

    try {
      const blob = await getFileInBlob(file.preview);
      const formData = new FormData();
      formData.append('file', blob as Blob, file.name || file.path);

      const response = await api.post(
        PATHS.REIMBURSE_RECEIPT_UPLOAD(documentId),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      const responseBlob = await getBlob(response?.data?.s3_url);

      return {
        ...file,
        id: toString(response?.data?.id),
        url: response?.data?.s3_url || '',
        blob: responseBlob,
        preview: URL.createObjectURL(responseBlob),
      } as FileImage;
    } catch {
      return null;
    }
  }, []);

  return useMemo(() => ({
    createDocumentId,
    uploadFiles: async (documentId: string, files: FileImage[]) => {
      const ids: FileImage[] = [];

      const uploadFileRecursive = async (fileIndex: number = 0) => {
        const uploadedFile = await uploadFile(files[fileIndex], documentId);
        if (uploadedFile) {
          ids.push(uploadedFile);
          if (files[fileIndex + 1]) {
            await uploadFileRecursive(fileIndex + 1);
          }
        }
      };

      await uploadFileRecursive();
      return ids;
    },
  }), [createDocumentId, uploadFile]);
};

export default useFileUploadQuery;
