import React from 'react';
import { Box,Text } from '@common-fe/common-fe';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  ${({ theme }) => theme.shadows.default};
`;

interface Props {
  title: string;
  value: string;
  testId?: string;
  children?: React.ReactNode;
}

const NotificationWrapper: React.FC<Props> = ({
  title, value, testId, children,
}) => (
  <Wrapper
    data-testId={testId}
    direction="row"
    background="canvas"
    pad="l"
    round="container1Round"
  >
    <Text>
      <Text size="large">{title}</Text>
      <Text size="large" weight={700}>
        {value}
      </Text>
    </Text>
    <Box>{children}</Box>
  </Wrapper>
);

export default NotificationWrapper;
