import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

export default (id: string) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    () => api.delete(`${PATHS.INVESTMENT_SCHEDULERS}/${id}`),
  );
  return {
    isSuccess,
    mutateAsync,
    isLoading,
  };
};
