import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';

const QUERY_KEY = 'useGetEmployerTerminationDataQueryKey';

const employerTerminationDataFormatter = (dto: EmployerTerminationDataDto): EmployerTerminationData => ({
  id: dto.id,
  organizationId: dto.organization_id,
  terminationDate: dayjs(dto.termination_date).format(DEFAULT_DATE_FORMAT),
  coverageEndDate: dto.coverage_end_date ? dayjs(dto.coverage_end_date).format(DEFAULT_DATE_FORMAT) : null,
  claimLastDate: dto.claim_last_date ? dayjs(dto.claim_last_date).format(DEFAULT_DATE_FORMAT) : null,
  bulkTransfer: dto.bulk_transfer,
});
    

interface EmployerTerminationDataDto {
  id: number;
  organization_id: number;
  termination_date: string;
  coverage_end_date?: string | null;
  claim_last_date?: string | null;
  bulk_transfer: boolean;
}

export interface EmployerTerminationData {
  id: number;
  organizationId: number;
  terminationDate: string;
  coverageEndDate?: string | null;
  claimLastDate?: string | null;
  bulkTransfer: boolean;
}
    

export const useGetEmployerTerminationDataQuery = (orgId?: string) => {
  const {
    isLoading, isSuccess, data, refetch, isError,
  } = useQuery(
    [orgId, QUERY_KEY],
    orgId
      ? () => api.get<EmployerTerminationDataDto>(PATHS.TERMINATE_ORGANIZATION(orgId))
      : () => null,
    {
      retry: false,
      cacheTime: 0,
    }
  );

  const terminationData = useMemo(() => {
    if (isError) {
      return undefined;
    }
    return data?.data && employerTerminationDataFormatter(data.data);
  }, [data, isError]);

  return ({
    data: data?.data,
    terminationData,
    isLoading,
    isSuccess,
    refetch,
  });
};
