import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, OutlinedCompleteIcon, Text } from '@common-fe/common-fe';

import { useInvestor } from '../hooks';
import { InvestmentPaths, InvestorStatus } from '../investments.types';
import { useInvestmentsStartQuery } from '../queries';


interface Props {
 accountId?: string; 
}
const InvestmentResumeBlock: React.FC<Props> = ({ accountId }) => {
  const { data: investor } = useInvestor();
  const { t } = useTranslation();
  const { handleSave: handleStartInvestments,  handleRedirectToStart } = useInvestmentsStartQuery(
    {
      accountId: accountId,
    }
  );
  const currentInvestmentModelTitle = useMemo(() => {
    const currentType = investor.type;
    if (currentType === InvestmentPaths.Managed) {
      return 'Managed';
    }
    if (currentType === InvestmentPaths.SelfDirected) {
      return 'Self-Directed';
    }
  }, [investor.type]);

  const currentStepText = useMemo(() => {
    const currentStatus = investor.status;
    if (currentStatus === InvestorStatus.QUESTIONNAIRE_FILLED) {
      return 'Questionnaire Filled';
    }
    if (currentStatus === InvestorStatus.INVESTMENT_AMOUNT_SELECTED) {
      return 'Investment Amount Selected';
    }
    if (currentStatus === InvestorStatus.INVESTMENT_PATH_SELECTED) {
      return 'Investment Path Selected';
    }
  }, [investor.status]);

  if (!investor.type || investor.status === InvestorStatus.INVESTOR_CREATED) {
    return null;
  }

  return (
    <Box direction="column">
      <Box direction="row" margin={{ vertical: 'm' }}>
        <OutlinedCompleteIcon size="20px" color="success" />
        <Text color="success" weight={400} size="medium">
          {currentInvestmentModelTitle} ({currentStepText})
        </Text>
      </Box>
      <Box direction="row">
        <Box>
          <AppButton width="220px" onClick={handleStartInvestments}>{t('Resume Session')}</AppButton>
        </Box>
        <Box margin={{ left: 's' }}></Box>
        <Box>
          <AppButton onClick={() => handleRedirectToStart(investor.investorId, true)} width="220px" buttonType="secondary">
            {t('Invest With Another Model')}
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};
export default React.memo(InvestmentResumeBlock);
