import React from 'react';

import { Roles } from '@/common/constants';
import { useHasAccess, useRedirectHome } from '@/modules/core/hooks';
import { UpdateRiskPortfolio } from '@/modules/investments/UpdateRiskPortfolio/UpdateRiskPortfolio';

const UpdateRiskPortfolioPage = () => {
  const isInvestmentsDisabled = useHasAccess([{
    role: Roles.employeeLimitedAccess,
  }]);
  useRedirectHome(isInvestmentsDisabled);
  return (
    <UpdateRiskPortfolio />
  );
};

export default UpdateRiskPortfolioPage;
