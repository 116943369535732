import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  capitalizeFirstLetter,
  costFormater,
  CountUp,
  Hint,
  InfoOutlineIcon,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import regexp from '@/common/regexp';

import InvestmentSwitchModel from '../InvestmentSwitchModal';

import EditBreakdownItem from './EditBreakdownItem';

const DURATION = 1000;
const IconWrapper = styled(Box)`
    svg {
      cursor: pointer;
      transition: color 0.3s ease-in-out;
    }
    &:hover {
      svg {
        fill: ${({ theme }) => theme.colors.iconAccent};
      }
    }
`;

const HintAccountNummberWrapper = styled(Box)`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;
interface Props {
  accountId?: string;
  availableToInvest?: number;
  accountBalance?: number;
  accountNumber?: string;
  riskModel?: string;
  updateRiskModel?: () => void;
}

const InvestmentsBreakdownDetails: React.FC<Props> = ({
  accountId,
  availableToInvest,
  accountBalance,
  accountNumber,
  riskModel,
  updateRiskModel,
}) => {
  const { t } = useTranslation();
  const preparedRiskModel = useMemo(() => riskModel
    && riskModel.split(regexp.DASH_SYMBOL).map((item) => capitalizeFirstLetter(item)).join(' '),
  [riskModel]);
  return (
    <Box
      direction="column"
      margin={{ left: 'l' }}
      width={{ min: '230px' }}
      style={{ position: 'relative' }}
    >
      {accountNumber
        && (
          <HintAccountNummberWrapper>
            <Hint
              hintAlign="left"
              hintElement={(
                <IconWrapper margin={{ left: 's' }}>
                  <InfoOutlineIcon color="iconSecondary" size="20px" />
                </IconWrapper>
              )}
            >
              <Box direction="column" justify="start">
                <Text size="small" weight={500} textAlign="center">
                  {t('DriveWealth account number:')}
                </Text>
                <Text size="small" weight={500} textAlign="center">
                  {accountNumber}
                </Text>
              </Box>
            </Hint>
          </HintAccountNummberWrapper>
        )}
      <Box direction="column" margin={{ top: 's', bottom: 'm' }}>
        <CountUp
          testId="available-to-invest"
          bold
          size="2xl"
          number={availableToInvest || 0}
          duration={DURATION}
        />
        <Text color="textSecondary" size="small" weight={500}>{t('Available to Invest')}</Text>
      </Box>

      <Box margin={{ bottom: 'm' }}>
        <Text
          weight="bold"
          size="2xl"
          data-testid="account-balance_count"
        >{costFormater(accountBalance, true)}
        </Text>
        <Text color="textSecondary" size="small" weight={500}>{t('Account Balance')}</Text>
      </Box>
    
      <InvestmentSwitchModel  accountId={accountId}/>
      {!!riskModel && (
        <EditBreakdownItem onClick={updateRiskModel} label={t('Risk Model')}>
          {t(preparedRiskModel || '')}
        </EditBreakdownItem>
      
      )}
    </Box>
  );
};
export default InvestmentsBreakdownDetails;
