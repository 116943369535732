import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription } from '@common-fe/common-fe';

import PaymentFreeTimeLabel from '@/components/elements/PaymentFreeTimeLabel';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import whiteLabelingConfig, { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

const isRmrTheme = THEMES.rmr === whiteLabelingConfig.title;
const DEPOSIT_CARD_DESCRIPTION = 'We will deposit your reimbursement into the personal checking account you designate. This can take 2-3 business days.';
const DEBIT_CARD_DESCRIPTION = `For faster access to your money, we will
  deposit your reimbursement on to your personal debit card.`;
const PAY_PAL_DESCRIPTION = `Get reimbursed faster and safer!
  We will deposit your reimbursement into your PayPal account where you can then transfer funds or make purchases.`;
const VENMO_DESCRIPTION = `Manage your reimbursement your way!
  We will deposit your reimbursement into your Venmo account so that you can choose what to do with it.`;
const CHECK_DESCRIPTION = 'We will send a check to your Mailing address.';

interface PaymentMethodCard {
  title: string | React.ReactNode;
  paymentType: PaymentMethodType;
  description: string;
  add?: () => void;
  addTitle: string;
  paymentMethods?: PaymentMethod[];
  setActiveMethod?: (id: number) => void;
}

interface Props {
  onClose: () => void;
  chooseDeposit?: () => void;
  chooseDebitCard?: () => void;
  choosePayPal?: () => void;
  chooseVenmo?: () => void;
  chooseCheck?: () => void;
  methods: PaymentMethod[];
  allowedPaymentMethods: PaymentMethodType[];
}

const TitleWithLabel: React.FC<{ title?: string }> = ({ title }) => (
  <Box direction="row">
    <Inscription size="large" weight="bold" color="textBody">{title}</Inscription>
    {isRmrTheme ? <Box margin={{ left: 'spacing8' }}><PaymentFreeTimeLabel /></Box> : null}
  </Box>
);

export const PAYMENT_METHOD_TITLE = {
  [PaymentMethodType.DIRECT_DEPOSIT]: 'Direct Deposit',
  [PaymentMethodType.DEBIT]: 'My Personal Debit Card',
  [PaymentMethodType.VENMO]: 'Venmo',
  [PaymentMethodType.PAYPAL]: 'PayPal',
  [PaymentMethodType.CHECK]: 'Check',
};

export const usePaymentMethodsCards = (props: Props) => {
  const { t } = useTranslation();
  const { allowedPaymentMethods } = props;

  const cards: PaymentMethodCard[] = useMemo(() => {
    const allPaymentMethodCard = [
      {
        title: t(PAYMENT_METHOD_TITLE[PaymentMethodType.DIRECT_DEPOSIT]),
        paymentType: PaymentMethodType.DIRECT_DEPOSIT,
        description: t(DEPOSIT_CARD_DESCRIPTION),
        add: props.chooseDeposit,
        addTitle: t('Add a new Direct Deposit'),
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.DIRECT_DEPOSIT,
        ),
      },

      {
        title: <TitleWithLabel title={t(PAYMENT_METHOD_TITLE[PaymentMethodType.DEBIT])} />,
        paymentType: PaymentMethodType.DEBIT,
        description: t(DEBIT_CARD_DESCRIPTION),
        add: props.chooseDebitCard,
        addTitle: t('Add new Debit Card'),
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.DEBIT,
        ),
      },
      {
        title: <TitleWithLabel title={t(PAYMENT_METHOD_TITLE[PaymentMethodType.PAYPAL])} />,
        paymentType: PaymentMethodType.PAYPAL,
        description: t(PAY_PAL_DESCRIPTION),
        add: props.choosePayPal,
        addTitle: t('Add PayPal account'),
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.PAYPAL,
        ),
      },
      {
        title: <TitleWithLabel title={t(PAYMENT_METHOD_TITLE[PaymentMethodType.VENMO])} />,
        paymentType: PaymentMethodType.VENMO,
        description: t(VENMO_DESCRIPTION),
        add: props.chooseVenmo,
        addTitle: t('Add Venmo account'),
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.VENMO,
        ),
      },
      {
        title: t(PAYMENT_METHOD_TITLE[PaymentMethodType.CHECK]),
        paymentType: PaymentMethodType.CHECK,
        description: t(CHECK_DESCRIPTION),
        add: props.chooseCheck,
        addTitle: t('Add Check Payment Method'),
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.CHECK,
        ),
      },
    ];

    const allowedPaymentMethodsCards = allPaymentMethodCard
      .filter((paymentMethod: PaymentMethodCard) => allowedPaymentMethods
        .some(
          (allowedPaymentMethod: string) => allowedPaymentMethod === paymentMethod.paymentType,
        ));
    return allowedPaymentMethodsCards;
  }, [t,
    props,
    allowedPaymentMethods]);

  return cards;
};
