import React, { useMemo } from 'react';
import { Box,Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { useCountriesQuery } from '@/modules/core/queries';

import { usePersonalInfoStore } from '../stores';

export default () => {
  const { data: countries, countriesMap } = useCountriesQuery();
  const currentCitizenship = usePersonalInfoStore((state) => state.state.citizenship);

  const currentCountry = useMemo(() => {
    if (!currentCitizenship) {
      return null;
    }
    return countriesMap[currentCitizenship];
  }, [countriesMap, currentCitizenship]);
  const fields = useMemo<Field[]>(() => [{
    name: 'citizenship',
    type: FieldTypes.AutocompleteDropdown,
    singleMode: true,
    label: 'Citizenship',
    showRequireIcon: true,
    maxOptionsCount: countries.length,
    leftIcon: currentCountry?.node ? (
      <Box margin={{top: '-4px'}}>
        {
          currentCountry.node
        }
      </Box>
    ) : undefined,
    options: countries,
    placeholder: 'Select country',
    validator: VALIDATORS.REQUIRED_STRING,

  }], [countries, currentCountry]);

  return fields;
};
