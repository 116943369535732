import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, FlexControlledForm,
  ProfileInformationIcon, useDebounceState, } from '@common-fe/common-fe';

import { useProfileInformationFormStore } from '@/modules/user/components/SignUp/ProfileInformation/stores/useProfileInformationFormMode.store';

import BlockWrapper from '../BlockWrapper';

import useProfileInformationFields from './hooks/useProfileInformationFields';
import { ProfileInformationPayload, useStore } from './useProfileInformation.store';

interface ProfileInformationProps {
  isOrphanMode?: boolean;
}
const ProfileInformation: React.FC<ProfileInformationProps> = ({ isOrphanMode }) => {
  const { t } = useTranslation();

  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const { values: { isFormEditMode, isFormError } } = useProfileInformationFormStore();

  const { setValues, values } = useDebounceState<ProfileInformationPayload>();
  const fields = useProfileInformationFields({
    isOrphanMode,
    isDisabledMode: !isFormEditMode,
    isError: isFormError,
  });
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <BlockWrapper
      Icon={ProfileInformationIcon}
      title={t('Profile Information')}
    >
      <Box background="canvas" round="container1Round">
        <FlexControlledForm
          fields={fields}
          showError={showErrors}
          editMode
          onChangeValues={setValues}
        />
      </Box>
    </BlockWrapper>
  );
};

export default ProfileInformation;
