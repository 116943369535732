import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Text,
} from '@common-fe/common-fe';

import { LinkedEmployee } from '@/modules/user/user.types';

import { EmployeeSwitcherItem } from './EmployeeSwitcherItem';

interface Props {
  currentEmployeeId: string;
  linkedEmployees: LinkedEmployee[];
  onSwitching: (employeeId: string) => void;
}

export const EmployeeSwitcher: React.FC<Props> = ({
  currentEmployeeId,
  linkedEmployees,
  onSwitching,
}) => {
  const { t } = useTranslation();

  return (
    <Box data-testid="orphan-mode-switcher-wrapper">
      <Box>
        <Text size="12px" color="textSecondary" weight={500}>
          {t('Linked employers')}
        </Text>
      </Box>

      <Box>
        {linkedEmployees.map((linkedEmployee) => (
          <EmployeeSwitcherItem
            key={linkedEmployee.employeeId}
            linkedEmployee={linkedEmployee}
            onSwitching={onSwitching}
            isSelected={currentEmployeeId === linkedEmployee.employeeId}
            testId={linkedEmployee.employeeId}
          />
        ))}
      </Box>
    </Box>
  );
};
