import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Field, FieldTypes, Inscription } from '@common-fe/common-fe';
import _ from 'lodash';

import { MFAChannel, MFAChannelType } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';

export interface FormValues {
  verificationMethod: string;
}

const ChannelTypeLabel = {
  [MFAChannelType.EMAIL]: 'Email',
  [MFAChannelType.SMS]: 'Phone number',
};

interface Props {
  channels?: MFAChannel[];
  activeChannel?: MFAChannel;
}

const useVerificationMethodsFields = ({ channels, activeChannel }: Props) => {
  const { t } = useTranslation();

  const uniqChannels = _.uniqBy(channels, 'value');

  const channelOptions = useMemo(() => uniqChannels?.map((channel) => ({
    label: (
      <Box width="auto">
        <Inscription
          size="small"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {channel.type ? ChannelTypeLabel[channel.type] : ''}{' '}
          <Inscription size="small" weight={700}>
            {channel?.value}
          </Inscription>
        </Inscription>
      </Box>
    ),
    value: channel?.value,
  })) || [], [uniqChannels]);

  const fields = useMemo<Field[]>(() => [
    {
      name: 'verificationMethod',
      type: FieldTypes.Radio,
      label: t('Verification Method'),
      showRequireIcon: true,
      value: activeChannel?.value || '',
      options: channelOptions,
      isWrappedMode: true,
      inputStyle: {
        width: 'auto',
      }
    }
  ], [t, channelOptions, activeChannel]);

  return fields;
};

export default useVerificationMethodsFields;
