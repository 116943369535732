import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const useVoidCheckQuery = (onSuccess?: () => void, id?: string) => {
  const { mutateAsync, isLoading } = useMutation(
    (reason?: string | null) => api.put(PATHS.VOID_CHECK(id), {
      void_reason: reason || null,
    }), {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      }
    },
  );
  return {
    mutateAsync,
    isLoading,
  };
};

export default useVoidCheckQuery;
