import React from 'react';
import { PaginationBar } from '@common-fe/common-fe';

import { PaginationBarWrapper } from '@/components/wrappers';

interface PaginationBarProps {
  page: number,
  total: number,
  pageSize: number,
  onChangePage: (value: number) => void,
  onChangePageSize: (value: number) => void,
}

const PaginationBarWithMediaQuery: React.FC<PaginationBarProps> = ({
  page,
  total,
  pageSize,
  onChangePage,
  onChangePageSize,
}) => {


  return (
    <PaginationBarWrapper>
      <PaginationBar
        page={page}
        total={total}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        
      />
    </PaginationBarWrapper>
  );
};

export default PaginationBarWithMediaQuery;
