import { storeCreator } from '@/utils/store/store.utils';

interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalAction: (files?: any) => void;
  resetFileStore: boolean;
  handleSetResetFileStore: (value: boolean) => void;
  handleSetAdditionalAction: (additionalAction: State['additionalAction']) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  additionalAction: () => {},
  resetFileStore: false,

  handleSetAdditionalAction: (additionalAction) => set((state) => ({
    ...state,
    additionalAction,
  })),

  handleSetResetFileStore: (value) => set((state) => ({
    ...state,
    resetFileStore: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    resetFileStore: false,
    additionalAction: () => {},
  })),
}));
