import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { MAX_LENGTH_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { useStatesQuery } from '@/modules/core/queries';
import { EmployeeAddress } from '@/modules/user/user.types';

interface Params {
  address: EmployeeAddress;
}

const useEditEmployeeAddressFields = ({ address }: Params) => {
  const { t } = useTranslation();
  const { options: stateOptions } = useStatesQuery();

  const fields = useMemo<Field[]>(
    () => [
      {
        name: 'line1',
        type: FieldTypes.Text,
        label: t('Address Line 1'),
        placeholder: t('Address Line 1'),
        showRequireIcon: true,
        defaultValue: address?.line1,
        validator: VALIDATORS.REQUIRED_STRING.max(200,MAX_LENGTH_TEXT(200)),
        
      },
      {
        name: 'line2',
        type: FieldTypes.Text,
        label: t('Address Line 2'),
        placeholder: t('Address Line 2'),
        defaultValue: address?.line2,
        validator: VALIDATORS.STRING.max(200,MAX_LENGTH_TEXT(200)),
      },
      {
        name: 'city',
        type: FieldTypes.Text,
        label: t('City'),
        showRequireIcon: true,
        placeholder: t('Enter City'),
        validator: VALIDATORS.REQUIRED_STRING,
        defaultValue: address?.city,
      },
      {
        name: 'state',
        type: FieldTypes.AutocompleteDropdown,
        label: t('State'),
        // singleMode: true,
        showRequireIcon: true,
        placeholder: t('Select state'),
        validator: VALIDATORS.REQUIRED_STRING,
        defaultValue: address?.state,

        options: stateOptions,
      },
      {
        name: 'zipcode',
        type: FieldTypes.ZipCode,
        label: t('ZIP code'),
        placeholder: t('Enter ZIP code'),
        showRequireIcon: true,
        defaultValue: address?.zipcode,
        validator: VALIDATORS.ZIP_CODE.required(REQUIRED_TEXT),
      },
    ],
    [address, stateOptions, t]
  );
  return fields;
};

export default useEditEmployeeAddressFields;
