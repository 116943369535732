import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const useReissueCheckQuery = (onSuccess: () => void, checkId?: string) => {
  const { mutateAsync, isLoading } = useMutation(
    (paymentMethodId?: string) => api.put(PATHS.REISSUE_CHECK(checkId), {
      ...paymentMethodId ? { payment_method_id: paymentMethodId } : {},
    }), { onSuccess },
  );
  return {
    mutateAsync,
    isLoading,
  };
};

export default useReissueCheckQuery;
