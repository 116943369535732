import { useMemo } from 'react';

import  useAccountDetailsFields from '../../AccountAccess/components/AccountDetailsForm/hooks/useAccountDetailsFields';
import useSecurityFields from '../../AccountAccess/components/SecurityForm/hooks/useSecurityFields';
import { useFields as useProfileInformationFields } from '../../ProfileInformation';

export default (isOrphanMode?: boolean) => {
  const accountDetailsFields = useAccountDetailsFields({ isOrphanMode });
  const securityFields = useSecurityFields();
  const profileInformationFields = useProfileInformationFields({ isOrphanMode });
  const fields = useMemo(() => ([
    ...accountDetailsFields,
    ...securityFields,
    ...profileInformationFields,
  ]), [profileInformationFields, accountDetailsFields, securityFields]);
  return fields;
};
