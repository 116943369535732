import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';

import { REGEXPS } from '@/common';
import { TransactionStatus, TransactionStatusKeys } from '@/modules/transaction/transaction.types';

interface StatusProps {
  null?: boolean;
  processing?: boolean;
  pending?: boolean;
  waitingForFunds?: boolean;
  needsHelp?: boolean;
  paid?: boolean;
  partiallyPaid?: boolean;
  denied?: boolean;
  repaid?: boolean;
  expired?: boolean;
  complete?: boolean;
  cancelled?: boolean;
  customHeight?: string;
}

export const Status = styled.p<StatusProps>`
  ${({ theme }) => theme.fonts.text14Medium};
  width: ${({ theme }) => theme.controls.mediumSize};
  height: ${({ customHeight }) => customHeight || '34px'};
  padding: 0;
  padding-left: 19px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    left: 0;
    ${({
    theme,
    processing,
    pending,
    waitingForFunds,
    complete,
    needsHelp,
    paid,
    partiallyPaid,
    denied,
    repaid,
    expired,
    cancelled,
  }) => {
    const colors = theme.colors.statusColors;
    let color = colors.lockedNull;
    if (processing) {
      color = colors.processingPending;
    }
    if (pending) {
      color = colors.processingPending;
    }
    if (needsHelp || waitingForFunds) {
      color = colors.needsHelp;
    }
    if (paid || complete) {
      color = colors.paidActiveComplete;
    }
    if (partiallyPaid) {
      color = colors.partiallyPaid;
    }
    if (denied) {
      color = colors.deniedErrorExpired;
    }
    if (repaid) {
      color = colors.paidActiveComplete;
    }
    if (expired) {
      color = colors.deniedErrorExpired;
    }
    if (cancelled) {
      color = colors.deniedErrorExpired;
    }
    if (complete) {
      color = colors.paidActiveComplete;
    }
    return {
      'background-color': color,
    };
  }}
  }
`;

interface Props {
  status?: string;
}

const ActivityStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const text = useMemo(() => {
    return t(_.capitalize(status || '').replace(REGEXPS.DASH_SYMBOL, ' '));
  }, [status, t]);

  if (status === TransactionStatusKeys.Processing || status === TransactionStatus.Processing) {
    return (
      <Status processing data-testid="ActivityStatus-processing-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.NeedsHelp || status === TransactionStatus.NeedsHelp) {
    return (
      <Status needsHelp data-testid="ActivityStatus-needshelp-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Paid || status === TransactionStatus.Paid) {
    return (
      <Status paid data-testid="ActivityStatus-paid-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.OutOfPocket || status === TransactionStatus.OutOfPocket) {
    return (
      <Status paid data-testid="ActivityStatus-paid-wrap">
        {text}
      </Status>
    );
  }
  if (
    status === TransactionStatusKeys.AppliedToDeductible ||
    status === TransactionStatus.AppliedToDeductible
  ) {
    return (
      <Status paid data-testid="ActivityStatus-paid-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.PartiallyPaid || status === TransactionStatus.PartiallyPaid) {
    return (
      <Status partiallyPaid data-testid="ActivityStatus-partiallypaid-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.WaitingForFunds || status === TransactionStatus.WaitingForFunds) {
    return (
      <Status waitingForFunds data-testid="ActivityStatus-waitingforfunds-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Pending || status === TransactionStatus.Pending) {
    return (
      <Status pending data-testid="ActivityStatus-pending-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Repaid || status === TransactionStatus.Repaid) {
    return (
      <Status repaid data-testid="ActivityStatus-repaid-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Denied || status === TransactionStatus.Denied) {
    return (
      <Status denied data-testid="ActivityStatus-denied-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Expired || status === TransactionStatus.Expired) {
    return (
      <Status expired data-testid="ActivityStatus-expired-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Complete || status === TransactionStatus.Complete) {
    return (
      <Status complete data-testid="ActivityStatus-complete-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Declined || status === TransactionStatus.Declined) {
    return (
      <Status denied data-testid="ActivityStatus-complete-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.RepaymentRequired || status === TransactionStatus.RepaymentRequired) {
    return (
      <Status partiallyPaid data-testid="ActivityStatus-repaymentRequired-wrap">
        {text}
      </Status>
    );
  }
  if (status === TransactionStatusKeys.Voided || status === TransactionStatus.Voided) {
    return (
      <Status denied data-testid="ActivityStatus-voided-wrap">
        {text}
      </Status>
    );
  }
  return (
    <Status null data-testid="ActivityStatus-null-wrap">
      {t('Null')}
    </Status>
  );
};

export default ActivityStatus;
