import React, { useMemo } from 'react';
import {
  Box,
  Checkbox,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import { DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';

import { OneTimeContributionFormValues } from '../contribution.types';

const currentYear = dayjs().year();
const currentToday = dayjs().toDate();
const maxAmount = 8750;

interface Props {
  formValues: OneTimeContributionFormValues;
  lastDayForContribution?: string;
  rollover?: boolean;
  setRollover?: (value: boolean) => void;
  minimumContributionAmount?: number;
  maximumContributionAmount?: number;
}

export const useMakeOneTimeContributionFields = (props: Props) => {
  const {
    lastDayForContribution,
    minimumContributionAmount = 10,
    maximumContributionAmount = maxAmount,
    rollover,
    setRollover,
    formValues: {
      contributionAmount,
      taxYear,
    },
  } = props;
  const isPrevYearAvailable = useMemo(() => {
    if (!lastDayForContribution) return false;

    const lastDay = dayjs(lastDayForContribution).format(DEFAULT_DATE_FORMAT);
    const today = dayjs(currentToday).format(DEFAULT_DATE_FORMAT);

    return today <= lastDay;
  }, [lastDayForContribution]);

  const taxYearOptions = useMemo(() => ([
    {
      key: `${currentYear}`,
      value: `${currentYear}`,
      title: `${currentYear}`,
    },
    ...isPrevYearAvailable ? [{
      key: `${currentYear - 1}`,
      value: `${currentYear - 1}`,
      title: `${currentYear - 1}`,
    }] : [],
  ]), [isPrevYearAvailable]);

  const fields: Field[] = useMemo(() => [
    {
      name: 'contributionAmount',
      type: FieldTypes.Currency,
      showRequireIcon: true,
      label: 'Contribution amount',
      placeholder: 'Enter Contribution amount',
      defaultValue: contributionAmount,
      validator: yup.string().test({
        test: (val) => {
          if (_.toNumber(val) < minimumContributionAmount) return false;
          return true;
        },
        message: `Amount must be greater than $${minimumContributionAmount}`,
      }).test({
        test: (val) => {
          if (_.toNumber(val) > maximumContributionAmount && !rollover) return false;
          return true;
        },
        message: 'Amount exceeds your remaining limit',
      }).required(REQUIRED_TEXT),
    },
    {
      name: 'taxYear',
      type: FieldTypes.Dropdown,
      showRequireIcon: true,
      label: 'Tax year',
      placeholder: 'Enter Tax year',
      validator: yup.string().required(REQUIRED_TEXT),
      singleMode: true,
      options: taxYearOptions,
      defaultValue: taxYear || `${currentYear}`,
    },
    {
      name: 'rollover',
      type: FieldTypes.Node,
      label: (
        <Box width={{ max: '245px' }}>
          <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
            Rollover
          </Inscription>
          <Box>
            <Inscription weight={500} color="textSecondary" size="small">
              This contribution is a rollover of money from another HSA.
            </Inscription>
          </Box>
        </Box>
      ),
      value: (
        <Box>
          <Checkbox checked={rollover} onChange={setRollover} />
        </Box>
      ),

    },
  ], [
    contributionAmount,
    rollover,
    setRollover,
    taxYear,
    taxYearOptions,
    minimumContributionAmount,
    maximumContributionAmount,
  ]);

  return fields;
};
