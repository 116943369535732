import React, { useCallback } from 'react';
import { Controller,useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box, FlatTextInput,
  Form, } from '@common-fe/common-fe';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';

import { useForgotPassword } from './hooks';

const USERNAME_FIELD_KEY = 'forgotUsername';

interface ForgotPasswordProps {
  onNext: (value: object) => void;
  username: string;
  children?: React.ReactNode;
}
const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onNext, username }) => {
  const { t } = useTranslation();
  const {
    control, formState: { errors }, handleSubmit,
  } = useForm({
    defaultValues: {
      [USERNAME_FIELD_KEY]: username,
    },
    // @ts-ignore
    resolver: yupResolver(yup.object().shape({
      [USERNAME_FIELD_KEY]: yup.string().required(REQUIRED_TEXT),
    })),
  });
  const { handleRequestCode } = useForgotPassword();
  const handleSubmitForgotPassword = useCallback(async (value: object) => {
    const currentUsername = _.get(value, USERNAME_FIELD_KEY, '') as string;
    await handleRequestCode(currentUsername);
    onNext(value);
  }, [handleRequestCode, onNext]);
  return (
    <Box margin={{ horizontal: 'l' }}>
      <Form onSubmit={handleSubmit(handleSubmitForgotPassword)}>
        <Box dir="column">
          <Controller
            name={USERNAME_FIELD_KEY}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FlatTextInput
                value={value}
                onChange={onChange}
                className="text-input"
                testId="text-input-username"
                defaultAutoComplete="off"
                name={USERNAME_FIELD_KEY}
                isLarge
                placeholder={t('Enter your username')}
                errorText={_.get(errors, `${USERNAME_FIELD_KEY}.message`)}
                isLowerCaseMode
              />
            )}
          />
          <Box margin={{ top: 'spacing32' }}>
            <AppButton
              testId="forgot-password-continue"
              type="submit"
              width="100%"
              size="L"
            >
              {t('Continue')}
            </AppButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default ForgotPassword;
