import { useMutation } from 'react-query';
import { onlyDateToServer } from '@common-fe/common-fe';

import { api } from '@/api';
import { PATHS } from '@/common';

import { SignUpPayload } from '../hooks/useSignUpState';

export const useSignUpQuery = () => {
  const { mutateAsync: singUp } = useMutation(
    (payload: SignUpPayload) => api.post<{ id: string }>(PATHS.SIGN_UP, {
      unique_id: payload.uniqueId,
      birth_date: onlyDateToServer(payload.birthdate),
      username: payload.username,
      password: payload.password,
      email: payload.email,
      is_electronic_statement_only: payload.isElectronicStatementOnly,
      phone_number: payload.phonenumber,
    }),
  );

  return { singUp };
};
