import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Hint,
  MinusThinIcon,
  PlusThinIcon,
  RawButton,
  Text,
  TextInput,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';
const InputWrapper = styled(Box)`
  input:disabled {
    opacity: 1;
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.textDisabled};
    background: ${({ theme }) => theme.colors.border1}
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

`;
interface StyledButtonProps {
  disabled?: boolean;
}
const StyledWrapper = styled(Box)<StyledButtonProps>`
  background: ${({ theme, disabled }) => (disabled ? theme.colors.border2 : theme.colors.accentContainer)};
  &:hover {
    background: ${({ theme, disabled }) => (disabled ? theme.colors.border2 : theme.colors.accentBorder)};
  }
`;
interface Props {
  value: number;
  isOnlyOneAvailable?: boolean;
  disabled?: boolean;
  onChange: (value: number) => void;
  isEmpty?: boolean;
  isMaxLimited?: boolean;
  maxValue?: number;
}

const PERCENTS_SIGN = '%';
const STEP_PERCENTS = 5;
const PARTITION_SIGN = '.';
const LOCAL_PARTITION_SIGN = ',';
const MINUS_SIGN = '-';

const handleParse = (value: string) => {
  const newValue = parseFloat(value);
  if (_.isNaN(newValue)) {
    return undefined;
  }
  return newValue;
};

const MAX_PERCENTS = 100;
const FundAllocationInput: React.FC<Props> = ({
  value, onChange,
  maxValue,
  disabled, isOnlyOneAvailable,
  isEmpty, isMaxLimited }) => {

  const currentMaxValue = useMemo(() => {
    if(isMaxLimited){
      return maxValue || MAX_PERCENTS;
    }
    return  MAX_PERCENTS;
  }, [isMaxLimited, maxValue]);
  const [isFocused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(`${value || ''}`);
  
  const displayedValue = useMemo(() => {
    const formattedValue = currentValue.replace(LOCAL_PARTITION_SIGN, PARTITION_SIGN).replace(PERCENTS_SIGN, ''); 
    if(!formattedValue && isFocused) {
      return '0';
    }
    if(!formattedValue && !isFocused) {
      return '0%';
    }
    if (isFocused) {
      return formattedValue;
    }
    const numberValue = _.toNumber(formattedValue);
    if(_.isNumber(numberValue) ) {
      return `${_.floor(numberValue, 2)}${PERCENTS_SIGN}`;
    }
    return `${formattedValue}${PERCENTS_SIGN}`;
    
  }, [currentValue, isFocused]);
  
  const { t } = useTranslation();
  const isDisabled = useMemo(() => {
    return isOnlyOneAvailable || disabled;
  }, [disabled, isOnlyOneAvailable]);
  useEffect(() => {
    const newValue = handleParse(currentValue);
    if (newValue !== undefined) {
      onChange(newValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);
  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);
  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  useEffect(() => {
    if (isOnlyOneAvailable && !disabled) {
      setCurrentValue('100%');
    }
  }, [disabled, isOnlyOneAvailable]);

  const handleDecrease = useCallback(() => {
    if (disabled || isOnlyOneAvailable) {
      return;
    }
    const parsedValue = handleParse(currentValue);
    if (parsedValue === undefined) {
      return;
    }
    const newValue = parsedValue - STEP_PERCENTS;

    if (newValue < 0) {
      setCurrentValue('0');
    } else {
      setCurrentValue(`${_.floor(newValue, 2)}`);
    }
  }, [currentValue, disabled, isOnlyOneAvailable]);

  const handleIncrease = useCallback(() => {
    if (disabled || isOnlyOneAvailable || isMaxLimited) {
      return;
    }
    const parsedValue = handleParse(currentValue);
    if (parsedValue === undefined) {
      setCurrentValue(`${STEP_PERCENTS}`);
      return;
    }
    
    let newValue = parsedValue + STEP_PERCENTS;
    if(currentMaxValue < newValue) {
      newValue  = currentMaxValue;
    }
    setCurrentValue(`${_.floor(newValue, 2)}`);
  }, [currentMaxValue, currentValue, disabled,
    isMaxLimited, isOnlyOneAvailable]);

  const handleChangeValue = useCallback(( e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    const formattedValue = value.replace(LOCAL_PARTITION_SIGN, PARTITION_SIGN ).replace(MINUS_SIGN, '');
    const numberValue  = _.floor(_.toNumber(formattedValue));
    if(formattedValue) {
      
      if(!_.isNaN(numberValue) && numberValue > currentMaxValue) {
        setCurrentValue(`${currentMaxValue}`);
      } else {
        setCurrentValue(formattedValue);
      }
      
    } else {
      setCurrentValue('0');
    }
    
    
  }, [currentMaxValue]);

  return (
    <Box direction="row">
      <RawButton onClick={handleDecrease}>
        <StyledWrapper
          disabled={isDisabled}
          align="center"
          justify="center"
          round="50%"
          width="40px"
          height="40px"
        >
          <MinusThinIcon color={isDisabled ? 'iconSecondary' : 'iconPrimary'} width="24px" height="24px" />
        </StyledWrapper>
      </RawButton>
      <Box width="80px" margin={{ horizontal: 'xs' }}>
        {disabled ? (
          <Hint
            hintElement={(
              <InputWrapper>
                <TextInput
                  type={isFocused && !isEmpty ? 'number' : 'text'}
                  onChange={handleChangeValue}
                  onBlur={handleBlur}
                  min="0"
                  max={currentMaxValue}
                  onFocus={handleFocus}
                  disabled={disabled || isOnlyOneAvailable}
                  value={isEmpty ? '0%' : displayedValue}
                  style={{
                    textAlign: 'center',
                    fontWeight: 700,
                    appearance: 'none',
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                  }}
                />
              </ InputWrapper>
            )}
          >
            {disabled && (
              <Text weight={500} textAlign='center'>{t('First, set the amount you would like to invest.')}</Text>
            )}
          </Hint>
        ) : (
          <InputWrapper>
            <TextInput
              type={isFocused ? 'number' : 'text'}
              onChange={handleChangeValue}
              onBlur={handleBlur}
              onFocus={handleFocus}
              min="0"
              max={maxValue || '100'}
              disabled={disabled || isOnlyOneAvailable}
              value={isEmpty && !isEmpty ? '' : displayedValue}
              style={{
                textAlign: 'center',
                fontWeight: 700,
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none',
              }}
            />
          </InputWrapper>
        )}
      </Box>

      <RawButton  onClick={handleIncrease}>
        <StyledWrapper
          disabled={isDisabled || isMaxLimited}
          align="center"
          justify="center"
          round="50%"
          width="40px"
          height="40px"
        >
          <PlusThinIcon color={isDisabled ||isMaxLimited ? 'iconSecondary' : 'iconPrimary'} width="24px" height="24px" />
        </StyledWrapper>
      </RawButton>
    </Box>
  );
};

export default FundAllocationInput;
