import { useCallback } from 'react';

import { FileImage } from '@/common/constants';
import prepareFile from '@/components/controls/DropScan/prepareFile';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import useFileUploadQuery from '@/modules/transaction/hooks/useFileUploadQuery';

const useUploadFilesToDocument = () => {
  const { values: { documentId, files }, handleSetValues } = useFilesInDocumentStore();
  const { createDocumentId, uploadFiles } = useFileUploadQuery();

  const handleUploadFilesToDocument = useCallback(async (filesToUpload: FileImage[]) => {
    let createdDocumentId = '';
    if (!documentId) {
      createdDocumentId = await createDocumentId();
      handleSetValues({ documentId: createdDocumentId });
    }
    const currentDocumentId = documentId || createdDocumentId;
    const preparedFiles = filesToUpload.map(prepareFile);
    const uploadedFiles = await uploadFiles(currentDocumentId, preparedFiles as FileImage[]);
    handleSetValues({ files: [...files, ...uploadedFiles.filter((file) => file.id)] });
    return { uploadedFiles, documentId: currentDocumentId };
  }, [createDocumentId, handleSetValues, uploadFiles, files, documentId]);

  return {
    handleUploadFilesToDocument,
  };
};

export default useUploadFilesToDocument;
