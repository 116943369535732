import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, MailMessageThinIcon, TextMessageIcon } from '@common-fe/common-fe';

import ProfileSettingCard from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/components/ProfileSettingCard/ProfileSettingCard';
import useUpdateEmployeeQuery from '@/modules/user/queries/useUpdateEmployee.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

import EmailVerificationModal from './components/EmailVerificationModal/EmailVerificationModal';
import PhoneVerificationModal from './components/PhoneVerificationModal/PhoneVerificationModal';
import useEmailOptions from './hooks/useEmailOptions';
import useTextMessagesOptions from './hooks/useTextMessagesOptions';

const CommunicationPreferences = () => {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { updateEmployee } = useUpdateEmployeeQuery();

  const [isEmailMessageModalVisible, setIsEmailMessageModalVisible] = useState(false);
  const [isPhoneMessageModalVisible, setIsPhoneMessageModalVisible] = useState(false);

  const { emailOptions } = useEmailOptions({ setIsEmailMessageModalVisible });
  const { textMessagesOptions } = useTextMessagesOptions();

  const defaultPhoneNumber = useMemo(() => employee?.phone?.number, [employee]);
  const defaultEmail= useMemo(() => employee?.employeeProvidedEmail || employee?.employerProvidedEmail, [employee]);

  const isEmailNotificationsActive = useMemo(() => !!employee?.employeeProvidedEmail
    || !!employee?.employerProvidedEmail, [employee]);
  const isSmsNotificationsActive = useMemo(() => employee?.isSmsNotificationEnabled
    && !!employee?.phone?.number
    && employee?.isVerifiedPhoneNumber, [employee]);

  const handleOpenEmailVerificationModal = useCallback(() => setIsEmailMessageModalVisible(true), []);
  const handleOpenPhoneVerificationModal = useCallback(() => setIsPhoneMessageModalVisible(true), []);

  const handleActivateSmsNotifications = useCallback((value: boolean) => {
    if (employee?.phone?.number && employee?.isVerifiedPhoneNumber) {
      updateEmployee({ is_sms_notification_enabled: value });
      return;
    }

    handleOpenPhoneVerificationModal();
  }, [employee, handleOpenPhoneVerificationModal, updateEmployee]);

  return (
    <>
      <Box round="moduleRound" pad="spacing24" background="module">
        <Grid columns={['1fr', '1fr']} gap="spacing24">
          <ProfileSettingCard
            testId="email"
            icon={<MailMessageThinIcon size="60px" color="textDisabled" />}
            title="Email"
            description={t('Choose your preferred email for essential account updates.')}
            options={emailOptions}
            isActive={isEmailNotificationsActive}
            onActivate={handleOpenEmailVerificationModal}
            isActivationDisabled={isEmailNotificationsActive}
            onActivateOption={handleOpenEmailVerificationModal}
            defaultValue={employee?.employeeProvidedEmail}
          />
          <ProfileSettingCard
            testId="textMessages"
            icon={<TextMessageIcon size="60px" color="textDisabled" />}
            title={t('Text messages')}
            description={t('Receive essential account updates on your mobile phone. Msg & Data rates may apply.')}
            options={textMessagesOptions}
            isActive={isSmsNotificationsActive}
            onActivate={handleActivateSmsNotifications}
            isActivationDisabled={false}
            onActivateOption={handleOpenPhoneVerificationModal}
            defaultValue={defaultPhoneNumber}
          />
        </Grid>
      </Box>
      {isPhoneMessageModalVisible && (
        <PhoneVerificationModal
          defaultValue={defaultPhoneNumber}
          onSetVisible={setIsPhoneMessageModalVisible}
        />
      )}
      {isEmailMessageModalVisible && (
        <EmailVerificationModal
          defaultValue={defaultEmail}
          onSetVisible={setIsEmailMessageModalVisible}
        />
      )}
    </>
  );
};

export default CommunicationPreferences;
