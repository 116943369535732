import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { InvestorDto } from '../investments.types';

export const QUERY_KEY = 'INVESTORS';
export default (
  successCallback?: (investor: InvestorDto) => void,
) => {
  const {
    isLoading, error, data, isSuccess, isError,
  } = useQuery(
    [QUERY_KEY],
    () => api.get<InvestorDto>(PATHS.INVESTORS), {
      onSuccess: (response) => {
        if (successCallback) {
          successCallback(response.data);
        }
        return response;
      },
      cacheTime: 2,
    },
  );

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
