import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, Preloader } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';

interface Props {
  icon?: React.ReactNode;
  title?: React.ReactNode | string;
  description?: React.ReactNode | string;
  form?: React.ReactNode;
  footerNode?: React.ReactNode;
  isLoading?: boolean;
  error?: React.ReactNode;
  testId?: string;
  onSetVisible: (value: boolean) => void;
  onConfirm: () => void;
  onBack?: () => void;
}

const VerificationCodeModal: React.FC<Props> = ({
  icon, title, description, form, footerNode, isLoading, error, testId, onSetVisible, onConfirm, onBack,
}) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper visible onSetVisible={onSetVisible} testId={testId}>
      <Box margin={{ top: 'spacing24' }} align="center">
        {icon}
        {title}
        {description}
      </Box>

      {
        form ? (
          <Box
            margin={{ top: 'spacing24' }}
            background="module"
            pad="spacing24"
            round="moduleRound"
          >
            <Box elevation='default' background="canvas" round="container1Round">
              {form}
            </Box>
          </Box>
        ) : null
      }

      <Box margin={{ top: 'spacing24' }}>
        {error}
        <Box direction="row" justify="between" align="center">
          {footerNode}
          <Box
            direction="row"
            justify="end"
            margin={{ left: 'auto' }}
          >
            {
              onBack && (
                <Box>
                  <AppButton
                    width="160px"
                    buttonType="secondary"
                    onClick={onBack}
                  >
                    {t('Back')}
                  </AppButton>
                </Box>
              )
            }
            <Box margin={{ left: 'spacing8' }}>
              <AppButton
                width="160px"
                {...isLoading ? {} : { onClick: onConfirm }}
              >
                {isLoading ? <Preloader /> : t('Confirm')}
              </AppButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default VerificationCodeModal;
