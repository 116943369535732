import { useCallback } from 'react';

import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import useOrganizationViewDataQuery from '@/modules/user/queries/useOrganizationViewData.query';
import { useAuthStore } from '@/modules/user/stores';
import { PaymentMethodType } from '@/modules/user/user.types';

const useOrganizationProperties = () => {
  const { user } = useAuthStore();
  const { formattedData: organizationProperties, ...queryInfo } = useOrganizationViewDataQuery(
    user?.organization?.id,
  );

  const checkIsAvailablePaymentMethod = useCallback((paymentMethod?: PaymentMethod) => {
    if (!organizationProperties || !paymentMethod) return false;
    return organizationProperties?.allowedPaymentMethods?.some(
      (paymentMethodType: PaymentMethodType) => paymentMethod.type === paymentMethodType,
    );
  }, [organizationProperties]);

  return {
    organizationProperties,
    checkIsAvailablePaymentMethod,
    ...queryInfo,
  };
};

export default useOrganizationProperties;
