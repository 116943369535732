import { storeCreator } from '@/utils/store/store.utils';

interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalAction: (files?: any) => void;
  claimId?: string;
  resetFileStore: boolean;
  handleSetResetFileStore: (value: boolean) => void;
  handleSetAdditionalAction: (additionalAction: State['additionalAction']) => void;
  handleSetClaimId: (claimId: string) => void;
  handleReset: () => void;
  onCancelScanning: () => void;
  handleSetOnCancelScanning: (onCancel: State['onCancelScanning']) => void;
}

export const useStore = storeCreator<State>((set) => ({
  additionalAction: () => {},
  onCancelScanning: () => {},
  resetFileStore: false,
  claimId: undefined,

  handleSetAdditionalAction: (additionalAction) => set((state) => ({
    ...state,
    additionalAction,
  })),

  handleSetResetFileStore: (value) => set((state) => ({
    ...state,
    resetFileStore: value,
  })),

  handleSetClaimId: (claimId) => set((state) => ({
    ...state,
    claimId,
  })),

  handleSetOnCancelScanning: (onCancelScanning) => set((state) => ({
    ...state,
    onCancelScanning,
  })),

  handleReset: () => set((state) => ({
    ...state,
    resetFileStore: false,
    claimId: undefined,
    additionalAction: () => {},
  })),
}));
