import { storeCreator } from '@/utils/store/store.utils';

interface State {
  displayed: boolean;
  setDisplayed: (value: boolean) => void;
}

const useStore = storeCreator<State>((set) => ({
  displayed: false,
  setDisplayed: (value: boolean) => set(() => ({
    displayed: value,
  })),
}));

export default useStore;
