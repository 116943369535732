import React, { useCallback } from 'react';

import { ModalWrapper } from '@/components/wrappers';

import ViewEmployerTerminationModalForm from './EmployerTerminationModalForms/ViewEmployerTerminationModalForm';
import { useViewEmployerTerminationModalStore } from './store';

export const ViewEmployerTerminationModal = () => {
  const handleChangeVisibility = useViewEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const modalVisible = useViewEmployerTerminationModalStore((state) => state.modalVisible);

  const handleSetVisible = useCallback(() => {
    handleChangeVisibility(false);
  }, [
    handleChangeVisibility,
  ]);

  return (
    <ModalWrapper
      testId="ViewEmployerTerminationModal_ModalWrapper"
      visible={modalVisible}
      onSetVisible={handleSetVisible}
    >
      <ViewEmployerTerminationModalForm />
    </ModalWrapper>
  );
};