import React, { useEffect } from 'react';
import {
  Box,
  FlexControlledForm,
  useDebounceState,
} from '@common-fe/common-fe';

import { InvestmentProfilePayload,useStore } from '../stores/useInvestmentProfile.store';

import { useInvestmentExperienceTypeFields } from './hooks';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const InvestmentExperienceType: React.FC<Props> = ({ onDirty }) => {
  const fields = useInvestmentExperienceTypeFields();
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<InvestmentProfilePayload>>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round" margin={{ bottom: 'l' }} style={{ position: 'relative' }} data-testid="InvestmentExperienceType-wrapper">
      <FlexControlledForm
        fields={fields}
        shouldControlValues
        testId="investment_experience_type_form"
        showError={showErrors}
        editMode
        onChangeValues={setValues}
        onDirty={onDirty}
      />
    </Box>
  );
};
export default InvestmentExperienceType;
