import * as React from 'react';
import { Box, InfoOutlineIcon, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import colors from '@/styles/colors';

const SecurityCardInfoPopupContent = styled(Box)`
  width: 320px;
  max-width: initial;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: calc(100% - 5px);
    left: calc(50% - 5px);
    background-color: ${({ theme }) => theme.colors.legend};
    transform: rotate(-45deg);
    border-radius: 0 0 0 3px;
  } 
`;

const SecurityCardInfoPopupWrapper = styled(Box)`
  position: absolute;
  bottom: 20px;
  right: -150px;
  padding-bottom: 21px;
  z-index: 100;
  display: none;
  max-width: fit-content;
`;

const Wrap = styled(Box)`
  position: relative;
  &:hover {
    ${SecurityCardInfoPopupWrapper} {
      display: block;
    } 
 
    svg {
      fill: ${({ theme }) => theme.colors.button};
    }
  }
`;

interface Props {
  icon: React.ReactNode;
  title: string;
  description: string | React.ReactNode;
}

const SecurityCardInfoPopup: React.FC<Props> = ({ icon, title, description }) => (
  <Wrap>
    
    <InfoOutlineIcon size="24px" color="iconPrimary" />
    <SecurityCardInfoPopupWrapper>
      <SecurityCardInfoPopupContent
        pad="spacing24"
        round="container1Round"
        background="background3"
      >
        <Box>
          <Box margin={{ bottom: 'spacing16' }}>
            <Text
              weight={700}
              size="small"
              color={colors.textOnColor}
              margin={{ bottom: 'spacing8' }}
            >
              {title}
            </Text>
            <Text weight={500} size="small" color={colors.textOnColor}>
              {description}
            </Text>
          </Box>
          <Box direction="row">
            {icon}
          </Box>
        </Box>
      </SecurityCardInfoPopupContent>
    </SecurityCardInfoPopupWrapper>
  </Wrap>
);

export default SecurityCardInfoPopup;
