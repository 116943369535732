import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ContentWrapper, ModalWrapper } from '@/components/wrappers';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import { PaymentMethodType } from '@/modules/user/user.types';

import { usePaymentMethodsCards } from './hooks/usePaymentMethodsCards';
import AddCard from './AddCard';
import ChoosePaymentMethodFooter from './ChoosePaymentMethodFooter';

const Wrapper = styled.span`
  > div:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacings.spacing8};
  }
`;

interface Props {
  onClose: () => void;
  chooseDeposit?: () => void;
  chooseDebitCard?: () => void;
  choosePayPal?: () => void;
  chooseVenmo?: () => void;
  chooseCheck?: () => void;
  removePaymentMethod?: () => void;
  editPaymentMethod?: () => void;
  onlyAddingMode?: boolean;
}

const ChoosePaymentMethod: React.FC<Props> = ({
  onClose,
  chooseDeposit,
  chooseDebitCard,
  choosePayPal,
  chooseVenmo,
  chooseCheck,
  removePaymentMethod,
  editPaymentMethod,
  onlyAddingMode,
}) => {
  const { t } = useTranslation();
  const paymentMethods = useStore((state) => state.methods);
  const { organizationProperties } = useOrganizationProperties();
  const cards = usePaymentMethodsCards({
    onClose,
    chooseDeposit,
    chooseDebitCard,
    choosePayPal,
    chooseVenmo,
    chooseCheck,
    methods: paymentMethods,
    allowedPaymentMethods: organizationProperties?.allowedPaymentMethods || [],
  });

  const availableCardsToAdd = useMemo(() => {
    if (onlyAddingMode) {
      return cards.filter((card) => card.paymentType !== PaymentMethodType.CHECK);
    }

    return cards;
  }, [cards, onlyAddingMode]);

  return (
    <ModalWrapper
      isHoveringSensitive
      visible
      title={t('Choose A Payment Method')}
      onSetVisible={onClose}
    >
      <ContentWrapper>
        <Wrapper>
          {availableCardsToAdd.map((card) => (
            <AddCard
              key={card.paymentType}
              title={card.title}
              paymentType={card.paymentType}
              description={card.description}
              add={card.add}
              addTitle={card.addTitle}
              paymentMethods={card.paymentMethods}
              removePaymentMethod={removePaymentMethod}
              editPaymentMethod={editPaymentMethod}
              onlyAddingMode={onlyAddingMode}
              hasPaymentFreeTimeLabel={false}
            />
          ))}
        </Wrapper>
      </ContentWrapper>

      {!onlyAddingMode && <ChoosePaymentMethodFooter onClose={onClose} />}
    </ModalWrapper>
  );
};

export default ChoosePaymentMethod;
