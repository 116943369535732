import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { formatPaymentMethod } from '@/modules/transaction/components/ReimburseMe/queries/useAddPaymentMethod.query';
import { PaymentMethod,PaymentMethodDtoPayload } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';

export const useGetPaymentMethods = (
  onSuccess?: (paymentMethods: PaymentMethod[]) => void,
  paymentMethodType?: PaymentMethodType,
  enabled?: boolean,
) => {
  const params = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    if (paymentMethodType) {
      queryGenerator.set('payment_method_type', paymentMethodType);
    }

    return queryGenerator;
  }, [paymentMethodType]);
  const {
    data, isSuccess, isLoading, isFetching, refetch,
  } = useQuery(
    PATHS.PAYMENT_METHODS,
    () => api.get<PaymentMethodDtoPayload[]>(
      PATHS.PAYMENT_METHODS,
      { params },
    ),
    {
      enabled: enabled,
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess((response?.data || [])
            .map((paymentMethod) => formatPaymentMethod(paymentMethod)));
        }
      },
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedData = useMemo(
    () => (data?.data || [])
      .map((paymentMethod) => formatPaymentMethod(paymentMethod)),
    [data],
  );

  return {
    isLoading,
    isFetching,
    isSuccess,
    data: data?.data || [],
    formattedData,
    refetch,
  };
};
