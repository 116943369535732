import React, {
  useCallback, useEffect, useMemo,
  useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  Box,
  CrossIcon,
  Enrollment,
  Inscription,
  NavigationRightIcon,
  Preloader,
  PriceInput,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import AppButton from '@/components/controls/AppButton';
import ServerErrorModal from '@/modules/investments/modal/ServerErrorModal';
import EnrollmentItem from '@/modules/transaction/components/EnrollmentItem';
import { ENROLLMENT_ITEM_WIDTH_L } from '@/modules/transaction/components/EnrollmentItem/EnrollmentItem';
import theme from '@/styles/theme';

import AutoInvestmentReminder, { useAutoInvestmentReminder } from '../../AutoInvestment/AutoInvestmentReminder';
import {  useInvestmentsValidation } from '../../hooks';
import { useTradeInvestmentsQuery } from '../../queries';
import {
  Title,
} from '../BuySell.styles';
import DisclosureFooter from '../DisclosureFooter';
import { useFundingAmountStore } from '../stores/useFundingAmount.store';

import ConfirmSellModal from './ConfirmSellModal';

export const PriceControl = styled(PriceInput)`
  ${() => theme.controls.mediumSize};
  min-height: 40px;
`;
interface Props {
  accountId: string;
  onCancel: () => void;
  onNext: () => void;
  currentMyAccount: Enrollment;
  isSwitchMode?: boolean;
  stepLength: number;
  step: number;
  successVisible?: boolean;
}


const FundingAmount: React.FC<Props> = ({
  onCancel,
  onNext,
  currentMyAccount,
  stepLength,
  step,
  accountId,
  isSwitchMode,
  successVisible,
}) => {
  const { t } = useTranslation();
  const { values, handleSetValues } = useFundingAmountStore();
  const { isDisplayedReminder } = useAutoInvestmentReminder();
  const [investingAmount, setInvestingAmount] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [showServerErrorModal, setShowServerErrorModal] = useState(false);
  const [isFundsLoading, setFundsLoading] = useState(false);
  const [succesButton, setSuccesButton] = useState(false);
  

  const formatedMyAccount = useMemo(() => ({
    ...currentMyAccount,
    available:  currentMyAccount?.available,
  }), [currentMyAccount]);
  const availableBalance = useMemo(
    () => {
      return  currentMyAccount?.available - currentMyAccount?.investmentThreshold > 0
        ? currentMyAccount?.available - currentMyAccount?.investmentThreshold : 0;
      
      
    }, [currentMyAccount?.available, currentMyAccount?.investmentThreshold],
  );


  const { isLoading, handleSave } = useTradeInvestmentsQuery({ isSellMode: true});
 
 
  const handleSellInvestments = useCallback(async () => {
    try {
      await handleSave({
        amount: _.toNumber(investingAmount),
      });

      onNext();
      handleSetValues({
        fundingAmount: investingAmount,
      });
    } catch {
      setShowServerErrorModal(true);
    }
  }, [handleSave, handleSetValues, investingAmount, onNext]);

  const { error } = useInvestmentsValidation({
    investingAmount: investingAmount,
    availableToInvest: availableBalance,
    isSellingMode: false,
    minimumInvestmentAmount: currentMyAccount?.minimumInvestmentAmount,
  });
  const handleSubmit = useCallback(async () => {
    setShowErrors(true);
    if (error) {
      return null;
    }
    setFundsLoading(true);

    if (succesButton) {
      return onNext();
    }
    if (!error) {
      setTimeout(() => {
        setSuccesButton(true);
        setTimeout(() => {
          setSuccesButton(false);
          setFundsLoading(false);
          onNext();
          handleSetValues({
            fundingAmount: investingAmount,
          });
        }, 1500);
      }, 2000);
    }
    return {};
  }, [error, succesButton, onNext, handleSetValues, investingAmount]);
  
  useEffect(() => {
    if (values?.fundingAmount) {
      setInvestingAmount(values?.fundingAmount);
    }
  }, [values]);
  const handleMaximumAllowed = useCallback(() => {
    setInvestingAmount(String(availableBalance));
  }, [availableBalance]);

  return (
    <Box direction="column" data-testid="Funding-wrapper-id">

      <ConfirmSellModal
        fundingAmount={investingAmount}
        isLoading={isLoading}
        visible={sellModalVisible}
        onSetVisible={setSellModalVisible}
        onSubmit={handleSellInvestments}
      />
     
      
      <ServerErrorModal
        visible={showServerErrorModal}
        isLoading={isLoading}
        onSetVisible={setShowServerErrorModal}
        onSendRequest={handleSellInvestments}
        style={{zIndex: 10000, position: 'fixed'}}
      />
      <Prompt
        when={!!investingAmount && !successVisible && !isSwitchMode}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Box flex="grow">
        <Box direction="row" align="center" justify="between">
          <Title>
            { t('Invest more Funds')}
          </Title>
        
        </Box>
        <Text size="large" margin={{ bottom: 'spacing24' }}>{
          t('Move money from your cash account to your investment account in a few easy steps.')
        }
        </Text>
        <Box
          direction="row"
          align="center"
          background="module"
          round="moduleRound"
          pad="spacing24"
          margin={{ bottom: 'spacing24' }}
        >
          {
            formatedMyAccount && (
              <EnrollmentItem
                enrollment={formatedMyAccount}
                investingMode
                isDateless
                isHoverCardNumber
                investingToCashMode={false}
                wrapperStyle={{ minWidth: ENROLLMENT_ITEM_WIDTH_L }}
              />
            )
          }
          <Box direction="row" align="center" justify="center" margin={{ horizontal: 'spacing12' }}>
            <NavigationRightIcon />
          </Box>
          <Box
            direction="column"
          >
            <Box
              direction="column"
              pad="l"
              height="248px"
              background="canvas"
              round="container1Round"
              data-testid="invest_more_funds-wrapper"
              border={{ size: 'small', color: 'border2' }}
              elevation="default"
            >
              <Text margin={{ bottom: 'spacing12' }} size="xxlarge">{ t('Invest more Funds')}</Text>
              <Text margin={{ bottom: 'spacing24' }} size="medium">{
                t('We will transfer this amount from your cash account to your investment account as soon as the trade is executed.')
              }
              </Text>
              <Box direction="row" align="center">
                <Box width="280px" margin={{ right: 'spacing24' }} data-testid="Price-control-wrapper-id">
                  <PriceControl
                    name="Funding amount"
                    placeholder={t('Enter $ amount')}
                    onChange={setInvestingAmount}
                    onIconClick={() => setInvestingAmount('')}
                    value={investingAmount}
                    disabled={isLoading || succesButton}
                    rightIcon={(
                      investingAmount !== ''  && (
                        <Box pad={{ left: 'spacing8' }} height="24px" align="center" justify='center' style={{ cursor: 'pointer' }}>
                          <CrossIcon color="iconPrimary" size="12px" />
                        </Box>
                      )
                    )}
                    autoFocus
                  />
                </Box>
                <Inscription
                  onClick={handleMaximumAllowed}
                  cursor="pointer"
                  size="medium"
                  color="textAccent"
                >{t('Maximum Allowed')}
                </Inscription>
              </Box>
            </Box>
            {
              isDisplayedReminder && (
                <AutoInvestmentReminder accountId={accountId} />
              )
            }
          </Box>
        </Box>
        {error && showErrors && (
          <Box align="end" width="100%" margin={{ bottom: 'spacing24' }}>
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>{t(error)}</Text>
          </Box>
        )}
        <Box direction="row" align="center" justify="end">
          <Box direction="row" justify="end" align="center">
            <AppButton
              onClick={() => onCancel()}
              buttonType="secondary"
              width="130px"
              containerStyle={{ marginRight: theme.spacings.s }}
            >
              {t('Cancel')}
            </AppButton>
            <AppButton
              type="submit"
              onClick={() => handleSubmit()}
              successMode={succesButton}
              width="control"
            >
              {
                isLoading || isFundsLoading
                  ? <Preloader color="white" />
                  : `${t('Next')}  (${step}/${stepLength})`
              }
            </AppButton>
          </Box>
        </Box>
        <DisclosureFooter /> 
      </Box>
    </Box>
  );
};

export default FundingAmount;
