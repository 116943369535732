import { ClaimWarningField, OcrWarnings,OcrWarningType } from '../../FileScan/fileScan.types';
import { StateValues } from '../../store/useClaimInfo.store';

export const OcrFieldsWarningErrorsTracker = (
  values: StateValues,
  fieldsWarnings: OcrWarnings,
  setFieldsOcrWarning: (type: OcrWarningType, warning: ClaimWarningField) => void,
  removeFieldsOcrWarning: (type: OcrWarningType, fieldName: string) => void,
) => {
  const fieldNames = fieldsWarnings[OcrWarningType.MISSING].map((warning) => warning.fieldName);

  fieldNames.forEach((fieldName) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((values as any)[fieldName]) {
      removeFieldsOcrWarning(OcrWarningType.MISSING, fieldName);
    } else {
      setFieldsOcrWarning(OcrWarningType.MISSING, {
        fieldName,
        fieldLabel: fieldName,
      });
    }
  });
};
