import { useCallback } from 'react';

import { useStore as useInvestmentProfileStore } from '../../Managed/PortfolioQuestionnaire/InvestmentProfile/stores/useInvestmentProfile.store';
import { useStore as usePersonalInformationStore } from '../../Managed/PortfolioQuestionnaire/PersonalInformation/stores/usePersonalInformation.store';
import { useFundingAmountStore } from '../FundingAmount/stores/useFundingAmount.store';
import { useInvestmentPathStore } from '../InvestmentPath/stores/useInvestmentPath.store';

export default () => {
  const { handleReset: handleResetInvestmentPathStore } = useInvestmentPathStore();
  const { handleReset: handleResetFundingAmountStore } = useFundingAmountStore();
  const handleResetInvestmentProfileStore = useInvestmentProfileStore((state) => state.handleReset);
  const handleResetPersonalInformationStore = usePersonalInformationStore(
    (state) => state.handleReset,
  );

  const handleReset = useCallback(() => {
    handleResetInvestmentPathStore();
    handleResetFundingAmountStore();
    handleResetInvestmentProfileStore();
    handleResetPersonalInformationStore();
  }, [
    handleResetInvestmentPathStore,
    handleResetFundingAmountStore,
    handleResetInvestmentProfileStore,
    handleResetPersonalInformationStore,
  ]);
  return handleReset;
};
