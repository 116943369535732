import { MFAChannel } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  activeChannel?: MFAChannel;
  channels?: MFAChannel[];
  session?: string;
  isRememberMe?: boolean;
  userName?: string;
  setActiveChannel: (channel: MFAChannel) => void;
  setChannels: (channels: MFAChannel[]) => void;
  setSession: (session: string) => void;
  setIsRememberMe: (isRememberMe: boolean) => void;
  setUserName: (userName: string) => void;
  resetStore: () => void;
}

const useMFAStore = storeCreator<State>((set) => ({
  activeChannel: undefined,
  session: undefined,
  isRememberMe: undefined,
  userName: undefined,
  channels: [],

  setActiveChannel: (channel: MFAChannel) => set((state) => ({
    ...state,
    activeChannel: channel,
  })),

  setChannels: (channels: MFAChannel[]) => set((state) => ({
    ...state,
    channels,
  })),

  setSession: (session: string) => set((state) => ({
    ...state,
    session,
  })),

  setIsRememberMe: (isRememberMe: boolean) => set((state) => ({
    ...state,
    isRememberMe,
  })),

  setUserName: (userName: string) => set((state) => ({
    ...state,
    userName,
  })),

  resetStore: () => set((state) => ({
    ...state,
    activeChannel: undefined,
    session: undefined,
    isRememberMe: undefined,
    userName: undefined,
    channels: [],
  })),
}));

export default useMFAStore;
