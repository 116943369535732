import React, { useCallback } from 'react';
import { Box, InfoOutlineIcon, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

import useEmployerTerminationBanner from '@/modules/core/hooks/useEmployerTerminationBanner';
import { useAuthStore } from '@/modules/user/stores';

import { useEmployerTerminationModalStore, useViewEmployerTerminationModalStore } from './store';
import { ViewEmployerTerminationModal } from './ViewEmployerTerminationModal';

const StyledInfoIcon = styled(InfoOutlineIcon)`
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 1 ;
    fill: ${({ theme }) => theme.colors.iconAccent};
  }
`;

export const EmployerTerminationBanner: React.FC = () => {
  const { auth } = useAuthStore();

  const handleChangeVisibility = useViewEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const handleSetEmployerId = useEmployerTerminationModalStore((state) => state.handleSetEmployerId);
  const handleSetTerminationDate = useEmployerTerminationModalStore((state) => state.handleSetTerminationDate);

  const {
    terminationDate,
    bannerHeight,
    showBanner,
    organizationId,
  } = useEmployerTerminationBanner();

  const handleViewEmployerTermination = useCallback(() => {
    handleSetEmployerId(organizationId ? `${organizationId}` : undefined);
    handleChangeVisibility(true);
    if (terminationDate) {
      handleSetTerminationDate(terminationDate);
    }
  }, [
    handleChangeVisibility,
    handleSetEmployerId,
    handleSetTerminationDate,
    organizationId,
    terminationDate,
  ]);

  if (!showBanner || !auth) return null;

  return (
    <>
      <Box
        data-testid="EmployerTerminationBanner__container"
        width="100%"
        background="warningBorder"
        height={{ max: bannerHeight }}
        justify="center"
        align="center"
        pad={{ vertical: 'spacing16'}}
        style={{
          position: 'fixed',
          zIndex: 999,
          top: 0,
        }}
      >
        <Box direction="row" align="center" height={{ max: '24px' }}>
          <Inscription size="large" lineHeight="24px">
            Organization has terminated
          </Inscription>

          <Box
            margin={{ left: 'spacing8' }}
            onClick={handleViewEmployerTermination}
          >
            <StyledInfoIcon size="medium" />
          </Box>
        </Box>
      </Box>
      <ViewEmployerTerminationModal />
    </>
  );
};
