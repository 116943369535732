import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import Funds from './Funds';
import SellInvestmentsWrapper from './SellInvestmentsWrapper';

interface Props {
  accountId: string;
}
const SellInvestments: React.FC<Props> = ({ accountId, }) => {
  const { t } = useTranslation();

  
  return (
    <SellInvestmentsWrapper currentStep={1}>
      <Box direction='column'>
        <Text size="3xl" weight={700}>{t('Sell Investments')}</Text>
        <Text>{t('Sells can take up to 2 to 3 business days to be reflected in your account. Any automatic investments will be paused.')}</Text>

        <Funds accountId={accountId} />
      </Box>
    </SellInvestmentsWrapper>
  );
};
export default SellInvestments;