import { CommunicationChannels, OrganizationTypes } from '@/common/constants';

export enum ClaimPayee {
  Participant = 'PARTICIPANT',
  Provider = 'PROVIDER',
  EmployeeChoice = 'EMPLOYEE_CHOICE',
}

export enum ClaimAutoPayType {
  Auto = 'AUTO',
  Click = 'CLICK',
}

export enum StatementType {
  PAPER_AND_ELECTRONIC = 'PAPER_AND_ELECTRONIC',
  ELECTRONIC_ONLY = 'ELECTRONIC_ONLY',
}

export interface Notification {
  id: string;
  text: string;
  time: string | number;
  additionalText?: string;
  status: string;
  createdAt: Date;
}

export interface GroupedNotification {
  createdAt: string;
  notifications: Notification[];
}

export interface UserEmail {
  id?: number;
  address?: string;
  preferred?: boolean;
  valid_from?: Date;
  valid_to?: Date;
  email_type?: string;
}

export interface Employee {
  id?: string;
  personId?: string;
  organizationPath?: string;
  organizationId?: string;
  hireOn?: string;
  claimAutopayType?: ClaimAutoPayType;
  defaultClaimPayeeType?: ClaimPayee;
  employeeProvidedEmail?: string;
  employerProvidedEmail?: string;
  isElectronicStatementOnly?: boolean;
  isEmployeeEmailPreferred?: boolean;
}

interface UserModelOrganization {
  id?: string;
  type?: OrganizationTypes;
  sessionTimeoutLogout?: number;
  sessionTimeoutWarn?: number;
  name?: string;
  path?: string;
  communicationChannels?: CommunicationChannels[];
}
export interface UserModel {
  id?: string;
  preferredName?: string;
  firstName?: string;
  lastName?: string;
  emails?: UserEmail[];
  email?: UserEmail;
  preferredEmail?: string;
  employee?: Employee;
  role?: string;
  isSsoUser?: boolean;
  permissions?: number[];
  organization?: UserModelOrganization;
  linkedEmployees?: string;
  terminationDate?: string;
}
interface Email {
  address: string;
}

export interface UserModelPayload {
  first_name: string;
  last_name: string;
  email: Email;
  role: string;
  permissions: string[];
}

export interface PersonInfoResponse {
  id: number;
  ssn: string;
  dependents: string[];
  status: string;
  unique_ee_id: number;
  person_id: number;
  hire_on: Date;
  full_name: string;
  gender_type: string;
  birth_on: Date;
  preferred_email: {
    id: number;
    address: string;
    preferred: boolean;
    valid_from: Date;
    valid_to: string;
    email_type: string;
  };
  preferred_address: {
    address: {
      id: number;
      line1: string;
      line2: string;
      city: string;
      state: string;
      zipcode: string;
      country_id: number;
      address_type: string;
    };
    valid_from: Date;
    valid_to: string;
    preferred_mailing: boolean;
    preferred_physical: boolean;
  };
  home_phone_number: string;
  mobile_phone_number: string;
  user_name: string;
  organization: {
    organization_name: string;
    communication_channels: string[];
  };
  communication_configuration: {
    communication_config_channels: string[];
    notification_priority: string;
  };
}

export enum EmployeePhoneNumber {
  Work = 'WORK',
  Home = 'HOME',
  Unkknown = 'UNKNOWN',
  Mobile = 'MOBILE',
}

export enum EmployeeAddressType {
  PO_BOX = 'PO_BOX',
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
  UNKNOWN = 'UNKNOWN',
}

export interface PreferredPhone {
  id?: number;
  number?: string;
  secondary?: boolean;
  preferred?: boolean;
  phoneType?: string;
}

export interface Phone {
  number?: string;
  phoneType?: string;
}

export interface EmployeeCommunicationConfigurationDto {
  communication_config_channels: string[];
  notification_priority: string;
}

export interface EmployeePayload {
  id?: string;
  person_id?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  suffix?: string;

  full_name?: string;
  birth_on?: string;
  ssn?: string;
  employer_provided_email?: string;
  employee_provided_email?: string;
  home_phone_number?: string;
  phone1?: {
    number?: string;
    phone_type?: EmployeePhoneNumber;
  };
  phone2?: {
    number?: string;
    phone_type?: EmployeePhoneNumber;
  };
  mailing_address?: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country_code?: string;
    address_type?: EmployeeAddressType;
  };
  physical_address?: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country_code?: string;
    address_type?: EmployeeAddressType;
  };
  use_same_address_for_mailing?: boolean;
  employer_name?: string;
  is_employee_email_preferred?: boolean;
  partner_employee_id?: string;
  employer_employee_id?: string;
  system_id?: string;
  status?: string;
  hire_on?: string;
  termination_on?: string;
  gender?: string;
  user_name?: string;

  user_communication_configuration?: EmployeeCommunicationConfigurationDto;

  organization_id?: number;
  organization_name?: string;
  organization_path?: string;

  claim_autopay_type?: ClaimAutoPayType;
  default_claim_payee_type?: ClaimPayee;
}

export enum AllowedFileTypes {
  CARRIER = 'CARRIER',
  CENSUS = 'CENSUS',
  CONTRIBUTION = 'CONTRIBUTION',
  ENROLLMENT = 'ENROLLMENT',
}

export enum PaymentMethodType {
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  CHECK = 'CHECK',
  PAYPAL = 'PAYPAL',
  DEBIT = 'DEBIT',
  VENMO = 'VENMO',
}

export enum PersonThemeNamesEnum {
  CLARITY = 'CLARITY',
  ELEVATE = 'ELEVATE',
}

export interface EmployeeAddress {
  id?: number;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryId?: number;
  addressType?: string;
  preferredMailing?: boolean;
  preferredPhysical?: boolean;
}

export interface EmployeeView {
  id?: string;
  firstName?: string;
  hireOn?: string;
  lastName?: string;
  preferredEmail?: string;
  preferredName?: string;
  employeeProvidedEmail?: string;
  employerProvidedEmail?: string;
  citizenshipId?: string;
  claimAutopayType?: ClaimAutoPayType;
  defaultClaimPayeeType?: ClaimPayee;
  terminationDate?: string;
  isElectronicStatementOnly?: boolean;
  isEmployeeEmailPreferred?: boolean;
  preferredPhone?: PreferredPhone;
  phone?: Phone;
  isSsoUser?: boolean;
  isVerifiedEmployeeEmail?: boolean;
  isVerifiedPhoneNumber?: boolean;
  isSmsNotificationEnabled?: boolean;
  addresses?: EmployeeAddress[];
}

export interface OrganizationView {
  id?: string;
  path?: string;
  type?: OrganizationTypes;
  allowedPaymentMethods?: PaymentMethodType[];
  allowEmployeeToEditAddress?: boolean;
  communicationChannels?: CommunicationChannels[];
  customerServiceEmail?: string;
  customerServiceIsEnabled?: boolean;
  customerServiceNumber?: string;
  customerServiceUrlLink?: string;
  customerServiceUrlName?: string;
  isBillPayAllowed?: boolean;
  isReimbursementAllowed?: boolean;
  sessionTimeoutLogout?: number;
  sessionTimeoutWarn?: number;
  faviconUrl?: string;
  largeLogoUrl?: string;
  minimizedLogoUrl?: string;
  darkMinimizedLogoUrl?: string;
  darkLargeLogoUrl?: string;
  statementType?: StatementType;
  terminationDate?: string;
  currentOrgStatus?: string;
}

export interface LinkedEmployee {
  employeeId: string;
  enrollmentTitle: string;
  largeLogoUrl: string;
  organizationId: string;
  organizationName: string;
  organizationPath: string;
  personId: string;
}
