import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@common-fe/common-fe';

import { StepperSidebar } from '@/components';
import { Topbar } from '@/modules/core/components';

interface Props {
  children?: React.ReactNode;
  currentStep: number;
}
const SelfDIrectedInvestMoreWrapper: React.FC<Props> = ({ children, currentStep }) => {
  const { t } = useTranslation();
  const OPTIONS = useMemo(
    () => [
      { key: 1, value: t('Invest More') },
    ],
    [t]
  );
  return (
    <Box direction="row" flex="grow" fill data-testid="StartInvesting-wrapper">
      <StepperSidebar title={t('Invest More')} options={OPTIONS} step={currentStep} />
      <Box
        fill
        style={{
          display: 'block',
          position: 'relative',
        }}
      >
        <Box direction="row" justify="center">
          <Box flex="grow" pad={{ horizontal: '40px' }} margin={{ bottom: 'l' }}>
            <Topbar />
          </Box>
        </Box>
        <Box
          direction="column"
          margin={{ horizontal: 'xl' }}
          pad={{ horizontal: 'xs' }}
          style={{ position: 'relative' }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
export default SelfDIrectedInvestMoreWrapper;
