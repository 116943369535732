import { ActivityType, CardTypesEnum, NotificationStatusColorType } from '@common-fe/common-fe';

import { StockQuoteType } from '@/common/constants';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const checksStatusColors = {
  checkStatusPending: '#F9C134',
  checkStatusIssued: '#176AF6',
  checkStatusCleared: '#19B269',
  checkStatusVoided: '#E72626',
  checkStatusExpired: '#9BA3B3',
  checkStatusReissued: '#E72626',
  checkStatusExpiring: '#F9C134',
  checkStatusReissuing: '#F9C134',
  checkStatusVoiding: '#E72626',
  checkStatusReady: '#19B269',
};

const feedbackErrorColors = {
  danger: whiteLabelingConfig?.colors?.danger || '#E72626',
  dangerBorder: whiteLabelingConfig?.colors?.dangerBorder || '#F8BCBC',
  dangerContainer: whiteLabelingConfig?.colors?.dangerContainer || '#FDE9E9',
  dangerHover: whiteLabelingConfig?.colors?.dangerHover || '#A41B1B',
  dangerActive: whiteLabelingConfig?.colors?.dangerActive || '#66120A',
};

const feedbackSuccessColors = {
  success: whiteLabelingConfig?.colors?.success || '#19B269',
  successBorder: whiteLabelingConfig?.colors?.successBorder || '#B8E7D1',
  successContainer: whiteLabelingConfig?.colors?.successContainer || '#E8F7F0',
};

const feedbackWarningColors = {
  warning: whiteLabelingConfig?.colors?.warning || '#F9C134',
  warningBorder: whiteLabelingConfig?.colors?.warningBorder || '#FDECC0',
  warningContainer: whiteLabelingConfig?.colors?.warningContainer || '#FEF9EB',
};

const feedbackInfoColors = {
  info: whiteLabelingConfig?.colors?.info || '#176AF6',
  infoBorder: whiteLabelingConfig?.colors?.infoBorder || '#B7D1FC',
  infoContainer: whiteLabelingConfig?.colors?.infoContainer || '#E8F0FE',
};

const backgroundColors = {
  accentActive: whiteLabelingConfig?.colors?.accentActive || '#176AF6',
  accentBorder: whiteLabelingConfig?.colors?.accentBorder || '#B7D1FC',
  accentContainer: whiteLabelingConfig?.colors?.accentContainer || '#E8F0FE',
  stressContainer: whiteLabelingConfig?.colors?.stressContainer || '#151F6D',
  background1: whiteLabelingConfig?.colors?.background1 || '#142641',
  background2: whiteLabelingConfig?.colors?.background2 || '#025248',
  background3: whiteLabelingConfig?.colors?.background3 || '#10101D',
  
  border: whiteLabelingConfig?.colors?.border || '#B8BFCC',

  border1: whiteLabelingConfig?.colors?.border1 || '#E5E9F0',
  border2: whiteLabelingConfig?.colors?.border2 || '#EEF1F4',
  module: whiteLabelingConfig?.colors?.module || '#F7F9FA',
  canvas: whiteLabelingConfig?.colors?.canvas || '#FFFFFF',
  popupBg2: '#0F16210D',
  legend: whiteLabelingConfig?.colors?.legend || '#BCD1EF',
};

const investments = {
  fund1: whiteLabelingConfig?.colors?.investmentsFund1 || '#00C3B3',
  fund2: whiteLabelingConfig?.colors?.investmentsFund2 || '#481A54',
  fund3: whiteLabelingConfig?.colors?.investmentsFund3 || '#D83B00',
  fund4: whiteLabelingConfig?.colors?.investmentsFund4 || '#176AF6',
  fund5: whiteLabelingConfig?.colors?.investmentsFund5 || '#FF9D0A',
  fund6: whiteLabelingConfig?.colors?.investmentsFund6 || '#8EB2A1',
  fund7: whiteLabelingConfig?.colors?.investmentsFund7 || '#E72626',
  fund8: whiteLabelingConfig?.colors?.investmentsFund8 || '#9BA3B3',
  fund9: whiteLabelingConfig?.colors?.investmentsFund9 || '#176AF6',
  fund10: whiteLabelingConfig?.colors?.investmentsFund10 || '#00B5EE',
  fund11: whiteLabelingConfig?.colors?.investmentsFund11 || '#CE8258',
  fund12: whiteLabelingConfig?.colors?.investmentsFund12 || '#7909AD',
};
const navigationColors = {
  navigationBackground: whiteLabelingConfig?.colors?.navigationBackground ||  '#142641',
  navigationBorder: whiteLabelingConfig?.colors?.navigationBorder ||  '#2D3C52',
  navigationAccent: whiteLabelingConfig?.colors?.navigationAccent ||  '#3F68FF',
  navigationAccent2: whiteLabelingConfig?.colors?.navigationAccent2 ||  '#00FF93',
  navigationTextDefault: whiteLabelingConfig?.colors?.navigationTextDefault ||  '#FFFFFF',
  navigationTextAccent: whiteLabelingConfig?.colors?.navigationTextAccent ||  '#FFFFFF',
  navigationIconDefault: whiteLabelingConfig?.colors?.navigationIconDefault ||  '#FFFFFF',
  navigationIconAccent: whiteLabelingConfig?.colors?.navigationIconAccent ||  '#FFFFFF',
  navigationAccentHover: whiteLabelingConfig?.colors?.navigationAccentHover ||  '#334766',
  navigationAccentActive: whiteLabelingConfig?.colors?.navigationAccentActive ||  '#213A7A',



};
const hierarchyEntitiesColors = {
  partner: '#D83B00',
  distributor: '#904D27',
  employer: '#174FBC',
  subsidiary: '#0F7970',
  subgroup: '#481A54',
  employee: '#0CAD09',
  dependent: '#5E1696',
  noAssignee: '#B8BFCC',
};

const hierarchyEntitiesShadowColors = {
  employeeShadow: '#C8EACA',
  dependentShadow: '#D9CBE6',
};

const claimColors = {
  nonClaimIcon: whiteLabelingConfig?.colors?.nonClaimIcon || '#151F6D',
  nonClaimBg: whiteLabelingConfig?.colors?.nonClaimBg || '#E5E9F0',
};


const buttonColors = {
  button :whiteLabelingConfig?.colors?.button || '#3F68FF',
  buttonHover :whiteLabelingConfig?.colors?.buttonHover || '#1052C8',
  buttonActive :whiteLabelingConfig?.colors?.buttonActive || '#093999',
  buttonSecondary :whiteLabelingConfig?.colors?.buttonSecondary || '#3F68FF',
  buttonSecondaryContainer :whiteLabelingConfig?.colors?.buttonSecondaryContainer || '#E8F0FE',
  buttonSecondaryActive :whiteLabelingConfig?.colors?.buttonSecondaryActive || '#093999',
};

const baseColors = {
  popup: whiteLabelingConfig?.colors?.popup || 'rgba(15, 22, 33, 0.5)',
  popup2: whiteLabelingConfig?.colors?.popup2 || 'rgba(15, 22, 33, 0.05)',


  button: whiteLabelingConfig?.colors?.button ||  buttonColors.button,
  buttonHover: whiteLabelingConfig?.colors?.buttonHover || buttonColors.buttonHover,
  buttonActive: whiteLabelingConfig?.colors?.buttonActive || buttonColors.buttonActive,

  buttonSecondary: whiteLabelingConfig?.colors?.buttonSecondary || '#151F6D',
  buttonSecondaryContainer: whiteLabelingConfig?.colors?.buttonSecondaryContainer || '#E8F0FE',
  buttonSecondaryActive: whiteLabelingConfig?.colors?.buttonSecondaryActive || '#093999',



  buttonText: whiteLabelingConfig?.colors?.buttonText || '#FFFFFF',
  textTitle: whiteLabelingConfig?.colors?.textTitle || '#0F1621',
  textBody: whiteLabelingConfig?.colors?.textBody || '#0F1621',
  textSecondary: whiteLabelingConfig?.colors?.textSecondary || '#6B7485',
  textDisabled: whiteLabelingConfig?.colors?.textDisabled || '#9BA3B3',
  textOnColor: whiteLabelingConfig?.colors?.textOnColor || '#FFFFFF',
  textAccent: whiteLabelingConfig?.colors?.textAccent || '#176AF6',
  textAccentHover: whiteLabelingConfig?.colors?.textAccentHover || '#1052C8',
  textAccentActive: whiteLabelingConfig?.colors?.textAccentActive || '#093999',
  textShade: whiteLabelingConfig?.colors?.textShade || '#A6A6A6',

  iconPrimary: whiteLabelingConfig?.colors?.iconPrimary || '#0F1621',
  iconSecondary: whiteLabelingConfig?.colors?.iconSecondary || '#B8BFCC',
  iconOnColor: whiteLabelingConfig?.colors?.iconOnColor || '#FFFFFF',
  iconAccent: whiteLabelingConfig?.colors?.iconAccent || '#176AF6',
  iconAccentHover: whiteLabelingConfig?.colors?.iconAccentHover || '#1052C8',
  iconAccentActive: whiteLabelingConfig?.colors?.iconAccentActive || '#093999',

  fade1: whiteLabelingConfig?.colors?.fade1 || 'linear-gradient(360deg, #F7F9FA 0%, rgba(255, 255, 255, 0) 100%)',
  fade2: whiteLabelingConfig?.colors?.fade2 || 'linear-gradient(180deg, #F7F9FA 0%, #EEF1F4 100%)',
};
export default {
  ...whiteLabelingConfig?.colors,
  ...feedbackErrorColors,
  ...feedbackSuccessColors,
  ...feedbackWarningColors,
  ...feedbackInfoColors,
  ...backgroundColors,
  ...claimColors,
  ...checksStatusColors,
  ...investments,
  ...hierarchyEntitiesColors,
  ...hierarchyEntitiesShadowColors,
  ...buttonColors,
  ...baseColors,
  ...navigationColors,
  
  loginTitleColor:  whiteLabelingConfig?.colors?.loginTitleColor  || baseColors.textOnColor,

  navigationBorder:whiteLabelingConfig?.colors?.navigationBorder || navigationColors.navigationBorder,
  
  listHeaderBackgroundColor: whiteLabelingConfig?.colors?.listHeaderBackgroundColor || backgroundColors.legend,
  listHeaderItemColor: whiteLabelingConfig?.colors?.listHeaderItemColor || baseColors.textBody,

  activity: {
    [ActivityType.DENTAL]: whiteLabelingConfig?.colors?.transactionDental || '#176AF6',
    [ActivityType.VISION]: whiteLabelingConfig?.colors?.transactionVision || '#481A54',
    [ActivityType.MEDICAL]: whiteLabelingConfig?.colors?.transactionMedical || '#00C3B3',
    [ActivityType.OTHER]: whiteLabelingConfig?.colors?.transactionOther || '#FF9D0A',
    [ActivityType.PARKING]: whiteLabelingConfig?.colors?.transactionParking || '#323D9B',
    [ActivityType.TRANSIT]: whiteLabelingConfig?.colors?.transactionTransit || '#CE8258',
    [ActivityType.ADOPTION]: whiteLabelingConfig?.colors?.transactionAdoption || '#0F7970',
    [ActivityType.DEPENDENT_CARE]: whiteLabelingConfig?.colors?.transactionDependentCare || '#CC3381',
    [ActivityType.PHARMACY]: whiteLabelingConfig?.colors?.transactionPharmacy || '#D83B00',
    [ActivityType.LIFESTYLE]: whiteLabelingConfig?.colors?.transactionLifestyle || '#D3375C',
    [ActivityType.WELLNESS]: whiteLabelingConfig?.colors?.transactionWellness || '#D3375C',
    [ActivityType.PREMIUM]: whiteLabelingConfig?.colors?.transactionPremium || '#3628D1',
    [ActivityType.TRAVEL]: whiteLabelingConfig?.colors?.transactionTravel || '#F26B31',
    [ActivityType.REFUND]: whiteLabelingConfig?.colors?.refund || '#588C0E',
    [ActivityType.DEFAULT]: whiteLabelingConfig?.colors?.transactionDefault || '#5E697E',
    [ActivityType.CLAIM_TRANSFER_INTERNAL]: whiteLabelingConfig?.colors?.claimTransferInternal || '#E5E9F0',

    [ActivityType.CONTRIBUTIONS]: claimColors.nonClaimBg,
    [ActivityType.PAYROLL]: claimColors.nonClaimBg,
    [ActivityType.ADJUSTMENTS]: claimColors.nonClaimBg,
    [ActivityType.INTEREST]: claimColors.nonClaimBg,
    [ActivityType.TRANSFER]: claimColors.nonClaimBg,
    [ActivityType.INTERNAL_TRANSFER_OF_ASSETS]: claimColors.nonClaimBg,
    [ActivityType.TRANSFER_OF_ASSETS]: claimColors.nonClaimBg,
    [ActivityType.INVESTMENTS]: claimColors.nonClaimBg,
    [ActivityType.BUY_INVESTMENT]: claimColors.nonClaimBg,
    [ActivityType.SELL_INVESTMENT]: claimColors.nonClaimBg,
    [ActivityType.FEES]: claimColors.nonClaimBg,
    [ActivityType.NOTIONAL_FUND]: claimColors.nonClaimBg,
    [ActivityType.NOTIONAL_PAYROLL]: claimColors.nonClaimBg,
    [ActivityType.ROLLOVER]: claimColors.nonClaimBg,
    [ActivityType.HSA_INCOMING_CONTRIBUTION]: claimColors.nonClaimBg,
    [ActivityType.HSA_ROLLOVER]: claimColors.nonClaimBg,
    [ActivityType.CLAIM_REPAYMENT]: claimColors.nonClaimBg,
    [ActivityType.HSA_INCOMING_CONTRIBUTION_REVERSE]: claimColors.nonClaimBg,
    [ActivityType.HSA_ROLLOVER_REVERSE]: claimColors.nonClaimBg,
    [ActivityType.PAYROLL_REVERSE]: claimColors.nonClaimBg,
  },
  cardTypes: {
    [CardTypesEnum.HSA]: whiteLabelingConfig?.colors?.planTypeHSA || '#2EA12B',
    [CardTypesEnum.DCAP]: whiteLabelingConfig?.colors?.planTypeDCAP || '#CC3381',
    [CardTypesEnum.HCFSA]: whiteLabelingConfig?.colors?.planTypeHCFSA || '#FA802A',
    [CardTypesEnum.HRA]: whiteLabelingConfig?.colors?.planTypeHRA || '#134E8F',
    [CardTypesEnum.PARKING]: whiteLabelingConfig?.colors?.planTypeCommuterParking || '#323D9B',
    [CardTypesEnum.TRANSIT]: whiteLabelingConfig?.colors?.planTypeCommuterTransit || '#CE8258',
    [CardTypesEnum.ADOPTION]: whiteLabelingConfig?.colors?.planTypeAdoption || '#0F7970',
    [CardTypesEnum.SPECIALTY]: whiteLabelingConfig?.colors?.planTypeSpecialty || '#00B5EE',
    [CardTypesEnum.LIFESTYLE]: whiteLabelingConfig?.colors?.planTypeLifestyle || '#D3375C',
    [CardTypesEnum.WELLNESS]: whiteLabelingConfig?.colors?.planTypeWellness || '#D3375C',
    [CardTypesEnum.TRAVEL]: whiteLabelingConfig?.colors?.planTypeTravel || '#F26B31',
    [CardTypesEnum.DENTAL]: whiteLabelingConfig?.colors?.planTypeDental || '#176AF6',
    [CardTypesEnum.RX]: whiteLabelingConfig?.colors?.planTypeRx || '#7909AD',
    [CardTypesEnum.MEDICAL]: whiteLabelingConfig?.colors?.planTypeMedical || '#00C3B3',
    [CardTypesEnum.VISION]: whiteLabelingConfig?.colors?.planTypeVision || '#481A54',
  },
  statusColors: {
    lockedNull: whiteLabelingConfig?.colors?.lockedNull || '#000000',
    processingPending: whiteLabelingConfig?.colors?.processingPending || '#F9C134',
    needsHelp: whiteLabelingConfig?.colors?.needsHelp || '#FF9D0A',
    paidActiveComplete: whiteLabelingConfig?.colors?.paidActiveComplete || '#19B269',
    partiallyPaid: whiteLabelingConfig?.colors?.partiallyPaid || '#B9CC29',
    deniedErrorExpired: whiteLabelingConfig?.colors?.deniedErrorExpired || '#E72626',
    uploading: whiteLabelingConfig?.colors?.uploading || '#9BA3B3',
    waitingForFund: whiteLabelingConfig.colors?.waitingForFund || '#8EB2A1',
    uploadedApproved: whiteLabelingConfig?.colors?.uploadedApproved || '#176AF6',
    loa: whiteLabelingConfig?.colors?.loa || '#00B5EE',
    retirement: whiteLabelingConfig?.colors?.retirement || '#CE8258',
    future: whiteLabelingConfig?.colors?.future || '#7909AD',
  },
  statusContainerColor: {
    lockedNullContainer: whiteLabelingConfig.colors?.lockedNullContainer || '#E6E6E6',
    processingPendingContainer: whiteLabelingConfig.colors?.processingPendingContainer || '#FEF9EB',
    needsHelpContainer: whiteLabelingConfig.colors?.needsHelpContainer || '#FFF5E6',
    paidActiveCompleteContainer: whiteLabelingConfig.colors?.paidActiveCompleteContainer || '#E8F7F0',
    partiallyPaidContainer: whiteLabelingConfig.colors?.partiallyPaidContainer || '#F8FAEA',
    deniedErrorExpiredContainer: whiteLabelingConfig.colors?.deniedErrorExpiredContainer || '#FDE9E9',
    uploadingContainer: whiteLabelingConfig.colors?.uploadingContainer || '#F5F6F7',
    waitingForFundContainer: whiteLabelingConfig.colors?.waitingForFundContainer || '#F4F7F6',
    uploadedApprovedContainer: whiteLabelingConfig.colors?.uploadedApprovedContainer || '#E8F0FE',
    loaContainer: whiteLabelingConfig.colors?.loaContainer || '#E6F8FD',
    retirementContainer: whiteLabelingConfig.colors?.retirementContainer || '#FAF3EE',
    futureContainer: whiteLabelingConfig.colors?.futureContainer || '#F2E6F7',
  },
  fileManagerTypes: {
    cencusType: whiteLabelingConfig.colors?.cencusType || '#323D9B',
    enrollmentType: whiteLabelingConfig.colors?.enrollmentType || '#C64B8A',
    contributionType: whiteLabelingConfig.colors?.contributionType || '#0F7970',
    carrierType: whiteLabelingConfig.colors?.carrierType || '#0C95D0',
    mixType: whiteLabelingConfig.colors?.mixType || '#481A54',
    unknownType: whiteLabelingConfig.colors?.unknownType || '#E72626',
  },

  // // File Manager Record Type

  notificationStatusIcon: {
    [NotificationStatusColorType.WARNING]: feedbackWarningColors.warning,
    [NotificationStatusColorType.SUCCESS]: feedbackSuccessColors.success,
    [NotificationStatusColorType.ERROR]: feedbackErrorColors.danger,
    [NotificationStatusColorType.WARNING_LIGHT]: feedbackWarningColors.warningContainer,
    [NotificationStatusColorType.SUCCESS_LIGHT]: feedbackSuccessColors.successContainer,
    [NotificationStatusColorType.ERROR_LIGHT]: feedbackErrorColors.dangerContainer,
    [NotificationStatusColorType.DEFAULT]: backgroundColors.border2,
  },
  stocksIcon: {
    [StockQuoteType.VTSMX]: investments.fund1,
    [StockQuoteType.VRIF]: investments.fund2,
    [StockQuoteType.SPDR]: investments.fund3,
    [StockQuoteType.SCHD]: investments.fund4,
    [StockQuoteType.TSLA]: investments.fund5,
  },
  stocksColors: [
    investments.fund1,
    investments.fund2,
    investments.fund3,
    investments.fund4,
    investments.fund5,
    investments.fund6,
    investments.fund7,
    investments.fund8,
    investments.fund9,
    investments.fund10,
    investments.fund11,
    investments.fund12,
  ],
};
