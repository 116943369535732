import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Option } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import {
  AnswersStatus,
  QuestionnaireField, QuestionnaireFormattedPayload,   QuestionPayloadOption, ResponsePayload,
} from '../cip.constants';

const QUERY_KEY = 'GET_CIP_IDENTIFICATION';
export const getFormattedQuestion = (item:QuestionPayloadOption): QuestionnaireField => ({
  key: item.type,
  label: item.prompt,
  options: item.answer?.map((answer) => ({
    key: answer,
    title: answer,
    value: answer,
  })) as Option[],
});
export const getFormattedQuestionnaireData = (data: ResponsePayload) => {
  const id = _.get(data, 'id_number', '') as number;
  const idScan = _.get(data, 'id_scan', '') as string;
  
  const idScanUrl = _.get(data, 'id_scan_url', '') as string;
  const questions = _.get(data, 'questions.question', []) as QuestionPayloadOption[];
  const formattedOptions: QuestionnaireField[] = questions.map(
    (item) => getFormattedQuestion(item),
  );
  return {
    id,
    idScan,
    idScanUrl,
    questions: formattedOptions,
  } as QuestionnaireFormattedPayload;
};
interface Params {
  enabled?: boolean;
  onError?: () => void;
  onSuccess?: () => void;
}
const ERROR_RESPONSE = 'error';
export default ({ enabled, onError, onSuccess }: Params) => {

  const {
    data, isLoading, isSuccess, refetch,
  } = useQuery([QUERY_KEY],
    () => api.post<ResponsePayload>(PATHS.CIP_IDENTIFICATION), {
      enabled,
      onError: (e) => {
        if (onError) {
          onError();
        }
      },
    });
  const handleRefetch = useCallback(async () => {
    const response = await refetch();
    if (response?.status === ERROR_RESPONSE) {
      throw new Error();
    }
    const summaryMessage = _.lowerCase(_.get(response, 'data.data.summary_result.message')) as string;
    const resultStatus = _.lowerCase(_.get(response, 'data.data.idology_status', '')) || _.lowerCase(_.get(response, 'data.data.status', ''));  

    if (summaryMessage === AnswersStatus.Pass || resultStatus === AnswersStatus.Pass) {
      if (onSuccess) {
        onSuccess();
        return null;
      }
    }
    return getFormattedQuestionnaireData(_.get(response, 'data.data', {}) as ResponsePayload);
  }, [onSuccess, refetch]);
  const formattedData = useMemo(
    () => getFormattedQuestionnaireData(_.get(data, 'data', {}) as ResponsePayload),
    [data],
  );
  return {
    handleRefetch,
    isSuccess,
    isLoading,
    data: formattedData,
  };
};
