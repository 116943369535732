import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useCurrentOrganisationStore } from '@/modules/core/store';

export const ORGANIZATION_QUERY_KEY = 'organization_id';
export const QUERY_KEY = '?';

export default () => {
  const { organistaionId, handleSetOrganistaionId } = useCurrentOrganisationStore();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const organizationIdQuery = useMemo(() => query.get(ORGANIZATION_QUERY_KEY), [query]);
  useEffect(() => {
    handleSetOrganistaionId(organizationIdQuery);
  }, [handleSetOrganistaionId, organizationIdQuery]);

  const push = useCallback((path: string) => {
    let currentPath = path;
    if (!currentPath.includes(ORGANIZATION_QUERY_KEY)) {
      if (organistaionId) {
        if (currentPath.includes(QUERY_KEY)) {
          currentPath.replace(QUERY_KEY, `${QUERY_KEY}${ORGANIZATION_QUERY_KEY}=${organistaionId}`);
        } else {
          currentPath = `${currentPath}${QUERY_KEY}${ORGANIZATION_QUERY_KEY}=${organistaionId}`;
        }
      }
    }

    history.push(currentPath);
  }, [history, organistaionId]);
  return {
    ...history,
    push,

  };
};
