import { useCallback } from 'react';

import useCreateDocumentByOneTimeLink from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/queries/useCreateDocumentByOneTimeLink.query';
import useGetScannedDocumentByHashIdQuery from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/queries/useGetScannedDocumentByHashId.query';
import useImportFileByLinkQuery, { UploadedFilePayload } from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/queries/useImportFileByLink.query';
import useOneTimeLinkStore from '@/modules/transaction/components/TransactionDetails/OneTimeLinkUpload/store/useOneTimeLink.store';

const useUploadFileByLink = () => {
  const oneTimeLink = useOneTimeLinkStore((state) => state.state);
  const { createDocumentByLink } = useCreateDocumentByOneTimeLink();
  const { importFileByLink } = useImportFileByLinkQuery();
  const { getScannedDocument } = useGetScannedDocumentByHashIdQuery();

  const handleCreateDocument = useCallback(async () => {
    const createdDocument = await createDocumentByLink({ 
      employeeId: oneTimeLink.employeeId || '',
      hashId: oneTimeLink.hashId || '',
      organizationId: oneTimeLink.organizationId || '',
      organizationPath: oneTimeLink.organizationPath || '',
    });
    return createdDocument;
  }, [oneTimeLink, createDocumentByLink]);

  const handleImportFile = useCallback(async (documentId: string, formData: FormData) => {
    const importedFile = await importFileByLink({ 
      documentId,
      formData,
      employeeId: oneTimeLink.employeeId || '',
      hashId: oneTimeLink.hashId || '',
    });
    return importedFile;
  }, [oneTimeLink, importFileByLink]);

  const handleUploadFile = useCallback(async (formData: FormData) => {
    if (oneTimeLink.documentId) {
      const importedFile = await handleImportFile(oneTimeLink.documentId, formData);
      return { ...importedFile, documentId: oneTimeLink.documentId };
    } else {
      const createdDocument = await handleCreateDocument();
      const documentId = `${createdDocument?.document_id}`;
      const importedFile = await handleImportFile(documentId, formData);
      return { ...importedFile, documentId };
    }
  }, [handleImportFile, handleCreateDocument, oneTimeLink]);

  const handleScanFile = useCallback(async (uploadedFile: UploadedFilePayload, hashId: string) => {
    if (!uploadedFile?.id) {
      return;
    }
    const scannedFile = await getScannedDocument(`${uploadedFile.id}`, hashId);
    return scannedFile;
  }, [getScannedDocument]);


  return {
    handleUploadFile,
    handleScanFile,
  };
};

export default useUploadFileByLink;
