import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { SuccessModal } from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import AddPatientForm from '@/modules/transaction/components/ReimburseMe/ClaimInfo/AddPatienForm/AddPatientForm';
import { QUERY_KEY } from '@/modules/user/components/ManageProfile/components/Dependents/queries/useDependents.query';
import useHighlightedDependentStore from '@/modules/user/components/ManageProfile/components/Dependents/stores/useHighlightedDependent.store';
import useHighlightById from '@/utils/hooks/useHighlightById';

enum Step {
  ADD_DEPENDENT = 'ADD_DEPENDENT',
  SUCCESSFULLY_ADDED = 'SUCCESSFULLY_ADDED',
}

interface Props {
  onSetVisible: (value: boolean) => void;
}

const AddDependentModal: React.FC<Props> = ({ onSetVisible }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [step, setStep] = useState(Step.ADD_DEPENDENT);
  const { reset, setHighlightedDependentId } = useHighlightedDependentStore();
  const { setHighlightedById } = useHighlightById({ onSetHighlightedId: setHighlightedDependentId, reset });

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const onSuccess = useCallback((dependentId?: string) => {
    queryClient.invalidateQueries(QUERY_KEY);
    setStep(Step.SUCCESSFULLY_ADDED);
    if (dependentId) {
      setHighlightedById(dependentId);
    }
  }, [queryClient, setHighlightedById]);

  return (
    <>
      {
        step === Step.ADD_DEPENDENT && (
          <ModalWrapper
            visible
            title={t('Add a Dependent')}
            onSetVisible={onSetVisible}
            nonOverflow
            testId='AddDependentModal'
          >
            <AddPatientForm 
              onCancel={handleClose}
              onSuccess={onSuccess}
              submitButtonText='Add Dependent'
            />
          </ModalWrapper>
        )
      }
      {step === Step.SUCCESSFULLY_ADDED && (
        <SuccessModal
          visible
          header={t('New dependent was successfully added!')}
          buttonText={t('Got It!')}
          onSetVisible={handleClose}
          buttonStyle={{ width: '200px' }}
        />
      )}
    </>
  );
};

export default AddDependentModal;
