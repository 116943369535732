import create from 'zustand';
// import { useState } from 'react';

interface State {
  applied: boolean;
  setApplied: (value: boolean) => void;
}
const useStore = create<State>((set) => ({
  applied: false,
  setApplied: (value: boolean) => set(() => ({
    applied: value,
  })),
}));
export default () => {
  const applied = useStore((state) => state.applied);
  const setApplied = useStore((state) => state.setApplied);
  return {
    applied,
    setApplied,
  };
};
