import React from 'react';
import {
  Anchor, Box, LinkIcon,
  Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

export interface DocumentItemType {
  title: string;
  date?: string;
  url: string;
}
const StyledText = styled(Text)``;
const StyledLinkIcon = styled(LinkIcon)``;
const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  border-radius: ${({ theme }) => theme.rounds.fieldRound};
  ${({ theme }) => theme.shadows.default};
  ${StyledLinkIcon} {
    display: none;
  }
  &:hover {
    text-decoration: none;
    ${StyledLinkIcon} {
      display: block;
    }
    ${StyledText} {

      color: ${({ theme }) => theme.colors.accentActive};
    };
  }


`;

const DocumentItem: React.FC<DocumentItemType> = ({ title, date, url }) => (
  <Box margin={{ bottom: 'xs' }}>
    <StyledAnchor href={url || ''} target="_blank">
      <Box
        direction="row"
        round="fieldRound"
        elevation="default"
        justify="between"
        background="canvas"
        pad={{ horizontal: 'l', vertical: 'm' }}
      >
        <Box direction="row" align="center">
          <StyledText>
            {title}
          </StyledText>
          <Box margin={{ left: 's' }}>
            <StyledLinkIcon size="16px" color="accentActive" />
          </Box>
        </Box>
        <Box>
          <Text size="medium" weight={400}>
            {date}
          </Text>
        </Box>
      </Box>
    </StyledAnchor>
  </Box>
);
export default DocumentItem;
