import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, GoPaperlessIcon, StatementsDocumentIcon } from '@common-fe/common-fe';

import { Banner } from '@/modules/core/components/Dashboard/components/BannersList/Banner';
import ElectronicAgreementCheckbox from '@/modules/transaction/components/Statements/components/ElectronicAgreement/ElectronicAgreementCheckbox';
import useCommunicationSettingsStore from '@/modules/user/components/SignUp/CommunicationSettings/useCommunicationSettings.store';

import BlockWrapper from '../BlockWrapper';

const CommunicationSettings = () => {
  const { t } = useTranslation();
  const {
    isElectronicStatementOnly, setIsElectronicStatementOnly,
  } = useCommunicationSettingsStore();

  const handleCheck = useCallback(
    () => setIsElectronicStatementOnly(!isElectronicStatementOnly),
    [isElectronicStatementOnly, setIsElectronicStatementOnly],
  );

  return (
    <BlockWrapper
      Icon={StatementsDocumentIcon}
      title="Statements"
    >
      <Banner
        title={t('Go Paperless')}
        description={t('Enjoy the convenience of paperless transactions and reduce your environmental impact.')}
        icon={<GoPaperlessIcon />}
      >
        <Box margin={{ top: 'spacing16' }}>
          <ElectronicAgreementCheckbox
            isChecked={isElectronicStatementOnly}
            onChecked={handleCheck}
            fontSize="medium"
          />
        </Box>
      </Banner>
    </BlockWrapper>
  );
};

export default CommunicationSettings;
