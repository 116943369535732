import React, { useMemo } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DATE_TOOLTIP_FORMAT } from '@/common/constants';
import { Notification, PaymentType,Service,TransactionApprovalCode } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import text from '../AppealText';

const list = [
  'Amount',
  'Date of Service',
  'Service description',
  'Who the service was for',
  'Name of the provider and/or merchant',
];

interface Props {
  data?: Notification;
  employeeFirstName?: string;
  paymentType?: PaymentType;
  service?: Service;
  serviceOn?: string;
}

const useGetNeedHelpsNotificationMessage = (props: Props) => {
  const { data, employeeFirstName, paymentType, service, serviceOn } = props;
  const paymentTypeLabel = useMemo(() => paymentType?.replace('_', ' ')?.toLowerCase(), [paymentType]);

  return useMemo(() => {
    switch (data?.approvalCode) {
    case TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES:
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. The IRS does not allow {paymentTypeLabel} for this service.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>If you feel this is incorrect please provide a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES:
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. Your plan does not allow {paymentTypeLabel} for this service.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>If you feel this is incorrect please provide a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD:
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. It looks like the date for the expense you submitted was outside the coverage period for your plan.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>If you feel this is incorrect please provide a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list,
        isUploadDocument: true,
      };
    // case TransactionApprovalCode.REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT:
    //   return {
    //     mainText: `Hi ${employeeFirstName}.`,
    //     text: '',
    //   }
    case TransactionApprovalCode.CLAIM_RECEIVED_AFTER_RUNOUT_DATE:
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. Unfortunately we received your request after the end of the allowable period to submit expenses.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>Please provide a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.DETAILED_RECEIPT_REQUIRED:
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. We'll need some more details on your end to process your request for {paymentTypeLabel}.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>Please provide a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.PROOF_OF_EXPENSE_NEEDED:
      return {
        mainText: `Hi ${employeeFirstName}. We'll need some more details on your end to process your request for ${paymentTypeLabel}. Please provide a detailed receipt or document with the following information:`,
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION:
      return {
        mainText: `Hi ${employeeFirstName}. We'll need some more details on your end to process your request for ${paymentTypeLabel}. Please provide a detailed receipt or document with the following information:`,
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.CLAIM_COULD_NOT_BE_AUTO_SUBSTANTIATED_BY_CARD_PROCESSOR:
      return {
        mainText: `Hi ${employeeFirstName}. We'll need some more details on your end to process your request for ${paymentTypeLabel}. Please provide a detailed receipt or document with the following information:`,
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.LETTER_OF_MEDICAL_NECESSITY_REQUIRED:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we were missing a letter of medical necessity from your physician, so we were only able to approve part of the amount requested.`,
        text,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.CLAIM_SUBMITTED_AFTER_GRACE_PERIOD:
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. It looks like the date of service provided is outside of the plan's coverage period.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>Please provide a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.DUPLICATE_CLAIM:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we already received and processed a ${paymentTypeLabel} for ${service?.name} provided on ${dayjs(serviceOn).format(DATE_TOOLTIP_FORMAT)}.`,
        isUploadDocument: true,
      };
    case TransactionApprovalCode.CUSTOM:
      return {
        text: data.customDenialReason,
        isUploadDocument: true,
      };
    default:
      return {};
    }
  }, [data, employeeFirstName, paymentTypeLabel, service, serviceOn]);
};

export default useGetNeedHelpsNotificationMessage;
