import { baseStoreCreator } from '@/utils/store';

export interface State {
  qrId: string | null;
  employeeId: string | null;
  validTo: string | null;
  docId: string | null;
}

export const DEFAULT_VALUES: State = {
  qrId: '',
  employeeId: '',
  validTo: '',
  docId: '',
};

export const useUploadFileByQrLinkStore = baseStoreCreator<State>(
  DEFAULT_VALUES,
);

export default useUploadFileByQrLinkStore;
