import {
  OcrWarnings,
  OcrWarningType,
} from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';
import { storeCreator } from '@/utils/store/store.utils';

const defaultOcrWarnings: OcrWarnings = {
  [OcrWarningType.MISSING]: [],
  [OcrWarningType.OUT_OF_COVERAGE_DATE]: [],
  [OcrWarningType.RETAIL_OUT_OF_COVERAGE_DATE]: [],
  [OcrWarningType.DUPLICATE_RECEIPT]: [],
};

interface State {
  ocrWarnings: OcrWarnings;
  handleSetOcrWarnings: (warnings?: OcrWarnings) => void;
  handleResetOcrWarnings: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  ocrWarnings: defaultOcrWarnings,

  handleSetOcrWarnings: (warnings) => {
    set({ ocrWarnings: warnings || defaultOcrWarnings });
  },

  handleResetOcrWarnings: () => {
    set({ ocrWarnings: defaultOcrWarnings });
  },
}));
