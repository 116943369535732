import { useMemo } from 'react';

import { CommunicationChannels } from '@/common/constants';
import { useAuthStore } from '@/modules/user/stores';

const useAllowNotifications = (): boolean => {
  const { user } = useAuthStore();
  return useMemo(() => !!user?.organization?.communicationChannels
    ?.some((item: string) => item === CommunicationChannels.NOTIFICATION_CENTER), [user]);
};

export default useAllowNotifications;
