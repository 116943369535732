import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ValuesMap } from '@/common/types';

interface Response {
  id_summary_result: string;

}

export default () => {
  const { mutateAsync, isLoading } = useMutation(
    (
      data: {
        values: ValuesMap<string | number>,
        isChallengeMode?: boolean,
      },

    ) => api.post<Response>(data?.isChallengeMode
      ? PATHS.CIP_CHALLENGE_ANSWERS : PATHS.CIP_QUESTIONS_ANSWERS, data.values),
  );

  return {
    save: mutateAsync,
    isLoading,
  };
};
