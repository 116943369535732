import _ from 'lodash';

const getCookie = (name: string) => {
  const nameEQ = name + '=';
  const cookies = document.cookie.split(';').map((cookie) => _.trim(cookie));
  const cookie = _.find(cookies, (cookie) => _.startsWith(cookie, nameEQ));
  return cookie ? cookie.substring(nameEQ.length) : null;
};

export default getCookie;
