import React, {
  useEffect, useMemo, useRef,
  useState, } from 'react';
import {
  Box, Preloader, RotateIcon,
  Zoomer, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { FileType } from '@/common/constants';
import currentTheme from '@/styles/theme';

const ZoomerWrapper = styled(Box)`
  position: relative;

  img {
    border-radius: 12px;
  }
`;

const RotateIconsWrapper = styled(Box)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacings.spacing6};
  right: ${({ theme }) => theme.spacings.spacing6};

  svg {
    cursor: pointer;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.spacings.spacing4};
    border-radius: ${({ theme }) => theme.rounds.checkboxRound};
    ${({ theme }) => theme.border.blueGray2};
    ${({ theme }) => theme.shadows.default};
    background: ${({ theme }) => theme.colors.canvas};
    width:  ${({ theme }) => theme.spacings.spacing32};
    height:  ${({ theme }) => theme.spacings.spacing32};
    fill: black;
    &:first-child {
      transform: scale(-1, 1);
      margin-right: ${({ theme }) => theme.spacings.spacing4};
    }
  }
`;

interface ImageSize {
  width: number;
  height: number;
}

const DEFAULT_ZOOM_WIDTH = 609;
const INIT_IMAGE_SIZE_STATE = { width: 0, height: 0 };
const WIDTH = 318;
const HEIGHT = 480;
const ASPECT_RATIO = WIDTH / HEIGHT;
const MIN_HEIGHT = DEFAULT_ZOOM_WIDTH / ASPECT_RATIO;
const INIT_STATE_ROTATIONS = 0;
const MAX_STATE_ROTATIONS = 270;
const STATE_ROTATIONS_SPET = 90;
const getCanvasSize = (size: ImageSize, isImageInclined: boolean) => {
  let width = 0;
  let height = 0;

  if (!size.width || !size.height) {
    return size;
  }

  const rotatedSize = isImageInclined ? {
    width: size.height,
    height: size.width,
  } : { ...size };

  if (rotatedSize.width >= rotatedSize.height) {
    width = rotatedSize.width;
    height = rotatedSize.width / ASPECT_RATIO;
  }

  if (rotatedSize.width < rotatedSize.height) {
    width = rotatedSize.height * ASPECT_RATIO;
    height = rotatedSize.height;
  }

  if (width < DEFAULT_ZOOM_WIDTH) {
    width = DEFAULT_ZOOM_WIDTH;
  }

  if (height < MIN_HEIGHT) {
    height = MIN_HEIGHT;
  }

  if (width < size.width) {
    height += (size.width - width) / ASPECT_RATIO;
    width = size.width;
  }

  return { width, height };
};

interface File {
  id?: string;
  path: string;
  preview: string;
  name: string;
  type: string;
}

export interface ZoomerProps {
  zoomWidth?: number;
  zoomPosition?: 'top' | 'left' | 'bottom' | 'right';
}

interface Props {
  file: File;
  zoomerProps?: ZoomerProps;
}

const ImageZoomer: React.FC<Props> = ({ file, zoomerProps }) => {

  const [url, setUrl] = useState('');
  const [rotatedUrl, setRotatedUrl] = useState('');
  const [rotations, setRotations] = useState(INIT_STATE_ROTATIONS);
  const isImageInclined = useMemo(
    () => rotations === STATE_ROTATIONS_SPET
      || rotations === MAX_STATE_ROTATIONS, [rotations],
  );
  const canvasRefWidthCorrectAspectRatio = useRef(null);
  const canvasRefWidthRotations = useRef(null);
  const [naturalImageSize, setNaturalImageSize] = useState<ImageSize>(INIT_IMAGE_SIZE_STATE);
  const canvasWithRotationsSize = useMemo(() => {
    if (isImageInclined) {
      return {
        width: naturalImageSize.height,
        height: naturalImageSize.width,
      };
    }

    return naturalImageSize;
  }, [naturalImageSize, isImageInclined]);
  const isPDF = useMemo(() => file.type === FileType.PDF, [file.type]);

  useEffect(() => {
    setRotatedUrl('');
    setRotations(INIT_STATE_ROTATIONS);
    setUrl('');

    const image = new Image();

    image.addEventListener('load', () => {
      setNaturalImageSize({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    }, false);

    image.src = file.preview;
  }, [file.preview]);

  useEffect(() => {
    if (rotatedUrl) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const canvasWidthCorrectAspectRatio = canvasRefWidthCorrectAspectRatio.current as any;
      const image = new Image();

      image.addEventListener('load', () => {
        if (canvasWidthCorrectAspectRatio) {
          const context = canvasWidthCorrectAspectRatio.getContext('2d');
          const offsetX = image.naturalWidth < image.naturalHeight
            ? context.canvas.width / 2 - image.naturalWidth / 2
            : 0;
          const offsetY = image.naturalWidth >= image.naturalHeight
            ? context.canvas.height / 2 - image.naturalHeight / 2
            : 0;

          context.fillStyle = '#fff';
          context.fillRect(0, 0, context.canvas.width, context.canvas.height);

          context.drawImage(image, offsetX, offsetY, image.naturalWidth, image.naturalHeight);
          canvasWidthCorrectAspectRatio.toBlob((blob: Blob) => {
            setUrl(URL.createObjectURL(blob));
          });
        }
      }, false);

      image.src = rotatedUrl;
    }
  }, [rotatedUrl, canvasRefWidthCorrectAspectRatio]);

  useEffect(() => {
    if (!rotatedUrl && naturalImageSize.width) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const canvas = canvasRefWidthRotations.current as any;
      const image = new Image();

      image.addEventListener('load', () => {
        if (canvas) {
          const context = canvas.getContext('2d');

          if (rotations === 0) {
            context.translate(canvas.width, canvas.height);
            context.drawImage(image, -image.width, -image.height);
          }

          if (rotations === 90) {
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(Math.PI / 2);
            context.drawImage(image, -image.width / 2, -image.height / 2);
          }

          if (rotations === 180) {
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(Math.PI / 1);
            context.drawImage(image, -image.width / 2, -image.height / 2);
          }

          if (rotations === 270) {
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(Math.PI / -2);
            context.drawImage(image, -image.width / 2, -image.height / 2);
          }

          canvas.toBlob((blob: Blob) => {
            setRotatedUrl(URL.createObjectURL(blob));
          });
        }
      }, false);

      image.src = file.preview;
    }
  }, [file, canvasRefWidthRotations, rotations, rotatedUrl, naturalImageSize]);

  return (
    <Box pad="spacing2" background="canvas" round="container1Round" margin={{ bottom: 'spacing12' }}>
      <Box background="canvas" round="container1Round" border={{ size: 'small', color: 'border1' }}>
        {isPDF && (
          <Box height={`${HEIGHT}px`} width={`${WIDTH}px`}>
            <embed src={file.preview} height="100%" width="100%" style={{ borderRadius: '12px' }} />
          </Box>
        )}

        {(!url && !isPDF) && (
          <Box justify="center" height={`${HEIGHT}px`} width={`${WIDTH}px`}>
            <Preloader color={currentTheme.colors.iconSecondary} />
          </Box>
        )}

        {(url && !isPDF) && (
          <>
            <ZoomerWrapper>
              <Zoomer key={file.id}
                {
                  ...{
                    width: WIDTH,
                    height: HEIGHT,
                    zoomWidth: DEFAULT_ZOOM_WIDTH,
                    offset: { horizontal: 12 },
                    img: url,
                    ...zoomerProps,
                  }
                }
              />

              <RotateIconsWrapper direction="row">
                <RotateIcon
                  data-testid="rotate-left"
                  onClick={() => {
                    setRotatedUrl('');
                    setRotations(rotations === INIT_STATE_ROTATIONS
                      ? MAX_STATE_ROTATIONS : rotations - STATE_ROTATIONS_SPET);
                  }}
                />
                <RotateIcon
                  data-testid="rotate-right"
                  onClick={() => {
                    setRotatedUrl('');
                    setRotations(rotations === MAX_STATE_ROTATIONS
                      ? INIT_STATE_ROTATIONS : rotations + STATE_ROTATIONS_SPET);
                  }}
                />
              </RotateIconsWrapper>
            </ZoomerWrapper>
          </>
        )}

        <canvas
          ref={canvasRefWidthCorrectAspectRatio}
          style={{ position: 'fixed', display: 'none' }}
          {...(getCanvasSize(naturalImageSize, isImageInclined))}
        />

        <canvas
          ref={canvasRefWidthRotations}
          style={{ position: 'fixed', display: 'none' }}
          {...canvasWithRotationsSize}
        />
      </Box>
    </Box>
  );
};

export default ImageZoomer;