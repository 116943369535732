import styled from 'styled-components';

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.text32Bold};
  margin-bottom: ${({ theme }) => theme.spacings.spacing12};
  color: ${({ theme }) => theme.colors.blockTitle || ''};
`;

export const FormLink = styled.a`
  color: ${({ theme }) => theme.colors.textAccent};
`;

export const GrayLine = styled.hr`
  background-color: ${({ theme }) => theme.colors.border2};
  height: 1px;
  width: 100%;
  border: none;
  margin-top: ${({ theme }) => theme.spacings.spacing24};
  margin-bottom: ${({ theme }) => theme.spacings.spacing16};
`;
