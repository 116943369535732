import dayjs from 'dayjs';
import { toString } from 'lodash';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { DefaultValues } from '@/common/types';

import { EmployeeView, OrganizationView } from '../user.types';

import { EmployeeViewPayload, OrganizationViewPayload } from './payloadTypes';

const getEntrie = (entrie: DefaultValues): DefaultValues => {
  const [key, value] = Object.entries(entrie).flat();

  if (value || typeof value === 'boolean' || typeof value === 'number') {
    return { [key]: value };
  }

  return {};
};

export const formatOrganizationViewData = (data?: OrganizationViewPayload): OrganizationView => ({
  ...getEntrie({ id: toString(data?.organization_id) }),
  ...getEntrie({ path: data?.organization_path }),
  ...getEntrie({ type: data?.organization_type }),
  ...getEntrie({ allowedPaymentMethods: data?.properties_view?.allowed_payment_methods }),
  ...getEntrie({ communicationChannels: data?.properties_view?.communication_channels }),
  ...getEntrie({ customerServiceEmail: data?.properties_view?.customer_service_email }),
  ...getEntrie({ customerServiceIsEnabled: data?.properties_view?.customer_service_is_enabled }),
  ...getEntrie({ customerServiceNumber: data?.properties_view?.customer_service_number }),
  ...getEntrie({ customerServiceUrlLink: data?.properties_view?.customer_service_url_link }),
  ...getEntrie({ customerServiceUrlName: data?.properties_view?.customer_service_url_name }),
  ...getEntrie({ isBillPayAllowed: data?.properties_view?.is_bill_pay_allowed }),
  ...getEntrie({ isReimbursementAllowed: data?.properties_view?.is_reimbursement_allowed }),
  ...getEntrie({ sessionTimeoutLogout: data?.properties_view?.session_timeout_logout }),
  ...getEntrie({ sessionTimeoutWarn: data?.properties_view?.session_timeout_warn }),
  ...getEntrie({ faviconUrl: data?.properties_view?.logo_info?.favicon_url }),
  ...getEntrie({ largeLogoUrl: data?.properties_view?.logo_info?.large_logo_url }),
  ...getEntrie({ darkMinimizedLogoUrl: data?.properties_view?.logo_info?.dark_minimized_logo_url }),
  ...getEntrie({ darkLargeLogoUrl: data?.properties_view?.logo_info?.dark_large_logo_url }),
  ...getEntrie({ minimizedLogoUrl: data?.properties_view?.logo_info?.minimized_logo_url }),
  ...getEntrie({ statementType: data?.properties_view?.statement_type }),
  ...getEntrie({ terminationDate: data?.termination_date }),
  ...getEntrie({ currentOrgStatus: data?.current_org_status }),
  ...getEntrie({ allowEmployeeToEditAddress: data?.properties_view?.allow_employees_to_edit_address }),
});

export const formatEmployeeViewData = (data?: EmployeeViewPayload, id?: string): EmployeeView => ({
  ...getEntrie({ id }),
  ...getEntrie({ firstName: data?.first_name }),
  ...getEntrie({ hireOn: data?.hire_on }),
  ...getEntrie({ lastName: data?.last_name }),
  ...getEntrie({ preferredEmail: data?.preferred_email }),
  ...getEntrie({ preferredName: data?.preferred_name }),
  ...getEntrie({ employeeProvidedEmail: data?.employee_provided_email }),
  ...getEntrie({ employerProvidedEmail: data?.employer_provided_email }),
  ...getEntrie({ claimAutopayType: data?.claim_autopay_type }),
  ...getEntrie({ defaultClaimPayeeType: data?.default_claim_payee_type }),
  ...getEntrie({ isElectronicStatementOnly: data?.is_electronic_statement_only }),
  ...getEntrie({ isEmployeeEmailPreferred: data?.is_employee_email_preferred }),
  ...getEntrie({ preferredPhone: data?.preferred_phone?.number }),
  ...getEntrie({ citizenshipId: data?.citizenship_country_id }),
  ...getEntrie({ isSsoUser: data?.is_sso_user }),

  ...getEntrie({
    terminationDate: data?.termination_date
      ? dayjs(data.termination_date).format(DEFAULT_DATE_FORMAT)
      : undefined,
  }),
});
