import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface CreateContributionRequest {
  payment_method_id?: string;
  tax_year?: string;
  employee_contribution_amount?: string;
  rollover?: boolean;
  plan_code?: string;
}

export interface CreateContributionResponse {
  employee_contribution_amount: number;
  hold_days: number;
}

export const useAddContributionQuery = () => {
  const { mutateAsync, isSuccess, isLoading } = useMutation(
    (value: CreateContributionRequest) => api.post(
      PATHS.CREATE_CONTRIBUTION,
      value,
    ),
  );

  const save = useCallback(async (value: CreateContributionRequest) => {
    const { data } = await mutateAsync(value);

    return data as CreateContributionResponse;
  }, [mutateAsync]);

  return { isLoading, save, isSuccess };
};
