import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, PlusRoundIcon, RawButton, Text } from '@common-fe/common-fe';

import AddMoreFundsContent from './AddMoreFundsContent';

interface Props {
  accountId: string;
  excludeIds: string[];
}

const AddMoreFunds: React.FC<Props> = ({ accountId, excludeIds }) => {
  const [isDisplayed, setDisplayed] = useState(false);
  const { t } = useTranslation();
  // return (
  //   <Box style={{position: 'relative'}}>

  //     <Text>{t('Add Fund')}</Text>
  //     <PlusRoundIcon />

  //     {isDisplayed && (
  //       <Box>
  //       </Box>
  //     )}
  //   </Box>
  // );
  const handleToggle = useCallback(() => {
    setDisplayed(!isDisplayed);
  }, [isDisplayed]);
  return (
    <Box style={{ position: 'relative' }} direction="row" align="center">
      <RawButton onClick={handleToggle}>
        <Box direction="row" align="center" justify="center">
          <Text color="button">{t('Add Fund')}</Text>
          <Box margin={{ left: 'xxs' }}>
            <PlusRoundIcon color="button" />
          </Box>
        </Box>
      </RawButton>
      {isDisplayed && (
        <AddMoreFundsContent excludeIds={excludeIds} accountId={accountId} onClose={handleToggle} />
      )}
    </Box>
  );
};

export default AddMoreFunds;
