import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface Params {
  documentId: string;
  hashId: string;
  employeeId: string;
  formData: FormData;
}

export interface UploadedFilePayload {
  id?: number;
  s3_url?: string;
}

const useImportFileByLinkQuery = () => {
  const { mutateAsync, isLoading } = useMutation((params: Params) =>
    api.post(PATHS.IMPORT_FILE_BY_ONE_TIME_LINK(params.documentId), params.formData, {
      params: {
        hash_id: params.hashId,
        employee_id: params.employeeId,
      },
    })
  );

  const importFileByLink = useCallback(
    async (params: Params) => {
      const { data } = await mutateAsync(params);

      return data as UploadedFilePayload;
    },
    [mutateAsync]
  );

  return {
    importFileByLink,
    isLoading,
  };
};

export default useImportFileByLinkQuery;
