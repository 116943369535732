import React from 'react';
import { useTranslation } from 'react-i18next';
import { Inscription } from '@common-fe/common-fe';

import VerificationModal from './VerificationModal';

interface Props {
  icon: React.ReactNode;
  title: string;
  value: string;
  form: React.ReactNode;
  footerNode?: React.ReactNode;
  isLoading: boolean;
  errorText?: string;
  testId?: string;
  onSetVisible: (value: boolean) => void;
  onConfirm: () => void;
  onBack?: () => void;
}

const VerificationCodeModal: React.FC<Props> = ({
  icon, title, value, form, footerNode, isLoading, errorText, testId, onSetVisible, onConfirm, onBack
}) => {
  const { t } = useTranslation();

  return (
    <VerificationModal
      testId={testId}
      icon={icon}
      title={(
        <Inscription margin={{ top: 'spacing12' }} size='2xl' weight={700}>{title}</Inscription>
      )}
      description={(
        <>
          <Inscription margin={{ top: 'spacing12' }} textAlign="center">
            {t('Please enter the 6-digit verification code sent to ')}
            <Inscription weight={700}>{value}</Inscription>
          </Inscription>
          <Inscription textAlign="center">
            {t('The code is valid for 10 minutes.')}
          </Inscription>
        </>
      )}
      form={form}
      footerNode={footerNode}
      error={(
        !!errorText && (
          <Inscription margin={{ bottom: 'spacing12' }} color="danger" textAlign="end">
            {t(errorText)}
          </Inscription>
        )
      )}
      isLoading={isLoading}
      onSetVisible={onSetVisible}
      onBack={onBack}
      onConfirm={onConfirm}
    />
  );
};

export default VerificationCodeModal;
