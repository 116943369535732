import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppButton, Box, Checkbox, Hint, SuccessModal, Text, WarningIcon } from '@common-fe/common-fe';
import _ from 'lodash';

import ROUTES from '@/common/routes';
import { ValuesMap } from '@/common/types';
import ServerErrorModal from '@/modules/investments/modal/ServerErrorModal';

import useSellInvestments from './hooks/useSellInvestments';
import ConfirmModal from './ConfirmModal';
import { PERCENTS_FULL_LIQUIDATION, PERCENTS_LIQUIDATION_LIMIT } from './Funds.constants';
import FundsHeader from './FundsHeader';
import FundsItem from './FundsItem';
import { useSellFunds } from './hooks';

interface Props {
  accountId: string;
}


const Funds: React.FC<Props> = ({ accountId }) => {
  const history = useHistory();
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isServerErrorModal,setServerErrorModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data, } = useSellFunds({
    accountId,
  });

  const totalAmount = useMemo(() => {
    return data.reduce((result, item) => {
      if(item.isFullLiquidation ){
        return result;
      }
      return result + (item.amount || 0);
    }, 0);
  }, [data]);

  const [isLiquidAll, setLiquidAll] = useState(false);
  const [values, setValues] = useState<ValuesMap<number>>({});
  const [liquidationValues, setLiquidationValues] = useState<ValuesMap<boolean>>({});
  const [fullLiquidationValues, setFullLiquidationValuesValues] = useState<ValuesMap<boolean>>({});
  const { t } = useTranslation();

  const isFundsError = useMemo(() => {
    if (Object.values(values).length === 0) return true;
    if (Object.values(values).some((item) => item !== 0)) return false;
    return true;
  }, [values]);

  const isForceLiquidation = useMemo(() => {
    const totalCurrentValues = _.sum(Object.values(values));
    const preparedTotalAmount = parseFloat(totalAmount.toFixed(2));
    const preparedCurrentValues = parseFloat(totalCurrentValues.toFixed(2));
    const percents = preparedCurrentValues / preparedTotalAmount;
    const isAllFundsLiquidated = Object.values(fullLiquidationValues).every((item) => item);
    if (_.isNaN(percents)) {
      return false;
    }
    return percents >= PERCENTS_LIQUIDATION_LIMIT
    && percents !== PERCENTS_FULL_LIQUIDATION
    && isAllFundsLiquidated;
  }, [totalAmount, values, fullLiquidationValues]);
  const handleChangeValue = useCallback(
    (id: string) => (value: number) => {
      setValues({
        ...values,
        [id]: value,
      });
    },
    [values]
  );
  
  
  const handleChangeLiquidationValue = useCallback(
    (id: string) => (value: boolean) => {
      setLiquidationValues({
        ...liquidationValues,
        [id]: value,
      });
    },
    [liquidationValues]
  );
  
  const handleCancel = useCallback(() => {
    history.push(`${ROUTES.INVESTMENTS}?id=${accountId}`);
  }, [history, accountId]);

  const handleSubmit = useCallback(() => {
    setIsSubmitted(true);
    if (!isFundsError) {
      setConfirmVisible(true);
    }
  }, [isFundsError]);

  const { handleSellInvestments, isLoading} = useSellInvestments();
  
  const handleSave = useCallback(async() => {

    try {
      const formattedList = Object.keys(values).map((key) => {
        const itemValue = values[key];
        const foundItem = data.find((item) => item.id === key);
        if(foundItem) {
          const currentAmountCash = itemValue;

          if (fullLiquidationValues[key]) {
            return {
              instrumentId: key,
              isFullLiquidation: true
            };
          }
          return {
            instrumentId: key,
            amountCash: _.round(currentAmountCash,2)
          };
        }
        return {
          instrumentId: key,
          quantity: 0
        };
        
      });
      await handleSellInvestments({
        isFullLiquidation: isLiquidAll,
        preOrders: formattedList
      });
      setConfirmVisible(false);
      setSuccessModal(true);
    } catch {
      setConfirmVisible(false);
      setServerErrorModal(true);
    }
  }, [values, handleSellInvestments, isLiquidAll, data, fullLiquidationValues]);

  const handleCloseSuccess = useCallback(() => {
    
    setSuccessModal(false);
    history.push(`${ROUTES.INVESTMENTS}?id=${accountId}`);
  }, [accountId, history]);

  useEffect(() => {
    const ids = data.map(({ id }) => id);
    if (isLiquidAll) {
      setLiquidationValues(
        ids.reduce((result, id) => ({
          ...result,
          [id]: true,
        }), {})
      );
    } else {
      setLiquidationValues(
        ids.reduce((result, id) => ({
          ...result,
          [id]: false,
        }), {})
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiquidAll]);

  useEffect(() => {
    if (isForceLiquidation) {
      setLiquidAll(false);
    }

    if (Object.values(liquidationValues).some((item) => !item)) {
      setLiquidAll(false);
    }
  }, [isForceLiquidation, liquidationValues]);

  useEffect(() => {
    const values = Object.values(liquidationValues);
    const allValuesExist = values.length > 0 && values.length === data.length;
    const isAllTrue = values.every((item) => item);
    if (allValuesExist && isAllTrue) {
      setLiquidAll(true);
    }
  }, [liquidationValues, data]);

  useEffect(() => {
    const liquidate = Object.keys(values).reduce((result, item) => {
      const currentValue = values[item];
      const foundItem = data.find((dataItem) => dataItem.id === item);
    
      if (foundItem && foundItem.amount) {
      
        const percents = currentValue / foundItem.amount;
    
        if (Number.isNaN(percents)) {
          return {
            ...result,
            [item]: false,
          };
        }
    
        return {
          ...result,
          [item]: percents >= PERCENTS_LIQUIDATION_LIMIT,
        };
      }
    
      return result;
    }, {});
    
    setFullLiquidationValuesValues(liquidate);
  }, [data, values]);
  
  const handleCheckAll = useCallback((value: boolean) => {
    setLiquidAll(value);
    if (value) {
      setValues(
        data.reduce((result, item) => {
          if(item.isFullLiquidation) {
            return result;
          }
          return ({
            ...result,
            [item.id]: item.amount,
          });
        } , {})
      );
      return;
    }
    setValues(
      data.reduce((result, item) => {
        if(item.isFullLiquidation) {
          return result;
        }
        return  ({
          ...result,
          [item.id]: 0,
        });
      } , {})
    );
  }, [data]);
  return (
    <Box background="module" pad="l" margin={{ vertical: 'l' }} round="moduleRound" direction="column">
      <ConfirmModal
        liquidationValues={liquidationValues}
        visible={isConfirmVisible}
        isLoading={isLoading}
        onSetVisible={setConfirmVisible} 
        onSubmit={handleSave}
        values={values}
        list={data}
        
      />

      <ServerErrorModal
        visible={isServerErrorModal}
        onSetVisible={setServerErrorModal}
        onSendRequest={handleSave}
      />
      <SuccessModal
        onSetVisible={handleCloseSuccess}
        buttonText={t('Go to Investment Dashboard')}
        visible={isSuccessModal}
        onSubmit={handleCloseSuccess}
        header={t(['Submitted!'])} />
      <Box direction="row" justify="between">
        <Text size="large" weight={700}>
          {t('Holdings: ')} {data.length}
        </Text>
        <Box direction="row" align="center">
          {isForceLiquidation && (
            <Hint
              hintAlign="left"
              hintElement={(
                <Box margin={{ right: 'xs' }}>
                  <WarningIcon color="warning" />
                </Box>
              )}
            >
              <Text>{t('Your request exceeds 95% of the portfolio. All holdings will be sold.')}</Text>
            </Hint>
          )}

          <Text>{t('Full portfolio liquidation')}</Text>
          <Box margin={{ left: 'xs' }}>
            <Checkbox
              checked={isLiquidAll || isForceLiquidation}
              disabled={isForceLiquidation}
              onChange={handleCheckAll} />
          </Box>
        </Box>
      </Box>

      <Box direction="column">
        <FundsHeader />

        {data.map((item) => (
          <FundsItem
            key={item.id}
            {...item}
            isLiquidationAllMode={isLiquidAll}
            value={values[item.id]}
            isPendingLiquidation={item.isFullLiquidation}
            onChangeValue={item.isFullLiquidation ? () => {} : handleChangeValue(item.id)}
            isLiquidation={liquidationValues[item.id] || isLiquidAll}
            onSetLiquidation={handleChangeLiquidationValue(item.id)}

          />
        ))}

        {isFundsError && isSubmitted && (
          <Box alignSelf="end" margin={{ top: 'l' }}>
            <Text size="medium" color="danger">
              {t('Please enter the amount to sell')}
            </Text>
          </Box>
        )}
        <Box direction="row" justify="end" margin={{ vertical: 'l' }}>
          <AppButton onClick={handleCancel} width="140px" buttonType="secondary">
            {t('Cancel')}
          </AppButton>
          <Box margin={{ left: 's' }}>
            <AppButton onClick={handleSubmit}  width="140px">{t('Submit')}</AppButton>
          </Box>
        </Box>

        <Box pad={{ vertical: 'm' }} border={{ side: 'horizontal', color: 'border1' }}>
          <Text weight={700}>{t('Disclosure')}</Text>
          <Text
            margin={{ top: 's' }}
            color="textSecondary"
          
            weight={400}
            style={{ maxWidth: '100%' }}
          >
            {t(
              'Trades will be submitted today. Trade settlement may take up to 3 days. If the market is not open at the time of trade submission, it will be executed at the next market open. Going forward, all trades will be automatically submitted on future investment account contributions and rebalancing of the holdings to the recommended portfolio allocation.'
            )}
          </Text>
        </Box>
        <Box pad={{ top: 'm' }}>
          <Text
            color="textSecondary"
            weight={400}
            style={{ maxWidth: '100%' }}
          >
            {t(
              'Investment advisory services offered through Intellicents investment solutions, inc., an SEC registered investment adviser.'
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Funds;
