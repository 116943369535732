import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CashIcon,
  costFormater,
  Hint,
  InfoOutlineIcon,
  Inscription,
  LinkIcon,
  ListItem,
  ListItemBase,
  ListItemType,
  Text,
} from '@common-fe/common-fe';
import { toString } from 'lodash';

import { RiskControlOptions } from '@/common/constants';
import { IconWrapper } from '@/modules/investments/hooks/useInvestmentsHoldings';
import { Investment } from '@/modules/investments/investments.types';
import colors from '@/styles/colors';

import { useInvestmentPortfolios } from '../queries/useInvestmentPortfolios.query';

import { useGetFundIcon } from '.';

const CASH_KEY = 'Cash';
const headers: ListItemBase[] = [{
  key: 'fundName',
  title: 'Fund Name',
  flex: 4,
}, {
  key: 'type',
  title: 'Type',
}, {
  key: 'election',
  title: 'Election',
}, {
  key: 'amount',
  title: 'Amount',
}, {
  key: 'research',
  title: 'Research',
  flex: 2,
  endAlign: true,
}];
const HTTPS_PREFIX = 'https://';
const HTTP_PREFIX = 'http://';

export default (
  riskValue: RiskControlOptions,
  investorId?: string | null,
  investmentAmount?: number,
) => {
  const { t } = useTranslation();
  const {
    data,
    isLoading,
    isSuccess,
    refetch,
  } = useInvestmentPortfolios({
    investor_id: investorId,
    risk_model: riskValue,
    amount: investmentAmount,
  });

  const iconLabel = useMemo(() => (
    <IconWrapper margin={{ left: 'spacing8' }}>
      <InfoOutlineIcon color="iconSecondary" size="20px" />
    </IconWrapper>
  ), []);
  const handleGetFundIcon = useGetFundIcon();
  const formatedList = useMemo<ListItem[]>(
    () => data?.map((item: Investment) => {
      let formattedLink = item?.researchUrl || '';
      if (formattedLink
         && (!formattedLink.includes(HTTPS_PREFIX) && !formattedLink.includes(HTTP_PREFIX))) {
        formattedLink = `${HTTP_PREFIX}${formattedLink}`;
      }
      return ({
        id: toString(item.instrumentId),
        fields: [
          {
            key: 'fundName',
            type: ListItemType.Node,
            title: '',
            node: (
              <Box direction="row" align="center">
                {
                   
                  item.fundName === CASH_KEY && !item.type ? <CashIcon />
                    : handleGetFundIcon({
                      image: item.imageUrl,
                      status: item.status
                    }) 
                }

                <Text weight="bold" margin={{ left: 's' }}>{item.fundName}</Text>
                {item.fundName === CASH_KEY && !item.type
                  && (
                    <Hint hintElement={iconLabel}>
                      <Text textAlign="center">
                        {t('DriveWealth keeps some amount of cash for market trades. The amount depends on your risk category level.')}
                      </Text>
                    </Hint>
                  )}
              </Box>
            ),
            flex: 4,
          },
          {
            key: 'type',
            type: ListItemType.Text,
            title: item.type,
          },
          {
            key: 'election',
            type: ListItemType.Text,
            title: `${(item.election || 0).toFixed(2)}%`,
          },
          {
            key: 'amount',
            type: ListItemType.Node,
            title: '',
            node: <Text weight="bold">{costFormater(item.amount, true)}</Text>,
          },
          {
            key: 'research',
            type: ListItemType.Node,
            title: '',
            hover: true,
            node: (item.fundName === CASH_KEY && !item.type
              ? (
                <Text textAlign="center">-</Text>
              ) : (
                <a href={formattedLink} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
                  <Box direction="row" align="center" justify="end" style={{ cursor: 'pointer' }}>

                    <Inscription color="textAccent" textAlign="end" margin={{ right: 'spacing8' }}>Fund research</Inscription>
                    <Box direction="row" align="center" justify="center">
                      <LinkIcon size="18px" color={colors.iconAccent} />
                    </Box>

                  </Box>
                </a>
              )
            ),
            flex: 2,
          },
        ],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [data, iconLabel, t],
  );

  return {
    formatedList: formatedList || [],
    data,
    headers,
    isLoading,
    isSuccess,
    refetch,
  };
};
