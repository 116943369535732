import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import AnnualIncome from './AnnualIncome';
import Broker from './Broker';
import Director from './Director';
import EmploymenType from './EmploymenType';
import NetworthTotal from './NetworthTotal';
import PoliticallyExposedNames from './PoliticallyExposedNames';
import YourPosition from './YourPosition';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const PersonalInformation: React.FC<Props> = ({ onDirty }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text size="xlarge" weight="bold" margin={{ top: 'spacing8', bottom: 'spacing16' }}>
        {t('Personal Information')}
      </Text>
      <Box
        direction="column"
        background="module"
        round="moduleRound"
        pad={{ top: 'l', horizontal: 'l' }}
        margin={{ bottom: 'spacing24' }}
      >
        <YourPosition onDirty={onDirty} />
        <EmploymenType onDirty={onDirty} />
        <Broker onDirty={onDirty} />
        <Director onDirty={onDirty} />
        <PoliticallyExposedNames onDirty={onDirty} />
        <NetworthTotal onDirty={onDirty} />
        <AnnualIncome onDirty={onDirty} />
      </Box>
    </>
  );
};

export default PersonalInformation;
