import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import useEmployeeViewDataQuery, { QUERY_KEY } from '@/modules/user/queries/useEmployeeViewData.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

import { EmployeePayload } from '../user.types';

interface UpdateEmployeePayload {
  is_electronic_statement_only: boolean;
  employee_provided_email: string;
  is_employee_email_preferred: boolean;
  preferred_phone_type: string;
  preferred_phone_number: string;
  citizenship_country_id: number;
  is_sms_notification_enabled: boolean;
}

const useUpdateEmployeeQuery = () => {
  const { employee } = useEmployeeStore();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    (data: Partial<UpdateEmployeePayload>) =>
      api.patch(PATHS.UI_EMPLOYEE, {
        is_electronic_statement_only: employee?.isElectronicStatementOnly,
        employee_provided_email: employee?.employeeProvidedEmail,
        is_employee_email_preferred: employee?.isEmployeeEmailPreferred,
        preferred_phone_type: employee?.preferredPhone?.phoneType,
        preferred_phone_number: employee?.preferredPhone?.number,
        is_sms_notification_enabled: employee?.isSmsNotificationEnabled,
        ...data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
      },
    }
  );

  return {
    updateEmployee: mutateAsync,
    isLoading,
    isSuccess,
    isError,
  };
};

export const usePutEmployeeQuery = () => {
  const { employee } = useEmployeeStore();
  const { rawData } = useEmployeeViewDataQuery(employee?.id);
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    (data: Partial<EmployeePayload>) =>
      api.put(`${PATHS.UI_EMPLOYEE}/${employee?.id}`, {
        ...rawData,
        ...data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
      },
    }
  );

  return {
    updateEmployee: mutateAsync,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useUpdateEmployeeQuery;
