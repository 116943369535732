import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  Anchor,
  Box,
  Checkbox,
  Preloader,
  Text,
} from '@common-fe/common-fe';

import { ExternalLink } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import { SwitchEmployeeModal } from '@/modules/core/components/SwitchEmployeeModal';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import useSignUp from './hooks/useSignUp';
import AccountExistError from './AccountExistingError';
import UserExistsError from './UserExistError';

const CONTAINER_WIDTH = '320px';


interface FooterProps {
  onSignInRedirect: () => void;
  isOrphanMode?: boolean;
}

const Footer: React.FC<FooterProps> = ({ onSignInRedirect, isOrphanMode }) => {
  const [termsChecked, setTermsChecked] = useState(false);

  const {
    loading, userExistError, handleSaveForm, handleSaveSsoForm,
    notFoundUserError, submitted, showConfirm, formErrors,
    isSwitchEmployeeModalVisible, setIsSwitchEmployeeModalVisible,
  } = useSignUp(termsChecked, isOrphanMode);

  const { t } = useTranslation();
  const isDisabled = useMemo(() => submitted && !termsChecked, [submitted, termsChecked]);
  return (
    <Box
      direction="row"
      align="center"
      justify="center"
      background="module"
      pad={{ vertical: 'spacing32', horizontal: 'spacing48' }}
    >
      <Prompt
        when={showConfirm}
        message="The information you entered will not be saved."
      />
      <Box fill direction="column" width={{ max: CONTAINER_WIDTH }}>
        {notFoundUserError && (
          <AccountExistError />
        )}

        {userExistError && (
          <UserExistsError onSignInRedirect={onSignInRedirect} />
        )}

        {isDisabled && (
          <Box direction="column" align="end" margin={{ bottom: 'spacing24' }} fill>
            <Text color="danger">
              {t('Please confirm that you’ve read and acknowledge our Terms&Conditions and Privacy Policy.')}
            </Text>
          </Box>
        )}
        {submitted && formErrors && termsChecked && (
          <Box direction="column" align="end" margin={{ bottom: 'spacing24' }} fill>
            <Text color="danger">
              {t('Please correct the highlighted errors to complete the registration')}
            </Text>
          </Box>
        )}
        <Box direction="column" align="center">
          <Box direction="row" align="center" margin={{ bottom: 'spacing24' }}>
            <Checkbox
              checked={termsChecked}
              onChange={setTermsChecked}
              isLarge
            />
            <Box fill direction="column">
              <Text size="medium">
                {t('I have read and acknowledge ')}{whiteLabelingConfig?.title  ? '' :  'Elevate’s'}&ensp;
              </Text>
              <Text size="medium">
                <Anchor weight={400} target="_blank" href={whiteLabelingConfig?.termsAndConditions || ExternalLink.termsAndCondition}>
                  {t('Terms & Conditions')}
                </Anchor>&ensp;
                {t('and')}&ensp;
                <Anchor weight={400} target="_blank" href={whiteLabelingConfig?.privacyPolicy || ExternalLink.privacyPolicy}>
                  {t('Privacy Policy.')}
                </Anchor>
              </Text>
            </Box>

          </Box>
          <AppButton
            disabled={isDisabled || !termsChecked}
            size="L"
            width="100%"
            onClick={isOrphanMode
              ? handleSaveSsoForm
              : handleSaveForm}
          >
            {loading ? <Preloader color="white" /> : t('Submit') }

          </AppButton>
        </Box>

      </Box>
      <SwitchEmployeeModal
        visible={isSwitchEmployeeModalVisible}
        onClose={() => setIsSwitchEmployeeModalVisible(false)}
      />
    </Box>

  );
};

export default Footer;
