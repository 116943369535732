import { useCallback, useMemo, useState } from 'react';
import { CIPStatus } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user';

import { ScanStatuses } from '../cip.constants';
import {
  useCIPIdentificationQuery,
  useCIPInfoQuery,
} from '../queries';

import useFailedCIPStorage from './useFailedCIPStorage';

interface Params {
  onFail?: ()=> void;
  onSuccess?: () => void;
}
export default (params?: Params) => {
  const [isServerErrorShowed, setServerErrorShowed] = useState(false);
  const { employeeId } = useAuthStore();
  const { data, invalidateQuery: invalidateCIPInfoQuery } = useCIPInfoQuery(employeeId);
  const isVerificationRequired = useMemo(
    () => data?.status === CIPStatus.Fail || data?.status === CIPStatus.Question, [data.status],
  );
  const [isVerificationLoading, setVerificationLoading] = useState(false);
  const { handleRefetch } = useCIPIdentificationQuery({
    enabled: false,
    onError: () => {
      setServerErrorShowed(true);
    },
    onSuccess: params?.onSuccess,
  });
  const history = useHistory();
  const { isFailed, handleFailed } = useFailedCIPStorage();

  const handleVerify = useCallback(async (url?: string) => {
    try {
      setVerificationLoading(true);
      try {
        const response = await handleRefetch();

        if (!response) {
          return;
        }
        const { idScan, idScanUrl, questions } = response;
        if (idScanUrl || url) {
          const encodedUrl = encodeURIComponent(url || idScan);
          history.push(ROUTES.ID_SCAN(encodedUrl));
        } else if (idScan === ScanStatuses.No || questions) {
        
          if (questions && questions.length) {
            history.push(ROUTES.POLL);
            return; 
          } else if (params?.onFail) {
            params?.onFail();
          }
        }
      } catch {
        setServerErrorShowed(true);
      }
    } catch {
      setServerErrorShowed(true);
    } finally {
      setVerificationLoading(false);
    }
  }, [handleRefetch, history, params]);
  return {
    invalidateCIPInfoQuery,
    isServerErrorShowed,
    setServerErrorShowed,
    data,
    handleFailedCip: handleFailed,
    isVerificationRequired,
    isVerificationLoading,
    handleVerify,
    isFailed,
  };
};
