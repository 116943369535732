import { useMemo } from 'react';

import { useAuthStore } from '@/modules/user';
import useEmployeeViewDataQuery from '@/modules/user/queries/useEmployeeViewData.query';

export default () => {
  const { user } = useAuthStore();
  const employeeId = useMemo(() => `${user?.employee?.id || ''}`, [user]);
  const { formattedData, isLoading } = useEmployeeViewDataQuery(employeeId);
  
  const params = useMemo(() => {
    const result = {
      phone: false,
      email: false,
      citizenship: false,
    };
    if (formattedData.preferredEmail) {
      result.email = true;
    }
    if (formattedData.preferredPhone && (formattedData?.preferredPhone as string)?.trim() ) {
      result.phone = true;
    }
    if (formattedData.citizenshipId) {
      result.citizenship = true;
    }
    return result;
  }, [formattedData]);
  const isFull = useMemo(() => Object.values(params).every((param) => param), [params]);
  const citizenShipId = useMemo(() => formattedData.citizenshipId, [formattedData.citizenshipId]);
  return {
    params,
    citizenShipId,
    isFull,
    isLoading,
  };
};
