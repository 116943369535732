import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AbsenceFilesPlaceholderIcon,
  Box,
  FlexList,
  ListItemBase,
  NoSearchItemsPlaceholder,
  PaginationBar,
  SearchInput,
  SearchPlaceholderIcon,
  SelectDropdown,
  Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { ALL_OPTION } from '@/common/constants';
import { useDownloadFileById, useOpenFile } from '@/modules/core/hooks';
import useSearchParams from '@/modules/transaction/components/Statements/components/StatementsList/hooks/useSearchParams';
import useStatementsList from '@/modules/transaction/components/Statements/components/StatementsList/hooks/useStatementsList';
import useStatementsQuery from '@/modules/transaction/components/Statements/components/StatementsList/queries/useStatements.query';
import { SORT_OPTIONS } from '@/modules/transaction/components/Statements/statements.types';
import dropdownStateAllToggling, { checkIsAllOptionSelected } from '@/utils/handlers/dropdownStateAllToggling';

export const HEADERS: ListItemBase[] = [
  {
    key: 'statementPeriod',
    title: 'Statement period',
    flex: 1,
  },
  {
    key: 'actions',
    title: 'Actions',
    flex: 1,
    endAlign: true,
  },
];

interface Props {
  accountId?: string;
  headers: ListItemBase[];
}

const StatementsList: React.FC<Props> = ({ accountId, headers }) => {
  const { t } = useTranslation();
  const { handleDownload } = useDownloadFileById();
  const { handleOpen } = useOpenFile();

  const {
    page,
    perPage,
    searchString,
    currentSearch,
    setSearchString,
    setPerPage,
    setPage,
    sortParams,
    setSortParams,
    years,
    setYears,
  } = useSearchParams();

  const yearsValues = useMemo(() => dropdownStateAllToggling(years), [years]);
  const isAllYearsSelected = useMemo(() => checkIsAllOptionSelected(years), [years]);

  const { formattedData, total, isLoading } = useStatementsQuery({
    accountId,
    page,
    pageSize: perPage,
    searchString: currentSearch,
    sortBy: sortParams,
    years: isAllYearsSelected ? [] : yearsValues,
  });

  const { fields } = useStatementsList({ statements: formattedData });

  const yearOptions = useMemo(() => {
    const startYear = 2022;
    const currentYear = dayjs().year();

    const statementsYears = Array
      .from({ length: currentYear - startYear }, (_, index) => currentYear - index);

    const statementsYearsOptions = statementsYears.map((statementYear) => ({
      key: `${statementYear}`,
      value: `${statementYear}`,
      title: `${statementYear}`,
    }));

    return [ALL_OPTION, ...statementsYearsOptions];
  }, []);

  const areFiltersUsed = useMemo(
    () => currentSearch || !isAllYearsSelected,
    [currentSearch, isAllYearsSelected],
  );

  const isSearchingDisabled = useMemo(() => !(areFiltersUsed || total), [areFiltersUsed, total]);

  return (
    <Box data-testId="statements-list" margin={{ top: 'spacing24' }}>
      <FlexList
        testId="statements_list"
        headers={headers}
        rows={fields}
        moreCount={perPage}
        total={total}
        loading={isLoading}
        options={[
          {
            onClick: (documentId) => handleOpen(documentId),
            name: 'View',
          },
          {
            onClick: (documentId) => handleDownload(documentId),
            name: 'Download',
          },
        ]}
        footer={(
          <PaginationBar
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )}
        placeholder={(
          <NoSearchItemsPlaceholder
            placeholderTitle={areFiltersUsed ? t('No results have been found') : t('There are no files yet')}
            node={areFiltersUsed ? (
              <SearchPlaceholderIcon size="xxlarge" color="iconSecondary" />
            ) : (
              <AbsenceFilesPlaceholderIcon size="xxlarge" color="iconSecondary" />
            )}
            description={areFiltersUsed ? t('Try to adjust your search or filter to find what you\'re looking for.') : ''}
          />
        )}
      >
        <Box
          margin={{ bottom: 'spacing24', top: 'spacing12' }}
          direction="row"
          align="center"
          justify="between"
        >
          <Box>
            <Text weight="bold" color="textBody" size="large" style={{ whiteSpace: 'nowrap' }}>
              Files: {total}
            </Text>
          </Box>
          <Box direction="row" justify="end">
            <Box width="220px">
              <SearchInput
                testId="statement"
                value={searchString}
                onChange={setSearchString}
                disabled={isSearchingDisabled}
                placeholder={t('ex. April, 2024')}
                hasSearchingHistory
              />
            </Box>
            <Box margin={{ left: 'spacing12' }} width="180px">
              <SelectDropdown
                id="years"
                name="Years"
                prefix="Years"
                values={yearsValues}
                onChangeValues={setYears}
                options={yearOptions}
                disabled={isSearchingDisabled}
                activeTitle
                allMode
                ellipsisMode
              />
            </Box>
            <Box margin={{ left: 'spacing12' }} width="180px">
              <SelectDropdown
                id="sort_by"
                name="Sort by"
                prefix="Sort by"
                value={sortParams}
                onChange={setSortParams}
                options={SORT_OPTIONS}
                disabled={isSearchingDisabled}
                activeTitle
                singleMode
              />
            </Box>
          </Box>
        </Box>
      </FlexList>
    </Box>
  );
};

export default StatementsList;
