import { useMemo } from 'react';
import { } from '@common-fe/common-fe';
import _ from 'lodash';

import { useInvestor } from '@/modules/investments/hooks';
import { useMyEnrollments } from '@/modules/transaction';
import costFormated from '@/utils/modifiers/costFormater';

const SELF_DIRECTED = 'SELF_DIRECTED';

interface Params {
  accountId?: string;
}
export default (params?: Params) => {
  const { currentMyAccount } = useMyEnrollments(params?.accountId);

  const minimumInvestmentAmount = useMemo(
    () => currentMyAccount?.minimumInvestmentAmount || 0, [currentMyAccount],
  );
  const availableBalance = useMemo(
    () => currentMyAccount?.available || 0, [currentMyAccount],
  );
  const investmentThreshold = useMemo(() => {
    const threshold = currentMyAccount?.investmentThreshold || 0;
    return threshold;
  }, [currentMyAccount]);
  const investors = useInvestor(params?.accountId);
  const placeholderText = useMemo(() => `${costFormated(investmentThreshold, true)}+`, [investmentThreshold]);

  const toleranceModel = useMemo(() => {
    const toleranceValue = investors.data.toleranceModel || '';
    const toleranceTitle = _.startCase(_.toLower(toleranceValue));
    return {
      title: toleranceTitle,
      value: toleranceValue,

    };
  }, [investors.data.toleranceModel]);
  const isSelfDirected = useMemo(() => investors.data.type === SELF_DIRECTED, [investors]);
  const investorAccountId = useMemo(() => investors.data.investorAccountId, [investors]);
  return {
    investorAccountId,
    availableBalance,
    investmentThreshold,
    minimumInvestmentAmount,
    toleranceModel,
    placeholderText,
    investors,
    isSelfDirected,
  };
};
