import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import ZendeskMoving from '@/modules/core/components/ZendeskMoving';
import { useProfileInformationFormStore } from '@/modules/user/components/SignUp/ProfileInformation/stores/useProfileInformationFormMode.store';
import { useAuthorization } from '@/modules/user/hooks';

import Footer from './Footer/Footer';
import ProfileInformation from './ProfileInformation/ProfileInformation';
import AccountAccess from './AccountAccess';
import CommunicationSettings from './CommunicationSettings';
import Header from './Header';
import Sidebar from './Sidebar';

const ESO_PARAM = 'eso';
const ORPHAN_MODE = 'orphan';

interface SignUpProps {

}

const SignUp: React.FC<SignUpProps> = () => {
  // It will be needed in the future
  // const [token, setToken] = useState<string>('');
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // useEffect(() => {
  //   if (executeRecaptcha) {
  //     executeRecaptcha('registration').then(setToken);
  //   }
  // }, [executeRecaptcha]);
  const { values: { isFormEditMode }, handleReset } = useProfileInformationFormStore();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const hasEsoParam = searchParams.has(ESO_PARAM);
  const { handleLogout } = useAuthorization();

  const isOrphanMode = useMemo(() => history.location.pathname.includes(ORPHAN_MODE), [history]);
  const isShowFullPage = useMemo(() => !isFormEditMode || isOrphanMode, [isFormEditMode, isOrphanMode]);

  const handleSigninRedirect = useCallback(async () => {
    if (isOrphanMode) {
      await handleLogout();
    }
    history.push(ROUTES.LOGIN);
  }, [handleLogout, history, isOrphanMode]);

  const handleHomeRedirect = useCallback(() => {
    history.push(ROUTES.HOME);
  }, [history]);

  useEffect(() => () => {
    handleReset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box direction="row" basis="full" style={{ minHeight: '100vh' }}>
      <Sidebar onSignInRedirect={handleSigninRedirect} />
      <Box fill>
        <Box fill align="center">
          <Box
            direction="column"
            fill
            pad="spacing48"
            style={{ maxWidth: '1100px' }}
          >
            <Header
              onSignInRedirect={isOrphanMode ? handleHomeRedirect : handleSigninRedirect}
              isOrphanMode={isOrphanMode}
            />
            <ProfileInformation isOrphanMode={isOrphanMode} />
            {
              isShowFullPage && (
                <>
                  <AccountAccess isOrphanMode={isOrphanMode} />
                  {!hasEsoParam && (
                    <CommunicationSettings />
                  )}
                </>
              )
            }

          </Box>
        </Box>
        {
          isShowFullPage && (
            <Footer onSignInRedirect={handleSigninRedirect} isOrphanMode={isOrphanMode} />
          )
        }
      </Box>
      <ZendeskMoving />
    </Box>
  );
};
export default SignUp;
