import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FloatImage,
  Image,
  NavigationLeftIcon
} from '@common-fe/common-fe';

import LoginBackgroundImage from '@/assets/backgrounds/loginBackground.svg';
import logoImage from '@/assets/login-logo.svg';
import { VisualImpairment } from '@/modules/core/components';
import { SignInContainer, SignInTitleContainer, SignInTitleHeading } from '@/modules/user/components/SignIn/SignInWrapper.styles';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';
import { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

import {
  BackButton,
  // Delimiter,
  // Content,
  // Text,
  BackgroundWrapper,
  // ContentWrapper,
  Footer,
  FormContent,
  FormSubtitle,
  FormTitle,
  FormWrapper,
  RightBlockWrapper,
  Wrapper,
} from './SignInWrapper.styles';

const isRmrTheme = whiteLabelingConfig.key === THEMES.rmr;
const logoWidth = isRmrTheme ? 330 : 240;
const logoHeight = isRmrTheme ? 159 : 65;

interface Props {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string;
  isBackButton?: boolean;
  onBack?: () => void;
  flat?: boolean;
  header?: React.ReactNode;
}

const SignIn: React.FC<Props> = ({
  flat,
  children,
  title,
  subtitle,
  isBackButton,
  header,
  onBack,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BackgroundWrapper>
        <FloatImage
          src={whiteLabelingConfig?.images?.loginBackground || LoginBackgroundImage}
          fit="cover"
          style={{
            backgroundPosition: 'center bottom',
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </BackgroundWrapper>
      <SignInContainer
        direction="row"
        style={{
          zIndex: 1,
        }}
      >
        <SignInTitleContainer
          flex
          margin={{ right: '100px', top: '80px' }}
        >
          <Image
            src={whiteLabelingConfig?.images?.signInLogo || whiteLabelingConfig?.logo || logoImage}
            width={logoWidth}
            alt="logo"
            height={logoHeight}
          />
          <SignInTitleHeading
            size="20px"
            level={3}
            color="loginTitleColor"
            style={{
              maxWidth: isRmrTheme ? '400px' : '600px',
              fontWeight: 400,
            }}
          >
            {t(whiteLabelingConfig?.loginScreenTitle || t('The only automated, AI-powered, and ultra-reliable solution for today’s consumer benefits.'))}
          </SignInTitleHeading>
        </SignInTitleContainer>
        <RightBlockWrapper>
          <FormWrapper flat={flat} isBackButton={isBackButton}>
            {isBackButton && (
              <BackButton onClick={onBack} type="button">

                <NavigationLeftIcon size="18px" color="iconPrimary" />
                
              </BackButton>
            )}
            {header}
            {typeof title === 'string' ? <FormTitle>{title}</FormTitle> : title}
            {subtitle && (
              <FormSubtitle>
                {subtitle}
              </FormSubtitle>
            )}
            <FormContent>
              {children}
            </FormContent>
          </FormWrapper>
        </RightBlockWrapper>

      </SignInContainer>

      <Footer style={{ visibility: 'hidden' }}>
        <VisualImpairment />
      </Footer>
    </Wrapper>
  );
};

export default SignIn;
