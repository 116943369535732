import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isAutoInvestmentExist: boolean;
  setAutoInvestmentExist: (value: boolean) => void;
}

const useStore = storeCreator<State>((set) => ({
  isAutoInvestmentExist: false,

  setAutoInvestmentExist: (value) => set(() => ({
    isAutoInvestmentExist: value,
  })),
}));

export default useStore;
