import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { StockQuoteType } from '@/common/constants';

import { useInvestor } from '../hooks';
import { InvestmentHolding, InvestmentHoldingStatus, InvestmentOrderType, InvestmentsType } from '../investments.types';

export const QUERY_KEY = 'GET_INVESTMENTS_HOLDINGS';

interface HoldingDTO {
  instrument_id?: string;
  symbol: StockQuoteType | string;
  image_url: string;
  research_url: string;
  side?: string;
  fund_name: string;
  '@type': InvestmentsType;
  type: InvestmentsType;
  amount: number;
  pending_amount: number;
  instrument_category_type?: string;
  shares: number;
  share_price: number;
  increase: number;
  order_type?: InvestmentOrderType;
  status?: InvestmentHoldingStatus;
  election: number;
}
interface InvestmentHoldingsDTO {
  available_balance?: number;
  available_to_invest?: number;
  investor: {
    account_id?: number;
    account_number?: string;
    investor_id: number;
    balance: number;
    invested_value: number;
    settled_portfolio_balance: number;
    holdings: HoldingDTO[];
  };
}
export default (accountId?: string, isQueryDisabled?: boolean) => {

  const parsedAccountId = useMemo(() => {
    if (accountId && !_.isNaN(_.toNumber(accountId))) {
      return _.toNumber(accountId);
    }
  }, [accountId]);
  const { isInvestorAccountExists, isInvestorExists } = useInvestor(accountId);
  
  const { data, isSuccess, isLoading } = useQuery(
    [QUERY_KEY, parsedAccountId, isInvestorAccountExists],
    () =>
      api.get<InvestmentHoldingsDTO>(PATHS.INVESTMENTS_HOLDINGS, {
        params: {
          account_id: parsedAccountId,
        },
      }),
    {
      retry: false,
      enabled: !!parsedAccountId && !isQueryDisabled && isInvestorAccountExists,
    }
  );

  const formattedDetails = useMemo(() => {
    const currentData = data?.data;
    return {
      availableBalance: currentData?.available_balance,
      availableToInvest: currentData?.available_to_invest,
      investmentsAmount: currentData?.investor.balance || 0,
      investorId: `${currentData?.investor.investor_id || ''}`,
      accountNumber: currentData?.investor?.account_number,

      investedValue: currentData?.investor.invested_value,
      portfolioBalance: currentData?.investor.settled_portfolio_balance,
    };
  }, [data]);
  const formattedHoldings = useMemo<InvestmentHolding[]>(() => {
    const holdings = data?.data.investor.holdings || [];
    return holdings.map((item) => ({
      id: `${item.instrument_id || ''}`,
      amount: item.amount,
      election: item.election,
      name: item.fund_name,
      image: item.image_url,
      increase: item.increase,
      categoryType: item.instrument_category_type,
      pendingAmount: item.pending_amount,
      researchURL: item.research_url,
      sharePrice: item.share_price,
      shares: item.shares,
      status:item.status, 
      orderType: item.order_type,
      symbol: item.symbol,
      side: item.side,
      type: item.type,
    }));
  }, [data]);

  const sortedFormattedHoldings = useMemo(
    () => _.sortBy(formattedHoldings, (item) => item.amount + item.pendingAmount),
    [formattedHoldings]
  );
  return {
    data: sortedFormattedHoldings,
    details: formattedDetails,
    isInvestorExists,
    isInvestorAccountExists,
    isSuccess,
    isLoading,
  };
};
