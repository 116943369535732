import React from 'react';
import { useTranslation } from 'react-i18next';
import { Inscription } from '@common-fe/common-fe';

interface Props {
  onClick: () => void;
}

const OpenChangeVerificationMethodModalButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Inscription
      color="textAccent"
      size="small"
      weight={500}
      onClick={onClick}
      cursor="pointer"
    >
      {t('Change verification method')}
    </Inscription>
  );
};

export default OpenChangeVerificationMethodModalButton;
