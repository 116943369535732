import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// import { Box, Modal, Preloader, Text } from '@common-fe/common-fe';
import useCheckScan from './hooks/useCheckScan';

interface Props {
  src: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const SCAN_STATUSES = {
  ERROR: 'error',
  SUCCESS: 'success',
};
const ScanFrame: React.FC<Props> = ({ src, onSuccess, onError }) => {
  const { setIsLoading, isLoading, isError, isSuccess}  = useCheckScan();
  const handleRunChecking = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);
  // const handleListen = useCallback((event: MessageEvent<unknown>) => {
  //   if (event.data === SCAN_STATUSES.SUCCESS && onSuccess) {
  //     handleRunChecking();
  //   }
  //   if (event.data === SCAN_STATUSES.ERROR && onError) {
  //     onError();
  //   }
  // }, [handleRunChecking, onError, onSuccess]);

  useEffect(() => {
    handleRunChecking();
    // window.addEventListener('message', handleListen);
  }, [handleRunChecking]);


  useEffect(() => {
    if(isError && onError) {
      onError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  useEffect(() => {
    if(isSuccess && onSuccess) {
      onSuccess();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const { t } = useTranslation();
  return (
    <>
      {/* <Modal visible={isLoading} isCloseButtonHidden>
        <Box margin={{vertical: 'm'}}>
          <Preloader size={40} thinSize={8}/>
          <Text textAlign="center" size="2xl" weight={700} margin={{top: 's'}}>{t('Loading...')}</Text>
        </Box>
      </Modal> */}

      <iframe
        data-testid="scan-frame"
        src={src}
        title="scan-frame"
        height="950px"

      />
    </>
  );
};

export default React.memo(ScanFrame);
