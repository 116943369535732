import React from 'react';
import { useTranslation } from 'react-i18next';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { Questionnaire } from '@/modules/investments/cip';

const PollPage = () => {
  const { t } = useTranslation();
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: t('Home'),
    value: ROUTES.HOME,
  }, {
    key: ROUTES.POLL,
    title: t('Verification Question'),
    value: ROUTES.POLL,
  },
  ], true);
  return (
    <Questionnaire />

  );
};
export default PollPage;
