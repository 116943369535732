import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Text } from '@common-fe/common-fe';
interface HeaderItemProps {
  children: React.ReactNode;
  testId: string;
}
const HeaderItem: React.FC<HeaderItemProps> = ({testId, children}) => (
  <Text
    color="textBody"
    weight={700}
    size="small"
    style={{ lineHeight: '18px' }}
    data-testid={`fund-list_header_${testId}`}
  >
    {children}
  </Text> 
);
const FundsHeader: React.FC = () => {
  
  const { t } = useTranslation();
  return (
    <Box
      margin={{top: 's'}}
      background="legend"
      align="center"
      round="container2Round"
      direction="row"
      pad={{ vertical: '7px', horizontal: 'l' }}
    >
      <Box width="70px"></Box>
      <Box style={{ flex: 2 }} margin={{left: 's'}}>
        <HeaderItem testId='fund-name'>
          {t('Fund Name')}
        </HeaderItem>
        
      </Box>
    
      <Box width="24px"></Box>

      <Box width="102px" margin={{ left: 'l' }}>
        <HeaderItem testId='current-election'>
          {t('Current election')}
        </HeaderItem>
     
      </Box>
      <Box width="110px" margin={{ left: 'l' }}>
        <HeaderItem testId='current-holdings'>
          {t('Current holdings')}
        </HeaderItem>
       
      </Box>
      <Box width="150px" margin={{ left: 'l' }}>
        <HeaderItem testId='amount-to-sell'>
          {t('Amount to sell')}
        </HeaderItem>
        
      </Box>
      <Box direction='row'
        align='center'
        justify='end'
        margin={{ left: 'xs' }}
        style={{flex: 1.2}}>
        <HeaderItem testId='liquidate-position'>
          {t('Liquidate position')}
        </HeaderItem>
       
      </Box>
    </Box>
  );
};
export default FundsHeader;