import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, NavigationToggler, Option } from '@common-fe/common-fe';

import { ManageProfileTabs } from '@/modules/user/components/ManageProfile/manageProfile.types';

import CommunicationPreferences from './components/CommunicationPreferences/CommunicationPreferences';
import Dependents from './components/Dependents/Dependents';
import ManageProfileDashboard from './components/ManageProfileDashboard/ManageProfileDashboard';
import ManageProfileModals from './components/ManageProfileModals/ManageProfileModals';
import useSetPaymentMethodsInStore from './components/ReimbursementOptions/hooks/useSetPaymentMethodsInStore';
import ReimbursementOptions from './components/ReimbursementOptions/ReimbursementOptions';
import StatementsAndDocuments from './components/StatementsAndDocuments/StatementsAndDocuments';

const ManageProfile = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>(ManageProfileTabs.COMMUNICATION_PREFERENCES);
  const { refetch: refetchPaymentMethods, isFetching } = useSetPaymentMethodsInStore();

  const options = useMemo<Option[]>(() => [
    {
      key: ManageProfileTabs.COMMUNICATION_PREFERENCES,
      value: ManageProfileTabs.COMMUNICATION_PREFERENCES,
      title: t('Communication Preferences')
    },
    {
      key: ManageProfileTabs.REIMBURSEMENT_OPTIONS,
      value: ManageProfileTabs.REIMBURSEMENT_OPTIONS,
      title: t('Reimbursement Options')
    },
    {
      key: ManageProfileTabs.DEPENDENTS,
      value: ManageProfileTabs.DEPENDENTS,
      title: t('Dependents')
    },
    {
      key: ManageProfileTabs.STATEMENTS_AND_DOCUMENTS,
      value: ManageProfileTabs.STATEMENTS_AND_DOCUMENTS,
      title: t('Statements & Documents ')
    }
  ], [t]);

  return (
    <>
      <Box margin={{ bottom: 'spacing24' }}>
        <ManageProfileDashboard refetchPaymentMethods={refetchPaymentMethods} />
        <Box margin={{ top: 'spacing24' }}>
          <NavigationToggler
            value={tab}
            onChange={setTab}
            options={options}
            buttonStyle={{ padding: '10px 32px' }}
          />
        </Box>
        <Box margin={{ top: 'spacing24' }}>
          {tab === ManageProfileTabs.COMMUNICATION_PREFERENCES && <CommunicationPreferences />}
          {tab === ManageProfileTabs.REIMBURSEMENT_OPTIONS && (
            <ReimbursementOptions isFetching={isFetching} refetchPaymentMethods={refetchPaymentMethods} />
          )}
          {tab === ManageProfileTabs.DEPENDENTS && <Dependents />}
          {tab === ManageProfileTabs.STATEMENTS_AND_DOCUMENTS && <StatementsAndDocuments />}
        </Box>
      </Box>
      <ManageProfileModals />
    </>
  );
};

export default ManageProfile;
