import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.text32Bold};
  margin-bottom: ${({ theme }) => theme.spacings.spacing24};
  color: ${({ theme }) => theme.colors.blockTitle || ''};
`;
