import { useState } from 'react';

import { ContributionPaymentMethod } from '../contribution.types';

export const useContributionPaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState<ContributionPaymentMethod>({});
  const [paymentMethodSetup, setPaymentMethodSetup] = useState(false);

  return {
    paymentMethod,
    setPaymentMethod,
    paymentMethodSetup,
    setPaymentMethodSetup,
  };
};
