import { useQuery, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { QUERY_KEY as ENROLLEMENT_QUERY_KEY } from '@/modules/transaction/hooks/useMyEnrollments';
export enum CipVerificationStatus {
  Success = 'PASS',
  Pending = 'PENDING',
  Error = 'FAIL',
}

interface CipResponse {
  status: CipVerificationStatus;
}

const QUERY_KEY = 'GET_CIP_VERIFICATION_STATUS';
export default () => {
  const queryClient = useQueryClient();
  const {
    refetch: handleCheck,
    isLoading,
    isError,
  } = useQuery([QUERY_KEY], () => api.get<CipResponse>(PATHS.CIP_DOCUMENT_SCAN_CHECK()), {
    enabled: false,
    onSuccess: () => {
      queryClient.invalidateQueries(ENROLLEMENT_QUERY_KEY);
    },
  });

  return {
    handleCheck,
    isLoading,
    isError,
  };
};
