import { useMemo } from 'react';
import {
  useQuery,
} from 'react-query';
import { formatSnakeCaseToGeneral } from '@common-fe/common-fe';
import { isNull } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Expense, ExpensesDetailsParams, ExpensesStatisticsDto } from '@/modules/transaction/transaction.types';

const EXPENSES_STATISTICS_QUERY_KEY = 'getExpensesStatisticsQueryKey';

const OTHER_EXPENSE = {
  key: 'OTHER',
  title: 'Other',
};

export const useExpensesStatisticsQuery = (params?: ExpensesDetailsParams) => {
  const accountId = params?.account_id;

  const {
    data, isLoading, isSuccess, refetch,
  } = useQuery(
    [EXPENSES_STATISTICS_QUERY_KEY, accountId],
    () => api.get(PATHS.EXPENSES_STATISTICS, { params }),
  );

  const formatedData = useMemo(() => {
    const receivedData: ExpensesStatisticsDto = data?.data || [];

    return {
      totalExpenses: Math.abs(receivedData?.total_expenses),
      expenses: receivedData?.expenses?.map((listItem: Expense) => {
        if (isNull(listItem.category_type)) {
          return {
            ...OTHER_EXPENSE,
            value: listItem?.percent,
            amount: listItem?.amount,
          };
        }

        return {
          key: listItem.category_type || '',
          title: formatSnakeCaseToGeneral(listItem.category_type || ''),
          value: listItem?.percent,
          amount: Math.abs(listItem?.amount) || 0,
        };
      }),
    };
  }, [data]);
  return ({
    formatedData,
    isLoading,
    isSuccess,
    data,
    refetch,
  });
};

export default useExpensesStatisticsQuery;
