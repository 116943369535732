import { Box } from 'grommet';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
interface ContentProps {
  minified?: boolean;
}
export const Content = styled(Box)<ContentProps>`
  flex: 1;
  padding-left: ${({ theme, minified }) => (minified ? 0 : theme.spacings.spacing24)};
  padding-right: ${({ theme, minified }) => (minified ? 0 : theme.spacings.spacing24)};
  background-color: ${({ theme }) => theme.colors.canvas};
  @media ${({ theme }) => theme.deviceSize.moreThen.laptopL} {
    padding: ${({ theme, minified }) => `0 ${minified ? 0 : theme.spacings.spacing32} ${minified ? 0 : theme.spacings.spacing48}`};
  }
`;
