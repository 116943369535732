import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, costFormater,Modal,Preloader,Text, WarningIcon } from '@common-fe/common-fe';

import { AllocationPercentsMap, FundsPayload } from '../../SelfDirected.types';

import ConfirmModalItem from './ConfirmModalItem';
import ConfirmModalListHeader from './ConfirmModalListHeader';

interface Props {
  list: FundsPayload[];
  isLoading?: boolean;
  investedAmount: number;
  isSwitchMode?: boolean;
  visible: boolean;
  percentsMap: AllocationPercentsMap;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  isInvestMoreMode?: boolean;
}
const ConfirmModal: React.FC<Props> = ({
  list, isSwitchMode, visible, onSetVisible,
  isLoading,
  investedAmount, onSubmit, percentsMap, isInvestMoreMode
}) => {
  const { t} = useTranslation();
  const handleClose = useCallback(() => {
    if(isLoading) {
      return;
    }
    onSetVisible(false);
  }, [isLoading, onSetVisible]);

  return (
    <Modal
      visible={visible}
      onSetVisible={onSetVisible}
    >
      <Box direction='column'>
        <Text size="2xl" textAlign='center' weight={700}>
          
          {!isInvestMoreMode ? t('Confirm'): t('Confirm Selections')}
        </Text>
      </Box>
      {isSwitchMode ? (
        <>
          <Text textAlign="center" size="medium">
            {t('Updating your investment model will take 3 - 4 business days to complete.')}
      
          </Text>
          <Text textAlign="center" size="medium">{t('During that time you will not be able to make changes to your investment portfolio. This includes additional buys or sells.')      }
          </Text>
        </>
      ) : null}
      
      <Box direction='column' background="module" pad="l" round="moduleRound">
        <ConfirmModalListHeader />
        <Box direction='column'>
          {list.map((item) => (
            <ConfirmModalItem percents={percentsMap[item.id] || 0} investedAmount={investedAmount} key={item.id} {...item}/>
          ))}
          
        </Box>
        <Box 
          direction='row'
          pad={{vertical: '6px', horizontal: 'l'}}
          background="border1"
          margin={{top: 'xs'}}
          round="container2Round"
          justify="between">
          <Text size="medium" weight={700}>{t('Total')}</Text>
          <Box direction='row' width="175px">
        
            <Box>
              <Text weight={700}>{
                costFormater(investedAmount, {
                  isPrecision: true
                })
              }
              </Text>
            </Box>
              
          </Box>
        </Box>
        {!isInvestMoreMode && (
          <Box background="canvas" 
            border={{color: 'border2', size: 'small'}}
            round="container1Round"
            pad="s"
            margin={{top: 'l'}}
            elevation='default'
            style={{position: 'relative'}}>
          
            <Box  height="2px"
              round="small"
              background="warning" 
              style={{position: 'absolute', top: '-0.5px', left: '12px', right: '12px'}}>

            </Box>
    
            <Box 
              direction='row'
              align='center'
              background="warningContainer"
              border={{color: 'warning', size: 'small'}}
              round='container2Round'
              pad={{vertical: 'm', horizontal: 'l'}}>
              <Box>
                <WarningIcon />
              </Box>
              <Text margin={{left: 'xs'}}>
                {t('Actual share price and number of shares will be calculated at the time the trade is executed.')}
              </Text>
            </Box>
       
          
          </Box>
        )}
      </Box>
    
      <Box direction='row' justify='end' margin={{top: 'l'}}>
        <Box>
          <AppButton
            buttonType="secondary"
            width="140px"
            onClick={handleClose}
          >
            {t('Back')}
          </AppButton>
        </Box>
        <Box margin={{ left: 's' }}>
          <AppButton  width="140px" disabled={isLoading} onClick={onSubmit}>
            {isLoading ? <Preloader color="white" />: t('Confirm')}
          </AppButton>
        </Box>
      </Box>

    </Modal>
  );
};
export default ConfirmModal;