import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Enrollment,
  InvestmentPathCard,
  InvestmentPathType,
  LinkButton,
  Preloader,
  Text,
} from '@common-fe/common-fe';

import { INVESTMENTS_ERROR_KEY } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import theme from '@/styles/theme';

import DOCS from '../../docs';
import { InvestmentPaths } from '../../investments.types';
import ServerErrorModal from '../../modal/ServerErrorModal';
import { useInvestmentPathQuery } from '../../queries';
import PersonalInfoModal from '../PersonalInfoModal';
import { useEmployeeData } from '../PersonalInfoModal/PersonalInfoForm/hooks';

import { useInvestmentPathStore } from './stores/useInvestmentPath.store';

interface Props {
  onNext: (key?: string) => void;
  onCancel: () => void;
  stepLength: number;
  step: number;
  currentMyAccount: Enrollment;
  isLoading?: boolean;

}

const STEPS_COUNT: {
  [key: string]: number;
} = {
  MANAGED: 4,
  SELF_DIRECTED: 4,
  BROKERAGE: 4,
};
const BUTTON_WIDTH = '130px';
const InvestmentPath: React.FC<Props> = ({
  onNext,
  onCancel,
  stepLength,
  step,
  currentMyAccount,
  isLoading,

}) => {
  const BLOCKED_TYPES = useMemo(() => {
    return [InvestmentPathType.BROKERAGE];
  }, []);
  const [citizenshipRejected, setCitizenshipRejected] = useState(false);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { INVESTMENT_PATH_ERROR_TEXT, AGREEMENT_ERROR_TEXT } = INVESTMENTS_ERROR_KEY;
  const { values, handleSetValues } = useInvestmentPathStore();
  const [selectedPath, setInvestmentPath] = useState('');
  const [isAcceptedDocs, setAcceptedDocs] = useState(false);

  const [errorWarningVisible, setErrorWarningVisible] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  
  const { handleSave: handleSavePath, isLoading: isPathSaveLoading} = useInvestmentPathQuery();

  const currentError = useMemo(() => {
    if (!selectedPath) {
      return t(INVESTMENT_PATH_ERROR_TEXT);
    }
    if (!isAcceptedDocs) {
      return t(AGREEMENT_ERROR_TEXT);
    }
    return '';
  }, [
    isAcceptedDocs,
    selectedPath,
    t,
    INVESTMENT_PATH_ERROR_TEXT,
    AGREEMENT_ERROR_TEXT,
  ]);

  const handleSave = useCallback(async () => {
    await handleSavePath({
      option: selectedPath as InvestmentPaths
    });
    
  }, [handleSavePath, selectedPath]);
  const handleSubmit = useCallback(async () => {
    setSubmitted(true);
    if (!!selectedPath && isAcceptedDocs) {
      await handleSave();
      return handleSetValues({
        investmentPath: selectedPath,
        termsCondition: isAcceptedDocs,
      });
      
    }
  }, [handleSave, handleSetValues, isAcceptedDocs, selectedPath]);

  const handleClick = useCallback((link : string) => {
    if (link) {
      window.open(link, '_blank');
    }
  }, []);
  const { isFull,isLoading: isProfileLoading } = useEmployeeData();

  const handleNext = useCallback(async () => {
    setSubmitted(true);
    if (currentError) {
      return;
    }
    if (isFull) {
      if (selectedPath === InvestmentPaths.SelfDirected) {
        await handleSubmit();
        onNext(selectedPath);
      } else {
        await handleSubmit();
        onNext(selectedPath);
      }
    } else {
      setVisible(true);
    }
  }, [currentError, handleSubmit, isFull, onNext, selectedPath]);

  useEffect(() => {
    if (values?.investmentPath !== '') {
      setInvestmentPath(values?.investmentPath);
    }
    if (values?.termsCondition) {
      setAcceptedDocs(values?.termsCondition);
    }
  }, [values]);

  useEffect(() => {
    const investmentTypes = currentMyAccount?.investmentOptionTypes;
    if (
      !selectedPath && investmentTypes?.includes(InvestmentPathType.MANAGED)
    ) {
      setInvestmentPath(InvestmentPathType.MANAGED);
    }
  
  }, [currentMyAccount, selectedPath]);
  const isSelfDirected = useMemo(() => {
    return selectedPath === InvestmentPathType.SELF_DIRECTED;
  }, [selectedPath]);
  return (
    <Box direction="column" data-testid="Investment-path-wrapper-id">
      <Box flex="grow" pad={{ horizontal: '40px', bottom: '40px' }}>
        <Text size="3xl" color="blockTitle" margin={{bottom: 's'}}>{t('Let’s Get Started with Investing!')}</Text>
        <Box
          direction="row"
          justify="center"
          height="488px"
          background="module"
          round="moduleRound"
          pad="spacing24"
          margin={{ bottom: 'spacing24' }}
        >
          {isLoading
            && (
              <Box flex="grow" align="center" justify="center">
                <Preloader />
              </Box>
            )}
          {currentMyAccount?.investmentOptionTypes?.map((option) => (
            <InvestmentPathCard
              key={option}
              type={option}
              commingSoon={BLOCKED_TYPES.includes(option)}
              checked={selectedPath === option}
              onClick={() => setInvestmentPath(option)}
            />
          ))}
        </Box>
        {!isFull && !isProfileLoading && (
          <PersonalInfoModal
            visible={visible}
            onSubmit={handleSubmit}
            onSetVisible={setVisible}
            onResetRejectByCitizenship={() => setCitizenshipRejected(false)}
            onRejectByCitizenship={() => setCitizenshipRejected(true)}
          />
        )}
        
        {currentError && isSubmitted && (
          <Box align="end" width="100%" margin={{ bottom: 'spacing24' }}>
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>
              {t(currentError)}
            </Text>
          </Box>
        )}
        <Box direction="row" align="center" justify="between">
          <Box width="615px">
            <Box margin={{ top: 's' }}>
              <Checkbox
                id="isAcceptedDocs"
                className="checkbox-item"
                checked={isAcceptedDocs}
                name={t('I confirm I have read and agree to the')}
                onChange={setAcceptedDocs}
              >
                <Text>
                  {t('I confirm I have read and agree to the ')}

                  <LinkButton
                    size="M"
                    linkInlineMode
                    onClick={() => handleClick(DOCS.HSA_TERMS_AND_CODITIONS)}
                  >
                    {t('Terms and Conditions and Privacy Policies')}
                  </LinkButton>
                  {', '}
                  <LinkButton
                    size="M"
                    linkInlineMode
                    onClick={() => handleClick('https://www.intellicents.com/adv2a')}
                  >
                    {t('ADV Part 2A')}
                  </LinkButton>
                  {', '}
                  <LinkButton
                    size="M"
                    linkInlineMode
                    onClick={() => handleClick('https://www.intellicents.com/adv2b')}
                  >
                    {t('ADV Part 2B')}
                  </LinkButton>
                  {', '}
                  <LinkButton
                    size="M"
                    linkInlineMode
                    onClick={() => handleClick(DOCS.ADV_PART3)}
                  >
                    {t('ADV Part 3')}
                  </LinkButton>
                  {', '}
                  <LinkButton
                    size="M"
                    linkInlineMode
                    onClick={() => handleClick(
                      'https://legal.drivewealth.com/privacy-policy',
                    )}
                  >
                    {t('DriveWealth Privacy Policy')}
                  </LinkButton>
                  {', '}
                  <LinkButton
                    size="M"
                    linkInlineMode
                    onClick={() => handleClick(
                      'https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true&citizenship=USA',
                    )}
                  >
                    {t('W9 Agreement')}
                  </LinkButton>
                  {', '}
                  <LinkButton
                    size="M"
                    linkInlineMode
                    onClick={() => handleClick(
                      'https://apps.drivewealth.com/disclosures/?ria=Intellicents?sweep=bank',
                    )}
                  >
                    {t('DriveWealth Agreements and Disclosures')}
                  </LinkButton>
                  {isSelfDirected ? (
                    <>
                      {', '}
                      <LinkButton
                        size="M"
                        linkInlineMode
                        onClick={() => handleClick(DOCS.DIVIDEND_REINVESTMENT_DISCLOSURE)}
                      >
                        {t('Dividends Reinvestment Disclosure')}
                      </LinkButton>
                    </>
                  ): null}
                 
                  .
                </Text>
              </Checkbox>
            </Box>
          </Box>
          <Box direction="row" justify="end" align="center">
            <AppButton
              buttonType="secondary"
              width={BUTTON_WIDTH}
              onClick={() => onCancel()}
              containerStyle={{ marginRight: theme.spacings.s }}
              data-testid="cancel_button"
            >
              {t('Cancel')}
            </AppButton>

        
            <AppButton
              type="submit"
              onClick={handleNext}
              width={BUTTON_WIDTH}
              disabled={citizenshipRejected || (isSubmitted && !!currentError)}
              data-testid="submit_button"
            >
              {isPathSaveLoading ? <Preloader color="white" /> : t(
                `Next (${step}/${STEPS_COUNT[selectedPath] || stepLength})`,
              )}
            </AppButton>
          </Box>
        </Box>
        <Box background="border2" height="1px" width="100%" margin={{top: 'l', bottom: 'm'}} />
        <Text size="large" weight="bold" margin={{ bottom: 'spacing12' }}>
          {t('Fees to Invest')}
        </Text>
        <Text
          margin={{ bottom: 'spacing24' }}
          size="large"
          color="textSecondary"
        >
          {t(
            'Fees to invest depend on the investment path you choose. Managed funds will be billed .25% annually and deducted from the cash portion of your investment account on a quarterly basis. Investments are not liquidated to cover investment fees. No other investment selections have annual fees. No other fees apply.',
          )}
        </Text>
        <Text size="large" color="textSecondary">
          {t(
            'In the event the cash portion of your account does not have a sufficient balance to pay your investment service fee, a negative balance will be reflected on your account until a contribution is posted to your account by your employer or a liquidation of your investment positions in initiated by you, the account holder.',
          )}
        </Text>
        <Box background="border2" height="1px" width="100%" margin={{top: 'l', bottom: 'm'}} />
        <Text
          size="large"
          color="textSecondary"
          margin={{ bottom: 'spacing12' }}
        >
          {t(
            'Investment advisory services offered through Intellicents investment solutions, inc., an SEC registered investment adviser.',
          )}
        </Text>
      </Box>
      {/* <Prompt
        when={selectedPath !== '' || isAcceptedDocs}
        message="You have unsaved changes, are you sure you want to leave?"
      /> */}
      <ServerErrorModal
        visible={errorWarningVisible}
        onSetVisible={setErrorWarningVisible}
        onSendRequest={handleSave}
        isLoading={isPathSaveLoading}
      />
    </Box>
  );
};

export default InvestmentPath;
