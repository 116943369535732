import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton, Box, Modal, Text,
  WarningIcon, } from '@common-fe/common-fe';

interface Props {
  onClose:() => void;
  visible:boolean;

}
const NotAllowedCitizenship: React.FC<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      onSetVisible={onClose}
    >
      <Box
        pad={{ vertical: '16px', horizontal: 'medium', bottom: '0px' }}
        margin={{ top: 'small', bottom: '16px' }}
        round="container2Round"
        align="center"
        data-testid="not-allowed-citizenship"
      >
        <Box direction="column" justify="center" align="center">
          <WarningIcon size="48px" />
          <Text
            textAlign="center"
            color="textBody"
            size="2xl"
            margin={{ top: 's' }}
            weight="bold"
          >
            {t(`Unfortunately, as a citizen of the selected
country, you cannot start investing`)}
          </Text>
        </Box>

        <Text color="textBody" size="small" textAlign="center" margin={{ top: 's', bottom: 'l' }}>
          {t('Get in touch with your administrator for the details.')}
        </Text>

        <AppButton
          testId="not-allowed-citizenship_close"
          onClick={onClose}
          width="control"
        >
          {t('Close')}
        </AppButton>
      </Box>
    </Modal>
  );
};

export default NotAllowedCitizenship;
