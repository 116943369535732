import { NotificationStatus, OptionKey } from '@common-fe/common-fe';


export enum NotificationType {
  Active = 'Active',
  Dismissed = 'Dismissed',
}

export interface NotificationsSearchParams {
  currentType?: NotificationType;
  priority?: OptionKey;
  fromDate?: Date | null;
  toDate?: Date | null;
  searchString?: string;
}
export interface NotificationsPaginationSearchParams extends NotificationsSearchParams{
  page: number;
  perPage: number;
  searchString: string;
  isFromChache?: boolean;
}
export interface RawNotification {
  id:number;
  createdAt: string;
  title:string;
  description:string;
  type: string;
  status:NotificationStatus;
  priority: string;
  page:string;
  templateVariablesValues: {
    payment_method_type: string;
    claim_status: string;
    claim_id: string;
    date_received: string;
  }
}
