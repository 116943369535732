import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldTypes } from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';

import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';

import { useGetProviderByIdQuery } from '../../ClaimInfo/useProviders.query';
import { useClaimInfoStore } from '../../store/useClaimInfo.store';

import PaymentMethodField from './PaymentMethodField';

const useImmediatelyForm = (addPaymentMethod: () => void) => {
  const { values: claimInfoValues } = useClaimInfoStore();
  const currentPaymentMethod = useStore((state) => state.activeMethod);
  const { isLoading } = useGetProviderByIdQuery(claimInfoValues.provider);
  const { t } = useTranslation();

  const fields = useMemo<Field[]>(() => [
    {
      name: 'paymentMethod',
      type: FieldTypes.Node,
      label: t('Payment method'),
      isThinMode: true,
      placeholder: t('Payment method'),
      value: <PaymentMethodField onAdd={addPaymentMethod} />,
    },
  ], [t, addPaymentMethod]);
  const activities = useMemo<Option[]>(() => [{
    key: '1',
    value: 16.70,
    title: t('Healthcare FSA 2021 (Limited)'),
  }], [t]);

  const isPaymentMethodExist = useMemo(() => !!currentPaymentMethod, [currentPaymentMethod]);
  return {
    activities,
    fields,
    isLoading,
    isPaymentMethodExist,
  };
};

export default useImmediatelyForm;
