import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Heading,
  InvestmentPathCard,
  InvestmentPathType,
  Modal,
  Text,
} from '@common-fe/common-fe';

import { BuySellOptions } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import useBuySellStore from '@/modules/investments/BuySell/stores/useBuySell.store';
interface Props {
  accountId?: string;
  isSelfDirectedMode?: boolean;
  visible: boolean;
  onSetVisible: (visible: boolean) => void;
}
const OPTIONS = [InvestmentPathType.MANAGED, InvestmentPathType.SELF_DIRECTED];

const InvestmentReactivationModal: React.FC<Props> = ({
  isSelfDirectedMode,
  accountId,
  visible,
  onSetVisible,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setBuySellTabState } = useBuySellStore();
  const [currentOption, setCurrentOption] = useState<InvestmentPathType>();
  const handleRedirect = useCallback(() => {
    onSetVisible(false);
    window.scrollTo({ top: 0 });
    if (isSelfDirectedMode) {
      if (accountId && currentOption === InvestmentPathType.MANAGED) {
        history.push(ROUTES.MANAGED_SWITCH_QUESTIONNAIRE(accountId));
      } else {
        history.push(ROUTES.SELF_DIRECTED_INVEST_MORE(accountId));
      }
    } else {
      if (accountId && currentOption === InvestmentPathType.SELF_DIRECTED) {
        history.push(ROUTES.SELF_DIRECTED_SWITCH_SELECT(accountId));
      } else {
        setBuySellTabState(BuySellOptions.INVEST_MORE_FUNDS);
        history.push(`${ROUTES.BUY_SELL}?id=${accountId}`);
      }
    }
  }, [accountId, currentOption, history, isSelfDirectedMode, onSetVisible, setBuySellTabState]);
  const renderPreviousSelected = useCallback(
    (planType: InvestmentPathType) => {
      if (isSelfDirectedMode) {
        if (planType !== InvestmentPathType.SELF_DIRECTED) {
          return null;
        }
      } else {
        if (planType !== InvestmentPathType.MANAGED) {
          return null;
        }
      }
      return (
        <Box margin={{ top: 's' }}>
          <Text size="medium" weight={700}>
            Previously Selected
          </Text>
        </Box>
      );
    },
    [isSelfDirectedMode]
  );
  return (
    <Modal visible={visible} onSetVisible={onSetVisible}>
      <Box data-testid="investment-switch-model_modal" direction="column" align="center">
        <Box margin={{ bottom: 's' }}>
          <Heading level={4} size="medium">
            {t('Choose Investment model')}
          </Heading>
        </Box>

        <Box pad="l" direction="row" background="module" round="moduleRound">
          {OPTIONS.map((option) => {
            return (
              <Box
                key={option}
                data-testId={`investment-switch-model_option_${option}`}
                margin={{ right: 's' }}
              >
                <InvestmentPathCard
                  key={option}
                  type={option}
                  commingSoon={false}
                  checked={currentOption === option}
                  onClick={() => setCurrentOption(option)}
                >
                  {renderPreviousSelected(option)}
                </InvestmentPathCard>
              </Box>
            );
          })}
        </Box>
        <Box fill direction="row" justify="end" align="end" margin={{ top: 'l' }}>
          <AppButton
            onClick={() => onSetVisible(false)}
            testId="investment-switch-model_cancel"
            width="140px"
            buttonType="secondary"
          >
            {t('Cancel')}
          </AppButton>
          <Box onClick={handleRedirect} margin={{ left: 's' }}>
            <AppButton testId="investment-switch-model_switch" width="140px">
              {t('Switch')}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default InvestmentReactivationModal;
