import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { toNumber } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

interface Body {
  hashId: string;
  employeeId: string;
  organizationId: string;
  organizationPath: string;
}

interface Response {
  document_id: number;
}

const useCreateDocumentByOneTimeLink = () => {
  const { mutateAsync, isLoading } = useMutation((body: Body) => api.post(PATHS.CREATE_DOCUMENT_BY_ONE_TIME_LINK, {
    hash_id: body.hashId,
    employee_id: toNumber(body.employeeId),
    organization_id: toNumber(body.organizationId),
    organization_path: body.organizationPath,
  }));

  const createDocumentByLink = useCallback(async (value: Body) => {
    const { data } = await mutateAsync(value);

    return data as Response;
  }, [mutateAsync]);

  return {
    createDocumentByLink,
    isLoading,
  };
};

export default useCreateDocumentByOneTimeLink;
