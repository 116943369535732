import React from 'react';
import {
  AppButton,
  Box,
  Hint,
  InfoOutlineIcon,
  Inscription,
  Preloader,
  RawButton,
  Text,
  Tip,
  WarningIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import baseTheme from '@/styles/theme';
import { useDetectTextIsEllipsis } from '@/utils/hooks/useDetectTextIsEllipsis';

import { FundManagementOption } from './hooks/useFundManagementOptions';

interface StyledBoxProps {
  isOnlyOneItem?: boolean;
  isDisabled?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  ${({ theme }) => theme.shadows.default};
  transition: all 0.3s ease;
  &:hover {
    cursor: ${({ isOnlyOneItem }) => (isOnlyOneItem ? 'default' : 'pointer')};
    cursor: ${({ isDisabled }) => isDisabled && 'not-allowed'};
    ${({ theme, isOnlyOneItem }) => !isOnlyOneItem && theme.shadows.hover};
    span[data-style="ItemTitle"] {
      color: ${({ theme, isOnlyOneItem, isDisabled }) => !isOnlyOneItem && !isDisabled && theme.colors.textAccent};
      transition: all 0.3s ease;
    }
  }
  &:active {
    span[data-style="ItemTitle"] {
      color: ${({ theme, isOnlyOneItem, isDisabled }) => !isOnlyOneItem && !isDisabled && theme.colors.textAccentActive};
    }
  }
`;

const StyledInfoBox = styled(Box)`
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.iconAccent};
    }
  }
`;

interface Props {
  item: FundManagementOption;
  isOnlyOneItem?: boolean;
}

export const FundsManagementItem: React.FC<Props> = ({ isOnlyOneItem, item }) => {
  const {
    title,
    description,
    onClick,
    icon,
    buttonText,
    isButtonLoading,
    additionalContent,
    isWithHint,
    hintContent,
    isWarningIcon,
    isButtonDisabled,
    children,
  } = item;

  const descRef = React.useRef<HTMLSpanElement>(null);

  const isDescriptionEllipse = useDetectTextIsEllipsis(descRef);
  
  return (
    <Box flex>

      <RawButton
        onClick={isOnlyOneItem || isButtonDisabled ? undefined : onClick}
        disabled={isButtonDisabled}
      >
        <StyledBox
          data-testid="funds-management-item"
          direction="column"
          pad="spacing24"
          background={{ color: 'canvas' }}
          border={{ color: 'border2', size: '1px' }}
          round="container1Round"
          justify="between"
          isOnlyOneItem={isOnlyOneItem}
          isDisabled={isButtonDisabled}

        >
          <Box direction='row' justify="between">
            <Box direction="row">
              <Box
                data-testid="funds-management-item-icon"
                margin={{ right: 'spacing16' }}
                width={{ min: 'min-content' }}
              >
                {icon}
              </Box>

              <Box>
                <Inscription
                  data-testid="funds-management-item-title"
                  size="16px"
                  weight={700}
                  lineHeight="22px"
                  margin={{ bottom: 'spacing4' }}
                  data-style="ItemTitle"
                >
                  {title}
                </Inscription>
                <Tip
                  plain
                  dropProps={{ align: { bottom: 'top' } }}
                  content={(isDescriptionEllipse
                    ? (
                      <Box
                        width="max-content"
                        height={{ max: 'l' }}
                        align="center"
                        justify="center"
                        background="background3"
                        border={{ size: 'small', color: 'popup' }}
                        pad={{ horizontal: 'spacing6' }}
                      >
                        <Text size="small" color="textOnColor">{description}</Text>
                      </Box>
                    ) : undefined
                  )}
                >
                  <Text
                    data-testid="funds-management-item-description"
                    ref={descRef}
                    color="textSecondary"
                    style={{
                      lineHeight: '20px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {description}
                  </Text>
                </Tip>
              </Box>
            </Box>

            {!isOnlyOneItem && isWithHint && (
              <StyledInfoBox style={{flex: 1,}} direction="row"  align="center" justify="end" pad={{ top: 'spacing4' }}>
                <Hint
                  hideHint={!isWithHint}
                  hintWidth={180}
                  disabled={isButtonDisabled}
                  hintElement={isWarningIcon ? (
                    <WarningIcon style={{ fill: baseTheme.colors.warning }} />
                  ) : (
                    <InfoOutlineIcon />
                  )}
                >
                  {hintContent}
                </Hint>
              </StyledInfoBox>
            )}
            {additionalContent}
            {isOnlyOneItem && (
              <Box justify="center">
                <Hint
                  hideHint={!isWithHint}
                  hintWidth={240}
                  disabled={isButtonDisabled}
                  hintElement={(
                    <Box>
                      <AppButton
                        testId="funds-management-item"
                        width="180px"
                        type="button"
                        onClick={onClick}
                        disabled={isButtonDisabled}
                      >
                        {isButtonLoading
                          ? <Preloader />
                          : buttonText}
                      </AppButton>
                    </Box>
                  )}
                >
                  {hintContent}
                </Hint>
              </Box>
            )}
          </Box>
          {children}
        </StyledBox>
      </RawButton>
    </Box>
  );
};
