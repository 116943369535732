import { useState } from 'react';
import { FileRejection } from 'react-dropzone';

import { FileImage, MAX_SIZE_OF_FILE } from '@/common/constants';
import prepareFile from '@/components/controls/DropScan/prepareFile';
import useFileUploadQuery from '@/modules/transaction/hooks/useFileUploadQuery';

import useFilesInDocumentStore from './useFilesInDocument.store';

const useOnDrop = (
  onFilesAdded: (Files: FileImage[], currentDocumentId?: string) => void,
  onFilesSelect?: () => void,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSizeError, setIsSizeError] = useState(false);
  const { values: { documentId, files }, handleSetValues } = useFilesInDocumentStore();
  const { createDocumentId, uploadFiles } = useFileUploadQuery();
  const onDrop = async <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
  ) => {
    if (onFilesSelect) {
      onFilesSelect();
    }
    if (fileRejections.some((item) => item.file.size >= MAX_SIZE_OF_FILE)) {
      setIsSizeError(true);
      return;
    }
    setIsSizeError(false);
    const allFiles = [...acceptedFiles, ...fileRejections
      ? fileRejections
        .map((item) => item.file)
        .filter((item) => item.size <= MAX_SIZE_OF_FILE) : []];
    if (!allFiles.length) return;
    setIsLoading(true);
    let createdDocumentId = '';
    if (!documentId) {
      createdDocumentId = await createDocumentId();
      handleSetValues({ documentId: createdDocumentId });
    }
    const preparedFiles = allFiles.map(prepareFile);
    const currentDocumentId = documentId || createdDocumentId;
    const uploadedFiles = await uploadFiles(currentDocumentId, preparedFiles as FileImage[]);
    setIsLoading(false);
    handleSetValues({ files: [...files, ...uploadedFiles.filter((file) => file.id)] });

    onFilesAdded([
      ...uploadedFiles as unknown as FileImage[],
    ] as FileImage[], currentDocumentId);
  };

  return {
    onDrop,
    isLoading,
    isSizeError,
  };
};

export default useOnDrop;
