import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  SuccessModal,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { BuySellOptions } from '@/common/constants';
import ROUTES from '@/common/routes';
import { ConfettiSuccessIcon, StepperSidebar } from '@/components/elements';
import { Topbar } from '@/modules/core/components';
import ServerErrorModal from '@/modules/investments/modal/ServerErrorModal';
import useMyEnrollments from '@/modules/transaction/hooks/useMyEnrollments';
import theme from '@/styles/theme';
import { useQueryParams } from '@/utils/hooks';

import { useInvestor } from '../hooks';
import { useTradeInvestmentsQuery } from '../queries';

import useBuySell from './stores/useBuySell.store';
import { useFundingAmountStore } from './stores/useFundingAmount.store';
import ConfirmBuyModal from './ConfirmBuyModal';
import FundingAmount from './FundingAmount';
import { useBuySellResetData } from './hooks';
import PortfolioAllocation from './PortfolioAllocation';

const QUERY_KEY = 'id';
const BuySell = React.memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const handleReset = useBuySellResetData();
  const [confirmBuyVisible, setConfirmBuyVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [showServerErrorModal, setShowServerErrorModal] = useState(false);
  const query = useQueryParams();
  const id = useMemo(() => query.get(QUERY_KEY), [query]);
  const investor = useInvestor(id || '');
  const { currentMyAccount } = useMyEnrollments(id);
  const { openBuySellTab } = useBuySell();
  const { values } = useFundingAmountStore();

  const OPTIONS_MORE_FUNDS = useMemo(
    () => [
      { key: 1, value: t('Invest more Funds') },
      { key: 2, value: t('Portfolio Allocation') },
    ],
    [t],
  );

  const OPTIONS_TO_CASH = useMemo(
    () => [
      { key: 1, value: t('Sell Investments') },
    ],
    [t],
  );
  const OPTIONS = openBuySellTab === BuySellOptions.INVEST_MORE_FUNDS
    ? OPTIONS_MORE_FUNDS : OPTIONS_TO_CASH;
  const handlePreviousStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const { handleSave , isLoading } = useTradeInvestmentsQuery({});

  const handleBuyInvestments = useCallback(async () => {
    try {
      await handleSave({
        amount: _.toNumber(values?.fundingAmount) || 0,
      });
      setSuccessVisible(true);
      setShowServerErrorModal(false);
      setConfirmBuyVisible(false);
    } catch {
      setConfirmBuyVisible(false);
      setShowServerErrorModal(true);
    }
  }, [handleSave, values]);

  const handleGoDashboard = useCallback(() => {
    handleReset();
    if (openBuySellTab === BuySellOptions.INVEST_MORE_FUNDS) {
      history.push(`${ROUTES.INVESTMENTS}?id=${currentMyAccount?.id}`);
    } else {
      history.push(`${ROUTES.MY_ACCOUNTS_DETAILS(currentMyAccount?.id)}`);
    }
  }, [handleReset, openBuySellTab, history, currentMyAccount]);

  const handleNextStep = useCallback(async () => {
    if (step === 1 && openBuySellTab === BuySellOptions.INVEST_MORE_FUNDS) {
      setStep(2);
    } else if (step === 1) {
      setSuccessVisible(true);
      return;
    } else {
      setConfirmBuyVisible(true);
    }
    if (step === 2) {
      setStep(3);
    }
  }, [step, openBuySellTab]);

  useEffect(() => () => handleReset(), [handleReset]);

  return (
    <Box direction="row" height={{ min: '100vh' }} data-testid="BuySell-wrapper" style={{ overflow: 'hidden' }}>
      <StepperSidebar title={t('Buy/Sell')} options={OPTIONS} step={step} />
      <Box
        width="100%"
        pad={{ horizontal: '40px' }}
        align="center"
        style={{
          position: 'relative',
        }}
      >
        <Box width={theme.defaultContentWidth} height={{ min: '100vh' }}>
          <Topbar />
          {step === 1 && (
            <FundingAmount
              onCancel={handleGoDashboard}
              onNext={handleNextStep}
              accountId={id || ''}
              currentMyAccount={currentMyAccount}
              step={step}
              stepLength={OPTIONS?.length}
              successVisible={successVisible}
            />
          )}
          {step === 2 && (
            <PortfolioAllocation
              onBack={handlePreviousStep}
              onNext={setConfirmBuyVisible}
              investorId={String(investor?.data?.investorId) || ''}
              investmentAmount={values?.fundingAmount}
              currentMyAccount={currentMyAccount}
              step={step}
              stepLength={OPTIONS?.length}
              toleranceModel={investor?.data?.toleranceModel}
            />
          )}
        </Box>
      </Box>
      <ConfirmBuyModal
        visible={confirmBuyVisible}
        fundingAmount={values.fundingAmount}
        onSetVisible={setConfirmBuyVisible}
        onSubmit={handleBuyInvestments}
        isLoading={isLoading}
      />
      <SuccessModal
        header={t('Submitted!')}
        buttonText={t('Go to Investment Dashboard')}
        icon={<ConfettiSuccessIcon />}
        visible={successVisible}
        buttonStyle={{
          zIndex: 100
        }}
        onSetVisible={handleGoDashboard}
        onSubmit={handleGoDashboard}
      />
      <ServerErrorModal
        visible={showServerErrorModal}
        isLoading={isLoading}
        onSetVisible={setShowServerErrorModal}
        onSendRequest={handleBuyInvestments}
      />
    </Box>
  );
});

export default BuySell;
