import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription, UploadIcon } from '@common-fe/common-fe';

import { Modal } from '@/components';
import useProgress from '@/components/controls/DropScan/hooks/useProgress';
import { InnerProgressBar, ProgressBar } from '@/components/controls/DropScan/ProcessScan.styles';
import globalTheme from '@/styles/theme';

interface Props {
  visible: boolean;
}

export const LoadingPopup: React.FC<Props> = ({ visible }) => {
  const { t } = useTranslation();
  const { progress } = useProgress(50);

  return (
    <Modal
      testId="LoadingPopup"
      visible={visible}
      style={{
        paddingInline: '32px',
      }}
      contentStyle={{
        maxWidth: '320px',
        borderRadius: globalTheme.rounds.container2Round,
        padding: '16px',
      }}
      hideCloseButton
    >
      <Box
        data-testId="LoadingPopup_content"
        justify="center"
        align="center"
      >
        <UploadIcon size="80px" color="button" />
        <Box
          margin={{ top: 'spacing12' }}
          align="center"
        >
          <Inscription weight={700}>
            {t('Hang tight while we upload your file…')}
          </Inscription>
          <ProgressBar width="100% !important">
            <InnerProgressBar progress={progress} />
          </ProgressBar>
          <Inscription>{`${Math.floor(progress * 100)}% Uploading`}</Inscription>
        </Box>
      </Box>
    </Modal>
  );
};
