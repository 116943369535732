import React, {
  useCallback,
  useRef,   useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionItem, Anchor,
  Box,
  Drop,   MoreIcon, } from '@common-fe/common-fe';
import styled from 'styled-components';

// @ts-ignore
import TermsConditionsPdf from '@/assets/docs/Elevate HSA Investment Terms and Conditions.pdf';
// @ts-ignore
import Pdf from '@/assets/docs/HSA_agreement.pdf';
import ROUTES from '@/common/routes';
import AppButton from '@/components/controls/AppButton';
import { useHistory } from '@/modules/core/hooks';

import { FundManagementOption } from '../../../FundsManagementBanner/hooks/useFundManagementOptions';

import { FundsManagementButton } from './FundsManagementButton';

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  padding: ${({ theme }) => `${theme.spacings.spacing4} ${theme.spacings.spacing8}`};
  border-radius: ${({ theme }) => theme.spacings.spacing4};
  &:focus, &:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.buttonSecondaryContainer};
  }
`;

interface Props {
  accountId: string;
  externalButtons?: FundManagementOption[];
  isCurrentHSA?: boolean;
}

const DisclosureButton: React.FC<Props> = ({ externalButtons, accountId, isCurrentHSA }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentRef = useRef(null);
  const [visiblePopup, setVisiblePopup] = useState(false);

  const handleHide = useCallback(() => {
    setVisiblePopup(false);
  }, []);

  const handleOpenStatementsPage = useCallback(
    () => history.push(ROUTES.STATEMENTS(accountId)),
    [history, accountId],
  );

  return (
    <Box
      ref={currentRef}
    >
      <AppButton
        testId="DisclosureButton"
        buttonType="secondary"
        width="130px"
        color="grey"
        rightIcon={<MoreIcon color="iconPrimary" />}
        onClick={() => {
          if (!visiblePopup) {
            setVisiblePopup(!visiblePopup);
          }
        }}
      >
        {t('More')}
      </AppButton>
      {
        visiblePopup && (
          <Drop
            target={currentRef?.current || undefined}
            onEsc={handleHide}
            onClickOutside={handleHide}
            round="fieldRound"
            align={{ top: 'bottom', right: 'right' }}
            data-testid="Drop-content-wrapper"
          >
            <Box pad="s" round="fieldRound" border={{ color: 'border1' }} width={{ min: 'medium' }}>
              {!!externalButtons?.length
                && (
                  <Box
                    pad={{ bottom: 'spacing8' }}
                    margin={{ bottom: 'spacing8' }}
                    border={{ color: 'border1', side: 'bottom' }}
                  >
                    {externalButtons.map((button) => (
                      <FundsManagementButton
                        fundManagementButton={button}
                        key={button.title}
                        additionalAction={() => setVisiblePopup(false)}
                      />
                    ))}
                  </Box>
                )}
              {isCurrentHSA && (
                <>
                  <Box pad={{ vertical: 'spacing4', horizontal: 'spacing8' }}>
                    <AccordionItem text="Legal Documents & Disclosures">
                      <Box margin={{ left: 'xl', top: 's' }}>
                        <StyledAnchor href={Pdf} size="medium" color="textBody" target="_blank" weight={400}>
                          {t('HSA Agreement')}

                        </StyledAnchor>
                      </Box>
                    </AccordionItem>
                  </Box>

                  <Box margin={{ top: 'xxxs' }}>
                    <StyledAnchor href={TermsConditionsPdf} size="medium" color="textBody" target="_blank" weight={400}>
                      {t('Investment Terms & Conditions')}
                    </StyledAnchor>
                  </Box>
                </>
              )}
              <Box margin={{ top: 'xxxs' }}>
                <StyledAnchor onClick={handleOpenStatementsPage} size="medium" color="textBody" target="_blank" weight={400}>
                  {t('Statements')}
                </StyledAnchor>
              </Box>
            </Box>
          </Drop>
        )
      }

    </Box>
  );
};
export default DisclosureButton;
