import { useCallback, useMemo, useState } from 'react';
import { Field } from '@common-fe/common-fe';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { ValuesMap } from '@/common/types';
import { useGetEmployeeDataQuery } from '@/modules/user/queries';
import { usePutEmployeeQuery } from '@/modules/user/queries/useUpdateEmployee.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';
import { EmployeeAddress } from '@/modules/user/user.types';

interface Params {
  fields: Field[];
  onSuccess: () => void;
}
export default ({ fields, onSuccess }: Params) => {
  const [state, setState] = useState<Partial<EmployeeAddress>>({});
  const { updateEmployee } = usePutEmployeeQuery();
  const [isSubmitted, setSubmitted] = useState(false);
  const validators = useMemo(() => {
    const currentValidators: ValuesMap<object> = {};
    fields.forEach((item) => {
      if (item.validator) {
        currentValidators[item.name] = item.validator;
      }
    });
    return currentValidators;
  }, [fields]);

  const handleValidate = useCallback(
    (values: Partial<EmployeeAddress>) => {
      const schema = yup.object().shape(validators as ObjectShape);
      schema.validateSync(values, { abortEarly: false });
    },
    [validators]
  );
  const { employee } = useEmployeeStore();
  const { rawData } = useGetEmployeeDataQuery(employee?.id);

  const handleSave = useCallback(async () => {
    setSubmitted(true);
    try {
      handleValidate(state);
      await updateEmployee({
        ...rawData,
        mailing_address: {
          ...rawData?.mailing_address,
          line1: state.line1,
          line2: state.line2,
          state: state.state,
          zipcode: state.zipcode,
          city: state.city,
        },
      });
      onSuccess();
    } catch (e) {
      //
    }
  }, [handleValidate, onSuccess, rawData, state, updateEmployee]);

  return {
    state,
    setState,
    handleSave,
    isSubmitted,
  };
};
