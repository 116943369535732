import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentType, TransactionType } from '@/modules/transaction/transaction.types';

export const useGetTransactionTypeLabel = (
  type: TransactionType | PaymentType,
  isPosting?: boolean,
) => {
  const { t } = useTranslation();

  const transactionTypeLabel = useMemo(() => {
    if (type === TransactionType.CLAIM_REPAYMENT) {
      return t('Claim Repayment');
    }
    if (type === TransactionType.PAY_CLAIM || type === PaymentType.REIMBURSEMENT) {
      return t('Reimburse Me');
    }
    if (type === TransactionType.CARD_SETTLEMENT || type === PaymentType.CARD_SWIPE) {
      return t('Card swipe');
    }
    if (type === TransactionType.NOTIONAL_FUND) {
      return t('Account Funding');
    }
    if (type === TransactionType.ROLLOVER) {
      return t('Rollover');
    }
    if (type === TransactionType.HSA_ROLLOVER) {
      return t('HSA Rollover');
    }
    if (type === TransactionType.CONTRIBUTION_PAYMENT) {
      return t('Contribution');
    }
    if (type === TransactionType.HSA_INCOMING_CONTRIBUTION) {
      return t('Employee Contribution');
    }
    if (type === TransactionType.PAYROLL || type === TransactionType.NOTIONAL_PAYROLL) {
      return isPosting ? t('Contribution') : t('Payroll');
    }
    if (type === TransactionType.INTEREST) {
      return t('Interest');
    }
    if (type === TransactionType.FEE) {
      return t('Fee');
    }
    if (type === TransactionType.PAYMENT) {
      return t('Payment');
    }
    if (type === TransactionType.TRANSFER_ADJUSMENT) {
      return t('Transfer Adjustment');
    }
    if (type === TransactionType.INTERNAL_TRANSFER_OF_ASSETS
      || type === TransactionType.TRANSFER_OF_ASSETS) {
      return t('Transfer of Assets');
    }
    if (type === TransactionType.INVESTMENT
        || type === TransactionType.BUY_INVESTMENT
        || type === TransactionType.SELL_INVESTMENT) {
      return t('Investment');
    }
    if (type === PaymentType.BILL_PAY) {
      return t('Bill Pay');
    }
    if (type === TransactionType.HSA_INCOMING_CONTRIBUTION_REVERSE
        || type === TransactionType.PAYROLL_REVERSE
    ) {
      return t('Reverse Contribution');
    }
    if (type === TransactionType.HSA_ROLLOVER_REVERSE) {
      return t(' Reverse Rollover');
    }
    return '-';
  }, [t, type, isPosting]);

  const transactionDateLabel = useMemo(() => {
    if (type === PaymentType.REIMBURSEMENT || type === PaymentType.BILL_PAY) {
      return t('Service date:');
    }
    if (type === PaymentType.CARD_SWIPE) {
      return t('Card swipe date:');
    }
    return !isPosting ? t('Transaction date:') : t('Date:');
  }, [t, type, isPosting]);

  return {
    transactionTypeLabel,
    transactionDateLabel,
  };
};
