import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CrossIcon,
  Enrollment,
  Inscription,
  NavigationRightIcon,
  Preloader,
  PriceInput,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import AppButton from '@/components/controls/AppButton';
import { useFundingAmountStore } from '@/modules/investments/StartInvesting/FundingAmount/stores/useFundingAmount.store';
import EnrollmentItem, { ENROLLMENT_ITEM_WIDTH_L } from '@/modules/transaction/components/EnrollmentItem/EnrollmentItem';
import theme from '@/styles/theme';

import DisclosureFooter from '../../BuySell/DisclosureFooter';
import { useInvestmentsValidation } from '../../hooks';
import ServerErrorModal from '../../modal/ServerErrorModal';
import { useInvestmentFundingQuery } from '../../queries';

export const PriceControl = styled(PriceInput)`
  ${() => theme.controls.mediumSize};
  min-height: 40px;
`;
interface Props {
  onBack: () => void;
  onNext: () => void;
  onSetInvestmentAmount: (amount: number) => void;
  currentMyAccount: Enrollment;
  stepLength: number;
  step: number;
}

const FundingAmount: React.FC<Props> = ({
  onBack, onNext, currentMyAccount, stepLength, step,  onSetInvestmentAmount,
}) => {
  const { t } = useTranslation();
  const { values, handleSetValues } = useFundingAmountStore();
  const [investingAmount, setInvestingAmount] = useState('');

  const [showErrors, setShowErrors] = useState(false);
  const [errorWarningVisible, setErrorWarningVisible] = useState(false);
  const availableToInvest = useMemo(() => {
    if ((currentMyAccount?.available - currentMyAccount?.investmentThreshold) > 0) {
      return currentMyAccount?.available - currentMyAccount?.investmentThreshold;
    }
    return 0;
  }, [currentMyAccount]);
  const {
    handleSave,
    isSuccess,
    isLoading,
  } = useInvestmentFundingQuery(
    {
      onSuccess:  () => {
        const parsedAmount = _.toNumber(investingAmount);
        if(!_.isNaN(parsedAmount)) {
          onSetInvestmentAmount(parsedAmount);
        }
        setTimeout(() => onNext(), 1000);
      },
      onError: () => {
        setErrorWarningVisible(true);
      },
    }
    
  );
  const { error } = useInvestmentsValidation({
    investingAmount,
    availableToInvest,
    minimumInvestmentAmount: currentMyAccount?.minimumInvestmentAmount,
  });
  const handleSubmit = useCallback(() => {
    setShowErrors(true);
    if (error) {
      return null;
    }

    if (isSuccess) {
      return onNext();
    }
    if (!!investingAmount && availableToInvest >= _.toNumber(investingAmount)) {
      handleSetValues({
        fundingAmount: investingAmount,
      });
      handleSave({
        amount: investingAmount
      });
    }
    return {};
  }, [error, isSuccess, investingAmount, availableToInvest, onNext, handleSetValues, handleSave]);

  useEffect(() => {
    if (values?.fundingAmount) {
      setInvestingAmount(values?.fundingAmount);
    }
  }, [values]);

  return (
    <Box direction="column" data-testid="Funding-wrapper-id" pad={{ horizontal: '40px' }}>
      <Box flex="grow">
        <Text size="3xl" color="blockTitle" margin={{bottom: 's'}}>{t('Initial Funding Amount')}</Text>
        <Text size="large" margin={{ bottom: 'spacing24' }}>{t('Move money from your cash account to your investment account in a few easy steps.')}</Text>
        <Box direction="row" background="module" round="moduleRound" pad="spacing24" margin={{ bottom: 'spacing24' }}>
          {
            currentMyAccount
            && (
              <EnrollmentItem
                enrollment={currentMyAccount}
                investingMode
                isDateless
                isHoverCardNumber
                wrapperStyle={{ minWidth: ENROLLMENT_ITEM_WIDTH_L }}
              />
            )
          }
          <Box direction="row" align="center" justify="center" margin={{ horizontal: 'spacing12' }}>
            <NavigationRightIcon />
          </Box>
          <Box
            direction="column"
            pad={{ horizontal: 'spacing24', top: '28px', bottom: '18px' }}
            background="canvas"
            round="container1Round"
            // width={{ max: '100%', min: '500px' }}
            border={{ size: 'small', color: 'border2' }}
            elevation="default"
            data-testid="EnrollmentItem-wrapper"
          >
            <Text margin={{ bottom: 'spacing12' }} size="xxlarge">{t('Initial Funding Amount')}</Text>
            <Text margin={{ bottom: 'spacing24' }} size="medium">{t('The amount will be transferred from your cash account to your investment account.')}</Text>
            <Box direction="row" align="center">
              <Box width="280px" margin={{ right: 'spacing24' }} data-testid="Price-control-wrapper-id">
                <PriceControl
                  name="Funding amount"
                  placeholder={t('Enter $ amount')}
                  onChange={setInvestingAmount}
                  onIconClick={() => setInvestingAmount('')}
                  value={investingAmount}
                  disabled={isLoading || isSuccess}
                  rightIcon={(
                    <Box pad={{ left: 'spacing8' }} style={{ cursor: 'pointer' }}>
                      <CrossIcon size="14px" color="iconPrimary" />
                      
                    </Box>
                  )}
                  autoFocus
                />
              </Box>
              <Inscription onClick={() => setInvestingAmount(String(availableToInvest))} cursor="pointer" size="medium" color="textAccent">{t('Maximum Allowed')}</Inscription>
            </Box>
          </Box>
        </Box>
        {error && showErrors && (
          <Box align="end" width="100%" margin={{ bottom: 'spacing24' }}>
            <Text size="medium" color="danger" style={{ lineHeight: '20px' }}>{t(error)}</Text>
          </Box>
        )}

        <Box direction="row" align="center" justify="end">
          <Box direction="row" justify="end" align="center">
            <AppButton
              onClick={() => onBack()}
              buttonType="secondary"
              containerStyle={{ marginRight: theme.spacings.s }}
              width="130px"
            >
              {t('Back')}
            </AppButton>
            <AppButton
              type="submit"
              onClick={() => handleSubmit()}
              successMode={isSuccess}
              width="200px"
            >
              {
                isLoading
                  ? <Preloader color="white" />
                  : t(`Next (${step}/${stepLength})`)
              }
            </AppButton>
          </Box>
        </Box>
        <DisclosureFooter /> 
      </Box>
      <ServerErrorModal
        visible={errorWarningVisible}
        onSetVisible={setErrorWarningVisible}
        onSendRequest={() => handleSave({
          amount:investingAmount
        })}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default FundingAmount;
