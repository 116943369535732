import React, {
  useCallback,
  useEffect, useMemo,   useState, } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, CalendarIcon, CalendarPickerIcon,   DateRangeDropdown,
  FlexList,
  OptionKey,
  PaginationBar,
  SelectDropdown,
  Text, usePaginationParams,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import {
  ALL_OPTION,
} from '@/common/constants';
import { InvestmentActivityStatus } from '@/modules/investments/investments.types';

import { useInvestmentsHoldings } from '../../hooks';

import { EmptyInvestmentActivitiesListPlaceholder } from './EmptyInvestmentActivitiesListPlaceholder';
import { useInvestmentActivitiesDatesRange, useInvestmentActivitiesList } from './hooks';

const stateAllToggling = (statuses: OptionKey[]) => {
  const minStatusesLength = 2;
  const isStatusAllSelected = statuses[statuses.length - 1] === ALL_OPTION.value;

  if (statuses.length === 0 || isStatusAllSelected) {
    return [ALL_OPTION.value];
  }

  const isStatusAllChangedToAnother = statuses.length === minStatusesLength
    && statuses[0] === ALL_OPTION.value;

  if (isStatusAllChangedToAnother) {
    return statuses.filter((status) => status !== ALL_OPTION.value);
  }

  return statuses;
};

interface Props {
  id?: string;
}
const DELIMITED = ',';
const COMPLETED_STATUS_KEY = `${InvestmentActivityStatus.FILLED}${DELIMITED}${InvestmentActivityStatus.PAID}`;
const InvestmentActivitiesList: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<OptionKey[]>([COMPLETED_STATUS_KEY]);
  const [initialData, setInitialData] = useState(false);
  const { onChangeDates, startDate, endDate } = useInvestmentActivitiesDatesRange();

  const statusValues = useMemo(() => stateAllToggling(statuses), [statuses]);

  const hasStatus = useMemo(
    () => statusValues.length > 0
      && !statusValues.some((status) => status === ALL_OPTION.value),
    [statusValues],
  );

  const {
    page, setPage,
    setPerPage, perPage,
  } = usePaginationParams();
  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    statuses,
    startDate,
    endDate,
  ]);
  const { details } = useInvestmentsHoldings(id);
  const removeDuplicationStatus = useMemo(() => { 
    let result: string[] = [];
    statusValues.forEach((item) => {
      const currentItem = item as string;
      if(currentItem.includes(DELIMITED)) {
        const splitedArray = currentItem.split(DELIMITED);
        result = [...result, ...splitedArray];
      } else {
        result.push(currentItem);
      }
    });
    return [...new Set(result)];
  }, [statusValues]);
 
  const {
    data,
    headers,
    isLoading,
    formatedList,
    total,
  } = useInvestmentActivitiesList(
    {
      investor_id:details?.investorId ? _.toNumber(details?.investorId): undefined,
      page: page - 1,
      size: perPage,
      status: (hasStatus ? removeDuplicationStatus.join(',') : ''),
      date_time_start: startDate && dayjs(startDate).startOf('day').format(),
      date_time_end: endDate && dayjs(endDate).endOf('day').format(),
    },
  );
  const statusesOptions = useMemo(() => {

    return [ALL_OPTION, {
      key: COMPLETED_STATUS_KEY,
      value: InvestmentActivityStatus.FILLED,
      title: t('Completed'),
    }, {
      key: InvestmentActivityStatus.EXPIRED,
      value: InvestmentActivityStatus.EXPIRED,
      title: t('Expired'),
    }];
  }, [t]);

  const isAnyInputFilled = useMemo(() => statuses.length || !!startDate || !!endDate, [
    startDate,
    endDate,
    statuses.length,
  ]);

  const isDataInclude = useMemo(() => !!data.length, [data]);
  const isInitialData = useCallback(() => (
    (!data.length && !isAnyInputFilled) ? setInitialData(false) : setInitialData(true)),
  [data.length, isAnyInputFilled]);
  useEffect(() => {
    isInitialData();
  }, [isInitialData]);

  return (
    <Box
      direction="column"
      data-testid="Investment_activities_list"
      background="module"
      round="container1Round"
      pad={{ bottom: initialData ? 'spacing12' : 'spacing24' }}
    >
      <Box
        direction="row"
        align="center"
        margin={{ bottom: 'spacing4' }}
        pad={{ horizontal: 'spacing24', top: 'spacing24' }}
      >
        <Box direction="row" style={{ flex: 1 }}>
          <Text size="large" weight="bold">{t(`Investment activities: ${total}`)}</Text>
        </Box>
        <Box width="200px">
          <SelectDropdown
            disabled={!initialData}
            id="status"
            name={t('Status')}
            prefix="Status"
            allMode
            options={statusesOptions}
            values={statusValues}
            onChangeValues={setStatuses}
          />
        </Box>
        <Box margin={{ left: 'spacing8' }}>
          <DateRangeDropdown
            disabled={!initialData}
            prefix={t('Period:')}
            className="period-picker"
            onChange={onChangeDates}
            startDate={startDate}
            endDate={endDate}
            icon={(
              <Box
                align="center"
                justify="center"
                style={{ fontSize: '18px' }}
                margin={{ bottom: !initialData ? 'spacing4' : undefined, left: 'xs' }}>
                {!initialData ? (
                  <CalendarPickerIcon />
                ) : (
                  <CalendarIcon size="16px" color="iconPrimary" />
                )}
              </Box>
            )}
          />
        </Box>
      </Box>
      <FlexList
        headers={headers}
        rows={formatedList}
        total={total}
        moreCount={perPage}
        loading={isLoading}
        placeholder={!initialData
          && (
            <EmptyInvestmentActivitiesListPlaceholder title="Investment activities" />
          )}
        footer={isDataInclude
          && (
            <PaginationBar
              page={page}
              total={total}
              pageSize={perPage}
              onChangePage={setPage}
              onChangePageSize={setPerPage}
            />
          )}
      />
    </Box>
  );
};

export default InvestmentActivitiesList;
