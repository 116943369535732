import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, FormField,   fromNow,
  Notification,
  NotificationStatusColorType,
  NotificationStatusIcon, Text, } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';

import { NotificationType } from '../notification.types';

import { Label, StyledContent, TextInputWrap } from './NotificationDetails.styles';

export interface Props {
  data?: Notification | null;
  dismissNotification: (id?: string) => void;
  onCancel: () => void;
  onGoToPage?: () => void;
  isPageLinkHidden?: boolean;
  currentType?: NotificationType;
}

const NEEDS_YOUR_HELP = 'Needs your help';
const FOR_YOUR_INFORMATION = 'For Your Information';
const IMPORTANT = 'Important';
const EMPTY_STATE_TEXT = '-';
const NotificationDetails: React.FC<Props> = (
  {
    data, dismissNotification, onCancel, onGoToPage,
    currentType, isPageLinkHidden,
  },
) => {
  const { t } = useTranslation();
  let statusTip;
  if (data?.status === NotificationStatusColorType.WARNING) {
    statusTip = NEEDS_YOUR_HELP;
  } else if (data?.status === NotificationStatusColorType.SUCCESS) {
    statusTip = FOR_YOUR_INFORMATION;
  } else {
    statusTip = IMPORTANT;
  }
  return (
    <>
      <Box background="module" pad="medium" round="container1Round" data-testid="ContactModal-view-wrapper">
        <StyledContent background="canvas" pad="medium" round="container1Round" border={{ size: 'small', color: 'border2' }} elevation="default">
          <TextInputWrap viewMode label={<Label viewMode>{t('Title')}</Label>}>
            <FormField>
              <Text weight="bold" data-testid="ContactModal-firstName-label">{data?.text || EMPTY_STATE_TEXT}</Text>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Description')}</Label>}>
            <FormField>
              <Text data-testid="ContactModal-firstName-label">{data?.additionalText || EMPTY_STATE_TEXT}</Text>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Priority')}</Label>}>
            <FormField>
              <Box direction="row">
                <NotificationStatusIcon status={data?.status} />
                <Text data-testid="ContactModal-firstName-label">{statusTip || EMPTY_STATE_TEXT}</Text>
              </Box>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Date created')}</Label>}>
            <FormField>
              <Text data-testid="ContactModal-firstName-label">{data?.createdAt ? fromNow(data?.createdAt) : EMPTY_STATE_TEXT}</Text>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>{t('Employer / Partner')}</Label>}>
            <FormField>
              <Text data-testid="ContactModal-firstName-label">{t('Elevate')}</Text>
            </FormField>
          </TextInputWrap>
        </StyledContent>
      </Box>
      <Box direction="row" justify="between" align="center" pad={{ top: 'medium' }}>
        {currentType !== NotificationType.Dismissed ? (
          <AppButton
            testId="ContactModal-to-edit"
            buttonType="secondary"
            onClick={() => {
              dismissNotification(data?.id);
              onCancel();
            }}
          >
            {t('Dismiss')}
          </AppButton>
        ) : (
          <Box />
        )}

        <Box>
          {!isPageLinkHidden && (
            <AppButton
              testId="ContactModal-to-edit"
              onClick={() => {
                if (onGoToPage) {
                  onGoToPage();
                }
              }}
            >
              {t('Go To Page')}
            </AppButton>
          )}

          {/* <RawButton
            label={<Text color="textOnColor" weight="bold">Contact Support</Text>}
            primary
            onClick={() => {}}
          /> */}
        </Box>
      </Box>
    </>
  );
};
export default NotificationDetails;
