import { useMemo } from 'react';

import { AccountAccessPayload, useStore as useAccountAccessStore } from '../../AccountAccess/useAccountAccess.store';
import useCommunicationSettingsStore, { CommunicationSettingsPayload } from '../../CommunicationSettings/useCommunicationSettings.store';
import { ProfileInformationPayload,useStore as useProfileInformationStore } from '../../ProfileInformation/useProfileInformation.store';

export type SignUpPayload = AccountAccessPayload
& ProfileInformationPayload
& CommunicationSettingsPayload;
export default () => {
  const accessAccount = useAccountAccessStore((state) => state.state);
  const profileInformation = useProfileInformationStore((state) => state.state);
  const communicationSettings = useCommunicationSettingsStore();
  const setAccessAccountErrors = useAccountAccessStore((state) => state.setErrors);
  const setShowProfileInformationErrors = useProfileInformationStore(
    (state) => state.setShowErrors,
  );
  const setShowAccessAccountErrors = useAccountAccessStore(
    (state) => state.setShowErrors,
  );
  const setProfileInformationErrors = useProfileInformationStore((state) => state.setErrors);
  const state = useMemo(() => ({
    ...profileInformation,
    ...accessAccount,
    ...communicationSettings,
  }), [accessAccount, profileInformation, communicationSettings]);

  return {
    profileInformation,
    accessAccount,
    communicationSettings,
    state,
    setShowAccessAccountErrors,
    setAccessAccountErrors,
    setShowProfileInformationErrors,
    setProfileInformationErrors,
  };
};
