import React, { useMemo } from 'react';
import { Box, Image, Inscription, useCountryByNumber } from '@common-fe/common-fe';

import { CommunicationOption } from '@/modules/user/components/ManageProfile/components/CommunicationPreferences/communicationPreferences.types';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

const useTextMessagesOptions = () => {
  const { employee } = useEmployeeStore();
  const { icon } = useCountryByNumber(employee?.preferredPhone?.number);

  const mobilePhone = employee?.phone?.number;

  const textMessagesOptions = useMemo((): CommunicationOption[] => [
    {
      key: 'mobilePhone',
      title: 'Mobile phone',
      value: mobilePhone && (
        <Box direction="row" align="center">
          <Image width="16px" height="16px" src={icon} />
          <Inscription margin={{ left: 'spacing4' }}>
            {mobilePhone}
          </Inscription>
        </Box>
      ),
      isActive: !!mobilePhone && employee?.isVerifiedPhoneNumber,
      isSelected: !!mobilePhone,
    }
  ], [mobilePhone, icon, employee]);

  return { textMessagesOptions };
};

export default useTextMessagesOptions;
