import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';

import { FileImage } from '@/common/constants';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import { StepperSidebar } from '@/components/elements';
import { Topbar, ZendeskMoving } from '@/modules/core/components';
import { FileUploadContent } from '@/modules/transaction/components/ReimburseMe/FileUpload/FileUploadContent';
import useSetFileFromDocumentStream from '@/modules/transaction/components/ReimburseMe/hooks/useSetFileFromDocumentStream';
import theme from '@/styles/theme';

import { clearScannedDocument } from './FileScan/clearScannedDocument';
import { ParsedFileByOcr } from './FileScan/fileScan.types';
import { useFileUploadPopupStore } from './FileUploadPopup/store';
import useRemoveFileQuery from './queries/useRemoveFile.query';
import useFilesStore from './store/useFiles.store';
import ClaimInfo from './ClaimInfo';
import FileScan from './FileScan';
import FileUpload from './FileUpload';
import Receipts from './Receipts';

const StyledBox = styled(Box)`
  flex: 1;
`;

enum Steps {
  DRAG_RECEIPTS = 1,
  FILE_UPLOAD = 2,
  FILE_SCAN = 3,
  CLAIM_INFO = 4,
}

interface Props {
  isBillPayMode?: boolean;
}

const ReimburseMe: React.FC<Props> = React.memo(({ isBillPayMode }) => {
  const { removeFile } = useRemoveFileQuery();
  const addFileButtonRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [step, setStep] = useState(Steps.DRAG_RECEIPTS);
  const [scannedDocument, setScannedDocument] = useState<ParsedFileByOcr | undefined>();
  const reUploadedOcrFile = useFileUploadPopupStore((store) => store.reUploadedOcrFile);
  const isScanningCanceled = useFileUploadPopupStore((store) => store.isScanningCanceled);
  const resetReUploadedFile = useFileUploadPopupStore((store) => store.handleReset);
  const setFiles = useFilesStore((state) => state.setFiles);
  const activeFile = useFilesStore((state) => state.activeFile);
  useSetFileFromDocumentStream();
  const { t } = useTranslation();
  const OPTIONS = useMemo(
    () => [
      { key: 1, value: t('File Upload'), hidden: step > Steps.DRAG_RECEIPTS },
      { key: 2, value: t('File Upload'), hidden: step < Steps.FILE_UPLOAD || step >= Steps.FILE_SCAN },
      { key: 3, value: t('File Upload'), hidden: step < Steps.FILE_SCAN },
      { key: 4, value: t('Claim Info') },
    ],
    [t, step],
  );

  const handlePreviousStep = useCallback(() => {
    if (step === Steps.CLAIM_INFO) {
      setStep(Steps.DRAG_RECEIPTS);
      setScannedDocument(undefined);
    } else {
      setStep(step - 1);
    }
  }, [step]);
  const handleUploadFiles = useCallback((values: FileImage[]) => {
    setFiles(values);

    if (values.length) {
      setStep(Steps.FILE_UPLOAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNavigateToFileUploadStep = useCallback(() => setStep(Steps.FILE_UPLOAD), []);

  const handleNextStep = useCallback(async () => {
    if (step === Steps.DRAG_RECEIPTS) {
      setStep(Steps.CLAIM_INFO);
      return;
    }
    if (step === Steps.FILE_SCAN) {
      setStep(Steps.CLAIM_INFO);
      return;
    }
    setStep(Steps.FILE_SCAN);
  }, [step]);

  const { values: { files }, handleReset, handleSetValues } = useFilesInDocumentStore();
  useEffect(() => {
    setIsMounted(true);
    return () => handleReset();
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const deleteFile = useCallback((id: string) => {
    const isScannedFileDeleted = files[activeFile].id === id;
    if (isScannedFileDeleted) {
      const deletedScannedDocument = clearScannedDocument(scannedDocument);
      setScannedDocument(deletedScannedDocument);
    }
    handleSetValues({ files: files.filter((file) => file.id !== id) });
    removeFile(id);
  }, [files, handleSetValues, removeFile, activeFile, scannedDocument]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (reUploadedOcrFile && !isScanningCanceled) {
      setScannedDocument(reUploadedOcrFile);
      setSubLoading(true);
      timer = setTimeout(() => {
        setSubLoading(false);
      }, 500);
    }

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUploadedOcrFile]);

  useEffect(() => () => resetReUploadedFile(), [resetReUploadedFile]);

  return (
    <Box direction="row" flex="grow" data-testid="Reimburse-me-wrapper">
      <StepperSidebar title={t(isBillPayMode ? 'Pay a Bill' : 'Reimburse Me')} options={OPTIONS} step={step} />
      <StyledBox
        direction="row"
        justify="center"
        height={{
          min: '100vh',
        }}
        style={{
          display: 'block',
          position: 'relative',
        }}
      >
        <StyledBox
          height={{
            min: '100%',
          }}
        >
          <Box direction="row" justify="center">
            <Box
              flex="grow"
              pad={{ horizontal: '40px' }}
              width={{ max: theme.defaultContentWidth }}
            >
              <Topbar />
            </Box>
          </Box>
          {step === Steps.DRAG_RECEIPTS && isMounted && (
            <Receipts
              onSetFiles={handleUploadFiles}
              onNext={handleNextStep}
              onNavigateToFileUpload={handleNavigateToFileUploadStep}
              isBillPayMode={isBillPayMode}
              handleDeleteFile={deleteFile}
            />
          )}

          {step === Steps.FILE_UPLOAD && (
            <FileUpload
              onBack={handlePreviousStep}
              onNext={handleNextStep}
              handleDeleteFile={deleteFile}
            />
          )}

          {step === Steps.FILE_SCAN && (
            <FileScan
              onNext={handleNextStep}
              onGetScannedDocument={setScannedDocument}
            />
          )}
          {step === Steps.CLAIM_INFO && (
            <>
              {subLoading ? (
                <Preloader />
              ) : (
                <ClaimInfo
                  onBack={handlePreviousStep}
                  scannedDocument={scannedDocument}
                  addFileButtonRef={addFileButtonRef}
                  isBillPayMode={isBillPayMode}
                >
                  <FileUploadContent
                    isSmallViewVersion
                    addFileButtonRef={addFileButtonRef}
                    noReceiptsTitle={t('There are no receipts uploaded')}
                    handleDeleteFile={deleteFile}
                  />
                </ClaimInfo>
              )}
            </>
          )}
        </StyledBox>
      </StyledBox>
      <ZendeskMoving translocation={500} />
    </Box>
  );
});

export default ReimburseMe;
