import React, { useCallback, useState } from 'react';
import { Box, FlexList } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { useInvestmentsHoldings } from '../hooks';
import { WarningUpdateRiskPortfolioModal } from '../UpdateRiskPortfolio/WarningUpdateRiskPortfolioModal';

import InvestmentsBreakdownChart from './InvestmentsBreakdownChart';
import InvestmentsBreakdownDetails from './InvestmentsBreakdownDetails';

interface Props {
  accountId?: string;
  investorId?: string | number;
  toleranceModel?: string;
  isPendingTransactionsPresent?: boolean;
}

const LIST_COUNT_NUMBER = 100;
const ALLOWED_NUMBER_STOKS_WITHOUT_SCROOL = 5;

const InvestmentsBreakdown: React.FC<Props> = ({
  accountId,
  investorId,
  toleranceModel,
  isPendingTransactionsPresent,
}) => {
  const history = useHistory();
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const {
    isLoading: isStocksLoading,
    formattedList,
    donutResults, holdingsData, 
    details, totalHoldingsAmount,
  } = useInvestmentsHoldings(accountId);
  const handleUpdateRiskModel = useCallback(() => {
    if (isPendingTransactionsPresent) {
      setIsWarningModalVisible(true);
      return;
    }
    history.push(`${ROUTES.INVESTMENTS_UPDATE_RISK_PORTFOLIO}?accountId=${accountId}&investorId=${investorId}`);
  }, [accountId, history, investorId, isPendingTransactionsPresent]);

  return (
    <>
      <Box direction="row" justify="between" background="canvas" round="container1Round" pad="s" border={{ size: 'small', color: 'border2' }} elevation="default">
        <Box width="100%">
          <Box direction="row" background="module" round="container1Round" pad="s">
            <InvestmentsBreakdownChart
              data={donutResults}
              holdingsData={holdingsData}
              investmentValue={details.portfolioBalance}
              totalHoldingsAmount={totalHoldingsAmount}
            />
            <Box height={{ max: '260px' }} style={{ flex: 1, overflow: 'auto' }}>
              <FlexList
                rows={formattedList}
                headers={[]}
                loading={isStocksLoading}
                moreCount={LIST_COUNT_NUMBER}
                stocksMode
                pad={{ left: 's', right: formattedList?.length > ALLOWED_NUMBER_STOKS_WITHOUT_SCROOL ? 's' : '0', vertical: '0' }}
              />
            </Box>

          </Box>
        </Box>
        <InvestmentsBreakdownDetails
          accountId={accountId}
          availableToInvest={details.availableToInvest}
          accountBalance={details.availableBalance}
          accountNumber={details.accountNumber}
          riskModel={toleranceModel}
          updateRiskModel={handleUpdateRiskModel}
        // lastQuarter={stocksFormatedData.lastQuarter}
        />
        {/* <Box direction="column" margin={{ left: 'spacing24' }} width={{ min: '225px' }}>
        <Box direction="column" margin={{ top: 'spacing4', bottom: 'spacing12' }}>
          <CountUp bold size="2xl" number={availableToInvest || 0} duration={1000} />
          <Text color="textSecondary" size="medium">{t('Available to Invest')}</Text>
        </Box>
        <Box margin={{ bottom: 'spacing12' }}>
          <CountUp bold size="2xl" number={investmentValue || 0} duration={1000} />
          <Text color="textSecondary" size="medium">{t('Investment Value')}</Text>
        </Box>
        <Box margin={{ bottom: 'spacing12' }}>
          <Text weight="bold" size="2xl">{costFormater(accountBalance, true)}</Text>
          <Text color="textSecondary" size="medium">{t('Account Balance')}</Text>
        </Box>
        <Box>
          <Text weight="bold" size="2xl">{lastQuarter ? costFormater(lastQuarter, true) : '-'}</Text>
          <Text color="textSecondary" size="medium">{t('Last Quarter')}</Text>
        </Box>
      </Box> */}
      
      </Box>
      <WarningUpdateRiskPortfolioModal
        visible={isWarningModalVisible}
        onSetVisible={setIsWarningModalVisible}
      />
    </>
  );
};

export default InvestmentsBreakdown;
