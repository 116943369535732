import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import routes from '@/common/routes';
import UploadFilePageWrapper from '@/components/wrappers/UploadFilePageWrapper';
import useUploadFileByQrLinkStore from '@/modules/transaction/components/ReimburseMe/UploadFileQRCode/store/useUploadFileByQrLink.store';
import { useTimer } from '@/utils/hooks';
import { getTimeDifferenceInMilliseconds } from '@/utils/modifiers';

import useUploadFileByQrLinkParams from './hooks/useUploadFileByQrLinkParams';
import { UploadFileByQrLinkError } from './UploadFileByQrLinkError';
import { UploadFileByQrLinkProcess } from './UploadFileByQrLinkProcess';
import { UploadFileByQrLinkSuccess } from './UploadFileByQrLinkSuccess';
import { QR_CODE_VALID_TIMER_KEY, Step } from './uploadQrFile.types';

export const UploadFileByQrLink: React.FC = () => {
  const [step, setStep] = useState(Step.PROCESS);
  const [popupAvailable, setPopupAvailable] = useState(true);
  const { setState, handleReset } = useUploadFileByQrLinkStore();
  const history = useHistory();

  const {
    docId,
    employeeId,
    qrId,
    validTo,
    handleResetStorage,
  } = useUploadFileByQrLinkParams();

  const { isTimeElapsed, setTimer } = useTimer(QR_CODE_VALID_TIMER_KEY);

  useEffect(() => {
    setState({
      docId,
      employeeId,
      qrId,
      validTo
    });
    if (validTo) {
      setTimer(getTimeDifferenceInMilliseconds(validTo));
    }
    history.push(routes.DOWNLOAD_FILE_BY_QR_LINK);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCurrentTimeElapsed = getTimeDifferenceInMilliseconds(validTo);
    if (isTimeElapsed && isCurrentTimeElapsed < 0) {
      handleReset();
      handleResetStorage();
      setStep(Step.ERROR);
    }
  }, [isTimeElapsed, validTo, handleReset, handleResetStorage]);

  return (
    <UploadFilePageWrapper>
      {step === Step.PROCESS && (
        <UploadFileByQrLinkProcess
          setStep={setStep}
          isPopupAvailable={popupAvailable}
          setIsPopupAvailable={setPopupAvailable}
        />
      )}
      {step === Step.SUCCESS && <UploadFileByQrLinkSuccess setStep={setStep} />}
      {step === Step.ERROR && <UploadFileByQrLinkError />}
    </UploadFilePageWrapper>
  );
};
