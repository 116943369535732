import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,   FlexControlledForm, Hint,
  Preloader, SuccessModal, Text,
} from '@common-fe/common-fe';
import { Option } from '@common-fe/common-fe';
import * as yup from 'yup';

import regexp from '@/common/regexp';
import {
  PendingModal,
} from '@/components';
import AppButton from '@/components/controls/AppButton';
import { LimitedAccessLabel } from '@/components/elements';
import { ModalWrapper } from '@/components/wrappers';
import { useSnackbar } from '@/modules/core/hooks';
import { CardHolderDto } from '@/modules/transaction/components/Cards/Cards.types';
import { AddCardDependentForm, DependentFormValue } from '@/modules/transaction/components/Cards/components/AddCardDependentModal/AddCardDependentForm';
import { useGetCardsQuery } from '@/modules/transaction/hooks/useGetCards.query';
import { useGetRelationshipTypesQuery } from '@/modules/transaction/hooks/useGetRelationshipTypes.query';
import { useAuthStore } from '@/modules/user/stores';
import { capitalizeFirstLetter } from '@/utils';

import { useCardDependentForm } from './hooks/useCardDependentForm';
import { useGetDependents } from './hooks/useGetDependents';
import { useSetDependent } from './hooks/useSetDependent';
import { useTypeForm } from './hooks/useTypeForm';
import { useAddCardDependentMutation, useUpdateCardDependentMutation } from './queries/useAddDependentCard.query';
import { StyledContent } from './AddCardDependentModal.styles';

interface DiscardConfirmationState {
  visible: boolean,
  type: string | null,
}

interface Props {
  isLimitedAccess?: boolean;
}

const CREATE_DEPENDENT_TYPE = 'new';

export const AddCardDependentModal: React.FC<Props> = ({ isLimitedAccess }) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [dependentModalVisible, setDependentModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [newDependent, setNewDependent] = useState<DependentFormValue | null>(null);
  const [oldDependent, setOldDependent] = useState<CardHolderDto | null>(null);
  const [newDependentTempData, setNewDependentTempData] = useState<CardHolderDto | null>(null);
  const [discardCreationConfirmation, setDiscardCreationConfirmation] = useState<
  DiscardConfirmationState
  >({
    visible: false, type: '',
  });
  const [showTypeError, setShowTypeError] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { mutateAsync } = useAddCardDependentMutation();
  const { mutateAsync: updateDependentCard } = useUpdateCardDependentMutation();
  const [typeForm, setTypeForm] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const createMode = useMemo(() => typeForm === CREATE_DEPENDENT_TYPE, [typeForm]);
  const { types, isLoading: relationshipListLoading } = useGetRelationshipTypesQuery();
  const { dependents, isLoading: dependentsLoading } = useGetDependents();

  const {
    // holderList,
    refetch: getCardHolderByIdRefetch,
    isLoading: getCardHolderByIdLoading,
    isFetching: getCardHolderByIdFetching,
  } = useGetCardsQuery(user?.employee?.id);

  const preparedTypes = useMemo(() => types.reduce((list, currentItem) => [
    ...list,
    {
      key: currentItem.name,
      value: capitalizeFirstLetter(currentItem.name).replace(regexp.DASH_SYMBOL, ' '),
    },
  ], [] as Option[]),
  [types]);

  // temporary solution
  const holdersWithoutRoot = useMemo(() => dependents.filter((holder) => holder.dependentId !== 'none'),
    [dependents]);

  const holder = useMemo(
    () => holdersWithoutRoot.find((item) => item.dependentId === typeForm),
    [holdersWithoutRoot, typeForm],
  );

  const fields = useCardDependentForm({
    createMode,
    data: createMode ? newDependentTempData : holder,
    relationshipTypes: preparedTypes,
  });

  useEffect(() => () => {
    setTypeForm(null);
  }, [setTypeForm, dependentModalVisible]);

  useEffect(() => {
    setIsLoading(
      getCardHolderByIdLoading
      || getCardHolderByIdFetching
      || relationshipListLoading
      || dependentsLoading,
    );
  }, [
    getCardHolderByIdLoading,
    getCardHolderByIdFetching,
    relationshipListLoading,
    dependentsLoading,
  ]);

  useEffect(() => {
    if (typeForm !== CREATE_DEPENDENT_TYPE) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [typeForm]);

  const typeFormFields = useTypeForm({
    holders: holdersWithoutRoot,
    defaultType: typeForm,
  });

  const isNewDependentValuesExist = useMemo(
    () => !!newDependent && Object.values(newDependent).some((item) => !!item),
    [newDependent],
  );

  const preparedDependent = useSetDependent(oldDependent || newDependent);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newDependentFullName = useMemo(() => `${newDependent?.firstName || ''} ${newDependent?.lastName || ''}`, [typeForm]);

  const submitButtonText = useMemo(() => (typeForm !== CREATE_DEPENDENT_TYPE
    ? t('Order a Card')
    : t('Add Dependent & Order Card')),
  [typeForm, t]);

  const closeModal = useCallback(() => {
    setTypeForm(null);
    setDependentModalVisible(false);
    setShowTypeError(false);
  }, []);

  const closePendingModal = useCallback(() => {
    setTypeForm(CREATE_DEPENDENT_TYPE);
    setNewDependentTempData({
      firstName: newDependent?.firstName,
      lastName: newDependent?.lastName,
      relationship: newDependent?.relationship,
      dateOfBirth: newDependent?.dateOfBirth,
    } as CardHolderDto);
    setDiscardCreationConfirmation((prevState) => ({
      ...prevState,
      visible: false,
      type: '',
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeForm]);

  useEffect(() => {
    if (typeForm) {
      const holder = holdersWithoutRoot?.find((item) => item.dependentId === typeForm);
      if (holder && !createMode) setOldDependent(holder);

      setShowTypeError(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeForm, createMode, setOldDependent]);

  const addCardDependentSubmit = useCallback(async () => {
    try {
      if (!typeForm) {
        setShowTypeError(true);
        return;
      }

      if (!createMode) {
        await updateDependentCard(preparedDependent);
        closeModal();
        setSuccessModalVisible(true);
        return;
      }

      setShowErrors(false);
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      await schema.validate(newDependent);

      if (newDependent) {
        await mutateAsync(preparedDependent);
        closeModal();
        setSuccessModalVisible(true);
      }
    } catch (e) {
      setShowErrors(true);
    }
  }, [
    closeModal,
    createMode,
    fields,
    mutateAsync,
    newDependent,
    preparedDependent,
    typeForm,
    updateDependentCard,
  ]);

  return (
    <>
      <Hint
        hideHint={!isLimitedAccess}
        hintAlign="left"
        hintWidth={240}
        disabled={isLimitedAccess}
        hintElement={(
          <Box>
            <AppButton
              type="submit"
              testId="add-card"
              disabled={isLimitedAccess}
              onClick={() => setDependentModalVisible(true)}
            >
              {t('Add a Card')}
            </AppButton>
          </Box>
        )}
      >
        <LimitedAccessLabel />
      </Hint>
      <ModalWrapper
        {
          ...discardCreationConfirmation.visible
            ? {
              style: { display: 'none' },
            }
            : {}
        }
        nonOverflow
        visible={dependentModalVisible}
        title="Select a Dependent"
        onSetVisible={closeModal}
      >
        <Box
          background="module"
          pad="medium"
          data-testid="AddCardDependentModal-form-wrapper"
          round="container1Round"
        >
          <StyledContent border={{ size: 'small', color: 'border2' }} elevation="default" background="canvas" round="container1Round">
            {
              !discardCreationConfirmation.visible && (
                <FlexControlledForm
                  fields={typeFormFields}
                  formTitle="Dependent"
                  editMode
                  isModalType
                  showError={showTypeError}
                  onChangeValues={({ type }) => {
                    if (typeForm
                      && type !== typeForm
                      && type !== CREATE_DEPENDENT_TYPE
                      && createMode
                      && isNewDependentValuesExist) {
                      setDiscardCreationConfirmation((prevState) => ({
                        ...prevState, visible: true, type,
                      }));
                    }
                    if (type && type !== typeForm) {
                      setTypeForm(type);
                    }
                  }}
                />
              )
            }
          </StyledContent>

          <AddCardDependentForm
            onChangeValues={setNewDependent}
            type={discardCreationConfirmation.visible ? CREATE_DEPENDENT_TYPE : typeForm}
            ref={formRef}
            loading={isLoading}
            fields={fields}
            showErrors={showErrors}
          />
        </Box>
        <Box direction="row" justify="end" margin={{ top: 'spacing24' }}>
          <Box margin={{ right: 'spacing24' }}>
            <AppButton
              onClick={closeModal}
              width="130px"
              buttonType="secondary"
            >
              <Text>{t('Cancel')}</Text>
            </AppButton>
          </Box>
          <AppButton
            testId="add-card_button"
            disabled={isLoading}
            onClick={addCardDependentSubmit}
            width="130px"
          >
            {
              isLoading
                ? <Preloader color="white" />
                : submitButtonText
            }
          </AppButton>
        </Box>
      </ModalWrapper>
      <SuccessModal
        visible={successModalVisible}
        onSetVisible={setSuccessModalVisible}
        header="We’ve received your request for a new card!"
        helptext="Card will be mailed in 3-5 days."
        buttonText="Got It!"
        onSubmit={() => {
          handleAddPermanentSnackbar({
            text: `${preparedDependent?.firstName} ${preparedDependent?.lastName}’s card has been requested and will be mailed in 3-5 days.`,
            closeIcon: true,
          });
          setNewDependent(null);
          setOldDependent(null);
          getCardHolderByIdRefetch();
        }}
      />
      <PendingModal
        header={`Are you sure you want to discard adding ${newDependentFullName}?`}
        helptext="All inserted data will be deleted."
        buttonText="Confirm"
        visible={discardCreationConfirmation.visible}
        onCancel={closePendingModal}
        onSubmit={() => {
          setTypeForm(discardCreationConfirmation.type);
          setNewDependentTempData(null);
          setDiscardCreationConfirmation({
            visible: false, type: '',
          });
        }}
      />
    </>
  );
};
