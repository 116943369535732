import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  ${({ theme }) => theme.fonts.text16Bold};
  margin-bottom: ${({ theme }) => theme.spacings.spacing16};
`;

export const Content = styled(Box)`
  flex-direction: row;
`;
