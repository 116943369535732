// import { useCallback, useEffect } from 'react';
import { useEffect } from 'react';
import { Option } from '@common-fe/common-fe';

import { useStore } from '../store/useBreadcrumbs.store';

const useBreadcrumbs = (breadcrumbs: Option<string>[], backBtn?: boolean) => {
  const setBreadcrumbs = useStore((state) => state.setBreadcrumbs);
  const handleSetBackBtn = useStore((state) => state.handleSetBackBtn);
  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
    handleSetBackBtn(!!backBtn);
  }, [backBtn, breadcrumbs, handleSetBackBtn, setBreadcrumbs]);
};
export default useBreadcrumbs;
