import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ValuesMap } from '@/common/types';
import { InvestmentHoldingStatus } from '@/modules/investments/investments.types';

import { useAddedFundsStore } from '../../AddMoreFunds/stores';
import { useDefaultAllocationFunds } from '../../hooks';
import { AllocationPercentsMap, FundsPayload } from '../../SelfDirected.types';

interface Params {
  selectedIds: string[];
  data: FundsPayload[];
  isInvestMoreMode?: boolean;

  investAmount: number;
}
const MAX_PERCENTS = 100;
const TIMEOUT = 3000;

export default ({ selectedIds, data, investAmount, isInvestMoreMode }: Params) => {
  const [key, setKey] = useState('');
  
  const [isOnceCalculated, setOnceCalculated] = useState(false);
  const [values, setValues] = useState<AllocationPercentsMap>({});
  const { defaultFundsValues } = useDefaultAllocationFunds();
  useEffect(() => {
    if(defaultFundsValues) {
      setOnceCalculated(true); 
      setValues(defaultFundsValues);
    }
  }, [defaultFundsValues]);
  const localFunds = useAddedFundsStore((state) => state.funds);
  const handleRemoveHighlight = useAddedFundsStore((state) => state.removeHighlight);
  const handleResetFunds = useAddedFundsStore((state) => state.reset);
  useEffect(() => {
    return () => {
    
      handleResetFunds();
    };
  }, [handleResetFunds]);
  const [checkIdsMap, setCheckIdsMap] = useState<ValuesMap<boolean>>({});

  const localIds = useMemo(() => localFunds.map((item) => item.id), [localFunds]);
  



  const filteredList = useMemo(() => {
    let list = data.filter((item) => selectedIds.includes(item.id));
    list = [...list];
    return list;
  }, [data, selectedIds]);

  const handleReset = useCallback(() => {
    
    const ids = filteredList.map((item) => item.id);
    
    let totalPercents = MAX_PERCENTS;
    const onlyChecked = ids.filter((id) => checkIdsMap[id] !== false );
    const percents = _.floor(MAX_PERCENTS / (onlyChecked.length || 1), 2);
    const formattedResult = ids.reduce((result: AllocationPercentsMap, id) => {
      if (!checkIdsMap[id]) {
        return {
          ...result,
          [id]: 0,
        };
      }
      totalPercents -= percents;
      if (_.last(onlyChecked) === id && totalPercents > 0) {
        
        return {
          ...result,
          [id]: _.floor(percents + totalPercents, 2),
        };
      }
      return {
        ...result,
        [id]: percents,
      };
    }, {});
    
    setValues(formattedResult);
    setKey(`${+ new Date()}`);

  
  }, [checkIdsMap, filteredList]);
  useEffect(() => {
    const result = selectedIds.reduce((result, item) => {
      if(isInvestMoreMode) {
        const foundItem = data.find((fundItem) => fundItem.id === item);
        if(foundItem?.status === InvestmentHoldingStatus.Inactive) {
          return {
            ...result,
            [item]: false,
          }; 
        }
      }
      return {
        ...result,
        [item]: true,
      };
    }, {} as ValuesMap<boolean>);
   
    setCheckIdsMap(result);


  // It's not obvious, we are should rely on reference on of there but on content of the array.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${selectedIds}`]);
  useEffect(() => {
  
    handleReset();
    setTimeout(() => {
      handleRemoveHighlight();
    }, TIMEOUT);

    const addedFunds: { [key: number]: boolean } = {};

    localIds.forEach((id) => {
      if (checkIdsMap[id] === undefined) {
        addedFunds[parseInt(id)] = true;
      }
    });

    if (Object.keys(checkIdsMap).length > 0) {
      setCheckIdsMap({
        ...checkIdsMap,
        ...addedFunds,
      });
    }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(localIds)]);
  useEffect(() => {
    if(!isOnceCalculated && investAmount && filteredList.length) {
      
      handleReset();
      setOnceCalculated(true);
    }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList.length, investAmount, isOnceCalculated]);

  const handleChange = useCallback(
    (id: string) => (newValue: number) => {
      
      setValues({
        ...values,
        [id]: newValue,
      });
    },
    [values]
  );
  const checkedIds = useMemo(() => {
    return Object.keys(checkIdsMap).reduce((result, key) => {
      if(checkIdsMap[key]) {
        return [...result, key];
      }
      return result;

    }, [] as string[]);
  }, [checkIdsMap]);
  const totalPercents = useMemo(() => {
    let total = 0;


    checkedIds.forEach((id) => {
      const value = values[id];
      total += value;
    });
    if (_.isNaN(total)) {
      return 0;
    }
    return _.ceil(total, 2);
  }, [checkedIds, values]);
  const handleCheckItem = useCallback(
    (id: string) => {
      if (checkIdsMap[id]) {
        setCheckIdsMap(({
          ...checkIdsMap,
          [id]: false
        }));
       
      } else {
        setCheckIdsMap(({
          ...checkIdsMap,
          [id]: true
        }));
      }
    },
    [checkIdsMap]
  );




  const { t } = useTranslation();
  const errorText = useMemo(() => {
  
    if(!investAmount) {
      return t('Allocation to funds must be 100% to submit the investment order');
    } 
    if (totalPercents !== MAX_PERCENTS) {
      return t('Allocation to funds must be 100% to submit the investment order');
    }
  }, [investAmount, t, totalPercents]);
  const percentsArray = useMemo(() => {


    const keys = Object.keys(values);
    const filteredKeys = keys.filter((key) => checkIdsMap[key]);
   
    let total = 100;
    return filteredKeys.map((key) => {
      if(_.last(filteredKeys) === key) {
        return ({
          instrumentId: _.toNumber(key),
          percentage: _.round(total,2)
        });
      }
      total -= _.toNumber(values[key]) || 0; 
      return ({
        instrumentId: _.toNumber(key),
        percentage: _.round(_.toNumber(values[key]),2),
      });
    } );
  },[checkIdsMap, values]);



  const amountValues = useMemo(() => {
    
    let totalSum = investAmount;
    const keys = Object.keys(values).filter(key => values[key]);
    const formattedValues = keys.reduce((result,key) => {
      const percents = values[key];
      const isCheckedItem = checkIdsMap[key];
      if(percents && isCheckedItem) {
        const currentTotalAmount = _.floor(investAmount * (percents / 100), 2);
        const reserveAmount = totalSum; 
        totalSum -= currentTotalAmount;
        const isSmallDifference = reserveAmount - currentTotalAmount < 1;
        if(_.last(keys) === key && isSmallDifference) {
          return {
            ...result,
            [key]: _.round(reserveAmount, 2),
          };
        }
        return {
          ...result,
          [key]: _.round( currentTotalAmount, 2),
        };
      }
      return {
        ...result,
      };
    }, {} as { [key: string]: number });
    
    return formattedValues;
  }, [checkIdsMap, investAmount, values]);
  const filteredListWithValues = useMemo(() => filteredList.filter((item) => amountValues[item.id]), [amountValues, filteredList]);
  const stocksQuantities = useMemo(() => {
    const keys = Object.keys(amountValues);
    
    return keys.map((key) => ({
      instrumentId: key,
      amountCash: amountValues[key] || 0, 
    }));
  }, [amountValues]);
  const onlyFilledValues = useMemo(() => {
    const valuesCopy = { ...values };
  
    Object.keys(valuesCopy).forEach(key => {
      if (valuesCopy[key] === 0) {
        delete valuesCopy[key];
      }
    });
  
    return valuesCopy;
  }, [values]);
  return {
    percentsArray,
    amountValues,
    errorText,
    handleCheckItem,
    checkedIds,
    filteredList,
    filteredListWithValues,
    values: onlyFilledValues,
    handleChange,
    handleReset,
    totalPercents,
    key,
    stocksQuantities,
  };
};
