import React, { useMemo } from 'react';
import { Box, PlusIcon, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import PaymentTypeIcon from '@/components/elements/PaymentTypeIcon';
import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import colors from '@/styles/colors';

import { AddCardPaymentMethod } from './AddCardPaymentMethod';

const Wrapper = styled(Box)`
  ${({ theme }) => theme.shadows.default};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.accentActive};
  }
`;
const StyledPlusIcon = styled(PlusIcon)`
  width: ${({ theme }) => theme.spacings.spacing16};
  height: ${({ theme }) => theme.spacings.spacing16};
  padding: ${({ theme }) => theme.spacings.spacing4};
`;

interface Props {
  title?: string | React.ReactNode;
  description?: string;
  icon?: string;
  paymentType?: PaymentMethodType;
  add?: () => void;
  removePaymentMethod?: () => void;
  editPaymentMethod?: () => void;
  addTitle?: string;
  paymentMethods?: PaymentMethod[];
  onlyAddingMode?: boolean;
  hasPaymentFreeTimeLabel?: boolean;
}

const AddCard: React.FC<Props> = ({
  title,
  description,
  icon,
  add,
  removePaymentMethod,
  editPaymentMethod,
  addTitle,
  paymentType,
  paymentMethods,
  onlyAddingMode,
  hasPaymentFreeTimeLabel = true,
}) => {
  const hideAddBtn = useMemo(() => (
    paymentMethods && paymentMethods.length && paymentType === PaymentMethodType.CHECK
  ), [paymentMethods, paymentType]);
  return (
    <Wrapper
      round="container1Round"
      border={{ color: 'border2', size: 'small' }}
      style={{
        transition: 'all .3s ease-out',
      }}
    >
      <Box
        pad={{ horizontal: 'spacing24', top: 'spacing24', bottom: 'spacing12' }}
        background="canvas"
        round="container1Round"
      >
        <Box direction="row">
          {icon && (
            <Box width={{ min: '48px' }} alignSelf="center">
              <img alt="" src={icon} />
            </Box>
          )}
          <PaymentTypeIcon
            paymentType={paymentType}
            wrap={(paymentTypeIcon) => (
              <Box alignSelf="center" width={{ min: '48px' }}>
                {paymentTypeIcon}
              </Box>
            )}
          />

          {(title || description) && (
            <Box margin={{ left: 'small' }}>
              {title && typeof title === 'string' ? <Text size="large" weight="bold" color="textBody">{title}</Text> : null}
              {title && typeof title !== 'string' ? title : null}
              {description && (
                <Text color="textSecondary" margin={{ top: 'spacing4' }}>{description}</Text>
              )}
            </Box>
          )}
        </Box>

        {!onlyAddingMode && paymentMethods
          ? paymentMethods.map((method) => (
            <AddCardPaymentMethod
              key={method.id}
              method={method}
              disableOptions={paymentType === PaymentMethodType.CHECK}
              removePaymentMethod={removePaymentMethod}
              editPaymentMethod={editPaymentMethod}
              hasPaymentFreeTimeLabel={hasPaymentFreeTimeLabel}
            />
          ))
          : undefined}

        {
          !hideAddBtn
          && (
            <>
              <Box
                height="1px"
                background="border2"
                margin={{ vertical: 'spacing12' }}
              />
              <Box
                pad={{ vertical: 'spacing8' }}
                onClick={add}
              >
                <Box direction="row" align="center">
                  <StyledPlusIcon width="16px" height="16px" color={colors.iconAccent} />
                  {addTitle && (
                    <Text color="textAccent" size="large" weight={400} margin={{ left: '15px' }}>{addTitle}</Text>
                  )}
                </Box>
              </Box>
            </>
          )
        }
      </Box>
    </Wrapper>
  );
};

export default AddCard;
