import React from 'react';
import { AppButton, Box } from '@common-fe/common-fe';

interface ForgotUsernameSuccessProps {
  onNext: (value?: object) => void;
}

const ForgotUsernameSuccess: React.FC<ForgotUsernameSuccessProps> = ({ onNext }) => (
  <Box margin={{ top: '-8px' }}>
    <AppButton
      testId="forgot-username-continue"
      type="submit"
      width="100%"
      size="L"
      onClick={onNext}
    >
      Sign In
    </AppButton>
  </Box>
);

export default ForgotUsernameSuccess;
