import React, { ChangeEvent  } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CameraIcon, GalleryIcon } from '@common-fe/common-fe';

import { FileGetAndUploadButton } from '@/modules/transaction/components/UploadFileByLink/FileGetAndUploadButton';
import UploadFilesHeader from '@/modules/transaction/components/UploadFileByLink/UploadFileByLinkHeader';

const BUTTON_WIDTH = '320px';

interface Props {
  onSelectFile: (file: File | undefined) => void;
}

const SelectFileScreen: React.FC<Props> = ({ onSelectFile }) => {
  const { t } = useTranslation();

  return (
    <Box height={{ min: '100svh' }}>
      <UploadFilesHeader title="Document Upload" />
      <Box
        width="100%"
        align="center"
        margin={{ top: 'spacing32' }}
        gap="spacing12"
      >
        <Box
          width="100%"
          align="center"
          margin={{ top: 'spacing32' }}
          gap="spacing12"
        >
          <Box width={BUTTON_WIDTH}>
            <FileGetAndUploadButton
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectFile(e?.target?.files?.[0])}
              title={t('Use Camera')}
              id="Camera"
              cameraInput
              icon={<CameraIcon color="canvas" />}
            />
          </Box>
          <Box width={BUTTON_WIDTH}>
            <FileGetAndUploadButton
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectFile(e?.target?.files?.[0])}
              title={t('Select From Gallery')}
              id="Gallery"
              secondary
              icon={<GalleryIcon color="iconPrimary" />}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectFileScreen;
