import React, { useCallback, useMemo } from 'react';
import { Box, Hint, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

import AppButton from '@/components/controls/AppButton';
import { useShouldShowCardActions } from '@/modules/transaction/components/Cards/hooks/useShouldShowCardActions';
import { useSetCardStatusModal } from '@/modules/transaction/components/Cards/store/useSetCardStatusModal.store';

import { CardDto, CardStatusEnum } from '../Cards.types';
import useCardActivationModalStore from '../store/useCardActivationModal.store';
import useCardReplaceModalStore from '../store/useCardReplaceModal.store';

import { useRequestedActivationCards } from './CardActivationModals/hooks/useRequestedActivationCards';

const StyledBox = styled(Box)`
  flex: 1;
`;

interface Props {
  card: CardDto;
}

export const CardItemActions: React.FC<Props> = ({ card }) => {
  const {
    status,
    holder,
    id,
    isMailedAfterCreationAndOlderThanWeek,
  } = card;
  const { showReplaceButton, readyForActivation } = useShouldShowCardActions(status);
  const {
    handleChangeState: cardStatusChangeState,
  } = useSetCardStatusModal();
  const {
    handleChangeVisibility,
    handleChangeCardHolderName,
    handleChangeCardHolderID,
    handleChangeCardID,
    handleChangeCardStatus,
    handleChangeMailedDate,
    handleChangeActivationDate,
  } = useCardReplaceModalStore();

  const {
    handleChangeVisibility: handleChangeActivationVisibility,
    handleChangeCardID: handleChangeActivationCardID,
    handleChangeLast4Numbers,
    handleChangeAlternateAccountId,
    handleChangeMemberSequenceIdentifier,
  } = useCardActivationModalStore();

  const setCardInfo = useCallback(() => {
    handleChangeCardHolderName(holder.name);
    handleChangeCardHolderID(holder.employeeId);
    handleChangeCardID(id);
    handleChangeCardStatus(status);
    handleChangeMailedDate(card.mailedDate);
    handleChangeActivationDate(card.activationDate);
  },
  [
    handleChangeCardHolderID,
    handleChangeCardHolderName,
    handleChangeCardStatus,
    handleChangeCardID,
    holder.employeeId,
    holder.name,
    id,
    status,
    handleChangeMailedDate,
    handleChangeActivationDate,
    card,
  ]);

  const setCardActivationInfo = useCallback(() => {
    handleChangeActivationCardID(id);
    handleChangeLast4Numbers(card.last4Number);
    handleChangeAlternateAccountId(card.alternateAccountId);
    handleChangeMemberSequenceIdentifier(card.memberSequenceIdentifier);
  },
  [
    handleChangeActivationCardID,
    id,
    handleChangeLast4Numbers,
    card,
    handleChangeMemberSequenceIdentifier,
    handleChangeAlternateAccountId,
  ]);

  const onCardReplacementModal = useCallback(() => {
    setCardInfo();
    handleChangeVisibility(true);
  }, [handleChangeVisibility, setCardInfo]);

  const onCardActivationModal = useCallback(() => {
    setCardActivationInfo();
    handleChangeActivationVisibility(true);
  }, [handleChangeActivationVisibility, setCardActivationInfo]);

  const { isCardRequestedToActivate } = useRequestedActivationCards({
    cardId: id,
    cardStatus: status,
    last4: card.last4Number,
  });

  const isCardLocked = useMemo(() => card.status === CardStatusEnum.FROZEN
  || card.status === CardStatusEnum.PENDING_FROZEN, [card]);

  return (
    <Box direction="row" justify="between" data-testid="Card-item-actions">
      {
        readyForActivation && !isCardRequestedToActivate(id) && (
          <StyledBox margin={{ right: 'spacing12' }}>
            <Hint
              hintAlign="right"
              hideHint={isMailedAfterCreationAndOlderThanWeek}
              disabled={!isMailedAfterCreationAndOlderThanWeek}
              hintElement={(
                <Box>
                  <AppButton
                    width="100%"
                    disabled={!isMailedAfterCreationAndOlderThanWeek}
                    onClick={onCardActivationModal}
                  >
                    Activate Card
                  </AppButton>
                </Box>
              )}
            >
              <Inscription size="12px">
                Your card is on its way and should arrive soon.
              </Inscription>
            </Hint>
          </StyledBox>
        )
      }
      {(status === CardStatusEnum.PENDING_UNLOCK || status === CardStatusEnum.ACTIVE || status === CardStatusEnum.FROZEN || status === CardStatusEnum.PENDING_FROZEN ) && (
        <StyledBox margin={{ right: 'spacing12' }}>
          <AppButton
            buttonType="secondary"
            width="100%"
            onClick={() => cardStatusChangeState({
              cardId: card.id,
            })}
          >
            {
              isCardLocked
                ? 'Unlock/Close Card'
                : 'Lock/Close Card'
            }
          </AppButton>
        </StyledBox>
      )}
      {
        (showReplaceButton || status === CardStatusEnum.PENDING_OPEN) && (
          <StyledBox>
            <Hint
              hintAlign="right"
              hideHint={isMailedAfterCreationAndOlderThanWeek || showReplaceButton}
              disabled={!isMailedAfterCreationAndOlderThanWeek && status === CardStatusEnum.PENDING_OPEN}
              hintElement={(
                <Box>
                  <AppButton
                    width="100%"
                    buttonType="secondary"
                    disabled={!isMailedAfterCreationAndOlderThanWeek && status === CardStatusEnum.PENDING_OPEN}
                    onClick={onCardReplacementModal}
                  >
                    Replace Card
                  </AppButton>
                </Box>
              )}
            >
              <Inscription size="12px">
                Your card is on its way and should arrive soon.
              </Inscription>
            </Hint>
          </StyledBox>
        )
      }
    </Box>
  );
};
