import { baseStoreCreator } from '@common-fe/common-fe';

export interface InvestmentProfilePayload {
  toleranceModel: string;
  investmentObjectivesType: string;
  investmentExperienceType: string;
  purposeType: string;
  riskToleranceType: string;
  medicalExpensesCoverType: string;
}

export const DEFAULT_VALUES: InvestmentProfilePayload = {
  toleranceModel: '',
  investmentObjectivesType: '',
  investmentExperienceType: '',
  purposeType: '',
  riskToleranceType: '',
  medicalExpensesCoverType: '',
};

export const useStore = baseStoreCreator<InvestmentProfilePayload>(DEFAULT_VALUES);

export default useStore;
