import { useMemo } from 'react';

import { CardStatusEnum } from '@/modules/transaction/components/Cards/Cards.types';

export const useShouldShowCardActions = (status: CardStatusEnum) => {
  const showReplaceButton = useMemo(
    () => status === CardStatusEnum.ACTIVE
    || status === CardStatusEnum.PENDING_UNLOCK,
    [status],
  );

  const readyForActivation = useMemo(
    () => status === CardStatusEnum.PENDING_OPEN,
    [status],
  );

  return {
    showReplaceButton,
    readyForActivation,
  };
};
