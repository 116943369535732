import React, { useCallback, useMemo,useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,   Box, Preloader, Text,
} from '@common-fe/common-fe';

import { ChangeEmailModal } from '@/modules/transaction/components/Statements/components/ChangeEmailModal';
import { ElectronicAgreement } from '@/modules/transaction/components/Statements/components/ElectronicAgreement';
import { GoPaperlessSuccessModal } from '@/modules/transaction/components/Statements/components/GoPaperless/components/GoPaperlessSuccessModal';
import { useOrganizationProperties } from '@/modules/transaction/hooks';
import useUpdateEmployeeQuery from '@/modules/user/queries/useUpdateEmployee.query';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';
import { StatementType } from '@/modules/user/user.types';

const ELECTRONIC_AGREEMENT_MESSAGE_STORE_KEY = 'ElectronicAgreementMessage';
const TRUE_KEY = 'true';

const ElectronicAgreementMessage = () => {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { organizationProperties } = useOrganizationProperties();
  const isMessageDismissed = localStorage.getItem(ELECTRONIC_AGREEMENT_MESSAGE_STORE_KEY);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isAgreementError, setIsAgreementError] = useState(false);
  const [isNotificationMode, setIsNotificationMode] = useState(isMessageDismissed === TRUE_KEY);
  const { updateEmployee, isLoading, isSuccess } = useUpdateEmployeeQuery();

  const currentEmail = useMemo(
    () => (employee?.isEmployeeEmailPreferred && employee.employeeProvidedEmail
      ? employee.employeeProvidedEmail
      : employee?.employerProvidedEmail
    ), [employee],
  );

  const handleNotificationMode = useCallback(() => {
    const updatedIsNotificationMode = !isNotificationMode;
    setIsNotificationMode(updatedIsNotificationMode);
    localStorage.setItem(ELECTRONIC_AGREEMENT_MESSAGE_STORE_KEY, `${updatedIsNotificationMode}`);
  }, [isNotificationMode]);

  const handleCheck = useCallback(() => {
    setIsChecked(!isChecked);
  }, [setIsChecked, isChecked]);

  const handleOpenChangeModal = useCallback(() => setIsChangeEmailModalVisible(true), []);
  const handleCloseChangeModal = useCallback(() => setIsChangeEmailModalVisible(false), []);

  const handleSubmit = useCallback(() => {
    if (!isChecked) {
      setIsAgreementError(true);
      return;
    }

    updateEmployee({
      is_electronic_statement_only: true,
    });
    setIsSuccessModalVisible(true);
  }, [updateEmployee, isChecked]);

  const isPaperAndElectronicsStatementType = useMemo(
    () => organizationProperties.statementType === StatementType.PAPER_AND_ELECTRONIC,
    [organizationProperties],
  );

  if (!employee
      || employee?.isElectronicStatementOnly
      || !isPaperAndElectronicsStatementType) {
    return null;
  }

  return (
    <>
      <Box margin={{ top: 'spacing24' }} data-testId="electronic-agreement-message">
        <ElectronicAgreement
          isChecked={isChecked}
          onChecked={handleCheck}
          onEdit={handleOpenChangeModal}
          email={currentEmail}
          notificationMode={isNotificationMode}
          onSetNotificationMode={handleNotificationMode}
          isError={isAgreementError && !isChecked}
        >
          <Box
            margin={{ top: 'spacing24' }}
            direction="row"
            justify="start"
          >
            <Box>
              <AppButton
                width="150px"
                onClick={handleSubmit}
                testId="submit"
                disabled={!currentEmail}
              >
                {isLoading ? <Preloader /> : t('Submit')}
              </AppButton>
            </Box>
            <Box
              margin={{ left: 'spacing12' }}
            >
              <AppButton
                buttonType="secondary"
                width="150px"
                onClick={handleNotificationMode}
                testId="dismiss"
              >
                {t('Dismiss')}
              </AppButton>
            </Box>
          </Box>
        </ElectronicAgreement>
        {
          isAgreementError && !isChecked && (
            <Box margin={{ top: 'spacing24' }} data-testId="error_block">
              <Text color="danger" textAlign="end">
                {t('Please confirm that you’ve read and acknowledge our Electronic Disclosure Agreement.')}
              </Text>
            </Box>
          )
        }
      </Box>
      {
        isChangeEmailModalVisible && (
          <ChangeEmailModal
            onClose={handleCloseChangeModal}
          />
        )
      }
      {
        isSuccess && isSuccessModalVisible && (
          <GoPaperlessSuccessModal
            onSetVisible={setIsSuccessModalVisible}
          />
        )
      }
    </>
  );
};

export default ElectronicAgreementMessage;
