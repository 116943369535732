import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Anchor,
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  Checkbox,
  costFormater,
  Image,
  NewTabIcon,
  NullIcon,
  RawButton,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { useGetFundIcon } from '../../hooks';
import { InvestmentHoldingStatus } from '../../investments.types';
// import FundsPercents from '../FundsPercents';
import useInstrumentCategoriesQuery from '../queries/useInstrumentCategories.query';
import { FundsPayload } from '../SelfDirected.types';

import FundAllocationInput from './FundAllocationInput';
interface WrapperProps {
  isHighlighted?: boolean;
}
const Wrapper = styled(Box)<WrapperProps>`
  ${({ theme }) => theme.shadows.default};
  transition: all 0.25s;
  &:hover {
    ${({ theme }) => theme.shadows.hover};
  }
  ${({ isHighlighted }) => {
    if(isHighlighted) {
      return css`
      animation-name: showHighlight;
        animation-duration: 3.5s;
        @keyframes showHighlight {
          0% {
            border: 2px solid ${ ({ theme }) => theme.colors.accentActive};
          }
          75% {
            border: 2px solid ${ ({ theme }) => theme.colors.accentActive};
          }
          100% {
            border-color: 1px solid ${({ theme }) => theme.colors.border1};
          }
        }
      `;
    }

    return css``;
  } }
`;

const EMPTY_SIGN = '-';

interface Props extends FundsPayload {
  checked?: boolean;
  onSelect: (value: string) => void;
  disabled?: boolean;
  isInvestMoreMode?: boolean;
  investedAmount: number;
  isOnlyOneAvailable?: boolean;
  value: number;
  onChangeValue: (value: number) => void;
  isMinified?: boolean;
}

const SelfDirectedAllocationItem: React.FC<Props> = ({
  value,
  status,
  onChangeValue,
  isInvestMoreMode,
  logo,
  title,
  stockCode,
  link,
  assetClass,
  disabled,
  isOnlyOneAvailable,
  onSelect,
  id,
  checked,
  investedAmount,
  price = 0,
  category,
  isMinified,
  isHighlighted
}) => {
  const { t} = useTranslation();
  const { dataMap } = useInstrumentCategoriesQuery();
  const currentCategory = useMemo(
    () => _.get(dataMap, `${[category]}`, '') as string, [category, dataMap],
  );
  

  const currentAmount = useMemo(() => {
    if(value !== undefined  && !investedAmount) {
      return EMPTY_SIGN;
    } 
    const displayedValue =  investedAmount * (value / 100);
    
    return _.isNaN(displayedValue) ? EMPTY_SIGN: displayedValue;
  }, [investedAmount, value]);


  const sharesAmount = useMemo(() => {
    if(_.isNumber(currentAmount)) {
      return currentAmount / price;
    }
    return EMPTY_SIGN;
  }, [currentAmount, price]);
  const handleGetFundIcon = useGetFundIcon(); 
  
  const [expanded, setExpanded] = useState(false);
  const handleToggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const isDisabledApplied = useMemo(() => {
    if(isInvestMoreMode) {
      return disabled || status === InvestmentHoldingStatus.Inactive;
    }
    return disabled;
  }, [disabled, isInvestMoreMode, status]);
  const isGreyMode = useMemo(() => {
    return !!((isDisabledApplied) && investedAmount);
  }, [isDisabledApplied, investedAmount]);
  const isForceDisabled = useMemo(() => {
    return isInvestMoreMode && status === InvestmentHoldingStatus.Inactive;
  }, [isInvestMoreMode, status]);
  return (
    <Wrapper
      data-testid="fund-item"
      direction="column"
      pad={{ vertical: '17px', horizontal: 'l' }}
      background="canvas"
      isHighlighted={isHighlighted}
      round="container2Round"
      border={{ size: 'small', color: 'border1' }}
      margin={{ top: 'xs' }}
    >
      <Box direction="row" align="center">
        {!isMinified && (
          <RawButton onClick={handleToggleExpanded}>
            <Box width="24px" height="24px">
              {expanded ? <ArrowUpIcon color="iconPrimary" /> : <ArrowDownIcon color="iconPrimary" />}
            </Box>
          </RawButton>
        )}
        <Box margin={{ left: 's' }}>
          <Checkbox testId="fund-item"
            name="select"
            checked={checked}
            disabled={isForceDisabled}
            onChange={() => {
              
              onSelect(id);
            }} />
        </Box>
        <Box margin={{ left: 's' }}>
          { handleGetFundIcon({
            image: logo,
            status: status,
            isGreyMode,
            isNullMode: true
          })}
        </Box>
        <Box
          margin={{ left: 's' }}
          style={{
            flex: 2,
            position: 'relative',
          }}
          direction="column"
        >
          <Box width="100%">
            <Text tip={title} color={isGreyMode ? 'textDisabled' : 'textBody'} truncate weight={700}>
              {title}
            </Text>
            <Text  color={isGreyMode ? 'textDisabled' : 'textBody'} >{stockCode}</Text>
          </Box>
        </Box>
        <Box
          margin={{ left: 's' }}
          style={{
            width: '24px',
          }}
          direction="column"
        >
          {link ? (
            <Anchor target="_blank" href={link}>
              <Box align="center">
                <NewTabIcon color="iconSecondary" size="26px" />
              </Box>
            </Anchor>
          ) : (
            <Box width="26px" />
          )}
        </Box>
        {/* <Box width="60px" margin={{ left: 'l' }}>
          <FundPercents value={yr3} />
        </Box>
        <Box width="60px" margin={{ left: 'l' }}>
          <FundPercents value={yr5} />
        </Box> */}
        <Box width="90px" margin={{ left: 'l' }}>
          <Text  color={isGreyMode ? 'textDisabled' : 'textBody'} >
            {costFormater(price, {
              isPrecision: true
            })}
          </Text>
        </Box>
        {/* <Box width={{min: '150px'}} margin={{ left: 'l' }}>
          <Text>{assetClass || '-'}</Text>
        </Box> */}
        <Box width={{min: '90px'}} margin={{ left: 'l' }}>
          <Text  color={isDisabledApplied ? 'textDisabled' : 'textBody'} >{_.isNumber(sharesAmount) ? _.floor(sharesAmount, 4): EMPTY_SIGN}</Text>
        </Box>
        <Box width={{min:'90px' }} margin={{ left: 'l' }}>
          <Text weight={700}  color={isDisabledApplied ? 'textDisabled' : 'textBody'} >
            {_.isNumber(currentAmount) ? costFormater(currentAmount, {isPrecision: true}): currentAmount}
          </Text>

        </Box>

        <Box margin={{ left: isMinified ? 'spacing24' : undefined }}>
          <FundAllocationInput 
            // key={`${value === undefined}`}
            isEmpty={!investedAmount}
            isOnlyOneAvailable={isOnlyOneAvailable}
            disabled={disabled}
            value={value}
            onChange={onChangeValue} />
        </Box>
      </Box>


      {expanded && (
        <>
          <Box height="1px" background="border1" margin={{top: 'l', bottom: 'l'}}>
          </Box>
          <Box direction='row' align='center'>
            <Box direction='column' width="150px" margin={{right: 'l'}}>
              <Text size="small"
                weight={700}
                color={isGreyMode ? 'textDisabled' : 'textBody'} 
                margin={{bottom: 'xxxs'}}>Category
              </Text>
              <Box pad={{vertical: 'xxxs'}}>
                <Text 
                  color={isGreyMode ? 'textDisabled' : 'textBody'} 
                  truncate
                  size="medium">{currentCategory}
                </Text>
              </Box>
            </Box>
            <Box direction='column' width="150px" margin={{right: 'l'}}>
              <Text size="small"
                weight={700}
                color={isGreyMode ? 'textDisabled' : 'textBody'} 
                margin={{bottom: 'xxxs'}}>{t('Asset class')}
              </Text>
              <Box pad={{vertical: 'xxxs'}}>
                <Text
                  color={isGreyMode ? 'textDisabled' : 'textBody'}
                  size="medium">{assetClass}
                </Text>
              </Box>
            </Box>
          </Box>
        </>



      )}
    </Wrapper>
  );
};

export default SelfDirectedAllocationItem;
