import { FileImage } from '@/common/constants';
import prepareFile from '@/components/controls/DropScan/prepareFile';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  files: FileImage[];
  activeFile: number;
  setFiles: (files: FileImage[]) => void;
  setActiveFile: (activeFile: number) => void;
}

const useFilesStore = storeCreator<State>((set) => ({
  files: [],
  activeFile: 0,
  setFiles: (files) => set(() => ({
    files: files.map(prepareFile),
  })),
  setActiveFile: (activeFile: number) => set(() => ({ activeFile })),
}));

export default useFilesStore;
