import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton, AutoInvestmentsIcon, Box,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { Banner } from '@/modules/core/components/Dashboard/components/BannersList/Banner';
import { useHistory } from '@/modules/core/hooks';
import { useMyEnrollments } from '@/modules/transaction';

import { useSetupAutoInvestmentsModalStore } from '../stores';

import { useAutoInvestmentBanner } from './hooks';

// import { useAutoInvestment } from './hooks';
interface Props {
  isWideMode?: boolean;
  accountId?: string;
}

const AutoInvestmentBanner: React.FC<Props> = ({ isWideMode, accountId }) => {
  const { mainHSAEnrollment } = useMyEnrollments();
  const { t } = useTranslation();
  const { handleDismiss } = useAutoInvestmentBanner();
  const history = useHistory();
  const setModalVisible = useSetupAutoInvestmentsModalStore((state) => state.setVisible);

  const handleRedirect = useCallback(() => {
    history.push(`${ROUTES.INVESTMENTS}?id=${accountId || mainHSAEnrollment?.id}`);
    setModalVisible(true);
  }, [accountId, history, mainHSAEnrollment, setModalVisible]);
  return (
    <>

      <Box data-testid="auto-investment-banner">
        <Banner
          isWideMode={isWideMode}
          title={t('Boost Your Investments Without Lifting a Finger')}
          description={t('Say hello to smarter investing. Automate your investments and enjoy a hands-free, hassle-free way to build wealth!')}
          icon={<AutoInvestmentsIcon />}
          testId="go-paperless"
        >
          <Box margin={{ top: 'spacing24' }} direction="row">
            <Box>
              <AppButton
                testId="auto-investment-banner_get-started"
                width="180px"
                onClick={handleRedirect}
              >
                {t('Get Started')}
              </AppButton>
            </Box>

            <Box margin={{ left: 'spacing12' }}>
              <AppButton
                testId="auto-investment-banner_cancel"
                buttonType="secondary"
                width="180px"
                onClick={handleDismiss}
              >
                {t('Dismiss')}
              </AppButton>
            </Box>
          </Box>
        </Banner>
      </Box>
    </>
  );
};

export default AutoInvestmentBanner;
