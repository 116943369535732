import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Text,
} from '@common-fe/common-fe';

import InvestmentExperienceType from './InvestmentExperienceType';
import InvestmentObjectivesType from './InvestmentObjectivesType';
import MedicalExpensesCoverType from './MedicalExpensesCoverType';
import PurposeType from './PurposeType';
import RiskToleranceType from './RiskToleranceType';
import ToleranceModel from './ToleranceModel';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const InvestmentProfile: React.FC<Props> = ({ onDirty }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text size="xlarge" weight="bold" margin={{ top: 'spacing8', bottom: 'spacing16' }}>{t('Investment Profile')}</Text>
      <Box direction="column" background="module" round="moduleRound" pad="spacing24" margin={{ bottom: 'spacing24' }}>
        <ToleranceModel onDirty={onDirty} />
        <InvestmentObjectivesType onDirty={onDirty} />
        <InvestmentExperienceType onDirty={onDirty} />
        <PurposeType onDirty={onDirty} />
        <RiskToleranceType onDirty={onDirty} />
        <MedicalExpensesCoverType onDirty={onDirty} />
      </Box>
    </>
  );
};

export default InvestmentProfile;
