import React, { useMemo } from 'react';
import {
  CellIcon,
  divideString,
  MailMessageIcon,
  NetworkIcon,
} from '@common-fe/common-fe';

import regexp from '@/common/regexp';
import { LinkIcon, StyledCopyIcon } from '@/modules/core/components/Menu/Sidebar/SidebarOption.styles';
import { OrganizationView } from '@/modules/user/user.types';
import { useCopyToClipboard } from '@/utils';

export interface Option {
  key: string;
  title: string;
  icon?: React.ReactNode;
  url?: string;
  link?: string;
  textStyle?: React.CSSProperties;
  activeBackgroundOnHover?: boolean;
  additionalIcon?: React.ReactNode;
}

interface Props {
  organizationProperties: OrganizationView;
}

interface IconsProps {
  text: string;
}

const CopyToClipboardIcon: React.FC<IconsProps> = ({ text }) => {
  const copyToClipboard = useCopyToClipboard(text);

  return <StyledCopyIcon size="18px" onClick={copyToClipboard} />;
};

const useHelpMenuItemList = ({ organizationProperties }: Props) => {
  const phoneNumbersOnly = `+${organizationProperties?.customerServiceNumber?.replace(regexp.NOT_NUMBERS_REGEXP, '')}`;

  const optionList = useMemo<Option[]>(
    () => (organizationProperties?.customerServiceIsEnabled ? [
      ...(organizationProperties.customerServiceUrlName ? [{
        key: 'customer_service_url_link',
        title: organizationProperties.customerServiceUrlName,
        icon: <NetworkIcon size="22px" />,
        link: organizationProperties?.customerServiceUrlLink,
        activeBackgroundOnHover: true,
        additionalIcon: <LinkIcon size="16px" />,
        textStyle: { textOverflow: 'ellipsis' },
      }] : []),
      ...(organizationProperties.customerServiceEmail ? [{
        key: 'customer_service_email',
        title: organizationProperties.customerServiceEmail,
        icon: <MailMessageIcon size="medium" color="iconPrimary" />,
        activeBackgroundOnHover: true,
        additionalIcon: <CopyToClipboardIcon text={organizationProperties.customerServiceEmail} />,
        textStyle: { textOverflow: 'ellipsis' },
      }] : []),
      ...(organizationProperties.customerServiceNumber ? [{
        key: 'home',
        title: divideString(phoneNumbersOnly, [2, 5, 8]),
        icon: <CellIcon size="22px" />,
        activeBackgroundOnHover: true,
        textStyle: { textOverflow: 'ellipsis' },
      }] : []),
    ] : []
    ),
    [organizationProperties, phoneNumbersOnly],
  );

  return {
    formattedList: optionList || [],
  };
};

export default useHelpMenuItemList;
