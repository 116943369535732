import { useCallback } from 'react';
import dayjs from 'dayjs';
import { toString } from 'lodash';

import { api } from '@/api';
import { FileImage,FileType } from '@/common/constants';
import PATHS from '@/common/paths';
import useCreateDocumentByQRCodeQuery from '@/modules/transaction/components/ReimburseMe/queries/useCreateDocumentByQRCode.query';
import useUploadFileByQrLinkStore from '@/modules/transaction/components/ReimburseMe/UploadFileQRCode/store/useUploadFileByQrLink.store';
import { getFileInBlob } from '@/utils/modifiers';

const isExpired = (validTo?: string | null) => {
  if (!validTo) {
    return true;
  }

  const currentDate = dayjs().toDate();
  const validToDate = dayjs(validTo).toDate();

  return currentDate > validToDate;
};

const getFileType = (url: string): FileType | undefined => {
  const formatStr = url?.split('?')?.[0]?.split('.')?.pop();
  const type = formatStr ? formatStr.trim().toUpperCase() : null;

  return FileType[type as keyof typeof FileType];
};
export const getBlob = async (url: string) => fetch(url)
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  .then(async (response: any) => {
    const blob = await response?.arrayBuffer();

    return new Blob(
      [blob],
      { type: getFileType(url) },
    );
  });

const useFileUploadByQrQuery = () => {
  const { docId, employeeId, qrId, validTo } = useUploadFileByQrLinkStore((state) => state.state);
  const { createDocument } = useCreateDocumentByQRCodeQuery();

  const createDocumentByQr = useCallback(async () => {
    if (!qrId || !employeeId) return undefined;

    if (docId) return docId;
    const { data: { document_id: newDocId } } = await createDocument({
      qrId,
      employeeId: Number(employeeId),
    });

    return newDocId;
  }, [createDocument, docId, employeeId, qrId]);

  const uploadFile = useCallback(async (file: FileImage) => {
    if (!qrId || !employeeId) return false;

    if (isExpired(validTo)) {
      return false;
    }

    const documentId = await createDocumentByQr();

    if (!documentId) return false;

    try {
      const blob = await getFileInBlob(file.preview);
      const formData = new FormData();
      formData.append('file', blob as Blob, file.name || file.path);

      const response = await api.post(
        PATHS.REIMBURSE_RECEIPT_UPLOAD_BY_QR(documentId),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            qr_id: qrId,
            employee_id: employeeId,
          },
        },
      );

      // const responseBlob = await getBlob(response?.data?.s3_url);

      return {
        ...file,
        id: toString(response?.data?.id),
        url: response?.data?.s3_url || '',
        // blob: responseBlob,
        // preview: URL.createObjectURL(responseBlob),
      } as FileImage;
    } catch {
      return false;
    }
  }, [createDocumentByQr, employeeId, qrId, validTo]);
  return {
    uploadFile,
  };
};

export default useFileUploadByQrQuery;
