import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// import { FontIcon } from '@common-fe/common-fe';
import { useVisualImpairment } from '@/modules/core/hooks';

import { IconWrapper,Text, Wrapper } from './VisualImpairment.styles';

interface Props {
  disabled?: boolean;
}
const VisualImpairment: React.FC<Props> = ({ disabled }) => {
  const { applied, setApplied } = useVisualImpairment();
  const { t } = useTranslation('buttons');
  const handleToggle = useCallback(() => {
    setApplied(!applied);
  }, [applied, setApplied]);

  return (
    <Wrapper onClick={handleToggle} disabled={disabled}>
      <IconWrapper>
        {/* <FontIcon type="impairment" /> */}
      </IconWrapper>
      <Text>{ applied ? t('Regular version') : t('Version for people with visual impairment')}</Text>
    </Wrapper>
  );
};
export default VisualImpairment;
