import React from 'react';
import { Box, Text } from '@common-fe/common-fe';
import { BoxProps } from 'grommet/components/Box';
import styled from 'styled-components';

export const StyledContent = styled(Box)`
  display: block;
`;

export const Wrapper = styled(Box)`
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex: 1;
  }
  width: 700px;
`;

export interface InputProps {
  label: React.ReactNode;
  children: React.ReactNode;
  align?: BoxProps['align'];
  margin?: BoxProps['margin'];
  viewMode?: boolean;
}

export interface LabelProps {
  children: React.ReactNode;
  viewMode?: boolean;
}

export const TextInputWrap: React.FC<InputProps> = ({
  label,
  children,
  align,
  margin,
  viewMode,
}) => (
  <Box direction="row" align={align || 'start'} margin={margin}>
    <Box width="medium" pad={viewMode ? {} : { top: '10px' }}>
      {label}
    </Box>
    <Box width="medium">
      {children}
    </Box>
  </Box>
);

export const Label: React.FC<LabelProps> = ({
  viewMode,
  children,
}) => (
  <Text color="textBody" weight={viewMode ? 'normal' : 'bold'}>{children}</Text>
);

export const BlackText = styled(Text)`
  color: ${({ theme }) => theme.colors.textBody};
`;
