import { useEffect,useState } from 'react';
import { useDebounce } from '@common-fe/common-fe';

const MINIMUM_SEARCH_LENGHT = 3;
export const usePaginationParams = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [providerSearchString, setSearchProvider] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const debouncedSearch = useDebounce(providerSearchString, 250);
  useEffect(() => {
    const trimedSearchString = debouncedSearch.trim();
    if (trimedSearchString.length >= MINIMUM_SEARCH_LENGHT) {
      setCurrentSearch(trimedSearchString);
    } else {
      setCurrentSearch('');
    }
  }, [debouncedSearch]);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
    providerSearchString,
    setSearchProvider,
    currentSearch,
  };
};
