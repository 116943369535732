import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box,SuccessModal } from '@common-fe/common-fe';
import Lottie from 'lottie-react';

import SuccessIcon from '@/assets/icons/successIcon.json';
import useEmployeeStore from '@/modules/user/stores/useEmployee.store';

interface Props {
  onSetVisible: (value: boolean) => void;
}

const GoPaperlessSuccessModal: React.FC<Props> = ({ onSetVisible }) => {
  const { t } = useTranslation();
  const { setEmployee } = useEmployeeStore();

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  useEffect(() => () => {
    setEmployee({ isElectronicStatementOnly: true });
  }, [setEmployee]);

  return (
    <SuccessModal
      icon={(
        <Box margin={{ bottom: 'spacing12' }}>
          <Lottie animationData={SuccessIcon} loop={false} />
        </Box>
      )}
      header={t('You’re all set!')}
      helptext={t('We\'ll let you know when new documents are posted to your account.')}
      visible
      onSetVisible={handleClose}
      buttonText={t('Close')}
      buttonStyle={{ width: '200px' }}
      testId="go-paperless-success_modal_wrapper"
    />
  );
};

export default GoPaperlessSuccessModal;
