import { useMemo } from 'react';
import {
  useQuery,
} from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { InvestmentDto, SearchInvestmentPortfoliosParams } from '@/modules/investments/investments.types';

const QUERY_KEY = 'InvestmentPortfolios';

export const useInvestmentPortfolios = (searchParams: SearchInvestmentPortfoliosParams) => {
  const {
    isLoading, data, isSuccess, refetch,
  } = useQuery([QUERY_KEY, searchParams], () => api.get(PATHS.INVESTMENTS_PORTFOLIOS, {
    params: { ...searchParams },
  }),
  {
    cacheTime: 4,
    retry: 0,
    enabled: !!searchParams?.investor_id,
  });
  
  const formatedData = useMemo(() => {
    const receivedData: InvestmentDto[] = data?.data || [];
    return receivedData?.map((listItem: InvestmentDto) => ({
      election: listItem.election,
      amount: listItem.amount,
      fundName: listItem.fund_name,
      researchUrl: listItem.research_url,
      imageUrl: listItem.image_url,
      type: listItem.type,
      status: listItem.status,
      instrumentId: listItem.instrument_id,
    }));
  }, [data]);
  return ({
    data: formatedData || [],
    isLoading,
    isSuccess,
    refetch,
  });
};
