import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Hint,
  Inscription,
  RangeInput,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { RiskControlOptions } from '@/common/constants';
import { RiskOption } from '@/common/types';
import colors from '@/styles/colors';

interface Props {
  riskValue: string;
  recommendedRisk?: string;
  currentRisk?: string;
  disabled?: boolean;
  labelColor?: string;
  labelText?: string;
  currentLabelColor?: string;
  currentLabelText?: string;
  isHintHidden?: boolean;
  riskOptions: RiskOption[];
  setRiskValue: (value: string) => void;
  setRiskModal: (value: boolean) => void;
}

const RecommendedTitleWrapper = styled(Box)`
  position: absolute;
  top: 0px;
  cursor: pointer;
`;

const RiskCard: React.FC<Props> = ({
  riskValue,
  recommendedRisk,
  currentRisk,
  disabled,
  labelColor,
  labelText,
  currentLabelColor,
  currentLabelText,
  isHintHidden,
  riskOptions,
  setRiskValue,
  setRiskModal,
}) => {
  const { t } = useTranslation();
  const details = useMemo(() => {
    switch (riskValue) {
    case RiskControlOptions.CONSERVATIVE: {
      return {
        description: t('This portfolio may be appropriate for investors who wish to minimize risk in their portfolio but want some exposure to the stock market. Also, consider this portfolio…'),
        label: t('Cash & Bonds: 80%, Stocks: 20%'),
      };
    }
    case RiskControlOptions.MODERATELY_CONSERVATIVE: {
      return {
        description: t('This portfolio may be appropriate for investors who want a balance of risk and return but prefer to take lower levels of risk and are comfortable accepting lower potential…'),
        label: t('Cash & Bonds: 60%, Stocks: 40%'),
      };
    }
    case RiskControlOptions.MODERATE: {
      return {
        description: t('This portfolio may be appropriate for investors who want a balance of risk and return. Consider this portfolio if you are up to 10+ years from retirement…'),
        label: t('Cash & Bonds: 40%, Stocks: 60%'),
      };
    }
    case RiskControlOptions.MODERATELY_AGGRESSIVE: {
      return {
        description: t('This portfolio may be appropriate for investors who are comfortable accepting additional risk for the potential to earn higher returns. Consider this portfolio if you are...'),
        label: t('Cash & Bonds: 25%, Stocks: 75%'),
      };
    }
    case RiskControlOptions.AGGRESSIVE: {
      return {
        description: t('This portfolio may be appropriate for investors who prioritize portfolio growth and are comfortable accepting substantial risk. Consider this portfolio…'),
        label: t('Cash & Bonds: 10%, Stocks: 90%'),
      };
    }
    default: {
      return {
        description: '',
        label: '',
      };
    }
    }
  }, [riskValue, t]);
  const titleLabel = useMemo(() => (
    <Box round={{ corner: 'bottom', size: '4px' }} pad={{ vertical: 'xxxs', horizontal: 'xs' }} background={labelColor || colors.success}>
      <Text size="small" color="textOnColor">{labelText}</Text>
    </Box>
  ), [labelColor, labelText]);
  const currentTitleLabel = useMemo(() => (
    <Box round={{ corner: 'bottom', size: '4px' }} pad={{ vertical: 'xxxs', horizontal: 'xs' }} background={currentLabelColor || colors.stressContainer}>
      <Text size="small" color="textOnColor">{currentLabelText}</Text>
    </Box>
  ), [currentLabelColor, currentLabelText]);

  return (
    <Box
      background="canvas"
      round="container1Round"
      width="580px"
      height="250px"
      pad={{ vertical: disabled ? 'xxl' : 'xl', horizontal: 'l' }}
      data-testid="riskcard-wrapper-id"
      align="center"
      border={{ size: 'small', color: 'border2' }}
      elevation="default"
      style={{
        position: 'relative',
      }}
    >
      <RecommendedTitleWrapper direction="row" gap="spacing8">
        {
          currentTitleLabel && currentRisk === riskValue ? (currentTitleLabel) : <></>
        }
        {
           
          isHintHidden
            ? (
              titleLabel
            ) : recommendedRisk === riskValue ? (
              <Hint
                hintElement={titleLabel}
                hintHeight={500}
              >
                
                <Text textAlign="center">
                  {t('This option best matches your investment portfolio (recommended based on your Portfolio Questionnaire).')}
                </Text>
              </Hint>
            ) : <></>
        }
      </RecommendedTitleWrapper>
      <RangeInput
        options={riskOptions}
        value={riskValue}
        onChange={setRiskValue}
        disabled={disabled}
        colorsThumb={colors.button}
        width={540}
        recommendedOption={recommendedRisk}
        currentOption={currentRisk}
      />
      <Text textAlign="center" weight="bold" size="medium" margin={{ bottom: 's', top: isHintHidden ? 'xxxl' : 's' }}>
        {details.label}
      </Text>
      <Box height={{ min: '60px', max: '60px'}}>
        <Text textAlign="center">
          {details.description}
          <Inscription
            onClick={() => setRiskModal(true)}
            margin={{ left: 'spacing4' }}
            cursor="pointer"
            weight={500}
            color="textAccent"
          >
            {t('Read more')}
          </Inscription>
        </Text>
      </Box>
    </Box>
  );
};

export default RiskCard;
