import { useMemo } from 'react';

import { useInvestmentsPendingActivities, useInvestor } from '@/modules/investments/hooks';
import { useActiveFunds } from '@/modules/investments/Investments/hooks';
import { FundsPayload } from '@/modules/investments/SelfDirected/SelfDirected.types';
interface Params {
  accountId?: string;

}
export default ({ accountId}: Params) => {
  const { data: activeFunds } = useActiveFunds({
    accountId,
  });

  const { data: { investorId} } = useInvestor(accountId);
  const { fullLiquidationMap } = useInvestmentsPendingActivities(investorId);


  const formattedData = useMemo(() => {
    return activeFunds?.map((item) => {
      const fund: FundsPayload = {
        ...item,
        isFullLiquidation: fullLiquidationMap?.[item.stockCode] || false
      };
      return fund;
    });
  }, [activeFunds, fullLiquidationMap]);
  return {
    data: formattedData,
    fullLiquidationMap
  };
};