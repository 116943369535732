import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton, Box, HASIcon } from '@common-fe/common-fe';

import { Banner } from '@/modules/core/components/Dashboard/components/BannersList/Banner';
import { useSwitchPopupStore } from '@/modules/user/components/UserDrodpdown/stores';

import { BannerActionCompleted, BannerStorageKey, YES_KEY } from '../bannersList.constants';
import useDismissBanner from '../hooks/useDismissBanner';

const ImportantChangesBanner = () => {
  const { t } = useTranslation();
  const { setIsPopupVisible } = useSwitchPopupStore();
  const { handleDismissBanner } = useDismissBanner();

  const handleOpenPopup = useCallback(() => {
    setIsPopupVisible(true);
    localStorage.setItem(BannerActionCompleted.ImportantChangesBannerAction, YES_KEY);
    window.scrollTo(0, 0);
  }, [setIsPopupVisible]);

  const handleDismiss = useCallback(() => {
    handleDismissBanner(BannerStorageKey.ImportantChangesBanner);
  }, [handleDismissBanner]);

  const isDismissButtonAvailable = localStorage
    .getItem(BannerActionCompleted.ImportantChangesBannerAction) === YES_KEY;

  return (
    <Banner
      title={t('Important Changes to Your HSA')}
      description={t('We noticed you recently separated from your employer. Don\'t worry! You still have access to your old employer sponsored account.')}
      icon={<HASIcon />}
      testId="important-changes"
    >
      <Box margin={{ top: 'spacing24' }} direction="row">
        <Box>
          <AppButton
            testId="open-popup"
            onClick={handleOpenPopup}
            width="180px"
          >
            Show Me How
          </AppButton>
        </Box>

        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            buttonType="secondary"
            width="180px"
            onClick={handleDismiss}
            disabled={!isDismissButtonAvailable}
          >
            Dismiss
          </AppButton>
        </Box>
      </Box>
    </Banner>
  );
};

export default ImportantChangesBanner;
