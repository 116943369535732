import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription } from '@common-fe/common-fe';

import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import UploadFileQRCode from './UploadFileByQRCode';

const isWhiteLabeling = whiteLabelingConfig.title;

const UploadFileQRCodeSection = () => {
  const { t } = useTranslation();

  return (
    <Box
      round="container1Round"
      background="canvas"
      pad="l"
      border={{ color: 'border2', size: 'small' }}
      align="center"
      justify="center"
      height="100%"
      data-testId="upload-file-qr-code-section"
    >
      <UploadFileQRCode size={150} imagePadding={10} withPadding />

      <Box margin={{ top: 'spacing8' }}>
        <Inscription
          weight={whiteLabelingConfig.fonts?.reimbursementWeight}
          size="20px"
          lineHeight="28px"
          textAlign="center"
        >
          {t('Scan QR Code')}
          <br />
          {t('to Upload File')}
        </Inscription>
      </Box>

      <Box margin={{ top: 'spacing8' }}>
        <Inscription
          textAlign="center"
          lineHeight="20px"
          color={isWhiteLabeling ? 'textSecondary' : 'textDisabled'}
          weight={whiteLabelingConfig.fonts?.reimbursementWeight || 'normal'}
        >
          {t('Effortlessly upload files by simply scanning the QR code with your device\'s camera.')}
        </Inscription>
      </Box>
    </Box>
  );
};

export default UploadFileQRCodeSection;
