import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { MFAChannel } from '@/modules/user/components/SignIn/MultiFactorAuthentication/mfa.types';

interface Payload {
  channel: MFAChannel;
  session: string;
}

const useSendMFACodeQuery = () => {
  const { mutateAsync, isError, isLoading } = useMutation((value: Payload) => api.post(PATHS.SEND_MFA_CODE, {
    channel: {
      type: value.channel.type,
      value: value.channel.value,
    },
    session: value.session,
  }));
  
  return {
    sendMFACode: mutateAsync,
    isError,
    isLoading,
  };
};

export default useSendMFACodeQuery;
