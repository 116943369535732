import React, { useEffect,useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Option,Preloader } from '@common-fe/common-fe';
import { toString } from 'lodash';

import ROUTES from '@/common/routes';
import useFilesInDocumentStore from '@/components/controls/DropScan/useFilesInDocument.store';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { TransactionDetails } from '@/modules/transaction/components';
import { useGetTransactionDetailsByClaimId, useGetTransactionDetailsByPostingId } from '@/modules/transaction/hooks/useGetTransactionDetails.query';
import { TransactionStatus } from '@/modules/transaction/transaction.types';
import { useAuthStore } from '@/modules/user/stores';
import theme from '@/styles/theme';

export enum TransactionPageType {
  ACCOUNT,
  EXPENSE,
}

interface Props {
  transactionPageType: TransactionPageType
}

const TransactionDetailsPage: React.FC<Props> = ({ transactionPageType }) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const {
    claimId,
    postingId,
    accountId,
  } = useParams<{ claimId: string, postingId: string, accountId: string }>();
  const {
    formattedData: claimData,
    isLoading: isLoadingClaim,
  } = useGetTransactionDetailsByClaimId(claimId);

  const {
    formattedData: postingData,
    isLoading: isLoadingPosting,
  } = useGetTransactionDetailsByPostingId(postingId, user?.employee?.id);
  const hasAddDoc = useMemo(
    () => (claimData?.approvalStatus === TransactionStatus.DocsNeeded),
    [claimData],
  );
  const parentPageBreadcrumb: Option<string> = useMemo(() => {
    switch (transactionPageType) {
    case TransactionPageType.EXPENSE: {
      return {
        key: ROUTES.EXPENSE_DETAILS,
        title: t('Expense Details'),
        value: ROUTES.EXPENSE_DETAILS,
      };
    }
    default: {
      return {
        key: accountId ? ROUTES.MY_ACCOUNTS_DETAILS(accountId) : ROUTES.MY_ACCOUNTS,
        title: t('Activity details'),
        value: accountId ? ROUTES.MY_ACCOUNTS_DETAILS(accountId) : ROUTES.MY_ACCOUNTS,
      };
    }
    }
  }, [transactionPageType, accountId, t]);
  useBreadcrumbs([{
    key: ROUTES.HOME,
    title: 'Home',
    value: ROUTES.HOME,
  }, parentPageBreadcrumb, {
    key: (postingId && ROUTES.TRANSACTION_DETAILS_POSTING(accountId, postingId))
      || (claimId && ROUTES.TRANSACTION_DETAILS_CLAIM(accountId, claimId)),
    title: `${claimData?.memo || postingData?.memo || ''}`,
    value: (postingId && ROUTES.TRANSACTION_DETAILS_POSTING(accountId, postingId))
      || (claimId && ROUTES.TRANSACTION_DETAILS_CLAIM(accountId, claimId)),
  },
  ], true);

  const { handleReset } = useFilesInDocumentStore();
  useEffect(() => () => {
    handleReset();
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box pad={{ top: '0', bottom: '40px' }} width="100%">
      <Box
        width={theme.defaultContentWidth}
        alignSelf="center"
      >
        {isLoadingClaim || isLoadingPosting || !user?.employee?.id ? (
          <Box align="center" justify="center"><Preloader /></Box>
        ) : null}
        {
          claimId && claimData.id && !isLoadingClaim && user?.employee?.id
            ? (
              <TransactionDetails
                claimId={claimId}
                data={claimData}
                employeeId={toString(user?.employee?.id)}
                hasAddDoc={hasAddDoc}
              />
            ) : null
        }
        {
          postingId && !isLoadingPosting && user?.employee?.id && postingData?.postingId
            ? (
              <TransactionDetails
                postingId={postingId}
                data={postingData}
                employeeId={toString(user?.employee?.id)}
                hasAddDoc={hasAddDoc}
              />
            ) : null
        }
      </Box>
    </Box>
  );
};
export default TransactionDetailsPage;
