import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CashIcon, Hint, Image, NullFundIcon, Text,WarningIcon } from '@common-fe/common-fe';

import NullIconURL from '@/assets/placeholders/FundNullIcon.svg';
import theme from '@/styles/theme';

import { InvestmentHolding, InvestmentHoldingStatus, InvestmentPending } from '../investments.types';
const CASH_KEY = 'CASH';

type IconObject= Partial<InvestmentPending | InvestmentHolding> & {
  isGreyMode?: boolean;
  isNullMode?: boolean;
  message?: string;
}
export default () => {
  const { t } = useTranslation();
  const handleGetIcon = useCallback((item: IconObject) => {
    let icon: React.ReactNode =( 
      <NullFundIcon 
        data-testid="null-fund-icon"
        width="32px"
        height="32px"
        style={{
          opacity: item.isGreyMode ? 0.2: 1 
        }} />
    );
    if (item?.type === CASH_KEY) {
      icon = <CashIcon data-testid="cash-icon" />;
    } else if (item?.status === InvestmentHoldingStatus.Inactive) {
      icon = (
        <Box align='center' justify='center' style={{position: 'relative'}}>
          {item?.image ? (
            <Image
              alt="stock image"
              data-testid="fund-icon"
              src={item.image}
              fallback={NullIconURL}
              style={{ width: theme.spacings.xl, height: theme.spacings.xl, opacity: 0.03 }}
            />
          ): (
            <NullFundIcon 
              data-testid="null-fund-icon"
              style={{ width: theme.spacings.xl, height: theme.spacings.xl, opacity: 0.03 }} 
            /> 
          )}
          <Box style={{position: 'absolute'}}>
            <Hint
              hintWidth={250}
              contentStyles={{
                zIndex: 10000,
                position:'relative',
              }}
              hintElement={(
                <WarningIcon    data-testid="warning-icon" />
              )}>
              <Text  
                data-testid="warning-text" 
                size="small"
                weight={500}>
                {item.message || t('The holding is delisted as it no longer meets the requirements for stock exchange reporting or goes private. Don\'t worry, you can still sell it.')}
              </Text>
            </Hint>
          </Box>
        </Box>
      );
    } else if (item.image) {
      icon = (
        <Image
          alt="stock image"
          data-testid="fund-icon"
          src={item.image}
          fallback={NullIconURL}
          style={{ 
            width: theme.spacings.xl, 
            height: theme.spacings.xl,  
            opacity: item?.isGreyMode ? 0.2: 1  }}
        />
      );
    }
    
    return icon;
  }, [t]);
  return handleGetIcon;
};