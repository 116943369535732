import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppButton,
  Box,
  Preloader,
  RoundPendingIcon,
  Text,
  UploadIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import { joinWithCommas } from '@/utils/modifiers/joinWithCommas';

import { OcrWarningType } from '../../ReimburseMe/FileScan/fileScan.types';

import { useTransactionOcrWarningsStore } from './store';

const CLAIM_MISSING_TEXT = 'Claim information';
const GO_TO_DASHBOARD_HEADER = 'Your request has been submitted for additional review';
const GO_TO_DASHBOARD_DESCRIPTION = 'You can check on the status of this request under account activity.';

enum ModalStep {
  MISSING_WARNINGS = 1,
  GO_TO_DASHBOARD = 2,
}

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
}

export const MissingOcrTransactionInfoModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSubmit,
  onCancel,
  isLoading,
}) => {
  const [step, setStep] = React.useState<ModalStep>(ModalStep.MISSING_WARNINGS);
  const { t } = useTranslation();
  const warnings = useTransactionOcrWarningsStore((store) => store.ocrWarnings);
  const missingFields = useMemo(() => {
    if (!warnings[OcrWarningType.MISSING].length) return undefined;

    const preparedFields = warnings[OcrWarningType.MISSING].map((warning) => warning.fieldLabel);

    return {
      length: preparedFields.length,
      text: joinWithCommas(preparedFields)
    };
  }, [warnings]);

  const headerText = useMemo(() => t(`${missingFields?.text || CLAIM_MISSING_TEXT} not found!`), [missingFields, t]);

  const descriptionText = useMemo(() => t(`Please take another picture of the receipt making sure the image is clear and
  the ${missingFields?.text || CLAIM_MISSING_TEXT} ${missingFields?.length && missingFields?.length > 1 ? 'are' : 'is'} visible.`),
  [missingFields, t]);

  if (step === ModalStep.GO_TO_DASHBOARD) {
    return (
      <ModalWrapper testId="ConfirmUpdateRiskPortfolioModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
        <Box align="center" pad="spacing24">
          <RoundPendingIcon color="warning" />
          <Box pad={{ horizontal: 'spacing96' }}>
            <Text
              data-testid="ConfirmUpdateRiskPortfolioModal_modal_header"
              margin={{ vertical: 'spacing12' }}
              textAlign="center"
              size="24px"
              weight={700}
            >
              {GO_TO_DASHBOARD_HEADER}
            </Text>
          </Box>
          <Text
            data-testid="ConfirmUpdateRiskPortfolioModal_help-text"
            margin={{ bottom: 'spacing24' }}
            textAlign="center"
          >
            {GO_TO_DASHBOARD_DESCRIPTION}
          </Text>

          <AppButton
            testId="ConfirmUpdateRiskPortfolioModal_modal_cancel"
            onClick={() => onSetVisible(false)}
            width="200px"
          >
            {t('Go to Expense Details')}
          </AppButton>
        </Box>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper testId="ConfirmUpdateRiskPortfolioModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad="spacing24">
        <RoundPendingIcon color="warning" />
        <Text
          data-testid="ConfirmUpdateRiskPortfolioModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
        >
          {headerText}
        </Text>
        <Text
          data-testid="ConfirmUpdateRiskPortfolioModal_help-text"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {descriptionText}
        </Text>

        <Box direction="row" gap="spacing12">
          <AppButton
            buttonType="secondary"
            testId="ConfirmUpdateRiskPortfolioModal_modal_cancel"
            onClick={() => {
              if (onCancel) onCancel();
              setStep(ModalStep.GO_TO_DASHBOARD);
            }}
            width="250px"
          >
            {t('Submit Without Changes')}
          </AppButton>
          <AppButton
            testId="ConfirmUpdateRiskPortfolioModal_modal_submit"
            rightIcon={<UploadIcon />}
            onClick={onSubmit}
            disabled={isLoading}
            width="250px"
          >
            {isLoading
              ? <Preloader />
              : t('Upload Another Document')}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
