import React, { useMemo } from 'react';
import { ListItem, ListItemType } from '@common-fe/common-fe';

import { DownloadStatementButton,ViewStatementButton } from '@/modules/transaction/components/Statements/components/StatementsList/components';
import { Statement } from '@/modules/transaction/components/Statements/statements.types';

interface Props {
  statements?: Statement[];
}

const useStatementsList = ({
  statements,
}: Props) => {
  const formattedFields = useMemo(() => {
    if (!statements?.length) return [];

    const formatted: ListItem[] = statements.map((statement: Statement) => ({
      id: statement?.documentId || '',
      dropAlign: { bottom: 'top', right: 'right' },
      fields: [
        {
          key: 'statementName',
          title: statement?.startDate,
          type: ListItemType.Node,
          node: (
            <ViewStatementButton
              name={statement.name}
              startDate={statement.startDate}
              documentId={statement.documentId}
            />
          ),
          flex: 1,
        },
        {
          key: 'downloadStatement',
          title: statement?.id,
          type: ListItemType.Node,
          node: (
            <DownloadStatementButton documentId={statement.documentId} />
          ),
          flex: 1,
        },
      ],
    }));

    return formatted;
  }, [statements]);

  return {
    fields: formattedFields,
  };
};

export default useStatementsList;
