import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, NavigationRightIcon, Text } from '@common-fe/common-fe';
import _ from 'lodash';

import { REGEXPS } from '@/common';
import { CURRENCY_SIGN } from '@/common/constants';

import AutoInvestmentReminder , {useAutoInvestmentReminder } from '../../AutoInvestment/AutoInvestmentReminder';
import EnrollmentCardBlock from '../SelfDirectedAllocation/EnrollmentCardBlock';
import { useSelfDirectedAllocationValidation } from '../SelfDirectedAllocation/hooks';
import SelfDirectedAllocationFunds from '../SelfDirectedAllocation/SelfDirectedAllocationFunds';
import SelfDirectedInvestAmount from '../SelfDirectedAllocation/SelfDirectedInvestAmount';

import SelfDIrectedInvestMoreWrapper from './SelfDirectedInvestMoreWrapper';
interface Props {
  accountId: string;
  investorId?: string;
}
export const SelfDirectedInvestMore : React.FC<Props> = ({ accountId, investorId}) => {
  const [value, setValue] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const valueToNumber = useMemo(() => {
    const clearedValue = value.replace(REGEXPS.ALL_COMMAS_REGEXP, '').replace(CURRENCY_SIGN, '');
    return _.toNumber(clearedValue);
  }, [value]);
  const { maxAmount, errorText } = useSelfDirectedAllocationValidation({
    accountId,
    investorId,
    value: valueToNumber
  });
  const { isDisplayedReminder } = useAutoInvestmentReminder({ accountId});
  const { t } = useTranslation();
  return (
    <SelfDIrectedInvestMoreWrapper currentStep={1}>

      <Text size="3xl" weight={700}>{t('Invest More')}</Text>
      <Text size="large">{t('Set your investment amount and distribute it across your selected investments.')}</Text>
      <Text size="large">{t('The selections you make here will apply only to this purchase and will not rebalance your current portfolio.')}</Text>
      
      <Box direction="row" margin={{ top: 'l', bottom: 'l' }} background="module" round="moduleRound" pad="l">
        <EnrollmentCardBlock accountId={accountId} />
        <Box pad={{ horizontal: 's' }} align="center" justify='center'> <NavigationRightIcon /></Box>
        <Box direction='column'  justify='center'>
          <SelfDirectedInvestAmount
            value={value}
            errorText={errorText}
            maxValue={maxAmount}
            isSubmitted={isSubmitted}
            onSetValue={setValue} />

          {
            isDisplayedReminder && (
              <AutoInvestmentReminder accountId={accountId} />
            )
          }
        </Box>
      </Box>
      <SelfDirectedAllocationFunds
        isErrors={!!errorText}
        isInvestMoreMode
        investorId={investorId}
        accountId={accountId}
        investAmount={valueToNumber}
        isSubmitted={isSubmitted}
        onSetSubmitted={setSubmitted} />
    </SelfDIrectedInvestMoreWrapper>

  );
};


export default SelfDirectedInvestMore;