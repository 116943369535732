import React, { useMemo } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DATE_TOOLTIP_FORMAT } from '@/common/constants';
import { Notification, PaymentType,Service,TransactionApprovalCode } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import text from '../AppealText';

interface Props {
  data?: Notification;
  service?: Service;
  serviceOn?: string;
  employeeFirstName?: string;
  serviceDate?: string;
  paymentType?: PaymentType;
}

const useGetPartiallyPaidNotificationMessage = (props: Props) => {
  const { data, employeeFirstName, paymentType, service, serviceOn } = props;
  const paymentTypeLabel = useMemo(() => paymentType?.replace('_', ' ')?.toLowerCase(), [paymentType]);

  return useMemo(() => {
    switch (data?.approvalCode) {
    case TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_IRS_RULES:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like some of the expenses are not eligible per your plan or IRS rules so we were only able to approve part of the amount requested for this service.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.EXPENSE_NOT_ELIGIBLE_PER_PLAN_RULES:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like some of the expenses are not eligible per your plan or IRS rules so we were only able to approve part of the amount requested for this service.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.SERVICE_DATE_OUTSIDE_OF_COVERAGE_PERIOD:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like the date for the expense you submitted was outside the coverage period for your plan. We can only process payments for expenses that have been incurred within the plan coverage period.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.REQUESTED_AMOUNT_IN_EXCESS_OF_ALLOWED_AMOUNT:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like the amount you requested for ${paymentTypeLabel} is more than what is allowed by your plan, so we were only able to approve part of the amount requested.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.DETAILED_RECEIPT_REQUIRED:
      return {
        mainText:
          `Hi ${employeeFirstName}. It looks like we didn't receive all of the required documentation, so we were only able to approve part of the amount requested. We were missing a detailed receipt or document with the following information:`,
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.PROOF_OF_EXPENSE_NEEDED:
      return {
        mainText:
          `Hi ${employeeFirstName}. It looks like we didn't receive all of the required documentation, so we were only able to approve part of the amount requested. We were missing a detailed receipt or document with the following information:`,
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.ADDITIONAL_DOCUMENTATION_NEEDED_TO_SUBSTANTIATE_TRANSACTION:
      return {
        mainText: (
          <Inscription size="large">
            <Inscription style={theme.fonts.text16Bold}>
              Hi {employeeFirstName}. It looks like we didn't receive all of the required documentation, so we were only able to approve part of the amount requested.
            </Inscription>
            <Box margin={{ top: 'spacing12' }}>We were missing a detailed receipt or document with the following information:</Box>
          </Inscription>
        ),
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CLAIM_COULD_NOT_BE_AUTO_SUBSTANTIATED_BY_CARD_PROCESSOR:
      return {
        mainText:
          `Hi ${employeeFirstName}. It looks like we didn't receive all of the required documentation, so we were only able to approve part of the amount requested. We were missing a detailed receipt or document with the following information:`,
        list: [
          'Amount',
          'Date of Service',
          'Service description',
          'Who the service was for',
          'Name of the provider and/or merchant',
        ],
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.LETTER_OF_MEDICAL_NECESSITY_REQUIRED:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we were missing a letter of medical necessity from your physician, so we were only able to approve part of the amount requested.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CLAIM_RECEIVED_AFTER_RUNOUT_DATE:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like this claim was submitted after the plan's coverage period. We are only able to approve a part of the amount requested for this service.`,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CLAIM_SUBMITTED_AFTER_GRACE_PERIOD:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like this claim was submitted after the plan's coverage period. We are only able to approve a part of the amount requested for this service.`,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.DUPLICATE_CLAIM:
      return {
        mainText: `Hi ${employeeFirstName}. It looks like we already received and processed a ${paymentTypeLabel} for ${service?.name} provided on ${dayjs(serviceOn).format(DATE_TOOLTIP_FORMAT)}, so we were only able to approve part of the amount requested.`,
        text,
        hasAppealButton: true,
      };
    case TransactionApprovalCode.CUSTOM:
      return {
        text: data.customDenialReason,
        hasAppealButton: true,
      };
    default:
      return {};
    }
  }, [data, employeeFirstName, paymentTypeLabel, service, serviceOn]);
};

export default useGetPartiallyPaidNotificationMessage;
