import React from 'react';
import {
  Box,   
  Preloader,   RoundPendingIcon,   SiblingBoxesWithSpacing,
  Text,
} from '@common-fe/common-fe';

import { Modal } from '@/components';
import AppButton from '@/components/controls/AppButton';
import theme from '@/styles/theme';

import { Wrapper } from './CloseCardConfirmationModal.styles';

const BUTTON_WIDTH = '140px';

interface Props {
  header: string;
  helpText: string;
  buttonText?: string;
  children?: React.ReactNode;
  visible: boolean;
  onSetVisible?: (value: boolean) => void;
  notHideClickOutside?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  submitting?: boolean;
}

const CloseCardConfirmationModal: React.FC<Props> = ({
  onSetVisible,
  visible,
  header,
  children,
  helpText,
  buttonText,
  onSubmit,
  notHideClickOutside,
  onCancel,
  submitting,
}) => {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    if (onSetVisible) onSetVisible(false);
  };
  return (
    <Modal
      visible={visible}
      onSetVisible={onSetVisible}
      notHideClickOutside={notHideClickOutside}
    >
      <Wrapper
        direction="column"
        align="center"
        pad={{ top: 'spacing12', bottom: 'spacing24' }}
      >
        <RoundPendingIcon  size="72px"  color="warning"/>
        
        <Text size={theme.spacings.spacing24} weight={700}>
          {header}
        </Text>

        <Text margin={{ bottom: 'spacing24' }}>{helpText}</Text>
        {children}
        <Box direction="row" align="center">
          {
            onCancel && (
              <SiblingBoxesWithSpacing width={BUTTON_WIDTH}>
                <AppButton
                  buttonType="secondary"
                  onClick={onCancel}
                  disabled={submitting}
                  width={BUTTON_WIDTH}
                >
                  Cancel
                </AppButton>
              </SiblingBoxesWithSpacing>
            )
          }
          {buttonText && (
            <SiblingBoxesWithSpacing>
              <AppButton
                onClick={handleSubmit}
                color="red"
                width={BUTTON_WIDTH}
              >
                {submitting ? <Preloader color="white" /> : buttonText}
              </AppButton>
            </SiblingBoxesWithSpacing>
          )}
        </Box>
      </Wrapper>
    </Modal>
  );
};

export default CloseCardConfirmationModal;
