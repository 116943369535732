import { useEffect,useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import {
  Transactor,
  TransactorResponse,
} from '@/modules/transaction/transaction.types';

export const GET_TRANSACTOR_BY_ID = 'getTransactorById';

export const useGetTransactor = (transactorId?: string) => {
  const {
    data, isLoading, isSuccess, isFetching, refetch, remove,
  } = useQuery(
    [GET_TRANSACTOR_BY_ID, transactorId],
    () => api.get(PATHS.TRANSACTOR_DETAILS_BY_ID(transactorId)),
  );

  useEffect(() => {
    if (transactorId) refetch();
    else remove();
  }, [transactorId, refetch, remove]);

  const formatData = (transactor?: TransactorResponse) => ({
    providerName: transactor?.facility_name || '',
    providerAddress1: transactor?.addresses?.[0]?.line1 || '',
    providerAddress2: transactor?.addresses?.[0]?.line2 || '',
    providerCity: transactor?.addresses?.[0]?.city || '',
    providerState: transactor?.addresses?.[0]?.state?.name || '',
    providerZipCode: transactor?.addresses?.[0]?.zipcode || '',
  });

  const formattedData = useMemo(() => formatData(data?.data), [data]);
  return {
    formattedData: formattedData as Transactor,
    data: data?.data,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  };
};
