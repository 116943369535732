import React, {
  useCallback,
  useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import SaveAsDefaultCheckbox from '@/modules/transaction/components/ReimburseMe/ConfirmationStep/Immediately/SaveAsDefaultCheckbox';
import { useSetDefaultPaymentMethodQuery } from '@/modules/transaction/components/ReimburseMe/queries/useUpdatePaymentMethod.query';
import { useStore } from '@/modules/transaction/components/ReimburseMe/store/usePaymentMethods.store';
import spacings from '@/styles/spacings';

interface Props {
  onClose: () => void;
}

const Footer: React.FC<Props> = ({ onClose }) => {
  const [isSaveAsDefault, setIsSaveAsDefault] = useState(false);
  const selectedMethod = useStore((state) => state.selectedMethod);
  const handleSetActivePaymentMethod = useStore((state) => state.handleSetActivePaymentMethod);
  const { updateDefaultPaymentMethod } = useSetDefaultPaymentMethodQuery();
  const { t } = useTranslation();

  const handleSavePaymentMethod = useCallback(() => {
    handleSetActivePaymentMethod();
    if (selectedMethod && isSaveAsDefault) {
      updateDefaultPaymentMethod(Number(selectedMethod.id));
    }
    onClose();
  }, [
    handleSetActivePaymentMethod,
    onClose,
    selectedMethod,
    updateDefaultPaymentMethod,
    isSaveAsDefault,
  ]);

  return (
    <Box direction="row" justify="between" margin={{ top: spacings.l }}>
      <SaveAsDefaultCheckbox
        isSaveAsDefault={isSaveAsDefault}
        setIsSaveAsDefault={setIsSaveAsDefault}
        isDisabled={!selectedMethod}
      />

      <AppButton width="130px" onClick={handleSavePaymentMethod}>
        {t('Save')}
      </AppButton>
    </Box>
  );
};

export default Footer;
