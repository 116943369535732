import React from 'react';
import { useTranslation } from 'react-i18next';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { Cards } from '@/modules/transaction/components/Cards/Cards';

export default () => {
  const { t } = useTranslation();
  useBreadcrumbs([
    {
      key: ROUTES.HOME,
      title: t('Home'),
      value: ROUTES.HOME,
    },
    {
      key: ROUTES.CARDS,
      title: t('Cards'),
      value: `${ROUTES.CARDS}`,
    },
  ]);

  return <Cards data-testid="Cards-wrapper" />;
};
