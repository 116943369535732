import React, { useMemo } from 'react';
import {
  BankCardIconLogo,
  BillingCheckIcon,
  Box,
  PayPalIcon,
  QuestionIcon,
  VenmoIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { PaymentMethod } from '@/modules/transaction/components/ReimburseMe/types/payment.types';
import { PaymentMethodType } from '@/modules/user/user.types';
import spacings from '@/styles/spacings';

import DebitPaymentMethodIcon from './DebitPaymentMethodIcon';

interface IconWrapperProps {
  hideIconBorder?: boolean;
}

const StyledBox = styled(Box)<IconWrapperProps>`
  ${({ theme, hideIconBorder }) => {
    if (hideIconBorder) {
      return null;
    }
    return {
      border: theme.border.blueGray2.border,
      borderRadius: theme.rounds.container2Round
    };
  }}
`;

interface Props {
  paymentMethod?: Partial<PaymentMethod>;
  hideIconBorder?: boolean;
}

const PaymentMethodIcon: React.FC<Props> = ({ paymentMethod, hideIconBorder }) => {
  const { type, requisites } = paymentMethod || {};

  const currentIcon = useMemo(() => {
    switch (type) {
    case PaymentMethodType.PAYPAL:
      return <PayPalIcon data-testid={`PaymentMethodLabel_icon_${type}`} />;
    case PaymentMethodType.VENMO:
      return <VenmoIcon data-testid={`PaymentMethodLabel_icon_${type}`} />;
    case PaymentMethodType.CHECK:
      return (
        <BillingCheckIcon
          data-testid={`PaymentMethodLabel_icon_${type}`}
          size={spacings.l}
          color="iconAccent"
        />
      );
    case PaymentMethodType.DEBIT:
      return (
        <DebitPaymentMethodIcon cardType={requisites?.cardType} />
      );
    case PaymentMethodType.DIRECT_DEPOSIT:
      return (
        <BankCardIconLogo
          data-testid={`PaymentMethodLabel_icon_${type}`}
          size={spacings.l}
          color="iconAccent"
        />
      );
    default:
      return (
        <QuestionIcon
          data-testid="PaymentMethodLabel_no_icon"
          size={spacings.l}
          color="iconAccent"
        />
      );
    }
  }, [type, requisites]);

  return (
    <StyledBox
      pad={spacings.xxs}
      margin={{ right: spacings.s }}
      height={{ min: '40px', max: '40px' }}
      width={{ min: '40px', max: '40px' }}
      align="center"
      justify="center"
      data-testid="PaymentMethodLabel_icon"
      hideIconBorder={hideIconBorder}
    >
      {currentIcon}
    </StyledBox>
  );
};

export default PaymentMethodIcon;
