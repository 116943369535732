import React from 'react';
import { Box, FlexListItem, Inscription, ListItem, ListItemBase } from '@common-fe/common-fe';

import TransactionsListHeader from '@/modules/transaction/components/TransactionsListHeader/TransactionsListHeader';

import { EmployerTerminationPlanListPlaceholder } from './EmployerTerminationPlanListPlaceholder';

const headers: ListItemBase[] = [
  {
    key: 'planName',
    title: 'Plans',
    flex: 1.8,
  },
  {
    key: 'planStartDate',
    title: 'Plan start date',
    flex: 0.8,
  },
  {
    key: 'planEndDate',
    title: 'Plan end date',
    flex: 1,
  }
];

interface Props {
  planList: ListItem[];
}

export const EmployerTerminationPlanList: React.FC<Props> = ({ planList }) => {
  return (
    <Box
      data-testid="EmployerTerminationPlanList_container"
      round="container1Round"
      background="canvas"
      pad="spacing24"
      border={{ color: 'border2' }}
    >
      <Box
        justify="center"
        height={{ min: '40px', max: '40px'}}
        margin={{ bottom: 'spacing16' }}
      >
        <Inscription size="20px">
          Current Plans
        </Inscription>
      </Box>

      <Box data-testid="EmployerTerminationPlanList_table">
        {planList.length === 0 ? (
          <EmployerTerminationPlanListPlaceholder />
        ) : (
          <>
            <TransactionsListHeader
              items={headers}
              style={{
                paddingInline: '12px',
                paddingBlock: '6px',
                marginBottom: 0,
                borderRadius: '8px',
              }}
            />

            {planList.map((item) => (
              <FlexListItem
                key={item.id}
                fields={item.fields}
                id={item.id}
                rowWrapperStyle={{
                  marginTop: '2px',
                }}
                rowStyle={{
                  border: 'none',
                  paddingInline: '12px',
                  paddingBlock: 0,
                  minHeight: '40px',
                  boxShadow: 'none',
                }}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};
