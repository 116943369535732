import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Text } from '@common-fe/common-fe';

// @ts-ignore
import elevateDisclosureAndConsent from '@/assets/docs/ELEVATE ESIGN DISCLOSURE AND CONSENT v1.pdf';
import { StyledAnchor } from '@/modules/transaction/components/Statements/components/ElectronicAgreement/ElectronicAgreement.styles';
import globalTheme from '@/styles/theme';

export interface ElectronicAgreementCheckboxProps {
  isChecked: boolean;
  onChecked: () => void;
  isError?: boolean;
  fontSize?: string;
}

const ElectronicAgreementCheckbox: React.FC<ElectronicAgreementCheckboxProps> = ({
  isChecked, onChecked, isError, fontSize
}) => {
  const { t } = useTranslation();

  return (
    <Box direction="row" align="center">
      <Checkbox
        checked={isChecked}
        onChange={onChecked}
        error={isError}
        backgroundColor={globalTheme.colors.canvas}
        testId="electronic-agreement"
        name="electronic-agreement"
      />
      <Box>
        <Text size={fontSize || 'large'}>{t('I would like to receive paperless statements and letters.')}</Text>
        <Text size={fontSize || 'large'}>
          {t('I have read and accept the')}&nbsp;
          <StyledAnchor size={fontSize || 'large'} href={elevateDisclosureAndConsent} target="_blank">
            {t('Electronic Disclosure Agreement.')}
          </StyledAnchor>
        </Text>
      </Box>
    </Box>
  );
};

export default ElectronicAgreementCheckbox;
