import { storeCreator } from '@/utils/store/store.utils';

export interface ProfileInformationPayload {
  email: string;
  phonenumber: string;
  birthdate: string;
  uniqueId: string;
  lastDigits: string;
  dontReceiveCard: boolean;
}
interface State {
  state: ProfileInformationPayload;
  setState: (values: Partial<ProfileInformationPayload>) => void;
  showErrors: boolean;
  errors: Partial<ProfileInformationPayload>,
  setShowErrors: (value: boolean) => void;
  setErrors: (values: Partial<ProfileInformationPayload>) => void
}

export const useStore = storeCreator<State>((set) => ({
  state: {
    email: '',
    phonenumber: '',
    birthdate: '',
    uniqueId: '',
    lastDigits: '',
    dontReceiveCard: false,
  },
  showErrors: false,
  errors: {},
  setState: (values: Partial<ProfileInformationPayload>) => set((currentState) => ({
    state: {
      ...currentState.state,
      ...values,
    },
  })),
  setShowErrors: (value: boolean) => set(() => ({
    showErrors: value,
  })),
  setErrors: (values: Partial<ProfileInformationPayload>) => set(() => ({
    errors: values,
  })),
}));

export default useStore;
