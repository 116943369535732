import React, {
  useCallback, useEffect,
  useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inscription } from '@common-fe/common-fe';

import { FileImage } from '@/common/constants';
import { ModalWrapper } from '@/components';
import DropScan from '@/components/controls/DropScan';
import { ParsedFileByOcr } from '@/modules/transaction/components/ReimburseMe/FileScan/fileScan.types';

import { useClaimInfoStore } from '../store/useClaimInfo.store';
import useFilesStore from '../store/useFiles.store';
// import useQRCodeStore from '../store/useQRCode.store';
import { UploadFileQRCodeSection } from '../UploadFileQRCode';

import { ReUploadFileScan } from './ReUploadFileScan';
import { useFileUploadPopupStore, useQrFileUploadAdditionalActionsStore } from './store';

enum Steps {
  FILE_UPLOAD = 'FILE_UPLOAD',
  FILE_SCAN = 'FILE_SCAN',
}

export const FileUploadPopup = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(Steps.FILE_UPLOAD);
  const visible = useFileUploadPopupStore((store) => store.modalVisible);
  const setScannedDocument = useFileUploadPopupStore((store) => store.handleSetReUploadedOcrFile);
  const handleSetIsScanningCanceled = useFileUploadPopupStore((store) => store.handleSetIsScanningCanceled);
  const { handleChangeVisibility } = useFileUploadPopupStore();

  const resetClaimInfo = useClaimInfoStore((state) => state.handleReset);
  const files = useFilesStore((state) => state.files);
  const setFiles = useFilesStore((state) => state.setFiles);
  const setActiveFile = useFilesStore((state) => state.setActiveFile);
  const setAdditionalAction = useQrFileUploadAdditionalActionsStore((state) => state.handleSetAdditionalAction);
  const setResetFileStore = useQrFileUploadAdditionalActionsStore((state) => state.handleSetResetFileStore);
  const resetQrFileUploadStore = useQrFileUploadAdditionalActionsStore((state) => state.handleReset);

  const handleSetFiles = useCallback((fileObjects: FileImage[]) => {
    const allFiles = [...files, ...fileObjects];
    setFiles(allFiles);
    setActiveFile(allFiles.length - 1);
    setStep(Steps.FILE_SCAN);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleStartScanning = useCallback(() => {
    handleSetIsScanningCanceled(false);
  }, [handleSetIsScanningCanceled]);

  const handleOnGetScannedDocument = useCallback((data: ParsedFileByOcr) => {
    resetClaimInfo();
    setScannedDocument(data);
  }, [resetClaimInfo, setScannedDocument]);

  const handleCancel = useCallback(() => {
    handleSetIsScanningCanceled(true);
  }, [handleSetIsScanningCanceled]);

  // const numberOfFiles = useMemo(() => files.length, [files]);

  // useEffect(() => {
  //   handleChangeVisibility(false);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [numberOfFiles]);

  useEffect(() => {
    setResetFileStore(true);
    setAdditionalAction(() => {
      resetClaimInfo();
      setActiveFile(0);
      setStep(Steps.FILE_SCAN);
    });
  }, [resetClaimInfo, setActiveFile, setAdditionalAction, setResetFileStore]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => resetQrFileUploadStore(), []);

  return (
    <ModalWrapper testId="FileUploadPopup_modal_wrapper" visible={visible} onSetVisible={handleChangeVisibility}>
      <Box align="center">
        {step === Steps.FILE_UPLOAD
        && (
          <>
            <Inscription weight={700} size="24px" lineHeight="32px">
              {(t('Upload Document'))}
            </Inscription>
            <Box
              pad="spacing24"
              margin={{ top: 'spacing24' }}
              background={{ color: 'module' }}
              round="moduleRound"
              direction="row"
              gap="spacing24"
            >
              <Box basis="1/2">
                <DropScan
                  onFilesAdded={handleSetFiles}
                  isCenterTitle
                  wrapperStyle={{ padding: 0 }}
                />
              </Box>
              <Box basis="1/2">
                <UploadFileQRCodeSection />
              </Box>
            </Box>
          </>
        )}
        {step === Steps.FILE_SCAN
          && (
            <ReUploadFileScan
              onNext={() => {
                setStep(Steps.FILE_UPLOAD);
                handleChangeVisibility(false);
              }}
              onGetScannedDocument={handleOnGetScannedDocument}
              onStartScanning={handleStartScanning}
              onCancel={handleCancel}
            />
          )}
      </Box>
    </ModalWrapper>
  );
};
