import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Field, FieldTypes, Inscription } from '@common-fe/common-fe';

import { centeredFieldStyles } from '@/modules/user/components/SignUp/signUp.constants';

import { VerifyMethod } from '../verificationForm.types';

export default () => {
  const { t } = useTranslation();

  const verificationOptions = useMemo(() => [
    {
      label: 'Email',
      value: VerifyMethod.EMAIL,
      viewModeHintText: (
        <Box margin={{ top: 'spacing8' }} width="320px">
          <Inscription>
            {t('We’ll send you an email to the email address listed. Just click verify and you’ll be all set to Sign in.')}
          </Inscription>
        </Box>
      ),
    },
  ], [t]);

  const fields = useMemo<Field[]>(() => [
    {
      name: 'verification',
      type: FieldTypes.Radio,
      label: (
        <Inscription>
          {t('Verification')}
        </Inscription>
      ),
      showRequireIcon: true,
      options: verificationOptions,
      defaultValue: VerifyMethod.EMAIL,
      ...centeredFieldStyles,
    },
  ], [verificationOptions, t]);
  return fields;
};
