import { storeCreator } from '@/utils/store/store.utils';

import { FormField } from '../../ReimburseMe.types';

export interface FilterByValues {
  patient?: string;
  service?: string;
  serviceDates?: string;
}

interface State {
  filterByValues?: FilterByValues;
  editedFields: Set<FormField>;
  setFilterValues: (values: FilterByValues) => void;
  setEditedFields: (value: FormField) => void;
  resetFilterValues: () => void;
}

export const useClaimInfoFieldsFilterStore = storeCreator<State>((set) => ({
  filterByValues: undefined,
  editedFields: new Set<FormField>(),

  setFilterValues: (values: FilterByValues) => set((state) => ({
    ...state,
    filterByValues: {
      ...state.filterByValues,
      ...values,
    },
  })),

  setEditedFields: (value: FormField) => set((state) => ({
    ...state,
    editedFields: state?.editedFields?.add(value),
  })),

  resetFilterValues: () => set((state) => ({
    ...state,
    filterByValues: undefined,
    editedFields: new Set<FormField>(),
  })),
}));
