import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text,TransactionsNotFoundIcon } from '@common-fe/common-fe';

import { FUNDS_TABS } from '../SelfDirected.types';

interface Props {
  currentTab?: string;
}
const SelfDirectedFundsPlaceholder: React.FC<Props> = ({ currentTab }) => {
  const { t } = useTranslation();
  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      margin={{ vertical: 'l' }}
      data-testid="selft-directed-funds-placeholder"
    >
      <TransactionsNotFoundIcon color="iconSecondary" size="72px" />
      <Box margin={{ top: 's' }}>
        <Text
          size="medium"
          weight={700}
        >
          {currentTab === FUNDS_TABS.SELECTED ? t('There are no selected funds') : t('There are no funds')}
        </Text>
      </Box>

    </Box>
  );
};
export default SelfDirectedFundsPlaceholder;
