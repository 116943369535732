import { useMemo } from 'react';
import {
  Notification,
  NotificationPriority,
  NotificationStatusColorType, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { FULL_DATE_TIME_FORMATE } from '@/common/constants';
import REGEXP from '@/common/regexp';

import { RawNotification } from '../notification.types';

const DATE_PREFIX = 'date_';

const NONE_VALUE = 'none';
export default (notifications: RawNotification[]) => {
  const formatedNotifications = useMemo(() => notifications.map((item) => {
    let status = NotificationStatusColorType.DEFAULT;
    if (item.priority === NotificationPriority.Important) {
      status = NotificationStatusColorType.ERROR;
    } else if (item.priority === NotificationPriority.Info) {
      status = NotificationStatusColorType.SUCCESS;
    } else if (item.priority === NotificationPriority.NeedHelp) {
      status = NotificationStatusColorType.WARNING;
    }

    let { title, page, description } = item;
    if (item.templateVariablesValues) {
      _.keys(item.templateVariablesValues).forEach((key) => {
        const replaceTemplateString = (field?: string) => {
          if (!field) {
            return '';
          }
          const template = `{{${key}}}`;
          const value = _.get(item, `templateVariablesValues.${key}`, '');
          if (key.includes(DATE_PREFIX) && field.includes(DATE_PREFIX)) {
            let formated = '';
            const date = dayjs(value);
            if (date.isValid()) {
              formated = date.format(FULL_DATE_TIME_FORMATE);
            } else {
              formated = value;
            }

            return field.replace(template, formated);
          }

          return field.replace(template, value);
        };
        title = replaceTemplateString(title);
        page = replaceTemplateString(page);
        description = replaceTemplateString(description);
      });
    }
    page = page ? page.replace(REGEXP.INSIDE_CURLED_DOUBLE_BRAKETS, NONE_VALUE) : page;

    return {
      id: `${item.id}`,
      createdAt: dayjs(item.createdAt).toDate(),
      text: title,
      additionalText: description,
      time: '',
      status,
      notificationStatus: item.status,
      page,
    } as Notification;
  }), [notifications]);

  return formatedNotifications;
};
