import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useAuthStore } from '@/modules/user/stores';

import { BankFields, BankInfo } from './useBankFields';

interface AccountDto {
  id?: number,
  description: string,
  number?: string,
  available_balance?: number,
  number_length?: number,
  on_deposit_balance?: number,
  live_balance?: number,
  parent_account_id: number,
  created_at?: string,
  modified_at?: string,
  leaf_node: boolean,
  routing_transit_number?: string,
  account_type: string,
  account_category: string,
  organization_id?: number,
  organization_path?: string,
  employee_id?: number,
}

interface AccountsWithPaginationDto {
  content: AccountDto[],
  empty: boolean,
  number: number,
  numberOfElements: number,
  size?: number,
  totalElements: number,
  totalPages: number,
}

const GET_BANK_ACCOUNTS_QUERY_KEY = 'getBanksAccountsQueryKey';
const GET_BANK_ACCOUNT_BY_ID_QUERY_KEY = 'getBankAccountByIdQueryKey';

export const useGetExternalBankAccountsQuery = () => {
  const { user } = useAuthStore();
  const employeeId = user?.employee?.id;
  const params = {
    employee_id: employeeId,
    type: 'EXTERNAL',
    status_type: 'OPEN',
  };
  const { data, isLoading } = useQuery(
    [GET_BANK_ACCOUNTS_QUERY_KEY],
    () => api.get<AccountsWithPaginationDto>(PATHS.BANC_ACCOUNT, { params }),
  );

  const externalBankAccounts = useMemo(() => data?.data.content
    .map((account) => ({
      id: account.id,
      [BankFields.associatedName]: account.description,
      [BankFields.routingNumber]: account.routing_transit_number,
      [BankFields.accountNumber]: account.number,
      [BankFields.organizationPath]: account.organization_path,
      [BankFields.createDate]: dayjs(account.created_at || '').toDate(),
    })) || [], [data]);

  return {
    data: data?.data,
    externalBankAccounts,
    isLoading,
  };
};

export const useGetBankAccountByIdQuery = (accountId?: string | number) => {
  const { data, isLoading } = useQuery(
    [GET_BANK_ACCOUNT_BY_ID_QUERY_KEY, accountId],
    accountId
      ? () => api.get<AccountDto>(PATHS.BANC_ACCOUNT_BY_ID(accountId))
      : () => null,
    {
      cacheTime: 0,
    },
  );

  const currentBankAccount = useMemo(() => (data?.data
    ? ({
      id: data?.data.id,
      [BankFields.associatedName]: data?.data.description,
      [BankFields.routingNumber]: data?.data.routing_transit_number,
      [BankFields.accountNumber]: data?.data.number
        ? data?.data.number
        : Array(data?.data.number_length).fill('*').join(''),
      [BankFields.accountType]: data?.data.account_category,
      [BankFields.organizationPath]: data?.data.organization_path,
      [BankFields.createDate]: dayjs(data?.data.created_at || '').toDate(),
    } as BankInfo)
    : undefined), [data]);

  return {
    data: data?.data,
    currentBankAccount,
    isLoading,
  };
};
