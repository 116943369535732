import _ from 'lodash';

import { NonCleanableFields, ParsedFileByOcr } from './fileScan.types';

const keysToKeep = Object.values(NonCleanableFields);

export const clearScannedDocument = (scannedDocument?: ParsedFileByOcr) => {
  const deletedScannedDocument = scannedDocument 
    ? _.pick(scannedDocument, keysToKeep)
    : undefined;

  return deletedScannedDocument;
};
