import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { CONFIG } from '@/utils';

import App, { ThemeWrapper } from './modules/core/components';
import MaintenanceWrapper from './modules/user/MaintenanceWrapper';

// import TokenRefreshWrapper from './modules/core/components/TokenRefreshWrapper';
import '@common-fe/common-fe/dist/style.css';
import './styles/global.scss';

dayjs.extend(relativeTime);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={CONFIG.RECAPTCHA_KEY}>
      <BrowserRouter>
        <ThemeWrapper>
          {/* <TokenRefreshWrapper> */}
          <QueryClientProvider client={queryClient}>
            <MaintenanceWrapper>
              <App />
            </MaintenanceWrapper>
          </QueryClientProvider>
          {/* </TokenRefreshWrapper> */}
        </ThemeWrapper>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
