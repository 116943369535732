import { useCallback } from 'react';

import { api } from '@/api';
import { PATHS } from '@/common';

const getFilesByDocumentId = (documentId?: string) => api
  .get(PATHS.GET_FILES_BY_DOCUMENT_ID(documentId));

const useOpenFile = () => {
  const handleOpen = useCallback(async (fileId: string) => {
    const response = await getFilesByDocumentId(fileId);
    const fileUrl = response?.data?.files?.[0]?.s3_url;

    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  }, []);

  return { handleOpen };
};

export default useOpenFile;
