import { useMemo, useState } from 'react';

import { OneTimeContributionFormValues } from '../contribution.types';

export const useOneTimeContribution = () => {
  const [formValues, setFormValues] = useState<OneTimeContributionFormValues>({});
  const [infoSetup, setInfoSetup] = useState(false);
  const [rollover, setRollover] = useState(false);

  const currentTaxYear = useMemo(() => formValues?.taxYear, [formValues.taxYear]);

  return {
    formValues,
    setFormValues,
    infoSetup,
    setInfoSetup,
    rollover,
    setRollover,
    currentTaxYear,
  };
};
