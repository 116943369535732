import React from 'react';
import { Box } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import ModalWrapper from '@/components/wrappers/ModalWrapper';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  children?:React.ReactNode;
}

export const TransactionDocumentsPreviewModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  modalTitle,
  children,
}) => (
  <ModalWrapper
    visible={visible}
    title={modalTitle}
    onSetVisible={onSetVisible}
    testId="transaction-documents-preview-Modal-wrapper-id"
    contentStyle={{
      maxWidth: '990px',
    }}
  >
    <Box
      width={{
        min: '990px',
      }}
      height={{
        min: '493px',
      }}
      background="module"
      pad={{ top: 'l', horizontal: 'l', bottom: 'm' }}
      round="moduleRound"
      margin={{ bottom: 'l' }}
    >
      {children}
    </Box>

    <Box alignSelf="end">
      <AppButton
        onClick={() => onSetVisible(false)}
        buttonType="secondary"
      >
        Close
      </AppButton>
    </Box>
  </ModalWrapper>
);
