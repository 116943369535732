import React from 'react';
import { FlexForm } from '@common-fe/common-fe';

import theme from '@/styles/theme';

import { Patient } from '../usePatients.query';
import { useRelationshipsTypesQuery } from '../useRelationshipsTypes.query';

import usePatientFields from './usePatientFields';

interface Props {
  onSubmit: (value: Patient) => void;
  patientName?: string,
  defaultValue?: Partial<Patient>,
  footer?: React.ReactNode;
}

const PatientForm: React.FC<Props> = ({ patientName, onSubmit, defaultValue, footer }) => {
  const { formattedRelationshipsTypes } = useRelationshipsTypesQuery();
  const fields = usePatientFields({
    relationshipTypes: formattedRelationshipsTypes,
    defaultValue,
    patientName,
  });

  return (
    <>
      <FlexForm
        fields={fields}
        contentStyles={theme.formFieldsEdging}
        paddingless
        isNarrowLabelMode
        editMode
        onSubmit={onSubmit}
        footer={footer}
      />
    </>
  );
};

export default PatientForm;
