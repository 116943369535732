import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import SelfDirectedAllocation from '@/modules/investments/SelfDirected/SelfDirectedAllocation';
import { useQueryParams } from '@/utils';
const INVESTOR_ID_QUERY = 'investorId';
const SelfDirectedDistributionPage = () => {
  const { accountId = '' } = useParams<{ accountId: string }>();
 
  const query = useQueryParams();
  const investorId = useMemo(() => query.get(INVESTOR_ID_QUERY), [query]);
  if (!accountId) {
    return null;
  }
  return (
    <SelfDirectedAllocation accountId={accountId} investorId={`${investorId || ''}`} />
  );
};
export default SelfDirectedDistributionPage;
