import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@common-fe/common-fe';

import colors from '@/styles/colors';

const InvestmentStatus = () => {
  const { t } = useTranslation();
  return (
    <Box direction="row" justify="end" align="center" data-testid="Investment-status-wrapper-id">
      <Text>{t('In Progress')}</Text>
      <Box
        width="10px"
        height="10px"
        margin={{ left: 'xs' }}
        background={colors.statusColors.needsHelp}
        style={{ borderRadius: '50%' }}
      />
    </Box>
  );
};
export default InvestmentStatus;
