import React from 'react';
import { Box, ToggleButton } from '@common-fe/common-fe';

import { NotificationType } from '../notification.types';

interface Props {
  activeCount?: number;
  dismissedCount?: number;
  currentType: NotificationType;
  onSetCurrentType: (value: NotificationType) => void;
}
const NotificationTypePicker: React.FC<Props> = ({
  activeCount = 0,
  dismissedCount = 0,
  currentType, onSetCurrentType,
}) => (
  <Box direction="row" margin={{ bottom: 'm' }}>
    <ToggleButton
      isLarge
      testId="NotificationTypePicker_active"
      active={currentType === NotificationType.Active}
      onClick={() => onSetCurrentType(NotificationType.Active)}
    >

      Active ({activeCount})
    </ToggleButton>
    <ToggleButton
      isLarge
      testId="NotificationTypePicker_dismissed"
      active={currentType === NotificationType.Dismissed}
      onClick={() => onSetCurrentType(NotificationType.Dismissed)}
    >
      Dismissed ({dismissedCount})
    </ToggleButton>
  </Box>
);

export default NotificationTypePicker;
